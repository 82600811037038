/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import ELCreatePopoverView from "./ELCreatePopoverView";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../../view/IBaseController";
import ELCreateOnDemand from "../../templates/el-create-on-demand/ELCreateOnDemand";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { ELCreateOnDemandAction } from "../../../../common/interfaces/creations/ELCreateOnDemandTypes";

class ELCreatePopover extends IViewController {
    private _owner: IViewController;
    private _createOnDemand: ELCreateOnDemand;

    constructor(owner: IViewController) {
        super();
        this._owner = owner;
        this._createOnDemand = new ELCreateOnDemand(this);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    popoverButtonPress(): void {
        const ingestObject = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utility, IngestEventSubTypes.click, IngestEventTypes.create);
        ingestObject[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.grid;
        this._owner.notify({ type: WorkflowActionType.ingest, payload: ingestObject });
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELCreatePopoverView, { key: "createPopver", popoverButtonPress: this.popoverButtonPress.bind(this) }, this._createOnDemand.getView("mediaGrid"));
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        ReactDOM.render(
            React.createElement(ELCreatePopoverView, { popoverButtonPress: this.popoverButtonPress.bind(this) }, this._createOnDemand.getView("mediaGrid")),
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch(action.type) {
            case ELCreateOnDemandAction.viewAllDialog: {
                this.popoverButtonPress();
                handled = true;
                break;
            }
            default: {
                this._owner.notify(action);
                handled = true;
                break;
            }
        }

        return handled;
    }
}

export default ELCreatePopover;
