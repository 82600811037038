/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Router } from 'react-router-dom';

//modules and utils
import { history } from '../../../../utils/Utils';

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../../IWorkflow";
import IWorkspace from "../../../IWorkspace";
import { CreationsComingSoonView } from "./CreationsComingSoonView";

class CreationsComingSoon extends IWorkflow {

    private _link!: string;

    constructor(workspace: IWorkspace) {
        super(workspace, WorkflowsName.creationsComingSoon);
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const creationsComingSoonView = React.createElement(CreationsComingSoonView, {
            controller: this,
        });
        const router = React.createElement(Router, { history }, creationsComingSoonView);
        ReactDOM.render(
            router,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
    }

}

export { CreationsComingSoon };
