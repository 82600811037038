/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useState, useRef, MouseEvent } from "react"

//Adobe Internal
import { AdobeAsset } from "@dcx/common-types";
import { ProgressCircle } from "@react-spectrum/progress";
import { Checkbox } from "@react-spectrum/checkbox";
import { useHover, usePress, useLongPress } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IconType } from "../../../../assets/IconConstants";
import { ELIcon } from "../el-icon/ELIconView";
import Utils from "../../../../utils/Utils";
import { AssetMimeType } from "../../../../common/interfaces/storage/AssetTypes";
import useCanvasDataURL from "../../../../utils/hooks/useCanvasDataURL";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { ERROR_THUMBDATA } from "../../../../utils/Constants/Constants";
import { ELMediaSelectionMode } from "../../../../common/interfaces/media/ELThumbTypes";

import "./ELMediaThumb.scss"

export interface ELMediaThumbProps {
    height: string | number,
    width: string | number,
    asset: AdobeAsset,
    mediaName?: string,
    toggleMediaTileSelectionFunc: (asset: AdobeAsset) => void,
    isSelected: boolean,
    base64Img: string,
    inSelectionMode: boolean,
    selectionEnabled: boolean,
    onPress: (asset: AdobeAsset) => void,
    selectionMode: ELMediaSelectionMode
}

type ThumbProps = Pick<ELMediaThumbProps, "asset"> & {
    sThumbData: string,
    // to call when render complete
    onLoadComplete: (success: boolean) => void
}

const getThumbOverlayActionIcon = (mimeType: AssetMimeType): React.ReactElement => {
    switch (mimeType) {
        case AssetMimeType.video:
            return (
                <div className="thumb_icon">
                    <ELIcon iconkey={IconType.videoPlayIcon} />
                </div>);
        case AssetMimeType.gif:
            return (
                <div className="thumb_icon">
                    <ELIcon iconkey={IconType.gifIcon} />
                </div>);
    }

    return <></>;
}

const Thumb = (props: ThumbProps): React.ReactElement => {
    const { asset, sThumbData, onLoadComplete } = props;
    const mimeType = Utils.getAssetMimeType(asset);
    const intlHandler = IntlHandler.getInstance();
    const thumbDisplayClass = "img-thumb";
    const { previewReady, dataUrl } = useCanvasDataURL({ elAsset: asset, thumbData: sThumbData });
    const progressCircleThumb = (
        <div className="progress_loader">
            <ProgressCircle isIndeterminate size="M" margin="auto"
                aria-label={intlHandler.formatMessage("progress-circle")} />
        </div>
    );
    if (sThumbData.length === 0) {
        return progressCircleThumb;
    } else if (sThumbData === ERROR_THUMBDATA) {
        onLoadComplete(true);
        const errorIconKey = mimeType === AssetMimeType.video ? IconType.staticVideoThumb : IconType.staticImgThumb;
        return (<ELIcon className={thumbDisplayClass} iconkey={errorIconKey} />);
    }

    switch (mimeType) {
        case AssetMimeType.gif: {
            if (previewReady) {
                onLoadComplete(true);
                return (
                    <>
                        <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className={thumbDisplayClass} src={dataUrl} alt={props.asset.name ?? ""} />
                        {getThumbOverlayActionIcon(mimeType)}
                    </>
                );
            }
            return progressCircleThumb;
        }
        default:
            onLoadComplete(true);
            return (
                <>
                    <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className={thumbDisplayClass} src={sThumbData} alt={props.asset.name ?? ""} />
                    {getThumbOverlayActionIcon(mimeType)}
                </>
            );
    }

}

const ELMediaThumb = (props: ELMediaThumbProps): React.ReactElement<ELMediaThumbProps> => {
    // Hover Props shall be used in all the returned thumbs since isHovered needs to be updated
    // across thumb rendering 
    const { hoverProps, isHovered } = useHover({});
    const [thumbLoaded, setThumbLoaded] = useState(false);
    const thumpTop = useRef(0);
    const imgContainerRef = useRef(null);
    const componentContainerRef = useRef(null);

    const toggleTileSelection = (): void => {
        if (props.selectionEnabled)
            props.toggleMediaTileSelectionFunc(props.asset);
    }

    const { longPressProps } = useLongPress({
        onLongPress: () => {
            if (!PlatformUtils.isRunningOnAndroid())
                toggleTileSelection();
        }
    });

    const imgContainerPressProps = usePress({
        onPress: () => {
            if (props.selectionMode === ELMediaSelectionMode.SINGLE_CLICK)
                toggleTileSelection();

            if (isPressScrollSafe())
                props.onPress(props.asset);
        },
        onPressStart: () => { setThumbTop() },
        shouldCancelOnPointerExit: true
    });

    const containerPressProps = usePress({
        onPress: toggleTileSelection,
        shouldCancelOnPointerExit: true
    });

    const onContextMenu = (e: MouseEvent): void => {
        if (PlatformUtils.isRunningOnAndroid()) {
            e.preventDefault();
            e.stopPropagation();
            toggleTileSelection();
        }
    };

    const isPressScrollSafe = (): boolean => {
        /**
         * don't want to impact desktop and android
         */
        if (!PlatformUtils.deviceHasTouchSupport() || PlatformUtils.isRunningOnAndroid())
            return true;

        /**
            * HACK_FIX - NEEDS_REVISIT for https://jira.corp.adobe.com/browse/EO-4203597
            * Ideally setting  shouldCancelOnPointerExit to true would've fixed the issue as verified on Android.
            * But on iOS we still get an onPress event from React Spectrum lib even after pointer has exited. 
            * Probably would get fixed in future React Spectrum update. Then we can remove this check.
            * Fix: Check onPressStart thumb position is same when onPress release event is received. If yes, then handle onPress.
            **/
        const scrollThreshold = 10;
        if (Math.abs(getUpdatedThumbTop() - thumpTop.current) <= scrollThreshold)
            return true;
        return false;
    };

    const getUpdatedThumbTop = (): number => {
        if (imgContainerRef.current) {
            const thumbTop = (imgContainerRef.current as HTMLDivElement).getBoundingClientRect().top;
            return thumbTop;
        }

        return thumpTop.current;
    }

    const setThumbTop = (): void => {
        thumpTop.current = getUpdatedThumbTop();
    }

    const getContainerClassName = (): string => {
        let className = "grid-media-tile";
        if (props.isSelected) {
            className += " grid-media-tile__selected";
        }
        if (!thumbLoaded) {
            className += " loading-background"
        }
        if (isHovered) {
            className += " grid-media-tile__hover"
        }
        return className;
    }

    const showCheckBox = (): boolean => {
        if (!props.selectionEnabled)
            return false;
        else if (isHovered || props.isSelected || props.inSelectionMode)
            return true;
        return false;
    }


    const getCheckBox = (): React.ReactElement => {
        return (<>
            {showCheckBox() &&
                <div className="checkBox-container" >
                    <Checkbox isSelected={props.isSelected} onChange={toggleTileSelection}
                        UNSAFE_className="media-thumb-checkbox" data-testid="media-thumb-checkbox" aria-label="select media" />
                </div>}
        </>);
    }

    const getTileThumbContainer = (): React.ReactElement => {
        if (props.inSelectionMode) {
            const { pressProps } = containerPressProps;
            return (
                <div className={getContainerClassName()}  {...mergeProps(pressProps, longPressProps, hoverProps)} style={{
                    height: props.height,
                    width: props.width,
                }} ref={componentContainerRef} data-testid="media-tile-container" onContextMenu={(e) => onContextMenu(e)}>
                    <Thumb asset={props.asset} sThumbData={props.base64Img} onLoadComplete={setThumbLoaded} />
                    {getCheckBox()}
                </div>);
        } else {
            const { pressProps } = imgContainerPressProps;
            return (<div className={getContainerClassName()} style={{
                height: props.height,
                width: props.width,
            }} {...hoverProps} data-testid="media-tile-container">
                <div ref={imgContainerRef} {...mergeProps(pressProps, longPressProps)} onContextMenu={(e) => onContextMenu(e)}
                    className="thumb-container" data-testid="thumb-container">
                    <Thumb asset={props.asset} sThumbData={props.base64Img} onLoadComplete={setThumbLoaded} />
                </div>
                {getCheckBox()}
            </div>);
        }
    }

    return (
        <>
            {getTileThumbContainer()}
        </>
    );
}

export default ELMediaThumb;