/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

//Application Specific
import store from '../../../../stores/store';
import { ViewAction } from '../../../IBaseController';
import ITemplateViewController from '../../../ITemplateViewController';
import ELSlideshowIntroView from './ELSlideshowIntroView';

export default class ELSlideshowIntro extends ITemplateViewController {

	createView(container: HTMLElement): void {
		super.createView(container);

		const introView = React.createElement(ELSlideshowIntroView, { controller: this });
		const reactReduxProviderHydratedIntroView = React.createElement(Provider, { store: store }, introView);

		ReactDOM.render(
			reactReduxProviderHydratedIntroView,
			container
		);
	}

	destroyView(): void {
		if (this.container) {
			ReactDOM.unmountComponentAtNode(this.container)
		}
		super.destroyView();
	}

	initialize(dispatch?: React.Dispatch<ViewAction>): void {
		super.initialize(dispatch);
	}

	destroy(): void {
		super.destroy();
	}
}
