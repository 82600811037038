/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { IEditingEngine } from "../../../../editors/editingEngines/IEditingEngine";

export enum ELPIEDocActions {
    layerAdded = "LAYER_ADDED",
    layerUpdated = "LAYER_UPDATED"
}


export interface ELPIEDocPayload {
    filename: string,
    buffer: Uint8Array,
    engine: IEditingEngine<unknown, unknown>
}