/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third Party
import { AxiosResponse } from 'axios';

//Adobe Internal
import { API } from "@elements/elementswebcommon";

//Application specific
import Logger, { LogLevel } from '../../utils/Logger';
import { IReportAbuseData } from "./ReportAbuseInterface";

/**
 * Leia is the Report Abuse Web Service for assets managed in ACP repositories
 * and accessed via Storage Repository API (Repo-API)
 * https://wiki.corp.adobe.com/display/TnS/Leia+Client+Onboarding#LeiaClientOnboarding-ServiceInterface
 */

export const PRODUCT_ID = "Photoshop Elements";

export class LeiaService {

    private static _instance: LeiaService | null = null;
    private _serviceAPI: API;
    private readonly _reportAbuseEndpoint: string = "/v1/acp_report_abuse";

    static getInstance(): LeiaService {
        if (LeiaService._instance === null)
            LeiaService._instance = new LeiaService();
        return LeiaService._instance;
    }

    constructor() {
        this._serviceAPI = new API(`${process.env.REACT_APP_LEIA_API_URL}`);
    }

    async reportAbuse(data: IReportAbuseData): Promise<AxiosResponse>  {
        try {
            data.productId = PRODUCT_ID; //overwrite explicitly here
            const requestBody = JSON.stringify(data);
            const config = {
                headers:  {
                    "Content-Type": "application/json",
                    "X-api-key": `${process.env.REACT_APP_LEIA_API_KEY}`,
                }
            }
            const response = await this._serviceAPI.post(requestBody, config, this._reportAbuseEndpoint);
            return response;
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "LeiaService:reportAbuse: ", (error as Error)?.message ?? error);
            return Promise.reject(error);
        }
    }
}
