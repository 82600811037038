/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

import "./ELSkeleton.scss";

interface ELSkeletonProps {
    variant: "rectangular",
    animation: "wave",
    height: string;
    width: string;
    dataTestId?: string
}

const ELSkeleton = (props: ELSkeletonProps): React.ReactElement => {
    const getVariantClass = (): string => {
        switch (props.variant) {
            case "rectangular":
            default:
                return "el-skeleton--rectangular";
        }
    }

    const getAnimationClass = (): string => {
        switch (props.animation) {
            case "wave":
            default:
                return "el-skeleton--wave";
        }
    }

    return (
        <div className={`${getVariantClass()} ${getAnimationClass()}`} style={{ width: props.width, height: props.height }} data-testid={props.dataTestId}/>
    )
}

ELSkeleton.defaultProps = {
    variant: "rectangular",
    animation: "wave",
    height: "2px",
    width: "100%"
}

export default ELSkeleton;