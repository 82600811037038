/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode } from "react";

//Adobe internal
import { Text, Flex } from "@adobe/react-spectrum";

//Application Specific
import { AssetMimeType } from "../../../../common/interfaces/storage/AssetTypes";

import "./ELFeatureBanner.scss";


export interface ELFeatureBannerProps {
    previewSrc: string,
    previewType: AssetMimeType
    title: string,
    description: string,
    children?: ReactNode,
}

const ELFeatureBannerView = (props: ELFeatureBannerProps): React.ReactElement => {

    return (
        <Flex UNSAFE_className="feature-banner-box">
            {props.previewType === AssetMimeType.video &&
                <video crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="feature-banner-img" src={props.previewSrc} playsInline loop autoPlay muted />}
            {props.previewType === AssetMimeType.image &&
                <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="feature-banner-img" alt="feature-data" src={props.previewSrc} />}
            <Flex UNSAFE_className="feature-banner-bottom-box" direction="column">
                <Flex UNSAFE_className="feature-banner-box__content" direction="column">
                    {(React.Children.count(props.children) === 1) ? <></> :
                        React.Children.toArray(props.children)[0]}
                    <Flex gap="1.5rem" direction="column" alignContent="start" justifyContent="start">
                        <Text UNSAFE_className="feature-banner-box__heading">{props.title}</Text>
                        <Text UNSAFE_className="feature-banner-box__description">{props.description}</Text>
                    </Flex>
                    {(React.Children.count(props.children) === 1) ? React.Children.toArray(props.children)[0] :
                        React.Children.toArray(props.children)[1]}
                </Flex>
            </Flex>
        </Flex>
    )
}

ELFeatureBannerView.defaultProps = {
    previewType: AssetMimeType.video
}

export default ELFeatureBannerView;