/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";
import { Menu, MenuTrigger, ActionButton, Section } from "@adobe/react-spectrum";
import { Item, CheckboxGroup, Checkbox } from "@adobe/react-spectrum"
import { Tooltip, TooltipTrigger } from "@adobe/react-spectrum";
import { Text } from "@adobe/react-spectrum";
import SortOrderDown from "@spectrum-icons/workflow/SortOrderDown";
import SortOrderUp from "@spectrum-icons/workflow/SortOrderUp";
import Close from "@spectrum-icons/workflow/Close";

//Application Specific
import { RootState } from "../../../../stores/store";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { Theme } from "../../../../utils/Theme";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import ELCustomIcon from "../el-custom-icon/ELCustomIcon";
import MediaGridConfigAction,
{
    MediaGridSortOrder,
    MediaGridSortBy,
    GridMediaType
} from "../../../../stores/actions/mediaGridConfigActions";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import ELPopover from "../el-popover/ELPopoverView";
import Utils from "../../../../utils/Utils";
import ELMediaGridToolBar from "./ELMediaGridToolbar";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import ImportButton from "../../organism/el-import-button/ELImportButton";
import ELButton from "../../atoms/el-button/ELButtonView";
import { ControllerAction } from "../../../IViewController";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELMediaGridToolbar.scss";
const IMPORT_DATE_KEY = "importDate";
const MODIFIED_DATE_KEY = "modifiedDate";
const CREATED_DATE_KEY = "createdDate";
const IMAGE = "image";
const VIDEO = "video";

interface ELMediaGridToolBarProps {
    controller: ELMediaGridToolBar
}

interface ELMediaGridFilterMenuProps {
    children: React.ReactElement
}

const ELMediaGridFilterMenu = (props: ELMediaGridFilterMenuProps): React.ReactElement => {
    const { width, isMobile } = useViewport();
    const [isOpen, setIsOpen] = useState(false);
    const MEDIA_FILTER_BREAKPOINT = 1060;

    useEffect(() => {
        if (width > MEDIA_FILTER_BREAKPOINT) {
            setIsOpen(false);
        }
    }, [width]);


    return (
        <ELPopover isDismissable hideArrow={true} isOpen={isOpen} placement="bottom" type={Utils.getSpectrumPopoverType(isMobile as boolean)}
            onOpenChange={(isOpen: boolean) => setIsOpen(isOpen)}>
            <ActionButton UNSAFE_className={"el-spectrum-action-button grid-toolbar-action-button"}>
                <ELIcon className="grid-toolbar_filter_icon" iconkey={IconType.filterIcon} />
            </ActionButton>
            <div className="grid-toolbar__mobile-checkbox-group">
                {props.children}
            </div>
        </ELPopover>
    );
}
export enum ELMediaGridToolbarViewAction {
    changeImportProgressState = "CHANGE_IMPORT_PROGRESS_STATE"
}

export const MediaTypeCheckBoxSelect = (props: { notify: (_: ControllerAction) => void }): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const configData = useSelector((state: RootState) => state.mediaConfigReducer)[GRID_CONFIG_KEY];
    const isFilterDisable = useSelector((state: RootState) => state.mediaGridToolbarStateReducer.isFilterDisable);
    const [sFilter, setFilter] = useState<Array<string>>();

    const dispatch = useDispatch();

    useEffect(() => {
        switch (configData.mediaType) {
            case GridMediaType.eImageAndVideo: {
                setFilter([IMAGE, VIDEO]);
                break;
            }
            case GridMediaType.eImageOnly: {
                setFilter([IMAGE]);
                break;
            }
            case GridMediaType.eVideoOnly: {
                setFilter([VIDEO]);
                break;
            }
            case GridMediaType.eNone: {
                setFilter([]);
                break;
            }
        }
    }, [configData.mediaType]);

    const handlerFilterTypeChange = (value: string[]): void => {
        // dispatch to state
        setFilter(value);
        const payload = configData;
        if (value.length === 2) {
            // image and video both selected
            payload.mediaType = GridMediaType.eImageAndVideo;
        } else if (value.length === 1 && value[0] === IMAGE) {
            payload.mediaType = GridMediaType.eImageOnly;
        } else if (value.length === 1 && value[0] === VIDEO) {
            payload.mediaType = GridMediaType.eVideoOnly;
        } else {
            payload.mediaType = GridMediaType.eNone;
        }
        dispatch(MediaGridConfigAction.updateConfig(payload));
        props.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.filter, value.length > 1 ? IngestEventSubTypes.all : value[0])
        });
    };

    const getCheckboxGroup = (showLabel: boolean, orientation: "vertical" | "horizontal"): React.ReactElement => {
        return (
            <CheckboxGroup isDisabled={isFilterDisable} orientation={orientation} label={showLabel ? intlHandler.formatMessage("show") : ""}
                labelAlign="end" labelPosition="side" onChange={handlerFilterTypeChange} value={sFilter}>
                <Checkbox value={IMAGE} UNSAFE_className="el-media-grid-toolbar-checkbox">
                    <span className="grid-toolbar__media-type-text">{intlHandler.formatMessage("photo")}</span>
                </Checkbox>
                <Checkbox value={VIDEO} UNSAFE_className="el-media-grid-toolbar-checkbox">
                    <span className="grid-toolbar__media-type-text">{intlHandler.formatMessage("video")}</span>
                </Checkbox>
            </CheckboxGroup>);
    }

    return (
        <>
            <Flex UNSAFE_className="grid-filter-toolbar__mobile">
                <ELMediaGridFilterMenu>
                    {getCheckboxGroup(false, "vertical")}
                </ELMediaGridFilterMenu>
            </Flex>
            <div className="grid-filter-toolbar__desktop">
                {getCheckboxGroup(true, "horizontal")}
            </div>
        </>
    );
}

interface ELMediaGridSortMenuProps {
    menuItems: any[],
    sortOrder: IconType,
    defaultSelectedKey: MediaGridSortBy,
    handleSortByChange: (key: React.Key) => void,
    reverseSortOrder: () => void
}

const ELMediaGridSortMenu = (props: ELMediaGridSortMenuProps): React.ReactElement => {
    const [selected, setSelected] = React.useState(new Set([props.defaultSelectedKey]));
    const intlHandler = IntlHandler.getInstance();
    const reverseOrderKey = "reverse-order";
    useEffect(() => {
        setSelected(new Set([props.defaultSelectedKey]));
    }, [props.defaultSelectedKey]);

    const handleSelectionChange = (keySet: "all" | Set<string | number>): void => {
        const selectedValue = [...keySet][0];

        if (selectedValue) {
            const selectedValueExists = props.menuItems.some(function (item) {
                return item.id === selectedValue;
            });

            if (selectedValueExists) {
                setSelected(new Set([selectedValue as MediaGridSortBy]));
                props.handleSortByChange(selectedValue);
            } else {
                props.reverseSortOrder();
            }
        }
    }

    const getMenuItems = (): React.ReactElement => {
        const menuList = props.menuItems.map((menu) =>
            <Item key={menu.id}>
                <Text UNSAFE_className="sort-order-picker__item">{menu.name}</Text>
            </Item>
        );

        return (<Menu selectionMode="single" defaultSelectedKeys={[props.defaultSelectedKey]} selectedKeys={selected}
            onSelectionChange={handleSelectionChange}>
            <Section>
                {menuList}
            </Section>
            <Section>
                <Item key={reverseOrderKey}>
                    {(props.sortOrder === IconType.sortOrderDown) ? <SortOrderDown size="S" /> : <SortOrderUp size="S" />}
                    <Text UNSAFE_className="sort-order-picker__item">{intlHandler.formatMessage("reverse-order")}</Text>
                </Item>
            </Section>
        </Menu>);
    }

    return (
        <MenuTrigger align="end">
            <ActionButton UNSAFE_className={"el-spectrum-action-button grid-toolbar-sort-dropdown"}>
                <ELCustomIcon variant="contained" iconkey={props.sortOrder} color={Theme.global.gray_controls_color_N}
                    hoverColor={Theme.global.gray_controls_color_H} width={"2.5rem"} height={"2.5rem"} />
            </ActionButton>
            {getMenuItems()}
        </MenuTrigger>
    );
}

// Util type function to provide ingestable information
const getIngestSortBy = (sortBy: MediaGridSortBy): string => {
    switch (sortBy) {
        case MediaGridSortBy.importDate:
            return IngestEventSubTypes.importDate;
        case MediaGridSortBy.modifiedDate:
            return IngestEventSubTypes.modifiedDate;
        case MediaGridSortBy.createdDate:
            return IngestEventSubTypes.createdDate;
        default:
            return "N/A";
    }
}

// Util type function to provide ingestable information
const getIngestSortOrder = (sortOrder: MediaGridSortOrder): string => {
    switch (sortOrder) {
        case MediaGridSortOrder.ascending:
            return IngestLogObjectValue.ascending;
        case MediaGridSortOrder.descending:
            return IngestLogObjectValue.descending;
        default:
            return "N/A";
    }
}

export const SortOrderSelectList = (props: { notify: (_: ControllerAction) => void }): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const configData = useSelector((state: RootState) => state.mediaConfigReducer)[GRID_CONFIG_KEY];
    const [sSortOrder, setSortOrderIcon] = useState(IconType.sortOrderDown);
    const dispatch = useDispatch();

    const sortOrderItems = [
        {
            name: intlHandler.formatMessage("imported"),
            id: MediaGridSortBy.importDate
        },
        {
            name: intlHandler.formatMessage("captured"),
            id: MediaGridSortBy.createdDate
        },
    ];

    useEffect(() => {
        if (configData.sortOrder === MediaGridSortOrder.ascending) {
            setSortOrderIcon(IconType.sortOrderUp);
        }
    }, [configData.sortOrder]);

    const reverseSortOrderIcon = (): void => {
        if (sSortOrder === IconType.sortOrderUp) {
            setSortOrderIcon(IconType.sortOrderDown);
            handleSortOrderChange(IconType.sortOrderDown);
        } else {
            setSortOrderIcon(IconType.sortOrderUp);
            handleSortOrderChange(IconType.sortOrderUp);
        }
    };

    const handleSortOrderChange = (sSortOrderIconType: IconType): void => {
        const payload = configData;
        payload.sortOrder = getSortOrderKey(sSortOrderIconType);
        dispatch(MediaGridConfigAction.updateConfig(payload));
        const ingestEventValue = getIngestSortBy(payload.sortBy) + "-" + getIngestSortOrder(payload.sortOrder);
        props.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.sort, ingestEventValue)
        });
    };

    const getSortByKey = (key: React.Key): MediaGridSortBy => {
        switch (key) {
            case IMPORT_DATE_KEY:
                return MediaGridSortBy.importDate;
            case MODIFIED_DATE_KEY:
                return MediaGridSortBy.modifiedDate;
            case CREATED_DATE_KEY:
                return MediaGridSortBy.createdDate;
            default:
                return MediaGridSortBy.importDate;
        }
    }

    const getSortOrderKey = (sSortOrder: IconType): MediaGridSortOrder => {
        switch (sSortOrder) {
            case IconType.sortOrderDown:
                return MediaGridSortOrder.descending;
            case IconType.sortOrderUp:
                return MediaGridSortOrder.ascending;
            default:
                return MediaGridSortOrder.descending;
        }
    }

    const handleSortByChange = (key: React.Key): void => {
        const payload = configData;
        payload.sortBy = getSortByKey(key);
        dispatch(MediaGridConfigAction.updateConfig(configData));
        const ingestEventValue = getIngestSortBy(payload.sortBy) + "-" + getIngestSortOrder(payload.sortOrder);
        props.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.sort, ingestEventValue)
        });
    }
    return (
        <>
            <Flex UNSAFE_className="grid-sort-options-toolbar__desktop" direction="row" height="100%" justifyContent={"center"} alignItems={"center"}>
                <ELMediaGridSortMenu menuItems={sortOrderItems} sortOrder={sSortOrder}
                    handleSortByChange={(key: React.Key) => handleSortByChange(key)}
                    reverseSortOrder={() => reverseSortOrderIcon()}
                    defaultSelectedKey={configData.sortBy} />
            </Flex>
            <Flex UNSAFE_className="grid-sort-options-toolbar__mobile" direction="row" height="100%" justifyContent={"center"} alignItems={"center"}>
                <ELMediaGridSortMenu menuItems={sortOrderItems} sortOrder={sSortOrder}
                    handleSortByChange={(key: React.Key) => handleSortByChange(key)}
                    reverseSortOrder={() => reverseSortOrderIcon()}
                    defaultSelectedKey={configData.sortBy} />
            </Flex>
        </>
    );
}

interface ELMediaGridHeadingProps {
    heading: string,
    selectedItemCount: number,
    notify: (_: ControllerAction) => void
}

export const ELMediaGridHeading = (props: ELMediaGridHeadingProps): React.ReactElement => {
    const { isMobile } = useViewport();
    const intlHandler = IntlHandler.getInstance();
    const rootDispatch = useDispatch();
    const ingestDeselectionCount = (): void => {
        props.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.selection, IngestEventTypes.click,
                IngestEventSubTypes.deSelectAll, props.selectedItemCount)
        });
    }
    const clearSelection = (): void => {
        rootDispatch(SelectedMediaListAction.updateSelectedMediaList([]));
        ingestDeselectionCount();
    };
    const inSelectionMode = props.selectedItemCount > 0;
    const itemCountLabel = intlHandler.formatMessage("selected", { itemCount: props.selectedItemCount });
    const itemCountHeading = inSelectionMode ?
        <TooltipTrigger delay={0} placement={"bottom"} offset={5}>
            <ELButton variant="secondary" onClick={clearSelection}>
                <div className="item-count-heading" >
                    <div className="item-count-heading__solid-fill" >
                        <Close size={isMobile ? "XXS" : "S"} aria-label={intlHandler.formatMessage("clear-selection")} UNSAFE_className="deselect-checkmark" />
                    </div>
                    <span className="item-count-heading__text">
                        {itemCountLabel}
                    </span>
                </div >
            </ELButton>
            <Tooltip>{intlHandler.formatMessage("clear-selection")}</Tooltip>
        </TooltipTrigger > : <></>;

    return (
        <Flex direction="row" height="100%" gap="1rem" alignItems={"baseline"}>
            <div className="all-media-heading">
                {props.heading}
            </div>
            {itemCountHeading}
        </Flex>);
}

const ELMediaGridToolBarView = (props: ELMediaGridToolBarProps): React.ReactElement<ELMediaGridToolBarProps> => {
    const selectedAssetsCount = useSelector((state: RootState) => state.selectedMediaListReducer?.length ?? 0);
    const [disabledClass, setDisabledClass] = useState("");
    const isImportInProgress = useSelector((state: RootState) => state.mediaGridToolbarStateReducer?.importInProgress ?? false);
    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {
        if (selectedAssetsCount > 0) {
            setDisabledClass("disabled")
        }
        return () => {
            setDisabledClass("");
        }
    }, [selectedAssetsCount]);

    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const notify = props.controller.notify.bind(props.controller);
    return (
        <ELReactSpectrumV3Provider>
            <div className="grid-toolbar-container">
                <Flex direction="row" height="100%" width="100%" position="absolute" bottom="size-0">
                    <div className="grid-toolbar-container__left-items">
                        <ELMediaGridHeading heading={intlHandler.formatMessage("all-media")} selectedItemCount={selectedAssetsCount}
                            notify={notify} />
                    </div>
                    <div className="grid-toolbar-container__right-items">
                        <Flex direction="row-reverse" height="50%" width="100%" gap="1rem" alignItems="center">
                            <div className="operational-blocks">
                                <div className="center-align-items">
                                    <ImportButton controller={props.controller} isDisabled={isImportInProgress} />
                                </div>
                            </div>
                            <div className="operational-blocks vertical-divider-line"></div>
                            <div className={`operational-blocks ${disabledClass}`}>
                                <SortOrderSelectList notify={notify} />
                            </div>
                            <div className={`operational-blocks media-type-checkbox ${disabledClass}`}>
                                <MediaTypeCheckBoxSelect notify={notify} />
                            </div>
                        </Flex>
                    </div>
                </Flex>
            </div>
        </ELReactSpectrumV3Provider>
    );
}

export default ELMediaGridToolBarView;
