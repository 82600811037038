/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import ELPeekThroughHeaderView from "./ELPeekThroughHeaderView";
import ICreationsHeader from "./ICreationsHeader";
import { WorkflowAction, WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { ControllerAction } from "../../../IViewController";
import { ELCreationsHeaderControllerAction, ELCreationsHeaderViewAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { CreationsData, CreationsDownloadFileType, CreationsJobProjectSubType } from "../../../../common/interfaces/creations/CreationTypes";
import { WorkspaceActionType } from "../../../../workspaces/IBaseWorkspace";
import PIEUtils from "../../../../editors/pie/utils/PIEUtils";

class ELPeekThroughHeader extends ICreationsHeader {

    createView(container: HTMLElement): Promise<void> {
        super.createView(container);

        const element = React.createElement(ELPeekThroughHeaderView, {
            controller: this,
            shareOptionController: this.shareOptions,
            radioButtonList: this.getDownloadOptions(),
            showOpenInDesktop: PIEUtils.isPIEWasmSupported()
        });
        const provider = this.getReactProvider(element, { history: true, store: true });

        return new Promise((resolve) => {
            ReactDOM.render(provider, container, () => { resolve(); });
        })
    }

    ingest = (payload: Record<string, string>): void => {
        this.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    protected getDownloadOptions(): CreationsDownloadFileType[] {
        const radioButtonList = super.getDownloadOptions();

        if (PIEUtils.isPIEWasmSupported()) {
            radioButtonList.push(CreationsDownloadFileType.psd);
        }

        return radioButtonList;
    }

    private _ingestDocumentError(): void {
        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough, IngestEventTypes.share,
            IngestEventSubTypes.error, "Missing media"));
    }

    private _showDocumentError(): void {
        const message = IntlHandler.getInstance().formatMessage("collage-missing-photo-error");
        ToastUtils.error(message);
    }

    protected _renameCreation(payload: string): Promise<boolean> {
        if (this.creationData) {
            return super._renameCreation(payload);
        } else {
            this.viewDispatcher?.call(this, {
                type: ELCreationsHeaderViewAction.nameChanged,
                payload: {
                    status: true,
                    title: payload
                }
            });
            return this._owner.notify({ type: ELCreationsHeaderControllerAction.changeName, payload: payload });
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELCreationsHeaderControllerAction.changeName: {
                this._renameCreation(action.payload as string);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.back: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.back, CreationsJobProjectSubType.peekThrough));
                handled = await this._owner.notify({ type: WorkspaceActionType.startPreviousWorkflow });
                break;
            }
            case ELCreationsHeaderControllerAction.updateCreationsData: {
                this._updateCreationsData(action.payload as CreationsData);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.documentError: {
                this._ingestDocumentError();
                this._showDocumentError();
                handled = true;
                break;
            }
        }

        if (!handled)
            handled = await super.notify(action);

        if (!handled)
            handled = await this._owner.notify(action as WorkflowAction);

        return handled;
    }
}

export default ELPeekThroughHeader;