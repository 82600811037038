/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode } from "react";
import { Button, Flex } from "@adobe/react-spectrum";
import { PressEvent } from "@react-types/shared";

//Application Specific
import "./ELButton.scss";

export interface ELButtonProps {
    variant: "primary" | "secondary" | "cta" | "overBackground" | "negative" | "accent",
    size: "S" | "L",
    isDisabled?: boolean,
    isHidden?: boolean,
    childrenGap?: number | string,
    childrenDirection?: "row" | "row-reverse" | "column" | "column-reverse"
    onClick?: ((e: PressEvent) => void),
    customStyle?: string,
    dataTestId?: string,
    children: ReactNode
}

type SpectrumVariant = "primary" | "secondary" | "cta" | "overBackground" | "negative";

const ELVariantToSpectrumVariantMap: Record<ELButtonProps["variant"], SpectrumVariant> = {
    "primary": "primary",
    "secondary": "secondary",
    "cta": "cta",
    "overBackground": "overBackground",
    "negative": "negative",
    "accent": "primary"
};

/**
 *  This function creates a button component which changes its color on hover. Props can be passed to handle click event.
 */
const ELButton = (props: ELButtonProps): React.ReactElement => {
    const getVariantClass = (): string => {
        if (props.variant === "primary") {
            return props.isDisabled ? "el-button-primary-disabled" : "el-button-primary";
        } else if (props.variant === "secondary") {
            return props.isDisabled ? "el-button-secondary-disabled" : "el-button-secondary";
        } else if (props.variant === "cta") {
            return props.isDisabled ? "el-button-cta-disabled" : "el-button-cta";
        } else if (props.variant === "overBackground") {
            return props.customStyle ? props.customStyle : ""; //let default spectrum style take over if not custom style specified
        } else if (props.variant === "negative") {
            return props.isDisabled ? "el-button-negative-disabled" : "el-button-negative";
        } else if (props.variant === "accent") {
            return props.isDisabled ? "el-button-accent-disabled" : "el-button-accent";
        } else { //return primary
            return props.isDisabled ? "el-button-primary-disabled" : "el-button-primary";
        }
    }

    const getSizeClass = (): string => {
        if (props.size === "S") {
            return ""; //let spectrum clas take over
        } else if (props.size === "L") {
            return "el-button-large";
        }

        return ""; //let spectrum clas take over
    }

    const getOnPressHandler = (e: PressEvent): unknown => {
        if (props.isDisabled)
            return;
        if (props.onClick)
            return props.onClick(e);
    }

    return (
        <Button data-testid={props.dataTestId} isHidden={props.isHidden} variant={ELVariantToSpectrumVariantMap[props.variant]} onPress={(e: PressEvent) => { getOnPressHandler(e) }}
            UNSAFE_className={"el-button " + getVariantClass() + " " + getSizeClass()}>
            <Flex direction={props.childrenDirection} gap={props.childrenGap} alignItems="center">
                {props.children}
            </Flex>
        </Button>
    )
}

ELButton.defaultProps = {
    variant: "primary",
    size: "S",
    childrenDirection: "row",
    isHidden: false
}

export default ELButton;
