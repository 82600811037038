/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { vec2 } from "gl-matrix";

//Application Specific
import IStageShapes from "../../../editors/stage/shapes/IStageShapes";
import { DocumentFormat } from "../document/DocumentTypes";
import { ELLayerBlendModes } from "../editing/pie/ELPIELayerTypes";
import { ELPIEPoint, ELStroke } from "../geometry/ELGeometry";
import { ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import { ELLayoutInfo } from "../creations/ELSocialLayoutTypes";
import { DocumentActions } from "../document/DocumentTypes";
import { ELViewType } from "../creations/CreationTypes";
import { ELLinkType } from "../link/ELLinkTypes";

/**
 * types, interfaces and enums
 */
export enum ELStageShapesType {
    imageFromURI = "imageFromURI",
    imageFromElement = "imageFromElement",
    path = "path",
    rect = "rect",
    imageArea = "imageArea",
    imageAreaWithLayout = "imageAreaWithLayout",
    text = "text"
}

export enum ELStageObjectType {
    image = "image",
    path = "path",
    rect = "rect",
    text = "text",
    group = "group"
}

export enum ELStageCorner {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight
}

export const ELCornerOffsetDict = {
    [ELStageCorner.topLeft]: [false, false],
    [ELStageCorner.topRight]: [true, false],
    [ELStageCorner.bottomLeft]: [false, true],
    [ELStageCorner.bottomRight]: [true, true]
};

export interface ELStageObjectData {
    payload?: unknown,
    transformedToImageArea: boolean,
    allowDrop: boolean,
    showBorderOnHover?: boolean,
    initialCenter?: ELPIEPoint
}

export type IMAGE_URI = string;

export interface ELStageObjectOptions extends fabric.IObjectOptions, fabric.IPathOptions, fabric.IRectOptions, fabric.IGroupOptions, fabric.TextOptions {
    svgPath?: string,
    addToStage?: boolean,
    strokeStyle?: ELStroke,
    fitToClipShape?: boolean,
    image?: IMAGE_URI | HTMLImageElement,
    isSubTarget?: boolean,
    subTargets?: ELStageObject[],
    text?: string,
    fitToCorner?: ELStageCorner,
    blendMode?: ELLayerBlendModes,
    fitToImageArea?: boolean,
    textSpread?: ELTextSpread
}

export type ELStageObject = fabric.Object;

export type ELStageImageObject = fabric.Image;

export type ELStageGroupObject = fabric.Group;

export type ELStageTextObject = fabric.IText;

export type ELStageRectObject = fabric.Rect;

export enum ELStageBackgroundMode {
    color = "color",
    image = "image",
    transparent = "transparent",
    none = "none"
}

export interface ELStageBackground {
    mode: ELStageBackgroundMode,
    value: string
}

export interface ELStageBackgroundOptions extends ELStageObjectOptions {
    background: ELStageBackground
}

export interface ELStageShapeAndObjectOptions {
    shape: IStageShapes,
    objectOptions: ELStageObjectOptions,
    clipShape?: IStageShapes,
    clipObjectOptions?: ELStageObjectOptions
}

export const ZoomDropdownValues: ({ keyName: string })[] = [
    { keyName: "400%" }, { keyName: "200%" }, { keyName: "150%" }, { keyName: "100%" }, { keyName: "75%" }, { keyName: "50%" }, { keyName: "25%" }, { keyName: "10%" }
]

export const ZoomDropdownStrings: ({ keyName: string })[] = [
    { keyName: "zoom-to-fit" }, { keyName: "zoom-to-fill" }
]

export const DEFAULT_ZOOM_PERCENTAGE = "100%";

/**
 * actions between views and controllers
 */
export enum CanvasViewAction {
    addCanvasChangedHandlers = "addCanvasChangedHandlers",
    sendDocumentCreatedUpdate = "sendDocumentCreatedUpdate"
}

export enum CanvasZoomLevelAction {
    changeZoomValue = "changeZoomValue",
    zoomToFit = "zoom-to-fit",
    zoomToFill = "zoom-to-fill",
    zoomInEvent = "zoom-in-event",
    zoomOutEvent = "zoom-out-event",
}

export enum CanvasControllerAction {
    download = "download",
    deleteActiveObject = "deleteActiveObject",
    linkObjects = "linkObjects",
    updateTextProperty = "updateTextProperty",
    activeObjectChange = "activeObjectChange"
}

export interface CanvasLinkObjectsPayload {
    objects: ELStageObject[],
    linkType: ELLinkType
}

export interface CanvasUpdateTextPropertyPayload {
    object: ELStageTextObject,
    key: ELStageTextProperty,
    value: unknown
}

export enum ELStageTextProperty {
    fontFamily = "fontFamily",
    fontSize = "fontSize",
    stroke = "stroke",
    strokeWidth = "strokeWidth",
    shadow = "shadow",
    textAlign = "textAlign",
    underline = "underline",
    spread = "spread"
}

export enum CanvasChangedMode {
    objectModified = "objectModified",
    objectRotated = "objectRotated",
    objectMoved = "objectMoved"
}

export interface CanvasChangedPayload {
    allObjects: ELStageObject[],
    mode: CanvasChangedMode,
    activeObject?: ELStageObject
}

export interface ELImageData {
    format?: DocumentFormat,
    quality?: number,
    objectUrl?: string
}

export interface ELDownloadData {
    name: string,
    imageData: ELImageData
}

export enum ELLayoutPanelControllerAction {
    commit = "commit",
    change = "change",
    cancel = "cancel",
    scale = "scale",
    revert = "revert",
    panelClose = "panelClose"
}

export interface ELFabricInfo {
    imageAreaTransformOffset: vec2,
    imageAreaTransformScale: number,
    docSize: ELSize,
    layoutInfo?: ELLayoutInfo
}

export const ELLayoutOperations: string[] = [
    ELLayoutPanelControllerAction.cancel,
    ELLayoutPanelControllerAction.change,
    ELLayoutPanelControllerAction.commit,
    ELLayoutPanelControllerAction.revert,
    ELLayoutPanelControllerAction.scale,
    DocumentActions.commitLayout
];

export interface IStageConfig {
    viewType?: ELViewType
}

export interface ELFabricConfig extends IStageConfig {
    showReplaceMediaButton: boolean,
    showDeleteButton: boolean,
    objectHoverColor: string,
    addCanvasHandlers?: boolean
}

export const DefaultELFabricConfig: ELFabricConfig = {
    showReplaceMediaButton: true,
    showDeleteButton: true,
    objectHoverColor: "rgb(0, 255, 255)"
}

export const STAGE_BACKGROUND_COLOR = "#ececec";

export const STAGE_VIEWTYPE_ATTRIBUTE_KEY = "viewType";

export enum ELTextSpread {
    fit = "fit",
    fill = "fill"
}

export enum ELTextAlign {
    left = "left",
    center = "center",
    right = "right"
}

export interface ELTextShadow {
    color: string,
    offsetX?: number,
    offsetY?: number,
    blur: number
}