/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

interface RepoVideoRenditionInfo {
    url: string,
    videoContainer: string
}

interface PosterFrameInfo {
    url: string,
    width: number,
    height: number
}

export interface AssetMetaData {
    name?: string,
    deviceCreateDate?: string,
    size?: number,
    width?: number,
    length?: number,
    format?: string,
    class?: string,
    focalLength?: string,
    deviceMake?: string,
    deviceModel?: string,
    focalNumber?: string,
    appMetaData?: string,
    isoSpeedRatings?: string,
    exposureTime?: string,
    mimeType?: string,
}

export interface DimensionsInfo {
    width: number,
    height: number
}

export interface VideoMetaData {
    dimensionsInfo?: DimensionsInfo
}

interface RenditionStatus {
    state: RepoRenditionState,
    progressValue?: string
}

export enum RepoRenditionState {
    processing = "PROCESSING",
    queued = "QUEUED",
    completed = "COMPLETED",
    failed = "FAILED"
}


export interface RepoVideoResponseType {
    posterframe: PosterFrameInfo,
    originalDimensions: DimensionsInfo,
    renditions: Array<RepoVideoRenditionInfo>,
    renditionsStatus: RenditionStatus,
    retryAfter?: number
}

export interface XMPDMDuration {
    "xmpDM:scale": string,
    "xmpDM:value": string
}

export interface ISOSpeedRating {
    "@list": [],
}

export enum EmbeddedMetaDataProperties {
    EXIF_DATE_TIME_ORIGINAL = "exif:DateTimeOriginal",
    TIFF_MAKE = "tiff:Make",
    TIFF_MODEL = "tiff:Model",
    EXIF_FOCAL_LENGTH = "exif:FocalLength",
    EXIF_FOCAL_NUMBER = "exif:FNumber",
    EXIF_ISO_SPEED_RATINGS = "exif:ISOSpeedRatings",
    EXIF_EXPOSURE_TIME = "exif:ExposureTime",
    IMAGE_LENGTH = "tiff:imageLength",
    IMAGE_WIDTH = "tiff:imageWidth",
}

export interface EmbeddedMetadataType {
    "exif:GPSAltitude": string,
    "exif:GPSImgDirectionRef": string,
    "xmp:CreatorTool": string,
    "exif:GPSTimeStamp": string,
    "exif:SensingMethod": number,
    "exif:GPSLatitude": string,
    "exif:PixelYDimension": number,
    "tiff:ResolutionUnit": number,
    "exif:PixelXDimension": number,
    "xmp:CreateDate": string,
    "xmp:ModifyDate": string,
    "tiff:XResolution": string,
    "exif:DateTimeOriginal": string,
    "exif:GPSAltitudeRef": number,
    "tiff:Orientation": number,
    "exif:GPSImgDirection": string,
    "exif:GPSLongitude": string,
    "tiff:YResolution": string,
    "xmp:keywords": [],
    "xmpDM:duration": XMPDMDuration,
    "tiff:Make": string,
    "tiff:Model": string,
    "exif:FocalLength": string,
    "exif:ISOSpeedRatings": any,
    "exif:FNumber": string,
    "exif:ExposureTime": string,
    "assetAppMetaData": string
}
