/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react"

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";

import "./ELNoMediaBanner.scss"

interface ELNoMediaBannerProps {
    icon: React.ReactElement,
    heading: string,
    textBody: React.ReactNode,
    onClick?: () => void,
    children?: React.ReactElement | React.ReactElement[],
    className?: string
}

export const ELNoMediaBanner = (props: ELNoMediaBannerProps): React.ReactElement => {
    return (
        <Flex UNSAFE_className={`no-media-banner-box`} alignItems="center" justifyContent="center">
            <Flex UNSAFE_className={`no-media-banner  ${props.className}`}>
                <div>
                    {props.icon}
                </div>
                <Flex gap="3rem" direction="column" alignItems="center">
                    <Flex gap="0.2rem" direction="column" alignItems="center">
                        <span className="no-media-banner-heading">{props.heading} </span>
                        {props.textBody}
                    </Flex>
                    {props.children}
                </Flex>
            </Flex>
        </Flex>
    );
}