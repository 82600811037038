/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELTabPanelType } from "../../common/interfaces/tabpanel/ELTabPanelTypes";
import ELPanelManager from "../../view/components/templates/el-panel-manager/ELPanelManager";
import IWorkflow from "../IWorkflow";

export default abstract class IPanelProvider {
    protected owner: IWorkflow;

    constructor(owner: IWorkflow) {
        this.owner = owner;
    }

    abstract getTabPanel(panelType: ELTabPanelType): ELPanelManager;
}