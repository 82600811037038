/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { IngestLogging } from "../services/IngestWrapper";
import { IngestUtils } from "../utils/IngestUtils";
import Utils from "../utils/Utils";
import { ControllerAction } from "../view/IViewController";
import { WorkflowAction, WorkflowsName } from "./IWorkflow";
import IBaseWorkspace, { WorkspaceAction, WorkspaceActionType, WorkspacesName } from "./IBaseWorkspace";
import IWorkspace from "./IWorkspace";
import { IngestLogObjectKey } from "../utils/IngestConstants";

export enum ModalWorkspaceName {
    share = "SHARE",
    modalWrapper = "MODAL_WRAPPER",
    sampleMedia = "SAMPLE_MEDIA"
}

export default abstract class IModalWorkspace extends IBaseWorkspace {
    protected overlayDiv!: HTMLDivElement;
    protected dialogClass?: string;
    protected container?: HTMLElement;
    protected _owner!: IWorkspace;
    protected modalWorkspaceName!: ModalWorkspaceName;

    protected createView(container: HTMLElement): void {
        this.container = container;
    }

    protected destroyView(): void {
        this.container = undefined;
    }

    get getNonModalWorkspace(): IWorkspace {
        return this._owner;
    }

    get getWorkspaceName(): WorkspacesName {
        return this._owner.getWorkspaceName;
    }

    get getModalWorkspaceName(): ModalWorkspaceName {
        return this.modalWorkspaceName;
    }

    destroy(): void {
        this.setWorkflow = undefined;
        this.viewDispatcher = undefined;
    }

    /**
     *
     * @param containerId : id of html element where workspace is mounted
     * @param workflowName : optional workflowName to be started, since there can be modal workspaces which have fixed workflow like share.
     * @param payload : any info that needs to be pass on to the workflow
     */
    startWorkspace<T extends WorkflowAction>(containerId: string, workflowName?: WorkflowsName, action?: T): void {
        this.createView(this.ensureHTMLElement(containerId));
    }

    /**
     *
     * destroys the workspace
     */
    endWorkspace(): void {
        this.endCurrentWorkflow();
        this._owner.notify({ type: WorkspaceActionType.startDefaultWorkflow });
        this.destroyView();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.ingest: {
                if (!Utils.isInputWellDefined(action.payload))
                    return handled;
                const ingestPayload = action.payload as Record<string, string>;
                if (IngestLogObjectKey.eventWorkflow in ingestPayload) {
                    IngestLogging.getInstance().logEvent(ingestPayload);
                } else {
                    IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(this.modalWorkspaceName, ingestPayload));
                }
                handled = true;
                break;
            }
            default:
                {
                    handled = await super.notify(action as WorkspaceAction);
                    break;
                }
        }
        return handled;
    }
}
