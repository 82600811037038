/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum ELUserHomeBannerViewActions {
    updateBannerSrc = "UPDATE_BANNER_SRC",
    bannerLoadComplete = "LOAD_COMPLETE"
}

export enum ELUserHomeBannerActions {
    refreshBannerSrc = "REFRESH_BANNER_SRC",
    startTrial = "START_TRIAL",
    continueTrial = "CONTINUE_TRIAL",
    bannerLoadComplete = "LOAD_COMPLETE",
    learnMore = "LEARN_MORE",
    buyNow = "BUY_NOW",
    watchVideo = "WATCH_VIDEO",
    getStarted = "GET_STARTED"
}

export enum ELUserHomeEliveCardsViewActions {
    updateCards = "UPDATE_CARDS"
}

export enum ELUserHomeEliveCardsActions {
    refreshCards = "REFRESH_CARDS",
    ctaClicked = "CTA_CLICKED",
    cardThumbClicked = "CARD_THUMB_CLICKED",
}

export enum ELUserHomeActions {
    watchVideo = "WATCH_VIDEO",
    closeVideo = "CLOSE_VIDEO",
    startTrialLater = "START_TRIAL_LATER",
    startTrial = "START_TRIAL",
    renderStartTrialDialog = "RENDER_START_TRIAL_DIALOG"
}

export enum ELUserHomeStaticContentActions {
    learnMoreClicked = "LEARN_MORE_CLICKED",
    takeSurveyClicked = "TAKE_SURVEY_CLICKED"
}