/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application specific
import IViewController from "../../../IViewController";
import { ELVideoOverlayView } from "./ELVideoOverlayView";

export default class ELVideoOverlay extends IViewController {
    private _src: string;
    private _handleEscape: () => void;
    private _autoPlay?: boolean;

    constructor(src: string, handleEscape: () => void, autoPlay?: boolean) {
        super();

        this._src = src;
        this._handleEscape = handleEscape;
        this._autoPlay = autoPlay;
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        ReactDOM.render(
            React.createElement(ELVideoOverlayView, {
                controller: this,
                src: this._src,
                handleEscape: this._handleEscape.bind(this),
                autoPlay: this._autoPlay,
                overlayDiv: this.ensureHTMLElement("root") as HTMLDivElement
            }),
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }
}