/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Asset, Version } from "@elements/elementswebcommon";

//Application Specific
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELCreationsPathResolver } from "./ELCreationsPathResolver";
import { ELAssetPathOrId } from "../../../../common/interfaces/storage/AssetTypes";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";

export class ELCollagePathResolver extends ELCreationsPathResolver {

    async getCreationPreviewAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();
        switch (requestVersion) {
            case Version.V1:
            case Version.V2:
                if (!this.creationsData.outputs || !this.creationsData.outputs.media) {
                    Logger.log(LogLevel.ERROR, "ELCollagePathResolver: getCreationPreviewAssetPathOrId: V1/V2, not able to find outputs or media");
                    return Promise.reject();
                }
                return this.getAssetPathFromOutputInfo(this.creationsData.outputs.media);
            case Version.V3: {
                if (!this.creationsData.outputs || !this.creationsData.outputs.preview) {
                    Logger.log(LogLevel.ERROR, "ELCollagePathResolver: getCreationPreviewAssetPathOrId : V3, not able to find outputs or preview");
                    return Promise.reject();
                }
                return this.getAssetIdFromOutputInfo(this.creationsData.outputs.preview);
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELCollagePathResolver: getCreationPreviewAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationFullAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();

        switch (requestVersion) {
            case Version.V1:
            case Version.V2: {
                if (!this.creationsData.outputs || !this.creationsData.outputs.media) {
                    Logger.log(LogLevel.ERROR, "ELCollagePathResolver: getCreationFullAssetPathOrId: V1/V2, not able to find outputs or outputs.media");
                    return Promise.reject();
                }
                return this.getAssetPathFromOutputInfo(this.creationsData.outputs.media);
            }
            case Version.V3: {
                if (!this.creationsData.outputs || !this.creationsData.outputs.preview) {
                    Logger.log(LogLevel.ERROR, "ELCollagePathResolver: getCreationFullAssetPathOrId : V3, not able to find outputs or outputs.preview");
                    return Promise.reject();
                }
                if (this.creationsData.status === CreationsStatus.success) {
                    return this.getAssetIdFromOutputInfo(this.creationsData.outputs.preview);
                }
                return this.getAssetPathFromOutputInfo(this.creationsData.outputs.preview);
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELCollagePathResolver: getCreationFullAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationMasterAssetData(): Promise<Asset[]> {
        Logger.log(LogLevel.WARN, "ELCollagePathResolver:getPSDCreationAssetID: ", "Function not supported for Collage");
        return Promise.reject(new Error("Function not supported for Collage"));
    }

}