/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect, useReducer, useRef } from "react";
import { useSelector } from "react-redux";

//Elements Internal
import { AssetMimeType } from "@elements/elementswebcommon";

//Adobe Internal
import { Flex, Grid, View, repeat, Text } from "@adobe/react-spectrum";

//Application Specific
import { CardData, ChannelName, ElivePlatform } from "../../../../common/interfaces/elive/EliveTypes"
import ELCreateOnDemandThumbView from "../../molecules/el-create-on-demand-thumb/ELCreateOnDemandThumb";
import IViewController from "../../../IViewController";
import { ELUserHomeEliveCardsActions, ELUserHomeEliveCardsViewActions } from "../../../../common/interfaces/home/UserHomeTypes";
import { RootState } from "../../../../stores/store";
import { ViewAction } from "../../../IBaseController";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import { IconType } from "../../../../assets/IconConstants";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import ELButton from "../../atoms/el-button/ELButtonView";
import ELSkeleton from "../../atoms/el-skeleton/ELSkeleton";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

import "./ELUserHomeEliveCards.scss";

interface ELUserHomeEliveCardsProps {
    controller: IViewController,
    isCardClickable: boolean
}

interface ELUserHomeEliveCardsState {
    cardsData: CardData[]
}

const initialState: ELUserHomeEliveCardsState = {
    cardsData: []
}

const elivePlatformToIconMap: Record<ElivePlatform, IconType> = {
    [ElivePlatform.web]: IconType.web,
    [ElivePlatform.desktop]: IconType.desktop,
    [ElivePlatform.mobile]: IconType.mobile
}

const ElivePlatformToLocKeyMap: Record<ElivePlatform, string> = {
    [ElivePlatform.web]: "web",
    [ElivePlatform.desktop]: "desktop",
    [ElivePlatform.mobile]: "mobile"
}

const EliveChannelToLocKeyMap: Record<ChannelName, string> = {
    [ChannelName.learn]: "elive-learn",
    [ChannelName.explore]: "explore",
    [ChannelName.news]: "news",
    [ChannelName.inspire]: "inspire",
    [ChannelName.tryNow]: "try-now",
    [ChannelName.watchVideo]: "watch-video"
}

const DUMMY_CARDS_NUMBER = 3;

const ELUserHomeEliveCardsView = (props: ELUserHomeEliveCardsProps): React.ReactElement => {
    const daysInTrial = useSelector((state: RootState) => state.appReducer.daysInTrial);
    const specLayoutConfig = {
        TILE_W: 16.88,
        TILE_H: 19.69,
        DIST_BETWEEN_TILE: 3.3
    };
    const gridRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: gridRef,
        gridRef: gridRef
    });

    const reducer = (state: ELUserHomeEliveCardsState, action: ViewAction): ELUserHomeEliveCardsState => {
        switch (action.type) {
            case ELUserHomeEliveCardsViewActions.updateCards: {
                const payload = action.payload as CardData[];
                return (
                    {
                        ...state,
                        cardsData: payload
                    }
                );
            }
            default:
                return state;
        }
    }
    const [state, viewDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        props.controller.notify({ type: ELUserHomeEliveCardsActions.refreshCards, payload: daysInTrial });
    }, [daysInTrial]);

    const eliveCardCtaClicked = (card: CardData): void => {
        props.controller.notify({ type: ELUserHomeEliveCardsActions.ctaClicked, payload: card });
    }

    const eliveCardThumbClicked = (card: CardData): void => {
        props.controller.notify({ type: ELUserHomeEliveCardsActions.cardThumbClicked, payload: card });
    }

    const getPlatformText = (card: CardData): string => {
        const intlHandler = IntlHandler.getInstance();
        const platform = ElivePlatformToLocKeyMap[card.platform];
        return intlHandler.formatMessage(platform);
    } 

    const getCardCtaText = (card: CardData): string => {
        const intlHandler = IntlHandler.getInstance();
        const channelName = EliveChannelToLocKeyMap[card.channelName];
        return intlHandler.formatMessage(channelName);
    } 

    const getFooter = (card: CardData): React.ReactElement => {
        return (
            <Flex justifyContent="space-between" alignItems="center" UNSAFE_className="elive-card__footer">
                <Flex gap="0.24rem" alignItems="end">
                    <ELIcon iconkey={elivePlatformToIconMap[card.platform]}
                        width="0.75rem" height="0.75rem" title={getPlatformText(card)} />
                </Flex>
                <ELButton variant="accent" onClick={() => eliveCardCtaClicked(card)} dataTestId={`elive-card-cta-${card.cardID}`}>
                    <Text UNSAFE_className="elive-card__cta">{getCardCtaText(card)}</Text>
                </ELButton>
            </Flex>
        )
    }

    const getOnPressHandler = (card: CardData): (() => void) | undefined => {
        if (props.isCardClickable) {
            return (() => eliveCardThumbClicked(card));
        }
        return undefined;
    }

    const getEliveCardsViewFromCardData = (): React.ReactElement[] => {
        return state.cardsData.map((cardData) => {
            return (
                <View key={cardData.cardID} width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H} data-testid="elive-card-data">
                    <ELCreateOnDemandThumbView width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H} key={cardData.cardID} thumbId={cardData.cardID}
                        previewSrc={cardData.thumbnail} previewType={AssetMimeType.image} headerText={cardData.title}
                        descriptiveText="" dataTestId={`elive-card-${cardData.cardID}`} footer={getFooter(cardData)} onPress={getOnPressHandler(cardData)}/>
                </View >
            )
        })
    }

    const getEliveCardsView = (): React.ReactElement[] => {
        return (state.cardsData.length > 0 ? getEliveCardsViewFromCardData(): getCardsSkeleton(DUMMY_CARDS_NUMBER));
    };

    const getEliveCardsGrid = (): React.ReactElement => {
        return (
            <div ref={gridRef} data-testid="user-home-elive-cards-container">
                <Grid columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                    justifyContent={"start"}
                    gap={viewLayoutConfig.DIST_BETWEEN_TILE}>{getEliveCardsView()}</Grid>
            </div>
        );
    }

    const getCardsSkeleton = (cardsNumber: number): React.ReactElement[] => {
        return Array.from({length: cardsNumber}, (value: number, index: number) => <ELSkeleton key={index} width={`${viewLayoutConfig.TILE_W}px`} height={`${viewLayoutConfig.TILE_H}px`} dataTestId={`el-skeleton-${index}`}/>)
    }

    return (
        getEliveCardsGrid()
    )
}

export default ELUserHomeEliveCardsView;