/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IMS from '../../services/IMS';
import Constants from '../../utils/Constants/Constants';
import Logger, { LogLevel } from '../../utils/Logger';
import { StorageService } from "../../services/StorageServiceWrapper";

// Currently we are using cc-storage service for fetching quota as 
// repoAPI do not yet support fetching user storage quota info
// Let this file go away once we get storage quota info from repoAPIs
// For tracking see JIRA-ACPCSS-2779 -- yaverma

export interface CloudStorageQuota {
    total: number,
    available: number,
    used: number
}

//Wiki: https://developers.corp.adobe.com/storage-api/docs/reference/quota-resource.md
//RAPI Quota Resource Properties
const KEY_USED = "storage:bytesUsed";
const KEY_TOTAL = "storage:bytesLimit";

const API_KEY: string | undefined = process.env.REACT_APP_REPO_API_KEY;

const FETCH_DURATION = 1 * 60 * 1000 // (TODO(yaverma):1 min for now, edit this later)

export class StorageQuota {
    private static _instance: StorageQuota | null = null;
    private _cloudStorageQuota: CloudStorageQuota;
    private _lastFetchTime: number;

    constructor() {
        if (API_KEY === undefined) {
            throw new Error("API Key not set");
        }

        this._cloudStorageQuota = this.getCloudStorageQuotaObj(0, 0, 0);
        this._lastFetchTime = 0;
    }

    private _getStorageQuotaAPIConfig(): Record<string, Record<string, string>> {
        return {
            headers: {
                "cache": "no-cache",
                "x-api-key": `${API_KEY}`,
                "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
            }
        }
    }

    resetStorageQuotaObj(): void {
        this._cloudStorageQuota = this.getCloudStorageQuotaObj(0, 0, 0);
        this._lastFetchTime = 0;
    }

    static getInstance(): StorageQuota {
        if (StorageQuota._instance === null)
            StorageQuota._instance = new StorageQuota();
        return StorageQuota._instance;
    }

    getCloudStorageQuotaObj(total: number, available: number, used: number): CloudStorageQuota {
        const quotaObj: CloudStorageQuota = {
            total: total,
            available: available,
            used: used
        };
        return quotaObj;
    }

    /**Requirement where there are high chances that quota would be available
     * Also, important for placees where we can't affor to get quota like starting slideshow
     */
    getCachedQuota(): CloudStorageQuota {
        return this._cloudStorageQuota;
    }

    async getQuota(forceSync: boolean): Promise<CloudStorageQuota> {
        // Probably need to implement a wrapper over, which retries (fetch-retry)
        // something similar is already done in Hz
        // Replace this fetch with fetch-retry
        let fetchQuota = false;

        if (Date.now() - this._lastFetchTime > FETCH_DURATION) {
            fetchQuota = true;
        }
        if (forceSync) {
            fetchQuota = true;
        }

        if (!fetchQuota) {
            return Promise.resolve(this._cloudStorageQuota);
        }

        try {
            const response = await StorageService.getInstance().getQuotaResponse();
            if (response.status === Constants.HTTP_RESPONSE_OK_200) {
                const responseBody = response.data;
                const fetchedQuota = this.getCloudStorageQuotaObj(
                    responseBody[KEY_TOTAL],
                    responseBody[KEY_TOTAL] - responseBody[KEY_USED],
                    responseBody[KEY_USED]);
                this._cloudStorageQuota = fetchedQuota;
                this._lastFetchTime = Date.now();
                return Promise.resolve(fetchedQuota);
            } else {
                if (response.status === Constants.HTTP_RESPONSE_FORBIDDEN_403) {
                    IMS.getInstance().getUserAccessToken(); // TODO(yaverma): Check how to refresh token. 
                    // Pick this with asset management task
                    // Fetch the quota, with new token.
                    return this.getQuota(true);
                } else {
                    throw new Error("Error fetching storage quota " + response);
                }
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "StorageQuota:getQuota: ", (error as Error)?.message ?? error);
            return Promise.resolve(this._cloudStorageQuota);
        }
    }

}
