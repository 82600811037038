/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { fabric } from "fabric";

//Application Specific
import { ELStageLayerDataOptions, CSLayerTextInfo } from "../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELStageObjectData, ELStageObjectOptions } from "../common/interfaces/stage/StageTypes";
import { RenderedShapesName } from "../common/interfaces/renderer/RendererTypes";
import ILayer from "../editors/document/layer/ILayer";

export default class ELStageObjectUtils {
    static updateStageObjectOptionsForTextLayer(objectOptions: ELStageObjectOptions, textOptions: CSLayerTextInfo): void {
        objectOptions.text = textOptions.text;
        objectOptions.fontFamily = textOptions.fontFamily;
        objectOptions.fontSize = textOptions.fontSize;
        objectOptions.textAlign = textOptions.textAlign;
        objectOptions.stroke = textOptions.stroke;
        objectOptions.strokeWidth = textOptions.strokeWidth;
        objectOptions.underline = textOptions.underline;
        if (textOptions.shadowBlur && textOptions.shadowColor) {
            objectOptions.shadow = new fabric.Shadow({ blur: textOptions.shadowBlur, color: textOptions.shadowColor });
        }
        objectOptions.textSpread = textOptions.spread;
    }

    static getStageClipObjectOptions(clipLayerOptions: ELStageLayerDataOptions): ELStageObjectOptions {
        const shapeData: ELStageObjectData = { transformedToImageArea: true, allowDrop: false };
        const clipObjectOptions = {
            name: RenderedShapesName.layer,
            data: shapeData,
            width: clipLayerOptions.editInfo?.transform?.quadrilateral.left_bottom,
            height: clipLayerOptions.editInfo?.transform?.quadrilateral.right_bottom,
            left: clipLayerOptions.editInfo?.transform?.offset.horizontal ?? 0,
            top: clipLayerOptions.editInfo?.transform?.offset.vertical ?? 0,
            scaleX: clipLayerOptions.editInfo?.transform?.scale.horizontal ?? 1,
            scaleY: clipLayerOptions.editInfo?.transform?.scale.vertical ?? 1,
            selectable: clipLayerOptions.selectable ?? false,
            fitToImageArea: clipLayerOptions.fitToBackground ?? false,
            angle: clipLayerOptions.editInfo?.transform?.rotate ?? 0,
            flipX: clipLayerOptions.editInfo?.transform?.flipX ?? false,
            flipY: clipLayerOptions.editInfo?.transform?.flipY ?? false,
            visible: clipLayerOptions.visible ?? true,
            addToStage: false,
            opacity: clipLayerOptions.editInfo?.opacity ?? 1,
        };
        if (clipLayerOptions.editInfo?.textInfo) {
            ELStageObjectUtils.updateStageObjectOptionsForTextLayer(clipObjectOptions, clipLayerOptions.editInfo.textInfo);
        }
        return clipObjectOptions;
    }

    static async getStageObjectOptions(layer: ILayer): Promise<ELStageObjectOptions> {
        const { width, height } = await layer.getSize();
        const { top, left } = await layer.getBounds();
        const shapeData: ELStageObjectData = { payload: layer.getId(), transformedToImageArea: true, allowDrop: true };
        const objectOptions: ELStageObjectOptions = {
            name: RenderedShapesName.layer,
            data: shapeData,
            width: layer.getEditInfo()?.transform?.quadrilateral.left_bottom ?? width,
            height: layer.getEditInfo()?.transform?.quadrilateral.right_bottom ?? height,
            left: layer.getEditInfo()?.transform?.offset.horizontal ?? left,
            top: layer.getEditInfo()?.transform?.offset.vertical ?? top,
            scaleX: layer.getEditInfo()?.transform?.scale.horizontal ?? 1,
            scaleY: layer.getEditInfo()?.transform?.scale.vertical ?? 1,
            selectable: layer.getSelectable(),
            fitToImageArea: layer.getFitToBackground(),
            angle: layer.getEditInfo()?.transform?.rotate ?? 0,
            flipX: layer.getEditInfo()?.transform?.flipX ?? false,
            flipY: layer.getEditInfo()?.transform?.flipY ?? false,
            visible: layer.getVisibility() ?? true,
            opacity: layer.getOpacity() ?? 1,
            fill: layer.getFill(),
            addToStage: true
        };
        const textOptions = layer.getTextOptions();
        if (textOptions) {
            ELStageObjectUtils.updateStageObjectOptionsForTextLayer(objectOptions, textOptions);
        }
        return Promise.resolve(objectOptions);
    }
}