/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReactReduxProvider } from "react-redux";

//Application Specific
import { ViewAction } from '../../../IBaseController';
import { ControllerAction } from '../../../IViewController';
import IWorkflow, { WorkflowActionType } from '../../../../workspaces/IWorkflow';
import SlideshowPanelView from "./SlideshowPanelView";
import SlideshowAudio from '../slideshow-audio-panel/SlideshowAudio';
import SlideshowMedia from '../slideshow-media-panel/SlideshowMedia';
import SlideshowTemplate from '../slideshow-template-panel/SlideshowTemplate';
import ELSlideshowIntro from '../el-slideshow-intro-panel/ELSlideshowIntro';
import { CreationMediaActionType } from '../el-creation-media-panel/ELCreationMediaView';
import ElementsContentService from '../../../../services/ElementsServices/ElementsContentService';
import Logger, { LogLevel } from '../../../../utils/Logger';
import { ELSlideshowPanelWorkflowAction } from '../../../../common/interfaces/creations/SlideshowTypes';
import store from '../../../../stores/store';
import { IngestUtils } from '../../../../utils/IngestUtils';
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from '../../../../utils/IngestConstants';
import ITemplateViewController from '../../../ITemplateViewController';
import { ELReactSpectrumV3Provider } from '../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider';

class SlideshowPanel extends ITemplateViewController {
    private _slideshowAudio: SlideshowAudio;
    private _slideshowMedia: SlideshowMedia;
    private _slideshowTemplate: SlideshowTemplate;
    private _slideshowIntro: ELSlideshowIntro;

    constructor(owner: IWorkflow) {
        super(owner);
        this._slideshowAudio = new SlideshowAudio(this, ElementsContentService.getInstance());
        this._slideshowMedia = new SlideshowMedia(this);
        this._slideshowTemplate = new SlideshowTemplate(this, ElementsContentService.getInstance());
        this._slideshowIntro = new ELSlideshowIntro(this);
    }

    get getSlideshowAudio(): SlideshowAudio {
        return this._slideshowAudio;
    }

    get getSlideshowMedia(): SlideshowMedia {
        return this._slideshowMedia;
    }

    get getSlideshowTemplate(): SlideshowTemplate {
        return this._slideshowTemplate;
    }

    get getSlideshowIntro(): ELSlideshowIntro {
        return this._slideshowIntro;
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const element = React.createElement(SlideshowPanelView, {
            controller: this
        });

        /*eslint-disable */
        const provider = React.createElement(ELReactSpectrumV3Provider, {
            children: '',
            height: "100%"
        }, element);
        /*eslint-enable */

        const reduxWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, provider);
        ReactDOM.render(reduxWrappedProvider, container);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CreationMediaActionType.manageMedia:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow, IngestEventTypes.click, IngestEventSubTypes.manageMedia));
                    const workflowAction = { type: ELSlideshowPanelWorkflowAction.manageMedia };
                    handled = await this._owner.notify(workflowAction);
                    break;
                }
            case WorkflowActionType.ingest:
                {
                    this._ingest(action.payload as Record<string, string>);
                    handled = true;
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "SlideshowPanel - notify : Invalid action" + action);
                }
        }
        return handled;
    }
}

export default SlideshowPanel;
