/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import { useRef } from "react";
import { useSelector } from "react-redux";

// Adobe internal
import { AssetWithRepoAndPathOrId, RenditionOptions } from "@dcx/common-types";
import {
    AdobeDirectoryData,
    PageOptions
} from "@dcx/assets";

// Application specific
import {
    GridMediaType
} from "../../stores/actions/mediaGridConfigActions";
import { MediaFetchParams, PageFetchReturnType } from "../hooks/useMediaFetch";
import {
    RepoDirListData,
    AssetTypes,
    RenditionSource,
    ELRenditionType,
} from "@elements/elementswebcommon";
import { StorageService } from "../../services/StorageServiceWrapper";
import { SIVUtils } from "./../SIVUtils";
import { RootState } from "../../stores/store";
import Logger, { LogLevel } from "../Logger";
import IMS from "../../services/IMS";
import { GRID_CONFIG_KEY } from "../../stores/reducers/mediaGridConfigReducer";
import { DataResponseType, MediaDataResponse, AssetStorageUtils } from "../AssetStorageUtils";
import { ELAdobeAsset, RenditionData, elDeserializeAsset } from "../../common/interfaces/storage/AssetTypes";
import ELAssetExportHandler from "../../modules/assetExporter/AssetExporter";
import { EmbeddedMetadataType, RepoRenditionState, RepoVideoResponseType } from "../../common/interfaces/storage/StorageTypes";
import { PROCESSING_THUMBDATA } from "../Constants/Constants";
import { ELExportOption, ELExportInfo } from "../../common/interfaces/export/ELExportTypes";
import { MediaRepoDirFetchUtils } from "./MediaRepoDirFetchUtils";

const FETCH_LIMIT = 60;

const useRepoDirFetch = (dirPath: string): MediaFetchParams => {

    const sPathRef = useRef<string>(dirPath);
    const rootData = useSelector((state: RootState) => state.mediaOrganizerReducer);
    const configData = useSelector((state: RootState) => state.mediaConfigReducer);


    // const getLimitStr = (): string => {
    //     return "&limit=" + FETCH_LIMIT;
    // }

    const fetchFirstPage = async (): Promise<PageFetchReturnType> => {
        const path = sPathRef.current;
        if (!path) {
            return Promise.reject();
        }
        if (!IMS.getInstance().isSignedInUser) {
            return Promise.reject();
        }
        if (configData[GRID_CONFIG_KEY].mediaType === GridMediaType.eNone) {
            const dummyRepoData: ELAdobeAsset = "";
            const dirResult: RepoDirListData = {
                children: [],
                hasNextPage: false,
                repoData: dummyRepoData as AdobeDirectoryData
            }
            const payload: PageFetchReturnType = {
                dirList: dirResult,
                stateKey: getStoreKey()
            }
            return Promise.resolve(payload);
        }
        try {
            const asset: AssetWithRepoAndPathOrId = { path: path, repositoryId: "" };
            const dir = await StorageService.getInstance().resolveAsset(asset, 'id') as AdobeDirectoryData;
            const pageOpts: PageOptions = {
                limit: FETCH_LIMIT,
                orderBy: MediaRepoDirFetchUtils.getOrderByStr(configData[GRID_CONFIG_KEY])
            };
            const selectedMediaType = configData[GRID_CONFIG_KEY].mediaType;
            if (selectedMediaType === GridMediaType.eImageOnly || selectedMediaType === GridMediaType.eVideoOnly) {
                pageOpts.type = MediaRepoDirFetchUtils.getTypeStr(configData[GRID_CONFIG_KEY]);
            }

            const dirResult: RepoDirListData = await StorageService.getInstance().getDirectoryList(dir, pageOpts);

            const payload: PageFetchReturnType = {
                dirList: dirResult,
                stateKey: getStoreKey()
            }
            return Promise.resolve(payload);
        } catch (error) {
            return Promise.reject();
        }
    }

    const fetchNextPage = async (): Promise<PageFetchReturnType> => {
        const path = getStoreKey();
        const rootDirData = rootData[path];
        if (!IMS.getInstance().isSignedInUser) {
            return Promise.reject();
        }

        if (rootDirData.hasNextPage && rootDirData.nextPage) {
            try {
                const dirData = await StorageService.getInstance().getNextPageData(rootDirData.nextPage);
                const payload: PageFetchReturnType = {
                    stateKey: getStoreKey(),
                    dirList: dirData
                }
                return Promise.resolve(payload);
            } catch (error) {
                return Promise.reject();
            }
        } else {
            return Promise.reject();
        }
    }

    const getVideoDataFromJson = (data: RepoVideoResponseType): RenditionData => {
        if (data.retryAfter) {
            return { imgData: PROCESSING_THUMBDATA, videoData: PROCESSING_THUMBDATA };
        }
        const state = data.renditionsStatus.state;
        if (state === RepoRenditionState.processing || state === RepoRenditionState.queued) {
            return { imgData: PROCESSING_THUMBDATA, videoData: PROCESSING_THUMBDATA };
        }
        const posterFrame = data.posterframe?.url ?? "";
        // 'at' is not supported by safari 
        const videoSrc = data.renditions?.filter((e) => e?.videoContainer === "MP4")[0]?.url ?? "";
        return { imgData: posterFrame, videoData: videoSrc, videoMetaData: { dimensionsInfo: data.originalDimensions } };
    }

    const fetchRendition = async (asset: ELAdobeAsset, renditionOptions: RenditionOptions, responseType: DataResponseType = "arraybuffer"): Promise<MediaDataResponse> => {

        if (!canFetchAssetRendition(asset))
            return Promise.reject();

        try {
            const response = await StorageService.getInstance().getRendition(asset, renditionOptions, responseType, RenditionSource.native, SIVUtils.hasSIVUrl(window.location.href));
            const responseData = renditionOptions.type === ELRenditionType.VIDEO_METADATA ? getVideoDataFromJson(response.result as RepoVideoResponseType) : response.result;
            const mediaFetchResponse = { data: responseData, statusCode: response.response.statusCode, responseType: response.response.responseType as DataResponseType };
            return Promise.resolve(mediaFetchResponse);

        }
        catch (error) {
            Logger.log(LogLevel.WARN, "useRepoDirFetch:fetchRendition: ", "Error fetching thumbnail ", error);
            return Promise.reject();
        }
    }

    const canFetchAssetRendition = (asset: ELAdobeAsset): boolean => {
        if (asset.assetClass === AssetTypes.Directory)
            return false;
        return true;
    }

    const getStoreKey = (): string => {
        return MediaRepoDirFetchUtils.getStoreKeyForPath(dirPath, configData);
    }

    const downloadDeserializedAsset = async (asset: ELAdobeAsset): Promise<void> => {
        if (asset === undefined) {
            return Promise.reject();
        }

        const downloadHandler = new ELAssetExportHandler();
        const exportInfo: ELExportInfo = {
            asset: asset
        }

        try {
            await downloadHandler.downloadFile(exportInfo, ELExportOption.thirdParty);
            return Promise.resolve();
        }
        catch (error) {
            Logger.log(LogLevel.WARN, "useRepoDirFetch:downloadDeserializedAsset: ", "Error in downloading file ", error, asset.assetId);
            return Promise.reject(error);
        }
    }

    const fetchAssetEmbeddedInfo = async (asset: ELAdobeAsset): Promise<EmbeddedMetadataType> => {
        const deserializeCurrentAsset = elDeserializeAsset(asset);
        return AssetStorageUtils.getAndUpdateMetadata(deserializeCurrentAsset);
    }

    const deleteDeserializedAsset = async (asset: ELAdobeAsset): Promise<boolean> => {
        try {
            const assetDeleted = await StorageService.getInstance().deleteAsset(asset);
            return Promise.resolve(assetDeleted);
        }
        catch (error) {
            Logger.log(LogLevel.WARN, "useRepoDirFetch:deleteDeserializedAsset: ", "Error in deleting file ", asset.assetId);
            return Promise.reject(false);
        }
    }

    const funcs: MediaFetchParams = {
        fetchFirstPage: fetchFirstPage,
        fetchNextPage: fetchNextPage,
        fetchRendition: fetchRendition,
        getStoreKey: getStoreKey,
        fetchAssetEmbeddedInfo: fetchAssetEmbeddedInfo,
        downloadDeserializedAsset: downloadDeserializedAsset,
        deleteDeserializedAsset: deleteDeserializedAsset
    }

    return funcs;
}

export default useRepoDirFetch;