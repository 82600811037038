/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { AssetWithRepoAndPathOrId } from "@dcx/common-types";
import { DirectoryMediaType } from "@dcx/assets";
import { DCXError } from '@dcx/error';

//Application Specific
import { DocumentFormat } from "../../common/interfaces/document/DocumentTypes";
import { ELAdobeAsset } from "../../common/interfaces/storage/AssetTypes";
import ELPIEDoc from "../../editors/pie/models/ELPIEDoc";
import { StorageService } from "../../services/StorageServiceWrapper";
import Constants from "../../utils/Constants/Constants";
import ELPIEImageFormatFactory from "../../editors/pie/formats/ELPIEImageFormatFactory";
import Utils from "../../utils/Utils";
import { EditingEngineManager } from "../../editors/editingEngines/EditingEngineManager";
import { PIEEditingEngine } from "../../editors/editingEngines/PIEEditingEngine";
import Logger, { LogLevel } from "../../utils/Logger";
import UploadHandler, { isAdobeDCXError } from "./UploadHandler";
import { IntlHandler } from "../intlHandler/IntlHandler";
import { PSD_NAME_INTL_KEY } from "../../editors/pie/utils/PIEUtils";
import { EditingEngineType } from "../../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { FileUtils } from "../../utils/FileUtils";
import { FileMimeType } from "@elements/elementswebcommon";

export default class ELNativeUploadHandler {
    private readonly _uploadFailedErrMsg = "Unable to upload file!";

    private async _isTempFolderPresent(): Promise<boolean> {
        const rootTempPath = Constants.CLOUD_TEMP_FOLDER as string;
        const asset: AssetWithRepoAndPathOrId = { path: Constants.DIR_SEPERATOR + rootTempPath, repositoryId: "" };
        try {
            await StorageService.getInstance().resolveAsset(asset, 'id') as ELAdobeAsset;
            return Promise.resolve(true);
        } catch (error) {
            if (isAdobeDCXError(error) && (error.code === DCXError.NOT_FOUND)) {
                Logger.log(LogLevel.INFO, "ELNativeUploadHandler:_isTempFolderPresent: ", "Root Temp Folder Not Present");
                return Promise.resolve(false);
            } else {
                Logger.log(LogLevel.ERROR, "ELNativeUploadHandler:_isTempFolderPresent: ", "Unknown error finding Root Temp Folder path");
                return Promise.reject(error);
            }
        }
    }

    private async _createTempFolder(): Promise<ELAdobeAsset> {
        try {
            const rootTempPath = Constants.CLOUD_TEMP_FOLDER as string;
            const dir = await StorageService.getInstance().createAssetRelativeToRoot(rootTempPath, true, DirectoryMediaType);
            return Promise.resolve(dir);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELNativeUploadHandler:_createTempFolder: ", "Failed in Creating Root Temp Folder path", error);
            return Promise.reject(error);
        }
    }

    private async _checkAndCreateTempFolder(): Promise<ELAdobeAsset | undefined> {
        const isTempFolderPresent = await this._isTempFolderPresent();
        if (!isTempFolderPresent) {
            return await this._createTempFolder();
        }
    }

    async uploadPSDDoc(psdDoc: ELPIEDoc, name?: string): Promise<ELAdobeAsset | undefined> {
        try {
            const pieEditingEngine = await EditingEngineManager.getEditingEngine(EditingEngineType.pie) as PIEEditingEngine;
            await pieEditingEngine.ready();
            const psdFormat = ELPIEImageFormatFactory.createFormat(DocumentFormat.PSD);
            const defaultPSDName = IntlHandler.getInstance().formatMessage(PSD_NAME_INTL_KEY);
            const uploadName = (name ? name.split(".")[0] : defaultPSDName) + ".psd";
            const uploadPath = Constants.ELEMENTS_PHOTOS_FOLDER + "/" + Utils.getRandomUUID() + "/" + uploadName;

            const file = await psdFormat.writeFormat(psdDoc, pieEditingEngine);
            const fileBuffer = await Utils.readLocalFile(file);
            await this._checkAndCreateTempFolder();
            const asset = await StorageService.getInstance().createAssetByPath(Constants.CLOUD_TEMP_FOLDER as string, uploadPath, true, DocumentFormat.PSD);
            await StorageService.getInstance().updatePrimaryResource(asset, fileBuffer, DocumentFormat.PSD, fileBuffer.byteLength);

            return Promise.resolve(asset);
        } catch (error) {
            Logger.log(LogLevel.ERROR, this._uploadFailedErrMsg, error);
            return Promise.reject(this._uploadFailedErrMsg);
        }
    }

    async uploadImageFromURL(imageURL: string, formatType: FileMimeType): Promise<ELAdobeAsset | undefined> {
        try {
            const uploadName = FileUtils.getFileNameFromPath(imageURL);
            const uploadPath = Constants.ELEMENTS_PHOTOS_FOLDER + "/" + uploadName;

            const file = await FileUtils.urlToFile(imageURL, uploadName, formatType);
            const fileBuffer = await Utils.readLocalFile(file);
            const uploadHandler = new UploadHandler();
            await uploadHandler.getOrCreateElementsPhotosDirAsset();
            const asset = await StorageService.getInstance().createAssetByPath(Constants.CLOUD_CONTENT_FOLDER as string, uploadPath, true, formatType);
            await StorageService.getInstance().updatePrimaryResource(asset, fileBuffer, formatType, fileBuffer.byteLength);

            return Promise.resolve(asset);
        } catch (error) {
            Logger.log(LogLevel.ERROR, this._uploadFailedErrMsg, error);
            return Promise.reject(this._uploadFailedErrMsg);
        }
    }
}