/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import Ingest from "@ccx-public/ingest";

//Application Specific
import Logger, { LogLevel } from "../utils/Logger";
import { PlatformUtils } from "../utils/PlatformUtils";
import Utils from "../utils/Utils";
import IMS from "./IMS";
import { IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestUserType } from "../utils/IngestConstants";
import { EnvConstants, IMSServiceCode } from "../utils/Constants/Constants";
import store from "../stores/store";
import { PrivacyUtils } from "../utils/PrivacyUtils";
import { IngestUtils } from "../utils/IngestUtils";
import { UserUtils } from "../utils/UserUtils";
import { AccessProfileManager } from "../modules/accessProfile/AccessProfile";

global.fetch = require('node-fetch');

const dependencies = {
    getAccessToken: function (callback: (err: any, token: string) => void) { // required
        if (callback) {
            const imsToken = IMS.getInstance().getUserAccessToken();
            if (imsToken)
                callback(null, imsToken);
            else
                callback(new Error("Could not fetch user access token"), "");
        }
    },
    clearAccessToken: function () { // optional
        // clear your cached access token since it's expired.
    },
    log: function (msg: string) { // optional
        // Uncomment to debug
        // Logger.log(LogLevel.INFO, "[IngestWrapper]", msg);
    }
};

const options = {
    ENVIRONMENT: `${process.env.REACT_APP_DUNAMIS_ENV}`,                   // 'prod', 'stage', or 'dev' (default is 'prod')
    ALLOW_NO_TOKEN: true,                    // Optional - set to allow events to be sent without a token        // Required
    ANALYTICS_API_KEY: `${process.env.REACT_APP_DUNAMIS_API_KEY}`,           // Required
    ANALYTICS_PROJECT: `${process.env.REACT_APP_DUNAMIS_PROJECT}`,           // Required
    ANALYTICS_X_PRODUCT: 'YOUR_PRODUCT',           // Required
    ANALYTICS_X_PRODUCT_LOCATION: 'YOUR_PRODUCT_LOCATION',  // Optional - specify a location in the product, like a panel/extension
    ANALYTICS_USER_AGENT: 'YOUR_USER_AGENT',        // Optional - if not specified, the API key will be used (browsers will set this automatically)
    ANALYTICS_MAX_QUEUED_EVENTS: 50,                       // Optional - default is max queue of 50 events
    ANALYTICS_DEBOUNCE: 10,                       // Optional - default is to send no faster than every 10 seconds
    ANALYTICS_USER_REGION: 'UNKNOWN',                // Optional - set the x-user-region header (default is 'UNKNOWN')
    ANALYTICS_INGEST_TYPE: 'dunamis',                // Optional - only change this if you know what you're doing
    TIMESTAMP_PROPERTY_NAME: 'event.dts_end'           // Optional - only change this if you know what you're doing (override SDM)
};

export class IngestLogging {
    private static _instance: IngestLogging | null = null;
    private _adobeIngest;

    static getInstance(): IngestLogging {
        if (IngestLogging._instance === null)
            IngestLogging._instance = new IngestLogging();
        return IngestLogging._instance;
    }

    static isEnabled(): boolean {
        const userEmail = IMS.getInstance().getUserEmail();
        if (userEmail && !IngestUtils.isValidEmail(userEmail)) {
            return false;
        }

        if (process.env.REACT_APP_DUNAMIS_ENABLED === EnvConstants.true)
            return true;
        return false;
    }

    private constructor() {
        this._adobeIngest = new Ingest(dependencies, options);
        this.enableAnalytics(true);
        store.subscribe(this.enableAnalyticsFromCookiesPreferences);
    }

    isAnalyticsEnabled = (): boolean => {
        return this._adobeIngest._isEnabled;
    }

    private _createPayloadData(basePayload: Record<string, unknown>): Record<string, unknown> {
        const payload = basePayload;
        payload[IngestLogObjectKey.eventGuid] = Utils.getRandomUUID();
        payload[IngestLogObjectKey.eventDtsEnd] = (new Date()).toISOString();
        payload[IngestLogObjectKey.eventCategory] = IngestLogObjectValue.eventCategory;
        payload[IngestLogObjectKey.eventUserGuid] = IMS.getInstance().getUserId();
        payload[IngestLogObjectKey.eventOffline] = Utils.checkNetworkAccess();
        payload[IngestLogObjectKey.eventUserAgent] = PlatformUtils.description();
        payload[IngestLogObjectKey.eventLanguage] = Utils.getCurrentLocaleInSnakeCase();
        payload[IngestLogObjectKey.eventDeviceGuid] = Utils.getDeviceUUID();
        payload[IngestLogObjectKey.eventSessionGuid] = Utils.getSessionUUID();
        payload[IngestLogObjectKey.eventBuild] = options.ENVIRONMENT;

        payload[IngestLogObjectKey.sourceClientId] = process.env.REACT_APP_IMS_API_KEY;
        payload[IngestLogObjectKey.sourceName] = IngestLogObjectValue.sourceName;
        payload[IngestLogObjectKey.sourceVersion] = PlatformUtils.browserVersion();
        payload[IngestLogObjectKey.sourcePlatform] = PlatformUtils.osFamily();//mac/win/ios/android
        payload[IngestLogObjectKey.sourceDevice] = PlatformUtils.deviceType();//mobile/desktop/ipad
        payload[IngestLogObjectKey.sourceOSVersion] = PlatformUtils.osVersion();
        payload[IngestLogObjectKey.eventVisitorGuid] = payload[IngestLogObjectKey.eventDeviceGuid];

        const userServiceAccount = IMS.getInstance().getUserProfile()?.serviceAccounts?.filter(a =>
            a.serviceCode === IMSServiceCode.kCreativeCloud)[0];
        payload[IngestLogObjectKey.userServiceCode] = userServiceAccount?.serviceCode;

        if (IMS.getInstance().isSignedInUser()) {
            const isUserPaid = UserUtils.isUserPaid();
            payload[IngestLogObjectKey.userServiceLevel] = isUserPaid ? IngestUserType.paid : IngestUserType.trial;
        }

        const screenResolution = window.screen.width + "x" + window.screen.height;
        payload[IngestLogObjectCustomKey.screenResolution] = screenResolution;

        return payload;
    }

    private _isEducationalUser(): boolean {
        if (AccessProfileManager.getInstance().isAccessProfileFetched() && AccessProfileManager.getInstance().isEducationalUser()) {
            return true;
        }
        return false;
    }

    async logEvent(basePayload: Record<string, unknown>): Promise<void> {
        if (!IngestLogging.isEnabled() || !this.isAnalyticsEnabled() || this._isEducationalUser()) return;

        if (!AccessProfileManager.getInstance().isAccessProfileFetched()) {
            const TIMEOUT = 200;
            setTimeout(() => {
                this.logEvent(basePayload);
            }, TIMEOUT);
            return;
        }

        const payload = this._createPayloadData(basePayload);
        this._adobeIngest.postEvent(payload, function (err: any, numOfSentEvents: any) {
            if (err)
                Logger.log(LogLevel.INFO, "[IngestWrapper][logEvent] Logging event was unsuccessful: ", payload, err);
            else
                Logger.log(LogLevel.INFO, "[IngestWrapper][logEvent] Logging event was successful: ", payload);
        });
        return;
    }

    async logEventAndWait(basePayload: Record<string, unknown>): Promise<void> {
        if (!IngestLogging.isEnabled() || !this.isAnalyticsEnabled()) return;
        const payload = this._createPayloadData(basePayload);
        return new Promise((resolve, reject) => {
            this._adobeIngest.postEvent(payload, function (err: any, numOfSentEvents: any) {
                Logger.log(LogLevel.INFO, "[IngestWrapper][logEventAndWait] Logging event was successful: ", payload);
                resolve();
            });
        });
    }

    async fetchAndLogEvent(forData: () => Promise<Record<string, unknown>>): Promise<void> {
        const basePayload = await forData();
        this.logEvent(basePayload);
    }

    enableAnalytics(val: boolean): void {
        this._adobeIngest.enable(val);
    }

    flush(val: boolean): void {
        // Flush any queued events
        // Specify true, to send immediately
        this._adobeIngest.flush(val);
    }

    enableAnalyticsFromCookiesPreferences = (): void => {
        const activeGroups = store.getState().cookiePreferencesReducer;
        Logger.log(LogLevel.INFO, activeGroups);

        if (PrivacyUtils.hasUserProvidedConsent(activeGroups))
            this.enableAnalytics(true);
        else
            this.enableAnalytics(false);
    }
}




