/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import _ from "lodash";

//Adobe Internal
import { CreationOperationTypeInfo, Request } from "@elements/elementswebcommon";

//Application Specific
import ElementsCreationInAppNotifier from "../../../../workspaces/creations/utils/ElementsCreationInAppNotifier";
import IBatchRequestHandler from "../IBatchRequestHandler";
import Logger, { LogLevel } from "../../../../utils/Logger";
import Utils from "../../../../utils/Utils";
import { ControllerAction } from "../../../../view/IViewController";
import { ElementsCreationBatchProcessor } from "../../elBatchProcessor/ElementsCreationBatchProcessor";

abstract class ELCreationBatchRequestHandler<ResponseData> extends IBatchRequestHandler<Request, ResponseData> {
    protected notifier: ElementsCreationInAppNotifier;
    protected requestResponses: ResponseData | null = null;

    constructor(owner: ElementsCreationBatchProcessor<ResponseData>) {
        super(owner);
        this.notifier = this.getNotifier();
        this.notifier.subscribe(this, this.getNotifierActionType());
    }

    destroy(): void {
        this.notifier.unsubscribe(this, this.getNotifierActionType());
    }

    async createBatchAndPostRequest(requestParams: Request, batchSize: number): Promise<ResponseData> {
        const requestList = this._makeBatch(requestParams, batchSize);
        try {
            const responseData = await this.postRequestsAndUpdateResponses(requestList);
            return Promise.resolve(responseData);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELCreationBatchRequestHandler: createBatchAndPostRequest, failed at posting for batch with error: " + error);
            return Promise.reject(error);
        }
    }

    async postRequestsAndUpdateResponses(requestObjList: Request[]): Promise<ResponseData> {
        try {
            const requestsResponseData = await this.makeRequests(requestObjList);
            this.updateRequestResponses(requestsResponseData);
            this.pollStatusForRequestResponses(requestsResponseData);
            return requestsResponseData;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELCreationBatchRequestHandler: postRequestsAndUpdateResponses, failed with error : " + error);
            return Promise.reject(error);
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            default: {
                Logger.log(LogLevel.INFO, "ELCreationBatchRequestHandler: notify, unhandled action.type: " + action.type);
            }
        }

        if (!handled) {
            handled = await super.notify(action);
        }

        return handled;
    }

    private _makeBatch(requestObj: Request, batchSize: number): Request[] {
        const creationConfigurations = (requestObj.operations[0].operationTypeInfo as CreationOperationTypeInfo).operationSubTypeInfo?.configurations;
        if (!creationConfigurations) {
            throw new Error("ELCreationBatchRequestHandler:_makeBatch, creationConfigurations does not exist");
        }

        const batchesOfCreationConfigurations = Utils.batchArray(creationConfigurations, batchSize);
        const batchOfRequests: Request[] = [];

        for (const batchConfigurations of batchesOfCreationConfigurations) {
            const newRequest = _.cloneDeep(requestObj);
            const operationTypeInfo = (newRequest.operations[0].operationTypeInfo as CreationOperationTypeInfo);
            if (operationTypeInfo.operationSubTypeInfo === undefined) {
                throw new Error("operationSubTypeInfo is undefined");
            }
            operationTypeInfo.operationSubTypeInfo.configurations = batchConfigurations;
            batchOfRequests.push(newRequest);
        }

        return batchOfRequests;
    }

    protected abstract getNotifierActionType(): unknown;

    protected abstract getNotifier(): ElementsCreationInAppNotifier;

    protected abstract makeRequests(requestObjList: Request[]): Promise<ResponseData>;

    protected abstract pollStatusForRequestResponses(requestResponses: ResponseData): void;

    protected abstract updateRequestResponses(responseData: ResponseData): void;

}

export default ELCreationBatchRequestHandler;