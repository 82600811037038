/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useEffect } from "react";

import { ELToast } from "./ELToast";
import { ELReactSpectrumV2Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV2Provider";

import "./ELToastView.scss";

interface ELToastViewProps {
    controller: ELToast;
}

export const ELToastView = (props: ELToastViewProps): React.ReactElement => {
    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller])
    // Saw the code in v2 they do document.query for v2 provider and hence just mounting this would solve our problem.
    // Keeping it separate from code solve further problem of mix providers
    return (
        <ELReactSpectrumV2Provider/>
    );
}
