/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { EditingEngineType, GLiaConfig } from "../../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { PIEConfig } from "../../editors/editingEngines/PIEEditingEngine";
import { PIEEngineConfigManager } from "./PIEEngineConfigManager";

export interface EditingEngineTypeToConfigTypeMap {
    [EditingEngineType.pie]: PIEConfig,
    [EditingEngineType.glia]: GLiaConfig
}

export class EditingEngineConfigManager {
    static getEditingEngineDefaultConfig(engineType: EditingEngineType): EditingEngineTypeToConfigTypeMap[EditingEngineType] {
        switch(engineType) {
            case EditingEngineType.pie:
               return PIEEngineConfigManager.getDefaultConfig();
            default:
                throw new Error(`No config found for ${engineType}`);
        }
    }
}