/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third Party
import React, { Key, useReducer, useEffect } from "react";
import { Provider } from "react-redux";

// App specefic
import store from "../../../../stores/store";
import IViewController from "../../../IViewController"
import ELScoopedTabButton from "../../atoms/el-scooped-tab-button/ELScoopedTabButton";
import { ELTabPanelManager } from "../../organism/el-tab-panel-manager/ELTabPanelManager";
import { ELTabPanelKey, ELTabPanelViewAction, ITabPanelButton, ITabPanelItem, ELTabData, ELTabItem, ELTabPanel, TabPanelButtonTypes, ELFlexDirection } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { ViewAction } from "../../../IBaseController";

export interface ELTabPanelViewProps {
    controller: IViewController,
    listOfTabItems: ITabPanelItem[],
    selectedKey: ELTabPanelKey,
    panelItemDirection?: ELFlexDirection
}

export interface ELTabPanelState {
    selectedKey: ELTabPanelKey
}

const getInitialState = (selectedKey: ELTabPanelKey): ELTabPanelState => {
    return {
        selectedKey: selectedKey
    }
}

const getTabButton = (buttonInfo: ITabPanelButton, isSelected: boolean): React.ReactElement => {
    switch (buttonInfo.buttonType) {
        default:
        case TabPanelButtonTypes.scoopedTabButton: {
            return (
                <ELScoopedTabButton iconkey={buttonInfo.iconType}
                    selected={isSelected}
                    text={buttonInfo.name}
                    tooltip={buttonInfo.name}
                    variant={"secondary"} />
            )
        }
    }
}

const getTabItem = (buttonInfo: ITabPanelButton, isSelected: boolean): ELTabItem => {
    const tabItem: ELTabItem = {
        item: getTabButton(buttonInfo, isSelected)
    }
    return tabItem;
}

const getTabBody = (view: React.ReactNode): ELTabPanel => {
    const tabBody: ELTabPanel = {
        panel: view
    }
    return tabBody;
}

const getTabButtonData = (tabItem: ITabPanelItem, selectedKey: string): ELTabData => {
    return {
        key: tabItem.key,
        item: getTabItem(tabItem.buttonInfo, tabItem.key === selectedKey),
        panel: getTabBody(tabItem.view)
    }
}

export const ELTabPanelView = (props: ELTabPanelViewProps): React.ReactElement => {
    const defaultSelectedKey = props.selectedKey;

    const reducer = (state: ELTabPanelState, action: ViewAction): ELTabPanelState => {
        switch (action.type) {
            case ELTabPanelViewAction.selectedKey: {
                const selectedKey = action.payload as ELTabPanelKey;
                return {
                    ...state,
                    selectedKey: selectedKey
                };
            }
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, getInitialState(props.selectedKey));

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        return () => {
            //clean up
            props.controller.destroy();
        }
    }, [props.controller]);

    const onSelectionChange = (key: Key): void => {
        viewDispatch({
            type: ELTabPanelViewAction.selectedKey,
            payload: state.selectedKey === key ? ELTabPanelKey.empty : key
        });
    }

    const getTabData = (): ELTabData[] => {
        return props.listOfTabItems.map(tabItem => getTabButtonData(tabItem, state.selectedKey))
    }

    const getSelectedKey = (): Key | undefined => {
        if (state.selectedKey !== ELTabPanelKey.empty) {
            return state.selectedKey;
        }
        return undefined;
    }

    return (
        <Provider store={store}>
            <ELTabPanelManager dataTestId="test-panel-tab-managers" variant="non-modal" tabData={getTabData()} orientation="vertical"
                defaultSelectedKey={defaultSelectedKey}
                onSelectionChange={onSelectionChange}
                panelItemDirection={props.panelItemDirection}
                showTabContent={state.selectedKey !== ELTabPanelKey.empty}
                selectedKey={getSelectedKey()} />
        </Provider>
    )
}