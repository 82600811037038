/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//Application specific
import { RootState } from "../../stores/store";
import IBaseWorkspace, { WorkspaceActionType } from "../IBaseWorkspace";
import { ELNavbarContainerView } from "../common/component/ELNavbarContainerView";

import "./UserHome.scss";

export interface IUserHomeProps {
    controller: IBaseWorkspace
}

export const UserHomeApp = (props: IUserHomeProps): React.ReactElement => {
    const daysInTrial = useSelector((state: RootState) => state.appReducer.daysInTrial);

    useEffect(() => {
        // If this app has dispatch please send it from here
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        props.controller.notify({ type: WorkspaceActionType.startDefaultWorkflow })
    }, [daysInTrial]);

    return (
        <>
            <ELNavbarContainerView />
            <div id="user-home-app-container" />
        </>
    )
};

