/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import Logger, { LogLevel } from "../../utils/Logger";
import { IEditingEngine } from "./IEditingEngine";
import { PIEEditingEngine } from "./PIEEditingEngine";
import { EditingEngineTypeToConfigTypeMap, EditingEngineConfigManager } from "../../modules/editingEngineConfig/EditingEngineConfigManager";
import { GLiaEditingEngine } from "./GliaEditingEngine";
import { EditingEngineType } from "../../common/interfaces/editing/editingEngines/EditingEnginesTypes";

const editingEngineTypeToEditingEngineMap = new Map<EditingEngineType, unknown>([
    [EditingEngineType.pie, PIEEditingEngine],
    [EditingEngineType.glia, GLiaEditingEngine]
]);

export class EditingEngineManager {
    private static _engineTypeToEngineMap = new Map<EditingEngineType, IEditingEngine<unknown, unknown>>();

    private static _getInitializedEditingEngine(engineType: EditingEngineType, config?: EditingEngineTypeToConfigTypeMap[EditingEngineType]): IEditingEngine<unknown, unknown> {
        const editingEngine = new (editingEngineTypeToEditingEngineMap.get(engineType) as any)() as IEditingEngine<unknown, unknown>;
        editingEngine.initialize(config);
        return editingEngine;
    }

    static async getEditingEngine(engineType: EditingEngineType, config?: EditingEngineTypeToConfigTypeMap[EditingEngineType]): Promise<IEditingEngine<unknown, unknown>> {
        if (EditingEngineManager._engineTypeToEngineMap.has(engineType)) {
            const editingEngine = EditingEngineManager._engineTypeToEngineMap.get(engineType);
            if (editingEngine) {
                return editingEngine;
            }
        }

        let editingEngineConfig = config;
        if (editingEngineTypeToEditingEngineMap.has(engineType)) {
            try {
                if (!editingEngineConfig) {
                    editingEngineConfig = EditingEngineConfigManager.getEditingEngineDefaultConfig(engineType);
                }
                const engine = EditingEngineManager._getInitializedEditingEngine(engineType, editingEngineConfig);
                EditingEngineManager._engineTypeToEngineMap.set(engineType, engine);
                return engine;
            } catch (error: any) {
                Logger.log(LogLevel.ERROR, "EditingEngineManager: getEditingEngine: Error while initializing editing engine", { editingEngine: engineType, error: error})
                return Promise.reject(new Error(`Error while initializing ${engineType}`));
            }
        }
        Logger.log(LogLevel.WARN, "EditingEngineManager: getEditingEngine: No editing engine found", { engineType: engineType})
        return Promise.reject(new Error(`No editing engine found for ${engineType}`));
    }
}