/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType } from "../../workspaces/IWorkflow";
import { ControllerAction } from "../../view/IViewController";
import Logger, { LogLevel } from "../../utils/Logger";
import { CSAssetWithData, CreationWorkflowActions, CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import { ELSize } from "../../common/interfaces/geometry/ELGeometry";
import { CanvasViewAction, ELDownloadData } from "../../common/interfaces/stage/StageTypes";
import { RendererUpdateData } from "../../common/interfaces/renderer/RendererTypes";
import { ELAdobeAssetControllerAction, ELAdobeAssetDocActions } from "../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import store from "../../stores/store";
import { StorageService } from "../../services/StorageServiceWrapper";
import ImageUtils from "../../utils/ImageUtils";
import { DocumentActions } from "../../common/interfaces/document/DocumentTypes";
import { IngestUtils } from "../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../utils/IngestConstants";
import Utils from "../../utils/Utils";
import DocActions from "../../stores/actions/DocActions";
import { CanvasMode } from "../../stores/actions/CanvasAction";
import { ELLayoutInfo } from "../../common/interfaces/creations/ELSocialLayoutTypes";
import IDoc from "../document/IDoc";
import { ELRenditionHandler } from "../../modules/elRenditionHandler/ELRenditionHandler";

export interface ELAdobeAssetDocPayload {
    assetInfo: CSAssetWithData,
    layoutInfo?: ELLayoutInfo
}

export default class ELAdobeAssetDoc extends IDoc {
    private _assetInfo: CSAssetWithData;

    constructor(owner: IWorkflow) {
        super(owner);
        this._assetInfo = {} as CSAssetWithData;
        this._showRenderError(true);

    }

    initialize<T>(payload?: T): Promise<boolean> {
        const docPayload: ELAdobeAssetDocPayload = payload as unknown as ELAdobeAssetDocPayload;
        this.data = docPayload;
        this._assetInfo = docPayload.assetInfo;
        this.layoutInfo = docPayload.layoutInfo;
        return Promise.resolve(true);
    }

    private _setAssetInfo(payload: ELAdobeAssetDocPayload): void {
        this._assetInfo = payload.assetInfo;
    }

    private _showRenderError(renderError: boolean): void {
        if (this.renderer) {
            this.renderer.setRenderError = renderError;
        }
    }

    private async _renderUpdatedAsset(): Promise<void> {
        try {
            const updateData: RendererUpdateData = {
                type: ELAdobeAssetDocActions.assetUpdated,
                payload: this._assetInfo
            };
            await this.renderer?.update(updateData);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject();
        }
    }

    private _setAssetData(objectURL: string): void {
        this._assetInfo = {
            ...this._assetInfo,
            objectURL: objectURL
        };
    }

    private async _getOriginalAsset(): Promise<void> {
        const assetId = this._assetInfo.assetURN;
        let objectURL: string | undefined = this._assetInfo.objectURL;

        if (!objectURL) {
            try {
                const rendtionHandler = new ELRenditionHandler();
                const asset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, 'id');
                objectURL = await rendtionHandler.getRendition(asset);
            } catch (error) {
                Logger.log(LogLevel.WARN, "ELAdobeAssetDoc::_getOriginalAsset", error);
                return Promise.reject();
            }
        }

        this._setAssetData(objectURL);
        return Promise.resolve();
    }

    private async _renderOriginalAsset(): Promise<void> {
        try {
            await this._getOriginalAsset();
            await this._renderUpdatedAsset();
            this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.success });
        } catch (error) {
            this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.error });
        }
    }

    private async _renderImage(image: ELAdobeAssetDocPayload): Promise<void> {
        this._setAssetInfo(image);
        await this._renderOriginalAsset();
    }

    async render(container: HTMLElement): Promise<void> {
        try {
            await this._getOriginalAsset();
            await this.renderer?.render(container);
            window.requestAnimationFrame(() => { //let canvas drawing complete
                this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.success });
                this.renderer?.notify({ type: CanvasViewAction.addCanvasChangedHandlers });
            });
        } catch (error) {
            Promise.reject(error);
        }
    }

    private _ingest(payload: Record<string, string>): void {
        this.owner.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case DocumentActions.download: {
                const downloadData = action.payload as ELDownloadData;
                this.download(downloadData);
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.renderImage:
                await this._renderImage(action.payload as ELAdobeAssetDocPayload);
                handled = true;
                break;
            case DocumentActions.zoomModified: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, Utils.getPercentageFromNumber(action.payload as number)));
                store.dispatch(DocActions.updateZoomLevel(action.payload as number));
                handled = true;
                break;
            }
            case DocumentActions.commitLayout: {
                if (!action.payload) {
                    action.payload = this.layoutInfo;
                }
                await this.owner.notify(action);
                handled = true;
                break;
            }
            default:
                Logger.log(LogLevel.INFO, "ELAdobeAssetDoc::notify::default case");
        }

        if (!handled) {
            handled = await super.notify(action);
        }

        if (!handled)
            handled = await this.owner.notify(action as WorkflowAction);

        return handled;
    }

    async getSize(): Promise<ELSize> {
        const size = await this.getOriginalSize();
        if (this.layoutInfo && store.getState().canvasReducer.mode === CanvasMode.render) {
            size.width = (this.layoutInfo.right - this.layoutInfo.left) * this.layoutInfo.scaleX * size.width;
            size.height = (this.layoutInfo.bottom - this.layoutInfo.top) * this.layoutInfo.scaleY * size.height;
        }
        return size;
    }

    async getOriginalSize(): Promise<ELSize> {
        if (!this.documentSize) {
            if (this._assetInfo.objectURL) {
                const img = await ImageUtils.getMetaDataFromURL(this._assetInfo.objectURL);
                this.documentSize = {
                    width: img.naturalWidth,
                    height: img.naturalHeight
                }
            } else {
                return Promise.reject("assetInfo doesn't contains objecturl");
            }
        }
        return _.cloneDeep(this.documentSize);
    }

    async getImageData(): Promise<ImageData> {
        try {
            const dataURL = await this.downloader?.getDataURL();
            if (dataURL) {
                const imageData = ImageUtils.createImageData(dataURL);
                return Promise.resolve(imageData);
            }
            return Promise.reject("Image data not available");
        } catch (error) {
            return Promise.reject(error);
        }
    }

    close(): Promise<void> {
        return Promise.resolve();
    }

    destroy(): void {
        super.destroy();
        this.close();
        this.renderer?.destroy();
    }

    save(): void {
        throw new Error("Method not implemented.");
    }

    documentUpdated(payload: unknown): void {
        throw new Error("Method not implemented.");
    }

    download(downloadData: ELDownloadData): void {
        this.downloader?.download(downloadData);
    }

    getData(): unknown {
        const payload: ELAdobeAssetDocPayload = {
            assetInfo: this._assetInfo,
            layoutInfo: this.layoutInfo
        }
        return payload;
    }
}