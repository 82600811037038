/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { Constants as CommonCodeConstants } from "@elements/elementswebcommon";

//Application specific
import { CreationsData, CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import ITemplateViewController from "../../../../view/ITemplateViewController";
import { ControllerAction } from "../../../../view/IViewController";
import CreationUtils from "../CreationUtils";
import { ELCreationsFullAssetProviderTypes } from "./ELCreationsFullAssetProviderTypes";
import Logger, { LogLevel } from "../../../../utils/Logger";
import ELOpenInDesktopCreationObserver from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopCreationObserver";
import CreationInAppNotifier from "../CreationInAppNotifier";
import { CreationAppSubscriberType } from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ElementsCreationsService from "../../../../services/ElementsServices/ElementsCreationsService";

abstract class ICreationsFullAssetProvider {
    protected owner: ITemplateViewController;
    protected creationsData: CreationsData;

    private _openInDesktopCreationsObserver: ELOpenInDesktopCreationObserver;

    constructor(owner: ITemplateViewController, creationsData: CreationsData) {
        this.owner = owner;
        this.creationsData = creationsData;
        this._openInDesktopCreationsObserver = new ELOpenInDesktopCreationObserver(this.owner);
    }

    protected abstract createMasterAssetEditRequestBody(): Promise<unknown>;

    async getFullAssetData(): Promise<void> {
        try {
            const handledForMasterAssetAvailable = await this._isMasterAssetAvailable();
            if (!handledForMasterAssetAvailable) {
                this._blockUIForMasterAssetCreation();
                const requestJson = await this.createMasterAssetEditRequestBody();
                await this._editProjectForOpenInDesktop(requestJson);
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR,
                "ICreationsFullAssetProvider:getFullAssetData, failed with error: " + error);
            return Promise.reject(error);
        }
    }

    private _pollOpenInDesktopProjectStatus(projectID: string): void {
        CreationInAppNotifier.subscribe(this._openInDesktopCreationsObserver, CreationAppSubscriberType.statusChange);
        CreationInAppNotifier.pollStatus(projectID);
    }

    private async _editProjectForOpenInDesktop(requestJson: unknown): Promise<void> {
        const projectId = this.creationsData.id;
        try {
            await ElementsCreationsService.getInstance().editCreation(projectId, requestJson);
        } catch (error) {
            Logger.log(LogLevel.ERROR,
                "ICreationsFullAssetProvider:_editProjectForOpenInDesktop, project edit failed with error: " + error);
            return Promise.reject();
        }
        this._pollOpenInDesktopProjectStatus(projectId);
    }

    private _blockUIForMasterAssetCreation(): void {
        const message = IntlHandler.getInstance().formatMessage("getting-creation-to-open-in-desktop");
        this._updateProgressText(message);
        this._updateStatus(CreationsStatus.requested);
    }

    private async _isMasterAssetAvailable(): Promise<boolean> {
        const projectData = await CreationUtils.getProjectData(this.creationsData.id);
        const assets = await CreationUtils.getMasterAssetData(projectData);
        if (assets && assets.length > 0) {
            if (assets[0].assetData) {
                const assetPathOrId = { path: CommonCodeConstants.SEPARATOR + assets[0].assetData?.assetURN };
                const outputAsset = await CreationUtils.getCreationOutputAssetFromPathOrId(assetPathOrId);
                const assetId = outputAsset.assetId;
                this.owner.notify({
                    type: ELCreationsFullAssetProviderTypes.assetIDResolved,
                    payload: { assetId, assetType: projectData.operationSubType }
                });
                return Promise.resolve(true);
            } else {
                Logger.log(LogLevel.ERROR,
                    "ICreationsFullAssetProvider:_isMasterAssetAvailable, ELReplaceBackgroundFullAssetProvider project creation failed!");
                return Promise.reject();
            }
        }
        return Promise.resolve(false);
    }

    private _updateProgressText(message: string): void {
        const action: ControllerAction = {
            type: ELCreationsFullAssetProviderTypes.updateProgress,
            payload: message
        };
        this.owner.notify(action);
    }

    private _updateStatus(status: CreationsStatus): void {
        const action: ControllerAction = {
            type: ELCreationsFullAssetProviderTypes.creationStatus,
            payload: status
        };
        this.owner.notify(action);
    }
}

export default ICreationsFullAssetProvider;