/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// ThirdParty
import React, { ReactNode } from "react";

//Application Specific
import "./ELBadge.scss";

export interface ELBadgeProps {
    variant: "primary" | "secondary" | "cta",
    message?: string,
    children?: ReactNode
}

const ELBadge = (props: ELBadgeProps): React.ReactElement => {

    return (<div className="badge-container">
        <span className={"badge-icon " + props.variant}>{props.message ?? ""}</span>
        {props.children}
    </div>);
};

export default ELBadge;