/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import MediaOrganizerAction, { MediaOrganizerActionPayloadUnion } from '../actions/mediaOrganizerActions';
import IAction from '../actions/IAction';
import { RepoDirListData, AssetTypes } from "@elements/elementswebcommon";
import { AdobeAsset } from '@dcx/assets';
import { elDeserializeAsset, ELAdobeAsset } from '../../common/interfaces/storage/AssetTypes';

const filterBadChildren = (children: AdobeAsset[]): AdobeAsset[] => {
    const updatedChildrenList = children.filter((ele) => (elDeserializeAsset(ele) as ELAdobeAsset).size !== 0).map((ele) => {
        return ele
    });

    return updatedChildrenList;
}

const filterBadDataInDirList = (dirListData: RepoDirListData): RepoDirListData => {
    const updatedDirListData = dirListData;
    updatedDirListData.children = filterBadChildren(dirListData.children);
    return updatedDirListData;
}

const updateDirectoryData = (state: Record<string, RepoDirListData> = {}, action: IAction<MediaOrganizerActionPayloadUnion>): Record<string, RepoDirListData> => {
    const dirListData = action.payload?.data as RepoDirListData;
    const key = action.payload?.stateKey;

    if (key) {
        return {
            ...state,
            [key]: filterBadDataInDirList(dirListData)
        }
    } else {
        return state;
    }
}

const appendDirectoryPath = (state: Record<string, RepoDirListData> = {}, action: IAction<MediaOrganizerActionPayloadUnion>): Record<string, RepoDirListData> => {
    const dirListData = action.payload?.data as RepoDirListData;
    const key = action.payload?.stateKey;

    if (key) {
        if (state[key]) {
            return {
                ...state,
                [key]: {
                    ...state[key],
                    hasNextPage: dirListData.hasNextPage,
                    nextPage: dirListData.nextPage,
                    children: state[key].children.concat(filterBadChildren(dirListData.children))
                }
            }
        } else {
            return {
                ...state,
                [key]: filterBadDataInDirList(dirListData)
            }
        }
    } else {
        return state;
    }
}

const resetDirectoryData = (state: Record<string, RepoDirListData> = {}, action: IAction<MediaOrganizerActionPayloadUnion>): Record<string, RepoDirListData> => {
    const path = action.payload?.stateKey;
    if (!path) {
        return state;
    }

    const updatedState: Record<string, RepoDirListData> = {};
    for (const key in state) {
        if (!key.startsWith(path)) {
            updatedState[key] = state[key];
        }
    }
    return updatedState;
}

const removeAsset = (state: Record<string, RepoDirListData> = {}, action: IAction<MediaOrganizerActionPayloadUnion>): Record<string, RepoDirListData> => {
    const asset = action.payload?.data as AdobeAsset;
    const stateKey = action.payload?.stateKey as string;
    if (!stateKey)
        return state;

    switch (asset.assetClass) {
        case AssetTypes.Asset:
        case AssetTypes.Composite:
        case AssetTypes.File: {
            const children = state[stateKey].children.filter((assetChildren) => {
                if ((elDeserializeAsset(assetChildren) as ELAdobeAsset).assetId === asset.assetId)
                    return false;
                return true;
            });

            return {
                ...state,
                [stateKey]: {
                    ...state[stateKey],
                    children: children
                }
            }
        }
        default:
            return state;
    }
}

const mediaOrganizerReducer = (state: Record<string, RepoDirListData> = {}, action: IAction<MediaOrganizerActionPayloadUnion>): Record<string, RepoDirListData> => {
    switch (action.type) {
        case MediaOrganizerAction.UPDATE_DIRECTORY_DATA:
            return updateDirectoryData(state, action);
        case MediaOrganizerAction.APPEND_DIRECTORY_CHILDREN:
            return appendDirectoryPath(state, action);
        case MediaOrganizerAction.REMOVE_ASSET:
            return removeAsset(state, action);
        case MediaOrganizerAction.RENAME_ASSET:
            return state;
        case MediaOrganizerAction.RESET_DIR:
            return resetDirectoryData(state, action);
        default:
            return state;
    }
}

export default mediaOrganizerReducer;