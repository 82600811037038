/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../../../IWorkflow";
import { ViewAction } from "../../../../../view/IBaseController";
import IBaseWorkspace, { WorkspaceActionType } from "../../../../IBaseWorkspace";
import { ShareHandlerFactory } from "../../../../../services/shareHandler/ShareHandlerFactory";
import { ShareMode } from "../../../../../common/interfaces/share/ShareTypes";
import store from "../../../../../stores/store";
import { ShareUtils } from "../../../../../utils/ShareUtils";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { ControllerAction } from "../../../../../view/IViewController";

class FacebookShare extends IWorkflow {

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.facebookShare);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    private _ingestShareComplete(success: boolean): void {
        ShareUtils.ingestShareComplete(ShareMode.facebook, success, this.notify.bind(this));
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        const shareHandler = ShareHandlerFactory.getInstance().getShareHandler(ShareMode.facebook);
        const linkInfo = store.getState().shareReducer.linkInfo;

        Logger.log(LogLevel.DEBUG, linkInfo.link);

        shareHandler?.shareLink(linkInfo.link, () => { return; });
        if (linkInfo.link)
            this._ingestShareComplete(true);
        else
            this._ingestShareComplete(false);

        this._owner.notify({ type: WorkspaceActionType.endWorkspace })
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }
}

export { FacebookShare };
