/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";

//Application specific
import IViewController from "../../../../view/IViewController";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import KeyEventUtils from "../../../../utils/KeyEventUtils";
import { ViewAction } from "../../../../view/IBaseController";
import { ELPeekThroughDocAction, PeekThroughViewActions } from "../../../../common/interfaces/creations/ELPeekThroughTypes";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";
import { CanvasZoomLevelAction } from "../../../../common/interfaces/stage/StageTypes";
import store, { RootState } from "../../../../stores/store";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import DocActions from "../../../../stores/actions/DocActions";
import { ZoomLevel } from "../../../../editors/stage/ELFabricStage";
import { ELCreationProgressView } from "../../../../view/components/organism/el-creation-progress-view/ELCreationProgressView";
import { useUnloadHandler } from "../../../../utils/hooks/useUnloadHandler";
import { ELOpenInDesktopManagerViewAction } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import { ELReactSpectrumV3Provider } from "../../../../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./PeekThrough.scss"

interface IPeekThroughViewProps {
    controller: IViewController
}

interface ELPeekThroughState {
    status: CreationsStatus,
    progressText: string,
    isClicked: boolean,
    openInDesktopInProgress: boolean
}

const getInitialState = (): ELPeekThroughState => {
    return {
        status: CreationsStatus.requested,
        progressText: IntlHandler.getInstance().formatMessage("generating-output"),
        isClicked: false,
        openInDesktopInProgress: false
    }
}

const PeekThroughView = (props: IPeekThroughViewProps): React.ReactElement => {

    const reducer = (state: ELPeekThroughState, action: ViewAction): ELPeekThroughState => {
        switch (action.type) {
            case PeekThroughViewActions.peekThroughStatus: {
                const status = action.payload as CreationsStatus;
                return {
                    ...state,
                    status: status
                };
            }
            case PeekThroughViewActions.peekThroughProgressText: {
                const progressText = action.payload as string;
                return {
                    ...state,
                    progressText: progressText
                };
            }
            case ELOpenInDesktopManagerViewAction.inProgressStatus: {
                const openInDesktopInProgress = action.payload as boolean;
                return {
                    ...state,
                    openInDesktopInProgress: openInDesktopInProgress
                }
            }
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, getInitialState());

    const addZoomEventListener = (e: KeyboardEvent): void => {
        const isRunningOnMac = PlatformUtils.isRunningOnMacOS();
        if (e.key === KeyboardKey.minus) {
            if ((isRunningOnMac && e.metaKey) || (!isRunningOnMac && e.ctrlKey)) {
                e.preventDefault();
                props.controller.notify({ type: CanvasZoomLevelAction.zoomOutEvent })
            }
        }
        else if (e.key === KeyboardKey.equals) {
            if ((isRunningOnMac && e.metaKey) || (!isRunningOnMac && e.ctrlKey)) {
                e.preventDefault();
                props.controller.notify({ type: CanvasZoomLevelAction.zoomInEvent })
            }
        } else if (KeyEventUtils.isDeleteKeyPressed(e)) {
            const targetName = (e.target as HTMLInputElement).tagName;
            if (targetName !== "INPUT") {
                e.preventDefault();
                props.controller.notify({ type: ELPeekThroughDocAction.removeOverlay });
            }
        }
    }

    const docStoreData = useSelector((rootState: RootState) => rootState.docStateReducer);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            window.onbeforeunload = null;
            props.controller.destroy();
        }
    }, [props.controller]);

    useUnloadHandler(docStoreData.isDirty === DocumentDirty.NON_DIRTY || state.openInDesktopInProgress);

    //EventListner 
    useEffect(() => {
        document.addEventListener('keydown', addZoomEventListener);

        return () => {
            store.dispatch(DocActions.updateZoomLevel(ZoomLevel.default));
            document.removeEventListener('keydown', addZoomEventListener);
        }
    }, []);

    useEffect(() => {
        if(state.openInDesktopInProgress === true)
            props.controller.notify({type: ELOpenInDesktopManagerViewAction.progressCompleteStatus});
    }, [state.openInDesktopInProgress]);


    return (
        <ELReactSpectrumV3Provider width="100%">
            <div id="peekThrough-container" >
                <div id="peekThrough-header-container" />
                <Flex direction="column">
                    <div className="peekThrough-main-content-box" >
                        <div data-testid="peekThrough-edit-container" id="peekThrough-edit-container"
                            onContextMenu={(e) => e.preventDefault()} />
                        <div id="peekThrough-right-panel-container" />
                        <div className="peekThrough-feedback-icon-container" id="feedback-popover-container" />
                    </div>
                </Flex>
                <div id="open-deeplink-container" data-testid="open-deeplink-container" />
                {(state.status === CreationsStatus.requested)
                    && <ELCreationProgressView progressText={state.progressText} mode="parent" />}
            </div>
        </ELReactSpectrumV3Provider>
    )

}

export default PeekThroughView;
