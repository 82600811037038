/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React from "react";

// Adobe internal
import { Content, Dialog, Divider, Flex, Heading, Text } from "@adobe/react-spectrum";

// Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../atoms/el-button/ELButtonView";

import "./ELConfirmationDialog.scss";

export interface ELConfirmationDialogProps {
    confirm: () => void,
    close: () => void,
    iconType: React.ReactNode,
    heading: string,
    subHeading: string,
    text?: React.ReactNode
}

export const ELConfirmationDialog = (props: ELConfirmationDialogProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const { confirm, close, text } = props;
    return (
        <Dialog UNSAFE_className={"confirmation-dialog"}>
            <Heading>
                <div className="confirmation-dialog__h1">{props.heading}</div>
                <div className="confirmation-dialog__h2">{props.subHeading}</div>
            </Heading>
            <Divider size={"S"} />
            <Content>
                <Flex direction="column" alignItems="center" gap={"size-100"}>
                    {props.iconType}
                    <p className="confirmation-dialog__text">
                        {text}
                    </p>
                    <ELButton size="L" onClick={confirm} dataTestId="yes">
                        <Text UNSAFE_className="dialog__cta-text confirmation-dialog__cta-text">{intlHandler.formatMessage("yes")}</Text>
                    </ELButton>
                    <ELButton size="L" onClick={close}>
                        <Text UNSAFE_className="dialog__cta-text confirmation-dialog__cta-text">{intlHandler.formatMessage("no")}</Text>
                    </ELButton>
                </Flex>
            </Content>
        </Dialog>
    );
}