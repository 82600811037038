/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { RenderedStageData } from "../../common/interfaces/renderer/RendererTypes";
import { ELLayoutOperations, ELStageObject } from "../../common/interfaces/stage/StageTypes";
import { CanvasMode } from "../../stores/actions/CanvasAction";
import store from "../../stores/store";

export class StageUtils {

	/**
	 * @returns ELStageObject[]
	 */
	static getObjectListByName(objectList: ELStageObject[], filterNameList: string[]): ELStageObject[] {
		const filteredObjectList = objectList.filter((object) => {
			if (object.name && filterNameList.includes(object.name))
				return true;
			return false;
		});

		return filteredObjectList;
	}

	static getRenderedDataByLayerId(stageDataList: RenderedStageData[], layerId: string): RenderedStageData {
		const data = stageDataList.filter((stageData) => {
			if (stageData.object.data && stageData.object.data.payload === layerId) {
				return true;
			}
			return false;
		});
		return data[0];
	}

	static getRenderedDataByExclusionLayerId(stageDataList: RenderedStageData[], layerId: string): RenderedStageData[] {
		const data = stageDataList.filter((stageData) => {
			if (!stageData.object.data || (stageData.object.data && stageData.object.data.payload !== layerId)) {
				return true;
			}
			return false;
		});
		return data;
	}

	/**
	 * @returns RenderedStageData[]
	 */
	static getRenderedDataListByName(stageDataList: RenderedStageData[], filterNameList: string[]): RenderedStageData[] {
		const data = stageDataList.filter((stageData) => {
			if (stageData.object.name && filterNameList.includes(stageData.object.name)) {
				return true;
			}
			return false;
		});

		return data;
	}

	/**
	 * @returns RenderedStageData[]
	 */
	static getRenderedDataListByExclusionName(stageDataList: RenderedStageData[], filterNameList: string[]): RenderedStageData[] {
		const data = stageDataList.filter((stageData) => {
			if (stageData.object.name && !filterNameList.includes(stageData.object.name)) {
				return true;
			}
			return false;
		});

		return data;
	}

	static isLayoutModeOn(): boolean {
		if (store.getState().canvasReducer.mode === CanvasMode.layout) {
			return true;
		}
		return false;
	}

	static isLayoutOperation(actionType: string): boolean {
		if (ELLayoutOperations.includes(actionType)) {
			return true;
		}
		return false;
	}

	static getStageContainer(container: HTMLElement, attributeKey: string, attributeValue: string): HTMLDivElement | undefined {
		for (const node of container.children) {
			if (node.getAttribute(attributeKey) === attributeValue) {
				return node as HTMLDivElement;
			}
		}
	}

	static createStageContainer(attributeKey: string, attributeValue: string): HTMLDivElement {
		const stageDocContainer = document.createElement("div");
		stageDocContainer.className = "stage-doc-container";
		stageDocContainer.setAttribute(attributeKey, attributeValue);
		return stageDocContainer;
	}
}