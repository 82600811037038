/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

//Application Specific
import IBaseController, { ViewAction } from "./IBaseController";
import { history } from "../utils/Utils";
import store from "../stores/store";
import { ELReactProviderConfig } from "../common/interfaces/view/ELViewTypes";

export interface ControllerAction {
	type: string,
	payload?: unknown;
}

export default abstract class IViewController extends IBaseController {
	protected container?: HTMLElement;
	protected prefData?: Record<string, unknown>;

	/**
	 * to Reset/Cleanup of member variables,
	 * some member-variable can't be cleaned through GarbageCollector
	 * specific cleanup for those will be done by this method
	 */
	reset(): void {
		return;
	}

	getView(viewProps?: unknown): React.ReactNode {
		return;
	}

	initialize(dispatch?: React.Dispatch<ViewAction>): void {
		super.initialize(dispatch);
		this.prefData = {};
	}

	createView(container: HTMLElement, viewProps?: unknown): void {
		this.container = container;
	}

	destroyView(): void {
		this.container = undefined;
	}

	readPrefs(): void { return; }

	writePrefs(prefList: { key: string, value: string; }[]): void {
		prefList.forEach(pref => localStorage.setItem(pref.key, pref.value));
	}

	getPrefsKeys(): string[] {
		return [];
	}

	updatePrefs(): void { return; }

	protected getReactProvider(element: React.ReactElement, config: ELReactProviderConfig): React.ReactElement {
		let provider = element;
		if (config.history) {
			provider = React.createElement(Router, { history }, provider);
		}
		if (config.store) {
			provider = React.createElement(Provider, { store }, provider);
		}
		return provider;
	}

	async notify<T extends ControllerAction>(action: T): Promise<boolean> { return false; }
}
