/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Adobe internal
import { Text, Flex } from "@adobe/react-spectrum";

//Application specific
import EcosystemPreview from "../../../../assets/home/EL_Ecosystem.png";
import GooglePlayBadge from "../../../../assets/home/EL_Google_Play_Badge.svg";
import ApplePlayBadge from "../../../../assets/home/EL_Apple_Play_Badge.svg";
import ELMobileAppStoreStageQR from "../../../../assets/home/EL_QR_Stage_Code.jpeg";
import ELMobileAppStoreProdQR from "../../../../assets/home/EL_QR_Prod_Code.jpeg";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../atoms/el-button/ELButtonView";
import { useViewport, ViewportProvider } from "../../../../utils/hooks/useViewport";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { UserUtils } from "../../../../utils/UserUtils";
import { PHOTOSHOP_ELEMENTS_APPLE_MOBILE_APP_LINK, PHOTOSHOP_ELEMENTS_GOOGLE_MOBILE_APP_LINK } from "../../../../utils/Constants/Constants";

import "./ELQRBanner.scss";

const ELQRBanner = (): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    const GetDownloadButton = (): React.ReactNode => {
        return (
            <div className="qr-banner-download-button">
                <ELButton size="L" variant="cta" onClick={UserUtils.downloadDesktop}>
                    <Text UNSAFE_className="feature-banner-box__cta-text">{intlHandler.formatMessage("download")}</Text>
                </ELButton>
            </div>
        )
    }

    const GetELMobileAppStoreQR = (): React.ReactNode => {
        return (
            <img crossOrigin="anonymous" src={process.env.REACT_APP_ENV === "prod" ? ELMobileAppStoreProdQR : ELMobileAppStoreStageQR} className="w-100" alt="qrcode" />
        )
    }

    const GetQRBanner = (): React.ReactNode => {
        const { width, height } = useViewport();
        const isMobileDevice = PlatformUtils.isMobileDevice((width as number), (height as number)) as boolean;

        return (
            <Flex UNSAFE_className="qr-banner-box">
                <Flex UNSAFE_className="qr-banner-bottom-box" direction="column">
                    <Flex UNSAFE_className="feature-banner-box__content" direction="column">
                        <Flex gap="1.5rem" direction="column" alignContent="start" justifyContent="start">
                            <Text UNSAFE_className="qr-banner-box__heading">{intlHandler.formatMessage("qr-banner-title")}</Text>
                            <Text UNSAFE_className="qr-banner-box__description">{intlHandler.formatMessage("qr-banner-download-desktop")}</Text>
                            {GetDownloadButton()}
                            <Text UNSAFE_className="qr-banner-box__description">{isMobileDevice ? intlHandler.formatMessage("qr-banner-download-mobile") : intlHandler.formatMessage("qr-banner-download-mobile-qr")}</Text>
                            <Flex direction={"row"} gap="2rem" UNSAFE_className="qr-banner-content">
                                <Flex UNSAFE_className="qr-banner-play-badge-content">
                                    <a href={PHOTOSHOP_ELEMENTS_GOOGLE_MOBILE_APP_LINK} className="w-100" target="_blank" rel="noopener noreferrer">
                                        <img crossOrigin="anonymous" src={GooglePlayBadge} className="qr-banner-play-badge-img" alt="google-play-badge" />
                                    </a>
                                    <a href={PHOTOSHOP_ELEMENTS_APPLE_MOBILE_APP_LINK} className="w-100" target="_blank" rel="noopener noreferrer">
                                        <img crossOrigin="anonymous" src={ApplePlayBadge} className="qr-banner-play-badge-img" alt="apple-play-badge" />
                                    </a>
                                </Flex>
                                <div className="qr-banner-divider" />
                                <Flex UNSAFE_className="qr-banner-qrcode">
                                    {GetELMobileAppStoreQR()}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex>
                    <div className="qr-banner-divider-main" />
                </Flex>
                <Flex UNSAFE_className="qr-banner-bottom-box">
                    <Flex UNSAFE_className="feature-banner-box__content" direction="column">
                        <Text UNSAFE_className="qr-banner-box__description">{intlHandler.formatMessage("qr-banner-connected-desc")}</Text>
                        <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="qr-banner-img" alt="qr-data" src={EcosystemPreview} />
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    return (
        <ViewportProvider>
            {GetQRBanner()}
        </ViewportProvider>
    )
}

export default ELQRBanner;
