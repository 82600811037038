/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { Text } from "@adobe/react-spectrum";

//Application specific
import "./CreationsComingSoonView.scss";
import IViewController from "../../../../view/IViewController";
import ELFeatureBannerView from "../../../../view/components/organism/el-feature-banner/ELFeatureBanner";
import SlideshowPreview from "../../../../../src/assets/home/Home-animation.mp4";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../../../view/components/atoms/el-button/ELButtonView";
import { Routes } from "../../../../app/AppRoute";

interface CreationsComingSoonViewProps {
    controller: IViewController
}

export const CreationsComingSoonView = (props: CreationsComingSoonViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const history = useHistory();

    const handleGoToMediaView = (): void => {
        history.push(Routes.MEDIA);
    }

    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <Flex UNSAFE_className="creations__feature-cta-box">
            <ELFeatureBannerView
                previewSrc={SlideshowPreview}
                title={intlHandler.formatMessage("coming-soon")}
                description={intlHandler.formatMessage("creation-description")} >
                <Flex UNSAFE_className="creations-feature-banner-box__cta" direction="column">
                    <Flex >
                        <ELButton size="L" variant="primary" childrenDirection="row-reverse" childrenGap="0.2rem" onClick={handleGoToMediaView}>
                            <Text UNSAFE_className="creations-feature-banner-box__cta-text">{intlHandler.formatMessage("go-to-media-view")}</Text>
                        </ELButton>
                    </Flex >
                </Flex>
            </ELFeatureBannerView>
        </Flex>
    )
}