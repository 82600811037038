/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import ELAdobeAssetDocDownloader from "../../adobeAsset/ELAdobeAssetDownloader";
import ELCollageDocDownloader from "../../collage/ELCollageDocDownloader";
import ELPIEDocDownloader from "../../pie/downloader/ELPIEDocDownloader";
import IStage from "../../stage/IStage";
import IDoc, { DocumentType } from "../IDoc";
import IDocumentDownloader from "./IDocumentDownloader";

export const DocumentDownloaderMap: { [key in DocumentType]: any } = {
    [DocumentType.collage]: ELCollageDocDownloader,
    [DocumentType.adobeAsset]: ELAdobeAssetDocDownloader,
    [DocumentType.peekThrough]: ELCollageDocDownloader,
    [DocumentType.stageDoc]: ELAdobeAssetDocDownloader,
    [DocumentType.pie]: ELPIEDocDownloader
};

export default abstract class DocumentDownloaderFactory {
    static createDownloader(docType: DocumentType, doc: IDoc, stage?: IStage): IDocumentDownloader {
        const documentDownloader = new DocumentDownloaderMap[docType](doc, stage);
        return documentDownloader;
    }
}
