/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ELViewType } from "../../../common/interfaces/creations/CreationTypes";
import { ELAdobeAsset } from "../../../common/interfaces/storage/AssetTypes";
import IDoc from "../../../editors/document/IDoc";
import { ELDualDocumentView } from "../../../editors/document/dualDocumentView/ELDualDocumentView";
import { DocEditingManager } from "../../../editors/editingManagers/DocEditingManager";
import { GRID_CONFIG_KEY } from "../../../stores/reducers/mediaGridConfigReducer";
import store from "../../../stores/store";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import ImageUtils from "../../../utils/ImageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import ITemplateViewController from "../../../view/ITemplateViewController";
import { ShareOptions } from "../../../view/components/organism/el-share-options/ELShareOptions";
import ELPanelManager from "../../../view/components/templates/el-panel-manager/ELPanelManager";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../IBaseWorkspace";
import IWorkflow, { WorkflowsName } from "../../IWorkflow";

const DEFAULT_SIZE = 5400;

export abstract class EditWorkflow<DocType extends IDoc, AppliedEditsConfig> extends IWorkflow {
    protected dualView?: ELDualDocumentView;
    protected beforeDoc?: DocType;
    protected doc?: DocType;
    protected currentMedia?: ELAdobeAsset;
    protected docEditingManager?: DocEditingManager<DocType, unknown>;
    protected editHeader?: ITemplateViewController;
    protected editRightPanel?: ELPanelManager;
    protected shareOptions: ShareOptions;
    protected mediaGridConfig;
    protected appliedEditsConfig?: AppliedEditsConfig;
    protected mediaAutoScaled: boolean;

    constructor(owner: IBaseWorkspace, workflowName: WorkflowsName) {
        super(owner, workflowName);
        this.mediaGridConfig = store.getState().mediaConfigReducer[GRID_CONFIG_KEY];
        this.shareOptions = new ShareOptions(owner);
        this.mediaAutoScaled = false;
    }

    protected async getImageDataFromAsset(asset: ELAdobeAsset): Promise<ImageData> {
        try {
            if (asset.assetId) {
                const mediaURL = await AssetStorageUtils.getAndStoreAssetData(asset.assetId);
                let imageData = await ImageUtils.createImageData(mediaURL);
                if(imageData.width > DEFAULT_SIZE || imageData.height > DEFAULT_SIZE) {
                    Logger.log(LogLevel.DEBUG, "EditWorkflow: _getImageDataFromAsset: Image dimensions are greater than the default max width and height");
                    imageData = ImageUtils.resizeImageDataWithAspectRatio(imageData, DEFAULT_SIZE) ?? imageData;
                    this.mediaAutoScaled = true;
                }

                Logger.log(LogLevel.INFO, "EditWorkflow: _getImageDataFromAsset: Successfully retrieved ImageData from asset");
                return Promise.resolve(imageData);
            } else {
                Logger.log(LogLevel.WARN, "EditWorkflow: _getImageDataFromAsset: Asset does not have assetId");
                return Promise.reject("Asset does not have assetId");
            }
        } catch (error) {
            Logger.log(LogLevel.WARN, "EditWorkflow: _getImageDataFromAsset: Error getting image data from asset" + error);
            return Promise.reject(error);
        }
    }

    protected async startPreviousWorkflow(): Promise<void> {
        this._owner.notify({ type: WorkspaceActionType.endWorkflow });

        if (this.prevWorkflow) {
            const workspacePayload: WorkspacePayload = {
                startWorkflow: this.prevWorkflow.getWorkflowName
            };
            const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
            this._owner.notify(workspaceAction);
        } else {
            this._owner.notify({ type: WorkspaceActionType.endWorkspace });
        }
    }

    protected abstract getDocData(doc: DocType): Promise<ImageData>;
    protected abstract createAndRenderDoc(): Promise<void>;
    protected abstract createDocument(viewType: ELViewType, imageData: ImageData): Promise<DocType>;
    protected abstract setupEditModules(): Promise<void>;
    protected abstract updateViewStatusAndProgressText(showProgress: boolean, progressText: string): void;
}