/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//Application Specific
import { CSAssetWithData } from "../../common/interfaces/creations/CreationTypes";
import { ELCollageTemplateBackgroundMode } from "../../common/interfaces/creations/ELCollageTypes";
import { RenderedShapesName } from "../../common/interfaces/renderer/RendererTypes";
import { ELStageBackgroundOptions, ELStageShapeAndObjectOptions, ELStageShapesType } from "../../common/interfaces/stage/StageTypes";
import CollageUtils from "../../workspaces/creations/workflows/collage/utils/CollageUtils";
import IDocDataParser from "../document/parser/IDocDataParser";
import ShapesFactory from "../stage/shapes/ShapesFactory";
import { ELAdobeAssetDocPayload } from "./ELAdobeAssetDoc";
import { ELSize } from "../../common/interfaces/geometry/ELGeometry";

export default class ELAdobeAssetDocParser extends IDocDataParser {
    private _createImageAreaWithLayout(beforeAssetInfo: CSAssetWithData, docSize: ELSize): ELStageShapeAndObjectOptions {
        const shape = ShapesFactory.createShape(ELStageShapesType.imageAreaWithLayout);

        const backgroundOptions: ELStageBackgroundOptions = {
            name: RenderedShapesName.imageAreaWithLayout,
            width: docSize.width,
            height: docSize.height,
            background: CollageUtils.getStageBackground({
                mode: ELCollageTemplateBackgroundMode.image,
                value: beforeAssetInfo.objectURL as string
            })
        };
        const stageAndObjectOptions: ELStageShapeAndObjectOptions = { shape: shape, objectOptions: backgroundOptions };

        return stageAndObjectOptions;
    }

    async parseDocData(): Promise<ELStageShapeAndObjectOptions[]> {
        const docSize = await this.doc.getSize();
        const docPayload = this.doc.getData() as ELAdobeAssetDocPayload;
        const assetInfo = docPayload.assetInfo;

        const stageAndObjectsOptions: ELStageShapeAndObjectOptions[] = [];
        stageAndObjectsOptions.push(this._createImageAreaWithLayout(assetInfo, docSize));

        return stageAndObjectsOptions;
    }

    parseDocUpdatedData(data: unknown): unknown {
        throw new Error("Method not implemented.");
    }
}