/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Asset, Version } from "@elements/elementswebcommon";

//Application Specfic
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELCreationsPathResolver } from "./ELCreationsPathResolver";
import { ELAssetPathOrId } from "../../../../common/interfaces/storage/AssetTypes";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";

export class ELPeekThroughPathResolver extends ELCreationsPathResolver {
    
    async getCreationPreviewAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();
        switch (requestVersion) {
            case Version.V3: {
                if((undefined === this.creationsData.outputs) || (undefined === this.creationsData.outputs.preview)) {
                    Logger.log(LogLevel.ERROR, "ELPeekThroughPathResolver: getCreationPreviewAssetPathOrId, unable to find the preview for the creation");
                    return Promise.reject();
                } else {
                    return this.getAssetIdFromOutputInfo(this.creationsData.outputs.preview);
                }
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELPeekThroughPathResolver: getCreationPreviewAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationFullAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();

        switch (requestVersion) {
            case Version.V3: {
                if ((undefined === this.creationsData.outputs) || (undefined === this.creationsData.outputs.preview)) {
                    Logger.log(LogLevel.ERROR, "ELPeekThroughPathResolver: getCreationFullAssetPathOrId, unable to find the preview for the creation");
                    return Promise.reject();
                } else {
                    if (this.creationsData.status === CreationsStatus.success) {
                        return this.getAssetIdFromOutputInfo(this.creationsData.outputs.preview);
                    }
                    return this.getAssetPathFromOutputInfo(this.creationsData.outputs.preview);
                }
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELPeekThroughPathResolver: getCreationFullAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationMasterAssetData(): Promise<Asset[]> {
        Logger.log(LogLevel.WARN, "ELPeekThroughPathResolver:getPSDCreationAssetID: ", "Function not supported for PeekThrough");
        throw new Error("Function not supported for PeekThrough");
    }

}