/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//Internal
import { ELAdobeAsset, RenditionSource, Resolution } from "@elements/elementswebcommon";

//Application Specific
import { GRID_CONFIG_KEY } from "../../../../../stores/reducers/mediaGridConfigReducer";
import { MediaGridConfig, MediaGridSortBy } from "../../../../../stores/actions/mediaGridConfigActions";
import store from "../../../../../stores/store";
import { GIF_FILE_FORMAT, PSDC_FILE_FORMAT, PSD_FILE_FORMATS } from "../../../../../common/interfaces/storage/FileTypes";
import { FilterMediaUiProp } from "../../../../../stores/actions/mediaGridConfigActions";
import { FilterMediaData } from "../../../../../stores/actions/mediaGridConfigActions";
import { GridMediaType } from "../../../../../stores/actions/mediaGridConfigActions";
import { WorkspaceAction, WorkspacePayload } from "../../../../IBaseWorkspace";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import Constants from "../../../../../utils/Constants/Constants";
import { ToastUtils } from "../../../../../utils/ToastUtils";
import { AssetStorageUtils } from "../../../../../utils/AssetStorageUtils";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { Routes } from "../../../../../app/AppRoute";
import { CreationsMode, CreationsStatusPayload, CreationsURLParams } from "../../../../../common/interfaces/creations/CreationTypes";
import { WorkflowsName } from "../../../../IWorkflow";
import Utils from "../../../../../utils/Utils";
import { ELSize } from "../../../../../common/interfaces/geometry/ELGeometry";
import { StorageService } from "../../../../../services/StorageServiceWrapper";
import ImageUtils from "../../../../../utils/ImageUtils";
import { elDeserializeAsset } from "../../../../../common/interfaces/storage/AssetTypes";

export default class MovingOverlayUtils {

    static getMovingOverlayMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);
        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageOnly;
        const filterData: FilterMediaData = {
            format: [PSDC_FILE_FORMAT, GIF_FILE_FORMAT, ...PSD_FILE_FORMATS],
            uiProp: FilterMediaUiProp.hide
        };
        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 1;
        mediaGridConfig.maxImageCount = 1;
        mediaGridConfig.minImageCount = 1;
        mediaGridConfig.maxVideoCount = 0;
        mediaGridConfig.maxTotalMediaSize = 400; //in MB;
        mediaGridConfig.maxVideoLength = 0;
        mediaGridConfig.selectUploadedMedia = true;
        return mediaGridConfig;
    }

    static filterMediaByCount(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): ELAdobeAsset[] {
        if ((mediaGridConfig.maxImageCount !== undefined) && assets.length > mediaGridConfig.maxImageCount) {
            assets.splice(mediaGridConfig.maxImageCount, assets.length - mediaGridConfig.maxImageCount);
        }

        return assets;
    }

    /**
     * @returns boolean - true if movingOverlay url is present
     */
    static hasMovingOverlayUrl(): boolean {
        const workflow = Utils.getLinkParamValue(window.location.href, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.movingOverlay.toLowerCase() === workflow.toLowerCase());
    }

    static isOpeningExistingMovingOverlay(workspaceAction: WorkspaceAction): boolean {
        return workspaceAction.initMode === CreationsMode.open;
    }

    /**
     * @returns string - movingOverlay id from window href
     */
    static getMovingOverlayIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    static async getMovingOverlayAssetDimensions(asset: ELAdobeAsset): Promise<ELSize> {
        try {
            const response = await StorageService.getInstance().getRendition(elDeserializeAsset(asset), { size: 220 }, "arraybuffer", RenditionSource.dcx, false);
            const sharedArrayImgData = response.result as SharedArrayBuffer;
            const objectURL = URL.createObjectURL(new Blob([sharedArrayImgData]));
            const dimensions = await ImageUtils.getImageSizeFromURL(objectURL);
            const isLandscape = dimensions.width > dimensions.height;
            const aspectRatio = (dimensions.width / dimensions.height);
            const baseDimension = isLandscape ? parseInt(Resolution._1280p) : parseInt(Resolution._1080p);
            if (isLandscape) {
                dimensions.width = baseDimension;
                dimensions.height = baseDimension * (1 / aspectRatio);
            } else {
                dimensions.width = baseDimension * aspectRatio;
                dimensions.height = baseDimension;
            }
            return dimensions;
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "Failed to get dimensions, returning default asset dimensions.");
            return { width: parseInt(Resolution._1280p), height: parseInt(Resolution._720p) };
        }
    }

    static getMovingOverlayPayload(): WorkspacePayload {
        const projectId = MovingOverlayUtils.getMovingOverlayIdFromHref();
        let mode = CreationsMode.open;
        let payload: CreationsStatusPayload | ELAdobeAsset[] | null = {
            projectId: projectId
        };

        if (projectId === Utils.getNilUUID()) {
            mode = CreationsMode.create;
            payload = store.getState().selectedMediaListReducer;
        }

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.movingOverlay,
            initMode: mode,
            payload: payload
        };

        return workspacePayload;
    }

    /**
     * @returns string - history state based on projectId
     */
    static getMovingOverlayHistoryState(projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + WorkflowsName.movingOverlay.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    static showErrorMessageForQuotaExceeded(): void {
        const message = IntlHandler.getInstance().formatMessage("creation-storage-quota-error", { creation: IntlHandler.getInstance().formatMessage("moving-overlay-creation").toLowerCase() });
        ToastUtils.error(message, { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number });
    }

    static canStartMovingOverlay(selectedAssets: ELAdobeAsset[]): boolean {
        const minImageCount = MovingOverlayUtils.getMovingOverlayMediaGridConfig().minImageCount;
        if (minImageCount && selectedAssets.length >= minImageCount)
            return true;
        return false;
    }

    static isMaxImageLimitExceeded(selectedAssets: ELAdobeAsset[]): boolean {
        const maxImageCount = MovingOverlayUtils.getMovingOverlayMediaGridConfig().maxImageCount;
        if ((maxImageCount !== undefined) && selectedAssets.length > maxImageCount)
            return true;
        return false;
    }

    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = AssetStorageUtils.filterRemoveVideoAssets(assets);
        filteredAssets = AssetStorageUtils.filterRemoveGifAssets(filteredAssets);
        filteredAssets = MovingOverlayUtils.filterMediaByCount(filteredAssets, mediaGridConfig);

        Logger.log(LogLevel.INFO, "MovingOverlay - getFilteredAssetList: ", filteredAssets);
        return filteredAssets;
    }
}