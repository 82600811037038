/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export class ClipboardService {
    /**
    * @param text - The text to write to clipboard.
    * @returns returns clipboard.writeText promise or Promise.reject.
    */
    static writeText(text: string): Promise<void> {
        if (!navigator.clipboard.writeText) {
            return Promise.reject(new Error("Clipboard.writeText is not available."));
        }

        return navigator.clipboard.writeText(text);
    }
}