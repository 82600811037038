/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/**
 * TODO: niskumar - add spectrum color variable names for these.
 */
export const Theme = {
    global: {
        controls_color_N: "#444bbf",
        controls_color_H: "#673fab",
        controls_color_S: "#444bbf",

        scooped_button_N: "#000000",
        scooped_button_H: "#000000",
        scooped_button_S: "#FFFFFF",

        share_link_button_N: "#D7373F",
        share_link_button_H: "#FFFFFF",
        share_link_button_S: "#000000",

        gray_controls_color_N: "#747474",
        gray_controls_color_H: "#505050",
        gray_controls_color_S: "#444bbf",
    },
    light: {
        controls_color_N: "#444bbf",
        controls_color_H: "#673fab",
        controls_color_S: "#444bbf",

        scooped_button_N: "#000000",
        scooped_button_H: "#000000",
        scooped_button_S: "#FFFFFF",

        gray_controls_color_N: "#747474",
        gray_controls_color_H: "#505050",
        gray_controls_color_S: "#444bbf",

        light_gray_color_N: "#2C2C2C"
    },
    dark: {
        controls_color_N: "#444bbf",
        controls_color_H: "#673fab",
        controls_color_S: "#444bbf",

        scooped_button_N: "#000000",
        scooped_button_H: "#000000",
        scooped_button_S: "#FFFFFF",

        gray_controls_color_N: "#323232",
        gray_controls_color_H: "#505050",
        gray_controls_color_S: "#444bbf",
    }
}
