/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { Dispatch } from "react";
import ReactDOM from "react-dom";

//Adobe Internal
import { Toast } from "@react/react-spectrum/Toast";

//Application Specific
import ITemplateViewController from "../../../ITemplateViewController";
import { ViewAction } from "../../../IBaseController";
import ELOpenInDesktopManagerView from "./ELOpenInDesktopManagerView";
import IViewController, { ControllerAction } from "../../../IViewController";
import ELDeeplinkManager from "../../../../modules/deeplink/ELDeeplinkManager";
import ELPSEDeeplinkCreator from "../../../../modules/deeplink/ELPSEDeeplinkCreator";
import { ELDeeplinkActionParams, ELDeeplinkActions } from "../../../../modules/deeplink/ELDeeplinkTypes";
import { ELOpenDeeplinkAction, ELOpenInDesktopDeeplinkAction, ELOpenInDesktopDialogIngestData, ELOpenInDesktopManagerViewAction, ELOpenInDesktopOpenAssetPayload } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes, IngestLogObjectCustomKey } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import Utils from "../../../../utils/Utils";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Constants, { WAIT_FOR_BLUR_EVENT } from "../../../../utils/Constants/Constants";
import Logger, { LogLevel } from "../../../../utils/Logger";

export default class ELOpenInDesktopManager extends ITemplateViewController {
    private _ingestSourceViewType: string;
    private _ingestAssetType: string;
    private _deeplinkRetryToast?: Toast;
    private _outOfFocusBeforeOpenURL = false;

    constructor(owner: IViewController, viewType: string) {
        super(owner);
        this._ingestSourceViewType = viewType;
        this._ingestAssetType = "";
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const openInDesktopManagerView = React.createElement(ELOpenInDesktopManagerView,
            { controller: this });
        ReactDOM.render(openInDesktopManagerView, container);
    }

    initialize(dispatch?: Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroy();
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    private _ingestHandlingForOpenInDesktopDialog(ingestData: ELOpenInDesktopDialogIngestData): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = this._ingestSourceViewType;
        this._ingest(IngestUtils.getPseudoLogObject(ingestData.subCategory, ingestData.eventType,
            ingestData.eventSubType, this._ingestAssetType));
    }

    private _getIngestEventType(isSuccessful: boolean): string {
        if (isSuccessful)
            return IngestEventTypes.success;
        else
            return IngestEventTypes.error;
    }

    private createRetryToast(payload: ELOpenInDesktopOpenAssetPayload): void {
        const retryToast = new Toast({
            children: IntlHandler.getInstance().formatMessage("app-not-found-try-again"),
            variant: "error",
            closable: true,
            actionLabel: IntlHandler.getInstance().formatMessage("try-again"),
            onAction: async () => {
                this._owner.notify({ type: ELOpenInDesktopDeeplinkAction.deeplinkTryAgain, payload: payload });
                this.removeRetryToast();
            },
            onClose: () => {
                this.removeRetryToast();
            }
        });
        this._deeplinkRetryToast = retryToast;
    }

    protected addToastForRetry(): void {
        if (this._deeplinkRetryToast)
            ToastUtils.addToast(this._deeplinkRetryToast, Constants.TOAST_NO_TIMEOUT as number);
    }

    protected removeRetryToast(): void {
        if (this._deeplinkRetryToast) {
            ToastUtils.removeToast(this._deeplinkRetryToast);
            this._deeplinkRetryToast = undefined;
        }
    }

    private _onDeeplinkSuccessFailure = (isSuccessful: boolean): void => {
        if (!this._outOfFocusBeforeOpenURL) {
            if (this.viewDispatcher)
                this.viewDispatcher({ type: ELOpenInDesktopManagerViewAction.openDeeplinkDialog, payload: !isSuccessful });

            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectCustomKey.viewType] = this._ingestSourceViewType;
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                this._getIngestEventType(isSuccessful), IngestEventSubTypes.openInDesktop, this._ingestAssetType, additionalLogInfo));
        }
        else {
            if (!isSuccessful)
                this.addToastForRetry();
        }

        this._outOfFocusBeforeOpenURL = false;
        this._owner.notify({ type: ELOpenInDesktopDeeplinkAction.deeplinkCallbackStatus, payload: isSuccessful });
    }

    private _createAndOpenDeeplink(paramsMap: Map<ELDeeplinkActionParams, string>): void {
        if (!document.hasFocus()) {
            this._outOfFocusBeforeOpenURL = true;
        }
        const deeplinkManager = new ELDeeplinkManager(new ELPSEDeeplinkCreator(), this._onDeeplinkSuccessFailure);
        deeplinkManager.createAndOpenDeeplink(paramsMap);
    }

    private _openDeeplink = (payload: ELOpenInDesktopOpenAssetPayload): void => {
        window.requestAnimationFrame(() => { 
            //This seems to be an issue in the Spectrum Toast component as we are getting undefined error for previous toast while rendering current toast
            //let canvas drawing complete
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("stay-in-browser"), { timeout: WAIT_FOR_BLUR_EVENT });
        });
        this._ingestAssetType = payload.assetType;

        const paramsMap = new Map<ELDeeplinkActionParams, string>();
        paramsMap.set(ELDeeplinkActionParams.action, ELDeeplinkActions.expertDefault);
        paramsMap.set(ELDeeplinkActionParams.assetId, payload.assetId);

        this._createAndOpenDeeplink(paramsMap);
    }

    private _openTryThisLink = (tryThisLink: string): void => {
        const tryThisLinkSubAction = Utils.getLinkParamValue(tryThisLink, ELDeeplinkActionParams.subAction);
        if (tryThisLinkSubAction) {
            const paramsMap = new Map<ELDeeplinkActionParams, string>();
            paramsMap.set(ELDeeplinkActionParams.action, ELDeeplinkActions.tryThisDefault);
            paramsMap.set(ELDeeplinkActionParams.subAction, tryThisLinkSubAction);
            this._createAndOpenDeeplink(paramsMap);
        } else {
            Logger.log(LogLevel.WARN, "ELOpenInDesktopManager::_openTryThisLink", "subAction not found in tryThis link");
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELOpenDeeplinkAction.openDeepLinkForAsset: {
                this.createRetryToast(action.payload as ELOpenInDesktopOpenAssetPayload);
                this._openDeeplink(action.payload as ELOpenInDesktopOpenAssetPayload);
                handled = true;
                break;
            }
            case ELOpenDeeplinkAction.openInDesktopDialogIngest: {
                this._ingestHandlingForOpenInDesktopDialog(action.payload as ELOpenInDesktopDialogIngestData);
                handled = true;
                break;
            }
            case ELOpenInDesktopDeeplinkAction.tryThis: {
                this._openTryThisLink(action.payload as string);
                handled = true;
                break;
            }
            default: {
                handled = await this._owner.notify(action);
            }
        }
        return handled;
    }
}