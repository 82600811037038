/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";

//Application Specific
import ELCreationsHeaderView from "./ELCreationsHeaderView";
import { ControllerAction } from "../../../IViewController";
import store from "../../../../stores/store";
import { CreationsData } from "../../../../common/interfaces/creations/CreationTypes";
import { WorkspaceActionType } from "../../../../workspaces/IBaseWorkspace";
import { ELCreationsHeaderControllerAction, ELCreationsHeaderViewAction, ELCreationsHeaderWorkflowAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import ICreationsHeader from "./ICreationsHeader";
import { WorkflowAction } from "../../../../workspaces/IWorkflow";
import CreationsAction from "../../../../stores/actions/CreationsAction";
import { CLOSED_PANEL_KEY } from "../../../../common/interfaces/creations/SlideshowTypes";

class ELCreationsHeader extends ICreationsHeader {

    createView(container: HTMLElement): Promise<void> {
        super.createView(container);

        const element = React.createElement(ELCreationsHeaderView, {
            controller: this,
            shareOptionController: this.shareOptions
        });

        const provider = this.getReactProvider(element, { history: true, store: true });

        return new Promise((resolve) => {
            ReactDOM.render(
                provider,
                container,
                () => { resolve(); });
        });
    }

    private _checkSlideshowMediaChange(): boolean | void {
        const selectedAssetIdList = store.getState().selectedMediaListReducer.map((asset) => { return asset.assetId });
        if (selectedAssetIdList.length > 0) {
            const slideshowStoreData = store.getState().creationsReducer;
            const slideshowId = slideshowStoreData.activeSlideshowId;
            const appliedAssetIdList = slideshowId ? slideshowStoreData.data[slideshowId].appliedData?.assets?.map((asset) => { return asset.assetURN }) : [];
            const isAssetListDifferent = !_.isEqual(_.sortBy(selectedAssetIdList), _.sortBy(appliedAssetIdList));
            return isAssetListDifferent;
        }
    }

    private _updateDoneControlState = (): void => {
        const isAssetListDifferent = this._checkSlideshowMediaChange();
        if (isAssetListDifferent === true && this.viewDispatcher) {
            this.viewDispatcher({ type: ELCreationsHeaderViewAction.updateDoneButtonState, payload: false });
            return;
        }

        const slideshowId = store.getState().creationsReducer.activeSlideshowId;
        if (slideshowId) {
            const appliedData = store.getState().creationsReducer.data[slideshowId].appliedData;
            const uiData = store.getState().creationsReducer.data[slideshowId].uiData;
            if (this.viewDispatcher) {
                this.viewDispatcher({ type: ELCreationsHeaderViewAction.updateDoneButtonState, payload: _.isEqual(uiData, appliedData) });
            }
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELCreationsHeaderControllerAction.changeName:
                {
                    this._renameCreation(action.payload as string);
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.back:
                {
                    store.dispatch(CreationsAction.updateSlideshowPanelSelectedKey(CLOSED_PANEL_KEY));
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                        IngestEventTypes.click, IngestEventSubTypes.back, IngestWorkflowTypes.slideshow));
                    handled = await this._owner.notify({
                        type: WorkspaceActionType.startPreviousWorkflow,
                    });
                    break;
                }
            case ELCreationsHeaderControllerAction.updateCreationsData:
                {
                    this._updateCreationsData(action.payload as CreationsData);
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.slideshowSettingsChanged:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.creations,
                        IngestEventTypes.change, IngestEventSubTypes.slideshowSettings, action.payload as string));
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.slideshowMediaUpdated:
            case ELCreationsHeaderControllerAction.slideshowDataUpdated:
                {
                    this._updateDoneControlState();
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.ctaDone:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow, IngestEventTypes.click, IngestEventSubTypes.done));
                    const workflowAction = { type: ELCreationsHeaderWorkflowAction.editSlideshow };
                    handled = await this._owner.notify(workflowAction);
                    break;
                }
            case ELCreationsHeaderControllerAction.ctaCancel:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow, IngestEventTypes.click, IngestEventSubTypes.cancel));
                    const workflowAction = { type: ELCreationsHeaderWorkflowAction.cancelCreation };
                    handled = await this._owner.notify(workflowAction);
                    break;
                }
        }

        if (!handled)
            handled = await super.notify(action);

        if (!handled)
            handled = await this._owner.notify(action as WorkflowAction);

        return handled;
    }
}

export default ELCreationsHeader;
