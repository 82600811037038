/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { editorInputToDocumentDataTypeMap } from "../../common/interfaces/document/DocumentTypes";
import { ELStageDocActions } from "../../common/interfaces/document/ELStageDocTypes";
import { Edit, EditTypeToConfigMap, editTypeToEditorMap } from "../../common/interfaces/editing/EditorTypes";
import { ELLayerKind } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import { IEditor } from "../IEditor";
import ELStageDoc from "../client/document/ELStageDoc";
import { ELStageDocDataConverterFactory } from "../document/dataConverter/ELStageDocDataConverterFactory";
import { DocEditingManager } from "./DocEditingManager";

export const enum ELStageDocEditingContextId {
    document = "document",
    layer = "layer"
}

/**
 * Represents the editing context for the ELStageDocEditingManager.
 */
export interface ELStageDocEditingContext {
    /**
     * The ID of the editing context. It can be from ELStageDocEditingContextId
     */
    contextId: ELStageDocEditingContextId;

    /**
     * An array of layer IDs. Only applicable when the contextId is "layer".
     */
    layerIds?: string[];

    /**
     * The name of the layer to be added that will contain edit. Only applicable when the contextId is "document".
     */
    layerName?: string;

    /**
     * The kind of the layer to be added that will contain edit. Only applicable when the contextId is "document".
     */
    layerKind?: ELLayerKind;

    /**
     * Specifies whether to delete an existing layer if layer with same name is found at the top. Only applicable when the contextId is "document".
     */
    deleteExistingLayer?: boolean;
}

export interface ELStageDocEditingConfig {
    editParams: EditTypeToConfigMap[Edit];
    docEditingContext: ELStageDocEditingContext;
}

interface AppliedEditsInfo {
    editName: Edit, 
    config: ELStageDocEditingConfig
}

export class ELStageDocEditingManager extends DocEditingManager<ELStageDoc, ELStageDocEditingConfig> {
    private _appliedEditsConfigStack: AppliedEditsInfo[] = [];

    private _getEditor(editName: Edit): IEditor<unknown, EditTypeToConfigMap[Edit]> {
        const Editor = editTypeToEditorMap[editName];
        return new Editor();
    }

    private async _preInitializeBeforeEdit(config: ELStageDocEditingConfig): Promise<void> {
        try {
            const { deleteExistingLayer, layerName, contextId } = config.docEditingContext;
            if (deleteExistingLayer && layerName && contextId === ELStageDocEditingContextId.document) {
                const topLayer = this.doc.getTopLayer();
                if (topLayer && topLayer.getName() === layerName) {
                    await this.doc.notify({ type: ELStageDocActions.removeLayer, payload: { layerId: topLayer.getId(), redraw: true } });
                }
            }
        } catch (error) {
            Logger.log(LogLevel.WARN, `Error initializing layer before applying edit: ${error}`);
        }
    }

    private async _applyEditToDoc(editName: Edit, config: ELStageDocEditingConfig): Promise<void> {
        try {
            const { editParams, docEditingContext } = config;
            const editor = this._getEditor(editName);
            //GLIA_REVISIT - Check better place to initialize editor
            await editor.initialize();
            await this._preInitializeBeforeEdit(config);
            const docConverter = ELStageDocDataConverterFactory.createDataConverter(this.doc, editorInputToDocumentDataTypeMap[editor.getInputDataType()]);
            const dataBeforeEdit = await docConverter.convertToData();
            const data = await editor.applyEdit(dataBeforeEdit, editParams as EditTypeToConfigMap[Edit]);
            const payload = {
                layerKind: docEditingContext.layerKind,
                layerName: docEditingContext.layerName,
                redraw: true
            };
            await docConverter.convertToDocData(data, payload);
        } catch (error: unknown) {
            Logger.log(LogLevel.ERROR, `Error applying edit ${editName}: ${error}`);
            return Promise.reject(error);
        }
    }

    private _saveConfig(editName: Edit, config: ELStageDocEditingConfig): void {
        this._appliedEditsConfigStack.push({ editName, config });
    }

    async applyEdit(editName: Edit, config: ELStageDocEditingConfig): Promise<void> {
        const contextId = config.docEditingContext.contextId;
        switch (contextId) {
            case ELStageDocEditingContextId.document:
                await this._applyEditToDoc(editName, config);
                break;
            default:
                return Promise.reject(`Unsupported contextId: ${contextId}`);
        }
        this._saveConfig(editName, config);
    }

    async resetEdit(editName: Edit): Promise<void> {
        const appliedEditConfig =  this._appliedEditsConfigStack[this._appliedEditsConfigStack.length - 1];

        if(!appliedEditConfig) {
            return Promise.reject("No edit is applied");
        }

        if (appliedEditConfig.editName !== editName) {
            return Promise.reject(`Edit ${editName} can't be reset as it is not latest edit`);
        }

        const contextId = appliedEditConfig.config?.docEditingContext.contextId;
        switch (contextId) {
            case ELStageDocEditingContextId.document: {
                const topLayer = this.doc.getTopLayer();
                if (topLayer) {
                    this.doc.notify({ type: ELStageDocActions.removeLayer, payload: { layerId: topLayer.getId(), redraw: true } });
                    this._appliedEditsConfigStack.pop();
                }
                break;
            }
            default:
                Promise.reject(`Unsupported contextId: ${contextId}`);
        }
    }
}
