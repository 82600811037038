/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { ELAdobeAsset, OutputType, Resolution } from "@elements/elementswebcommon";

//Application Specific
import ICreationsFullAssetProvider from "./ICreationsFullAssetProvider";
import CreationUtils from "../CreationUtils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import PatternOverlayUtils from "../../workflows/pattern-overlay/utils/PatternOverlayUtils";
import { PatternOverlayJobCreator } from "../../workflows/pattern-overlay/utils/PatternOverlayJobCreator";
import { ELPatternOverlayCreationRequestParams } from "../../../../common/interfaces/creations/ELPatternOverlayTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import ImageUtils from "../../../../utils/ImageUtils";
import { ELCreationDocumentDetails } from "../../../../common/interfaces/creations/CreationTypes";

class ELPatternOverlayFullAssetProvider extends ICreationsFullAssetProvider {

    private async _getLayoutData(asset: ELAdobeAsset): Promise<ELCreationDocumentDetails | undefined> {
        try {
            const projectData = await CreationUtils.getProjectData(this.creationsData.id);
            let documentDetails: ELCreationDocumentDetails | undefined = undefined;
            if (asset.assetId && projectData?.outputs) {
                const imageUrl = await AssetStorageUtils.getAndStoreAssetData(asset.assetId);
                const imageSize = await ImageUtils.getImageSizeFromURL(imageUrl);

                const outputConfigJson = await CreationUtils.getJsonFromProjectData(projectData);

                const layoutDetails = await PatternOverlayUtils.getLayoutDetails(outputConfigJson, imageSize);
                if (layoutDetails) {
                    documentDetails = {
                        layoutInfo: layoutDetails,
                        size: imageSize
                    }
                }
            }
            return Promise.resolve(documentDetails);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELPatternOverlayFullAssetProvider: _getLayoutData failed with error: " + error);
            return Promise.reject(error);
        }
    }

    protected async createMasterAssetEditRequestBody(): Promise<unknown> {
        let asset: ELAdobeAsset;
        try {
            asset = await CreationUtils.getOriginalAssetForSingleAssetBasedProject(this.creationsData.id);
        } catch (error) {
            const message = IntlHandler.getInstance().formatMessage("creation-asset-not-found");
            ToastUtils.error(message);
            return Promise.reject(error);
        }

        try {
            const title = this.creationsData.title;
            const outputJson = await CreationUtils.getJsonFromProjectData(this.creationsData);
            const mapOfOverlayIdAssetPath = await PatternOverlayUtils.getRecommendedAssetsIdForOverlays(outputJson);

            let assetIdSelected: string | undefined;
            let selectedOverlayId: string | undefined;

            mapOfOverlayIdAssetPath.forEach((assetId, overlayId) => {
                assetIdSelected = assetId;
                selectedOverlayId = overlayId;
            });
            if (assetIdSelected && selectedOverlayId) {
                const requestJson = await this._getRequestBodyForOpenInDesktop(asset, selectedOverlayId, title, await this._getLayoutData(asset));
                return Promise.resolve(requestJson);
            } else {
                Logger.log(LogLevel.ERROR,
                    "ELPatternOverlayFullAssetProvider:createMasterAssetEditRequestBody, not able to get (assetIdSelected && selectedOverlayId)");
                return Promise.reject();
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELPatternOverlayFullAssetProvider: createMasterAsset failed with error: " + error);
            return Promise.reject(error);
        }
    }

    private async _getRequestBodyForOpenInDesktop(asset: ELAdobeAsset, overlayId: string, title?: string, documentDetails?: ELCreationDocumentDetails): Promise<unknown> {
        const requestCreatorObj = new PatternOverlayJobCreator();
        const projectRequestParams: ELPatternOverlayCreationRequestParams = {
            assets: [asset],
            overlayIds: [overlayId],
            title: title,
            outputTypes: [OutputType.preview, OutputType.full],
            contentResolution: Resolution._1080p,
            documentDetails: documentDetails
        };
        try {
            const requestJson = await requestCreatorObj.getRequestBody(projectRequestParams);
            return Promise.resolve(requestJson);
        } catch (error) {
            Logger.log(LogLevel.ERROR,
                "ELPatternOverlayFullAssetProvider:_getRequestBodyForOpenInDesktop, PatternOverlay project edit failed!", error);
            return Promise.reject();
        }
    }
}

export default ELPatternOverlayFullAssetProvider;