/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application specific
import ITemplateViewController from "../../../ITemplateViewController";
import { ViewAction } from "../../../IBaseController";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ELFeedbackDialogView } from "./ELFeedbackDialogView";
import { ELUserFeedbackAction, FeedbackIconVariant } from "../../../../common/interfaces/creations/templates/ELFeedbackDialogTypes";

export class ELFeedbackDialog extends ITemplateViewController {

    private _feedbackText: string;
    private _variant: FeedbackIconVariant;

    constructor(owner: IViewController, feedbackText: string, variant: FeedbackIconVariant) {
        super(owner);
        this._owner = owner;
        this._feedbackText = feedbackText;
        this._variant = variant;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const userFeedbackDialogView = React.createElement(ELFeedbackDialogView, {
            controller: this,
            text: this._feedbackText,
            variant: this._variant
        });
        ReactDOM.render(userFeedbackDialogView, container);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELUserFeedbackAction.clickedYes: {
                handled = await this._owner.notify({
                    type: ELUserFeedbackAction.clickedYes,
                });
                break;
            }
            case ELUserFeedbackAction.clickedNo: {
                handled = await this._owner.notify({
                    type: ELUserFeedbackAction.clickedNo,
                });
                break;
            }
            default:
                break;
        }
        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }
}