/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Application Specific
import IViewController, { ControllerAction } from "../../../IViewController";
import SlideshowAudioView from "./SlideshowAudioView";
import { ELSlideshowAudioViewAction } from "../../../../common/interfaces/creations/SlideshowTypes";
import { ViewAction } from "../../../IBaseController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import BaseContentService, { ContentType } from "../../../../services/ElementsServices/BaseContentService";
import { SlideshowAudioData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import store from "../../../../stores/store";
import ITemplateViewController from "../../../ITemplateViewController";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";

export default class SlideshowAudio extends ITemplateViewController {
	private _contentService: BaseContentService;
	private _audioList: SlideshowAudioData[] = [];

	constructor(owner: IViewController, contentService: BaseContentService) {
		super(owner);

		this._contentService = contentService;
		this._fetchAudioList();
	}

	private async _fetchAudioList(): Promise<void> {
		try {
			this._audioList = await this._contentService.getContent(ContentType.slideshowAudio) as SlideshowAudioData[];
			this._updateAudioList();
		} catch (error) {
			Logger.log(LogLevel.WARN, "SlideshowAudio:_fetchAudioList: ", error);
		}
	}

	private _updateAudioList(): void {
		if (this.viewDispatcher) {
			this.viewDispatcher({ type: ELSlideshowAudioViewAction.audioData, payload: this._audioList });
		}
	}

	get getAudioList(): SlideshowAudioData[] {
		return this._audioList;
	}

	createView(container: HTMLElement): void {
		super.createView(container);

		const audioView = React.createElement(SlideshowAudioView, { controller: this });
		const reactReduxProviderHydratedAudioView = React.createElement(Provider, { store: store }, audioView);

		ReactDOM.render(reactReduxProviderHydratedAudioView, container);
	}

	destroyView(): void {
		if (this.container) {
			ReactDOM.unmountComponentAtNode(this.container);
		}
		super.destroyView();
	}

	initialize(dispatch?: React.Dispatch<ViewAction>): void {
		super.initialize(dispatch);
		this._updateAudioList();
	}

	destroy(): void {
		super.destroy();
	}

	async notify<T extends ControllerAction>(action: T): Promise<boolean> {
		const handled = false;
		switch (action.type) {
			case WorkflowActionType.ingest:
				{
					this._owner.notify({
						type: WorkflowActionType.ingest,
						payload: action?.payload
					});
					break;
				}
			default: {
				Logger.log(LogLevel.WARN, "SlideshowAudio(notify) - Invalid action type", action);
				break;
			}
		}
		return handled;
	}
}
