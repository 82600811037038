/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { fabric } from "fabric";

//Application Specific
import { ELCheckBoardPatternConfig, ELPatternType } from "../../../common/interfaces/stage/ELFabricPatternTypes";

export default class ELPatternCreator {
    private _createCheckBoardPattern(config: ELCheckBoardPatternConfig): Promise<fabric.Pattern> {
        return new Promise((resolve, reject) => {
            const squareSize = config.squareSize;
            const patternCanvas = document.createElement('canvas');
            patternCanvas.width = squareSize * 2;
            patternCanvas.height = squareSize * 2;
            const patternCtx = patternCanvas.getContext('2d');
            if (patternCtx) {
                for (let rowIndex = 0; rowIndex < 2; rowIndex++) {
                    for (let columnIndex = 0; columnIndex < 2; columnIndex++) {
                        patternCtx.fillStyle = (rowIndex + columnIndex) % 2 === 0 ? '#ffffff' : "#cccccc";
                        patternCtx.fillRect(rowIndex * squareSize, columnIndex * squareSize, squareSize, squareSize);
                    }
                }
                const patternImage = new Image();
                patternImage.src = patternCanvas.toDataURL();
                patternImage.onload = () => {
                    const pattern = new fabric.Pattern({
                        source: patternImage,
                        repeat: 'repeat'
                    });
                    resolve(pattern);
                }
                patternCanvas.onerror = (error) => {
                    reject(error);
                }
            } else {
                reject("Couldn't get Pattern context");
            }
        });
    }

    getPattern(type: ELPatternType, config: unknown): Promise<fabric.Pattern> {
        switch (type) {
            case ELPatternType.checkBoard:
            default:
                return this._createCheckBoardPattern(config as ELCheckBoardPatternConfig);
        }
    }
}