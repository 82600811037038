/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';
import { RepoDirListData } from "@elements/elementswebcommon";
import { AdobeAsset } from '@dcx/assets';

export interface MediaOrganizerActionPayloadUnion {
    stateKey: string,
    data: void | RepoDirListData | AdobeAsset
}

export interface MediaOrganizerActionPayload<T> {
    stateKey: string,
    data: T
}

export default class MediaOrganizerAction {

    public static readonly UPDATE_DIRECTORY_DATA: string = "MediaOrganizerAction.UPDATE_DIRECTORY_DATA";
    public static readonly APPEND_DIRECTORY_CHILDREN: string = "MediaOrganizerAction.APPEND_DIRECTORY_CHILDREN";
    public static readonly REMOVE_ASSET: string = "MediaOrganizerAction.REMOVE_ASSET";
    public static readonly RENAME_ASSET: string = "MediaOrganizerAction.RENAME_ASSET";
    public static readonly RESET_DIR: string = "MediaOrganizerAction.RESET_DIR";

    public static updateDirectory(dirPath: string, dirData: RepoDirListData): IAction<MediaOrganizerActionPayload<RepoDirListData>> {
        return {
            type: MediaOrganizerAction.UPDATE_DIRECTORY_DATA,
            payload: { stateKey: dirPath, data: dirData }
        };
    }

    public static appendChildren(dirPath: string, dirData: RepoDirListData): IAction<MediaOrganizerActionPayload<RepoDirListData>> {
        return {
            type: MediaOrganizerAction.APPEND_DIRECTORY_CHILDREN,
            payload: { stateKey: dirPath, data: dirData }
        };
    }

    public static removeAsset(dirPath: string, asset: AdobeAsset): IAction<MediaOrganizerActionPayload<AdobeAsset>> {
        return {
            type: MediaOrganizerAction.REMOVE_ASSET,
            payload: { stateKey: dirPath, data: asset }
        };
    }

    public static renameAsset(dirPath: string, asset: AdobeAsset): IAction<MediaOrganizerActionPayload<AdobeAsset>> {
        return {
            type: MediaOrganizerAction.REMOVE_ASSET,
            payload: { stateKey: dirPath, data: asset }
        };
    }

    public static reset(dirPath: string): IAction<MediaOrganizerActionPayload<void>> {
        return {
            type: MediaOrganizerAction.RESET_DIR,
            payload: { stateKey: dirPath, data: undefined }
        }
    }
}