/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELCollageRenderedShapesName } from "../../common/interfaces/creations/collage/ELCollageRendererTypes";
import { CSAssetWithData } from "../../common/interfaces/creations/CreationTypes";
import { CSAssetEditInfo, CSAssetTransformInfo, ELCollageAssetInfo, ELCollageLayout, ELCollageTemplate } from "../../common/interfaces/creations/ELCollageTypes";
import { ELPIEPoint, ELPIEQuad } from "../../common/interfaces/geometry/ELGeometry";
import { RenderedShapesName } from "../../common/interfaces/renderer/RendererTypes";
import {
    ELStageBackgroundOptions,
    ELStageObjectData,
    ELStageObjectOptions,
    ELStageShapeAndObjectOptions,
    ELStageShapesType
} from "../../common/interfaces/stage/StageTypes";
import { IntlHandler } from "../../modules/intlHandler/IntlHandler";
import CollageUtils from "../../workspaces/creations/workflows/collage/utils/CollageUtils";
import IDocDataParser from "../document/parser/IDocDataParser";
import ShapesFactory from "../stage/shapes/ShapesFactory";
import ELCollageDoc from "./ELCollageDoc";

export default class ELCollageDocDataParser extends IDocDataParser {

    private _createImageArea(template: ELCollageTemplate): ELStageShapeAndObjectOptions {
        const shape = ShapesFactory.createShape(ELStageShapesType.imageArea);

        const backgroundOptions: ELStageBackgroundOptions = {
            name: RenderedShapesName.imageArea,
            width: template.bounds.width,
            height: template.bounds.height,
            background: CollageUtils.getStageBackground(template.background)
        };
        const stageAndObjectOptions: ELStageShapeAndObjectOptions = { shape: shape, objectOptions: backgroundOptions };

        return stageAndObjectOptions;
    }

    private _getErrorObjectList(options: ELStageObjectOptions): ELStageShapeAndObjectOptions[] {
        const intlHandler = IntlHandler.getInstance();
        const errorObjectAndOptionsList: ELStageShapeAndObjectOptions[] = [];

        const headerTextShape = ShapesFactory.createShape(ELStageShapesType.text);
        const headerTextOptions = {
            ...options,
            text: intlHandler.formatMessage("media-not-found"),
            hoverCursor: "default",
            fill: "black",
            top: options.top ? options.top - 75 : options.top,
            fontSize: 20,
            originX: 'center',
            originY: 'center'
        };
        errorObjectAndOptionsList.push({
            shape: headerTextShape,
            objectOptions: headerTextOptions,
        });

        const subTextBackgroundShape = ShapesFactory.createShape(ELStageShapesType.rect);
        const subTextBackgroundOptions = {
            ...options,
            isSubTarget: true,
            rx: 25,
            ry: 25,
            fill: "lightgrey",
            width: 145,
            height: 40,
            stroke: "#5151D3",
            strokeWidth: 2,
            top: options.top ? options.top - 40 : options.top,
            left: options.left ? options.left - 120 : options.left
        };
        errorObjectAndOptionsList.push({
            shape: subTextBackgroundShape,
            objectOptions: subTextBackgroundOptions,
        });

        const subTextShape = ShapesFactory.createShape(ELStageShapesType.text);
        const subTextOptions = {
            ...options,
            text: intlHandler.formatMessage("select-photo"),
            fill: '#5151D3',
            isSubTarget: true,
            fontSize: 20,
            originX: 'center',
            originY: 'center'
        };
        errorObjectAndOptionsList.push({
            shape: subTextShape,
            objectOptions: subTextOptions,
        });

        const iconShape = ShapesFactory.createShape(ELStageShapesType.path);
        const iconShapeOptions = {
            ...options,
            hoverCursor: "default",
            left: options.left ? options.left - 25 : options.left,
            top: options.top ? options.top - 150 : options.top,
            addToStage: false,
            idth: 50,
            height: 50,
            fill: "black",
            svgPath: "M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM7.7,3.3875A.35552.35552,0,0,1,7.8685,3.05,3.12313,3.12313,0,0,1,8.981,2.821a3.4307,3.4307,0,0,1,1.116.172.38849.38849,0,0,1,.2.3435v1.225c0,1.4425-.2885,5.4455-.3415,5.9735a.26351.26351,0,0,1-.26528.26171L9.662,10.795H8.3a.284.284,0,0,1-.289-.2365c-.05-.682-.311-4.55-.311-5.9455ZM9,14.425a1.287,1.287,0,0,1-1.4-1.3155,1.33,1.33,0,0,1,1.308-1.35167q.046-.00076.092.00167a1.316,1.316,0,0,1,1.3978,1.22877q.00389.06054.0022.12123A1.287,1.287,0,0,1,9,14.425Z"
        };
        errorObjectAndOptionsList.push({
            shape: iconShape,
            objectOptions: iconShapeOptions,
        });

        return errorObjectAndOptionsList;
    }

    private _createImageLayouts(layouts: ELCollageLayout[], assetInfoList: ELCollageAssetInfo[]): ELStageShapeAndObjectOptions[] {
        const stageAndObjectOptionsList: ELStageShapeAndObjectOptions[] = [];

        layouts.forEach((layout, index) => {
            const clipShape = ShapesFactory.createShape(ELStageShapesType.path);
            const clipObjectOptions: ELStageObjectOptions = {
                name: ELCollageRenderedShapesName.slot,
                svgPath: layout.svgPath,
                width: layout.bounds.width,
                height: layout.bounds.height,
                left: layout.bounds.left,
                top: layout.bounds.top,
                fill: "lightgrey",
                selectable: false,
                strokeStyle: layout.stroke,
                addToStage: true
            };

            const shape = ShapesFactory.createShape(ELStageShapesType.imageFromURI);

            const shapeData: ELStageObjectData = { payload: { asset: assetInfoList[index].asset, layoutId: index }, transformedToImageArea: true, allowDrop: true };
            const errorShapesAndOptionsList = this._getErrorObjectList({
                ...clipObjectOptions,
                data: shapeData,
                name: RenderedShapesName.imageLoadError,
                strokeStyle: undefined,
                addToStage: false
            });
            shape.setErrorShapeAndOptionsList = errorShapesAndOptionsList;

            const assetEditInfo = assetInfoList[index].edit;
            const objectOptions: ELStageObjectOptions = {
                name: ELCollageRenderedShapesName.image,
                data: shapeData,
                image: assetInfoList[index].asset.objectURL ?? "",
                width: assetEditInfo ? assetEditInfo.transform.quadrilateral.left_bottom : layout.bounds.width,
                height: assetEditInfo ? assetEditInfo.transform.quadrilateral.right_bottom : layout.bounds.height,
                left: assetEditInfo ? assetEditInfo.transform.offset.horizontal : layout.bounds.left,
                top: assetEditInfo ? assetEditInfo.transform.offset.vertical : layout.bounds.top,
                fill: "lightgrey",
                selectable: true,
                fitToClipShape: assetEditInfo === undefined,
                scaleX: assetEditInfo?.transform.scale.horizontal,
                scaleY: assetEditInfo?.transform.scale.vertical,
                angle: assetEditInfo?.transform.rotate
            };

            const stageAndObjectOptions: ELStageShapeAndObjectOptions = {
                shape: shape,
                objectOptions: objectOptions,
                clipShape: clipShape,
                clipObjectOptions: clipObjectOptions
            };
            stageAndObjectOptionsList.push(stageAndObjectOptions);
        });


        return stageAndObjectOptionsList;
    }

    async parseDocData(): Promise<ELStageShapeAndObjectOptions[]> {
        const collageDoc = this.doc as ELCollageDoc;
        const template = collageDoc.getTemplate;

        const stageAndObjectsOptions: ELStageShapeAndObjectOptions[] = [];
        stageAndObjectsOptions.push(this._createImageArea(template));
        stageAndObjectsOptions.push(...this._createImageLayouts(template.layouts, collageDoc.getAssetInfoList));

        return stageAndObjectsOptions;
    }

    parseDocUpdatedData(data: unknown): unknown {
        const objectList = data as fabric.Object[];
        const assetsInfoList: ELCollageAssetInfo[] = [];

        objectList.forEach((object) => {
            const { width, height, top, left, scaleX, scaleY, angle, data, name } = object;

            if (name !== ELCollageRenderedShapesName.image) {
                return;
            }

            const quadrilateral: ELPIEQuad = {
                left_top: left ?? 0,
                right_top: top ?? 0,
                left_bottom: width ?? 0,
                right_bottom: height ?? 0
            }

            const scale: ELPIEPoint = {
                horizontal: scaleX ?? 1,
                vertical: scaleY ?? 1
            }

            const offset: ELPIEPoint = {
                horizontal: left ?? 0,
                vertical: top ?? 0
            }

            const rotate = angle ?? 0;

            const assetTransformInfo: CSAssetTransformInfo = {
                quadrilateral: quadrilateral,
                scale: scale,
                rotate: rotate,
                offset: offset
            }

            const assetEditInfo: CSAssetEditInfo = {
                transform: assetTransformInfo
            }

            const assetData = data.payload as CSAssetWithData;
            const assetInfo: ELCollageAssetInfo = { asset: assetData };
            assetInfo.edit = assetEditInfo;

            assetsInfoList.push(assetInfo);
        });

        return assetsInfoList;
    }
}
