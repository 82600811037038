/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Adobe Internal
import { DialogContainer } from "@adobe/react-spectrum";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import { ELRenameMediaProps, ELRenameMediaView } from "./ELRenameMediaView";
import IViewController from "../../../IViewController";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

export class ELRenameMedia extends IViewController {
    private _onSave: (title: string) => void;
    private _dialogHeading: string;
    private _defaultTitle: string;
    private _thumbURL?: string;
    private _isValidTitle?: (title: string) => boolean;
    private _onCancel?: () => void;

    constructor(props: ELRenameMediaProps) {
        super();
        this._onSave = props.onSave;
        this._dialogHeading = props.dialogHeading;
        this._defaultTitle = props.defaultTitle;
        this._thumbURL = props.thumbURL;
        this._isValidTitle = props.isValidTitle;
        this._onCancel = props.onCancel;
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const renameDialog = React.createElement(ELRenameMediaView, {
            onSave: this._onSave,
            dialogHeading: this._dialogHeading,
            defaultTitle: this._defaultTitle,
            thumbURL: this._thumbURL,
            isValidTitle: this._isValidTitle,
            onCancel: this._onCancel
        });
        // eslint-disable-next-line react/no-children-prop
        const renameDialogWithContainer = React.createElement(DialogContainer, { onDismiss: this.destroyView.bind(this), children: renameDialog });
        const providerHydratedContainer = React.createElement(ELReactSpectrumV3Provider, undefined, renameDialogWithContainer);
        ReactDOM.render(providerHydratedContainer, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }
}