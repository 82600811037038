/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import ICreationsHeader from "./ICreationsHeader";
import store from "../../../../stores/store";
import { ControllerAction } from "../../../IViewController";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { WorkspaceActionType } from "../../../../workspaces/IBaseWorkspace";
import { WorkflowAction } from "../../../../workspaces/IWorkflow";
import ELMovingOverlayHeaderView from "./ELMovingOverlayHeaderView";
import { CreationsData, CreationsJobProjectSubType } from "../../../../common/interfaces/creations/CreationTypes";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";

export default class ELMovingOverlayHeader extends ICreationsHeader {
    async createView(container: HTMLElement): Promise<void> {
        super.createView(container);

        const element = React.createElement(ELMovingOverlayHeaderView, {
            controller: this,
            shareOptionController: this.shareOptions
        });
        const provider = this.getReactProvider(element, { history: true, store: true });

        return new Promise((resolve) => {
            ReactDOM.render(
                provider,
                container,
                () => { resolve(); });
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELCreationsHeaderControllerAction.changeName:
                {
                    store.dispatch(RecommendationWorkflowAction.updateProjectTitle(action.payload as string));
                    this._renameCreation(action.payload as string);
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.back:
                {
                    handled = await this._owner.notify({
                        type: WorkspaceActionType.startPreviousWorkflow,
                    });
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                        IngestEventTypes.click, IngestEventSubTypes.back, CreationsJobProjectSubType.movingOverlay));
                    break;
                }
            case ELCreationsHeaderControllerAction.updateCreationsData:
                {
                    this._updateCreationsData(action.payload as CreationsData);
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.openInDesktop:
                {
                    this._owner.notify({ type: ELCreationsHeaderControllerAction.openInDesktop });
                    handled = true;
                    break;
                }
        }

        if (!handled)
            handled = await super.notify(action);

        if (!handled)
            handled = await this._owner.notify(action as WorkflowAction);

        return handled;
    }
} 