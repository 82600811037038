/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { RenderedStageData, RendererUpdateData } from "../../common/interfaces/renderer/RendererTypes";
import { CanvasViewAction, CanvasZoomLevelAction, ELLayoutPanelControllerAction, ELStageObject, ELStageObjectOptions, ELStageShapeAndObjectOptions } from "../../common/interfaces/stage/StageTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import Utils from "../../utils/Utils";
import { ControllerAction } from "../../view/IViewController";
import IDocDataParser from "../document/parser/IDocDataParser";
import IGraphicsStage from "../stage/IGraphicsStage";
import IStageShapes from "../stage/shapes/IStageShapes";

export enum RendererType {
    collageDoc = "COLLAGE_DOC",
    adobeAsset = "ADOBE_ASSET",
    peekThrough = "PEEK_THROUGH",
    stageDoc = "STAGE_DOC",
    pie = "PIE"
}

export default abstract class IRenderer {
    protected stage: IGraphicsStage;
    protected dataParser: IDocDataParser;
    protected stageDataList: RenderedStageData[];
    protected renderError: boolean;

    constructor(stage: IGraphicsStage, dataParser: IDocDataParser) {
        this.stage = stage;
        this.dataParser = dataParser;
        this.stageDataList = [];
        this.renderError = false;
    }

    protected async updateShape(shape: IStageShapes, options: ELStageObjectOptions): Promise<ELStageObject> {
        const updatedObject = await shape.update(this.stage, options);

        if (this.renderError) {
            try {
                await shape.drawOrRemoveError(this.stage, options);
            } catch (error) {
                Logger.log(LogLevel.WARN, "IRenderer - (updateShape) error in drawing errorShape", shape, error);
            }
        }
        return updatedObject;
    }

    protected async drawShapes(stageShapeAndObjectOptionsList: ELStageShapeAndObjectOptions[]): Promise<ELStageObject[]> {
        const stageObjectList: ELStageObject[] = [];

        for (let index = 0; index < stageShapeAndObjectOptionsList.length; index++) {
            const stageShapeAndObjectOption = stageShapeAndObjectOptionsList[index];
            if (!stageShapeAndObjectOption.clipShape || !stageShapeAndObjectOption.clipObjectOptions) {
                const object = await stageShapeAndObjectOption.shape.draw(this.stage, stageShapeAndObjectOption.objectOptions);
                stageObjectList.push(object);

                if (this.renderError) {
                    await stageShapeAndObjectOption.shape.drawOrRemoveError(this.stage, stageShapeAndObjectOption.objectOptions);
                }
            } else {
                const clipShape = await stageShapeAndObjectOption.clipShape.draw(this.stage, stageShapeAndObjectOption.clipObjectOptions);
                stageShapeAndObjectOption.objectOptions.clipPath = clipShape;

                const object = await stageShapeAndObjectOption.shape.draw(this.stage, stageShapeAndObjectOption.objectOptions);
                stageObjectList.push(object);

                if (this.renderError) {
                    await stageShapeAndObjectOption.shape.drawOrRemoveError(this.stage, stageShapeAndObjectOption.objectOptions);
                }
            }

            //HACK_FIX : ELWEB-800, ELWEB-691 -  (issue)image shifting on opening for collages with image in image area
            if (index === 0) {
                await Utils.wait(200);
            }
        }

        return Promise.resolve(stageObjectList);
    }

    protected async addShape(stageShapeAndObjectOptions: ELStageShapeAndObjectOptions, layerIndex?: number): Promise<ELStageObject> {
        const stageObject = await stageShapeAndObjectOptions.shape.draw(this.stage, stageShapeAndObjectOptions.objectOptions, layerIndex);
        return stageObject;
    }

    protected async createCanvas(container: HTMLElement): Promise<void> {
        if (!this.stage.getCanvas())
            await this.stage.createView(container);
    }

    set setRenderError(renderError: boolean) {
        this.renderError = renderError;
    }

    hasRenderingError(): boolean {
        return false;
    }

    destroy(): void {
        Logger.log(LogLevel.INFO, this, " renderer getting destroyed");
        this.stageDataList = [];
        return;
    }

    abstract update(updateData: RendererUpdateData): Promise<void>;

    async render(container: HTMLElement): Promise<void> {
        await this.createCanvas(container);
    }

    saveStageData(stageShapeAndObjectOptionsList: ELStageShapeAndObjectOptions[], stageObjectList: ELStageObject[]): void {
        if (stageShapeAndObjectOptionsList.length !== stageObjectList.length) {
            Logger.log(LogLevel.WARN, "stageShapeAndObjectOptionsList and stageObjectList length are not matching");
        }

        for (let index = 0; index < stageObjectList.length && index < stageShapeAndObjectOptionsList.length; index++) {
            const object = stageObjectList[index];
            const renderedData: RenderedStageData = {
                stageShapeAndObjectOptions: stageShapeAndObjectOptionsList[index],
                object: object
            }
            this.stageDataList.push(renderedData);
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CanvasViewAction.sendDocumentCreatedUpdate:
            case CanvasViewAction.addCanvasChangedHandlers:
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent:
            case CanvasZoomLevelAction.changeZoomValue:
            case CanvasZoomLevelAction.zoomToFill:
            case CanvasZoomLevelAction.zoomToFit:
            case ELLayoutPanelControllerAction.scale:
            case ELLayoutPanelControllerAction.change:
            case ELLayoutPanelControllerAction.commit:
            case ELLayoutPanelControllerAction.cancel:
            case ELLayoutPanelControllerAction.revert:
                handled = await this.stage.notify(action);
                break;
        }
        return handled;
    }

    async setLayerVisible(payload: unknown): Promise<void> {
        throw new Error("Method not implemented!");
    }

    async setLayerFill(payload: unknown): Promise<void> {
        throw new Error("Method not implemented!");
    }

    async setLayerOpacity(payload: unknown): Promise<void> {
        throw new Error("Method not implemented!");
    }

    async setLayerTextOptions(payload: unknown): Promise<void> {
        throw new Error("Method not implemented!");
    }

    async setClipPathOptions(payload: unknown): Promise<void> {
        throw new Error("Method not implemented!");
    }
}
