/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "./IAction";


export default class AppAction {

    public static readonly SET_SESSION_ID: string = "AppActions.SET_SESSION_ID";
    public static readonly SET_DAYS_IN_TRIAL: string = "AppActions.SET_DAYS_IN_TRIAL";

    public static setSessionId(sessionId: string): IAction<string> {
        return {
            type: AppAction.SET_SESSION_ID,
            payload: sessionId
        };
    }

    public static setDaysInTrial(daysInTrial: number): IAction<number> {
        return {
            type: AppAction.SET_DAYS_IN_TRIAL,
            payload: daysInTrial
        };
    }
}