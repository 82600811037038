/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import App from "../app/App";
import IBaseWorkspace from "./IBaseWorkspace";
import IWorkflow from "./IWorkflow";

export default abstract class IWorkspace extends IBaseWorkspace {
    protected _owner: App;

    constructor(owner: App) {
        super();
        this._owner = owner;
    }

    get getActiveWorkflow(): IWorkflow | undefined {
        if (this.modalWorkspace)
            return this.modalWorkspace.getWorkflow;

        return this.currentWorkflow;
    }

    get getNonModalWorkspace(): IWorkspace {
        return this;
    }
}
