/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { UrlOpenTargets } from "./Constants/Constants";

export class WindowUtils {
    /**
     * @param  {url} string
     * @param {target} UrlOpenTargets
     * @returns void
     */
    static open(url: string, target = UrlOpenTargets.self): void {
        window.open(url, target);
    }

    static reload(): void {
        window.location.reload();
    }
}