/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';

export default class UploadedMediaListAction {
    public static readonly APPEND_MEDIA: string = 'UploadedMediaListAction.APPEND_MEDIA';
    public static readonly CLEAR_MEDIA: string = 'UploadedMediaListAction.CLEAR_MEDIA';

    public static appendMedia(uploadedMedia: string): IAction<string> {
        return {
            type: UploadedMediaListAction.APPEND_MEDIA,
            payload: uploadedMedia
        };
    }

    public static clearMedia(): IAction<string> {
        return {
            type: UploadedMediaListAction.CLEAR_MEDIA
        };
    }
}
