/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum ELDeeplinkActions {
    expertDefault = "EXPERT_DEEPLINK_WORKFLOW",
    guidedDefault = "GUIDED_DEEPLINK_WORKFLOW",
    tryThisDefault = "TRY_THIS_DEEPLINK_WORKFLOW"
}

export enum ELDeeplinkActionParams {
    action = "action",
    assetId = "assetId",
    subRoomIndex = "subRoomIndex",
    targetName = "targetName",
    subAction = "subAction",
    source = "source"
}

export const WEB_APP_SOURCE_NAME = "el-web";
