/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";

//Application Specific
import IViewController from "../../../IViewController";
import { IconType } from "../../../../assets/IconConstants";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { ELNavBarControllerAction } from "../../../../common/interfaces/navbar/ELNavBarTypes";
import { Theme } from "../../../../utils/Theme";

import "./ELWhatsNewNavView.scss";

interface ELWhatsNewNavProps {
    controller: IViewController,
    shouldShowWhatsNewIcon: boolean;
}

const ELWhatsNewNavView = (props: ELWhatsNewNavProps): React.ReactElement => {
    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    const handleWhatsNewClick = (): void => {
        props.controller.notify({ type: ELNavBarControllerAction.whatsNewIconClick });
    };

    return (
        props.shouldShowWhatsNewIcon ? (
            <div className="whatsnew-icon__box" data-testid="whatsnew-icon__box">
                <ELCustomIcon variant="independent" iconkey={IconType.whatsNewIcon}
                    color={Theme.global.gray_controls_color_N} hoverColor={Theme.global.gray_controls_color_H}
                    width={"1.6rem"} height={"1.6rem"} cursor={"pointer"} onPress={handleWhatsNewClick} />
            </div>
        ) : <></>
    );
};

export default ELWhatsNewNavView;