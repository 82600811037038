/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Grid, repeat } from "@adobe/react-spectrum";

//Application Specific
import { ELReplaceBackgroundContentPanel } from "./ELReplaceBackgroundContentPanel";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import { TRANSPARENT_BACKGROUND_ID, TRANSPARENT_BACKGROUND_NAME } from "../../../../common/interfaces/creations/CreationTypes";
import { RootState } from "../../../../stores/store";
import { ELPreviewCreationThumbData, ELRecommendationWorkflowControllerActions } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { ELTransparentCard } from "../../organism/el-transparent-card/ELTransparentCard";

import "./ELReplaceBackgroundContentPanel.scss";

interface ELReplaceBackgroundPanelViewProps {
    controller: ELReplaceBackgroundContentPanel
}

const ELReplaceBackgroundContentPanelView = (props: ELReplaceBackgroundPanelViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    const specLayoutConfig = { TILE_W: 5, TILE_H: 5, DIST_BETWEEN_TILE: 1 };
    const gridRef = useRef(null);
    const scrollParentRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: scrollParentRef,
        gridRef: gridRef
    });

    const recommendationWorkflowReducer = useSelector((state: RootState) => state.recommendationWorkflowReducer);

    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller])

    const transparentCardClicked = (): void => {
        const payload: ELPreviewCreationThumbData = { id: TRANSPARENT_BACKGROUND_ID, name: TRANSPARENT_BACKGROUND_NAME };
        props.controller.notify({ type: ELRecommendationWorkflowControllerActions.overlayClicked, payload });
    }

    return (
        <div className="replace-background-content-panel-box">
            <ELPanelHeader title={intlHandler.formatMessage("background")} alignTitle="center" />
            <ELScrollPanel forwardedRef={scrollParentRef} UNSAFE_className="replace-background-content-panel-scroll" scrollY={true} width="100%">
                <div className="replace-background-content-panel-content-box">
                    <div ref={gridRef} >
                        <Grid marginTop={"1rem"} columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                            justifyContent="center"
                            autoRows={viewLayoutConfig.TILE_W}
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                            <ELTransparentCard width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H} onPress={() => transparentCardClicked()}
                                selected={TRANSPARENT_BACKGROUND_ID === recommendationWorkflowReducer.selectedOverlayId} />
                        </Grid>
                    </div>
                </div>
            </ELScrollPanel>
        </div>
    )
}

export { ELReplaceBackgroundContentPanelView };