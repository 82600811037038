/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { API } from "@elements/elementswebcommon";

//Application Specific
import BaseContentService, {ContentType} from "./BaseContentService";
import { CollageTemplateData, CollageBackgroundData, SlideshowAudioData, SlideshowTemplateData, ASSETS_ENDPOINT } from "../../common/interfaces/creations/ElementsContentTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import { LocaleType } from "../../common/interfaces/intl/LocaleTypes";

const ContentTypeToContentRootPathMap: Map<ContentType, string> = new Map([
    [ContentType.slideshowTemplate, "creations"],
    [ContentType.slideshowAudio, "creations"],
    [ContentType.collageTemplates, "photocollage"],
    [ContentType.collageBackground, "photocollage"],
    [ContentType.collageDefaultBackground, "photocollage"]
]);

export default class ElementsContentService extends BaseContentService {
    private static _instance: ElementsContentService | null = null;
    private _locale: string = LocaleType.enUS;
    
    static getInstance(): ElementsContentService {
        if (ElementsContentService._instance === null)
            ElementsContentService._instance = new ElementsContentService();
        return ElementsContentService._instance;
    }

    private constructor() {
        super();
        this._client = new API(`${process.env.REACT_APP_ELEMENTS_URL}`);
    }

    public initialize(locale: string): void {
        this._locale = locale;
    }

    private _getConfigPath(contentType: ContentType): string {
        const ASSETS_ENDPOINT = "assets";
        const INDEX_JSON = "index.json";
        const root = ContentTypeToContentRootPathMap.get(contentType);

        if (this._locale === LocaleType.enUS) {
            return `${ASSETS_ENDPOINT}/${root}/${INDEX_JSON}`;
        } else {
            return `${ASSETS_ENDPOINT}/${root}/${this._locale}/${INDEX_JSON}`;
        }
    }

    private async _getSlideshowTemplate(): Promise<SlideshowTemplateData[]> {
        try {
            const creationsContent = await this._client.get(this._getConfigPath(ContentType.slideshowTemplate));
            return Promise.resolve(creationsContent.data.slideshowTemplate.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsContentService:_getSlideshowTemplate: ", error);
            return Promise.reject();
        }
    }

    private async _getSlideshowAudio(): Promise<SlideshowAudioData[]> {
        try {
            const creationsContent = await this._client.get(this._getConfigPath(ContentType.slideshowAudio));
            return Promise.resolve(creationsContent.data.slideshowAudio.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsContentService:_getSlideshowAudio: ", error);
            return Promise.reject();
        }
    }

    private async _getCollageTemplates(): Promise<CollageTemplateData[]> {
        try {
            const collageContent = await this._client.get(this._getConfigPath(ContentType.collageTemplates));
            return Promise.resolve(collageContent.data.collageTemplates.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsContentService:_getCollageTemplates: ", error);
            return Promise.reject();
        }
    }

    private async _getCollageBackground(): Promise<CollageBackgroundData[]> {
        try {
            const collageContent = await this._client.get(this._getConfigPath(ContentType.collageBackground));
            return Promise.resolve(collageContent.data.collageBackgrounds.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsContentService:_getCollageBackground: ", error);
            return Promise.reject();
        }
    }

    private async _getDefaultBackground(): Promise<CollageBackgroundData[]> {
        try {
            const collageContent = await this._client.get(this._getConfigPath(ContentType.collageDefaultBackground));
            return Promise.resolve(collageContent.data.defaultBackgrounds.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsContentService:_getDefaultBackground: ", error);
            return Promise.reject();
        }
    }

    async getContentFromURI(URI: string): Promise<unknown> {
        try {
            const content = await this._client.get(ASSETS_ENDPOINT + URI);
            return Promise.resolve(content.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsContentService:getContentFromURI: ", error);
            return Promise.reject();
        }
    }

    async getContent(contentType: ContentType): Promise<unknown> {
        switch (contentType) {
            case ContentType.slideshowTemplate:
                {
                    return await this._getSlideshowTemplate();
                }
            case ContentType.slideshowAudio:
                {
                    return await this._getSlideshowAudio();
                }
            case ContentType.collageTemplates:
                {
                    return await this._getCollageTemplates();
                }
            case ContentType.collageBackground:
                {
                    return await this._getCollageBackground();
                }
            case ContentType.collageDefaultBackground:
                {
                    return await this._getDefaultBackground();
                }
            default:
                {
                    Logger.log(LogLevel.DEBUG, "Invalid content type", contentType);
                    throw new Error("Invalid content type");
                }
        }
    }
}
