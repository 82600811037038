/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELViewType } from "../../common/interfaces/creations/CreationTypes";
import DualViewAction, { DualViewActionPayload } from "../actions/DualViewAction";
import IAction from "../actions/IAction";

interface DualViewReducerData {
    viewType: ELViewType
}

const updateView = (state: DualViewReducerData, action: IAction<DualViewActionPayload>): DualViewReducerData => {
    if (action.payload) {
        return {
            ...state,
            viewType: action.payload as ELViewType
        }
    }
    return state;
}

const dualViewReducer = (state: DualViewReducerData = { viewType: ELViewType.before }, action: IAction<DualViewActionPayload>): DualViewReducerData => {
    switch (action.type) {
        case DualViewAction.UPDATE_VIEW:
            return updateView(state, action);
        default:
            return state;
    }
}

export default dualViewReducer;
