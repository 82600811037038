/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import { Flex } from '@adobe/react-spectrum';

//Application specific
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from '../../../../utils/Theme';
import ELCustomIcon from '../../molecules/el-custom-icon/ELCustomIcon';

import "./ELScoopedTabButton.scss";

export interface ELScoopedTabButtonProps {
    text: string,
    iconkey: IconType,
    selected?: boolean,
    variant: "primary" | "secondary",
    tooltip?: string
}

/*
    These are buttons which would be usually used inside a workflow. Similar to the philosophy of React spectrum Action Button
*/

const ELScoopedTabButton = (props: ELScoopedTabButtonProps): React.ReactElement => {
    // TODO:
    // 1. svg color change

    const getVariantClass = (): string => {
        if (props.variant === "primary")
            return props.selected ? "tab-button-primary-container selected" : "tab-button-primary-container";
        else if (props.variant === "secondary")
            return props.selected ? "tab-button-secondary-container selected" : "tab-button-secondary-container";
        return "";
    }

    return (
        //TODO - vib, use TooltipTrigger instead
        <div title={props.tooltip}>
            <Flex direction="column" alignItems="center" UNSAFE_className={getVariantClass()}>
                <div className="tab-icon-container">
                    <ELCustomIcon id={IconType[props.iconkey]} iconkey={props.iconkey} selected={props.selected}
                        color={Theme.global.scooped_button_N}
                        hoverColor={Theme.global.scooped_button_N}
                        selectedColor={Theme.global.scooped_button_N}
                        width="1.5rem" height="1.5rem" display="flex" variant="contained" />
                </div>
                <span className={props.selected ? "tab-text-container selected" : "tab-text-container"}>{props.text}</span>
            </Flex>
        </div>
    )
}

ELScoopedTabButton.defaultProps = {
    variant: "primary"
}

export default ELScoopedTabButton;
