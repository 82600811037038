/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Text, Flex, Checkbox } from "@adobe/react-spectrum";

//Application Specific
import ELButton from "../../../../../view/components/atoms/el-button/ELButtonView";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import { ELIcon } from "../../../../../view/components/atoms/el-icon/ELIconView";
import { ShareModeViewData } from "../../ShareModeData";
import { RootState } from "../../../../../stores/store";
import IViewController from "../../../../../view/IViewController";
import { LocalStorageKeys } from "../../../../../utils/Constants/Constants";

import "./../../ShareWorkspaceView.scss";

interface ConsentViewProps {
    controller: IViewController,
    text: string
}

export enum ConsentControllerAction {
    continuePressed = "CONTINUE_PRESSED"
}

export const ConsentView = (props: ConsentViewProps): React.ReactElement => {

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const intlHandler = IntlHandler.getInstance();
    let donNotShowConsent = (localStorage.getItem(LocalStorageKeys.kShareConsent) === "false") ? true : false;

    const onConsentChange = (isSelected: boolean): void => {
        donNotShowConsent = isSelected;
        localStorage.setItem(LocalStorageKeys.kShareConsent, isSelected ? "false" : "true");
    }

    const onContinueClick = (): void => {
        props.controller.notify({ type: ConsentControllerAction.continuePressed, payload: donNotShowConsent });
    }

    const shareMode = useSelector((state: RootState) => state.shareReducer.shareMode);

    return (
        <Flex UNSAFE_className="share-dialog__content-container">
            <Flex UNSAFE_className="share-dialog__description">
                <ELIcon iconkey={ShareModeViewData.getShareModeData(shareMode).icon} width="5rem" height="5rem" />
                <Text justifySelf="center" alignSelf="center" UNSAFE_className="share-dialog__msg">{props.text}</Text>
            </Flex>
            <Flex UNSAFE_className="share-dialog__cta">
                <Flex direction="row" alignItems="center" justifyContent="center">
                    <Checkbox onChange={onConsentChange} UNSAFE_style={{paddingRight: 0}}>
                        <Text UNSAFE_className="share-dialog__dont-show-text">{intlHandler.formatMessage("do-not-show-again")}</Text>
                    </Checkbox>
                </Flex>
                <ELButton size="L" onClick={() => onContinueClick()}>
                    <Text UNSAFE_className="dialog__cta-text">{intlHandler.formatMessage("continue")}</Text>
                </ELButton>
            </Flex>
        </Flex>
    )
}