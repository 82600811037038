/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { DocumentConvertibleDataType } from "../../../common/interfaces/document/DocumentTypes";
import { ELStageDocConversionPayload } from "../../../common/interfaces/document/ELStageDocTypes";
import ELStageDoc from "../../client/document/ELStageDoc";
import { DocDataConverter } from "./DocDataConverter";
import { ELStageDocToImageDataConverter } from "./ELStageDocToImageDataConverter";

export const DocumentDataTypeConverterMap: { [key in DocumentConvertibleDataType]: any } = {
    [DocumentConvertibleDataType.imageData]: ELStageDocToImageDataConverter
};

export class ELStageDocDataConverterFactory {
    static createDataConverter(doc: ELStageDoc, outputDataType: DocumentConvertibleDataType): DocDataConverter<ELStageDoc, unknown, ELStageDocConversionPayload> {
        return new DocumentDataTypeConverterMap[outputDataType](doc);
    }
}