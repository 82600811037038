/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";

//Utils
import Utils from "../../../utils/Utils";

//Adobe internal
import { Flex, Image, Divider, Heading, Link } from "@adobe/react-spectrum";
import { Routes } from "../../../app/AppRoute";

//Application specific
import IViewController from "../../../view/IViewController";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import noPageFoundImg from "../../../assets/home/nopageFound.jpeg";/* This is just placeholder , will change it later once spec available*/
import "./NoPageFoundView.scss"

interface NoPageFoundViewProps {
    controller: IViewController
}

export const NoPageFoundView = (props: NoPageFoundViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <Flex UNSAFE_className="no-page-found" direction="column" >
            <Heading>
                <div className="no-page-found-heading">{intlHandler.formatMessage("no-page-found-heading")}</div>
                <Flex UNSAFE_className="no-page-found-top-sub-heading">
                    {intlHandler.formatMessage("no-page-found-sub-heading")}
                </Flex>
            </Heading>
            <Divider size={"S"} />
            <Image UNSAFE_className="no-page-found-img" src={noPageFoundImg} objectFit="cover" />

            <Flex UNSAFE_className="no-page-found-sub-heading-link" direction="column">
                {intlHandler.formatMessage("no-page-found-check-link")}
            </Flex>
            <Link onPress={() => Utils.openInSameTab(Routes.HOME)} UNSAFE_className="no-page-found-home-page">
                {intlHandler.formatMessage("homepage")}
            </Link>
        </Flex>
    )
}
