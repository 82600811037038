/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import MediaThumbAction, { ThumbDataPayload } from "../actions/mediaThumbAction";
import IAction from "../actions/IAction";

const cleanData = (objectURL: string): void => {
    URL.revokeObjectURL(objectURL);
}

const cleanUpThumbData = (state: Array<ThumbDataPayload> = [], data: ThumbDataPayload): void => {
    state.some((ele) => {
        if (ele.assetId === data.assetId) {
            URL.revokeObjectURL(ele.objectURL);
            return true;
        }
        return false;
    });
}

const udpateThumbData = (state: Array<ThumbDataPayload> = [], action: IAction<ThumbDataPayload>): Array<ThumbDataPayload> => {
    const data = action.payload;
    if (data) {
        cleanUpThumbData(state, data);
        const arr = state.filter((ele) => ele.assetId !== data.assetId).map((ele) => {
            return ele;
        });
        arr.push(data);
        return arr;
    } else {
        return state;
    }
}

const clearAllData = (state: ThumbDataPayload[] = []): ThumbDataPayload[] => {
    state.every((ele) => {
        cleanData(ele.objectURL);
        return true;
    });

    return [];
}

const mediaThumbReducer = (state: Array<ThumbDataPayload> = [], action: IAction<ThumbDataPayload>): Array<ThumbDataPayload> => {
    switch (action.type) {
        case MediaThumbAction.UPDATE_THUMB:
            return udpateThumbData(state, action);
        case MediaThumbAction.CLEAR_ALL_DATA:
            return clearAllData(state);
        default:
            return state;
    }
}

export default mediaThumbReducer;
