/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import CookiePreferencesAction, { CookiePreferencesPayload } from "../actions/CookiePreferencesActions";
import IAction from "../actions/IAction";

const updateCookiePreferences = (state: CookiePreferencesPayload = [], action: IAction<CookiePreferencesPayload>):  CookiePreferencesPayload => {
    const data = action.payload;
    if(data) {
        return data;
    }
    else {
        return state;
    }
}

const cookiePreferencesReducer = (state: CookiePreferencesPayload = [] , action: IAction<CookiePreferencesPayload>): CookiePreferencesPayload => {
    switch (action.type) {
        case CookiePreferencesAction.UPDATE_COOKIE_PREFERNCES:
            return updateCookiePreferences(state, action);
        default:
            return state;
    }
}

export default cookiePreferencesReducer;