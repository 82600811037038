/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { Key, useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

//Adobe Internal
import { Text } from '@react-spectrum/text';
import { Content, Dialog, DialogContainer, Flex, Grid, repeat } from "@adobe/react-spectrum";

//Application Specific
import ELCarousel from "../../organism/el-carousel/ELCarousel";
import ELThumbContent from "../../molecules/el-thumb-content/ELThumbContent";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import IViewController from "../../../IViewController";
import { CreateOnDemandViewActions, ELCreateOnDemandAction, ELCreateOnDemandData, ELCreateOnDemandVariant } from "../../../../common/interfaces/creations/ELCreateOnDemandTypes";
import ELCreateOnDemandThumbView from "../../molecules/el-create-on-demand-thumb/ELCreateOnDemandThumb";
import ELBadge from "../../atoms/el-badge/ELBadge";
import { ViewAction } from "../../../IBaseController";
import { WorkflowsName } from "../../../../workspaces/IWorkflow";
import { CreationsURLParams } from "../../../../common/interfaces/creations/CreationTypes";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import { Routes } from "../../../../app/AppRoute";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import ELActionButton from "../../molecules/el-action-button/ELActionButton";
import { IconType } from "../../../../assets/IconConstants";

import "./ELCreateOnDemand.scss";

interface ELCreateButtonProps {
    onPress: () => void,
}

const ELCreateButton = (props: ELCreateButtonProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const createIcon = (<ELIcon iconkey={IconType.create} fill={"#fff"} className="share-action-button__icon" />);
    return (<ELActionButton tooltip={intlHandler.formatMessage("create")} elIcon={createIcon} text={intlHandler.formatMessage("create")}
        onPress={props.onPress} />)
}

export interface ELCreateOnDemandProps {
    controller: IViewController,
    variant: ELCreateOnDemandVariant,
    creationList: ELCreateOnDemandData[]
}

interface CreateOnDemandState {
    creationList: ELCreateOnDemandData[]
}

interface ELCreateViewAllDialogProps {
    variant: ELCreateOnDemandVariant,
    creationList: ELCreateOnDemandData[],
    showBadgeOnThumb: (thumbData: ELCreateOnDemandData) => boolean,
    badgeText: string,
    handleThumbClick: (createOnDemandData: ELCreateOnDemandData) => void,
    isCreationHighlighted: (creationData: ELCreateOnDemandData) => boolean
}

const ELCreateViewAllDialog = (props: ELCreateViewAllDialogProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const gridRef = useRef(null);
    const specLayoutConfig = {
        TILE_W: 14,
        TILE_H: 18,
        DIST_BETWEEN_TILE: 2.5
    };
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: gridRef,
        gridRef: gridRef
    });
    return (<Dialog width="80vw" height="85vh" isDismissable>
        <Content UNSAFE_className="create-on-demand__dialog-content">
            <div data-testid="create-on-demand-text-box" className={"create-on-demand-text-box"}>
                <Text>{intlHandler.formatMessage("create")}</Text>
            </div>
            <div ref={gridRef}> 
                <Grid data-test-id="creations-grid" id="creations-grid" columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                    marginTop="size-400"
                    gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                {props.creationList.map((item, index) => props.showBadgeOnThumb(item) ? <ELBadge key={index} message={props.badgeText} variant="primary">
                    <ELCreateOnDemandThumbView key={index} onPress={() => props.handleThumbClick(item)} width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H}
                        headerText={intlHandler.formatMessage(item.headerText)} descriptiveText={intlHandler.formatMessage(item.descriptiveText)} previewSrc={item.previewSrc} previewType={item.previewType}
                        thumbId={item.thumbId} dataTestId={"create-on-demand-video-thumb-" + index} isHighlighted = {props.isCreationHighlighted(item)}/>
                </ELBadge> :
                    <ELCreateOnDemandThumbView key={index} onPress={() => props.handleThumbClick(item)} width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H}
                        headerText={intlHandler.formatMessage(item.headerText)} descriptiveText={intlHandler.formatMessage(item.descriptiveText)} previewSrc={item.previewSrc} previewType={item.previewType}
                        thumbId={item.thumbId} dataTestId={"create-on-demand-video-thumb-" + index} isHighlighted = {props.isCreationHighlighted(item)}/>
                )}
                </Grid>
            </div>
        </Content>
    </Dialog>);
}

const ELCreateOnDemandView = (props: ELCreateOnDemandProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const location = useLocation();
    const [highlightCreation, setHighlightCreation] = useState<WorkflowsName | null>(null);
    const [dialog, setDialog] = React.useState(props.variant as Key);

    const defaultState = (): CreateOnDemandState => {
        return {
            creationList: props.creationList
        };
    };

    const reducer = (state: CreateOnDemandState, action: ViewAction): CreateOnDemandState => {
        switch (action.type) {
            case CreateOnDemandViewActions.UPDATE_CREATIONS_DATA: {
                return {
                    ...state,
                    creationList: action.payload as ELCreateOnDemandData[]
                };
            }
            default: {
                return state;
            }
        }
    };

    const [state, dispatch] = useReducer(reducer, defaultState());

    useEffect(() => {
        props.controller.initialize(dispatch);

        //clean up
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);


    useEffect(() => {
        let animationTimer: NodeJS.Timeout | null = null;
        if(props.variant === "creations") {
            HistoryUtils.replaceHistory(Routes.CREATIONS);
            const highlightCreation = (new URLSearchParams(location.search))?.get(CreationsURLParams.highlightCreation) as WorkflowsName ?? null;
            if(highlightCreation) {
                setHighlightCreation(highlightCreation);
                const animationDuration = 10000; // Match your animation duration (10s)
                animationTimer = setTimeout(() => {
                    setHighlightCreation(null);
                }, animationDuration);
            }
        }

        return () => {
            if(animationTimer) {
                clearTimeout(animationTimer);
            }
        };
    }, [location]);

    useEffect(() => {
        if(dialog === "viewAllDialog") {
            props.controller.notify({type: ELCreateOnDemandAction.viewAllDialog});
        }
    }, [dialog])

    const handleThumbClick = (createOnDemandData: ELCreateOnDemandData): void => {
        props.controller.notify({ type: ELCreateOnDemandAction.workflowThumb, payload: createOnDemandData });
    };

    const onDialogThumbClick = (createOnDemandData: ELCreateOnDemandData): void => {
        setDialog("");
        handleThumbClick(createOnDemandData);
    };

    const showBadgeOnThumb = (thumbData: ELCreateOnDemandData): boolean => {
        return thumbData.showNewBadge ?? false;
    };

    const getBadgeText = (): string => {
        return IntlHandler.getInstance().formatMessage("new-badge");
    };

    const isCreationHighlighted = (creationData: ELCreateOnDemandData): boolean => {
        return highlightCreation === creationData.thumbId;
    };

    /**
     * TODO - niskumar:
     * 1. Check why custom provider needed to be present at parent level to be able to listen for change in children?
     * 2. Check if custom provider has separate instance at every places we use?
     */
    return (
        <ViewportProvider>
            <div data-testid="create-on-demand-container">
                {props.variant === "creations" && <div data-testid="create-on-demand-text-box" className={"create-on-demand-text-box"}>
                    <Text>{intlHandler.formatMessage("create")}</Text>
                    <Flex marginBottom="size-100">
                        <button className="create-on-demand__view-all-button" onClick={() => { setDialog("viewAllDialog") }}>
                            <span>{intlHandler.formatMessage("view-all")}</span>
                        </button>
                    </Flex>
                </div>}
                {(props.variant === "creations") && <ELCarousel variant="independent">
                    {state.creationList.map((item, index) => showBadgeOnThumb(item) ? <ELBadge key={index} message={getBadgeText()} variant="primary">
                        <ELCreateOnDemandThumbView key={index} onPress={() => handleThumbClick(item)}
                            headerText={intlHandler.formatMessage(item.headerText)} descriptiveText={intlHandler.formatMessage(item.descriptiveText)} previewSrc={item.previewSrc} previewType={item.previewType}
                            thumbId={item.thumbId} dataTestId={"create-on-demand-video-thumb-" + index} isHighlighted = {isCreationHighlighted(item)}/>
                    </ELBadge> :
                        <ELCreateOnDemandThumbView key={index} onPress={() => handleThumbClick(item)}
                            headerText={intlHandler.formatMessage(item.headerText)} descriptiveText={intlHandler.formatMessage(item.descriptiveText)} previewSrc={item.previewSrc} previewType={item.previewType}
                            thumbId={item.thumbId} dataTestId={"create-on-demand-video-thumb-" + index} isHighlighted = {isCreationHighlighted(item)}/>
                    )}
                </ELCarousel>
                }
                {props.variant === "mediaGrid" && <ELCreateButton onPress = {() => setDialog("viewAllDialog")} />}
                {dialog === "viewAllDialog" && <ELReactSpectrumV3Provider key="view_all_creation">
                        <DialogContainer onDismiss={() => setDialog("")}>
                            <ELCreateViewAllDialog
                                variant={props.variant}
                                creationList={state.creationList}
                                showBadgeOnThumb={showBadgeOnThumb}
                                badgeText={getBadgeText()}
                                handleThumbClick={onDialogThumbClick}
                                isCreationHighlighted={isCreationHighlighted}
                            />
                        </DialogContainer>
                    </ELReactSpectrumV3Provider>
                }
            </div>
        </ViewportProvider>
    );
};

const variant: ELCreateOnDemandVariant = "creations";

ELCreateOnDemandView.defaultProps = {
    variant: variant
};

export default ELCreateOnDemandView;
