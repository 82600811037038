/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';
import { FeatureMetadatamap } from '../reducers/FloodgateFeaturesReducer';

export type FeaturesPayload = string[];

export default class FloodgateFeaturesAction {

    public static readonly UPDATE_FEATURES: string = "FloodgateFeaturesAction.UPDATE_FEATURES";
    public static readonly RESET_FEATURES: string = "FloodgateFeaturesAction.RESET_FEATURES";
    public static readonly UPDATE_METADATA: string = "FloodgateFeaturesAction.UPDATE_MEDATA";

    public static updateFeaturesMap(featuresList: FeaturesPayload): IAction<FeaturesPayload | FeatureMetadatamap> {
        return {
            type: FloodgateFeaturesAction.UPDATE_FEATURES,
            payload: featuresList
        };
    }

    public static updateFeaturesMetadata(featuresMetadata: FeatureMetadatamap): IAction<FeaturesPayload | FeatureMetadatamap> {
        return {
            type: FloodgateFeaturesAction.UPDATE_METADATA,
            payload: featuresMetadata
        };
    }

    public static resetFeaturesMap(): IAction<void> {
        return {
            type: FloodgateFeaturesAction.RESET_FEATURES
        };
    }
}