/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { ReactNode, useEffect } from "react";
import {
	Switch,
	Route,
	useLocation,
	Redirect,
	RouteComponentProps
} from "react-router-dom";
import { StaticContext } from "react-router";
import { useSelector } from "react-redux";

//Application specific
import App from "./App";
import CreationsApp from "../workspaces/creations/CreationsApp";
import OrganizerApp from "../workspaces/organizer/OrganizerApp";
import { LinkPreviewApp } from "../workspaces/LinkPreview/LinkPreviewApp";
import { UserHomeApp } from "../workspaces/Home/UserHomeApp";
import { RootState } from "../stores/store";

//Utils 
import Logger, { LogLevel } from "../utils/Logger";
import { ShareUtils } from "../utils/ShareUtils";
import { UserAllowedUtils } from "../utils/LoginUtils";
import { SIVUtils } from "../utils/SIVUtils";
import { UserUtils } from "../utils/UserUtils";
import IMS from "../services/IMS";
import { useNetwork } from "../utils/hooks/useNetwork";
import EditWorkspaceView from "../workspaces/edit/EditWorkspaceView";

export enum Routes {
	VIEW = "/view",
	MEDIA = "/media",
	CREATIONS = "/creations",
	ROOT = "/",
	HOME = "/home",
	SIV = "/siv",
	EDIT = "/edit",
	CATCH_ALL_ROUTE = "/(.+)"
}

interface AppRouteProps {
	controller: App
}

const AppRoute = (props: AppRouteProps): React.ReactElement<AppRouteProps> => {
	const location = useLocation();
	const daysInTrial = useSelector((state: RootState) => state.appReducer.daysInTrial);

	useNetwork();

	useEffect(() => {
		Logger.log(LogLevel.INFO, 'routename:', location.pathname);
		props.controller.routeChanged(location.pathname);
	}, [props.controller, location]);

	function withAuthentication(component: ReactNode): ReactNode {
		return (IMS.getInstance().isSignedInUser() && UserAllowedUtils.isUserAllowed(daysInTrial) ? component : <UserHomeApp controller={props.controller.getUserHomeApp} />);
	}

	function catchAllInvalidRoutes(): ReactNode {
		// For invalid routes, handling is done at CDN side, returning empty here if in case it gets hit.
		return <></>;
	}

	const getViewRouteComponent = (routeProps: RouteComponentProps<{
		[x: string]: string | undefined;
	}, StaticContext, unknown>): ReactNode => {
		if (ShareUtils.isDesktopToWebShareHandover(window.location.href)) {
			return withAuthentication(<OrganizerApp controller={props.controller.getOrganizerApp} />);
		} else {
			return <LinkPreviewApp controller={props.controller.getLinkPreviewWorkspace} />
		}
	}

	const getSIVRouteComponent = (routeProps: RouteComponentProps<{
		[x: string]: string | undefined;
	}, StaticContext, unknown>): ReactNode => {
		if (SIVUtils.isLinkPreviewRoute(window.location.href)) {
			return <LinkPreviewApp controller={props.controller.getLinkPreviewWorkspace} />
		} else {
			return withAuthentication(<OrganizerApp controller={props.controller.getOrganizerApp} />);
		}
	}

	const getRouteForRootRedirection = (): Routes => {
		if (UserUtils.isUserPaid())
			return Routes.MEDIA;
		else
			return Routes.HOME;
	}

	return (
		<>
			<Switch>
				<Route path={Routes.MEDIA}>
					{withAuthentication(<OrganizerApp controller={props.controller.getOrganizerApp} />)}
				</Route>
				<Route path={Routes.EDIT}>
					{withAuthentication(<EditWorkspaceView controller={props.controller.getEditWorkspace} />)}
				</Route>
				<Route path={Routes.CREATIONS}>
					{withAuthentication(<CreationsApp controller={props.controller.getCreationsApp} />)}
				</Route>
				<Route path={Routes.VIEW} render={getViewRouteComponent} />
				<Route path={Routes.SIV} render={getSIVRouteComponent} />
				<Route path={Routes.HOME}>
					{withAuthentication(<UserHomeApp controller={props.controller.getUserHomeApp} />)}
				</Route>
				<Route path={Routes.CATCH_ALL_ROUTE}>
					{catchAllInvalidRoutes}
				</Route>
				<Route path={Routes.ROOT}>
					{withAuthentication(<Redirect to={getRouteForRootRedirection()} />)}
				</Route>
			</Switch>
		</>
	);
}

export default AppRoute;
