/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* eslint-disable no-bitwise */

//Thirdparty
import axios from "axios";
import { createBrowserHistory } from "history";
import { RefObject } from "react";
import { NIL, v4 as uuid } from "uuid";

//Application specific
import { IntlHandler } from "./../modules/intlHandler/IntlHandler";
import Constants, { ERROR_THUMBDATA, LocalStorageKeys, PROCESSING_THUMBDATA, UrlOpenTargets } from "./Constants/Constants";
import { AssetMimeType, ELAdobeAsset } from "../common/interfaces/storage/AssetTypes";
import { Routes } from "../app/AppRoute";
import Logger, { LogLevel } from "./Logger";
import store from "../stores/store";
import { LocaleLanguage } from "../common/interfaces/intl/LocaleTypes";

export enum DurationParsingFormat {
	mm_ss = "mm:ss"
}

const ONE_MILLION = 1000000;

export default class Utils {
	static async loadScript(id: string, src: string, crossOrigin = false): Promise<void> {
		const existingScript = document.getElementById(id)
		if (existingScript) {
			return Promise.resolve()
		}
		return new Promise((resolve, reject) => {
			const script = document.createElement("script")
			script.src = src
			script.id = id

			if (crossOrigin) {
				script.crossOrigin = "anonymous"
			}

			script.onload = () => {
				resolve()
			}
			script.onerror = (event: Event | string) => {
				console.error(event)
				reject(new Error(`unable to load script ${id} from ${src}`))
			}
			document.body.appendChild(script)
		})
	}

	static async loadCSS(id: string, src: string, crossOrigin = false): Promise<void> {
		const existingCss = document.getElementById(id)
		if (existingCss) {
			return Promise.resolve()
		}
		return new Promise((resolve, reject) => {
			const link = document.createElement("link")
			link.rel = "stylesheet"
			link.type = "text/css"
			link.href = src
			link.id = id

			if (crossOrigin) {
				link.crossOrigin = "anonymous"
			}

			const head = document.getElementsByTagName("head")[0]
			link.onload = () => {
				resolve()
			}
			link.onerror = () => {
				reject()
			}
			head.appendChild(link)
		})
	}

	static openInNewTab(href: string, features?: string): void {
		window.open(href, UrlOpenTargets.blank, features);
	}

	static openInSameTab(href: Routes): void {
		window.open(href, UrlOpenTargets.self);
	}

	static openPopup(href: string, width: number, height: number): void {
		const windowTop = window.screenTop || window.screenY || 0;
		const windowLeft = window.screenLeft || window.screenX || 0;
		const windowWidth = document.documentElement.clientWidth;
		const windowHeight = document.documentElement.clientHeight;

		const top = windowTop + Math.max(0, (windowHeight - height) / 2);
		const left = windowLeft + Math.max(0, (windowWidth - width) / 2);

		Utils.openInNewTab(href, `top=${top},left=${left},width=${width},height=${height}`)
	}

	static isValidRoutePath(path: string): boolean {
		const transformedPath = path.toLowerCase();
		if (transformedPath && transformedPath.startsWith("/")) { // Double check route paths like /.* 
			const inputPath = transformedPath.split("/")[1];
			for (const element of Object.values(Routes)) {
				if (inputPath === element.split("/")[1]) {
					return true; // If input URL transformedPath matches one of valid Route transformedPath
				}
			}
		}
		return false;
	}

	static isValidDate(date: Date): boolean {
		if (isNaN(date.getDate()) || isNaN(date.getMonth()) || isNaN(date.getFullYear()))
			return false;
		if (date.getDate() > 31 || date.getMonth() > 11)
			return false;
		return true;
	}

	static formatDate(date: Date, locale = LocaleLanguage.enGB): string { // Day Date Month Year format
		if (!Utils.isValidDate(date))
			return "";
		return Intl.DateTimeFormat(locale, { dateStyle: "full" }).format(date);
	}

	static formatTime(date: Date, locale = LocaleLanguage.enGB): string {
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const seconds = date.getSeconds();
		if (isNaN(hours) || isNaN(minutes) || isNaN(seconds))
			return "";
		const strTime = Intl.DateTimeFormat(locale, { timeStyle: "medium" }).format(date)
		return strTime;
	}

	static humanFileSize(bytes: number, si = true, showUnitAfterSpace = true): string {
		const thresh = si ? 1024 : 1000;
		if (Math.abs(bytes) < thresh) {
			return showUnitAfterSpace ? `${bytes} B` : `${bytes}B`;
		}
		// TODO: ideally we should show GiB but leaving commented for now
		// si ? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]: ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]; 
		const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		let u = -1
		let calcBytes = bytes
		do {
			calcBytes /= thresh
			u += 1
		} while (Math.abs(calcBytes) >= thresh && u < units.length - 1)
		const unitString = showUnitAfterSpace ? ` ${units[u]}` : `${units[u]}`;
		return `${(Math.round(calcBytes * 100) / 100).toLocaleString(Utils.getCurrentLanguage().replace("_", "-"))}${unitString}`;
	}

	static getFileExtension(fileName: string): string {
		const pos = fileName.lastIndexOf(".");
		if (fileName === "" || pos < 1)
			return "";
		return fileName.slice(pos + 1);
	}

	static calculateResolution(length: number, width: number): string {
		return ((length * width) / ONE_MILLION).toFixed(2) + " MP";
	}

	/**
	 * @param  {number} bytes
	 * @returns GiB (and not GB)
	 */
	static getGiBtoBytes(num: number): number {
		return num * 1024 * 1024 * 1024;
	}

	/**
	 * @param  {number} bytes
	 * @returns MB
	 */
	static getBytesToMB(bytes: number): number {
		return bytes / 1024 / 1024;
	}

	/**
	 * @param {number} num
	 * @return {string} percentage, considering upto 2 decimal
	 * @example (1.035) ---> "103%"
	 */
	static getPercentageFromNumber(num: number): string {
		const numPercentage = num * 100;
		return numPercentage.toFixed(0) + "%";
	}

	/**
	 * @param {string} strWithPercentage
	 * @return {number} number, 
	 * @example "103%" ---> 1.03: number 
	 */
	static getNumberFromPercentageString(str: string): number {
		const splitStr = str.split('%')[0];
		return Number(splitStr) / 100;
	}

	/**
	 * @param  {string} fraction
	 * @returns {number} denominator
	 */
	static getDenominatorFromFraction(fraction: string): number {
		if (fraction.split('/').length !== 2)
			return 1;

		const denominator = parseInt(fraction.split('/')[1]);
		return denominator;
	}

	static getCurrentLanguage(): string {
		return Utils.getCurrentLocaleInSnakeCase().split("_")[0];
	}

	static getNormalizedCurrentLocale(): string {
		/*
		return adobeLocales.normalize(Utils.getCurrentLanguage())
		*/
		return "en-US";
	}

	static convertToSnakeCase(inputString: string): string {
		return inputString.split("-").join("_");
	}

	static getCurrentLocaleInKebabCase(): string {
		const _intlHandler = IntlHandler.getInstance();
		return _intlHandler.getCurrentLocale();
	}

	static getCurrentLocaleInSnakeCase(): string {
		const _intlHandler = IntlHandler.getInstance();
		return Utils.convertToSnakeCase(_intlHandler.getCurrentLocale());
	}

	static hasUSEnglish(): boolean {
		/*
		const cookies = Utils.parseCookies()
		if (cookies.international !== undefined) {
		  return adobeLocales.normalize(cookies.international, "adobedotcom") === "en-US"
		}
		if (cookies["creative-cloud-loc"] !== undefined) {
		  return adobeLocales.normalize(cookies["creative-cloud-loc"], "cchome") === "en-US"
		}*/
		return Utils.getNormalizedCurrentLocale() === "en-US"
	}
	/**
	 * @param  {DurationParsingFormat} format
	 * @param  {number} duration - Must be in seconds
	 * @returns string
	 */
	static getParsedDuration(format: DurationParsingFormat, duration: number): string {
		let seconds = null;
		let minutes = null;
		switch (format) {
			case DurationParsingFormat.mm_ss:
				minutes = Math.floor(duration / 60);
				seconds = Math.floor(duration % 60);
				if (seconds < 10) {
					seconds = `0${seconds}`;
				}
				return `${minutes}:${seconds}`
			default:
				throw new Error("Unexpected format passed");
		}
	}

	static getLocalizedURL(url: string): string {
		/*
		const locale = Utils.getNormalizedCurrentLocale()
		return adobeLocales.getLocalizedURL(url, locale)
		*/
		return url;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static parseCookies(cookies?: string): any {
		return Object.assign(
			{},
			...(cookies ?? document.cookie)?.split(";").map(cookie => {
				const name = cookie.split("=")[0]?.trim()
				const value = cookie.split("=")[1]?.trim()
				return { [name]: value }
			})
		)
	}

	static isValidEmail(email: string): boolean {
		const emailList = email.replace(/\s/g, '').split(',');
		for (let i = 0; i < emailList.length; i++) {
			//eslint-disable-next-line
			const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!regex.test(emailList[i]))
				return false;
		}
		return true;
	}

	static getFileExt(fileName: string): string | undefined {
		return fileName
			.trim()
			.toLowerCase()
			.split(".")
			.pop()
	}

	static getFileName(file: File | string): string {
		return file instanceof File ? file.name : file.split("/").pop() ?? ""
	}

	static hasSupportedFileExt(fileName: string): boolean {
		const ext = Utils.getFileExt(fileName)
		if (!ext) {
			return false
		}
		return ext.match(/^(jpe?g)?(png)?$/) !== null
	}

	static getURLSearchParam(name: string): string | null {
		const queryParams = new URLSearchParams(window.location.search)
		return queryParams.get(name)
	}

	static focusFirstDivChild(element: HTMLElement): void {
		if (element.getElementsByTagName("div").length > 0)
			element.getElementsByTagName("div")[0].focus();
	}

	static showHideHtmlElement(element: HTMLElement, displayProp = "none"): void {
		element.setAttribute("style", "display:" + displayProp);
	}

	static wait(timeout: number): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return new Promise((resolve, reject) => setTimeout(resolve, timeout))
	}
	/**
	 * @param  {number} retryCount ranging between [0,Constants.maxRetries] 
	 * @returns Promise
	 */
	static exponentialWait(retryCount: number, maxRetryCount = Constants.MAX_RETRIES): Promise<void> {
		if (retryCount < 0 || retryCount > Number(maxRetryCount))
			return Promise.reject("This much wait is not supported by the application");
		return new Promise((resolve) => setTimeout(resolve, (2 ** retryCount) * 1000));
	}

	static async readFileToBuffer(file: string | File): Promise<Uint8Array> {
		if (file instanceof File) {
			return Utils.readLocalFile(file);
		}

		/* WEB_REVISIT
		if (isString(file)) {
		  return Utils.fetchRemoteFile(file as string)
		}*/

		return Promise.reject("unknwon error");
	}

	static async readLocalFile(file: File): Promise<Uint8Array> {
		return new Promise<Uint8Array>((resolve, reject) => {
			const reader = new FileReader()

			reader.onerror = e => {
				reject(e)
			}

			reader.onload = e => {
				if (e.target && e.target.result instanceof ArrayBuffer) {
					resolve(new Uint8Array(e.target.result))
				} else {
					reject(new Error("unknwon error"))
				}
			}

			reader.readAsArrayBuffer(file)
		})
	}

	static async fetchRemoteFile(file: string): Promise<Uint8Array> {
		const response = await fetch(file)
		return new Uint8Array(await response.arrayBuffer())
	}

	static createProgressResponse(response: Response, progress: (loaded: number, total: number) => void): Response {
		if (!response.ok) return response
		if (!response.body) return response

		const contentEncoding = response.headers.get("content-encoding")
		const contentLength = response.headers.get(contentEncoding ? "x-file-size" : "content-length")
		if (!contentLength) {
			progress(0, -1)
			return response
		}

		const reader = response.body.getReader()
		const total = parseInt(contentLength, 10)
		let loaded = 0

		return new Response(
			new ReadableStream({
				start(controller: any) {
					function read(): void {
						reader
							.read()
							.then(({ done, value }) => {
								if (done) {
									controller.close()
								} else {
									loaded += value?.byteLength || 0
									progress(loaded, total)
									controller.enqueue(value)
									read()
								}
							})
							.catch(error => {
								controller.error(error)
							})
					}
					read()
				},
			})
		)
	}
	/**
	 * This code sets the cursor positioning at end
	 * @param  {RefObject<HTMLSpanElement>} ref
	 * @returns void
	 */
	static setCursorPositionToEnd(ref: RefObject<HTMLSpanElement>): void {
		const range = document.createRange();
		range.setStart(ref.current?.childNodes[0] as Node, ref.current?.textContent?.length ?? 0);
		range.collapse(true);

		const selection = window.getSelection();
		selection?.removeAllRanges();
		selection?.addRange(range);

		ref.current?.focus();
	}

	/**
	 * Checks if internet access is available
	 * @returns Promise<boolean>
	 */

	static async checkInternetAccess(): Promise<boolean> {
		if (!window.navigator.onLine) {
			return Promise.resolve(false);
		}

		try {
			const online = await axios.get(process.env.PUBLIC_URL + "/favicon.png", {
				headers: {
					"Cache-Control": "no-cache",
					"Pragma": "no-cache",
					"Expires": "0",
				}
			});
			return Promise.resolve(online.status >= 200 && online.status < 300);
		} catch (err) {
			return Promise.resolve(false);
		}
	}

	/**
	 * Checks if connected to network
	 * @returns boolean
	 */
	static checkNetworkAccess(): boolean {
		// if one wants to check for internet access, use checkInternetAccess method.
		// if browser is connected to network which don't have internet access this will still return true
		if (window.navigator.onLine)
			return true;
		return false;
	}

	static getRouteFromHref(href: string): string | null {
		let url;
		try {
			url = new URL(href);
		} catch (error) {
			return null;
		}
		return url.pathname;
	}

	static getLinkParamValue(href: string, linkParam: string): string | null {
		let url;
		try {
			url = new URL(href);
		} catch (error) {
			return null;
		}
		return url.searchParams?.get(linkParam);
	}

	/**
	 * @returns "00000000-0000-0000-0000-000000000000" as a string
	 */
	static getNilUUID(): string {
		return NIL;
	}
	/**
	 * @returns random uuid as a string
	 */
	static getRandomUUID(): string {
		return uuid();
	}
	/**
	 * @returns string: Device UUID
	 */
	static getDeviceUUID(): string {
		let deviceGuid = localStorage.getItem(LocalStorageKeys.kDeviceGuid);
		if (!deviceGuid) {
			const newDeviceGuid = this.getRandomUUID();
			localStorage.setItem(LocalStorageKeys.kDeviceGuid, newDeviceGuid);
			deviceGuid = newDeviceGuid;
		}
		return deviceGuid;
	}

	static getSessionUUID(): string {
		const sessionId = store.getState().appReducer.sessionId;
		if (sessionId)
			return sessionId;
		throw new Error("Session Id not set");
	}

	/**
	 * @param  {string|SharedArrayBuffer} input : string (which can be 2 byte character as well)
	 * @returns string which is base64 encoded 
	 */
	static getBase64Data = (input: string | SharedArrayBuffer): string => {
		let charCodes: Uint8Array;
		if (typeof input === "string") {
			const codeUnits = new Uint16Array(input.length);
			for (let i = 0; i < codeUnits.length; i++) {
				codeUnits[i] = input.charCodeAt(i);
			}
			charCodes = new Uint8Array(codeUnits.buffer);
		} else {
			charCodes = new Uint8Array(input);
		}
		let result = "";
		for (let i = 0; i < charCodes.byteLength; i++) {
			result += String.fromCharCode(charCodes[i]);
		}
		return btoa(result);
	}

	/**
	 * @returns base font scale factor as number
	 */
	static getBaseFontScale(): number {
		return 16;
	}

	/**
	 * @returns renderedFactor as number
	 */
	static getRenderedFactor(): number {
		return parseInt(window.getComputedStyle(document.body).getPropertyValue("font-size"));
	}

	/**
	 * @param  {ELAdobeAsset} asset
	 * @returns true if the asset is image type
	 */
	static isImageMimeType(asset: ELAdobeAsset): boolean {
		if (asset.format) {
			if (asset.format.startsWith("image"))
				return true;
		}
		return false;
	}

	/**
	 * @param  {ELAdobeAsset} asset
	 * @returns true if the asset is video type
	 */
	static isVideoMimeType(asset: ELAdobeAsset): boolean {
		if (asset.format) {
			if (asset.format.startsWith("video"))
				return true;
		}
		return false;
	}

	/**
	 * @param  {ELAdobeAsset} asset
	 * @returns true if the asset is image/gif type
	 */
	static isGifMimeType(asset: ELAdobeAsset): boolean {
		if (asset.format) {
			if (asset.format.startsWith("image/gif"))
				return true;
		}
		return false;
	}

	/**
   * @param  {ELAdobeAsset} asset
   * @returns AssetMimeType default returns image
   */
	static getAssetMimeType(asset: ELAdobeAsset): AssetMimeType {
		if (asset.format) {
			if (asset.format.startsWith("video"))
				return AssetMimeType.video;
			if (asset.format.toLowerCase() === "image/gif")
				return AssetMimeType.gif;
		}
		return AssetMimeType.image;
	}

	/**
	 * @returns dialog type: modal | tray
	 */
	static getSpectrumDialogType(isMobilePortraitMode: boolean): "tray" | "modal" {
		if (isMobilePortraitMode) {
			return "tray";
		}

		return "modal";
	}

	/**
	 * @returns dialog type: popover | tray
	 */
	static getSpectrumPopoverType(isMobile: boolean): "popover" | "tray" {
		if (isMobile) {
			return "tray";
		}

		return "popover";
	}

	/**
	 * @returns difference between two dates
	 * @param date1 : Date
	 * @param date2 : Date
	 * @param format : "min" (can be extended in future)
	 */
	static getDateDifference(date1: Date, date2: Date, format: "min" | "second"): number {
		switch (format) {
			case "min": {
				return Math.abs(date1.getTime() - date2.getTime()) / 1000 / 60;
			}
			case "second": {
				return Math.abs(date1.getTime() - date2.getTime()) / 1000;
			}
		}
	}

	/**
	 * @returns is the givenDate is same as Today's date
	 * @parm date : Date
	 */
	static isTodaysDate(date: Date): boolean {
		const currDate = new Date();

		const todayDate = currDate.getDate() + "_" + currDate.getMonth() + "_" + currDate.getFullYear();
		const givenDate = date.getDate() + "_" + date.getMonth() + "_" + date.getFullYear();

		return todayDate === givenDate;
	}

	/**
	 * @returns best fit tile width, height in a given grid
	 * @param TILE_W : number
	 * @param TILE_H : number
	 */
	static getBestFitTileInGrid(layout: { TILE_W: number, TILE_H: number, X_DIST_BETWEEN_TILE: number },
		gridWidth: number): { width: number, height: number } {
		if (gridWidth === 0)
			return { width: layout.TILE_W, height: layout.TILE_H };

		const tileAspectRatio = layout.TILE_W / layout.TILE_H;
		const tileWidth = Math.floor(layout.TILE_W);

		const totalTilesInRow = Math.floor(gridWidth / tileWidth);
		const spaceLeft = gridWidth - (totalTilesInRow * tileWidth) - ((totalTilesInRow - 1) * layout.X_DIST_BETWEEN_TILE);

		const newTileWidth = Math.floor(tileWidth + spaceLeft / totalTilesInRow);
		const newTileHeight = Math.floor(newTileWidth / tileAspectRatio);

		return { width: newTileWidth, height: newTileHeight };
	}

	/**
	 * downloads file from the url provided
	 * @param uri URL from where file can be downloaded 
	 */
	static downloadURI(uri: string, name?: string, target?: UrlOpenTargets): void {
		const link = document.createElement("a");
		target && (link.target = target);
		name && (link.download = name);
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	static removeParamFromLink(href: string, param: string): string {
		let url;
		try {
			url = new URL(href);
		} catch (error) {
			Logger.log(LogLevel.WARN, "Utils:removeParamFromLink: ", `Invalid url for removing link param, href = ${href} and param = ${param}`);
			return href;
		}
		url?.searchParams.delete(param);
		return url.toString();
	}

	/**
	 * if URL is elements.adobe.com?a=b&c=d and we want to hide a's value resultant URL will be 
	 * elements.adobe.com?a=REMOVED&c=d
	 * @param href URL from which params are to be removed
	 * @param params params whose values are to be hidden
	 * @returns new URL with hidden param values
	 */
	static hideQueryParametersValue(href: string, params: string[]): string {
		let url: URL;
		const queryParamNewValue = "REMOVED";

		try {
			url = new URL(href);
		} catch (error) {
			Logger.log(LogLevel.WARN, "Utils:hideQueryParametersValue: Invalid URL for hiding params");
			return href;
		}

		params.forEach((param) => {
			if (url.searchParams.has(param))
				url.searchParams.set(param, queryParamNewValue);
		});

		return url.toString();
	}

	/**
	 * @param  {unknown} input
	 * @returns boolean stating whether it is null/undefined or not
	 */
	static isInputWellDefined(input: unknown): boolean {
		if (input === undefined || input === null) return false;
		return true;
	}

	/**
	 * @param  {string} thumbData
	 * @returns boolean True if PROCESSING/ERROR Thumb 
	 */
	static isMalformedThumbData(thumbData: string | undefined): boolean {
		if (!thumbData || PROCESSING_THUMBDATA === thumbData || ERROR_THUMBDATA === thumbData) {
			return true;
		}
		return false;
	}

	/**
	 * @param  {string} toastId (div id)
	 * @returns boolean True if toast with same id is visible
	 */
	static isMessageToastVisible(toastId: string): boolean {
		return document.getElementById(toastId) !== null;
	}

	/**
	 * Sort T[] in random order
	 * @param array
	 * @returns 
	 */
	static sortArrayRandomly<T>(array: T[]): T[] {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
		return array;

	}

	/**
	 * Slices an input array into batches of a specified size or less. 
	 * @param inputArray The array to be batched.
	 * @param batchSize The size of each batch.
	 */
	static batchArray<ArrayType>(inputArray: ArrayType[], batchSize: number): ArrayType[][] {
		const resultArray: ArrayType[][] = [];
		for (let i = 0; i < inputArray.length; i += batchSize) {
			resultArray.push(inputArray.slice(i, i + batchSize));
		}
		return resultArray;
	}

	/**
 	* Stringify the given object in a safe manner for logging. This ensures
 	* all sensitive keys are removed, BigInts are converted, etc.
 	*/
	static safeStringify(target: unknown): string {
		try {
			const sensitiveKeys = ["authorization", "authtoken", "servicetoken"];
			const visited = new Set();
			return JSON.stringify(target, (key: unknown, value: unknown) => {
				// All Symbol-keyed properties will be ignored by JSON stringify
				if (typeof key === "string" && sensitiveKeys.includes(key.toLowerCase())) {
					return "xxxxx";
				}
				if (value instanceof Date) {
					return (value as Date).toISOString();
				}
				const valueType = typeof value;
				if (valueType === "object" || value instanceof Array) {
					if (visited.has(value)) {
						return "--visited--";
					}
					visited.add(value);
				} else if (valueType === "bigint") {
					return (value as bigint).toString();
				}
				return value;
			});
		} catch (e) {
			Logger.log(LogLevel.DEBUG, "safeStringify() - Failed to stringify", e);
			return "";
		}
	}
}

// Creating custom history object since we need to define routes in different place of application
// using common history, we can "link" the effect of clicking across different components

export const history = createBrowserHistory();