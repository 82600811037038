/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Request } from "@elements/elementswebcommon";

//Application Specific
import { ELRecommendationsProgressPayload, RecommendationsStatusData, RequestId } from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { ElementsCreationBatchProcessor } from "./ElementsCreationBatchProcessor";
import RecommendationBatchRequestHandler from "../elBatchRequestHandler/ELCreationBatchHandler/RecommendationBatchRequestHandler";
import { CreationsStatus } from "../../../common/interfaces/creations/CreationTypes";
import { ELBatchProcessorAction,
    ELCreationBatchActionTypes,
    ELRecommendationRequestProcessorData,
    ELRecommendationBatchResponseData,
    ELRecommendationBatchProcessorRequestInfo,
    ELBatchRecommendationStatusData,
    ELRecommendationBatchProgressData } from "../../../common/interfaces/modules/elBatchHandler/ELCreationBatchHandlerTypes";
import Logger, { LogLevel } from "../../../utils/Logger";
import ElementsCreationsService from "../../../services/ElementsServices/ElementsCreationsService";
import IWorkflow from "../../../workspaces/IWorkflow";

export class ELRecommendationBatchProcessor extends ElementsCreationBatchProcessor<ELRecommendationBatchResponseData[]> {

    protected batchResponseData: ELRecommendationRequestProcessorData[] = [];

    constructor(owner: IWorkflow) {
        super(owner);
        this.batchRequestHandler = new RecommendationBatchRequestHandler(this);
    }

    async retryAllFailedRequests(): Promise<boolean> {
        try {
            const listOfFailedRequestObj = this._getAndFilterListForErrorRequests();

            if (!this.batchRequestHandler) {
                Logger.log(LogLevel.ERROR, "ELRecommendationBatchProcessor: retryAllFailedRequests, batchRequestHandler is not set.");
                return Promise.resolve(false);
            }

            const retryResponseData = await this.batchRequestHandler.postRequestsAndUpdateResponses(listOfFailedRequestObj);
            this._updateBatchResponseData(retryResponseData);
            return Promise.resolve(true);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELRecommendationBatchProcessor: retryAllFailedRequests, failed with error " + error);
            return Promise.resolve(false);
        }
    }

    async makeBatchRequest(requestObj: Request, batchSize: number): Promise<ELRecommendationBatchResponseData[]> {
        try {
            this._cleanUpWorkflowBeforeRequesting();
            
            this.batchRequestHandler = new RecommendationBatchRequestHandler(this);
            const batchResponseData = await this.batchRequestHandler.createBatchAndPostRequest(requestObj, batchSize);
            
            this._updateBatchResponseData(batchResponseData);
            
            return batchResponseData;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELRecommendationBatchProcessor: makeBatchRequest, failed with error " + error);
            return Promise.reject(error);
        }
    }

    notify<BatchAction extends ELBatchProcessorAction>(action: BatchAction): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELCreationBatchActionTypes.recommendationBatchStatusChanged: {
                const recommendationsStatusData = action.payload as RecommendationsStatusData;
                handled = this._updateStatusChange(recommendationsStatusData);
                this._notifyForOverallBatchStatus();
                break;
            }
            case ELCreationBatchActionTypes.recommendationBatchProgressChanged: {
                const recommendationProgressPayload = action.payload as ELRecommendationsProgressPayload;
                handled = this._updateProgressChange(recommendationProgressPayload);
                break;
            }
            default: {
                Logger.log(LogLevel.INFO, "RecommendationBatchProcessor: notify, invalid action: " + action);
                break;
            }
        }

        return Promise.resolve(handled);
    }

    async deleteRequestedCreations(): Promise<boolean> {
        try {
            const deleteRecommendationPromises: Promise<boolean>[] = [];
            const filteredBatchResponseData: ELRecommendationRequestProcessorData[] = [];
            this.batchResponseData.forEach(async (responseData) => {
                if (responseData.status === CreationsStatus.success) {
                    deleteRecommendationPromises.push(ElementsCreationsService.getInstance().deleteRecommendations(responseData.requestId));
                } else {
                    filteredBatchResponseData.push(responseData);
                }
            });
            this.batchResponseData = filteredBatchResponseData;
            await Promise.all(deleteRecommendationPromises);
            return Promise.resolve(true);
        } catch (error) {
            Logger.log(LogLevel.WARN, "RecommendationBatchProcessor:deleteRequestedCreations failed with error: " + error);
            return Promise.resolve(false);
        }
    }

    getRequestObjForRequestId(requestId: RequestId): Request | null {
        const filteredResponse = this.batchResponseData.filter(data => data.requestId === requestId);
        if (filteredResponse.length) {
            return filteredResponse[0].requestObj;
        }
        return null;
    }

    private _getAndFilterListForErrorRequests(): Request[] {
        const listOfFailedRequestObj: Request[] = [];

        this.batchResponseData.forEach(requestData => {
            const recommendationRequestData = requestData as ELRecommendationRequestProcessorData;
            if (recommendationRequestData.status === CreationsStatus.error) {
                ElementsCreationsService.getInstance().deleteRecommendations(recommendationRequestData.requestId);
                listOfFailedRequestObj.push(recommendationRequestData.requestObj);
            }
        });

        this.batchResponseData = this.batchResponseData.filter((requestData) => requestData.status !== CreationsStatus.error);
        return listOfFailedRequestObj;
    }

    private _updateBatchResponseData(listOfResponseData: ELRecommendationBatchResponseData[]): void {
        listOfResponseData.forEach(data => {
            const responseData: ELRecommendationRequestProcessorData = {
                requestId: data.requestId,
                requestObj: data.requestObj,
                progress: 0,
                status: CreationsStatus.requested
            };
            this.batchResponseData.push(responseData);
        });
    }

    private async _cleanUpWorkflowBeforeRequesting(): Promise<void> {
        this.batchRequestHandler?.destroy();
        this.progress = 0;
        this._notifyProgressChange(this.progress);
        this.deleteRequestedCreations();
        this.batchResponseData = [];
    }

    private _getCurrentStatusForBatchRequests(): ELRecommendationBatchProcessorRequestInfo {
        const batchRequestsStatusInfo: ELRecommendationBatchProcessorRequestInfo = {
            batchProcessor: this,
            requestCount: this.batchResponseData.length,
            successCount: 0,
            failureCount: 0
        };

        this.batchResponseData.forEach(responseData => {
            if (responseData.status === CreationsStatus.success) {
                batchRequestsStatusInfo.successCount++;
            } else if (responseData.status === CreationsStatus.error) {
                batchRequestsStatusInfo.failureCount++;
            }
        });

        return batchRequestsStatusInfo;
    }

    private _notifyForOverallBatchStatus(): void {
        const batchRequestsStatusInfo = this._getCurrentStatusForBatchRequests();
        this.owner.notify({
            type: ELCreationBatchActionTypes.overallBatchRequestsStatus,
            payload: batchRequestsStatusInfo
        });
    }

    private _updateStatusChange(recommendationsStatusData: RecommendationsStatusData): boolean {
        const { requestId, status } = recommendationsStatusData;
        let handled = false;
        this.batchResponseData.forEach(responseData => {
            if (responseData.requestId === requestId) {
                responseData.status = status;
                this._notifyForBatchStatusChange(recommendationsStatusData);
                handled = true;
            }
        });
        return handled;
    }

    private _notifyForBatchStatusChange(recommendationsStatusData: RecommendationsStatusData): void {
        const recommendationStatusPayload = {...recommendationsStatusData, batchProcessor: this} as ELBatchRecommendationStatusData;
        this.owner.notify({
            type: ELCreationBatchActionTypes.recommendationBatchStatusChanged,
            payload: recommendationStatusPayload
        });
    }

    private _notifyProgressChange(progress: number): void {
        const progressChangePayload = {progress: progress, batchProcessor: this} as ELRecommendationBatchProgressData;
        this.owner.notify({
            type: ELCreationBatchActionTypes.updateProgressChange,
            payload: progressChangePayload
        });
    }

    private _updateProgressChange(recommendationProgressPayload: ELRecommendationsProgressPayload): boolean {
        const { progress, requestId } = recommendationProgressPayload;
        let handled = false;
        this.batchResponseData.forEach(responseData => {
                if (responseData.requestId === requestId) {
                    responseData.progress = progress;
                    if (this.progress < progress) {
                        this.progress = progress;
                        this._notifyProgressChange(progress);
                    }
                    handled = true;
                }
            });
        return handled;
    }
}