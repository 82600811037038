/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useCallback, useEffect, useRef, useState } from "react";

import { useButton } from "@react-aria/button";

import { IconType } from "../../../../assets/IconConstants";
import Utils, { DurationParsingFormat } from "../../../../utils/Utils";
import "./ELAudioThumb.scss";
import { ELIcon } from "../el-icon/ELIconView";

enum AudioTagEvents {
    TIME_UPDATE = "timeupdate",
    LOADED_METADATA = "loadedmetadata",
}

export interface ELAudioThumbProps {
    title: string,
    id: string,
    dataTestId: string,
    src: string,
    isSelected: boolean,
    forcedPause?: boolean,
    // Handle play must inform parent controller/view to pass forcedPause to all the other audio thumbs
    handlePlay: ((targetAudioId: string) => void)
}

function classesIfPlayingOrSelected(simplePause: boolean, isSelected: boolean, forcedPause?: boolean): string {
    if (isSelected) {
        return "selected";
    }
    if (!forcedPause && !simplePause)
        return "non-selected playing";
    return "non-selected";
}

function selectIcon(simplePause: boolean, forcedPause?: boolean): IconType {
    if (simplePause || forcedPause)
        return IconType.creationsAudioPanelPlay;
    return IconType.creationsAudioPanelPause;
}

function iconFill(isSelected: boolean): string {
    if (isSelected)
        // SPECTRUM_COLOR_CHECK
        return "#fff";
    return "#6e6e6e";
}

export const ELAudioThumb = (props: ELAudioThumbProps): React.ReactElement => {
    const elAudioThumbRef = useRef<HTMLDivElement>(null);
    const audioTagRef = useRef<HTMLMediaElement>(null);
    const [duration, setDuration] = useState("0:00");
    const [seekTime, setSeekTime] = useState("");
    const [simplePause, setSimplePause] = useState(true);
    const toggle = useCallback((): void => {
        if (!audioTagRef.current?.paused) {
            // this can be called as manual pause
            audioTagRef.current?.pause();
            audioTagRef.current && (audioTagRef.current.currentTime = 0);
            setSimplePause(true);
        } else {
            props.handlePlay(props.id);
            audioTagRef.current?.play();
            setSimplePause(false);
        }
    }, [audioTagRef.current, props.id]);

    const { buttonProps } = useButton({
        onPress: toggle,
        elementType: 'div'
    }, elAudioThumbRef);


    if (props.forcedPause) {
        // Though in first render ref would not be there so it 
        // is more like no op
        audioTagRef.current?.pause();
        audioTagRef.current && (audioTagRef.current.currentTime = 0);
    }

    useEffect(() => {
        const metadataEventListener = (): void => {
            const duration = audioTagRef.current?.duration;
            setDuration(duration ? Utils.getParsedDuration(DurationParsingFormat.mm_ss, duration) : "");
        };
        // can we optimise this? But I think we would need setState anyways
        const timeUpdateListener = (): void => {
            audioTagRef.current?.addEventListener(AudioTagEvents.TIME_UPDATE, () => {
                const currentTime = audioTagRef.current?.currentTime;
                setSeekTime(currentTime
                    ? Utils.getParsedDuration(DurationParsingFormat.mm_ss, Math.floor(currentTime))
                    : "");
            });
        };
        audioTagRef.current?.addEventListener(AudioTagEvents.LOADED_METADATA, metadataEventListener);
        audioTagRef.current?.addEventListener(AudioTagEvents.TIME_UPDATE, timeUpdateListener);
        return () => {
            audioTagRef.current?.removeEventListener(AudioTagEvents.LOADED_METADATA, metadataEventListener);
            audioTagRef.current?.removeEventListener(AudioTagEvents.TIME_UPDATE, timeUpdateListener);
        }
    }, []);

    // Browser loading audio fast - side effect
    useEffect(() => {
        const audioTagReadyState = audioTagRef.current?.readyState;
        if (audioTagReadyState && audioTagReadyState > 0) {
            // Browser can load audio faster than adding the event listener
            // For such cases this code block
            const duration = audioTagRef.current.duration;
            setDuration(audioTagRef.current.duration
                ? Utils.getParsedDuration(DurationParsingFormat.mm_ss, duration)
                : "");
        }
        // No need of clean up
    }, [audioTagRef.current?.readyState, audioTagRef.current?.duration]);

    return (
        // Can we use react-classnames here?
        <div data-test-id={props.dataTestId} ref={elAudioThumbRef} className={`audio-thumb-div 
                ${classesIfPlayingOrSelected(simplePause, props.isSelected, props.forcedPause)}`}
            {...buttonProps}>
            <audio ref={audioTagRef} src={props.src} preload="metadata" loop />
            <ELIcon
                fill={`${iconFill(props.isSelected)}`}
                className="audio-thumb-div__button"
                iconkey={selectIcon(simplePause, props.forcedPause)}
                width={"0.9em"} height={"0.9em"} />
            <span className="audio-thumb-div__title">{props.title}</span>
            <span>
                <span className="audio-thumb-div__seek-time">
                    {seekTime}{seekTime ? "/" : null}
                </span>
                <span className="audio-thumb-div__duration" >
                    {duration}
                </span>
            </span>
        </div>
    );
}
