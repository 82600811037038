/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const THREE_GPP_FILE_FORMAT = "video/3gpp";
export const JPEG_FILE_FORMAT = "image/jpeg";
export const PNG_FILE_FORMAT = "image/png";
export const GIF_FILE_FORMAT = "image/gif";
export const HEIC_FILE_FORMAT = "image/heic";
export const MP4_FILE_FORMAT = "video/mp4";
export const MPEG_FILE_FORMAT = "video/mpeg";
export const MSVIDEO_FILE_FORMAT = "video/x-msvideo";
export const QUICKTIME_FILE_FORMAT = "video/quicktime";
export const M4V_FILE_FORMAT = "video/x-m4v";
export const MS_WMV_FILE_FORMAT = "video/x-ms-wmv";
export const AVI_FILE_FORMAT = "video/avi";
export const MPEG2_FILE_FORMAT = "video/mpeg2";
export const PSDC_FILE_FORMAT = "document/vnd.adobe.cpsd+dcx";
export const PSD_FILE_FORMATS = ["image/vnd.adobe.photoshop", "application/x-photoshop"];

export const FILE_TYPE_FILTER = [
    THREE_GPP_FILE_FORMAT,
    JPEG_FILE_FORMAT,
    PNG_FILE_FORMAT,
    GIF_FILE_FORMAT,
    MP4_FILE_FORMAT,
    MPEG_FILE_FORMAT,
    MSVIDEO_FILE_FORMAT,
    QUICKTIME_FILE_FORMAT,
    M4V_FILE_FORMAT,
    MS_WMV_FILE_FORMAT,
    AVI_FILE_FORMAT,
    MPEG2_FILE_FORMAT,
    HEIC_FILE_FORMAT
];