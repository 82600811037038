/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import ITemplateViewController from "../../../ITemplateViewController";
import IViewController, { ControllerAction } from "../../../IViewController";
import {
    ELCreationFeedbackDialogView,
} from "./ELCreationFeedbackDialogView";
import { ViewAction } from "../../../IBaseController";
import { ELCreationFeedbackCheckboxProps, ELCreationFeedbackDialogAction, ELCreationFeedbackPayload, ELCreationFeedbackProps } from "./ELCreationFeedbackTypes";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IngestEventSubTypes, IngestLogObjectKey, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestLogging } from "../../../../services/IngestWrapper";

const CREATION_FEEDBACK_CHARACTER_LIMIT = 500;

export const DEFAULT_FEEDBACK_POINTS: ELCreationFeedbackCheckboxProps[] = [
    {
        message: "feedback-output-quality",
        ingestString: IngestEventSubTypes.quality
    },
    {
        message: "feedback-performance-issue",
        ingestString: IngestEventSubTypes.speed
    },
    {
        message: "feedback-additional-options",
        ingestString: IngestEventSubTypes.editing
    },
];

export class ELCreationFeedbackDialog extends ITemplateViewController {
    private _feedbackConfig: ELCreationFeedbackProps;

    constructor(owner: IViewController, creationFeedbackProps: ELCreationFeedbackProps) {
        super(owner);
        if(creationFeedbackProps.feedbackCharacterLimit >= CREATION_FEEDBACK_CHARACTER_LIMIT) {
            creationFeedbackProps.feedbackCharacterLimit = CREATION_FEEDBACK_CHARACTER_LIMIT;
        }
        this._feedbackConfig = creationFeedbackProps;
    }

    private _onFeedbackSubmit = ({checkboxFeedback, feedbackMessage}: ELCreationFeedbackPayload): void => {
        // TODO/REVISIT: ingest handling for both kind of feedback.
        const ingestKey = checkboxFeedback;
        const additionalLogInfo: Record<string, string> = {};
        if(feedbackMessage) {
            ingestKey.push(IngestEventSubTypes.others);
            additionalLogInfo[IngestLogObjectKey.uiSearchKeyword] = IngestUtils.sanitizeFeedbackMessage(feedbackMessage);
        }
        const ingestSubType = ingestKey.join("_");

        const ingestWorkflowName = IngestUtils.getIngestCreationsWorkflowName(this._feedbackConfig.creationName);
        const feedbackIngestObject = IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.feedback, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.dislikeFeedback,
            IngestEventSubTypes.success, ingestSubType, ingestWorkflowName, additionalLogInfo));
        
        IngestLogging.getInstance().logEvent(feedbackIngestObject);
        ToastUtils.success(IntlHandler.getInstance().formatMessage("feedback-submitted-successfully"));
        this.destroyView();
    };

    createView(container: HTMLElement): void {
        super.createView(container);
        const feedbackDialog = React.createElement(ELCreationFeedbackDialogView, {
            controller: this,
            feedbackConfig: this._feedbackConfig
        });

        ReactDOM.render(feedbackDialog, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELCreationFeedbackDialogAction.onDismiss: {
                this.destroyView();
                handled = true;
                break;
            }
            case ELCreationFeedbackDialogAction.onFeedbackSubmit: {
                const feedback = action.payload as ELCreationFeedbackPayload;
                this._onFeedbackSubmit(feedback);
                handled = true;
                break;
            }
        }

        if (!handled)
            return this._owner.notify(action);

        return handled;
    }
}