/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { AdobeAsset } from "@dcx/common-types/types/src";
import store from "../stores/store";
import { LinkParams, MAX_ASSETS_SHARE_LIMIT } from "./Constants/Constants";
import { ShareErrorCode, ShareMode, ShareOptionData, ShareType, SHARE_OPTIONS_LIST, ShareTypeCategory, ShareTypeDisplayNameKeys } from "../common/interfaces/share/ShareTypes";
import { IntlHandler } from "../modules/intlHandler/IntlHandler";
import { Routes } from "../app/AppRoute";
import { ElementsShareServiceWrapper } from "../services/ElementsShareServiceWrapper";
import SelectedMediaListAction from "../stores/actions/selectedMediaListActions";
import { ShareAction } from "../stores/actions/ShareAction";
import Logger, { LogLevel } from "./Logger";
import Utils from "./Utils";
import { ShareModeViewData } from "../workspaces/dialog/shareDialog/ShareModeData";
import { WorkflowActionType, WorkflowsName } from "../workspaces/IWorkflow";
import { ControllerAction } from "../view/IViewController";
import { IngestEventTypes, IngestEventSubTypes, ShareInvocationPoint, IngestLogObjectCustomKey, IngestWorkflowTypes, IngestLogObjectKey, IngestLogObjectValue } from "./IngestConstants";
import { IngestUtils } from "./IngestUtils";
import { ToastUtils } from "./ToastUtils";
import { ELTryThisAction, ELTryThisUrlParams } from "../common/interfaces/workflows/TryThisInDesktoptypes";
import { LINK_SOURCE_DESKTOP } from "./RedirectToWebWorkflowsUtils";

export class ShareUtils {
    /**
     * returns true if workflow is desktop to web handover, if url provided in function is invalid returns false
     * @param  {string} href the url in string
     * @returns boolean
     */
    static isDesktopToWebShareHandover(href: string): boolean {
        let url;
        try {
            url = new URL(href);
        } catch (error) {
            Logger.log(LogLevel.WARN, "ShareUtils:isDesktopToWebShareHandover: ", "invalid url for checking desktop to web share handover");
            return false;
        }
        const shareMode = Utils.getLinkParamValue(href, LinkParams.ELEMENTS_SHARE_MODE_PARAM);
        const linkSource = Utils.getLinkParamValue(href, LinkParams.ELEMENTS_LINK_SOURCE_PARAM);
        const route = url.pathname;
        if ((route === Routes.VIEW) && shareMode && (linkSource === LINK_SOURCE_DESKTOP))
            return true;
        return false;
    }

    /**
     * returns true if workflow is link preview workflow
     * @param  {string} route route without "/" eg. "view", "Media"
     * @returns boolean
     */
    static isLinkPreviewWorkflow(): boolean {
        if (!ShareUtils.isDesktopToWebShareHandover(window.location.href) && window.location.pathname === Routes.VIEW)
            return true;
        return false;
    }

    static async getAssetsFromCollection(collectionId: string): Promise<AdobeAsset[]> {
        try {
            return (await ElementsShareServiceWrapper.getInstance().getCollectionData(collectionId)).children;
        } catch (error) {
            return [];
        }
    }

    static async setupDesktopToWebShareWorkflow(): Promise<void> {
        //fetch assets in collection and update the assets in redux store
        const shareMode = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_SHARE_MODE_PARAM);
        const collectionId = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_COLLECTION_ID_PARAM);
        if (collectionId && shareMode) {
            const assets = await ShareUtils.getAssetsFromCollection(collectionId);
            const shareModeEnum = ShareMode[shareMode as keyof typeof ShareMode];
            if (assets.length > 0 && shareModeEnum) {
                store.dispatch(ShareAction.updateShareMode(shareModeEnum));
                store.dispatch(SelectedMediaListAction.updateSelectedMediaList(assets));
                return Promise.resolve();
            }
        }
        return Promise.reject();
    }

    static getShareOptionTextKey(shareMode: ShareMode): string {
        const shareOption = SHARE_OPTIONS_LIST.find((shareOption) => {
            return shareOption.shareMode === shareMode;
        });
        const shareOptionTextKey = shareOption?.text ?? "";

        Logger.log(LogLevel.DEBUG, "ShareUtils:getShareOptionKey: share option text = " + shareOptionTextKey + "for share mode =" + shareMode);
        return shareOptionTextKey;
    }

    static getShareCompleteToastMsg(count: number, shareMode: ShareMode, shareType: ShareType, isDesktopToWebShareHandover = false): string {
        const intlHandler = IntlHandler.getInstance();
        const shareModeDisplayName = intlHandler.formatMessage(this.getShareOptionTextKey(shareMode) ?? "");
        const shareTypeDisplayName = intlHandler.formatMessage(ShareTypeDisplayNameKeys[shareType]);

        if (isDesktopToWebShareHandover) {
            return IntlHandler.getInstance().formatMessage("successfully-uploaded-from-desktop", { count: count });
        } else {
            if (shareMode === ShareMode.email) {
                if (shareType === ShareType.media)
                    return IntlHandler.getInstance().formatMessage("successfully-shared-in-email", { count: count, media: shareTypeDisplayName, shareMode: shareModeDisplayName });
                else {
                    return IntlHandler.getInstance().formatMessage("successfully-shared-asset", { media: intlHandler.formatMessage("creation"), shareMode: shareModeDisplayName });
                }
            }
            else
                return IntlHandler.getInstance().formatMessage("successfully-shared", { count: count, media: shareTypeDisplayName, shareMode: shareModeDisplayName });
        }
    }

    static checkForShareError = (assetCount: number): ShareErrorCode => {
        if (assetCount > MAX_ASSETS_SHARE_LIMIT)
            return ShareErrorCode.shareLimitExceed;
        // else if (!Utils.checkNetworkAccess())
        //     return ShareErrorCode.noNetwork;
        return ShareErrorCode.noError;
    }

    static showShareSuccessToast(link: string, msg: string): void {
        ToastUtils.success(msg, {
            timeout: 0,
            actionLabel: IntlHandler.getInstance().formatMessage("view"),
            onAction: () => {
                if (link) {
                    Utils.openInNewTab(link)
                }
            }
        });
    }

    static showShareErrorToast(errorCode: ShareErrorCode): void {
        let message = "";
        const intlHandler = IntlHandler.getInstance();

        switch (errorCode) {
            // case ShareErrorCode.noNetwork: {
            //     message = intlHandler.formatMessage("no-internet-share");
            //     break;
            // }
            case ShareErrorCode.shareLimitExceed: {
                message = intlHandler.formatMessage("max-share-limit", { count: MAX_ASSETS_SHARE_LIMIT });
                ToastUtils.error(message, {
                    timeout: 0
                });
                return;
            }
        }

        if (message !== "") {
            ToastUtils.error(message, {
                timeout: 5000
            });
        }
    }

    /**
     * returns name of final workflow according to share mode
     */
    static get getShareWorkflowName(): WorkflowsName {
        const shareMode = store.getState().shareReducer.shareMode;
        return ShareModeViewData.getShareModeData(shareMode).shareWorkflow;
    }

    static ingestShareStart(invocationPoint: ShareInvocationPoint, notify: (_: ControllerAction) => void): void {
        switch (invocationPoint) {
            case ShareInvocationPoint.grid:
            case ShareInvocationPoint.mediaGrid: store.dispatch(ShareAction.updateViewType(IngestLogObjectValue.grid));
                break;
            case ShareInvocationPoint.siv: store.dispatch(ShareAction.updateViewType(IngestLogObjectValue.siv));
                break;
            case ShareInvocationPoint.workspace: store.dispatch(ShareAction.updateViewType(IngestLogObjectValue.workspace));
                break;
            case ShareInvocationPoint.managelink: store.dispatch(ShareAction.updateViewType(IngestLogObjectValue.sharedLink));
                break;
            default:
                store.dispatch(ShareAction.updateViewType(""));
        }
    }

    static ingestShareComplete(shareMode: ShareMode, success: boolean, notify: (_: ControllerAction) => void): void {
        const eventSubType = success === true ? IngestEventSubTypes.success : IngestEventSubTypes.error;
        const shareType = IngestUtils.shareTypeToIngest(store.getState().shareReducer.shareType);
        const viewType = store.getState().shareReducer.viewType;
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        if (viewType)
            additionalLogInfo[IngestLogObjectCustomKey.viewType] = viewType;
        notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                eventSubType, IngestEventTypes.share, shareType, additionalLogInfo)
        });
        if (shareType === ShareType.media && viewType !== IngestLogObjectValue.sharedLink) {
            this.ingestAdditionalMediaLogInfo(shareType, notify, additionalLogInfo);
        }
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.shareChannel;
        additionalLogInfo[IngestLogObjectKey.eventCount] = shareMode;
        notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.info, IngestEventTypes.share, shareType, additionalLogInfo)
        });
    }

    static ingestAdditionalMediaLogInfo(shareType: string, notify: (_: ControllerAction) => void,
        additionalLogInfo: Record<string, string>): void {
        const allMedia = store.getState().selectedMediaListReducer;
        if (allMedia.length > 0) {
            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            const eventContextId = Utils.getRandomUUID();

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                        IngestEventSubTypes.info, IngestEventTypes.share, shareType, additionalLogInfoTemp)
                });
            }
        }
    }

    static makePublicLink(collectionId: string): string {
        const publicLink = window.location.origin + Routes.VIEW;
        const url = new URL(publicLink);
        url.searchParams.append(LinkParams.ELEMENTS_COLLECTION_ID_PARAM, collectionId);
        return url.toString();
    }

    static getDefaultShareOptionsForShareModes(shareModes: ShareMode[]): ShareOptionData[] {
        const shareOptions = SHARE_OPTIONS_LIST.filter((option) => {
            return shareModes.includes(option.shareMode);
        });
        return shareOptions;
    }

    static getShareTypeCategory(shareType: ShareType): ShareTypeCategory {
        switch (shareType) {
            case ShareType.slideshow:
            case ShareType.photoCollage:
            case ShareType.patternOverlay:
            case ShareType.peekThrough:
            case ShareType.photoText:
                return ShareTypeCategory.creation;
            case ShareType.media:
            default:
                return ShareTypeCategory.media;
        }
    }

    static isTryNowUrl(url: string): boolean {
        const action = Utils.getLinkParamValue(url, ELTryThisUrlParams.action);
        return (action !== null && ELTryThisAction.toLowerCase() === action.toLowerCase());
    }
}
