/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ShareHandler } from "./ShareHandler";
import { EmailData, ShareResponse, ShareType, ShareTypeDisplayNameKeys } from "../../common/interfaces/share/ShareTypes";
import { EmailNotificationService } from "../NotificationServices/EmailNotificationService";
import Logger, { LogLevel } from "../../utils/Logger";
import IMS from "../IMS";
import { EmailTriggerSource, NotificationRequestBody, NotificationType } from "../NotificationServices/utils/NotificationConstants";
import Constants from "../../utils/Constants/Constants";
import { IntlHandler } from "../../modules/intlHandler/IntlHandler";

class EmailShareHandler extends ShareHandler {
    private _emailService: EmailNotificationService;

    constructor() {
        super();
        this._emailService = new EmailNotificationService();
    }

    async shareLink(link: string, callback: (response: ShareResponse) => void, payload?: unknown): Promise<void> {
        const emailData = payload as EmailData;
        const response: ShareResponse = { link: link }
        try {
            const statusCode = await this._sendEmailNotification(link, emailData);

            if (statusCode === Constants.HTTP_RESPONSE_ACCEPTED_202) {
                Logger.log(LogLevel.INFO, "Email request Accepted : " + statusCode);
                callback(response);
                return Promise.resolve();
            } else {
                Logger.log(LogLevel.ERROR, "EmailShareHandler:shareLink: ", "Error in sending email request ");
                response.errorMessage = "emailService-error-toast-msg";
                callback(response);
                return Promise.reject();
            }
        } catch (err) {
            Logger.log(LogLevel.ERROR, "EmailShareHandler:shareLink: ", "Error in sending email request ", err);
            response.errorMessage = "emailService-error-toast-msg";
            callback(response);
            return Promise.reject(err);
        }
    }

    private _getEventSource(shareType: ShareType): EmailTriggerSource {
        switch (shareType) {
            case ShareType.slideshow:
            case ShareType.photoCollage:
            case ShareType.replaceBackground:
            case ShareType.patternOverlay:
            case ShareType.peekThrough:
            case ShareType.movingOverlay:
            case ShareType.photoText:
                return EmailTriggerSource.eCreationShare;
            case ShareType.media:
            default:
                return EmailTriggerSource.eMediaShare;
        }
    }

    private _getLocalizedEmailShareType(shareType: ShareType): string {
        const intlHandler = IntlHandler.getInstance();
        const localizedShareType = intlHandler.formatMessage(ShareTypeDisplayNameKeys[shareType]);
        return localizedShareType.toLocaleLowerCase();
    }

    private _generatePayload(emailAddressList: string, link: string, emailData: EmailData): NotificationRequestBody {
        const payload: NotificationRequestBody = {
            eventType: NotificationType.eEmail,
            eventSource: this._getEventSource(emailData.shareType),
            eventData: {
                "receiptentList": `${emailAddressList}`,
                "USER_MESSAGE": `${emailData.message}`,
                "SHARE_LINK": `${link}`,
                "SHARE_TYPE": `${this._getLocalizedEmailShareType(emailData.shareType)}`,
            }
        };
        return payload;
    }

    async _sendEmailNotification(link: string, emailData: EmailData): Promise<number> {
        try {
            let emailAddressList = emailData.receiptentList;
            if (emailData.sendCopyValue) {
                const currentUserMailId = IMS.getInstance().getUserEmail();
                if (currentUserMailId)
                    emailAddressList += ", " + currentUserMailId;
            }
            const payload = this._generatePayload(emailAddressList, link, emailData);
            const response = await this._emailService.sendEmail(payload);
            Logger.log(LogLevel.INFO, "Response status code for Email Notification service", response.status);
            return response.status;
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "EmailShareHandler:_sendEmailNotification: ", "Unable to send email notification for shared media", error);
            return Promise.reject(error);
        }
    }
}

export { EmailShareHandler }