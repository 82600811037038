/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { TQInitParams, tqtypes, TQWeb, TQLogLevel, TQFont } from '@coretech/typequestweb';

//Application Specific
import IMS from '../IMS';
import Utils from '../../utils/Utils';
import Logger, { LogLevel } from '../../utils/Logger';
import store from '../../stores/store';

enum FontLoadStatus {
    NOT_LOADED,
    LOADING,
    LOADED,
    UNLOADABLE,
}

type FontInfo = {
    postscriptName: string
    familyName: string
    styleName: string
    psFamilyIndex: number
    psStyleIndex: number
    loadStatus: FontLoadStatus
    webID: string
}

type LoadedFontInfo = {
    postscriptName: string
    familyName: string
    styleName: string
    fontBuffer: Uint8Array
    webID: string
}

type LoadingCallback = (info: LoadedFontInfo) => boolean

/**
 * Class for managing fonts.
 */
export class FontsStore {
    private static sharedInstance?: FontsStore;
    private usedFonts = new Map<string, FontInfo>();
    private tqWeb?: TQWeb;
    private tqInitParams?: TQInitParams;

    /**
     * Returns the shared instance of the FontsStore class.
     * @returns The shared instance of the FontsStore class.
     */
    static getInstance(): FontsStore {
        if (!FontsStore.sharedInstance) {
            FontsStore.sharedInstance = new FontsStore();
        }
        return FontsStore.sharedInstance;
    }

    /**
     * Adds a font to the usedFonts map and sets the hasBegunLoadingFonts flag to true.
     * @param newFont - The font to add.
     */
    private addFontToUsedFonts = (newFont: FontInfo): void => {
        this.usedFonts.set(newFont.postscriptName, newFont);
    }

    /**
     * Reports the usage of a font.
     * @param postscriptName - The postscript name of the font.
     */
    private reportUsedFont(postscriptName: string, usageOperation: tqtypes.FontUsageOperation): void {
        // PREVIEW should be sent when a user (anonymous or logged-in) is using royalty-bearing fonts in a document. 
        // Elements Web only uses only free fonts hence not sending PREVIEW event.
        if(usageOperation === tqtypes.FontUsageOperation.PREVIEW) {
            return;
        }
        const doc = store.getState().docStateReducer.activeDoc
        const docID = doc?.getDocumentId ?? 'elWebDocID';
        this.tqWeb?.reportUseFonts([{ postscriptName: postscriptName }], docID, usageOperation);
    }

    /**
     * Loads a font asynchronously.
     * @param name - The name of the font.
     * @param callback - The callback function to invoke after loading the font.
     * @returns A promise that resolves once the font is loaded.
     */
    private loadFont = async (name: string, callback: LoadingCallback, usageOperation: tqtypes.FontUsageOperation): Promise<void> => {
        await this.tqWeb?.findFonts([name], tqtypes.OperationMode.DATA, [], (font: TQFont, error: tqtypes.Error): boolean => {
            if (error.errCode !== tqtypes.ErrorCode.NONE) {
                return callback({ postscriptName: name, familyName: '', styleName: '', fontBuffer: new Uint8Array(), webID: '' });
            }

            const { postscriptName, familyName, styleName, fontBuffer } = font;
            const loadedFont = { postscriptName, familyName, styleName, fontBuffer, webID: font.fontId };

            const fontFace: any = new window.FontFace(loadedFont.postscriptName, loadedFont.fontBuffer);
            fontFace.load().then(() => {
                document.fonts.add(fontFace);
                this.onFontLoaded(loadedFont, usageOperation);
            });

            return callback(loadedFont);
        });
    }

    /**
     * Loads a font asynchronously.
     * @param postscriptName - The postscript name of the font.
     * @returns A promise that resolves to true if the font is loaded successfully, or false otherwise.
     */
    private loadFontAsync = async (postscriptName: string, usageOperation: tqtypes.FontUsageOperation): Promise<boolean> => {
        if (this.tqWeb) {
            const fontIdentifier: tqtypes.TQFontIdentifer = {
                postscriptName: postscriptName,
            };
            const map = await this.tqWeb.findFontsAsync(new Set([fontIdentifier]), tqtypes.OperationMode.DATA);
            const findFont = await map?.get(fontIdentifier);
            const font = findFont?.font;

            if (findFont?.error.errCode === tqtypes.ErrorCode.NONE && font) {
                const fontFace: any = new FontFace(
                    font.postscriptName,
                    font.fontBuffer
                );

                const { postscriptName, familyName, styleName, fontBuffer } = font;
                const loadedFont = { postscriptName, familyName, styleName, fontBuffer, webID: font.fontId }

                fontFace.load().then(() => {
                    document.fonts.add(fontFace);
                    this.onFontLoaded(loadedFont, usageOperation);
                    return true;
                }).catch((e: any) => {
                    Logger.log(LogLevel.ERROR, "ERROR adding font", e);
                    return false;
                });
            } else {
                Logger.log(LogLevel.ERROR, postscriptName, findFont?.error);
                return false;
            }
        }
        return false;
    }

    /**
     * Callback function invoked when a font is loaded.
     * @param info - The loaded font information.
     * @returns True if the font is loaded successfully, or false otherwise.
     */
    private onFontLoaded = (info: LoadedFontInfo, usageOperation: tqtypes.FontUsageOperation): boolean => {
        const { postscriptName, familyName, styleName } = info;
        const newFontInfo = {
            postscriptName,
            familyName,
            styleName,
            psFamilyIndex: 0,
            psStyleIndex: 0,
            loadStatus: FontLoadStatus.LOADED,
            webID: info.webID,
        };
        this.addFontToUsedFonts(newFontInfo);
        this.reportUsedFont(postscriptName, usageOperation);
       
        return true;
    }

    /**
     * Initializes TypeKit by setting up authentication parameters and starting the TypeQuest Web instance.
     * @returns A promise that resolves once TypeKit is successfully initialized.
     */
    public initTypeKit = async (): Promise<void> => {
        // Get the user access token and other necessary information
        const token = IMS.getInstance().getUserAccessToken() || "";
        const userID = IMS.getInstance().getUserId() || "";
        const clientID = process.env.REACT_APP_IMS_API_KEY!;
        const env = process.env.REACT_APP_ENV!;

        // Set up authentication parameters for TypeQuest Web
        const authParams = new tqtypes.AuthParams(clientID, userID, token);
        authParams.isStaging = env !== "prod";
        this.tqInitParams = new TQInitParams(authParams);
        this.tqInitParams.appEntitlement = tqtypes.AppEntitlement.FREE;
        this.tqInitParams.logLevel = TQLogLevel.INFO;

        // Set up status update callback
        this.tqInitParams.statusUpdateCallback = (status: tqtypes.StatusCode, error: tqtypes.Error) => {
            Logger.log(LogLevel.DEBUG, "Status updated to " + status + " with error code " + tqtypes.ErrorCode[error.errCode]);
            if (status == tqtypes.StatusCode.DONE) {
                Logger.log(LogLevel.INFO, "TypeQuest Web is successfully initialized");
            } else if (status == tqtypes.StatusCode.ERROR_OCCURED) {
                Logger.log(LogLevel.ERROR, "TypeQuest Web faced issue " + tqtypes.ErrorCode[error.errCode] + " in initialization");
            }
            return true;
        };

        // Set up recent fonts update callback
        this.tqInitParams.recentFontsUpdateCallback = (_fonts: TQFont[]): boolean => {
            return false;
        }

        // Set up runtime mode and application fonts collection type
        this.tqInitParams.runtimeMode = tqtypes.RuntimeMode.FOUND_FONTS;
        this.tqInitParams.applicationFontsCollectionType = tqtypes.ApplicationFontsType.PHOTOSHOP;

        // Create and start the TypeQuest Web instance
        this.tqWeb = new TQWeb(this.tqInitParams);
        this.tqWeb.updateLanguage(Utils.getCurrentLanguage() as tqtypes.Language);
        await this.tqWeb.start();
    }

    /**
     * Checks if TypeKit is initialized.
     * @returns True if TypeKit is initialized, or false otherwise.
     */
    public isInitialized = (): boolean => {
        return this.tqInitParams !== undefined
    }

    /**
     * Updates the authentication parameters for TypeKit.
     * @returns A promise that resolves once the authentication parameters are updated.
     */
    public updateAuthParams = async (): Promise<void> => {
        const token = IMS.getInstance().getUserAccessToken() || "";
        const userID = IMS.getInstance().getUserId() || "";
        
        await this.tqWeb?.updateAuthParams(userID, token);
    }

    /**
     * Preloads the used fonts.
     * @param usedPostscriptNames - The postscript names of the used fonts.
     * @returns A promise that resolves once the fonts are preloaded.
     */
    public preloadUsedFonts = async (usedPostscriptNames: string[]): Promise<void> => {
        for (const psName of usedPostscriptNames) {
            if (!this.usedFonts || this.usedFonts?.get(psName) || this.usedFonts?.get(psName)?.loadStatus === FontLoadStatus.NOT_LOADED) {
                await this.checkCacheAndLoadFont(psName, tqtypes.FontUsageOperation.OPEN);
            }
        }
    }

    /**
     * Checks the cache and loads a font.
     * @param postscriptName - The postscript name of the font.
     * @returns A promise that resolves to true if the font is loaded successfully, or rejects with an error message otherwise.
     */
    public async checkCacheAndLoadFont(postscriptName: string, usageOperation = tqtypes.FontUsageOperation.APPLY): Promise<boolean>  {
        if (!this.tqWeb) {
            return Promise.reject("TypeQuest Web is not initialized. Please initialize TypeQuest Web before calling this API.");
        }

        const fontInfo = this.usedFonts.get(postscriptName);
        if (!fontInfo || fontInfo.loadStatus === FontLoadStatus.NOT_LOADED) {
            if (fontInfo) {
                fontInfo.loadStatus = FontLoadStatus.LOADING;
            }
            await this.loadFontAsync(postscriptName, usageOperation);
            return Promise.resolve(true);
        } else if(fontInfo.webID !== "") {
            this.reportUsedFont(postscriptName, usageOperation);
            return Promise.resolve(true);
        }

        return Promise.reject("Unknown error occurred while loading font. (DEBUG: this should never come)");
    }
}