/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import { useEffect, useState } from "react";

//Adobe Internal
import { addToast, removeToast, Toast } from "@react/react-spectrum/Toast";

//Application Specific
import { IntlHandler } from "../../modules/intlHandler/IntlHandler";
import Constants from "../Constants/Constants";

interface NetworkState {
    online: boolean
}

export function useNetwork(showToast = true): NetworkState {
    const NO_INTERNET_CONNECTION_TOAST = "no-internet-connection-toast";

    const [state, setState] = useState(() => {
        return {
            online: window.navigator.onLine,
            toast: new Toast({
                variant: "error",
                timeout: Constants.TOAST_NO_TIMEOUT as number,
                closable: false,
                children: IntlHandler.getInstance().formatMessage("no-internet-app"),
                id: NO_INTERNET_CONNECTION_TOAST
            })
        };
    });

    useEffect(() => {
        const handleOnline = (): void => {

            setState((prevState) => ({
                ...prevState,
                online: true
            }));

            //remove internet connection toast if present
            if (document.querySelector(`#${NO_INTERNET_CONNECTION_TOAST}`) && showToast) {
                removeToast(state.toast, undefined);
            }
        }

        const handleOffline = (): void => {
            setState((prevState) => ({
                ...prevState,
                online: false
            }));

            //show internet connection issue toast
            if (showToast) {
                addToast(state.toast, Constants.TOAST_NO_TIMEOUT as number, undefined);
            }
        }

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            //remove internet connection toast if present
            if (document.querySelector(`#${NO_INTERNET_CONNECTION_TOAST}`) && showToast) {
                removeToast(state.toast, undefined);
            }
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        }
    }, []);
    return state;
}