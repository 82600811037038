/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//utils
import Utils from "../../../../../utils/Utils";
import Logger, { LogLevel } from "../../../../../utils/Logger";

//services
import ElementsContentService from "../../../../../services/ElementsServices/ElementsContentService";
import { ContentType } from "../../../../../services/ElementsServices/BaseContentService";


//Application Specific
import { ASSETS_ENDPOINT, CollageTemplateData } from "../../../../../common/interfaces/creations/ElementsContentTypes";
import { ELCollageTemplate } from "../../../../../common/interfaces/creations/ELCollageTypes";
import store from "../../../../../stores/store";
import { FilterMediaData, FilterMediaUiProp, GridMediaType, MediaGridConfig, MediaGridSortBy } from "../../../../../stores/actions/mediaGridConfigActions";
import { GIF_FILE_FORMAT, PSDC_FILE_FORMAT, PSD_FILE_FORMATS } from "../../../../../common/interfaces/storage/FileTypes";
import { GRID_CONFIG_KEY } from "../../../../../stores/reducers/mediaGridConfigReducer";
import { ELCollageTemplateBackground, ELCollageTemplateBackgroundMode } from "../../../../../common/interfaces/creations/ELCollageTypes";
import { CollageBackgroundData } from "../../../../../common/interfaces/creations/ElementsContentTypes";
import { Routes } from "../../../../../app/AppRoute";
import { CreationsJobProjectSubType, CreationsMode, CreationsStatusPayload, CreationsURLParams } from "../../../../../common/interfaces/creations/CreationTypes";
import { WorkflowActionType, WorkflowsName } from "../../../../IWorkflow";
import { ELAdobeAsset } from "../../../../../common/interfaces/storage/AssetTypes";
import { ELStageBackground, ELStageBackgroundMode } from "../../../../../common/interfaces/stage/StageTypes";
import { AssetStorageUtils } from "../../../../../utils/AssetStorageUtils";
import { WorkspaceAction, WorkspacePayload } from "../../../../IBaseWorkspace";
import CreationUtils from "../../../utils/CreationUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../../utils/IngestUtils";
import { MediaManagerControllerAction } from "../../../../../common/interfaces/workflows/MediaManagerTypes";
import { ReplaceMediaManagerWorkflowAction } from "../../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import DeleteIcon from "./../../../../../../src/assets/icons/EL_delete_N.svg";
import ReplaceIcon from "./../../../../../../src/assets/icons/EL_asset_type_N.svg";
import { ELQuickAction } from "../../../../../view/components/organism/el-quick-action-container/ELQuickActionContainer";
import IViewController from "../../../../../view/IViewController";


export default class CollageUtils {

    /**
    * @returns backgroundURL or color
    **/
    static async getBackground(background: ELCollageTemplateBackground): Promise<string> {
        switch (background.mode) {
            case ELCollageTemplateBackgroundMode.image: {
                const backgroundList = await ElementsContentService.getInstance().getContent(ContentType.collageBackground) as CollageBackgroundData[];
                const backgroundData = backgroundList.filter((data) => data.id === background.value).map((ele) => { return ele })[0];
                return process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + backgroundData.contentUrl;
            }
            case ELCollageTemplateBackgroundMode.embeddedFromPSD: {
                const backgroundList = await ElementsContentService.getInstance().getContent(ContentType.collageDefaultBackground) as CollageBackgroundData[];
                const backgroundData = backgroundList.filter((data) => data.id === background.value).map((ele) => { return ele })[0];
                return process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + backgroundData.contentUrl;
            }
            default:
                return background.value;
        }
    }

    static getStageBackground(background: ELCollageTemplateBackground): ELStageBackground {
        switch (background.mode) {
            case ELCollageTemplateBackgroundMode.image:
            case ELCollageTemplateBackgroundMode.embeddedFromPSD: {
                const stageBackground: ELStageBackground = { mode: ELStageBackgroundMode.image, value: background.value };
                return stageBackground;
            }
            default: {
                const stageBackground: ELStageBackground = { mode: ELStageBackgroundMode.color, value: background.value };
                return stageBackground;
            }
        }
    }

    /**
     * @returns MediaGridConfig for collage workflow
     **/
    static getCollageMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);
        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageOnly;
        const filterData: FilterMediaData = {
            format: [PSDC_FILE_FORMAT, GIF_FILE_FORMAT, ...PSD_FILE_FORMATS],
            uiProp: FilterMediaUiProp.hide
        }
        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 8;
        mediaGridConfig.maxImageCount = 8;
        mediaGridConfig.minImageCount = 2;
        mediaGridConfig.maxVideoCount = 0;
        mediaGridConfig.maxTotalMediaSize = 400; //in MB
        mediaGridConfig.maxVideoLength = 0;
        mediaGridConfig.selectUploadedMedia = true;
        return mediaGridConfig;
    }

    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = AssetStorageUtils.filterRemoveVideoAssets(assets);
        filteredAssets = AssetStorageUtils.filterRemoveGifAssets(filteredAssets);
        filteredAssets = CreationUtils.filterImagesByCount(filteredAssets, mediaGridConfig);

        Logger.log(LogLevel.INFO, "CollageUtils - getFilteredAssetList: ", filteredAssets);
        return filteredAssets;
    }

    /**
     * @returns boolean - true if collage url is present
     */
    static hasCollageUrl(): boolean {
        const workflow = Utils.getLinkParamValue(window.location.href, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.collage.toLowerCase() === workflow.toLowerCase());
    }

    /**
     * @returns string - history state based on projectId
     */
    static getCollageHistoryState(projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + WorkflowsName.collage.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    /**
     * @returns string - collage id from window href
     */
    static getCollageIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    /**
     * @returns WorkspacePayload
     */
    static getCollagePayload(): WorkspacePayload {
        const projectId = CollageUtils.getCollageIdFromHref();
        let mode = CreationsMode.render;
        let payload: CreationsStatusPayload | ELAdobeAsset[] | null = { projectId: projectId };

        if (projectId === Utils.getNilUUID()) {
            mode = CreationsMode.create;
            payload = store.getState().selectedMediaListReducer;
        }

        const workspacePayload = {
            startWorkflow: WorkflowsName.collage,
            initMode: mode,
            payload: payload
        };

        return workspacePayload;
    }

    /**
     * @returns list of templates with matching layoutCount
     */
    static filterTemplateByLayoutCount(templates: CollageTemplateData[], layoutCount: number): CollageTemplateData[] {
        const filteredTemplateList = templates.filter((template) => {
            if (parseInt(template.layout) === layoutCount)
                return true;
            return false;
        });

        return filteredTemplateList;
    }

    /**
    * @returns template with matching id
    */
    static async filterTemplateById(templates: CollageTemplateData[], id: string): Promise<ELCollageTemplate> {
        const filteredTemplateList = templates.filter((template) => {
            if (template.id === id)
                return true;
            return false;
        });
        return await CollageUtils.fetchTemplate(filteredTemplateList[0]);
    }

    /**
    * @returns fetch template data from server
    */
    static async fetchTemplate(template: CollageTemplateData): Promise<ELCollageTemplate> {
        return await ElementsContentService.getInstance().getContentFromURI(template.contentUrl) as ELCollageTemplate;
    }

    /**
    * @returns object URL for asset
    */
    static getAssetFullResObjectURL(assetId: string): string | undefined {
        let objectURL: string | undefined = undefined;
        const fullResData = store.getState().fullResMediaReducer.filter((fullResData) => {
            if (fullResData.assetId === assetId)
                return true;
            return false;
        })[0];

        if (fullResData) {
            objectURL = fullResData.objectURL;
        }
        return objectURL;
    }

    /**
    * @returns object URL for asset
    */
    static getAssetObjectURL(assetId: string): string | undefined {
        let objectURL: string | undefined = CollageUtils.getAssetFullResObjectURL(assetId);

        if (!objectURL) {
            const assetThumb = store.getState().mediaThumbReducer.filter((ele) => ele.assetId === assetId);
            if (assetThumb.length > 0) {
                objectURL = assetThumb[0].objectURL;
            }
        }

        return objectURL;
    }

    static canStartCollage(selectedAssets: ELAdobeAsset[]): boolean {
        const minImageCount = CollageUtils.getCollageMediaGridConfig().minImageCount;
        if (minImageCount && selectedAssets.length >= minImageCount)
            return true;
        return false;
    }

    static isMaxImageLimitExceeded(selectedAssets: ELAdobeAsset[]): boolean {
        const maxImageCount = CollageUtils.getCollageMediaGridConfig().maxImageCount;
        if ((maxImageCount !== undefined) && selectedAssets.length > maxImageCount)
            return true;
        return false;
    }

    static isOpeningExistingCollage(workspaceAction: WorkspaceAction): boolean {
        return workspaceAction.initMode === CreationsMode.render;
    }

    /**
     * @returns ELQuickAction list
     */
    static getQuickActions(controller: IViewController): ELQuickAction[] {
        const quickActions = [
            {
                actionKey: "delete",
                iconSrc: DeleteIcon,
                onClickHandler: () => {
                    const additionalLogInfo: Record<string, string> = {};
                    additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
                    controller.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                                                                IngestEventTypes.click, 
                                                                IngestEventSubTypes.delete, 
                                                                CreationsJobProjectSubType.photoCollage,
                                                                additionalLogInfo) })
    
                    controller.notify({
                        type: MediaManagerControllerAction.mediaDeleted,
                        payload: {}
                    })
    
                }
            },
            {
                actionKey: "replace",
                iconSrc: ReplaceIcon,
                onClickHandler: () => {
                    const additionalLogInfo: Record<string, string> = {};
                    additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
                    controller.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                                                                IngestEventTypes.click, 
                                                                IngestEventSubTypes.replace, 
                                                                CreationsJobProjectSubType.photoCollage, 
                                                                additionalLogInfo) })
    
                    controller.notify({
                        type: ReplaceMediaManagerWorkflowAction.replaceActiveMedia,
                        payload: {}
                    })
                }
            }
        ];
    
        return quickActions;
    }
    
}
