/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { KeyboardKey } from "./KeyboardConstants";
import { PlatformUtils } from "./PlatformUtils";

export default class KeyEventUtils {
    static isDeleteKeyPressed(e: KeyboardEvent): boolean {
        const isRunningOnMac = PlatformUtils.isRunningOnMacOS();
        return (e.key === KeyboardKey.backspace && isRunningOnMac) || (e.key === KeyboardKey.delete && !isRunningOnMac);
    }

    static isComposingEscape(event: KeyboardEvent): boolean {
        return event.key.toLowerCase() === KeyboardKey.escape && event.isComposing;
    }
    
    static isComposingEnter(event: KeyboardEvent): boolean {
        return event.key.toLowerCase() === KeyboardKey.enter && event.isComposing;
    }
}