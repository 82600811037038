/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { Key, useEffect, useState } from "react";

//Adobe Internal
import { Flex, Item, Menu, MenuTrigger, Text } from "@adobe/react-spectrum";

//Application Specific
import IViewController from "../../../IViewController";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IconType } from "../../../../assets/IconConstants";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

//Utils
import { Theme } from "../../../../utils/Theme";

import "./ELHelpMenuNavView.scss";

export enum ELHelpMenuItems {
    faqs = "faqs",
    supportCommunity = "support-community",
}
interface ELHelpMenuNavProps {
    controller: IViewController
}

export const ELHelpMenuNavView = (props: ELHelpMenuNavProps): React.ReactElement => {
    const [isHover, setHover] = useState(false);

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller])

    const helpLinkIcon = (<ELCustomIcon iconkey={isHover ? IconType.helpMenuFillIcon : IconType.helpMenuIcon} color={Theme.global.gray_controls_color_H}
        width={"1.6rem"} height={"1.6rem"} cursor={"pointer"} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} variant={"independent"} />);

    const onHelpMenuKeyAction = (key: Key): void => {
        props.controller.notify({
            type: key as string
        });
    }

    const helpMenuItems = [
        { name: ELHelpMenuItems.faqs },
        { name: ELHelpMenuItems.supportCommunity },
    ];

    const helpMenuList = helpMenuItems.map((item) =>
        <Item key={item.name} textValue={"menuItem"}>
            <Text data-testid={"el-help-menu-" + item.name} UNSAFE_className="menu-item-text">{IntlHandler.getInstance().formatMessage(item.name)}</Text>
        </Item>
    )

    return (
        <ELReactSpectrumV3Provider height="100%" width="100%">
            <MenuTrigger align="end" >
                <Flex UNSAFE_className={"help-menu-icon"}>
                    {helpLinkIcon}
                </Flex>
                <Menu data-testid="el-help-menu" onAction={(key) => onHelpMenuKeyAction(key)}>
                    {helpMenuList}
                </Menu>
            </MenuTrigger>
        </ELReactSpectrumV3Provider>
    );
}