/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { KeyboardEvent, useState } from "react";

//Adobe Internal
import { Button, Dialog, Content, Divider, Heading, TextField, useDialogContainer, Flex, Image } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { MAX_PROJECT_TITLE_LENGTH } from "../../../../common/interfaces/creations/CreationTypes";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELRenameMedia.scss";

export interface ELRenameMediaProps {
    onSave: (title: string) => void,
    dialogHeading: string,
    defaultTitle: string,
    thumbURL?: string,
    isValidTitle?: (title: string) => boolean,
    onCancel?: () => void,
}

export const ELRenameMediaView = (props: ELRenameMediaProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const dialog = useDialogContainer();
    const [title, setTitle] = useState(props.defaultTitle);

    const onSave = (): void => {
        dialog.dismiss();
        props.onSave(title);
    }

    const onKeyDown = (e: KeyboardEvent): void => {
        if (e.key === "Enter") {
            onSave();
        }
    };

    const isValidTitle = (): boolean => {
        return title.length !== 0 && title !== props.defaultTitle;
    }

    const isSaveDisabled = (): boolean => {
        return props.isValidTitle ? !props.isValidTitle(title) :  !isValidTitle();
    }

    const onChangeRenameTextField = (userInput: string): void => {
        const parseInputTitle = userInput.trimStart();
        setTitle(parseInputTitle);
    }

    const onCancel = (): void => {
        dialog.dismiss();
        if (props.onCancel) {
            props.onCancel();
        }
    }

    return (<ELReactSpectrumV3Provider key="rename_creation" >
        <Dialog width="25vw" isDismissable onDismiss={onCancel}>
            <Heading>{props.dialogHeading}</Heading>
            <Divider />
            <Content>
                <Flex gap="size-500" direction="column">
                    <Flex marginTop="size-200" gap="size-400">
                        {props.thumbURL && <Image width="10%" src={props.thumbURL} alt={props.defaultTitle} />}
                        <TextField maxLength={MAX_PROJECT_TITLE_LENGTH} onChange={onChangeRenameTextField} autoFocus defaultValue={props.defaultTitle} width="100%" onKeyDown={onKeyDown} />
                    </Flex>
                    <Flex gap="size-100" justifyContent="end">
                        <Button variant="secondary" onPress={onCancel} UNSAFE_className="rename-media__cta">{intlHandler.formatMessage("cancel")}</Button>
                        <Button isDisabled={isSaveDisabled()} variant="cta" onPress={onSave} UNSAFE_className="rename-media__cta">{intlHandler.formatMessage("save")}</Button>
                    </Flex>
                </Flex>
            </Content>
        </Dialog>
    </ELReactSpectrumV3Provider>
    )
}