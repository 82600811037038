/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { useEffect } from "react";

//Application specific
import EditWorkspace from "./EditWorkspace";

export interface IEditWorkspaceProps {
    controller: EditWorkspace
}

const EditWorkspaceView = (props: IEditWorkspaceProps): React.ReactElement => {

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <>
            <div id="edit-workspace-container" />
            <div id="edit-workspace-modal-container" />
        </>
    )
};

export default EditWorkspaceView;