/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React, { useEffect, useState } from "react";

// Adobe internal
import { Content, Dialog, DialogContainer, Divider, Flex, Heading, Text, TextArea } from "@adobe/react-spectrum";
import { KeyboardEvent } from "@react-types/shared";

// Application Specific
import ELButton from "../../atoms/el-button/ELButtonView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import IViewController, { ControllerAction } from "../../../IViewController";
import KeyEventUtils from "../../../../utils/KeyEventUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELFeedbackDialogView.scss";

interface ELFeedbackDialogViewProps {
    controller: IViewController,
    heading: string,
    feedbackQuestion: string,
    feedbackCharacterLimit: number,
    message: string,
}

export enum ELFeedbackDialogAction {
    onDismiss = "ON_DISMISS",
    onFeedbackSubmit = "FEEDBACK_SUBMIT",
    onCTA = "ON_CTA"
}

export const ELFeedbackDialogView = (props: ELFeedbackDialogViewProps): React.ReactElement => {

    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller])

    const intlHandler = IntlHandler.getInstance();
    const [feedback, setFeedback] = useState("");

    const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFeedback(e.target.value);
    }

    const onKeyDown = (e: KeyboardEvent): void => {
        if(KeyEventUtils.isComposingEscape(e.nativeEvent)) {
            e.stopPropagation();
        } else {
            e.continuePropagation();
        }
    }

    const handleSubmit = (feedback: string): void => {
        const action: ControllerAction = { type: ELFeedbackDialogAction.onFeedbackSubmit, payload: feedback };
        props.controller.notify(action);
    }

    const notifyDismiss = (): void => {
        const action: ControllerAction = { type: ELFeedbackDialogAction.onDismiss };
        props.controller.notify(action);
    }

    return (
        <ELReactSpectrumV3Provider height="100%" width="100%">
            <DialogContainer onDismiss={() => notifyDismiss()} isDismissable>
                <Dialog data-testid="feedback-dialog" UNSAFE_className={"feedback-dialog__container"}>
                    <Heading>
                        <div data-testid="feedback-dialog__heading" className="feedback-dialog__title">{intlHandler.formatMessage(props.heading)}</div>
                    </Heading>
                    <Divider size={"S"} />
                    <Content>
                        <Flex direction="column" alignItems="start" gap={"size-50"}>
                            <div data-testid="feedback-dialog__question"
                                className="feedback-dialog__question">{intlHandler.formatMessage(props.feedbackQuestion)}</div>
                            <TextArea data-testid="feedback-dialog__text-area"
                                onInput={handleFeedbackChange} placeholder={intlHandler.formatMessage("type-here")}
                                width="100%" height="9.375rem" id="feedback-dialog__text-area"
                                maxLength={props.feedbackCharacterLimit} onKeyDown={onKeyDown}/>
                            <p data-testid="feedback-dialog__message"
                                className="feedback-dialog__message">{intlHandler.formatMessage(props.message)}</p>
                            <Flex alignSelf="center" marginTop="1.75rem">
                                <ELButton dataTestId="feedback-dialog__submit" size="L"
                                    onClick={() => handleSubmit(feedback)} isDisabled={feedback.length <= 0} >
                                    <Text UNSAFE_className="dialog__cta-text">{intlHandler.formatMessage("submit")}</Text>
                                </ELButton>
                            </Flex>
                        </Flex>
                    </Content>
                </Dialog>
            </DialogContainer>
        </ELReactSpectrumV3Provider>
    );
}