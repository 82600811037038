/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { Provider as ReactReduxProvider } from "react-redux";

//Adobe Internal
import { warning } from "@react/react-spectrum/Toast";

//Application Specific
import { ViewAction } from "../../../../view/IBaseController";
import ReplaceMediaManagerView from "./ReplaceMediaManagerView";
import IBaseWorkspace, { WorkspaceActionType } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../../IWorkflow";
import ELMediaGrid, { ELMediaGridData, SelectedMediaListType } from "../../../../view/components/organism/el-mediagrid/ELMediaGrid";
import useMediaFetch, { MediaSource } from "../../../../utils/hooks/useMediaFetch";
import Constants from "../../../../utils/Constants/Constants";
import { ControllerAction } from "../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import store from "../../../../stores/store";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import {
    ReplaceMediaManagerViewAction,
    ReplaceMediaManagerControllerAction,
    ReplaceMediaManagerWorkflowAction,
    ReplaceMediaManagerMode,
    ReplaceMediaManagerPayload
} from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Utils from "../../../../utils/Utils";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectValue } from "../../../../utils/IngestConstants";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { ELNoMediaBanner } from "../../../../view/components/molecules/el-no-media-banner/ELNoMediaBannerView";
import { ELIcon } from "../../../../view/components/atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import ELMediaUploader from "../../../../view/components/templates/el-media-uploader/ELMediaUploader";
import { ELImportButtonAction } from "../../../../view/components/organism/el-import-button/ELImportButton";
import { MediaGridWorkflowActions } from "../../../organizer/workflows/mediaGrid/MediaGrid";
import { ELMediaSelectionMode } from "../../../../common/interfaces/media/ELThumbTypes";
import MediaGridConfigAction, { MediaGridConfig } from "../../../../stores/actions/mediaGridConfigActions";

class ReplaceMediaManager extends IWorkflow {
    private _grid?: ELMediaGrid;
    private _selectedAssets: SelectedMediaListType;
    private _initialSelectedAssets: SelectedMediaListType;
    private _mediaGridConfig: MediaGridConfig;
    private _initialMediaGridConfig: MediaGridConfig;
    private _continueState: boolean;
    private readonly _mediaSelectionWarningToastId = "media-selection-warning-toast-id";
    private _replaceMediaPayload: ReplaceMediaManagerPayload = {} as ReplaceMediaManagerPayload;
    private _mediaUploader: ELMediaUploader | undefined;
    private readonly _importProgressContainer = "import-progress-container";

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.replaceMediaManager);
        this._selectedAssets = [];
        this._initialSelectedAssets = store.getState().selectedMediaListReducer;
        this._initialMediaGridConfig = this._mediaGridConfig = store.getState().mediaConfigReducer[GRID_CONFIG_KEY];
        this._continueState = false;
        this._mediaUploader = undefined;
    }

    set setContinueState(state: boolean) {
        this._continueState = state;
    }

    get getContinueState(): boolean {
        return this._continueState;
    }

    private async _showWarningMessage(selectedAssets: SelectedMediaListType): Promise<boolean> {
        let warn = false;
        warn = warn || this._showWarningMessageForImageCount(selectedAssets);
        warn = warn || this._showWarningMessageForTotalMediaSize(selectedAssets);
        warn = warn || await this._showWarningMessageForVideoLength(selectedAssets);

        return warn;
    }

    private _showWarningMessageForTotalMediaSize(selectedAssets: SelectedMediaListType): boolean {
        let totolMediaSize = 0;
        for (let i = 0; i < selectedAssets.length; i++) {
            const size = selectedAssets[i].size;
            totolMediaSize += size ? Utils.getBytesToMB(size) : 0;
        }

        let warn = false;
        if ((this._mediaGridConfig.maxTotalMediaSize !== undefined) && totolMediaSize > this._mediaGridConfig.maxTotalMediaSize) {
            warn = true;
            if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                const message = IntlHandler.getInstance().formatMessage("max-total-media-size-limit",
                    { max_size: this._mediaGridConfig.maxTotalMediaSize });
                warning(message, { id: this._mediaSelectionWarningToastId });
            }
        }

        return warn;
    }

    private async _showWarningMessageForVideoLength(selectedAssets: SelectedMediaListType): Promise<boolean> {
        let warn = false;
        for (let i = 0; i < selectedAssets.length; i++) {
            if (Utils.isVideoMimeType(selectedAssets[i])) {
                try {
                    const embeddedMetadata = await AssetStorageUtils.getAndUpdateMetadata(selectedAssets[i]);
                    const videoDuration = AssetStorageUtils.getVideoDuration(embeddedMetadata);

                    if ((this._mediaGridConfig.maxVideoLength !== undefined) && videoDuration > this._mediaGridConfig.maxVideoLength) {
                        warn = true;
                        if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                            const message = IntlHandler.getInstance().formatMessage("max-video-length-limit",
                                { max_duration: this._mediaGridConfig.maxVideoLength / 60 });
                            warning(message, { id: this._mediaSelectionWarningToastId });
                        }
                    }

                    return warn;
                } catch (error) {
                    Logger.log(LogLevel.WARN, "MediaManager:_showWarningMessageForVideoLength: ", error);
                }
            }
        }

        return warn;
    }

    private _showWarningMessageForImageCount(selectedAssets: SelectedMediaListType): boolean {
        const selectedImageCount = AssetStorageUtils.parsePhotoCount(selectedAssets);

        let warn = false;
        if (selectedImageCount > 1) {
            warn = true;
            if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                const message = IntlHandler.getInstance().formatMessage("image-count-limit", { image_count: 1 });
                warning(message, { id: this._mediaSelectionWarningToastId });
            }
        }

        return warn;
    }

    private _updateContinueControl(selectedAssets: SelectedMediaListType, warn: boolean): void {
        const isReplacedAssetSame = (selectedAssets.length === 1) && (selectedAssets[0].assetId === this._replaceMediaPayload.assetId);
        const disableControl = (selectedAssets.length !== 1 || isReplacedAssetSame || warn);

        this.setContinueState = !disableControl;

        if (this.viewDispatcher) {
            this.viewDispatcher({
                type: ReplaceMediaManagerViewAction.updateContinueButtonState,
                payload: disableControl
            });
        }
    }

    private async _onSelectedAssetChange(selectedAssets: SelectedMediaListType): Promise<void> {
        const warn = await this._showWarningMessage(selectedAssets);
        this._updateContinueControl(selectedAssets, warn);
    }

    private _getEmptyBanner(): React.ReactElement {
        const emptyElement = React.createElement('div', null);
        return React.createElement(ELNoMediaBanner, {
            icon: React.createElement(ELIcon, {
                iconkey: IconType.emptyELMediaGrid,
                width: "10rem",
                height: "10rem"
            }),
            heading: IntlHandler.getInstance().formatMessage("no-media-banner-media-manager"),
            textBody: emptyElement
        });
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private _updateMediaListOnExit(selectedAssets: ELAdobeAsset[]): void {
        if (this._replaceMediaPayload.mode === ReplaceMediaManagerMode.replacingMedia) {
            const updatedAssetList = this._initialSelectedAssets.filter((asset) => {
                if (asset.assetId === this._replaceMediaPayload.assetId) {
                    return false;
                }
                return true;
            });
            this._initialSelectedAssets = [...updatedAssetList, selectedAssets[0]];
        } else if (this._replaceMediaPayload.mode === ReplaceMediaManagerMode.addingMissingMedia) {
            this._initialSelectedAssets = [...this._initialSelectedAssets, selectedAssets[0]];
        }

        //remove duplicates
        this._initialSelectedAssets = this._initialSelectedAssets.filter((asset1, index, array) =>
            array.findIndex(asset2 => asset1.assetId === asset2.assetId) === index);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const replaceMediaManager: React.ReactElement = React.createElement(ReplaceMediaManagerView, {
            controller: this,
            title: "replace-media"
        });

        const reduxWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, replaceMediaManager);

        ReactDOM.render(
            reduxWrappedProvider,
            container,
            (): void => {
                const dirPath = Constants.ELEMENTS_PHOTOS_PATH as string;
                const mediaSourceType = MediaSource.repoDirDataFetch;

                const repoDirFetchHook = _.partial(useMediaFetch, _, mediaSourceType);

                const setSelectedAssets = (arr: SelectedMediaListType): void => {
                    this._selectedAssets = arr;

                }
                const emptyBanner = this._getEmptyBanner();
                const mediaGridData: ELMediaGridData = {
                    workflow: this,
                    mediaFetchHookFunc: repoDirFetchHook,
                    dirPath: dirPath,
                    createTracks: true,
                    emptyGridBanner: emptyBanner,
                    selectionEnabled: true,
                    setSelectedMediaAssetsInWorkflow: setSelectedAssets,
                    selectionMode: ELMediaSelectionMode.SINGLE_CLICK
                };
                this._grid = new ELMediaGrid(mediaGridData);
                this._grid.createView(this.ensureHTMLElement("replace-media-manager-grid-container"));
            }
        )
    }

    destroyView(): void {
        this._grid?.destroyView();
        this._mediaUploader?.destroyView();
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this._grid = undefined;
        this._selectedAssets = [];
    }

    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow, action?: T): void {
        this._replaceMediaPayload = action?.payload as ReplaceMediaManagerPayload;
        this._mediaGridConfig = this._replaceMediaPayload.mediaGridConfig;

        store.dispatch(MediaGridConfigAction.updateConfig(this._mediaGridConfig));
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));

        super.startWorkflow<T>(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(this._initialSelectedAssets));
        store.dispatch(MediaGridConfigAction.updateConfig(this._initialMediaGridConfig));
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ReplaceMediaManagerControllerAction.toolbarCancel: {
                this._ingest(IngestUtils.getPseudoLogObject(this.getWorkflowName ?? "", IngestEventTypes.click, IngestEventSubTypes.cancel));
                const workspaceAction = { type: WorkspaceActionType.endWorkflow };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ReplaceMediaManagerControllerAction.toolbarContinue: {
                this._ingest(IngestUtils.getPseudoLogObject(this.getWorkflowName ?? "", IngestEventTypes.click, IngestEventSubTypes.continue, this._selectedAssets.length));
                const selectedAssets = this._selectedAssets; //store it before ending workflow
                const prevWorkflow = this.prevWorkflow;

                this._updateMediaListOnExit(selectedAssets);

                const endModalWorkflowAction = { type: WorkspaceActionType.endWorkflow };
                this._owner.notify(endModalWorkflowAction);

                const workflowPayload = {
                    nextWorkflow: this.initAction?.nextWorkflow,
                    nextWorkflowInitMode: this.initAction?.nextWorkflowInitMode,
                    payload: {
                        assetIdToReplace: this._replaceMediaPayload.assetId,
                        assetToReplaceWith: selectedAssets[0],
                        data: this._replaceMediaPayload.data
                    }
                };
                const workflowAction = { type: ReplaceMediaManagerWorkflowAction.replaceMediaSelection, ...workflowPayload };
                prevWorkflow?.notify(workflowAction);
                handled = true;
                break;
            }
            case ReplaceMediaManagerControllerAction.mediaChanged: {
                this._onSelectedAssetChange(action.payload as SelectedMediaListType);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "ReplaceMediaManager(notify): Bad action" + action);
            }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELImportButtonAction.startImport: {
                this._mediaUploader = new ELMediaUploader(this);
                this._mediaUploader.createView(this.ensureHTMLElement(this._importProgressContainer));
                this._mediaUploader.handleImport(Array.from(action.payload as FileList), IngestLogObjectValue.creations);
                handled = true;
                break;
            }
            case MediaGridWorkflowActions.stopUpload: {
                if (this._mediaUploader) {
                    this._mediaUploader.stopImport();
                }
                handled = true;
                break;
            }
            case MediaGridWorkflowActions.retryUpload: {
                if (this._mediaUploader) {
                    this._mediaUploader.retryImport();
                }
                handled = true;
                break;
            }
            default:
                handled = await super.notifyWorkflow(action);
                break;
        }
        return handled;
    }
}

export default ReplaceMediaManager;
