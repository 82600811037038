/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React from "react";

import "./ELQuickActionContainer.scss";

export interface ELQuickAction {
    actionKey: string;
    iconSrc: string;
    onClickHandler: () => void;
}

export interface ELQuickActionContainerProps {
    showQuickActions: boolean;
    quickActions: ELQuickAction[];
    children?: React.ReactNode;
}

export const ELQuickActionContainer = (props: ELQuickActionContainerProps): React.ReactElement => {
    return (

        <div style={{position: "relative"}}>
            {props.showQuickActions ? 
                <div className="quick-action-container">
                    {props.quickActions.map((quickAction) => {
                        return(
                            <img  className="quick-action"
                                key={quickAction.actionKey}
                                src={quickAction.iconSrc} 
                                onClick= {quickAction.onClickHandler}
                                onKeyDown={quickAction.onClickHandler}
                                alt={quickAction.actionKey}
                                role="button"
                                tabIndex={0}
                            ></img>)
                    })}
                </div>
                : null}

            {props.children}
        </div>
    )
}