/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from "react";
import ReactDOM from "react-dom"
import { Provider as ReactReduxProvider } from "react-redux";

import IViewController, { ControllerAction } from "../../../IViewController";
import ELMediaGridToolBarView from "./ELMediaGridToolbarView";
import store from "../../../../stores/store";
import { ViewAction } from "../../../IBaseController";
import IWorkflow from "../../../../workspaces/IWorkflow";
import { MediaGridWorkflowActions } from "../../../../workspaces/organizer/workflows/mediaGrid/MediaGrid";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import MediaGridConfigAction, { MediaGridSortBy, MediaGridSortOrder } from "../../../../stores/actions/mediaGridConfigActions";

export enum ELMediaGridToolbarControllerAction {
    startImport = "START_IMPORT",
    changeImportProgressState = "CHANGE_IMPORT_PROGRESS_STATE",
    changeFilterEnableState = "CHANGE_FILTER_STATE"
}

export enum ELMediaImportAction {
    changeImportProgressState = "CHANGE_IMPORT_PROGRESS_STATE",
    changeImportProgressBarVisibility = "CHANGE_IMPORT_PROGRESS_BAR_VISIBILITY"
}

export enum ELMediaGridSortPref {
    kSortBy = 'SORT_BY',
    kSortOrder = 'SORT_ORDER'
}

class ELMediaGridToolBar extends IViewController {

    private _workflow: IWorkflow;

    constructor(workflow: IWorkflow) {
        super();
        this._workflow = workflow;
    }

    createView(container: HTMLElement): void {
        super.createView(container)
        const toolbar = React.createElement(ELMediaGridToolBarView, {
            controller: this
        });

        const providerHydratedToolbar = React.createElement(ReactReduxProvider, { store: store }, toolbar);
        const viewPortAwareToolbar = React.createElement(ViewportProvider, null, providerHydratedToolbar);

        ReactDOM.render(
            viewPortAwareToolbar,
            container
        );
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELMediaGridToolbarControllerAction.startImport: {
                if (this._workflow)
                    handled = await this._workflow.notify({ type: MediaGridWorkflowActions.startImport, payload: action.payload });
                break;
            }
            case ELMediaGridToolbarControllerAction.changeImportProgressState: {
                this.viewDispatcher?.({ type: ELMediaImportAction.changeImportProgressState, payload: action.payload as boolean });
                handled = true;
                break;
            }
        }

        if (!handled) {
            handled = await this._workflow.notify(action);
        }

        return handled;
    }


    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container)
        }
        super.destroyView()
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this.readPrefs();
    }

    subscribeToSortPrefChange(sortBy: MediaGridSortBy, sortOrder: MediaGridSortOrder): void {
        let previousSortByValue = sortBy;
        let previousSortOrderValue = sortOrder;
        const saveSortPref = (sortBy: MediaGridSortBy, sortOrder: MediaGridSortOrder): void => {
            const currentSortByValue = store.getState().mediaConfigReducer[GRID_CONFIG_KEY].sortBy;
            if (previousSortByValue !== currentSortByValue) {
                this.writePrefs([{ key: ELMediaGridSortPref.kSortBy, value: store.getState().mediaConfigReducer[GRID_CONFIG_KEY].sortBy }]);
                previousSortByValue = currentSortByValue;
            }
            const currentSortOrderValue = store.getState().mediaConfigReducer[GRID_CONFIG_KEY].sortOrder;
            if (previousSortOrderValue !== currentSortOrderValue) {
                this.writePrefs([{ key: ELMediaGridSortPref.kSortOrder, value: store.getState().mediaConfigReducer[GRID_CONFIG_KEY].sortOrder }]);
                previousSortOrderValue = currentSortOrderValue;
            }
        }
        store.subscribe(() => { saveSortPref(sortBy, sortOrder) });
    }

    destroy(): void {
        super.destroy();
        this.destroyView();
    }

    readPrefs(): void {
        const userPrefsortBy = (localStorage.getItem(ELMediaGridSortPref.kSortBy) as MediaGridSortBy) ?? MediaGridSortBy.importDate;
        const userPrefsortOrder = (localStorage.getItem(ELMediaGridSortPref.kSortOrder) as MediaGridSortOrder) ?? MediaGridSortOrder.descending;
        const payload = store.getState().mediaConfigReducer[GRID_CONFIG_KEY];
        payload.sortBy = userPrefsortBy;
        payload.sortOrder = userPrefsortOrder;
        store.dispatch(MediaGridConfigAction.updateConfig(payload));
        this.subscribeToSortPrefChange(userPrefsortBy, userPrefsortOrder);
    }
}

export default ELMediaGridToolBar;
