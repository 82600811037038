/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//Third Party
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Application Specific
import { ELSampleMediaGridData } from "../../../../common/interfaces/media/ELSampleMediaTypes";
import { ELSampleMediaGridView } from "./ELSampleMediaGridView";
import store from "../../../../stores/store";
import IWorkflow, { WorkflowActionType, WorkflowsName } from "../../../../workspaces/IWorkflow";
import { ViewAction } from "../../../IBaseController";
import { sampleMediaData } from "./ELSampleMediaGridData";
import { SampleMediaGridViewAction, SampleMediaManagerControllerAction } from "../../../../common/interfaces/workflows/SampleMediaManagerTypes";
import ITemplateViewController from "../../../ITemplateViewController";
import { ControllerAction } from "../../../IViewController";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { ELImportButtonAction } from "../el-import-button/ELImportButton";

export class ELSampleMediaGrid extends ITemplateViewController {
    private _workflow: IWorkflow;
    private readonly _workflowName: WorkflowsName;
    private _mediaData: ELSampleMediaGridData[] = [];

    constructor(workflow: IWorkflow, workflowName: WorkflowsName = WorkflowsName.patternOverlay) {
        super(workflow);
        this._workflow = workflow;
        this._workflowName = workflowName;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._fetchMedia(this._workflowName);
        this._updateView();
        this._logSampleMediaRendered();
    }

    private _logSampleMediaRendered(): void {
        const mediaSelectionScreenText = "media-selection-screen";
        const mediaGridType = "sample-media";
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = mediaGridType;
        additionalLogInfo[IngestLogObjectKey.contentName] = mediaSelectionScreenText;
        const ingestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success,
            IngestEventSubTypes.render, IngestUtils.getIngestCreationsWorkflowName(this._workflowName), additionalLogInfo);
        this.notify({ type: WorkflowActionType.ingest, payload: ingestPayload });
    }

    private _fetchMedia(workflowName: WorkflowsName): void {
        // Below commented code is to filter media based on workflowName which is not currently used but is a requirement
        // Please toggle the commented code below to see the filtered media based on workflowName
        // const mediaData = sampleMediaData.filter((media) => media.useInCreation.includes(workflowName));
        // this._mediaData = mediaData.map((media) => media.data);
        this._mediaData = sampleMediaData.map((media) => media.data);
    }

    private _updateView(): void {
        this.viewDispatcher?.call(this.viewDispatcher, { type: SampleMediaGridViewAction.updateView, payload: this._mediaData });
    }

    private _onMediaClick = (key: number): void => {
        const selectedMedia = [this._mediaData[key].src];
        this._workflow.notify({ type: SampleMediaManagerControllerAction.updateSelectedSampleMediaAssets, payload: selectedMedia });
        this._workflow.notify({ type: SampleMediaManagerControllerAction.updateContinueButtonState, payload: true });
    };

    updateProgressText = (progressText: string): void => {
        this.viewDispatcher?.call(this.viewDispatcher, { type: SampleMediaGridViewAction.updateProgressText, payload: progressText });
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const sampleMediaGrid = React.createElement(ELSampleMediaGridView, { controller: this, onMediaClick: this._onMediaClick });
        const reactProvider = React.createElement(Provider, { store: store }, sampleMediaGrid);

        ReactDOM.render(reactProvider, container);
    }

    destroyView(): void {
        super.destroyView();
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case SampleMediaManagerControllerAction.handleDroppedFiles: {
                await this._workflow.notify(action);
                handled = true;
                break;
            }
            case ELImportButtonAction.startImport: {
                const uploadMediaAction = { type: SampleMediaManagerControllerAction.mediaImportedWithUploadMedia, payload: action.payload };
                await this._workflow.notify(uploadMediaAction);
                handled = true;
                break;
            }
            default:
                break;
        }
        if (!handled) {
            handled = await this._workflow.notify(action);
        }

        return Promise.resolve(handled);
    }

}