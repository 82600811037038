/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    ExportConfigurationType,
    ImageConfigurationInfoType,
    ImageExportOperationSubTypeConfiguration,
    ImageExportOperationSubTypeConfigurationInfo,
    RequestInvocationMode,
    ELAdobeAsset,
    SequenceExportOperationSubTypeConfigurationInfo,
    SequenceConfigurationInfoType,
    SequenceExportOperationSubTypeConfiguration,
    ExportPreset,
    RenderQuality,
    OperationContentType,
    Constants,
    Request,
    Asset,
    AssetData,
    RequestType,
    StorageType,
    CreationType,
    Operation,
    CreationOperationTypeInfo,
    CreationOperationTypeOutput,
    ExportOperationTypeInfo,
    CreationOperationTypeOutputInfo,
    ImageExportOperationSubTypeInfo,
    RootFolderName,
    CreationOperationSubTypeInfo,
    CreationOperationSubTypeConfigurationCompositePostEdit,
    CreationOperationSubTypeConfigurationCrop,
    CreationOperationSubTypeInfoCompositeSettings,
    Languages,
} from "@elements/elementswebcommon";

//Application Specific
import { ELSlideshowProjectRequestParams } from "../../../common/interfaces/creations/SlideshowTypes";
import { ELPatternOverlayCreationRequestParams } from "../../../common/interfaces/creations/ELPatternOverlayTypes";
import { ELMovingOverlayCreationRequestParams } from "../../../common/interfaces/creations/ELMovingOverlayTypes";

import { ELCollageProjectRequestParams } from "../../../common/interfaces/creations/ELCollageTypes";
import Logger, { LogLevel } from "../../../utils/Logger";
import Utils from "../../../utils/Utils";
import { ELImageExportConfigurationParams, IBaseRequestParams } from "../../../common/interfaces/creations/ELCreationsJobTypes";
import { CompositeSettings, ELCreationDocumentDetails } from "../../../common/interfaces/creations/CreationTypes";
import { LocaleType } from "../../../common/interfaces/intl/LocaleTypes";

export type ELCreationJobRequestPayload = ELSlideshowProjectRequestParams | ELPatternOverlayCreationRequestParams | ELMovingOverlayCreationRequestParams | ELCollageProjectRequestParams;

export abstract class CreationsJobCreator {

    private readonly _currentRequestVersion = 3;
    private readonly _currentOperationVersion = 3;
    private readonly _currentOperationTypeInfoVersion = 1;
    private readonly _currentOperationSubTypeInfoVersion = 1;
    private readonly _currentConfigurationVersion = 1;
    private readonly _currentOutputVersion = 1;
    private readonly _currentOutputInfoVersion = 1;

    getRequestObject(requestParameters: IBaseRequestParams): Request {
        try {
            const request = new Request();
            request.language = this._getRequestBodyLanguage(Utils.getCurrentLocaleInSnakeCase());
            request.version = this.requestVersion;
            request.assets = this._getAssetList(requestParameters.assets);
            request.title = requestParameters.title ?? this._getProjectDefaultTitle();
            request.requestInvocationMode = requestParameters.requestInvocationMode ?? this._getRequestInvocationMode();
            request.operations = this._getCreationsOperations(requestParameters);
            return request;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationsJobCreator:getRequestObject ", error);
            throw error;
        }
    }

    getRequestBody(requestParameters: IBaseRequestParams): unknown {
        try {
            const requestObj = this.getRequestObject(requestParameters);
            const requestJson = requestObj.serializeOut();
            return requestJson;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationsJobCreator:getRequestBody ", error);
            throw error;
        }
    }

    protected createImageExportOperationSubTypeInfo(imageExportOperationSubTypeConfigurationParams: ELImageExportConfigurationParams[]): ImageExportOperationSubTypeInfo {
        const exportOperationSubTypeInfo = new ImageExportOperationSubTypeInfo();
        exportOperationSubTypeInfo.configurations = imageExportOperationSubTypeConfigurationParams.map(imageExportOperationSubTypeConfiguration => {
            return this._createImageExportOperationSubTypeConfiguration(imageExportOperationSubTypeConfiguration);
        });
        return exportOperationSubTypeInfo;
    }

    protected createOperationOutput(requestParameters: IBaseRequestParams): CreationOperationTypeOutput | undefined {
        const output = new CreationOperationTypeOutput();
        output.version = this.outputVersion;
        output.outputInfo = this._createOutputInfo(requestParameters);
        return output;
    }

    protected createOperationSubTypeInfoCompositeSettings(compositeSettings: CompositeSettings): CreationOperationSubTypeInfoCompositeSettings {
        const operationSubTypeInfoCompositeSettings = new CreationOperationSubTypeInfoCompositeSettings();
        operationSubTypeInfoCompositeSettings.width = compositeSettings.width;
        operationSubTypeInfoCompositeSettings.height = compositeSettings.height;
        operationSubTypeInfoCompositeSettings.layoutId = compositeSettings.layoutId;
        return operationSubTypeInfoCompositeSettings;
    }

    protected abstract getCreationType(): CreationType;
    protected abstract createOperationSubTypeInfo(requestParameters: IBaseRequestParams): CreationOperationSubTypeInfo | undefined;
    protected abstract createExportOperationTypeInfo(requestParameters: IBaseRequestParams): ExportOperationTypeInfo | undefined;

    protected get requestVersion(): number {
        return this._currentRequestVersion;
    }

    protected get operationVersion(): number {
        return this._currentOperationVersion;
    }

    protected get operationTypeInfoVersion(): number {
        return this._currentOperationTypeInfoVersion;
    }

    protected get operationSubTypeInfoVersion(): number {
        return this._currentOperationSubTypeInfoVersion;
    }

    protected get configurationVersion(): number {
        return this._currentConfigurationVersion;
    }

    protected get outputVersion(): number {
        return this._currentOutputVersion;
    }

    protected get outputInfoVersion(): number {
        return this._currentOutputInfoVersion;
    }

    protected _getProjectDefaultTitle(): string {
        return Constants.DEFAULT_TITLE;
    }

    protected _getRequestInvocationMode(): RequestInvocationMode {
        return RequestInvocationMode.user;
    }

    protected _getAssetList(assetList: ELAdobeAsset[]): Asset[] {
        const assetInfoList: Asset[] = [];
        for (const asset of assetList) {
            const mediaInfo: AssetData = {
                id: Utils.getRandomUUID(),
                assetURN: asset.assetId ?? "",
                storageType: StorageType.RAPI,
                mimeType: asset.format ?? "image/jpeg"
            };
            const assetObj = new Asset();
            assetObj.assetData = mediaInfo;
            assetInfoList.push(assetObj);
        }
        return assetInfoList;
    }

    protected _getImageExportOperationSubTypeConfigurationInfo(imageConfigurationInfo: ImageConfigurationInfoType): ImageExportOperationSubTypeConfigurationInfo {
        const configInfoObj = new ImageExportOperationSubTypeConfigurationInfo();
        configInfoObj.configurationInfo = imageConfigurationInfo;
        return configInfoObj;
    }

    protected _getImageExportOperationSubTypeConfiguration(exportOperationConfiguration: ExportConfigurationType,
        imageConfigurationInfo: ImageConfigurationInfoType): ImageExportOperationSubTypeConfiguration {

        const exportOperationConfigurationObj = new ImageExportOperationSubTypeConfiguration();
        exportOperationConfigurationObj.operationConfiguration = exportOperationConfiguration;
        exportOperationConfigurationObj.configurationInfo = this._getImageExportOperationSubTypeConfigurationInfo(imageConfigurationInfo);

        return exportOperationConfigurationObj;
    }

    protected _getSequenceExportOperationSubTypeConfigurationInfo(sequenceConfigurationInfo: SequenceConfigurationInfoType): SequenceExportOperationSubTypeConfigurationInfo {
        const configInfoObj = new SequenceExportOperationSubTypeConfigurationInfo();
        configInfoObj.configurationInfo = sequenceConfigurationInfo;
        return configInfoObj;
    }

    protected _getSequenceExportOperationSubTypeConfiguration(exportOperationConfiguration: ExportConfigurationType,
        sequenceConfigurationInfo: SequenceConfigurationInfoType): SequenceExportOperationSubTypeConfiguration {
        const exportOperationConfigurationObj = new SequenceExportOperationSubTypeConfiguration();
        exportOperationConfigurationObj.operationConfiguration = exportOperationConfiguration;
        exportOperationConfigurationObj.configurationInfo = this._getSequenceExportOperationSubTypeConfigurationInfo(sequenceConfigurationInfo);

        return exportOperationConfigurationObj;
    }

    protected _getExportPreset(operationContentType: OperationContentType): ExportPreset {
        const preset: ExportPreset = new ExportPreset();
        preset.operationContentData = {
            id: operationContentType.id,
            contentType: operationContentType.contentType,
            mimetype: operationContentType.mimetype,
            resolution: operationContentType.resolution
        };
        return preset;
    }

    protected _getSequenceConfigurationInfoType(sequencePayload: SequenceConfigurationInfoType): SequenceConfigurationInfoType {
        const sequenceConfigurationInfo: SequenceConfigurationInfoType = {
            preset: sequencePayload.preset,
            hardwareAccelerated: sequencePayload.hardwareAccelerated ?? true,
            startTime: sequencePayload.startTime ?? 0,
            endTime: sequencePayload.endTime ?? 360000000,
            renderQuality: sequencePayload.renderQuality ?? RenderQuality.high
        };
        return sequenceConfigurationInfo;
    }

    protected createProjectEditInfo(documentDetails: ELCreationDocumentDetails): CreationOperationSubTypeConfigurationCompositePostEdit {
        const configObj = new CreationOperationSubTypeConfigurationCompositePostEdit();

        configObj.crop = new CreationOperationSubTypeConfigurationCrop();
        configObj.crop.leftBound = documentDetails.layoutInfo.left
        configObj.crop.rightBound = documentDetails.layoutInfo.right
        configObj.crop.bottomBound = documentDetails.layoutInfo.bottom;
        configObj.crop.topBound = documentDetails.layoutInfo.top;

        return configObj;
    }

    protected createProjectSettings(documentDetails: ELCreationDocumentDetails): CreationOperationSubTypeInfoCompositeSettings {
        const layoutInfoObj = new CreationOperationSubTypeInfoCompositeSettings();
        layoutInfoObj.width = Math.floor(documentDetails.size.width * documentDetails.layoutInfo.scaleX);
        layoutInfoObj.height = Math.floor(documentDetails.size.height * documentDetails.layoutInfo.scaleY);
        layoutInfoObj.layoutId = documentDetails.layoutInfo.layoutId;
        return layoutInfoObj;
    }

    private _getRequestBodyLanguage(locale: string): Languages {
        switch (locale as LocaleType) {
            case LocaleType.frFR: {
                return Languages.frFR;
            }
            case LocaleType.deDE: {
                return Languages.deDE;
            }
            case LocaleType.jaJP: {
                return Languages.jaJP;
            }
            case LocaleType.enUS: {
                return Languages.enUS;
            }
            default: {
                return Languages.en;
            }
        }
    }

    private _createImageExportOperationSubTypeConfiguration(imageExportOperationSubTypeConfiguration: ELImageExportConfigurationParams): ImageExportOperationSubTypeConfiguration {
        const randomUniqueId = Utils.getRandomUUID();

        const exportOperationConfiguration: ExportConfigurationType = {
            tag: imageExportOperationSubTypeConfiguration.outputType,
            root: RootFolderName.cloudContent,
            fileName: imageExportOperationSubTypeConfiguration.fileName,
            storageType: StorageType.RAPI,
            representativeFolderPath: randomUniqueId
        };

        const imageConfigurationInfo: ImageConfigurationInfoType = {
            exportFormat: imageExportOperationSubTypeConfiguration.exportFormat,
            width: imageExportOperationSubTypeConfiguration.exportWidth,
            height: imageExportOperationSubTypeConfiguration.exportHeight
        };

        const exportOperationConfigurationObj = this._getImageExportOperationSubTypeConfiguration(
            exportOperationConfiguration,
            imageConfigurationInfo
        );
        exportOperationConfigurationObj.id = randomUniqueId;

        return exportOperationConfigurationObj;
    }

    private _createExportOperation(requestParams: IBaseRequestParams): Operation[] {
        const exportOperation = new Operation();
        exportOperation.id = Utils.getRandomUUID();
        exportOperation.version = this.operationVersion;
        exportOperation.operationType = RequestType.export;
        exportOperation.operationTypeInfo = this.createExportOperationTypeInfo(requestParams);

        return [exportOperation];
    }

    private _createOutputInfo(requestParameters: IBaseRequestParams): CreationOperationTypeOutputInfo {
        const outputInfo = new CreationOperationTypeOutputInfo();
        outputInfo.version = this.outputInfoVersion;
        outputInfo.exportOperations = this._createExportOperation(requestParameters);
        return outputInfo;
    }

    private _createOperationTypeInfo(requestParameters: IBaseRequestParams): CreationOperationTypeInfo {
        const operationTypeInfo = new CreationOperationTypeInfo();
        operationTypeInfo.version = this.operationTypeInfoVersion;
        operationTypeInfo.operationSubType = this.getCreationType();
        operationTypeInfo.operationSubTypeInfo = this.createOperationSubTypeInfo(requestParameters);
        operationTypeInfo.output = this.createOperationOutput(requestParameters);

        return operationTypeInfo;
    }

    private _getCreationsOperations(requestParameters: IBaseRequestParams): Operation[] {
        const operation = new Operation();
        operation.version = this.operationVersion;
        operation.id = Utils.getRandomUUID();
        operation.operationType = RequestType.creation;
        operation.operationTypeInfo = this._createOperationTypeInfo(requestParameters);
        return [operation];
    }
}