/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";
import { Provider as ReactReduxProvider } from "react-redux";

//Adobe Internal
import { Flex, TextField, Divider, Text } from "@adobe/react-spectrum";
import OverlayTrigger from "@react/react-spectrum/OverlayTrigger";
import Button from "@react/react-spectrum/Button";
import Tooltip from "@react/react-spectrum/Tooltip";

//Application Specific
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import { ShareOptionsView } from "../../../../../view/components/organism/el-share-options/ELShareOptionsView";
import store from "../../../../../stores/store";
import IViewController from "../../../../../view/IViewController";
import { ShareOptions } from "../../../../../view/components/organism/el-share-options/ELShareOptions";
import { ELReactSpectrumV2Provider } from "../../../../../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV2Provider";
import { ELReactSpectrumV3Provider } from "../../../../../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./LinkShareView.scss";

interface LinkShareViewProps {
    controller: IViewController,
    link: string,
    shareOptions: ShareOptions
}

export enum LinkShareControllerAction {
    shareOptionPressed = "SHARE_OPTION_PRESSED",
    copyPressed = "COPY_PRESSED"
}

export const LinkShareView = (props: LinkShareViewProps): React.ReactElement => {

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const intlHandler = IntlHandler.getInstance();
    const shareOptions = props.shareOptions;

    const handleCopy = (): void => {
        props.controller.notify({ type: LinkShareControllerAction.copyPressed });
    }

    const handleShare = (): void => {
        props.controller.notify({ type: LinkShareControllerAction.shareOptionPressed });
    }

    return (
        <ELReactSpectrumV3Provider width="100%">
            <Flex direction="column" alignItems="start" gap="size-200" UNSAFE_className="link-share-view">
                <Flex direction="row" alignItems="end" width="95%">
                    <TextField name="link_text_field" label={intlHandler.formatMessage("public-link")} alignSelf="stretch" flexGrow={2} isReadOnly defaultValue={props.link} />
                    <OverlayTrigger trigger="click" onClick={handleCopy} placement="bottom">
                        <Button variant="action" id="link-share-view__copy-button">{intlHandler.formatMessage("copy")}</Button>
                        <ELReactSpectrumV2Provider id="link-share-view__v2-provider">
                            <Tooltip variant="success" placement="bottom">
                                {intlHandler.formatMessage("link-copied")}
                            </Tooltip>
                        </ELReactSpectrumV2Provider>
                    </OverlayTrigger>
                </Flex>
                <Divider size="M" />
                <Text UNSAFE_className="link-share-view__other-options">{intlHandler.formatMessage("other-options")}</Text>
                <ReactReduxProvider store={store}>
                    <ShareOptionsView controller={shareOptions} onPress={() => { handleShare() }} showQRView={false} />
                </ReactReduxProvider>
            </Flex>
        </ELReactSpectrumV3Provider>
    )
}
