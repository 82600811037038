/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ReactComponent as AppLogo } from "./icons/EL_appLogo_N.svg";
import { ReactComponent as PSEAppLogo } from "./icons/EL_PSEAppLogo_N.svg";
import { ReactComponent as PREAppLogo } from "./icons/EL_preAppLogo_N.svg";
import { ReactComponent as HomeIcon } from "./icons/nav-bar-icons/EL_home_N.svg";
import { ReactComponent as CreationIcon } from "./icons/nav-bar-icons/EL_creations_N.svg";
import { ReactComponent as MediaIcon } from "./icons/nav-bar-icons/EL_media_N.svg";
import { ReactComponent as LearnIcon } from "./icons/nav-bar-icons/EL_learn_N.svg";
import { ReactComponent as CloudStorageIcon } from "./icons/nav-bar-icons/EL_cloudStorage_N.svg";
import { ReactComponent as CloudStorageWarnIcon } from "./icons/nav-bar-icons/EL_cloudStorageWarn_N.svg";
import { ReactComponent as FeedbackIcon } from "./icons/EL_feedback_N.svg";
import { ReactComponent as FeedbackNavIcon } from "./icons/nav-bar-icons/EL_feedback_N.svg";
import { ReactComponent as WhatsNewIcon } from "./icons/nav-bar-icons/EL_whatsNew_N.svg";
import { ReactComponent as PopoutIcon } from "./icons/nav-bar-icons/EL_popout_N.svg";
import { ReactComponent as AnonymousUser } from "./icons/nav-bar-icons/EL_anonymousUser_N.svg";
import { ReactComponent as HelpMenuIcon } from "./icons/nav-bar-icons/EL_helpMenu_N.svg"
import { ReactComponent as HelpMenuFillIcon } from "./icons/nav-bar-icons/EL_helpMenuFill_N.svg"

import { ReactComponent as CreateIcon } from "./icons/EL_create_N.svg";
import { ReactComponent as DeleteIcon } from "./icons/EL_delete_N.svg";
import { ReactComponent as DeleteIconForDialog } from "./icons/EL_DeleteDialog_N.svg";
import { ReactComponent as ReportAbuse } from "./icons/EL_reportAbuse_N.svg";
import { ReactComponent as Info } from "./icons/EL_info_N.svg";
import { ReactComponent as collapseSidebarPanel } from "./icons/EL_collapse_panel_N.svg";
import { ReactComponent as assetName } from "./icons/EL_asset_name_N.svg";
import { ReactComponent as assetDate } from "./icons/EL_asset_date_N.svg";
import { ReactComponent as imageTypeAsset } from "./icons/EL_asset_image_type_N.svg";
import { ReactComponent as videoTypeAsset } from "./icons/EL_asset_video_type_N.svg";
import { ReactComponent as assetSource } from "./icons/EL_asset_source_N.svg";
import { ReactComponent as DownloadIcon } from "./icons/creations-icons/EL_header_download.svg";
import { ReactComponent as EditIcon } from "./icons/creations-icons/EL_project_edit.svg";
import { ReactComponent as ShareIcon } from "./icons/creations-icons/EL_header_share.svg";
import { ReactComponent as OpenInDesktopIcon } from "./icons/creations-icons/EL_header_OpenInDesktop.svg";
import { ReactComponent as UploadImageIcon } from "./icons/EL_AddCircle_18_N.svg"

import { ReactComponent as MediaManagerTabIcon } from "./icons/slideshow-icons/EL_media_N.svg";
import { ReactComponent as SlideshowAudioIcon } from "./icons/slideshow-icons/EL_audio_N.svg";
import { ReactComponent as SlideshowTemplateIcon } from "./icons/slideshow-icons/EL_template_N.svg";
import { ReactComponent as SlideshowIntroTextIcon } from "./icons/slideshow-icons/EL_IntroText_N.svg";
import { ReactComponent as SlideshowManageMediaIcon } from "./icons/slideshow-icons/EL_manage_media_N.svg";
import { ReactComponent as LogoPopoutIcon } from "./icons/EL_popOut_N.svg";

import { ReactComponent as SortOrderDownIcon } from "./icons/media-grid-icons/EL_SortOrderDown_N.svg";
import { ReactComponent as SortOrderUpIcon } from "./icons/media-grid-icons/EL_SortOrderUp_N.svg";
import { ReactComponent as ImportIcon } from "./icons/media-grid-icons/EL_import_N.svg";
import { ReactComponent as VideoPlayIcon } from "./icons/media-grid-icons/EL_play.svg";
import { ReactComponent as FilterIcon } from "./icons/media-grid-icons/EL_Filter.svg";
import { ReactComponent as ImportStopIcon } from "./icons/media-grid-icons/EL_importStop_N.svg";
import { ReactComponent as GIFIcon } from "./icons/media-grid-icons/EL_gif_N.svg";
import { ReactComponent as TooManyFilters } from "./icons/media-grid-icons/EL_tooManyFilter_N.svg";
import { ReactComponent as NoMediaSharedViewIcon } from "./icons/media-grid-icons/EL_noMediaSharedView_N.svg";
import { ReactComponent as IncorrectCollectionSharedViewIcon } from "./icons/EL_incorrectCollectionId_N.svg";
import { ReactComponent as EmptyELMediaGrid } from "./icons/media-grid-icons/EL_emptyGrid_N.svg";
import { ReactComponent as StaticImgThumb } from "./icons/media-grid-icons/EL_staticImgThumb_N.svg";
import { ReactComponent as StaticVideoThumb } from "./icons/media-grid-icons/EL_staticVideoThumb_N.svg";

import { ReactComponent as ThumbLaodingIcon } from "./icons/media-grid-icons/Smock_Image_18_N.svg";

import { ReactComponent as SlideshowIcon } from "./icons/creations-icons/EL_Slideshow.svg";
import { ReactComponent as PhotoCollageIcon } from "./icons/creations-icons/EL_PhotoCollage.svg";
import { ReactComponent as PatternOverlayIcon } from "./icons/creations-icons/EL_PatternOverlay.svg";
import { ReactComponent as PeekThroughIcon } from "./icons/creations-icons/EL_PeekThroughIcon.svg";
import { ReactComponent as MovingOverlayIcon } from "./icons/creations-icons/EL_MovingOverlay.svg";
import { ReactComponent as ReplaceBackgroundIcon } from "./icons/creations-icons/EL_ReplaceBackground.svg";
import { ReactComponent as PhotoTextIcon } from "./icons/creations-icons/EL_PhotoText.svg";
import { ReactComponent as CreationsHeaderBackIcon } from "./icons/creations-icons/EL_header_back.svg";
import { ReactComponent as CreationsHeaderSaveIcon } from "./icons/creations-icons/EL_header_save.svg";
import { ReactComponent as CreationsHeaderSavingIcon } from "./icons/creations-icons/EL_header_saving.svg";
import { ReactComponent as CreationsHeaderRenameIcon } from "./icons/creations-icons/EL_header_rename.svg";
import { ReactComponent as CreationsAudioPanelPlayIcon } from "./icons/creations-icons/EL_AudioPanel_play.svg";
import { ReactComponent as CreationsAudioPanelPauseIcon } from "./icons/creations-icons/EL_AudioPanel_pause.svg";
import { ReactComponent as creationsHeaderUndoIcon } from "./icons/creations-icons/EL_header_undo.svg";
import { ReactComponent as creationsHeaderRedoIcon } from "./icons/creations-icons/EL_header_redo.svg";
import { ReactComponent as CreationsHeaderCloudOffIcon } from "./icons/creations-icons/EL_header_cloud_off.svg";
import { ReactComponent as CreationsHeaderCloudErrorIcon } from "./icons/creations-icons/EL_header_cloud_error.svg";
import { ReactComponent as CreationsHeaderServerErrorIcon } from "./icons/creations-icons/EL_header_server_error.svg";

import { ReactComponent as EmailIcon } from "./icons/EL_email_N.svg";
import { ReactComponent as LinkIcon } from "./icons/EL_link_N.svg";
import { ReactComponent as FacebookIcon } from "./icons/EL_facebook_N.svg";
import { ReactComponent as CopyLinkIcon } from "./icons/EL_copyLink_N.svg";
import { ReactComponent as LinkGenerationError } from "./icons/EL_linkGenerationError_N.svg";
import { ReactComponent as ManageIcon } from "./icons/EL_manage_N.svg";
import { ReactComponent as CancelIcon } from "./icons/EL_cancel_N.svg";
import { ReactComponent as InstagramIcon } from "./icons/EL_Instagram_N.svg";
import { ReactComponent as VimeoIcon } from "./icons/EL_Vimeo_N.svg";
import { ReactComponent as WhatsappIcon } from "./icons/EL_Whatsapp_N.svg";
import { ReactComponent as YouTubeIcon } from "./icons/EL_YouTube_N.svg";
import { ReactComponent as TwitterIcon } from "./icons/EL_Twitter_N.svg";


import { ReactComponent as ProgressArtworkIcon1 } from "./icons/progress-artwork-icons/EL_progress_Artwork_1.svg";
import { ReactComponent as ProgressArtworkIcon2 } from "./icons/progress-artwork-icons/EL_progress_Artwork_2.svg";
import { ReactComponent as ProgressArtworkIcon3 } from "./icons/progress-artwork-icons/EL_progress_Artwork_3.svg";
import { ReactComponent as ProgressArtworkIcon4 } from "./icons/progress-artwork-icons/EL_progress_Artwork_4.svg";
import { ReactComponent as ProgressArtworkIcon5 } from "./icons/progress-artwork-icons/EL_progress_Artwork_5.svg";
import { ReactComponent as ProgressArtworkIcon6 } from "./icons/progress-artwork-icons/EL_progress_Artwork_6.svg";
import { ReactComponent as ProgressArtworkIcon7 } from "./icons/progress-artwork-icons/EL_progress_Artwork_7.svg";
import { ReactComponent as ProgressArtworkIcon8 } from "./icons/progress-artwork-icons/EL_progress_Artwork_8.svg";
import { ReactComponent as ProgressArtworkIcon9 } from "./icons/progress-artwork-icons/EL_progress_Artwork_9.svg";
import { ReactComponent as ProgressArtworkIcon10 } from "./icons/progress-artwork-icons/EL_progress_Artwork_10.svg";

import { ReactComponent as CloseIcon } from "./icons/EL_close_N.svg";
import { ReactComponent as RightArrowIcon } from "./home/EL_icon_right_arrow.svg";
import { ReactComponent as BackIcon } from "./icons/EL_back_N.svg";
import { ReactComponent as BackIconSmall } from "./icons/EL_back_S.svg";

import { ReactComponent as ChevronLeftIcon } from "./icons/generic-icons/EL_chevron_left.svg";
import { ReactComponent as ChevronRightIcon } from "./icons/generic-icons/EL_chevron_right.svg";
import { ReactComponent as RestartIcon } from "./icons/generic-icons/EL_Restart.svg";
import { ReactComponent as NegativeSentimentIcon } from "./icons/generic-icons/EL_Sentiment_Negative_N.svg";
import { ReactComponent as NegativeFeedbackFace } from "./icons/generic-icons/EL_Negative_Feedback_N.svg";
import { ReactComponent as PositiveFeedbackFace } from "./icons/generic-icons/EL_Positive_Feedback_N.svg";
import { ReactComponent as playIcon } from "./icons/generic-icons/EL_play_N.svg";
import { ReactComponent as pauseIcon } from "./icons/generic-icons/EL_pause_N.svg";
import { ReactComponent as settingsIcon } from "./icons/generic-icons/EL_settings.svg";

import { ReactComponent as CollapsibleForwardIcon } from "./icons/collage-icons/EL_collapsiblepanel_forward.svg";
import { ReactComponent as collapsibleDownwardIcon } from "./icons/collage-icons/EL_collapsiblepanel_downward.svg";

import { ReactComponent as Background } from "./icons/EL_Background_N.svg";
import { ReactComponent as CreationsShuffle } from "./icons/creations-icons/EL_Smock_Shuffle.svg";
import { ReactComponent as revert } from "./icons/creations-icons/EL_Smock_Revert.svg";

import { ReactComponent as MovingOverlayTabIcon } from "./icons/pattern-overlay-icons/EL_pattern_overlay_tab_icon.svg"
import { ReactComponent as PatternOverlayTabIcon } from "./icons/pattern-overlay-icons/EL_pattern_overlay_tab_icon.svg"
import { ReactComponent as ReplaceBackgroundTabIcon } from "./icons/replace-background-icons/EL_replace_background_tab_icon.svg"
import { ReactComponent as PhotoTextPresetsTabIcon } from "./icons/photo-text-icons/EL_photo_text_presets_tab_icon.svg";

import { ReactComponent as DesktopIcon } from "./icons/EL_Desktop_N.svg";
import { ReactComponent as MobileIcon } from "./icons/EL_Mobile_N.svg";
import { ReactComponent as WebIcon } from "./icons/EL_Web_N.svg";
import { ReactComponent as checkMark } from "./icons/EL_check_mark.svg"
import { ReactComponent as crossMark } from "./icons/EL_cross_mark.svg"

import { ReactComponent as socialLayoutIcon } from "./icons/EL_social_layout.svg";
import { ReactComponent as peekThroughOverlayIcon } from "./icons/creations-icons/EL_peek_through_overlay_panel_icon.svg";

import { ReactComponent as LooksIcon } from "./icons/edit-icons/EL_looks_icon.svg";

import { ReactComponent as TextAlignmentLeftIcon } from "./icons/text-icons/EL_Text_Align_Left.svg";
import { ReactComponent as TextAlignmentRightIcon } from "./icons/text-icons/EL_Text_Align_Right.svg";
import { ReactComponent as TextAlignmentCenterIcon } from "./icons/text-icons/EL_Text_Align_Center.svg";
import { ReactComponent as TextBoldIcon } from "./icons/text-icons/EL_Text_Bold.svg";
import { ReactComponent as TextItalicIcon } from "./icons/text-icons/EL_Text_Italic.svg";
import { ReactComponent as TextUnderlineIcon } from "./icons/text-icons/EL_Text_Underline.svg";
import { ReactComponent as TextTabIcon } from "./icons/text-icons/EL_Text_Tab.svg";
import { ReactComponent as TextFitIcon } from "./icons/text-icons/EL_Text_Fit.svg";
import { ReactComponent as TextFillIcon } from "./icons/text-icons/EL_Text_Fill.svg";

export enum IconType {
    appLogo,
    pseAppLogo,
    preAppLogo,
    home,
    creation,
    media,
    learn,
    cloudStorage,
    feedback,
    anonymousUser,
    cloudStorageWarnIcon,
    popout,
    create,
    delete,
    deleteforDialog,
    reportAbuse,
    info,
    collapseSidebarPanel,
    assetName,
    imageTypeAsset,
    videoTypeAsset,
    assetDate,
    assetSource,
    share,
    mediaManagerTab,
    slideshowAudio,
    slideshowTemplate,
    slideshowIntroText,
    slideshowManageMedia,
    thumbLoading,
    tooManyFilters,
    emptyELMediaGrid,
    staticImgThumb,
    staticVideoThumb,
    noMediaSharedView,
    incorrectCollectionSharedView,
    logoPopout,
    sortOrderDown,
    sortOrderUp,
    import,
    slideshow,
    photoCollage,
    patternOverlay,
    peekThrough,
    movingOverlay,
    replaceBackground,
    photoText,
    creationsHeaderBack,
    download,
    edit,
    creationsHeaderCloudOffIcon,
    creationsHeaderCloudErrorIcon,
    creationsHeaderServerErrorIcon,
    creationsHeaderSave,
    creationsHeaderSaving,
    creationsHeaderRename,
    creationsAudioPanelPlay,
    creationsAudioPanelPause,
    email,
    link,
    facebook,
    linkGenerationError,
    progressArtwork1,
    progressArtwork2,
    progressArtwork3,
    progressArtwork4,
    progressArtwork5,
    progressArtwork6,
    progressArtwork7,
    progressArtwork8,
    progressArtwork9,
    progressArtwork10,
    videoPlayIcon,
    close,
    rightArrowIcon,
    back,
    backSmall,
    filterIcon,
    importStop,
    gifIcon,
    chevronLeftIcon,
    chevronRightIcon,
    restartIcon,
    helpMenuIcon,
    helpMenuFillIcon,
    copyLink,
    negativeSentimentIcon,
    negativeFeedbackFaceIcon,
    positiveFeedbackFaceIcon,
    playIcon,
    pauseIcon,
    settingsIcon,
    creationsHeaderUndo,
    creationsHeaderRedo,
    collapsibleForward,
    collapsibleDownward,
    manage,
    cancel,
    background,
    creationsShuffle,
    patternOverlayTab,
    replaceBackgroundTab,
    openInDesktop,
    desktop,
    mobile,
    web,
    revert,
    checkMark,
    crossMark,
    peekThroughOverlayIcon,
    movingOverlayTab,
    socialLayout,
    feedbackNavIcon,
    whatsNewIcon,
    InstagramIcon,
    VimeoIcon,
    WhatsappIcon,
    YouTubeIcon,
    TwitterIcon,
    UploadImageIcon,
    looksIcon,
    textAlignmentLeft,
    textAlignmentRight,
    textAlignmentCenter,
    textBold,
    textItalic,
    textUnderline,
    photoTextPresets,
    textTabIcon,
    textFit,
    textFill
}

export const ICONS: { [key in IconType]: React.FunctionComponent } = {
    [IconType.appLogo]: AppLogo,
    [IconType.pseAppLogo]: PSEAppLogo,
    [IconType.preAppLogo]: PREAppLogo,
    [IconType.home]: HomeIcon,
    [IconType.creation]: CreationIcon,
    [IconType.media]: MediaIcon,
    [IconType.learn]: LearnIcon,
    [IconType.cloudStorage]: CloudStorageIcon,
    [IconType.feedback]: FeedbackIcon,
    [IconType.whatsNewIcon]: WhatsNewIcon,
    [IconType.anonymousUser]: AnonymousUser,
    [IconType.cloudStorageWarnIcon]: CloudStorageWarnIcon,
    [IconType.popout]: PopoutIcon,
    [IconType.create]: CreateIcon,
    [IconType.delete]: DeleteIcon,
    [IconType.deleteforDialog]: DeleteIconForDialog,
    [IconType.reportAbuse]: ReportAbuse,
    [IconType.info]: Info,
    [IconType.collapseSidebarPanel]: collapseSidebarPanel,
    [IconType.assetName]: assetName,
    [IconType.assetDate]: assetDate,
    [IconType.imageTypeAsset]: imageTypeAsset,
    [IconType.videoTypeAsset]: videoTypeAsset,
    [IconType.assetSource]: assetSource,
    [IconType.download]: DownloadIcon,
    [IconType.edit]: EditIcon,
    [IconType.share]: ShareIcon,
    [IconType.mediaManagerTab]: MediaManagerTabIcon,
    [IconType.slideshowAudio]: SlideshowAudioIcon,
    [IconType.slideshowTemplate]: SlideshowTemplateIcon,
    [IconType.slideshowIntroText]: SlideshowIntroTextIcon,
    [IconType.slideshowManageMedia]: SlideshowManageMediaIcon,
    [IconType.thumbLoading]: ThumbLaodingIcon,
    [IconType.noMediaSharedView]: NoMediaSharedViewIcon,
    [IconType.incorrectCollectionSharedView]: IncorrectCollectionSharedViewIcon,
    [IconType.emptyELMediaGrid]: EmptyELMediaGrid,
    [IconType.staticImgThumb]: StaticImgThumb,
    [IconType.staticVideoThumb]: StaticVideoThumb,
    [IconType.logoPopout]: LogoPopoutIcon,
    [IconType.sortOrderDown]: SortOrderDownIcon,
    [IconType.sortOrderUp]: SortOrderUpIcon,
    [IconType.tooManyFilters]: TooManyFilters,
    [IconType.import]: ImportIcon,
    [IconType.slideshow]: SlideshowIcon,
    [IconType.photoCollage]: PhotoCollageIcon,
    [IconType.patternOverlay]: PatternOverlayIcon,
    [IconType.peekThrough]: PeekThroughIcon,
    [IconType.movingOverlay]: MovingOverlayIcon,
    [IconType.replaceBackground]: ReplaceBackgroundIcon,
    [IconType.photoText]: PhotoTextIcon,
    [IconType.creationsHeaderBack]: CreationsHeaderBackIcon,
    [IconType.creationsHeaderSave]: CreationsHeaderSaveIcon,
    [IconType.creationsHeaderSaving]: CreationsHeaderSavingIcon,
    [IconType.creationsHeaderCloudErrorIcon]: CreationsHeaderCloudErrorIcon,
    [IconType.creationsHeaderCloudOffIcon]: CreationsHeaderCloudOffIcon,
    [IconType.creationsHeaderServerErrorIcon]: CreationsHeaderServerErrorIcon,
    [IconType.creationsHeaderRename]: CreationsHeaderRenameIcon,
    [IconType.creationsAudioPanelPlay]: CreationsAudioPanelPlayIcon,
    [IconType.creationsAudioPanelPause]: CreationsAudioPanelPauseIcon,
    [IconType.email]: EmailIcon,
    [IconType.link]: LinkIcon,
    [IconType.facebook]: FacebookIcon,
    [IconType.linkGenerationError]: LinkGenerationError,
    [IconType.progressArtwork1]: ProgressArtworkIcon1,
    [IconType.progressArtwork2]: ProgressArtworkIcon2,
    [IconType.progressArtwork3]: ProgressArtworkIcon3,
    [IconType.progressArtwork4]: ProgressArtworkIcon4,
    [IconType.progressArtwork5]: ProgressArtworkIcon5,
    [IconType.progressArtwork6]: ProgressArtworkIcon6,
    [IconType.progressArtwork7]: ProgressArtworkIcon7,
    [IconType.progressArtwork8]: ProgressArtworkIcon8,
    [IconType.progressArtwork9]: ProgressArtworkIcon9,
    [IconType.progressArtwork10]: ProgressArtworkIcon10,
    [IconType.videoPlayIcon]: VideoPlayIcon,
    [IconType.close]: CloseIcon,
    [IconType.rightArrowIcon]: RightArrowIcon,
    [IconType.back]: BackIcon,
    [IconType.backSmall]: BackIconSmall,
    [IconType.filterIcon]: FilterIcon,
    [IconType.importStop]: ImportStopIcon,
    [IconType.gifIcon]: GIFIcon,
    [IconType.chevronLeftIcon]: ChevronLeftIcon,
    [IconType.chevronRightIcon]: ChevronRightIcon,
    [IconType.restartIcon]: RestartIcon,
    [IconType.helpMenuIcon]: HelpMenuIcon,
    [IconType.helpMenuFillIcon]: HelpMenuFillIcon,
    [IconType.copyLink]: CopyLinkIcon,
    [IconType.negativeSentimentIcon]: NegativeSentimentIcon,
    [IconType.negativeFeedbackFaceIcon]: NegativeFeedbackFace,
    [IconType.positiveFeedbackFaceIcon]: PositiveFeedbackFace,
    [IconType.playIcon]: playIcon,
    [IconType.pauseIcon]: pauseIcon,
    [IconType.settingsIcon]: settingsIcon,
    [IconType.creationsHeaderUndo]: creationsHeaderUndoIcon,
    [IconType.creationsHeaderRedo]: creationsHeaderRedoIcon,
    [IconType.collapsibleForward]: CollapsibleForwardIcon,
    [IconType.collapsibleDownward]: collapsibleDownwardIcon,
    [IconType.manage]: ManageIcon,
    [IconType.cancel]: CancelIcon,
    [IconType.background]: Background,
    [IconType.creationsShuffle]: CreationsShuffle,
    [IconType.patternOverlayTab]: PatternOverlayTabIcon,
    [IconType.replaceBackgroundTab]: ReplaceBackgroundTabIcon,
    [IconType.openInDesktop]: OpenInDesktopIcon,
    [IconType.desktop]: DesktopIcon,
    [IconType.mobile]: MobileIcon,
    [IconType.web]: WebIcon,
    [IconType.revert]: revert,
    [IconType.checkMark]: checkMark,
    [IconType.crossMark]: crossMark,
    [IconType.peekThroughOverlayIcon]: peekThroughOverlayIcon,
    [IconType.movingOverlayTab]: MovingOverlayTabIcon,
    [IconType.socialLayout]: socialLayoutIcon,
    [IconType.feedbackNavIcon]: FeedbackNavIcon,
    [IconType.InstagramIcon]: InstagramIcon,
    [IconType.VimeoIcon]: VimeoIcon,
    [IconType.WhatsappIcon]: WhatsappIcon,
    [IconType.YouTubeIcon]: YouTubeIcon,
    [IconType.TwitterIcon]: TwitterIcon,
    [IconType.UploadImageIcon]: UploadImageIcon,
    [IconType.looksIcon]: LooksIcon,
    [IconType.textAlignmentLeft]: TextAlignmentLeftIcon,
    [IconType.textAlignmentRight]: TextAlignmentRightIcon,
    [IconType.textAlignmentCenter]: TextAlignmentCenterIcon,
    [IconType.textBold]: TextBoldIcon,
    [IconType.textItalic]: TextItalicIcon,
    [IconType.textUnderline]: TextUnderlineIcon,
    [IconType.photoTextPresets]: PhotoTextPresetsTabIcon,
    [IconType.textTabIcon]: TextTabIcon,
    [IconType.textFit]: TextFitIcon,
    [IconType.textFill]: TextFillIcon
};
