/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//ThirdParty
import QRCode from "qrcode";

//App specific
import Logger, { LogLevel } from "../../utils/Logger";

export class ELQRGenerator {
    async getQRData(url: string): Promise<string> {
        try {
            const data = await QRCode.toDataURL(url);
            return Promise.resolve(data);
        } catch (error) {
            Logger.log(LogLevel.WARN, "ELQRGenerator: getQRData" + error);
            return Promise.reject(error);
        }
    }
}