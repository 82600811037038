/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useState, ReactNode } from 'react'
import { PressEvent } from '@react-types/shared';

//Application Specific
import ELCustomIcon from '../el-custom-icon/ELCustomIcon';
import ELButton from '../../atoms/el-button/ELButtonView';
import { IconType } from '../../../../assets/IconConstants';

import "./ELIconButton.scss";

export interface ELIconButtonProps {
    iconkey: IconType,
    size: "S" | "L",
    variant?: "primary" | "secondary" | "cta" | "overBackground" | "negative",
    iconWidth?: string,
    iconHeight?: string,
    iconColor?: string,
    iconHoverColor?: string,
    isDisabled?: boolean,
    children?: ReactNode,
    onClick?: ((e: PressEvent) => void),
    dataTestId?: string
}

/**
 *  This is small wrapper over ELButton to support colors change for icons contained in a button.
 *  React spectrum button doesn't support color change of icons as we required according to the spec
 */
const ELIconButton = (props: ELIconButtonProps): React.ReactElement => {
    const [sIsHover, setHover] = useState(false);

    return (
        <div className="icon-button-box" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            <ELButton size={props.size} variant={props.variant ?? "secondary"} childrenGap={"0.5rem"} 
                isDisabled={props.isDisabled} onClick={props.onClick} dataTestId={props.dataTestId} >
                <ELCustomIcon variant="contained" iconkey={props.iconkey} width={props.iconWidth} height={props.iconHeight}
                    color={sIsHover ? props.iconHoverColor : props.iconColor} isDisabled = {props.isDisabled}/>
                {props.children}
            </ELButton>
        </div>
    )
}

ELIconButton.defaultProps = {
    size: "S",
}

export default ELIconButton;
