/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELExportOption, ELExportInfo } from "../../common/interfaces/export/ELExportTypes";
import { ELAdobeAsset } from "../../common/interfaces/storage/AssetTypes";
import { StorageService } from "../../services/StorageServiceWrapper";

export default abstract class IExportHandler {

    protected async getValidAdobeAsset(asset: ELAdobeAsset): Promise<ELAdobeAsset | undefined> {
        if (asset.assetId === undefined) {
            return Promise.reject();
        }
        if (asset.name && asset.assetId && asset.format) {
            return Promise.resolve(asset);
        }

        try {
            const resolvedAsset = await StorageService.getInstance().resolveAsset({ assetId: asset.assetId }, 'id');
            return Promise.resolve(resolvedAsset);
        } catch (error) {
            return undefined;
        }
    }

    abstract downloadFile(exportInfo: ELExportInfo, exportOption: ELExportOption): Promise<void>;
}