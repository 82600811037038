/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//values are consistent with those found in elive DB
export enum UserState {
    signedOut = "Signed out",
    paid = "Paid",
    trialInProgress = "Trial in progress",
    trialNotStarted = "Trial not started",
    trialExpired = "Trial expired"
}

export enum ChannelName {
    learn = "Learn",
    explore = "Explore",
    news = "News",
    inspire = "Inspire",
    tryNow = "Try now",
    watchVideo = "Watch video"
}

export enum ElivePlatform {
    web = "Web",
    desktop = "Desktop",
    mobile = "Mobile"
}

export interface CardData {
    cardID: string,
    channelName: ChannelName,
    title: string,
    desc: string,
    cardURL: string,
    thumbnail: string,
    workflowName: string,
    userStates: UserState[],
    platform: ElivePlatform
}

export interface BannerData {
    bannerID: string,
    bannerURL: string,
    userStates: UserState[]
}

export enum EliveContentType {
    card = "card",
    banner = "banner"
}