/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode } from "react";

//Adobe internal
import { Text, Flex, Button } from "@adobe/react-spectrum";

//Application specific

import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { UserUtils } from "../../../../utils/UserUtils";
import Utils from "../../../../utils/Utils";
import { elementsProductDataInterface, getElementsProductsData } from "../../../../utils/desktopBuy/ELDesktopBuyBannerData";

import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { LocaleUtils } from "../../../../utils/LocaleUtils";

import "./ELDesktopBuyBanner.scss";

const ELDesktopBuyBanner = (): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const elementsProducts = getElementsProductsData();

    const handleBuyNow = (index: number): void => {
        Utils.openInNewTab(elementsProducts[index].productBuyLink);
    }


    const showDesktopBuyBanner = (): React.ReactNode => {
        if (!FeaturesManager.getInstance().IsFeatureActive(FeatureName.eShowDesktopProductCards) || UserUtils.isUserPaid()) {
            return <></>;
        } else {
            return getDesktopBuyBanner();
        }
    }

    const getDesktopBuyBannerHeader = (product: elementsProductDataInterface, index: number): React.ReactNode => {
        return (
            <Flex UNSAFE_className="buy-desktop-card__header">
                <Flex gap="1rem">
                    <div><ELIcon iconkey={product.productIconKey} width="4rem" height="4rem" /></div>
                    {product.productSecondIconKey && <div><ELIcon iconkey={product.productSecondIconKey} width="4rem" height="4rem" /></div>}
                </Flex>
                <Flex UNSAFE_className="buy-desktop-card__sub-header">
                    <Text UNSAFE_className="buy-desktop-card__product-name">
                        <a className="buy-desktop-card__product-name-anchor-style" href={product.productAppLink} target="_blank" rel="noopener noreferrer">{product.productName}</a>
                    </Text>
                    <Text UNSAFE_className="buy-desktop-card__product-description">{product.productDescription}</Text>
                </Flex>
            </Flex>
        );
    }

    const getDesktopBuyBannerBody = (product: elementsProductDataInterface, index: number): React.ReactNode => {
        const isCurrencySymbolInLeft = LocaleUtils.LocaleSpecificData.isCurrencySymbolInLeft;
        const flexDirection = isCurrencySymbolInLeft ? "row" : "row-reverse";
        return (
            <Flex UNSAFE_className="buy-desktop-card__body">

                <Flex UNSAFE_className="buy-desktop-card__body" >
                    <Text UNSAFE_className="buy-desktop-card__product-price">
                        {<p style={{ display: "flex", flexDirection: flexDirection }}><sup>{LocaleUtils.LocaleSpecificData.currencySymbol}</sup>{product.productPrice}</p>}
                    </Text>
                    <Button variant="cta" onPress={() => handleBuyNow(index)} data-testid="buy-desktop-card-button" UNSAFE_className={"buy-desktop-card__buy-button"}>
                        <Flex direction={"row-reverse"} alignItems="center">
                            <Text UNSAFE_className="buy-desktop-card__buy-button-text">{intlHandler.formatMessage("buy-now")}</Text>
                        </Flex>
                    </Button>
                    <Text UNSAFE_className="buy-desktop-card__product-price-upgradation">
                        <a className="buy-desktop-card__product-price-upgradation-anchor" href={product.productUpgradeLink} target="_blank" rel="noopener noreferrer">
                            {product.productUpgradePrice}
                        </a>
                    </Text>
                </Flex >

                <Flex UNSAFE_className="buy-desktop-card__bottom-box">
                    <></>
                </Flex>
            </Flex>
        );
    }

    const getDesktopBuyCard = (product: elementsProductDataInterface, index: number): ReactNode => {
        return (
            <Flex key={"desktopBuyCard" + index} UNSAFE_className="buy-desktop-card">
                <Flex UNSAFE_className="buy-desktop-card__content">
                    {getDesktopBuyBannerHeader(product, index)}
                    {getDesktopBuyBannerBody(product, index)}
                </Flex>
            </Flex>
        );
    }

    const getDesktopBuyBanner = (): React.ReactNode => {
        return (<Flex UNSAFE_className="buy-desktop-card__container" >
            {elementsProducts.map((product: elementsProductDataInterface, index: number) => getDesktopBuyCard(product, index))}
        </Flex>);
    }

    return (
        <ViewportProvider>
            {showDesktopBuyBanner()}
        </ViewportProvider>
    )
}

export default ELDesktopBuyBanner;
