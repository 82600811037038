/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { IconType } from "../../../assets/IconConstants";

export enum ShareMode {
    facebook = "Facebook",
    email = "email",
    link = "Link"
}

export enum ShareType {
    media = "media",
    slideshow = "slideshow",
    photoCollage = "photo collage",
    patternOverlay = "pattern overlay creation",
    peekThrough = "peek-through overlay",
    movingOverlay = "moving overlay creation",
    replaceBackground = "auto background creation",
    photoText = "photo text creation"
}

//TODO - vib, think of better solution for these scenarios
export const ShareTypeDisplayNameKeys: Record<ShareType, string> = {
    [ShareType.media]: "media",
    [ShareType.slideshow]: "slideshow",
    [ShareType.photoCollage]: "photo-collage",
    [ShareType.patternOverlay]: "pattern-overlay-creation",
    [ShareType.peekThrough]: "peek-through",
    [ShareType.movingOverlay]: "moving-overlay-creation",
    [ShareType.replaceBackground]: "replace-background-creation",
    [ShareType.photoText]: "photo-text-creation"
}

export enum ShareTypeCategory {
    media = "media",
    creation = "creations"
}

export interface LinkInfo {
    link: string;
    sharedAssetsCount: number;
}

export interface ShareResponse {
    link: string
    errorCode?: number;
    errorMessage?: string;
}

export enum LinkGenerationStatus {
    notStarted = "NOT_STARTED",
    started = "STARTED",
    complete = "COMPLETE",
    partial = "PARTIAL",
    error = "ERROR",
    cancelled = "CANCELLED"
}

export interface EmailData {
    receiptentList: string,
    message: string,
    sendCopyValue: boolean,
    shareType: ShareType
}

export enum ShareErrorCode {
    noError = "noError",
    noNetwork = "noNetwork",
    shareLimitExceed = "shareLimitExceed"
}

export const ShareDialogIntlHeaders: { [key in ShareMode]: string } = {
    [ShareMode.facebook]: "to-facebook",
    [ShareMode.email]: "via-email",
    [ShareMode.link]: "via-link"
}

export enum UnshareLinkStatus {
    success = "SUCCESS",
    error = "ERROR"
}

export type ShareOptionData = {
    key: string,
    src: IconType,
    text: string,
    shareMode: ShareMode
};

export const SHARE_OPTIONS_LIST = [
    {
        key: "Facebook",
        src: IconType.facebook,
        text: "facebook",
        shareMode: ShareMode.facebook
    },
    {
        key: "Email",
        src: IconType.email,
        text: "email",
        shareMode: ShareMode.email
    },
    {
        key: "Link",
        src: IconType.link,
        text: "link",
        shareMode: ShareMode.link
    }
];

export enum ELShareOptionsViewAction {
    qrLink = "QRLINK",
    qrData = "QRDATA"
}

export enum ELSharePopUpAction {
    popUpRendered = "POP_UP_RENDERED",
    popUpClosed = "POP_UP_CLOSED",
    popUpGetStarted = "POP_UP_GET_STARTED"
}

export const QR_WEB_APP_SOURCE_NAME = "elweb";

export interface ELQRSharePayload {
    assetsToShare: string[],
    category: ShareTypeCategory,
    source: string
}

export enum ELQRShareAction {
    SHARE = "share",
    DOWNLOAD = "download",
    MULTISHARE = "multishare"
}

export enum ELQRShareVersion {
    version_1_0 = "1.0"
}

export type QRShareOptionsData = {
    key: string,
    src: string,
    text: string
}