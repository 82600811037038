/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";
import { Text } from "@adobe/react-spectrum";

//Adobe internal
import { Flex, Button } from "@adobe/react-spectrum";

//Application specific
import IViewController from "../../../view/IViewController";
import ELFeatureBannerView from "../../../view/components/organism/el-feature-banner/ELFeatureBanner";
import SlideshowPreview from "../../../assets/home/Home-animation.mp4";
import ELCTABannerView from "../../../view/components/organism/el-cta-banner/ELCTABanner";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { LoginUtils } from "../../../utils/LoginUtils";
import IconAndDescriptor from "../../../view/components/molecules/el-icon-and-text/ELIconAndText";
import { IconType } from "../../../assets/IconConstants";
import { PHOTOSHOP_ELEMENTS_APP_NAME } from "../../../utils/Constants/Constants";

import ELButton from "../../../view/components/atoms/el-button/ELButtonView";
import { ELIcon } from "../../../view/components/atoms/el-icon/ELIconView";

import "./PublicLandingView.scss";

interface PublicLandingViewProps {
    controller: IViewController
}

export const PublicLandingView = (props: PublicLandingViewProps): React.ReactElement => {

    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <>
            <div className="publiclanding-app-icon-box">
                <Button
                    variant="secondary"
                    UNSAFE_className="publiclanding-app-icon">
                    <IconAndDescriptor
                        icon={IconType.appLogo}
                        iconClass="publiclanding-app-logo"
                        text={PHOTOSHOP_ELEMENTS_APP_NAME}
                        textClass="publiclanding-app-name" />
                </Button>
            </div>
            <Flex UNSAFE_className="feature-cta-box">
                <ELFeatureBannerView
                    title={intlHandler.formatMessage("create-and-share")}
                    description={intlHandler.formatMessage("import-create-desc")}
                    previewSrc={SlideshowPreview}
                >
                    <Flex UNSAFE_className="feature-banner-box__cta" direction="column">
                        <ELButton size="L" variant="cta" childrenDirection="row-reverse" childrenGap="0.2rem" onClick={() => LoginUtils.signIn()}>
                            <Text UNSAFE_className="feature-banner-box__cta-text">{intlHandler.formatMessage("sign-in-free")}</Text>
                            <ELIcon iconkey={IconType.rightArrowIcon} width="2.5rem" height="2.5rem" />
                        </ELButton>
                    </Flex>
                </ELFeatureBannerView>
                <ELCTABannerView text={intlHandler.formatMessage("survey-description")} ctaText={intlHandler.formatMessage("sure")} ctaHandler={LoginUtils.signIn} />
            </Flex>
        </>
    )
}
