/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum SampleMediaManagerControllerAction {
    toolbarCancel = "TOOLBAR_CANCEL",
    toolbarContinue = "TOOLBAR_CONTINUE",
    updateContinueButtonState = "UPDATE_CONTINUE_BUTTON_STATE",
    updateSelectedSampleMediaAssets = "UPDATE_SELECTED_SAMPLE_MEDIA_ASSETS",
    handleDroppedFiles = "HANDLE_DROPPED_FILES",
    mediaImportedWithUploadMedia = "MEDIA_IMPORTED_WITH_UPLOAD_MEDIA",
}

export enum SampleMediaManagerViewAction {
    updateContinueButtonState = "UPDATE_CONTINUE_BUTTON_STATE"
}

export enum SampleMediaManagerWorkflowAction {
    mediaSelection = "mediaSelection"
}

export enum SampleMediaGridViewAction {
    updateView = "UPDATE_VIEW",
    updateProgressText = "UPDATE_PROGRESS_TEXT",
}