/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third Party
import React from "react";
import { Flex, Heading } from "@adobe/react-spectrum";

//Application Specific
import { ELWhatsNewPanelViewPropsData } from "../../../../common/interfaces/ELWhatsNewTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../atoms/el-button/ELButtonView";

export interface ELWhatsNewPanelViewProviderProps {
    data: ELWhatsNewPanelViewPropsData;
    onTryNowButtonPress: (tabName: string, buttonLabel: string) => void;
}

export const ELWhatsNewPanelViewProvider = (props: ELWhatsNewPanelViewProviderProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    const onTryNowButtonPress = (): void => {
        props.onTryNowButtonPress(props.data.itemContent.tabName, props.data.itemContent.buttonData[0].label);
    };

    const getMediaElement = (): React.ReactElement => {
        if (props.data.itemContent.mediaType === "image") {
            return <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} className="whats-new-dialog__media" alt="feature-data" src={props.data.itemContent.contentURL} />;
        } else {
            return <video crossOrigin="anonymous" className="whats-new-dialog__media" src={props.data.itemContent.contentURL} onContextMenu={(e) => e.preventDefault()} playsInline loop autoPlay muted />;
        }
    };

    return (
        <>
            {getMediaElement()}
            <Heading UNSAFE_className="whats-new-tab-body__heading">{intlHandler.formatMessage(props.data.itemContent.tabTitle)}</Heading>
            <p className="whats-new-tab-body__description">{intlHandler.formatMessage(props.data.itemContent.tabDescription[0].desc)}</p>
            <Flex UNSAFE_className="whats-new__icon-button-box">
                <ELButton size="L" variant="cta" onClick={() => onTryNowButtonPress()}>
                    <span className="whats-new-dialog__cta-text">{intlHandler.formatMessage("try-now")}</span>
                </ELButton>
            </Flex>
        </>
    );
};