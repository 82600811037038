/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Flex, Grid, ProgressCircle, repeat } from "@adobe/react-spectrum";

//Application specific
import store, { RootState } from "../../../../stores/store";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import Constants, { ERROR_THUMBDATA, PROCESSING_THUMBDATA } from "../../../../utils/Constants/Constants";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import useMediaFetch, { MediaSource } from "../../../../utils/hooks/useMediaFetch";
import ELDeletableMediaThumb from "../../atoms/el-deletable-media-thumb/ELDeletableMediaThumbView";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import IViewController from "../../../IViewController";
import { ELDynamicLayoutConfig } from "../../../../common/interfaces/hooks/DynamicLayoutTypes";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";

import "./ELCreationMediaView.scss"

export enum CreationMediaActionType {
    manageMedia = "MANAGE_MEDIA",
    replaceMedia = "REPLACE_MEDIA"
}

export interface ELCreationMediaViewProps {
    controller: IViewController,
    variant: string,
    isDeletableThumb: boolean,
    specLayoutConfig: ELDynamicLayoutConfig,
    creationHeaderBox?: React.ReactElement,
}

const ELCreationMediaView = (props: ELCreationMediaViewProps): React.ReactElement => {
    const mediaFetchParams = useMediaFetch(Constants.ELEMENTS_PHOTOS_PATH as string, MediaSource.repoDirDataFetch);
    const mediaData = useSelector((state: RootState) => state.mediaThumbReducer);
    const selectedMediaList = useSelector((state: RootState) => state.selectedMediaListReducer);

    const gridRef = useRef(null);
    const observerRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: props.specLayoutConfig,
        observerRef: observerRef,
        gridRef: gridRef
    });

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const onMediaDelete = (assetId: number): void => {
        const updatedSelectedMediaList = selectedMediaList.filter((media, index) => (index !== assetId));
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(updatedSelectedMediaList));
    }

    const getMediaList = (isDeletableThumb: boolean): ReactNode => {
        const getOrpushThumbDataRequestForAsset = (asset: ELAdobeAsset): string => {
            const assetThumb = mediaData.filter((ele) => ele.assetId === asset.assetId);
            if (assetThumb.length > 0) {
                return assetThumb[0].objectURL;
            }

            mediaFetchParams.pushAssetInThumbQueue(asset);
            return PROCESSING_THUMBDATA;
        }

        const getMediaNode = (media: ELAdobeAsset, assetId: number): ReactNode => {
            const imgSrc = getOrpushThumbDataRequestForAsset(media);
            if (imgSrc === ERROR_THUMBDATA) {
                return (<ELIcon className="creation-media-thumb" iconkey={IconType.staticImgThumb} />);
            } else if (imgSrc === PROCESSING_THUMBDATA) {
                return (<ProgressCircle aria-label="Loading…" isIndeterminate />);
            } else {
                return (
                    <ELDeletableMediaThumb imgSrc={imgSrc} altName={media.name} assetId={assetId} onDeleteClick={onMediaDelete}
                        tileWidth={viewLayoutConfig.TILE_W} tileHeight={viewLayoutConfig.TILE_H} isDeletableThumb={isDeletableThumb} />
                )
            }
        }

        return (<div data-test-id="creation-media-box"
            className={props.variant + "-media-box"} ref={gridRef}>
            <Grid columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                justifyContent="left"
                gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                {selectedMediaList.map((media, index) =>
                    <div className="creation-media-thumb-box" key={index} style={{ width: viewLayoutConfig.TILE_W, height: viewLayoutConfig.TILE_H }}>
                        {getMediaNode(media, index)}
                    </div>
                )}
            </Grid>
        </div>);
    }

    return (
        <>
            <div data-test-id="creation-media-parent-box" className={props.variant + "-panel-box"}>
                {props.creationHeaderBox}
                {selectedMediaList.length === 0 && <Flex data-test-id="creation-media-progress-box" width="100%" height="80%"
                    alignItems="center" justifyContent="center">
                    <ProgressCircle aria-label="Loading…" isIndeterminate />
                </Flex>}
                {selectedMediaList.length > 0 && <ELScrollPanel forwardedRef={observerRef} UNSAFE_className={props.variant + "-panel-scroll"} scrollY={true} width="100%">
                    <div className="creation-panel__content-box">
                        {getMediaList(props.isDeletableThumb)}
                    </div>
                </ELScrollPanel>}
            </div>
        </>
    )
}

export default ELCreationMediaView;
