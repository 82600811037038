/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "../actions/IAction";
import PhotoTextAction, { PhotoTextActionPayload } from "../actions/PhotoTextAction";

interface PhotoTextReducerData {
    transparentBackground: boolean,
    background: string,
    opacity: number
}

const updateTransparentBackground = (state: PhotoTextReducerData, action: IAction<PhotoTextActionPayload>): PhotoTextReducerData => {
    return {
        ...state,
        transparentBackground: action.payload as boolean
    }
}

const updateBackground = (state: PhotoTextReducerData, action: IAction<PhotoTextActionPayload>): PhotoTextReducerData => {
    if (action.payload) {
        return {
            ...state,
            background: action.payload as string
        }
    }
    return state;
}

const updateOpacity = (state: PhotoTextReducerData, action: IAction<PhotoTextActionPayload>): PhotoTextReducerData => {
    if (action.payload) {
        return {
            ...state,
            opacity: action.payload as number
        }
    }
    return state;
}

const photoTextReducer = (state: PhotoTextReducerData = { transparentBackground: false, background: "black", opacity: 100 }, action: IAction<PhotoTextActionPayload>): PhotoTextReducerData => {
    switch (action.type) {
        case PhotoTextAction.UPDATE_TRANSPARENT_BACKGROUND:
            return updateTransparentBackground(state, action);
        case PhotoTextAction.UPDATE_BACKGROUND:
            return updateBackground(state, action);
        case PhotoTextAction.UPDATE_OPACITY:
            return updateOpacity(state, action);
        default:
            return state;
    }
}

export default photoTextReducer;