/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import ElementsCreationsService from "../../../../services/ElementsServices/ElementsCreationsService";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import store from "../../../../stores/store";
import { ToastUtils } from "../../../../utils/ToastUtils";
import IWorkflow, { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { CreationsData, CreationsDownloadFileType, CreationsTypeToShareTypeMap } from "../../../../common/interfaces/creations/CreationTypes";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import { ShareOptions } from "../../organism/el-share-options/ELShareOptions";
import { ShareAction } from "../../../../stores/actions/ShareAction";
import { ELCreationsHeaderControllerAction, ELCreationsHeaderViewAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes, ShareInvocationPoint } from "../../../../utils/IngestConstants";
import ITemplateViewController from "../../../ITemplateViewController";
import { ShareOptionsActionType } from "../../organism/el-share-options/ELShareOptionsView";
import { CreationWorkflowActions } from "../../../../common/interfaces/creations/CreationTypes";
import Utils from "../../../../utils/Utils";
import { ShareUtils } from "../../../../utils/ShareUtils";
import { ControllerAction } from "../../../IViewController";
import { ELQRSharePayload, ShareTypeCategory } from "../../../../common/interfaces/share/ShareTypes";

class ICreationsHeader extends ITemplateViewController {
    protected creationData?: CreationsData;
    protected shareOptions: ShareOptions;

    constructor(owner: IWorkflow, shareOptions: ShareOptions) {
        super(owner);
        this.shareOptions = shareOptions;
    }

    private async _fetchAndUpdateCreationData(): Promise<void> {
        if (!this.creationData)
            return;

        this.creationData = await CreationUtils.getProjectData(this.creationData?.id);
    }

    private _updateShareType(): void {
        if (!this.creationData)
            return;

        const shareType = CreationsTypeToShareTypeMap[this.creationData.operationSubType];
        if (shareType) {
            store.dispatch(ShareAction.updateShareType(shareType));
        }
    }

    protected async createShareQR(): Promise<void> {
        if (!this.creationData) {
            Logger.log(LogLevel.DEBUG, "[_createShareQR]: Please debug how creation Id is empty up till here");
            return Promise.reject(false);
        }

        await this._fetchAndUpdateCreationData();

        const outputAsset = await CreationUtils.getCreationOutputAsset(this.creationData);
        const workflowName = IngestUtils.getIngestCreationsWorkflowName((this._owner as IWorkflow).getWorkflowName);

        if (outputAsset.assetId) {
            const payload: ELQRSharePayload = {
                assetsToShare: [outputAsset.assetId],
                category: ShareTypeCategory.creation,
                source: workflowName
            };
            this.shareOptions.notify({ type: ShareOptionsActionType.createShareQR, payload: payload });
        } else {
            Logger.log(LogLevel.WARN, "[createShareQR]: asset doesn't have assetId");
            return Promise.reject(false);
        }
    }

    protected async shareCreation(): Promise<void> {
        if (!this.creationData) {
            Logger.log(LogLevel.DEBUG, "[shareCreation]: Please debug how creation Id is empty up till here");
            return Promise.reject(false);
        }

        await this._fetchAndUpdateCreationData();
        this._updateShareType();
        const shareMode = store.getState().shareReducer.shareMode;
        const shareType = IngestUtils.shareTypeToIngest(store.getState().shareReducer.shareType);
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.shareChannel;
        additionalLogInfo[IngestLogObjectKey.eventCount] = shareMode;
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
            IngestEventSubTypes.click, IngestEventTypes.share, shareType, additionalLogInfo));
        ShareUtils.ingestShareStart(ShareInvocationPoint.workspace, this.notify.bind(this));
        const outputAsset = await CreationUtils.getCreationOutputAsset(this.creationData);
        this.shareOptions.notify({ type: ShareOptionsActionType.openShareWorkspace, payload: { mediaList: [outputAsset.assetId] } });
    }

    protected async sharePopoverClicked(): Promise<void> {
        const ingestWorkflowName = IngestUtils.getIngestCreationsWorkflowName((this._owner as IWorkflow).getWorkflowName);
        this._owner.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventSubTypes.click, IngestEventTypes.share, ingestWorkflowName)
        });
        await this.createShareQR();
    }

    private _onCreationTitleChanged(): void {
        this._owner.notify({ type: CreationWorkflowActions.renameCreationTitle });
    }

    protected async _renameCreation(payload: string): Promise<boolean> {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;

        if (!this.creationData) {
            Logger.log(LogLevel.DEBUG, "[_renameCreation]: Please debug how creation Id is empty up till here");
            this.viewDispatcher?.call(this, {
                type: ELCreationsHeaderViewAction.changeName,
                payload: {
                    status: true
                }
            });
            return Promise.reject(false);
        }
        const ownerWorkflowName = (this._owner as IWorkflow).getWorkflowName;

        try {
            const status = await ElementsCreationsService.getInstance().renameCreation(this.creationData.id, payload);
            this._fetchAndUpdateCreationData();
            this.viewDispatcher?.call(this, {
                type: ELCreationsHeaderViewAction.nameChanged,
                payload: {
                    status,
                    title: payload
                }
            });
            if (ownerWorkflowName) {
                const ingestWorkflowName = IngestUtils.getIngestCreationsWorkflowName(ownerWorkflowName);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.success, IngestEventTypes.rename, ingestWorkflowName, additionalLogInfo));
            }
            this._onCreationTitleChanged();
            return Promise.resolve(status);
        } catch (e) {
            Logger.log(LogLevel.WARN, "ICreationHeader:_renameCreation: ", "API call failure while renaming creation");
            this.viewDispatcher?.call(this, {
                type: ELCreationsHeaderViewAction.nameChanged,
                payload: {
                    status: false,
                    title: this.creationData.title
                }
            });
            if (ownerWorkflowName) {
                const ingestWorkflowName = IngestUtils.getIngestCreationsWorkflowName(ownerWorkflowName);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.error, IngestEventTypes.rename, ingestWorkflowName, additionalLogInfo));
            }
            /* Toast_V2 */
            ToastUtils.error(IntlHandler.getInstance().formatMessage("error-renaming"), {
                closable: false,
                timeout: 5000
            });
            return Promise.reject(false);
        }
    }

    protected _updateCreationsData(creationData: CreationsData): void {
        this.creationData = creationData;

        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELCreationsHeaderViewAction.updateCreationsData,
            payload: this.creationData
        });

        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELCreationsHeaderViewAction.nameChanged,
            payload: {
                status: true,
                title: this.creationData.title
            }
        });

        Logger.log(LogLevel.DEBUG, "[updateFromCreationsData]: creation Id:", this.creationData);
    }

    protected _ingest(payload: Record<string, string>): void {
        this.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    protected getDownloadOptions(): CreationsDownloadFileType[] {
        const radioButtonList = [CreationsDownloadFileType.jpeg, CreationsDownloadFileType.png];
        return radioButtonList;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this.creationData = undefined;
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELCreationsHeaderControllerAction.sharePopoverClicked:
                {
                    await this.sharePopoverClicked();
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.createShareQR:
                {
                    await this.createShareQR();
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.download:
                {
                    await this._owner.notify({ type: ELCreationsHeaderControllerAction.download, payload: action.payload });
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.save:
                {
                    await this._owner.notify({ type: ELCreationsHeaderControllerAction.save });
                    handled = true;
                    break;
                }
            case ELCreationsHeaderControllerAction.share:
                {
                    await this.shareCreation();
                    handled = true;
                    break;
                }
        }
        return handled;
    }
}

export default ICreationsHeader;