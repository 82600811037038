/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer } from "react";

//adobe internal
import { Flex, Slider } from '@adobe/react-spectrum'

//Application Specific
import IViewController from "../../../IViewController";
import { ELLayoutPanelControllerAction } from "../../../../common/interfaces/stage/StageTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import { ViewAction } from "../../../IBaseController";
import { ELSocialLayoutState, ELSocialLayoutThumbData, ELSocialLayoutViewActions } from "../../../../common/interfaces/creations/ELSocialLayoutTypes";
import ELCollapsiblePanelView from "../../organism/el-collapsible-panel/ELCollapsiblePanelView";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import LayoutAction from "../../../../stores/actions/LayoutAction";
import { CollapsiblePanelData } from "../../../../common/interfaces/creations/templates/ELCollapsiblePanelTypes";
import ELIconButton from "../../molecules/el-icon-button/ELIconButtonView";
import { CanvasMode } from "../../../../stores/actions/CanvasAction";

import "./ELLayoutPanel.scss"


interface ELLayoutPanelProps {
    controller: IViewController,
}

const initialState: ELSocialLayoutState = {
    viewData: [],
    selectedPanelId: ""
}

const ELLayoutPanelView = (props: ELLayoutPanelProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const [scaleValue, setScaleValue] = React.useState(0);
    const layoutStoreData = useSelector((state: RootState) => state.layoutReducer);
    const canvasStoreData = useSelector((state: RootState) => state.canvasReducer);
    const reduxDispatch = useDispatch();

    const reducer = (state: ELSocialLayoutState, action: ViewAction): ELSocialLayoutState => {
        switch (action.type) {
            case ELSocialLayoutViewActions.updateAllThumbsData: {
                const updatedData = action.payload as ELSocialLayoutThumbData[];
                return {
                    ...state,
                    viewData: updatedData
                };
            }
            case ELSocialLayoutViewActions.selectedPanelId: {
                return {
                    ...state,
                    selectedPanelId: action.payload as string
                };
            }
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (layoutStoreData.scale >= 0) {
            setScaleValue(layoutStoreData.scale);
        }
    }, [layoutStoreData.scale]);

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const getLayoutList = (): React.ReactNode => {
        const onPanelChange = (id: string): void => {
            if (state.selectedPanelId === id)
                viewDispatch({ type: ELSocialLayoutViewActions.selectedPanelId, payload: "" });
            else
                viewDispatch({ type: ELSocialLayoutViewActions.selectedPanelId, payload: id });
        }

        const changeAspectRatio = (id: string): void => {
            const selectedTemplate = state.viewData.filter(value => value.id === id)[0];
            if (selectedTemplate && selectedTemplate.id !== layoutStoreData.selectedLayout) {
                reduxDispatch(LayoutAction.updateSelectedLayout(id));
                props.controller.notify({ type: ELLayoutPanelControllerAction.change, payload: selectedTemplate.aspectRatio });
            }
        }

        const layoutMap = new Map();

        state.viewData.forEach(function (template) {
            const templateData: CollapsiblePanelData = {
                id: template.id,
                name: template.subType,
                previewUrl: template.previewUrl,
                hoverText: template.hoverText
            };

            if (layoutMap.has(template.type))
                layoutMap.get(template.type).push(templateData);
            else
                layoutMap.set(template.type, [templateData]);
        });

        const keys = [...layoutMap.keys()];

        return (
            keys.map((item, index) => <ELCollapsiblePanelView key={index} flyoutHeaderText={item} panelID={item}
                onThumbPress={(id) => changeAspectRatio(id)} onPress={onPanelChange} opened={item === state.selectedPanelId}
                data={layoutMap.get(item)} selectedTemplateId={layoutStoreData.selectedLayout} showImageText={true} showImageBorder={true} />)
        )
    }

    const onSliderChange = (val: number): void => {
        setScaleValue(val);
        props.controller.notify({ type: ELLayoutPanelControllerAction.scale, payload: val });
    }

    return (
        <>
            <Flex UNSAFE_className="social-layout-header">
                <div>{intlHandler.formatMessage("layout")}</div>
                <div className={"social-layout-revert-box"} title={intlHandler.formatMessage("revert")}>
                    <ELCustomIcon variant="independent" iconkey={IconType.revert} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={() => props.controller.notify({ type: ELLayoutPanelControllerAction.revert })}>
                    </ELCustomIcon>
                </div>
            </Flex>
            <Flex UNSAFE_className="social-layout-body">
                <ELScrollPanel UNSAFE_className="social-layout-panel-scroll" scrollY={true} width="100%">
                    <div className="collage-panel__content-box">
                        <Flex direction="column" gap="0.625rem" marginTop="1rem">
                            {getLayoutList()}
                        </Flex>
                    </div>
                </ELScrollPanel >
                <Flex direction={"column"}>
                    <Flex UNSAFE_className="social-layout-scale-slider">
                        <Slider
                            label={intlHandler.formatMessage("scale-image")}
                            value={scaleValue}
                            onChange={onSliderChange}
                            width={"100%"}
                            isDisabled={canvasStoreData.mode !== CanvasMode.layout}
                            isFilled={true}
                        />
                    </Flex>
                    <Flex UNSAFE_className="social-layout-apply-box"
                        direction="row" gap="1rem">
                        <ELIconButton size="L" variant="secondary" iconkey={IconType.crossMark} iconWidth={"1rem"} iconHeight={"1rem"} iconColor={"black"} iconHoverColor={"black"}
                            onClick={() => props.controller.notify({ type: ELLayoutPanelControllerAction.cancel })} isDisabled={canvasStoreData.mode !== CanvasMode.layout}>
                            <span className="social-layout-button-text">{intlHandler.formatMessage("cancel")}</span>
                        </ELIconButton>
                        <ELIconButton size="L" variant="cta" iconkey={IconType.checkMark} iconWidth={"1rem"} iconHeight={"1rem"} iconColor={"white"} iconHoverColor={"white"}
                            onClick={() => props.controller.notify({ type: ELLayoutPanelControllerAction.commit })} isDisabled={canvasStoreData.mode !== CanvasMode.layout}>
                            <span className="social-layout-button-text">{intlHandler.formatMessage("apply")}</span>
                        </ELIconButton>
                    </Flex >
                </Flex>
            </Flex>
        </>
    )
}

export default ELLayoutPanelView;