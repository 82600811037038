/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ChangeEvent, useRef } from "react";

//Application Specific
import IViewController from "../../../IViewController";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { FILE_TYPE_FILTER } from "../../../../common/interfaces/storage/FileTypes";
import ELButton from "../../atoms/el-button/ELButtonView";
import { ELNoMediaBanner } from "../../molecules/el-no-media-banner/ELNoMediaBannerView";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import { GridMediaType } from "../../../../stores/actions/mediaGridConfigActions";

import "./ELEmptyBannerMediaGrid.scss";

export enum ELEmptyBannerMediaGridControllerAction {
    startImport = "START_IMPORT",
    removeFilter = "REMOVE_FILTER"
}

interface ELEmptyBannerMediaGridViewProps {
    controller: IViewController,
    showAddButton?: boolean
}

const tooManyFiltersBanner = (removeFilter: () => void): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const elIcon = <ELIcon iconkey={IconType.tooManyFilters} width="12.25rem" height="11.56rem" />;
    const textBody = (<>
        <span className="too-many-filters-paras">{intlHandler.formatMessage("too-many-filters-para1")}</span>
    </>);

    return (
        <ELNoMediaBanner icon={elIcon} heading={intlHandler.formatMessage("too-many-filters-heading")}
            textBody={textBody} className="too-many-filter-media-view">
            <ELButton size="L" onClick={removeFilter}>
                <span className="too-many-filter-media-view__remove-filters-button__text">
                    {intlHandler.formatMessage("remove-filters")}
                </span>
            </ELButton>
        </ELNoMediaBanner>
    )
}

export const ELEmptyBannerMediaGridView = (props: ELEmptyBannerMediaGridViewProps): React.ReactElement => {
    const configData = useSelector((state: RootState) => state.mediaConfigReducer)[GRID_CONFIG_KEY];
    const elIcon = <ELIcon iconkey={IconType.emptyELMediaGrid} width="13rem" height="13rem" />;
    const intlHandler = IntlHandler.getInstance();
    const importButtonRef = useRef<HTMLInputElement>(null);
    const isImportInProgress = useSelector((state: RootState) => state.mediaGridToolbarStateReducer?.importInProgress?? false);
    const filtersApplied = (): boolean => {
        return configData.mediaType !== GridMediaType.eImageAndVideo;
    }

    if (filtersApplied()) {
        return tooManyFiltersBanner(() => {
            props.controller.notify({
                type: ELEmptyBannerMediaGridControllerAction.removeFilter
            })
        });

    }

    const textBody = (<>
        <span className="no-media-banner-media-view__paras">{intlHandler.formatMessage("no-media-banner-media-view-para1")}</span>
        <span className="no-media-banner-media-view__paras">{intlHandler.formatMessage("no-media-banner-media-view-para2")} </span>
    </>);

    const openFilePicker = (): void => {
        if (importButtonRef.current) {
            importButtonRef.current.click();
        }
    }

    const startImport = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files?.length && event.target.files.length > 0) {
            props.controller.notify({
                type: ELEmptyBannerMediaGridControllerAction.startImport,
                payload: event.target.files
            });
        }
    }

    return (
        <ELNoMediaBanner icon={elIcon} textBody={textBody} className="no-media-banner-media-view"
            heading={intlHandler.formatMessage("no-media-banner-media-view-heading")}>
            <input type="file" id="import-input" className="hidden-input-container"
                multiple accept={FILE_TYPE_FILTER.join(", ")}
                onChange={startImport} ref={importButtonRef} />
            <ELButton isHidden={!props.showAddButton} size="L" onClick={openFilePicker} isDisabled={isImportInProgress}>
                <span className="too-many-filter-media-view__browse-button__text">{intlHandler.formatMessage("add")}</span>
            </ELButton>
        </ELNoMediaBanner>
    )
}

ELEmptyBannerMediaGridView.defaultProps = {
    showAddButton: true
}
