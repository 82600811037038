/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

//Application Specific
import ITemplateViewController from "../../../ITemplateViewController";
import { ELEditErrorDialogView, ELEditErrorDialogViewProps } from "./ELEditErrorDialogView";
import { ViewAction } from "../../../IBaseController";
import { history } from "../../../../utils/Utils";

export class ELEditErrorDialog extends ITemplateViewController {
    private readonly _defaultHeading = "";
    private readonly _defaultDescription = "";
    private readonly _defaultLearnMoreLink = "";
    private readonly _defaultOnClose = (): void => {return;};

    createView(container: HTMLElement): void {
        super.createView(container);
        const editErrorDialog = React.createElement(ELEditErrorDialogView, {
            controller: this,
            heading: this._defaultHeading,
            description: this._defaultDescription,
            onClose: this._defaultOnClose,
            learnMoreLink: ""
        });
        const editErrorDialogWithHeader = React.createElement(Router, { history }, editErrorDialog);

        ReactDOM.render(editErrorDialogWithHeader, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    getView(viewProps?: unknown): React.ReactNode {
        const props = viewProps as Partial<ELEditErrorDialogViewProps>;
        const editErrorDialog = React.createElement(ELEditErrorDialogView, {
            controller: this,
            heading: props?.heading ?? this._defaultHeading,
            description: props?.description ?? this._defaultDescription,
            onClose: props?.onClose ?? this._defaultOnClose,
            learnMoreLink: props?.learnMoreLink ?? this._defaultLearnMoreLink
        });
        return React.createElement(Router, { history }, editErrorDialog);
    }
}