/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from "react";

export interface ViewAction {
  type: string;
  payload?: unknown;
}

export default abstract class IBaseController {
  protected viewDispatcher?: React.Dispatch<ViewAction>

  initialize(dispatch?: React.Dispatch<ViewAction>): void {
    this.viewDispatcher = dispatch
  }

  destroy(): void {
    this.viewDispatcher = undefined
  }

  ensureHTMLElement(id: string): HTMLElement {
    const element = document.getElementById(id);
    if (!element) throw new Error(`missing element ${id}`);
    return element;
  }
}
