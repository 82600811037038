/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { AxiosResponse } from "axios";

//Adobe Internal
import { API } from "@elements/elementswebcommon";

//Application specific
import { EmailTriggerSource, NotificationRequestBody, EmailTemplateInfoMap } from "./utils/NotificationConstants";
import IMS from "./../IMS";
import Utils from "./../../utils/Utils";

export class EmailNotificationService {
    private static _instance: EmailNotificationService | null = null;
    private _serviceAPI: API;
    private readonly _notifyEndpoint: string = "notify";

    static getInstance(): EmailNotificationService {
        if (EmailNotificationService._instance === null)
            EmailNotificationService._instance = new EmailNotificationService();
        return EmailNotificationService._instance;
    }

    constructor() {
        this._serviceAPI = new API(`${process.env.REACT_APP_ELEMENTS_V1_URL}`);
    }

    private _getEmailTemplateName(eventSource: EmailTriggerSource): string | undefined {
        return EmailTemplateInfoMap[eventSource];
    }

    private _getHeaders(): Record<string, string> {
        const imsToken = IMS.getInstance().getUserAccessToken();
        return {
            "Content-Type": "application/json",
            "X-api-key": `${process.env.REACT_APP_IMS_API_KEY}`,
            "Authorization": `${imsToken}`
        }
    }

    private _fetchTemplateData(payload: any): Record<string, unknown> {
        const templateData = Object.assign({}, payload.eventData);
        delete templateData.receiptentList;
        return {
            "notificationType": payload.eventType,
            "notificationPayload": [{
                "templateName": `${this._getEmailTemplateName(payload.eventSource)}`,
                "locale": `${Utils.getCurrentLocaleInSnakeCase()}`,
                "emailRequestBody": {
                    "toList": payload.eventData.receiptentList,
                    "templateData": templateData
                }
            }]
        };
    }

    async sendEmail(payload: NotificationRequestBody): Promise<AxiosResponse>  {
        try {
            const data = this._fetchTemplateData(payload);
            const config = {
                headers: this._getHeaders()
            }
            const response = await this._serviceAPI.post(data, config, this._notifyEndpoint);
            return response;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
