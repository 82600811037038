/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { DocumentFormat } from "../../../common/interfaces/document/DocumentTypes";
import IImageFormat from "../../document/formats/IImageFormat";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";
import ELCPSDFormat from "./ELCPSDFormat";
import ELJPGFormat from "./ELJPGFormat";
import ELPNGFormat from "./ELPNGFormat";
import ELPSDFormat from "./ELPSDFormat";
import ELTIFFFormat from "./ELTIFFFormat";

export const ImageFormatMap: { [key in DocumentFormat]: any } = {
    [DocumentFormat.JPG]: { format: ELJPGFormat },
    [DocumentFormat.PNG]: { format: ELPNGFormat },
    [DocumentFormat.TIFF]: { format: ELTIFFFormat },
    [DocumentFormat.PSD]: { format: ELPSDFormat },
    [DocumentFormat.PSD2]: { format: ELPSDFormat },
    [DocumentFormat.CPSD]: { format: ELCPSDFormat },

};

export default abstract class ELPIEImageFormatFactory {
    static createFormat(format: DocumentFormat): IImageFormat<PIEEditingEngine> {
        return new ImageFormatMap[format].format();
    }
}
