/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';
import { SelectedMediaListType } from '../../view/components/organism/el-mediagrid/ELMediaGrid';

export type SelectedMediaListPayload = SelectedMediaListType;

export default class SelectedMediaListAction {

    public static readonly UPDATE_SELECTED_MEDIA: string = 'SelectedMediaListAction.UPDATE_SELECTED_MEDIA';

    public static updateSelectedMediaList(selectedMediaList: SelectedMediaListPayload): IAction<SelectedMediaListPayload> {
        return {
            type: SelectedMediaListAction.UPDATE_SELECTED_MEDIA,
            payload: selectedMediaList
        };
    }
}