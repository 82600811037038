/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import { AdobeAsset } from "@dcx/common-types";

//Application Specific
import { StorageService } from "../../services/StorageServiceWrapper";
import Constants from "../../utils/Constants/Constants";
import ImageUtils from "../../utils/ImageUtils";
import Logger, { LogLevel } from "../../utils/Logger";

export interface ELSaveInfo {
    blob: Blob,
    metaData?: Record<string, unknown>
}

export interface ELUploadInfo {
    assetPath: string,
    contentType: string,
    saveInfo: ELSaveInfo
}

export const ASSET_ALREADY_EXISTS_ERROR = "ALREADY_EXIST";

/**
 * Handles client upload operations for EL (Elements) application.
 */
export default class ELClientUploadHandler {

    private async _putAppMetadata(asset: AdobeAsset, metaData: Record<string, unknown>): Promise<void> {
        try {
            const stringifiedMetaData = JSON.stringify(metaData);
            Logger.log(LogLevel.INFO, "ELClientUploadHandler::_putAppMetadata, stringifiedDoc: ", stringifiedMetaData);
            await StorageService.getInstance().putAppMetadata(asset, stringifiedMetaData);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELClientUploadHandler::_putAppMetadata error in putting app metadata " + error);
            throw error;
        }
    }

    /**
     * Uploads an asset and its metadata.
     * @param uploadInfo - The upload information containing the asset path, blob, content type, and metadata.
     * @returns A Promise that resolves to the asset ID if the upload is successful, or rejects with an error.
     */
    async upload(uploadInfo: ELUploadInfo): Promise<string | undefined> {
        try {
            const buffer = await ImageUtils.readArrayBufferFromBlob(uploadInfo.saveInfo.blob);
            const path = uploadInfo.assetPath.split(Constants.CLOUD_CONTENT_FOLDER as string + Constants.DIR_SEPERATOR)[1];

            const asset = await StorageService.getInstance().createAssetByPath(Constants.CLOUD_CONTENT_FOLDER as string, path, true, uploadInfo.contentType);
            const status = await StorageService.getInstance().updatePrimaryResource(asset, buffer, uploadInfo.contentType, buffer.byteLength);
            Logger.log(LogLevel.INFO, "ELClientUploadHandler::upload, buffer: ", buffer, buffer.byteLength, status);

            if (uploadInfo.saveInfo.metaData) {
                await this._putAppMetadata(asset, uploadInfo.saveInfo.metaData);
            }

            return Promise.resolve(asset.assetId);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELClientUploadHandler::upload error in uploading asset and metadata " + error);
            if(StorageService.getInstance().isAssetAlreadyExistError(error as Error)) {
                return Promise.reject(ASSET_ALREADY_EXISTS_ERROR);
            }
            return Promise.reject(error);
        }
    }

    /**
     * Updates the asset with the provided upload information.
     * @param uploadInfo - The upload information containing the asset path, blob, content type, and metadata.
     * @returns A Promise that resolves to the asset ID if the update is successful, or rejects with an error.
     */
    async updateAsset(uploadInfo: ELUploadInfo): Promise<string | undefined> {
        try {
            const buffer = await ImageUtils.readArrayBufferFromBlob(uploadInfo.saveInfo.blob);
            const asset = await StorageService.getInstance().resolveAsset({ path: uploadInfo.assetPath, repositoryId: "" }, "path");
            const status = await StorageService.getInstance().updatePrimaryResource(asset, buffer, uploadInfo.contentType, buffer.byteLength);
            Logger.log(LogLevel.INFO, "ELClientUploadHandler::updateAsset, buffer: ", buffer, buffer.byteLength, status);

            //TODO: Glia Revisit this code
            //upload the new metadata (override the common keys), keep the old metadata as it is
            if (uploadInfo.saveInfo.metaData) {
                await this._putAppMetadata(asset, uploadInfo.saveInfo.metaData);
            }

            return Promise.resolve(asset.assetId);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELClientUploadHandler::updateAsset error in updatingAsset and metadata " + error);
            return Promise.reject(error);
        }
    }
}