/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useRef } from 'react';
import { StyleProps, PressEvent } from '@react-types/shared';
import { useStyleProps } from "@react-spectrum/utils";
import { useButton } from "@react-aria/button";

import "./ELArrow.scss"

type Direction = 'top' | 'left' | 'right' | 'bottom';

export interface ELArrowProps extends StyleProps {
    direction: Direction,
    isVisible?: boolean,
    onPress?: (e: PressEvent) => void
}

const ELArrow = (props: ELArrowProps): React.ReactElement => {
    const {
        direction,
        isVisible,
        ...otherProps
    } = props;
    const { styleProps } = useStyleProps(otherProps);

    const ref = useRef(null);
    const { buttonProps } = useButton({
        onPress: props.onPress,
    }, ref);

    return (
        <div className={"arrow-content-box " + props.direction} ref={ref} {...buttonProps}
            style={{ ...styleProps.style, "visibility": isVisible ? "visible" : "hidden" }}>
            <div className={"arrow-box"}>
            </div>
        </div>
    )
}

export default ELArrow;