/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application specific
import ITemplateViewController from "../../../ITemplateViewController";
import IViewController, { ControllerAction } from "../../../IViewController";
import ELFeedbackNavView from "./ELFeedbackNavView";
import { ViewAction } from "../../../IBaseController";
import { ELFeedbackNavControllerAction } from "../../../../common/interfaces/navbar/ELNavBarTypes";
import { ELFeedbackDialog } from "../el-feedback-dialog/ELFeedbackDialog";

const LEAVE_FEEDBACK = "leave-feedback";
const FEEDBACK_QUESTION = "feedback-question";
const FEEDBACK_MESSAGE = "feedback-message";

class ELFeedbackNav extends ITemplateViewController {
    private _feedbackDialog: ELFeedbackDialog;

    constructor(owner: IViewController) {
        super(owner);
        this._feedbackDialog = new ELFeedbackDialog(this,
            LEAVE_FEEDBACK,
            FEEDBACK_QUESTION,
            FEEDBACK_MESSAGE,
            undefined,
            true);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const feedbackNav = React.createElement(ELFeedbackNavView, {
            controller: this
        })

        ReactDOM.render(feedbackNav, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELFeedbackNavControllerAction.feedbackIconClick:
                {
                    this._feedbackDialog.createView(this.ensureHTMLElement("feedback-dialog-container"));
                    handled = true;
                    break;
                }
        }

        if (!handled) {
            handled = await this._owner.notify(action);
        }

        return handled;
    }
}

export default ELFeedbackNav;