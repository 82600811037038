/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useEffect, useReducer, useRef } from "react";
import _ from "lodash";

//Adobe Internal
import { RadioGroup } from "@react-spectrum/radio";
import { usePress } from "@react-aria/interactions";
import { Flex, Grid, repeat } from "@adobe/react-spectrum";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import { ASSETS_ENDPOINT, SlideshowTemplateData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ELSlideshowTemplateViewAction } from "../../../../common/interfaces/creations/SlideshowTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import CreationsAction from "../../../../stores/actions/CreationsAction";
import ELVideo from "../../atoms/el-video/ELVideo";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";

import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestWorkflowTypes } from "../../../../utils/IngestConstants";

import "./SlideshowTemplateView.scss";

export interface SlideshowTemplateViewProps {
    controller: IViewController
}

interface ELSlideshowTemplateState {
    slideshowTemplateData: SlideshowTemplateData[]
    selectedTemplateId?: string
}

const initialState: ELSlideshowTemplateState = {
    slideshowTemplateData: []
}

interface SlideshowTemplateCardProps {
    onPress: (id: string) => void,
    dataTestId: string,
    templateData: SlideshowTemplateData,
    isSelected: boolean,
    notify: (_: ControllerAction) => void
}
const SlideshowTemplateCard = (props: SlideshowTemplateCardProps): React.ReactElement => {

    const { pressProps } = usePress({
        onPress: () => { props.onPress(props.templateData.id); }
    });

    const onPreviewPlay = (): void => {
        const template = props.templateData.id;
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = template;
        props.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventTypes.click,
                IngestEventSubTypes.theme, IngestWorkflowTypes.slideshow, additionalLogInfo)
        });
    }

    return (
        <div data-test-id={props.dataTestId} className={"template-card-box" + (props.isSelected ? " selected" : "")}
            {...pressProps}>
            <ELVideo UNSAFE_className="template-card-img" type="video/mp4" variant="customContained"
                posterURL={process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + props.templateData.thumbnailUrl}
                src={process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + props.templateData.previewUrl}
                onPlayCallback={onPreviewPlay} />
            <span className="template-card-text">{props.templateData.title}</span>
        </div>
    )
}

const SlideshowTemplateView = (props: SlideshowTemplateViewProps): React.ReactElement => {
    const reducer = (state: ELSlideshowTemplateState, action: ViewAction): ELSlideshowTemplateState => {
        switch (action.type) {
            case ELSlideshowTemplateViewAction.themeData:
                return {
                    ...state,
                    slideshowTemplateData: action.payload as SlideshowTemplateData[]
                };
            case ELSlideshowTemplateViewAction.setTemplateId:
                return {
                    ...state,
                    selectedTemplateId: action.payload as string
                };
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);
    const slideshowStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);
    const dispatch = useDispatch();

    const specLayoutConfig = {
        TILE_W: 25,
        TILE_H: 20,
        DIST_BETWEEN_TILE: 2
    };

    const gridRef = useRef(null);
    const observerRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: observerRef,
        gridRef: gridRef
    });

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        if (slideshowStoreData.activeSlideshowId) {
            const slideshowId = slideshowStoreData.activeSlideshowId;
            const selectedThemeId = slideshowStoreData.data[slideshowId].uiData?.inputs?.templates?.video;
            viewDispatch({ type: ELSlideshowTemplateViewAction.setTemplateId, payload: selectedThemeId });
        }
    }, [slideshowStoreData.data]);

    const intlHandler = IntlHandler.getInstance();

    const onTemplateChange = (id: string): void => {
        const slideshowId = slideshowStoreData.activeSlideshowId;
        if (slideshowId) {
            const uiData = _.cloneDeep(slideshowStoreData.data[slideshowId].uiData);
            if (uiData && uiData.inputs && uiData.inputs.templates) {
                uiData.inputs.templates.video = id;
                dispatch(CreationsAction.updateUIData(uiData));
            }
        }
    }

    const notify = props.controller.notify.bind(props.controller);

    const getTemplateList = (): ReactNode => {
        const templateList =
            (<Grid columns={repeat("auto-fit", viewLayoutConfig.TILE_W)} gap={viewLayoutConfig.DIST_BETWEEN_TILE} marginTop="1rem"
                justifyContent="start" alignItems="center">
                {state.slideshowTemplateData.map((slideshowTemplate) =>
                    <Flex key={slideshowTemplate.id} direction="row" justifyContent="start" alignItems="center"
                        position="relative" width="100%" height="100%">
                        <SlideshowTemplateCard dataTestId={"slideshow-template-" + slideshowTemplate.id} onPress={(id) => onTemplateChange(id)}
                            templateData={slideshowTemplate} isSelected={state.selectedTemplateId === slideshowTemplate.id} notify={notify} />
                    </Flex>
                )}
            </Grid>);

        return (<div ref={gridRef} >
            <RadioGroup data-test-id="slideshow-template-radio-group" width="100%" aria-label="slideshow-template-radio-group"
                value={state.selectedTemplateId} onChange={(id) => onTemplateChange(id)}>
                {templateList}
            </RadioGroup>
        </div>);
    }

    return (
        <>
            <div className="slideshow-panel-box">
                <ELPanelHeader title={intlHandler.formatMessage("template")} alignTitle="start" />
                <div className="slideshow-panel-description">{intlHandler.formatMessage("template-play-description")}</div>
                <ELScrollPanel forwardedRef={observerRef} UNSAFE_className={"slideshow-panel-scroll"} scrollY={true} maxHeight="90%" width="100%">
                    <div className="slideshow-panel__content-box">
                        {getTemplateList()}
                    </div>
                </ELScrollPanel>
            </div>
        </>
    )
}

export default SlideshowTemplateView;
