/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CreationsData, CreationsProgressPayload, CreationsVideoExportPresetPayload } from "../../common/interfaces/creations/CreationTypes";
import { RenditionData } from "../../common/interfaces/storage/AssetTypes";
import IAction from "./IAction";

export type CreationsActionPayloadUnion = CreationsVideoExportPresetPayload | CreationsThumb | CreationsData | CreationStatusPayload | CreationsProgressPayload | string;

export type CreationStatusPayload = Pick<CreationsData, "id" | "status">;

export interface CreationsThumb {
    id: string,
    objectURL: string
}

export interface CreationsVideoRendition {
    id: string,
    renditionData: RenditionData
}

export default class CreationsAction {

    public static readonly UPDATE_THUMB: string = "CreationsAction.UPDATE_THUMB";
    public static readonly UPDATE_STATUS: string = "CreationsAction.UPDATE_STATUS";
    public static readonly UPDATE_PROGRESS: string = "CreationsAction.UPDATE_PROGRESS";
    public static readonly UPDATE_APPLIED_DATA: string = "CreationsAction.UPDATE_APPLIED_DATA";
    public static readonly UPDATE_UI_DATA: string = "CreationsAction.UPDATE_UI_DATA";
    public static readonly UPDATE_ACTIVE_SLIDESHOW_ID: string = "CreationsAction.UPDATE_ACTIVE_SLIDESHOW_ID";
    public static readonly RESET_CREATIONS_DATA: string = "CreationsAction.RESET_CREATIONS_DATA";
    public static readonly UPDATE_SLIDESHOW_PANEL_SELECTED_KEY = "CreationsAction.UPDATE_SLIDESHOW_PANEL_SELECTED_KEY";
    public static readonly UPDATE_SLIDESHOW_VIDEO_EXPORTSETTINGS = "CreationsActions.UPDATE_CREATIONS_VIDEO_EXPORTSETTINGS";

    public static updateThumb(thumbData: CreationsThumb): IAction<CreationsThumb> {
        return {
            type: CreationsAction.UPDATE_THUMB,
            payload: thumbData
        };
    }

    public static updateStatus(data: CreationStatusPayload): IAction<CreationStatusPayload> {
        return {
            type: CreationsAction.UPDATE_STATUS,
            payload: data
        };
    }

    public static updateProgress(data: CreationsProgressPayload): IAction<CreationsProgressPayload> {
        return {
            type: CreationsAction.UPDATE_PROGRESS,
            payload: data
        };
    }

    public static updateAppliedData(data: CreationsData): IAction<CreationsData> {
        return {
            type: CreationsAction.UPDATE_APPLIED_DATA,
            payload: data
        };
    }

    public static resetCreationsData(creationsId: string): IAction<string> {
        return {
            type: CreationsAction.RESET_CREATIONS_DATA,
            payload: creationsId
        };
    }

    public static updateUIData(data: CreationsData): IAction<CreationsData> {
        return {
            type: CreationsAction.UPDATE_UI_DATA,
            payload: data
        };
    }

    public static updateActiveSlideshowId(creationsId: string): IAction<string> {
        return {
            type: CreationsAction.UPDATE_ACTIVE_SLIDESHOW_ID,
            payload: creationsId
        };
    }

    public static updateSlideshowPanelSelectedKey(selectedKey: string): IAction<string> {
        return {
            type: CreationsAction.UPDATE_SLIDESHOW_PANEL_SELECTED_KEY,
            payload: selectedKey
        };
    }

    public static updateVideoExportSettings(exportPresetPayload: CreationsVideoExportPresetPayload): IAction<CreationsVideoExportPresetPayload> {
        return {
            type: CreationsAction.UPDATE_SLIDESHOW_VIDEO_EXPORTSETTINGS,
            payload: exportPresetPayload
        }
    }
}
