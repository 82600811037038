/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { LinkInfo, ShareMode, ShareType } from "../../common/interfaces/share/ShareTypes";
import IAction from "./IAction";

export interface SharePayloadUnion {
    data: ShareMode | LinkInfo | string[] | ShareType | string
}

export class ShareAction {

    public static readonly UPDATE_SHARE_MODE: string = "ShareModeAction.UPDATE_SHARE_MODE";
    public static readonly UPDATE_SHARE_TYPE: string = "ShareModeAction.UPDATE_SHARE_TYPE";
    public static readonly UPDATE_LINK_INFO: string = "ShareModeAction.UPDATE_LINK_INFO";
    public static readonly UPDATE_ASSETS_COUNT: string = "ShareModeAction.UPDATE_ASSETS_COUNT";
    public static readonly UPDATE_ASSETS_TO_SHARE: string = "ShareModeAction.UPDATE_ASSETS_TO_SHARE";
    public static readonly UPDATE_VIEW_TYPE: string = "ShareModeAction.UPDATE_VIEW_TYPE";
    public static readonly RESET: string = "ShareModeAction.RESET";

    public static updateShareMode(shareMode: ShareMode): IAction<SharePayloadUnion> {
        return {
            type: ShareAction.UPDATE_SHARE_MODE,
            payload: { data: shareMode }
        };
    }

    public static updateShareType(shareType: ShareType): IAction<SharePayloadUnion> {
        return {
            type: ShareAction.UPDATE_SHARE_TYPE,
            payload: { data: shareType }
        };
    }

    public static updateLinkInfo(linkInfo: LinkInfo): IAction<SharePayloadUnion> {
        return {
            type: ShareAction.UPDATE_LINK_INFO,
            payload: { data: linkInfo }
        };
    }

    public static updateAssetsToShare(assets: string[]): IAction<SharePayloadUnion> {
        return {
            type: ShareAction.UPDATE_ASSETS_TO_SHARE,
            payload: { data: assets }
        };
    }

    public static updateViewType(viewType: string): IAction<SharePayloadUnion> {
        return {
            type: ShareAction.UPDATE_VIEW_TYPE,
            payload: { data: viewType }
        };
    }

    public static reset(): IAction<any> {
        return {
            type: ShareAction.RESET
        };
    }
}