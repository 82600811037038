/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { Key, useRef } from "react";

//Adobe Internal
import { StyleProps } from "@react-types/shared";
import { useStyleProps } from "@react-spectrum/utils";
import { usePress } from "@react-aria/interactions";
import { DialogContainer, ActionButton, Flex, Item, Menu, MenuTrigger, ProgressCircle } from "@adobe/react-spectrum";
import Info from "@spectrum-icons/ui/InfoMedium";
import Alert from "@spectrum-icons/ui/AlertMedium";

//Application Specific
import { ELMenuState, ELMenuData, ELMenuKeys } from '../../../../common/interfaces/menu/ELMenuTypes';
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { ERROR_THUMBDATA } from "../../../../utils/Constants/Constants";
import { ELRenameMediaView } from "../../organism/el-rename-media-dialog/ELRenameMediaView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { CreationsData, CreationsDisplayNames, CreationsJobProjectSubType, CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELCreationThumb.scss";

export interface ELCreationThumbProps extends StyleProps {
    data: CreationsData,
    srcData: string,
    menuItems: ELMenuData[],
    onMenuActions: (menuId: ELMenuKeys) => void,
    onPress: (data: CreationsData) => void,
    onRename: (title: string) => void
}

const ELCreationThumb = (props: ELCreationThumbProps): React.ReactElement<ELCreationThumbProps> => {
    const {
        data,
        srcData,
        onMenuActions,
        onPress,
        ...otherProps
    } = props;
    const { styleProps } = useStyleProps(otherProps);
    const intlHandler = IntlHandler.getInstance();
    const imgContainerRef = useRef(null);

    const imgContainerPressProps = usePress({
        onPress: () => { props.onPress(data) }
    });

    const onRename = (title: string): void => {
        props.onRename(title);
    }

    const isValidData = (): boolean => {
        if ((data.status === CreationsStatus.success || data.status === CreationsStatus.oldVersion) && srcData && srcData !== ERROR_THUMBDATA)
            return true;
        return false;
    }

    const shouldShowPlayIcon = (): boolean => {
        if (data.operationSubType === CreationsJobProjectSubType.slideshow && isValidData())
            return true;
        return false;
    }

    const shouldShowAlertThumb = (): boolean => {
        return data.status === CreationsStatus.editing
            || data.status === CreationsStatus.stale
            || data.status === CreationsStatus.created
            || data.status === CreationsStatus.error;
    }

    const getThumb = (): React.ReactElement => {
        if (data.status === CreationsStatus.success
            || data.status === CreationsStatus.oldVersion) {
            if (srcData) {
                if (srcData !== ERROR_THUMBDATA) {
                    const imageUri = srcData;
                    const spriteClass = "creation-img-thumb";
                    return (<img crossOrigin="anonymous" className={spriteClass} src={imageUri} alt={data.title} />);
                } else if (srcData === ERROR_THUMBDATA) {
                    return (<ELIcon className="creation-img-thumb" iconkey={IconType.staticImgThumb} />);
                }
            }

            return (<ProgressCircle aria-label="Loading…" isIndeterminate />);
        } else if (shouldShowAlertThumb()) {
            return <Alert width="1.875rem" height="1.875rem" />
        } else if (data.status === CreationsStatus.retry) {
            return <Info width="1.875rem" height="1.875rem" />
        }

        return (<ProgressCircle aria-label="Loading…" isIndeterminate />);
    }

    const getPlayIcon = (): React.ReactElement => {
        if (shouldShowPlayIcon())
            return (<div className={"video-play-icon"}>
                <ELIcon iconkey={IconType.playIcon} />
            </div>)

        return <></>;
    }

    const getTileThumbContainer = (): React.ReactElement => {
        const { pressProps } = imgContainerPressProps;
        return (<div data-test-id="creation-tile-container" onContextMenu={(e) => e.preventDefault()}>
            <div ref={imgContainerRef} {...pressProps} style={{ ...styleProps.style }} className="creation-img-container" data-test-id="creation-thumb-container">
                {getThumb()}
                {getPlayIcon()}
            </div>
        </div>);
    }

    const getDisplayNameFromOperationSubType = (data: CreationsData): string => {
        const creationName = CreationUtils.getCreationNameFromOperationSubtype(data);
        return intlHandler.formatMessage(CreationsDisplayNames[creationName]);
    }

    const getThumbSubTitle = (data: CreationsData): string => {
        let subTitle = getDisplayNameFromOperationSubType(data);
        if (data.status === CreationsStatus.requested) {
            subTitle += " " + intlHandler.formatMessage("creation-in-progress");
        }
        return subTitle;
    }

    return (
        <ELReactSpectrumV3Provider>
            <Flex direction="column" gap="size-50" >
                {getTileThumbContainer()}
                <Flex direction="row" justifyContent="space-between">
                    <Flex direction="column">
                        {data.title && <span className="creation-project-title-text" title={data.title}>{data.title}</span>}
                        <span className="creation-project-type-text">{getThumbSubTitle(data)}</span>
                    </Flex>
                    <ELSpriteMenu thumb={srcData} title={data.title ?? ""} onMenuActions={props.onMenuActions}
                        menuItems={props.menuItems} onRename={(title) => onRename(title)} />
                </Flex>
            </Flex>
        </ELReactSpectrumV3Provider>
    );
}

export interface ELSpriteMenuProps {
    title: string,
    thumb: string,
    menuItems: ELMenuData[],
    onMenuActions: (menuId: ELMenuKeys) => void,
    onRename: (title: string) => void
}

const ELSpriteMenu = (props: ELSpriteMenuProps): React.ReactElement<ELSpriteMenuProps> => {
    const intlHandler = IntlHandler.getInstance();
    const [dialog, setDialog] = React.useState("" as Key);

    const onMenuAction = (key: Key): void => {
        setDialog(key);
        props.onMenuActions(key as ELMenuKeys);
    }
    const getMenuItems = (): React.ReactElement => {
        const menuList = props.menuItems.map((menu) => {
            return (<Item data-testid={"el-creations-thumb-" + menu.id} key={menu.id}>{menu.title}</Item>);
        });

        const disabledKeys = props.menuItems.filter(menu => menu.state === ELMenuState.disabled).map((menu) => { return menu.id });

        return (<Menu data-testid="el-creations-thumb-menu" onAction={(key) => onMenuAction(key)} disabledKeys={disabledKeys}>{menuList}</Menu>);
    }

    const onRename = (title: string): void => {
        props.onRename(title);
    }

    return (
        <Flex direction="row">
            <MenuTrigger align="start">
                <ActionButton data-test-id="creation-thumb-menu-button" UNSAFE_className="el-spectrum-action-button">
                    <span className="dot">{"..."}</span>
                </ActionButton>
                {getMenuItems()}
            </MenuTrigger>
            <DialogContainer onDismiss={() => setDialog("")}>
                {dialog === ELMenuKeys.creationsRename &&
                    (
                        <ELRenameMediaView dialogHeading={intlHandler.formatMessage("rename-creation")}
                            defaultTitle={props.title} onSave={(title) => onRename(title)} />
                    )}
            </DialogContainer>
        </Flex>
    );
}

export default ELCreationThumb;
