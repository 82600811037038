/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { WorkspaceAction } from "../IBaseWorkspace";
import { WorkflowsName } from "../IWorkflow";

export class WorkflowSwitcher {
    private static _instance: WorkflowSwitcher = new WorkflowSwitcher();
    private _workflowName?: WorkflowsName;
    private _workflowAction?: WorkspaceAction;

    private constructor() {
        // Private constructor to prevent instantiation
    }

    static getInstance(): WorkflowSwitcher {
        return this._instance;
    }

    /**
     * Initializes the various class members
     * @param workflowName next workflow which should be started
     * @param workflowAction the workflowAction that will be required to start the next workflow
     */
    initialize(workflowName: WorkflowsName, workflowAction: WorkspaceAction): void {
        this._workflowName = workflowName;
        this._workflowAction = workflowAction;
    }

    get getWorkflowName(): WorkflowsName | undefined {
        return this._workflowName;
    }

    get getWorkflowAction(): WorkspaceAction | undefined {
        return this._workflowAction;
    }
}