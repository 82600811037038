/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import store from "../../../stores/store";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";

export class MediaExistUtil {
    private static _instance: MediaExistUtil;
    private _mediaExistPromise: Promise<boolean> | null = null;
    private _needToCheckMediaExist = true;


    private constructor() {
        // Private constructor to prevent instantiation
    }

    static getInstance(): MediaExistUtil {
        if (!this._instance) {
            this._instance = new MediaExistUtil();
        }
        return this._instance;
    }

    subscribeToStore(): void {
        let previousState = store.getState().mediaOrganizerReducer;

        store.subscribe(() => {
            const currentState = store.getState().mediaOrganizerReducer;
            if (previousState !== currentState) {
                previousState = currentState;
                this.handleMediaOrganizerReducerChange();
            }
        });
    }

    private handleMediaOrganizerReducerChange(): void {
        MediaExistUtil.getInstance().updateMediaExist();
    }

    private mediaExistInStore(): boolean {
        let mediaExist = false;
        const mediaOrganizerFullData = store.getState().mediaOrganizerReducer;
        for (const key in mediaOrganizerFullData) {
            if (mediaOrganizerFullData[key] && mediaOrganizerFullData[key].children.length > 0) {
                mediaExist = true;
                break;
            }
        }
        return mediaExist;
    }

    async doesMediaExist(): Promise<boolean> {
        if (this.mediaExistInStore()) {
            return true;
        } else {
            if (this._needToCheckMediaExist) {
                this._needToCheckMediaExist = false;
                this._mediaExistPromise = AssetStorageUtils.doesMediaExistForUser();
                return this._mediaExistPromise;
            } else {
                if (this._mediaExistPromise) {
                    return this._mediaExistPromise;
                } else {
                    return false;
                }
            }
        }
    }

    updateMediaExist(): void {
        this._needToCheckMediaExist = true;
        this.doesMediaExist();
    }
}