/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Application Specific
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import ELActionButton from "../../molecules/el-action-button/ELActionButton";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

import "./ELEditActionBarButton.scss";

interface ELEditButtonProps {
    onPress: () => void,
}

export const ELEditButton = (props: ELEditButtonProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const text = intlHandler.formatMessage("edit-action");
    const editIcon = (<ELIcon iconkey={IconType.edit} fill={"#fff"} className="edit-action-bar-button__icon" />);
    return (<ELActionButton tooltip={text} elIcon={editIcon} text={text} key={"edit-action-button"}
        onPress={props.onPress} dataTestId="edit-action-button" />)
}