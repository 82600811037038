/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { CreationsStatus } from "./CreationTypes";

export type RequestId = string;
export type ProjectId = string;

export enum CreationInAppNotifierAction {
    creationStatusChanged = "CREATION_STATUS_CHANGED",
    creationProgressChanged = "CREATION_PROGRESS_CHANGED"
}

export interface ELProjectProgressPayload {
    projectId: string,
    progress: number
}

export enum CreationAppSubscriberType {
    all = "ALL",
    statusChange = "STATUS_CHANGE"
}

export interface CreationStatusData {
    projectId: string,
    status: CreationsStatus
}

export enum RecommendationsInAppNotifierAction {
    recommendationStatusChanged = "RECOMMENDATION_STATUS_CHANGED",
    recommendationProgressChanged = "RECOMMENDATION_PROGRESS_CHANGED"
}

export interface ELRecommendationsProgressPayload {
    requestId: string,
    progress: number
}

export enum RecommendationsAppSubscriberType {
    all = "ALL",
    statusChange = "STATUS_CHANGE"
}

export interface RecommendationsStatusData {
    requestId: string,
    status: CreationsStatus
}