/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third Party
import React, { useEffect, useReducer } from "react";

// App Specific
import ELVideo from "../../view/components/atoms/el-video/ELVideo";
import { ViewAction } from "../../view/IBaseController";
import { CreationsStatus} from "../../common/interfaces/creations/CreationTypes";
import { ELAdobeAssetDocPayload } from "../adobeAsset/ELAdobeAssetDoc";
import { ELVideoDocViewActions, ELVideoViewProps } from "../../common/interfaces/document/ELVideoDocTypes";

import "./ELVideoDocView.scss"

interface ELVideoDocViewState {
    status: CreationsStatus,
    videoDocViewData: string
}

const initialState: ELVideoDocViewState = {
    status: CreationsStatus.requested,
    videoDocViewData: ""
}


export const ELVideoDocView = (props: ELVideoViewProps): React.ReactElement => {

    const reducer = (state: ELVideoDocViewState, action: ViewAction): ELVideoDocViewState => {
        switch (action.type) {
            case ELVideoDocViewActions.videoDocViewData:
                return { ...state, videoDocViewData: ((action.payload) as ELAdobeAssetDocPayload).assetInfo.objectURL as string };
            default:
                return state;
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        props.controller.initialize(dispatch);
        return () => {
            //clean up
            props.controller.destroy();
        }
    }, [props.controller]);

    return (<ELVideo
        key={state.videoDocViewData} UNSAFE_className="moving-overlay-video" autoPlay={true} loop={true} variant="native" type="video/mp4"
        src={state.videoDocViewData} />);
}