/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELError } from "../modules/error/ELError";
import { LogLevel } from "../utils/Logger";
import { PlatformUtils } from "../utils/PlatformUtils";
import Utils from "../utils/Utils";
import NewRelic from "./NewRelic";

declare global {
    interface Window {
        newrelic: any
    }
}

export default class NewRelicLogger {
    private static _getErrorMetadata(): Record<string, unknown> {
        return {
            "appConfigurations": {
                "userAgent": PlatformUtils.getUserAgent(),
                "locale": Utils.getCurrentLocaleInSnakeCase(),
                "version": PlatformUtils.getBuildVersion()
            }
        }
    }

    private static _getCustomAttributes(data: Record<string, unknown>): Record<string, string | number> {
        const attributes: Record<string, string | number> = {};
        if (data) {
            for (const key in data) {
                const value = data[key];
                if (typeof value === "string" || typeof value === "number") {
                    attributes[key] = value;
                } else if (typeof value === "bigint") {
                    attributes[key] = (value as bigint).toString();
                } else {
                    attributes[key] = Utils.safeStringify(value);
                }
            }
        }
        return attributes;
    }

    static postError(errorClass: LogLevel, args: any[]): void {
        if (NewRelic.isNewRelicEnabled() === false)
            return;
       
        if(args[0] && args[0] instanceof ELError) {
            const error = args[0] as ELError;

            const attributes = NewRelicLogger._getCustomAttributes(NewRelicLogger._getErrorMetadata());
            Object.assign(attributes, NewRelicLogger._getCustomAttributes(error.fullLogMetadata ?? {}));            
            attributes.fullStack = error.fullStack;
            attributes.fullMessageWithCode = error.fullMessageWithCode;

            window.newrelic?.noticeError(attributes);
        } else if (args[0] && typeof args[0] === "string") {
            args[0] = `${LogLevel[errorClass]} ${args[0]}`;
            window.newrelic?.noticeError(...args);
        }
    }
}   