/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import MediaGridConfigAction, { MediaGridConfig, mediaGridDefaultConfig } from "../actions/mediaGridConfigActions";
import IAction from "./../actions/IAction";

export const GRID_CONFIG_KEY = "GRID_CONFIG";

const defaultState: Record<string, MediaGridConfig> = {
    GRID_CONFIG: mediaGridDefaultConfig
}

const updateConfigData = (state: Record<string, MediaGridConfig> = defaultState, action: IAction<MediaGridConfig>): Record<string, MediaGridConfig> => {
    const config = action.payload;
    if (config) {
        return { GRID_CONFIG: config };
    } else {
        return state;
    }
}

const mediaConfigReducer = (state: Record<string, MediaGridConfig> = defaultState, action: IAction<MediaGridConfig>): Record<string, MediaGridConfig> => {
    switch (action.type) {
        case MediaGridConfigAction.UPDATE_CONFIG:
            return updateConfigData(state, action);
        default:
            return state;
    }
}

export default mediaConfigReducer;