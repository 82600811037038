/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { WindowUtils } from "../../utils/WindowUtils";
import { WAIT_FOR_BLUR_EVENT } from "../../utils/Constants/Constants";

export default class ELDeeplinkHandler {

    public async openLink(link: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let didReceiveBlurEvent = false;
            const handleBlur = (): void => {
                didReceiveBlurEvent = true;
            }

            setTimeout(() => {
                if (!didReceiveBlurEvent) {
                    window.removeEventListener("blur", handleBlur);
                    return reject(false);
                }

                window.removeEventListener("blur", handleBlur);
                return resolve(true);
            }, WAIT_FOR_BLUR_EVENT);

            window.addEventListener("blur", handleBlur);

            WindowUtils.open(link);
        });
    }
}
