/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import { WorkflowsName } from "../../IWorkflow";
import ImageEditWorkflowUtils from "./ImageEditWorkflowUtils";

export class EditWorkspaceUtils {
    static isSaveAvailable(isOnline: boolean): boolean {
        if (!isOnline || AssetStorageUtils.hasQuotaExceeded()) {
            return false;
        }
        return true;
    }

    static getEditWorkflowNameFromUrl(url: string): WorkflowsName {
        let workflow: WorkflowsName | null = null;

        if (ImageEditWorkflowUtils.hasImageEditWorkflowUrl(url) && ImageEditWorkflowUtils.getAssetURNFromUrl(url)) {
            workflow = WorkflowsName.imageEditWorkflow;
            return workflow;
        } else {
            throw new Error("EditWorkspaceUtils:getEditWorkflowNameFromUrl: Invalid workflow name.");
        }
    }
}