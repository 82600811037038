/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELPeekThroughDocPayload } from "../../../common/interfaces/creations/ELPeekThroughTypes";
import { EditingEngineType } from "../../../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { ELLayerKind, ELStageLayerData } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELLayerBlendModes } from "../../../common/interfaces/editing/pie/ELPIELayerTypes";
import { ELPIEPoint } from "../../../common/interfaces/geometry/ELGeometry";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import ImageUtils from "../../../utils/ImageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import IDoc from "../../document/IDoc";
import { EditingEngineManager } from "../../editingEngines/EditingEngineManager";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";
import ELPIEDoc from "../models/ELPIEDoc";
import IPSDConvertor from "./IPSDConvertor";

export default class ELPeekThroughPSDConvertor extends IPSDConvertor {
    private async _getBufferFromAsset(assetURN: string): Promise<ArrayBuffer> {
        const downloadURI = await AssetStorageUtils.getAndStoreAssetData(assetURN);
        const blob = await ImageUtils.createBlob(downloadURI);
        const buffer = await ImageUtils.readArrayBufferFromBlob(blob);

        return Promise.resolve(buffer);
    }

    private async _addAssetAsLayer(inputDoc: IDoc, psdDoc: IDoc): Promise<IDoc> {
        const inputDocData = inputDoc.getData() as ELPeekThroughDocPayload;
        const buffer = await this._getBufferFromAsset(inputDocData.backgroundAsset.assetURN);

        const size = await inputDoc.getOriginalSize();
        const scaledWidth = size.width * (inputDocData.layoutInfo ? inputDocData.layoutInfo?.scaleX : 1);
        const scaledHeight = size.height * (inputDocData.layoutInfo ? inputDocData.layoutInfo?.scaleY : 1);

        const widthDelta = inputDocData.layoutInfo ? scaledWidth * inputDocData.layoutInfo?.left : 0;
        const heightDelta = inputDocData.layoutInfo ? scaledHeight * inputDocData.layoutInfo?.top : 0;

        const center: ELPIEPoint = {
            horizontal: (scaledWidth / 2) - widthDelta,
            vertical: (scaledHeight / 2) - heightDelta
        };

        const layerData: ELStageLayerData = {
            data: buffer,
            layerParams: { layerBlendMode: ELLayerBlendModes.normal },
            redraw: false,
            layerKind: ELLayerKind.pixel,
            editInfo: {
                transform: {
                    center: center,
                    rotate: 0,
                    quadrilateral: {
                        left_top: inputDocData.layoutInfo?.left ?? 0,
                        right_top: inputDocData.layoutInfo?.top ?? 0,
                        left_bottom: scaledWidth,
                        right_bottom: scaledHeight
                    },
                    scale: {
                        horizontal: inputDocData.layoutInfo?.scaleX ?? 1,
                        vertical: inputDocData.layoutInfo?.scaleY ?? 1
                    },
                    offset: {
                        horizontal: inputDocData.layoutInfo?.left ?? 0,
                        vertical: inputDocData.layoutInfo?.top ?? 0
                    }
                }
            }
        };

        await psdDoc.addLayer(layerData);

        return Promise.resolve(psdDoc);
    }

    private async _addOverlaysAsLayer(inputDoc: IDoc, psdDoc: IDoc): Promise<IDoc> {
        const inputDocData = inputDoc.getData() as ELPeekThroughDocPayload;
        const overlayInfoList = inputDocData.overlayInfoList;
        const size = await inputDoc.getSize();

        if (overlayInfoList) {
            for (const overlayInfo of overlayInfoList) {
                if (!overlayInfo || !overlayInfo.asset.objectURL) {
                    continue;
                }

                const blob = await ImageUtils.createBlob(overlayInfo.asset.objectURL);
                const buffer = await ImageUtils.readArrayBufferFromBlob(blob);

                const layerData: ELStageLayerData = {
                    data: buffer,
                    layerParams: { layerBlendMode: ELLayerBlendModes.normal },
                    redraw: false,
                    layerKind: ELLayerKind.pixel,
                    editInfo: overlayInfo.edit
                };
                await psdDoc.addLayer(layerData);
            }
        }

        return Promise.resolve(psdDoc);
    }

    async convertToPSD(inputDoc: IDoc, doResize = false): Promise<IDoc> {
        try {
            const pieEditingEngine = await EditingEngineManager.getEditingEngine(EditingEngineType.pie) as PIEEditingEngine;
            await pieEditingEngine.ready();
            let psdDoc: IDoc = await this.createDocWithWhiteBackgroundLayer(inputDoc, pieEditingEngine);

            psdDoc = await this._addAssetAsLayer(inputDoc, psdDoc);
            await this._addOverlaysAsLayer(inputDoc, psdDoc);

            if (doResize && (await this.shouldResize())) {
                psdDoc = await this.resizeDocument(psdDoc as ELPIEDoc, pieEditingEngine);
            }

            return Promise.resolve(psdDoc);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Unable to create PSD", error);
            return Promise.reject("PSD couldn't be created!");
        }
    }
}