/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { AdjustmentsParams } from "../../common/interfaces/editing/adjustments/AdjustmentsEditorTypes"
import Logger, { LogLevel } from "../../utils/Logger";

export type AdjustmentsPreset = {
  id: number
  title: string
  additive: boolean
  hasIntensity: boolean
  params: AdjustmentsParams
  previewUrl?: string
}

export const DEFAULT_PRESET = "None";

export default class AdjustmentsPresets {
  static _presets: AdjustmentsPreset[] = [
    {
      id: 1,
      title: "natural",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 10,
        highlights: -10,
        shadows: 15,
        whites: -10,
        blacks: -20,
        vibrance: 10,
      },
    },
    {
      id: 4,
      title: "high-contrast",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 50,
        highlights: 20,
        blacks: -40,
        clarity: 30,
      },
    },
    {
      id: 6,
      title: "matte",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: -10,
        highlights: -20,
        shadows: 10,
        whites: 20,
        blacks: -25,
        saturation: -25,
      },
    },
    {
      id: 8,
      title: "cool-light",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 15,
        whites: 45,
        blacks: -5,
        temperature: -20,
        tint: 5,
        vibrance: -15,
        saturation: -20,
        clarity: 10,
      },
    },
    {
      id: 9,
      title: "red-tone",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 10,
        highlights: 10,
        whites: 45,
        blacks: -20,
        temperature: 10,
        tint: 45,
        vibrance: 10,
        saturation: 5,
        clarity: 5,
      },
    },
    {
      id: 12,
      title: "warm-contrast",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 70,
        highlights: -20,
        shadows: 15,
        whites: 20,
        blacks: -30,
        tint: 10,
        vibrance: 25,
        saturation: -15,
        clarity: -20,
      },
    },
    {
      id: 14,
      title: "red-matte",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 20,
        highlights: 40,
        whites: 50,
        blacks: -15,
        temperature: 5,
        tint: 20,
        vibrance: -40,
        saturation: 0,
        clarity: -15,
      },
    },
    {
      id: 15,
      title: "warm-glow",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 15,
        shadows: -50,
        whites: -60,
        blacks: 20,
        temperature: 15,
        tint: 15,
        vibrance: 30,
        saturation: -5,
        clarity: 10,
      },
    },
    {
      id: 18,
      title: "bw-high-contrast",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 90,
        highlights: -25,
        shadows: -60,
        whites: -10,
        blacks: -20,
        temperature: -15,
        saturation: -100,
        clarity: -20,
      },
    },
    {
      id: 20,
      title: "bw-low-contrast",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: -40,
        highlights: 15,
        shadows: -30,
        whites: 40,
        tint: 20,
        saturation: -100,
        clarity: -15,
      },
    },
    {
      id: 22,
      title: "bw-soft",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 15,
        shadows: -15,
        whites: 15,
        blacks: -60,
        temperature: -25,
        saturation: -100,
        clarity: -55,
      },
    },
    {
      id: 23,
      title: "bw-infra-tone",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 15,
        highlights: -100,
        shadows: 55,
        whites: 70,
        blacks: -40,
        temperature: 20,
        tint: 70,
        saturation: -100,
        clarity: -40,
      },
    },
    {
      id: 24,
      title: "bw-selenium",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 20,
        highlights: -25,
        shadows: -45,
        whites: -10,
        blacks: -45,
        temperature: -55,
        tint: 10,
        vibrance: -25,
        saturation: -90,
        clarity: -5,
      },
    },
    {
      id: 25,
      title: "bw-sepia",
      additive: false,
      hasIntensity: true,
      params: {
        contrast: 25,
        highlights: -50,
        shadows: -40,
        whites: -50,
        blacks: -60,
        temperature: 60,
        tint: 100,
        vibrance: -30,
        saturation: -90,
        clarity: -10,
      }
    }
  ];

  static getAdjustmentsPresets(): AdjustmentsPreset[] {
    return this._presets;
  }

  static getById(id: number): AdjustmentsPreset {
    const preset = AdjustmentsPresets.getAdjustmentsPresets().filter((adjustment) => {
      return (adjustment.id === id);
    })[0];

    if (preset) {
      Logger.log(LogLevel.DEBUG, "AdjustmentsPresets: getById: Preset found with id " + id);
      return preset;
    } else {
      Logger.log(LogLevel.WARN, "AdjustmentsPresets: getById: Preset not found with id " + id);
      throw new Error("Preset not found");
    }
  }

  static setPreviewURL(id: number, url: string): void {
    const preset = this.getById(id);
    preset.previewUrl = url;
  }
}
