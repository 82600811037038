/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import MediaGridConfigAction, { GridMediaType } from "../../../../stores/actions/mediaGridConfigActions";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import store, { RootState } from "../../../../stores/store";

//Application specific
import IWorkflow from "../../../../workspaces/IWorkflow";
import { MediaGridWorkflowActions } from "../../../../workspaces/organizer/workflows/mediaGrid/MediaGrid";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ELEmptyBannerMediaGridView, ELEmptyBannerMediaGridControllerAction } from "./ELEmptyBannerMediaGridView";

class ELEmptyBannerMediaGrid extends IViewController {

    private _workflow: IWorkflow;
    private _showAddButton: boolean;

    constructor(workflow: IWorkflow, showAddButton = true) {
        super();
        this._workflow = workflow;
        this._showAddButton = showAddButton;
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELEmptyBannerMediaGridView, {
            controller: this,
            showAddButton: this._showAddButton
        });
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        ReactDOM.render(
            React.createElement(ELEmptyBannerMediaGridView, {
                controller: this,
                showAddButton: this._showAddButton
            }),
            container
        );
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELEmptyBannerMediaGridControllerAction.startImport:
                {
                    handled = await this._workflow.notify({ type: MediaGridWorkflowActions.startImport, payload: action.payload });
                    break;
                }
            case ELEmptyBannerMediaGridControllerAction.removeFilter: {
                const config = (store.getState() as RootState).mediaConfigReducer[GRID_CONFIG_KEY];
                config.mediaType = GridMediaType.eImageAndVideo;
                store.dispatch(MediaGridConfigAction.updateConfig(config));
                handled = true;
                break;
            }
        }
        return handled;
    }


}

export { ELEmptyBannerMediaGrid };
