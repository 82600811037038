/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { UserState } from "../common/interfaces/elive/EliveTypes";
import { TrialState } from "../common/interfaces/trial/TrialTypes";
import IMS from "../services/IMS";
import { TrialUtils } from "./TrialUtils";
import { UserUtils } from "./UserUtils";

export const EliveUtils = {
    getUserState(daysInTrial: number): UserState {
        const trialState = TrialUtils.getTrialState(daysInTrial);

        if (!IMS.getInstance().isSignedInUser()) {
            return UserState.signedOut;
        } else if (UserUtils.isUserPaid()) {
            return UserState.paid;
        } else if (trialState === TrialState.inProgress) {
            return UserState.trialInProgress;
        } else if (trialState === TrialState.notStarted) {
            return UserState.trialNotStarted;
        } else {
            return UserState.trialExpired;
        }
    }
}