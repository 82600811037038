/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import _ from "lodash";

//Application Specific
import { ViewAction } from "../../../../view/IBaseController";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import IBaseWorkspace, { WorkspaceActionType } from "../../../IBaseWorkspace";
import { SingleImageView, SingleImageControllerActionType, SingleImageViewActionType, SIV_INVALID_INDEX } from "./SingleImageView";
import { ControllerAction } from "../../../../view/IViewController";
import store from "../../../../stores/store";
import { GetMediaFetchHookFunc } from "../../../../view/components/organism/el-mediagrid/ELMediaGrid";
import { ELAdobeAsset, elDeserializeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { IconType } from "../../../../assets/IconConstants";
import { ShareOptions } from "../../../../view/components/organism/el-share-options/ELShareOptions";
import { ShareOptionsActionType } from "../../../../view/components/organism/el-share-options/ELShareOptionsView";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import { ModalWorkflowWrapperViewActionType } from "../../../common/workspaces/modal/ModalWorkflowWrapperView";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";
import { history } from "../../../../utils/Utils";
import Constants, { LinkParams } from "../../../../utils/Constants/Constants";
import useMediaFetch, { MediaSource } from "../../../../utils/hooks/useMediaFetch";
import { SIVUtils } from "../../../../utils/SIVUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes, ShareInvocationPoint } from "../../../../utils/IngestConstants";
import { ShareUtils } from "../../../../utils/ShareUtils";
import { ShareAction } from "../../../../stores/actions/ShareAction";
import { ShareType } from "../../../../common/interfaces/share/ShareTypes";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import ELOpenInDesktopManager from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopManager";
import { ELOpenDeeplinkAction } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import { EditWorkflowLauncher } from "../../../edit/utils/EditWorkflowLauncher";

export interface SIVWorkflowPayload {
    asset: ELAdobeAsset,
    getMediaFetchHook: GetMediaFetchHookFunc,
    dirPath: string,
    storeKey: string,
    iconList?: Array<IconType>
}

class SingleImage extends IWorkflow {

    private _asset?: ELAdobeAsset;
    private _mediaFetchHookFunction!: GetMediaFetchHookFunc;
    private _dirPath!: string;
    private _currentIndex: number = SIV_INVALID_INDEX;
    private _storeKey!: string;
    private shareOptions: ShareOptions;
    private _iconList: Array<IconType>;
    private _hasOwnerData: boolean;
    private _needMoreData = false;
    private _openInDesktopManager: ELOpenInDesktopManager;

    private readonly _openDeeplinkContainer = "open-deeplink-container";

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.singleImageView);
        this.shareOptions = new ShareOptions(this._owner);
        this._hasOwnerData = false;
        // Default list for single image view
        this._iconList = [IconType.edit, IconType.delete, IconType.download, IconType.info, IconType.share];
        this._openInDesktopManager = new ELOpenInDesktopManager(this, WorkflowsName.singleImageView);
    }

    get getCurrentIndex(): number {
        return this._currentIndex;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._openInDesktopManager.createView(this.ensureHTMLElement(this._openDeeplinkContainer));
    }

    private _setIconListForSIV(iconList?: Array<IconType>): void {
        if (!iconList)
            return;
        this._iconList = iconList;
        return;
    }

    private _setWorkflowParams(): void {
        if (SIVUtils.isUserMedia(this._owner.getWorkspaceName)) {
            this._needMoreData = true;
            this._asset = undefined;
            this._dirPath = Constants.ELEMENTS_PHOTOS_PATH as string;
            this._hasOwnerData = true;
            const mediaSourceType = MediaSource.repoDirDataFetch;
            const repoDirFetchHook = _.partial(useMediaFetch, _, mediaSourceType);
            this._mediaFetchHookFunction = repoDirFetchHook;
        } else if (SIVUtils.isSharedMedia(this._owner.getWorkspaceName)) {
            this._needMoreData = true;
            this._asset = undefined;
            this._dirPath = (new URLSearchParams(window.location.search))?.get(LinkParams.ELEMENTS_COLLECTION_ID_PARAM) ?? "";
            this._hasOwnerData = false;
            const mediaSourceType = MediaSource.sharedMediaDirFetch;
            const sharedMediaDirFetch = _.partial(useMediaFetch, _, mediaSourceType);
            this._mediaFetchHookFunction = sharedMediaDirFetch;
            this._setIconListForSIV([IconType.download]);
        }
    }


    createView(container: HTMLElement): void {
        super.createView(container);

        const singleImageView = React.createElement(SingleImageView, {
            controller: this,
            getMediaFetchHook: this._mediaFetchHookFunction,
            dirPath: this._dirPath,
            buttons: this._iconList,
            shareOptionsController: this.shareOptions,
            hasOwnerData: this._hasOwnerData,
            needMoreData: this._needMoreData
        });

        const router = React.createElement(Router, { history }, singleImageView);
        const routerWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, router);
        const viewportReduxConsentWrappedProvider = React.createElement(ViewportProvider, {}, routerWrappedProvider);

        ReactDOM.render(viewportReduxConsentWrappedProvider,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        const payload = action?.payload as SIVWorkflowPayload;
        if (payload) {
            this._setIconListForSIV(payload.iconList);
            this._asset = payload.asset;
            this._dirPath = payload.dirPath;
            this._storeKey = payload.storeKey;
            this._mediaFetchHookFunction = payload.getMediaFetchHook;
            this._hasOwnerData = true;
            HistoryUtils.pushHistory(SIVUtils.getSIVHistoryState(this._owner.getWorkspaceName, this._asset.assetId ?? ""));
        } else { //refresh workflow
            this._setWorkflowParams();
        }
        this.createView(this.ensureHTMLElement(containerId));
    }

    private _updateSIVCurrentAsset = (): void => {
        this.viewDispatcher?.({ type: SingleImageViewActionType.setRenditionData, payload: { imgData: "" } });
        this.viewDispatcher?.({ type: SingleImageViewActionType.setCurrentIndex, payload: this._currentIndex });
    }

    private _handleRightArrowClick = (): void => {
        if (!((this._currentIndex + 1) < store.getState().mediaOrganizerReducer[this._storeKey].children.length))
            return; //no op

        this._currentIndex += 1;
        this._updateSIVCurrentAsset();
    }

    private _handleLeftArrowClick = (): void => {
        if (!((this._currentIndex - 1) >= 0))
            return; //no op

        this._currentIndex -= 1;
        this._updateSIVCurrentAsset();
    }

    private _onKeyPressed = (e: KeyboardEvent): void => {
        if (e.key.toLowerCase() === KeyboardKey.arrowRight) {
            this._handleRightArrowClick();
        }
        else if (e.key.toLowerCase() === KeyboardKey.arrowLeft) {
            this._handleLeftArrowClick();
        }
    }

    private _onOpenDeeplink = (assetId: string): void => {
        this._openInDesktopManager.notify({
            type: ELOpenDeeplinkAction.openDeepLinkForAsset,
            payload: { assetId, assetType: "photo" }
        });
    }

    private async _handleEditWorkflow(asset: ELAdobeAsset): Promise<void> {
        this.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.siv, IngestEventTypes.click,
                IngestEventSubTypes.edit, true)
        });
        const editWorkflowLauncher = new EditWorkflowLauncher();
        //GLIA_REVISIT - vib - Will need to have handling here if video/other edit workflows are introduced.
        editWorkflowLauncher.startImageEditWorkflow([asset]);
    }

    async notify<T extends ControllerAction>(action?: T): Promise<boolean> {
        let handled = false;
        switch (action?.type) {
            case SingleImageControllerActionType.exit:
                {
                    HistoryUtils.replaceHistory(SIVUtils.getParentHistoryState(this._owner.getWorkspaceName, this._dirPath));
                    const workspaceAction = { type: WorkspaceActionType.endWorkflow };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            case SingleImageControllerActionType.sharePressed:
                {
                    store.dispatch(ShareAction.updateShareType(ShareType.media));
                    ShareUtils.ingestShareStart(ShareInvocationPoint.siv, this.notify.bind(this));
                    const currentAsset = elDeserializeAsset(action.payload as ELAdobeAsset);
                    SIVUtils.ingestMediaActionStatus(IngestEventTypes.share, IngestEventSubTypes.info, this.notify.bind(this), currentAsset.format);
                    const assetList = [currentAsset];
                    handled = await this.shareOptions.notify({ type: ShareOptionsActionType.openShareWorkspace, payload: { mediaList: assetList.map((asset) => asset.assetId) } });
                    break;
                }
            case SingleImageControllerActionType.rightArrowPressed:
                {
                    this._handleRightArrowClick();
                    this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.siv,
                            IngestEventTypes.click, IngestEventSubTypes.chevron, true)
                    });
                    handled = true;
                    break;
                }
            case SingleImageControllerActionType.leftArrowPressed:
                {
                    this._handleLeftArrowClick();
                    this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.siv,
                            IngestEventTypes.click, IngestEventSubTypes.chevron, true)
                    });
                    handled = true;
                    break;
                }
            case SingleImageControllerActionType.setCurrentIndex:
                {
                    this._currentIndex = action.payload as number;
                    handled = true;
                    break;
                }
            case SingleImageControllerActionType.updateURLPath:
                {
                    HistoryUtils.pushHistory(SIVUtils.getSIVHistoryState(this._owner.getWorkspaceName, action.payload as string));
                    handled = true;
                    break;
                }
            case SingleImageControllerActionType.updateStoreKey:
                {
                    this._storeKey = action.payload as string;
                    handled = true;
                    break;
                }
            case SingleImageControllerActionType.openDeeplink:
                {
                    this._onOpenDeeplink(action.payload as string);
                    handled = true;
                    break;
                }
            case ModalWorkflowWrapperViewActionType.keyPressed:
                {
                    const key = action.payload as KeyboardEvent;
                    this._onKeyPressed(key);
                    handled = true;
                    break;
                }
            case SingleImageControllerActionType.editPressed:
                {
                    const currentAsset = elDeserializeAsset(action.payload as ELAdobeAsset);
                    this._handleEditWorkflow(currentAsset);
                    handled = true;
                    break;
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

}

export { SingleImage };
