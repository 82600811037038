/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export class ConcurrentTaskUtils {

    static runConcurrentTasks<T, V>(taskParams: T[], taskExecutor: (taskParams: T) => Promise<V>, concurrencyLimit: number): { isComplete: () => boolean, getAllConcurrentPromises: () => Promise<PromiseSettledResult<V>[]>; } {
        const pendingTasksParams = taskParams.slice();
        const activePromises: Promise<V>[] = [];

        const executeNextTask = (): void => {
            if (pendingTasksParams.length) {
                const taskParam = pendingTasksParams.shift();
                if (taskParam) {
                    const taskPromise = taskExecutor(taskParam);

                    activePromises.push(taskPromise);

                    taskPromise.finally(() => {
                        executeNextTask();
                    });
                }
            }
        };

        while (activePromises.length < concurrencyLimit && pendingTasksParams.length > 0) {
            executeNextTask();
        }

        const isComplete = (): boolean => {
            return pendingTasksParams.length === 0;
        };

        const getAllConcurrentPromises = async (): Promise<PromiseSettledResult<V>[]> => {
            return await Promise.allSettled(activePromises);
        };

        return {
            isComplete: isComplete,
            getAllConcurrentPromises: getAllConcurrentPromises
        };
    }

}