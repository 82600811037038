/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";

//Application specific
import { RootState } from "../../../../stores/store";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ControllerAction } from "../../../IViewController";
import { ELMediaGridHeading } from "../el-media-grid-toolbar/ELMediaGridToolbarView";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import ELCloseButton from "../../atoms/el-close-button/ELCloseButton";
import { MediaManagerControllerAction } from "../../../../common/interfaces/workflows/MediaManagerTypes";

import "./ELMediaManagerToolbar.scss"

interface ELMediaManagerToolBarProps {
    title: string,
    notify: (_: ControllerAction) => void
    children: React.ReactNode
}


const ELMediaManagerToolBarView = (props: ELMediaManagerToolBarProps): React.ReactElement<ELMediaManagerToolBarProps> => {
    const intlHandler = IntlHandler.getInstance();
    const selectedAssetsList = useSelector((state: RootState) => state.selectedMediaListReducer);

    return (
        <ELReactSpectrumV3Provider>
            <Flex UNSAFE_className="grid-toolbar-container" alignItems="center">
                <ELCloseButton
                    UNSAFE_className="grid-toolbar-container__close-button"
                    label={intlHandler.formatMessage("close")}
                    onClick={() => props.notify({ type: MediaManagerControllerAction.toolbarCancel })}

                />
                <Flex direction="row" width="100%" alignItems="center">
                    <div className="grid-toolbar-container__left-items">
                        <ELMediaGridHeading heading={intlHandler.formatMessage(props.title)} selectedItemCount={selectedAssetsList.length}
                            notify={props.notify} />
                    </div>
                    <Flex direction="row" gap="4%" alignItems="end">
                        {props.children}
                    </Flex>
                </Flex>
            </Flex>
        </ELReactSpectrumV3Provider>
    );
}

export default ELMediaManagerToolBarView;
