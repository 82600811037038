/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { EmbeddedMetadataType } from '../../common/interfaces/storage/StorageTypes';
import IAction from './IAction';

export interface MetadataPayload {
    assetId: string,
    embeddedMetadata: EmbeddedMetadataType
}

export default class MediaMetadataAction {

    public static readonly UPDATE_METADATA: string = 'MediaMetadataAction.UPDATE_METADATA';

    public static udpateMetadata(data: MetadataPayload): IAction<MetadataPayload> {
        return {
            type: MediaMetadataAction.UPDATE_METADATA,
            payload: data
        };
    }
}
