/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageShapeAndObjectOptions } from "../../../common/interfaces/stage/StageTypes";
import IDoc from "../IDoc";

export default abstract class IDocDataParser {
    protected doc: IDoc;

    constructor(doc: IDoc) {
        this.doc = doc;
    }

    getStageData<T>(data: T): Promise<ELStageShapeAndObjectOptions> {
        throw new Error("Method not implemented!");
    }

    abstract parseDocData(): Promise<ELStageShapeAndObjectOptions[]>;
    abstract parseDocUpdatedData(data: unknown): unknown;

    parseDocUpdatedClipData(data: unknown): unknown {
        throw new Error("Method not implemented!");
    }
}
