/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

interface InvitationVideoRenditionInfo {
    url: string,
    videoContainer: string
}

interface PosterFrameInfo {
    url: string,
    width: number,
    height: number
}

export interface DimensionsInfo {
    width: number,
    height: number
}

export interface VideoMetaData {
    dimensionsInfo?: DimensionsInfo
}

interface RenditionStatus {
    state: InvitationRenditionState,
    progressValue?: string
}

export enum InvitationRenditionState {
    processing = "PROCESSING",
    queued = "QUEUED",
    completed = "COMPLETED",
    failed = "FAILED"
}


export interface InvitationVideoResponseType {
    posterframe: PosterFrameInfo,
    originalDimensions: DimensionsInfo,
    renditions: Array<InvitationVideoRenditionInfo>,
    renditionsStatus: RenditionStatus
}