/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import Axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken,
  CancelTokenSource
} from "axios";
import AxiosRetry from 'axios-retry';
import { ServiceUtils } from "../utils/ServiceUtils";
import Constants from "../utils/Constants/Constants";
import Logger, { LogLevel } from "./../utils/Logger";

const REQUEST_TIMEOUT = 10 * 1000;

export interface ApiClientConfig {
  needResponseMonitoring: boolean,
  monitorSuccessStatus: number,
  monitorInProgressStatus: number
}

export interface AxiosRetryConfig {
  retries?: number,
  retryCondition?: (error: unknown) => boolean,
  shouldResetTimeout?: boolean,
  retryDelay?: (retryCount: unknown, error: unknown) => number,
  onRetry?: (retryCount: unknown, error: unknown, requestConfig: unknown) => void
}


export function isAxiosError(value: unknown): value is AxiosError {
  return typeof (value as any)?.response === "object";
}

export class RequestCancelHandler {
  private _cancelTokenSource?: CancelTokenSource;

  get getCancelToken(): CancelToken {
    this._cancelTokenSource = Axios.CancelToken.source();
    return this._cancelTokenSource.token;
  }

  cancelRequest(msg?: string): void {
    //TODO - https://git.corp.adobe.com/Elements/elements-web-ui/pull/49, check if user cancel the request even before request was generated
    this._cancelTokenSource?.cancel(msg);
  }
}

export class API {
  private _client: AxiosInstance;
  private _config: ApiClientConfig;

  private _createAxiosClient(
    url: string
  ): AxiosInstance {

    return Axios.create({
      baseURL: url,
      timeout: REQUEST_TIMEOUT,
    });
  }

  constructor(baseURL: string, config?: ApiClientConfig, axiosRetryConfig?: AxiosRetryConfig) {
    this._client = this._createAxiosClient(baseURL);
    this._config = config ? config : this._getDefaultApiClientConfig();
    AxiosRetry(this._client,
      axiosRetryConfig ? axiosRetryConfig : this._getDefaultAxiosRetryConfig());
  }

  private _getDefaultApiClientConfig(): ApiClientConfig {
    return {
      needResponseMonitoring: false,
      monitorSuccessStatus: Constants.HTTP_RESPONSE_OK_200 as number,
      monitorInProgressStatus: Constants.HTTP_RESPONSE_OK_202 as number,
    }
  }

  private _getDefaultAxiosRetryConfig(): AxiosRetryConfig {
    return {
      onRetry: (retryCount: unknown, error: unknown, requestConfig: unknown) => {
        Logger.log(LogLevel.WARN, "Retrying times : ", retryCount, " for error  ", error);
        return;
      }
    }
  }
  private _handleError(error: unknown): Promise<Error> {
    if (error instanceof Error) {
      if (isAxiosError(error)) {
        if (error.response) {
          Logger.log(LogLevel.WARN, error.response.data);
          Logger.log(LogLevel.WARN, error.response.status);
          Logger.log(LogLevel.WARN, error.response.headers);
          return Promise.reject(error);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          Logger.log(LogLevel.WARN, error.request);
          return Promise.reject(new Error(error as any));
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        Logger.log(LogLevel.WARN, error.message);
        return Promise.reject(new Error(error.message));
      }
    } else if (Axios.isCancel(error)) {
      Logger.log(LogLevel.WARN, error);
      if (error instanceof Object && "message" in error)
        return Promise.reject(new Error((error as any).message));
      else
        return Promise.reject(new Error(error as any));
    }
    return Promise.reject(new Error(error as any));
  }

  private _handleResponse(response: AxiosResponse): Promise<any> {
    switch (response.status) {
      case Constants.HTTP_RESPONSE_ACCEPTED_202:
         if (this._config.needResponseMonitoring && response.headers.location)
           return ServiceUtils.monitorResponse(response.headers.location, 0, this._config.monitorSuccessStatus, this._config.monitorInProgressStatus);
         break;
      default:
        return Promise.resolve(response);
    }
    return Promise.resolve(response);
  }

  get(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return this._client.get(endpoint, config)
      .then(this._handleResponse.bind(this))
      .catch(this._handleError.bind(this));
  }

  post(data: unknown, config?: AxiosRequestConfig, endpoint = ""): Promise<AxiosResponse> {
    return this._client.post(endpoint, data, config)
      .then(this._handleResponse.bind(this))
      .catch(this._handleError.bind(this));
  }

  put(endpoint: string, data: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return this._client.put(endpoint, data, config)
      .then(this._handleResponse.bind(this))
      .catch(this._handleError.bind(this));
  }

  delete(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return this._client.delete(endpoint, config)
      .then(this._handleResponse.bind(this))
      .catch(this._handleError.bind(this));
  }

  patch(data: unknown, config?: AxiosRequestConfig, endpoint = ""): Promise<AxiosResponse> {
    return this._client.patch(endpoint, data, config)
      .then(this._handleResponse.bind(this))
      .catch(this._handleError.bind(this));
  }
}