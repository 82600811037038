/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third-party
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType } from "../../workspaces/IWorkflow";
import { ControllerAction } from "../../view/IViewController";
import Logger, { LogLevel } from "../../utils/Logger";
import { CSAssetWithData, CreationWorkflowActions, CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import { ELSize } from "../../common/interfaces/geometry/ELGeometry";
import { CanvasZoomLevelAction, ELDownloadData } from "../../common/interfaces/stage/StageTypes";
import CollageUtils from "../../workspaces/creations/workflows/collage/utils/CollageUtils";
import { RendererUpdateData } from "../../common/interfaces/renderer/RendererTypes";
import { ELAdobeAssetControllerAction, ELAdobeAssetDocActions } from "../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import ELAdobeAssetDataResolver, { DocumentDataType } from "../document/dataResolver/ELAdobeAssetDataResolver";
import FullResMediaAction from "../../stores/actions/FullResMediaAction";
import store from "../../stores/store";
import { StorageService } from "../../services/StorageServiceWrapper";
import { AssetStorageUtils } from "../../utils/AssetStorageUtils";
import { DocumentActions } from "../../common/interfaces/document/DocumentTypes";
import { IngestUtils } from "../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../utils/IngestConstants";
import Utils from "../../utils/Utils";
import DocActions from "../../stores/actions/DocActions";
import { ELVideoDocView } from "./ELVideoDocView";
import { ELMovingOverlayControllerActions } from "../../common/interfaces/creations/ELMovingOverlayTypes";
import IDoc from "../document/IDoc";
import { ELVideoDocActions, ELVideoDocViewActions } from "../../common/interfaces/document/ELVideoDocTypes";
import { ELAdobeAssetDocPayload } from "../adobeAsset/ELAdobeAssetDoc";


export enum ELAdobeAssetViewType {
    before = "BEFORE",
    after = "AFTER"
}

export default class ELVideoDoc extends IDoc {
    private _assetInfo: CSAssetWithData;

    constructor(owner: IWorkflow, payload: ELAdobeAssetDocPayload) {
        super(owner);
        this._showRenderError(true);
        this._assetInfo = payload.assetInfo;
    }

    private _setAssetInfo(payload: ELAdobeAssetDocPayload): void {
        this._assetInfo = payload.assetInfo;
    }

    private _showRenderError(renderError: boolean): void {
        if (this.renderer) {
            this.renderer.setRenderError = renderError;
        }
    }

    private async _renderUpdatedAsset(): Promise<void> {
        try {
            const updateData: RendererUpdateData = {
                type: ELAdobeAssetDocActions.assetUpdated,
                payload: this._assetInfo
            };
            await this.renderer?.update(updateData);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject();
        }
    }

    private _setAssetData(objectURL: string): void {
        this._assetInfo = {
            ...this._assetInfo,
            objectURL: objectURL
        };
    }

    private async _getOriginalAsset(): Promise<void> {
        const assetId = this._assetInfo.assetURN;
        let objectURL: string | undefined = CollageUtils.getAssetFullResObjectURL(assetId ?? "");

        if (!objectURL) {
            try {
                const dataResolver = new ELAdobeAssetDataResolver();
                const asset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, 'id');
                const getDataType = AssetStorageUtils.shouldUseAssetRendition(asset) ? DocumentDataType.rendition : DocumentDataType.fullRes;
                objectURL = await dataResolver.getData(asset, getDataType);
                store.dispatch(FullResMediaAction.updateData({ assetId: assetId, objectURL: objectURL }));
            } catch (error) {
                Logger.log(LogLevel.WARN, "ELVideoDoc::_getOriginalAsset", error);
                return Promise.reject();
            }
        }

        this._setAssetData(objectURL);
        return Promise.resolve();
    }

    private async _renderOriginalAsset(): Promise<void> {
        try {
            await this._getOriginalAsset();
            await this._renderUpdatedAsset();
        } catch (error) {
            this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.error });
        }
    }

    async getOriginalSize(): Promise<ELSize> {
        return { width: 1000, height: 1000 };
    }

    private _renderImage(image: ELAdobeAssetDocPayload): void {
        this._setAssetInfo(image);
        this._renderOriginalAsset();

        this.viewDispatcher?.call(this.viewDispatcher, { type: ELVideoDocViewActions.videoDocViewData, payload: image });
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const element = React.createElement(ELVideoDocView, { controller: this, });
        const provider = React.createElement(Provider, { store }, element);
        ReactDOM.render(
            provider,
            container
        );
    }

    async render(container: HTMLElement): Promise<void> {
        try {
            await this._getOriginalAsset();
            this.createView(container);
        } catch (error) {
            this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.error });
        }
    }

    private _ingest(payload: Record<string, string>): void {
        this.owner.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case DocumentActions.download: {
                const downloadData = action.payload as ELDownloadData;
                this.download(downloadData);
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.renderImage:
                this._renderImage(action.payload as ELAdobeAssetDocPayload);
                handled = true;
                break;
            case ELMovingOverlayControllerActions.updateEditViewData: {
                const adobeAssetDocPayload = action.payload as ELAdobeAssetDocPayload;
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELVideoDocViewActions.videoDocViewData, payload: adobeAssetDocPayload });
                handled = true;
                break;
            }

            case ELVideoDocActions.pauseVideo: {
                const videoElement = document.querySelector('[data-testid="el-video-player"]') as HTMLVideoElement | null;
                if (videoElement && (videoElement instanceof HTMLVideoElement)) {
                    videoElement.pause();
                }
                handled = true;
                break;
            }

            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent:
            case CanvasZoomLevelAction.changeZoomValue:
            case CanvasZoomLevelAction.zoomToFill:
            case CanvasZoomLevelAction.zoomToFit:
                handled = this.renderer ? await this.renderer.notify(action) : false;
                break;
            case DocumentActions.zoomModified: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, Utils.getPercentageFromNumber(action.payload as number)));
                store.dispatch(DocActions.updateZoomLevel(action.payload as number));
                handled = true;
                break;
            }
            default:
                Logger.log(LogLevel.INFO, "ELAdobeAssetDoc::notify::default case");
        }

        if (!handled)
            handled = await this.owner.notify(action as WorkflowAction);

        return handled;
    }

    async getSize(): Promise<ELSize> {
        return { width: 0, height: 0 };
    }

    close(): Promise<void> {
        return Promise.resolve();
    }

    destroy(): void {
        super.destroy();
        this.close();
        this.renderer?.destroy();
    }

    save(): void {
        throw new Error("Method not implemented.");
    }

    documentUpdated(payload: unknown): void {
        throw new Error("Method not implemented.");
    }

    download(downloadData: ELDownloadData): void {
        this.downloader?.download(downloadData);
    }

    getData(): unknown {
        const payload: ELAdobeAssetDocPayload = {
            assetInfo: this._assetInfo
        }
        return payload;
    }
}