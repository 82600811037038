/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "./IAction";

export type LayoutActionPayload = string | number;

export default class LayoutAction {
    public static readonly UPDATE_SELECTED_LAYOUT: string = "LayoutAction.UPDATE_SELECTED_LAYOUT";
    public static readonly UPDATE_SCALE: string = "LayoutAction.UPDATE_SCALE";

    public static updateSelectedLayout(selectedLayout: string): IAction<string> {
        return {
            type: LayoutAction.UPDATE_SELECTED_LAYOUT,
            payload: selectedLayout
        };
    }

    public static updateScale(scale: number): IAction<number> {
        return {
            type: LayoutAction.UPDATE_SCALE,
            payload: scale
        }
    }
}