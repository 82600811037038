/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode } from "react";
import { Flex, ProgressCircle } from "@adobe/react-spectrum";

//Application Specific
import "./ELProgressContent.scss";

export interface ELProgressContentProps {
    contentHeader: string,
    progressText: string,
    progressPercentNode?: ReactNode,
    children?: ReactNode
}

const ELProgressContent = (props: ELProgressContentProps): React.ReactElement => {
    return (
        <Flex gap="size-50" direction="column" alignItems="center" zIndex={1}>
            <ProgressCircle
                size="L"
                aria-label="Loading…"
                minValue={50}
                maxValue={150}
                value={0}
                marginBottom={0}
                isIndeterminate
            />
            {props.progressPercentNode ?? <></>}
            <span data-testid="progress-content" className="progress-text"> {props.contentHeader} </span>
            <span data-testid="progress-text" className="progress-text"> {props.progressText} </span>
            {props.children}
        </Flex>
    )
}

export default ELProgressContent;