/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ELQRShareAction, ELQRSharePayload } from "../../common/interfaces/share/ShareTypes";
import { ElementsShareServiceWrapper } from "../../services/ElementsShareServiceWrapper";
import IMediaShareLinkCreator, { LinkKeys } from "./IMediaShareLinkCreator";

export class ELMultiMediaLinkCreator extends IMediaShareLinkCreator {
    async getParams(payload: ELQRSharePayload): Promise<[LinkKeys, string][]> {
        try {
            const collectionId = await ElementsShareServiceWrapper.getInstance().generateCollectionId(payload.assetsToShare);
            const searchParams = this.getCommonParams();
            searchParams.push([LinkKeys.CATEGORY, payload.category]);
            searchParams.push([LinkKeys.SOURCE, payload.source]);
            searchParams.push([LinkKeys.ACTION, ELQRShareAction.MULTISHARE]);
            searchParams.push([LinkKeys.COLLECTIONID, collectionId]);
            return searchParams;
        } catch (error) {
            return Promise.reject(error);
        }
    }
}