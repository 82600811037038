/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ImportStore, IProgressCallbackArg, IImportFileData, IFileProgressInfo } from "@ccxi/ccac-utilities";
import { PageOptions, AdobeDirectoryData, deserializeAsset, DirectoryMediaType } from "@dcx/assets";
import { AssetWithRepoAndPathOrId, AdobeAsset } from "@dcx/common-types";
import { AdobeDCXError } from "@dcx/common-types";
import { DCXError } from '@dcx/error';

import IMS from "../../services/IMS";
import { RepoDirListData } from "@elements/elementswebcommon";
import { StorageService } from "../../services/StorageServiceWrapper";
import { StorageQuota } from "../storageQuota/StorageQuota";

import Constants from "../../utils/Constants/Constants";
import Logger, { LogLevel } from "../../utils/Logger";
import Utils from "../../utils/Utils";
import { FILE_TYPE_FILTER } from "../../../src/common/interfaces/storage/FileTypes";
import { IngestEventSubTypes, IngestWorkflowTypes } from "../../utils/IngestConstants";
import store from "../../stores/store";
import UploadedMediaListAction from "../../stores/actions/uploadedMediaListActions";
import { GRID_CONFIG_KEY } from "../../stores/reducers/mediaGridConfigReducer";
import { MetaDataUtils } from "../../utils/MetaDataUtils";
import { FileUtils } from "../../utils/FileUtils";

export enum UploadStatus {
    success = "success",
    waiting = "waiting",
    skipped = "skipped",
    failed = "failed",
}

export enum UploadErrorCode {
    noError = "noError",
    storageFull = "storageFull",
    serverError = "serverError",
    noInternet = "noInternet",
    noSupportedFile = "noSupportedFile",
    uploadAborted = "uploadAborted",
    unknowError = "unknownError"
}

export interface FileInfo {
    file: File,
    uploadID: string, // used by import store
    cloudFileName?: string,
    assetCloudId?: string,
    error?: UploadErrorCode,
    status: UploadStatus,
}

export interface BatchUploadState {
    successCnt: number,
    failedCnt: number,
    totalCnt: number
}

export interface BatchUploadCompleteInfo {
    fileInfo: FileInfo[],
    uploadState: BatchUploadState,
    error?: UploadErrorCode
}


export type FileInfoArg = FileInfo;
export type BatchUploadCompleteInfoArg = BatchUploadCompleteInfo;

export interface UploadInfoArg {
    files: File[],
    progressCallback?: (fileData: FileInfoArg, batchData: BatchUploadState) => void,
    batchUploadCompleteCallback?: (data: BatchUploadCompleteInfoArg) => void,
    ingestCallback: (workflow: string, eventSubType: string, eventValue: string) => void
}

const WAITING = "waiting";
const SUCCESS = "done";
const FAILED = "failed";

export const MAX_DIR_FETCH_LIMIT = 70;
export const REPO_API_ORDER_BY_NAME = "repo:name";

export function isAdobeDCXError(value: unknown): value is AdobeDCXError {
    return typeof (value as any)?.response === "object";
}

export class UploadHandler {

    private _importStore: ImportStore;
    private _uploadedFiles: Array<FileInfo> = [];
    private _failedFiles: Array<FileInfo> = [];
    private _waiting: Array<FileInfo> = [];
    private _skippedFiles: Array<FileInfo> = [];
    private _initFileList: Array<File> = [];
    private _uploadInfo: UploadInfoArg;

    constructor() {
        const defaultUploadInfo: UploadInfoArg = {
            files: [],
            progressCallback: (data) => Logger.log(LogLevel.DEBUG, data),
            batchUploadCompleteCallback: (data) => Logger.log(LogLevel.DEBUG, data),
            ingestCallback: (_: string, __: string) => false
        }

        this._uploadInfo = defaultUploadInfo;
        this._waiting = [];
        this._uploadedFiles = [];
        this._failedFiles = [];
        this._skippedFiles = [];
        this._importStore = new ImportStore();
        if (!this._importStore) {
            Logger.log(LogLevel.ERROR, "UploadHandler:constructor: ", "Unable to init ImportStore");
            return;
        }
    }

    async stopUpload(): Promise<void> {
        if (this._waiting.length === 0) {
            return Promise.reject();
        }
        this._waiting.forEach((file: FileInfo) => {
            this._importStore.cancelOperationOnFile(file.uploadID);
        });
        Promise.resolve();
    }

    async retryUpload(): Promise<File[]> {
        const nonUploadedFiles = Array<File>();
        this._failedFiles = [];
        this._skippedFiles = [];
        for (let i = 0; i < this._initFileList.length; i++) {
            let fileUploaded = false;
            for (let j = 0; (j < this._uploadedFiles.length) && (!fileUploaded); j++) {
                if (this._uploadedFiles[j].file.name === this._initFileList[i].name) {
                    fileUploaded = true;
                }
            }
            if (!fileUploaded) {
                nonUploadedFiles.push(this._initFileList[i]);
            }
        }
        this._uploadInfo.files = nonUploadedFiles;
        return this.uploadFiles(this._uploadInfo);
    }

    private _getErrorCode(data: IFileProgressInfo): UploadErrorCode {
        const dataErr = data.error as Error;
        if (!Utils.checkNetworkAccess()) {
            return UploadErrorCode.noInternet;
        }
        if (dataErr.message.includes("AbortError")) {
            return UploadErrorCode.uploadAborted;
        }
        return UploadErrorCode.unknowError;
    }

    private _getBatchUploadCompleteError(fileInfo: IFileProgressInfo[]): UploadErrorCode {
        let failCnt = 0;

        fileInfo.forEach((file) => {
            if (file.status === FAILED) {
                failCnt += 1;
            }
        });

        if (failCnt === 0)
            return UploadErrorCode.noError;

        const isUploadAborted = this._failedFiles.some((file) => {
            return (file.error === UploadErrorCode.uploadAborted);
        });
        if (isUploadAborted) {
            return UploadErrorCode.uploadAborted;
        }

        const isNoInternetError = this._failedFiles.some((file) => {
            return (file.error === UploadErrorCode.noInternet);
        });
        if (isNoInternetError) {
            return UploadErrorCode.noInternet;
        }

        let isPartialUploadError = false;
        let encounteredFailedUpload = false;
        for (let i = 0; i < fileInfo.length; i++) {
            if (fileInfo[i].status === FAILED) {
                encounteredFailedUpload = true;
            }
            if (encounteredFailedUpload) {
                if (fileInfo[i].status === SUCCESS) {
                    isPartialUploadError = true;
                    break;
                }
            }
        }
        if (isPartialUploadError) {
            return UploadErrorCode.unknowError;
        } else {
            return UploadErrorCode.serverError;
        }
    }

    private _shouldSendBatchCompleteNotif(errorCode: UploadErrorCode): boolean {
        switch (errorCode) {
            case UploadErrorCode.noInternet:
            case UploadErrorCode.uploadAborted:
                return false;
            default: {
                return true;
            }
        }
    }

    private _progressDataReducer(callbackData: IProgressCallbackArg): void {
        Logger.log(LogLevel.DEBUG, "[UploadHandler progressDataReducer]", callbackData);
        if (callbackData.batch === true) { // upload is complete
            if (this._uploadInfo?.batchUploadCompleteCallback) {
                const uploadState: BatchUploadState = {
                    successCnt: this._uploadedFiles.length,
                    failedCnt: this._failedFiles.length,
                    totalCnt: this._initFileList.length
                };
                const data: BatchUploadCompleteInfo = {
                    fileInfo: [...this._uploadedFiles, ...this._failedFiles, ...this._skippedFiles],
                    uploadState: uploadState,
                    error: this._getBatchUploadCompleteError(callbackData.filesInfo)
                };
                if (this._shouldSendBatchCompleteNotif(data.error ?? UploadErrorCode.noError)) {
                    this._uploadInfo.batchUploadCompleteCallback(data);
                }
            }
        } else {
            callbackData.filesInfo.forEach((data) => {
                let fileData = data.file;
                if (data.fileData) {
                    fileData = data.fileData.file as File;
                }

                const fileInfo: FileInfo = {
                    file: fileData,
                    uploadID: data.id,
                    status: UploadStatus.waiting
                };
                switch (data.status) {
                    case WAITING: {
                        this._waiting.push(fileInfo);
                        break;
                    }
                    case FAILED: {
                        fileInfo.status = UploadStatus.failed;
                        this._waiting = this._waiting.filter((data) => {
                            return data.uploadID !== fileInfo.uploadID;
                        });
                        fileInfo.error = this._getErrorCode(data);
                        this._failedFiles.push(fileInfo);
                        break;
                    }
                    case SUCCESS: {
                        fileInfo.status = UploadStatus.success;
                        fileInfo.cloudFileName = data.cloudFileName;
                        fileInfo.assetCloudId = data.cloudUrn;
                        this._waiting = this._waiting.filter((data) => {
                            return data.uploadID !== fileInfo.uploadID;
                        });
                        this._uploadedFiles.push(fileInfo);
                        break;
                    }

                }

                if (this._uploadInfo.progressCallback) {
                    const uploadState: BatchUploadState = {
                        totalCnt: this._initFileList.length,
                        successCnt: this._uploadedFiles.length,
                        failedCnt: this._failedFiles.length
                    };
                    this._uploadInfo.progressCallback(fileInfo, uploadState);
                }
            });
        }
    }

    // given a file name and list of used names, returns a non-conflicting name
    // eg. ["file Copy(1)", "file Copy(2)"] -> file Copy(3)
    private _suggestName(fileName: string, ext: string, list: string[]): string {
        list.sort();
        let suggestedName = fileName;
        let cnt = 1;
        do {
            suggestedName = fileName + " " + cnt + "." + ext;
            cnt += 1;
        } while ((list.includes(suggestedName)))
        return suggestedName;
    }

    // similar to what we are doing in desktop, if some issue is found in this logic
    // make a similar change in desktop too. -- yaverma
    private async _fetchSuitableName(name: string, ext: string): Promise<string> {
        const assetPath = (Constants.ELEMENTS_PHOTOS_PATH as string) + "/" + name + "." + ext;
        const asset = await StorageService.getInstance().resolveAsset({ path: assetPath, repositoryId: "" }, 'id').catch(() => {
            return undefined;
        });
        if (asset === undefined) {
            const fileName = name + "." + ext;
            if (store.getState().mediaConfigReducer[GRID_CONFIG_KEY].selectUploadedMedia)
                store.dispatch(UploadedMediaListAction.appendMedia(fileName));
            return Promise.resolve(fileName);
        }
        const pageOpts: PageOptions = {
            start: name + " ",
            orderBy: REPO_API_ORDER_BY_NAME,
            limit: MAX_DIR_FETCH_LIMIT
        }
        const dir = await this.getOrCreateElementsPhotosDirAsset() as AdobeDirectoryData;
        const dirList = await StorageService.getInstance().getDirectoryList(dir, pageOpts) as RepoDirListData;
        let fileNames: string[] = [];
        if (dirList.children) {
            fileNames = dirList.children.map((file, indx) => {
                const asset = deserializeAsset(file);
                return asset.name ?? name;
            });
        }
        const fileName = this._suggestName(name, ext, fileNames)
        if (store.getState().mediaConfigReducer[GRID_CONFIG_KEY].selectUploadedMedia)
            store.dispatch(UploadedMediaListAction.appendMedia(fileName));
        return Promise.resolve(fileName);
    }

    async getOrCreateElementsPhotosDirAsset(): Promise<AdobeAsset> {
        const elementsPhotosDirPath = Constants.ELEMENTS_PHOTOS_PATH as string;
        const asset: AssetWithRepoAndPathOrId = { path: elementsPhotosDirPath, repositoryId: "" };
        let isElementsFolderPresent = true;
        try {
            const dir = await StorageService.getInstance().resolveAsset(asset, 'id') as AdobeAsset;
            return Promise.resolve(dir);
        } catch (error) {
            // in case the folder is not present / new user, we will create the folder
            if (isAdobeDCXError(error) && (error.code === DCXError.NOT_FOUND)) {
                Logger.log(LogLevel.INFO, "UploadHandler:getOrCreateElementsPhotosDirAsset: ", "Elements Photos Folder Not Present");
                isElementsFolderPresent = false;
            }
            else {
                Logger.log(LogLevel.ERROR, "UploadHandler:getOrCreateElementsPhotosDirAsset: ", "Unknown error finding Elements Photos Folder path");
                return Promise.reject(error);
            }
        }
        if (!isElementsFolderPresent) {
            try {
                const elementsPhotosRelPath = Constants.CLOUD_CONTENT_FOLDER as string + Constants.DIR_SEPERATOR as string + Constants.ELEMENTS_PHOTOS_FOLDER as string;
                const asset = await StorageService.getInstance().createAssetRelativeToRoot(elementsPhotosRelPath, true, DirectoryMediaType);
                return Promise.resolve(asset);
            } catch (error) {
                Logger.log(LogLevel.ERROR, "UploadHandler:getOrCreateElementsPhotosDirAsset: ", "Failed in Creating Elements Photos Folder path");
                return Promise.reject(error);
            }
        }
        //Shouldn't reach here
        return Promise.reject();
    }

    private async _checkForAvailableStorageQuota(files: File[]): Promise<boolean> {
        const totalSize = files.reduce((totalSize, file2) => { return totalSize + file2.size }, 0);
        const quota = await StorageQuota.getInstance().getQuota(true);
        if (totalSize > quota.available) {
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    /**
     * * * Specifically for ingesting imported media
     * @param  {Map<string,number>} ingestFormatMediaCount
     * @returns Promise
     */
    private async _ingestImportFileData(ingestFormatMediaCount: Map<string, number>): Promise<void> {
        for (const key of ingestFormatMediaCount.keys()) {
            this._uploadInfo.ingestCallback(`${IngestWorkflowTypes.import} ${key}`, IngestEventSubTypes.count,
                `${ingestFormatMediaCount.get(key)}`);
        }
        this._uploadInfo.ingestCallback(IngestWorkflowTypes.importInvalidFormat, IngestEventSubTypes.count,
            `${this._skippedFiles.length}`);
    }

    private _filterUnsupportedFiles(fileList: File[]): File[] {
        const formatMediaCount = new Map<string, number>();
        const filteredFileList = fileList.filter((file) => {

            const fileType = file.type;
            const fileName = file.name;

            if (formatMediaCount.has(fileType)) {
                const count = formatMediaCount.get(fileType) as number;
                formatMediaCount.set(fileType, count + 1);
            } else {
                formatMediaCount.set(fileType, 1);
            }
            if (!FileUtils.isUnsupportedFileExtensions(fileName)) {
                if (FILE_TYPE_FILTER.includes(fileType)) {
                    return true;
                }
                if (FileUtils.additionalSupportedFiles(fileName)) {
                    return true;
                }
            }
            const fileInfo: FileInfo = {
                file: file,
                uploadID: "",
                status: UploadStatus.skipped
            };
            this._skippedFiles.push(fileInfo);
            return false;
        });
        this._ingestImportFileData(formatMediaCount);

        return filteredFileList;
    }

    private _handlePreUploadErrors(errorCode: UploadErrorCode, uploadInfo: UploadInfoArg): void {
        const fileInfoList = uploadInfo.files.map((file) => {
            const fileInfo: FileInfo = {
                uploadID: "",
                file: file,
                status: UploadStatus.failed
            };
            return fileInfo;
        });
        const uploadState: BatchUploadState = {
            totalCnt: this._initFileList.length,
            successCnt: this._uploadedFiles.length,
            failedCnt: this._initFileList.length - this._uploadedFiles.length
        }
        const info: BatchUploadCompleteInfo = {
            fileInfo: fileInfoList,
            uploadState: uploadState,
            error: errorCode
        }
        if (uploadInfo.batchUploadCompleteCallback) {
            uploadInfo.batchUploadCompleteCallback(info);
        }
    }

    async uploadFiles(uploadInfo: UploadInfoArg): Promise<File[]> {

        try {
            const hasInternetAccess = await Utils.checkInternetAccess();

            this._uploadInfo = uploadInfo;
            let fileList = this._uploadInfo.files;
            if (this._initFileList.length === 0) {
                this._initFileList = fileList;
            }

            if (!hasInternetAccess) {
                Logger.log(LogLevel.WARN, "UploadHandler:uploadFiles: ", "Unable to upload files, internet offline");
                this._handlePreUploadErrors(UploadErrorCode.noInternet, uploadInfo);
                return Promise.reject();
            }

            const isStorageAvailable = await this._checkForAvailableStorageQuota(uploadInfo.files);

            if (!isStorageAvailable) {
                Logger.log(LogLevel.WARN, "UploadHandler:uploadFiles: ", "Unable to upload files, storage unavailable");
                this._handlePreUploadErrors(UploadErrorCode.storageFull, uploadInfo);
                return Promise.reject();
            }

            fileList = this._filterUnsupportedFiles(fileList);
            if (fileList.length === 0) {
                this._handlePreUploadErrors(UploadErrorCode.noSupportedFile, uploadInfo);
                return Promise.reject();
            }
            const env = process.env.REACT_APP_ENV ?? "";
            const clientID = process.env.REACT_APP_REPO_API_KEY ?? "";
            if (env === "" || clientID === "") {
                Logger.log(LogLevel.DEBUG, "Error uploading file", env, clientID);
                this._handlePreUploadErrors(UploadErrorCode.unknowError, uploadInfo);
                return Promise.reject();
            }
            this._importStore.init({
                environment: env,
                clientId: clientID,
                getAccessToken: () => Promise.resolve(IMS.getInstance().getUserAccessToken())
            });

            const getZeroPaddedString = (inStr: string, finalLen: number): string => {
                let zeroPaddedStr = inStr;
                while (zeroPaddedStr.length < finalLen) {
                    zeroPaddedStr = "0" + zeroPaddedStr;
                }
                return zeroPaddedStr;
            }

            const getUTCDateStr = async (file: File): Promise<string> => {
                let date: Date;
                try {
                    const captureDate = await MetaDataUtils.getInstance().getCaptureDateForFile(file);
                    date = new Date(captureDate);
                } catch (error) {
                    Logger.log(LogLevel.WARN, "UploadHandler:getUTCDateStr: ", "Couldn't get capture date for uploaded media", error);
                    date = new Date();
                }

                return getZeroPaddedString(date.getUTCFullYear().toString(), 4) + "-" +
                    getZeroPaddedString((date.getUTCMonth() + 1).toString(), 2) + "-" +
                    getZeroPaddedString(date.getUTCDate().toString(), 2) + "T" +
                    getZeroPaddedString(date.getUTCHours().toString(), 2) + ":" +
                    getZeroPaddedString(date.getUTCMinutes().toString(), 2) + ":" +
                    getZeroPaddedString(date.getUTCSeconds().toString(), 2) + "." +
                    getZeroPaddedString(date.getUTCMilliseconds().toString(), 2) + "Z";
            }

            const formattedImportFile = async (item: File): Promise<IImportFileData> => ({ data: item, storageDeviceCreateDate: await getUTCDateStr(item) });
            const formattedImportFiles = async (items: File[]): Promise<IImportFileData[]> => {
                const formattedFile: IImportFileData[] = [];
                for (const item of items) {
                    formattedFile.push(await formattedImportFile(item));
                }
                return formattedFile;
            }

            const dir = await this.getOrCreateElementsPhotosDirAsset();
            const assetID = dir.assetId;

            const data = await this._importStore.uploadFiles({
                files: await formattedImportFiles(fileList),
                urn: assetID,
                fetchSuitableName: this._fetchSuitableName.bind(this),
                logAnalytics: (data) => { Logger.log(LogLevel.DEBUG, "analytics", data) } // add analytics later
            },
                {
                    progressCallback: this._progressDataReducer.bind(this)
                }
            );

            // not required, remove later -- yaverma
            data.onComplete.catch(() => {
                Logger.log(LogLevel.WARN, "in data.onComplete.catch");
            });

            // resolves with the list of files we will try to upload
            return Promise.resolve(fileList);
        } catch {
            Logger.log(LogLevel.INFO, "Server error");
            this._handlePreUploadErrors(UploadErrorCode.serverError, uploadInfo);
            return Promise.reject();
        }
    }
}



export default UploadHandler;
