/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Adobe Internal
import { Flex, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";
import { Switch } from "@adobe/react-spectrum";

//Application Specific
import { IconType } from "../../../../assets/IconConstants";
import { RootState } from "../../../../stores/store";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { CreationsVideoExportPreset } from "../../../../common/interfaces/creations/CreationTypes";
import CreationsAction from "../../../../stores/actions/CreationsAction";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { ControllerAction } from "../../../IViewController";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";

import "./ELSlideshowSettings.scss";

export const ELSlideshowSettingsPopover = (props: { notify: (param: ControllerAction) => void }): React.ReactElement => {
    const [isHighQuality, setIsHighQuality] = useState(true);
    const intlHandler = IntlHandler.getInstance();
    const dispatch = useDispatch();
    const slideshowStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);

    useEffect(() => {
        if (slideshowStoreData.activeSlideshowId) {
            const slideshowId = slideshowStoreData.activeSlideshowId;
            const exportPreset = (slideshowStoreData.data[slideshowId].exportPreset as CreationsVideoExportPreset);
            if (exportPreset) {
                setIsHighQuality(exportPreset === CreationsVideoExportPreset.EPR720);
            }
        }
    }, [slideshowStoreData.data]);

    const updateStoreData = (highQuality: boolean): void => {
        const slideshowId = slideshowStoreData.activeSlideshowId;
        if (slideshowId) {
            const exportPreset = _.cloneDeep(slideshowStoreData.data[slideshowId].exportPreset);
            if (exportPreset) {
                dispatch(CreationsAction.updateVideoExportSettings(
                    { projectId: slideshowId, exportPreset: highQuality ? CreationsVideoExportPreset.EPR720 : CreationsVideoExportPreset.EPR360 }
                ));
            } else {
                dispatch(CreationsAction.updateVideoExportSettings(
                    { projectId: slideshowId, exportPreset: CreationsVideoExportPreset.EPR360 }
                ));
            }
        }
    }

    const onQualityChange = (highQuality: boolean): void => {
        updateStoreData(highQuality);
        props.notify({
            type: ELCreationsHeaderControllerAction.slideshowSettingsChanged, payload: isHighQuality ? CreationsVideoExportPreset.EPR720 :
                CreationsVideoExportPreset.EPR360
        });
        //start slideshow creation as soon as quality is changed
        props.notify({
            type: ELCreationsHeaderControllerAction.ctaDone
        });
    }

    return (
        <Flex alignItems="center" marginEnd="2rem">
            <Switch onChange={onQualityChange} isSelected={isHighQuality} >{intlHandler.formatMessage("high-definition-text")}</Switch>
            <TooltipTrigger delay={0} placement={"bottom"} offset={5}>
                <Flex marginStart="-0.8rem" alignItems="center" justifyContent="center" UNSAFE_className="slideshow-settings__info-box" width="1.9rem" height="1.9rem">
                    <ELCustomIcon variant="independent" iconkey={IconType.info} width={"1.12rem"} height={"1.12rem"} />
                </Flex>
                <Tooltip>{intlHandler.formatMessage("high-quality-tooltip")}</Tooltip>
            </TooltipTrigger >
        </Flex>
    );

}
