/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import AdobePrivacy from "../services/AdobePrivacy";

export default class NewRelic {
    static enableNewRelicTracking(): void {
        if (AdobePrivacy.getInstance().isPerformanceCookieActive()) {
            const script = document.createElement('script');
            script.type = "text/javascript";
            script.src = process.env.PUBLIC_URL + "/static/js/nr.js";
            document.head.appendChild(script);
        }
    }

    static isNewRelicEnabled(): boolean {
        if (AdobePrivacy.getInstance().isPerformanceCookieActive()) {
            return true
        }
        return false;
    }
}
