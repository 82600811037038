/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application Specific
import { WorkspaceAction, WorkspaceActionType } from "../../IBaseWorkspace";
import WorkflowFactory from "../../WorkflowFactory";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../IWorkflow";
import { ViewAction } from "../../../view/IBaseController";
import IModalWorkspace, { ModalWorkspaceName } from "../../IModalWorkspace";
import Logger, { LogLevel } from "../../../utils/Logger";
import store from "../../../stores/store";
import { ShareWorkspaceView } from "./ShareWorkspaceView";
import { ElementsShareServiceWrapper } from "../../../services/ElementsShareServiceWrapper";
import { ShareAction } from "../../../stores/actions/ShareAction";
import { ControllerAction } from "../../../view/IViewController";
import IWorkspace from "../../IWorkspace";

class ShareWorkspace extends IModalWorkspace {

    private readonly _modalWorkflowContainerId = "share-modal-workflow-container";
    private _consent: IWorkflow;
    private _linkGeneration: IWorkflow;
    private _linkGenerationError: IWorkflow;
    private _initWorkflowName: WorkflowsName;

    constructor(owner: IWorkspace, overlayDiv: HTMLDivElement) {
        super();
        this._owner = owner;
        this.modalWorkspaceName = ModalWorkspaceName.share;
        this._consent = WorkflowFactory.createWorkflow(WorkflowsName.consent, this);
        this._linkGeneration = WorkflowFactory.createWorkflow(WorkflowsName.linkGeneration, this);
        this._linkGenerationError = WorkflowFactory.createWorkflow(WorkflowsName.linkGenerationError, this);
        this._initWorkflowName = WorkflowsName.consent;
        this.overlayDiv = overlayDiv;
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const shareWorkspace = React.createElement(ShareWorkspaceView, {
            controller: this,
            overlayDiv: this.overlayDiv
        });

        const shareWorkspaceWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, shareWorkspace);
        ReactDOM.render(
            shareWorkspaceWrappedProvider,
            container
        );
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container)
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        const workspacePayload = { startWorkflow: this._initWorkflowName };
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload }
        this._startWorkflows(workspaceAction);
    }

    destroy(): void {
        super.destroy();
        return;
    }

    /**
     *
     * @param containerId : id of html element where workspace is mounted
     * @param workflowName : optional workflowName to be started, since there can be modal workspaces which have fixed set of workflows like share.
     * @param payload : any info that needs to be pass on to the workflow
     */
    startWorkspace<T extends WorkflowAction>(containerId: string, workflowName?: WorkflowsName, action?: T): void {
        this.createView(this.ensureHTMLElement(containerId));
        if (workflowName) {
            this._initWorkflowName = workflowName;
        }
    }

    private _startWorkflows(action: WorkspaceAction): void {
        const startWorkflowName = action.startWorkflow;
        if (startWorkflowName) {
            const { type, ...workflowPayload } = action;
            const workflowAction = { type: WorkflowActionType.initialize, payload: workflowPayload };

            switch (startWorkflowName) {
                case WorkflowsName.consent:
                    {
                        this.startWorkflow(this._modalWorkflowContainerId, this._consent, workflowAction);
                        break;
                    }
                case WorkflowsName.linkGeneration:
                    {
                        this.startWorkflow(this._modalWorkflowContainerId, this._linkGeneration, workflowAction);
                        break;
                    }
                case WorkflowsName.linkGenerationError:
                    {
                        this.startWorkflow(this._modalWorkflowContainerId, this._linkGenerationError, workflowAction);
                        break;
                    }
                case WorkflowsName.facebookShare:
                case WorkflowsName.linkShare:
                case WorkflowsName.emailShare:
                    {
                        const share = WorkflowFactory.createWorkflow(startWorkflowName, this);
                        this.startWorkflow(this._modalWorkflowContainerId, share, workflowAction);
                        break;
                    }
                default:
                    {
                        Logger.log(LogLevel.WARN, "ShareDialogWorkspace:_startWorkflows: ", "Invalid next workflow name");
                        break;
                    }
            }
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startWorkflow:
                {
                    this._startWorkflows(action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.startPreviousWorkflow:
                {
                    super.startPrevWorkflow(this._modalWorkflowContainerId, action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.endWorkspace:
                {
                    ElementsShareServiceWrapper.getInstance().cancelLinkGeneration();
                    action.type = WorkspaceActionType.endModalWorkspace;
                    handled = await this.notifyWorkspace(action);
                    break;
                }
            case WorkspaceActionType.endModalWorkspace:
                {
                    ElementsShareServiceWrapper.getInstance().cancelLinkGeneration();
                    //reset the store on workspace end
                    store.dispatch(ShareAction.reset());
                    this.endCurrentWorkflow();
                    handled = await this._owner.notify(action);
                    break;
                }
            default:
                {
                    handled = await super.notifyWorkspace(action);
                    break;
                }
        }
        return handled;
    }
}



export { ShareWorkspace };
