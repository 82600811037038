/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import ELCollageDocDataParser from "../../collage/ELCollageDocDataParser";
import ELAdobeAssetDocParser from "../../adobeAsset/ELAdobeAssetDocDataParser";
import ELPIEDocDataParser from "../../pie/parser/ELPIEDocDataParser";
import IDoc, { DocumentType } from "../IDoc";
import IDocDataParser from "./IDocDataParser";
import ELPeekThroughDocDataParser from "../../peekThrough/ELPeekThroughDocDataParser";
import ELStageDocDataParser from "../../client/parser/ELStageDocParser";

export const DocDataParserMap: { [key in DocumentType]: any } = {
    [DocumentType.collage]: ELCollageDocDataParser,
    [DocumentType.adobeAsset]: ELAdobeAssetDocParser,
    [DocumentType.peekThrough]: ELPeekThroughDocDataParser,
    [DocumentType.stageDoc]: ELStageDocDataParser,
    [DocumentType.pie]: ELPIEDocDataParser
};

export default abstract class DocDataParserFactory {
    static createParser(docType: DocumentType, doc: IDoc): IDocDataParser {
        const dataParser = new DocDataParserMap[docType](doc);
        return dataParser;
    }
}
