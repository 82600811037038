/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { JarvisCTA, JarvisChatState, ChatState } from '../common/interfaces/services/JarvisTypes';
import { FeaturesManager } from '../modules/floodgate/Featuresmanager';
import { APPLICATION_TYPE, APP_VERSION, JARVIS_APP_ID, LANGUAGE_EN, REGION_US, THEME_LIGHT } from '../utils/Constants/AppConstants';
import { IngestEventTypes, IngestLogObjectCustomKey, IngestWorkflowTypes } from '../utils/IngestConstants';
import { IngestUtils } from '../utils/IngestUtils';
import Logger, { LogLevel } from '../utils/Logger';
import { PrivacyUtils } from '../utils/PrivacyUtils';
import Utils from './../utils/Utils';
import { FeatureName } from './Floodgate/FloodgateConstants';
import IMS from './IMS';
import { IngestLogging } from './IngestWrapper';

export const JARVIS_CTA_ID = "adbmsgCta";

// Interface for Jarvis library
declare global {
    interface Window {
        AdobeMessagingExperienceClient: {
            initialize(config: any): void;
            openMessagingWindow(sourceInfo: any): void;
            isAdobeMessagingClientEnabled(): boolean;
            handleSignOut(): void;
        };
    }
}

export default class Jarvis {
    private static instance: Jarvis = new Jarvis();
    private _isJarvisReady: boolean;
    private _disableJarvisCTA: boolean;

    static getInstance(): Jarvis {
        return Jarvis.instance
    }

    private constructor() {
        this._isJarvisReady = false;
        this._disableJarvisCTA = false;
    }

    async loadAndInitializeJarvis(): Promise<void> {
        if (FeaturesManager.getInstance().IsFeatureActive(FeatureName.eJarvisChatBot)) {
            try {
                await this.load();
                await this.initialize();
                Logger.log(LogLevel.INFO, "Jarvis: load and initialize success.");
            } catch (error) {
                this._logIngest(IngestEventTypes.error, IngestEventTypes.render);
                Logger.log(LogLevel.ERROR, "Jarvis: Error in load or initialize ", error);
                return Promise.reject(error);
            }
        }
    }

    async load(): Promise<void> {
        if (!process.env.REACT_APP_JARVIS_JS_URL) return Promise.reject(new Error('REACT_APP_JARVIS_JS_URL not set'))
        if (!process.env.REACT_APP_JARVIS_CSS_URL) return Promise.reject(new Error('REACT_APP_JARVIS_CSS_URL not set'))
        if (!process.env.REACT_APP_IMS_API_KEY) return Promise.reject(new Error('REACT_APP_IMS_API_KEY not set'))
        try {
            this._isJarvisReady = false;
            const jarvisJsLoadPromise = Utils.loadScript('jarvisJs', process.env.REACT_APP_JARVIS_JS_URL);
            const jarvisCssLoadPromise = Utils.loadCSS('jarvisCss', process.env.REACT_APP_JARVIS_CSS_URL);
            await Promise.all([jarvisJsLoadPromise, jarvisCssLoadPromise]);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Jarvis load error ", error);
            return Promise.reject(error);
        }
        return Promise.resolve();
    }

    private async _configureJarvisCTA(): Promise<void> {
        const ctaId = document.getElementById(JARVIS_CTA_ID);
        if (ctaId) {
            await Utils.wait(10);//Bug at Jarvis side, can remove this once it is resolved.
            ctaId.style.display = "none";
        } else {
            this._disableJarvisCTA = true;
        }
    }

    private _showJarvisCTA(): void {
        const ctaId = document.getElementById(JARVIS_CTA_ID);
        if (ctaId) {
            ctaId.style.display = "block";
        }
    }

    showHideJarvisCTA(actionType: JarvisCTA): void {
        switch (actionType) {
            case JarvisCTA.hide:
                this._configureJarvisCTA();
                break;
            case JarvisCTA.show:
                this._showJarvisCTA();
                break;
            default:
                Logger.log(LogLevel.ERROR, "Jarvis: invalid CTA action.");
        }
    }

    isJarvisReady(): boolean {
        return this._isJarvisReady;
    }

    private _logIngest(eventType: string, eventSubType: string): void {
        const jarvisIngestObject = IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.jarvis,
            IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                eventType, eventSubType));
        jarvisIngestObject[IngestLogObjectCustomKey.viewType] = IngestUtils.getIngestUIViewNameFromRoutePath(window.location.pathname);
        IngestLogging.getInstance().logEvent(jarvisIngestObject);
    }


    async initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.isJarvisReady()) {
                Logger.log(LogLevel.INFO, "Jarvis: library already initialized");
                return resolve();
            }
            const payloadObject = {
                appid: JARVIS_APP_ID,
                appver: APP_VERSION,
                appType: APPLICATION_TYPE,
                componentid: "utilnav",// will change based on xd inputs
                componentver: APP_VERSION,
                env: process.env.REACT_APP_ENV,
                language: LANGUAGE_EN,
                region: REGION_US,
                clientId: process.env.REACT_APP_IMS_API_KEY,
                accessToken: `Bearer ${IMS.getInstance().getUserAccessToken()}`,
                cookiesEnabled: PrivacyUtils.isCookiesEnabled(),
                theme: THEME_LIGHT,
                callbacks: {
                    onReadyCallback: () => {
                        this._isJarvisReady = true;
                        Logger.log(LogLevel.INFO, "Jarvis: library is ready to use.");
                        if (this._disableJarvisCTA) {
                            this._configureJarvisCTA();
                            this._disableJarvisCTA = false;
                        }
                        this._logIngest(IngestEventTypes.success, IngestEventTypes.render);
                        resolve();
                    },
                    chatStateCallback: (state: JarvisChatState) => {
                        Logger.log(LogLevel.INFO, "Jarvis: chat state: ", state);
                        if (state.chatState === ChatState.open) {
                            this._logIngest(IngestEventTypes.success, IngestEventTypes.chatInitialized);
                        } else if (state.chatState === ChatState.close) {
                            this._logIngest(IngestEventTypes.success, IngestEventTypes.chatEnd);
                        }
                    },
                    initCallback: (data: any) => {
                        Logger.log(LogLevel.INFO, "Jarvis: library initialized: ", data);
                    },
                    getContextCallback: (contextData: any) => {
                        if (contextData)
                            Logger.log(LogLevel.INFO, "Jarvis: context data: ", contextData);
                    },
                    openMessagingWindow: (sourceInfo: any) => {
                        Logger.log(LogLevel.INFO, "Jarvis: opening messaging window with source info : ", sourceInfo);
                    },
                    initErrorCallback: (_errorCode: any) => {
                        Logger.log(LogLevel.ERROR, "Jarvis: error while initializing : ", _errorCode);
                        reject();
                    }
                },
            };
            window.AdobeMessagingExperienceClient.initialize(payloadObject);
        });
    }
}
