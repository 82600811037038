/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Header, Divider, Content, Flex } from "@adobe/react-spectrum";

//Application Specific
import { ViewportProvider } from "../../../utils/hooks/useViewport";
import ELDialog from "../../../view/components/organism/el-dialog/ELDialog";
import { WorkspaceActionType } from "../../IBaseWorkspace";
import { RootState } from "../../../stores/store";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { ShareWorkspace } from "./ShareWorkspace";
import { IngestEventTypes, IngestEventSubTypes } from "../../../utils/IngestConstants";
import { IngestUtils } from "../../../utils/IngestUtils";
import { ShareDialogIntlHeaders } from "../../../common/interfaces/share/ShareTypes";
import { Routes } from "../../../app/AppRoute";
import { ShareUtils } from "../../../utils/ShareUtils";
import { history } from "../../../utils/Utils";

import "./ShareWorkspaceView.scss";

interface ShareWorkspaceViewProps {
    controller: ShareWorkspace,
    overlayDiv: HTMLDivElement
}

const ShareWorkspaceView = (props: ShareWorkspaceViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const ref = useRef(null);
    const [overlayDiv, setOverlayDiv] = useState(props.overlayDiv);
    const shareModeData = useSelector((state: RootState) => state.shareReducer);

    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
            if (ShareUtils.isDesktopToWebShareHandover(window.location.href)) {
                history.replace(Routes.MEDIA);
            }
        }
    }, [props.controller]);

    useEffect(() => {
        if (ref.current)
            setOverlayDiv(ref.current);
    }, [ref.current]);

    const handleEscape = (): void => {
        props.controller.notify({
            type: WorkspaceActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(props.controller.getWorkflow?.getWorkflowName ?? "",
                IngestEventTypes.close, IngestEventSubTypes.shareDialog, true)
        })
        props.controller.notify({ type: WorkspaceActionType.endModalWorkspace });
    }

    return (
        <ViewportProvider>
            <ELDialog overlayDiv={overlayDiv} UNSAFE_className="el-share-dialog" isDismissible={true}
                handleEscape={() => { handleEscape() }} dataTestId="el-share-dialog">
                <div id="share-dialog">
                    <Flex UNSAFE_className="dialog__header-box">
                        <div className="dialog__title">{intlHandler.formatMessage("share")}</div>
                        <Header UNSAFE_className="dialog__header-content">{intlHandler.formatMessage(ShareDialogIntlHeaders[shareModeData.shareMode])}</Header>
                    </Flex>
                    <Divider size="S" marginBottom="1.25rem" marginTop="1rem" />
                    <Content>
                        <div id="share-modal-workflow-container" className="dialog__content-container"></div>
                    </Content>
                </div>
            </ELDialog>
        </ViewportProvider>
    )
};


export { ShareWorkspaceView };
