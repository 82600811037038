/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//App specific
import Logger, { LogLevel } from "../../utils/Logger";
import { ELAdobeAsset } from "../../common/interfaces/storage/AssetTypes";
import ELAdobeAssetDataResolver, { DocumentDataType } from "../../editors/document/dataResolver/ELAdobeAssetDataResolver";
import store from "../../stores/store";
import FullResMediaAction from "../../stores/actions/FullResMediaAction";
import { AssetStorageUtils } from "../../utils/AssetStorageUtils";

interface ELRenditionPromiseArray {
    assetId: string,
    rendition: Promise<string>
}

export class ELRenditionHandler {
    private static renditionPromiseArray: ELRenditionPromiseArray[] = [];

    async getRendition(asset: ELAdobeAsset, getType = DocumentDataType.fullRes): Promise<string> {
        const assetId = asset.assetId;

        if (!assetId) {
            return Promise.reject("ELRenditionHandler::getRendition, AssetID not present in asset");
        }

        const renditionDataPromises = ELRenditionHandler.renditionPromiseArray.filter(item => item.assetId === assetId);

        if (renditionDataPromises.length > 0) {
            return renditionDataPromises[0].rendition;
        }

        try {
            const dataResolver = new ELAdobeAssetDataResolver();
            const updatedGetType = AssetStorageUtils.shouldUseAssetRendition(asset) ? DocumentDataType.rendition : getType;
            const renditionDataPromise = dataResolver.getDataAndUpdateStore(asset, updatedGetType);
            ELRenditionHandler.renditionPromiseArray.push({ assetId: assetId, rendition: renditionDataPromise });
            return renditionDataPromise;
        } catch (err) {
            Logger.log(LogLevel.WARN, `Error in getRendition: ${err}`);
            return Promise.reject("ELRenditionHandler::getRendition, Error in getRendition");
        }
    }

    clearRenditions(): void {
        store.dispatch(FullResMediaAction.clearAllData());
        ELRenditionHandler.renditionPromiseArray = [];
    }
}