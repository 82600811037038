/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELPeekThroughDocAction, ELPeekThroughRectBorder, ELPeekThroughRectFill, ELPeekThroughShapeData, overlayCornerflipDict, rectOriginMultiplier } from "../../common/interfaces/creations/ELPeekThroughTypes";
import { RenderedShapesName, RendererUpdateData } from "../../common/interfaces/renderer/RendererTypes";
import { ELStageShapesType } from "../../common/interfaces/stage/StageTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import { ControllerAction } from "../../view/IViewController";
import IDocDataParser from "../document/parser/IDocDataParser";
import IRenderer from "../renderer/IRenderer";
import IGraphicsStage from "../stage/IGraphicsStage";
import ShapesFactory from "../stage/shapes/ShapesFactory";
import ELPeekThroughDoc from "./ELPeekThroughDoc";

export default class ELPeekThroughRenderer extends IRenderer {
    private _doc: ELPeekThroughDoc;

    constructor(doc: ELPeekThroughDoc, stage: IGraphicsStage, dataParser: IDocDataParser) {
        super(stage, dataParser);

        this._doc = doc;
    }

    hasRenderingError(): boolean {
        for (const stageData of this.stageDataList) {
            if (stageData.stageShapeAndObjectOptions.shape.hasError()) {
                return true;
            }
        }

        return false;
    }

    async update(updateData: RendererUpdateData): Promise<void> {
        switch (updateData.type) {
            case ELPeekThroughDocAction.overlayUpdated:
                {
                    const data = updateData.payload as ELPeekThroughShapeData;
                    for (const stageData of this.stageDataList) {
                        if (stageData.object.data && stageData.object.data.payload.overlayPlacementId === data.overlayPlacementId) {
                            const docSize = await this._doc.getSize();
                            const shapeData = stageData.stageShapeAndObjectOptions.objectOptions.data;
                            shapeData.allowDrop = true;
                            (shapeData.payload as ELPeekThroughShapeData).asset = data.asset;
                            const overlayPlacementId = (shapeData.payload as ELPeekThroughShapeData).overlayPlacementId;

                            stageData.stageShapeAndObjectOptions.shape.remove(this.stage);
                            stageData.stageShapeAndObjectOptions.shape = ShapesFactory.createShape(ELStageShapesType.imageFromURI);
                            stageData.stageShapeAndObjectOptions.objectOptions = {
                                name: RenderedShapesName.image,
                                data: shapeData,
                                image: (shapeData.payload as ELPeekThroughShapeData).asset?.objectURL ?? "",
                                width: docSize.width / 2,
                                height: docSize.height / 2,
                                left: 0,
                                top: 0,
                                fill: "lightgrey",
                                selectable: true,
                                fitToCorner: overlayPlacementId,
                                flipX: overlayCornerflipDict[overlayPlacementId][0],
                                flipY: overlayCornerflipDict[overlayPlacementId][1]
                            };
                            stageData.object = await stageData.stageShapeAndObjectOptions.shape.draw(this.stage, stageData.stageShapeAndObjectOptions.objectOptions);
                        }
                    }
                    break;
                }
            case ELPeekThroughDocAction.removeOverlay:
                {
                    const data = updateData.payload as ELPeekThroughShapeData;
                    for (const stageData of this.stageDataList) {
                        if (stageData.object.data && stageData.object.data.payload.overlayPlacementId === data.overlayPlacementId) {
                            const docSize = await this._doc.getSize();
                            const shapeData = stageData.stageShapeAndObjectOptions.objectOptions.data;
                            (shapeData.payload as ELPeekThroughShapeData).asset = undefined;
                            const overlayPlacementId = (shapeData.payload as ELPeekThroughShapeData).overlayPlacementId;

                            stageData.stageShapeAndObjectOptions.shape.remove(this.stage);
                            stageData.stageShapeAndObjectOptions.shape = ShapesFactory.createShape(ELStageShapesType.rect);
                            stageData.stageShapeAndObjectOptions.objectOptions = {
                                name: RenderedShapesName.rect,
                                data: shapeData,
                                width: docSize.width / 2,
                                height: docSize.height / 2,
                                left: rectOriginMultiplier[overlayPlacementId][0] * docSize.width,
                                top: rectOriginMultiplier[overlayPlacementId][1] * docSize.height,
                                fill: ELPeekThroughRectFill,
                                selectable: true,
                                lockMovementX: true,
                                lockMovementY: true,
                                lockRotation: true,
                                addToStage: true,
                                scaleX: 1,
                                scaleY: 1,
                                originX: "left",
                                originY: "top",
                                borderColor: ELPeekThroughRectBorder
                            };
                            stageData.object = await stageData.stageShapeAndObjectOptions.shape.draw(this.stage, stageData.stageShapeAndObjectOptions.objectOptions);
                        }
                    }
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "ELPeekThroughRenderer::update", "default case");
                }
        }
    }

    destroy(): void {
        super.destroy();
        this.stage.destroy();
    }

    async render(container: HTMLElement): Promise<void> {
        await super.render(container);
        return new Promise((resolve) => {
            this.stage.setBackground("#ececec");
            this.dataParser.parseDocData().then((stageShapeAndObjectOptionsList) => {
                this.drawShapes(stageShapeAndObjectOptionsList).then((stageObjectList) => {
                    this.saveStageData(stageShapeAndObjectOptionsList, stageObjectList);
                    resolve();
                });
            });
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return await super.notify(action);
    }
}