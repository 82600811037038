/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from "react";
import { IconType, ICONS } from "../../../../assets/IconConstants";
import Logger, { LogLevel } from "../../../../utils/Logger";

export interface ELIconProps extends React.SVGProps<SVGSVGElement> {
    iconkey: IconType,
    title?: string
}

export const ELIcon = (props: ELIconProps): React.ReactElement => {
    const Icon = ICONS[props.iconkey];

    // This is done because while running yarn test, svg files are not compiled and thus return undefined from the map.
    // Causing test cases to break.
    // So we are returning empty div in that case.
    if (Icon === undefined) {
        Logger.log(LogLevel.WARN, "ELIcon is undefined for iconKey ", props.iconkey);
        return (
            <></>
        )
    }
    return (
        <Icon {...props} />
    )
}