/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELSize } from "../geometry/ELGeometry";
import { ShareType } from "../share/ShareTypes";
import { ELAdobeAsset } from "../storage/AssetTypes";
import { ClientInputInfo, CurrOperationInput } from "./common/CSTypes";
import { ELLayoutInfo } from "./ELSocialLayoutTypes";
import { SlideshowInputInfo } from "./SlideshowTypes";

export const CURRENT_CREATION_VERSION = 3;

export enum CreationsJobStorageType {
    RAPI = "RAPI"
}

export enum CreationJobRequestInvocationMode {
    user = "user",
    auto = "auto"
}

export enum CreationsJobProjectType {
    creation = "creation"
}

export enum CreationsJobProjectSubType {
    slideshow = "slideshow",
    photoCollage = "photoCollage",
    patternOverlay = "patternOverlay",
    peekThrough = "peekThroughOverlay",
    magicalBackdrop = "magicalBackdrop",
    replaceBackground = "replaceBackground",
    movingOverlay = "movingOverlay",
    photoText = "photoText"
}

export enum CreationName {
    creation = "Creation",
    slideshow = "Slideshow",
    photoCollage = "Photo Collage",
    patternOverlay = "Pattern Overlay",
    movingOverlay = "Moving Overlay",
    replaceBackground = "Auto Background",
    peekThrough = "Peek-through Overlay",
    photoText = "Photo Text"
}


export enum CreationsMode {
    create = "create",
    open = "render",
    retry = "retry",
    update = "update",
    save = "save",
    edit = "edit",
    render = "render",
    preview = "preview",
    start = "start"
}
export interface ELCreationWorkflowPayload {
    initMode?: CreationsMode,
    payload?: unknown
}

export enum CreationJobResourceType {
    private = "private",
    public = "public"
}

export interface CreationJobAsset {
    assetURN: string,
    storageType: CreationsJobStorageType,
    mimeType: string
}


export interface CreationsJobOperations {
    version: number,
    id: string,
    operationType: string,
    operationSubType: CreationsJobProjectSubType,
    specificInfo?: CreationsOperationsSpecificInfo[]
}

export interface CreationsJobProjectSchema {
    version: number,
    language: string,
    title: string,
    requestInvocationMode: CreationJobRequestInvocationMode,
    operations: CreationsJobOperations[],
    assets: CreationJobAsset[]
}

export interface CSAsset {
    id?: string
    assetURN: string,
    storageType: string,
    mimeType: string
}

export interface CSAssetWithData extends CSAsset {
    objectURL?: string
}

export interface CreationsOutputInfo {
    assetURN: string;
    mimeType: string;
    root: string;
    storageType: string;
    exportSettings?: CreationsExportSettings
}

export interface CreationsOutput {
    media: CreationsOutputInfo, // remove
    config: CreationsOutputInfo
    preview?: CreationsOutputInfo
}
export interface CreationsOutputConfigResultPath {
    rootPath: string,
    relativeFilePath: string
}

export interface RecommendationsData {
    id: string,
    creationDate: string,
    modifiedDate: string,
    outputs: any,
    assets?: CSAsset[],
    version?: number
}
export interface CreationsData {
    id: string;
    title: string;
    creationDate: string;
    modifiedDate: string;
    operationSubType: string;
    operationType: string;
    status: string;
    errorCode?: string,
    outputs?: CreationsOutput,
    inputs?: CurrOperationInput,
    assets?: CSAsset[],
    optionalInfo?: ClientInputInfo, //this will go away once v2 is active, don't use it on client
    isMultipleVersion?: boolean,
    version?: number
}

export interface CreationsTitleInfo {
    loopID: string,
    mainText: string
}

export interface CreationsOptionalInfo {
    themeid: string,
    musicid?: string,
    titleInfo?: CreationsTitleInfo[]
}

export enum CreationsVideoExportPreset {
    EPR360 = "video360",
    EPR720 = "video720"
}

export interface CreationsVideoExportSettings {
    version: number,
    preset: CreationsVideoExportPreset,
    hardwareAccelerated: boolean,
    startTime: number,
    endTime: number,
    renderQuality: number
}

export enum CreationsImageMimeType {
    IMAGE_PNG = "image/png"
}

export interface CreationsImageExportSettings {
    version: number,
    width: number,
    height: number,
    mimeType: CreationsImageMimeType
}

export type CreationsExportSettings = CreationsVideoExportSettings | CreationsImageExportSettings;

export interface CreationsOperationsOutputsData {
    version: number,
    type: string,
    exportSettings?: CreationsExportSettings,
}

export interface CreationsOperationsOutputs {
    preview: CreationsOperationsOutputsData,
    full: CreationsOperationsOutputsData,
    project: {
        version: number
    },
}

export type CreationsOperationsInputs = SlideshowInputInfo;

export interface CreationsOperationsSpecificInfo {
    version: number,
    inputs: CreationsOperationsInputs,
    outputs: CreationsOperationsOutputs,
}

export interface CreationsJob {
    assets: ELAdobeAsset[],
    operationSubType: CreationsJobProjectSubType,
    title: string,
    requestInvocationMode: CreationJobRequestInvocationMode,
    specificInfo?: CreationsOperationsSpecificInfo[]
}

export interface RenameCreationPayload {
    projectId: string,
    title: string,
    operationSubType: string
}

export interface CreationsStatusPayload {
    projectId: string,
    data?: CreationsData
}

export interface CreationsProgressPayload {
    projectId: string,
    percent: number
}

export interface CreationsVideoExportPresetPayload {
    projectId: string,
    exportPreset: CreationsVideoExportPreset
}

export enum CreationsStatus {
    created = "created",
    requested = "requested",
    success = "success",
    error = "error",
    retry = "retry",
    cancelled = "cancelled",
    editing = "editing",
    stale = "stale",
    oldVersion = "oldVersion",
    apiError = "apiError",
}

export enum CreationsCancelStatus {
    cancelRequested = "cancelRequested",
    cancelProcessing = "cancelProcessing",
    cancelled = "cancelled",
    errorCancelling = "errorCancelling",
}

export enum RecommendationErrorCode {
    recommendationsFailed = "recommendationFailed"
}

export enum RecommendationErrorMsg {
    recommendationsFailed = "recommendations could not be created!",
    failedRecommendationsStatus = "could not get recommendations request status!",
}

export enum CreationsErrorCode {
    creationsFailed = "creationsFailed",
    editingFailed = "editingFailed",
    failedProjectData = "failedProjectData",
    failedProjectStatus = "failedProjectStatus",
    invalidOutput = "invalidOutput",
    failedOutput = "failedOutput",
    invalidOutputPreview = "invalidOutputPreview",
    failedFetchingOutput = "failedFetchingOutput",
    invalidRendtion = "invalidRendtion",
    failedRecentCreationsData = "failedRecentCreationsData",
    failedDownloadingCreation = "failedDownloadingCreation"
}

export enum CreationsErrorMsg {
    creationsFailed = "creations could not be created!",
    editingFailed = "creations editing failed!",
    failedProjectData = "could not get creations project data!",
    failedProjectStatus = "could not get creations project status!",
    invalidOutput = "creations output invalid!",
    failedOutput = "could not create creations output!",
    invalidOutputPreview = "creations preview output invalid!",
    failedFetchingOutput = "could not fetch creations output!",
    invalidRendtion = "could not fetch creations rendition!",
    failedRecentCreationsData = "Unable to fetch recent creations!",
    failedDownloadingCreation = "Error in downloading creation!",
    failedAppMetada = "could not get app metadata",
}

export const enum CreationsURLParams {
    projectId = "projectId",
    workflow = "workflow",
    highlightCreation = "highlightCreation"
}

export const CreationsTypeToShareTypeMap: Record<string, ShareType> = {
    [CreationsJobProjectSubType.slideshow]: ShareType.slideshow,
    [CreationsJobProjectSubType.photoCollage]: ShareType.photoCollage,
    [CreationsJobProjectSubType.patternOverlay]: ShareType.patternOverlay,
    [CreationsJobProjectSubType.movingOverlay]: ShareType.movingOverlay,
    [CreationsJobProjectSubType.replaceBackground]: ShareType.replaceBackground,
    [CreationsJobProjectSubType.magicalBackdrop]: ShareType.replaceBackground,
    [CreationsJobProjectSubType.peekThrough]: ShareType.peekThrough,
    [CreationsJobProjectSubType.photoText]: ShareType.photoText
}

export const CreationsDisplayNames: Record<CreationName, string> = {
    [CreationName.creation]: "creation",
    [CreationName.slideshow]: "slideshow",
    [CreationName.photoCollage]: "photo-collage",
    [CreationName.patternOverlay]: "pattern-overlay",
    [CreationName.movingOverlay]: "moving-overlay",
    [CreationName.replaceBackground]: "replace-background",
    [CreationName.peekThrough]: "peek-through",
    [CreationName.photoText]: "photo-text"
}

export enum CreationWorkflowActions {
    creationRenderStatus = "CREATION_RENDER_STATUS",
    updateCreationStatus = "UPDATE_CREATION_STATUS",
    backgroundChanged = "BACKGROUND_CHANGED",
    replaceMediaStatus = "REPLACE_MEDIA_STATUS",
    renameCreationTitle = "RENAME_CREATION_TITLE",
    confirmMediaDeletion = "CONFIRM_MEDIA_DELETION"
}

export const UNTITLED_INTL_KEY = "untitled";
export const MAX_PROJECT_TITLE_LENGTH = 256

export enum CreationsDownloadFileType {
    jpeg = "JPEG",
    png = "PNG",
    mp4 = "MP4",
    psd = "PSD"
}

export interface ELCreationDocumentDetails {
    size: ELSize
    layoutInfo: ELLayoutInfo
}

export interface CompositeSettings {
    width: number,
    height: number,
    layoutId?: string
}

export enum ELViewType {
    before = "BEFORE",
    after = "AFTER"
}

export type CreationReducers = "peekThroughReducer" | "recommendationWorkflowReducer" | "imageEditWorkflowReducer"

export const TRANSPARENT_BACKGROUND_ID = "00000000-0000-0000-0000-000000000000";
export const TRANSPARENT_BACKGROUND_NAME = "TRANSPARENT";