/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import Logger, { LogLevel } from "../../../utils/Logger";
import IViewController, { ControllerAction } from "../../../view/IViewController";
import { IBatchProcessor } from "../elBatchProcessor/IBatchProcessor";


/**
 * @template RequestParams The type of input request parameters for creating a batch.
 * @template ResponseData The type of response data returned after posting the batch request.
 */
abstract class IBatchRequestHandler<RequestParams, ResponseData> extends IViewController {
    protected owner: IBatchProcessor<RequestParams, ResponseData>;

    constructor(owner: IBatchProcessor<RequestParams, ResponseData>) {
        super();
        this.owner = owner;
    }

    abstract createBatchAndPostRequest(requestParams: RequestParams, batchSize: number): Promise<ResponseData>;

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            default: {
                Logger.log(LogLevel.INFO, "IBatchRequestHandler: notify, unhandled action.type: " + action.type);
            }
        }

        if (!handled) {
            handled = await this.owner.notify(action);
        }

        return handled;
    }
}

export default IBatchRequestHandler;