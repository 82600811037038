/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { Key, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";

//Application Specific
import store, { RootState } from "../../../../stores/store";
import { ELTabPanelManager } from "../../organism/el-tab-panel-manager/ELTabPanelManager";
import { IconType } from "../../../../assets/IconConstants";
import ELScoopedTabButton from "../../atoms/el-scooped-tab-button/ELScoopedTabButton";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import SlideshowAudioView from "./../slideshow-audio-panel/SlideshowAudioView";
import SlideshowMediaView from "./../slideshow-media-panel/SlideshowMediaView";
import SlideshowTemplateView from "./../slideshow-template-panel/SlideshowTemplateView";
import ELSlideshowIntroView from "../el-slideshow-intro-panel/ELSlideshowIntroView";
import { ELPanelState, SlideshowPanelTypes } from "../../../../common/interfaces/creations/common/PanelTypes";
import SlideshowPanel from "./SlideshowPanel";
import CreationsAction from "../../../../stores/actions/CreationsAction";
import { CLOSED_PANEL_KEY } from "../../../../common/interfaces/creations/SlideshowTypes";

import "./SlideshowPanelView.scss";
import { ELTabData, ELTabItem, ELTabPanel } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";

interface ISlideshowPanelViewProps {
    controller: SlideshowPanel
}

const SlideshowPanelView = (props: ISlideshowPanelViewProps): React.ReactElement => {

    const defaultSelectedKey = "template";
    const creationsStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);
    const intlHandler = IntlHandler.getInstance();
    const dispatch = useDispatch();


    //TODO: filter slideshow data from creationsReducer;
    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const getAudioTabItem = (): ELTabItem => {
        const text = intlHandler.formatMessage("audio");
        const tabItem: ELTabItem = {
            item: (<ELScoopedTabButton iconkey={IconType.slideshowAudio} selected={creationsStoreData.panelSelectedKey === SlideshowPanelTypes.audioPanel}
                text={text} variant="secondary" tooltip={text} />)
        };
        return tabItem;
    }

    const getMediaTabItem = (): ELTabItem => {
        const text = intlHandler.formatMessage("media");
        const tabItem: ELTabItem = {
            item: (<ELScoopedTabButton iconkey={IconType.mediaManagerTab} selected={creationsStoreData.panelSelectedKey === SlideshowPanelTypes.mediaPanel}
                text={text} variant="secondary" tooltip={text} />)
        };
        return tabItem;
    }

    const getTemplateTabItem = (): ELTabItem => {
        const text = intlHandler.formatMessage("template");
        const tabItem: ELTabItem = {
            item: (<ELScoopedTabButton iconkey={IconType.slideshowTemplate} selected={creationsStoreData.panelSelectedKey === SlideshowPanelTypes.templatePanel}
                text={text} variant="secondary" tooltip={text} />)
        };
        return tabItem;
    }

    const getIntroTextTabItem = (): ELTabItem => {
        const text = intlHandler.formatMessage("intro-text");
        const tabItem: ELTabItem = {
            item: (<ELScoopedTabButton iconkey={IconType.slideshowIntroText} selected={creationsStoreData.panelSelectedKey === SlideshowPanelTypes.introPanel}
                text={text} variant="secondary" tooltip={text} />)
        };
        return tabItem;
    }

    const getSlideshowAudioTabBody = (): ELTabPanel => {
        const tabBody: ELTabPanel = { panel: (<SlideshowAudioView controller={props.controller.getSlideshowAudio} />) };
        return tabBody;
    }

    const getSlideshowMediaTabBody = (): ELTabPanel => {
        const tabBody: ELTabPanel = {
            panel: (<SlideshowMediaView controller={props.controller.getSlideshowMedia} />)
        };
        return tabBody;
    }

    const getSlideshowTemplateTabBody = (): ELTabPanel => {
        const tabBody: ELTabPanel = { panel: (<SlideshowTemplateView controller={props.controller.getSlideshowTemplate} />) };
        return tabBody;
    }

    const getIntroTextTabBody = (): ELTabPanel => {
        const tabBody: ELTabPanel = { panel: (<ELSlideshowIntroView controller={props.controller.getSlideshowIntro} />) };
        return tabBody;
    }

    const getTabData = (): ELTabData[] => {
        const tabItems = [] as ELTabData[];
        const audioTab: ELTabData = { key: SlideshowPanelTypes.audioPanel, item: getAudioTabItem(), panel: getSlideshowAudioTabBody() };
        const mediaTab: ELTabData = { key: SlideshowPanelTypes.mediaPanel, item: getMediaTabItem(), panel: getSlideshowMediaTabBody() };
        const templateTab: ELTabData = { key: SlideshowPanelTypes.templatePanel, item: getTemplateTabItem(), panel: getSlideshowTemplateTabBody() };
        const introTextTab: ELTabData = { key: SlideshowPanelTypes.introPanel, item: getIntroTextTabItem(), panel: getIntroTextTabBody() };

        tabItems.push(mediaTab);
        tabItems.push(templateTab);
        tabItems.push(audioTab);
        tabItems.push(introTextTab);

        return tabItems;
    }

    const onSelectionChange = (key: Key): void => {
        if (creationsStoreData.panelSelectedKey === key)
            dispatch(CreationsAction.updateSlideshowPanelSelectedKey(CLOSED_PANEL_KEY as string));
        else
            dispatch(CreationsAction.updateSlideshowPanelSelectedKey(key as string));
    }

    const getPanelState = (): ELPanelState => {
        if (creationsStoreData.panelSelectedKey === CLOSED_PANEL_KEY)
            return ELPanelState.closed;
        else
            return ELPanelState.opened;
    }

    const getClassName = (): "S" | "M" | "L" => {
        switch (creationsStoreData.panelSelectedKey) {
            case SlideshowPanelTypes.audioPanel:
            case SlideshowPanelTypes.introPanel: {
                return "S";
            }
            default:
                return "M";
        }
    }

    return (
        <div className={"slideshow-right-panel " + getPanelState()}>
            <Provider store={store}>
                <ELTabPanelManager dataTestId="test-panel-tab-manager" tabData={getTabData()} orientation="vertical" variant="modal"
                    defaultSelectedKey={defaultSelectedKey}
                    onSelectionChange={onSelectionChange}
                    panelItemDirection="row-reverse"
                    showTabContent={creationsStoreData.panelSelectedKey !== CLOSED_PANEL_KEY}
                    size={getClassName()} />
            </Provider>
        </div>
    )
};

export default SlideshowPanelView;
