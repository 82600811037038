/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application specific
import IBaseWorkspace, { WorkspaceAction, WorkspaceActionType } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowsName, WorkflowActionType } from "../../../IWorkflow";
import { MediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/MediaManagerTypes";
import { ViewAction } from "../../../../view/IBaseController";
import CreationsHomeView from "./CreationHomeView";
import ELCreateOnDemand from "../../../../view/components/templates/el-create-on-demand/ELCreateOnDemand";
import ELRecentCreations from "../../../../view/components/templates/el-recent-creations/ELRecentCreations";
import { ControllerAction } from "../../../../view/IViewController";
import { ELCreateOnDemandAction, ELCreateOnDemandData } from "../../../../common/interfaces/creations/ELCreateOnDemandTypes";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELRecentCreationsControllerActions } from "../../../../common/interfaces/creations/ELRecentCreationsTypes";
import store from "../../../../stores/store";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import { Routes } from "../../../../app/AppRoute";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import { ELOpenInDesktopDeeplinkAction } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import { CreationsMode } from "../../../../common/interfaces/creations/CreationTypes";
import Utils from "../../../../utils/Utils";
import CreationUtils from "../../utils/CreationUtils";

class CreationHome extends IWorkflow {
    private _createOnDemand: ELCreateOnDemand;
    private _recentCreations: ELRecentCreations;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.creationsHome);
        this._createOnDemand = new ELCreateOnDemand(this);
        this._recentCreations = new ELRecentCreations(this);
    }

    private _ingest(payload: Record<string, string>): void {
        this.notifyWorkflow({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    private _createOnDemandActions(createOnDemandData: ELCreateOnDemandData): void {
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.info,
            IngestEventSubTypes.start, "Creations"));

        const nextWorkflow = createOnDemandData.thumbId;

        switch (nextWorkflow) {
            case WorkflowsName.slideshow:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
                        IngestEventSubTypes.start, WorkflowsName.slideshow));

                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.slideshow,
                        nextWorkflowInitMode: CreationsMode.create,
                        payload: createOnDemandData.mediaGridConfig
                    };
                    this._startWorkflows(workspacePayload);
                    break;
                }
            case WorkflowsName.collage:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
                        IngestEventSubTypes.start, WorkflowsName.collage));

                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.collage,
                        nextWorkflowInitMode: CreationsMode.create,
                        payload: createOnDemandData.mediaGridConfig
                    };
                    this._startWorkflows(workspacePayload);
                    break;
                }
            case WorkflowsName.patternOverlay:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
                        IngestEventSubTypes.start, WorkflowsName.patternOverlay));

                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.patternOverlay,
                        nextWorkflowInitMode: CreationsMode.create,
                        payload: createOnDemandData.mediaGridConfig
                    };

                    this._startWorkflows(workspacePayload);
                    break;
                }
            case WorkflowsName.peekThrough:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
                        IngestEventSubTypes.start, WorkflowsName.peekThrough));

                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.peekThrough,
                        nextWorkflowInitMode: CreationsMode.create,
                        payload: createOnDemandData.mediaGridConfig
                    };
                    this._startWorkflows(workspacePayload);
                    break;
                }
            case WorkflowsName.movingOverlay:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
                        IngestEventSubTypes.start, WorkflowsName.movingOverlay));

                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.movingOverlay,
                        nextWorkflowInitMode: CreationsMode.create,
                        payload: createOnDemandData.mediaGridConfig
                    };

                    this._startWorkflows(workspacePayload);
                    break;
                }
            case WorkflowsName.replaceBackground:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
                        IngestEventSubTypes.start, WorkflowsName.replaceBackground));

                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.replaceBackground,
                        nextWorkflowInitMode: CreationsMode.create,
                        payload: createOnDemandData.mediaGridConfig
                    };

                    this._startWorkflows(workspacePayload);
                    break;
                }
            case WorkflowsName.photoText:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.createOnDemand, IngestEventTypes.click,
                        IngestEventSubTypes.start, WorkflowsName.photoText));

                    const workspacePayload = {
                        startWorkflow: WorkflowsName.mediaManager,
                        nextWorkflow: WorkflowsName.photoText,
                        nextWorkflowInitMode: CreationsMode.create,
                        payload: createOnDemandData.mediaGridConfig
                    };

                    this._startWorkflows(workspacePayload);
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "CreationHome:_createOnDemandActions: ", "Invalid nextWorkflow in _createOnDemandActions");
                    break;
                }
        }
    }

    private _startWorkflows(workspacePayload: Record<string, unknown>): void {
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
        this._owner.notify(workspaceAction);
    }

    private _recentCreationsActions(workspaceAction: WorkspaceAction): void {
        const workspacePayload = {
            startWorkflow: WorkflowsName.creationsPreview,
            initMode: CreationsMode.preview,
            payload: workspaceAction.payload
        };

        this._startWorkflows(workspacePayload);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._createOnDemand.createView(this.ensureHTMLElement("create-on-demand-container"));
        this._recentCreations.createView(this.ensureHTMLElement("recent-creations-container"));
        HistoryUtils.replaceHistory(Routes.CREATIONS);
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.creationHome, IngestEventSubTypes.success, IngestEventTypes.render, true));
    }

    createView(container: HTMLElement): void {
        super.createView(container)

        ReactDOM.render(
            React.createElement(CreationsHomeView, {
                controller: this
            }),
            container
        )
    }

    destroyView(): void {
        this._createOnDemand.destroyView();
        this._recentCreations.destroyView();

        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELCreateOnDemandAction.workflowThumb:
                {
                    this._createOnDemandActions(action.payload as ELCreateOnDemandData);
                    handled = true;
                    break;
                }
            case ELOpenInDesktopDeeplinkAction.deeplinkStatusChanged: {
                this._recentCreations.notify({ type: ELOpenInDesktopDeeplinkAction.deeplinkStatusChanged, payload: action.payload });
                handled = true;
                break;
            }
            default:
                {
                    Logger.log(LogLevel.WARN, "CreationHome(ControllerAction): Invalid action" + action);
                    break;
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

    /**
    * Handles core actions
    * @param action WorkflowAction
    */
    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case MediaManagerWorkflowAction.mediaSelection:
                {
                    const workspacePayload = {
                        startWorkflow: action.nextWorkflow,
                        initMode: action.nextWorkflowInitMode,
                        payload: action.payload
                    };

                    if (action.nextWorkflow)
                        HistoryUtils.pushHistory(CreationUtils.getCreationWorkflowHistoryState(action.nextWorkflow, Utils.getNilUUID()));

                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            case ELRecentCreationsControllerActions.openCreation:
                {
                    this._recentCreationsActions(action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.ingest:
            default:
                {
                    handled = await super.notifyWorkflow(action);
                    break;
                }
        }
        return handled;
    }
}

export default CreationHome;
