/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { ContentType, RenderQuality, Resolution } from "@elements/elementswebcommon";

//Application Specific
import { CreationsVideoExportPreset } from "./CreationTypes";
import { IBaseRequestParams } from "./ELCreationsJobTypes";

export interface SlideshowInputInfo {
    templates: {
        video: string,
        audio: string,
    },
    titles: [{
        position: number,
        mainText: string
    }]
}

export enum ELSlideshowTemplateViewAction {
    themeData = "THEME_DATA",
    setTemplateId = "SET_TEMPLATE_ID"
}

export enum ELSlideshowTemplateControllerAction {
    setTemplateId = "SET_TEMPLATE_ID"
}

export enum ELSlideshowAudioViewAction {
    audioData = "AUDIO_DATA"
}

export enum ELSlideshowPanelWorkflowAction {
    manageMedia = "MANAGE_MEDIA"
}

export const SlideshowViewActions = {
    slideshowStatus: "SLIDESHOW_STATUS",
    slideshowThumbData: "SLIDESHOW_THUMB_DATA"
}

export const SlideshowControllerActions = {
    notifyOnEmail: "NOTIFY_ON_EMAIL",
    retrySlideshow: "RETRY_SLIDESHOW",
    deleteSlideshow: "DELETE_SLIDESHOW",
    backSlideshowView: "BACK_SLIDESHOW_VIEW",
    fetchRendition: "FETCH_RENDITION"
}

export interface ELSlideshowProjectRequestParams extends IBaseRequestParams {
    inputInfo?: SlideshowInputInfo,
    resolution?: CreationsVideoExportPreset,
}

export interface SlideshowExportPresets {
    contentType: ContentType
    id: string
    mimetype: string
    resolution: Resolution
}
export interface SlideshowExportSettings {
    preset: SlideshowExportPresets,
    startTime: number
    endTime: number
    hardwareAccelerated: boolean
    renderQuality: RenderQuality
}

export const CLOSED_PANEL_KEY = "CLOSED_PANEL_KEY";
