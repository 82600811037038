/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";

//adobe internal
import { Flex } from "@adobe/react-spectrum";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ASSETS_ENDPOINT, CollageTemplateData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import ELCollapsiblePanelView from "../../organism/el-collapsible-panel/ELCollapsiblePanelView";
import ELCollageLayout from "./ELCollageLayout";
import { ELCollageLayoutViewAction } from "../../../../common/interfaces/creations/ELCollageTypes";
import { RootState } from "../../../../stores/store";
import CollageUtils from "../../../../workspaces/creations/workflows/collage/utils/CollageUtils";
import { ELCollageLayoutControllerActions, layoutPriority } from "../../../../common/interfaces/creations/collage/ELCollageLayoutTypes";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import { CollapsiblePanelData } from "../../../../common/interfaces/creations/templates/ELCollapsiblePanelTypes";

import "./ELCollageLayout.scss";


export interface CollapsiblePanelProps {
    controller: ELCollageLayout
}

interface CollapsiblePanelState {
    collageTemplateData: CollageTemplateData[],
    selectedPanelId: string,
    selectedTemplateId: string
}

const initialState: CollapsiblePanelState = {
    collageTemplateData: [],
    selectedPanelId: "",
    selectedTemplateId: ""
}

const ELCollageLayoutView = (props: CollapsiblePanelProps): React.ReactElement => {
    const [layoutCount, setLayoutCount] = useState(0);
    const collageStoreData = useSelector((state: RootState) => state.collageReducer);

    const intlHandler = IntlHandler.getInstance();

    const reducer = (state: CollapsiblePanelState, action: ViewAction): CollapsiblePanelState => {
        switch (action.type) {
            case ELCollageLayoutViewAction.layoutData:
                return {
                    ...state,
                    collageTemplateData: action.payload as CollageTemplateData[]
                };
            case ELCollageLayoutViewAction.setLayoutId:
                return {
                    ...state,
                    selectedPanelId: action.payload as string
                };
            case ELCollageLayoutViewAction.setTemplateId:
                return {
                    ...state,
                    selectedTemplateId: action.payload as string
                }
            default:
                return state;
        }
    }
    const [state, viewDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        if (collageStoreData.layout)
            setLayoutCount(collageStoreData.layout);
    }, [collageStoreData.layout]);

    useEffect(() => {
        if (collageStoreData.id) {
            viewDispatch({ type: ELCollageLayoutViewAction.setTemplateId, payload: collageStoreData.id });
        }
    }, [collageStoreData.id]);

    useEffect(() => {
        if (collageStoreData.category && state.selectedPanelId !== collageStoreData.category) {
            viewDispatch({ type: ELCollageLayoutViewAction.setLayoutId, payload: collageStoreData.category });
        }
    }, [collageStoreData.category]);

    const onPanelChange = (id: string): void => {
        if (state.selectedPanelId === id)
            viewDispatch({ type: ELCollageLayoutViewAction.setLayoutId, payload: "" });
        else
            viewDispatch({ type: ELCollageLayoutViewAction.setLayoutId, payload: id });
    }

    const onThumbPress = (id: string): void => {
        props.controller.notify({ type: ELCollageLayoutControllerActions.templateChanged, payload: id });
    }

    const getFilteredTemplate = (templateList: CollageTemplateData[]): CollageTemplateData[] => {
        return CollageUtils.filterTemplateByLayoutCount(templateList, layoutCount);
    }

    const getCategoryTitle = (title: string): string => {
        return title.charAt(0).toLowerCase() + title.slice(1);
    }

    const getLayoutView = (): React.ReactNode => {
        const layoutMap = new Map();
        const filteredTemplates = getFilteredTemplate(state.collageTemplateData) as CollageTemplateData[];
        filteredTemplates.forEach(function (template) {
            const templateCategory = getCategoryTitle(template.category);
            const templateData: CollapsiblePanelData = {
                id: template.id,
                name: template.name,
                previewUrl: process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + template.previewUrl
            };

            if (layoutMap.has(templateCategory))
                layoutMap.get(templateCategory).push(templateData);
            else
                layoutMap.set(templateCategory, [templateData]);
        });
        const keys = [...layoutMap.keys()];
        keys.sort((category1: string, category2: string) => layoutPriority[category1] - layoutPriority[category2]);
        return (
            keys.map((item, index) => <ELCollapsiblePanelView key={index} flyoutHeaderText={item} panelID={item}
                onThumbPress={(id) => onThumbPress(id)} onPress={onPanelChange} opened={item === state.selectedPanelId}
                data={layoutMap.get(item)} selectedTemplateId={state.selectedTemplateId} />)
        )
    }

    return (
        <div className="collage-panel-box">

            <Flex UNSAFE_className="layout-header">
                <div>{intlHandler.formatMessage("layout")}</div>
                <div className={"layout-shuffle-box"} title={intlHandler.formatMessage("shuffle-tootip-msg")}>
                    <ELCustomIcon variant="independent" iconkey={IconType.creationsShuffle} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={() => props.controller.notify({ type: ELCollageLayoutControllerActions.shuffle })}>
                    </ELCustomIcon>
                </div>
            </Flex>

            <ELScrollPanel UNSAFE_className="collage-panel-scroll" scrollY={true} width="100%">
                <div className="collage-panel__content-box">
                    <Flex direction="column" gap="0.625rem" marginTop="1rem">
                        {getLayoutView()}
                    </Flex>
                </div>
            </ELScrollPanel>
        </div>
    );
}

export default ELCollageLayoutView;
