/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { DocumentFormat } from "../../../common/interfaces/document/DocumentTypes";
import { EditingEngineType } from "../../../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { ELLayerKind, ELStageLayerData } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELPIEDocRenderedShapesName } from "../../../common/interfaces/editing/pie/ELPIEDocRendererTypes";
import { ELPIEDocPayload } from "../../../common/interfaces/editing/pie/ELPIEDocTypes";
import { DEFAULT_STAGE_BACKGROUND, RenderedShapesName } from "../../../common/interfaces/renderer/RendererTypes";
import {
    CanvasChangedPayload,
    ELDownloadData,
    ELImageData,
    ELStageBackgroundMode,
    ELStageBackgroundOptions,
    ELStageObjectData,
    ELStageObjectOptions,
    ELStageShapeAndObjectOptions,
    ELStageShapesType
} from "../../../common/interfaces/stage/StageTypes";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import ImageUtils from "../../../utils/ImageUtils";
import DocumentFactory, { DocumentFactoryPayload } from "../../document/DocumentFactory";
import IDoc, { DocumentType } from "../../document/IDoc";
import ILayer from "../../document/layer/ILayer";
import IDocDataParser from "../../document/parser/IDocDataParser";
import { EditingEngineManager } from "../../editingEngines/EditingEngineManager";
import ShapesFactory from "../../stage/shapes/ShapesFactory";
import ELPIEDoc from "../models/ELPIEDoc";
import { PSD_NAME_INTL_KEY } from "../utils/PIEUtils";

export default class ELPIEDocDataParser extends IDocDataParser {

    private async _createImageArea(): Promise<ELStageShapeAndObjectOptions> {
        const shape = ShapesFactory.createShape(ELStageShapesType.imageArea);
        const { width, height } = await this.doc.getSize();

        const backgroundOptions: ELStageBackgroundOptions = {
            name: RenderedShapesName.imageArea,
            width: width,
            height: height,
            background: { mode: ELStageBackgroundMode.color, value: DEFAULT_STAGE_BACKGROUND },
        };
        const stageAndObjectOptions: ELStageShapeAndObjectOptions = { shape: shape, objectOptions: backgroundOptions };

        return stageAndObjectOptions;
    }

    async _createLayerData(): Promise<ELStageShapeAndObjectOptions[]> {
        const pieDoc = this.doc as ELPIEDoc;
        const pieLayers = await pieDoc.getLayers();

        const stageAndObjectOptionsList: ELStageShapeAndObjectOptions[] = [];

        for (const layer of pieLayers) {
            stageAndObjectOptionsList.push(await this.getStageData(layer));
        }

        return stageAndObjectOptionsList;
    }

    async getStageData<T>(data: T): Promise<ELStageShapeAndObjectOptions> {
        const layer = data as unknown as ILayer;
        const shape = ShapesFactory.createShape(ELStageShapesType.imageFromURI);
        const { width, height } = await layer.getSize();
        const { top, left } = await layer.getBounds();
        const shapeData: ELStageObjectData = { payload: layer.getId(), transformedToImageArea: true, allowDrop: true };

        const objectOptions: ELStageObjectOptions = {
            name: ELPIEDocRenderedShapesName.layer,
            data: shapeData,
            image: await layer.getRendtionURL(),
            width: width,
            height: height,
            left: left,
            top: top,
            scaleX: 1,
            scaleY: 1,
            selectable: true
        };

        const stageAndObjectOptions: ELStageShapeAndObjectOptions = {
            shape: shape,
            objectOptions: objectOptions,
        };

        return stageAndObjectOptions;
    }

    async parseDocData(): Promise<ELStageShapeAndObjectOptions[]> {
        const stageAndObjectsOptions: ELStageShapeAndObjectOptions[] = [];
        stageAndObjectsOptions.push(await this._createImageArea());
        stageAndObjectsOptions.push(...(await this._createLayerData()));
        return stageAndObjectsOptions;
    }

    async parseDocUpdatedData(data: unknown): Promise<unknown> {
        const objectList = (data as CanvasChangedPayload).allObjects;
        let doc: IDoc | undefined = undefined;

        for (const object of objectList) {
            if (object.name === ELPIEDocRenderedShapesName.layer) {
                const canvas = object.toCanvasElement();
                const getBlob = async (): Promise<Blob> => {
                    return new Promise((resolve, reject) => {
                        canvas.toBlob((blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject("unable to create blob!");
                            }
                        });
                    });
                }

                const buffer = await ImageUtils.readArrayBufferFromBlob(await getBlob());

                const pieEditingEngine = await EditingEngineManager.getEditingEngine(EditingEngineType.pie);
                await pieEditingEngine.ready();

                if (!doc) {
                    const docPayload: ELPIEDocPayload = { filename: "temp doc", buffer: new Uint8Array(buffer), engine: pieEditingEngine };
                    const pieDocPayload: DocumentFactoryPayload = {
                        docPayload: docPayload
                    };
                    doc = await DocumentFactory.createDocument(DocumentType.pie, this.doc.getOwner, pieDocPayload);
                } else {
                    const layerData: ELStageLayerData = {
                        data: buffer,
                        redraw: false,
                        layerKind: ELLayerKind.pixel
                    };
                    await doc.addLayer(layerData);
                }
            }
        }

        const imageData: ELImageData = { format: DocumentFormat.PSD };
        const defaultPSDName = IntlHandler.getInstance().formatMessage(PSD_NAME_INTL_KEY);
        const downloadData: ELDownloadData = { name: defaultPSDName + ".psd", imageData: imageData };
        doc?.download(downloadData);

        return;
    }
}
