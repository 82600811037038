/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export class HistoryUtils {
    /**
     * @param  {url} string or URL to be pushed to history
     * @returns void
     */
    static pushHistory(url: string | URL | undefined | null): void {
        window.history.pushState("", "", url);
    }

    /**
     * @param  {url} string or URL to be pushed to history
     * @returns void
     */
    static replaceHistory(url: string | URL | undefined | null): void {
        window.history.replaceState("", "", url);
    }
}