/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, ReactNode } from "react";

// Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { lightTheme, View, Provider as ELReactSpectrumV3Provider } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import ELIconButton from "../../molecules/el-icon-button/ELIconButtonView";
import Utils from "../../../../utils/Utils";
import { CreationsJobProjectSubType, CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import { ViewportProvider, useViewport } from "../../../../utils/hooks/useViewport";
import ELPopover from "../../molecules/el-popover/ELPopoverView";
import { ShareOptionsView } from "../../organism/el-share-options/ELShareOptionsView";
import {
    ELCreationsHeaderControllerAction,
    ELCreationsHeaderViewProps,
} from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { BackButton } from "../../organism/el-creations-header/ELCreationsHeaderComponents";
import { LocaleUtils, AppCode } from "../../../../utils/LocaleUtils";

import "./ELCreationPreviewHeaderView.scss";


const ELCreationPreviewHeaderView = (props: ELCreationsHeaderViewProps): React.ReactElement => {

    const { isMobile } = useViewport();
    const intlHandler = IntlHandler.getInstance();
    const creationsStatus = props.data?.status;

    useEffect(() => {
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const isCreationReady = (): boolean => {
        return creationsStatus === CreationsStatus.success;
    }

    const isCreationInProgress = (): boolean => {
        return creationsStatus === CreationsStatus.requested ||
            creationsStatus === CreationsStatus.editing;
    }

    const getHeaderClass = (): string => {
        let headerClass = "creation-preview-header__text-box";
        if (isCreationInProgress())
            headerClass = " disabled";
        return headerClass;
    }

    const onShare = (): void => {
        props.controller.notify({ type: ELCreationsHeaderControllerAction.sharePopoverClicked });
    }

    const showEditButton = (): boolean => {
        return !(props.data?.operationSubType === CreationsJobProjectSubType.replaceBackground)
    }

    const getCreationPreviewHeaderContent = (): ReactNode => {
        return (
            <Flex isHidden={!isCreationReady()} UNSAFE_className="creation-preview-header__cta-box">
                {showEditButton() && (
                    <ELIconButton size="L" iconkey={IconType.edit} variant="cta"
                        onClick={() => props.controller.notify({ type: ELCreationsHeaderControllerAction.edit })}
                        iconWidth={"1.275rem"} iconHeight={"1.275rem"}>
                        <span className="workflow-header__cta-text-edit">{intlHandler.formatMessage("edit-project")}</span>
                    </ELIconButton>
                )}
                <ELIconButton size="L" iconkey={IconType.download} iconColor={Theme.dark.gray_controls_color_N} variant="secondary"
                    onClick={() => props.controller.notify({ type: ELCreationsHeaderControllerAction.download })}
                    iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.375rem"} iconHeight={"1.375rem"}>
                    <span className="workflow-header__cta-text">{intlHandler.formatMessage("download")}</span>
                </ELIconButton>

                <ELPopover type={Utils.getSpectrumPopoverType((isMobile as boolean))} closeOnPress>
                    <ELIconButton size="L" iconkey={IconType.share} iconColor={Theme.dark.gray_controls_color_N} variant="secondary"
                        iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.375rem"} iconHeight={"1.375rem"}
                        onClick={() => onShare()}>
                        <span className="workflow-header__cta-text">{intlHandler.formatMessage("share")}</span>
                    </ELIconButton>
                    <ShareOptionsView controller={props.shareOptionController} onPress={() => {
                        props.controller.notify({ type: ELCreationsHeaderControllerAction.share });
                    }} showQRView={true} />
                </ELPopover>
            </Flex>
        )

    }

    return (
        <ELReactSpectrumV3Provider theme={lightTheme} colorScheme={"light"} locale={LocaleUtils.getAppLocale(AppCode.reactSpectrum)}>
            <ViewportProvider>
                <Flex UNSAFE_className="creation-preview-header" gap="0.8rem" direction="row" alignItems="center">
                    <BackButton notify={props.controller.notify.bind(props.controller)} />
                    <Flex UNSAFE_className={getHeaderClass()} direction="row" gap="size-250" alignItems="center">
                        <View UNSAFE_className="creation-preview-header__edit-box">
                            <span className={`creation-preview-header__title-text`}
                                title={props.data?.title}>
                                {props.data?.title}
                            </span>
                        </View>
                    </Flex>
                    <Flex direction="row" width={"20%"}><span className={"workflow-header__preview-tag"}>{intlHandler.formatMessage("project-preview")}</span></Flex>
                    {getCreationPreviewHeaderContent()}
                </Flex >
            </ViewportProvider>
        </ELReactSpectrumV3Provider>
    );
}
export default ELCreationPreviewHeaderView;



