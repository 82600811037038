/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELLinkType } from "../../../common/interfaces/link/ELLinkTypes";
import { ELStageObjectToJsonConverterByTextLayer } from "./ELStageObjectToJsonConverterByTextLayer";
import IStageObjectToJsonConverter from "./IStageObjectToJsonConverter";

export class ELStageObjectToJsonConverterFactory {
    static createConverter(type: ELLinkType): IStageObjectToJsonConverter {
        switch (type) {
            case ELLinkType.textLayer:
                return new ELStageObjectToJsonConverterByTextLayer();
            default:
                throw new Error(`Unsupported link type: ${type}`);
        }
    }
}