/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

// Adobe internal
import { Button, Content, Dialog, DialogContainer, Divider, Flex, Heading } from "@adobe/react-spectrum";

//Application specific
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import ELButton from "../../../view/components/atoms/el-button/ELButtonView";

import "./ReplaceMediaDialogView.scss";


export interface ELReplaceMediaDialogProps {
    onDismiss: () => void,
    onSaveAs: () => void,
    onDontSave: () => void
}

export const ReplaceMediaDialogView = (props: ELReplaceMediaDialogProps): React.ReactElement => {

    const intlHandler = IntlHandler.getInstance();

    return (
        <DialogContainer onDismiss={props.onDismiss} isDismissable>
            <Dialog UNSAFE_className="replace-media-dialog-container">
                <Heading>
                    <div className="replace-media-dialog-heading">{intlHandler.formatMessage("unsaved-changes")}</div>
                </Heading>
                <Content>
                    <Divider size="S" margin={"4px 0px 4px 0px"} />
                    <Flex gap="size-500" direction="column" alignItems={"center"}>
                        <Flex marginTop="size-200" justifyContent={"center"}>
                            <div className="replace-media-dialog-description" >
                                {intlHandler.formatMessage("replace-media-dialog-description")}
                            </div>
                        </Flex>
                        <Flex gap="size-100" alignSelf="end">
                            <Button margin={"0px 4px 0px 4px"} variant="secondary" onPress={props.onDontSave}>
                                <div className="replace-media-dialog-save">
                                    {intlHandler.formatMessage("dont-save")}
                                </div>
                            </Button>
                            <ELButton size="S" onClick={props.onSaveAs}>
                                <div className="replace-media-dialog-save">
                                    {intlHandler.formatMessage("save-a-copy")}
                                </div>
                            </ELButton>
                        </Flex>
                    </Flex>
                </Content>
            </Dialog>
        </DialogContainer>
    )
}