/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//Application Specific
import ELPanelManager, { ELPanelManagerConfig } from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import IPanelProvider from "../../../common/IPanelProvider";
import { ELTabPanelKey, ELTabPanelType, ITabPanelItem } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { ELSingleImageMediaManager } from "../../../../view/components/templates/el-single-image-media-manager/ELSingleImageMediaManager";
import ELLayoutPanel from "../../../../view/components/templates/el-social-layout/ELLayoutPanel";
import ELPeekThroughOverlay from "../../../../view/components/templates/el-peekThrough-overlay/ELPeekThroughOverlay";
import ELPeekThroughPanelItemProvider from "../peekThrough/ELPeekThroughPanelItemProvider";

export default class ELPeekThroughPanelProvider extends IPanelProvider {
    private _getRightTabPanel(): ELPanelManager {
        const peekThroughMedia = new ELSingleImageMediaManager(this.owner);
        const peekThroughOverlay = new ELPeekThroughOverlay(this.owner);
        const peekThroughLayout = new ELLayoutPanel(this.owner);

        const listOfTabItemsUIInfo = ELPeekThroughPanelItemProvider();
        const listOfTabItems: ITabPanelItem[] = [];
        listOfTabItemsUIInfo.forEach((buttonItem) => {
            switch (buttonItem.key) {
                case ELTabPanelKey.media: {
                    listOfTabItems.push({
                        ...buttonItem,
                        controller: peekThroughMedia,
                        view: peekThroughMedia.getView("peekThroughReducer")
                    });
                    break;
                }
                case ELTabPanelKey.overlays: {
                    listOfTabItems.push({
                        ...buttonItem,
                        controller: peekThroughOverlay,
                        view: peekThroughOverlay.getView()
                    });
                    break;
                }
                case ELTabPanelKey.layout: {
                    listOfTabItems.push({
                        ...buttonItem,
                        controller: peekThroughLayout,
                        view: peekThroughLayout.getView()
                    });
                    break;
                }
            }
        })

        const config: ELPanelManagerConfig = {
            selectedKey: ELTabPanelKey.overlays,
            panelItemDirection: "row-reverse"
        };

        return new ELPanelManager(this.owner, listOfTabItems, config);
    }

    getTabPanel(panelType: ELTabPanelType): ELPanelManager {
        if (panelType === ELTabPanelType.rightTabPanel) {
            return this._getRightTabPanel();
        } else {
            throw new Error("ELPeekThroughPanelProvider::getTabPanel panelType not valid");
        }
    }
}
