/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application Specific
import IWorkflow from "../../../../workspaces/IWorkflow";
import ITemplateViewController from "../../../ITemplateViewController";
import { ELTabPanelView } from "../../organism/el-tab-panel/ELTabPanelView";
import store from "../../../../stores/store";
import { ELFlexDirection, ELTabPanelKey, ELTabPanelViewAction, ITabPanelItem } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

export interface ELPanelManagerConfig {
    selectedKey: ELTabPanelKey,
    panelItemDirection: ELFlexDirection
}

const defaultPanelManagerConfig: ELPanelManagerConfig = {
    selectedKey: ELTabPanelKey.empty,
    panelItemDirection: "row-reverse"
}

class ELPanelManager extends ITemplateViewController {
    private _listOfTabItems: ITabPanelItem[];
    private _config: ELPanelManagerConfig;

    constructor(owner: IWorkflow, listOfTabItems: ITabPanelItem[], config?: ELPanelManagerConfig) {
        super(owner);
        this._listOfTabItems = listOfTabItems;
        this._config = config ?? defaultPanelManagerConfig;
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const element = React.createElement(ELTabPanelView, {
            controller: this,
            listOfTabItems: this._listOfTabItems,
            selectedKey: this._config.selectedKey,
            panelItemDirection: this._config.panelItemDirection
        });

        /*eslint-disable */
        const spectrumProvider = React.createElement(ELReactSpectrumV3Provider, {
            children: '',
            height: "100%"
        }, element);
        /*eslint-enable */

        const reduxWrappedSpectrumProvider = React.createElement(ReactReduxProvider, { store: store }, spectrumProvider);
        ReactDOM.render(reduxWrappedSpectrumProvider, container)
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this.viewDispatcher?.({ type: ELTabPanelViewAction.selectedKey, payload: this._config.selectedKey });
    }

    updateSelectedTabKey(selectedKey: ELTabPanelKey): void {
        this.viewDispatcher?.({ type: ELTabPanelViewAction.selectedKey, payload: selectedKey });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        for (let item = 0; item < this._listOfTabItems.length; item++) {
            handled = await this._listOfTabItems[item].controller.notify(action);
            if (handled) {
                break;
            }
        }
        return handled;
    }

    reset(): void {
        this._listOfTabItems.forEach((item) => {
            item.controller.reset();
        });
    }
}
export default ELPanelManager;