/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";
//Internal
import {
    AssetPath, CreationOperationTypeInfo, CreationOperationTypeOutput,
    ELAdobeAsset, OutputType, PatternOverlayCreationOperationSubTypeInfo,
    PatternOverlayJobUtils, TemplateId, Request as ELServiceRequest
} from "@elements/elementswebcommon";
//Application Specific
import { GRID_CONFIG_KEY } from "../../../../../stores/reducers/mediaGridConfigReducer";
import { MediaGridConfig, MediaGridSortBy } from "../../../../../stores/actions/mediaGridConfigActions";
import store from "../../../../../stores/store";
import { GIF_FILE_FORMAT, PSDC_FILE_FORMAT, PSD_FILE_FORMATS } from "../../../../../common/interfaces/storage/FileTypes";
import { FilterMediaUiProp } from "../../../../../stores/actions/mediaGridConfigActions";
import { FilterMediaData } from "../../../../../stores/actions/mediaGridConfigActions";
import { GridMediaType } from "../../../../../stores/actions/mediaGridConfigActions";
import { WorkspaceAction, WorkspacePayload } from "../../../../IBaseWorkspace";
import { AssetStorageUtils } from "../../../../../utils/AssetStorageUtils";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { Routes } from "../../../../../app/AppRoute";
import { CreationsMode, CreationsStatusPayload, CreationsURLParams } from "../../../../../common/interfaces/creations/CreationTypes";
import { WorkflowsName } from "../../../../IWorkflow";
import Utils from "../../../../../utils/Utils";
import CreationUtils from "../../../utils/CreationUtils";
import { ELLayoutInfo } from "../../../../../common/interfaces/creations/ELSocialLayoutTypes";
import { ELSize } from "../../../../../common/interfaces/geometry/ELGeometry";

export default class PatternOverlayUtils {

    static getPatternOverlayMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);
        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageOnly;
        const filterData: FilterMediaData = {
            format: [PSDC_FILE_FORMAT, GIF_FILE_FORMAT, ...PSD_FILE_FORMATS],
            uiProp: FilterMediaUiProp.hide
        }
        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 1;
        mediaGridConfig.maxImageCount = 1;
        mediaGridConfig.minImageCount = 1;
        mediaGridConfig.maxVideoCount = 0;
        mediaGridConfig.maxTotalMediaSize = 400; //in MB;
        mediaGridConfig.maxVideoLength = 0;
        mediaGridConfig.selectUploadedMedia = true;
        return mediaGridConfig;
    }

    /**
     * @returns boolean - true if patternOverlay url is present
     */
    static hasPatternOverlayUrl(): boolean {
        const workflow = Utils.getLinkParamValue(window.location.href, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.patternOverlay.toLowerCase() === workflow.toLowerCase());
    }

    static isOpeningExistingPatternOverlay(workspaceAction: WorkspaceAction): boolean {
        return workspaceAction.initMode === CreationsMode.open;
    }

    /**
     * @returns string - patternOverlay id from window href
     */
    static getPatternOverlayIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    static getPatternOverlayPayload(): WorkspacePayload {
        const projectId = PatternOverlayUtils.getPatternOverlayIdFromHref();
        let mode = CreationsMode.open;
        let payload: CreationsStatusPayload | ELAdobeAsset[] | null = {
            projectId: projectId
        };

        if (projectId === Utils.getNilUUID()) {
            mode = CreationsMode.create;
            payload = store.getState().selectedMediaListReducer;
        }

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.patternOverlay,
            initMode: mode,
            payload: payload
        }

        return workspacePayload;
    }

    /**
     * @returns string - history state based on projectId
     */
    static getPatternOverlayHistoryState(projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + WorkflowsName.patternOverlay.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    static canStartPatternOverlay(selectedAssets: ELAdobeAsset[]): boolean {
        const minImageCount = PatternOverlayUtils.getPatternOverlayMediaGridConfig().minImageCount;
        if (minImageCount && selectedAssets.length >= minImageCount)
            return true;
        return false;
    }

    static isMaxImageLimitExceeded(selectedAssets: ELAdobeAsset[]): boolean {
        const maxImageCount = PatternOverlayUtils.getPatternOverlayMediaGridConfig().maxImageCount;
        if ((maxImageCount !== undefined) && selectedAssets.length > maxImageCount)
            return true;
        return false;
    }

    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = AssetStorageUtils.filterRemoveVideoAssets(assets);
        filteredAssets = AssetStorageUtils.filterRemoveGifAssets(filteredAssets);
        filteredAssets = CreationUtils.filterImagesByCount(filteredAssets, mediaGridConfig);

        Logger.log(LogLevel.INFO, "PatternOverlay - getFilteredAssetList: ", filteredAssets);
        return filteredAssets;
    }

    static async getRecommendedAssetsIdForOverlays(outputJsonData: unknown): Promise<Map<TemplateId, AssetPath>> {
        try {
            const requestObj = new ELServiceRequest();
            await requestObj.serializeInWithContext(outputJsonData);
            const operation = requestObj.operations[0];
            const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
            const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as PatternOverlayCreationOperationSubTypeInfo;
            const output = operationTypeInfo.output as CreationOperationTypeOutput;
            const mapOfTemplateIdToAssetPath = PatternOverlayJobUtils.getMappedTemplateIdsToAssetPath(operationSubTypeInfo, output, OutputType.preview);
            for (const [overlayId, assetPath] of mapOfTemplateIdToAssetPath) {
                const assetID = await AssetStorageUtils.getAssetIdForAssetPath(assetPath);
                if (assetID !== undefined) {
                    mapOfTemplateIdToAssetPath.set(overlayId, assetID);
                }
            }
            return mapOfTemplateIdToAssetPath;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlayUtils:_getRecommendedAssetsIdForOverlays, failed to process OutputResultConfig.json");
            return Promise.reject();
        }
    }

    static async getLayoutDetails(outputJsonData: unknown, imageSize: ELSize): Promise<ELLayoutInfo | undefined> {
        try {
            const requestObj = await CreationUtils.getSerializedRequestObject(outputJsonData);
            const operation = requestObj.operations[0];
            const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
            const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as PatternOverlayCreationOperationSubTypeInfo;
            const projectSettings = operationSubTypeInfo.compositeSettings;
            const cropDetails = operationSubTypeInfo.configurations[0].compositePostEdit?.crop;

            if (projectSettings && cropDetails) {
                const layoutInfo: ELLayoutInfo = {
                    left: cropDetails.leftBound,
                    right: cropDetails.rightBound,
                    top: cropDetails.topBound,
                    bottom: cropDetails.bottomBound,
                    scaleX: projectSettings.width / imageSize.width,
                    scaleY: projectSettings.height / imageSize.height,
                    layoutId: projectSettings.layoutId ?? ""
                };
                return Promise.resolve(layoutInfo);
            }
            return Promise.resolve(undefined);
        } catch {
            Logger.log(LogLevel.ERROR, "PatternOverlayUtils:getLayoutDetails, failed to create layout info");
            return Promise.reject();
        }
    }

    static async getSelectedOverlay(outputConfigJson: unknown): Promise<string> {
        const mapOfOverlayIdAssetPath = await PatternOverlayUtils.getRecommendedAssetsIdForOverlays(outputConfigJson);
        return [...mapOfOverlayIdAssetPath][0][0];
    }
}