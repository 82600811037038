/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react"

//Adobe internal
import {
    DialogContainer, Dialog, Heading, Divider, Content, Flex, Text,
} from "@adobe/react-spectrum";
import Link from "@react/react-spectrum/Link";

//Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../atoms/el-button/ELButtonView";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELOpenInDesktopDialogContainer.scss";

interface ELOpenInDesktopDialogContainerProps {
    dataTestId: string,
    onPressReset: () => void,
    onLearnMoreClick: () => void,
    learnMoreLink: string
}

const ELOpenInDesktopDialogContainer = (props: ELOpenInDesktopDialogContainerProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    return (
        <div data-testid={props.dataTestId} >
            <ELReactSpectrumV3Provider>
                <DialogContainer onDismiss={() => props.onPressReset()} isDismissable>
                    <Dialog UNSAFE_className={"open-in-desktop-dialog"} >
                        <Heading>
                            <div className="open-in-desktop-dialog__h1">{intlHandler.formatMessage("not-found")}</div>
                        </Heading>
                        <Divider size={"L"} />
                        <Content>
                            <Flex direction="column" alignItems="start" gap={"size-50"}>
                                <p className="open-in-desktop-dialog__text" >
                                    {intlHandler.formatMessage("desktop-app-not-found")}
                                </p>
                                <Link onClick={props.onLearnMoreClick} href={props.learnMoreLink} target="_blank" rel="noopener noreferrer">
                                    {intlHandler.formatMessage("learn-more")}
                                </Link>
                                <div className="open-in-desktop-dialog__ok-button">
                                    <ELButton size="L" onClick={() => props.onPressReset()} >
                                        <Text UNSAFE_className="openInDeskto-dialog__cta-text">{intlHandler.formatMessage("ok")}</Text>
                                    </ELButton>
                                </div>
                            </Flex>
                        </Content>
                    </Dialog>
                </DialogContainer>
            </ELReactSpectrumV3Provider>
        </div>
    )
}

export default ELOpenInDesktopDialogContainer;
