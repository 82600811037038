/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { ReactElement, useRef } from "react";
import { Flex } from "@adobe/react-spectrum";
import { useButton } from "@react-aria/button";
import { PressEvent, FlexStyleProps } from "@react-types/shared";

import "./ELOption.scss";

export interface ELOptionProps extends FlexStyleProps {
    children: ReactElement | ReactElement[],
    onPress?: (e: PressEvent) => void
}

/** 
*   This function creates an option component which creates a boxshadow on hover. Props can be passed to handle click event.
*   The contents of button can be styled accordingly passing flex style props
*/
export const ELOption = (props: ELOptionProps): React.ReactElement => {
    const ref = useRef(null);
    const { buttonProps } = useButton({
        onPress: props.onPress,
    }, ref);

    return (
        <button className="el-option" ref={ref} {...buttonProps} data-testid="el-option">
            <Flex {...props}>
                {props.children}
            </Flex>
        </button>
    )
}

