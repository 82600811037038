/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from "react";

import { SpectrumProgressCircleProps } from "@react-types/progress";
import { Flex, ProgressCircle } from "@adobe/react-spectrum";

import "./ELProgressCircle.scss";

interface ELProgressCircleProps extends SpectrumProgressCircleProps {
    label?: string
}

export const ELProgressCircle = (props: ELProgressCircleProps): React.ReactElement => {
    const { label, ...progressCircleProps } = props;
    return (
        <Flex direction="column" alignItems="center" justifyContent="center" UNSAFE_className="media-processing" gap={"1rem"}>
            <ProgressCircle {...progressCircleProps} />
            {label && <span className="media-processing__text">{label}</span>}
        </Flex>);
}