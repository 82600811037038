/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { DocumentDirty, DocumentSaveStatus } from "../../common/interfaces/document/DocumentTypes";
import IDoc from "../../editors/document/IDoc";
import IAction from "./IAction";

export interface DocActionsPayload {
    zoomLevel: number,
    isDirty: DocumentDirty,
    hasError: boolean,
    saveStatus: DocumentSaveStatus,
    activeDoc?: IDoc
}

export enum DocActionTypes {
	changeZoomLevel = "CHANGE_ZOOM_LEVEL",
    updateDocumentDirty = "UPDATE_DOCUMENT_DIRTY",
    updateDocumentError = "UPDATE_DOCUMENT_ERROR",
    updateDocumentSaveStatus = "UPDATE_DOCUMENT_SAVE_STATUS",
    updateActiveDocument = "UPDATE_ACTIVE_DOC"
}

export default class DocActions {
    public static updateZoomLevel(zoomLevel: number): IAction<number> {
        return {
            type: DocActionTypes.changeZoomLevel,
            payload: zoomLevel
        };
    }

    public static updateDocumentDirty(documentDirty: DocumentDirty): IAction<DocumentDirty> {
        return {
            type: DocActionTypes.updateDocumentDirty,
            payload: documentDirty
        };
    }

    public static updateDocumentError(hasError: boolean): IAction<boolean> {
        return {
            type: DocActionTypes.updateDocumentError,
            payload: hasError
        };
    }

    public static updateDocumentSaveStatus(documentSaveStatus: DocumentSaveStatus): IAction<DocumentSaveStatus> {
        return {
            type: DocActionTypes.updateDocumentSaveStatus,
            payload: documentSaveStatus
        };
    }

    public static updateActiveDocument(activeDoc: IDoc): IAction<IDoc> {
        return {
            type: DocActionTypes.updateActiveDocument,
            payload: activeDoc
        };
    }
}