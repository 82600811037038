/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import { ControllerAction } from "../../../IViewController";
import { CreationsData } from "../../../../common/interfaces/creations/CreationTypes";
import { WorkspaceActionType } from "../../../../workspaces/IBaseWorkspace";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import ICreationsHeader from "./ICreationsHeader";
import IWorkflow, { WorkflowAction } from "../../../../workspaces/IWorkflow";
import ELCreationPreviewHeaderView from "./ELCreationPreviewHeaderView";
import { ShareOptions } from "../../organism/el-share-options/ELShareOptions";
import Logger, { LogLevel } from "../../../../utils/Logger";

class ELCreationsPreviewHeader extends ICreationsHeader {

    constructor(owner: IWorkflow, shareOptions: ShareOptions, creationsData: CreationsData) {
        super(owner, shareOptions);
        this.creationData = creationsData;
    }

    createView(container: HTMLElement): Promise<void> {
        super.createView(container);

        const element = React.createElement(ELCreationPreviewHeaderView, {
            controller: this,
            shareOptionController: this.shareOptions,
            data: this.creationData
        });

        const provider = this.getReactProvider(element, { history: true, store: true });

        return new Promise((resolve) => {
            ReactDOM.render(
                provider,
                container,
                () => { resolve(); });
        });
    }



    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {

            case ELCreationsHeaderControllerAction.back:
                {
                    this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                        IngestEventTypes.click, IngestEventSubTypes.back, IngestWorkflowTypes.preview));
                    handled = await this._owner.notify({
                        type: WorkspaceActionType.startPreviousWorkflow,
                    });
                    break;
                }

            case ELCreationsHeaderControllerAction.edit:
                {
                    await this._owner.notify({ type: ELCreationsHeaderControllerAction.edit, payload: action.payload });
                    handled = true;
                    break;
                }

            default:
                Logger.log(LogLevel.DEBUG, "ELCreationsPreviewHeader(ControllerAction): Invalid action" + action);
                break;
        }

        if (!handled)
            handled = await super.notify(action);

        if (!handled)
            handled = await this._owner.notify(action as WorkflowAction);

        return handled;
    }
}

export default ELCreationsPreviewHeader;
