/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Elements Specific
import { API } from "@elements/elementswebcommon";
import Utils from "../../utils/Utils";
import IMS from "./../IMS";
import { PSFeatureName, PSFeaturePayload, MimeType, Storage } from "../../common/interfaces/services/PhotoshopServiceTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import AperitifTokenService from "./AperitifTokenService";
import { AperitifData } from "../../common/interfaces/services/AperitifTokenServiceTypes";

const enum JobStatus {
    succeeded = "succeeded",
    failed = "failed",
    running = "running"
}

export class PhotoshopService {
    private static readonly _maskEndpoint = "sensei/mask";

    private static _getCommonHeaders(): Record<string, string> {
        if (!process.env.REACT_APP_IMS_API_KEY) {
            throw new Error("REACT_APP_IMS_API_KEY missing");
        }

        return {
            "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`,
            "x-api-key": process.env.REACT_APP_IMS_API_KEY,
            "Content-Type": "application/json"
        }
    }

    private static async _getJobStatus(url: string): Promise<Record<string, JobStatus>> {
        const client = new API("");
        const options = {
            headers: this._getCommonHeaders()
        };
        const result = await client.get(url, options);
        return result.data;
    }

    private static _isDone(status: any): boolean {
        if (status === JobStatus.succeeded || status === JobStatus.failed)
            return true;

        return false;
    }

    private static async _generateMask(featurePayload: PSFeaturePayload): Promise<string> {
        if (!process.env.REACT_APP_PHOTOSHOP_API_ENDPOINT) {
            return Promise.reject(new Error('REACT_APP_PHOTOSHOP_API_ENDPOINT not set'));
        }
        try {
            const client = new API(process.env.REACT_APP_PHOTOSHOP_API_ENDPOINT);

            const aperitifData: AperitifData = await AperitifTokenService.getInstance().getAperitifData();

            const outputPath = aperitifData.outputs.urls[0].put;
            const options = {
                headers: this._getCommonHeaders()
            };
            const result = await client.post({
                input: {
                    href: featurePayload.inputPath,
                    storage: featurePayload.inputStorage ?? Storage.EXTERNAL
                }, output: {
                    href: outputPath,
                    storage: Storage.EXTERNAL,
                    type: MimeType.PNG
                }
            }, options, this._maskEndpoint);

            let status = JobStatus.running;
            while (!PhotoshopService._isDone(status)) {
                await Utils.wait(300);
                status = (await PhotoshopService._getJobStatus(result.data._links.self.href)).status;
            }
            return Promise.resolve(aperitifData.outputs.urls[0].get);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async applyEdit(featurePayload: PSFeaturePayload): Promise<string> {
        switch (featurePayload.featureName) {
            case PSFeatureName.generateMask:
                return this._generateMask(featurePayload);
            default: {
                const errorText = "No valid feature name found: " + featurePayload.featureName;
                Logger.log(LogLevel.DEBUG, errorText);
                return Promise.reject(errorText);
            }
        }
    }

}