/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ELTextAlign } from "../../common/interfaces/stage/StageTypes";
import IAction from "../actions/IAction";
import TextEditAction, { TextEditActionPayload } from "../actions/TextEditAction";

interface TextEditData {
    postscriptName: string,
    fontSize: number,
    strokeWidth: number,
    strokeColor: string,
    shadowBlur: number,
    underline: boolean,
    textAlign: ELTextAlign
}

const updatePostscriptName = (state: TextEditData, action: IAction<TextEditActionPayload>): TextEditData => {
    if (action.payload) {
        return {
            ...state,
            postscriptName: action.payload as string
        }
    }
    return state;
}

const updateFontSize = (state: TextEditData, action: IAction<TextEditActionPayload>): TextEditData => {
    if (action.payload) {
        return {
            ...state,
            fontSize: action.payload as number
        }
    }
    return state;
}

const updateStrokeWidth = (state: TextEditData, action: IAction<TextEditActionPayload>): TextEditData => {
    return {
        ...state,
        strokeWidth: action.payload as number
    }
}

const updateStrokeColor = (state: TextEditData, action: IAction<TextEditActionPayload>): TextEditData => {
    if (action.payload) {
        return {
            ...state,
            strokeColor: action.payload as string
        }
    }
    return state;
}

const updateShadowBlur = (state: TextEditData, action: IAction<TextEditActionPayload>): TextEditData => {
    return {
        ...state,
        shadowBlur: action.payload as number
    }
}

const updateUnderline = (state: TextEditData, action: IAction<TextEditActionPayload>): TextEditData => {
    return {
        ...state,
        underline: action.payload as boolean
    }
}

const updateTextAlign = (state: TextEditData, action: IAction<TextEditActionPayload>): TextEditData => {
    return {
        ...state,
        textAlign: action.payload as ELTextAlign
    }
}

const textEditReducer = (state: TextEditData = { postscriptName: "", fontSize: 1, strokeWidth: 0, strokeColor: "ffffff", shadowBlur: 0, underline: false, textAlign: ELTextAlign.left }, action: IAction<any>): TextEditData => {
    switch (action.type) {
        case TextEditAction.UPDATE_POSTSCRIPT_NAME: {
            return updatePostscriptName(state, action);
        }
        case TextEditAction.UPDATE_FONT_SIZE: {
            return updateFontSize(state, action);
        }
        case TextEditAction.UPDATE_STROKE_WIDTH: {
            return updateStrokeWidth(state, action);
        }
        case TextEditAction.UPDATE_STROKE_COLOR: {
            return updateStrokeColor(state, action);
        }
        case TextEditAction.UPDATE_SHADOW_BLUR: {
            return updateShadowBlur(state, action);
        }
        case TextEditAction.UPDATE_UNDERLINE: {
            return updateUnderline(state, action);
        }
        case TextEditAction.UPDATE_TEXT_ALIGN: {
            return updateTextAlign(state, action);
        }
        default:
            return state;
    }
}

export default textEditReducer;