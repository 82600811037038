/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { ReactNode, useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { usePress } from "@react-aria/interactions";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";

//Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { RootState } from "../../../../stores/store";
import Utils from "../../../../utils/Utils";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import IViewController from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import { ELPeekThroughOverlayPanelControllerActions, ELPeekThroughOverlayPanelViewAction } from "../../../../common/interfaces/creations/templates/ELPeekThroughOverlayPanelTypes";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import { ELPeekThroughCornerData, ELPeekThroughOverlayData } from "../../../../common/interfaces/creations/ELPeekThroughTypes";
import Logger, { LogLevel } from "../../../../utils/Logger";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";

import "./ELPeekThroughOverlayPanel.scss"

export interface ELPeekThroughOverlayPanelProps {
    controller: IViewController
}

interface ELOverlayPanelState {
    overlayData: ELPeekThroughOverlayData[],
    selectedOverlayId: string
}

const initialState: ELOverlayPanelState = {
    overlayData: [],
    selectedOverlayId: ""
}

interface PeekThroughOverlayCommonProps {
    id: string,
    name: string,
    thumbnailUrl: string,
    contentUrl: string,
    width: number,
    height: number,
    isSelected: boolean
}

interface PeekThroughOverlayCardProps extends PeekThroughOverlayCommonProps {
    onPress: (id: string, contentUrl: string) => void
}

interface PeekThroughOverlayCornerCardProps extends PeekThroughOverlayCommonProps {
    defaultCorner: number,
    onPress: (id: string, contentUrl: string, defaultCorner: number) => void
}

const PeekThroughOverlayCornerCard = (props: PeekThroughOverlayCornerCardProps): React.ReactElement => {
    const { pressProps } = usePress({
        onPress: () => { props.onPress(props.id, props.contentUrl, props.defaultCorner); }
    })

    const isSelected = (): boolean => {
        return props.isSelected;
    }

    return (
        <div data-testid={"peekThrough-overlay-corner-card"} {...pressProps}
            className={"peekThrough-overlay-corner-card" + (isSelected() ? " selected" : "")}>
            <img crossOrigin="anonymous" className={"peekThrough-overlay-corner-image" + (isSelected() ? " selected" : "")}
                src={props.thumbnailUrl} alt={props.name} style={{ width: (props.width), height: (props.height) }} />
        </div>
    )
}

const PeekThroughOverlayCard = (props: PeekThroughOverlayCardProps): React.ReactElement => {
    const { pressProps } = usePress({
        onPress: () => { props.onPress(props.id, props.contentUrl); }
    })

    const isSelected = (): boolean => {
        return props.isSelected;
    }

    return (
        <>
            <div>
                <div data-testid="peekThrough-overlay-card" {...pressProps}
                    className={"peekThrough-overlay-card" + (isSelected() ? " selected" : "")}>
                    <img crossOrigin="anonymous" className={"peekThrough-overlay-image" + (isSelected() ? " selected" : "")}
                        src={props.thumbnailUrl} alt={props.name} style={{ width: props.width, height: props.height }} />
                </div>
            </div>
        </>
    )
}

const ELPeekThroughOverlayView = (props: ELPeekThroughOverlayPanelProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const peekThroughStoreData = useSelector((state: RootState) => state.peekThroughReducer);
    const renderedFactor = Utils.getRenderedFactor();
    const specLayoutConfig = {
        TILE_W: 5 * renderedFactor,
        TILE_H: 5 * renderedFactor,
        DIST_BETWEEN_TILE: 1 * renderedFactor
    };
    const gridRef = useRef(null);
    const scrollParentRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: scrollParentRef,
        gridRef: gridRef
    });

    const [selectedParentOverlayId, setSelectedParentOverlayId] = useState("");

    const reducer = (state: ELOverlayPanelState, action: ViewAction): ELOverlayPanelState => {
        switch (action.type) {
            case ELPeekThroughOverlayPanelViewAction.overlayData: {
                return {
                    ...state,
                    overlayData: action.payload as ELPeekThroughOverlayData[]
                };
            }
            case ELPeekThroughOverlayPanelViewAction.setOverlayId: {
                return {
                    ...state,
                    selectedOverlayId: action.payload as string
                };
            }
            default:
                return state;
        }
    }
    const [state, viewDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        viewDispatch({ type: ELPeekThroughOverlayPanelViewAction.setOverlayId, payload: peekThroughStoreData.overlayData });
    }, [peekThroughStoreData.overlayData])

    const handleOverlayChange = (id: string, contentUrl: string, defaultCorner: number): void => {
        Logger.log(LogLevel.INFO, "Overlay Content Link: ", id, contentUrl);
        const overlayUrl = contentUrl;
        const overlayAssetData: ELPeekThroughCornerData = {
            commonData: {
                id: id, contentUrl: overlayUrl, name: "", previewUrl: ""
            },
            defaultCorner: defaultCorner
        };
        props.controller.notify({ type: ELPeekThroughOverlayPanelControllerActions.overlayChanged, payload: overlayAssetData });
    }

    const handleParentOverlayChange = (id: string, contentUrl: string): void => {
        if (selectedParentOverlayId === id)
            setSelectedParentOverlayId("");
        else
            setSelectedParentOverlayId(id);
    }

    const isParentOverlaySelected = (id: string): boolean => {
        if (selectedParentOverlayId === id)
            return true;
        return false;
    }

    const chunkArray = (overlayList: any[], columns: number): any[][] => {
        const chunkedArray: string[][] = [];
        for (let i = 0; i < overlayList.length; i += columns) {
            chunkedArray.push(overlayList.slice(i, i + columns));
        }
        return chunkedArray;
    };

    const chunkSize = 3;
    const chunkedImages = chunkArray(state.overlayData, chunkSize);

    const onClickOverlay = (id: string, contentUrl: string, defaultCorner: number): void => {
        handleOverlayChange(id, contentUrl, defaultCorner);
    }

    const getOverlayCornerList = (cornerList: ELPeekThroughCornerData[]): ReactNode => {
        const cardlist: ReactNode[] = [];
        cornerList.forEach((item, index) =>
            cardlist.push(
                <PeekThroughOverlayCornerCard key={index} id={item.commonData.id} name={item.commonData.name}
                    thumbnailUrl={item.commonData.previewUrl} contentUrl={item.commonData.contentUrl} defaultCorner={item.defaultCorner}
                    onPress={(id, contentUrl, defaultCorner) => onClickOverlay(id, contentUrl, defaultCorner)}
                    width={0.65 * viewLayoutConfig.TILE_W} height={0.65 * viewLayoutConfig.TILE_H}
                    isSelected={state.selectedOverlayId === item.commonData.id} />)
        )
        return cardlist;
    }

    const getPeekThroughOverlayPanelContainer = (): ReactNode => {
        return (
            <Flex UNSAFE_className="peekThrough-overlay-panel-container" direction="column" gap="size-200">
                {chunkedImages.map((row, rowIndex) => (
                    <div key={"chunkedImages" + row + rowIndex}>
                        <Flex UNSAFE_className="peekThrough-overlay-panel-row" key={rowIndex}
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE} justifyContent="start">
                            {row.map((item, columnIndex) => (
                                <Flex UNSAFE_className="peekThrough-overlay-panel-item" key={columnIndex}>
                                    <PeekThroughOverlayCard key={`${rowIndex}-${columnIndex}`} id={item.commonData.id} name={item.commonData.name}
                                        thumbnailUrl={item.commonData.previewUrl} contentUrl={item.commonData.contentUrl}
                                        onPress={(id, contentUrl) => handleParentOverlayChange(id, contentUrl)}
                                        width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H}
                                        isSelected={selectedParentOverlayId === item.commonData.id} />
                                </Flex>
                            ))}
                        </Flex>
                        <div className="peekThrough-overlay-panel-corner-row-container">
                            {row.map((item, columnIndex) => (
                                <Flex isHidden={!isParentOverlaySelected(item.commonData.id)} gap={0.67 * viewLayoutConfig.DIST_BETWEEN_TILE}
                                    alignItems="center" UNSAFE_className={"peekThrough-overlay-panel-corner-row"}
                                    key={columnIndex} justifyContent="start" marginStart="size-100" wrap="wrap">
                                    {getOverlayCornerList(item.cornerlist)}
                                </Flex>
                            ))}
                        </div>
                    </div>
                ))}
            </Flex>)
    }

    return (
        <div className="peekThrough-overlay-panel-box">
            <Flex UNSAFE_className="peekThrough-overlay-header">
                <div>{intlHandler.formatMessage("overlays")}</div>
                <div className={"peekThrough-overlay-revert-box"} title={intlHandler.formatMessage("revert")}>
                    <ELCustomIcon variant="independent" iconkey={IconType.revert} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={() => props.controller.notify({ type: ELPeekThroughOverlayPanelControllerActions.revert })}>
                    </ELCustomIcon>
                </div>
            </Flex>
            <ELScrollPanel UNSAFE_className="peekThrough-overlay-scroll" scrollY={true} width="100%">
                <div className="peekThrough-overlay-panel-content-box">
                    {getPeekThroughOverlayPanelContainer()}
                </div>
            </ELScrollPanel>
        </div>
    )
}

export default ELPeekThroughOverlayView;