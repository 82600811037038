/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

//Adobe Internal
import { Flex, Grid, ProgressCircle, repeat, View } from "@adobe/react-spectrum";
import { Text } from "@react-spectrum/text";

//Application Specific
import { RootState } from "../../../../stores/store";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { CreationsData, CreationsJobProjectSubType, CreationsStatus, RenameCreationPayload } from "../../../../common/interfaces/creations/CreationTypes";
import { ELMenuState, ELMenuData, ELMenuKeys } from "../../../../common/interfaces/menu/ELMenuTypes";
import ELCreationThumb from "../../molecules/el-creation-thumb/ELCreationThumbView";
import ELRecentCreations from "./ELRecentCreations";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { useNetwork } from "../../../../utils/hooks/useNetwork";
import {
    ELRecentCreationsControllerActions,
    ELRecentCreationsStatus,
    ELRecentCreationsViewActions,
    ELRecentCreationsViewData,
    RecentCreationFilterType,
    RecentCreationsFilterMenu,
} from "../../../../common/interfaces/creations/ELRecentCreationsTypes";
import { ELEmptyCreationsBannerView } from "../../organism/el-empty-creations-banner/ELEmptyCreationsBannerView";
import ELButton from "../../atoms/el-button/ELButtonView";
import { ERROR_THUMBDATA } from "../../../../utils/Constants/Constants";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import { ELCreationProgressView } from "../../organism/el-creation-progress-view/ELCreationProgressView";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { ELRecentCreationFilterView, ELRecentCreationsSortOrderList } from "../../organism/el-creation-filter/ELRecentCreationsSortedView";
import { ViewAction } from "../../../IBaseController";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { ELCreateOnDemandUtils } from "../../../../workspaces/creations/utils/ELCreateOnDemandUtils";

import "./ELRecentCreations.scss";

export interface ELRecentCreationsProps {
    controller: ELRecentCreations
}

interface ELRecentCreationsState {
    data: CreationsData[],
    status: ELRecentCreationsStatus,
    creationStatus: CreationsStatus,
    progressText: string,
    dataFetched: boolean
}

const initialState: ELRecentCreationsState = {
    data: [],
    status: "error",
    creationStatus: CreationsStatus.error,
    progressText: "",
    dataFetched: false
}

const ELRecentCreationsView = (props: ELRecentCreationsProps): React.ReactElement => {
    const creationsStoreData = useSelector((state: RootState) => state.creationsReducer);
    const { width, height } = useViewport();

    const reducer = (state: ELRecentCreationsState, action: ViewAction): ELRecentCreationsState => {
        switch (action.type) {
            case ELRecentCreationsViewActions.creationsData: {
                const viewData = action.payload as ELRecentCreationsViewData;
                return {
                    ...state,
                    data: viewData.data,
                    status: viewData.status,
                    dataFetched: true
                };
            }
            case ELRecentCreationsViewActions.recentCreationStatus: {
                const viewData = action.payload as CreationsStatus;
                return {
                    ...state,
                    creationStatus: viewData
                };
            }
            case ELRecentCreationsViewActions.recentCreationProgressText: {
                const progressText = action.payload as string;
                return {
                    ...state,
                    progressText: progressText
                };
            }
            case ELRecentCreationsViewActions.refetchCreations: {
                return {
                    ...state,
                    dataFetched: false
                };
            }
            default:
                return state;
        }
    }

    const isOpenInDesktopFeatureSupported = (): boolean => {
        if (FeaturesManager.getInstance().IsFeatureActive(FeatureName.eOpenInDesktop)
            && !PlatformUtils.isAHandheldDevice((width as number), (height as number)))
            return true;
        return false;
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);
    const intlHandler = IntlHandler.getInstance();
    const menuItems: ELMenuData[] = [{
        id: ELMenuKeys.creationsRename,
        title: intlHandler.formatMessage("rename"),
        state: ELMenuState.disabled
    },
    {
        id: ELMenuKeys.creationsDelete,
        title: intlHandler.formatMessage("delete"),
        state: ELMenuState.enabled
    },
    {
        id: ELMenuKeys.creationsDownloadAsMP4,
        title: intlHandler.formatMessage("download-creation"),
        state: ELMenuState.enabled
    },
    ...isOpenInDesktopFeatureSupported() ?
        [{
            id: ELMenuKeys.creationsOpenInDesktop,
            title: intlHandler.formatMessage("open-in-desktop"),
            state: ELMenuState.enabled
        }] : [],
    ];
    const gridRef = useRef(null);
    const specLayoutConfig = {
        TILE_W: 16.75,
        TILE_H: 21.25,
        DIST_BETWEEN_TILE: 1.5
    };
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: gridRef,
        gridRef: gridRef
    });

    const [maxColumn, setMaxColumn] = useState<"auto-fit" | number>("auto-fit");
    const networkStatus = useNetwork(false);
    const wasOnline = useRef(networkStatus.online);

    const filterSelected = useSelector((state: RootState) => state.recentCreationReducer.creationType);
    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        const creationsWithNotValidThumbnail = state.data.filter(creationData => !isValidThumbPresentOnStore(creationData));
        if (state.data) {
            props.controller.notify({ type: ELRecentCreationsControllerActions.fetchAllThumbnails, payload: creationsWithNotValidThumbnail });
        }
    }, [state.data]);

    useEffect(() => {
        setMaxColumnForGrid();
    }, [gridRef.current]);

    useEffect(() => {
        if (networkStatus.online === true && wasOnline.current === false) {
            //TODO: timeout is temporary so that delay of connecting to global protect is taken care of
            setTimeout(() => {
                props.controller.notify({ type: ELRecentCreationsControllerActions.refreshCreation });
            }, 5000);
        }
        wasOnline.current = networkStatus.online;
    }, [networkStatus]);

    const setMaxColumnForGrid = (): void => {
        if (gridRef.current) {
            const gridWidth = (gridRef.current as HTMLDivElement).getBoundingClientRect().width;
            setMaxColumn(Math.floor(gridWidth / (specLayoutConfig.TILE_W + specLayoutConfig.DIST_BETWEEN_TILE)));
        }

        setMaxColumn("auto-fit");
    }

    const isValidThumbPresentOnStore = (data: CreationsData): boolean => {
        if (isThumbPresentOnStore(data) && creationsStoreData.data[data.id].thumbnailData !== ERROR_THUMBDATA) {
            return true;
        }
        return false;
    }

    const isThumbPresentOnStore = (data: CreationsData): boolean => {
        if (creationsStoreData.data[data.id] && creationsStoreData.data[data.id].thumbnailData) {
            return true;
        }
        return false;
    }

    const getCreationsThumbURL = (data: CreationsData): string => {
        if (isThumbPresentOnStore(data)) {
            return creationsStoreData.data[data.id].thumbnailData;
        }
        return "";
    }

    const showProgressUI = (): boolean => {
        return (state.creationStatus === CreationsStatus.requested);
    }

    const handleThumbMenuActions = (menuId: ELMenuKeys, data: CreationsData): void => {
        Logger.log(LogLevel.INFO, "ELRecentCreationsView - (handleThumbMenuActions) : ", menuId, data);
        switch (menuId) {
            case ELMenuKeys.creationsDelete: {
                props.controller.notify({ type: ELRecentCreationsControllerActions.deleteCreation, payload: data });
                break;
            }
            case ELMenuKeys.creationsDownloadAsMP4: {
                props.controller.notify({ type: ELRecentCreationsControllerActions.downloadCreation, payload: data });
                break;
            }
            case ELMenuKeys.creationsOpenInDesktop: {
                props.controller.notify({ type: ELRecentCreationsControllerActions.openDeepLink, payload: data });
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "ELRecentCreationsView - (handleThumbMenuActions) : invalid menu action", menuId);
            }
        }
    }

    const onRefetchCreation = (): void => {
        props.controller.notify({ type: ELRecentCreationsControllerActions.refreshCreation });
        viewDispatch({ type: ELRecentCreationsViewActions.refetchCreations, payload: undefined });
    }

    const onRenameCreation = (projectId: string, title: string, operationSubType: string): void => {
        const payload: RenameCreationPayload = { projectId: projectId, title: title, operationSubType: operationSubType };
        props.controller.notify({ type: ELRecentCreationsControllerActions.renameCreation, payload: payload });
    }

    const isOpenInDesktopSupportedCreation = (creationsData: CreationsData): boolean => {
        switch (creationsData.operationSubType) {
            case CreationsJobProjectSubType.replaceBackground:
            case CreationsJobProjectSubType.patternOverlay: {
                return true;
            }
            default: {
                return false;
            }
        }
    }

    const updateMenuItemState = (menuItem: ELMenuData, creationsData: CreationsData): ELMenuData => {
        const updatedMenuItem = menuItem;
        const creationsStatus = creationsData.status;
        switch (menuItem.id) {
            case ELMenuKeys.creationsDownloadAsMP4: {
                updatedMenuItem.state = (creationsStatus === CreationsStatus.success ||
                    creationsStatus === CreationsStatus.oldVersion) ? ELMenuState.enabled : ELMenuState.disabled;
                break;
            }
            case ELMenuKeys.creationsRename: {
                updatedMenuItem.state = (creationsStatus === CreationsStatus.success ||
                    creationsStatus === CreationsStatus.oldVersion) ? ELMenuState.enabled : ELMenuState.disabled;
                break;
            }
            case ELMenuKeys.creationsDelete: {
                updatedMenuItem.state = ELMenuState.enabled;
                break;
            }
            case ELMenuKeys.creationsOpenInDesktop: {
                updatedMenuItem.state = ELMenuState.enabled;
                break;
            }
        }
        return updatedMenuItem;
    }

    const getSupportedMenuItems = (data: CreationsData): ELMenuData[] => {
        const updatedMenuItems = menuItems.filter((item) => {
            if ((item.id !== ELMenuKeys.creationsOpenInDesktop) || isOpenInDesktopSupportedCreation(data))
                return true;
            else
                return false;
        });
        return updatedMenuItems;
    }

    const getMenuItems = (data: CreationsData): ELMenuData[] => {
        const supportedMenuItems = getSupportedMenuItems(data);
        const updatedMenuItems = supportedMenuItems.map((item) => {
            const menuItem = updateMenuItemState(_.cloneDeep(item), data as CreationsData);
            return menuItem;
        });
        return updatedMenuItems;
    }

    const getCreationsGrid = (): React.ReactNode => {
        if (state.data.length > 0) {
            return (
                <Grid data-test-id="creations-grid" id="creations-grid" columns={repeat(maxColumn, viewLayoutConfig.TILE_W)}
                    marginTop="size-400"
                    gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                    {state.data && (state.data.length > 0) && state.data.map((data, index) =>
                        <View key={index} width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H}>
                            <ELCreationThumb data={data}
                                width={viewLayoutConfig.TILE_W}
                                height={viewLayoutConfig.TILE_W}
                                srcData={getCreationsThumbURL(data)}
                                onMenuActions={(menuId: ELMenuKeys) => { handleThumbMenuActions(menuId, data) }}
                                menuItems={getMenuItems(data)}
                                onRename={(title) => onRenameCreation(data.id, title, data.operationSubType)}
                                onPress={(data) => { props.controller.notify({ type: ELRecentCreationsControllerActions.openCreation, payload: data }) }} />
                        </View>
                    )}
                </Grid>
            );
        } else if (state.data.length === 0 && state.dataFetched) {
            const textKey = (state.status === "success") ? "no-creations-banner-para1" : "no-creations-banner-para2";
            return (<Flex alignItems="center" justifyContent="center" marginTop="2rem">
                <ELEmptyCreationsBannerView textKey={textKey}>
                    <ELButton isHidden={state.status === "success"} size="L" variant="secondary"
                        onClick={() => onRefetchCreation()}>
                        <Text UNSAFE_className="failed-slideshow__cta-text">{intlHandler.formatMessage("retry")} </Text>
                    </ELButton>
                </ELEmptyCreationsBannerView>
            </Flex>);
        } else if (!state.dataFetched) {
            return (<Flex alignItems="center" justifyContent="center" height="50vh">
                <ProgressCircle isIndeterminate size="L" aria-label="Creations Loading..." />
            </Flex>);
        }

        return (<></>);
    };

    const handleFilterTypeChange = (key: RecentCreationFilterType): void => {
        props.controller.notify({
            type: ELRecentCreationsControllerActions.filterCreations,
            payload: key
        });
    };

    const getRecentCreationsFilterMenuItems = (): RecentCreationsFilterMenu[] => {
        return ELCreateOnDemandUtils.getRecentCreationFilterList();
    }


    return (
        <ELReactSpectrumV3Provider>
            <div className="recent-creations-text-box">
                <Flex justifyContent={"space-between"}>
                    <Text>{intlHandler.formatMessage("your-creations")}</Text>
                    <Flex UNSAFE_className="recent-creations-toolbar-right-items">
                        <Flex>
                            <ELRecentCreationFilterView menuItems={getRecentCreationsFilterMenuItems()} defaultSelectedKey={filterSelected} onFilterChange={handleFilterTypeChange} />
                        </Flex>
                        <Flex>
                            <ELRecentCreationsSortOrderList controller={props.controller} />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
            <div ref={gridRef}>
                {getCreationsGrid()}
            </div>
            {
                showProgressUI() && <ELCreationProgressView progressText={state.progressText} mode="fullscreen" />
            }
            <div id="open-deeplink-container" data-testid="open-deeplink-container" />
        </ELReactSpectrumV3Provider>
    );
};

export default ELRecentCreationsView;
