/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { MediaGridConfig } from "../../../stores/actions/mediaGridConfigActions";

export enum ReplaceMediaManagerControllerAction {
    toolbarCancel = "TOOLBAR_CANCEL",
    toolbarContinue = "TOOLBAR_CONTINUE",
    mediaChanged = "MEDIA_CHANGED",
}

export enum ReplaceMediaManagerViewAction {
    updateContinueButtonState = "UPDATE_CONTINUE_BUTTON_STATE"
}

export enum ReplaceMediaManagerWorkflowAction {
    replaceMediaSelection = "REPLACE_MEDIA_SELECTION",
    replaceActiveMedia = "REPLACE_ACTIVE_MEDIA"
}

export enum ReplaceMediaManagerMode {
    replacingMedia = "REPLACING_MEDIA",
    addingMissingMedia = "ADDING_MISSING_MEDIA"
}

export interface ReplaceMediaManagerPayload {
    data?: unknown,
    assetId: string,
    mode: ReplaceMediaManagerMode,
    mediaGridConfig: MediaGridConfig
}