/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { EnvConstants, LocalStorageKeys } from "./Constants/Constants";
import NewRelicLogger from "../newRelic/NewRelicLogger";

export enum LogLevel {
    TRACE,
    DEBUG,
    INFO,
    WARN,
    ERROR,
    SILENT
}

export enum LogState {
    ENABLED,
    DISABLED
}

class RootLogger {
    private _dict: Map<LogLevel, (...args: any[]) => void> = new Map();
    private _level: LogLevel;
    private _logState: LogState = (process.env.REACT_APP_CONSOLE_LOGGING_ENABLED === EnvConstants.true) ?
        LogState.ENABLED : LogState.DISABLED;

    constructor(level: LogLevel) {
        this._level = level;
        this._dict.set(LogLevel.TRACE, this._trace);
        this._dict.set(LogLevel.DEBUG, this._debug);
        this._dict.set(LogLevel.INFO, this._info);
        this._dict.set(LogLevel.WARN, this._warn);
        this._dict.set(LogLevel.ERROR, this._error);
    }

    private _forcedLog(): boolean {
        return localStorage.getItem(LocalStorageKeys.kForceLog) === EnvConstants.true;
    }

    private _trace(...args: any[]): void {
        if (this._level <= LogLevel.TRACE) {
            console.trace('[TRACE]', ...args)
        }
    }

    private _debug(...args: any[]): void {
        if (this._level <= LogLevel.DEBUG) {
            console.debug('[DEBUG]', ...args)
        }
    }

    private _info(...args: any[]): void {
        if (this._level <= LogLevel.INFO) {
            console.info('[INFO]', ...args)
        }
    }

    private _warn(...args: any[]): void {
        if (this._level <= LogLevel.WARN) {
            console.warn('[WARN]', ...args)
        }
    }

    private _error(...args: any[]): void {
        if (this._level <= LogLevel.ERROR) {
            console.error('[ERROR]', ...args)
        }
    }

    set setLogState(logState: LogState) {
        this._logState = logState;
    }

    async log(level: LogLevel, ...args: any[]): Promise<void> {
        if (this._forcedLog() || this._logState === LogState.ENABLED)
            this._dict.get(level)?.call(this, ...args);
        if (level === LogLevel.ERROR) {
            NewRelicLogger.postError(level, args);
        }
    }
}

const Logger = new RootLogger(LogLevel.TRACE);
export default Logger;

