/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useEffect } from "react";

//Application
import IMS from "../../services/IMS";
import { ELAdobeAsset, elDeserializeAsset } from "../../common/interfaces/storage/AssetTypes";

interface AssetUserInfoContext {
    isOwner: boolean
}

interface AssetUserInfoProviderProps {
    children?: ReactNode,
    elAdobeAsset: ELAdobeAsset
}

const assetUserInfoContext = React.createContext<AssetUserInfoContext>({ isOwner: false });

export const UserInfoProvider = (props: AssetUserInfoProviderProps): JSX.Element => {
    // Right now not a Data structure. Will have when we have more info
    const ownerId = elDeserializeAsset(props.elAdobeAsset).createdBy ?? ""
    const [isOwner, setIsOwner] = React.useState(false);

    useEffect(() => {
        const isOwner = IMS.getInstance().getUserId() === ownerId;
        setIsOwner(isOwner);
    }, [IMS.getInstance().getUserId(), ownerId]);


    return (
        <assetUserInfoContext.Provider value={{ isOwner }} >
            {props.children}
        </assetUserInfoContext.Provider>
    );
};

// Give detail about the user and its properties with respect to an asset
export const useAssetUserInfo = (): AssetUserInfoContext => {
    const { isOwner } = React.useContext(assetUserInfoContext);
    return { isOwner };
};

