/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import store from "../stores/store";

export enum CookiePreferences {
    "C0001",
    "C0002",
    "C0003",
    "C0004"
}

const PrivacyUtils = {

    isCookiesEnabled: (): boolean => {
        const activeGroups = store.getState().cookiePreferencesReducer;
        if (PrivacyUtils.hasUserProvidedConsent(activeGroups))
            return true;
        return false;
    },

    hasUserProvidedConsent: (activeCookies: string[]): boolean => {
        if (activeCookies.length === Object.keys(CookiePreferences).length / 2)
            return true;
        else
            return false;
    }
}

export { PrivacyUtils };
