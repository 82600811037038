/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";

//Application Specefic
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { ViewAction } from "../../../IBaseController";
import ITemplateViewController from "../../../ITemplateViewController";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ELFeedbackDialogAction, ELFeedbackDialogView } from "./ELFeedbackDialogView";

const FEEDBACK_CHARACTER_LIMIT = 500;

/**
 * not touching the existing code, as true was being logged for generic feedback
 * added the string params for specific feedback,
 * like feedback launched in certain creation
 */
type ELFeedbackOnSubmitIngestValue = string | boolean;

export class ELFeedbackDialog extends ITemplateViewController {
    private _heading: string;
    private _feedbackQuestion: string;
    private _feedbackCharacterLimit: number;
    private _message: string;
    private _onSubmitIngestValue: ELFeedbackOnSubmitIngestValue;

    constructor(owner: IViewController, heading: string, feedbackQuestion: string, message: string, feedbackCharacterLimit?: number, ingestValue?: ELFeedbackOnSubmitIngestValue) {
        super(owner);
        this._heading = heading;
        this._feedbackQuestion = feedbackQuestion;
        this._message = message;
        this._feedbackCharacterLimit = feedbackCharacterLimit ? feedbackCharacterLimit : FEEDBACK_CHARACTER_LIMIT;
        this._onSubmitIngestValue = ingestValue ? ingestValue : true;
    }

    private _onFeedbackSubmit = (msg: string): void => {
        const intlHandler = IntlHandler.getInstance();
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.uiSearchKeyword] = IngestUtils.sanitizeFeedbackMessage(msg);
        const feedbackIngestObject = IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.feedback, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
            IngestEventSubTypes.success, IngestEventSubTypes.submitFeedback, this._onSubmitIngestValue, additionalLogInfo));
        IngestLogging.getInstance().logEvent(feedbackIngestObject);
        ToastUtils.success(intlHandler.formatMessage("feedback-submitted-successfully"));
        this.destroyView();
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const feedbackDialog = React.createElement(ELFeedbackDialogView, {
            controller: this,
            heading: this._heading,
            feedbackQuestion: this._feedbackQuestion,
            feedbackCharacterLimit: this._feedbackCharacterLimit,
            message: this._message,
        })

        const feedbackRenderIngestObj = IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.feedback, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success, IngestEventSubTypes.render,
            this._onSubmitIngestValue));
        IngestLogging.getInstance().logEvent(feedbackRenderIngestObj);

        ReactDOM.render(feedbackDialog, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELFeedbackDialogAction.onDismiss: {
                this.destroyView();
                this._owner.notify({ type: ELFeedbackDialogAction.onCTA });
                handled = true;
                break;
            }
            case ELFeedbackDialogAction.onFeedbackSubmit: {
                const feedback = action.payload as string;
                this._onFeedbackSubmit(feedback);
                this._owner.notify({ type: ELFeedbackDialogAction.onCTA });
                handled = true;
                break;
            }
        }

        if (!handled)
            return this._owner.notify(action);

        return handled
    }

}