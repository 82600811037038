/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { CreationsStatus } from "../../../common/interfaces/creations/CreationTypes";
import ElementsCreationsService from "../../../services/ElementsServices/ElementsCreationsService";
import Logger, { LogLevel } from "../../../utils/Logger";
import IViewController from "../../../view/IViewController";
import {
    CreationInAppNotifierAction,
    CreationAppSubscriberType,
    CreationStatusData,
    ELProjectProgressPayload,
    ProjectId
} from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import Utils from "../../../utils/Utils";
import ElementsCreationInAppNotifier from "./ElementsCreationInAppNotifier";


class CreationInAppNotifier extends ElementsCreationInAppNotifier {

    private _statusSubscriberQueue: IViewController[] = [];

    private _notifyCreationProgressChanged(projectId: string, progress: number): void {
        this._statusSubscriberQueue.forEach(controller => {
            const payload: ELProjectProgressPayload = {
                projectId: projectId,
                progress: progress
            };
            controller.notify({ type: CreationInAppNotifierAction.creationProgressChanged, payload: payload });
        });
    }

    private _notifyCreationStatusChanged(creationStatusData: CreationStatusData): void {
        const action = { type: CreationInAppNotifierAction.creationStatusChanged, payload: creationStatusData };
        this._allActionSubscriberQueue.forEach((controller) => {
            controller.notify(action);
        });

        this._statusSubscriberQueue.forEach((controller) => {
            controller.notify(action);
        });
    }

    public subscribe(controller: IViewController, forAction = CreationAppSubscriberType.all): void {
        switch (forAction) {
            case CreationAppSubscriberType.all: {
                this._allActionSubscriberQueue.push(controller);
                break;
            }
            case CreationAppSubscriberType.statusChange: {
                this._statusSubscriberQueue.push(controller);
                break;
            }
        }
    }

    public unsubscribe(controller: IViewController, forAction = CreationAppSubscriberType.all): void {
        switch (forAction) {
            case CreationAppSubscriberType.all: {
                this._allActionSubscriberQueue = this._allActionSubscriberQueue.filter((element) => (controller !== element));
                break;
            }
            case CreationAppSubscriberType.statusChange: {
                this._statusSubscriberQueue = this._statusSubscriberQueue.filter((element) => (controller !== element));
                break;
            }
        }
    }


    public async pollStatus(id: ProjectId): Promise<void> {
        const POLLING_CALL_GAP = 2000;
        try {
            if(id === "") {
                return Promise.reject(new Error("Empty projectID received"));
            }
            const { status, progress } = await ElementsCreationsService.getInstance().getCreationsStatusWithProgress(id);   
            this._notifyCreationProgressChanged(id, progress);
            if (this.isRequestActive(status)) {
                await Utils.wait(POLLING_CALL_GAP);
                return this.pollStatus(id);
            } else {
                const creationStatusData = { projectId: id, status: status };
                this._notifyCreationStatusChanged(creationStatusData);
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationInAppNotifier:pollStatus, failed to poll for id" + id + " with error:" + error);
            const creationStatusData = { projectId: id, status: CreationsStatus.apiError };
            this._notifyCreationStatusChanged(creationStatusData);
        }
    }
}

export default new CreationInAppNotifier();
