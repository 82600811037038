/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import ReactDOM from 'react-dom';

//Application Specific
import { ViewAction } from '../../../IBaseController';
import ITemplateViewController from '../../../ITemplateViewController';
import { ELPhotoTextEditPanelView } from './ELPhotoTextEditPanelView';
import { ControllerAction } from '../../../IViewController';

export class ELPhotoTextEditPanel extends ITemplateViewController {

    createView(container: HTMLElement): void {
        throw new Error("It is not called currently, viewProps needs to be added to createView for using this.");
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELPhotoTextEditPanelView, {
            controller: this
        })
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this._owner.notify(action);
    }
}
