/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ContentEntity, ContentType } from "@elements/elementswebcommon";
import Logger, { LogLevel } from "../../../utils/Logger";
import { ElementsContentManagerWrapper } from "../../../services/ElementsServices/ElementsContentManagerWrapper";

export class ELContentCacheDownloader {
    private static _cachedContent: Map<ContentType, ContentEntity[]> = new Map<ContentType, ContentEntity[]>();

    private static async _fetchAndCacheData(contentType: ContentType): Promise<ContentEntity[]> {
        try {
            //TODO : samyjain : Don't use generic Array here
            const data = await ElementsContentManagerWrapper.instance.contentManager?.fetchListing(contentType) as ContentEntity[];
            return data;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELContentCacheDownloader:_fetchAndCacheData, ", contentType, error);
            throw error;
        }
    }

    static async getContentForContentType(contentType: ContentType): Promise<ContentEntity[]> {
        const contentData = ELContentCacheDownloader._cachedContent.get(contentType);

        if (contentData && contentData.length !== 0) {
            return contentData;
        }

        try {
            const data = await ELContentCacheDownloader._fetchAndCacheData(contentType);
            ELContentCacheDownloader._cachedContent.set(contentType, data);
            return ELContentCacheDownloader._cachedContent.get(contentType) as ContentEntity[];
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELContentCacheDownloader:getContentForContentType, ", contentType, error);
            throw error;
        }
    }
}