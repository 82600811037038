/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import { Provider as ReactReduxProvider } from "react-redux";
import ReactDOM from "react-dom";

//Application specific
import ITemplateViewController from "../../../ITemplateViewController";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import { CardData, ChannelName, EliveContentType, ElivePlatform, UserState } from "../../../../common/interfaces/elive/EliveTypes";
import { EliveContentManager } from "../../../../services/ElementsServices/EliveContentManager";
import ELUserHomeEliveCardsView from "./ELUserHomeEliveCardsView";
import store from "../../../../stores/store";
import { ELUserHomeActions, ELUserHomeEliveCardsActions, ELUserHomeEliveCardsViewActions } from "../../../../common/interfaces/home/UserHomeTypes";
import AppRouteUtil from "../../../../app/AppRouteUtil";
import ELDeeplinkManager from "../../../../modules/deeplink/ELDeeplinkManager";
import ELPSEDeeplinkCreator from "../../../../modules/deeplink/ELPSEDeeplinkCreator";
import { ELDeeplinkActionParams, ELDeeplinkActions } from "../../../../modules/deeplink/ELDeeplinkTypes";
import IMS from "../../../../services/IMS";

//Utils
import { EliveUtils } from "../../../../utils/EliveUtils";
import Utils from "../../../../utils/Utils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes, IngestLogObjectKey, IngestLogObjectCustomKey } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { LoginUtils } from "../../../../utils/LoginUtils";
import { TrialUtils } from "../../../../utils/TrialUtils";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";

class ELUserHomeEliveCards extends ITemplateViewController {
    private _cards: CardData[] = [];
    private _cardsReadyPromise: Promise<void>;
    private _cardsReadyPromiseResolve!: () => void;
    private _cardsReadyPromiseReject!: (reason: any) => void;

    constructor(owner: IViewController) {
        super(owner);
        this._cardsReadyPromise = new Promise((resolve, reject) => {
            this._cardsReadyPromiseResolve = resolve;
            this._cardsReadyPromiseReject = reject;
        });
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._fetchCards();
    }

    private async _fetchCards(): Promise<void> {
        try {
            this._cards = await EliveContentManager.instance.fetchListing(EliveContentType.card) as CardData[];
            this._cardsReadyPromiseResolve();
        } catch (error) {
            this._cardsReadyPromiseReject(error);
        }
    }

    private _filterCards(cards: CardData[], userState: UserState): CardData[] {
        const filteredCards = cards.filter((card) => {
            return (card.userStates.includes(userState))
        });
        return filteredCards;
    }

    private async _setViewCards(userState: UserState): Promise<void> {
        try {
            await this._cardsReadyPromise;
            const filteredCards = this._filterCards(this._cards, userState);
            if (filteredCards.length !== 0)
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELUserHomeEliveCardsViewActions.updateCards, payload: filteredCards });
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "ELUserHomeEliveCards: _setViewCards: Error fetching cards for user state", { error: error, userState: userState });
        }
    }

    private _onDeeplinkSuccessFailure = (isSuccessful: boolean): void => {
        if (!isSuccessful) {
            Logger.log(LogLevel.ERROR, "ELUserHomeEliveCards: _onDeeplinkSuccessFailure: Error opening try this workflow");
        }
    }

    private _handleTryThisDesktop = (workflowName: string): void => {
        const deeplinkManager = new ELDeeplinkManager(new ELPSEDeeplinkCreator(), this._onDeeplinkSuccessFailure);

        const paramsMap = new Map<ELDeeplinkActionParams, string>();
        paramsMap.set(ELDeeplinkActionParams.action, ELDeeplinkActions.tryThisDefault);
        paramsMap.set(ELDeeplinkActionParams.subAction, workflowName);

        deeplinkManager.createAndOpenDeeplink(paramsMap);
    }

    private _handleTryThisWeb = async (workflowName: string): Promise<void> => {
        const daysInTrial = store.getState().appReducer.daysInTrial;
        const userState = EliveUtils.getUserState(daysInTrial);
        AppRouteUtil.changeRoute(workflowName);

        if (!IMS.getInstance().isSignedInUser()) {
            LoginUtils.signIn();
        } else if (userState === UserState.trialNotStarted) {
            return await TrialUtils.startTrial();
        }
    }

    private _handleTryNowClicked(card: CardData): void {
        const workflowName = card.workflowName;
        switch (card.platform) {
            case ElivePlatform.desktop:
                this._handleTryThisDesktop(workflowName);
                break;
            case ElivePlatform.web: //ELIVE_REVISIT - for now only providing support to open tabs
                this._handleTryThisWeb(workflowName);
                break;
            case ElivePlatform.mobile:
                Utils.openInNewTab(card.cardURL);
                break;
        }
    }

    private _handleCtaClicked(card: CardData, isCardClicked = false): void {
        switch (card.channelName) {
            case ChannelName.tryNow: {
                !isCardClicked ? this._ingestCTAClick(IngestEventSubTypes.tryNow, card) : this._ingestCardClick(IngestEventSubTypes.tryNow, card);
                this._handleTryNowClicked(card);
                break;
            }
            case ChannelName.watchVideo: {
                !isCardClicked ? this._ingestCTAClick(IngestEventSubTypes.watchVideo, card) : this._ingestCardClick(IngestEventSubTypes.watchVideo, card);
                this.notify({ type: ELUserHomeActions.watchVideo, payload: card.cardURL });
                break;
            }
            case ChannelName.inspire:
            case ChannelName.explore:
            case ChannelName.learn:
            case ChannelName.news:
            default:
                !isCardClicked ? this._ingestCTAClick(card.channelName, card) : this._ingestCardClick(card.channelName, card);
                Utils.openInNewTab(card.cardURL);
        }
    }

    private _ingest(payload: Record<string, string>): void {
        this.notify({
            type: WorkflowActionType.ingest,
            payload: payload

        });
    }

    private _ingestCTAClick(ctaNameToIngest: string, card: CardData): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.cardHeading;
        additionalLogInfo[IngestLogObjectKey.eventCount] = card.title;
        if (FeaturesManager.getInstance().IsFeatureActive(FeatureName.eABTestEliveCardClickable))
            additionalLogInfo[IngestLogObjectKey.expCampaignId] = FeatureName.eABTestEliveCardClickable;

        const ctaClickIngestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.eliveCard, IngestEventTypes.click,
            ctaNameToIngest, card.platform, additionalLogInfo);

        this._ingest(ctaClickIngestPayload);
    }

    private _ingestCardClick(ctaNameToIngest: string, card: CardData): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.cardHeading;
        additionalLogInfo[IngestLogObjectKey.eventCount] = card.title;
        additionalLogInfo[IngestLogObjectKey.expCampaignId] = FeatureName.eABTestEliveCardClickable;

        const ctaClickIngestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.eliveCard, IngestEventTypes.select,
            ctaNameToIngest, card.platform, additionalLogInfo);

        this._ingest(ctaClickIngestPayload);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const userHomeCards = React.createElement(ELUserHomeEliveCardsView, {
            controller: this,
            isCardClickable: FeaturesManager.getInstance().IsFeatureActive(FeatureName.eABTestEliveCardClickable)
        });
        const providerHydratedCardsView = React.createElement(ReactReduxProvider, { store: store }, userHomeCards);
        ReactDOM.render(
            providerHydratedCardsView,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELUserHomeEliveCardsActions.refreshCards: {
                const daysInTrial = action.payload as number;
                this._setViewCards(EliveUtils.getUserState(daysInTrial));
                handled = true;
                break;
            }
            case ELUserHomeEliveCardsActions.ctaClicked: {
                const card = action.payload as CardData;
                this._handleCtaClicked(card);
                handled = true;
                break;
            }
            case ELUserHomeEliveCardsActions.cardThumbClicked: {
                const card = action.payload as CardData;
                this._handleCtaClicked(card, true);
                handled = true;
                break;
            }
        }
        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }
}

export default ELUserHomeEliveCards;
