/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import ModalWorkspaceView from "./ModalWorkflowWrapperView";
import IModalWorkspace, { ModalWorkspaceName } from "../../../IModalWorkspace";
import { WorkflowAction, WorkflowsName } from "../../../IWorkflow";
import { WorkspaceAction, WorkspaceActionType } from "../../../IBaseWorkspace";
import { ViewAction } from "../../../../view/IBaseController";
import WorkflowFactory from "../../../WorkflowFactory";
import { ControllerAction } from "../../../../view/IViewController";
import ModalWorkspaceFactory from "../../../ModalWorkspaceFactory";
import IWorkspace from "../../../IWorkspace";

export default class ModalWorkflowWrapper extends IModalWorkspace {

	private _action!: WorkflowAction;
	private readonly _modalWorkflowContainerId = "modal-wrapper-app-container"; // This div should be used to mount the workflow that needs to be opened modally
	private readonly _modalAppContainerId = "modal-wrapper-modal-container"; // This div would be used to open another modal dialog on top of existing modal dialog

	constructor(owner: IWorkspace, overlayDiv: HTMLDivElement, dialogClass?: string) { //The workspace passed is the owner workspace of modalWorkspace
		super();
		this.modalWorkspaceName = ModalWorkspaceName.modalWrapper;
		this._owner = owner;
		this.overlayDiv = overlayDiv;
		this.dialogClass = dialogClass;
	}

	initialize(dispatch?: React.Dispatch<ViewAction>): void {
		super.initialize(dispatch);
		this.currentWorkflow?.startWorkflow(this._modalWorkflowContainerId, this._owner.getWorkflow, this._action);
	}

	createView(container: HTMLElement): void {
		super.createView(container);

		const modalWorkspace = React.createElement(ModalWorkspaceView, {
			controller: this,
			overlayDiv: this.overlayDiv,
			dialogClass: this.dialogClass
		});

		ReactDOM.render(
			modalWorkspace,
			container
		);
	}

	destroyView(): void {
		if (this.container) {
			ReactDOM.unmountComponentAtNode(this.container)
		}

		super.destroyView();
	}

	/**
	 *
	 * @param containerId : id of html element where workspace is mounted
	 * @param workflowName : optional workflowName to be started
	 * @param payload : any info that needs to be pass on to the workflow
	 */
	startWorkspace<T extends WorkflowAction>(containerId: string, workflowName?: WorkflowsName, action?: T): void {
		if (workflowName) {
			this.currentWorkflow = WorkflowFactory.createWorkflow(workflowName, this);
			this._action = (action as WorkflowAction);
			super.startWorkspace(containerId, workflowName, action);
		}
	}

	private _startModalWorkspace<T extends WorkspaceAction>(action: T): void {
		const modalWorkspaceName = action.startModalWorkspace;
		const payload = action.payload as Record<string, unknown>;
		const overlayDiv = payload?.overlayDiv as string;
		const workflowName = payload?.workflowName as WorkflowsName;

		if (modalWorkspaceName && overlayDiv) {
			this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(modalWorkspaceName, this, this.ensureHTMLElement(overlayDiv) as HTMLDivElement);
			this.modalWorkspace.startWorkspace(this._modalAppContainerId, workflowName);
		}
	}

	/**
	 * Pass the actions to parent workspace or handle actions
	 */
	protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
		let handled = false;
		switch (action.type) {
			case WorkspaceActionType.startModalWorkspace: {
				this._startModalWorkspace(action);
				handled = true;
				break;
			}
			case WorkspaceActionType.endWorkflow: {
				handled = await this._owner.notify({ type: WorkspaceActionType.endModalWorkspace });
				break;
			}
			case WorkspaceActionType.endModalWorkspace: {
				super.endModalWorkspace();
				handled = true;
				break;
			}
			default:
				handled = await this._owner.notify(action);
		}
		return handled;
	}

	/**
	 * Pass the action down to current workflow controller
	 * @param action
	 */
	async notify<T extends ControllerAction>(action: T): Promise<boolean> {
		let handled = this.currentWorkflow ? await this.currentWorkflow.notify(action) : false;
		if (!handled)
			handled = await this.notifyWorkspace(action as WorkspaceAction);
		return handled;
	}
}
