/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";

//Application Specific
import ELActionBar from "../../../../view/components/organism/el-action-bar/ELActionBar";
import MediaGrid, { MediaGridControllerAction } from "./MediaGrid";
import { ShareOptionsActionType } from "../../../../view/components/organism/el-share-options/ELShareOptionsView";
import store, { RootState } from "../../../../stores/store";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { ELDeleteDialog } from "../../../../view/components/organism/el-delete-dialog/ELDeleteDialogView";
import { ELShareButton, ELShareOptionsPopover } from "../../../../view/components/organism/el-share-popover/ELSharePopoverView";
import { ShareUtils } from "../../../../utils/ShareUtils";
import { ELQRSharePayload, ShareErrorCode, ShareType, ShareTypeCategory } from "../../../../common/interfaces/share/ShareTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectValue, IngestWorkflowTypes, ShareInvocationPoint } from "../../../../utils/IngestConstants";
import { ShareAction } from "../../../../stores/actions/ShareAction";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import { ELEditButton } from "../../../../view/components/organism/el-edit-action/ELEditActionBarButton";
import { EditWorkspaceAction } from "../../../../common/interfaces/editing/editWorkspace/EditWorkspaceTypes";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";

import "./MediaGrid.scss";

interface IMediaGridProps {
    controller: MediaGrid,
    createPopoverView: React.ReactNode
}

export interface MediaGridState {
    uploadInProgress: boolean
}

const MediaGridView = (props: IMediaGridProps): React.ReactElement => {
    const { width, height, isMobile, isMobilePortraitMode } = useViewport();
    const selectedAssetsList = useSelector((state: RootState) => state.selectedMediaListReducer);

    useEffect(() => {
        props.controller.initialize();
        props.controller.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGrid, IngestEventSubTypes.success, IngestEventTypes.render, true)
        });
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const handleShare = (): void => {
        store.dispatch(ShareAction.updateShareType(ShareType.media));
        ShareUtils.ingestShareStart(ShareInvocationPoint.mediaGrid, props.controller.notify.bind(props.controller));
        props.controller.getShareOptionsController?.notify({ type: ShareOptionsActionType.openShareWorkspace, payload: { mediaList: selectedAssetsList.map((asset) => asset.assetId) } });
    }

    const handleDelete = (): void => {
        props.controller.notify({ type: MediaGridControllerAction.delete });
    }

    const actionButtonPress = (): void => {
        const ingestObject = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utility, IngestEventSubTypes.click, IngestEventTypes.delete);
        ingestObject[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.grid;
        props.controller.notify({ type: WorkflowActionType.ingest, payload: ingestObject });
    }

    const handlePopoverButtonPress = (): void => {
        const assetsToShare = selectedAssetsList.map((asset) => asset.assetId) as string[];
        const payload: ELQRSharePayload = {
            assetsToShare: assetsToShare,
            category: ShareTypeCategory.media,
            source: WorkflowsName.mediaGrid
        };
        props.controller.getShareOptionsController?.notify({ type: ShareOptionsActionType.createShareQR, payload: payload });

        const ingestObject = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utility, IngestEventSubTypes.click, IngestEventTypes.share);
        ingestObject[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.grid;
        props.controller.notify({ type: WorkflowActionType.ingest, payload: ingestObject });
    }

    const addEditButtonIfFeatureEnabled = (items: React.ReactElement[]): void => {
        if (FeaturesManager.getInstance().IsFeatureActive(FeatureName.eAdjustments)) {
            const invokeEditWorkflowAction = {
                type: EditWorkspaceAction.startImageEditWorkflow
            };
            items.push(<ELEditButton key={"media-grid-edit-button"} onPress={() => { props.controller.notify(invokeEditWorkflowAction) }} />);
        }
    }

    const shouldShowQRView = (): boolean => {
        if (selectedAssetsList.length === 1 || FeaturesManager.getInstance().IsFeatureActive(FeatureName.eMultiMediaQRShareView)) {
            return true;
        }
        return false;
    }

    const getELActionBarItems = (): Array<React.ReactElement> => {
        const intlHandler = IntlHandler.getInstance();
        const isMobileDevice = PlatformUtils.isMobileDevice((width as number), (height as number));
        const items: React.ReactElement[] = [];
        if (!isMobileDevice) {
            items.push(props.createPopoverView as ReactElement);
            addEditButtonIfFeatureEnabled(items);
        }
        const shareOptions = props.controller.getShareOptionsController ?? undefined;
        const shareError = ShareUtils.checkForShareError(selectedAssetsList.length);
        if (shareOptions && shareError === ShareErrorCode.noError) {
            items.push(<ELShareOptionsPopover key="Share Option Popover"
                shareOptions={shareOptions} sharePressCallback={handleShare}
                isMobile={isMobile as boolean}
                positionParams={{ placement: "bottom", crossOffset: 15, offset: 10 }}
                text={intlHandler.formatMessage("share")}
                popoverButtonPress={handlePopoverButtonPress} showQRView={shouldShowQRView()} />);
        } else if (shareOptions && shareError) {
            items.push(<ELShareButton text={intlHandler.formatMessage("share")} onPress={() => ShareUtils.showShareErrorToast(shareError)} />)
        }
        items.push(<ELDeleteDialog isMobilePortraitMode={isMobilePortraitMode as boolean} text={intlHandler.formatMessage("delete")}
            key="Delete" selectedItemCount={selectedAssetsList.length} deletePressCallback={() => { handleDelete() }}
            actionButtonPressCallback={actionButtonPress} />);
        return items;
    }

    const showActionBar = (): boolean => {
        return (props.controller.getShareOptionsController !== undefined);
    }

    return (
        <div id="media-grid-container">
            <div id="media-grid-toolbar" />
            <div id="media-grid" />
            <Flex UNSAFE_className="action-bar__import-progress-box" width="100%" direction="column" position="fixed" bottom="size-0" alignItems="center">
                {showActionBar() &&
                    <div id="action-bar" >
                        {<ELActionBar items={getELActionBarItems()} />}
                    </div>}
            </Flex>
        </div>
    )
};

export default MediaGridView;
