/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ViewAction } from "../../view/IBaseController";
import IWorkflow, { WorkflowActionType, WorkflowsName } from "../IWorkflow";
import { WorkspaceAction, WorkspaceActionType, WorkspacesName } from "../IBaseWorkspace";
import WorkflowFactory from "../WorkflowFactory";
import IWorkspace from "../IWorkspace";
import App from "../../app/App";
import { ControllerAction } from "../../view/IViewController";

//Utils
import Utils from "../../utils/Utils";

export class UserHome extends IWorkspace {
    private readonly _containerId = "user-home-app-container";

    constructor(owner: App) {
        super(owner);
        this.workspaceName = WorkspacesName.userHome;
    }

    private _getWorkflowToStart(): IWorkflow {
        let userHome: IWorkflow;

        if (Utils.isValidRoutePath(window.location.pathname)) {
            userHome = WorkflowFactory.createWorkflow(WorkflowsName.userHome, this);

        } else {
            userHome = WorkflowFactory.createWorkflow(WorkflowsName.noPageFound, this);
        }

        return userHome;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._owner.createNavbarView();

        this.startWorkflow(this._containerId, this._getWorkflowToStart(), {
            type: WorkflowActionType.initialize
        });
    }

    // Base controller
    destroy(): void {
        this._owner.destroyNavbarView();
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startDefaultWorkflow:
                {
                    if (this.getWorkflow?.getWorkflowName !== this._getWorkflowToStart().getWorkflowName) {
                        this.startWorkflow(this._containerId, this._getWorkflowToStart());
                    }
                    handled = true;
                    break;
                }
            default:
                {
                    handled = await super.notify(action as WorkspaceAction);
                    break;
                }
        }
        if (!handled)
            handled = await super.notify(action as WorkspaceAction);
        return handled;
    }
}
