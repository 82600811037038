/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const JARVIS_APP_ID = "elementsWeb";
export const APP_VERSION = "1.0";
export const APPLICATION_TYPE = "web";
export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";
export const LANGUAGE_EN = "en";
export const REGION_US = "us";