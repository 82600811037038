/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useState } from "react";
import SortOrderDown from "@spectrum-icons/workflow/SortOrderDown";
import SortOrderUp from "@spectrum-icons/workflow/SortOrderUp";
import { useDispatch, useSelector } from "react-redux";

//Adobe Internal
import { Text } from "@adobe/react-spectrum";
import { ActionButton, Flex, Item, Menu, MenuTrigger, Section } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { RootState } from "../../../../stores/store";
import ELRecentCreations from "../../templates/el-recent-creations/ELRecentCreations";
import { ELRecentCreationsControllerActions, RecentCreationFilterType, RecentCreationSortOrder, RecentCreationSortOrderKey, RecentCreationSortType, RecentCreationsFilterMenu, RecentCreationsSortByField } from "../../../../common/interfaces/creations/ELRecentCreationsTypes";
import { IconType } from "../../../../assets/IconConstants";
import RecentCreationAction from "../../../../stores/actions/RecentCreationsAction";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { Theme } from "../../../../utils/Theme";
import { ELIcon } from "../../atoms/el-icon/ELIconView";

import "./ELRecentCreationsSortedView.scss";

const CREATION_TITLE = "name";
const MODIFIED_DATE = "modifiedDate";

interface RecentCreationsSortMenuProps {
    menuItems: RecentCreationsFilterMenu[],
    sortOrder: IconType,
    defaultSelectedKey: RecentCreationSortType,
    handleSortByChange: (key: React.Key) => void,
    reverseSortOrder: () => void
}

interface ELRecentCreationFilterMenuProps {
    menuItems: RecentCreationsFilterMenu[],
    defaultSelectedKey: RecentCreationFilterType,
    onFilterChange: (key: RecentCreationFilterType) => void;
}

export const ELRecentCreationFilterView = (props: ELRecentCreationFilterMenuProps): React.ReactElement => {
    const [selected, setSelected] = React.useState(new Set([props.defaultSelectedKey]));

    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {
        setSelected(new Set([props.defaultSelectedKey]));
    }, [props.defaultSelectedKey]);

    const handleSelectionChange = (keySet: "all" | Set<string | number>): void => {
        const selectedValue = [...keySet][0] as RecentCreationFilterType;
        if (selectedValue) {
            props.onFilterChange(selectedValue);
            setSelected(new Set([selectedValue]));
        }
    };

    const getMenuItems = (): React.ReactElement => {
        const menuList = props.menuItems.map((menu) =>
            <Item key={menu.id}>
                <Text UNSAFE_className="sort-order-picker__item">{intlHandler.formatMessage(menu.name)}</Text>
            </Item>
        );

        return (
            <Menu selectionMode="single" defaultSelectedKeys={[props.defaultSelectedKey]} selectedKeys={selected}
                onSelectionChange={handleSelectionChange}>
                <Section>
                    {menuList}
                </Section>
            </Menu>);
    };

    return (
        <MenuTrigger align="end">
            <ActionButton UNSAFE_className={"el-spectrum-action-button grid-toolbar-sort-dropdown"}>
                <ELIcon className="recent-creation-toolbar_filter_icon" iconkey={IconType.filterIcon} />
            </ActionButton>
            {getMenuItems()}
        </MenuTrigger>
    );
};

const RecentCreationsSortMenu = (props: RecentCreationsSortMenuProps): React.ReactElement => {
    const [selected, setSelected] = React.useState(new Set([props.defaultSelectedKey]));
    const intlHandler = IntlHandler.getInstance();
    const reverseOrderKey = "reverse-order";
    useEffect(() => {
        setSelected(new Set([props.defaultSelectedKey]));
    }, [props.defaultSelectedKey]);

    const handleSelectionChange = (keySet: "all" | Set<string | number>): void => {
        const selectedValue = [...keySet][0];

        if (selectedValue) {
            const selectedValueExists = props.menuItems.some(function (item) {
                return item.id === selectedValue;
            });

            if (selectedValueExists) {
                setSelected(new Set([selectedValue as RecentCreationsSortByField]));
                props.handleSortByChange(selectedValue);
            } else {
                props.reverseSortOrder();
            }
        }
    }

    const getMenuItems = (): React.ReactElement => {
        const menuList = props.menuItems.map((menu) =>
            <Item key={menu.id}>
                <Text UNSAFE_className="sort-order-picker__item">{menu.name}</Text>
            </Item>
        );

        return (<Menu selectionMode="single" defaultSelectedKeys={[props.defaultSelectedKey]} selectedKeys={selected}
            onSelectionChange={handleSelectionChange}>
            <Section>
                {menuList}
            </Section>
            <Section>
                <Item key={reverseOrderKey}>
                    {(props.sortOrder === IconType.sortOrderDown) ? <SortOrderDown size="S" /> : <SortOrderUp size="S" />}
                    <Text UNSAFE_className="sort-order-picker__item">{intlHandler.formatMessage("reverse-order")}</Text>
                </Item>
            </Section>
        </Menu>);
    }

    return (
        <MenuTrigger align="end">
            <ActionButton UNSAFE_className={"el-spectrum-action-button grid-toolbar-sort-dropdown"}>
                <ELCustomIcon variant="contained" iconkey={props.sortOrder} color={Theme.global.gray_controls_color_N}
                    hoverColor={Theme.global.gray_controls_color_H} width={"2.0rem"} height={"2.0rem"} />
            </ActionButton>
            {getMenuItems()}
        </MenuTrigger>
    );
}



export const ELRecentCreationsSortOrderList = (props: { controller: ELRecentCreations }): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const recentCreationState = useSelector((state: RootState) => state.recentCreationReducer);
    const [sortOrder, setSortOrderIcon] = useState(IconType.sortOrderDown);
    const dispatch = useDispatch();

    const sortOrderItems = [
        {
            name: intlHandler.formatMessage("name"),
            id: RecentCreationsSortByField.name
        },
        {
            name: intlHandler.formatMessage("modified-date"),
            id: RecentCreationsSortByField.modifiedDate
        },
    ];

    useEffect(() => {
        if (recentCreationState.order === RecentCreationSortOrderKey.ascending) {
            setSortOrderIcon(IconType.sortOrderUp);
        }
    }, [recentCreationState.order]);

    const reverseSortOrderIcon = (): void => {
        if (sortOrder === IconType.sortOrderUp) {
            setSortOrderIcon(IconType.sortOrderDown);
            handleSortOrderChange(IconType.sortOrderDown);

        } else {
            setSortOrderIcon(IconType.sortOrderUp);
            handleSortOrderChange(IconType.sortOrderUp);
        }
    };

    const handleSortOrderChange = (sSortOrderIconType: IconType): void => {
        const payload = recentCreationState;
        payload.order = getSortOrderKey(sSortOrderIconType);
        props.controller.notify({
            type: ELRecentCreationsControllerActions.sortCreationsByOrder,
            payload: getSortOrderKey(sSortOrderIconType)
        });
        dispatch(RecentCreationAction.changeSortOrder(payload.order));
    };

    const getSortByKey = (key: React.Key): RecentCreationsSortByField => {
        switch (key) {
            case CREATION_TITLE:
                return RecentCreationsSortByField.name;
            case MODIFIED_DATE:
                return RecentCreationsSortByField.modifiedDate;
            default:
                return RecentCreationsSortByField.modifiedDate;
        }
    }

    const getSortOrderKey = (sortOrder: IconType): RecentCreationSortOrder => {
        switch (sortOrder) {
            case IconType.sortOrderUp:
                return RecentCreationSortOrderKey.ascending;
            case IconType.sortOrderDown:
            default:
                return RecentCreationSortOrderKey.descending;
        }
    }

    const handleSortByChange = (key: React.Key): void => {
        props.controller.notify({
            type: ELRecentCreationsControllerActions.sortCreationsByField,
            payload: getSortByKey(key)
        });
    }
    return (
        <>
            <Flex UNSAFE_className="grid-sort-options-toolbar__desktop" direction="row" height="100%" width="100%" justifyContent={"center"} alignItems={"center"}>
                <RecentCreationsSortMenu menuItems={sortOrderItems} sortOrder={sortOrder}
                    handleSortByChange={(key: React.Key) => handleSortByChange(key)}
                    reverseSortOrder={() => reverseSortOrderIcon()}
                    defaultSelectedKey={recentCreationState.sortBy} />
            </Flex>
            <Flex UNSAFE_className="grid-sort-options-toolbar__mobile" direction="row" height="100%" justifyContent={"center"} alignItems={"center"}>
                <RecentCreationsSortMenu menuItems={sortOrderItems} sortOrder={sortOrder}
                    handleSortByChange={(key: React.Key) => handleSortByChange(key)}
                    reverseSortOrder={() => reverseSortOrderIcon()}
                    defaultSelectedKey={recentCreationState.sortBy} />
            </Flex>
        </>
    );
}