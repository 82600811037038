/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, {useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";

//Application specific
import { RootState } from "../../../../stores/store";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";

import ELCreationMediaView, { CreationMediaActionType } from "../el-creation-media-panel/ELCreationMediaView";
import ELManageMediaButton from "../../molecules/el-manage-media-button/ELManageMediaButton";
import IViewController from "../../../IViewController";

import "./SlideshowMediaView.scss";

export interface SlideshowMediaViewProps {
    controller: IViewController,
}

const SlideshowMediaView = (props: SlideshowMediaViewProps): React.ReactElement => {
    const SLIDESHOW_WIDTH_HEIGHT_MULTIPLIER = 14;
    const SLIDESHOW_DIST_BETWEEN_TILE_MULTIPLIER = 3;
    const specLayoutConfig = {
        TILE_W: SLIDESHOW_WIDTH_HEIGHT_MULTIPLIER,
        TILE_H: SLIDESHOW_WIDTH_HEIGHT_MULTIPLIER,
        DIST_BETWEEN_TILE: SLIDESHOW_DIST_BETWEEN_TILE_MULTIPLIER
    };

    const intlHandler = IntlHandler.getInstance();
    const [status, setStatus] = useState(CreationsStatus.requested);
    const slideshowStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);

    const isSlideshowSuccess = (): boolean => {
        return status === CreationsStatus.success;
    }

    useEffect(() => {
        if (slideshowStoreData.activeSlideshowId) {
            const slideshowId = slideshowStoreData.activeSlideshowId;
            setStatus(slideshowStoreData.data[slideshowId].status as CreationsStatus);
        }
    }, [slideshowStoreData]);


    const onPress = (): void => {
        props.controller.notify({ type: CreationMediaActionType.manageMedia });
    }

    const slideshowHeaderBox = (): React.ReactElement => {
        return (<div>
            <Flex UNSAFE_className="panel-header-box-with-button" >
                <div>{intlHandler.formatMessage("media")}</div>
                <ELManageMediaButton isEnabled={isSlideshowSuccess()} onPress={onPress}></ELManageMediaButton>
            </Flex>
        </div>)
    }

    return (
        <ELCreationMediaView controller={props.controller} variant="slideshow" isDeletableThumb={true}
            specLayoutConfig={specLayoutConfig} creationHeaderBox={slideshowHeaderBox()} ></ELCreationMediaView>
    )
}

export default SlideshowMediaView;
