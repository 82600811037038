/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "../actions/IAction";
import SIVAction, { SIVDataPayload, SIVData } from "../actions/SIVAction";

const defaultState = { currentAssetId: "", sivRenditionData: [] };

const updateSIVData = (state: SIVData = defaultState, action: IAction<SIVDataPayload | string>): SIVData => {
    const data = action.payload as SIVDataPayload;
    if (data) {
        const arr = state.sivRenditionData.filter((ele) => {
            return ele.assetId !== data.assetId;
        });
        arr.push(data);
        return {
            ...state,
            sivRenditionData: arr
        };
    } else {
        return state;
    }
}

const clearSIVData = (state: SIVData = defaultState): SIVData => {
    state.sivRenditionData.map((ele) => {
        if(ele.renditionData.imgData) {
            URL.revokeObjectURL(ele.renditionData.imgData);
        }
        return ele;
    });
    return defaultState;
}

const updateSIVAsset = (state: SIVData = defaultState, action: IAction<SIVDataPayload | string>): SIVData => {
    const assetId = action.payload as string;
    if(assetId !== "") {
        return {
            ...state,
            currentAssetId: assetId
        };
    }

    return state;
}

const sivReducer = (state: SIVData = defaultState, action: IAction<SIVDataPayload | string>): SIVData => {
    switch (action.type) {
        case SIVAction.UPDATE_DATA:
            return updateSIVData(state, action);
        case SIVAction.CLEAR_DATA:
            return clearSIVData(state);
        case SIVAction.SIV_ASSET_UPDATED:
            return updateSIVAsset(state, action);
        default:
            return state;
    }
}

export default sivReducer;