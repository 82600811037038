/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ELTextAlign } from "../../common/interfaces/stage/StageTypes";
import IAction from "./IAction";

export type TextEditActionPayload = number | string | boolean | ELTextAlign;

export default class TextEditAction {
    public static readonly UPDATE_POSTSCRIPT_NAME: string = "TextEditAction.UPDATE_POSTSCRIPT_NAME";
    public static readonly UPDATE_FONT_SIZE: string = "TextEditAction.UPDATE_FONT_SIZE";
    public static readonly UPDATE_STROKE_WIDTH: string = "TextEditAction.UPDATE_STROKE_WIDTH";
    public static readonly UPDATE_STROKE_COLOR: string = "TextEditAction.UPDATE_STROKE_COLOR";
    public static readonly UPDATE_SHADOW_BLUR: string = "TextEditAction.UPDATE_SHADOW_BLUR";
    public static readonly UPDATE_UNDERLINE: string = "TextEditAction.UPDATE_UNDERLINE";
    public static readonly UPDATE_TEXT_ALIGN: string = "TextEditAction.UPDATE_TEXT_ALIGN";

    public static updatePostscriptName(postscriptName: string): IAction<string> {
        return {
            type: TextEditAction.UPDATE_POSTSCRIPT_NAME,
            payload: postscriptName
        };
    }

    public static updateFontSize(fontSize: number): IAction<number> {
        return {
            type: TextEditAction.UPDATE_FONT_SIZE,
            payload: fontSize
        };
    }

    public static updateStrokeWidth(strokeWidth: number): IAction<number> {
        return {
            type: TextEditAction.UPDATE_STROKE_WIDTH,
            payload: strokeWidth
        };
    }

    public static updateStrokeColor(strokeColor: string): IAction<string> {
        return {
            type: TextEditAction.UPDATE_STROKE_COLOR,
            payload: strokeColor
        };
    }

    public static updateShadowBlur(shadowBlur: number): IAction<number> {
        return {
            type: TextEditAction.UPDATE_SHADOW_BLUR,
            payload: shadowBlur
        };
    }

    public static updateUnderline(underline: boolean): IAction<boolean> {
        return {
            type: TextEditAction.UPDATE_UNDERLINE,
            payload: underline
        };
    }

    public static updateTextAlign(textAlign: ELTextAlign): IAction<ELTextAlign> {
        return {
            type: TextEditAction.UPDATE_TEXT_ALIGN,
            payload: textAlign
        };
    }
}