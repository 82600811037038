/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//Application Specific
import { CreationsData } from "../../../common/interfaces/creations/CreationTypes";
import { ELRecommendationsOutputJsonConfigData } from "../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import IDoc from "../../../editors/document/IDoc";
import store from "../../../stores/store";
import PatternOverlayUtils from "../workflows/pattern-overlay/utils/PatternOverlayUtils";
import CreationUtils from "./CreationUtils";
import { ELLayoutInfo } from "../../../common/interfaces/creations/ELSocialLayoutTypes";
import MathUtils from "../../../utils/MathUtils";

export class ELRecommendationStateManager {
    private static _instance: ELRecommendationStateManager | null = null;
    private readonly _layoutFloatTolerance = 0.001;

    static getInstance(): ELRecommendationStateManager {
        if (ELRecommendationStateManager._instance === null)
            ELRecommendationStateManager._instance = new ELRecommendationStateManager();
        return ELRecommendationStateManager._instance;
    }

    private _isLayoutSame(currentLayoutInfo: ELLayoutInfo | undefined, lastSavedLayoutInfo: ELLayoutInfo | undefined): boolean {
        if (currentLayoutInfo && lastSavedLayoutInfo) {
            let isSame = true;
            isSame = isSame && MathUtils.isFloatEqual(currentLayoutInfo.left, lastSavedLayoutInfo.left, this._layoutFloatTolerance);
            isSame = isSame && MathUtils.isFloatEqual(currentLayoutInfo.right, lastSavedLayoutInfo.right, this._layoutFloatTolerance);
            isSame = isSame && MathUtils.isFloatEqual(currentLayoutInfo.top, lastSavedLayoutInfo.top, this._layoutFloatTolerance);
            isSame = isSame && MathUtils.isFloatEqual(currentLayoutInfo.bottom, lastSavedLayoutInfo.bottom, this._layoutFloatTolerance);
            isSame = isSame && MathUtils.isFloatEqual(currentLayoutInfo.scaleX, lastSavedLayoutInfo.scaleX, this._layoutFloatTolerance);
            isSame = isSame && MathUtils.isFloatEqual(currentLayoutInfo.scaleY, lastSavedLayoutInfo.scaleY, this._layoutFloatTolerance);
            isSame = isSame && (currentLayoutInfo.layoutId === lastSavedLayoutInfo.layoutId);
            return isSame;
        }
        return (currentLayoutInfo === undefined) && (lastSavedLayoutInfo === undefined);
    }

    async hasStateChanged(projectData?: CreationsData, doc?: IDoc): Promise<boolean> {
        if (!projectData || !projectData.outputs || !doc) {
            return true;
        }

        const currentState = {
            assetId: store.getState().selectedMediaListReducer[0].assetId,
            selectedOverlayId: store.getState().recommendationWorkflowReducer.selectedOverlayId,
            layoutInfo: doc.getLayoutInfo
        };

        const outputConfigJson = await CreationUtils.getJsonFromProjectData(projectData) as ELRecommendationsOutputJsonConfigData;
        const imageSize = await doc.getOriginalSize();

        const lastSavedState = {
            assetId: outputConfigJson.assets[0].assetURN,
            selectedOverlayId: await PatternOverlayUtils.getSelectedOverlay(outputConfigJson),
            layoutInfo: await PatternOverlayUtils.getLayoutDetails(outputConfigJson, imageSize)
        };

        return !(currentState.assetId === lastSavedState.assetId
            && currentState.selectedOverlayId === lastSavedState.selectedOverlayId &&
            this._isLayoutSame(currentState.layoutInfo, lastSavedState.layoutInfo));
    }
}