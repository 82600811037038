/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum NotificationType {
    eEmail = "email"
}
export enum EmailTriggerSource {
    eMediaShare = "MEDIA_SHARE",
    eCreationShare = "CREATION_SHARE"
}

export enum EmailTemplateName {
    eMediaShareEmailTemplate = "elements-web_share_media", //template Name to be replaced later,
    eCreationShareEmailTemplate = "elements-web_share_creation"
}

export const EmailTemplateInfoMap: Record<EmailTriggerSource, EmailTemplateName> = {
    [EmailTriggerSource.eMediaShare]: EmailTemplateName.eMediaShareEmailTemplate,
    [EmailTriggerSource.eCreationShare]: EmailTemplateName.eCreationShareEmailTemplate
}

export interface NotificationRequestBody {
    readonly eventType : NotificationType,
    readonly eventSource : EmailTriggerSource,
    readonly eventData : Record<string, string>
}