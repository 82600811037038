/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { DocumentFormat } from "../../../common/interfaces/document/DocumentTypes";
import { ELDownloadData } from "../../../common/interfaces/stage/StageTypes";
import { UrlOpenTargets } from "../../../utils/Constants/Constants";
import Logger, { LogLevel } from "../../../utils/Logger";
import Utils from "../../../utils/Utils";
import IDocumentDownloader from "../../document/downloader/IDocumentDownloader";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";
import ELPIEImageFormatFactory from "../formats/ELPIEImageFormatFactory";
import ELPIEDoc from "../models/ELPIEDoc";

export default class ELPIEDocDownloader extends IDocumentDownloader {

    private _getDocumentURL(downloadData: ELDownloadData): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const format = DocumentFormat.PSD;
                const imageFormat = ELPIEImageFormatFactory.createFormat(format);

                const pieDoc = this.doc as ELPIEDoc;
                const engine = pieDoc.getEngine() as PIEEditingEngine;
                if (engine) {
                    imageFormat.writeFormat(pieDoc, engine, downloadData).then((file: File) => {
                        const imageURL = URL.createObjectURL(file);
                        resolve(imageURL);
                    });
                } else {
                    reject("invalid document engine!");
                }
            } catch (error) {
                reject("Failed to image URL!");
            }
        });
    }

    download(downloadData: ELDownloadData): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                Logger.log(LogLevel.INFO, "ELPIEDocDownloader - (download)", "downloading pie document");

                this._getDocumentURL(downloadData).then((imageURL) => {
                    Utils.downloadURI(imageURL, downloadData.name + ".psd", UrlOpenTargets.blank);
                    URL.revokeObjectURL(imageURL);
                    resolve(true);
                });
            } catch (error) {
                Logger.log(LogLevel.WARN, "ELPIEDocDownloader - (download) failed", error);
                reject("ELPIEDocDownloader - (download) failed");
            }
        });
    }

    async getDataURL(): Promise<string> {
        const downloadData: ELDownloadData = { name: Utils.getRandomUUID(), imageData: {} };
        return (await this._getDocumentURL(downloadData));
    }
}
