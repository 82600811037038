/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

//Adobe internal
import { Provider, lightTheme } from "@adobe/react-spectrum";
import { ProviderProps } from "@react-types/provider";

//Application specific
import { AppCode, LocaleUtils } from "../../../../utils/LocaleUtils";

type ELReactSpectrumV3ProviderProps = ProviderProps & React.RefAttributes<import("@react-types/shared").DOMRefValue<HTMLDivElement>>

export const ELReactSpectrumV3Provider = (props: ELReactSpectrumV3ProviderProps): React.ReactElement => {
    const reactSpectrumLocale = props.locale ?? LocaleUtils.getAppLocale(AppCode.reactSpectrum);

    return (
        <Provider theme={props.theme ?? lightTheme} colorScheme={props.colorScheme ?? "light"} locale={reactSpectrumLocale} {...props}></Provider>
    )
}