/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

//Application Specific
import AppRouteUtilView from "./AppRouteUtilView";
import IViewController from "../view/IViewController";
import { ViewAction } from "../view/IBaseController";
import { history } from "../utils/Utils";

class AppRouteUtil extends IViewController {
    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const view = React.createElement(AppRouteUtilView, { controller: this});
        const router = React.createElement(Router, { history }, view);

        ReactDOM.render(
            router,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    changeRoute(route: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: "",
            payload: route
        });
    }
}

export default new AppRouteUtil();