/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from "react";
import { ProgressCircle } from "@adobe/react-spectrum";

import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ERROR_THUMBDATA, FETCHING_THUMBDATA } from "../../../../utils/Constants/Constants";

import "./ELPreviewThumb.scss"

export interface ELPreviewThumbProps {
    onClick: (data: any) => void,
    isSelected: boolean,
    key: string,
    showTitle: boolean,
    showName: boolean,
    imageData?: string,
    name?: string
}

const ELPreviewThumb = (props: ELPreviewThumbProps): React.ReactElement => {

    const intlHandler = IntlHandler.getInstance();

    const progressCircleThumb = (
        <>
            <span className="preview-thumb-progress-container">
                <div className="preview-thumb-progress-loader">
                    <ProgressCircle isIndeterminate size="M" margin="auto"
                        aria-label={intlHandler.formatMessage("progress-circle")} />
                </div>
            </span>
            <p className="preview-thumb-label">{props.showName ? props.name : ""}</p>
        </>
    );

    const getThumbNail = (): React.ReactElement => {
        if (props.imageData === ERROR_THUMBDATA) {
            return <></>;
        }
        else if (props.imageData === FETCHING_THUMBDATA || props.imageData === undefined) {
            return progressCircleThumb;
        }
        else if (props.imageData) {
            return (
                <>
                    <div className={props.isSelected ? "preview-thumb-img-container selected" : "preview-thumb-img-container"}>
                        <img crossOrigin="anonymous" onClick={() => props.onClick(props.key)}
                            onKeyDown={() => props.onClick(props.key)} onContextMenu={(e) => e.preventDefault()}
                            className="preview-thumb-imgtag"
                            src={props.imageData}
                            title={props.showTitle ? props.name : ""}
                            alt={props.name}
                        />
                    </div>
                    <p className="preview-thumb-label">{props.showName ? props.name : ""}</p>
                </>
            );
        } else {
            return <></>;
        }
    }

    return (
        <div key={props.key} className="preview-thumb-container">
            {getThumbNail()}
        </div>
    )
}

export default ELPreviewThumb; 