/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React from "react";

// Adobe internal
import { Checkbox, Content, Dialog, Divider, Flex, Heading, Text, useDialogContainer } from "@adobe/react-spectrum";

// Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../../../view/components/atoms/el-button/ELButtonView";
import IViewController from "../../../../view/IViewController";
import { CreationWorkflowActions } from "../../../../common/interfaces/creations/CreationTypes";

import "./ELDeleteConfirmationDialogView.scss";

export interface ELDeleteConfirmationDialogProps {
    controller: IViewController,
}

export const ELDeleteConfirmationDialogView = (props: ELDeleteConfirmationDialogProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const dialog = useDialogContainer();

    let doNotShowConsent = false;

    const onConsentChange = (isSelected: boolean): void => {
        doNotShowConsent = isSelected;
    }

    const onRemoveClick = (): void => {
        props.controller.notify({ type: CreationWorkflowActions.confirmMediaDeletion, payload: doNotShowConsent });
        dialog.dismiss();
    }

    return (
        <Dialog UNSAFE_className={"delete-confirmation-dialog"}>
            <Heading>
                <div className="delete-confirmation-dialog__h1">{intlHandler.formatMessage("remove-media")}</div>
                <div className="delete-confirmation-dialog__h2">{intlHandler.formatMessage("photo-collage")}</div>
            </Heading>
            <Divider size={"S"} />
            <Content>
                <Flex direction="column" gap={"0.625rem"}>
                    <Text UNSAFE_className="delete-confirmation-dialog__text">
                        {intlHandler.formatMessage("remove-media-text")}
                    </Text>
                    <Checkbox data-testid="collage-delete-confirmation-dialog-do-not-show-me-again" onChange={onConsentChange} UNSAFE_style={{ paddingRight: 0 }}>
                        <Text UNSAFE_className="share-dialog__dont-show-text">{intlHandler.formatMessage("do-not-show-again")}</Text>
                    </Checkbox>
                    <Divider size={"S"} />
                    <Flex direction="row" justifyContent="end" gap={"1.25rem"} marginTop={"0.3rem"}>
                        <ELButton size="L" onClick={dialog.dismiss} >
                            <Text UNSAFE_className="dialog__cta-text delete-confirmation-dialog__cta-text">{intlHandler.formatMessage("cancel")}</Text>
                        </ELButton>
                        <ELButton dataTestId="collage-delete-confirmation-dialog-remove" size="L" variant="cta" onClick={() => onRemoveClick()} >
                            <Text UNSAFE_className="dialog__cta-text delete-confirmation-dialog__cta-text">{intlHandler.formatMessage("remove")}</Text>
                        </ELButton>
                    </Flex>
                </Flex>
            </Content>
        </Dialog>
    );
}