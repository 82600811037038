/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { ELAdobeAsset, OutputType, Resolution } from "@elements/elementswebcommon";

//Application Specific
import { StorageService } from "../../../../services/StorageServiceWrapper";
import ICreationsFullAssetProvider from "./ICreationsFullAssetProvider";
import CreationUtils from "../CreationUtils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import ReplaceBackgroundUtils from "../../workflows/replace-background/utils/ReplaceBackgroundUtils";
import { ReplaceBackgroundJobCreator } from "../../workflows/replace-background/utils/ReplaceBackgroundJobCreator";
import { ELReplaceBackgroundCreationRequestParams } from "../../../../common/interfaces/creations/ELReplaceBackgroundTypes";

class ELReplaceBackgroundFullAssetProvider extends ICreationsFullAssetProvider {
    
    protected async createMasterAssetEditRequestBody(): Promise<unknown> {
        try {
            const title = this.creationsData.title;
            const outputJson = await CreationUtils.getJsonFromProjectData(this.creationsData);
            const mapOfOverlayIdAssetPath = await ReplaceBackgroundUtils.getRecommendedAssetsIdForOverlays(outputJson);

            let assetIdSelected: string | undefined;
            let selectedOverlayId: string | undefined;

            mapOfOverlayIdAssetPath.forEach((assetId, overlayId) => {
                assetIdSelected = assetId;
                selectedOverlayId = overlayId;
            });
            if (assetIdSelected && selectedOverlayId) {
                const asset = await StorageService.getInstance().resolveAsset({ assetId: assetIdSelected }, "id");
                const requestJson = await this._getRequestBodyForOpenInDesktop(asset, selectedOverlayId, title);
                return Promise.resolve(requestJson);
            } else {
                Logger.log(LogLevel.ERROR, "ELReplaceBackgroundFullAssetProvider:createMasterAssetEditRequestBody, not able to get (assetIdSelected && selectedOverlayId)");
                return Promise.reject();
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELReplaceBackgroundFullAssetProvider: createMasterAsset failed with error: " + error);
            return Promise.reject();
        }
    }

    private async _getRequestBodyForOpenInDesktop(asset: ELAdobeAsset, overlayId: string, title?: string): Promise<unknown> {
        const requestCreatorObj = new ReplaceBackgroundJobCreator();
        const projectRequestParams: ELReplaceBackgroundCreationRequestParams = {
            assets: [asset],
            backgroundId: [overlayId],
            title: title,
            outputTypes: [OutputType.preview, OutputType.full],
            contentResolution: Resolution._1080p
        };
        try {
            const requestJson = await requestCreatorObj.getRequestBody(projectRequestParams);
            return Promise.resolve(requestJson);
        } catch (error) {
            Logger.log(LogLevel.ERROR,
                "ELReplaceBackgroundFullAssetProvider:_getRequestBodyForOpenInDesktop, ReplaceBackground project edit failed!", error);
            return Promise.reject();
        }
    }
}

export default ELReplaceBackgroundFullAssetProvider;