/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ElementsContentManager, ContentServer } from "@elements/elementswebcommon";
import { LocaleCompiler } from "../../modules/intlHandler/LocaleCompiler";

const DeploymentStackToContentServerMap: Map<string, ContentServer> = new Map([
    ["next", ContentServer.next],
    ["development", ContentServer.development],
    ["qe1", ContentServer.qe1],
    ["stage", ContentServer.stage],
    ["prod", ContentServer.prod],
    ["preprod", ContentServer.prod]
]);

export class ElementsContentManagerWrapper {
    private static _instance: ElementsContentManagerWrapper | null = null;
    private _contentManager: ElementsContentManager | null = null;
    private _dictionaryMap: Record<string, string> = {};

    static get instance(): ElementsContentManagerWrapper {
        if(!this._instance) {
            this._instance = new ElementsContentManagerWrapper();
        } 
        return this._instance;
    }

    async initialize(): Promise<void> {
        if (process.env.REACT_APP_ELEMENTS_DEPLOYMENT_STACK === undefined) {
            throw new Error("Content Server not found in env");
        }

        const contentServer = DeploymentStackToContentServerMap.get(process.env.REACT_APP_ELEMENTS_DEPLOYMENT_STACK);
        if(contentServer === undefined) {
            throw new Error("Content Server not found for deployment stack");
        }

        const response = await ElementsContentManager.initialize(contentServer);
        this._contentManager = response.elementsContentManager;
        this._dictionaryMap = response.dictionaryMap;
        
        LocaleCompiler.instance.addDictionary(this.dictionaryMap);
    }

    get contentManager(): ElementsContentManager | null {
        return this._contentManager;
    }

    get dictionaryMap(): Record<string, string> {
        return this._dictionaryMap;
    }
}