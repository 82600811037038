/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application specific
import ITemplateViewController from "../../../ITemplateViewController";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import { ELNavBarControllerAction } from "../../../../common/interfaces/navbar/ELNavBarTypes";
import { ELWhatsNewPanel } from "./ELWhatsNewPanel";
import ELWhatsNewNavView from "../../organism/el-whatsnew-nav/ELWhatsNewNavView";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";


class ELWhatsNewNav extends ITemplateViewController {
    private _whatsNewDialog: ELWhatsNewPanel;
    private readonly _whatsNewDialogContainer = "whatsnew-dialog-container";
    private readonly _shouldShowWhatsNewIcon : () => boolean;

    constructor(owner: IViewController, shouldShowWhatsNewIcon: () => boolean) {
        super(owner);
        this._whatsNewDialog = new ELWhatsNewPanel(this);
        this._shouldShowWhatsNewIcon = shouldShowWhatsNewIcon;
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const whatsNewNav = React.createElement(ELWhatsNewNavView, {
            controller: this,
            shouldShowWhatsNewIcon: this._shouldShowWhatsNewIcon()
        });

        ReactDOM.render(whatsNewNav, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELNavBarControllerAction.whatsNewIconClick:
                {
                    this._whatsNewDialog.createView(this.ensureHTMLElement(this._whatsNewDialogContainer));
                    IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.whatsNew,
                        IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success, IngestEventSubTypes.render, "manual")));
                    handled = true;
                    break;
                }
        }

        if (!handled) {
            handled = await this._owner.notify(action);
        }
        return handled;
    }
}

export default ELWhatsNewNav;
