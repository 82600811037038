/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { dict, point_t, blend_options_t, layer_effects_t } from "@piewasm/pie-web-npm-package";

export const TEMP_DIRECTORY = "/temp";
export const PIE_PERSISTENT_DIRECTORY = "/persistent";
export const PHOTOSHOP_TEMP_DIRECTORY = "/photoshoptemp";
export const PIE_WASM_SCRIPT = "./pie_web.js";

export type ELPIEDict = dict;
export type ELPIEPointT = point_t;
export type ELPIEBlendOptions = blend_options_t;
export type ELPIELayerEffects = layer_effects_t;