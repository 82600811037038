/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import ELCreateOnDemandView from "./ELCreateOnDemandView";
import { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../../view/IBaseController";
import { CreateOnDemandViewActions, ELCreateOnDemandAction, ELCreateOnDemandData, ELCreateOnDemandVariant } from "../../../../common/interfaces/creations/ELCreateOnDemandTypes";
import ITemplateViewController from "../../../ITemplateViewController";
import { ELCreateOnDemandUtils } from "../../../../workspaces/creations/utils/ELCreateOnDemandUtils";
import { WorkflowsName } from "../../../../workspaces/IWorkflow";

const getMappedPrefToCreationName = (creationName: WorkflowsName): string => {
    const clickedSuffix = "_CLICKED_COUNT";
    return creationName + clickedSuffix;
};

class ELCreateOnDemand extends ITemplateViewController {

    private _processCreationDataForBadge(creationData: ELCreateOnDemandData): ELCreateOnDemandData {
        const clickCountForCreation = this.prefData ? Number(this.prefData[getMappedPrefToCreationName(creationData.thumbId)]) : 0;
        return { ...creationData, showNewBadge: creationData.showNewBadge && clickCountForCreation < 1 };
    }

    private _getCreationList(): ELCreateOnDemandData[] {
        return ELCreateOnDemandUtils.getCreateOnDemandData().map(creationData => this._processCreationDataForBadge(creationData));
    }

    getPrefsKeys(): string[] {
        return ELCreateOnDemandUtils.getCreateOnDemandData().map(creationData => getMappedPrefToCreationName(creationData.thumbId));
    }

    private _handleCreationClicked(creationName: WorkflowsName): void {
        if (this.prefData) {
            const clickCount = this.prefData[getMappedPrefToCreationName(creationName)] as number;
            this.prefData[getMappedPrefToCreationName(creationName)] = clickCount + 1;
            this.updatePref();
        }
        this.viewDispatcher?.({
            type: CreateOnDemandViewActions.UPDATE_CREATIONS_DATA,
            payload: this._getCreationList()
        });
    }

    updatePref(): void {
        const updatePref: { key: string, value: string; }[] = [];
        this.getPrefsKeys().forEach(prefType => {
            updatePref.push({
                key: prefType,
                value: this.prefData ? this.prefData[prefType] as string : "0"
            });
        });
        this.writePrefs(updatePref);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this.readPrefs();
        this.viewDispatcher?.({
            type: CreateOnDemandViewActions.UPDATE_CREATIONS_DATA,
            payload: this._getCreationList()
        });
    }

    getView(viewProps?: unknown): React.ReactNode {
        const variant = viewProps as ELCreateOnDemandVariant;
        const view = React.createElement(ELCreateOnDemandView, {
            controller: this,
            variant: variant,
            creationList: this._getCreationList()
        });
        return this.getReactProvider(view, { history: true, store: false });
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const view = React.createElement(ELCreateOnDemandView, {
            controller: this,
            variant: "creations",
            creationList: this._getCreationList()
        });
        const provider = this.getReactProvider(view, { history: true, store: false });

        ReactDOM.render(
            provider,
            container
        );
    }

    destroyView(): void {
        this.updatePref();

        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    readPrefs(): void {
        this.prefData = {};
        this.getPrefsKeys().forEach(prefType => {
            const value = Number(localStorage.getItem(prefType)) ?? 0;
            if (this.prefData) {
                this.prefData[prefType] = value;
            }
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {

        let handled = false;
        switch (action.type) {
            case ELCreateOnDemandAction.workflowThumb: {
                const creationName = (action.payload as ELCreateOnDemandData).thumbId;
                this._handleCreationClicked(creationName);
                handled = await this._owner.notify(action);
                break;
            }
            case ELCreateOnDemandAction.viewAllDialog: {
                this._owner.notify(action);
                handled = true;
                break;
            }
            default: {
                break;
            }
        }
        if (!handled) {
            handled = await this._owner.notify(action);
        }
        return handled;
    }
}

export default ELCreateOnDemand;
