/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";
import { Provider } from "react-redux";

//Application specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import { MediaManagerControllerAction, MediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/MediaManagerTypes";
import { ViewAction } from "../../../../view/IBaseController";
import CollageView from "./CollageView";
import { ControllerAction } from "../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import ELCollageDoc from "../../../../editors/collage/ELCollageDoc";
import { CollageViewActions, ELCollageAssetInfo, ELCollageTemplate, ELSavedCollageData, ELCollagePanelWorkflowAction, ReplaceAssetInfo, BackgroundChangedStatus, ReplaceMediaStatus, ELCollageTemplateBackgroundMode, ELCollageShapeData, ELCollageProjectRequestParams } from "../../../../common/interfaces/creations/ELCollageTypes";
import DocumentFactory, { DocumentFactoryPayload } from "../../../../editors/document/DocumentFactory";
import { DocumentType } from "../../../../editors/document/IDoc";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../../IBaseWorkspace";
import CollagePanel from "../../../../view/components/templates/el-collage-panel/ELCollagePanel";
import { CreationsData, CreationsJobProjectSubType, CreationsJobStorageType, CreationsMode, CreationsStatus, CreationsStatusPayload, CreationWorkflowActions, CSAssetWithData, ELCreationWorkflowPayload, UNTITLED_INTL_KEY } from "../../../../common/interfaces/creations/CreationTypes";
import store from "../../../../stores/store";
import CollageUtils from "./utils/CollageUtils";
import { ELCollageLayoutControllerActions } from "../../../../common/interfaces/creations/collage/ELCollageLayoutTypes";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { CanvasZoomLevelAction, ELStageObjectData, ELImageData } from "../../../../common/interfaces/stage/StageTypes";
import { ELCollageTemplateBackground } from "../../../../common/interfaces/creations/ELCollageTypes";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import CreationsAction, { CreationsThumb } from "../../../../stores/actions/CreationsAction";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Constants from "../../../../utils/Constants/Constants";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { CollageTemplateData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import { DocumentActions, DocumentDirty, DocumentSaveStatus } from "../../../../common/interfaces/document/DocumentTypes";
import { ELGraphicPanelControllerAction } from "../../../../common/interfaces/creations/templates/ELGraphicPanelTypes";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import CreationWorkflow from "../CreationWorkflow";
import CreationUtils from "../../utils/CreationUtils";
import { ELCollageDocActions, ELCollageDocPayload } from "../../../../common/interfaces/creations/collage/ELCollageDocTypes";
import { SelectedMediaListType } from "../../../../view/components/organism/el-mediagrid/ELMediaGrid";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import { ReplaceMediaManagerMode, ReplaceMediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import DocActions from "../../../../stores/actions/DocActions";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestWorkflowTypes, IngestLogObjectValue, IngestLogObjectKey } from "../../../../utils/IngestConstants";
import { CollageBackgroundData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import CollageAction from "../../../../stores/actions/CollageAction";
import Utils from "../../../../utils/Utils";
import ELCollageHeader from "../../../../view/components/templates/el-creations-header/ELCollageHeader";
import { ZoomLevel } from "../../../../editors/stage/ELFabricStage";
import FullResMediaAction from "../../../../stores/actions/FullResMediaAction";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { CollageJobCreator } from "./utils/CollageJobCreator";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";

const DONOT_SHOW_DELETE_CONSENT_STORAGE_KEY = "donotShowDeleteConsent";

class Collage extends CreationWorkflow<ELCollageDocPayload> {
    // TODO/REVISIT: (samyjain): Creation/Recommendation Workflow Design update
    protected openProject(projectId: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    // TODO/REVISIT: (samyjain): Creation/Recommendation Workflow Design update

    private _doc?: ELCollageDoc;
    private _doNotRemoveProgressScreen?: boolean;
    private _collagePanel!: CollagePanel;
    private _creationsHeader!: ELCollageHeader;
    private _collagePayload!: ELCreationWorkflowPayload;
    private _autoSaveOnFirstRender: boolean;
    private _assetIdsToDelete: string[] = [];
    private readonly _minimumMediaDeleteWarningToastId = "minimum-media-delete-warning-toast-id";
    private readonly _collageDeleteMediaErrorToastId = "collage-delete-media-error-toast-id";
    private readonly _defaultCollageCategory = "horizontal";
    private _activeCanvasObject?: ELStageObjectData;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.collage);
        this._autoSaveOnFirstRender = false;
        this.mediaGridConfig = CollageUtils.getCollageMediaGridConfig();
    }

    protected getJobCreator(): CreationsJobCreator {
        return new CollageJobCreator();
    }

    private async _setCloudAssetPathForDoc(): Promise<void> {
        if (!this.projectId || !this.projectData) {
            Logger.log(LogLevel.ERROR, "Collage: (_setCloudAssetForDoc)", "project id or data not valid", this.projectId, this.projectData);
            return;
        }

        const outputAssetPath = (await CreationUtils.getCreationOutputAssetPathOrId(this.projectData)).path;
        this._doc?.notify({ type: DocumentActions.updateCloudAssetPath, payload: outputAssetPath });
    }

    protected async createAndRenderDoc(collageDocPayload: ELCollageDocPayload, documentDirty: DocumentDirty): Promise<void> {
        if (this._doc) {
            this._doc.destroy();
        }

        const docFactoryPayload: DocumentFactoryPayload = {
            docPayload: collageDocPayload
        };

        this._doc = await DocumentFactory.createDocumentWithStage(DocumentType.collage, this, docFactoryPayload) as ELCollageDoc;
        this._doc.markAndNotifyDocumentDirty(documentDirty);

        try {
            await this._doc.render(this.ensureHTMLElement("collage-edit-container"));
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Collage:_createAndRenderDoc: ", "Couldn't render document", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("photo-collage") }));
            this.startPreviousWorkflow();
        }

        Logger.log(LogLevel.INFO, "Document Object: ", this._doc);
    }

    private async _createCollageWithTemplate(templateId: string, assetList: ELAdobeAsset[]): Promise<void> {
        try {
            const templateData = await this._collagePanel.getCollageLayout.getTemplateData();
            const template: ELCollageTemplate = await CollageUtils.filterTemplateById(templateData, templateId);
            const assetInfoList: ELCollageAssetInfo[] = [];

            for (let index = 0; index < assetList.length; index++) {
                const asset = assetList[index];
                const assetId = asset.assetId;
                const objectURL: string | undefined = CollageUtils.getAssetObjectURL(assetId ?? "");

                const tempAsset: CSAssetWithData = {
                    id: uuid(),
                    assetURN: asset.assetId ?? "",
                    storageType: CreationsJobStorageType.RAPI,
                    mimeType: asset.format ?? "",
                    objectURL: objectURL
                };

                const tempAssetInfo = { asset: tempAsset };
                assetInfoList.push(tempAssetInfo);
                this._doc?.notify({ type: DocumentActions.zoomModified, payload: ZoomLevel.default });
            }

            const templateBackground: ELCollageTemplateBackground = {
                ...template.background,
                value: await CollageUtils.getBackground(template.background)
            };

            const collageDocPayload: ELCollageDocPayload = {
                template: { ...template, background: templateBackground },
                assetInfoList: assetInfoList,
                assetList: assetList,
                background: template.background
            };

            store.dispatch(CollageAction.updateTemplateData({ "id": template.id, "category": template.category, "layout": template.layouts.length, backgroundData: template.background }));

            this.createAndRenderDoc(collageDocPayload, DocumentDirty.DIRTY);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Collage:_createCollageWithTemplate: ", "could't fetch template data ", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-template-error"));
            throw new Error("could't fetch template data");
        }
    }

    protected logIngestData(operationStatus: string): void {
        try {
            const timeElapsed = Utils.getDateDifference(this.startDate, new Date(), "second");
            const allMedia = store.getState().selectedMediaListReducer;

            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            customEntries[IngestLogObjectCustomKey.timeTaken] = timeElapsed.toString();
            customEntries[IngestLogObjectCustomKey.totalCount] = allMedia.length.toString();

            const template = store.getState().collageReducer;

            if (template) {
                if (template.id && template.category) {
                    customEntries[IngestLogObjectCustomKey.layoutName] = template.id;
                    customEntries[IngestLogObjectCustomKey.layoutCategory] = template.category;
                }
                if (template.backgroundData) {
                    if (template.backgroundData.mode === ELCollageTemplateBackgroundMode.color)
                        customEntries[IngestLogObjectCustomKey.customColor] = template.backgroundData.value;
                    else if (template.backgroundData.mode === ELCollageTemplateBackgroundMode.image)
                        customEntries[IngestLogObjectCustomKey.background] = template.backgroundData.value;
                }
            }

            const eventContextId = Utils.getRandomUUID();
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.mode, CreationsJobProjectSubType.photoCollage, additionalLogInfoTemp));
            }

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                operationStatus, this.mode, CreationsJobProjectSubType.photoCollage, additionalLogInfo));
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `Collage:logIngestData:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    private _populatePanelData(savedCollageData: ELSavedCollageData): void {
        const assetInfoList = savedCollageData.assetInfoList;
        const csAssetList = assetInfoList.map((assetInfo) => {
            return assetInfo.asset;
        });

        this.populateSelectedMediaList(csAssetList);
    }

    private _updateCollageRoute(): void {
        if (!this.projectId) {
            Logger.log(LogLevel.ERROR, "Collage:_updateCollageRoute: ", "project id not valid");
            return;
        }

        HistoryUtils.replaceHistory(CollageUtils.getCollageHistoryState(this.projectId));
    }

    private async _openSavedCollage(savedCollageData: ELSavedCollageData): Promise<void> {
        this.startDate = new Date();
        this.mode = CreationsMode.render;
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        try {
            const templateId = savedCollageData.templateData.templateId;
            const assetInfoList = savedCollageData.assetInfoList;
            const templateData = await this._collagePanel.getCollageLayout.getTemplateData();
            const template: ELCollageTemplate = await CollageUtils.filterTemplateById(templateData, templateId);
            for (let index = 0; index < assetInfoList.length; index++) {
                const assetId = assetInfoList[index].asset.assetURN;
                let objectURL: string | undefined = CollageUtils.getAssetObjectURL(assetId);

                if (!objectURL) {
                    try {
                        objectURL = await AssetStorageUtils.getAndStoreAssetRendition(assetId);
                    } catch (error) {
                        objectURL = "";
                    }
                }

                assetInfoList[index] = {
                    ...assetInfoList[index],
                    asset: {
                        ...assetInfoList[index].asset,
                        objectURL: objectURL
                    }
                }
            }

            const templateBackground: ELCollageTemplateBackground = {
                ...savedCollageData.templateData.background,
                value: await CollageUtils.getBackground(savedCollageData.templateData.background)
            };

            const collageDocPayload: ELCollageDocPayload = {
                template: { ...template, background: templateBackground },
                assetInfoList: assetInfoList,
                background: savedCollageData.templateData.background
            };

            store.dispatch(CollageAction.updateTemplateData({ "id": template.id, "category": template.category, "layout": template.layouts.length, "backgroundData": collageDocPayload.background }));
            await this.createAndRenderDoc(collageDocPayload, DocumentDirty.NON_DIRTY);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.render, CreationsJobProjectSubType.photoCollage, additionalLogInfo));

            if(this._doc?.hasRenderingError()) {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.open, IngestEventSubTypes.error, "Missing media"));
            }
        } catch (error) {
            additionalLogInfo[IngestLogObjectKey.errorDescription] = "Couldn't get template or background data used in collage";
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.render, CreationsJobProjectSubType.photoCollage, additionalLogInfo));
            throw new Error("Couldn't get template or background data used in collage");
        }
    }

    private _preprocessCollageEdit(): void {
        if (!this.projectId) {
            Logger.log(LogLevel.ERROR, "Collage:_preprocessCollageEdit: ", "project id not valid");
            return;
        }

        const creationsThumb: CreationsThumb = { id: this.projectId, objectURL: "" };
        store.dispatch(CreationsAction.updateThumb(creationsThumb));
    }

    private _getEditCreationRequestParams(projectId: string, projectData: CreationsData): ELCollageProjectRequestParams {
        const requestParams = {
            assets: store.getState().selectedMediaListReducer,
            title: this.getTrueTitleForRequest(projectData.title)
        };
        return requestParams;
    }

    private async _editCollageCreation(): Promise<void> {
        this.mode = CreationsMode.update;
        this.startDate = new Date();
        if (!this.projectId || !this.projectData) {
            Logger.log(LogLevel.ERROR, "Collage:_editCollage: ", "project id or project data not valid");
            this.logIngestData(IngestEventSubTypes.error);
            return;
        }

        const requestParams = this._getEditCreationRequestParams(this.projectId, this.projectData);

        try {
            this._preprocessCollageEdit();
            const requestJson = this.createRequestJson(requestParams);
            this.projectId = await this.editCreation(this.projectId, requestJson);
            this.logIngestData(IngestEventSubTypes.success);
        } catch (error) {
            Logger.log(LogLevel.WARN, "Collage:_editCollage: , project edit failed!" + error);
            this.logIngestData(IngestEventSubTypes.error);
            return;
        }

        this.projectData = await this.getProjectData(this.projectId);
    }

    private async _getAndSetProjectMetadata(projectId: string): Promise<string> {
        this.projectId = projectId;
        try {
            this.projectData = await this.getProjectData(this.projectId);
            const appMetadata = await CreationUtils.getAppMetadata(this.projectData) as string;
            return appMetadata;
        } catch (error) {
            throw new Error("couldn't get project data or app metadata in the output asset not set " + error);
        }
    }

    private async _openCollage(collagePayload: ELCreationWorkflowPayload): Promise<void> {
        const collageStatusPayload: CreationsStatusPayload = collagePayload.payload as CreationsStatusPayload;
        this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("fetching-collage"));
        try {
            const appMetadata = await this._getAndSetProjectMetadata(collageStatusPayload.projectId);
            const savedCollageData = JSON.parse(JSON.stringify(appMetadata)) as ELSavedCollageData;
            this._openSavedCollage(savedCollageData);
            this._populatePanelData(savedCollageData);
            this._updateCollageRoute();
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Collage:_openCollage: ", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-open-error"));
            this.startPreviousWorkflow();
        }
    }

    private async _createCollageCreation(assetList: ELAdobeAsset[]): Promise<void> {
        const defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
        const requestParams: ELCollageProjectRequestParams = {
            title: defaultTitle,
            assets: assetList
        };
        try {
            const requestJson = this.createRequestJson(requestParams);
            this.projectId = await this.createCreation(requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "Collage:_createCollageCreation: project creation failed!" + error);
            return;
        }

        this.projectData = await this.getProjectData(this.projectId);
        this._updateCollageRoute();
    }

    private async _getInitialTemplate(layoutCount: number, category: string): Promise<CollageTemplateData> {
        const templateData = await this._collagePanel.getCollageLayout.getTemplateData();
        let filteredTemplates = CollageUtils.filterTemplateByLayoutCount(templateData, layoutCount);
        filteredTemplates = filteredTemplates.filter(element => element.category === category);
        const randomTemplate = filteredTemplates[Math.floor(Math.random() * filteredTemplates.length)];
        return randomTemplate;
    }

    private async _createCollage(assetList: ELAdobeAsset[]): Promise<void> {
        this.startDate = new Date();
        this.mode = CreationsMode.create;
        try {
            const assetCount = store.getState().selectedMediaListReducer.length;
            const template: CollageTemplateData = await this._getInitialTemplate(assetCount, this._defaultCollageCategory);
            this._createCollageWithTemplate(template.id, assetList);
            this.logIngestData(IngestEventSubTypes.success);
        } catch (error) {
            if (this.projectId) {
                CreationUtils.deleteCreation(this.projectId);
            }
            Logger.log(LogLevel.WARN, "Collage - (_createCollage) Couldn't create collage ", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-create-error"));
            this.logIngestData(IngestEventSubTypes.error);
            this.startPreviousWorkflow();
        }
    }

    async enterProject(collagePayload: ELCreationWorkflowPayload): Promise<void> {
        switch (collagePayload.initMode) {
            case CreationsMode.create: {
                this._autoSaveOnFirstRender = true;
                this._doNotRemoveProgressScreen = true;
                this._createCollage(collagePayload.payload as ELAdobeAsset[]);
                break;
            }
            case CreationsMode.render: {
                this._autoSaveOnFirstRender = false;
                this._openCollage(collagePayload);
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "Collage (_enterCollage) - Invalid collage mode");
                break;
            }
        }
    }

    private _shouldUseSameTemplate(newCollageMedia: ELAdobeAsset[]): boolean {
        if (this._doc?.getAssetInfoList.length === newCollageMedia.length)
            return true;
        return false;
    }

    private async _onTemplateChanged(templateId: string): Promise<void> {
        if (this._doc?.hasRenderingError()) {
            if (!Utils.isMessageToastVisible(this._collageDeleteMediaErrorToastId)) {
                const message = IntlHandler.getInstance().formatMessage("collage-missing-photo-error");
                ToastUtils.error(message, { id: this._collageDeleteMediaErrorToastId });
            }
            return;
        }

        this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("generating-high-resolution"));
        try {
            const assetList: ELAdobeAsset[] = [];
            const assetInfoList = this._doc?.getAssetInfoList;
            if (assetInfoList) {
                for (const assetInfo of assetInfoList) {
                    assetList.push({ assetId: assetInfo.asset.assetURN });
                }
            }
            const assetListForCollage = assetList.length > 0 ? assetList : store.getState().selectedMediaListReducer;
            await this._createCollageWithTemplate(templateId, assetListForCollage);
        } catch (error) {
            this.viewDispatcher?.call(this.viewDispatcher, {
                type: CollageViewActions.collageStatus,
                payload: CreationsStatus.success
            });
        }
    }

    private async _onShuffleTemplate(): Promise<void> {
        if (this._doc?.hasRenderingError()) {
            if (!Utils.isMessageToastVisible(this._collageDeleteMediaErrorToastId)) {
                const message = IntlHandler.getInstance().formatMessage("collage-missing-photo-error");
                ToastUtils.error(message, { id: this._collageDeleteMediaErrorToastId });
            }
            return;
        }

        const templateData = await this._collagePanel.getCollageLayout.getTemplateData();
        const currentLayoutCount = this._doc?.getTemplate.layouts.length;
        if (currentLayoutCount) {
            let filteredTemplates = CollageUtils.filterTemplateByLayoutCount(templateData, currentLayoutCount);
            const currentTemplateId = this._doc?.getTemplate.id;
            if (currentTemplateId)
                filteredTemplates = filteredTemplates.filter(element => element.id !== currentTemplateId);

            const randomTemplate = filteredTemplates[Math.floor(Math.random() * filteredTemplates.length)];
            this._onTemplateChanged(randomTemplate.id);
        }
    }

    private _getAssetListAfterMediaDeletion(): ELAdobeAsset[] {
        const updatedAssets: SelectedMediaListType = store.getState().selectedMediaListReducer;
        const assetInfoList = this._doc?.getAssetInfoList;
        if (assetInfoList) {
            const assetIdsUsed = assetInfoList.map((assetInfo) => { return assetInfo.asset.assetURN });
            const filteredAssetIds = assetIdsUsed.filter((assetId, index) => assetIdsUsed.indexOf(assetId) !== index);
            const assetIdsUsedMoreThanOnce = Array.from(new Set(filteredAssetIds));
            this._assetIdsToDelete = this._assetIdsToDelete.filter(assetId => !assetIdsUsedMoreThanOnce.includes(assetId));
        }
        return updatedAssets.filter(asset => !this._assetIdsToDelete.includes(asset.assetId as string));
    }

    private async _confirmMediaDeletion(): Promise<void> {
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.click, IngestEventSubTypes.mediaDeleted));
        let updatedAssets: SelectedMediaListType = store.getState().selectedMediaListReducer;
        this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("generating-high-resolution"));

        const minImageCount = CollageUtils.getCollageMediaGridConfig().minImageCount;
        if (!minImageCount || (minImageCount && updatedAssets.length > minImageCount)) {
            updatedAssets = this._getAssetListAfterMediaDeletion();
        }

        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(updatedAssets));
        const currentTemplateCategory = this._doc?.getTemplate.category;
        const template: CollageTemplateData = await this._getInitialTemplate(updatedAssets.length, currentTemplateCategory ?? this._defaultCollageCategory);
        this._createCollageWithTemplate(template.id, updatedAssets);
    }

    private _canDeleteMedia(): boolean {
        const updatedAssets: SelectedMediaListType = store.getState().selectedMediaListReducer;
        return (updatedAssets.length > (CollageUtils.getCollageMediaGridConfig().minImageCount as number));
    }

    private _showMediaDeleteWarning(): void {
        const updatedAssets: SelectedMediaListType = store.getState().selectedMediaListReducer;
        if (updatedAssets.length === (CollageUtils.getCollageMediaGridConfig().minImageCount as number)) {
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.delete, IngestEventSubTypes.error, "Minimum image limit"));
            if (!Utils.isMessageToastVisible(this._minimumMediaDeleteWarningToastId)) {
                ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-delete-image-collage"), { id: this._minimumMediaDeleteWarningToastId });
            }
            return;
        }

        if (updatedAssets.length < (CollageUtils.getCollageMediaGridConfig().minImageCount as number)) {
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.delete, IngestEventSubTypes.error, "Single media used"));
            if (!Utils.isMessageToastVisible(this._minimumMediaDeleteWarningToastId)) {
                ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-delete-single-image-collage"), { id: this._minimumMediaDeleteWarningToastId });
            }
            return;
        }
    }

    private async _onMediaDeleted(stageObjData: ELStageObjectData): Promise<void> {
        if (this._doc?.hasRenderingError()) {
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.delete, IngestEventSubTypes.error, "Missing media"));
            ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-missing-photo-error"));
        } else {
            if (!this._canDeleteMedia()) {
                this._showMediaDeleteWarning();
                return;
            }

            this._assetIdsToDelete = [(stageObjData.payload as ELCollageShapeData).asset.assetURN];
            const doNotShowPreference = localStorage.getItem(DONOT_SHOW_DELETE_CONSENT_STORAGE_KEY);
            if (doNotShowPreference === "true") {
                this._confirmMediaDeletion();
            }
            else {
                this.viewDispatcher?.call(this.viewDispatcher, {
                    type: CollageViewActions.showDeleteConfirmationDialog,
                    payload: true
                });
            }
        }
    }

    private async _onMediaChanged(newCollageMedia: ELAdobeAsset[]): Promise<void> {
        this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("generating-high-resolution"));
        const templateId = this._doc?.getTemplate.id;

        if (templateId && this._shouldUseSameTemplate(newCollageMedia)) {
            this._createCollageWithTemplate(templateId, newCollageMedia);
        } else {
            const currentTemplateCategory = this._doc?.getTemplate.category;
            const template: CollageTemplateData = await this._getInitialTemplate(newCollageMedia.length, currentTemplateCategory ?? this._defaultCollageCategory);
            this._createCollageWithTemplate(template.id, newCollageMedia);
        }
    }

    private async _download(imageData: ELImageData): Promise<void> {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
        try {
            if (this._doc?.hasRenderingError()) {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.download, IngestEventSubTypes.error, "Missing media"));
                ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-missing-photo-error"));
                additionalLogInfo[IngestLogObjectKey.errorDescription] = "Missing media";
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.error, IngestEventTypes.download, CreationsJobProjectSubType.photoCollage, additionalLogInfo));
            } else {
                const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
                    { media: IntlHandler.getInstance().formatMessage("photo-collage").toLowerCase() });
                ToastUtils.info(message);
                this._doc?.notify({ type: DocumentActions.download, payload: { name: this.projectData?.title, imageData: imageData } });
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.success, IngestEventTypes.download, CreationsJobProjectSubType.photoCollage, additionalLogInfo));
            }
        } catch (e) {
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.download, CreationsJobProjectSubType.photoCollage, additionalLogInfo));
        }
    }

    private _onCollageImageBackgroundChanged(action: ControllerAction): void {
        if (this._doc?.hasRenderingError()) {
            if (!Utils.isMessageToastVisible(this._collageDeleteMediaErrorToastId)) {
                const message = IntlHandler.getInstance().formatMessage("collage-missing-photo-error");
                ToastUtils.error(message, { id: this._collageDeleteMediaErrorToastId });
            }
            return;
        }

        const updateBackgroundData = { "mode": ELCollageTemplateBackgroundMode.image, "value": (action.payload as CollageBackgroundData).id };
        store.dispatch(CollageAction.updateBackgroundData({ "backgroundData": updateBackgroundData }));

        this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("collage-changing-background"));
        this._doc?.notify(action);
    }

    private _onCollageColorBackgroundChanged(action: ControllerAction): void {
        if (this._doc?.hasRenderingError()) {
            if (!Utils.isMessageToastVisible(this._collageDeleteMediaErrorToastId)) {
                const message = IntlHandler.getInstance().formatMessage("collage-missing-photo-error");
                ToastUtils.error(message, { id: this._collageDeleteMediaErrorToastId });
            }
            return;
        }

        const updateBackgroundData = { "mode": ELCollageTemplateBackgroundMode.color, "value": (action.payload as string) };
        store.dispatch(CollageAction.updateBackgroundData({ "backgroundData": updateBackgroundData }));

        this._doc?.notify(action);
    }

    private _shouldAutoSaveOnFirstRender(status: CreationsStatus): boolean {
        return (status === CreationsStatus.success && this._autoSaveOnFirstRender);
    }

    private _hasCollageCreationFailed(): boolean {
        return !this.projectId || !this.projectData;
    }

    private _shouldDeleteOnRenderFailed(status: CreationsStatus): boolean {
        return (status === CreationsStatus.error && this._collagePayload.initMode === CreationsMode.create);
    }

    private _hasRenderFailed(status: CreationsStatus): boolean {
        return (status === CreationsStatus.error) ||
            (this._doc ? (this._doc.hasRenderingError() && !this.projectId && !this.projectData) : true);
    }

    private _hasWorkflowError(status: CreationsStatus): boolean {
        return this._hasRenderFailed(status) || this._hasCollageCreationFailed();
    }

    private _notifySubViews(): void {
        this._creationsHeader.notify({ type: ELCreationsHeaderControllerAction.updateCreationsData, payload: this.projectData });
    }

    private async _processFirstRender(status: CreationsStatus): Promise<void> {
        if (this._shouldAutoSaveOnFirstRender(status) && this._canSaveDocument()) {
            await this._createCollageCreation(this._collagePayload.payload as ELAdobeAsset[]);
            this._notifySubViews();
            store.dispatch(CollageAction.updateStatus(status));
            this._autoSaveOnFirstRender = false;
            await this._save(true, "generating-high-resolution");
        } else {
            this._notifySubViews();
            store.dispatch(CollageAction.updateStatus(status));
        }
    }

    private async _onCollagedRendered(status: CreationsStatus): Promise<void> {
        if (!this._doNotRemoveProgressScreen) {
            this.viewDispatcher?.call(this.viewDispatcher, {
                type: CollageViewActions.collageStatus,
                payload: status
            });
        } else {
            this._doNotRemoveProgressScreen = false;
        }

        let firstRenderFailed = false;
        try {
            await this._processFirstRender(status);
        } catch (error) {
            firstRenderFailed = true;
        }
        if (this._doc) {
            store.dispatch(DocActions.updateDocumentError(this._doc.hasRenderingError()));
        }

        if (firstRenderFailed || this._hasWorkflowError(status)) {
            Logger.log(LogLevel.ERROR, "Collage:_onCollagedRendered: ", "rendered failed for document");
            if (this._collagePayload.initMode === CreationsMode.render) {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-open-error"));
            } else {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-create-error"));
            }
            if (this._shouldDeleteOnRenderFailed(status)) {
                this.projectId && CreationUtils.deleteCreation(this.projectId);
            }
            this.startPreviousWorkflow();
        }
    }

    private async _isDocumentSavedBefore(): Promise<boolean> {
        let isDocumentSavedBefore = false;
        if (this.projectData) {
            try {
                const outputAsset = await CreationUtils.getCreationOutputAsset(this.projectData);
                isDocumentSavedBefore = !!outputAsset;
            } catch (error) {
                isDocumentSavedBefore = false;
            }
        }
        return isDocumentSavedBefore;
    }

    private async _save(showProgress = true, savingProgressText = "saving-creation"): Promise<void> {
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            return;
        }

        store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));

        if (this._doc?.hasRenderingError()) {
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveError));
            ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-save-failed"));
            return;
        }

        if (showProgress) {
            this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage(savingProgressText));
        }

        try {
            const isDocumentSavedBefore = await this._isDocumentSavedBefore();
            if (isDocumentSavedBefore) {
                await this._editCollageCreation();
            }

            await this._setCloudAssetPathForDoc();
            await this._doc?.save();
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
        } catch (error) {
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveError));
            Logger.log(LogLevel.ERROR, "Collage:_save: ", "error in saving document", error);
        }

        if (showProgress) {
            this._updateViewStatusAndProgressText(CreationsStatus.success, IntlHandler.getInstance().formatMessage("saving-creation"));
        }
    }

    private _onDocumentDirty(dirty: DocumentDirty): void {
        if (this._doc) {
            store.dispatch(DocActions.updateDocumentError(this._doc.hasRenderingError()));
        }

        store.dispatch(DocActions.updateDocumentDirty(dirty));
    }

    private _canSaveDocument(): boolean {
        return this._doc?.isDocumentDirty === DocumentDirty.DIRTY && !this._doc?.hasRenderingError();
    }

    private async _waitForSaveComplete(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            await Utils.wait(2000);
            await this._waitForSaveComplete(waitCount + 1);
        }
    }

    private async _saveAndStartPreviousWorkflow(): Promise<void> {
        if (this._autoSaveOnFirstRender) {
            Logger.log(LogLevel.INFO, "call to start previous workflow, auto save not complete - ignored");
            return;
        }

        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("saving-creation"));
            await this._waitForSaveComplete();
        } else if (this._canSaveDocument()) {
            await this._save();
        }

        this.startPreviousWorkflow();
    }

    private _onReplaceErrorImage(data: ELStageObjectData): void {
        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.replaceMediaManager,
            payload: {
                assetId: (data.payload as ELCollageShapeData).asset.assetURN,
                data: (data.payload as ELCollageShapeData).layoutId,
                mode: ReplaceMediaManagerMode.addingMissingMedia,
                mediaGridConfig: this.mediaGridConfig
            }
        };

        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
        this._owner.notify(workspaceAction);
    }

    private _onBackgroundChanged(status: BackgroundChangedStatus): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: CollageViewActions.collageStatus,
            payload: CreationsStatus.success,
        });

        if (status === BackgroundChangedStatus.error) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-background-changed-failed"));
        }
    }

    private _updateViewStatusAndProgressText(status: CreationsStatus, progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: CollageViewActions.collageStatus,
            payload: status,
        });

        this.viewDispatcher?.call(this.viewDispatcher, {
            type: CollageViewActions.collageProgressText,
            payload: progressText
        });
    }


    private _onReplaceMedia(replaceAssetInfo: ReplaceAssetInfo): void {
        this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("collage-replacing-image"));
        this._doc?.notify({ type: ELCollageDocActions.replaceMedia, payload: replaceAssetInfo });
    }

    private _onReplaceMediaStatus(status: ReplaceMediaStatus): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: CollageViewActions.collageStatus,
            payload: CreationsStatus.success,
        });

        if (status === ReplaceMediaStatus.error) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("collage-media-update-failed"));
        }
    }

    async initialize(dispatch?: React.Dispatch<ViewAction>): Promise<void> {
        super.initialize(dispatch);

        this._collagePanel = new CollagePanel(this);
        await this._collagePanel.createView(this.ensureHTMLElement("collage-right-panel-container"));

        this._creationsHeader = new ELCollageHeader(this, this.shareOptions);
        await this._creationsHeader.createView(this.ensureHTMLElement("collage-header-container"));

        this.createFeedbackView(this.ensureHTMLElement("feedback-popover-container"));


        this.enterProject(this._collagePayload);
    }

    createView(container: HTMLElement): Promise<void> {
        super.createView(container)

        const element = React.createElement(CollageView, {
            controller: this
        });

        const provider = React.createElement(Provider, { store }, element);

        return new Promise((resolve) => {
            ReactDOM.render(
                provider,
                container,
                () => { resolve(); });
        });
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this._doc?.destroy();
        this._doc = undefined;
    }

    async startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): Promise<void> {
        super.startWorkflow(containerId, prevWorkflow, action);
        await this.createView(this.ensureHTMLElement(containerId));

        const collagePayload: ELCreationWorkflowPayload = {
            initMode: action?.initMode as CreationsMode,
            payload: action?.payload
        }

        this._collagePayload = collagePayload;
    }

    endWorkflow(): void {
        super.endWorkflow();
        store.dispatch(FullResMediaAction.clearAllData());
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private async _updateProjectData(projectId: string): Promise<boolean> {
        try {
            this.projectData = await this.getProjectData(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "Collage:_updateProjectData: Could not fetch collage project data! " + error);
            return false;
        }
        return true;
    }

    private async _onCollageTitleChanged(): Promise<void> {
        if (this.projectId) {
            await this._updateProjectData(this.projectId);
        }
    }

    protected async ingestCreationFeedback(eventSubType: string): Promise<void> {
        try {
            const customEntries: Record<string, string> = {};
            const template = store.getState().collageReducer;
            if (template) {
                if (template.id && template.category) {
                    customEntries[IngestLogObjectCustomKey.layoutName] = template.id;
                    customEntries[IngestLogObjectCustomKey.layoutCategory] = template.category;
                }
                if (template.backgroundData) {
                    if (template.backgroundData.mode === ELCollageTemplateBackgroundMode.color)
                        customEntries[IngestLogObjectCustomKey.customColor] = template.backgroundData.value;
                    else if (template.backgroundData.mode === ELCollageTemplateBackgroundMode.image)
                        customEntries[IngestLogObjectCustomKey.background] = template.backgroundData.value;
                }
            }
            for (const key in customEntries) {
                const additionalLogInfoTemp: Record<string, string> = {};
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, eventSubType, IngestUtils.getIngestCreationsWorkflowName(WorkflowsName.collage), additionalLogInfoTemp));
            }
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `Collage:ingestCreationFeedback: Dunamis Logging Error:${e as string}`);
        }
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case DocumentActions.markDocumentDirty: {
                this._onDocumentDirty(action.payload as DocumentDirty);
                handled = true;
                break;
            }
            case DocumentActions.replaceErrorImage: {
                this._onReplaceErrorImage(action.payload as ELStageObjectData);
                handled = true;
                break;
            }
            case ELCollageLayoutControllerActions.templateChanged: {
                const templateId = action.payload as string;
                const additionalLogInfo: Record<string, string> = {};
                additionalLogInfo[IngestLogObjectKey.eventCount] = templateId;
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.layout, CreationsJobProjectSubType.photoCollage, additionalLogInfo));

                this._onTemplateChanged(templateId);
                handled = true;
                break;
            }
            case ELCollagePanelWorkflowAction.manageMedia: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage, IngestEventTypes.click, IngestEventSubTypes.manageMedia));
                const workspacePayload: WorkspacePayload = {
                    startWorkflow: WorkflowsName.mediaManager,
                    payload: this.mediaGridConfig
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                const imageData = action.payload as ELImageData;
                this._download(imageData);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.save: {
                await this._save();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSave: {
                this.startPreviousWorkflow();
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.backDialogDontSave, CreationsJobProjectSubType.photoCollage));
                handled = true;
                break;
            }
            case ELGraphicPanelControllerAction.imageBackgroundChanged: {
                this._onCollageImageBackgroundChanged(action);
                handled = true;
                break;
            }
            case ELGraphicPanelControllerAction.colorBackgroundChanged: {
                this._onCollageColorBackgroundChanged(action);
                handled = true;
                break;
            }
            case MediaManagerControllerAction.mediaDeleted: {
                if(this._activeCanvasObject ) {
                    this._onMediaDeleted(this._activeCanvasObject)
                }
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent: {
                this._doc?.notify(action);
                handled = true;
                break;
            }
            case ELCollageLayoutControllerActions.shuffle: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.shuffle, CreationsJobProjectSubType.photoCollage));
                this._onShuffleTemplate();
                handled = true;
                break;
            }
            case WorkflowActionType.ingest:
                {
                    this._ingest(action.payload as Record<string, string>);
                    handled = true;
                    break;
                }
            case DocumentActions.activeObjectChange: {
                this._activeCanvasObject = action.payload as ELStageObjectData;

                this.viewDispatcher?.call(this.viewDispatcher, {
                    type: CollageViewActions.showActiveObjectMenu,
                    payload: this._activeCanvasObject ? true : false
                });

                break;
            }
            default: {
                Logger.log(LogLevel.DEBUG, "Collage(ControllerAction): Invalid action" + action);
                break;
            }
        }

        if (!handled)
            handled = await super.notify(action);

        return handled;
    }

    /**
    * Handles core actions
    * @param action WorkflowAction
    */
    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case MediaManagerWorkflowAction.mediaSelection: {
                const newCollageMedia = action.payload;
                this._onMediaChanged(newCollageMedia as ELAdobeAsset[]);
                handled = true;
                break;
            }
            case CreationWorkflowActions.updateCreationStatus: {
                if (!this.projectId) {
                    Logger.log(LogLevel.WARN, "Collage:notifyWorkflow: ", "project id empty");
                } else {
                    await CreationUtils.updateCreationStatus(this.projectId, action.payload as CreationsStatus);
                }
                handled = true;
                break;
            }
            case CreationWorkflowActions.renameCreationTitle: {
                this._onCollageTitleChanged();
                handled = true;
                break;
            }
            case CreationWorkflowActions.creationRenderStatus: {
                this._onCollagedRendered(action.payload as CreationsStatus);
                handled = true;
                break;
            }
            case CreationWorkflowActions.backgroundChanged: {
                this._onBackgroundChanged(action.payload as BackgroundChangedStatus);
                handled = true;
                break;
            }
            case CreationWorkflowActions.replaceMediaStatus: {
                this._onReplaceMediaStatus(action.payload as ReplaceMediaStatus);
                handled = true;
                break;
            }
            case WorkspaceActionType.startPreviousWorkflow: {
                this._saveAndStartPreviousWorkflow();
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue: {
                this._doc?.notify(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill: {
                this._doc?.notify(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFit: {
                this._doc?.notify(action);
                handled = true;
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceActiveMedia: {                
                const collageShapeData = this._activeCanvasObject?.payload as ELCollageShapeData;
                
                const workspacePayload: WorkspacePayload = {
                    startWorkflow: WorkflowsName.replaceMediaManager,
                    payload: {
                        assetId: collageShapeData.asset.assetURN,
                        mode: ReplaceMediaManagerMode.replacingMedia,
                        data: collageShapeData.layoutId,
                        mediaGridConfig: this.mediaGridConfig
                    }
                };

                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceMediaSelection: {
                const replaceAssetInfo = action.payload as ReplaceAssetInfo;
                this._onReplaceMedia(replaceAssetInfo);
                handled = true;
                break;
            }
            case CreationWorkflowActions.confirmMediaDeletion: {
                const doNotShowSelected = action.payload as string;
                localStorage.setItem(DONOT_SHOW_DELETE_CONSENT_STORAGE_KEY, doNotShowSelected);
                this._confirmMediaDeletion();
                handled = true;
                break;
            }
            default: {
                handled = await super.notifyWorkflow(action as WorkflowAction);
                break;
            }
        }
        return handled;
    }
}

export default Collage;
