/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import { useEffect } from "react";

//Application Specific
import IViewController from "../../view/IViewController";
import { PlatformUtils } from "../PlatformUtils";
import { CanvasZoomLevelAction } from "../../common/interfaces/stage/StageTypes";
import { KeyboardKey } from "../KeyboardConstants";

interface ELZoomEventListenerProps {
    controller: IViewController,
    unmountCB: () => void
}

export const useKeyboardZoomEventListener = (props: ELZoomEventListenerProps): void => {
    const addZoomEventListener = (e: KeyboardEvent): void => {
        const isRunningOnMac = PlatformUtils.isRunningOnMacOS();
        if (e.key === KeyboardKey.minus) {
            if ((isRunningOnMac && e.metaKey) || (!isRunningOnMac && e.ctrlKey)) {
                e.preventDefault();
                props.controller.notify({ type: CanvasZoomLevelAction.zoomOutEvent });
            }
        }
        else if (e.key === KeyboardKey.equals) {
            if ((isRunningOnMac && e.metaKey) || (!isRunningOnMac && e.ctrlKey)) {
                e.preventDefault();
                props.controller.notify({ type: CanvasZoomLevelAction.zoomInEvent });
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', addZoomEventListener);

        return () => {
            props.unmountCB();
            document.removeEventListener('keydown', addZoomEventListener);
        };
    }, []);
};