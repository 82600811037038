/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer, useState } from "react";
import store from "../../../../stores/store";
import { useSelector } from "react-redux";

//Adobe Internal
import { DialogContainer, Flex } from "@adobe/react-spectrum";

//Application Specific
import IViewController from "../../../../view/IViewController";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ViewAction } from "../../../../view/IBaseController";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import Utils from "../../../../utils/Utils";
import { CollageViewActions } from "../../../../common/interfaces/creations/ELCollageTypes";
import DocActions from "../../../../stores/actions/DocActions";
import { ZoomLevel } from "../../../../editors/stage/ELFabricStage";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import { RootState } from "../../../../stores/store";
import { ELDeleteConfirmationDialogView } from "./ELDeleteConfirmationDialogView";
import { ELCreationProgressView } from "../../../../view/components/organism/el-creation-progress-view/ELCreationProgressView";
import { useUnloadHandler } from "../../../../utils/hooks/useUnloadHandler";
import { useKeyboardZoomEventListener } from "../../../../utils/hooks/useKeyboardZoomEventListener";
import { ELReactSpectrumV3Provider } from "../../../../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { ELQuickActionContainer } from "../../../../view/components/organism/el-quick-action-container/ELQuickActionContainer";
import CollageUtils from "./utils/CollageUtils";

import "./Collage.scss";

interface ICollageViewProps {
    controller: IViewController
}

interface ELCollageState {
    id: string,
    status: CreationsStatus,
    progressText: string,
    isClicked: boolean,
    isActiveObjectMenuVisible: boolean
}

const getInitialState = (): ELCollageState => {
    return {
        id: Utils.getNilUUID(),
        status: CreationsStatus.requested,
        progressText: IntlHandler.getInstance().formatMessage("generating-high-resolution"),
        isClicked: false,
        isActiveObjectMenuVisible: false
    };
};


const CollageView = (props: ICollageViewProps): React.ReactElement => {
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const reducer = (state: ELCollageState, action: ViewAction): ELCollageState => {
        switch (action.type) {
            case CollageViewActions.collageStatus: {
                const status = action.payload as CreationsStatus;
                return {
                    ...state,
                    status: status
                };
            }
            case CollageViewActions.collageProgressText: {
                const progressText = action.payload as string;
                return {
                    ...state,
                    progressText: progressText
                };
            }
            case CollageViewActions.showDeleteConfirmationDialog: {
                const isClicked = action.payload as boolean;
                setConfirmationDialogOpen(true);
                return {
                    ...state,
                    isClicked: isClicked
                };
            }
            case CollageViewActions.showActiveObjectMenu: {
                const isActiveObjectMenuVisible = action.payload as boolean;
                return {
                    ...state,
                    isActiveObjectMenuVisible: isActiveObjectMenuVisible
                };
            }
            default:
                return state;
        }
    };

    const [state, viewDispatch] = useReducer(reducer, getInitialState());

    const docStoreData = useSelector((rootState: RootState) => rootState.docStateReducer);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    useUnloadHandler(docStoreData.isDirty === DocumentDirty.NON_DIRTY);

    useKeyboardZoomEventListener({
        controller: props.controller,
        unmountCB: () => {
            store.dispatch(DocActions.updateZoomLevel(ZoomLevel.default));
        }
    });

    return (
        <ELReactSpectrumV3Provider width="100%">
            <div id="collage-container" >
                <div id="collage-header-container" />

                <ELQuickActionContainer quickActions={CollageUtils.getQuickActions(props.controller)} showQuickActions={state.isActiveObjectMenuVisible}>
                    <Flex direction="column">
                        <div className="collage-main-content-box">
                            <div data-testid="collage-edit-container" id="collage-edit-container" onContextMenu={(e) => e.preventDefault()} />
                            <div id="collage-right-panel-container" />
                            <div className="collage-feedback-icon-container" id="feedback-popover-container" />            
                        </div>
                    </Flex>
                </ELQuickActionContainer>

                {(state.status === CreationsStatus.requested)
                    && <ELCreationProgressView progressText={state.progressText} mode="parent" />}
                {(state.isClicked && <div className="collage__image-delete-clicked">
                    <DialogContainer onDismiss={() => setConfirmationDialogOpen(false)}>
                        {confirmationDialogOpen &&
                            <ELDeleteConfirmationDialogView
                                controller={props.controller} />
                        }
                    </DialogContainer>
                </div>)}
            </div>
        </ELReactSpectrumV3Provider>
    );
};

export default CollageView;
