/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Application Specific
import AppRouteUtil from "../../../app/AppRouteUtil";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { IngestLogging } from "../../../services/IngestWrapper";
import SelectedMediaListAction from "../../../stores/actions/selectedMediaListActions";
import store from "../../../stores/store";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../utils/IngestConstants";
import { IngestUtils } from "../../../utils/IngestUtils";
import { ToastUtils } from "../../../utils/ToastUtils";
import Utils from "../../../utils/Utils";
import { SelectedMediaListType } from "../../../view/components/organism/el-mediagrid/ELMediaGrid";
import { WorkspacesName } from "../../IBaseWorkspace";
import CollageUtils from "../workflows/collage/utils/CollageUtils";
import PatternOverlayUtils from "../workflows/pattern-overlay/utils/PatternOverlayUtils";
import PeekThroughUtils from "../workflows/peekThrough/utils/PeekThroughUtils";
import MovingOverlayUtils from "../workflows/moving-overlay/utils/MovingOverlayUtils";
import ReplaceBackgroundUtils from "../workflows/replace-background/utils/ReplaceBackgroundUtils";
import SlideshowUtils from "../workflows/slideshow/utils/SlideshowUtils";
import CreationUtils from "./CreationUtils";
import PhotoTextUtils from "../workflows/photoText/utils/PhotoTextUtils";

class CreationLauncher {
    public async startSlideshow(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            CreationUtils.showErrorMessageForQuotaExceeded("slideshow");
            return false;
        }

        const mediaGridConfig = SlideshowUtils.getSlideshowMediaGridConfig();
        const filteredAssets = await SlideshowUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);
        if (!SlideshowUtils.canStartSlideshow(filteredAssets)) {
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-proceed-creation",
                { creation: "slideshow", formats: "formats" }));
            return false;
        } else {
            const hasSkippedMedia = selectedAssets.length !== filteredAssets.length;
            if (hasSkippedMedia) {
                const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.slideshow,
                    IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
                IngestLogging.getInstance().logEvent(unsupportedMediaObject);
                ToastUtils.info(IntlHandler.getInstance().formatMessage("media-selection-skipped"));
            }
            store.dispatch(SelectedMediaListAction.updateSelectedMediaList(filteredAssets));
            AppRouteUtil.changeRoute(SlideshowUtils.getSlideshowHistoryState(Utils.getNilUUID()));
            return true;
        }
    }

    private _warningToastForMinImageLimit(minImageLimit: number | undefined): void {
        const minImageObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage,
            IngestEventTypes.create, IngestEventSubTypes.error, "Minimum image limit"));
        IngestLogging.getInstance().logEvent(minImageObject);
        ToastUtils.warning(IntlHandler.getInstance().formatMessage("min-image-limit", { min_count: minImageLimit }));
    }

    private _warningToastForMaxImageLimit(maxImageLimit: number | undefined): void {
        const maxImageObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage,
            IngestEventTypes.create, IngestEventSubTypes.error, "Maximum image limit"));
        IngestLogging.getInstance().logEvent(maxImageObject);
        ToastUtils.warning(IntlHandler.getInstance().formatMessage("max-image-limit", { max_count: maxImageLimit }));
    }

    public async startCollage(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            CreationUtils.showErrorMessageForQuotaExceeded("photo-collage");
            return false;
        }

        const mediaGridConfig = CollageUtils.getCollageMediaGridConfig();

        if (!CollageUtils.canStartCollage(selectedAssets)) {
            this._warningToastForMinImageLimit(mediaGridConfig.minImageCount);
            return false;
        }

        if (CollageUtils.isMaxImageLimitExceeded(selectedAssets)) {
            this._warningToastForMaxImageLimit(mediaGridConfig.maxImageCount);
            return false;
        }

        const filteredAssets = await CollageUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);
        if (!CollageUtils.canStartCollage(filteredAssets)) {
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-proceed-creation",
                { creation: IntlHandler.getInstance().formatMessage("photo-collage").toLowerCase(), formats: "formats" }));
            return false;
        } else {
            const hasSkippedMedia = selectedAssets.length !== filteredAssets.length;
            if (hasSkippedMedia) {
                const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.collage,
                    IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
                IngestLogging.getInstance().logEvent(unsupportedMediaObject);
                ToastUtils.info(IntlHandler.getInstance().formatMessage("media-selection-skipped"));
            }
            store.dispatch(SelectedMediaListAction.updateSelectedMediaList(filteredAssets));
            AppRouteUtil.changeRoute(CollageUtils.getCollageHistoryState(Utils.getNilUUID()));
            return true;
        }
    }

    async startPatternOverlay(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            CreationUtils.showErrorMessageForQuotaExceeded("pattern-overlay-creation");
            return false;
        }

        const mediaGridConfig = PatternOverlayUtils.getPatternOverlayMediaGridConfig();

        if (!PatternOverlayUtils.canStartPatternOverlay(selectedAssets)) {
            this._warningToastForMinImageLimit(mediaGridConfig.minImageCount);
            return false;
        }

        if (PatternOverlayUtils.isMaxImageLimitExceeded(selectedAssets)) {
            this._warningToastForMaxImageLimit(mediaGridConfig.maxImageCount);
            return false;
        }

        const filteredAssets = await PatternOverlayUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);

        if (!PatternOverlayUtils.canStartPatternOverlay(filteredAssets)) {
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-proceed-creation",
                { creation: IntlHandler.getInstance().formatMessage("pattern-overlay-creation").toLowerCase(), formats: "format" }));
            return false;
        } 

        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(filteredAssets));
        AppRouteUtil.changeRoute(PatternOverlayUtils.getPatternOverlayHistoryState(Utils.getNilUUID()));

        return true;
    }

    async startMovingOverlay(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.movingOverlay,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            MovingOverlayUtils.showErrorMessageForQuotaExceeded();
            return false;
        }

        const mediaGridConfig = MovingOverlayUtils.getMovingOverlayMediaGridConfig();

        if (!MovingOverlayUtils.canStartMovingOverlay(selectedAssets)) {
            this._warningToastForMinImageLimit(mediaGridConfig.minImageCount);
            return false;
        }

        if (MovingOverlayUtils.isMaxImageLimitExceeded(selectedAssets)) {
            this._warningToastForMaxImageLimit(mediaGridConfig.maxImageCount);
            return false;
        }

        const filteredAssets = await MovingOverlayUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);

        if (!MovingOverlayUtils.canStartMovingOverlay(filteredAssets)) {
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.movingOverlay,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-proceed-creation",
                { creation: IntlHandler.getInstance().formatMessage("moving-overlay-creation").toLowerCase(), formats: "format" }));
            return false;
        } 

        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(filteredAssets));
        AppRouteUtil.changeRoute(MovingOverlayUtils.getMovingOverlayHistoryState(Utils.getNilUUID()));

        return true;
    }

    async startReplaceBackground(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            ReplaceBackgroundUtils.showErrorMessageForQuotaExceeded();
            return false;
        }

        const mediaGridConfig = ReplaceBackgroundUtils.getReplaceBackgroundMediaGridConfig();

        if (!ReplaceBackgroundUtils.canStartReplaceBackground(selectedAssets)) {
            this._warningToastForMinImageLimit(mediaGridConfig.minImageCount);
            return false;
        }

        if (ReplaceBackgroundUtils.isMaxImageLimitExceeded(selectedAssets)) {
            this._warningToastForMaxImageLimit(mediaGridConfig.maxImageCount);
            return false;
        }

        const filteredAssets = await ReplaceBackgroundUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);

        if (!ReplaceBackgroundUtils.canStartReplaceBackground(filteredAssets)) {
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.replaceBackground,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-proceed-creation",
                { creation: IntlHandler.getInstance().formatMessage("replace-background").toLowerCase(), formats: "format" }));
            return false;
        }

        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(filteredAssets));
        AppRouteUtil.changeRoute(ReplaceBackgroundUtils.getReplaceBackgroundHistoryState(Utils.getNilUUID()));
        
        return true;
    }

    public async startPeekThrough(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            CreationUtils.showErrorMessageForQuotaExceeded("peek-through");
            return false;
        }

        const mediaGridConfig = PeekThroughUtils.getPeekThroughMediaGridConfig();

        if (!PeekThroughUtils.canStartPeekThrough(selectedAssets)) {
            this._warningToastForMinImageLimit(mediaGridConfig.minImageCount);
            return false;
        }

        if (PeekThroughUtils.isMaxImageLimitExceeded(selectedAssets)) {
            this._warningToastForMaxImageLimit(mediaGridConfig.maxImageCount);
            return false;
        }

        const filteredAssets = await PeekThroughUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);
        if (!PeekThroughUtils.canStartPeekThrough(filteredAssets)) {
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.peekThrough,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-proceed-creation",
                { creation: IntlHandler.getInstance().formatMessage("peek-through").toLowerCase(), formats: "format" }));
            return false;
        } 
        
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(filteredAssets));
        AppRouteUtil.changeRoute(PeekThroughUtils.getPeekThroughHistoryState(Utils.getNilUUID()));
            
        return true;
    }

    public async startPhotoText(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.photoText,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            CreationUtils.showErrorMessageForQuotaExceeded("photo-text");
            return false;
        }

        const mediaGridConfig = PhotoTextUtils.getMediaGridConfig();

        if (!PhotoTextUtils.canStart(selectedAssets)) {
            this._warningToastForMinImageLimit(mediaGridConfig.minImageCount);
            return false;
        }

        if (PhotoTextUtils.isMaxImageLimitExceeded(selectedAssets)) {
            this._warningToastForMaxImageLimit(mediaGridConfig.maxImageCount);
            return false;
        }

        const filteredAssets = await PhotoTextUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);
        if (!PhotoTextUtils.canStart(filteredAssets)) {
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.creations, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.photoText,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("unable-to-proceed-creation",
                { creation: IntlHandler.getInstance().formatMessage("photo-text").toLowerCase(), formats: "format" }));
            return false;
        } 
        
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList(filteredAssets));
        AppRouteUtil.changeRoute(PhotoTextUtils.getHistoryState(Utils.getNilUUID()));
        
        return true;
    }
}

export default CreationLauncher;