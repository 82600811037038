/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { ReactElement } from "react";
import { Provider as ReactReduxProvider } from "react-redux";
import ReactDOM from "react-dom";

//Application specific
import ITemplateViewController from "../../../ITemplateViewController";
import { ViewAction } from "../../../IBaseController";
import ELUserHomeStaticContentView from "./ELUserHomeStaticContentView";
import store from "../../../../stores/store";
import Utils from "../../../../utils/Utils";
import { ControllerAction } from "../../../IViewController";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { ELUserHomeStaticContentActions } from "../../../../common/interfaces/home/UserHomeTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { URLConstants } from "../../../../utils/Constants/URLConstants";
import { APP_VERSION } from "../../../../utils/Constants/Constants";

class ELUserHomeStaticContent extends ITemplateViewController {
    private _handleLearnMoreClicked = (): void => {
        const learnMoreURL = URLConstants.GoURL.REACT_APP_TRIAL_ENDED_LEARN_MORE_URL;
        if (learnMoreURL)
            Utils.openInNewTab(learnMoreURL);
        this.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.click, IngestEventSubTypes.learnMore, true)
        });
    }


    private _handleTakeSurveyClicked = (): void => {
        Utils.openInNewTab(URLConstants.GoURL.ELEMENTS_WEB_SURVEY_HREF);
        this.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.success, IngestEventSubTypes.takeSurvey, true)
        });
    }

    private _getFaqs = (): Record<string, ReactElement> => {
        const intlHandler = IntlHandler.getInstance();
        const NUMBER_OF_FAQS = 11;
        const faqs: Record<string, ReactElement> = {};

        for (let i = 1; i <= NUMBER_OF_FAQS; i++) {
            faqs[intlHandler.formatMessage(`el-user-home-faq-question${i}`, { version: APP_VERSION })] = React.createElement("p", null, intlHandler.formatMessage(`el-user-home-faq-answer${i}`, { version: APP_VERSION }))
        }
        return faqs;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const userHomeStaticContent = React.createElement(ELUserHomeStaticContentView, {
            controller: this,
            faqs: this._getFaqs()
        });
        const providerHydratedStaticContentView = React.createElement(ReactReduxProvider, { store: store }, userHomeStaticContent);
        ReactDOM.render(
            providerHydratedStaticContentView,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELUserHomeStaticContentActions.learnMoreClicked: {
                this._handleLearnMoreClicked();
                handled = true;
                break;
            }
            case ELUserHomeStaticContentActions.takeSurveyClicked: {
                this._handleTakeSurveyClicked();
                handled = true;
                break;
            }
            default:
                break;
        }
        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }
}

export default ELUserHomeStaticContent;