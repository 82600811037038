/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";

//Adobe Internal
import { ContentEntity, ContentType } from "@elements/elementswebcommon";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import IViewController, { ControllerAction } from "../../../IViewController";
import ITemplateViewController from "../../../ITemplateViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELRenditionHandler } from "../../../../modules/elRenditionHandler/ELRenditionHandler";
import {
    ELRecommendationWorkflowViewActions,
    ELThumbUpdateProps,
    ELRecommendationWorkflowControllerActions,
    ELPreviewCreationThumbData
} from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { ELContentCacheDownloader } from "../../../../workspaces/creations/utils/ELContentCacheDownloader";
import Utils from "../../../../utils/Utils";
import store from "../../../../stores/store";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";
import { ELRecommendationWorkflowThumbPanelView } from "./ELRecommendationWorkflowThumbPanelView";
import { ERROR_THUMBDATA } from "../../../../utils/Constants/Constants";

export default class ELRecommendationWorkflowThumbPanel extends ITemplateViewController {

    private _overlaysThumbList: ELPreviewCreationThumbData[];
    private _selectedOverlayId: string;
    private _renditionHandler: ELRenditionHandler;
    private _overlaysData: ContentEntity[] = [];
    private _contentType: ContentType;
    private _panelHeading: string;
    private _showThumbTitle = false;

    constructor(owner: IViewController, contentType: ContentType, panelHeading: string, showThumbTitle = false) {
        super(owner);
        this._renditionHandler = new ELRenditionHandler();
        this._selectedOverlayId = "";
        this._overlaysThumbList = [];
        this._contentType = contentType;
        this._panelHeading = panelHeading;
        this._showThumbTitle = showThumbTitle;
    }

    private async _fetchOverlaysData(): Promise<void> {
        try {
            if (this._overlaysData.length === 0) {
                this._overlaysData = await ELContentCacheDownloader.getContentForContentType(this._contentType);
                this._overlaysData = Utils.sortArrayRandomly(this._overlaysData);
                this._parseOverlaysDataForThumb();
                this._updateAllThumbsView();
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELRecommendationWorkflowThumbPanel: _fetchOverlayData, failed to get overlay content", error);
            Promise.reject()
        }
    }

    get selectedOverlayId(): string {
        return this._selectedOverlayId;
    }

    private async _getOverlaysData(): Promise<ContentEntity[]> {
        if (this._overlaysData.length === 0) {
            await this._fetchOverlaysData();
        }
        return this._overlaysData;
    }

    private _parseOverlaysDataForThumb(): void {
        this._overlaysThumbList = this._overlaysData.map(data => {
            return {
                id: data.props.id,
                name: data.props.title,
            } as ELPreviewCreationThumbData
        });
    }

    private _cleanAllThumbsDataAndUpdateView(): void {
        this._overlaysThumbList = this._overlaysThumbList.map(thumbData => {
            return {
                id: thumbData.id,
                name: thumbData.name
            }
        })
        this._updateAllThumbsView();
    }

    private _updateAllThumbsView(): void {
        this.viewDispatcher?.({
            type: ELRecommendationWorkflowViewActions.updateAllThumbsData,
            payload: this._overlaysThumbList
        });
    }

    private _updateSelectedThumbId(): void {
        this.viewDispatcher?.({
            type: ELRecommendationWorkflowViewActions.setSelectedOverlayId,
            payload: this._selectedOverlayId
        });
    }

    private _updateOverlaysThumbListData(thumbInfo: ELPreviewCreationThumbData): void {
        this._overlaysThumbList = this._overlaysThumbList.map((oldData) => {
            return oldData.id === thumbInfo.id ? thumbInfo : oldData;
        });
    }

    private _updateSingleThumbView(thumbInfo: ELPreviewCreationThumbData): void {
        this._updateOverlaysThumbListData(thumbInfo);
        this.viewDispatcher?.({
            type: ELRecommendationWorkflowViewActions.updateSingleThumbData,
            payload: thumbInfo
        });
    }

    private async _getAndDispatchRendition(asset: ELAdobeAsset): Promise<string> {
        try {
            const objectURL = await this._renditionHandler.getRendition(asset);
            return objectURL;
        } catch (errorData) {
            Logger.log(LogLevel.WARN, "Rendition not fetched", errorData);
            return ERROR_THUMBDATA;
        }
    }

    private async _updateSingleThumbData(thumbData: ELThumbUpdateProps): Promise<void> {
        const updatedThumb = this._overlaysThumbList.filter(thumbs => thumbs.id === thumbData.id)[0];
        if (thumbData.asset) {
            updatedThumb.assetId = thumbData.asset.assetId;
            updatedThumb.imageData = thumbData.asset?.url;
            const objectUrl = await this._getAndDispatchRendition(thumbData.asset);
            updatedThumb.imageData = objectUrl;
            this._updateSingleThumbView(updatedThumb);
        }
    }

    private _updateThumbDataForErrorThumb(id: string): void {
        const updatedThumb = this._overlaysThumbList.filter(thumbs => thumbs.id === id)[0];
        if (updatedThumb) {
            updatedThumb.imageData = ERROR_THUMBDATA;
            this._updateSingleThumbView(updatedThumb);
        }
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._getOverlaysData();
        this._updateAllThumbsView();
        this._updateSelectedThumbId();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELRecommendationWorkflowControllerActions.appendToOverlaysData: {
                const overlaysToBeAppended = action.payload as ELPreviewCreationThumbData[];
                this._overlaysThumbList.push(...overlaysToBeAppended);
                this._updateAllThumbsView();
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.putErrorDataInThumbnail: {
                const overlayId = action.payload as string;
                this._updateThumbDataForErrorThumb(overlayId);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.updateSingleOverlayData: {
                const thumbData = action.payload as ELThumbUpdateProps;
                this._updateSingleThumbData(thumbData);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.cleanAllThumbsData: {
                this._cleanAllThumbsDataAndUpdateView();
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.setSelectedOverlayId: {
                const selectedOverlayId = action.payload as string;
                this._selectedOverlayId = selectedOverlayId;
                handled = true;
                this._updateSelectedThumbId()
                break;
            }
            case ELRecommendationWorkflowControllerActions.overlayClicked: {
                const clickedThumb = action.payload as ELPreviewCreationThumbData;
                store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayName(clickedThumb.name));
                this._selectedOverlayId = clickedThumb.id;
                this._owner.notify(action);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.keepThumbIdOnTop: {
                const thumbId = action.payload as string;
                const topThumb = this._overlaysThumbList.filter((thumbData) => thumbData.id === thumbId);
                if (topThumb.length > 0)
                    store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayName(topThumb[0].name));
                const latterThumbs = this._overlaysThumbList.filter((thumbData) => thumbData.id !== thumbId);
                this._overlaysThumbList = topThumb.concat(latterThumbs);
                this._updateAllThumbsView();
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "ELRecommendationWorkflowThumbPanel - (notify)", "Bad action: ", action);
            }
        }
        if (!handled)
            handled = await this._owner.notify(action);

        return handled;
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELRecommendationWorkflowThumbPanelView, {
            controller: this,
            panelHeading: this._panelHeading,
            showThumbTitle: this._showThumbTitle
        })
    }
}