/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";
import { DialogContainer, Provider as SpectrumProvider, lightTheme } from "@adobe/react-spectrum";

//Utils and constants
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectValue, IngestWorkflowTypes } from "../../../utils/IngestConstants";
import { IngestUtils } from "../../../utils/IngestUtils";

//Application specific
import IViewController from "../../../view/IViewController";
import { WorkflowActionType } from "../../IWorkflow";
import { ELStartTrialDialog } from "../../../view/components/organism/el-start-trial-dialog/ELStartTrialDialogView";
import { ELUserHomeActions } from "../../../common/interfaces/home/UserHomeTypes";
import store from "../../../stores/store";
import { EliveUtils } from "../../../utils/EliveUtils";
import { UserState } from "../../../common/interfaces/elive/EliveTypes";
import { ELIngestParams } from "../../../common/interfaces/ingest/IngestTypes";
import { ELCustomFooterView } from "../../../view/components/organism/el-footer/ELCustomFooter";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { FeaturesManager } from "../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../services/Floodgate/FloodgateConstants";

import "./UserHomeView.scss";

interface UserHomeViewProps {
    controller: IViewController
}

export const UserHomeView = (props: UserHomeViewProps): React.ReactElement => {
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const shouldRenderStartTrialDialog = (): boolean => {
        const isStartTrialDialogFeatureActive = FeaturesManager.getInstance().IsFeatureActive(FeatureName.eStartTrialDialog);
        if (isStartTrialDialogFeatureActive) {
            const daysInTrial = store.getState().appReducer.daysInTrial;
            const userState = EliveUtils.getUserState(daysInTrial);
            if (userState === UserState.trialNotStarted) {
                return true;
            }
        }
        return false;
    }

    const checkAndRenderStartTrialDialog = (): void => {
        if (shouldRenderStartTrialDialog()) {
            renderStartTrialDialog();
        }
    }

    const renderStartTrialDialog = (): void => {
        setIsDialogOpen(true);
        props.controller.notify({ type: ELUserHomeActions.renderStartTrialDialog });
    }

    const onStartLaterPress = (): void => {
        setIsDialogOpen(false);
        const payload: ELIngestParams = {
            eventViewType: IngestLogObjectValue.trialModal,
            eventValue: IngestLogObjectValue.trialModal
        };
        props.controller.notify({ type: ELUserHomeActions.startTrialLater, payload: payload });
    }

    const onStartTrialPress = (): void => {
        const payload: ELIngestParams = {
            eventViewType: IngestLogObjectValue.trialModal,
            eventValue: IngestLogObjectValue.trialModal
        };
        props.controller.notify({ type: ELUserHomeActions.startTrial, payload: payload });
    }

    const startTrialDialog = (): React.ReactElement => {
        return (
            <SpectrumProvider theme={lightTheme} colorScheme="light">
                <DialogContainer onDismiss={onStartLaterPress}>
                    {isDialogOpen &&
                        (
                            <ELStartTrialDialog onStartTrialPress={onStartTrialPress} />
                        )}
                </DialogContainer>
            </SpectrumProvider>
        )
    }

    useEffect(() => {
        props.controller.initialize();
        props.controller.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.userHome, IngestEventSubTypes.success, IngestEventTypes.render, true)
        });
        checkAndRenderStartTrialDialog();

        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <>
            <div id="user-home-workflow-container">
                <div id="user-home-workflow__banner-container" />
                <div id="user-home-workflow__filter-search-container" />
                <div id="user-home-workflow__cards-container" />
                <div id="user-home-workflow__static-content-container" />
                <ELCustomFooterView currentLocale={IntlHandler.getInstance().getCurrentLocale()} />
            </div>
            <div id="user-home__full_screen-container"></div>
            {startTrialDialog()}
        </>
    )
}
