/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { ReactNode, RefObject } from 'react';
import { StyleProps } from '@react-types/shared';
import { useStyleProps } from "@react-spectrum/utils";

export interface ELScrollPanelProps extends StyleProps {
    scrollX?: boolean,
    scrollY?: boolean,
    forwardedRef?: RefObject<HTMLDivElement>,
    onScroll?: React.UIEventHandler<HTMLDivElement>,
    children?: ReactNode
}

const ELScrollPanel = (props: ELScrollPanelProps): React.ReactElement => {
    const {
        scrollX,
        scrollY,
        forwardedRef,
        onScroll,
        children,
        ...otherProps
    } = props;
    const { styleProps } = useStyleProps(otherProps);

    return (
        <div className={styleProps.className} ref={props.forwardedRef}
            onScroll={props.onScroll}
            style={{ ...styleProps.style, overflowX: scrollX ? "auto" : "clip", overflowY: scrollY ? "auto" : "clip" }}>
            {props.children}
        </div>
    )
}

export default ELScrollPanel;