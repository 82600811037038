/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, Key } from "react";

//Adobe Internal
import { Item } from "@react-stately/collections";
import { Orientation } from "@react-types/shared";
import { Tabs, TabPanels, TabList } from "@react-spectrum/tabs";
import { Flex } from "@adobe/react-spectrum";
import { ELTabData, ELFlexDirection } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";

import "./ELTabPanelManager.scss";

export interface ELTabPanelManagerProps {
    orientation: Orientation,
    tabData: ELTabData[],
    defaultSelectedKey: Key,
    onSelectionChange?: (key: Key) => any
    panelItemDirection?: ELFlexDirection,
    children?: ReactNode,
    showTabContent: boolean,
    dataTestId: string,
    variant: "modal" | "non-modal",
    size?: "S" | "M" | "L",
    selectedKey?: Key
}

export function ELTabPanelManager(props: ELTabPanelManagerProps): React.ReactElement {

    const showTabPanels = (): React.ReactElement => {
        if (props.showTabContent) {
            return (
                <div className={`tab-panel-box ${props.variant} ` + props.size}>
                    <TabPanels UNSAFE_className="tab-panel-box" height="100%" >
                        {(item: any) => <Item key={item.key}>{item.panel.panel}</Item>}
                    </TabPanels>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    return (
        <Tabs {...props} items={(props as any).tabData} onSelectionChange={props.onSelectionChange} orientation={props.orientation} data-testid={props.dataTestId}
            UNSAFE_style={{ "flexDirection": props.panelItemDirection }} height="100%" selectedKey={props.selectedKey}>
            <Flex id="vertical-tabs-parent" gap="size-0" direction="column" UNSAFE_style={{ "border": 'none' }}>
                <TabList flex="1 1 auto" minWidth="0px" UNSAFE_style={{ "border": "none" }}>
                    {(item: any) => <Item key={item.key}>
                        {item.item.item}
                    </Item>}
                </TabList>
            </Flex>
            {showTabPanels()}
        </Tabs>
    );
}
