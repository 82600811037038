/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Adobe Internal
import {
    AssetPath,
    ContentType,
    OutputType,
    Constants as CommonCodeConstants,
    PatternOverlayCreationOperationSubTypeInfo,
    PatternOverlayJobUtils,
    CreationOperationTypeInfo,
    CreationOperationTypeOutput,
    TemplateId,
    ELAdobeAsset,
    Resolution
} from "@elements/elementswebcommon";

//Application Specific
import store from "../../../../stores/store";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ViewAction } from "../../../../view/IBaseController";
import { ControllerAction } from "../../../../view/IViewController";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import PatternOverlayUtils from "./utils/PatternOverlayUtils";
import PatternOverlayView from "./PatternOverlayView";
import { CreationStatusPayload } from "../../../../stores/actions/CreationsAction";
import { PatternOverlayJobCreator } from "./utils/PatternOverlayJobCreator";
import {
    CSAssetWithData,
    CreationWorkflowActions,
    CreationsJobProjectSubType,
    CreationsJobStorageType,
    CreationsStatus,
    CreationsStatusPayload,
    ELCreationDocumentDetails,
    ELViewType,
    ELCreationWorkflowPayload,
    UNTITLED_INTL_KEY,
    CreationsMode
} from "../../../../common/interfaces/creations/CreationTypes";
import {
    ELPatternOverlayCreationRequestParams,
    ELOverlayProjectActionMode,
    ELPatternOverlayUpdateDocumentPayload
} from "../../../../common/interfaces/creations/ELPatternOverlayTypes";
import {
    ELRecommendationWorkflowViewActions,
    ELRecommendationWorkflowControllerActions,
    ELRecommendationsOutputJsonConfigData,
    ELThumbUpdateProps,
    ELPreviewCreationThumbData,
    ELCreateAndEditProjectParams,
} from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import CollageUtils from "./../collage/utils/CollageUtils";
import { DocumentType } from "../../../../editors/document/IDoc";
import ELAdobeAssetDoc, { ELAdobeAssetDocPayload } from "../../../../editors/adobeAsset/ELAdobeAssetDoc";
import { DocumentActions, DocumentDirty, DocumentSaveStatus } from "../../../../common/interfaces/document/DocumentTypes";
import DocumentFactory, { DocumentFactoryPayload } from "../../../../editors/document/DocumentFactory";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELAdobeAssetControllerAction } from "../../../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import DualViewAction from "../../../../stores/actions/DualViewAction";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { CanvasZoomLevelAction, ELImageData, ELLayoutPanelControllerAction } from "../../../../common/interfaces/stage/StageTypes";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import Constants from "../../../../utils/Constants/Constants";
import DocActions from "../../../../stores/actions/DocActions";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";
import { ELContentCacheDownloader } from "../../utils/ELContentCacheDownloader";
import RecommendationsInAppNotifier from "../../utils/RecommendationsInAppNotifier";
import {
    CreationAppSubscriberType,
    CreationInAppNotifierAction,
    CreationStatusData,
    ELProjectProgressPayload,
    ELRecommendationsProgressPayload,
    RecommendationsAppSubscriberType,
    RecommendationsInAppNotifierAction,
    RecommendationsStatusData
} from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import CreationInAppNotifier from "../../utils/CreationInAppNotifier";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import { StorageService } from "../../../../services/StorageServiceWrapper";
import CreationUtils from "../../utils/CreationUtils";
import { ReplaceMediaManagerMode, ReplaceMediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import { ReplaceAssetInfo } from "../../../../common/interfaces/creations/ELCollageTypes";
import ELOpenInDesktopManager from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopManager";
import ELOpenInDesktopCreationObserver from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopCreationObserver";
import { ELOpenDeeplinkAction, ELOpenInDesktopDeeplinkAction, ELOpenInDesktopOpenAssetPayload } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import Utils from "../../../../utils/Utils";
import LayoutAction from "../../../../stores/actions/LayoutAction";
import CanvasAction, { CanvasMode } from "../../../../stores/actions/CanvasAction";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { ELLayoutInfo } from "../../../../common/interfaces/creations/ELSocialLayoutTypes";
import { StageUtils } from "../../../../utils/stage/StageUtils";
import { ZoomLevel } from "../../../../editors/stage/ELFabricStage";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import ImageUtils from "../../../../utils/ImageUtils";
import { ELSize } from "../../../../common/interfaces/geometry/ELGeometry";
import RecommendationsWorkflow from "../RecommendationsWorkflow";
import { CreationMediaActionType } from "../../../../view/components/templates/el-creation-media-panel/ELCreationMediaView";
import { ELDualDocumentView } from "../../../../editors/document/dualDocumentView/ELDualDocumentView";
import ELMediaRecommendationHeader from "../../../../view/components/templates/el-creations-header/ELMediaRecommendationHeader";
import { ELRecommendationStateManager } from "../../utils/ELRecommendationStateManager";
import ELPanelManager from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import ELPatternOverlayPanelProvider from "../../utils/panelProvider/ELPatternOverlayPanelProvider";
import { ELTabPanelType } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";

class PatternOverlay extends RecommendationsWorkflow<ELAdobeAssetDocPayload> {
    // TODO/REVISIT: (samyjain): Creation/Recommendation Workflow Design update
    protected createRecommendationProject(createProjectParams: ELCreateAndEditProjectParams): Promise<void> {
        throw new Error("Method not implemented.");
    }
    protected updateRecommendationWorkflowRoute(): void {
        throw new Error("Method not implemented.");
    }
    protected editRecommendationsProject(editProjectParams: ELCreateAndEditProjectParams): Promise<void> {
        throw new Error("Method not implemented.");
    }
    // TODO/REVISIT: (samyjain): Creation/Recommendation Workflow Design update

    private _requestId?: string;
    private _creationsHeader!: ELMediaRecommendationHeader;
    private _leftTabPanel!: ELPanelManager;
    private _rightTabPanel!: ELPanelManager;
    private _patternOverlayPayload!: ELCreationWorkflowPayload;
    private _openInDesktopManager!: ELOpenInDesktopManager;
    private _openInDesktopCreationManager!: ELOpenInDesktopCreationObserver;

    private readonly _leftTabPanelContainer = "pattern-overlay-left-panel-container";
    private readonly _rightTabPanelContainer = "pattern-overlay-right-panel-container";
    private readonly _creationsHeaderContainer = "pattern-overlay-creations-header-container";
    private readonly _openDeeplinkContainer = "open-deeplink-container";
    private readonly _feedbackContainer = "feedback-popover-container";
    private readonly _defaultContentResolution = Resolution._1080p;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.patternOverlay);
        this.mediaGridConfig = PatternOverlayUtils.getPatternOverlayMediaGridConfig();
    }

    protected logIngestData(creationStatus: string, errorInfo?: string): void {
        try {
            const allMedia = store.getState().selectedMediaListReducer;

            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            customEntries[IngestLogObjectCustomKey.totalCount] = allMedia.length.toString();

            const selectedOverlayName = store.getState().recommendationWorkflowReducer.selectedOverlayName;

            if (selectedOverlayName) {
                customEntries[IngestLogObjectCustomKey.overlay] = selectedOverlayName;
            }

            const layoutReducerData = store.getState().layoutReducer;
            if (layoutReducerData.selectedLayout) {
                customEntries[IngestLogObjectCustomKey.socialLayoutId] = layoutReducerData.selectedLayout;
                customEntries[IngestLogObjectCustomKey.socialLayoutScaling] = layoutReducerData.scale.toString();
            }

            const eventContextId = Utils.getRandomUUID();
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;

            if (errorInfo)
                additionalLogInfo[IngestLogObjectKey.errorDescription] = errorInfo;

            if (!this.ingestParams.subType)
                this.ingestParams.subType = this.mode;

            const timeElapsed = Utils.getDateDifference(this.startDate, new Date(), "second");
            customEntries[IngestLogObjectCustomKey.timeTaken] = timeElapsed.toString();

            if (this.ingestParams.subType === CreationsMode.save && this.ingestParams.eventViewType) {
                additionalLogInfo[IngestLogObjectCustomKey.viewType] = this.ingestParams.eventViewType;
            }

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.ingestParams.subType, CreationsJobProjectSubType.patternOverlay, additionalLogInfoTemp));
            }

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                creationStatus, this.ingestParams.subType, CreationsJobProjectSubType.patternOverlay, additionalLogInfo));
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `PatternOverlay:logIngestData:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    protected async openDeeplink(): Promise<void> {
        try {
            const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
            if (selectedOverlayId) {
                const asset = store.getState().selectedMediaListReducer[0];
                const title = this.getFormattedTitleForRequest();
                const documentDetails = await this._getDocumentDetails();
                if (!this.projectId) {
                    store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));
                    this._setStateBeforeEditAndCreateOpenInDesktopCreation();
                    await this._createPatternOverlayProject(asset, selectedOverlayId, ELOverlayProjectActionMode.openInDesktop, title, documentDetails);
                } else {
                    if (this.doc?.isDocumentDirty === DocumentDirty.DIRTY) {
                        store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));
                        this._setStateBeforeEditAndCreateOpenInDesktopCreation();
                        await this._editPatternOverlayProject(asset, selectedOverlayId, ELOverlayProjectActionMode.openInDesktop, title, documentDetails);
                    } else {
                        this._openDeeplinkForSavedCreation();
                    }
                }
            } else {
                return Promise.reject("selectedOverlayId is not set");
            }
        } catch {
            Logger.log(LogLevel.ERROR, "PatternOverly:openDeeplink, PatternOverlay project creation failed!");
            const message = IntlHandler.getInstance().formatMessage("failed-open-deeplink");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            return Promise.reject();
        }
    }

    protected getJobCreator(): CreationsJobCreator {
        return new PatternOverlayJobCreator();
    }

    private async _createPatternOverlay(asset: ELAdobeAsset): Promise<void> {
        this.startDate = new Date();
        try {
            const allOverlayIds = this.getAllOverlayIds(await ELContentCacheDownloader.getContentForContentType(ContentType.patternOverlay));
            const requestParams: ELPatternOverlayCreationRequestParams = {
                assets: [asset],
                overlayIds: allOverlayIds,
                outputTypes: [OutputType.preview],
                contentResolution: this._defaultContentResolution
            };
            store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.requested));
            const requestJson = this.createRequestJson(requestParams);
            this._requestId = await this.createRecommendations(requestJson);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlay recommendations failed!", error);
            const message = IntlHandler.getInstance().formatMessage("failed-to-create-recommendations");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PatternOverlay recommendations failed");
            return Promise.reject();
        }

        this.pollRecommendationStatus(this._requestId);
    }

    private _updatePatternOverlayRoute(): void {
        if (!this.projectId) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_updatePatternOverlayRoute: ", "project id not valid");
            return;
        }

        HistoryUtils.replaceHistory(PatternOverlayUtils.getPatternOverlayHistoryState(this.projectId));
    }

    private async _editPatternOverlayProject(asset: ELAdobeAsset, overlayId: string, editMode: ELOverlayProjectActionMode,
        title?: string, documentDetails?: ELCreationDocumentDetails): Promise<void> {

        if (!this.projectId || !this.projectData) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_editPatternOverlayProject: ", "project id or project data not valid");
            return Promise.reject();
        }

        let projectRequestParams: ELPatternOverlayCreationRequestParams = {
            assets: [asset],
            overlayIds: [overlayId],
            title: this.getTrueTitleForRequest(title ?? this.projectData.title),
            outputTypes: [OutputType.preview],
            contentResolution: this._defaultContentResolution,
            documentDetails: documentDetails
        };
        try {
            projectRequestParams = this._getUpdatedParamsBasedOnActionMode(editMode, projectRequestParams);
            const requestJson = this.createRequestJson(projectRequestParams);
            this.preprocessCreationEdit(this.projectId);
            this.projectId = await this.editCreation(this.projectId, requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "PatternOverlay:_editPatternOverlayProject: , project edit failed!" + error);
            return Promise.reject();
        }
        if (editMode === ELOverlayProjectActionMode.openInDesktop)
            this._pollOpenInDesktopProjectStatus();
        else
            this._pollPatternOverlayProjectStatus();
    }

    private _getUpdatedParamsBasedOnActionMode = (createMode: ELOverlayProjectActionMode,
        projectRequestParams: ELPatternOverlayCreationRequestParams): ELPatternOverlayCreationRequestParams => {
        const updatedProjectRequestParams = projectRequestParams;
        if (createMode === ELOverlayProjectActionMode.openInDesktop) {
            updatedProjectRequestParams.outputTypes.push(OutputType.full);
        }
        return updatedProjectRequestParams;
    }

    private async _createPatternOverlayProject(asset: ELAdobeAsset, overlayId: string, createMode: ELOverlayProjectActionMode,
        title?: string, documentDetails?: ELCreationDocumentDetails): Promise<void> {
        let projectRequestParams: ELPatternOverlayCreationRequestParams = {
            assets: [asset],
            overlayIds: [overlayId],
            title: title,
            outputTypes: [OutputType.preview],
            contentResolution: this._defaultContentResolution,
            documentDetails: documentDetails
        };

        try {
            projectRequestParams = this._getUpdatedParamsBasedOnActionMode(createMode, projectRequestParams);
            const requestJson = this.createRequestJson(projectRequestParams);
            this.projectId = await this.createCreation(requestJson);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlay project creation failed!", error);
            const message = IntlHandler.getInstance().formatMessage("failed-recommendation-project");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PatternOverlay project creation failed");
            return Promise.reject();
        }
        if (createMode === ELOverlayProjectActionMode.openInDesktop)
            this._pollOpenInDesktopProjectStatus();
        else
            this._pollPatternOverlayProjectStatus();
        this._updatePatternOverlayRoute();
    }

    private async _pollPatternOverlayProjectStatus(): Promise<void> {
        if (this.projectId) {
            CreationInAppNotifier.pollStatus(this.projectId);
        }
    }

    private async _pollOpenInDesktopProjectStatus(): Promise<void> {
        if (this.projectId) {
            CreationInAppNotifier.subscribe(this._openInDesktopCreationManager, CreationAppSubscriberType.statusChange);
            CreationInAppNotifier.pollStatus(this.projectId);
        }
    }

    private async _startPreviousWorkflow(): Promise<void> {
        this._owner.notify({ type: WorkspaceActionType.endWorkflow });

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.creationsHome
        };
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
        this._owner.notify(workspaceAction);
    }

    private _updateViewStatusAndProgressText(status: CreationsStatus, progressText: string): void {
        this._updateProgressText(progressText);
        this._updateViewStatus(status);
    }

    private _updateViewStatus(status: CreationsStatus): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowStatus,
            payload: status,
        });
    }

    private _updateProgressText(progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowProgressText,
            payload: progressText
        });
    }

    protected async createAndRenderBeforeDoc(patternDocPayload: ELAdobeAssetDocPayload): Promise<void> {
        this.beforeDoc?.destroy();

        const beforeDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: patternDocPayload.assetInfo
        }

        const beforePayload: DocumentFactoryPayload = {
            docPayload: beforeDocPayload,
            stagePayload: {
                showReplaceMediaButton: false,
                showDeleteButton: false,
                objectHoverColor: "rgb(0, 255, 255)",
                viewType: ELViewType.before
            }
        }

        this.beforeDoc = await DocumentFactory.createDocumentWithStage(DocumentType.adobeAsset, this, beforePayload) as ELAdobeAssetDoc;

        this.dualView = new ELDualDocumentView(this.beforeDoc);

        try {
            await this.dualView.createView(this.ensureHTMLElement("pattern-overlay-edit-container"));
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_createAndRenderBeforeDoc: ", "Couldn't render document", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("pattern-overlay-creation") }));
            this._startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }

        Logger.log(LogLevel.INFO, "Document Object: ", this.beforeDoc);
    }

    protected async createAndRenderAfterDoc(patternDocPayload: ELAdobeAssetDocPayload, documentDirty: DocumentDirty): Promise<void> {
        this.doc?.destroy();
        const afterPayload: DocumentFactoryPayload = {
            docPayload: patternDocPayload,
            stagePayload: {
                showReplaceMediaButton: false,
                showDeleteButton: false,
                objectHoverColor: "rgb(0, 255, 255)",
                viewType: ELViewType.after
            }
        }
        this.doc = await DocumentFactory.createDocumentWithStage(DocumentType.adobeAsset, this, afterPayload) as ELAdobeAssetDoc;
        this.doc.markAndNotifyDocumentDirty(documentDirty);
        try {
            await this.dualView?.renderAfterDoc(this.doc);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_createAndRenderAfterDoc: ", "Couldn't render document", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("pattern-overlay-creation") }));
            this._startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }
        this.updateLayoutStore(patternDocPayload.layoutInfo);
        Logger.log(LogLevel.INFO, "Document Object: ", this.doc);
    }

    private _getDocumentPayload(asset: ELAdobeAsset, layoutInfo?: ELLayoutInfo): ELAdobeAssetDocPayload {
        const assetId = asset.assetId;
        const objectURL: string | undefined = CollageUtils.getAssetFullResObjectURL(assetId ?? "");
        const tempAsset: CSAssetWithData = {
            id: Utils.getRandomUUID(),
            assetURN: asset.assetId ?? "",
            storageType: CreationsJobStorageType.RAPI,
            mimeType: asset.format ?? "",
            objectURL: objectURL
        };
        const patternDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: tempAsset,
            layoutInfo: layoutInfo
        }
        return patternDocPayload;
    }

    private _overlayClicked(thumbInfo: ELPreviewCreationThumbData): void {
        this.startDate = new Date();
        const assetId = thumbInfo.assetId;
        const objectURL: string | undefined = CollageUtils.getAssetObjectURL(assetId ?? "");
        const tempAsset: CSAssetWithData = {
            id: thumbInfo.id,
            assetURN: assetId ?? "",
            storageType: CreationsJobStorageType.RAPI,
            mimeType: "",
            objectURL: objectURL
        };
        const patternDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: tempAsset
        };

        const isAssetPresent = store.getState().fullResMediaReducer.filter(data => {
            return data.assetId === tempAsset.assetURN;
        }).length;

        if (!isAssetPresent) {
            this._updateViewStatusAndProgressText(
                CreationsStatus.requested,
                IntlHandler.getInstance().formatMessage("applying-recommendations-effect")
            );
        }

        this.doc?.notify({
            type: ELAdobeAssetControllerAction.renderImage,
            payload: patternDocPayload
        });
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
        this.dualView?.showDoc(ELViewType.after);

        this._updateSelectedOverlayId(thumbInfo.id);
        store.dispatch(DualViewAction.updateView(ELViewType.after));

        this.ingestParams.subType = CreationsMode.update;
        this.logIngestData(IngestEventSubTypes.success);
    }

    private _populatePanelData(projectData: ELRecommendationsOutputJsonConfigData): void {
        this.populateSelectedMediaList(projectData.assets);
    }

    private async _processFirstRender(outputJsonData: ELRecommendationsOutputJsonConfigData): Promise<void> {
        const mapOfOverlayIdAssetPath = await this._getRecommendedAssetsForOverlays(outputJsonData);
        mapOfOverlayIdAssetPath.forEach((asset, overlayId) => { this._updateSelectedOverlayId(overlayId); });

        const patternDocPayload: ELAdobeAssetDocPayload = {
            assetInfo: outputJsonData.assets[0]
        };
        await this.createAndRenderBeforeDoc(patternDocPayload);
    }

    private async _processUIRenderForProject(): Promise<void> {
        if (!this.projectData || !this.projectData.outputs) {
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.warning(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PatternOverlay get project ouputs failed");
            return;
        }
        try {
            if (!this.projectData.assets) {
                const message = IntlHandler.getInstance().formatMessage("creation-asset-not-found");
                ToastUtils.error(message);
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                return Promise.reject();
            }
            const assetId = this.projectData.assets[0].assetURN;
            const projectAsset = await StorageService.getInstance().resolveAsset({ assetId }, "id");
            this._createPatternOverlay(projectAsset);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_openSavedPatternOverlay, not able to resolve asset, might be deleted", error);
            const message = IntlHandler.getInstance().formatMessage("failed-to-resolve-asset-for-doc");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Not able to resolve asset");
            return Promise.reject();
        }
        this._notifySubViews();
        const outputConfigJson = await CreationUtils.getJsonFromProjectData(this.projectData) as ELRecommendationsOutputJsonConfigData;
        this._populatePanelData(outputConfigJson);
        this._processFirstRender(outputConfigJson);
    }

    private async _openSavedPatternOverlay(): Promise<void> {
        this.ingestParams.subType = this.mode;
        this._processUIRenderForProject();
        this._pollPatternOverlayProjectStatus();
    }

    private async _getAndSetProjectData(projectId: string): Promise<void> {
        this.projectId = projectId;
        try {
            this.projectData = await this.getProjectData(this.projectId);
            this._updatePatternOverlayRoute();
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_getAndSetProjectData, failed to get projectData for projectId, ", projectId);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"));
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PatternOverlay get project ouputs failed");
            return Promise.reject();
        }
    }

    async openProject(projectId: string): Promise<void> {
        await this._getAndSetProjectData(projectId);
        this._openSavedPatternOverlay();
    }

    protected _shouldAllowCreationToOpen(): boolean {
        const featureFlagName = FeatureName.ePatternOverlay;
        const isCreationFeatureActive = FeaturesManager.getInstance().IsFeatureActive(featureFlagName);
        return isCreationFeatureActive;
    }

    async enterProject(patternOverlayPayload: ELCreationWorkflowPayload): Promise<void> {
        const isCreationActive = this._shouldAllowCreationToOpen();
        if (!isCreationActive) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creation-disabled-via-feature-flag-message"));
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            return;
        }
        switch (patternOverlayPayload.initMode) {
            case CreationsMode.create: {
                this.projectId = undefined;
                this.updateStoreStateOnEnter();
                const asset = (patternOverlayPayload.payload as ELAdobeAsset[])[0];
                this._createPatternOverlay(asset);
                const documentPayload = this._getDocumentPayload(asset);
                this.createAndRenderBeforeDoc(documentPayload);
                store.dispatch(DocActions.updateDocumentDirty(DocumentDirty.DIRTY));
                break;
            }
            case CreationsMode.open: {
                const patternOverlayStatusPayload = patternOverlayPayload.payload as CreationsStatusPayload;
                this.openProject(patternOverlayStatusPayload.projectId);
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "PatternOverlay (_enterCollage) - Invalid patternOverlay mode");
                break;
            }
        }
    }

    private async _handleFailedRecommendations(recommendationStatusData: RecommendationsStatusData): Promise<boolean> {
        let handled = false;
        const isRecommendationsCreateError = this.isFailedRecommendation(recommendationStatusData);
        if (isRecommendationsCreateError) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"), { timeout: 0 });
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Failed to create recommendations");
            handled = true;
        }
        return handled;
    }

    private async _getMappedAssetToOverlayId(overlayId: string, assetPath: string): Promise<any> {
        const asset = await StorageService.getInstance().resolveAsset({ repositoryId: "", path: assetPath }, "path");
        return {
            overlayId: overlayId,
            asset: asset
        };
    }

    private async _getMapOfOverlayIdToAssetPath(outputJsonData: unknown): Promise<Map<TemplateId, AssetPath>> {
        const requestObj = await this.getSerializedRequestObject(outputJsonData);
        const operation = requestObj.operations[0];
        const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
        const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as PatternOverlayCreationOperationSubTypeInfo;
        const output = operationTypeInfo.output as CreationOperationTypeOutput;
        const mapOfTemplateIdToAssetPath = PatternOverlayJobUtils.getMappedTemplateIdsToAssetPath(operationSubTypeInfo, output, OutputType.preview);
        return mapOfTemplateIdToAssetPath;
    }

    private async _getRecommendedAssetsForOverlays(outputJsonData: unknown): Promise<Map<TemplateId, ELAdobeAsset>> {
        try {
            const mapOfTemplateIdToAssets = await this._getMapOfOverlayIdToAssetPath(outputJsonData);
            const resolveAssetPromises = [];
            for (const [overlayId, assetPath] of mapOfTemplateIdToAssets) {
                resolveAssetPromises.push(this._getMappedAssetToOverlayId(overlayId, assetPath));
            }
            const response = await Promise.all(resolveAssetPromises);
            response.forEach(data => {
                const { overlayId, asset } = data;
                mapOfTemplateIdToAssets.set(overlayId, asset);
            });
            return mapOfTemplateIdToAssets;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_getRecommendedAssetsForOverlays, failed to process OutputResultConfig.json");
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PatternOverlay get project ouputs failed");
            return Promise.reject();
        }
    }

    private async _getLayoutDetails(outputJsonData: unknown, imageSize: ELSize): Promise<ELLayoutInfo | undefined> {
        try {
            return await PatternOverlayUtils.getLayoutDetails(outputJsonData, imageSize);
        } catch {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_getLayoutDetails, failed to process OutputResultConfig.json");
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.error(message)
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PatternOverlay get project ouputs failed");
            return Promise.reject();
        }
    }

    protected async updateDocumentWithOverlay(updateDocumentPayload: ELPatternOverlayUpdateDocumentPayload): Promise<void> {
        const mapOfTemplateIdToAsset = updateDocumentPayload.mapOfTemplateIdToAsset;
        const overlayId = updateDocumentPayload.overlayId;

        const asset = mapOfTemplateIdToAsset.get(overlayId);
        if (!asset || !AssetStorageUtils.isValidAsset(asset)) {
            Logger.log(LogLevel.WARN, "PatternOverlay: updateDocumentWithOverlay", "AssetID not present in asset");
            return Promise.reject("AssetID not present in asset");
        }

        this._updateViewStatus(CreationsStatus.requested);
        store.dispatch(DualViewAction.updateView(ELViewType.after));
        const isDocumentDirty = store.getState().docStateReducer.isDirty;

        let layoutDetails;
        if (asset.assetId && this.projectData?.outputs && this.shouldUpdateLayoutDetails()) {
            const imageUrl = await AssetStorageUtils.getAndStoreAssetData(asset.assetId);
            const imageSize = await ImageUtils.getImageSizeFromURL(imageUrl);

            const outputConfigJson = await CreationUtils.getJsonFromProjectData(this.projectData) as ELRecommendationsOutputJsonConfigData;

            layoutDetails = await this._getLayoutDetails(outputConfigJson, imageSize);
        }

        const docPayload = this._getDocumentPayload(asset, layoutDetails);
        await this.createAndRenderAfterDoc(docPayload, isDocumentDirty);
    }

    private async _getFirstOverlayIdToRender(): Promise<string> {
        let selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
        const projectId = store.getState().recommendationWorkflowReducer.id;

        if (!selectedOverlayId) {
            const overlayContent = await ELContentCacheDownloader.getContentForContentType(ContentType.patternOverlay);
            const randomOverlayId = Math.floor(Math.random() * overlayContent.length);
            selectedOverlayId = overlayContent[randomOverlayId].props.id;
            this._updateSelectedOverlayId(selectedOverlayId);
            if (!projectId) this.ingestParams.subType = CreationsMode.create;
        }

        if (!selectedOverlayId) {
            return Promise.reject("Failed to get a valid overlay ID");
        }

        return selectedOverlayId;
    }

    private async _processRecommendations(recommendationsStatusData: RecommendationsStatusData): Promise<void> {
        if (!this._requestId || this._requestId !== recommendationsStatusData.requestId) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_processRecommendations: requestId not matching or request status isn't success");
            return;
        }

        // need handling for failed case
        if (await this._handleFailedRecommendations(recommendationsStatusData)) {
            return Promise.reject();
        }

        const requestData = await this.getRecommendationRequestData(this._requestId);
        if (!requestData.outputs) {
            const message = IntlHandler.getInstance().formatMessage("failed-at-fetching-recommendations");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Failed at fetching pattern overlays");
            return Promise.reject();
        }
        const outputConfigPath = this.getOutputConfigPathFromOutputConfigurations(requestData.outputs.config)
        const outputJsonData = await CreationUtils.getOutputResultConfigJsonData(outputConfigPath.rootPath, outputConfigPath.relativeFilePath);

        const mapOfOverlayIdAssetPath = await this._getRecommendedAssetsForOverlays(outputJsonData);
        const selectedOverlayId = await this._getFirstOverlayIdToRender();
        const updateDocumentPayload: ELPatternOverlayUpdateDocumentPayload = {
            mapOfTemplateIdToAsset: mapOfOverlayIdAssetPath,
            overlayId: selectedOverlayId
        };

        this._updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdAssetPath).then(() => {
            this.updateDocumentWithOverlay(updateDocumentPayload);
        });

        store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(recommendationsStatusData.status as unknown as CreationsStatus));

        this._reorderPanelThumbnails(selectedOverlayId);
        this.logIngestData(IngestEventSubTypes.success);
    }

    private _reorderPanelThumbnails(overlayId: string): void {
        this._leftTabPanel.notify({
            type: ELRecommendationWorkflowControllerActions.keepThumbIdOnTop,
            payload: overlayId
        });
    }

    private async _updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdAssetPath: Map<TemplateId, ELAdobeAsset>): Promise<void> {
        const overlayData = await ELContentCacheDownloader.getContentForContentType(ContentType.patternOverlay);
        overlayData.forEach(data => {
            if (mapOfOverlayIdAssetPath.has(data.props.id)) {
                const updatedThumbData: ELThumbUpdateProps = {
                    id: data.props.id,
                    asset: mapOfOverlayIdAssetPath.get(data.props.id)
                };
                this._leftTabPanel.notify({
                    type: ELRecommendationWorkflowControllerActions.updateSingleOverlayData,
                    payload: updatedThumbData
                });
            }
        });
    }

    //TODO : samyjain, once delete API comes, delete handling for the project will be needed.
    private async _handleFailedPatternOverlayProject(projectId: string, projectStatusData: CreationStatusData): Promise<boolean> {
        let handled = false;
        const isProjectCreateError = projectStatusData.status !== CreationsStatus.success;
        if (isProjectCreateError) {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"), { timeout: 0 });
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Failed to open project");
            handled = true;
        }
        return handled;
    }

    private _notifySubViews(): void {
        this._creationsHeader.notify({ type: ELCreationsHeaderControllerAction.updateCreationsData, payload: this.projectData });
    }

    private async _processPatternOverlayProject(projectStatusData: CreationStatusData): Promise<void> {
        if (!this.projectId || this.projectId !== projectStatusData.projectId) {
            Logger.log(LogLevel.ERROR, "PatternOverlay:_processPatternOverlayProject: project Id not matching or project status isn't success");
            return;
        }

        if (await this._handleFailedPatternOverlayProject(this.projectId, projectStatusData)) {
            this.logIngestData(IngestEventSubTypes.error);
            return Promise.reject();
        }
        this.logIngestData(IngestEventSubTypes.success);
        this.projectData = await this.getProjectData(this.projectId);
        this._notifySubViews();
        store.dispatch(RecommendationWorkflowAction.updateProjectStatus(CreationsStatus.success));
        store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
    }

    private async _getDocumentDetails(): Promise<ELCreationDocumentDetails | undefined> {
        const docSize = await this.doc?.getOriginalSize();
        const layoutInfo = this.doc?.getLayoutInfo;

        if (docSize && layoutInfo) {
            return {
                size: docSize,
                layoutInfo: layoutInfo
            };
        }
    }

    private async _save(): Promise<void> {
        store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));
        const hasStateChanged = await ELRecommendationStateManager.getInstance().hasStateChanged(this.projectData, this.doc);
        if (hasStateChanged) {
            this.startDate = new Date();
            this._updateProgressPercentageToView(Constants.ZERO as number);
            const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
            if (!selectedOverlayId) {
                return Promise.reject("Failed to get a valid overlay ID");
            }
            const asset = store.getState().selectedMediaListReducer[0];
            store.dispatch(RecommendationWorkflowAction.updateProjectStatus(CreationsStatus.requested));
            const message = IntlHandler.getInstance().formatMessage("saving-creation");
            this._updateProgressText(message);
            const title = this.getFormattedTitleForRequest();
            const documentDetails = await this._getDocumentDetails();

            if (!this.projectId) {
                Logger.log(LogLevel.INFO, "PatternOverlay:_save, project is not created yet, so no projectId found");
                await this._createPatternOverlayProject(asset, selectedOverlayId, ELOverlayProjectActionMode.regular, title, documentDetails);
            } else {
                const asset = store.getState().selectedMediaListReducer[0];
                const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
                if (selectedOverlayId) {
                    await this._editPatternOverlayProject(asset, selectedOverlayId, ELOverlayProjectActionMode.regular, title, documentDetails);
                }
            }
        } else {
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
            this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
        }
    }

    private _setStateBeforeEditAndCreateOpenInDesktopCreation(): void {
        const message = IntlHandler.getInstance().formatMessage("saving-creation");
        store.dispatch(RecommendationWorkflowAction.updateProjectStatus(CreationsStatus.requested));
        this._updateProgressText(message);
        this._updateCreationProgress(Constants.ZERO as number);
    }

    private _showErrorInOpenDeeplinkForSavedCreation(logMsg: string): void {
        Logger.log(LogLevel.ERROR, "PatternOverlay:_showErrorInOpenDeeplinkForSavedCreation:", logMsg);
        const message = IntlHandler.getInstance().formatMessage("failed-open-deeplink");
        ToastUtils.error(message);
    }

    private async _openDeeplinkForSavedCreation(): Promise<void> {
        if (!this.projectId) {
            this._showErrorInOpenDeeplinkForSavedCreation("project id not valid");
            return;
        }
        const projectData = await CreationUtils.getProjectData(this.projectId);
        const assets = await CreationUtils.getMasterAssetData(projectData);
        if (assets && assets.length > 0) {
            if (assets[0].assetData) {
                const assetPathorId = { path: CommonCodeConstants.SEPARATOR + assets[0].assetData?.assetURN };
                const outputAsset = await CreationUtils.getCreationOutputAssetFromPathOrId(assetPathorId);
                const assetId = outputAsset.assetId;
                await this._openInDesktopManager.notify({
                    type: ELOpenDeeplinkAction.openDeepLinkForAsset,
                    payload: { assetId: assetId, assetType: projectData.operationSubType }
                });
            }
        } else {
            const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
            if (selectedOverlayId) {
                const asset = store.getState().selectedMediaListReducer[0];
                const title = this.getFormattedTitleForRequest();
                const documentDetails = await this._getDocumentDetails();
                this._setStateBeforeEditAndCreateOpenInDesktopCreation();
                store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));
                await this._editPatternOverlayProject(asset, selectedOverlayId, ELOverlayProjectActionMode.openInDesktop, title, documentDetails);
            } else {
                this._showErrorInOpenDeeplinkForSavedCreation("selectedOverlayId not valid");
            }
        }
    }

    private _onRecommendationRequestStatusChanged(recommendationStatusData: RecommendationsStatusData): void {
        this._processRecommendations(recommendationStatusData);
    }

    private _onProjectRequestStatusChanged(creationStatusData: CreationStatusData): void {
        this._processPatternOverlayProject(creationStatusData);
    }

    private async _updateProjectData(projectId: string): Promise<boolean> {
        try {
            this.projectData = await this.getProjectData(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "PatternOverlay:_updateProjectData: Could not fetch PatternOverlay project data! " + error);
            return false;
        }
        return true;
    }

    private async _onPatternOverlayTitleChange(): Promise<void> {
        if (this.projectId) {
            await this._updateProjectData(this.projectId);
        }
    }

    async initialize(dispatch?: React.Dispatch<ViewAction>): Promise<void> {
        super.initialize(dispatch);

        const panelProvider = new ELPatternOverlayPanelProvider(this);

        this._leftTabPanel = panelProvider.getTabPanel(ELTabPanelType.leftTabPanel);
        this._leftTabPanel.createView(this.ensureHTMLElement(this._leftTabPanelContainer));

        this._rightTabPanel = panelProvider.getTabPanel(ELTabPanelType.rightTabPanel);
        await this._rightTabPanel.createView(this.ensureHTMLElement(this._rightTabPanelContainer));

        const backButtonDialogHeading = IntlHandler.getInstance().formatMessage("pattern-overlay-creation");
        this._creationsHeader = new ELMediaRecommendationHeader(this, this.shareOptions, backButtonDialogHeading, CreationsJobProjectSubType.patternOverlay);
        await this._creationsHeader.createView(this.ensureHTMLElement(this._creationsHeaderContainer));

        this.createFeedbackView(this.ensureHTMLElement(this._feedbackContainer));

        this._openInDesktopManager = new ELOpenInDesktopManager(this, WorkflowsName.patternOverlay);
        this._openInDesktopManager.createView(this.ensureHTMLElement(this._openDeeplinkContainer));

        this._openInDesktopCreationManager = new ELOpenInDesktopCreationObserver(this);

        CreationInAppNotifier.subscribe(this, CreationAppSubscriberType.statusChange);
        RecommendationsInAppNotifier.subscribe(this, RecommendationsAppSubscriberType.statusChange);

        this.enterProject(this._patternOverlayPayload);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const element = React.createElement(PatternOverlayView, {
            controller: this
        });

        const provider = React.createElement(Provider, { store }, element);

        ReactDOM.render(
            provider,
            container
        );
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        this._leftTabPanel.reset();
        this.projectData = undefined;
        this.doc?.destroy();
        this.doc = undefined;

        this.renditionHandler.clearRenditions();

        CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
        RecommendationsInAppNotifier.unsubscribe(this, RecommendationsAppSubscriberType.statusChange);
        this._updateSelectedOverlayId(undefined);
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);

        const workflowPayload = action?.payload as CreationStatusPayload;
        this.mode = action?.initMode as CreationsMode;

        const patternOverlayPayload: ELCreationWorkflowPayload = {
            initMode: this.mode,
            payload: workflowPayload
        };

        this._patternOverlayPayload = patternOverlayPayload;

        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private async _download(imageData: ELImageData): Promise<void> {
        this.startDate = new Date();
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
        try {
            const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
                { media: IntlHandler.getInstance().formatMessage("pattern-overlay-creation").toLowerCase() });
            ToastUtils.info(message);
            const defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
            this.doc?.notify({ type: DocumentActions.download, payload: { name: this.projectData?.title ?? defaultTitle, imageData: imageData } });
            this.ingestParams.subType = IngestEventSubTypes.download;
            this.logIngestData(IngestEventSubTypes.success);
        } catch (e) {
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this.ingestParams.subType = IngestEventSubTypes.download;
            this.logIngestData(IngestEventSubTypes.error, e as string);
        }
    }

    private _updateSelectedOverlayId(selectedOverlayId?: string): void {
        store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayId(selectedOverlayId));
        this._leftTabPanel.notify({
            type: ELRecommendationWorkflowControllerActions.setSelectedOverlayId,
            payload: selectedOverlayId
        });
    }

    private async _onReplaceMedia(replaceAssetInfo: ReplaceAssetInfo): Promise<void> {
        this._updateProgressPercentageToView(Constants.ZERO as number);
        const asset = replaceAssetInfo.assetToReplaceWith;
        this.ingestParams.subType = CreationsMode.update;
        this._updateViewStatus(CreationsStatus.requested);
        this._updateProgressText(IntlHandler.getInstance().formatMessage("generating-pattern-overlay"));

        const docPayload = this._getDocumentPayload(asset);
        this.createAndRenderBeforeDoc(docPayload);
        store.dispatch(DocActions.updateDocumentDirty(DocumentDirty.DIRTY));
        this.dualView?.showDoc(ELViewType.before);

        this.deleteRecommendedCreations(this._requestId);
        this._createPatternOverlay(asset);
    }

    private _onDocumentDirty(dirty: DocumentDirty): void {
        if (this.doc) {
            store.dispatch(DocActions.updateDocumentError(this.doc.hasRenderingError()));
        }

        store.dispatch(DocActions.updateDocumentDirty(dirty));
    }

    private async _onPatternOverlayRendered(status: CreationsStatus): Promise<void> {
        this._updateViewStatus(status);
    }

    private async _onOpenInDesktopStatusChanged(payload: ELOpenInDesktopOpenAssetPayload): Promise<void> {
        this._openInDesktopManager.notify({ type: ELOpenDeeplinkAction.openDeepLinkForAsset, payload: payload });
    }

    private async _updateProgressPercentageToView(progress: number): Promise<void> {
        this.viewDispatcher?.({
            type: CreationInAppNotifierAction.creationProgressChanged,
            payload: progress
        });
    }

    private _updateCreationProgress(progress: number): void {
        if (this.mode === CreationsMode.open && progress === 100) {
            return;
        }
        this._updateProgressPercentageToView(progress);
    }

    private async _commitLayoutWorkflow(layoutInfo: ELLayoutInfo): Promise<void> {
        store.dispatch(CanvasAction.updateMode(CanvasMode.render));
        const assetId = (this.doc?.getData() as ELAdobeAssetDocPayload).assetInfo.assetURN;
        const asset = await StorageService.getInstance().resolveAsset({ assetId }, "id");
        const isDocumentDirty = store.getState().docStateReducer.isDirty;
        const docPayload = this._getDocumentPayload(asset, layoutInfo);
        await this.createAndRenderAfterDoc(docPayload, isDocumentDirty);
    }

    private async _layoutPanelApplyPress(): Promise<void> {
        await this.doc?.notify({ type: ELLayoutPanelControllerAction.commit });
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
    }

    private async _layoutPanelCancelPress(): Promise<void> {
        await this.doc?.notify({ type: ELLayoutPanelControllerAction.cancel });
    }

    private _layoutScaleChange<T extends ControllerAction>(action: T): void {
        this.doc?.notify(action);
        store.dispatch(LayoutAction.updateScale(action.payload as number));
    }

    private _layoutRevert<T extends ControllerAction>(action: T): void {
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
            IngestEventTypes.click, IngestEventSubTypes.socialLayoutRevert, CreationsJobProjectSubType.patternOverlay));

        this.doc?.notify(action);
        store.dispatch(LayoutAction.updateScale(0));
        store.dispatch(LayoutAction.updateSelectedLayout(""));
    }

    private async _layoutChange<T extends ControllerAction>(action: T): Promise<void> {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = store.getState().layoutReducer.selectedLayout;
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
            IngestEventTypes.click, IngestEventSubTypes.socialLayoutName, CreationsJobProjectSubType.patternOverlay, additionalLogInfo));

        const viewType = store.getState().dualViewReducer.viewType;
        if (viewType === ELViewType.before) {
            this.dualView?.showDoc(ELViewType.after);
            store.dispatch(DualViewAction.updateView(ELViewType.after));
        }

        const zoomValue = store.getState().docStateReducer.zoomLevel;
        if (zoomValue !== ZoomLevel.default) {
            this.dualView?.synchronizeZoom({ type: CanvasZoomLevelAction.changeZoomValue, payload: "100%" });
        }

        await this.doc?.notify(action);
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        if (StageUtils.isLayoutModeOn() && !StageUtils.isLayoutOperation(action.type)) {
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                IngestEventTypes.click, IngestEventSubTypes.socialLayoutAutoCancel, CreationsJobProjectSubType.patternOverlay));

            await this._layoutPanelCancelPress();
        }

        let handled = false;
        switch (action.type) {
            case DocumentActions.markDocumentDirty: {
                this._onDocumentDirty(action.payload as DocumentDirty);
                handled = true;
                break;
            }
            case DocumentActions.commitLayout: {
                await this._commitLayoutWorkflow(action.payload as ELLayoutInfo);
                handled = true;
                break;
            }
            case CreationWorkflowActions.creationRenderStatus: {
                this._onPatternOverlayRendered(action.payload as CreationsStatus);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                const imageData = action.payload as ELImageData;
                this._download(imageData);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.overlayClicked: {
                const thumbInfo = action.payload as ELPreviewCreationThumbData;
                this._overlayClicked(thumbInfo);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView: {
                const workspacePayload = {
                    startWorkflow: WorkflowsName.creationsHome
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ELAdobeAssetControllerAction.showAfterImage: {
                this.dualView?.showDoc(ELViewType.after);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.after, CreationsJobProjectSubType.patternOverlay));
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.showBeforeImage: {
                this.dualView?.showDoc(ELViewType.before);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.before, CreationsJobProjectSubType.patternOverlay));
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent: {
                this.doc?.notify(action);
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, "Zoom-In-Out"));
                handled = true;
                break;
            }
            case CreationMediaActionType.replaceMedia: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay, IngestEventTypes.click, IngestEventSubTypes.replaceMedia));
                const workspacePayload: WorkspacePayload = {
                    startWorkflow: WorkflowsName.replaceMediaManager,
                    payload: {
                        assetId: (this.beforeDoc?.getData() as ELAdobeAssetDocPayload).assetInfo.assetURN,
                        mode: ReplaceMediaManagerMode.replacingMedia,
                        mediaGridConfig: this.mediaGridConfig
                    }
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceMediaSelection: {
                const replaceAssetInfo = action.payload as ReplaceAssetInfo;
                this._onReplaceMedia(replaceAssetInfo);
                handled = true;
                break;
            }
            case RecommendationsInAppNotifierAction.recommendationStatusChanged: {
                const recommendationsStatus = action.payload as RecommendationsStatusData;
                this._onRecommendationRequestStatusChanged(recommendationsStatus);
                handled = true;
                break;
            }
            case CreationInAppNotifierAction.creationStatusChanged: {
                const creationStatusData = action.payload as CreationStatusData;
                this._onProjectRequestStatusChanged(creationStatusData);
                handled = true;
                break;
            }
            case ELOpenInDesktopDeeplinkAction.deeplinkStatusChanged: {
                const payload = action.payload as ELOpenInDesktopOpenAssetPayload;
                this._onOpenInDesktopStatusChanged(payload);
                handled = true;
                break;
            }
            case RecommendationsInAppNotifierAction.recommendationProgressChanged: {
                const payload = action.payload as ELRecommendationsProgressPayload;
                if (payload.requestId === this._requestId) {
                    this._updateProgressPercentageToView(payload.progress);
                }
                handled = true;
                break;
            }
            case CreationInAppNotifierAction.creationProgressChanged: {
                const { progress, projectId } = action.payload as ELProjectProgressPayload;
                if (projectId === this.projectId) {
                    this._updateCreationProgress(progress);
                }
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.commit: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.socialLayoutApply, CreationsJobProjectSubType.patternOverlay));

                await this._layoutPanelApplyPress();
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.cancel: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.socialLayoutCancel, CreationsJobProjectSubType.patternOverlay));

                await this._layoutPanelCancelPress();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.save: {
                this.ingestParams.subType = CreationsMode.save;
                this.ingestParams.eventViewType = IngestLogObjectValue.workspace;
                await this._save();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSave: {
                this.deleteRecommendedCreations(this._requestId);
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.backDialogDontSave, CreationsJobProjectSubType.patternOverlay));
                handled = true;
                break;
            }
            case WorkflowActionType.ingest: {
                this._ingest(action.payload as Record<string, string>);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.scale: {
                this._layoutScaleChange(action);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.change: {
                await this._layoutChange(action);
                handled = true;
                break;
            }
            case ELLayoutPanelControllerAction.revert: {
                this._layoutRevert(action);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.DEBUG, "PatternOverlay(ControllerAction): Invalid action" + action);
                break;
            }
        }
        if (!handled) {
            handled = await super.notify(action);
        }
        return handled;
    }

    private async _waitForSaveComplete(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            await Utils.wait(2000);
            await this._waitForSaveComplete(waitCount + 1);
        }
    }

    private async _waitForDocumentDirtyStatus(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const isDocDirty = store.getState().docStateReducer.isDirty;
        if (isDocDirty === DocumentDirty.DIRTY) {
            await Utils.wait(2000);
            await this._waitForDocumentDirtyStatus(waitCount + 1);
        }
    }

    private async _saveAndStartPreviousWorkflow(): Promise<void> {
        this.ingestParams.subType = CreationsMode.save;
        this.ingestParams.eventViewType = IngestLogObjectValue.dialog;
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            this._updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("saving-creation"));
            await this._waitForSaveComplete();
        } else if (this.doc?.isDocumentDirty === DocumentDirty.DIRTY) {
            await this._save();
            await this._waitForDocumentDirtyStatus();
        }
        this.deleteRecommendedCreations(this._requestId);
        this._startPreviousWorkflow();
    }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case WorkspaceActionType.startPreviousWorkflow: {
                this._saveAndStartPreviousWorkflow();
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, action.payload as string));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fill"));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFit: {
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.patternOverlay, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fit"));
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CreationWorkflowActions.renameCreationTitle: {
                this._onPatternOverlayTitleChange();
                handled = true;
                break;
            }
            default:
                Logger.log(LogLevel.WARN, "PatternOverlay(notifyWorkflow): Invalid action" + action);
                break;
        }
        return handled;
    }
}

export default PatternOverlay;