/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { API } from "../../services/APIServiceWrapper";
import { FeatureName } from "../../services/Floodgate/FloodgateConstants";
import { FloodgateServiceWrapper } from "../../services/Floodgate/FloodgateServiceWrapper";
import { IngestLogging } from "../../services/IngestWrapper";
import FloodgateFeaturesAction from "../../stores/actions/FloodgateFeaturesAction";
import { FeatureMetadatamap } from "../../stores/reducers/FloodgateFeaturesReducer";
import store from "../../stores/store";
import { INGEST_APP_WORKSPACE, IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../utils/IngestConstants";
import { IngestUtils } from "../../utils/IngestUtils";
import Logger, { LogLevel } from "../../utils/Logger";

type ELFeatureMetadataKeyValuePair = { k: string, v: string; };

export class FeaturesManager {
    private static _instance: FeaturesManager | null = null;

    static getInstance(): FeaturesManager {
        if (FeaturesManager._instance === null)
            FeaturesManager._instance = new FeaturesManager();
        return FeaturesManager._instance;
    }

    private _parseFeatureMetadata(metaData: ELFeatureMetadataKeyValuePair[]): FeatureMetadatamap {
        const metadataMap = new Map<FeatureName, Promise<Record<string, unknown>>>();
        metaData.forEach((meta: ELFeatureMetadataKeyValuePair) => {
            const resolvedValue = Buffer.from(meta.v, "base64").toString();
            const metadataContentAPI = new API(resolvedValue);
            const dataPromise = metadataContentAPI.get("").then((response) => response.data);
            metadataMap.set(meta.k as FeatureName, dataPromise);
        });
        return metadataMap;
    }

    private _logFeaturesToIngest(features: string[]): void {
        features.forEach((feature: string) => {
            const featureObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.featureFlag,
                IngestEventTypes.info, IngestEventSubTypes.featureFlagName, feature));
            IngestLogging.getInstance().logEvent(featureObject);
        });
    }

    async refreshFloodgateFeatures(): Promise<void> {
        try {
            const response = await FloodgateServiceWrapper.getInstance().fetchFloodgateFeatures();
            if (response.data?.releases) {
                const features: string[] = [];
                const metaData: ELFeatureMetadataKeyValuePair[] = [];
                response.data.releases.forEach((release: { features: string[],
                    meta: ELFeatureMetadataKeyValuePair[]
                 }) => {
                    features.push(...release.features);
                    metaData.push(...release.meta);
                });
                store.dispatch(FloodgateFeaturesAction.updateFeaturesMap(features));
                store.dispatch(FloodgateFeaturesAction.updateFeaturesMetadata(this._parseFeatureMetadata(metaData)));
                this._logFeaturesToIngest(features);
            } else {
                Logger.log(LogLevel.ERROR, "FeaturesManager:refreshFloodgateFeatures: ", "Incorrect response received from fetch floodgate features service");
            }
        } catch (err) {
            Logger.log(LogLevel.ERROR, "FeaturesManager:refreshFloodgateFeatures: ", "Unknown error in fetching floodgate features");
        }
    }

    IsFeatureActive(featureName: FeatureName): boolean {
        const featuresMap = store.getState().floodgateFeaturesReducer;
        if (featuresMap.featureState.has(featureName))
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return (featuresMap.featureState.get(featureName))!;

        return false;
    }

    getFeatureMetaData(featureName: FeatureName): Promise<Record<string, unknown>> | undefined {
        const featuresMap = store.getState().floodgateFeaturesReducer;
        if (featuresMap.featureMetadata.has(featureName))
            return (featuresMap.featureMetadata.get(featureName));

        return Promise.resolve({});
    }
}
