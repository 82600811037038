/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELSize } from "../geometry/ELGeometry"

export interface ELSocialLayoutThumbData {
    id: string,
    type: string,
    subType: string,
    previewUrl: string,
    aspectRatio: ELSize,
    hoverText: string
}

export enum ELSocialLayoutViewActions {
    updateAllThumbsData = "UPDATE_ALL_THUMBS_DATA",
    selectedPanelId = "SELECTED_PANEL_ID"
}

export interface ELSocialLayoutState {
    viewData: ELSocialLayoutThumbData[],
    selectedPanelId: string
}

export interface ELLayoutInfo {
    top: number,
    left: number,
    bottom: number,
    right: number,
    scaleX: number,
    scaleY: number,
    layoutId: string
}

/*
ELLayoutModeLayerName.floor - floor represents translucent white rectangle layer on top of image to distinct the layouted image from original image
ELLayoutModeLayerName.image - background image layer on which layout will be applied
*/
export enum ELLayoutModeLayerName {
    image = "IMAGE",
    floor = "FLOOR"
}