/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { FeaturesManager } from "../../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../../services/Floodgate/FloodgateConstants";

export default class ELClientCreationsUtils {
    static isFeatureActive(feature: FeatureName): boolean {
        if (localStorage.getItem(feature) === "true") {
            return true;
        } else if (localStorage.getItem(feature) === "false") {
            return false;
        }

        return FeaturesManager.getInstance().IsFeatureActive(feature);
    }
}