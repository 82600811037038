/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//Adobe Internal
import { error } from "@react/react-spectrum/Toast";
import { RenditionType } from "@dcx/assets";

//utils
import Logger, { LogLevel } from "../../../../utils/Logger";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import Constants, { ERROR_THUMBDATA } from "../../../../utils/Constants/Constants";
import { ViewportProvider } from '../../../../utils/hooks/useViewport';
import { IngestUtils } from "../../../../utils/IngestUtils";
import Utils from "../../../../utils/Utils";
import { ToastUtils } from "../../../../utils/ToastUtils";
import CreationInAppNotifier from "../../../../workspaces/creations/utils/CreationInAppNotifier";
import {
    IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey,
    IngestLogObjectValue, IngestWorkflowTypes
} from "../../../../utils/IngestConstants";
import ELCreationsFullAssetProviderFactory from "../../../../workspaces/creations/utils/FullAssetResolver/ELCreationsFullAssetProviderFactory";
import { ELCreationsFullAssetProviderTypes } from "../../../../workspaces/creations/utils/FullAssetResolver/ELCreationsFullAssetProviderTypes";

//Application Specific
import ELRecentCreationsView from "./ELRecentCreationsView";
import ElementsCreationsService from "../../../../services/ElementsServices/ElementsCreationsService";
import {
    CreationsData, CreationsErrorMsg, CreationsJobProjectSubType, CreationsStatus,
    RenameCreationPayload
} from "../../../../common/interfaces/creations/CreationTypes";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import store from "../../../../stores/store";
import CreationsAction, { CreationsThumb } from "../../../../stores/actions/CreationsAction";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { WorkflowAction, WorkflowActionType } from "../../../../workspaces/IWorkflow";
import {
    CreationAppSubscriberType,
} from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { CreationInAppNotifierAction, CreationStatusData } from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import {
    ELRecentCreationsControllerActions,
    ELRecentCreationsViewActions,
    ELRecentCreationsViewData,
    RecentCreationFilterType,
    RecentCreationSortOrderKey,
    RecentCreationsSortByField
} from "../../../../common/interfaces/creations/ELRecentCreationsTypes";
import ITemplateViewController from "../../../ITemplateViewController";
import ELOpenInDesktopManager from "../el-open-in-desktop-manager/ELOpenInDesktopManager";
import { ELOpenDeeplinkAction, ELOpenInDesktopDeeplinkAction, ELOpenInDesktopOpenAssetPayload } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import RecentCreationAction from "../../../../stores/actions/RecentCreationsAction";
import { ConcurrentTaskUtils } from "../../../../utils/ConcurrentTaskUtils";
import { MediaThumbSize } from "../../../../../src/utils/Constants/Constants";


class ELRecentCreations extends ITemplateViewController {
    private _userCreations: CreationsData[] = [];
    private readonly _maxRetryCount = 3;
    private readonly _maxConcurrentRenditionBatch = 10;
    private _openInDesktopManager: ELOpenInDesktopManager;

    private readonly _openDeeplinkContainer = "open-deeplink-container";
    private readonly _viewName = "Recent Creations"

    private _getSortedCreationsByDate(creationsData: CreationsData[], sortOrder: RecentCreationSortOrderKey): CreationsData[] {
        const sortedData = creationsData.sort((data1, data2) =>
            CreationUtils.compareCreationDates(data1.modifiedDate, data2.modifiedDate, sortOrder));
        return sortedData;
    }

    private _getSortedCreationsByName(creationsData: CreationsData[], sortOrder: RecentCreationSortOrderKey): CreationsData[] {
        const sortedData = creationsData.sort((data1, data2) =>
            CreationUtils.compareCreationTitles(data1.title, data2.title, sortOrder));
        return sortedData;
    }

    private _getSortedCreations(creationsData: CreationsData[]): CreationsData[] {
        const sortByField = store.getState().recentCreationReducer.sortBy;
        const sortOrder = store.getState().recentCreationReducer.order === RecentCreationSortOrderKey.descending ?
            RecentCreationSortOrderKey.descending : RecentCreationSortOrderKey.ascending;
        if (sortByField === RecentCreationsSortByField.modifiedDate)
            return this._getSortedCreationsByDate(creationsData, sortOrder);
        else if (sortByField === RecentCreationsSortByField.name)
            return this._getSortedCreationsByName(creationsData, sortOrder);
        else
            return creationsData;
    }

    private async _getRecentCreations(forcedUpdate = true, retryCount = 1): Promise<void> {
        try {
            const cachedCreationsData = store.getState().recentCreationReducer.creationsData;
            const shouldFetchData = forcedUpdate || cachedCreationsData.length === 0;
            if (shouldFetchData) {
                this._userCreations = (await ElementsCreationsService.getInstance().getCreationsData());
                store.dispatch(RecentCreationAction.cacheRecentCreationsList(this._userCreations));
            } else {
                this._userCreations = cachedCreationsData;
            }

            const filterType = store.getState().recentCreationReducer.creationType;
            const filteredCreations = this._filterCreations(filterType);
            this._updateCreationsView(this._getSortedCreations(filteredCreations));

            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.recentCreations, IngestEventTypes.info,
                IngestEventSubTypes.start, this._userCreations.length));
        } catch (e) {
            if (retryCount < this._maxRetryCount) {
                await Utils.exponentialWait(retryCount, this._maxRetryCount);
                this._getRecentCreations(forcedUpdate, retryCount + 1);
            } else {
                const viewData: ELRecentCreationsViewData = {
                    data: [],
                    status: "error"
                }

                this.viewDispatcher?.call(this.viewDispatcher, {
                    type: ELRecentCreationsViewActions.creationsData,
                    payload: viewData
                });
                Logger.log(LogLevel.WARN, "ELRecentCreation:_getRecentCreations: " + CreationsErrorMsg.failedRecentCreationsData + e);
            }
        }
    }

    private _filterCreations(filterType: RecentCreationFilterType): CreationsData[] {
        const filteredCreation: CreationsData[] = [];
        this._userCreations.forEach(creation => {
            let creationOperationSubType = creation.operationSubType;
            if (creationOperationSubType === CreationsJobProjectSubType.magicalBackdrop) {
                creationOperationSubType = CreationsJobProjectSubType.replaceBackground;
            }
            if (filterType === "all" || creationOperationSubType === filterType) {
                filteredCreation.push(creation);
            }
        });
        return filteredCreation;
    }

    private _updateFilteredCreationsView(filterType: RecentCreationFilterType): void {
        store.dispatch(RecentCreationAction.changeFilter(filterType));
        const filteredCreations = this._filterCreations(filterType);
        this._updateCreationsView(this._getSortedCreations(filteredCreations));
    }

    private _updateCreationsViewSortByField(sortByField: RecentCreationsSortByField): void {
        store.dispatch(RecentCreationAction.changeSortByField(sortByField));
        const filterType = store.getState().recentCreationReducer.creationType;
        const filteredCreations = this._filterCreations(filterType);
        this._updateCreationsView(this._getSortedCreations(filteredCreations));
    }

    private _updateCreationsView(filteredCreations: CreationsData[]): void {

        const viewData: ELRecentCreationsViewData = {
            data: filteredCreations,
            status: "success"
        };

        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecentCreationsViewActions.creationsData,
            payload: viewData
        });
    }

    private _updateCreationsViewSortByOrder(sortOrder: RecentCreationSortOrderKey): void {
        store.dispatch(RecentCreationAction.changeSortOrder(sortOrder));
        const filterType = store.getState().recentCreationReducer.creationType;
        const filteredCreations = this._filterCreations(filterType);
        this._updateCreationsView(this._getSortedCreations(filteredCreations));
    }

    private _hasValidCreationOutput(creations: CreationsData): boolean {
        if (!(creations.status === CreationsStatus.success || creations.status === CreationsStatus.oldVersion)) {
            return false;
        }

        if (!creations.outputs) {
            return false;
        }

        return true;
    }

    private async _fetchAllCreationRenditions(creationList: CreationsData[]): Promise<void> {
        ConcurrentTaskUtils.runConcurrentTasks(creationList, this._getCreationRendition.bind(this), this._maxConcurrentRenditionBatch);
    }

    private async _getCreationRendition(creations: CreationsData): Promise<void> {
        if (!this._hasValidCreationOutput(creations)) {
            Logger.log(LogLevel.WARN, "ELRecentCreations:_getCreationRendition: " + CreationsErrorMsg.invalidOutput + JSON.stringify(creations));
            const creationsThumb: CreationsThumb = { id: creations.id, objectURL: ERROR_THUMBDATA };
            store.dispatch(CreationsAction.updateThumb(creationsThumb));
            return Promise.resolve();
        }

        Logger.log(LogLevel.INFO, "ELRecentCreations (_getCreationRendition) : ", creations);

        try {
            const outputAsset = await CreationUtils.getCreationRenditionAsset(creations);
            const response = await CreationUtils.getCreationRendition(outputAsset, RenditionType.IMAGE_PNG, "arraybuffer", Number(MediaThumbSize));
            const sharedArrayImgData = response.result as SharedArrayBuffer;
            const objectURL = URL.createObjectURL(new Blob([sharedArrayImgData]));

            const creationsThumb: CreationsThumb = { id: creations.id, objectURL: objectURL };
            store.dispatch(CreationsAction.updateThumb(creationsThumb));
        } catch (e) {
            const creationsThumb: CreationsThumb = { id: creations.id, objectURL: ERROR_THUMBDATA };
            store.dispatch(CreationsAction.updateThumb(creationsThumb));
            Logger.log(LogLevel.WARN, "ELRecentCreations:_getCreationRendition: " + CreationsErrorMsg.invalidRendtion + JSON.stringify(creations) + " " + e);
        }
        return Promise.resolve();
    }

    private async _getDeleteCreations(data: CreationsData): Promise<void> {
        const message = IntlHandler.getInstance().formatMessage("creation-deletion-in-background");
        ToastUtils.info(message, { timeout: Constants.TOAST_DEFAULT_MEDIUM_TIME_OUT_LIMIT as number });

        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();

        const deleteStatus = await CreationUtils.deleteCreation(data.id);
        if (deleteStatus) {
            ToastUtils.success(IntlHandler.getInstance().formatMessage("items-deleted",
                { itemCount: 1, media: IntlHandler.getInstance().formatMessage("creation") }), {
                timeout: Constants.TOAST_DEFAULT_MEDIUM_TIME_OUT_LIMIT as number
            });
            this._getRecentCreations();
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.delete, data.operationSubType, additionalLogInfo));
        } else {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-deleting-creation"), {
                timeout: Constants.TOAST_DEFAULT_MEDIUM_TIME_OUT_LIMIT as number
            });
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.delete, data.operationSubType, additionalLogInfo));
        }
    }

    private _getCreationDisplayNameFromOperationSubtype(data: CreationsData): string {
        const intlHandler = IntlHandler.getInstance();
        switch (data.operationSubType) {
            case CreationsJobProjectSubType.slideshow: {
                return intlHandler.formatMessage("slideshow").toLowerCase();
            }
            case CreationsJobProjectSubType.photoCollage: {
                return intlHandler.formatMessage("photo-collage").toLowerCase();
            }
            case CreationsJobProjectSubType.patternOverlay: {
                return intlHandler.formatMessage("pattern-overlay-creation").toLowerCase();
            }
            case CreationsJobProjectSubType.peekThrough: {
                return intlHandler.formatMessage("peek-through-creation").toLowerCase();
            }
            case CreationsJobProjectSubType.movingOverlay: {
                return intlHandler.formatMessage("moving-overlay-creation").toLowerCase();
            }
            case CreationsJobProjectSubType.replaceBackground: {
                return intlHandler.formatMessage("replace-background-creation").toLowerCase();
            }
            default: {
                return intlHandler.formatMessage("creation");
            }
        }
    }

    private async _getDownloadCreations(creationData: CreationsData): Promise<void> {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.grid;

        const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
            { media: this._getCreationDisplayNameFromOperationSubtype(creationData) });
        ToastUtils.info(message, { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number });
        try {
            await CreationUtils.downloadCreation(creationData);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.download, creationData.operationSubType, additionalLogInfo));
        } catch (e) {
            //wait for download start message to go away then show the failed msg
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    timeout: Constants.TOAST_NO_TIMEOUT as number
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.download, creationData.operationSubType, additionalLogInfo));
            Logger.log(LogLevel.WARN, "ELRecentCreations:notifyController: ", "could not download creation ", creationData);
        }
    }

    private async _renameCreation(payload: RenameCreationPayload): Promise<void> {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.grid;

        try {
            const status = await ElementsCreationsService.getInstance().renameCreation(payload.projectId, payload.title);
            if (status) {
                await this._getRecentCreations();
                ToastUtils.success(IntlHandler.getInstance().formatMessage("success-renaming"));
                this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.success, IngestEventTypes.rename, payload.operationSubType, additionalLogInfo));
                return;
            }
        } catch (err) {
            Logger.log(LogLevel.WARN, "ELRecentCreation:_renameCreation): ", error);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.rename, payload.operationSubType, additionalLogInfo));
            ToastUtils.error(IntlHandler.getInstance().formatMessage("error-renaming"));
        }
    }

    private _updateViewStatus(status: CreationsStatus): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecentCreationsViewActions.recentCreationStatus,
            payload: status,
        });
    }

    private _updateProgressText(progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecentCreationsViewActions.recentCreationProgressText,
            payload: progressText
        });
    }

    private async _openDeeplink(creationData: CreationsData): Promise<void> {
        try {
            const fullAssetProvider = ELCreationsFullAssetProviderFactory.createFullAssetProvider(this, creationData);
            await fullAssetProvider.getFullAssetData();
        } catch {
            Logger.log(LogLevel.ERROR,
                "ELRecentCreations:_openDeeplink, ELRecentCreations project creation failed!");
            this._updateViewStatus(CreationsStatus.error);
            const message = IntlHandler.getInstance().formatMessage("failed-to-open-creation-in-desktop");
            ToastUtils.error(message);
            this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.error,
                IngestEventSubTypes.openInDesktop));
        }
    }

    private _ingest(payload: Record<string, string>): void {
        this.notify({
            type: WorkflowActionType.ingest,
            payload: payload

        });
    }

    private async _onOpenInDesktopStatusChanged(payload: ELOpenInDesktopOpenAssetPayload): Promise<void> {
        this._openInDesktopManager.notify({ type: ELOpenDeeplinkAction.openDeepLinkForAsset, payload: payload });
    }

    constructor(_owner: IViewController) {
        super(_owner);
        this._openInDesktopManager = new ELOpenInDesktopManager(this, this._viewName);
    }

    private _isForceUpdate(): boolean {
        const cachedCreationsData = store.getState().recentCreationReducer.creationsData;
        return (cachedCreationsData.filter(creation => creation.status === CreationsStatus.requested).length !== 0);
    }


    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);

        this._openInDesktopManager.createView(this.ensureHTMLElement(this._openDeeplinkContainer));
        CreationInAppNotifier.subscribe(this, CreationAppSubscriberType.statusChange);
        this._getRecentCreations(this._isForceUpdate());
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const element = React.createElement(ELRecentCreationsView, {
            controller: this
        });

        const reduxProvider = React.createElement(Provider, { store }, element);
        const viewPortProvider = React.createElement(ViewportProvider, null, reduxProvider);

        ReactDOM.render(
            viewPortProvider,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELRecentCreationsControllerActions.fetchAllThumbnails: 
                {
                    this._fetchAllCreationRenditions(action.payload as CreationsData[]);
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.fetchThumbnail:
                {
                    this._getCreationRendition(action.payload as CreationsData);
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.filterCreations:
                {
                    const filterType = action.payload as RecentCreationFilterType;
                    this._updateFilteredCreationsView(filterType);
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.sortCreationsByField:
                {
                    const sortByField = action.payload as RecentCreationsSortByField;
                    this._updateCreationsViewSortByField(sortByField);
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.sortCreationsByOrder:
                {
                    const order = action.payload as RecentCreationSortOrderKey;
                    this._updateCreationsViewSortByOrder(order);
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.openCreation:
                {
                    const creationsData = action.payload as CreationsData;

                    const workflowPayload = {
                        nextWorkflow: CreationUtils.getWorkflowNameFromOperationSubtype(creationsData),
                        payload: { projectId: creationsData.id, data: creationsData }
                    }
                    const workflowAction: WorkflowAction = { type: ELRecentCreationsControllerActions.openCreation, ...workflowPayload };
                    handled = await this._owner.notify(workflowAction);
                    break;
                }
            case ELRecentCreationsControllerActions.refreshCreation:
                {
                    this._getRecentCreations();
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.deleteCreation:
                {
                    this._getDeleteCreations(action.payload as CreationsData);
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.downloadCreation:
                {
                    this._getDownloadCreations(action.payload as CreationsData);
                    handled = true;
                    break;
                }
            case ELRecentCreationsControllerActions.renameCreation:
                {
                    this._renameCreation(action.payload as RenameCreationPayload);
                    handled = true;
                    break;
                }
            case CreationInAppNotifierAction.creationStatusChanged:
                {
                    const creationStatusData = action.payload as CreationStatusData;
                    Logger.log(LogLevel.INFO, "CreationInAppNotifierAction.creationStatusChanged", creationStatusData);
                    this._getRecentCreations(true);
                    handled = true;
                    break;
                }
            case WorkflowActionType.ingest:
                {
                    handled = await this._owner.notify(action);
                    break;
                }
            case ELRecentCreationsControllerActions.openDeepLink:
                {
                    this._openDeeplink(action.payload as CreationsData);
                    handled = true;
                    break;
                }
            case ELOpenInDesktopDeeplinkAction.deeplinkStatusChanged: {
                const creationAssetData = action.payload as ELOpenInDesktopOpenAssetPayload;
                this._updateViewStatus(CreationsStatus.success);
                this._onOpenInDesktopStatusChanged(creationAssetData);
                handled = true;
                break;
            }
            case ELCreationsFullAssetProviderTypes.assetIDResolved: {
                const creationAssetData = action.payload as ELOpenInDesktopOpenAssetPayload;
                this._openInDesktopManager.notify({ type: ELOpenDeeplinkAction.openDeepLinkForAsset, payload: creationAssetData });
                handled = true;
                break;
            }
            case ELCreationsFullAssetProviderTypes.creationStatus: {
                const creationsStatus = action.payload as CreationsStatus;
                if (creationsStatus === CreationsStatus.requested) {
                    const message = IntlHandler.getInstance().formatMessage("getting-creation-to-open-in-desktop");
                    this._updateProgressText(message);
                }
                this._updateViewStatus(creationsStatus);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "ELRecentCreations:notify: ", "bad controller action", action);
            }
        }
        return handled;
    }
}

export default ELRecentCreations;
