/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Request } from "@elements/elementswebcommon";

//Application Specific
import ELCreationBatchRequestHandler from "./ELCreationBatchRequestHandler";
import {
    ELRecommendationsProgressPayload,
    RecommendationsAppSubscriberType,
    RecommendationsInAppNotifierAction,
    RecommendationsStatusData
} from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import ElementsCreationInAppNotifier from "../../../../workspaces/creations/utils/ElementsCreationInAppNotifier";
import RecommendationsInAppNotifier from "../../../../workspaces/creations/utils/RecommendationsInAppNotifier";
import ElementsCreationsService from "../../../../services/ElementsServices/ElementsCreationsService";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ControllerAction } from "../../../../view/IViewController";
import {
    ELBatchProcessorAction,
    ELCreationBatchActionTypes,
    ELRecommendationBatchResponseData
} from "../../../../common/interfaces/modules/elBatchHandler/ELCreationBatchHandlerTypes";

class RecommendationBatchRequestHandler extends ELCreationBatchRequestHandler<ELRecommendationBatchResponseData[]> {

    protected updateRequestResponses(responseData: ELRecommendationBatchResponseData[]): void {
        if (this.requestResponses) {
            this.requestResponses.concat(responseData);
        } else {
            this.requestResponses = responseData;
        }
    }

    protected pollStatusForRequestResponses(requestResponses: ELRecommendationBatchResponseData[]): void {
        if (requestResponses) {
            requestResponses.forEach((responseData) => {
                this.notifier.pollStatus(responseData.requestId);
            });
        }
    }

    protected getNotifierActionType(): unknown {
        return RecommendationsAppSubscriberType.statusChange;
    }

    protected getNotifier(): ElementsCreationInAppNotifier {
        return RecommendationsInAppNotifier;
    }

    protected async makeRequests(requestObjList: Request[]): Promise<ELRecommendationBatchResponseData[]> {
        try {
            const promiseForRequestIds = Promise.all(requestObjList.map(async (requestObj) => {
                const requestJson = requestObj.serializeOut();
                return {
                    requestId: await ElementsCreationsService.getInstance().createRecommendations(requestJson),
                    requestObj: requestObj
                };
            }));
            const responseData = await promiseForRequestIds;
            return responseData;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "RecommendationBatchRequestHandler:makeRequests(), failed with error: " + error);
            return Promise.reject(error);
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {

        let handled = false;

        switch (action.type) {
            case RecommendationsInAppNotifierAction.recommendationStatusChanged: {
                const recommendationsStatus = action.payload as RecommendationsStatusData;
                const batchAction: ELBatchProcessorAction = {
                    type: ELCreationBatchActionTypes.recommendationBatchStatusChanged,
                    payload: recommendationsStatus
                };
                handled = await this.owner.notify(batchAction);
                break;
            }
            case RecommendationsInAppNotifierAction.recommendationProgressChanged: {
                const recommendationProgressPayload = action.payload as ELRecommendationsProgressPayload;
                const batchAction: ELBatchProcessorAction = {
                    type: ELCreationBatchActionTypes.recommendationBatchProgressChanged,
                    payload: recommendationProgressPayload
                };
                handled = await this.owner.notify(batchAction);
                break;
            }
            default: {
                Logger.log(LogLevel.INFO, "RecommendationBatchRequestHandler: notify, invalid action: " + action);
                break;
            }
        }

        return Promise.resolve(handled);
    }
}

export default RecommendationBatchRequestHandler;