/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React from "react";

// Adobe internal
import { Content, Dialog, Divider, Flex, Heading, Text } from "@adobe/react-spectrum";

// Application Specific
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import ELIconButton from "../../molecules/el-icon-button/ELIconButtonView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ShareOptionsView } from "../el-share-options/ELShareOptionsView";
import { ShareOptions } from "../el-share-options/ELShareOptions";
import { handleDiscard } from "./ELManageShareLinkDialog";

import "./ELManageShareLinkDialog.scss";

interface ManageShareLinkDialogProps {
    heading: string,
    shareAgain: string,
    stopSharing: string,
    discardLinkMessage: string,
    onClose: () => void,
    onShareOptionsPress: () => void,
    shareOptionsController: ShareOptions,
}

export const ManageShareLinkDialog = (props: ManageShareLinkDialogProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    const handleShareOptionsPress = (): void => {
        props.onShareOptionsPress();
    }

    return (
        <Dialog UNSAFE_className={"share-link-dialog__container"} data-testid="manage-share-link-dialog">
            <Heading>
                <div className="share-link-dialog__title">{props.heading}</div>
            </Heading>
            <Content>
                <div className="share-link-dialog__again">{props.shareAgain}</div>
                <Flex direction="column" alignItems="start" marginBottom="1rem" gap="size-200">
                    <ShareOptionsView controller={props.shareOptionsController} onPress={() => { handleShareOptionsPress() }} showQRView={false} />
                </Flex>
                <Divider size={"S"} />
                <div className="share-link-dialog__stop">{props.stopSharing}</div>
                <Flex direction="column" alignItems="start" marginTop="1rem">
                    <ELIconButton size="L" iconkey={IconType.cancel} variant="negative" iconColor={Theme.global.share_link_button_N} iconHoverColor={Theme.global.share_link_button_H}
                        iconWidth={"1.125rem"} iconHeight={"1.125rem"} onClick={() => handleDiscard()} >
                        <Text UNSAFE_className="share-link-dialog__button-text">{intlHandler.formatMessage("discard-link")}</Text>
                    </ ELIconButton>
                    <Text UNSAFE_className="share-link-dialog__text">{props.discardLinkMessage}</Text>
                </Flex>
            </Content>
        </Dialog>
    )
}
