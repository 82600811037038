/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";

//Application Specific
import Logger, { LogLevel } from "../../../../utils/Logger";

export interface ELIFrameProps extends React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> {
    title: string
    messageHandler?: (event: MessageEvent<any>) => any
}

export const ELIframe = (props: ELIFrameProps): React.ReactElement => {
    const { messageHandler, ...iframeProps } = props;

    useEffect(() => {
        const handleEvent = (event: MessageEvent<any>): any => {
            try {
                const origin = props.src ? (new URL(props.src)).origin : "";
                if (event.origin === origin && props.messageHandler) {
                    props.messageHandler(event);
                }
            } catch(error: any) {
                Logger.log(LogLevel.WARN, "ELIframe: handleEvent: Error getting origin from URL", error);
            }
        }

        window.addEventListener("message", handleEvent, false);
        return () => {
            window.removeEventListener("message", handleEvent);
        }
    }, [props.messageHandler, props.src]);

    return (
        <iframe {...iframeProps} title={props.title}/>
    )
}