/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const enum PSFeatureName {
    generateMask = "generateMask"
}

/**
 * copied from "@adobe/aio-lib-photoshop-api" - breaking UT due to usage of spread operator, hence copying enum.
 */
export enum MimeType {
    /**
     * Digital Negative, available from `autoTone`, `straighten`, `applyPreset`
     */
    DNG = "image/x-adobe-dng",
    /**
     * JPEG, available from all operations
     */
    JPEG = "image/jpeg",
    /**
     * PNG, available from all operations
     */
    PNG = "image/png",
    /**
     * Photoshop Document, available from `createDocument`, `modifyDocument`, `createRendition`, `replaceSmartObject`
     */
    PSD = "image/vnd.adobe.photoshop",
    /**
     * TIFF format, available from `createDocument`, `modifyDocument`, `createRendition`, `replaceSmartObject`
     */
    TIFF = "image/tiff"
}

export enum Storage {
    /**
     * href is a path in Adobe I/O Files: https://github.com/adobe/aio-lib-files
     */
    AIO = "aio",
    /**
     * href is a path in Creative Cloud
     */
    ADOBE = "adobe",
    /**
     * href is a presigned get/put url, e.g. AWS S3
     */
    EXTERNAL = "external",
    /**
     * href is an Azure SAS (Shared Access Signature) URL for upload/download
     */
    AZURE = "azure",
    /**
     * href is a temporary upload/download Dropbox link: https://dropbox.github.io/dropbox-api-v2-explorer/
     */
    DROPBOX = "dropbox"
}


export interface PSFeaturePayload {
    featureName: PSFeatureName,
    inputPath: string,
    inputStorage?: Storage
}