/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom"
import { Provider as ReactReduxProvider } from "react-redux";
import _ from "lodash";

//Application Specific
import ELMediaGridView, { ELMediaGridLayoutConfig, EL_MEDIA_GRID_SCROLL_THROTTLE_TIME } from "./ELMediaGridView"
import { ViewAction } from "../../../IBaseController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import IViewController, { ControllerAction } from "../../../IViewController";
import IWorkflow, { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import store from "../../../../stores/store";
import { MediaFetchHook } from "../../../../utils/hooks/useMediaFetch";
import { FilterMediaUiProp, MediaGridConfig, MediaGridSortBy } from "../../../../stores/actions/mediaGridConfigActions";
import { ELAdobeAsset, elDeserializeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import Utils from "../../../../utils/Utils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { ELMediaSelectionMode } from "../../../../common/interfaces/media/ELThumbTypes";

export type SelectedMediaListType = ELAdobeAsset[];
export type GetMediaFetchHookFunc = (path: string) => MediaFetchHook;
export type GetSelectedMediaAssetsFunc = () => SelectedMediaListType;
export type SetSelectedMediaAssetsFunc = (arr: SelectedMediaListType) => void;

export type VisibleElementType = ELMediaTileData | ELSectionHeaderData | ELAnchorData;

type VisibleElementsCallbackFunc = (inp: VisibleElementType[]) => void;
type MarkPendingFetchCallbackFunc = () => void;

const RESIZE_THROTTLE_TIME = 500;

const CREATE_DATE = "createDate";
const MODIFY_DATE = "modifyDate";
const CAPTURE_DATE = "deviceCreateDate";

export enum ElementType {
    tile,
    sectionHeading,
    anchor
}

export interface ELMediaTileData {
    positionX: number;
    positionY: number;
    width: number,
    height: number,
    elementType: ElementType,
    asset: ELAdobeAsset,
    isHidden?: boolean,
    isDisabled?: boolean
}

export interface ELSectionHeaderData {
    positionX: number,
    positionY: number,
    title: string,
    elementType: ElementType
}

export interface ELAnchorData {
    positionX: number,
    positionY: number,
    elementType: ElementType
}

export interface ELMediaGridData {
    workflow: IWorkflow,
    mediaFetchHookFunc: GetMediaFetchHookFunc,
    dirPath: string,
    createTracks: boolean,
    emptyGridBanner: React.ReactElement,
    selectionEnabled: boolean,
    selectedAssets?: SelectedMediaListType,
    setSelectedAssets?: SetSelectedMediaAssetsFunc,
    setSelectedMediaAssetsInWorkflow?: SetSelectedMediaAssetsFunc,
    toolbar?: IViewController,
    componentRenderedCallback?: () => void,
    selectionMode: ELMediaSelectionMode
}

export enum ELMediaGridControllerAction {
    startSIV = "START_SIV"
}

export class MediaGridHandler {
    private _setVisibleElements: VisibleElementsCallbackFunc;
    private _markPendingDirFetch: MarkPendingFetchCallbackFunc;

    private _containerElemRef: React.RefObject<HTMLDivElement> | null = null;
    private _scrollContainerRectPos: DOMRect = new DOMRect();

    private _pendingDirFetch = true;
    private _inRequestAnimationFrame = false;

    private _visibleElementsList: Array<VisibleElementType> = [];
    private _elementList: Array<VisibleElementType> = [];
    private _ingest: (_: Record<string, string>) => void;

    private _lowerBoundaryMarkForFetching = 0;
    private _layoutConfig: ELMediaGridLayoutConfig;
    private _containerResizeObserver;

    resizeEventHandler = _.throttle((): void => {
        if (this._elementList.length === 0)
            return;

        if (!this._inRequestAnimationFrame) {
            window.requestAnimationFrame(() => {
                this.computeLayout();
                this.doLayout();
                this._setVisibleElements(this._visibleElementsList);
                this._inRequestAnimationFrame = false;
            });
        }
        this._inRequestAnimationFrame = true;
    }, RESIZE_THROTTLE_TIME);

    constructor(setVisibleElements: VisibleElementsCallbackFunc,
        markPendingDirFetch: MarkPendingFetchCallbackFunc,
        layoutConfig: ELMediaGridLayoutConfig, ingest: (_: Record<string, string>) => void) {
        this._setVisibleElements = setVisibleElements;
        this._markPendingDirFetch = markPendingDirFetch;
        this._layoutConfig = layoutConfig;
        this._ingest = ingest;
        this._containerResizeObserver = new ResizeObserver(this.resizeEventHandler);
    }

    private _scrollAssetInView = (assetId: string): void => {
        this._elementList.some((ele) => {
            if (ele.elementType === ElementType.tile) {
                const mediaTile = ele as ELMediaTileData;
                if (mediaTile.asset.assetId === assetId) {
                    const scrollPadding = 20;
                    const mediaTileBottom = mediaTile.positionY + mediaTile.height + scrollPadding;
                    const mediaTileTop = mediaTile.positionY - scrollPadding;
                    let scrollTop = null;
                    if (this._containerElemRef && this._containerElemRef.current) {
                        const isAssetNotVisible = (mediaTileTop < this._containerElemRef.current.scrollTop) ||
                            (mediaTileBottom > (this._containerElemRef.current.scrollTop + this._scrollContainerRectPos.height));
                        if (isAssetNotVisible)
                            scrollTop = mediaTileTop - this._containerElemRef.current.scrollTop;
                    }
                    if (scrollTop) {
                        this._containerElemRef?.current?.scrollBy({
                            top: scrollTop,
                            behavior: 'smooth'
                        });
                    }
                    return true;
                }
            }
            return false;
        });
    }

    setPendingDirFetch(flag: boolean): void {
        this._pendingDirFetch = flag;
        if (flag === true) {
            this._markPendingDirFetch();
        }
    }

    setContainerRef(container: React.RefObject<HTMLDivElement>): void {
        if (container.current) {
            this._containerElemRef = container;
            this.addEventListeners();
        } else {
            Logger.log(LogLevel.WARN, "ELMediaGrid:setContainerRef: ", "Container Ref not defined");
        }
    }

    addEventListeners = (): void => {
        this._containerElemRef?.current?.addEventListener('scroll', this.scrollEventHandler);
        this._containerElemRef?.current && this._containerResizeObserver.observe(this._containerElemRef?.current);
    }

    removeEventListeners = (): void => {
        this._containerElemRef?.current?.removeEventListener('scroll', this.scrollEventHandler);
        this._containerElemRef?.current && this._containerResizeObserver.unobserve(this._containerElemRef?.current);
    }

    scrollEventHandler = _.throttle((): void => {
        if (this._elementList.length === 0)
            return;

        if (!this._inRequestAnimationFrame) {
            window.requestAnimationFrame(() => {
                this.doLayout();
                this._setVisibleElements(this._visibleElementsList);
                this._inRequestAnimationFrame = false;
            })
        }
        this._inRequestAnimationFrame = true;
    }, EL_MEDIA_GRID_SCROLL_THROTTLE_TIME);

    private _getKeyValue = <U extends keyof T, T extends Record<string, any>>(key: U) => (obj: T) =>
        obj[key];

    getAssetKeyFromSortOrderParam = (sortOrder: MediaGridSortBy): string => {
        switch (sortOrder) {
            case MediaGridSortBy.importDate:
                return CREATE_DATE;
            case MediaGridSortBy.modifiedDate:
                return MODIFY_DATE;
            case MediaGridSortBy.createdDate:
                return CAPTURE_DATE;
            default:
                return MODIFY_DATE;
        }
    }

    private async _sendToIngest(data: Array<ELAdobeAsset>, stacks: number): Promise<void> {
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGridPhotos, IngestEventTypes.info,
            IngestEventSubTypes.count, AssetStorageUtils.parsePhotoCount(data)));
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGridVideos, IngestEventTypes.info,
            IngestEventSubTypes.count, AssetStorageUtils.parseVideoCount(data)));
        this._ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGridStacks, IngestEventTypes.info,
            IngestEventSubTypes.count, stacks));
    }

    private _getMediaUIProp(elAdobeAsset: ELAdobeAsset, config: MediaGridConfig): Record<string, boolean> {
        let isInvalidFormat = false;
        let disabled = false;
        let hidden = false;
        if (elAdobeAsset.format) {
            if (config.filterMedia.format.includes(elAdobeAsset.format)) {
                isInvalidFormat = true;
                disabled = config.filterMedia.uiProp === FilterMediaUiProp.disable;
                hidden = config.filterMedia.uiProp === FilterMediaUiProp.hide;
            }
        }
        return { isInvalidFormat, disabled, hidden };
    }

    private _removeEmptySectionHeading(): void {
        const listLength = this._elementList.length;
        this._elementList = this._elementList.filter((element) => {
            if (element.elementType === ElementType.sectionHeading) {
                const indx = this._elementList.indexOf(element) + 1;
                const isNextElementTile = (indx < listLength && this._elementList[indx].elementType === ElementType.tile);
                if (isNextElementTile) {
                    return true;
                }
                return false;
            }
            return true;
        });
    }

    parseDirListingData = (data: Array<ELAdobeAsset>, createTracks: boolean, config: MediaGridConfig): void => {
        const sectionMap: Map<string, boolean> = new Map();
        this._elementList = data.flatMap((elem) => {
            const elements = Array<ELMediaTileData | ELSectionHeaderData>();
            const elAdobeAsset = elDeserializeAsset(elem) as ELAdobeAsset;

            const assetKeyStr = this.getAssetKeyFromSortOrderParam(config.sortBy);
            const key = assetKeyStr as keyof ELAdobeAsset;
            if (createTracks === true && key === undefined) {
                Logger.log(LogLevel.ERROR, "ELMediaGrid:parseDirListingData: ", "Error reading sortBy from ELAdobeAsset");
                return elements;
            }
            let trackPropertyValue = "modifyDate"; // default property
            if (createTracks === true && assetKeyStr) {
                trackPropertyValue = this._getKeyValue<keyof ELAdobeAsset, ELAdobeAsset>(key)(elAdobeAsset) as string;
            }
            if (trackPropertyValue) {
                const date = new Date(trackPropertyValue);
                const isTodaysDate = Utils.isTodaysDate(date);
                const sectionMapKey = isTodaysDate ? "Today" : date.getMonth() + "_" + date.getFullYear();
                if ((createTracks === true) && !(sectionMap.get(sectionMapKey))) {
                    sectionMap.set(sectionMapKey, true);
                    const sectionHeading: ELSectionHeaderData = {
                        positionX: 0,
                        positionY: 0,
                        title: trackPropertyValue,
                        elementType: ElementType.sectionHeading
                    }
                    elements.push(sectionHeading);
                }

                const { isInvalidFormat, disabled, hidden } = this._getMediaUIProp(elAdobeAsset, config);
                const mediaTile: ELMediaTileData = {
                    positionX: 0,
                    positionY: 0,
                    width: 0,
                    height: 0,
                    elementType: ElementType.tile,
                    asset: elAdobeAsset,
                    isHidden: isInvalidFormat && hidden,
                    isDisabled: isInvalidFormat && disabled
                }
                if (!mediaTile.isHidden)
                    elements.push(mediaTile);
            } else {
                Logger.log(LogLevel.ERROR, "ELMediaGrid:parseDirListingData: ", "Could not parse", elAdobeAsset.assetId, config.sortBy, "property not found");
            }
            return elements;
        });

        this._removeEmptySectionHeading();

        const anchorStart: ELAnchorData = {
            positionX: 0,
            positionY: 0,
            elementType: ElementType.anchor
        };
        const anchorEnd: ELAnchorData = {
            positionX: 0,
            positionY: 0,
            elementType: ElementType.anchor
        };
        this._elementList.unshift(anchorStart);
        this._elementList.push(anchorEnd);
        this._sendToIngest(data, sectionMap.size);
    }

    getRenderedLayoutConfig = (layoutConfig: ELMediaGridLayoutConfig): ELMediaGridLayoutConfig => {
        const renderedFactor = Utils.getRenderedFactor();
        const xDistBetweenTile = (layoutConfig.X_DIST_BETWEEN_TILE / Utils.getBaseFontScale()) * renderedFactor;
        const yDistBetweenTile = (layoutConfig.Y_DIST_BETWEEN_TILE / Utils.getBaseFontScale()) * renderedFactor;

        const mediaGridLayoutConfig: ELMediaGridLayoutConfig = {
            TILE_W: layoutConfig.TILE_W * renderedFactor,
            TILE_H: layoutConfig.TILE_H * renderedFactor,
            SECTION_HEADING_H: layoutConfig.SECTION_HEADING_H * renderedFactor,
            BUF_SIZE: layoutConfig.BUF_SIZE,
            Y_DIST_BETWEEN_TILE: Math.max(layoutConfig.Y_DIST_BETWEEN_TILE, yDistBetweenTile),
            X_DIST_BETWEEN_TILE: Math.max(layoutConfig.X_DIST_BETWEEN_TILE, xDistBetweenTile),
            Y_DIST_BETWEEN_SECTION: layoutConfig.Y_DIST_BETWEEN_SECTION * renderedFactor,
            OFFSET_PERCENTAGE_X: layoutConfig.OFFSET_PERCENTAGE_X,
            Y_OFFSET_TOP: layoutConfig.Y_OFFSET_TOP * renderedFactor,
            Y_OFFSET_BOTTOM: layoutConfig.Y_OFFSET_BOTTOM * renderedFactor
        };

        return mediaGridLayoutConfig;
    }

    onSIVAssetUpdated = (assetId: string): void => {
        this._scrollAssetInView(assetId);
    }

    computeLayout = (): void => {
        let yOffSet = 0;
        if (this._containerElemRef?.current) {
            this._scrollContainerRectPos = this._containerElemRef.current.getBoundingClientRect();
            const toolbar = document.getElementById("grid-toolbar");
            if (toolbar) {
                yOffSet = parseInt(window.getComputedStyle(toolbar).height);
            }
        }
        const layout = this.getRenderedLayoutConfig(this._layoutConfig);
        const scrollContainerWidth = this._scrollContainerRectPos.right - this._scrollContainerRectPos.left;
        const gridStartX = scrollContainerWidth * (layout.OFFSET_PERCENTAGE_X / 100.0);
        const gridEndX = Number(this._scrollContainerRectPos.right - gridStartX);
        let posX = gridStartX;
        let posY = yOffSet + layout.Y_OFFSET_TOP;

        const { width, height } = Utils.getBestFitTileInGrid(layout, (gridEndX - gridStartX));

        layout.TILE_H = height;
        layout.TILE_W = width;

        this._elementList = this._elementList.map((ele, indx) => {
            if (ele.elementType === ElementType.sectionHeading) {
                if (posX > gridStartX) {
                    posY += layout.TILE_H + layout.Y_DIST_BETWEEN_SECTION;
                }
                ele.positionX = gridStartX;
                ele.positionY = posY;
                posX = gridStartX;
                posY += layout.SECTION_HEADING_H;
            } else if (ele.elementType === ElementType.tile) {
                ele.positionX = posX;
                ele.positionY = posY;
                (ele as ELMediaTileData).width = layout.TILE_W;
                (ele as ELMediaTileData).height = layout.TILE_H;

                posX += layout.TILE_W;
                const isGridEnd = !(posX < gridEndX);

                if (!isGridEnd)
                    posX += layout.X_DIST_BETWEEN_TILE;

                const isNextElementTile = ((indx + 1) < this._elementList.length &&
                    (this._elementList[indx + 1].elementType === ElementType.tile));

                if (posX + layout.TILE_W > gridEndX && isNextElementTile) {
                    posX = gridStartX;
                    posY += layout.TILE_H + layout.Y_DIST_BETWEEN_TILE;
                }
            } else if (ele.elementType === ElementType.anchor) {
                if (indx === 0) {
                    ele.positionX = gridStartX;
                    ele.positionY = posY;
                } else {
                    if (posX === gridStartX) {
                        ele.positionY = posY + layout.Y_OFFSET_BOTTOM;
                    } else {
                        ele.positionY = posY + layout.TILE_H + layout.Y_OFFSET_BOTTOM;
                    }
                    ele.positionX = gridStartX;
                }
            }
            return ele;
        });
        this._lowerBoundaryMarkForFetching = posY - layout.BUF_SIZE;
    }

    doLayout = (): void => {
        let currentScroll = 0;
        if (this._containerElemRef?.current) {
            currentScroll = this._containerElemRef.current.scrollTop;
        }
        if (currentScroll > this._lowerBoundaryMarkForFetching && this._pendingDirFetch === false) {
            this.setPendingDirFetch(true);
            Logger.log(LogLevel.DEBUG, "Need to fetch dir", currentScroll, this._lowerBoundaryMarkForFetching);
        }
        const buffSize = this._layoutConfig.BUF_SIZE;
        this._visibleElementsList = this._elementList.filter(ele => {
            if (ele.elementType === ElementType.anchor) {
                return true;
            }

            if (ele && ele.positionY >= (currentScroll - buffSize) && ele.positionY <= (currentScroll + this._scrollContainerRectPos.height + buffSize)) {
                return true;
            }
            return false;
        });
    }

    setData = (data: ELAdobeAsset[], createTracks: boolean, config: MediaGridConfig): void => {
        Logger.log(LogLevel.DEBUG, 'set data called', data);
        this.parseDirListingData(data, createTracks, config);
        this.computeLayout();
        this.doLayout();
        this._setVisibleElements(this._visibleElementsList);
    }
}

class ELMediaGrid extends IViewController {
    private _mediaGridData: ELMediaGridData;
    /** The sizes are as per spec divided by 16 for rem or em.
    * Except BUF_SIZE, X_DIST_BETWEEN_TILE, Y_DIST_BETWEEN_TILE which are kept at px size
    * OFFSET_PERCENTAGE_X is in percentage
    * */
    private _mediaGridLayoutCSSConfig: ELMediaGridLayoutConfig = {
        TILE_W: 16.75,
        TILE_H: 14.5,
        SECTION_HEADING_H: 3.125,
        BUF_SIZE: 1500,
        Y_DIST_BETWEEN_TILE: 18,
        X_DIST_BETWEEN_TILE: 18,
        Y_DIST_BETWEEN_SECTION: 4.375,
        OFFSET_PERCENTAGE_X: 5,
        Y_OFFSET_TOP: 1.875,
        Y_OFFSET_BOTTOM: 1.875
    };

    constructor(mediaGridData: ELMediaGridData,
        mediaGridConfig?: ELMediaGridLayoutConfig) {
        super();
        this._mediaGridData = mediaGridData
        this._mediaGridData.selectedAssets = [];
        // we need this toolbar as everything in media grid which is scrollable is absolutely positioned.
        // if we provide a valid toolbar object to constructor, grid will position the toolbar in grid-toolbar div, and shift
        // vertically down to make space for toolbar.
        if (mediaGridConfig) {
            this._mediaGridLayoutCSSConfig = mediaGridConfig;
        }

        const setSelectedAssets = (arr: SelectedMediaListType): void => {
            this._mediaGridData.selectedAssets = arr;
            if (this._mediaGridData.setSelectedMediaAssetsInWorkflow)
                this._mediaGridData.setSelectedMediaAssetsInWorkflow(arr);
        }
        this._mediaGridData.setSelectedAssets = setSelectedAssets;
    }

    createView(container: HTMLElement): void {
        super.createView(container)
        const mediaGrid = React.createElement(ELMediaGridView, {
            controller: this,
            dirPath: this._mediaGridData.dirPath,
            getMediaFetchHook: this._mediaGridData.mediaFetchHookFunc,
            layoutConfig: this._mediaGridLayoutCSSConfig,
            createTracks: this._mediaGridData.createTracks,
            setSelectedMediaAssets: this._mediaGridData.setSelectedAssets,
            toolbar: this._mediaGridData.toolbar,
            emptyGridBanner: this._mediaGridData.emptyGridBanner,
            selectionEnabled: this._mediaGridData.selectionEnabled,
            selectionMode: this._mediaGridData.selectionMode
        });

        const providerHydratedMediaGrid = React.createElement(ReactReduxProvider, { store: store }, mediaGrid);

        ReactDOM.render(
            providerHydratedMediaGrid,
            container,
            () => {
                this._mediaGridData.toolbar?.createView(this.ensureHTMLElement("el-media-grid-scroll-container__grid-toolbar"));
                this._mediaGridData.componentRenderedCallback?.();
            }
        );
    }

    destroyView(): void {
        this._mediaGridData.toolbar?.destroyView();
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action?: T): Promise<boolean> {
        let handled = false;
        switch (action?.type) {
            case ELMediaGridControllerAction.startSIV:
                this._mediaGridData.workflow.notify({
                    type: ELMediaGridControllerAction.startSIV,
                    payload: action?.payload
                });
                this.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGrid,
                        IngestEventTypes.click, IngestEventSubTypes.siv, true)
                });
                handled = true;
                break;
            case WorkflowActionType.ingest:
                handled = await this._mediaGridData.workflow.notify({
                    type: WorkflowActionType.ingest,
                    payload: action?.payload

                });
                break;
            default:
                if (this.viewDispatcher) {
                    this.viewDispatcher({ type: action?.type ?? "", payload: action?.payload });
                }
        }
        return handled;
    }
}

export default ELMediaGrid;
