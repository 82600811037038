/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//AdobeInternal
import { StyleProps } from "@react-types/shared";
import { useStyleProps } from "@react-spectrum/utils";

//Application Specific
import ELOverlay from "../../atoms/el-overlay/ELOverlay";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";

import "./ELProgressBanner.scss";

export interface ELProgressBannerProps extends StyleProps {
    children?: React.ReactNode
}

const ELProgressBanner = (props: ELProgressBannerProps): React.ReactElement => {
    const {
        children,
        ...otherProps
    } = props;
    const { styleProps } = useStyleProps(otherProps);

    return (
        <ViewportProvider>
            <div className="banner-content-box" style={{ ...styleProps.style }}>
                <ELOverlay>
                    {props.children}
                </ELOverlay>
            </div>
        </ViewportProvider>
    )
}

export default ELProgressBanner;
