/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../../IWorkflow";
import { ProgressCircle } from "@react-spectrum/progress";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { ViewAction } from "./../../../../../view/IBaseController";
import { LinkGenerationView, LinkGenerationControllerAction } from "./LinkGenerationView";
import IBaseWorkspace, { WorkspaceActionType } from "../../../../IBaseWorkspace";
import { LinkGenerationStatus, LinkInfo, ShareMode } from "../../../../../common/interfaces/share/ShareTypes";
import { ShareModeViewData } from "../../ShareModeData";
import store from "../../../../../stores/store";
import { ElementsShareServiceWrapper, LINK_GENERATION_CANCEL_MSG } from "../../../../../services/ElementsShareServiceWrapper";
import { ShareAction } from "../../../../../stores/actions/ShareAction";
import { ControllerAction } from "../../../../../view/IViewController";
import { LinkGenerationErrorType } from "../linkGenererationError/LinkGenerationError";
import { IngestEventTypes, IngestEventSubTypes } from "../../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../../utils/IngestUtils";
import { ShareUtils } from "../../../../../utils/ShareUtils";

class LinkGeneration extends IWorkflow {

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.linkGeneration);
    }

    private get _getShareWorkflowName(): WorkflowsName {
        const shareMode = store.getState().shareReducer.shareMode;
        return ShareModeViewData.getShareModeData(shareMode).shareWorkflow;
    }

    private get _getLinkGenerationMsg(): string {
        const intlHandler = IntlHandler.getInstance();
        const shareMode = store.getState().shareReducer.shareMode;
        return intlHandler.formatMessage(ShareModeViewData.getShareModeData(shareMode).linkGenerationMsgLabel);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    getSpinner(): JSX.Element {
        const intlHandler = IntlHandler.getInstance();
        return React.createElement(ProgressCircle, {
            "aria-label": intlHandler.formatMessage("loading"),
            isIndeterminate: true,
            size: "L"
        });

    }

    createView(container: HTMLElement): void {
        super.createView(container);

        ReactDOM.render(
            React.createElement(LinkGenerationView, {
                controller: this,
                text: this._getLinkGenerationMsg
            }, this.getSpinner()),
            container
        )

    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async startLinkGeneration(assetList: string[]): Promise<void> {
        let errorType: undefined | LinkGenerationErrorType = undefined;

        try {
            if (assetList.length > 0) {
                const response = await (ElementsShareServiceWrapper.getInstance().generateShareLink(assetList, true));
                const linkInfo: LinkInfo = {
                    link: response.link,
                    sharedAssetsCount: response.assetsCount
                }

                //add values in store
                store.dispatch(ShareAction.updateLinkInfo(linkInfo));

                Logger.log(LogLevel.INFO, response.link);
                if (response.status === LinkGenerationStatus.complete) {
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: this._getShareWorkflowName };
                    this._owner.notify(workspaceAction);
                    this._ingestShareComplete(true);
                }
                else if (response.status === LinkGenerationStatus.partial) {
                    errorType = LinkGenerationStatus.partial;
                    this._ingestShareComplete(false);
                }
            } else {
                this._ingestShareComplete(false);
                throw new Error("no assets to share");
            }
        }
        catch (error: any) {
            Logger.log(LogLevel.WARN, "LinkGeneration:startLinkGeneration: ", "Could not generate link ", error);
            if (error.message === LINK_GENERATION_CANCEL_MSG) {
                errorType = LinkGenerationStatus.cancelled;
                this._ingestShareComplete(false);
            }
            else {
                errorType = LinkGenerationStatus.error;
                this._ingestShareComplete(false);
            }
        }

        if (errorType && errorType !== LinkGenerationStatus.cancelled) {
            //if operation was cancelled, don't show the link generation error dialog
            const workspaceAction = {
                type: WorkspaceActionType.startWorkflow,
                startWorkflow: WorkflowsName.linkGenerationError,
                payload: { linkGenerationState: errorType }
            };
            this._owner.notify(workspaceAction);
        }
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
        this.startLinkGeneration(store.getState().shareReducer.assetsToShare);
    }

    _ingestShareComplete(success: boolean): void {
        ShareUtils.ingestShareComplete(ShareMode.link, success, this.notify.bind(this));
    }

    /**
    * Handles ui events generated by views rendered in the workflow
    * @param action ControllerAction
    */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case LinkGenerationControllerAction.cancelPressed:
                {
                    this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(WorkflowsName.linkGeneration,
                            IngestEventTypes.click, IngestEventSubTypes.cancel, true)
                    });
                    this._owner.notify({ type: WorkspaceActionType.endWorkspace });
                    handled = true;
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "LinkGeneration - ControllerAction: Invalid action" + action);
                    break;
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }
}

export { LinkGeneration };
