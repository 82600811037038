/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';

export enum MediaGridSortOrder {
    ascending = "ascending",
    descending = "descending"
}

export enum GridMediaType {
    eImageOnly,
    eVideoOnly,
    eImageAndVideo,
    eNone
}

export enum MediaGridSortBy {
    name = "name",
    importDate = "importDate",
    modifiedDate = "modifiedDate",
    createdDate = "createdDate"
}

export enum FilterMediaUiProp {
    hide = "hide",
    disable = "disable",
}

export interface FilterMediaData {
    format: string[],
    uiProp: FilterMediaUiProp,
}

const defaultFilterMediaData = { format: [], uiProp: FilterMediaUiProp.disable };

export interface MediaGridConfig {
    sortBy: MediaGridSortBy,
    sortOrder: MediaGridSortOrder,
    mediaType: GridMediaType,
    filterMedia: FilterMediaData,
    maxImageCount?: number,
    minImageCount?: number,
    maxVideoCount?: number,
    maxMediaCount?: number,
    maxTotalMediaSize?: number, //in MB
    maxVideoLength?: number //in seconds,
    selectUploadedMedia?: boolean
}

export const mediaGridDefaultConfig: MediaGridConfig = {
    sortBy: MediaGridSortBy.importDate,
    sortOrder: MediaGridSortOrder.descending,
    mediaType: GridMediaType.eImageAndVideo,
    filterMedia: defaultFilterMediaData,
    selectUploadedMedia: false
}

export default class MediaGridConfigAction {

    public static readonly UPDATE_CONFIG: string = 'MediaGridConfigAction.UPDATE_CONFIG';

    public static updateConfig(config: MediaGridConfig): IAction<MediaGridConfig> {
        return {
            type: MediaGridConfigAction.UPDATE_CONFIG,
            payload: config
        };
    }
}
