/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CreationsData } from "../../common/interfaces/creations/CreationTypes";
import { RecentCreationFilterType, RecentCreationSortOrder, RecentCreationSortType } from "../../common/interfaces/creations/ELRecentCreationsTypes";
import IAction from "./IAction";

export type RecentCreationsActionUnion = RecentCreationSortType | RecentCreationSortOrder | RecentCreationFilterType | CreationsData[]
export default class RecentCreationAction {
    public static readonly CHANGE_FILTER: string = "CHANGE_FILTER";
    public static readonly CHANGE_SORT_BY_FIELD: string = "CHANGE_SORT_BY_FIELD";
    public static readonly CHANGE_SORT_ORDER: string = "CHANGE_SORT_ORDER";
    public static readonly RECENT_CREATIONS_LIST: string = "RECENT_CREATIONS_LIST";

    public static changeFilter(filterType: RecentCreationFilterType): IAction<RecentCreationFilterType> {
        return {
            type: RecentCreationAction.CHANGE_FILTER,
            payload: filterType
        };
    }

    public static changeSortByField(sortByField: RecentCreationSortType): IAction<RecentCreationSortType> {
        return {
            type: RecentCreationAction.CHANGE_SORT_BY_FIELD,
            payload: sortByField
        };
    }

    public static changeSortOrder(sortOrder: RecentCreationSortOrder): IAction<RecentCreationSortOrder> {
        return {
            type: RecentCreationAction.CHANGE_SORT_ORDER,
            payload: sortOrder
        };
    }

    public static cacheRecentCreationsList(creationsData: CreationsData[]): IAction<CreationsData[]> {
        return {
            type: RecentCreationAction.RECENT_CREATIONS_LIST,
            payload: creationsData
        };
    }
}