/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//thirdparty
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Flex } from "@adobe/react-spectrum";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

//Adobe Internal
import { Radio, RadioGroup } from "@react-spectrum/radio";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import { ELAudioThumb } from "../../atoms/el-audio-thumb/ELAudiothumb";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ASSETS_ENDPOINT, SlideshowAudioData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import { ELSlideshowAudioViewAction } from "../../../../common/interfaces/creations/SlideshowTypes";
import { ViewAction } from "../../../IBaseController";
import { RootState } from "../../../../stores/store";
import CreationsAction from "../../../../stores/actions/CreationsAction";

import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestWorkflowTypes } from "../../../../utils/IngestConstants";

import "./SlideshowAudioView.scss";

interface ELSlideshowAudioState {
    slideshowAudioData: SlideshowAudioData[]
}

const initialState: ELSlideshowAudioState = {
    slideshowAudioData: []
}

interface AudioListProps {
    slideshowAudioData: SlideshowAudioData[],
    notify: (_: ControllerAction) => void
}

const AudioList = (props: AudioListProps): React.ReactElement => {
    const [selectedAudioId, setSelectedAudioId] = useState<string | undefined>(undefined);
    const [currentPlayingAudioId, setCurrentPlayingAudioId] = useState<string | undefined>(undefined);
    const slideshowStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (slideshowStoreData.activeSlideshowId) {
            const slideshowId = slideshowStoreData.activeSlideshowId;
            const selectedAudio = slideshowStoreData.data[slideshowId].uiData?.inputs?.templates?.audio;
            setSelectedAudioId(selectedAudio);
        }
    }, [slideshowStoreData.data]);

    const handlePlayRequest = useCallback((targetAudioId: string) => {
        if (targetAudioId !== currentPlayingAudioId) {
            setCurrentPlayingAudioId(targetAudioId);
        }
    }, [currentPlayingAudioId]);

    useEffect(() => {
        if (currentPlayingAudioId) {
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventCount] = currentPlayingAudioId;
            props.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.audio, IngestWorkflowTypes.slideshow, additionalLogInfo)
            });
        }
    }, [currentPlayingAudioId]);

    const onAudioChange = (id: string): void => {
        setSelectedAudioId(id);
        updateStoreData(id);
    }

    const updateStoreData = (id: string): void => {
        const slideshowId = slideshowStoreData.activeSlideshowId;
        if (slideshowId) {
            const uiData = _.cloneDeep(slideshowStoreData.data[slideshowId].uiData);
            if (uiData && uiData.inputs && uiData.inputs.templates) {
                uiData.inputs.templates.audio = id;
                dispatch(CreationsAction.updateUIData(uiData));
            }
        }
    }


    const listItems = (<Flex direction="column" gap="0" marginTop="1rem">
        {props.slideshowAudioData.map((audio) =>
            <Flex key={audio.title} direction="row" alignItems="center" UNSAFE_className={"audio-box__list-item"}>
                <Radio data-test-id={"slideshow-audio-radio-" + audio.id} aria-label="slideshow-audio-radio" value={audio.id} />
                <ELAudioThumb isSelected={selectedAudioId === audio.id ? true : false}
                    dataTestId={"slideshow-audio-" + audio.id}
                    forcedPause={currentPlayingAudioId !== audio.id}
                    title={audio.title}
                    id={audio.id}
                    src={process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + audio.previewUrl}
                    handlePlay={handlePlayRequest} />
            </Flex>)}
    </Flex>);

    return (<RadioGroup data-test-id="slideshow-audio-radio-group"
        aria-label="slideshow-template-radio-group" value={selectedAudioId} onChange={(id) => onAudioChange(id)}>
        {listItems}
    </RadioGroup>);
}

export interface SlideshowAudioViewProps {
    controller: IViewController
}

const SlideshowAudioView = (props: SlideshowAudioViewProps): React.ReactElement => {
    const reducer = (state: ELSlideshowAudioState, action: ViewAction): ELSlideshowAudioState => {
        switch (action.type) {
            case ELSlideshowAudioViewAction.audioData:
                return {
                    ...state,
                    slideshowAudioData: action.payload as SlideshowAudioData[]
                };
            default:
                return state;
        }
    }
    const [state, viewDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);


    const intlHandler = IntlHandler.getInstance();

    const notify = props.controller.notify.bind(props.controller);

    return (
        <>
            <div className="slideshow-panel-box">
                <ELPanelHeader title={intlHandler.formatMessage("audio")} alignTitle="start" />
                <div className="slideshow-panel-description">{intlHandler.formatMessage("audio-play-description")}</div>
                <ELScrollPanel scrollY UNSAFE_className="slideshow-panel-scroll">
                    <div className="slideshow-panel__content-box">
                        <AudioList slideshowAudioData={state.slideshowAudioData} notify={notify} />
                    </div>
                </ELScrollPanel>
            </div>
        </>
    )
}

export default SlideshowAudioView;
