/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";

//Application Specific
import { DocumentActions } from "../../../common/interfaces/document/DocumentTypes";
import { ELStageObject } from "../../../common/interfaces/stage/StageTypes";
import IDragDropHandler, { DropData } from "./IDragDropHandler";

export interface StrokeInfo {
    stroke: string
}

export default class ELFabricDragDropHandler extends IDragDropHandler<fabric.Canvas, IEvent> {
    protected targetObject?: ELStageObject;
    protected strokeInfo?: StrokeInfo;

    private _clearTargetObject(): void {
        if (this.targetObject) {
            this.targetObject.stroke = this.strokeInfo?.stroke;
            this.targetObject = undefined;
        }
    }

    private _updateTargetObject(targetObject: ELStageObject): void {
        this.targetObject = targetObject;
        this.strokeInfo = { stroke: this.targetObject.stroke ?? "" };
        this.targetObject.stroke = "blue";
    }

    private _checkDrop(canvas: fabric.Canvas, mouseEvent: IEvent, currentObject: fabric.Object): boolean {
        const mousePointer = mouseEvent.pointer;
        if (mousePointer && currentObject.containsPoint(mousePointer) && !canvas.isTargetTransparent(currentObject, mousePointer.x, mousePointer.y)) {
            return true;
        }
        return false;
    }

    startDragging(canvas: fabric.Canvas, mouseEvent: IEvent): void {
        this._startDragging = true;
        const activeObject = canvas.getActiveObject();
        const mousePointer = mouseEvent.pointer;
        let targetObject: ELStageObject | null = null;
        if (activeObject && mousePointer) {
            const objects = canvas.getObjects();
            objects.forEach(object => {
                if (object !== activeObject && object.data?.allowDrop) {
                    if (object.clipPath) {
                        if (this._checkDrop(canvas, mouseEvent, object.clipPath)) {
                            targetObject = object.clipPath;
                        }
                    } else {
                        if (this._checkDrop(canvas, mouseEvent, object)) {
                            targetObject = object;
                        }
                    }
                }
            });
        }

        if (targetObject && this.targetObject && targetObject === this.targetObject)
            return;

        this._clearTargetObject();
        if (targetObject)
            this._updateTargetObject(targetObject as ELStageObject);
        canvas.requestRenderAll();
    }

    stopDragging(canvas: fabric.Canvas): void {
        this._startDragging = false;
        this._clearTargetObject();
        canvas.requestRenderAll();
    }

    onDrop(canvas: fabric.Canvas, mouseEvent: IEvent): DropData {
        const dropData: DropData = {
            type: DocumentActions.swapAssets
        };
        const activeObject = canvas.getActiveObject();
        const mousePointer = mouseEvent.pointer;
        if (activeObject && this._startDragging && mousePointer) {
            const objects = canvas.getObjects();
            objects.forEach(object => {
                if (object !== activeObject && object.data?.allowDrop) {
                    if (object.clipPath) {
                        if (this._checkDrop(canvas, mouseEvent, object.clipPath)) {
                            dropData.payload = [activeObject.data.payload, object.data.payload];
                        }
                    } else {
                        if (this._checkDrop(canvas, mouseEvent, object)) {
                            dropData.payload = [activeObject.data.payload, object.data.payload];
                        }
                    }
                }
            });
        }
        this.stopDragging(canvas);
        return dropData;
    }
}
