/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom"
import { Provider as ReactReduxProvider } from "react-redux";

//Application specific
import IViewController, { ControllerAction } from "../../../IViewController";
import ELImportProgressBarView from "./ELImportProgressBarView";
import { ViewAction } from "../../../IBaseController";
import IWorkflow from "../../../../workspaces/IWorkflow";
import { MediaGridWorkflowActions } from "../../../../workspaces/organizer/workflows/mediaGrid/MediaGrid";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import { ProgressState, ELProgressValue, ELProgressBarActions } from "../../../../common/interfaces/import/ImportProgressTypes";
import Jarvis from "../../../../services/Jarvis";
import { JarvisCTA } from "../../../../common/interfaces/services/JarvisTypes";
import store from "../../../../stores/store";

class ELImportProgressBar extends IViewController {

    private _workflow?: IWorkflow;
    private _currentProgress: ELProgressValue;

    createView(container: HTMLElement): void {
        Jarvis.getInstance().showHideJarvisCTA(JarvisCTA.hide);
        super.createView(container);
        const progressBar = React.createElement(ELImportProgressBarView, {
            controller: this,
            currentProgress: this._currentProgress,
        });

        const viewportProviderImportProgress = React.createElement(ViewportProvider, {}, progressBar);
        const providerHydratedImportProgress = React.createElement(ReactReduxProvider, { store: store }, viewportProviderImportProgress);

        ReactDOM.render(
            providerHydratedImportProgress,
            container
        );
    }

    constructor(workflow?: IWorkflow) {
        super();
        this._workflow = workflow;
        this._currentProgress = { progressState: ProgressState.notStarted, value: 0, total: 0, }
    }

    destroyView(): void {
        Jarvis.getInstance().showHideJarvisCTA(JarvisCTA.show);
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELProgressBarActions.stopUpload: {
                if (this._workflow) {
                    handled = await this._workflow.notify({ type: MediaGridWorkflowActions.stopUpload });
                }
                break;
            }
            case ELProgressBarActions.retryUpload: {
                if (this._workflow) {
                    handled = await this._workflow.notify({ type: MediaGridWorkflowActions.retryUpload });
                }
                break;
            }
            case ELProgressBarActions.updateProgress: {
                if (this.viewDispatcher) {
                    this._currentProgress = action.payload as ELProgressValue;
                    this.viewDispatcher({ type: action.type, payload: action.payload });
                }
                handled = true;
                break;
            }
            default: {
                if (this.viewDispatcher) {
                    this.viewDispatcher({ type: action.type ?? "", payload: action.payload });
                }

            }
        }
        return handled;
    }
}

export default ELImportProgressBar;
