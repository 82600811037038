/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELStageObject, ELStageShapeAndObjectOptions } from "../stage/StageTypes";

export interface RendererUpdateData {
    type: string,
    payload: unknown
}

export interface RenderedStageData {
    stageShapeAndObjectOptions: ELStageShapeAndObjectOptions,
    object: ELStageObject
}

export enum RenderedShapesName {
    imageArea = "IMAGE_AREA",
    image = "IMAGE",
    rect = "RECT",
    imageAreaWithLayout = "IMAGE_AREA_WITH_LAYOUT",
    imageLoadError = "IMAGE_LOAD_ERROR",
    layer = "LAYER"
}

export const DEFAULT_STAGE_BACKGROUND = "#ececec";
