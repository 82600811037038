/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

//Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { URLConstants } from "../../../../utils/Constants/URLConstants";
import AdobePrivacy from "../../../../services/AdobePrivacy";
import { ELFooterLangMenuView } from "../el-footer-lang-menu/ELFooterLangMenu";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { IngestWorkflowTypes, IngestEventSubTypes, INGEST_APP_WORKSPACE, IngestEventTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELCustomFooter.scss";

interface ELCustomFooterProps {
    currentLocale: string;
}

interface ELCustomFooterLinksProps {
    name: string,
    href?: string,
    target?: string,
    rel?: string;
    onClick?: () => void;
}

export const ELCustomFooterView = (props: ELCustomFooterProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const onCookiePreferencesClick = (): void => {
        const cookieIngestObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utilityNav,
            IngestEventTypes.cookiepreferences, IngestEventSubTypes.click, true));
        IngestLogging.getInstance().logEvent(cookieIngestObject);

        AdobePrivacy.getInstance().showConsentPopup();
    }

    const footerLinks: ELCustomFooterLinksProps[] = [
        {
            name: "footer-term-of-use",
            href: URLConstants.NotGoURL.FOOTER_TERMS_OF_USE,
            target: "_blank",
            rel: "noopener noreferrer"
        },
        {
            name: "footer-cookies",
            onClick: onCookiePreferencesClick
        },
        {
            name: "footer-ad-choices",
            href: URLConstants.NotGoURL.FOOTER_AD_CHOICES,
            target: "_blank",
            rel: "noopener noreferrer"
        },
        {
            name: "footer-privacy-policy",
            href: URLConstants.NotGoURL.FOOTER_PRIVACY_POLICY,
            target: "_blank",
            rel: "noopener noreferrer"
        },
    ];

    return (
        <ELReactSpectrumV3Provider scale="medium">
            <div className="el-custom-footer">
                {
                    footerLinks.map((alinkProps) => {
                        return (
                            <a key={alinkProps.name} className="el-custom-footer__link" {...alinkProps}>
                                {intlHandler.formatMessage(alinkProps.name)}
                            </a>
                        );
                    })
                }
                <div className="el-custom-footer__lang">
                    <ELFooterLangMenuView selectedLocale={props.currentLocale} />
                </div>
                <div className="el-custom-footer__legal">{intlHandler.formatMessage("footer-adobe-legal", { year: new Date().getFullYear() })}</div>
            </div>
        </ELReactSpectrumV3Provider>
    );
};