/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//Application specific
import { FeatureName } from "../../services/Floodgate/FloodgateConstants";
import FloodgateFeaturesAction, { FeaturesPayload } from "../actions/FloodgateFeaturesAction";
import IAction from "../actions/IAction";

export type FeaturesMap = Map<FeatureName, boolean>;
export type FeatureMetadatamap = Map<FeatureName, Promise<Record<string, unknown>>>;

interface ELFeaturesPayloadType {
    featureState: FeaturesMap;
    featureMetadata: FeatureMetadatamap;
}

const defaultState = new Map<FeatureName, boolean>([
    [FeatureName.eWebAccess, false],
    [FeatureName.eOpenInDesktop, false],
    [FeatureName.ePatternOverlay, false],
    [FeatureName.eJarvisChatBot, false],
    [FeatureName.ePeekThrough, false],
    [FeatureName.eMovingOverlay, false],
    [FeatureName.eReplaceBackground, false],
    [FeatureName.eShowDesktopProductCards, false],
    [FeatureName.eClientReplaceBackground, false],
    [FeatureName.eQRShareView, false],
    [FeatureName.eMultiMediaQRShareView, false],
    [FeatureName.eStartTrialDialog, false],
    [FeatureName.eAdjustments, false],
    [FeatureName.eSampleMediaInCreations, false],
    [FeatureName.ePhotoText, false],
    [FeatureName.eSharePagePopup, false],
    [FeatureName.eABTestEliveCardClickable, false]
]);

const defaultMetadata = new Map<FeatureName, Promise<Record<string, unknown>>>();

const defaultELFeaturesPayload = {
    featureState: defaultState,
    featureMetadata: defaultMetadata,
};

const updateFeaturesMap = (state: ELFeaturesPayloadType = defaultELFeaturesPayload, action: IAction<FeaturesPayload | FeatureMetadatamap>): ELFeaturesPayloadType => {
    const data = action.payload as FeaturesPayload;
    if (data && data.length > 0) {
        const newState = _.cloneDeep(state);
        for (const feature of data) {
            const featureName = feature as FeatureName;
            if (newState.featureState.has(featureName)) {
                newState.featureState.set(featureName, true);
            }
        }
        return newState;
    } else {
        return state;
    }
};

const updateFeaturesMetadata = (state: ELFeaturesPayloadType = defaultELFeaturesPayload, action: IAction<FeaturesPayload | FeatureMetadatamap>): ELFeaturesPayloadType => {
    const data = action.payload as FeatureMetadatamap;
    if (data && data.size > 0) {
        const newState = _.cloneDeep(state);
        const keys = Array.from(data.keys());
        keys.forEach((featureName) => {
            const featureMetadata = data.get(featureName as FeatureName);
            if (featureMetadata) {
                newState.featureMetadata.set(featureName as FeatureName, featureMetadata);
            }
        });
        return newState;
    } else {
        return state;
    }
};

const resetFeaturesMap = (): ELFeaturesPayloadType => {
    const newState = defaultELFeaturesPayload;
    return newState;
};

const floodgateFeaturesReducer = (state: ELFeaturesPayloadType = defaultELFeaturesPayload, action: IAction<FeaturesPayload | FeatureMetadatamap>): ELFeaturesPayloadType => {
    switch (action.type) {
        case FloodgateFeaturesAction.UPDATE_FEATURES:
            return updateFeaturesMap(state, action);
        case FloodgateFeaturesAction.UPDATE_METADATA:
            return updateFeaturesMetadata(state, action);
        case FloodgateFeaturesAction.RESET_FEATURES:
            return resetFeaturesMap();
        default:
            return state;
    }
};

export default floodgateFeaturesReducer;