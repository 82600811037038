/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "../actions/IAction";
import { ZoomLevel } from "../../editors/stage/ELFabricStage";
import { DocActionsPayload, DocActionTypes } from "../actions/DocActions";
import { DocumentDirty, DocumentSaveStatus } from "../../common/interfaces/document/DocumentTypes";
import IDoc from "../../editors/document/IDoc";

type DocActionPayloadUnion = number | DocumentDirty | boolean | DocumentSaveStatus | IDoc;

function getDefaultDocState(): DocActionsPayload {
    return {
        zoomLevel: ZoomLevel.default,
        isDirty: DocumentDirty.NON_DIRTY,
        hasError: false,
        saveStatus: DocumentSaveStatus.unsaved,
        activeDoc: undefined
    }
}

const changeZoomLevel = (state: DocActionsPayload, action: IAction<DocActionPayloadUnion>): DocActionsPayload => {
    return {
        ...state,
        zoomLevel: action.payload as number
    }
}

const updateDocumentDirty = (state: DocActionsPayload, action: IAction<DocActionPayloadUnion>): DocActionsPayload => {
    return {
        ...state,
        isDirty: action.payload as DocumentDirty
    }
}

const updateDocumentError = (state: DocActionsPayload, action: IAction<DocActionPayloadUnion>): DocActionsPayload => {
    return {
        ...state,
        hasError: action.payload as boolean
    }
}

const updateDocumentSaveStatus = (state: DocActionsPayload, action: IAction<DocActionPayloadUnion>): DocActionsPayload => {
    return {
        ...state,
        saveStatus: action.payload as DocumentSaveStatus
    }
}

const updateActiveDocument = (state: DocActionsPayload, action: IAction<DocActionPayloadUnion>): DocActionsPayload => {
    return {
        ...state,
        activeDoc: action.payload as IDoc
    }
}

const docStateReducer = (state: DocActionsPayload = getDefaultDocState(), action: IAction<DocActionPayloadUnion>): DocActionsPayload => {
    switch (action.type) {
        case DocActionTypes.changeZoomLevel: {
            return changeZoomLevel(state, action);
        }
        case DocActionTypes.updateDocumentDirty: {
            return updateDocumentDirty(state, action);
        }
        case DocActionTypes.updateDocumentError: {
            return updateDocumentError(state, action);
        }
        case DocActionTypes.updateDocumentSaveStatus: {
            return updateDocumentSaveStatus(state, action);
        }
        case DocActionTypes.updateActiveDocument: {
            return updateActiveDocument(state, action);
        }
        default:
            return state;
    }
}

export default docStateReducer;
