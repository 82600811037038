/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";
import React, { ReactNode, useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";

//adobe internal
import { Grid, repeat } from "@adobe/react-spectrum";
import { usePress } from "@react-aria/interactions";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import ELColorPickerView from "../../organism/el-color-picker/ELColorPickerView";
import { ASSETS_ENDPOINT, CollageBackgroundData } from "../../../../common/interfaces/creations/ElementsContentTypes";
import IViewController from "../../../IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import {
    ELGraphicPanelControllerAction,
    ELGraphicPanelViewAction
} from "../../../../common/interfaces/creations/templates/ELGraphicPanelTypes";
import { RootState } from "../../../../stores/store";
import { ELCollageTemplateBackgroundMode } from "../../../../common/interfaces/creations/ELCollageTypes";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";

import "./ELGraphicPanel.scss";

export interface ELGraphicPanelProps {
    controller: IViewController;
}

interface ELGraphicPanelState {
    collageBackgroundData: CollageBackgroundData[],
    selectedBackgroundId: string
}

const initialState: ELGraphicPanelState = {
    collageBackgroundData: [],
    selectedBackgroundId: ""
}

interface CollageBackgroundCardProps {
    id: string,
    name: string,
    thumbnailUrl: string,
    contentUrl: string,
    onPress: (id: string, contentUrl: string) => void,
    selectedBackgroundId: string,
    width: number,
    height: number
}

const CollageBackgroundCard = (props: CollageBackgroundCardProps): React.ReactElement => {

    const { pressProps } = usePress({
        onPress: () => { props.onPress(props.id, props.contentUrl); }
    });

    return (
        <div data-testid="collage-background-card" {...pressProps} title={props.name} className={props.id === props.selectedBackgroundId ? "collage-background-card-selected" : "collage-background-card"}
            style={{ width: props.width, height: props.height }}>
            <img crossOrigin="anonymous" className="collage-background-image" src={process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + props.thumbnailUrl} alt={props.name} />
        </div>
    )
}

const COLOR_CHANGE_THROTTLE = 100;

const ELGraphicPanelView = (props: ELGraphicPanelProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const [colorValue, setColorValue] = useState("ffffff");
    const collageStoreData = useSelector((state: RootState) => state.collageReducer);
    const specLayoutConfig = {
        TILE_W: 5,
        TILE_H: 5,
        DIST_BETWEEN_TILE: 1
    };
    const gridRef = useRef(null);
    const scrollParentRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: scrollParentRef,
        gridRef: gridRef
    });

    const handleColorChange = _.debounce((color: string): void => {
        Logger.log(LogLevel.INFO, "Background color: ", color);
        setColorValue(color);
        props.controller.notify({ type: ELGraphicPanelControllerAction.colorBackgroundChanged, payload: color });
    }, COLOR_CHANGE_THROTTLE);

    const handleBackgroundChange = (id: string, contentUrl: string): void => {
        Logger.log(LogLevel.INFO, "Background Content Link: ", id, process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + contentUrl);
        const backgroundURL = process.env.REACT_APP_ELEMENTS_URL + ASSETS_ENDPOINT + contentUrl;
        const graphicData: CollageBackgroundData = { id: id, contentUrl: backgroundURL, name: "", thumbnailUrl: "" };
        props.controller.notify({ type: ELGraphicPanelControllerAction.imageBackgroundChanged, payload: graphicData });
    }

    const reducer = (state: ELGraphicPanelState, action: ViewAction): ELGraphicPanelState => {
        switch (action.type) {
            case ELGraphicPanelViewAction.backgroundData:
                return {
                    ...state,
                    collageBackgroundData: action.payload as CollageBackgroundData[]
                };
                break;
            case ELGraphicPanelViewAction.setBackgroundId:
                return {
                    ...state,
                    selectedBackgroundId: action.payload as string
                }
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        if (collageStoreData.backgroundData) {
            switch (collageStoreData.backgroundData.mode) {
                case ELCollageTemplateBackgroundMode.color:
                    setColorValue(collageStoreData.backgroundData.value);
                    viewDispatch({ type: ELGraphicPanelViewAction.setBackgroundId, payload: "" });
                    break;
                case ELCollageTemplateBackgroundMode.image:
                    viewDispatch({ type: ELGraphicPanelViewAction.setBackgroundId, payload: collageStoreData.backgroundData.value });
                    break;
            }
        }
    }, [collageStoreData.backgroundData]);

    const getBackgroundList = (): ReactNode => {
        return (
            state.collageBackgroundData.map((item, index) =>
                <CollageBackgroundCard key={index} id={item.id} name={item.name}
                    thumbnailUrl={item.thumbnailUrl} contentUrl={item.contentUrl} onPress={(id, contentUrl) => handleBackgroundChange(id, contentUrl)}
                    selectedBackgroundId={state.selectedBackgroundId} width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H} />
            )
        )
    }

    return (
        <div className="collage-panel-box">
            <ELPanelHeader title={intlHandler.formatMessage("graphic")} alignTitle="start" />
            <ELScrollPanel forwardedRef={scrollParentRef} UNSAFE_className="collage-background-scroll" scrollY={true} width="100%">
                <div className="collage-panel__content-box">
                    <div ref={gridRef}>
                        <Grid marginTop={"1rem"} columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                            justifyContent="center"
                            autoRows={viewLayoutConfig.TILE_W}
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                            <ELColorPickerView color={colorValue} onChange={(e) => { handleColorChange(e.target.value); }} />
                        </Grid>
                        <Grid marginTop={"1rem"} columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                            justifyContent="center"
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                            {getBackgroundList()}
                        </Grid>
                    </div>
                </div>
            </ELScrollPanel>
        </div>
    )
}

export default ELGraphicPanelView;
