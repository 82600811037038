/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { BroadcastChannel } from "broadcast-channel";

//utils and constants
import Utils from "../../utils/Utils";
import Logger, { LogLevel } from "../../utils/Logger";
import { BROADCAST_LOG_OUT_EVENT } from "../../utils/Constants/Constants";

//Application Specific
import { Routes } from "../../app/AppRoute";

const ELEMENTS_BROADCAST_CHANNEL_NAME = "elements broadcast channel";

export default class ELBroadcastChannel {
    private _broadcastChannel: BroadcastChannel;
    private static _instance?: ELBroadcastChannel;

    static createInstance(): void {
        if (!this._instance)
            this._instance = new ELBroadcastChannel();
    }

    static getInstance(): ELBroadcastChannel | undefined {
        if (!this._instance) {
            Logger.log(LogLevel.WARN, "ELBroadcastChannel: ", "instance not available");
        }
        return this._instance;
    }

    getBroadcastChannel(): BroadcastChannel {
        return this._broadcastChannel;
    }

    closeBroadcastChannel(): void {
        this._broadcastChannel.close();
    }

    private constructor() {
        this._broadcastChannel = new BroadcastChannel(ELEMENTS_BROADCAST_CHANNEL_NAME);
        this._broadcastChannel.onmessage = event => {
            try {
                Logger.log(LogLevel.INFO, event);
                if (event.type === BROADCAST_LOG_OUT_EVENT) {
                    Utils.openInSameTab(Routes.ROOT);
                }
            } catch (e) {
                //...
            }
        };
    }
}