/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

//Adobe internal
import { Text, Flex, View } from "@adobe/react-spectrum";

//Application Specific
import ELButton from "../../atoms/el-button/ELButtonView";

import "./ELCTABanner.scss";

export interface ELCTABannerProps {
    text: string,
    ctaText: string,
    ctaHandler?: (() => void),
    ctaDataTestId?: string,
    variant?: "primary"
}

const ELCTABannerView = (props: ELCTABannerProps): React.ReactElement => {

    const getVariantClass = (): string => {
        if (props.variant) {
            return "-" + props.variant;
        }
        return "";
    }

    const getButtonVariantClass = (): "primary" | "overBackground" => {
        if (props.variant === "primary") {
            return "primary";
        }
        return "overBackground";
    }

    const getButtonSizeClass = (): "L" | "S" | undefined => {
        if (props.variant === "primary") {
            return "L";
        }
    }

    return (
        <View UNSAFE_className={"cta-banner-box" + getVariantClass()}>
            <Flex direction="column" alignContent="center" justifyContent="center" height="100%" width="100%" UNSAFE_className="cta-banner-box__content-container">
                <Flex UNSAFE_className={"cta-banner-content-box" + getVariantClass()} >
                    <Text UNSAFE_className={"cta-banner-box__description" + getVariantClass()}>{props.text}</Text>
                    <ELButton variant={getButtonVariantClass()} size={getButtonSizeClass()} customStyle="el-button-custom-cta" onClick={props.ctaHandler} dataTestId={props.ctaDataTestId}>
                        <Text UNSAFE_className={"feature-banner-box__cta-text" + getVariantClass()}>{props.ctaText}</Text>
                    </ELButton>
                </Flex>
            </Flex>
        </View>
    )
}

export default ELCTABannerView;
