/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const enum ProgressState {
    notStarted = "notStarted",
    inProgress = "inProgress",
    interrupted = "interrupted"
}

export const enum ELProgressBarActions {
    updateProgress = "updateProgress",
    showStopButton = "showStopButton",
    showRetryButton = "showRetryButton",
    stopUpload = "stopUpload",
    retryUpload = "retryUpload",
}

export enum ImportCompleteState {
    success = "success",
    successWithSkipped = "successWithSkipped",
    partialUpload = "partialUpload",
    partialWithSkipped = "partialWithSkipped",
    storageQuotaFull = "storageQuotaFull",
    noSupportedFile = "noSupportedFile",
    unreachableServer = "unreachableServer",
    internetConnectionLost = "internetConnectionLost",
    importStopped = "importStopped"
}

export interface ELProgressValue {
    value: number,
    total: number,
    progressState: ProgressState
}

export enum MediaImportStatusActions {
    mediaImported = "mediaImported",
}