/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../../IWorkflow";
import { ConsentView, ConsentControllerAction } from "./ConsentView";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import { ViewAction } from "./../../../../../view/IBaseController";
import { CookiesUtils, PERSISTENT_COOKIE_MAXIMUM_EXPIRY } from "./../../../../../utils/CookiesUtils";
import IBaseWorkspace, { WorkspaceActionType } from "../../../../IBaseWorkspace";
import store from "../../../../../stores/store";
import { ControllerAction } from "../../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { IngestEventSubTypes, IngestWorkflowTypes } from "../../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../../utils/IngestUtils";
import { LocalStorageKeys } from "../../../../../utils/Constants/Constants";
import { ShareUtils } from "../../../../../utils/ShareUtils";
const DONOT_SHOW_SHARE_CONSENT_COOKIE = "donotShowShareConsent";

class Consent extends IWorkflow {


    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.consent);

    }

    private set _setDonotShowConsentPreference(value: boolean) {
        CookiesUtils.write(DONOT_SHOW_SHARE_CONSENT_COOKIE, value.toString(), {
            expires: PERSISTENT_COOKIE_MAXIMUM_EXPIRY
        });
    }

    private get _getDonotShowConsentPreference(): boolean | undefined {
        const showConsentPreference = localStorage.getItem(LocalStorageKeys.kShareConsent);
        if (showConsentPreference) {
            return (showConsentPreference === "false") ? true : false;
        }
        return undefined;
    }

    private get _getConsentMsg(): string {
        const intlHandler = IntlHandler.getInstance();
        const shareType = store.getState().shareReducer.shareType;
        const shareTypeCategory = intlHandler.formatMessage(ShareUtils.getShareTypeCategory(shareType));
        return intlHandler.formatMessage("consent-msg", { media: shareTypeCategory });
    }

    private _onContinuePressed(donotShowAgain: boolean): void {
        this.startNextWorkflow(donotShowAgain)
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    startNextWorkflow(doNotShowSelected?: boolean): void {
        this.endWorkflow(doNotShowSelected);
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: WorkflowsName.linkGeneration }
        this._owner.notify(workspaceAction);
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const consent = React.createElement(ConsentView, {
            controller: this,
            text: this._getConsentMsg
        }
        );

        const consentWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, consent);
        ReactDOM.render(consentWrappedProvider,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        //decide if we new need to show consent to user - start next workflow if donot show is set
        const doNotShowPreference = this._getDonotShowConsentPreference;
        if (doNotShowPreference === true) {
            this.startNextWorkflow();
        }
        else {
            this.createView(this.ensureHTMLElement(containerId));
        }
    }

    endWorkflow(doNotShowSelected?: boolean): void {
        //set preference before exiting
        if (doNotShowSelected === true) {
            this.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.consentScreen,
                    IngestEventSubTypes.click, IngestEventSubTypes.doNotShowAgain, true)
            });
            this._setDonotShowConsentPreference = doNotShowSelected;
        }
        this.destroyView();
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ConsentControllerAction.continuePressed:
                {
                    const doNotShowConsent = action.payload as boolean;
                    this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.consentScreen,
                            IngestEventSubTypes.click, IngestEventSubTypes.continue, true)
                    });
                    this._onContinuePressed(doNotShowConsent);
                    handled = true;
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "Consent - ControllerAction: Invalid action" + action);
                    break;
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

}

export { Consent };
