/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application specific
import IViewController, { ControllerAction } from "../../../IViewController";
import { ShareMode, SHARE_OPTIONS_LIST, ELShareOptionsViewAction, ELQRSharePayload, QRShareOptionsData } from "../../../../common/interfaces/share/ShareTypes";
import { ShareOptionsActionType, ShareOptionsView } from "./ELShareOptionsView";
import { IconType } from "../../../../assets/IconConstants";
import IBaseWorkspace, { WorkspaceActionType } from "../../../../workspaces/IBaseWorkspace";
import { WorkflowsName } from "../../../../workspaces/IWorkflow";
import { ModalWorkspaceName } from "../../../../workspaces/IModalWorkspace";
import { ShareAction } from "../../../../stores/actions/ShareAction";
import store from "../../../../stores/store";
import { ELQRGenerator } from "../../../../modules/elQRGenerator/ELQRGenerator";
import MediaShareLinkCreatorFactory from "../../../../modules/elMediaShareLinkCreator/MediaShareLinkCreatorFactory";
import { ViewAction } from "../../../IBaseController";
import { ContentEntity, ContentEntitySubtype, ContentType } from "@elements/elementswebcommon";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELContentCacheDownloader } from "../../../../workspaces/creations/utils/ELContentCacheDownloader";
import { ELError } from "../../../../modules/error/ELError";

type ShareOptionData = {
    key: string,
    src: IconType,
    text: string,
    shareMode: ShareMode
};

class ShareOptions extends IViewController {

    private _workspace: IBaseWorkspace;
    private _options: ShareOptionData[];
    private _qrOptions: QRShareOptionsData[];

    constructor(workspace: IBaseWorkspace) {
        super();

        this._workspace = workspace;
        this._options = [];
        this._qrOptions = [];
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        ReactDOM.render(
            React.createElement(ShareOptionsView, {
                controller: this,
                onPress: () => { this.notify({ type: WorkspaceActionType.startModalWorkspace }) },
                showQRView: store.getState().shareReducer.assetsToShare.length === 1
            }),
            container
        );
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._getQRData().then(() => {
            this._updateQRView();
        });
    }

    private async _getQRData(): Promise<QRShareOptionsData[]> {
        const shouldFetchQROptions = (): boolean => this._qrOptions.length === 0;

        if (shouldFetchQROptions()) {
            await this._fetchQRData();
        }
        return this._qrOptions;
    }

    private async _fetchQRData(): Promise<void> {
        try {
            const iconsData = await ELContentCacheDownloader.getContentForContentType(ContentType.icons);
            const qrData = iconsData.filter((item) => item.props.subtype === ContentEntitySubtype.qrShare);
            await this._parseQRData(qrData);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ShareOptions: _fetchQRData, failed to get qr content", error);
            Promise.reject(error);
        }
    }

    private async _parseQRData(qrData: ContentEntity[]): Promise<void> {
        const contentUrls: string[] = [];

        for (const data of qrData) {
            contentUrls.push(await data.getContentURL(""));
        }

        this._qrOptions = qrData.map((data, index) => {
            return {
                key: data.props.title.toLowerCase(),
                src: contentUrls[index],
                text: (data.props.title[0].toUpperCase() + data.props.title.slice(1))
            }
        })
    }

    private _updateQRView(): void {
        this.viewDispatcher?.call(this.viewDispatcher, { type: ELShareOptionsViewAction.qrData, payload: this._qrOptions });
    }

    private async _createShareQR(payload: ELQRSharePayload): Promise<void> {
        try {
            const assetsToShare = payload.assetsToShare;
            const linkCreator = MediaShareLinkCreatorFactory.getShareLinkCreator(assetsToShare.length);
            const link = await linkCreator.createLink(payload);
            const qrGenerator = new ELQRGenerator();
            const qrUrl = await qrGenerator.getQRData(link);
            this.viewDispatcher?.call(this.viewDispatcher, { type: ELShareOptionsViewAction.qrLink, payload: qrUrl });
        } catch (error) {
            const workflowConfiguration = this._workspace.getWorkflow?.getWorkflowConfiguration();
            const elError = new ELError("ShareOptions:_createShareQR: ", workflowConfiguration, error as Error);
            Logger.log(LogLevel.ERROR, elError);
            return Promise.reject(error);
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ShareOptionsActionType.openShareWorkspace:
                {
                    const payload = action.payload as Record<string, unknown>;
                    const assetsToShare = payload?.mediaList as string[];
                    const workflowName = payload?.workflowName as WorkflowsName;

                    const workspaceAction = {
                        type: WorkspaceActionType.startModalWorkspace,
                        startModalWorkspace: ModalWorkspaceName.share,
                        payload: { overlayDiv: "root", workflowName: workflowName }
                    };
                    store.dispatch(ShareAction.updateAssetsToShare(assetsToShare));
                    handled = await this._workspace.notify(workspaceAction);
                    break;
                }
            case ShareOptionsActionType.openShareWorkflow:
                {
                    const workflowName = action.payload as WorkflowsName;
                    const workspacePayload = { startWorkflow: workflowName };
                    handled = await this._workspace.notify({ type: WorkspaceActionType.startWorkflow, ...workspacePayload })
                    break;
                }
            case ShareOptionsActionType.createShareQR:
                {
                    await this._createShareQR(action.payload as ELQRSharePayload);
                    handled = true;
                    break;
                }
        }
        return handled;
    }

    get getOptions(): Array<ShareOptionData> {
        if (this._options.length <= 0) {
            this._options = SHARE_OPTIONS_LIST;
        }
        return this._options;
    }

    set setOptions(options: Array<ShareOptionData>) {
        this._options = options;
    }
}

export { ShareOptions };
