/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { PIE_WASM_SCRIPT } from "../../common/interfaces/editing/pie/PIETypes";
import { PIEConfig } from "../../editors/editingEngines/PIEEditingEngine";

export class PIEEngineConfigManager {
    static getDefaultConfig(): PIEConfig {
        const pieConfig = {
            locateFile: (file: any) => process.env.PUBLIC_URL + "/" + file,
            mainScriptUrlOrBlob: PIE_WASM_SCRIPT
        }
        return pieConfig;
    }
}