/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ELCollageCategoryEnum } from "./ELCollageTypes";

export interface SlideshowTemplateData {
    category: string;
    id: string;
    previewUrl: string;
    resource: unknown;
    thumbnailUrl: string;
    title: string;
}

export interface SlideshowAudioData {
    category: string;
    id: string;
    previewUrl: string;
    resource: unknown;
    title: string;
}

export interface CollageTemplateData {
    id: string,
    name: string,
    type: "layout",
    creation: string,
    layout: string,
    category: ELCollageCategoryEnum,
    previewUrl: string,
    contentUrl: string
}

export interface CollageBackgroundData {
    id: string;
    name: string;
    thumbnailUrl: string;
    contentUrl: string;
}

export const ASSETS_ENDPOINT = "assets/";
