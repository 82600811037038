/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//TODO - vib, ask samyjain  the need of 2 enums
export const enum LocaleLanguage {
    enGB = "en-GB",
    jaJP = "ja-JP",
    frFR = "fr-FR",
    deDE = "de-DE"
}

export const enum LocaleType {
    enUS = "en_US",
    jaJP = "ja_JP",
    frFR = "fr_FR",
    deDE = "de_DE"
}

export type LocaleData = {
    currencySymbol: string,
    isCurrencySymbolInLeft: boolean,
    pseAppPrice: number,
    pseAppUpgradePrice: number,
    preAppPrice: number,
    preAppUpgradePrice: number,
    pepeAppPrice: number,
    pepeAppUpgradePrice: number
}