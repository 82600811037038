/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELViewType } from "../../../common/interfaces/creations/CreationTypes";
import { STAGE_VIEWTYPE_ATTRIBUTE_KEY } from "../../../common/interfaces/stage/StageTypes";
import { StageUtils } from "../../../utils/stage/StageUtils";
import IViewController, { ControllerAction } from "../../../view/IViewController";
import IDoc from "../IDoc";

enum displayStyle {
    show = "block",
    hide = "none"
}

enum visibleStyle {
    visible = "visible",
    hidden = "hidden"
}

export class ELDualDocumentView extends IViewController {
    private _viewMode: ELViewType;
    private _beforeDoc: IDoc;
    private _afterDoc?: IDoc;

    constructor(beforeDoc: IDoc) {
        super();
        this._beforeDoc = beforeDoc;
        this._viewMode = ELViewType.before;
    }

    private _createStageContainers(container: HTMLElement): void {
        if (!StageUtils.getStageContainer(container, STAGE_VIEWTYPE_ATTRIBUTE_KEY, ELViewType.before)) {
            container.append(StageUtils.createStageContainer(STAGE_VIEWTYPE_ATTRIBUTE_KEY, ELViewType.before));
        }
        if (!StageUtils.getStageContainer(container, STAGE_VIEWTYPE_ATTRIBUTE_KEY, ELViewType.after)) {
            container.append(StageUtils.createStageContainer(STAGE_VIEWTYPE_ATTRIBUTE_KEY, ELViewType.after));
        }
    }

    showDoc(viewMode?: ELViewType): void {
        this._viewMode = viewMode ?? this._viewMode;
        if (this.container) {
            const beforeContainer = this.container.children[0] as HTMLElement;
            const afterContainer = this.container.children[1] as HTMLElement;
            if (this._viewMode === ELViewType.after) {
                afterContainer.style.display = displayStyle.show;
                beforeContainer.style.display = displayStyle.hide;
            } else {
                afterContainer.style.display = displayStyle.hide;
                beforeContainer.style.display = displayStyle.show;
            }
        }
    }

    async createView(container: HTMLElement): Promise<void> {
        super.createView(container);
        this._createStageContainers(container);
        this.showDoc();
        await this._beforeDoc.render(container);
    }

    destroyView(): void {
        if (this.container) {
            this.container.style.visibility = visibleStyle.hidden;
        }
        super.destroyView();
    }

    toggle(): void {
        this._viewMode = (this._viewMode === ELViewType.before) ? ELViewType.after : ELViewType.before;
        this.showDoc();
    }

    synchronizeZoom(action: ControllerAction): void {
        this._beforeDoc.notify(action);
        this._afterDoc?.notify(action);
    }

    async renderAfterDoc(afterDoc: IDoc): Promise<void> {
        this._afterDoc = afterDoc;
        if (this.container) {
            await this._afterDoc.render(this.container);
            this.showDoc(ELViewType.after);
        }
    }
}