/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELDeeplinkActionParams } from "./ELDeeplinkTypes";

export default abstract class IDeeplinkCreator {
    protected readonly _scheme;
    protected readonly _host;
    protected readonly _version;

    constructor(scheme: string, host: string, version: string) {
        this._scheme = scheme;
        this._host = host;
        this._version = version;
    }

    public abstract createLink(params: Map<ELDeeplinkActionParams, string>): string;
}
