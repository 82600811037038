/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { Routes } from "../app/AppRoute";
import { ShareType } from "../common/interfaces/share/ShareTypes";
import { SelectedMediaListPayload } from "../stores/actions/selectedMediaListActions";
import { WorkflowsName } from "../workspaces/IWorkflow";
import { LinkParams } from "./Constants/Constants";
import { IngestCreationWorkflows, IngestLogObjectCustomKey, IngestLogObjectKey, IngestWorkflowTypes } from "./IngestConstants";
import Utils from "./Utils";

const sensitiveParams = [
    LinkParams.ELEMENTS_COLLECTION_ID_PARAM
];

class IngestPayloadFormatter {
    /**
     * Format values in Record<string, string> to lowercase
     * @param rec record
     * @returns 
     */
    public static formatRecordToLowerCase(rec?: Record<string, string>): Record<string, string> | undefined {
        for (const key in rec) { rec[key] = rec[key].toLowerCase(); }
        return rec;
    }

    /**
     * Format a string to titlecase
     * @param st input string
     * @returns 
     */
    public static titleCase(st: string): string {
        return st.toLowerCase().split(" ").reduce((s, c) =>
            s + "" + (c.charAt(0).toUpperCase() + c.slice(1) + " "), '').trim();
    }
}

export class IngestUtils {
    /**
     * @param  {string} email string
     * @returns {boolean} returns true if email string doesn't end with @adobetest.com
     */
    static isValidEmail(email: string): boolean {
        if (/@adobetest.com$/.test(email)) {
            return false;
        }

        return true;
    }

    /**
     * @param  {string} workspace
     * @param  {Record<string,string>} ext?
     * @returns Incomplete Log object with Workspace name in it
     */
    static addWorkspaceDetail(workspace: string, ext?: Record<string, string>): Record<string, string> {
        return {
            ...ext,
            [IngestLogObjectKey.eventWorkflow]: IngestPayloadFormatter.titleCase(workspace)
        }
    }

    static hasEventWorkflow(ext?: Record<string, string>): boolean {
        if (ext && ext[IngestLogObjectKey.eventWorkflow] !== undefined) {
            return true;
        }
        return false;
    }

    /**
     * @param  {string|null} eventSubcategory (sub category)
     * @param  {string|null} eventType
     * @param  {string|null} eventSubType
     * @param  {boolean|number|string|null} value
     * @param  {Record<string, string>} ext? : extra values to be logged 
     * @returns Incomplete Log object
     */
    static getPseudoLogObject(eventSubcategory: string | null, eventType: string | null,
        eventSubType: string | null, value?: boolean | number | string | null, ext?: Record<string, string>): Record<string, string> {
        const pseudoLogObject = { ...IngestPayloadFormatter.formatRecordToLowerCase(ext) };
        if (eventSubcategory) pseudoLogObject[IngestLogObjectKey.eventSubcategory] = IngestPayloadFormatter.titleCase(eventSubcategory);
        if (eventType) pseudoLogObject[IngestLogObjectKey.eventType] = eventType.toLowerCase();
        if (eventSubType) pseudoLogObject[IngestLogObjectKey.eventSubtype] = eventSubType.toLowerCase();
        if (value) pseudoLogObject[IngestLogObjectKey.eventValue] = `${value}`.toLowerCase();
        return { ...pseudoLogObject, ...IngestUtils.getLocationReferrer() };
    }

    /** to fetch location and referrer from wherever ingest is called
    * @returns Record
    */
    static getLocationReferrer(): Record<string, string> {
        return {
            [IngestLogObjectKey.eventUrl]: Utils.hideQueryParametersValue(window.location.href, sensitiveParams),
            [IngestLogObjectKey.eventReferrer]: Utils.hideQueryParametersValue(document.referrer, sensitiveParams)
        }
    }

    static getMediaLoggingInfo(allMedia: SelectedMediaListPayload): Record<string, string> {
        const photos = allMedia.map(asset => asset.format).filter(val => val?.includes("image")) as string[] ?? [];
        const videos = allMedia.map(asset => asset.format).filter(val => val?.includes("video")) as string[] ?? [];
        const photoFormatCounts = this.photoFormatCount(photos);
        const videoFormatCounts = this.videoFormatCount(videos);

        const customEntries: Record<string, string> = {};
        customEntries[IngestLogObjectCustomKey.photoCount] = photos.length.toString();
        customEntries[IngestLogObjectCustomKey.videoCount] = videos.length.toString();
        customEntries[IngestLogObjectCustomKey.totalCount] = allMedia.length.toString();

        for (const format in photoFormatCounts) {
            const customEntry = format + IngestLogObjectCustomKey.countSuffix;
            if (format) customEntries[customEntry] = photoFormatCounts[format].toString();
        }

        for (const format in videoFormatCounts) {
            const customEntry = format + IngestLogObjectCustomKey.countSuffix;
            if (format) customEntries[customEntry] = videoFormatCounts[format].toString();
        }

        return customEntries;
    }

    static photoFormatCount(photos: string[]): { [key: string]: number } {
        const photoFormats = photos.map(str => {
            const splitArray = str.split("/");
            return splitArray.pop();
        });
        const photoCount: { [key: string]: number } = {};
        for (let i = 0; i < photoFormats.length; i++) {
            const current = photoFormats[i];
            if (current)
                photoCount[current] = (photoCount[current] || 0) + 1;
        }
        return photoCount;
    }

    static videoFormatCount(videos: string[]): { [key: string]: number } {
        const videoFormats = videos.map(str => {
            const splitArray = str.split("/");
            return splitArray.pop();
        });
        const videoCount: { [key: string]: number } = {};
        for (let i = 0; i < videoFormats.length; i++) {
            const current = videoFormats[i];
            if (current)
                videoCount[current] = (videoCount[current] || 0) + 1;
        }
        return videoCount;
    }

    static getIngestCreationsWorkflowName(workflow: WorkflowsName): string {
        let ingestWorkflowName = workflow.toString();
        switch (workflow) {
            case WorkflowsName.collage:
                ingestWorkflowName = IngestCreationWorkflows.photoCollage;
                break;
            case WorkflowsName.slideshow:
                ingestWorkflowName = IngestCreationWorkflows.slideshow;
                break;
            case WorkflowsName.patternOverlay:
                ingestWorkflowName = IngestCreationWorkflows.patternOverlay;
                break;
            case WorkflowsName.movingOverlay:
                ingestWorkflowName = IngestCreationWorkflows.movingOverlay;
                break;

            case WorkflowsName.replaceBackground:
                ingestWorkflowName = IngestCreationWorkflows.replaceBackground;
                break;
            case WorkflowsName.peekThrough:
                ingestWorkflowName = IngestCreationWorkflows.peekThrough;
                break;
            case WorkflowsName.photoText:
                ingestWorkflowName = IngestCreationWorkflows.photoText;
                break;
            default:
                ingestWorkflowName = workflow;
                break;
        }
        return ingestWorkflowName;
    }

    static getIngestUIViewNameFromRoutePath(path: string): string {
        if (Utils.isValidRoutePath(path)) {
            switch (path) {
                case Routes.MEDIA:
                    return IngestWorkflowTypes.mediaGrid;
                case Routes.CREATIONS:
                    return IngestWorkflowTypes.creationHome;
                case Routes.HOME:
                    return IngestWorkflowTypes.userHome;
                case Routes.SIV:
                    return IngestWorkflowTypes.siv;
                default:
                    return IngestWorkflowTypes.app;
            }
        }
        return "";
    }

    static shareTypeToIngest(shareType: string): string {
        switch (shareType) {
            case ShareType.patternOverlay:
                return IngestCreationWorkflows.patternOverlay;
            case ShareType.movingOverlay:
                return IngestCreationWorkflows.movingOverlay;
            case ShareType.replaceBackground:
                return IngestCreationWorkflows.replaceBackground;
            case ShareType.photoCollage:
                return IngestCreationWorkflows.photoCollage;
            case ShareType.slideshow:
                return IngestCreationWorkflows.slideshow;
            case ShareType.peekThrough:
                return IngestCreationWorkflows.peekThrough;
            case ShareType.photoText:
                return IngestCreationWorkflows.photoText;
            default:
                return shareType;
        }
    }

    static sanitizeFeedbackMessage = (msg: string): string => {
        const DENY_LIST_CHARS = /(&|>|<|\/|:)*/g;
        const modifiedMsg = msg.replace(DENY_LIST_CHARS, "");
        return modifiedMsg;
    }
}