/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useRef, useState } from 'react';
import { useButton } from "@react-aria/button";

import { IconType } from '../../../../assets/IconConstants';
import { ELIcon } from '../../atoms/el-icon/ELIconView';

import "./ELCustomIcon.scss";

export interface ELCustomIconProps extends React.SVGProps<SVGSVGElement> {
    iconkey: IconType,
    variant: "independent" | "contained",
    color?: string,
    hoverColor?: string,
    selectedColor?: string,
    selected?: boolean,
    onPress?: () => void,
    isDisabled?: boolean
}

/**
* This is small wrapper over ELIcon to support colors change and click handler for icons
* variant:
*    independent: 1. supports hover color change inherently 2. support onPress
*    contained: 1. should be controlled by container 2. no onPress handler
*/

const ELCustomIcon = (props: ELCustomIconProps): React.ReactElement => {
    const {
        iconkey,
        variant,
        color,
        hoverColor,
        selectedColor,
        selected,
        onPress,
        isDisabled,
        ...otherProps
    } = props;

    const ref = useRef(null);
    const { buttonProps } = useButton({
        onPress: () => {
            if (props.isDisabled)
                return;
            if (props.onPress)
                props.onPress();
        }
    }, ref);

    const [sIsHover, setHover] = useState(false);

    const handleMouseEvent = (hover: boolean): void => {
        if (variant === "independent") {
            setHover(hover);
        }
    }

    const getColor = (): string | undefined => {
        if (selected)
            return selectedColor;

        if (variant === "independent") {
            return sIsHover ? hoverColor : color;
        } else if (variant === "contained") {
            return color;
        }
    }

    const getButtonProps = (): Record<string, unknown> => {
        if (variant === "independent")
            return buttonProps;
        return {};
    }

    const getClassName = (): string => {
        return props.isDisabled ? "el-custom-icon-disabled" : "el-custom-icon";
    }

    return (
        <div data-testid={"el-custom-icon " + props.iconkey} style={{ "width": otherProps.width, "height": otherProps.height, "display": otherProps.display as string }} ref={ref} {...getButtonProps()}
            onMouseOver={() => handleMouseEvent(true)}
            onMouseOut={() => handleMouseEvent(false)}>
            <ELIcon {...otherProps} iconkey={props.iconkey} fill={getColor()} stroke={getColor()} className={getClassName()} />
        </div>
    )
}

export default ELCustomIcon;
