/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELTabPanelKey, ITabPanelButtonInfo, TabPanelButtonTypes } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";

const ELPeekThroughPanelItemProvider = ():
    ITabPanelButtonInfo[] => {

    const intlHandler = IntlHandler.getInstance();

    const listOfTabButtonItems: ITabPanelButtonInfo[] = [{
        buttonInfo: {
            iconType: IconType.mediaManagerTab,
            name: intlHandler.formatMessage("media"),
            buttonType: TabPanelButtonTypes.scoopedTabButton
        },
        key: ELTabPanelKey.media,
    },
    {
        key: ELTabPanelKey.overlays,
        buttonInfo: {
            iconType: IconType.peekThroughOverlayIcon,
            name: intlHandler.formatMessage("overlays"),
            buttonType: TabPanelButtonTypes.scoopedTabButton
        },
    },
    {
        buttonInfo: {
            iconType: IconType.socialLayout,
            name: intlHandler.formatMessage("layout"),
            buttonType: TabPanelButtonTypes.scoopedTabButton
        },
        key: ELTabPanelKey.layout
    }];

    return listOfTabButtonItems;
}

export default ELPeekThroughPanelItemProvider;