/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { ReactElement, useState } from "react";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { Accordion, AccordionItem } from "@react/react-spectrum/Accordion";

//Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELReactSpectrumV2Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV2Provider";

import "./ELFaq.scss";

interface ELFaqProps {
    faqs: Record<string, ReactElement>;
    visibleCount: number;
}

export const ELFaq = (props: ELFaqProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    const [showAll, setShowAll] = useState(false);
    const { faqs, visibleCount } = props;

    const toggleShowMore = (): void => {
        setShowAll(!showAll);
    };

    const visibleFAQs = showAll ? Object.keys(faqs) : Object.keys(faqs).slice(0, visibleCount);

    return (
        <Flex direction="column" gap="1.56rem" UNSAFE_className="el-faq__container">
            <Flex UNSAFE_className="el-faq__header">
                <span>{intlHandler.formatMessage("faqs")}</span>
            </Flex>
            <Flex direction="column" UNSAFE_className="el-faq__accordian-container">
                <ELReactSpectrumV2Provider>
                    <Accordion multiselectable>
                        {
                            visibleFAQs.map((key) => {
                                return (
                                    <AccordionItem header={key} key={key}>
                                        <span className="el-faq__answer">
                                            {faqs[key]}
                                        </span>
                                    </AccordionItem>
                                )
                            })
                        }
                    </Accordion>
                </ELReactSpectrumV2Provider>
            </Flex>
            {Object.keys(faqs).length > visibleCount && (
                <div className="el-faq__show-more-container">
                    <button className="el-faq__show-more-button" onClick={toggleShowMore}>
                        {showAll ? intlHandler.formatMessage("el-user-home-faq-show-less") : intlHandler.formatMessage("el-user-home-faq-show-more")}
                    </button>
                </div>
            )}
        </Flex>
    );
}