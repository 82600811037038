/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { useRef } from "react";

import Logger, { LogLevel } from "../Logger";
import { RepoDirListData } from "@elements/elementswebcommon";

import { MediaFetchParams, PageFetchReturnType } from "../hooks/useMediaFetch";
import { ElementsShareServiceWrapper } from "../../services/ElementsShareServiceWrapper";
import { InvitationAuthResponseType, InvitationServiceHeaderRelations, InvitationServiceWrapper } from "../../services/InvitationServiceWrapper";
import { DataResponseType, MediaDataResponse } from "../AssetStorageUtils";
import { ELAdobeAsset, ELRenditionOptions } from "../../common/interfaces/storage/AssetTypes";
import Utils from "../Utils";
import { PROMISE_FULFILLED } from "../Constants/Constants";
import { EmbeddedMetadataType } from "../../common/interfaces/storage/StorageTypes";

// assume Collection is logically a directory only
/*
    And store can be thought like this structure:

    Root store:{
        mediaOrganizerReducer : {                          
            (collectionId:string) : 
                {                                   ----                          
                    children:Array : [                  |
                    {                                   |
                            assetId: string             | This is a Repo Dir List Data object stored against collection Id
                            creationDate: string        |
                            ModifyDate: string          |
                    }                                   |
                    ]                                   |
                }                                   -----                                             
        },
        mediaThumbReducer: [
                {
                    assetId: string                 ----
                    base64Img: string                   | Should be used in view
                }                                   ----
        ]
    }
*/

const useSharedMediaFetch = (collectionId: string): MediaFetchParams => {
    const repoDirListDataRef = useRef<RepoDirListData>();
    const currentOffsetRef = useRef<number>(0);
    const assetAccessCredentials = useRef<Map<string, InvitationAuthResponseType>>(new Map());
    // TODO: define it in some constants file
    const limit = 60;
    const INVITATION_AUTH_CALL_GAP = 30; //ms==> same as media grid

    const fetchFirstPage = async (): Promise<PageFetchReturnType> => {
        if (!collectionId || collectionId === "") {
            Logger.log(LogLevel.WARN, "Empty collection ID")
            return Promise.reject();
        }

        try {
            repoDirListDataRef.current = await ElementsShareServiceWrapper.getInstance().getCollectionData(collectionId);
            const paginatedDirList = await getPaginatedDirList();
            const page: PageFetchReturnType = {
                stateKey: getStoreKey(),
                dirList: paginatedDirList
            }
            return Promise.resolve(page);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "useSharedMediaFetch:fetchFirstPage: ", "error fetching directory data: ", error);
            return Promise.reject();
        }
    }

    const fetchNextPage = async (): Promise<PageFetchReturnType> => {
        try {
            const paginatedDirList = await getPaginatedDirList();
            const page: PageFetchReturnType = {
                stateKey: getStoreKey(),
                dirList: paginatedDirList
            }
            return Promise.resolve(page);
        } catch (error) {
            Logger.log(LogLevel.WARN, "[fetchNextPage]: error fetching next page", error);
            return Promise.reject();
        }
    }

    const getPublicAssetList = async (offset: number, limit: number): Promise<ELAdobeAsset[]> => {
        if (!repoDirListDataRef.current) {
            throw new Error("[getPublicAssetList]: repo dir list data not set");
        }
        const collectionAssets = repoDirListDataRef.current.children;
        const assetAccessInfoPromises = [];
        for (let i = offset; i < collectionAssets.length && assetAccessInfoPromises.length < limit; ++i) {
            await Utils.wait(INVITATION_AUTH_CALL_GAP);
            assetAccessInfoPromises.push(InvitationServiceWrapper.getInstance().getAssetAccessInfo(collectionAssets[i].assetId));
        }
        try {
            const responses = await Promise.allSettled(assetAccessInfoPromises);
            const publicAssetList: ELAdobeAsset[] = [];
            responses.forEach((resp, i) => {
                if (resp.status === PROMISE_FULFILLED) {
                    const accessInfo = resp.value as InvitationAuthResponseType;
                    const asset = collectionAssets[i];
                    if (accessInfo && !accessInfo.isPrivate) {
                        assetAccessCredentials.current.set(asset.assetId as string, accessInfo);
                        // EO-4203411: resourceType for shared is fetched while getting asset info from invitation service
                        asset.format = accessInfo.resourceType;
                        publicAssetList.push(asset);
                    } else {
                        Logger.log(LogLevel.INFO, `[getPublicAssetList]: ${asset.assetId}  is private`);
                    }
                } else {
                    Logger.log(LogLevel.WARN, "[getPublicAssetList]: Invitation API for asset", collectionAssets[i].assetId, " is failing");
                    Logger.log(LogLevel.WARN, "[getPublicAssetList]: error", resp.reason);
                }
            });
            return publicAssetList;
        } catch (error) {
            Logger.log(LogLevel.WARN, `Error calling invitation auth end point for [offset]:${offset} [limit]:${limit}`);
            return Promise.reject();
        }
    }
    const getPaginatedDirList = async (): Promise<RepoDirListData> => {
        if (!repoDirListDataRef.current) {
            throw new Error("[getPaginatedDirList]: repo dir list data not set");
        }
        const publicAssetList = [];
        const collectionAssets = repoDirListDataRef.current.children;
        // Clearing previously fetched credentials
        assetAccessCredentials.current.clear();
        let offset = currentOffsetRef.current;
        // Dynamic limit
        let requiredAssetInCurrentPage = limit;

        while (requiredAssetInCurrentPage > 0 && offset < collectionAssets.length) {
            let newlyDiscoveredPublicAssets = null;
            try {
                newlyDiscoveredPublicAssets = await getPublicAssetList(offset, requiredAssetInCurrentPage);
                publicAssetList.push(...newlyDiscoveredPublicAssets);
            } catch (e) {
                Logger.log(LogLevel.ERROR, "useSharedMediaFetch:getPaginatedDirList: ", "Error resolving batch: [offset]", offset,
                    " [requiredAssetInCurrentPage]", requiredAssetInCurrentPage);
            } finally {
                Logger.log(LogLevel.INFO, "[getPaginatedDirList]: Asset retrieved in this call from inv service:",
                    newlyDiscoveredPublicAssets?.length ?? 0);
                offset += requiredAssetInCurrentPage;
                // will Try in next batch if > 0
                requiredAssetInCurrentPage -= newlyDiscoveredPublicAssets?.length ?? 0;
            }
        }

        currentOffsetRef.current = offset
        const hasNextPage = offset < collectionAssets.length;
        if (publicAssetList.length) {
            const enrichedPublicAssetList = await InvitationServiceWrapper.getInstance()
                .getAssetInfo(publicAssetList, assetAccessCredentials.current);
            return Promise.resolve(
                {
                    ...repoDirListDataRef.current,
                    hasNextPage: hasNextPage,
                    children: enrichedPublicAssetList
                }
            );
        }
        return Promise.resolve({
            ...repoDirListDataRef.current,
            hasNextPage: false,
            children: []
        });
    }

    const fetchRendition = async (asset: ELAdobeAsset, renditionOptions: ELRenditionOptions, responseType: DataResponseType = "arraybuffer"): Promise<MediaDataResponse> => {
        try {
            // Since the user of this method knows that asset is public, we should fetch credentials even if map do not have that credential
            // use case: while fetching for SIV, this hook was re-init and hence assetAccessCredentials map was empty
            // This would stop two calls while rendering grid at least
            const assetAccessInfo = assetAccessCredentials.current.get(asset.assetId ?? "") ?? await InvitationServiceWrapper.getInstance()
                .getAssetAccessInfo(asset.assetId);

            const relation = Utils.isGifMimeType(asset) ? InvitationServiceHeaderRelations.primary : InvitationServiceHeaderRelations.rendition;
            //assetCredentials.current.get(asset.assetId as string);

            if (assetAccessInfo && !assetAccessInfo.isPrivate) {
                return InvitationServiceWrapper
                    .getInstance()
                    .fetchRendition(assetAccessInfo.accessURL, assetAccessInfo.accessToken, relation, renditionOptions, responseType);
            }
            return Promise.reject();
        } catch (error) {
            Logger.log(LogLevel.WARN, "useSharedMediaFetch:fetchRendition: ", "Error Fetching thumbnail data for thumbnail:", asset.assetId, error);
            return Promise.reject();
        }
    }

    const getStoreKey = (): string => {
        return collectionId;
    }

    const downloadDeserializedAsset = async (asset: ELAdobeAsset): Promise<void> => {
        try {
            const invitationServiceInstance = InvitationServiceWrapper.getInstance();
            const downloadURL = await invitationServiceInstance.getPublicDownloadURL(asset.assetId);
            Utils.downloadURI(downloadURL);
            Logger.log(LogLevel.INFO, "[useShareMediaFetch] Asset downloaded ", asset.assetId);
            return Promise.resolve();
        } catch (error) {
            Logger.log(LogLevel.ERROR, "useSharedMediaFetch:useShareMediaFetch: ", "Error downloading asset", asset.assetId, error);
            return Promise.reject();
        }
    }

    const fetchAssetEmbeddedInfo = async (asset: ELAdobeAsset): Promise<EmbeddedMetadataType> => {
        try {
            const invitationServiceInstance = InvitationServiceWrapper.getInstance();
            const response = await invitationServiceInstance.fetchAssetEmbeddedInfo(asset.assetId);
            const embeddedInfo: EmbeddedMetadataType = response.data;
            return Promise.resolve(embeddedInfo);
        } catch (error) {
            Logger.log(LogLevel.INFO, "[useShareMediaFetch]: Error fetching asset embedded data ", asset.assetId, error);
            return Promise.reject();
        }
    }

    const deleteDeserializedAsset = (asset: ELAdobeAsset): Promise<boolean> => {
        //Logic to delete asset using invitation service can be added here
        Logger.log(LogLevel.INFO, "[useShareMediaFetch] Asset deleted ", asset.assetId);
        return Promise.resolve(true);
    }

    const useMediaFetchObj: MediaFetchParams = {
        fetchFirstPage: fetchFirstPage,
        fetchNextPage: fetchNextPage,
        fetchRendition: fetchRendition,
        getStoreKey: getStoreKey,
        downloadDeserializedAsset: downloadDeserializedAsset,
        fetchAssetEmbeddedInfo: fetchAssetEmbeddedInfo,
        deleteDeserializedAsset: deleteDeserializedAsset
    }
    return useMediaFetchObj;
};

export default useSharedMediaFetch;