/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Asset, Constants, Request as ELServiceRequest, Utils, Version } from "@elements/elementswebcommon";

//Application Specific
import { CreationsData, CreationsOutputInfo } from "../../../../common/interfaces/creations/CreationTypes";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import CreationUtils from "../CreationUtils";
import { ELAssetPathOrId } from "../../../../common/interfaces/storage/AssetTypes";

export abstract class ELCreationsPathResolver {

    protected creationsData: CreationsData;

    constructor(creationsData: CreationsData) {
        this.creationsData = creationsData;
    }

    private async _fetchCreationsConfigJson(): Promise<unknown> {
        const config = this.creationsData.outputs?.config;
        if (config) {
            const root = config.root;
            const relativeFilePath = config.assetURN;
            const jsonData = await AssetStorageUtils.getAssetJsonData(root, relativeFilePath);
            const parsedJsonData = Utils.convertJsonKeys(jsonData);
            return parsedJsonData;
        }
        else {
            Logger.log(LogLevel.ERROR, "ELCreationsPathResolver: _fetchCreationsConfigJson, not able to find config in outputs");
            Promise.reject();
        }
    }

    private async _getRequestObject(outputJson: unknown): Promise<ELServiceRequest> {
        const requestObj = await CreationUtils.getSerializedRequestObject(outputJson);
        return requestObj;
    }

    protected async getRequestObjectFromProjectData(): Promise<ELServiceRequest> {
        const projectJson = await this._fetchCreationsConfigJson();
        const requestObj = await this._getRequestObject(projectJson);
        return requestObj;
    }

    protected getAssetPathFromOutputInfo(outputInfo: CreationsOutputInfo): ELAssetPathOrId {
        const path = Constants.SEPARATOR + outputInfo.root + Constants.SEPARATOR + outputInfo.assetURN;
        return { path: path } as ELAssetPathOrId;
    }

    protected getAssetIdFromOutputInfo(outputInfo: CreationsOutputInfo): ELAssetPathOrId {
        const assetId = outputInfo.assetURN;
        return { assetId: assetId };
    }

    protected getCreationVersion(): Version {
        if (!this.creationsData.version) {
            throw new Error("ELCreationsPathResolver: getCreationVersion, failed to extract version from creationData");
        }
        return this.creationsData.version;
    }

    abstract getCreationFullAssetPathOrId(): Promise<ELAssetPathOrId>;
    abstract getCreationPreviewAssetPathOrId(): Promise<ELAssetPathOrId>;
    abstract getCreationMasterAssetData(): Promise<Asset[]>;
}