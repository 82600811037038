/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import Logger, { LogLevel } from "../../../../utils/Logger";

/**
 * Represents a collection of performance measurements for an action that occurred in the application. The action may
 * represent a user action (e.g. drawing a rectangle), or it may represent an internal application action (e.g. loading
 * a document).
 */
export class PerfAction {
    private _endTime = -1;
    private _endTimeOffset = 0;

    /**
     * Constructor.
     * @param _category - A category label for the action.
     * @param _name - A name for the action.
     * @param _id - A unique ID for the action. The performance monitor uses this to associate the beginning of an
     *      action with the end of the action.
     * @param _startTime - The start time for action, typically in ms, although any unit may be used as long as there is
     *      consistency.
     * @param _startTimeOffset - Offset between performance.now+performance.timeOrigin and Date.now at startTime.
     * @param _locked - Whether the performance monitor should "lock" this action, and persist it throughout the current
     *      application session. If this is true, the action will not be garbage collected, no matter how old it is.
     *      This can be useful for retaining important measurements that occur early in the app lifecycle such as
     *      document load time.
     * @param _marked - Whether this action should be marked using the performance.mark and performance.measure APIs, so
     *      that it is visible in the Timings row in the Chrome web inspector profiler.
     */
    constructor(
        private _category: string,
        private _name: string,
        private _id: string,
        private _startTime: number,
        private _startTimeOffset: number,
        private _locked: boolean,
        private _marked: boolean
    ) {}

    /**
     * True if the action is complete, i.e. if `end` was called.
     */
    get hasEnded(): boolean {
        return this._endTime > 0;
    }

    /**
     * The category label for the action.
     */
    get category(): string {
        return this._category;
    }

    /**
     * The name of the action.
     */
    get name(): string {
        return this._name;
    }

    /**
     * The ID for the action. The performance monitor uses this to associate the beginning of an action with the
     * end of the action.
     */
    get id(): string {
        return this._id;
    }

    /**
     * The start time for the action, typically in ms, although any unit may be used as long as there is consistency.
     */
    get startTime(): number {
        return this._startTime;
    }

    /**
     * The end time for the action, typically in ms, although any unit may be used as long as there is consistency.
     */
    get endTime(): number {
        return this._endTime;
    }

    /**
     * Gets offset between performance.now+performance.timeOrigin and Date.now at startTime.
     */
    get startTimeOffset(): number {
        return this._startTimeOffset;
    }

    /**
     * Gets offset between performance.now+performance.timeOrigin and Date.now at endTime.
     */
    get endTimeOffset(): number {
        return this._endTimeOffset;
    }

    /**
     * Sets offset between performance.now+performance.timeOrigin and Date.now at endTime.
     */
    set endTimeOffset(endTimeOffset: number) {
        this._endTimeOffset = endTimeOffset;
    }

    /**
     * Whether the performance monitor should "lock" this action, and persist it throughout the current application
     * session. If this is true, the action will not be garbage collected, no matter how old it is. This can be useful
     * for retaining important measurements that occur early in the app lifecycle such as document load time.
     */
    get locked(): boolean {
        return this._locked;
    }

    /**
     * Whether this action should be marked using the performance.mark and performance.measure APIs, so that it is
     * visible in the Timings row in the Chrome web inspector profiler.
     */
    get marked(): boolean {
        return this._marked;
    }

    /**
     * The duration of this action or -1 if it hasn't ended yet. This is in the same units as start time and end time,
     * usually ms.
     */
    get duration(): number {
        if (!this.hasEnded) {
            return -1;
        }

        return this._endTime - this._startTime;
    }

    /**
     * End the action at the specified time.
     * @param endTime - The end time for the action, typically in ms, although any unit may be used as long as there is
     *      consistency.
     */
    end(endTime: number): void {
        if (endTime < this._startTime) {
            Logger.log(LogLevel.DEBUG,
                `PerfAction.end: endTime should be greater than or equal to startTime for action with name "${this.name}" and id "${this.id}".`
            );
            this._endTime = this._startTime;
        } else {
            this._endTime = endTime;
        }
    }
}
