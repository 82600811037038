/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Utils and constants
import { LoginUtils } from "../../../utils/LoginUtils";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../IWorkflow";
import IWorkspace from "../../IWorkspace";
import { PublicLandingView } from "./PublicLandingView";

class PublicLanding extends IWorkflow {

    private _link!: string;

    constructor(workspace: IWorkspace) {
        super(workspace, WorkflowsName.publicLanding);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        ReactDOM.render(
            React.createElement(PublicLandingView,
                {
                    controller: this
                }),
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        //this.createView(this.ensureHTMLElement(containerId));
        //For web 1.0 to reduce testing effort we are using this solution for EO-4204030
        LoginUtils.signIn();
    }

}

export { PublicLanding };
