/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { useEffect, useReducer } from "react";

//App Specific
import { ViewAction } from "../../../IBaseController";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import ELPreviewThumb from "../../organism/el-preview-thumb/ELPreviewThumb";
import {
    ELRecommendationWorkflowViewActions,
    ELRecommendationWorkflowControllerActions,
    ELPreviewCreationThumbData,
    ELRecommendationWorkflowCreationState
} from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import ELRecommendationWorkflowThumbPanel from "./ELRecommendationWorkflowThumbPanel";
import "./ELRecommendationWorkflowThumbPanelView.scss"

interface ELRecommendationWorkflowThumbPanelViewProps {
    controller: ELRecommendationWorkflowThumbPanel,
    panelHeading: string,
    showThumbTitle: boolean
}

const initialState: ELRecommendationWorkflowCreationState = {
    viewData: [],
    selectedOverlay: ""
}

const getOverlaysView = (overlaysData: ELPreviewCreationThumbData[], selectedOverlay: string, onClickingOverlay: (key: string) => void, showTitle: boolean): React.ReactElement => {

    const list = overlaysData.map((item, index) => {
        return ELPreviewThumb({
            key: item.id,
            name: item.name,
            imageData: item.imageData,
            isSelected: selectedOverlay === item.id,
            showTitle: showTitle,
            onClick: onClickingOverlay,
            showName: false
        })
    })

    return (
        <div className="single-image-recommendations-data-container">
            {list}
        </div>
    )
}

const ELRecommendationWorkflowThumbPanelView = (props: ELRecommendationWorkflowThumbPanelViewProps): React.ReactElement => {
    const reducer = (state: ELRecommendationWorkflowCreationState, action: ViewAction): ELRecommendationWorkflowCreationState => {
        switch (action.type) {
            case ELRecommendationWorkflowViewActions.updateAllThumbsData: {
                const updatedData = action.payload as ELPreviewCreationThumbData[];
                return {
                    ...state,
                    viewData: updatedData
                };
            }
            case ELRecommendationWorkflowViewActions.updateSingleThumbData: {
                const newThumbData = action.payload as ELPreviewCreationThumbData;
                let updatedViewData = state.viewData.map(thumbData => {
                    return thumbData.id === newThumbData.id ? newThumbData : thumbData;
                })
                const availableThumbList = updatedViewData.slice(1).filter(thumbData => thumbData.imageData);
                const unavailableThumbList = updatedViewData.slice(1).filter(thumbData => thumbData.imageData === undefined);
                updatedViewData = [updatedViewData[0], ...availableThumbList, ...unavailableThumbList];
                return {
                    ...state,
                    viewData: updatedViewData
                };
            }
            case ELRecommendationWorkflowViewActions.setSelectedOverlayId: {
                const selectedId = action.payload as string;
                return {
                    ...state,
                    selectedOverlay: selectedId
                }
            }
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);

    const onClickingOverlay = (key: string): void => {
        if (key !== state.selectedOverlay) {
            viewDispatch({
                type: ELRecommendationWorkflowViewActions.setSelectedOverlayId,
                payload: key
            });
            const selectedThumbData = state.viewData.filter(thumb => thumb.id === key)[0];
            props.controller.notify({
                type: ELRecommendationWorkflowControllerActions.overlayClicked,
                payload: selectedThumbData
            });
        }
    }

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        return () => {
            props.controller.destroy();
        }
    }, [props.controller])

    return (
        <div className="single-image-recommendations-panel-box">
            <ELPanelHeader title={props.panelHeading} alignTitle="center" />
            <ELScrollPanel UNSAFE_className="single-image-recommendations-background-scroll" scrollY={true} width="100%">
                <div className="single-image-recommendations-panel-content-box">
                    {getOverlaysView(state.viewData, state.selectedOverlay, onClickingOverlay, props.showThumbTitle)}
                </div>
            </ELScrollPanel>
        </div>
    )
}

export { ELRecommendationWorkflowThumbPanelView };