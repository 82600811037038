/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactElement, useEffect, useReducer, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

//Adobe internal
import { Flex, ProgressCircle } from "@adobe/react-spectrum";

//utils
import Constants from "../../../../utils/Constants/Constants";
import useRenditionFetch, { ELAssetProps } from "../../../../utils/hooks/useRenditionFetch";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { ERROR_THUMBDATA, LinkParams } from "../../../../utils/Constants/Constants";
import Utils, { DurationParsingFormat } from "../../../../utils/Utils";
import { SIVUtils } from "../../../../utils/SIVUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { ToastUtils } from "../../../../utils/ToastUtils";

//Application Specific
import IViewController from "../../../../view/IViewController";
import ELActionButton from "../../../../view/components/molecules/el-action-button/ELActionButton";
import { IconType } from "../../../../assets/IconConstants";
import { GetMediaFetchHookFunc } from "../../../../view/components/organism/el-mediagrid/ELMediaGrid";
import { RootState } from "../../../../stores/store";
import { ELIcon } from "../../../../view/components/atoms/el-icon/ELIconView";
import { ViewAction } from "../../../../view/IBaseController";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ShareOptions } from "../../../../view/components/organism/el-share-options/ELShareOptions";
import { ELDeleteDialog } from "../../../../view/components/organism/el-delete-dialog/ELDeleteDialogView";
import { ELSidebarPanelView } from "../../../../view/components/organism/el-sidebar-panel/ELSidebarPanelView";
import { AssetMetaData } from "../../../../common/interfaces/storage/StorageTypes";
import { ELReportAbuse } from "../../../../view/components/organism/el-report-abuse/ELReportAbuse";
import { ELShareOptionsPopover } from "../../../../view/components/organism/el-share-popover/ELSharePopoverView";
import SIVAction from "../../../../stores/actions/SIVAction";
import { ELAdobeAsset, RenditionData, elDeserializeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import IMS from "../../../../services/IMS";
import { PSDC_FILE_FORMAT } from "../../../../common/interfaces/storage/FileTypes";
import MediaOrganizerAction from "../../../../stores/actions/mediaOrganizerActions";
import { ELProgressCircle } from "../../../../view/components/molecules/el-progress-circle/ELProgressCircle";
import { EmbeddedMetadataType, EmbeddedMetaDataProperties, VideoMetaData } from "../../../../common/interfaces/storage/StorageTypes";
import { WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import ELOpenInDesktopButton from "../../../../view/components/molecules/el-open-in-desktop-button/ELOpenInDesktopButton";
import { RenditionState } from "../../../../common/interfaces/hooks/RenditionFetchTypes";
import { ShareOptionsActionType } from "../../../../view/components/organism/el-share-options/ELShareOptionsView";
import { ELQRSharePayload, ShareTypeCategory } from "../../../../common/interfaces/share/ShareTypes";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";

import "./SingleImageView.scss";

const FETCH_DIRECTORY_LISTING_BEFORE_IND = 3;
export const SIV_INVALID_INDEX = -1;

export enum SingleImageControllerActionType {
    exit = "EXIT",
    sharePressed = "SHARE_PRESSED",
    rightArrowPressed = "RIGHT_ARROW_PRESSED",
    leftArrowPressed = "LEFT_ARROW_PRESSED",
    setCurrentIndex = "SET_CURRENT_INDEX",
    updateURLPath = "UPDATE_URL_PATH",
    updateStoreKey = "UPDATE_STORE_KEY",
    openDeeplink = "OPEN_DEEP_LINK",
    editPressed = "EDIT_PRESSED",
}

export enum SingleImageViewActionType {
    showArrow = "SHOW_ARROW",
    setCurrentIndex = "SET_CURRENT_INDEX",
    setRenditionData = "SET_RENDITION_DATA",
    updateHistory = "UPDATE_HISTORY",
    setNeedMoreData = "SET_NEED_MORE_DATA",
    setHasAssetUpdated = "SET_HAS_ASSET_UPDATED",
    setIsFileNotDownloadable = "SET_IS_FILE_NOT_DOWNLOADABLE",
    setCtaButtons = "SET_CTA_BUTTON"
}

interface SingleImageViewProps {
    controller: IViewController,
    getMediaFetchHook: GetMediaFetchHookFunc,
    dirPath: string,
    buttons: IconType[],
    shareOptionsController: ShareOptions,
    hasOwnerData: boolean,
    needMoreData?: boolean
}

interface SingleImageViewState {
    startTime: number,
    showLeftArrow: boolean,
    showRightArrow: boolean,
    currentIndex: number,
    imgData: string,
    //  In order to deduce if-else video data is optional.
    //  For video both img and video data will be present and for Image only image data will be present.
    videoData?: string,
    videoMetaData?: VideoMetaData,
    updateHistory: boolean,
    needMoreData: boolean,
    hasAssetUpdated: boolean,
    isFileNotDownloadable: boolean,
    ctaButtons: IconType[]
}

const getSingleImageViewDefaultState = (props: SingleImageViewProps): SingleImageViewState => {
    return {
        startTime: Date.now(),
        showLeftArrow: false,
        showRightArrow: false,
        currentIndex: SIV_INVALID_INDEX,
        imgData: "",
        updateHistory: false,
        needMoreData: props.needMoreData ? props.needMoreData : false,
        hasAssetUpdated: false,
        isFileNotDownloadable: false,
        ctaButtons: props.buttons
    }
}

function reducer(state: SingleImageViewState, action: ViewAction): SingleImageViewState {
    // If you intentionally want to send undefined or null payload then remove these checks
    if (action.payload === undefined || action.payload === null) {
        return state;
    }
    switch (action.type) {
        case SingleImageViewActionType.showArrow:
            {
                const payload = action.payload as { showLeftArrow: boolean, showRightArrow: boolean };
                return {
                    ...state,
                    showLeftArrow: payload.showLeftArrow === true,
                    showRightArrow: payload.showRightArrow === true,
                }
            }
        case SingleImageViewActionType.setCurrentIndex:
            return {
                ...state,
                currentIndex: action.payload as number
            }
        case SingleImageViewActionType.setRenditionData:
            {
                const payload = action.payload as { imgData: string, videoData?: string, videoMetaData?: VideoMetaData };
                return {
                    ...state,
                    imgData: payload.imgData,
                    videoData: payload.videoData,
                    videoMetaData: payload.videoMetaData
                }
            }
        case SingleImageViewActionType.updateHistory:
            {
                const payload = action.payload as boolean;
                return {
                    ...state,
                    updateHistory: payload
                }
            }
        case SingleImageViewActionType.setNeedMoreData:
            {
                const payload = action.payload as boolean;
                return {
                    ...state,
                    needMoreData: payload
                }
            }
        case SingleImageViewActionType.setHasAssetUpdated:
            {
                const payload = action.payload as boolean;
                return {
                    ...state,
                    hasAssetUpdated: payload
                }
            }
        case SingleImageViewActionType.setIsFileNotDownloadable:
            {
                const payload = action.payload as boolean;
                return {
                    ...state,
                    isFileNotDownloadable: payload
                }
            }
        case SingleImageViewActionType.setCtaButtons:
            {
                const payload = action.payload as IconType[];
                return {
                    ...state,
                    ctaButtons: payload
                }
            }

        default:
            return state;
    }
}

const RETRY_FOR_RENDITION_FETCH = 8;

export const SingleImageView = (props: SingleImageViewProps): React.ReactElement => {

    const rootData = useSelector((state: RootState) => state.mediaOrganizerReducer);
    const [infoPanelData, setInfoPanelMetaData] = useState("" as AssetMetaData);
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const isMediaOrganizerResetRequired = useRef(false);
    const sivContainerRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, getSingleImageViewDefaultState(props));
    const reduxDispatch = useDispatch();
    const mediaFetchHookObj = props.getMediaFetchHook(props.dirPath);
    const { isMobile, isMobilePortraitMode, width, height } = useViewport();
    const viewPortWidth = (width as number);
    const viewPortHeight = (height as number);
    const [isOpenInDesktopSupported, setIsOpenInDesktopSupported] = useState(false);

    const intlHandler = IntlHandler.getInstance();
    const mediaRef = useRef(null);

    const location = useLocation();
    const notify = props.controller.notify.bind(props.controller);
    const abortFetchRendition = useRef<() => void>(() => { return; });

    const getAssetProps = (): ELAssetProps => {
        const assetProps: ELAssetProps = { current: undefined };
        if (rootData && rootData[mediaFetchHookObj.storeKey]) {
            if (state.currentIndex < rootData[mediaFetchHookObj.storeKey].children.length) {
                assetProps.current = rootData[mediaFetchHookObj.storeKey].children[state.currentIndex];
                SIVUtils.ingestSIVMedia(assetProps.current, notify);
            }
            if ((state.currentIndex + 1) < rootData[mediaFetchHookObj.storeKey].children.length)
                assetProps.next = rootData[mediaFetchHookObj.storeKey].children[state.currentIndex + 1];
            if ((state.currentIndex - 1) >= 0)
                assetProps.previous = rootData[mediaFetchHookObj.storeKey].children[state.currentIndex - 1];
        }
        return assetProps;
    }

    const { renditionState, fetchAndCacheRenditionWithAbortFunction } = useRenditionFetch();

    useEffect(() => {
        props.controller.initialize(dispatch);
        notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.siv, IngestEventTypes.render, IngestEventSubTypes.success, true)
        });
        return () => {
            //clean up
            abortPreviousAssetRenditionFetch();
            reduxDispatch(SIVAction.clearData());
            if (isMediaOrganizerResetRequired.current) {
                reduxDispatch(MediaOrganizerAction.reset(Constants.ELEMENTS_PHOTOS_PATH as string));
            }
            const timeSpentOnSiv = Utils.getParsedDuration(DurationParsingFormat.mm_ss, (Date.now() - state.startTime) / 1000);
            notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.siv, IngestEventTypes.close,
                    IngestEventSubTypes.success, timeSpentOnSiv)
            });
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        if (!mediaFetchHookObj.isFetching && state.needMoreData)
            mediaFetchHookObj.fetchData();
    }, [mediaFetchHookObj.isFetching, state.needMoreData]);

    useEffect(() => {
        notify({ type: SingleImageControllerActionType.updateStoreKey, payload: mediaFetchHookObj.storeKey });
    }, [mediaFetchHookObj.storeKey]);

    useEffect(() => {
        if (rootData && rootData[mediaFetchHookObj.storeKey]) {
            setOrFetchCurrentAssetData();
            updateOwnerData();
            Logger.log(LogLevel.DEBUG, "useEffect : Root data : [SingleImageView] Current Index :", state.currentIndex);
        } else {
            dispatch({ type: SingleImageViewActionType.setNeedMoreData, payload: true });
        }

    }, [rootData[mediaFetchHookObj.storeKey], rootData, rootData[mediaFetchHookObj.storeKey]?.children]);


    useEffect(() => {
        const assetId = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_MEDIA_ID_PARAM);
        if (!AssetStorageUtils.isValidAssetId(assetId)) { //We need to route back to main grid
            handleEscape();
        }
        else if (assetId !== getCurrentAssetId()) { //This is the case when we coming from back button in browser we dont want to update history
            dispatch({
                type: SingleImageViewActionType.updateHistory, payload: false
            });
            dispatch({
                type: SingleImageViewActionType.setCurrentIndex, payload:
                    rootData[mediaFetchHookObj.storeKey]?.children.findIndex(asset => (AssetStorageUtils.getAssetID(asset) === assetId))
            });
        }

    }, [location]);

    useEffect(() => {
        Logger.log(LogLevel.INFO, "SIV currentIndex: ", state.currentIndex);
        //asset changed
        if (isValidAsset()) {
            dispatch({ type: SingleImageViewActionType.setHasAssetUpdated, payload: true });
        }

        notify({ type: SingleImageControllerActionType.setCurrentIndex, payload: state.currentIndex });
        updateOpenInDesktopSupported();
    }, [state.currentIndex]);

    useEffect(() => {
        if (state.hasAssetUpdated) {
            abortPreviousAssetRenditionFetch();
            updateRenditionData();
            fetchAndCacheInfoPanelMetaData();
            setOrFetchNextAndPreviousAssetData();
            prepareAndSetAssetInfoData();
            updateURLPath();
            cacheFutureData();
            notifyAssetUpdate();
            updateFileDownloadableStatus();

            dispatch({
                type: SingleImageViewActionType.updateHistory, payload: true
            });
        }
        dispatch({ type: SingleImageViewActionType.setHasAssetUpdated, payload: false });
    }, [state.hasAssetUpdated]);

    useEffect(() => {
        if (mediaRef && mediaRef.current) {
            const mediaElement = (mediaRef.current as HTMLElement);
            let scaleFactor = 1.0;
            if (mediaElement.clientWidth > viewPortWidth) {
                scaleFactor = viewPortWidth / mediaElement.clientWidth;
                mediaElement.style.transformOrigin = "left center";
            } else if (mediaElement.clientHeight > viewPortHeight) {
                scaleFactor = viewPortHeight / mediaElement.clientHeight;
                mediaElement.style.transformOrigin = "top center";
            }
            mediaElement.style.transform = `scale(${scaleFactor})`;
        }
    }, [state.imgData, state.videoData, viewPortWidth, viewPortHeight]);

    useEffect(() => {
        if (isValidAsset())
            prepareAndSetAssetInfoData();
    }, [state.videoMetaData?.dimensionsInfo]);

    const notifyAssetUpdate = (): void => {
        reduxDispatch(SIVAction.sivAssetUpdated(getCurrentAssetId()));
    }

    const getCurrentAsset = (): ELAdobeAsset | undefined => {
        if (rootData && rootData[mediaFetchHookObj.storeKey])
            if (state.currentIndex < rootData[mediaFetchHookObj.storeKey].children.length)
                return rootData[mediaFetchHookObj.storeKey].children[state.currentIndex];
        return undefined;
    }

    const getCurrentAssetId = (): string => {
        const currentAsset = getCurrentAsset();
        return currentAsset ? (AssetStorageUtils.getAssetID(currentAsset)) : "";
    }

    const getCurrentAssetName = (): string => {
        const currentAsset = getCurrentAsset();
        return currentAsset ? (AssetStorageUtils.getAssetName(currentAsset)) : "";
    }

    const isValidAsset = (): boolean => {
        return state.currentIndex !== SIV_INVALID_INDEX;
    }

    const updateFileDownloadableStatus = (): void => {
        // For CPSD file type , download button will be not be shown
        const currentAsset = getCurrentAsset();
        if (currentAsset && (AssetStorageUtils.getAssetFormat(currentAsset)) === PSDC_FILE_FORMAT) {
            dispatch({ type: SingleImageViewActionType.setIsFileNotDownloadable, payload: true });
        }
        else {
            dispatch({ type: SingleImageViewActionType.setIsFileNotDownloadable, payload: false });
        }
    }

    const cacheFutureData = (): void => {
        const dirSize = rootData[mediaFetchHookObj.storeKey].children.length;
        if (state.currentIndex > dirSize - FETCH_DIRECTORY_LISTING_BEFORE_IND) { // We are fetching the dir listing before we are reaching the end
            dispatch({ type: SingleImageViewActionType.setNeedMoreData, payload: true });
        }
    }

    const setOrFetchNextAndPreviousAssetData = (): void => {
        const dirSize = rootData[mediaFetchHookObj.storeKey].children.length;

        if (state.currentIndex === dirSize - 1) {
            dispatch({ type: SingleImageViewActionType.setNeedMoreData, payload: true });
            dispatch({
                type: SingleImageViewActionType.showArrow, payload: {
                    showLeftArrow: state.currentIndex !== 0,
                    showRightArrow: false
                }
            });
        } else {
            dispatch({ type: SingleImageViewActionType.setNeedMoreData, payload: false });
            dispatch({
                type: SingleImageViewActionType.showArrow, payload: {
                    showLeftArrow: state.currentIndex !== 0,
                    showRightArrow: state.currentIndex !== dirSize - 1
                }
            });
        }
    }

    const setOrFetchCurrentAssetData = async (): Promise<void> => {
        if (!isValidAsset()) {
            const assetId = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_MEDIA_ID_PARAM);
            const assetIndex = rootData[mediaFetchHookObj.storeKey]?.children.findIndex(asset => (AssetStorageUtils.getAssetID(asset) === assetId));

            if (assetIndex !== SIV_INVALID_INDEX) {
                dispatch({
                    type: SingleImageViewActionType.setCurrentIndex, payload: assetIndex
                });
                dispatch({ type: SingleImageViewActionType.setNeedMoreData, payload: false });
            } else if (mediaFetchHookObj.hasMoreData()) { //need more data
                dispatch({ type: SingleImageViewActionType.setNeedMoreData, payload: true });
            } else {
                handleEscape();
            }
        }
    }

    const updateURLPath = (): void => {
        if (state.updateHistory) {
            notify({ type: SingleImageControllerActionType.updateURLPath, payload: getCurrentAssetId() });
        }
    }

    const abortPreviousAssetRenditionFetch = (): void => {
        abortFetchRendition.current();
        abortFetchRendition.current = () => { return; };
    }

    const updateRenditionData = async (): Promise<void> => {
        try {
            const fetchAndCacheRenditionWithAbortObj = fetchAndCacheRenditionWithAbortFunction({ fetchRendition: mediaFetchHookObj.fetchRendition, assetProps: getAssetProps() }, RETRY_FOR_RENDITION_FETCH);
            abortFetchRendition.current = fetchAndCacheRenditionWithAbortObj.abortFetchRendition;
            const renditionData: RenditionData = await fetchAndCacheRenditionWithAbortObj.fetchAndCacheRenditionData({ fetchRendition: mediaFetchHookObj.fetchRendition, assetProps: getAssetProps() });
            dispatch({
                type: SingleImageViewActionType.setRenditionData,
                payload: {
                    imgData: renditionData.imgData,
                    videoData: renditionData.videoData,
                    videoMetaData: renditionData.videoMetaData
                }
            });
        } catch (error) {
            dispatch({
                type: SingleImageViewActionType.setRenditionData,
                payload: {
                    imgData: ERROR_THUMBDATA
                }
            });
        }
    }

    const fetchAndCacheInfoPanelMetaData = async (): Promise<void> => {
        const assetProps = getAssetProps();
        if (assetProps.current) {
            mediaFetchHookObj.fetchAssetEmbeddedData(assetProps.current);
        }
        if (assetProps.next) {
            mediaFetchHookObj.fetchAssetEmbeddedData(assetProps.next);
        }
        if (assetProps.previous) {
            mediaFetchHookObj.fetchAssetEmbeddedData(assetProps.previous);
        }
    }

    const updateOwnerData = (): void => {
        if (!props.hasOwnerData) {
            const collectionOwnerId = rootData[mediaFetchHookObj.storeKey]?.children[0].createdBy ?? "";
            const isOwner = IMS.getInstance().getUserId() === collectionOwnerId;
            updateIconList(isOwner);
        }
    }

    const updateIconList = (isOwner: boolean): void => {
        const iconList = isOwner ? [IconType.edit, IconType.download, IconType.share] : [IconType.download];
        dispatch({ type: SingleImageViewActionType.setCtaButtons, payload: iconList });
    }

    const handleDownloadNotify = (isDownloadSuccess: boolean): void => {
        if (!isDownloadSuccess) {
            ToastUtils.error(intlHandler.formatMessage("download-fail-toast-msg"), {
                timeout: Constants.TOAST_NO_TIMEOUT as number
            });
        }
    }

    const handleDownload = (): void => {
        const currentAsset = getAssetProps().current;
        let format: string | undefined;
        if (currentAsset)
            format = getAssetInfo(currentAsset).format?.toString();

        const message = IntlHandler.getInstance().formatMessage("file-download-in-background", {
            media: IntlHandler.getInstance().formatMessage("media").toLowerCase()
        });

        ToastUtils.info(message);
        SIVUtils.ingestMediaAction(IngestEventSubTypes.download, notify);
        mediaFetchHookObj.downloadAsset(rootData[mediaFetchHookObj.storeKey].children[state.currentIndex]).then(() => {
            handleDownloadNotify(true);
            if (currentAsset)
                SIVUtils.ingestMediaActionStatus(IngestEventTypes.download, IngestEventSubTypes.success, notify, format);
        }).catch(() => {
            handleDownloadNotify(false);
            if (currentAsset)
                SIVUtils.ingestMediaActionStatus(IngestEventTypes.download, IngestEventSubTypes.error, notify, format);
        });

    }

    const handleDeleteNotify = (isDeleteSuccess: boolean): void => {
        const currentAsset = getAssetProps().current;
        let format: string | undefined;
        if (currentAsset)
            format = getAssetInfo(currentAsset).format?.toString();

        const intlHandler = IntlHandler.getInstance();
        if (isDeleteSuccess) {
            ToastUtils.success(intlHandler.formatMessage("items-deleted", {
                itemCount: 1,
                media: IntlHandler.getInstance().formatMessage("media").toLowerCase()
            }));
            if (currentAsset)
                SIVUtils.ingestMediaActionStatus(IngestEventTypes.delete, IngestEventSubTypes.success, notify, format);
        } else {
            if (currentAsset)
                SIVUtils.ingestMediaActionStatus(IngestEventTypes.delete, IngestEventSubTypes.error, notify, format);
        }
    }

    const handleDelete = async (): Promise<void> => {
        try {
            SIVUtils.ingestMediaAction(IngestEventSubTypes.delete, notify);
            const response = await mediaFetchHookObj.deleteAsset(rootData[mediaFetchHookObj.storeKey].children[state.currentIndex]);
            const dirSize = rootData[mediaFetchHookObj.storeKey].children.length;

            let nextAssetIndex = state.currentIndex;
            if (state.currentIndex === dirSize - 1) {
                if (mediaFetchHookObj.hasMoreData()) {
                    dispatch({ type: SingleImageViewActionType.setNeedMoreData, payload: true });
                } else {
                    nextAssetIndex = state.currentIndex - 1;
                }
            }

            const isLastMedia = dirSize === 1;
            if (isLastMedia) dismissSIV();

            dispatch({ type: SingleImageViewActionType.setCurrentIndex, payload: nextAssetIndex });
            dispatch({ type: SingleImageViewActionType.setHasAssetUpdated, payload: true });
            handleDeleteNotify(response);
            isMediaOrganizerResetRequired.current = true;
        }
        catch (error) {
            handleDeleteNotify(false);
        }
    }

    const getAssetInfo = (asset: ELAdobeAsset): AssetMetaData => {
        const deserializedAsset = elDeserializeAsset(asset) as ELAdobeAsset;
        const mimeType = Utils.getAssetMimeType(deserializedAsset).toString();
        const infoPanelData: AssetMetaData = {
            name: deserializedAsset.name ?? "",
            deviceCreateDate: deserializedAsset.deviceCreateDate ?? "",
            size: deserializedAsset?.size,
            width: deserializedAsset?.width,
            length: deserializedAsset?.length,
            format: deserializedAsset?.format,
            class: deserializedAsset?.assetClass,
            mimeType: mimeType
        }
        return infoPanelData;
    }

    const populateEmbeddedData = (sivInfoPanelData: AssetMetaData, data: EmbeddedMetadataType): AssetMetaData => {
        sivInfoPanelData.deviceCreateDate = data[EmbeddedMetaDataProperties.EXIF_DATE_TIME_ORIGINAL] ?? sivInfoPanelData.deviceCreateDate ?? "";
        sivInfoPanelData.deviceMake = data[EmbeddedMetaDataProperties.TIFF_MAKE] ?? "";
        sivInfoPanelData.deviceModel = data[EmbeddedMetaDataProperties.TIFF_MODEL] ?? "";
        sivInfoPanelData.focalLength = data[EmbeddedMetaDataProperties.EXIF_FOCAL_LENGTH] ?? "";
        sivInfoPanelData.focalNumber = data[EmbeddedMetaDataProperties.EXIF_FOCAL_NUMBER] ?? "";
        sivInfoPanelData.exposureTime = data[EmbeddedMetaDataProperties.EXIF_EXPOSURE_TIME] ?? "";
        if (data[EmbeddedMetaDataProperties.EXIF_ISO_SPEED_RATINGS]) {
            sivInfoPanelData.isoSpeedRatings = data[EmbeddedMetaDataProperties.EXIF_ISO_SPEED_RATINGS]["@list"][0];
        }
        return sivInfoPanelData;
    }

    const prepareAndSetAssetInfoData = (): void => {
        const currentAsset = getCurrentAsset();
        if (!currentAsset)
            return;
        const deserializeCurrentAsset = elDeserializeAsset(currentAsset);
        if (Utils.isVideoMimeType(deserializeCurrentAsset) && !state.videoMetaData)
            return;
        let sivInfoPanelData = getAssetInfo(currentAsset);
        if (Utils.isVideoMimeType(deserializeCurrentAsset) && state.videoMetaData) {
            sivInfoPanelData.length = state.videoMetaData.dimensionsInfo?.height;
            sivInfoPanelData.width = state.videoMetaData.dimensionsInfo?.width;
        } else {
            sivInfoPanelData.length = sivInfoPanelData.length != null ? sivInfoPanelData.length : (sivInfoPanelData as Record<string, number>)[EmbeddedMetaDataProperties.IMAGE_LENGTH];
            sivInfoPanelData.width = sivInfoPanelData.width != null ? sivInfoPanelData.width : (sivInfoPanelData as Record<string, number>)[EmbeddedMetaDataProperties.IMAGE_WIDTH];
        }
        mediaFetchHookObj.fetchAssetEmbeddedData(currentAsset).then((embeddedData: EmbeddedMetadataType) => {
            sivInfoPanelData = { ...sivInfoPanelData, ...populateEmbeddedData(sivInfoPanelData, embeddedData) };
            setInfoPanelMetaData(sivInfoPanelData);
        }).catch((reason: any) => {
            setInfoPanelMetaData(sivInfoPanelData);
            Logger.log(LogLevel.ERROR, "SIV:InfoPanel: Some issue while fetching asset embedded data", reason);
        });
    }

    const handleInfoIconClick = (): void => {
        let isInfoPanelOpened;
        setShowInfoPanel(prevState => {
            isInfoPanelOpened = !prevState;
            return isInfoPanelOpened;
        });
        if (isInfoPanelOpened) {
            notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.siv, IngestEventTypes.open,
                    IngestEventSubTypes.infoPanel, isInfoPanelOpened)
            });
        }
        prepareAndSetAssetInfoData();
    }

    const handleInfoPanelCloseClick = (): void => {
        setShowInfoPanel(false);
        if (sivContainerRef.current)
            ((sivContainerRef.current) as HTMLDivElement).focus();
    }

    const handleShare = (): void => {
        notify({ type: SingleImageControllerActionType.sharePressed, payload: rootData[mediaFetchHookObj.storeKey].children[state.currentIndex] });
    }

    const handleEdit = (): void => {
        const editPressedAction = {
            type: SingleImageControllerActionType.editPressed,
            payload: getCurrentAsset()
        };
        notify(editPressedAction);
    }

    const createShareQR = (): void => {
        const assetId = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_MEDIA_ID_PARAM);
        if (assetId) {
            const payload: ELQRSharePayload = {
                assetsToShare: [assetId],
                category: ShareTypeCategory.media,
                source: WorkflowsName.singleImageView
            };
            props.shareOptionsController.notify({ type: ShareOptionsActionType.createShareQR, payload: payload });
        }
    }

    const handlePopoverButtonPress = (): void => {
        createShareQR();

        const ingestObject = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utility, IngestEventSubTypes.click, IngestEventTypes.share);
        ingestObject[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.siv;
        props.controller.notify({ type: WorkflowActionType.ingest, payload: ingestObject });
    }

    const actionButtonPress = (): void => {
        const ingestObject = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utility, IngestEventSubTypes.click, IngestEventTypes.delete);
        ingestObject[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.siv;
        props.controller.notify({ type: WorkflowActionType.ingest, payload: ingestObject });
    }

    const handleReportAbuse = (): void => {
        // Need to handle it later
        SIVUtils.ingestMediaAction(IngestEventSubTypes.reportAbuse, notify);
    }

    const addEditButtonIfFeatureEnabled = (iconActionMap: Map<IconType, React.ReactElement>): void => {
        if (FeaturesManager.getInstance().IsFeatureActive(FeatureName.eAdjustments)) {
            iconActionMap.set(IconType.edit, <ELActionButton key={IconType.edit} elIcon={editIcon} tooltip={intlHandler.formatMessage("edit-action")}
                onPress={handleEdit} variant="secondary" dataTestId="editPanelIcon" />);
        }
    }

    const iconActionMap = new Map<IconType, React.ReactElement>();
    const downloadIcon = (<ELIcon iconkey={IconType.download} fill={"#fff"} className="el-action-button__icon" />);
    const infoIcon = (<ELIcon iconkey={IconType.info} fill={"#fff"} className="el-action-button__icon" />);
    const closeIcon = (<ELIcon iconkey={IconType.close} fill={"#fff"} className="el-action-button__icon" />);
    const leftArrowIcon = (<ELIcon iconkey={IconType.chevronLeftIcon} fill={"#fff"} className="el-action-button__icon" />);
    const rightArrowIcon = (<ELIcon iconkey={IconType.chevronRightIcon} fill={"#fff"} className="el-action-button__icon" />);
    const editIcon = (<ELIcon iconkey={IconType.edit} fill={"#fff"} className="el-action-button__icon" />);

    iconActionMap.set(IconType.download, <ELActionButton key={IconType.download} tooltip={intlHandler.formatMessage("download")}
        variant="secondary" elIcon={downloadIcon} onPress={handleDownload} isHidden={state.isFileNotDownloadable} dataTestId="download" />);
    iconActionMap.set(IconType.share, <ELShareOptionsPopover key="sivShareDialog" shareOptions={props.shareOptionsController}
        actionButtonVariant="secondary" sharePressCallback={handleShare}
        isMobile={isMobile as boolean} positionParams={{ placement: "bottom", crossOffset: 15, offset: 10 }}
        popoverButtonPress={handlePopoverButtonPress} showQRView={true} />);
    iconActionMap.set(IconType.delete, <ELDeleteDialog key="sivDeleteDialog" isMobilePortraitMode={isMobilePortraitMode as boolean} actionButtonVariant="secondary"
        selectedItemCount={1} deletePressCallback={handleDelete} actionButtonPressCallback={actionButtonPress} />); //1 asset would be deleted
    iconActionMap.set(IconType.reportAbuse, <ELReportAbuse key="sivReportAbuseDialog" reportAbuseSubmitCallback={handleReportAbuse} assetID={getCurrentAssetId()} actionButtonVariant="secondary" />);
    iconActionMap.set(IconType.info, <ELActionButton key={IconType.info} elIcon={infoIcon} tooltip={intlHandler.formatMessage("show-info")}
        onPress={handleInfoIconClick} variant="secondary" dataTestId="infoPanelIcon" />);
    addEditButtonIfFeatureEnabled(iconActionMap);
    const handleRightArrowClick = (): void => {
        notify({ type: SingleImageControllerActionType.rightArrowPressed });
    }

    const handleLeftArrowClick = (): void => {
        notify({ type: SingleImageControllerActionType.leftArrowPressed });
    }

    const handleEscape = (): void => {
        SIVUtils.ingestMediaAction(IngestEventSubTypes.close, notify);
        notify({ type: SingleImageControllerActionType.exit });
    }

    const dismissSIV = (): void => {
        handleEscape();
    }

    const getResource = (): React.ReactElement => {
        if (renditionState === RenditionState.isFetching) {
            return <ProgressCircle aria-label={intlHandler.formatMessage("loading")} isIndeterminate size="L" />;
        } else {
            if (renditionState === RenditionState.inProcessing) {
                return <ELProgressCircle aria-label={intlHandler.formatMessage("processing")} isIndeterminate size="L"
                    label={intlHandler.formatMessage("media-processing")} />;
            } else if (state.videoData !== undefined) {
                Logger.log(LogLevel.DEBUG, "imgData", state.imgData, "vidData", state.videoData);
                return <video crossOrigin="anonymous" id="siv-tag" ref={mediaRef} controls poster={state.imgData} src={state.videoData}
                    disablePictureInPicture controlsList="nodownload noplaybackrate" />;
            }
            else if (state.imgData === ERROR_THUMBDATA) {
                return <ELIcon className="siv-default-thumb" iconkey={IconType.staticImgThumb} />;
            } else if (state.imgData !== "") {
                return <img crossOrigin="anonymous" data-testid="siv-img" onContextMenu={(e) => e.preventDefault()} id="siv-tag" ref={mediaRef} src={state.imgData}
                    alt={getCurrentAssetName()} />;
            }
            return <ProgressCircle aria-label={intlHandler.formatMessage("loading")} isIndeterminate size="L" />;
        }
    }

    const getActionButtons = (): any => {
        const arr = state.ctaButtons.map((ele): React.ReactElement => {
            let button: ReactElement = <></>;
            if (iconActionMap.has(ele)) {
                button = iconActionMap.get(ele) as ReactElement;
            }
            return button;
        });
        return arr;
    }

    const getArrowTopPos = (): number => {
        return (viewPortHeight) / 2;
    }

    const showSidebarPanel = (): React.ReactElement => {
        if (showInfoPanel) {
            return (<ELSidebarPanelView infoPanelData={infoPanelData} handleInfoPanelCloseIconClick={handleInfoPanelCloseClick} />);
        } else {
            return <></>;
        }
    }

    const onClose = (): void => {
        /**
         * HACK_FIX - NEEDS_REVISIT for https://jira.corp.adobe.com/browse/EO-4203928
         * Hunch: In case of mobile since on clicking close button our touch remains over profile icon,
         *        utilitynav component(adobe thirdparty) trigger show flyout event.
         * Delaying SIV workflow dismissal, makes sure utilitynav component doesn't capture touch event
         */
        setTimeout(() => {
            handleEscape();
        }, 100);
    }

    const openDeeplink = (): void => {
        props.controller.notify({ type: SingleImageControllerActionType.openDeeplink, payload: getCurrentAssetId() });
    }

    const updateOpenInDesktopSupported = (): void => {
        const currentAsset = getCurrentAsset();
        if (currentAsset && Utils.isImageMimeType(elDeserializeAsset(currentAsset)))
            setIsOpenInDesktopSupported(true);
        else
            setIsOpenInDesktopSupported(false);
    }

    return (
        <>
            <div tabIndex={0} id="siv-parent-container" ref={sivContainerRef}>
                <Flex direction="column" id={showInfoPanel ? "siv-container-with-info-panel" : "siv-container"}>
                    <Flex direction="row" justifyContent="center" alignItems="center" >
                        <div id="siv-media-container">
                            {getResource()}
                        </div>
                    </Flex>
                    <Flex UNSAFE_className="siv-left-button" position="fixed" left="size-10" top={getArrowTopPos()} >
                        <ELActionButton elIcon={leftArrowIcon} isHidden={!state.showLeftArrow} transition="smooth" dataTestId="leftArrow"
                            onPress={handleLeftArrowClick} variant="secondary" />
                    </Flex>
                    <Flex UNSAFE_className="siv-right-button" position="absolute" right="size-10" top={getArrowTopPos()} >
                        <ELActionButton elIcon={rightArrowIcon} isHidden={!state.showRightArrow} transition="smooth" dataTestId="rightArrow"
                            onPress={handleRightArrowClick} variant="secondary" />
                    </Flex>
                </Flex>
                <Flex id={showInfoPanel ? "siv-menubar-with-info-panel" : "siv-menubar"} direction="row" gap="0.93rem">
                    {isOpenInDesktopSupported && <Flex>
                        <ELOpenInDesktopButton onPress={() => openDeeplink()} size="S"></ELOpenInDesktopButton>
                    </Flex>}
                    {getActionButtons()}
                    <Flex UNSAFE_className="siv-close-button">
                        <ELActionButton elIcon={closeIcon} tooltip={intlHandler.formatMessage("close")} dataTestId="close"
                            onPress={onClose} variant="secondary" />
                    </Flex>
                </Flex>
                <Flex id="info-panel">
                    {showSidebarPanel()}
                </Flex>
            </div>
            <div id="open-deeplink-container" data-testid="open-deeplink-container" />
        </>
    );
}
