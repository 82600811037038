/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';

//Application Specific
import Logger, { LogLevel } from '../../../../utils/Logger';
import IViewController, { ControllerAction } from '../../../IViewController';
import { CollageTemplateData } from '../../../../common/interfaces/creations/ElementsContentTypes';
import { ViewAction } from '../../../IBaseController';
import BaseContentService, { ContentType } from '../../../../services/ElementsServices/BaseContentService';
import ITemplateViewController from '../../../ITemplateViewController';
import { ELCollageLayoutControllerActions } from '../../../../common/interfaces/creations/collage/ELCollageLayoutTypes';
import { ELCollageLayoutViewAction } from '../../../../common/interfaces/creations/ELCollageTypes';

export default class ELCollageLayout extends ITemplateViewController {
    private _contentService: BaseContentService;
    private _templateList: CollageTemplateData[] = [];

    constructor(owner: IViewController, contentService: BaseContentService) {
        super(owner);
        this._contentService = contentService;
    }

    private async _fetchLayoutData(): Promise<void> {
        try {
            this._templateList = await this._contentService.getContent(ContentType.collageTemplates) as CollageTemplateData[];
            this._updateLayoutList();
        } catch (error) {
            Logger.log(LogLevel.WARN, "CollageLayout:_fetchLayoutList: ", error);
        }
    }

    private _updateLayoutList(): void {
        if (this.viewDispatcher) {
            this.viewDispatcher({ type: ELCollageLayoutViewAction.layoutData, payload: this._templateList });
        }
    }

    async getTemplateData(): Promise<CollageTemplateData[]> {
        if (this._templateList.length === 0) {
            await this._fetchLayoutData();
        }
        return this._templateList;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._updateLayoutList();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELCollageLayoutControllerActions.templateChanged:
            case ELCollageLayoutControllerActions.shuffle: {
                this._owner.notify(action);
                handled = true;
                break;
            }
        }

        return handled;
    }
}
