/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { useEffect, useRef, useState } from "react";
import { ELAdobeAsset } from "../../common/interfaces/storage/AssetTypes";

import Logger, { LogLevel } from "../Logger";
import Utils from "../Utils";

interface CanvasDataURLProps {
    elAsset: ELAdobeAsset,
    thumbData: string
}

export interface CanvasDataURL {
    previewReady: boolean,
    dataUrl: string
}

const useCanvasDataURL = (props: CanvasDataURLProps): CanvasDataURL => {
    // Later on we can add here other checks
    const shouldDraw = useRef(Utils.isGifMimeType(props.elAsset));
    const [previewReady, setPreviewReady] = useState(false);
    const [sDataUrl, setDataUrl] = useState("");

    const canvasElement = useRef<HTMLCanvasElement | null>(null);
    const setCanvasElement = (c: HTMLCanvasElement): void => {
        canvasElement.current = c;
    }
    const imgElement = useRef<HTMLImageElement | null>(null);
    const setImgElement = (i: HTMLImageElement): void => {
        imgElement.current = i;
    }
    const [isImgElementLoaded, setIsImgElementLoaded] = useState(false);
    const setImageElementReady = (): void => setIsImgElementLoaded(true);

    useEffect(() => {
        if (!shouldDraw.current) {
            return;
        }
        const canvasElement = document.createElement("canvas");
        const imgElement = document.createElement("img");
        canvasElement.setAttribute("class", "no-display");
        imgElement.setAttribute("class", "no-display");
        imgElement.addEventListener("load", setImageElementReady);
        imgElement.setAttribute("src", props.thumbData);
        setCanvasElement(canvasElement);
        setImgElement(imgElement);
        return () => {
            imgElement.removeEventListener("load", setImageElementReady)
            canvasElement.remove();
            imgElement.remove();
        }
    }, [props.thumbData]);

    useEffect(() => {
        Logger.log(LogLevel.DEBUG, "[useCanvasDataURL][useEffect]: Trying to generate frame");
        if (previewReady || !shouldDraw.current || props.thumbData?.length === 0) { return; }
        const canvas = canvasElement.current;
        if (!canvas) { return; }
        const img = imgElement.current;
        if (!img || img.width === 0 || img.height === 0) { return; }

        Logger.log(LogLevel.DEBUG, `[useCanvasDataURL][useEffect]: width ${img.width} height:${img.height}`);
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.drawImage(img, 0, 0, img.width, img.height);
            setDataUrl(canvas.toDataURL("image/jpeg"));
            setPreviewReady(true);
        } else {
            // error in rendering img
            Logger.log(LogLevel.WARN, "[useCanvasDataURL][useEffect]: Error in getting context");
        }
    }, [isImgElementLoaded]);

    return {
        previewReady: previewReady,
        dataUrl: sDataUrl
    };
};

export default useCanvasDataURL;