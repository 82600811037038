/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";

//Adobe internal
import { KeyboardEvent } from '@react-types/shared';

//Application Specific
import ModalWorkflowWraper from "./ModalWorkflowWrapper";
import ELDialog from "../../../../view/components/organism/el-dialog/ELDialog";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import { WorkspaceActionType } from "../../../IBaseWorkspace";

import "./ModalWorkflowWrapper.scss";

interface ModalWorkflowWrapperViewProps {
    controller: ModalWorkflowWraper,
    overlayDiv: HTMLDivElement,
    dialogClass?: string
}

export enum ModalWorkflowWrapperViewActionType {
    keyPressed = "KEY_PRESSED"
}

const ModalWorkflowWrapperView = (props: ModalWorkflowWrapperViewProps): React.ReactElement => {
    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);


    return (
        <>
            <ViewportProvider>
                <ELDialog overlayDiv={props.overlayDiv} UNSAFE_className={props.dialogClass}
                    handleEscape={() => props.controller.notify({ type: WorkspaceActionType.endWorkflow })}
                    onKeyDown={(e: KeyboardEvent) => props.controller.notify(
                        {
                            type: ModalWorkflowWrapperViewActionType.keyPressed,
                            payload: e
                        })}>
                    <div id="modal-wrapper-app-container" />
                </ELDialog>
            </ViewportProvider>
            <div id="modal-wrapper-modal-container" />
        </>
    )
};

export default ModalWorkflowWrapperView;
