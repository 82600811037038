/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import SelectedMediaListAction, { SelectedMediaListPayload } from "../actions/selectedMediaListActions";
import IAction from "../actions/IAction";

const updateSelectedMediaList = (state: SelectedMediaListPayload = [], action: IAction<SelectedMediaListPayload>):  SelectedMediaListPayload => {
    const data = action.payload;
    if(data){
        return data;
    }else{
        return state;
    }
}

const selectedMediaListReducer = (state: SelectedMediaListPayload = [] , action: IAction<SelectedMediaListPayload>): SelectedMediaListPayload => {
    switch (action.type) {
        case SelectedMediaListAction.UPDATE_SELECTED_MEDIA:
            return updateSelectedMediaList(state, action);
        default:
            return state;
    }
}

export default selectedMediaListReducer;