/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";
import { Text } from "@adobe/react-spectrum";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";

//Utils and constants
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../utils/IngestConstants";
import { IngestUtils } from "../../../utils/IngestUtils";

//Application specific
import IViewController from "../../../view/IViewController";
import ELFeatureBannerView from "../../../view/components/organism/el-feature-banner/ELFeatureBanner";
import SlideshowPreview from "../../../assets/home/Home-animation.mp4";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import IMS from "../../../services/IMS";
import { WorkflowActionType } from "../../IWorkflow";

import "./NoAccessUserHomeView.scss";

interface NoAccessUserHomeViewProps {
    controller: IViewController
}

export const NoAccessUserHomeView = (props: NoAccessUserHomeViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    useEffect(() => {
        props.controller.initialize();
        props.controller.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.noAccessUserHome, IngestEventTypes.render, IngestEventSubTypes.success, true)
        });
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <Flex UNSAFE_className="noaccess-feature-cta-box">
            <ELFeatureBannerView
                previewSrc={SlideshowPreview}
                title={intlHandler.formatMessage("create-and-share")}
                description={intlHandler.formatMessage("import-create-desc")}
            >
                <Text UNSAFE_className="noaccess-feature-banner-box__hello">{intlHandler.formatMessage("hello")} {IMS.getInstance().getUserName()}</Text>
                <Text UNSAFE_className="noaccess-user-do-not-have-access">{intlHandler.formatMessage("user-do-not-have-access-to-elements-web")}</Text>
            </ELFeatureBannerView>
        </Flex>
    )
}
