/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import axios from "axios";

//Application Specific
import { ELAdobeAsset, elDeserializeAsset } from "../../../common/interfaces/storage/AssetTypes";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import CreationUtils from "../../../workspaces/creations/utils/CreationUtils";
import IDocumentDataResolver from "./IDocumentDataResolver";
import store from "../../../stores/store";
import FullResMediaAction from "../../../stores/actions/FullResMediaAction";
import ResizedDocAction from "../../../stores/actions/ResizedDocAction";
import { ELRenditionType } from "@elements/elementswebcommon";
import ImageUtils from "../../../utils/ImageUtils";

export enum DocumentDataType {
    fullRes = "FULL_RES",
    rendition = "RENDITION"
}

export default class ELAdobeAssetDataResolver extends IDocumentDataResolver<ELAdobeAsset, DocumentDataType> {
    private readonly _minimumRendtionSizeToUpdateStore = 1024;

    private async _getFullResAssetData(asset: ELAdobeAsset, getType: DocumentDataType, size?: number): Promise<string> {
        try {
            if (getType === DocumentDataType.rendition) {
                const response = await CreationUtils.getCreationRendition(asset, ELRenditionType.IMAGE_PNG, "arraybuffer", size);
                const sharedArrayImgData = response.result as SharedArrayBuffer;
                const objectURL = URL.createObjectURL(new Blob([sharedArrayImgData]));
                return objectURL;
            } else {
                const downloadURI = await AssetStorageUtils.getDownloadURI(elDeserializeAsset(asset));

                const response = await axios.get(downloadURI, {
                    responseType: "arraybuffer"
                });

                const sharedArrayImgData = response.data as SharedArrayBuffer;
                const objectURL = URL.createObjectURL(new Blob([sharedArrayImgData]));
                return objectURL;
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELAdobeAssetDataResolver:_getFullResAssetData: ", "couldn't fetch full res asset data", error);
            return Promise.reject();
        }
    }

    private _getAssetDataFromStore(assetId?: string, documentDataType?: DocumentDataType): string | undefined {
        let mediaReducer = store.getState().fullResMediaReducer;
        if (documentDataType === DocumentDataType.rendition) {
            mediaReducer = store.getState().resizedDocReducer; //REVISIT - clear this store data
        }

        const docData = mediaReducer.filter((docData) => {
            if (docData.assetId === assetId)
                return true;
            return false;
        })[0];

        let objectURL: string | undefined = undefined;
        if (docData) {
            objectURL = docData.objectURL;
        }
        return objectURL;
    }

    private _updateStore(url: string, assetId?: string, documentDataType?: DocumentDataType, size?: number): void {
        if (assetId) {
            if (documentDataType === DocumentDataType.rendition && (size && this._minimumRendtionSizeToUpdateStore >= size)) {
                store.dispatch(ResizedDocAction.updateData({ assetId: assetId, objectURL: url }));
            } else {
                store.dispatch(FullResMediaAction.updateData({ assetId: assetId, objectURL: url }));
            }
        }
    }

    private async _getAsLocalAssetData(asset: ELAdobeAsset, getType: DocumentDataType, size?: number): Promise<string | null> {
        if(AssetStorageUtils.isLocalAsset(asset) && asset.url) {
            if(getType === DocumentDataType.fullRes) {
                return asset.url;
            } else if(getType === DocumentDataType.rendition && size) {
                const resizedURL = await ImageUtils.resizeImageFromURL(asset.url, size);
                return resizedURL;
            } else {
                return asset.url;
            }
        }

        return null;
    }

    async getData(asset: ELAdobeAsset, getType = DocumentDataType.fullRes, size?: number): Promise<string> {
        return this._getFullResAssetData(asset, getType, size);
    }

    async getDataAndUpdateStore(asset: ELAdobeAsset, getType = DocumentDataType.fullRes, size?: number): Promise<string> {
        const localAssetURL = await this._getAsLocalAssetData(asset, getType, size);
        
        if(localAssetURL) {
            return localAssetURL;
        }

        const url: string | undefined = this._getAssetDataFromStore(asset.assetId, getType);

        if (!url) {
            const assetURL = await this.getData(asset, getType, size);
            this._updateStore(assetURL, asset.assetId, getType, size);
            return assetURL;
        }

        return url;
    }
}