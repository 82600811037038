/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import _ from "lodash";

//Application specific
import { Routes } from "../../../../../app/AppRoute";
import { CreationsMode, CreationsStatusPayload, CreationsURLParams } from "../../../../../common/interfaces/creations/CreationTypes";
import { GIF_FILE_FORMAT, PSDC_FILE_FORMAT, PSD_FILE_FORMATS } from "../../../../../common/interfaces/storage/FileTypes";
import {
    FilterMediaData, FilterMediaUiProp, GridMediaType, MediaGridConfig,
    MediaGridSortBy
} from "../../../../../stores/actions/mediaGridConfigActions";
import { GRID_CONFIG_KEY } from "../../../../../stores/reducers/mediaGridConfigReducer";
import store from "../../../../../stores/store";
import { WorkspaceAction, WorkspacePayload } from "../../../../IBaseWorkspace";
import { WorkflowsName } from "../../../../IWorkflow";
import { ELAdobeAsset } from "@elements/elementswebcommon";
import { AssetStorageUtils } from "../../../../../utils/AssetStorageUtils";
import CreationUtils from "../../../utils/CreationUtils";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import Utils from "../../../../../utils/Utils";

class PeekThroughUtils {

    /**
     * @returns MediaGridConfig for peekThrough workflow
     **/
    static getPeekThroughMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);
        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageOnly;
        const filterData: FilterMediaData = {
            format: [PSDC_FILE_FORMAT, GIF_FILE_FORMAT, ...PSD_FILE_FORMATS],
            uiProp: FilterMediaUiProp.hide
        }
        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 1;
        mediaGridConfig.maxImageCount = 1;
        mediaGridConfig.minImageCount = 1;
        mediaGridConfig.maxVideoCount = 0;
        mediaGridConfig.maxTotalMediaSize = 400; //in MB
        mediaGridConfig.maxVideoLength = 0;
        mediaGridConfig.selectUploadedMedia = true;
        return mediaGridConfig;
    }

    /**
     * @returns boolean - true if collage url is present
     */
    static hasPeekThroughUrl(): boolean {
        const workflow = Utils.getLinkParamValue(window.location.href, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.peekThrough.toLowerCase() === workflow.toLowerCase());
    }

    /**
    * @returns string - history state based on projectId
    */
    static getPeekThroughHistoryState(projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + WorkflowsName.peekThrough.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    /**
     * @returns string - collage id from window href
     */
    static getPeekThroughIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    /**
     * @returns WorkspacePayload
     */
    static getPeekThroughPayload(): WorkspacePayload {
        const projectId = PeekThroughUtils.getPeekThroughIdFromHref();
        let mode = CreationsMode.render;
        let payload: CreationsStatusPayload | ELAdobeAsset[] | null = { projectId: projectId };

        if (projectId === Utils.getNilUUID()) {
            mode = CreationsMode.create
            payload = store.getState().selectedMediaListReducer;
        }

        const workspacePayload = {
            startWorkflow: WorkflowsName.peekThrough,
            initMode: mode,
            payload: payload
        };

        return workspacePayload;
    }

    static canStartPeekThrough(selectedAssets: ELAdobeAsset[]): boolean {
        const minImageCount = PeekThroughUtils.getPeekThroughMediaGridConfig().minImageCount;
        if (minImageCount && selectedAssets.length >= minImageCount)
            return true;
        return false;
    }

    static isMaxImageLimitExceeded(selectedAssets: ELAdobeAsset[]): boolean {
        const maxImageCount = PeekThroughUtils.getPeekThroughMediaGridConfig().maxImageCount;
        if ((maxImageCount !== undefined) && selectedAssets.length > maxImageCount)
            return true;
        return false;
    }

    static isOpeningExistingPeekThrough(workspaceAction: WorkspaceAction): boolean {
        return workspaceAction.initMode === CreationsMode.render;
    }

    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = AssetStorageUtils.filterRemoveVideoAssets(assets);
        filteredAssets = AssetStorageUtils.filterRemoveGifAssets(filteredAssets);
        filteredAssets = CreationUtils.filterImagesByCount(filteredAssets, mediaGridConfig);

        Logger.log(LogLevel.INFO, "PeekThroughUtils - getFilteredAssetList: ", filteredAssets);
        return filteredAssets;
    }

}

export default PeekThroughUtils;