/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

//Adobe internal
import { Flex, Slider, Text } from "@adobe/react-spectrum";
import { usePress } from "@react-aria/interactions";

//Application Specific
import { ELPhotoTextEditPanel } from "./ELPhotoTextEditPanel";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import { COLOR_CHANGE_THROTTLE, ELPhotoTextWorkflowControllerActions } from "../../../../common/interfaces/creations/ELPhotoTextWorkflowTypes";
import { ELFontPickerView } from "../../organism/el-font-picker/ELFontPickerView";
import { FontIdentifier } from "@adobe-fonts/fontpicker";
import ELColorPickerView from "../../organism/el-color-picker/ELColorPickerView";
import { ELTextAlign, ELTextSpread } from "../../../../common/interfaces/stage/StageTypes";
import TextEditAction from "../../../../stores/actions/TextEditAction";
import store, { RootState } from "../../../../stores/store";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import ELComboBox, { ELComboBoxOptions } from "../../organism/el-combo-box/ELComboBox";
import ELNumberField from "../../organism/el-number-field/ELNumberField";

import "./ELPhotoTextEditPanel.scss";

interface TextSpreadCardProps {
    spreadType: ELTextSpread,
    onPress: (spreadType: ELTextSpread) => void
}

const TextSpreadCard = (props: TextSpreadCardProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const { pressProps } = usePress({
        onPress: () => { props.onPress(props.spreadType); }
    });

    const getIconKey = (): IconType => {
        if (props.spreadType === ELTextSpread.fit) {
            return IconType.textFit;
        } else {
            return IconType.textFill;
        }
    }

    const getCardText = (): string => {
        if (props.spreadType === ELTextSpread.fit) {
            return intlHandler.formatMessage("fit");
        } else {
            return intlHandler.formatMessage("fill");
        }
    }

    return (
        <Flex direction={"column"}>
            <div className="photo-text-edit-panel-font-picker-spread" {...pressProps}>
                <ELIcon iconkey={getIconKey()} />
            </div>
            <Text UNSAFE_className="photo-text-edit-panel-font-picker-spread-text">{getCardText()}</Text>
        </Flex>
    )
}

interface ELPhotoTextEditPanelViewProps {
    controller: ELPhotoTextEditPanel
}

const ELPhotoTextEditPanelView = (props: ELPhotoTextEditPanelViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const dispatch = useDispatch();

    const getStrokeWidthOptions = (): ELComboBoxOptions[] => {
        const strokeSizes = [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 128, 256, 360];
        const strokeWidthChildren = strokeSizes.map((size) => { return { "keyName": size.toString() }; });
        const strokeWidthOptions = [{ name: "Stroke Width Options", children: strokeWidthChildren }];
        return strokeWidthOptions;
    }

    const textEditData = useSelector((state: RootState) => state.textEditReducer);
    const documentData = useSelector((state: RootState) => state.docStateReducer);

    const onFontChange = (psName: FontIdentifier): void => {
        dispatch(TextEditAction.updatePostscriptName(psName.postscriptName));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.fontChange, payload: psName });
    }

    const onFontPreviewChange = async (psName: FontIdentifier): Promise<void> => {
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.fontPreviewChange, payload: psName });
    }

    const onUnderlineChange = (): void => {
        const underline = store.getState().textEditReducer.underline;
        dispatch(TextEditAction.updateUnderline(underline === false));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.underlineChange, payload: underline === false });
    }

    const onFontSizeChange = (fontSize: number): void => {
        dispatch(TextEditAction.updateFontSize(fontSize));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.fontSizeChange, payload: fontSize });
    }

    const textAlignChange = (textAlign: ELTextAlign): void => {
        dispatch(TextEditAction.updateTextAlign(textAlign));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.textAlignmentChange, payload: textAlign });
    }

    const handleStrokeWidthChange = (key: React.Key): void => {
        dispatch(TextEditAction.updateStrokeWidth(parseInt(key as string)));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.strokeWidthChange, payload: key });
    }

    const handleStrokeColorChange = _.debounce((color: string): void => {
        dispatch(TextEditAction.updateStrokeColor(color));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.strokeColorChange, payload: color });
    }, COLOR_CHANGE_THROTTLE);

    const handleShadowBlurChange = (blur: number): void => {
        dispatch(TextEditAction.updateShadowBlur(blur));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.shadowBlurChange, payload: blur });
    }

    const handleSpreadChange = (spreadType: ELTextSpread): void => {
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.spreadChange, payload: spreadType });
    }

    const getPanelHeader = (): React.ReactElement => {
        return (
            <Flex UNSAFE_className="photo-text-edit-panel-header">
                <div>{intlHandler.formatMessage("text")}</div>
                <div className={"photo-text-edit-panel-revert-box"} title={intlHandler.formatMessage("revert")}>
                    <ELCustomIcon variant="independent" iconkey={IconType.revert} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={() => props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.revert })}
                        isDisabled={documentData.isDirty === DocumentDirty.NON_DIRTY}>
                    </ELCustomIcon>
                </div>
            </Flex>
        )
    }

    const getPanelFontPicker = (): React.ReactElement => {
        return (
            <Flex UNSAFE_className="photo-text-edit-panel-font-picker">
                <Text UNSAFE_className="photo-text-edit-panel-font-picker-text">{intlHandler.formatMessage("font")}</Text>
                <ELFontPickerView onChange={onFontChange} onUnderlineChange={onUnderlineChange} onPreview={onFontPreviewChange} />
            </Flex>
        )
    }

    const getPanelTextAlignment = (): React.ReactElement => {
        return (
            <Flex direction={"row"} gap={"1rem"}>
                <div className={textEditData.textAlign === ELTextAlign.left ? "photo-text-edit-panel-alignment-selected" : "photo-text-edit-panel-alignment"}>
                    <ELCustomIcon variant="independent" iconkey={IconType.textAlignmentLeft} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={() => textAlignChange(ELTextAlign.left)} />
                </div>
                <div className={textEditData.textAlign === ELTextAlign.center ? "photo-text-edit-panel-alignment-selected" : "photo-text-edit-panel-alignment"}>
                    <ELCustomIcon className={"photo-text-edit-panel-alignment"} variant="independent" iconkey={IconType.textAlignmentCenter} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={() => textAlignChange(ELTextAlign.center)} />
                </div>
                <div className={textEditData.textAlign === ELTextAlign.right ? "photo-text-edit-panel-alignment-selected" : "photo-text-edit-panel-alignment"}>
                    <ELCustomIcon className={"photo-text-edit-panel-alignment"} variant="independent" iconkey={IconType.textAlignmentRight} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={() => textAlignChange(ELTextAlign.right)} />
                </div>
            </Flex>
        )
    }

    const getPanelFontSize = (): React.ReactElement => {
        return (
            <Flex direction={"column"} gap={"0.5rem"}>
                <Flex direction={"row"} gap={"1rem"} justifyContent={"space-between"} width={"90%"}>
                    <Text UNSAFE_className="photo-text-edit-panel-font-picker-text">{intlHandler.formatMessage("size")}</Text>
                    <ELNumberField value={textEditData.fontSize} minValue={1} maxValue={2048} onChange={onFontSizeChange} />
                </Flex>
                <Slider
                    value={Math.min(textEditData.fontSize, 2048)}
                    onChange={onFontSizeChange}
                    width={"90%"}
                    isFilled={true}
                    minValue={1}
                    maxValue={2048}
                />
            </Flex>
        );
    }

    const getPanelStroke = (): React.ReactElement => {
        return (
            <Flex direction={"column"} gap={"0.5rem"}>
                <Text UNSAFE_className="photo-text-edit-panel-font-picker-text">{intlHandler.formatMessage("stroke")}</Text>
                <Flex direction={"row"} gap={"1rem"}>
                    <ELComboBox options={getStrokeWidthOptions()} defaultValue={textEditData.strokeWidth + "px"} inputValue={textEditData.strokeWidth + "px"} onSelectionChange={handleStrokeWidthChange} />
                    <Flex width={"3rem"}>
                        <ELColorPickerView color={textEditData.strokeColor} onChange={(e) => { handleStrokeColorChange(e.target.value); }} />
                    </Flex>
                </Flex>
            </Flex>
        );
    }

    const getPanelShadow = (): React.ReactElement => {
        return (
            <Flex direction={"column"} gap={"0.5rem"}>
                <Text UNSAFE_className="photo-text-edit-panel-font-picker-text">{intlHandler.formatMessage("shadow")}</Text>
                <Slider
                    value={textEditData.shadowBlur}
                    onChange={handleShadowBlurChange}
                    width={"90%"}
                    isFilled={true}
                />
            </Flex>
        );
    }

    const getPanelTextSpread = (): React.ReactElement => {
        return (
            <Flex direction={"column"} gap={"0.5rem"}>
                <Text UNSAFE_className="photo-text-edit-panel-font-picker-text">{intlHandler.formatMessage("spread")}</Text>
                <Flex direction={"row"} gap={"1rem"} justifyContent={"center"}>
                    <TextSpreadCard spreadType={ELTextSpread.fit} onPress={(spreadType) => handleSpreadChange(spreadType)} />
                    <TextSpreadCard spreadType={ELTextSpread.fill} onPress={(spreadType) => handleSpreadChange(spreadType)} />
                </Flex>
            </Flex>
        );
    }

    return (
        <div className="photo-text-edit-panel-box">
            {getPanelHeader()}
            <ELScrollPanel UNSAFE_className="photo-text-edit-panel-scroll" scrollY={true}>
                <Flex UNSAFE_className="photo-text-edit-panel-body">
                    {getPanelFontPicker()}
                    {getPanelFontSize()}
                    {getPanelTextAlignment()}
                    {getPanelStroke()}
                    {getPanelShadow()}
                    {getPanelTextSpread()}
                </Flex>
            </ELScrollPanel>
        </div >
    );
}

export { ELPhotoTextEditPanelView };