/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "../actions/IAction";
import { ELMediaGridToolbarPayload } from "../actions/mediaGridToolbarAction";
import { ELMediaImportAction, ELMediaGridToolbarControllerAction } from "../../view/components/molecules/el-media-grid-toolbar/ELMediaGridToolbar";

function getDefaultELMediaGridToolbarPayload(): ELMediaGridToolbarPayload {
    return {
        importInProgress: false,
        isFilterDisable: false,
        isImportProgressBarVisible: false
    }
}

const mediaGridToolbarStateReducer = (state: ELMediaGridToolbarPayload = getDefaultELMediaGridToolbarPayload(), action: IAction<ELMediaGridToolbarPayload>): ELMediaGridToolbarPayload => {
    switch (action.type) {
        case ELMediaImportAction.changeImportProgressState:
            {
                const payload = action.payload?.importInProgress as boolean;
                return {
                    ...state,
                    importInProgress: payload
                }
            }
        case ELMediaImportAction.changeImportProgressBarVisibility:
            {
                const payload = action.payload?.isImportProgressBarVisible as boolean;
                return {
                    ...state,
                    isImportProgressBarVisible: payload
                }
            }
        case ELMediaGridToolbarControllerAction.changeFilterEnableState:
            {
                const payload = action.payload?.isFilterDisable as boolean
                return {
                    ...state,
                    isFilterDisable: payload
                }
            }
        default:
            return state;
    }
}

export default mediaGridToolbarStateReducer;