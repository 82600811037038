/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "../actions/IAction";
import LayoutAction, { LayoutActionPayload } from "../actions/LayoutAction";

interface LayoutReducerData {
    selectedLayout: string,
    scale: number
}

const updateSelectedLayout = (state: LayoutReducerData, action: IAction<LayoutActionPayload>): LayoutReducerData => {
    const layoutId = action.payload as string;
    return {
        ...state,
        selectedLayout: layoutId
    }
}

const updateScale = (state: LayoutReducerData, action: IAction<LayoutActionPayload>): LayoutReducerData => {
    const scale = action.payload as number;
    if (scale >= 0) {
        return {
            ...state,
            scale: scale
        }
    }
    return state;
}

const layoutReducer = (state: LayoutReducerData = { selectedLayout: "", scale: 0 }, action: IAction<LayoutActionPayload>): LayoutReducerData => {
    switch (action.type) {
        case LayoutAction.UPDATE_SELECTED_LAYOUT:
            return updateSelectedLayout(state, action);
        case LayoutAction.UPDATE_SCALE:
            return updateScale(state, action);
        default:
            return state;
    }
}

export default layoutReducer;