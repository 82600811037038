/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { glMatrix, vec2 } from "gl-matrix";

//Application Specific
import ImageUtils from "../../utils/ImageUtils";
import IViewController from "./../../view/IViewController";
import IDoc from "../document/IDoc";
import { ELSize } from "../../common/interfaces/geometry/ELGeometry";
import { IStageConfig, STAGE_VIEWTYPE_ATTRIBUTE_KEY } from "../../common/interfaces/stage/StageTypes";
import { StageUtils } from "../../utils/stage/StageUtils";

const DEFAULT_MAX_WIDTH = 5400;
const DEFAULT_MAX_HEIGHT = 5400;

export default abstract class IStage extends IViewController {
    protected zoomFactors = [1, 1.5, 2, 3, 4, 5, 12.5, 25, 100 / 3, 50, (100 / 3) * 2, 100, 150, 200, 250, 300, 400, 600, 800];
    protected maxWidth = 0;
    protected maxHeight = 0;
    protected doc: IDoc;
    protected canvas!: HTMLCanvasElement;
    protected containerSize: vec2;
    protected transformScale: number;
    protected transformOffset: vec2;
    protected config: unknown;

    constructor(doc: IDoc) {
        super();

        glMatrix.setMatrixArrayType(Array);
        this.doc = doc;
        this.containerSize = vec2.create();
        this.transformOffset = vec2.create();
        this.transformScale = 1.0;
    }

    abstract updateTransform(): void;
    abstract setBackground(color: string): void;

    protected createAndGetStageContainer(container: HTMLElement): HTMLDivElement {
        const viewType = (this.config as IStageConfig).viewType;
        if (viewType) {
            let stageDocContainer = StageUtils.getStageContainer(container, STAGE_VIEWTYPE_ATTRIBUTE_KEY, viewType);
            if (!stageDocContainer) {
                stageDocContainer = StageUtils.createStageContainer(STAGE_VIEWTYPE_ATTRIBUTE_KEY, viewType);
                container.appendChild(stageDocContainer);
            }
            return stageDocContainer;
        } else if (container.children.length > 0) {
            return container.children[0] as HTMLDivElement;
        } else {
            const stageDocContainer = document.createElement("div");
            stageDocContainer.className = "stage-doc-container";
            container.appendChild(stageDocContainer);
            return stageDocContainer;
        }
    }

    async createView(container: HTMLElement): Promise<void> {
        super.createView(container);

        const stageDocContainer = this.createAndGetStageContainer(container);

        const size = await this.doc.getOriginalSize();
        this.canvas = ImageUtils.createHTMLCanvasElementForSize(size);
        this.canvas.id = "main-canvas";
        stageDocContainer.appendChild(this.canvas);

        this.containerSize[0] = container.clientWidth;
        this.containerSize[1] = container.clientHeight;
    }

    destroyView(): void {
        super.destroyView();

        if (this.container) {
            this.container.innerHTML = '';
        }
    }

    getMaxCanvasSizes(): number[] {
        if (this.maxWidth === 0 || this.maxHeight === 0) {
            const viewport = ImageUtils.getMaxViewportDimsWebGl();
            this.maxWidth = viewport ? Math.min(viewport[0], DEFAULT_MAX_WIDTH) : DEFAULT_MAX_WIDTH;
            this.maxHeight = viewport ? Math.min(viewport[1], DEFAULT_MAX_HEIGHT) : DEFAULT_MAX_HEIGHT;
        }
        return [this.maxWidth, this.maxHeight];
    }

    getCanvas(): HTMLCanvasElement | undefined {
        return this.canvas;
    }

    setCanvasDimension(size: ELSize): void {
        if (this.canvas) {
            this.canvas.width = size.width;
            this.canvas.height = size.height;
        }
    }

    set setContainerSize(containerSize: vec2) {
        this.containerSize = containerSize;
    }

    async ensure2DCanvas(): Promise<void> {
        if (this.canvas && this.canvas.getContext('2d') !== null) return;

        this.canvas = await this.createNewCanvas();
        this.canvas.getContext('2d');
    }

    async ensureWebGLCanvas(options?: WebGLContextAttributes): Promise<void> {
        if (!options && this.canvas && this.canvas.getContext('webgl') !== null) return;

        this.canvas = await this.createNewCanvas();
        this.canvas.getContext('webgl', options);
    }

    getCanvasScale(): number {
        return 1.0;
    }

    centerContent(): void {
        if (this.canvas && this.container) {
            this.containerSize[0] = this.container.clientWidth;
            this.containerSize[1] = this.container.clientHeight;

            this.transformOffset[0] = (this.container.clientWidth - this.canvas.width * this.getCanvasScale() * this.transformScale) / 2.0;
            this.transformOffset[1] = (this.container.clientHeight - this.canvas.height * this.getCanvasScale() * this.transformScale) / 2.0;

            this.updateTransform();
        }
    }

    setZoomFactor(zoom: number, containerOffset?: vec2): void {
        if (containerOffset) {
            const cw = containerOffset[0];
            const ch = containerOffset[1];
            const dx = cw + (zoom * (this.transformOffset[0] - cw)) / this.transformScale;
            const dy = ch + (zoom * (this.transformOffset[1] - ch)) / this.transformScale;
            this.transformOffset[0] = dx;
            this.transformOffset[1] = dy;
            this.transformScale = zoom;
            //this.checkScroll();
        } else {
            this.transformScale = zoom;
            this.centerContent();
        }
    }

    protected async createNewCanvas(): Promise<HTMLCanvasElement> {
        const newCanvas = document.createElement('canvas');
        newCanvas.id = 'main-canvas';
        newCanvas.className = 'main-canvas';

        const imageData = await this.doc.getImageData();
        newCanvas.width = imageData.width;
        newCanvas.height = imageData.height;

        const stageDocContainer = this.container?.firstChild;
        if (this.canvas && stageDocContainer?.contains(this.canvas)) {
            stageDocContainer?.replaceChild(newCanvas, this.canvas);
        } else {
            stageDocContainer?.appendChild(newCanvas);
        }

        return newCanvas;
    }
}
