/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { Asset, OutputType, SlideshowJobUtils, Version } from "@elements/elementswebcommon";
import { ELCreationsPathResolver } from "./ELCreationsPathResolver";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELAssetPathOrId } from "../../../../common/interfaces/storage/AssetTypes";

export class ELSlideshowPathResolver extends ELCreationsPathResolver {

    private async _getCreationFullAssetPathForV3OrAbove(): Promise<ELAssetPathOrId> {
        try {
            const requestObj = await this.getRequestObjectFromProjectData();
            const assetPath = SlideshowJobUtils.getOutputSlideShowPath(requestObj.operations[0], OutputType.full);

            if (!assetPath) {
                throw new Error("assetPath is undefined");
            }

            return { path: assetPath };
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELSlideshowPathResolver:_getCreationFullAssetPathForV3OrAbove failed with error " + error);
            return Promise.reject(error);
        }
    }

    async getCreationPreviewAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();
        switch (requestVersion) {
            case Version.V1:
                if (!this.creationsData.outputs || !this.creationsData.outputs.media) {
                    Logger.log(LogLevel.ERROR, "ELSlideshowPathResolver: getCreationPreviewAssetPathOrId: V1, not able to find outputs or media");
                    return Promise.reject();
                }
                return this.getAssetPathFromOutputInfo(this.creationsData.outputs.media);
            case Version.V2:
                if (!this.creationsData.outputs || !this.creationsData.outputs.preview) {
                    Logger.log(LogLevel.ERROR, "ELSlideshowPathResolver: getCreationPreviewAssetPathOrId: V2, not able to find outputs or preview");
                    return Promise.reject();
                }
                return this.getAssetPathFromOutputInfo(this.creationsData.outputs.preview);
            case Version.V3: {
                if (!this.creationsData.outputs || !this.creationsData.outputs.preview) {
                    Logger.log(LogLevel.ERROR, "ELSlideshowPathResolver: getCreationPreviewAssetPathOrId: V3, not able to find outputs or preview");
                    return Promise.reject();
                }
                return this.getAssetIdFromOutputInfo(this.creationsData.outputs.preview);
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELSlideshowPathResolver: getCreationPreviewAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationFullAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();

        switch (requestVersion) {
            case Version.V1:
            case Version.V2: {
                if (!this.creationsData.outputs || !this.creationsData.outputs.media) {
                    Logger.log(LogLevel.ERROR, "ELSlideshowPathResolver: getCreationPreviewAssetPathOrId: V1/V2, not able to find outputs or media");
                    return Promise.reject();
                }
                return this.getAssetPathFromOutputInfo(this.creationsData.outputs.media);
            }
            case Version.V3: {
                return this._getCreationFullAssetPathForV3OrAbove();
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELSlideshowPathResolver: getCreationFullAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationMasterAssetData(): Promise<Asset[]> {
        Logger.log(LogLevel.WARN, "ELSlideshowPathResolver:getPSDCreationAssetID: ", "Function not supported for Slideshow");
        throw new Error("Function not supported for Slideshow");
    }
}