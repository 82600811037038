/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CreationsData, CreationsProgressPayload, CreationsVideoExportPreset, CreationsVideoExportPresetPayload } from "../../common/interfaces/creations/CreationTypes";
import { CLOSED_PANEL_KEY } from "../../common/interfaces/creations/SlideshowTypes";
import CreationsAction, { CreationsActionPayloadUnion, CreationStatusPayload, CreationsThumb } from "../actions/CreationsAction";
import IAction from "../actions/IAction";
/**
 *  creationReducer: {                              ------------
 *      [creationId] : {                                        |
 *          status: boolean,                                    | ==> Store chop for this reducer
 *          thumbnailData: string,                              |
 *          appliedData?: CreationsData,                        |
 *          uiData?: CreationsData                              |
 *      },                                                      |
 *      activeSlideshowId?: string
 *  }                                               ------------
 *
 */

type CreationsReducerDataChop = Record<string, Pick<CreationsData, "status"> & { thumbnailData: string }
    & { appliedData?: CreationsData } & { uiData?: CreationsData } & { progress?: number }
    & { exportPreset?: CreationsVideoExportPreset }>

interface CreationsReducerData {
    data: CreationsReducerDataChop,
    activeSlideshowId?: string,
    panelSelectedKey: string
}

const initialData = {
    data: {},
    panelSelectedKey: CLOSED_PANEL_KEY
}

const updateThumbData = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const data = action.payload as CreationsThumb;
    if (data) {
        return {
            ...state,
            data: {
                ...state.data,
                [data.id]: {
                    ...state.data[data.id],
                    thumbnailData: data.objectURL
                }
            }
        };
    } else {
        return state;
    }
}

const updateStatus = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const data = action.payload as CreationStatusPayload;
    if (data) {
        return {
            ...state,
            data: {
                ...state.data,
                [data.id]: {
                    ...state.data[data.id],
                    status: data.status,
                }
            }
        }
    } else {
        return state;
    }
}

const updateAppliedData = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const data = action.payload as CreationsData;
    if (data) {
        return {
            ...state,
            data: {
                ...state.data,
                [data.id]: {
                    ...state.data[data.id],
                    appliedData: data
                }
            }
        }
    } else {
        return state;
    }
}

const updateUIData = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const data = action.payload as CreationsData;
    if (data) {
        return {
            ...state,
            data: {
                ...state.data,
                [data.id]: {
                    ...state.data[data.id],
                    uiData: data,
                }
            }
        }
    } else {
        return state;
    }
}

const resetCreationsData = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const id = action.payload as string;
    if (id) {
        return {
            ...state,
            data: {
                ...state.data,
                [id]: {
                    ...state.data[id],
                    uiData: undefined,
                    appliedData: undefined
                }
            },
            activeSlideshowId: undefined
        }
    } else {
        return state;
    }
}

const updateActiveSlideshowId = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const id = action.payload as string;
    if (id) {
        return {
            ...state,
            activeSlideshowId: id
        }
    } else {
        return state;
    }
}

const updateProgress = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const data = action.payload as CreationsProgressPayload;
    if (data) {
        return {
            ...state,
            data: {
                ...state.data,
                [data.projectId]: {
                    ...state.data[data.projectId],
                    progress: data.percent,
                }
            }
        }
    } else {
        return state;
    }
}

const updateSlideshowPanelSelectedKey = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const selectedKey = action.payload as string;
    return {
        ...state,
        panelSelectedKey: selectedKey
    }
}

const updateVideoExportSettings = (state: CreationsReducerData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    const data = action.payload as CreationsVideoExportPresetPayload;
    if (data) {
        return {
            ...state,
            data: {
                ...state.data,
                [data.projectId]: {
                    ...state.data[data.projectId],
                    exportPreset: data.exportPreset,
                }
            }
        }
    } else {
        return state;
    }
}

const creationsReducer = (state: CreationsReducerData = initialData, action: IAction<CreationsActionPayloadUnion>): CreationsReducerData => {
    switch (action.type) {
        case CreationsAction.UPDATE_THUMB:
            return updateThumbData(state, action);
        case CreationsAction.UPDATE_STATUS:
            return updateStatus(state, action);
        case CreationsAction.UPDATE_PROGRESS:
            return updateProgress(state, action);
        case CreationsAction.UPDATE_APPLIED_DATA:
            return updateAppliedData(state, action);
        case CreationsAction.UPDATE_UI_DATA:
            return updateUIData(state, action);
        case CreationsAction.UPDATE_ACTIVE_SLIDESHOW_ID:
            return updateActiveSlideshowId(state, action);
        case CreationsAction.RESET_CREATIONS_DATA:
            return resetCreationsData(state, action);
        case CreationsAction.UPDATE_SLIDESHOW_PANEL_SELECTED_KEY:
            return updateSlideshowPanelSelectedKey(state, action);
        case CreationsAction.UPDATE_SLIDESHOW_VIDEO_EXPORTSETTINGS:
            return updateVideoExportSettings(state, action);
        default:
            return state;
    }
}

export default creationsReducer;
