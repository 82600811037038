/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "./IAction";
import { ELMediaGridToolbarControllerAction, ELMediaImportAction } from "../../view/components/molecules/el-media-grid-toolbar/ELMediaGridToolbar";

export interface ELMediaGridToolbarPayload {
    importInProgress?: boolean,
    isFilterDisable?: boolean,
    isImportProgressBarVisible?: boolean
}

export interface ELMediaGridToolbarImportPayload {
    importInProgress: boolean;
}

export interface ELMediaGridToolbarFilterPayload {
    isFilterDisable: boolean;
}

export default class MediaGridToolbarAction {

    public static updateMediaGridToolbarImportState(payload: ELMediaGridToolbarImportPayload): IAction<ELMediaGridToolbarImportPayload> {
        return {
            type: ELMediaImportAction.changeImportProgressState,
            payload: payload
        };
    }

    public static updateImportProgressBarVisibility(payload: boolean): IAction<ELMediaGridToolbarPayload> {
        return {
            type: ELMediaImportAction.changeImportProgressBarVisibility,
            payload: { isImportProgressBarVisible: payload }
        };
    }

    public static updateMediaGridToolbarFilterState(payload: ELMediaGridToolbarFilterPayload): IAction<ELMediaGridToolbarFilterPayload> {
        return {
            type: ELMediaGridToolbarControllerAction.changeFilterEnableState,
            payload: payload
        }
    }
}