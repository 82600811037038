/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Adobe Internal
import { success, error, info, ToastOption, warning, addToast, Toast, removeToast } from "@react/react-spectrum/Toast";

//Application Specific
import Constants from "./Constants/Constants";

export const ToastUtils = {
    success(message: string, options: ToastOption = { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number }): void {
        success(message, options);
    },

    error(message: string, options: ToastOption = { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number }): void {
        error(message, options);
    },

    warning(message: string, options: ToastOption = { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number }): void {
        warning(message, options);
    },

    info(message: string, options: ToastOption = { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number }): void {
        info(message, options);
    },

    addToast(toast: Toast, timeout = Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number, container: React.ReactElement | undefined = undefined): void {
        addToast(toast, timeout, container);
    },

    removeToast(toast: Toast, container: React.ReactElement | undefined = undefined): void {
        removeToast(toast, container);
    }
}