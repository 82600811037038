/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import {
    PHOTOSHOP_ELEMENTS_APP_FULL_NAME,
    PREMIERE_ELEMENTS_APP_FULL_NAME,
} from "../Constants/Constants";
import { IconType } from "../../assets/IconConstants";
import { IntlHandler } from "../../modules/intlHandler/IntlHandler";
import { URLConstants } from "../Constants/URLConstants";
import { LocaleUtils } from "../LocaleUtils";

export interface elementsProductDataInterface {
    productName: string,
    productDescription: string,
    productIconKey: IconType,
    productSecondIconKey?: IconType,
    productPrice: string,
    productUpgradePrice: string,
    productAppLink: string,
    productBuyLink: string,
    productUpgradeLink: string,
}

export const getElementsProductsData = (): elementsProductDataInterface[] => {

    const intlHandler = IntlHandler.getInstance();

    const getPriceWithSymbol = (symbol: string, price: string, isCurrencySymbolInLeft: boolean): string => {
        if (isCurrencySymbolInLeft) {
            return symbol + price;
        } else return price + symbol;
    };

    const currencySymbol = LocaleUtils.LocaleSpecificData.currencySymbol;
    const isCurrencySymbolInLeft = LocaleUtils.LocaleSpecificData.isCurrencySymbolInLeft;
    const pseAppPrice = intlHandler.formatNumber(LocaleUtils.LocaleSpecificData.pseAppPrice);
    const pseAppUpgradePrice = intlHandler.formatNumber(LocaleUtils.LocaleSpecificData.pseAppUpgradePrice);
    const preAppPrice = intlHandler.formatNumber(LocaleUtils.LocaleSpecificData.preAppPrice);
    const preAppUpgradePrice = intlHandler.formatNumber(LocaleUtils.LocaleSpecificData.preAppUpgradePrice);
    const pepeAppPrice = intlHandler.formatNumber(LocaleUtils.LocaleSpecificData.pepeAppPrice);
    const pepeAppUpgradePrice = intlHandler.formatNumber(LocaleUtils.LocaleSpecificData.pepeAppUpgradePrice);

    const elementsProductsData = [
        {
            productName: PHOTOSHOP_ELEMENTS_APP_FULL_NAME + " & " + PREMIERE_ELEMENTS_APP_FULL_NAME,
            productDescription: intlHandler.formatMessage("desktop-app-description"),
            productIconKey: IconType.appLogo,
            productSecondIconKey: IconType.preAppLogo,
            productPrice: pepeAppPrice,
            productUpgradePrice: intlHandler.formatMessage("upgrade-desktop-app-desc",
                { price: getPriceWithSymbol(currencySymbol, pepeAppUpgradePrice, isCurrencySymbolInLeft) }),
            productAppLink: URLConstants.GoURL.PEPE_APP_LINK,
            productBuyLink: URLConstants.GoURL.PEPE_BUY_LINK,
            productUpgradeLink: URLConstants.GoURL.PEPE_UPGRADE_LINK,
        },
        {
            productName: PHOTOSHOP_ELEMENTS_APP_FULL_NAME,
            productDescription: intlHandler.formatMessage("pse-app-description"),
            productIconKey: IconType.pseAppLogo,
            productPrice: pseAppPrice,
            productUpgradePrice: intlHandler.formatMessage("upgrade-pse-app-desc",
                { price: getPriceWithSymbol(currencySymbol, pseAppUpgradePrice, isCurrencySymbolInLeft) }),
            productAppLink: URLConstants.GoURL.PHOTOSHOP_ELEMENTS_APP_LINK,
            productBuyLink: URLConstants.GoURL.PHOTOSHOP_ELEMENTS_BUY_LINK,
            productUpgradeLink: URLConstants.GoURL.PHOTOSHOP_ELEMENTS_UPGRADE_LINK,
        },
        {
            productName: PREMIERE_ELEMENTS_APP_FULL_NAME,
            productDescription: intlHandler.formatMessage("pre-app-description"),
            productIconKey: IconType.preAppLogo,
            productPrice: preAppPrice,
            productUpgradePrice: intlHandler.formatMessage("upgrade-pse-app-desc",
                { price: getPriceWithSymbol(currencySymbol, preAppUpgradePrice, isCurrencySymbolInLeft) }),
            productAppLink: URLConstants.GoURL.PREMIERE_ELEMENTS_APP_LINK,
            productBuyLink: URLConstants.GoURL.PREMIERE_ELEMENTS_BUY_LINK,
            productUpgradeLink: URLConstants.GoURL.PREMIERE_ELEMENTS_UPGRADE_LINK,
        }
    ];
    return elementsProductsData;
};
