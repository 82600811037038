/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { PerfClockBase } from "./PerfClockBase";

/**
 * Options for beginning a new action, passed into `PerfMonitorBase.beginAction`.
 */
export interface BeginActionOptions {
    //TODO - vib - Not giving support of recording frames as of now, can be added later
    /**
     * Whether this action should record the collection of frames that occur between its start time and its end time. If
     * frames are not recorded, the performance data for the action effectively consists of only a duration (a start
     * time and end time).
     */
    recordsFrames: boolean;

    /**
     * Whether the performance monitor should "lock" this action, and persist it throughout the current application
     * session. If this is true, the action will not be garbage collected, no matter how old it is. This can be useful
     * for retaining important measurements that occur early in the app lifecycle such as document load time.
     */
    locked: boolean;

    /**
     * Whether this action should be marked using the performance.mark and performance.measure APIs, so that it is
     * visible in the Timings row in the Chrome web inspector profiler.
     *
     * This value can be overridden by perf-monitor-mark-all-actions setting which makes all the markers visible in
     * the Timings row.
     */
    marked: boolean;

    /**
     * Offset between performance.now+performance.timeOrigin and Date.now at startTime. Used to detect unexpected clock
     * shifts that would produce misleading data.
     */
    startTimeOffset?: number;

    /**
     * Start time to be passed on to performance.mark() API. Note that this time is typically
     * offset from application start and may not match system time.
     */
    markStartTime?: number;

    /**
     * Whether this action is origin action
     */
    originAction?: boolean;
}

/**
 * Enum for describing when to stop measuring the action in perf monitor.
 */
export enum MeasureUntil {
    rendered = "rendered"
}

/**
 * Options for ending an action, passed into `PerfMonitorBase.endAction`.
 */
export interface EndActionOptions {
    /**
     * When set to true, suppress errors about action not being found.
     */
    noError: boolean;

    /**
     * The end time for the action, typically in ms, although any unit may be used as long as there is consistency.
     */
    endTime: number;

    /**
     * Offset between performance.now+performance.timeOrigin and Date.now at endTime Used to detect unexpected clock
     * shifts that would produce misleading data.
     */
    endTimeOffset?: number;

    /**
     * When to stop measuring this action.
     */
    measureUntil?: MeasureUntil;
}

/**
 * Base class for a performance monitor that can record actions and frames (see PerfAction and PerfFrame).
 */
export abstract class PerfMonitorBase extends PerfClockBase {
    /**
     * Begin recording performance measurements for an action. An action is anything that happens in the application
     * that can be delineated by a start time and an end time. Actions can collect frames, which are individual
     * performance measurements.
     *
     * For example, the action may represent a user action (e.g. drawing a rectangle), or it may represent an internal
     * application action (e.g. loading a document). Then, individual frames that occur during the duration of the
     * action may be "renderer frames" or "authoring frames", essentially measurements of recurring work. In this way,
     * we can answer questions like, "What was the application's framerate while the user was drawing a rectangle?" or
     * "How long did it take to load the document?".
     *
     * @param category - A category label for the action.
     * @param name - A name for the action.
     * @param id - An ID for the action. The performance monitor uses this to associate the beginning of an
     *      action with the end of the action. If no ID is available, the action name may be a reasonable
     *      substitute for an ID, assuming actions with the same name are unlikely to overlap.
     * @param startTime - The start time for action, typically in ms, although any unit may be used as long as there is
     *      consistency.
     * @param options - Extra options for the new action. See `BeginActionOptions`.
     */
    beginAction(category: string, name: string, id: string, startTime?: number, options?: Partial<BeginActionOptions>): void {
        const resolvedStartTime = startTime ?? this.currentTime(); // Evaluate first for accuracy.
        const resolvedOptions = {
            recordsFrames: options?.recordsFrames ?? true,
            locked: options?.locked ?? false,
            startTimeOffset: this.currentTimeOffset(),
            marked: options?.marked ?? false,
            markStartTime: options?.markStartTime,
            originAction: options?.originAction ?? false
        };
        this._beginAction(category, name, id, resolvedStartTime, resolvedOptions);
    }

    /**
     * Stop recording performance measurements for an action and set its end time.
     * @param id - The ID for the action, used for locating the action to end.
     * @param options - Options for ending the action. See `EndActionOptions`.
     */
    endAction(id: string, options?: Partial<EndActionOptions>): void {
        const endTime = options?.endTime ?? this.currentTime(); // Evaluate first for maximum accuracy.
        const resolvedOptions: EndActionOptions = {
            noError: options?.noError ?? false,
            endTime,
            endTimeOffset: this.currentTimeOffset(),
            measureUntil: options?.measureUntil
        };
        this._endAction(id, resolvedOptions);
    }

    /**
     * A convenient method to begin an action related to the application startup sequence, which is a particularly
     * important area of focus for performance. These actions are retained for logging / never garbage collected
     * (locked=true) and they're marked so they appear in the Timings row of Chrome web inspector profiler.
     * @param name - The name of the startup action. This will also be used as its ID for convenience.
     * @param startTime - The start time for action, typically in ms, although any unit may be used as long as there is
     *      consistency.
     * @param marked - Default true for startup actions. This determines whether the action should be marked using the
     *      performance.mark and performance.measure APIs, so that it is visible in the Timings row in the Chrome web
     *      inspector profiler.
     * @param markStartTime - Start time to be passed on to performance.mark() API. Note that this time is typically
     *      offset from application start and may not match system time. Defaults to current time offset as per
     *      the performance API
     */
    beginStartupAction(
        name: string,
        startTime?: number,
        marked?: boolean,
        markStartTime?: number,
        originAction?: boolean
    ): void {
        this.beginAction("startup", name, name, startTime, {
            locked: true,
            recordsFrames: false,
            marked: marked ?? true,
            markStartTime,
            originAction
        });
    }

    /**
     * The counterpart to `beginStartupAction`.
     */
    endStartupAction(name: string, options?: Partial<EndActionOptions>): void {
        this.endAction(name, options);
    }

    /**
     * This method provides the implementation for the public `beginAction` API. All arguments are well-defined when
     * this is called, so subclasses are able to share the same default values easily. Subclasses are expected to
     * implement this method.
     *
     * See `beginAction` for more details.
     */
    protected abstract _beginAction(
        category: string,
        name: string,
        id: string,
        startTime: number,
        options: BeginActionOptions
    ): void;

    /**
     * This method provides the implementation for the public `endAction` API. All arguments are well-defined when this
     * is called, so subclasses are able to share the same default values easily. Subclasses are expected to implement
     * this method.
     *
     * See `endAction` for more details.
     */
    protected abstract _endAction(id: string, endActionOptions: EndActionOptions): void;
}
