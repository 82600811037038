/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { BROADCAST_LOG_OUT_EVENT } from "./Constants/Constants";
import { TrialUtils } from "./TrialUtils";
import IMS from "../services/IMS";
import ELBroadcastChannel from "../modules/broadcastChannel/ELBroadcastChannel";
import Logger, { LogLevel } from "./Logger";
import { AppCode, LocaleUtils } from "./LocaleUtils";
import { UserUtils } from "./UserUtils";

const LoginUtils = {
    signIn: (): void => {
        const locale = LocaleUtils.getAppLocale(AppCode.ims);
        IMS.getInstance().loginUser({ locale: locale });
    },

    handleUserSignOut(): void {
        Logger.log(LogLevel.INFO, "LoginUtils: handleUserSignOut() : sending user sign out event");
        window.AdobeMessagingExperienceClient?.handleSignOut();
        ELBroadcastChannel.getInstance()?.getBroadcastChannel().postMessage({ type: BROADCAST_LOG_OUT_EVENT, });
    }
};

const UserAllowedUtils = {
    isUserAllowed: (daysInTrial: number): boolean => {
        if (UserUtils.isUserPaid()) {
            return true;
        } else if (TrialUtils.isTrialInProgress(daysInTrial)) {
            return true;
        } else {
            return false;
        }
    }
};

export { LoginUtils };
export { UserAllowedUtils };