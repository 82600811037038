/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe internal
import { getAppLocale } from "@intl/adobe-locales";

//Application Specific
import Utils from "./Utils";
import { LocaleData, LocaleLanguage, LocaleType } from "../common/interfaces/intl/LocaleTypes";

export const enum AppCode {
    globalNav = "globalnav",
    ims = "ims",
    fonts = "fonts",
    reactSpectrum = "react_spectrum"
}

export class LocaleUtils {
    static get LocaleSpecificData(): LocaleData {
        const locale = Utils.getCurrentLocaleInSnakeCase();

        const localeData: LocaleData = {
            currencySymbol: "US$",
            isCurrencySymbolInLeft: true,
            pseAppPrice: 99.99,
            pseAppUpgradePrice: 79.99,
            preAppPrice: 99.99,
            preAppUpgradePrice: 79.99,
            pepeAppPrice: 149.99,
            pepeAppUpgradePrice: 119.99
        };

        switch (locale as LocaleType) {

            case LocaleType.frFR: {
                localeData.currencySymbol = "€";
                localeData.isCurrencySymbolInLeft = false;
                localeData.pseAppPrice = 99.60;
                localeData.pseAppUpgradePrice = 81.60;
                localeData.preAppPrice = 99.60;
                localeData.preAppUpgradePrice = 81.60;
                localeData.pepeAppPrice = 150.00;
                localeData.pepeAppUpgradePrice = 123.60;
                break;
            }
            case LocaleType.deDE: {
                localeData.currencySymbol = "€";
                localeData.isCurrencySymbolInLeft = false;
                localeData.pseAppPrice = 98.77;
                localeData.pseAppUpgradePrice = 80.92;
                localeData.preAppPrice = 98.77;
                localeData.preAppUpgradePrice = 80.92;
                localeData.pepeAppPrice = 148.75;
                localeData.pepeAppUpgradePrice = 122.57;
                break;
            }
            case LocaleType.jaJP: {
                localeData.currencySymbol = "円";
                localeData.isCurrencySymbolInLeft = false;
                localeData.pseAppPrice = 19580;
                localeData.pseAppUpgradePrice = 12980;
                localeData.preAppPrice = 19580;
                localeData.preAppUpgradePrice = 12980;
                localeData.pepeAppPrice = 27280;
                localeData.pepeAppUpgradePrice = 20680;
                break;
            }
            default:
        }

        return Object.freeze(localeData);
    }

    static get DateFormat(): LocaleLanguage {
        const locale = Utils.getCurrentLocaleInSnakeCase();

        switch (locale as LocaleType) {
            case LocaleType.frFR: {
                return LocaleLanguage.frFR;
            }
            case LocaleType.deDE: {
                return LocaleLanguage.deDE;
            }
            case LocaleType.jaJP: {
                return LocaleLanguage.jaJP;
            }
            default:
                return LocaleLanguage.enGB;
        }
    }

    static getAppLocale(appCode: AppCode, locale?: string): string {
        return getAppLocale(appCode, locale ?? Utils.getCurrentLocaleInSnakeCase());
    }
}