/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { PIE } from "@piewasm/pie-web-npm-package";
import { layer } from "@piewasm/pie-web-npm-package";

//Application Specific
import { ELBounds, ELPoint, ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import { ControllerData } from "../../../common/interfaces/editing/ControllerTypes";
import { ELPIEPixel } from "./ELPIEPixel";
import { ELPIEPixelActions } from "../../../common/interfaces/editing/pie/ELPIEPixelTypes";
import { DocumentFormat } from "../../../common/interfaces/document/DocumentTypes";
import ILayer from "../../document/layer/ILayer";
import { IEditingEngine } from "../../editingEngines/IEditingEngine";
import { ELPIEBlendOptions, ELPIEDict, ELPIELayerEffects, ELPIEPointT, PIE_PERSISTENT_DIRECTORY } from "../../../common/interfaces/editing/pie/PIETypes";
import PIEUtils from "../utils/PIEUtils";
import IDoc from "../../document/IDoc";

export class ELPIELayer extends ILayer {
    private _layer?: layer;
    private _pieInstance?: PIE;
    private _pieFS?: any;
    private _renditionURL = "";

    private constructor(doc?: IDoc, engine?: IEditingEngine<unknown, unknown>) {
        super(doc, engine);
    }

    public static async createLayer(layer: layer, doc?: IDoc, engine?: IEditingEngine<unknown, unknown>): Promise<ELPIELayer> {
        const pieLayer = new ELPIELayer(doc, engine);
        await pieLayer.initialize(layer);
        return pieLayer;
    }

    async initialize<T>(payload?: T): Promise<boolean> {
        const layer = payload as unknown as layer;
        this._layer = layer;

        const engine = this.engine;

        if (!engine) {
            return Promise.reject("ELPIELayer couldn't be intialized since no appropriate engine found");
        }

        this._pieInstance = (engine.getModule() as PIE);
        this._pieFS = this._pieInstance.FS as any;

        this.setLastOriginalSize(await this.getSize());
        this.setLastPosition({ x: 0, y: 0 });

        await this.getRendtionURL();

        return Promise.resolve(true);
    }

    destroy(): void {
        URL.revokeObjectURL(this._renditionURL);
    
        if(this.layerImageDataBlobURL) {
            URL.revokeObjectURL(this.layerImageDataBlobURL);   
        }
    }

    async notify(action: ControllerData): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    validateLayerData(): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    getBitmap(): Promise<ImageBitmap> {
        throw new Error("Method not implemented.");
    }

    async getRendtionURL(): Promise<string> {
        if (this._renditionURL !== "") {
            return this._renditionURL;
        }

        let hasRendition = await this.isRenditionAvailable();
        //return new Promise<string>((resolve, reject) => {
        //  this._pieFS.syncfs(false, async (err: any) => {
        //    if (err) {
        //      reject("Couldn't create rendition URL!");
        //} else {
        if (hasRendition) {
            const renditionPath = `${PIE_PERSISTENT_DIRECTORY}/${this._layer?.get_name()}_${this._layer?.get_id()}.png`;
            hasRendition = await this.save(renditionPath);

            if (hasRendition) {
                this._renditionURL = await this.creatURLForRendition(renditionPath, DocumentFormat.PNG);
            }
        }
        return Promise.resolve(this._renditionURL);
        //}
        //});
        //});
    }

    async getCenter(): Promise<ELPoint> {
        const size = await this.getSize();
        const bounds = await this.getBounds();
        const centerX = Math.floor(bounds.left + (size.width) / 2);
        const centerY = Math.floor(bounds.top + (size.height) / 2);

        return { x: centerX, y: centerY };
    }

    async getSize(): Promise<ELSize> {
        const bounds = this._layer?.get_composite(true, true).get_bounds();
        return Promise.resolve({ width: Number(bounds?.width()) ?? 0, height: Number(bounds?.height()) ?? 0 });
    }

    async getBounds(): Promise<ELBounds> {
        const bounds = this._layer?.get_composite(true, true).get_bounds();
        return Promise.resolve({
            top: Number(bounds?.f_top ?? 0),
            left: Number(bounds?.f_left ?? 0),
            right: Number(bounds?.f_right ?? 0),
            bottom: Number(bounds?.f_bottom ?? 0),
        });
    }

    getEngine(): IEditingEngine<unknown, unknown> | undefined {
        return this.engine;
    }

    async save(path: string): Promise<boolean> {
        const { width, height } = await this.getSize();
        if (width * height <= 0) {
            return false;
        }

        const max_size = BigInt(Math.max(Number(width), Number(height)));

        const pixel = new ELPIEPixel(this);
        pixel.initialize((this._layer?.get_composite(true, true) as any).get_thumbnail(max_size, this._pieInstance?.scale_quality_e.automatic_best_fit));

        return pixel.notify({ type: ELPIEPixelActions.saveAsPNG, payload: path });
    }

    async flipYLayer(): Promise<boolean> {
        const bounds = await this.getBounds();
        const size = await this.getSize();
        return new Promise((resolve, reject) => {
            if (!this._pieInstance) {
                return Promise.reject("");
            }

            const rectArray = new this._pieInstance.array();
            rectArray.put_real(0);
            rectArray.put_real(0);
            rectArray.put_real(size.width);
            rectArray.put_real(size.height);

            const quadArray = new this._pieInstance.array();

            quadArray.put_real(bounds.left);
            quadArray.put_real(bounds.top + bounds.bottom);

            quadArray.put_real(bounds.left + bounds.right);
            quadArray.put_real(bounds.top + bounds.bottom);

            quadArray.put_real(bounds.left + bounds.right);
            quadArray.put_real(bounds.top);

            quadArray.put_real(bounds.left);
            quadArray.put_real(bounds.top);

            const transformDict = new this._pieInstance.dict("transformDict");

            transformDict.put_array("rectangle", rectArray);
            transformDict.put_array("quadrilateral", quadArray);

            this.transform(transformDict);

            resolve(true);
        });
    }

    async flipXLayer(): Promise<boolean> {
        const bounds = await this.getBounds();
        const size = await this.getSize();
        return new Promise((resolve, reject) => {
            if (!this._pieInstance) {
                return Promise.reject("");
            }

            const rectArray = new this._pieInstance.array();
            rectArray.put_real(0);
            rectArray.put_real(0);
            rectArray.put_real(size.width);
            rectArray.put_real(size.height);

            const quadArray = new this._pieInstance.array();

            quadArray.put_real(bounds.left + bounds.right);
            quadArray.put_real(bounds.top);

            quadArray.put_real(bounds.left);
            quadArray.put_real(bounds.top);

            quadArray.put_real(bounds.left);
            quadArray.put_real(bounds.top + bounds.bottom);

            quadArray.put_real(bounds.left + bounds.right);
            quadArray.put_real(bounds.top + bounds.bottom);

            const transformDict = new this._pieInstance.dict("transformDict");

            transformDict.put_array("rectangle", rectArray);
            transformDict.put_array("quadrilateral", quadArray);

            this.transform(transformDict);

            resolve(true);
        });
    }

    async moveLayer(position: ELPoint): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this._pieInstance) {
                reject("Invalid pie instance");
                return;
            }

            const offset_dict = new this._pieInstance.dict("offset");

            offset_dict.put_unit_real("horizontal", position.x, "pixelsUnit");
            offset_dict.put_unit_real("vertical", position.y, "pixelsUnit");

            const transformDict = new this._pieInstance.dict("transformDict");
            transformDict.put_dict("offset", offset_dict);

            this.transform(transformDict);
            this.setLastPosition({ x: position.x, y: position.y });

            resolve(true);
        });
    }

    async rotateLayer(angle: number): Promise<boolean> {
        const center = await this.getCenter();
        return new Promise((resolve, reject) => {
            if (!this._pieInstance) {
                reject("Invalid pie instance");
                return;
            }

            const anchor_dict = new this._pieInstance.dict("anchor_dict");

            const offset_horizontal = BigInt(center.x);
            const offset_vertical = BigInt(center.y);

            anchor_dict.put_unit_real("horizontal", parseInt(offset_horizontal.toString()), "pixelsUnit");
            anchor_dict.put_unit_real("vertical", parseInt(offset_vertical.toString()), "pixelsUnit");

            const dict2 = new this._pieInstance.dict("dict");
            dict2.put_dict("anchor", anchor_dict);
            dict2.put_unit_real("angle", angle - this.getLastRotation(), "angleUnit");

            this.transform(dict2);
            this.setLastRotation(angle);

            resolve(true);
        });
    }

    async scaleLayer(newSize: ELSize): Promise<boolean> {
        const center = await this.getCenter();
        const size = await this.getSize();
        return new Promise((resolve, reject) => {
            if (!this._pieInstance) {
                reject("Invalid pie instance");
                return;
            }

            const anchor_dict = new this._pieInstance.dict("anchor");

            const offset_horizontal = BigInt(center.x);
            const offset_vertical = BigInt(center.y);

            anchor_dict.put_unit_real("horizontal", parseInt(offset_horizontal.toString()), "pixelsUnit");
            anchor_dict.put_unit_real("vertical", parseInt(offset_vertical.toString()), "pixelsUnit");


            const scaleDict = new this._pieInstance.dict("transform");

            scaleDict.put_dict("anchor", anchor_dict);

            const newWidth = (newSize.width / size.width) * 100;
            const newHeight = (newSize.height / size.height) * 100;

            scaleDict.put_unit_real("width", newWidth, "percentUnit");
            scaleDict.put_unit_real("height", newHeight, "percentUnit");
            this.transform(scaleDict);

            this.setLastOriginalSize({ width: newWidth, height: newHeight });
            resolve(true);
        });
    }

    async setPixels(data: ArrayBuffer): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this._pieInstance) {
                reject("PIE instance is invalid!");
                return;
            }
            PIEUtils.getPIEPixelsFromArrayBuffer(data, this._pieInstance, this._pieFS).then((pixels) => {
                this._layer?.set_pixels(pixels);
                resolve(true);
            }).catch((reason) => {
                reject(reason);
            });
        });
    }

    async setBlendOptions(blendOptions: ELPIEBlendOptions): Promise<boolean> {
        this._layer?.set_blend_options(blendOptions);
        return true;
    }

    async setEffects(effects: ELPIELayerEffects): Promise<boolean> {
        this._layer?.set_layer_effects(effects);
        return true;
    }

    async setVisibility(visible: boolean): Promise<boolean> {
        this._layer?.set_visibility(visible);
        return true;
    }

    protected isLinkedSmartObject(): boolean {
        let linked = false;
        if (this._layer?.get_type() === "smartObject") {
            const data = JSON.parse(this._layer?.get_smart_object_data().to_json());
            if (data.fileInfo) {
                const file_info = data.fileInfo;
                linked = file_info.linked;
            }
        }
        return linked;
    }

    protected transform(dict: ELPIEDict): void {
        this._layer?.transform(dict);
    }

    protected setOffset(offSet: ELPIEPointT, relative = false): void {
        this._layer?.set_offset(offSet, relative);
    }

    protected async isRenditionAvailable(): Promise<boolean> {
        const { width, height } = await this.getSize();
        const hasRendition = width * height > 0;

        return Promise.resolve(hasRendition && !(
            this._layer?.get_type() === "layerSection" ||
            this._layer?.get_type() === "adjustmentLayer" //||
            //this._layer?.is_clipped() === true //PIE_WASM_REVISIT check if we need to remove this
        ));
    }

    protected async creatURLForRendition(filename: string, mimeType: DocumentFormat): Promise<string> {
        const content = this._pieFS.readFile(filename);
        return new Promise<string>((resolve) => {
            //this._pieFS.syncfs(false, async (err: any) => {
            //  if (err) {
            //    throw new Error("Couldn't create rendition URL");
            //} else {
            resolve(
                URL.createObjectURL(new Blob([content], { type: (mimeType as unknown as string) }))
            );
            //}
            //});
        });
    }
}
