/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { WorkflowsName } from "../../../workspaces/IWorkflow";
import { IconType } from "../../../assets/IconConstants";
import { AssetMimeType } from "../storage/AssetTypes";
import { MediaGridConfig } from "../../../stores/actions/mediaGridConfigActions";
import { CreationsJobProjectSubType } from "./CreationTypes";

export enum ELCreateOnDemandAction {
    workflowThumb = "WORKFLOW_THUMB",
    viewAllDialog = "VIEW_ALL_DIALOG",
}

export interface ELCreateOnDemandData {
    headerText: string,
    thumbId: WorkflowsName,
    iconKey: IconType,
    previewtext: string,
    descriptiveText: string,
    previewSrc: string,
    previewType: AssetMimeType,
    mediaGridConfig: MediaGridConfig,
    creationsJobProjectSubType: CreationsJobProjectSubType,
    featureFlag?: undefined,
    showNewBadge?: boolean
}

export enum CreateOnDemandViewActions {
    UPDATE_CREATIONS_DATA = "UPDATE_CREATIONS_DATA"
}

export type ELCreateOnDemandVariant = "mediaGrid" | "creations";
