/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Request } from "@elements/elementswebcommon";

//Application Specific
import { RecommendationsStatusData, RequestId } from "../../creations/CreationInAppNotifierTypes";
import { CreationsStatus } from "../../creations/CreationTypes";
import { ELRecommendationBatchProcessor } from "../../../../modules/elBatchProcessing/elBatchProcessor/ELRecommendationBatchProcessor";

export enum ELBatchHandlerType {
    recommendations = 0,
    creations = 1
}

export interface ELBatchProcessorAction {
	type: string,
	payload?: unknown;
}

export interface ELBatchRequestResponse {
    progress: number,
    status: CreationsStatus
}

export interface ELRecommendationRequestProcessorData extends ELBatchRequestResponse {
    requestId: RequestId,
    requestObj: Request
}

export enum ELCreationBatchActionTypes {
    recommendationBatchProgressChanged = "RECOMMENDATION_BATCH_PROGRESS_CHANGED",
    recommendationBatchStatusChanged = "RECOMMENDATION_BATCH_STATUS_CHANGED",
    overallBatchRequestsStatus = "OVERALL_BATCH_REQUESTS_STATUS",
    updateProgressChange = "UPDATE_PROGRESS_CHANGE"
}

export interface ELRecommendationBatchResponseData {
    requestId: RequestId,
    requestObj: Request
}

export interface ELRecommendationBatchProcessorRequestInfo {
    batchProcessor: ELRecommendationBatchProcessor,
    requestCount: number,
    successCount: number,
    failureCount: number
}

export interface ELBatchRecommendationStatusData extends RecommendationsStatusData {
    batchProcessor: ELRecommendationBatchProcessor
}

export interface ELRecommendationBatchProgressData {
    batchProcessor: ELRecommendationBatchProcessor,
    progress: number
}

export const DEFAULT_NUMBER_OF_RETRIES = 2;