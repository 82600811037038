/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import ELCollageRenderer from "../collage/ELCollageRenderer";
import IDocDataParser from "../document/parser/IDocDataParser";
import ELAdobeAssetRenderer from "../adobeAsset/ELAdobeAssetRenderer";
import ELPIEDocRenderer from "../pie/renderer/ELPIEDocRenderer";
import IGraphicsStage from "../stage/IGraphicsStage";
import IRenderer, { RendererType } from "./IRenderer";
import ELPeekThroughRenderer from "../peekThrough/ELPeekThroughRenderer";
import ELStageDocRenderer from "../client/renderer/ELStageDocRenderer";

export const RendererMap: { [key in RendererType]: any } = {
    [RendererType.collageDoc]: { renderer: ELCollageRenderer },
    [RendererType.adobeAsset]: { renderer: ELAdobeAssetRenderer },
    [RendererType.peekThrough]: { renderer: ELPeekThroughRenderer },
    [RendererType.stageDoc]: { renderer: ELStageDocRenderer },
    [RendererType.pie]: { renderer: ELPIEDocRenderer }
};

export default abstract class RendererFactory {
    static createRenderer<T>(rendererType: RendererType, doc: T, stage: IGraphicsStage, dataParser: IDocDataParser): IRenderer {
        const renderer = new RendererMap[rendererType].renderer(doc, stage, dataParser);
        return renderer;
    }
}
