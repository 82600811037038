/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELBatchProcessorAction } from "../../../common/interfaces/modules/elBatchHandler/ELCreationBatchHandlerTypes";
import IWorkflow from "../../../workspaces/IWorkflow";
import IBatchRequestHandler from "../elBatchRequestHandler/IBatchRequestHandler";

export abstract class IBatchProcessor<RequestParams, ResponseData> {
    protected owner: IWorkflow;
    protected batchRequestHandler?: IBatchRequestHandler<RequestParams, ResponseData>;

    constructor(owner: IWorkflow) {
        this.owner = owner;
    }

    abstract makeBatchRequest(requestData: RequestParams, batchSize: number): Promise<ResponseData>;

    async notify<BatchAction extends ELBatchProcessorAction>(action: BatchAction): Promise<boolean> { return false; }
}