/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import ELFabricStage from "./ELFabricStage";
import IGraphicsStage from "./IGraphicsStage";
import { GraphicsStageType } from "./IGraphicsStage";


export const GraphicsStageMap: { [key in GraphicsStageType]: any } = {
    [GraphicsStageType.fabricJS]: ELFabricStage,
};

export default abstract class GraphicsStageFactory {
    static createStage<T>(stageType: GraphicsStageType, doc: T, payload: unknown): IGraphicsStage {
        const stage = new GraphicsStageMap[stageType](doc, payload);
        return stage;
    }
}
