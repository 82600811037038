/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import { Flex, View } from "@adobe/react-spectrum";
import { ActionButton, DialogTrigger } from "@adobe/react-spectrum";

//Application Specific
import { ManageShareLinkDialog } from "../../../../view/components/organism/el-share-link-dialog/ELManageShareLinkDialogView";
import { SharedMedia } from "./SharedMedia";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { RootState } from "../../../../stores/store";
import { ShareOptions } from "../../../../view/components/organism/el-share-options/ELShareOptions";
import Utils from "../../../../utils/Utils";
import { ViewportProvider, useViewport } from "../../../../utils/hooks/useViewport";
import IMS from "../../../../services/IMS";
import { ControllerAction } from "../../../../view/IViewController";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { WorkflowActionType } from "../../../IWorkflow";
import ELIconButton from "../../../../view/components/molecules/el-icon-button/ELIconButtonView";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import ELCustomIcon from "../../../../view/components/molecules/el-custom-icon/ELCustomIcon";
import { ELCustomFooterView } from "../../../../view/components/organism/el-footer/ELCustomFooter";
import { ELReactSpectrumV3Provider } from "../../../../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./SharedMediaView.scss";

interface SharedMediaProps {
    controller: SharedMedia,
    shareOptionController: ShareOptions
    collectionId: string
}

export enum SharedMediaControllerAction {
    setIsOwner = "SET_IS_OWNER",
    handleShare = "HANDLE_SHARE"
}

interface SharedMediaData {
    sharedMediaList: Array<string>,
    selectedMediaList: Array<string>,
    collectionOwnerId: string
}

interface ToolbarProps extends SharedMediaData {
    isOwner: boolean,
    shareOptionController: ShareOptions,
    notify: (_: ControllerAction) => void
}

const SharedMediaView = (props: SharedMediaProps): React.ReactElement => {
    const [isOwner, setIsOwner] = useState(false);
    const sharedMediaData = useSelector((state: RootState): SharedMediaData => {
        return {
            sharedMediaList: state.mediaOrganizerReducer[props.collectionId]
                ?.children
                ?.map(asset => asset.assetId).filter(val => val) as string[] ?? [],
            selectedMediaList: state.selectedMediaListReducer.map(asset => asset.assetId).filter(val => val) as string[] ?? [],
            collectionOwnerId: state.mediaOrganizerReducer[props.collectionId]?.children[0]?.createdBy ?? ""
        }
    });

    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        const isCurrentUserOwner = IMS.getInstance().getUserId() === sharedMediaData.collectionOwnerId;
        setIsOwner(isCurrentUserOwner);
        props.controller.notify({
            type: SharedMediaControllerAction.setIsOwner,
            payload: isCurrentUserOwner
        });
        if (sharedMediaData.collectionOwnerId !== "") {
            // we shall wait before finally ingesting, since initially it is empty
            // once the share service call sets it we get actual picture
            props.controller.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.userIcon,
                    IngestEventTypes.info, IngestEventSubTypes.anonymous, !isCurrentUserOwner)
            });
        }
    }, [setIsOwner, sharedMediaData.collectionOwnerId, IMS.getInstance().getUserId()]);

    return (
        <ViewportProvider>
            <div className="shared-media-container" data-testid="shared-media-container">
                <Toolbar
                    shareOptionController={props.shareOptionController}
                    isOwner={isOwner} {...sharedMediaData}
                    notify={props.controller.notify.bind(props.controller)} />
                <div id="shared-media-container__media-grid" className="shared-media-container__media-grid" />
                <ELCustomFooterView currentLocale={IntlHandler.getInstance().getCurrentLocale()} />
            </div>
        </ViewportProvider>
    )
};
export default SharedMediaView;

const Toolbar = (props: ToolbarProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const { isOwner, sharedMediaList, selectedMediaList } = props;
    const heading = isOwner ? intlHandler.formatMessage("shared-by-you") : intlHandler.formatMessage("shared-media");
    let itemsText = null, itemsTextClass = "toolbar__items-text";
    if (selectedMediaList.length > 0) {
        itemsTextClass += " toolbar__items-selected-text";
        itemsText = intlHandler.formatMessage("selected", { itemCount: selectedMediaList.length });
    } else if (sharedMediaList.length !== 0) {
        itemsText = intlHandler.formatMessage("num-items", { itemCount: sharedMediaList.length });
    }
    return (
        <Flex UNSAFE_className="grid-toolbar-container shared-media-toolbar" justifyContent="space-between" alignItems="center">
            <div className="grid-toolbar-container__left-items">
                <Flex gap="1rem" height="100%" alignItems="baseline">
                    <div><span className="all-media-heading shared-media-heading">{heading}</span></div>
                    <div><span className={itemsTextClass}>{itemsText}</span></div>
                </Flex>
            </div>
            {
                isOwner ? <ManageButton {...props} /> : <></>
            }
        </Flex>);
}


const ManageButton = (props: ToolbarProps): React.ReactElement => {
    // isOwner = true
    const { isMobilePortraitMode } = useViewport();
    const { sharedMediaList } = props;
    const intlHandler = IntlHandler.getInstance();
    const handleShare = (close: () => void): void => {
        props.notify({ type: SharedMediaControllerAction.handleShare, payload: { mediaList: sharedMediaList, closeManageLinkDialog: close } });
    }

    const getTriggerControl = (): React.ReactElement => {
        return (<>
            <div className="manage_cta__desktop">
                <ELIconButton size="L" iconkey={IconType.manage} iconColor={Theme.dark.gray_controls_color_N}
                    iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.125rem"} iconHeight={"1.125rem"}
                    onClick={() => {
                        props.notify({
                            type: WorkflowActionType.ingest,
                            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.manageLink,
                                IngestEventTypes.click, IngestEventSubTypes.manage, true)
                        });
                    }} isDisabled={!sharedMediaList.length}>
                    <span className="manage-button-text">{intlHandler.formatMessage("manage")}</span>
                </ ELIconButton>
            </div>
            <div className="manage_cta__mobile">
                <View UNSAFE_className="media-import-mobile__cta">
                    <ELCustomIcon variant="independent" iconkey={IconType.manage} color={Theme.dark.gray_controls_color_N}
                        onClick={() => {
                            props.notify({
                                type: WorkflowActionType.ingest,
                                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.manageLink,
                                    IngestEventTypes.click, IngestEventSubTypes.manage, true)
                            });
                        }} isDisabled={!sharedMediaList.length}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"2.25rem"} height={"2.25rem"} />
                </View>
            </div>
        </>);
    }

    return (
        <ELReactSpectrumV3Provider>
            <DialogTrigger type={Utils.getSpectrumDialogType((isMobilePortraitMode as boolean))} isDismissable>
                <ActionButton isQuiet UNSAFE_className="shared-icon__box">
                    {getTriggerControl()}
                </ActionButton>
                {(close) => (
                    <ManageShareLinkDialog
                        onClose={() => {
                            close();
                        }}
                        heading={intlHandler.formatMessage("manage-shared-link")}
                        shareAgain={intlHandler.formatMessage("share-again")}
                        stopSharing={intlHandler.formatMessage("stop-sharing")}
                        discardLinkMessage={intlHandler.formatMessage("discard-link-message")}
                        shareOptionsController={props.shareOptionController}
                        onShareOptionsPress={() => { handleShare(close) }}
                    />
                )}
            </DialogTrigger>
        </ELReactSpectrumV3Provider>
    );
}
