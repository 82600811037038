/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useCallback, useEffect, useReducer } from "react";

import { View, Meter } from "@adobe/react-spectrum";
import { useHover } from "@react-aria/interactions";
import { SpectrumMeterProps } from "@react-types/meter";

import { CloudStorageQuota, StorageQuota } from "../../../../modules/storageQuota/StorageQuota";
import Utils from "../../../../utils/Utils"
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELPopover from "../el-popover/ELPopoverView";
import { IconType } from "../../../../assets/IconConstants";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { ViewAction } from "../../../IBaseController";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes, INGEST_APP_WORKSPACE } from "../../../../utils/IngestConstants";
import { IngestLogging } from "../../../../services/IngestWrapper";
import Constants from "../../../../utils/Constants/Constants";

import "./ELStorageQuotaNav.scss";

const CRITICAL_THRESHOLD = 90;

interface StorageQuotaFlyoutProps {
	fetchQuota: () => void,
	quota: ELStorageQuotaState
}

const StorageQuotaFlyoutContent = (props: StorageQuotaFlyoutProps): React.ReactElement => {
	const { quota, fetchQuota } = props;
	const intlHandler = IntlHandler.getInstance();
	const assetsUrl = process.env.REACT_APP_ELEMENTS_PHOTOS_ASSET_URL;
	const assetsFolderUrl = assetsUrl ? assetsUrl + Constants.DIR_SEPERATOR + Constants.CLOUD_DOCUMENTS_FOLDER + Constants.DIR_SEPERATOR + Constants.ELEMENTS_PHOTOS_FOLDER : "";

	// when user clicks on it
	useEffect(fetchQuota, []);

	const sMeterProps: SpectrumMeterProps = {
		variant: quota.percentUsed < CRITICAL_THRESHOLD ? "positive" : "critical",
		label: intlHandler.formatMessage("your-storage"),
		value: quota.percentUsed,
		size: "L",
		width: "100%"
	};

	return (
		<div className="storage-quota-flyout-content" >
			<View >
				<Meter {...sMeterProps} />
			</View>
			<View paddingTop="size-100">
				<span className="storage-quota-flyout-content__quota-text">
					{intlHandler.formatMessage("quota-used", { used: Utils.humanFileSize(quota.used), total: Utils.humanFileSize(quota.total) })}
					<a className="manage-link" href={assetsFolderUrl} target="_blank" rel="noopener noreferrer">{intlHandler.formatMessage("manage")}</a>
				</span>
			</View>
		</div>
	);
}

interface ELStorageQuotaState {
	used: number,
	total: number,
	percentUsed: number
}

enum ELStorageQuotaViewAction {
	updateQuota = "UPDATE_QUOTA"
}

function getDefaultELStorageState(): ELStorageQuotaState {
	return {
		used: 0,
		total: 0,
		percentUsed: 0
	}
}

function reducer(state: ELStorageQuotaState, action: ViewAction): ELStorageQuotaState {
	if (!action.payload) {
		return state;
	}
	switch (action.type) {
		case ELStorageQuotaViewAction.updateQuota:
			{
				const payload = action.payload as CloudStorageQuota;
				return {
					...state,
					used: payload?.used ?? 0,
					total: payload?.total ?? 0,
					percentUsed: payload.total && payload.used ? (payload.used / payload.total) * 100 : 0
				}
			}
		default:
			return state;
	}

}

function ELStorageQuota(): React.ReactElement {
	const [state, viewDispatch] = useReducer(reducer, getDefaultELStorageState());
	const { hoverProps, isHovered } = useHover({});

	const fetchQuota = useCallback(() => {
		StorageQuota.getInstance().getQuota(false).then((fetchedQuota) => {
			viewDispatch({ type: ELStorageQuotaViewAction.updateQuota, payload: fetchedQuota });
		});
	}, [viewDispatch]);

	// For page refresh
	useEffect(() => {
		fetchQuota();
		//TODO: View layer file shouldn't be dealing with StorageQuota. To be moved to template folder.
		let iQuota: CloudStorageQuota;
		IngestLogging.getInstance().fetchAndLogEvent(async () => {
			// Force sync ==> false 
			iQuota = await StorageQuota.getInstance().getQuota(false)
			IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE,
				IngestUtils.getPseudoLogObject(IngestWorkflowTypes.storage, IngestEventSubTypes.storageUtilized, IngestEventTypes.info,
					Utils.getBytesToMB(iQuota.used).toFixed(1))));
			if (iQuota.total <= iQuota.used) {
				IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE,
					IngestUtils.getPseudoLogObject(IngestWorkflowTypes.storage, IngestEventSubTypes.storageQuotaFull, IngestEventTypes.info,
						Utils.getBytesToMB(iQuota.total).toFixed(1))));
			}
			return IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE,
				IngestUtils.getPseudoLogObject(IngestWorkflowTypes.storage, IngestEventSubTypes.storageLimit, IngestEventTypes.info,
					Utils.getBytesToMB(iQuota.total).toFixed(1)));
		});
	}, []);

	const storageIcon = state.percentUsed < CRITICAL_THRESHOLD ?
		<ELIcon iconkey={IconType.cloudStorage} className={"cloud-storage__icon"} /> :
		<ELIcon iconkey={IconType.cloudStorageWarnIcon} className="cloud-storage__warning-icon" />

	const cloudStorageClassName = `cloud-storage ${isHovered ? "cloud-storage__hovered" : ""}`;
	return (
		<ELPopover placement="bottom right" type="popover" offset={-15}>
			<div {...hoverProps} className={cloudStorageClassName}>
				{storageIcon}
			</div>
			<StorageQuotaFlyoutContent quota={state} fetchQuota={fetchQuota} />
		</ELPopover>
	);
}

export default ELStorageQuota;