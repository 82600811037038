/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import ILayer from "../../../editors/document/layer/ILayer";
import { ELLayoutInfo } from "../creations/ELSocialLayoutTypes";
import { ELLayerKind, ELStageLayerData } from "../editing/layer/ELStageLayerTypes";
import { ELLinkType } from "../link/ELLinkTypes";
import { ELStageTextProperty } from "../stage/StageTypes";

export enum ELSavedStageVersion {
    version_1_0 = "1.0",
    version_2_0 = "2.0"
}

export interface ELStageDocPayload extends ELStageLayerData {
    layoutInfo?: ELLayoutInfo
}

export enum ELStageDocActions {
    updateLayerData = "UPDATE_LAYER_DATA",
    addLayer = "ADD_LAYER",
    removeLayer = "REMOVE_LAYER",
    addLayerByIndex = "ADD_LAYER_BY_INDEX",
    linkLayers = "LINK_LAYERS",
    updateTextProperty = "UPDATE_TEXT_PROPERTY",
    updateActiveObject = "UPDATE_ACTIVE_OBJECT"
}

export interface ELStageDocAddLayerByIndexPayload {
    layer: ILayer,
    layerIndex: number
}

export interface ELStageDocLinkLayersPayload {
    linkLayers: { layerId: string, isClipPath: boolean }[],
    linkType: ELLinkType
}

export interface ELStageDocDataPayload {
    layerId: string,
    data: unknown
}

export interface ELStageDocTextPropertyPayload {
    layerId: string,
    key: ELStageTextProperty,
    value: unknown
}

export interface ELStageDocAddLayerPayload {
    data: ELStageLayerData,
    redraw?: boolean
}

export interface ELStageDocDeleteLayerPayload {
    layerId: string,
    redraw?: boolean
}

/**
 * Represents the payload required by DocDataConverter to convert any data to stage doc.
 */
export interface ELStageDocConversionPayload {
    layerName?: string,
    layerKind?: ELLayerKind,
    redraw?: boolean
}