/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { PIE } from "@piewasm/pie-web-npm-package";

//Application Specific
import IImageFormat from "../../document/formats/IImageFormat";
import ELPIEDoc from "../models/ELPIEDoc";
import { ELSaveOptions } from "../../../common/interfaces/document/DocumentTypes";
import Utils from "../../../utils/Utils";
import { PIE_PERSISTENT_DIRECTORY } from "../../../common/interfaces/editing/pie/PIETypes";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";

export default class ELJPGFormat extends IImageFormat<PIEEditingEngine> {
    async writeFormat(doc: ELPIEDoc, engine: PIEEditingEngine, saveOptions?: ELSaveOptions): Promise<File> {
        return new Promise((resolve, reject) => {
            try {
                const image = doc.getData() as any;
                const pieInstance = (engine.getModule() as PIE);
                const pieFS = pieInstance.FS as any;

                const outputFileName = `${PIE_PERSISTENT_DIRECTORY}/` + (saveOptions?.name ?? Utils.getRandomUUID()) + ".jpeg";

                image.write_jpeg(
                    outputFileName,
                    new (pieInstance.write_jpeg_options_t)()
                );

                const idbFile = (pieFS as any).findObject(
                    outputFileName
                );

                if (idbFile) {
                    const file = new File([idbFile.contents.buffer], idbFile.name);
                    resolve(file);
                } else {
                    reject("Unable to create document object");
                }
            } catch (error) {
                reject("Unable to save document as JPG: " + error);
            }
        });
    }
}