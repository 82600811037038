/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";

//Application Specific
import { ELSampleMediaGrid } from "../../../../view/components/organism/el-sample-media-grid/ELSampleMediaGrid";
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../../IWorkflow";
import IWorkspace from "../../../IWorkspace";
import MediaManagerView from "../mediaManager/MediaManagerView";
import store from "../../../../stores/store";
import { ViewAction } from "../../../../view/IBaseController";
import { ControllerAction } from "../../../../view/IViewController";
import { SampleMediaManagerControllerAction, SampleMediaManagerViewAction, SampleMediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/SampleMediaManagerTypes";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { WorkspaceActionType } from "../../../IBaseWorkspace";
import ELNativeUploadHandler from "../../../../modules/uploadHandler/ELNativeUploadHandler";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { WorkflowSwitcher } from "../../WorkflowSwitcher";
import ELMediaUploader from "../../../../view/components/templates/el-media-uploader/ELMediaUploader";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { MediaImportStatusActions } from "../../../../common/interfaces/import/ImportProgressTypes";
import { ELImportButtonAction } from "../../../../view/components/organism/el-import-button/ELImportButton";
import MediaOrganizerAction from "../../../../stores/actions/mediaOrganizerActions";
import Constants from "../../../../utils/Constants/Constants";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import MediaGridConfigAction, { MediaGridConfig } from "../../../../stores/actions/mediaGridConfigActions";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { MediaGridWorkflowActions } from "../../../organizer/workflows/mediaGrid/MediaGrid";
import { MediaExistUtil } from "../../../creations/utils/MediaExistUtils";
import { FileMimeType } from "@elements/elementswebcommon";
import { ToastUtils } from "../../../../utils/ToastUtils";

enum ELIngestSampleMediaImportMethodType {
    addFromComputer = "add-from-computer",
    uploadImage = "upload-image",
    dragAndDrop = "drag-drop",
}

enum ELIngestMediaImportType {
    sampleMedia = "sample-media",
    userMedia = "user-media",
    noMedia = "no-media",
}

export class SampleMediaManager extends IWorkflow {
    private _grid?: ELSampleMediaGrid;
    private _selectedSampleMediaAssets: string[] = [];
    private _selectedAssets: ELAdobeAsset[] = [];
    private _intlHandler = IntlHandler.getInstance();
    private _initialMediaGridConfig: MediaGridConfig;
    private _nextWorkflowName?: WorkflowsName;
    private _mediaUploader?: ELMediaUploader;
    private readonly _importProgressContainer = "import-progress-container";
    private _mediaImportType = ELIngestMediaImportType.noMedia;

    private _handleDroppedFiles = (files: File[]): void => {
        this._mediaUploader = new ELMediaUploader(this);
        this._mediaUploader.createView(this.ensureHTMLElement(this._importProgressContainer));
        this._mediaUploader.handleImport(files, IngestLogObjectValue.creations);
    };

    constructor(owner: IWorkspace) {
        super(owner, WorkflowsName.sampleMediaManager);
        this._initialMediaGridConfig = store.getState().mediaConfigReducer[GRID_CONFIG_KEY];
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._grid?.createView(this.ensureHTMLElement("media-manager-grid-container"));
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const sampleMediaManager: React.ReactElement = React.createElement(MediaManagerView, {
            controller: this,
            title: this._intlHandler.formatMessage("select-media")
        });
        const reduxWrappedProvider = React.createElement(Provider, { store }, sampleMediaManager);
        ReactDOM.render(reduxWrappedProvider, container);
    }

    destroyView(): void {
        this._grid?.destroyView();
        this._mediaUploader?.destroyView();
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this._selectedSampleMediaAssets = [];
    }

    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow | undefined, action?: T | undefined): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this._nextWorkflowName = action?.nextWorkflow;
        this._grid = new ELSampleMediaGrid(this, action?.nextWorkflow);
        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
        store.dispatch(MediaGridConfigAction.updateConfig(this._initialMediaGridConfig));
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private _updateContinueButtonState(isDisabled: boolean): void {
        if (this.viewDispatcher) {
            this.viewDispatcher({
                type: SampleMediaManagerViewAction.updateContinueButtonState,
                payload: isDisabled
            });
        }
    }

    private async _startNextWorkflow(): Promise<void> {
        const modalWorkflowSwitcher = WorkflowSwitcher.getInstance();
        const mediaSwitcherWorkflowAction = modalWorkflowSwitcher.getWorkflowAction;
        if (mediaSwitcherWorkflowAction) {
            const { type, ...workflowPayload } = mediaSwitcherWorkflowAction;
            const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: workflowPayload.startWorkflow };
            await this._owner.notify(workspaceAction);
        }
    }

    private _logMediaImported(importType: ELIngestSampleMediaImportMethodType): void {
        const importMode = "import-mode";
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = importType;
        additionalLogInfo[IngestLogObjectKey.contentName] = importMode;
        const ingestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.init,
            IngestEventSubTypes.import, IngestUtils.getIngestCreationsWorkflowName(this._nextWorkflowName ?? WorkflowsName.sampleMediaManager), additionalLogInfo);
        this._ingest(ingestPayload);
    }

    private _logToolbarButtonEvents(controlEventType: string): void {
        const mediaTypeText = "media-type";
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = this._mediaImportType;
        additionalLogInfo[IngestLogObjectKey.contentName] = mediaTypeText;
        const ingestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventTypes.click,
            controlEventType, IngestUtils.getIngestCreationsWorkflowName(this._nextWorkflowName ?? WorkflowsName.sampleMediaManager), additionalLogInfo);
        this._ingest(ingestPayload);
    }

    private async _uploadSelectedSampleMedia(): Promise<void> {
        this._grid?.updateProgressText(this._intlHandler.formatMessage("media-upload-in-progress"));
        try {
            const uploadHandler = new ELNativeUploadHandler();
            for (const imageURL of this._selectedSampleMediaAssets) {
                const asset = await uploadHandler.uploadImageFromURL(imageURL, FileMimeType.eJpegImage);
                MediaExistUtil.getInstance().updateMediaExist();
                if (asset) {
                    this._selectedAssets.push(asset);
                    store.dispatch(SelectedMediaListAction.updateSelectedMediaList([asset]));
                    store.dispatch(MediaOrganizerAction.reset(Constants.ELEMENTS_PHOTOS_PATH as string));
                }
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "SampleMediaManager(_uploadSelectedSampleMedia): Error uploading sample media" + error);
            ToastUtils.error(this._intlHandler.formatMessage("create-output-error-try-again"));
        }
        this._grid?.updateProgressText("");
    }

    private async _handleToolbarContinue(): Promise<void> {
        this._logToolbarButtonEvents(IngestEventSubTypes.continue);
        await this._uploadSelectedSampleMedia();

        const selectedAssets = this._selectedAssets;
        const prevWorkflow = this.prevWorkflow;

        const endModalWorkflowAction = { type: WorkspaceActionType.endWorkflow };
        await this._owner.notify(endModalWorkflowAction);

        const modalWorkflowSwitcher = WorkflowSwitcher.getInstance();
        let switchModalWorkflowAction: WorkflowAction;
        if (modalWorkflowSwitcher.getWorkflowAction) {
            const { type, ...modalworkflowPayload } = modalWorkflowSwitcher.getWorkflowAction;
            switchModalWorkflowAction = { type: WorkspaceActionType.startWorkflow, ...modalworkflowPayload };
        } else {
            Logger.log(LogLevel.WARN, "SampleMediaManager(notify): Bad action" + modalWorkflowSwitcher.getWorkflowAction);
            switchModalWorkflowAction = { type: WorkspaceActionType.startDefaultWorkflow };
        }

        const workflowPayload = {
            nextWorkflow: switchModalWorkflowAction.nextWorkflow,
            nextWorkflowInitMode: switchModalWorkflowAction.nextWorkflowInitMode,
            payload: selectedAssets
        };
        const workflowAction = { type: SampleMediaManagerWorkflowAction.mediaSelection, ...workflowPayload };
        await prevWorkflow?.notify(workflowAction);
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case SampleMediaManagerControllerAction.toolbarCancel:
                {
                    store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
                    this._logToolbarButtonEvents(IngestEventSubTypes.cancel);
                    const workspaceAction = { type: WorkspaceActionType.endWorkflow };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            case SampleMediaManagerControllerAction.toolbarContinue:
                {
                    await this._handleToolbarContinue();
                    handled = true;
                    break;
                }
            case SampleMediaManagerControllerAction.updateContinueButtonState:
                {
                    const continueEnabled = action.payload as boolean;
                    this._updateContinueButtonState(!continueEnabled);
                    handled = true;
                    break;
                }
            case SampleMediaManagerControllerAction.updateSelectedSampleMediaAssets:
                {
                    this._selectedSampleMediaAssets = action.payload as string[];
                    this._mediaImportType = ELIngestMediaImportType.sampleMedia;
                    handled = true;
                    break;
                }
            case MediaImportStatusActions.mediaImported:
                {
                    if (action.payload === true) {
                        await this._startNextWorkflow();
                    }
                    handled = true;
                    break;
                }
            case ELImportButtonAction.startImport:
                {
                    this._handleDroppedFiles(Array.from(action.payload as FileList));
                    this._mediaImportType = ELIngestMediaImportType.userMedia;
                    this._logMediaImported(ELIngestSampleMediaImportMethodType.addFromComputer);
                    this._logToolbarButtonEvents(IngestEventSubTypes.addFromComputer);
                    handled = true;
                    break;
                }
            case SampleMediaManagerControllerAction.mediaImportedWithUploadMedia:
                {
                    this._handleDroppedFiles(Array.from(action.payload as FileList));
                    this._mediaImportType = ELIngestMediaImportType.userMedia;
                    this._logMediaImported(ELIngestSampleMediaImportMethodType.uploadImage);
                    handled = true;
                    break;
                }
            case SampleMediaManagerControllerAction.handleDroppedFiles:
                {
                    this._handleDroppedFiles(action.payload as File[]);
                    this._mediaImportType = ELIngestMediaImportType.userMedia;
                    this._logMediaImported(ELIngestSampleMediaImportMethodType.dragAndDrop);
                    handled = true;
                    break;
                }
            case MediaGridWorkflowActions.stopUpload:
                {
                    this._mediaUploader?.stopImport();
                    handled = true;
                    break;
                }
            case MediaGridWorkflowActions.retryUpload:
                {
                    this._mediaUploader?.retryImport();
                    handled = true;
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "SampleMediaManager(notify): Bad action" + action);
                }
        }

        if (!handled)
            handled = await super.notifyWorkflow(action as WorkflowAction);

        return handled;
    }
}