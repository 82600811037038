/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import {
    deserializeAsset,
    AdobeAsset,
    RenditionType
} from "@dcx/assets";
import { RenditionOptions, AssetWithRepoAndPathOrId } from "@dcx/common-types";
import { VideoMetaData } from "./StorageTypes";

export interface ELAdobeAsset extends AdobeAsset {
    isLocal?: boolean,
    url?: string
}

export type ELAssetWithRepoAndPathOrId = AssetWithRepoAndPathOrId;
export type ELRenditionOptions = RenditionOptions;

export const ELRenditionType = RenditionType;
export interface RenditionData {
    imgData?: string,
    videoData?: string,
    videoMetaData?: VideoMetaData
}
export const elDeserializeAsset = deserializeAsset;

export enum AssetMimeType {
    image = "IMAGE",
    video = "VIDEO",
    gif = "GIF"
}

export interface ELAssetPathOrId {
    path?: string,
    assetId?: string
}
