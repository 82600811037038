/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Application Specific
import { ViewAction } from "../../view/IBaseController";
import { ShareOptions } from "../../view/components/organism/el-share-options/ELShareOptions";
import { ModalWorkspaceName } from "../IModalWorkspace";
import IWorkflow, { WorkflowActionType, WorkflowsName } from "../IWorkflow";
import { WorkspaceAction, WorkspaceActionType, WorkspacesName } from "../IBaseWorkspace";
import ModalWorkspaceFactory from "../ModalWorkspaceFactory";
import WorkflowFactory from "../WorkflowFactory";
import { SIV_DIALOG_CLASSNAME } from "../../utils/SIVUtils";
import Logger, { LogLevel } from "../../utils/Logger";
import { ControllerAction } from "../../view/IViewController";
import IWorkspace from "../IWorkspace";
import App from "../../app/App";
import LinktoWorkflowNameFactory from "./LinktoWorkfowNameFactory";
import { CreationPopUpAction } from "../../common/interfaces/creation-popup/CreationPopUpTypes";
import { FeatureName } from "../../services/Floodgate/FloodgateConstants";
import mediaPath from "../../assets/popup/share_page_pop_up_media.mp4";

export class LinkPreview extends IWorkspace {
    // Declare the workflows that maybe triggered in /view[?/] workspace
    private readonly _containerId = "link-preview-app-container";
    private readonly _modalAppContainerId = "link-preview-modal-app-container";
    private readonly _fullScreenWorkflowContainerId = "full-screen-workflow-container";

    private _sharedMedia: IWorkflow;
    private shareOptions: ShareOptions;

    constructor(owner: App) {
        super(owner);
        this.workspaceName = WorkspacesName.linkPreview;
        this.shareOptions = new ShareOptions(this);
        // Initialize the workflows here
        this._sharedMedia = WorkflowFactory.createWorkflow(WorkflowsName.sharedMedia, this);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._owner.createNavbarView();

        const workflowName = LinktoWorkflowNameFactory.getWorkflowName(window.location.href);

        switch (workflowName) {
            case WorkflowsName.singleImageView:
                {
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: WorkflowsName.singleImageView };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case WorkflowsName.tryThisInDesktop:
                {
                    this.startWorkflow(this._fullScreenWorkflowContainerId, WorkflowFactory.createWorkflow(WorkflowsName.tryThisInDesktop, this));
                    break;
                }
            case WorkflowsName.sharedMedia:
                {
                    this.startWorkflow(this._containerId, this._sharedMedia, {
                        type: WorkflowActionType.initialize,
                        payload: this.shareOptions
                    });
                    this._owner.notify({
                        type: CreationPopUpAction.showCreationPopUp, payload: {
                            showTimeout: 4000,
                            hideTimeout: 30000,
                            screen: "shared-screen",
                            storageKey: "SHARE_POP_UP_LAST_SHOWN",
                            mediaPath: mediaPath,
                            contentKey: "share-page-pop-up-content",
                            floodgateKey: FeatureName.eSharePagePopup
                        }
                    });
                    break;
                }
        }
    }

    // Base controller
    destroy(): void {
        this._owner.destroyNavbarView();
        super.destroy();
    }

    private _startModalWorkspace<T extends WorkspaceAction>(action: T): void {
        const modalWorkspaceName = action.startModalWorkspace;

        const payload = action.payload as Record<string, unknown>;
        const overlayDiv = payload?.overlayDiv as string;
        const workflowName = payload?.workflowName as WorkflowsName;

        if (modalWorkspaceName && overlayDiv) {
            this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(modalWorkspaceName, this, this.ensureHTMLElement(overlayDiv) as HTMLDivElement);
            this.modalWorkspace.startWorkspace(this._modalAppContainerId, workflowName);
        }
    }

    private _startWorkflows(action: WorkspaceAction): void {
        switch (action.startWorkflow) {
            case WorkflowsName.singleImageView:
                {
                    const { type, ...workflowPayload } = action;
                    const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                    this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.modalWrapper, this, this.ensureHTMLElement("root") as HTMLDivElement, SIV_DIALOG_CLASSNAME);
                    this.modalWorkspace.startWorkspace(this._modalAppContainerId, WorkflowsName.singleImageView, workflowAction);
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "LinkPreview:_startWorkflows: ", "Invalid next workflow name");
                    break;
                }
        }
    }

    private _checkAndSetCurrentWorkflow(): void {
        if (!this.getWorkflow) {
            this.startWorkflow(this._containerId, this._sharedMedia);
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startModalWorkspace: {
                this._startModalWorkspace(action);
                handled = true;
                break;
            }
            case WorkspaceActionType.endModalWorkspace: {
                super.endModalWorkspace();
                this._checkAndSetCurrentWorkflow();
                handled = true;
                break;
            }
            case WorkspaceActionType.startWorkflow:
                {
                    this._startWorkflows(action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.startPreviousWorkflow:
                {
                    super.startPrevWorkflow(this._containerId, action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.startDefaultWorkflow:
                {
                    if (this.getWorkflow !== this._sharedMedia) {
                        this.startWorkflow(this._containerId, this._sharedMedia);
                    }
                    handled = true;
                    break;
                }
            default:
                handled = await super.notify(action as WorkspaceAction);
                break;
        }
        return handled;
    }
}
