/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useRef } from "react";

import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import colorPickerIcon from "../../../../assets/icons/EL_color_picker_N.svg";
import Logger, { LogLevel } from "../../../../utils/Logger";

import "./ELColorPicker.scss";

export interface ELColorPickerProps {
    color: string
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void)
}

const ELColorPickerView = (props: ELColorPickerProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const colorPickerRef = useRef<HTMLInputElement>(null);

    const openColorPicker = (): void => {
        if (colorPickerRef.current) {
            colorPickerRef.current.click();
        }
    }

    return (
        <div data-testid="el-color-picker" className="color-filling-container">
            <input onChange={(event) => { props.onChange(event); }} className="color-filling" type="color" value={props.color} ref={colorPickerRef} />
            <img crossOrigin="anonymous" src={colorPickerIcon} className="color-filling-icon" onClick={openColorPicker} alt={intlHandler.formatMessage("color-picker")}
                onKeyPress={() => Logger.log(LogLevel.INFO, "Not handling key events for ELCollapsiblePanelView")} />
        </div>
    )
}

export default ELColorPickerView;
