/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { Dispatch } from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application specific
import { ViewAction } from "../../../view/IBaseController";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../IWorkflow";
import ImageEditWorkflowView from "./ImageEditWorkflowView";
import store from "../../../stores/store";
import { ViewportProvider } from "../../../utils/hooks/useViewport";
import ELStageDoc from "../../../editors/client/document/ELStageDoc";
import { ELTabPanelActions, ELTabPanelType } from "../../../common/interfaces/tabpanel/ELTabPanelTypes";
import Logger, { LogLevel } from "../../../utils/Logger";
import { ELEditPanelProvider } from "../utils/panel/ELEditPanelProvider";
import { ELAdobeAsset } from "../../../common/interfaces/storage/AssetTypes";
import DocumentFactory, { DocumentFactoryPayload } from "../../../editors/document/DocumentFactory";
import { DocumentType } from "../../../editors/document/IDoc";
import { ELLayerKind } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELStageDocActions, ELStageDocPayload } from "../../../common/interfaces/document/ELStageDocTypes";
import { ELViewType } from "../../../common/interfaces/creations/CreationTypes";
import { ELDualDocumentView } from "../../../editors/document/dualDocumentView/ELDualDocumentView";
import { ControllerAction } from "../../../view/IViewController";
import { EditWorkflow } from "./EditWorkflow";
import ELImageEditWorkflowHeader from "../../../view/components/templates/el-image-edit-workflow-header/ELImageEditWorkflowHeader";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { CreationsDownloadFileType } from "../../../common/interfaces/creations/CreationTypes";
import { ELStageDocEditingConfig, ELStageDocEditingManager } from "../../../editors/editingManagers/ELStageDocEditingManager";
import { ELLooksPanelControllerActions } from "../../../common/interfaces/editing/adjustments/ELLooksPanelTypes";
import { EditWorkspaceAction } from "../../../common/interfaces/editing/editWorkspace/EditWorkspaceTypes";
import { DocumentActions, DocumentConvertibleDataType, DocumentDirty, DocumentFormat, DocSaveError } from "../../../common/interfaces/document/DocumentTypes";
import DocActions from "../../../stores/actions/DocActions";
import { Edit } from "../../../common/interfaces/editing/EditorTypes";
import { ELImageEditWorkflowControllerAction, ELImageEditWorkflowHeaderViewAction, ELImageEditWorkflowViewAction } from "../../../common/interfaces/editing/editWorkspace/ELImageEditWorkflowTypes";
import { ELCreationsHeaderControllerAction } from "../../../common/interfaces/creations/ELCreationsHeaderTypes";
import ELStageDocSaveManager from "../../../editors/document/documentSaveManager/ELStageDocSaveManager";
import { StorageService } from "../../../services/StorageServiceWrapper";
import { ToastUtils } from "../../../utils/ToastUtils";
import MediaOrganizerAction from "../../../stores/actions/mediaOrganizerActions";
import Constants from "../../../utils/Constants/Constants";
import { ELAdobeAssetControllerAction } from "../../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes, IngestLogObjectValue, IngestLogObjectKey, IngestLogObjectCustomKey } from "../../../utils/IngestConstants";
import { IngestUtils } from "../../../utils/IngestUtils";
import DualViewAction from "../../../stores/actions/DualViewAction";
import { HistoryUtils } from "../../../utils/HistoryUtils";
import ImageEditWorkflowUtils from "../utils/ImageEditWorkflowUtils";
import { CanvasZoomLevelAction, DEFAULT_ZOOM_PERCENTAGE } from "../../../common/interfaces/stage/StageTypes";
import { ELImageData } from "../../../common/interfaces/stage/StageTypes";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import SelectedMediaListAction from "../../../stores/actions/selectedMediaListActions";
import { ReplaceMediaManagerMode, ReplaceMediaManagerWorkflowAction } from "../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import { CreationMediaActionType } from "../../../view/components/templates/el-creation-media-panel/ELCreationMediaView";
import { ReplaceAssetInfo } from "../../../common/interfaces/creations/ELCollageTypes";
import { ELStageDocDataConverterFactory } from "../../../editors/document/dataConverter/ELStageDocDataConverterFactory";
import { ELEditErrorDialog } from "../../../view/components/templates/el-edit-error-dialog/ELEditErrorDialog";
import { ELEditErrorDialogViewProps } from "../../../view/components/templates/el-edit-error-dialog/ELEditErrorDialogView";
import AdjustmentsPresets from "../../../editors/adjustments/AdjustmentsPresets";
import { DEFAULT_INTENSITY_VALUE } from "../../../common/interfaces/editing/adjustments/AdjustmentsEditorTypes";
import { ELRenameMedia } from "../../../view/components/organism/el-rename-media-dialog/ELRenameMedia";

import "./ImageEditWorkflowView.scss";

interface ImageEditWorkflowAppliedEditsConfig {
    looksPresetName: string;
    looksPresetIntensitySliderUsed: boolean;
}

class ImageEditWorkflow extends EditWorkflow<ELStageDoc, ImageEditWorkflowAppliedEditsConfig> {
    private readonly _editHeaderContainerId: string = "edit-workflow__header-container";
    private readonly _editDocContainerId: string = "edit-workflow__doc-container";
    private readonly _editRightPanelContainerId: string = "edit-workflow__right-panel-container";
    private readonly _editDialogContainerId: string = "edit-workflow__dialog-container";
    private _defaultNewFileName: string;
    private _defaultNewFileExtension: string;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.imageEditWorkflow);
        this.mediaGridConfig = ImageEditWorkflowUtils.getMediaGridConfig();
        this._defaultNewFileName = "";
        this._defaultNewFileExtension = "";
    }

    private _getStagePayload(viewType: ELViewType): unknown {
        switch (viewType) {
            default:
                return {
                    showReplaceMediaButton: false,
                    showDeleteButton: false,
                    objectHoverColor: "rgb(0, 255, 255)",
                    viewType: viewType
                };
        }
    }

    private _onDocumentDirty(isDirty: DocumentDirty): void {
        if (this.doc) {
            store.dispatch(DocActions.updateDocumentError(this.doc.hasRenderingError()));
        }
        store.dispatch(DocActions.updateDocumentDirty(isDirty));
    }

    private async _backButtonClicked(): Promise<void> {
        await this._exitTheWorkflow();
        return Promise.resolve();
    }

    private async _dontSaveAndBack(): Promise<void> {
        await this._backButtonClicked();
        return Promise.resolve();
    }

    private async _exitTheWorkflow(): Promise<void> {
        store.dispatch(DocActions.updateDocumentDirty(DocumentDirty.NON_DIRTY));
        this.notify({ type: EditWorkspaceAction.exitImageEditWorkflow });
        return Promise.resolve();
    }

    private _uiUpdateAfterSaveSuccess(): void {
        if (this.doc) {
            this.doc.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
        }
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELImageEditWorkflowViewAction.showProgress,
            payload: false
        });
        store.dispatch(MediaOrganizerAction.reset(Constants.ELEMENTS_PHOTOS_PATH as string));
        ToastUtils.info(IntlHandler.getInstance().formatMessage("file-saved-successfully-media"));
    }

    private async _onMediaChange(): Promise<void> {
        if (this.currentMedia) {
            store.dispatch(SelectedMediaListAction.updateSelectedMediaList([this.currentMedia]));
        }
        this._updateNewDefaultFileName();
        await this.editRightPanel?.notify({ type: ELTabPanelActions.updateCurrentMedia, payload: this.currentMedia });
        await this.editHeader?.notify({ type: ELImageEditWorkflowHeaderViewAction.updateCurrentAsset, payload: this.currentMedia });
        const newUrl = ImageEditWorkflowUtils.getHistoryState([this.currentMedia!]);
        HistoryUtils.replaceHistory(newUrl)
    }

    private _updateAfterDocTopLayerNameOnSave(): void {
        const TOP_LAYER_NAME_SUFFIX = "final";
        const topLayerName = this.doc?.getTopLayer().getName();
        this.doc?.getTopLayer().setName(topLayerName + TOP_LAYER_NAME_SUFFIX);
    }

    private async _saveAs(fileName?: string): Promise<void> {
        try {
            if (this.doc) {
                this.updateViewStatusAndProgressText(true, IntlHandler.getInstance().formatMessage("saving-media"));
                const saveManager = new ELStageDocSaveManager();
                const newAssetPath = await saveManager.saveAs(this.doc, this.currentMedia as ELAdobeAsset, undefined, fileName);
                const newAsset = await StorageService.getInstance().resolveAsset({ path: newAssetPath, repositoryId: "" }, 'path') as ELAdobeAsset;
                this.currentMedia = newAsset;
                this._updateAfterDocTopLayerNameOnSave();
                await this._commitChangesToBeforeDoc();
                await this._onMediaChange();
                this._uiUpdateAfterSaveSuccess();
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ImageEditWorkflow: _saveAs: Error saving document " + error);
            return Promise.reject(error);
        }
    }

    private async _commitChangesToBeforeDoc(): Promise<void> {
        if (!this.beforeDoc || !this.doc) {
            return Promise.reject("Before or after doc not set");
        }

        //before doc will always have just one layer since created directly from ImageData of provided asset.
        const baseLayer = (await this.beforeDoc?.getLayers())?.[0];
        if (!baseLayer) {
            return Promise.reject("Before doc doesn't have any layer");
        }

        const imageData = await this.getDocData(this.doc);
        const payload = { layerId: baseLayer.getId(), data: imageData };
        this.beforeDoc?.notify({ type: ELStageDocActions.updateLayerData, payload: payload });
    }

    private async _showErrorDialog(heading: string, description: string, learnMoreLink: string): Promise<void> {
        const errorDialog = new ELEditErrorDialog(this);
        const view = errorDialog.getView({
            heading: heading,
            description: description,
            learnMoreLink: learnMoreLink,
            onClose: () => {
                this._exitTheWorkflow();
            }
        }) as React.ReactElement;
        ReactDOM.render(view, this.ensureHTMLElement(this._editDialogContainerId));
    }

    private async _setupDoc(): Promise<void> {
        this.createAndRenderDoc().then(() => {
            // Create and render doc will set the doc hence it will never be null, if there is some error
            // creating doc previous workflow is started hence won't reach this point
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.docEditingManager = new ELStageDocEditingManager(this.doc!);
        });
    }

    private async _createBeforeAfterDoc(): Promise<void> {
        const createDocPromises: Promise<any>[] = [];
        if (!this.currentMedia) {
            Logger.log(LogLevel.WARN, "ImageEditWorkflow: _createBeforeAfterDoc: No media to create stage document");
            return Promise.reject("No media to create document");
        }

        const imageData = await this.getImageDataFromAsset(this.currentMedia);
        const createBeforeDocPromise = this.createDocument(ELViewType.before, imageData).then((doc) => {
            this.beforeDoc = doc;
        });
        const createAfterDocPromise = this.createDocument(ELViewType.after, imageData).then((doc) => {
            this.doc = doc;
        })
        createDocPromises.push(createBeforeDocPromise);
        createDocPromises.push(createAfterDocPromise);
        await Promise.all(createDocPromises);
    }

    private _createViewComponents(): void {
        const panelProvider = new ELEditPanelProvider(this);
        this.editRightPanel = panelProvider.getTabPanel(ELTabPanelType.rightTabPanel);
        this.editRightPanel.createView(this.ensureHTMLElement(this._editRightPanelContainerId));
        this.editRightPanel?.notify({ type: ELTabPanelActions.updateCurrentMedia, payload: this.currentMedia });
        this.editRightPanel?.notify({ type: ELTabPanelActions.initWorkflow });
        //GLIA_REVISIT - vib - Change this to use the adjustments related options
        const backButtonDialogHeading = IntlHandler.getInstance().formatMessage("photo-adjustments-workflow");
        const downloadOptions = [CreationsDownloadFileType.jpeg, CreationsDownloadFileType.png];
        this.editHeader = new ELImageEditWorkflowHeader(this, this.shareOptions, backButtonDialogHeading, downloadOptions);
        this.editHeader.notify({ type: ELImageEditWorkflowHeaderViewAction.updateCurrentAsset, payload: this.currentMedia });
        this.editHeader.createView(this.ensureHTMLElement(this._editHeaderContainerId));
    }

    private _showDownloadBeginToast(): void {
        const message = IntlHandler.getInstance().formatMessage("file-download-in-background", {
            media: IntlHandler.getInstance().formatMessage("media").toLowerCase()
        });

        ToastUtils.info(message);
    }

    private _showDownloadFailedToast(): void {
        //wait for download start message to go away then show the failed msg
        setTimeout(() => {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                timeout: Constants.TOAST_NO_TIMEOUT as number
            });
        }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
    }

    private _ingestLooksIntensitySlider(): void {
        if (store.getState().adjustmentsReducer.intensity !== DEFAULT_INTENSITY_VALUE) {
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.click, IngestEventTypes.looks, IngestLogObjectValue.intensitySlider));
        }
    }

    private _getCommonAdditionalLogInfo(): Record<string, string> {
        try {
            const additionalLogInfo: Record<string, string> = {};
            const preset = AdjustmentsPresets.getById(Number(store.getState().adjustmentsReducer.selectedPresetId));
            additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.looksPresetUsed;
            additionalLogInfo[IngestLogObjectKey.eventCount] = preset.title;
            return additionalLogInfo;
        } catch (error: any) {
            Logger.log(LogLevel.DEBUG, "ImageEditWorkflow: _getCommonAdditionalLogInfo: Error received on logging preset info " + error);
            return {};
        }
    }

    private _ingestDownload(isSuccessful: boolean, format?: DocumentFormat, additionalLogInfo?: Record<string, string>): void {
        const subType = isSuccessful ? IngestEventSubTypes.success : IngestEventSubTypes.error;
        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, subType, IngestEventTypes.download, format, additionalLogInfo));
        this._ingestLooksIntensitySlider();
    }

    private _ingestSave(isSuccessful: boolean, origin?: string, additionalLogInfo?: Record<string, string>): void {
        const subType = isSuccessful ? IngestEventSubTypes.success : IngestEventSubTypes.error;
        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, subType, IngestEventSubTypes.save, origin, additionalLogInfo));
    }

    private async _download(imageData: ELImageData): Promise<void> {
        const additionalDownloadLogInfo = this._getCommonAdditionalLogInfo();
        try {
            const assetInfo = await AssetStorageUtils.resolveAssetInfo(this.currentMedia as ELAdobeAsset);
            //TODO: Glia Revisit this code
            // what if name is of format abc.xyz.
            // should be some function to get file name without extension
            const name = assetInfo.name?.split(".")[0] ?? IntlHandler.getInstance().formatMessage("untitled");
            this._showDownloadBeginToast();
            await this.doc?.notify({ type: DocumentActions.download, payload: { name: name, imageData: imageData } });
            this._ingestDownload(true, imageData.format, additionalDownloadLogInfo);
        } catch (error: unknown) {
            this._showDownloadFailedToast();
            this._ingestDownload(false, imageData.format, additionalDownloadLogInfo);
        }
    }

    private async _applyEdit(editName: Edit, config: ELStageDocEditingConfig): Promise<void> {
        const viewType = store.getState().dualViewReducer.viewType;
        if (viewType === ELViewType.before) {
            this.dualView?.showDoc(ELViewType.after);
            store.dispatch(DualViewAction.updateView(ELViewType.after));
        }
        this.updateViewStatusAndProgressText(true, IntlHandler.getInstance().formatMessage("applying-edit"));
        await this.docEditingManager?.applyEdit(editName, { docEditingContext: config.docEditingContext, editParams: config.editParams });
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELImageEditWorkflowViewAction.showProgress,
            payload: false
        });
    }

    private async _invokeReplaceMediaManager(): Promise<boolean> {
        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.replaceMediaManager,
            payload: {
                assetId: store.getState().selectedMediaListReducer[0].assetId,
                mode: ReplaceMediaManagerMode.replacingMedia,
                mediaGridConfig: this.mediaGridConfig
            }
        };
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
        await this._owner.notify(workspaceAction);
        return Promise.resolve(true);
    }

    private async _triggerReplaceMediaWorkflow(): Promise<boolean> {
        let handled = false;
        if (store.getState().docStateReducer.isDirty === DocumentDirty.DIRTY) {
            this.viewDispatcher?.call(this.viewDispatcher, {
                type: ELImageEditWorkflowViewAction.updateReplaceMediaDialogState,
                payload: true
            });
            handled = true;
        } else {
            handled = await this._invokeReplaceMediaManager();
        }
        return Promise.resolve(handled);
    }

    private async _onSaveAs(assetName?: string, saveCompleteCallback?: () => void, saveCancelCallback?: () => void, ingestSaveSource?: string): Promise<void> {
        const additionalSaveLogInfo = this._getCommonAdditionalLogInfo();
        this._ingestLooksIntensitySlider();
        try {
            const assetNameWithoutExtension = assetName ? assetName.split(".")[0] : undefined;
            const assetNameWithDefaultExtension = assetNameWithoutExtension ? assetNameWithoutExtension + "." + this._defaultNewFileExtension : undefined;
            await this._saveAs(assetNameWithDefaultExtension);
            this._ingestSave(true, ingestSaveSource, additionalSaveLogInfo);
            saveCompleteCallback?.();
        } catch (error) {
            this._ingestSave(false, ingestSaveSource, additionalSaveLogInfo);
            this.viewDispatcher?.call(this.viewDispatcher, {
                type: ELImageEditWorkflowViewAction.showProgress,
                payload: false
            });
            if (error === DocSaveError.ASSET_ALREADY_EXISTS) {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("save-failed-file-already-exists"));
                this._showSaveAsDialog(saveCompleteCallback, saveCancelCallback, ingestSaveSource);
            } else {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("saving-psd-failed"));
            }
        }
    }

    private _showSaveAsDialog(saveCompleteCallback?: () => void, saveCancelCallback?: () => void, ingestSaveSource?: string): void {
        const onSave = (title: string): void => {
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.titleRenamed;
            additionalLogInfo[IngestLogObjectKey.eventCount] = title !== this._defaultNewFileName ? IngestLogObjectValue.yes :IngestLogObjectValue.no; 
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.info, IngestEventSubTypes.save, ingestSaveSource, additionalLogInfo));
            this._onSaveAs(title, saveCompleteCallback, saveCancelCallback, ingestSaveSource);
        }
        const onCancel = (): void => {
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventTypes.click, IngestEventSubTypes.cancel, ingestSaveSource));
            saveCancelCallback?.();
        }

        const renameMediaProps = {
            onSave: onSave,
            dialogHeading: IntlHandler.getInstance().formatMessage("save-a-copy"),
            defaultTitle: this._defaultNewFileName,
            isValidTitle: (title: string) => title.length > 0,
            onCancel: onCancel,
        }
        const saveAsDialog = new ELRenameMedia(renameMediaProps);
        saveAsDialog.createView(this.ensureHTMLElement(this._editDialogContainerId));
    }

    private async _updateNewDefaultFileName(): Promise<void> {
        const saveManager = new ELStageDocSaveManager();
        const assetNameForSaveAs = await saveManager.getAssetNameForSaveAs(this.currentMedia as ELAdobeAsset);
        this._defaultNewFileName = assetNameForSaveAs?.split(".")[0];
        this._defaultNewFileExtension = assetNameForSaveAs?.split(".")[1];
    }

    protected async getDocData(doc: ELStageDoc): Promise<ImageData> {
        const docConverter = ELStageDocDataConverterFactory.createDataConverter(doc, DocumentConvertibleDataType.imageData);
        return await docConverter.convertToData() as ImageData;
    }

    protected async createDocument(viewType: ELViewType, imageData: ImageData): Promise<ELStageDoc> {
        try {
            if(viewType === ELViewType.after && this.mediaAutoScaled) {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("auto-scaled"));
            }
            const docPayload: ELStageDocPayload = { data: imageData, layerKind: ELLayerKind.pixel };
            const docFactoryPayload: DocumentFactoryPayload = {
                docPayload: docPayload,
                stagePayload: this._getStagePayload(viewType)
            }

            const doc = await DocumentFactory.createDocumentWithStage(DocumentType.stageDoc, this, docFactoryPayload) as ELStageDoc;
            return doc;
        } catch (error) {
            Logger.log(LogLevel.WARN, "ImageEditWorkflow: createDocument: Error creating stage document" + error);
            return Promise.reject(error);
        }
    }

    protected updateViewStatusAndProgressText(showProgress: boolean, progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELImageEditWorkflowViewAction.showProgress,
            payload: showProgress
        });

        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELImageEditWorkflowViewAction.progressText,
            payload: progressText
        });
    }

    protected async setupEditModules(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    protected async createAndRenderDoc(): Promise<void> {
        try {
            this.beforeDoc?.destroy();
            this.doc?.destroy();
            await this._createBeforeAfterDoc();
            if (this.doc && this.beforeDoc) {
                this.dualView = new ELDualDocumentView(this.beforeDoc);
                await this.dualView.createView(this.ensureHTMLElement(this._editDocContainerId));
                await this.dualView.renderAfterDoc(this.doc);
                store.dispatch(DualViewAction.updateView(ELViewType.after));
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELImageEditWorkflowViewAction.showProgress, payload: false });
            }
        } catch (error) {
            this.viewDispatcher?.call(this.viewDispatcher, { type: ELImageEditWorkflowViewAction.showProgress, payload: false });
            Logger.log(LogLevel.ERROR, "ImageEditWorkflow: _createAndRenderDoc: Couldn't render document" + error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("could-not-render-document"));
            this.startPreviousWorkflow();
            //GLIA_REVISIT - vib - Check ingest logging
            //this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }
    }

    async initialize(dispatch?: Dispatch<ViewAction> | undefined): Promise<void> {
        super.initialize(dispatch);
        this._createViewComponents();
        this._setupDoc();
        this._updateNewDefaultFileName();
        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.success, IngestEventTypes.render, true));
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const imageEditWorkflow: React.ReactElement = React.createElement(ImageEditWorkflowView, {
            controller: this
        });

        const reduxProviderEditWorkflow = React.createElement(ReactReduxProvider, { store: store }, imageEditWorkflow);
        const viewportReduxProviderEditWorkflow = React.createElement(ViewportProvider, {}, reduxProviderEditWorkflow);

        ReactDOM.render(viewportReduxProviderEditWorkflow, container);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this.beforeDoc?.destroy();
        this.beforeDoc = undefined;
        this.doc?.destroy();
        this.doc = undefined;
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CreationMediaActionType.replaceMedia: {
                handled = await this._triggerReplaceMediaWorkflow();
                break;
            }
            case ELImageEditWorkflowControllerAction.onSaveAsReplaceMediaDialog: {
                this.viewDispatcher?.call(this.viewDispatcher, {
                    type: ELImageEditWorkflowViewAction.updateReplaceMediaDialogState,
                    payload: false
                });
                const saveCompleteCallback = async (): Promise<void> => {
                    await this._invokeReplaceMediaManager();
                };
                const saveCancelCallback = async (): Promise<void> => {
                    this.viewDispatcher?.call(this.viewDispatcher, {
                        type: ELImageEditWorkflowViewAction.updateReplaceMediaDialogState,
                        payload: true
                    });
                };
                this._showSaveAsDialog(saveCompleteCallback, saveCancelCallback, IngestLogObjectValue.replaceMediaSaveCopy);
                handled = true;
                break;
            }
            case ELImageEditWorkflowControllerAction.onDontSaveReplaceMediaDialog: {
                this.viewDispatcher?.call(this.viewDispatcher, {
                    type: ELImageEditWorkflowViewAction.updateReplaceMediaDialogState,
                    payload: false
                });
                await this._invokeReplaceMediaManager();
                handled = true;
                break;
            }
            case ELImageEditWorkflowControllerAction.onDismissReplaceMediaDialog: {
                this.viewDispatcher?.call(this.viewDispatcher, {
                    type: ELImageEditWorkflowViewAction.updateReplaceMediaDialogState,
                    payload: false
                });
                handled = true;
                break;
            }

            case ELLooksPanelControllerActions.reset: {
                await this.docEditingManager?.resetEdit(Edit.adjustments);
                this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
                const looksResetIngestDone = sessionStorage.getItem("looksResetIngestDone");
                if (!looksResetIngestDone) {
                    this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.click, IngestEventTypes.looks, IngestLogObjectValue.reset));
                    sessionStorage.setItem("looksResetIngestDone", "true");
                }
                handled = true;
                break;
            }

            case DocumentActions.markDocumentDirty: {
                const isDirty = action.payload as DocumentDirty;
                if (this.doc?.isDocumentDirty === isDirty) {
                    this._onDocumentDirty(isDirty);
                }
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.back: {
                if (store.getState().docStateReducer.isDirty === DocumentDirty.DIRTY) {
                    const saveCompleteCallback = async (): Promise<void> => {
                        await this._backButtonClicked();
                    };
                    const saveCancelCallback = async (): Promise<void> => {
                        this.editHeader?.notify({ type: ELImageEditWorkflowHeaderViewAction.updateBackDialogState, payload: true });
                    };
                    this._showSaveAsDialog(saveCompleteCallback, saveCancelCallback, IngestLogObjectValue.exitSaveCopy);
                } else {
                    await this._backButtonClicked();
                }
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSave: {
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.backDialogDontSave));
                handled = true;
                await this._dontSaveAndBack();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.save: {
                this._showSaveAsDialog(undefined, undefined, IngestLogObjectValue.saveCopy);
                handled = true;
                break;
            }
            case EditWorkspaceAction.exitImageEditWorkflow: {
                await this._owner.notify(action);
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.showAfterImage: {
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.after));
                this.dualView?.showDoc(ELViewType.after);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                const imageData = action.payload as ELImageData;
                this._download(imageData);
                handled = true;
                break;
            }
            case ELAdobeAssetControllerAction.showBeforeImage: {
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.before));
                this.dualView?.showDoc(ELViewType.before);
                handled = true;
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceMediaSelection: {
                const replaceAssetInfo = action.payload as ReplaceAssetInfo;
                this.currentMedia = replaceAssetInfo.assetToReplaceWith;
                this.updateViewStatusAndProgressText(true, IntlHandler.getInstance().formatMessage("generating-output"));
                this._setupDoc();
                await this._onMediaChange();
                this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill:
            case CanvasZoomLevelAction.zoomToFit: {
                this.dualView?.synchronizeZoom({ type: CanvasZoomLevelAction.changeZoomValue, payload: DEFAULT_ZOOM_PERCENTAGE });
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue:
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent: {
                this.dualView?.synchronizeZoom(action);
                handled = true;
                break;
            }
            case DocumentActions.applyEdit: {
                const { editName, config } = action.payload as { editName: Edit, config: ELStageDocEditingConfig };
                await this._applyEdit(editName, config);
                handled = true;
                break;
            }
            case ELImageEditWorkflowControllerAction.showErrorDialog: {
                const { heading, description, learnMoreLink } = action.payload as Pick<ELEditErrorDialogViewProps, "heading" | "description" | "learnMoreLink">;
                this._showErrorDialog(heading, description, learnMoreLink);
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "Invalid action type clicked " + action.type + " in workflow " + this.workflowName);
                handled = false;
                break;
            }
        }
        if (!handled)
            handled = await super.notify(action);

        return handled;
    }

    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow | undefined, action?: T | undefined): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this.currentMedia = action?.payload as ELAdobeAsset;
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([this.currentMedia]));
        store.dispatch(DocActions.updateDocumentDirty(DocumentDirty.NON_DIRTY));
        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
    }
}

export default ImageEditWorkflow;
