/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import { keys } from "lodash";

//Application specific
import { EditorInputDataType } from "../../common/interfaces/editing/EditorTypes";
import { AdjustmentsConfig, AdjustmentsParams, AdjustmentsParamsMinMax } from "../../common/interfaces/editing/adjustments/AdjustmentsEditorTypes";
import { GLiaConfig } from "../../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { GLia, GLiaParams } from "../../libraries/glia";
import ImageUtils from "../../utils/ImageUtils";
import Logger, { LogLevel } from "../../utils/Logger";
import { IEditor } from "../IEditor";
import { GLiaEditingEngine } from "../editingEngines/GliaEditingEngine";
import Utils from "../../utils/Utils";
import { LoadTimeMarkers } from "../../common/interfaces/performance/ELPerfTypes";

const adjustmentsParamsMinMax: AdjustmentsParamsMinMax = {
    temperature: { min: -100, max: 100 },
    tint: { min: -100, max: 100 },
    exposure: { min: -5, max: 5 },
    contrast: { min: -100, max: 100 },
    highlights: { min: -100, max: 100 },
    shadows: { min: -100, max: 100 },
    whites: { min: -100, max: 100 },
    blacks: { min: -100, max: 100 },
    clarity: { min: -100, max: 100 },
    vibrance: { min: -100, max: 100 },
    saturation: { min: -100, max: 100 },
    vignette_amount: { min: -100, max: 100 },
    vignette_roundness: { min: -100, max: 100 },
    vignette_midpoint: { min: -100, max: 100 },
    vignette_feather: { min: -100, max: 100 },
    vignette_highlight: { min: -100, max: 100 },
}

export class AdjustmentsEditor implements IEditor<ImageData, AdjustmentsConfig> {
    private _gliaEditingEngine?: GLiaEditingEngine;
    private _gliaModule?: GLia;
    private _canvas: OffscreenCanvas | HTMLCanvasElement;
    private _isInitialized = false;

    constructor() {
        //TODO GLIA_REVISIT - Check performance safari and if we need to create new canvas.
        const size = { width: 256, height: 256 };
        this._canvas = ImageUtils.createOffscreenCanvasElementForSize(size);
    }

    private _getWebGLContext(): WebGLRenderingContext {
        return this._canvas.getContext("webgl", {
            preserveDrawingBuffer: true,
            premultipliedAlpha: false,
        }) as WebGLRenderingContext;
    }

    private async _setGLiaModule(): Promise<void> {
        if (this._gliaEditingEngine) {
            await this._gliaEditingEngine.ready();
            this._gliaModule = this._gliaEditingEngine.getModule();
        } else {
            Logger.log(LogLevel.WARN, "AdjustmentsEditor: _setGLiaModule: GLia editing engine not initialized");
            return Promise.reject("GLia editing engine not initialized");
        }
    }

    private async _setGLiaImage(image: ImageData): Promise<void> {
        if(!this._gliaModule) {
            Logger.log(LogLevel.WARN, "AdjustmentsEditor: _setGLiaImage: GLia module not set");
            return Promise.reject("GLia module not set");
        }

        try {
            const imageBitmap = await createImageBitmap(image);
            const imageInfoMaps = await this._gliaModule?.getMapsForImageBitmap(imageBitmap);

            this._resizeCanvas(image.width, image.height);
            if (imageInfoMaps) {
                this._gliaModule?.setImage(
                    imageBitmap,
                    imageInfoMaps.blurMap,
                    imageInfoMaps.toneMap,
                    imageInfoMaps.srcStats,
                    imageInfoMaps.dstStats
                );
            }
            Logger.log(LogLevel.DEBUG, "AdjustmentsEditor: _setGLiaImage: Image set successfully");
        } catch (error: any) {
            Logger.log(LogLevel.WARN, "AdjustmentsEditor: _initGLia: Error initializing glia with image " + error);
            return Promise.reject("Error initializing glia with image");
        }
    }

    private _resizeCanvas(width: number, height: number): void {
        this._canvas.width = width;
        this._canvas.height = height;
    }

    private _getImageDataFromCanvas(): ImageData {
        const gl = this._getWebGLContext();
        const width = this._canvas.width;
        const height = this._canvas.height;
        const pixels = new Uint8Array(width * height * 4);
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
        Logger.log(LogLevel.DEBUG, "AdjustmentsEditor: _getImageDataFromCanvas: Image data read successfully");
        return new ImageData(new Uint8ClampedArray(pixels), width, height);
    }

    private _setGLiaParams(gliaParams: GLiaParams, adjustmentsParams: AdjustmentsParams): void {
        gliaParams.temperature = adjustmentsParams.temperature ?? 0.0;
        gliaParams.tint = adjustmentsParams.tint ?? 0.0
        gliaParams.exposure = adjustmentsParams.exposure ?? 0.0
        gliaParams.contrast = adjustmentsParams.contrast ?? 0.0
        gliaParams.highlights = adjustmentsParams.highlights ?? 0.0
        gliaParams.shadows = adjustmentsParams.shadows ?? 0.0
        gliaParams.whites = adjustmentsParams.whites ?? 0.0
        gliaParams.blacks = adjustmentsParams.blacks ?? 0.0
        gliaParams.clarity = adjustmentsParams.clarity ?? 0.0
        gliaParams.vibrance = adjustmentsParams.vibrance ?? 0.0
        gliaParams.saturation = adjustmentsParams.saturation ?? 0.0
    }

    private _setBoundedParams(key: string, value: number, intensity: number): number {
        const minMax = adjustmentsParamsMinMax[key];
        if (minMax === undefined) {
            throw Error("_setBoundedParams::unknown param key")
        }
        return Math.max(minMax.min, Math.min(minMax.max, value * intensity));
    }

    private _processedAdjustmentParams(config: AdjustmentsConfig): AdjustmentsParams {
        const params = config.params;
        const intensity = config.intensity;
        const processedParams: AdjustmentsParams = {};
        keys(params).forEach(key => {
            const value = params[key];
            if (value === undefined) {
                throw Error("_processedAdjustmentParams::unknown param key");
            }
            processedParams[key] = this._setBoundedParams(key, value, intensity);
        })
        return processedParams;
    }

    private _applyAdjustment(config: AdjustmentsConfig): void {
        try {
            const gliaParams = new GLiaParams();
            const processedParams = this._processedAdjustmentParams(config);
            this._setGLiaParams(gliaParams, processedParams);
            this._gliaModule?.setParams(gliaParams);
            this._gliaModule?.drawFilter();
            Logger.log(LogLevel.DEBUG, "AdjustmentsEditor: _applyAdjustment: Adjustment applied successfully");
        } catch (error) {
            Logger.log(LogLevel.WARN, "AdjustmentsEditor: _applyParams: Error applying params " + error);
            throw(new Error("Error applying adjustment params"));
        }
    }

    async initialize(): Promise<void> {
        Logger.log(LogLevel.DEBUG, "AdjustmentsEditor: initialize called");
        const webGLContext = this._getWebGLContext();

        if (webGLContext) {
            const gliaConfig: GLiaConfig = {
                ctx: webGLContext
            }

            //GLIA_REVISIT - vib - Using editing engine manager to initialize GLiaEditingEngine
            this._gliaEditingEngine = new GLiaEditingEngine();
            await this._gliaEditingEngine.initialize(gliaConfig);
            this._isInitialized = true;
        } else {
            Logger.log(LogLevel.INFO, "AdjustmentsEditor: initialize: webgl not supported");
            return Promise.reject("webgl not supported");
        }
    }

    async applyEdit(image: ImageData, config: AdjustmentsConfig): Promise<ImageData> {
        try {
            //GLIA_REVISIT - vib - Remove performance checks
            const uniquePerfId = Utils.getRandomUUID();
            globalThis.perfMonitor.beginAction(
                LoadTimeMarkers.applyAdjustments,
                LoadTimeMarkers.applyAdjustments,
                uniquePerfId,
                undefined,
                {
                    marked: true
                }
            );
            if(!this._isInitialized) {
                Promise.reject("AdjustmentsEditor not initialized");
            }
            if (!this._gliaModule)
                await this._setGLiaModule();
            await this._setGLiaImage(image);
            this._applyAdjustment(config);
            const imageData = this._getImageDataFromCanvas();
            globalThis.perfMonitor.endAction(uniquePerfId);
            return imageData;
        } catch (error: any) {
            Logger.log(LogLevel.WARN, "AdjustmentsEditor: applyEdit: Error applying adjustments " + error);
            return Promise.reject(error);
        }
    }

    async shutdown(): Promise<void> {
        await this._gliaEditingEngine?.shutdown();
    }

    getInputDataType(): EditorInputDataType {
        return EditorInputDataType.imageData;
    }
}