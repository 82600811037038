/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { WorkflowsName } from "../../../../workspaces/IWorkflow";
import Image1 from "./../../../../assets/sampleMedia/Kid.jpeg";
import Image2 from "./../../../../assets/sampleMedia/Bird On Hand.jpeg";
import Image3 from "./../../../../assets/sampleMedia/Girl.jpeg";

//TODO: ashishsingla, Repalce this with the actual data received from Vikas
export const sampleMediaData = [
    {
        data: {
            src: Image1,
            alt: "Kid",
        },
        useInCreation: [WorkflowsName.patternOverlay, WorkflowsName.replaceBackground]
    },
    {
        data: {
            src: Image2,
            alt: "Bird On Hand",
        },
        useInCreation: [WorkflowsName.patternOverlay, WorkflowsName.replaceBackground]
    },
    {
        data: {
            src: Image3,
            alt: "Girl",
        },
        useInCreation: [WorkflowsName.patternOverlay, WorkflowsName.peekThrough]
    }
];
