/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { ReactNode } from "react";

//Adobe internal
import Provider from "@react/react-spectrum/Provider";

//Application specific
import { AppCode, LocaleUtils } from "../../../../utils/LocaleUtils";
import { FontUtils } from "../../../../utils/FontUtils";
import Utils from "../../../../utils/Utils";
import { LocaleType } from "../../../../common/interfaces/intl/LocaleTypes";

/**
 * LOC-TODO took these props from src/types/react-spectrum/index.d.ts, need to revisit if we can directly import the props from there
 * Error "Type 'LegacyRef<HTMLDivElement> | undefined' is not assignable to type 'LegacyRef<Provider> | undefined'." coming on doing this change
*/
interface ELReactSpectrumV2ProviderProps {
    theme?: "light" | "lightest" | "dark" | "darkest"; 
    scale?: "medium" | "large";
    toastPlacement?:
        | "top"
        | "top left"
        | "top center"
        | "top right"
        | "bottom"
        | "bottom left"
        | "bottom center"
        | "bottom right";
    typekitId?: string;
    locale?: string;
    className?: string;
    children?: ReactNode,
    id?: string
}

export const ELReactSpectrumV2Provider = (props: ELReactSpectrumV2ProviderProps): React.ReactElement => {
    const reactSpectrumLocale = props.locale ?? LocaleUtils.getAppLocale(AppCode.reactSpectrum);
    const typekitId = props.typekitId ?? FontUtils.getTypeKitId(Utils.getCurrentLocaleInSnakeCase() as LocaleType);

    return (
        <Provider locale={reactSpectrumLocale} typekitId={typekitId} theme={props.theme ?? "light"} {...props}></Provider>
    )
}