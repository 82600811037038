/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ShareWorkspace } from "./dialog/shareDialog/ShareWorkspace";
import ModalWorkflowWrapper from "./common/workspaces/modal/ModalWorkflowWrapper";
import IModalWorkspace, { ModalWorkspaceName } from "./IModalWorkspace";
import IBaseWorkspace from "./IBaseWorkspace";
import { SampleMediaWorkspace } from "./dialog/sampleMedia/SampleMediaWorkspace";

export const ModalWorkspaceMap: { [key in ModalWorkspaceName]: any } = {
    [ModalWorkspaceName.share]: ShareWorkspace,
    [ModalWorkspaceName.modalWrapper]: ModalWorkflowWrapper,
    [ModalWorkspaceName.sampleMedia]: SampleMediaWorkspace
};

export default abstract class ModalWorkspaceFactory {
    static createModalWorkspace(modalWorkspace: ModalWorkspaceName, workspace: IBaseWorkspace, overlayDiv: HTMLDivElement, dialogClass?: string): IModalWorkspace {
        return new ModalWorkspaceMap[modalWorkspace](workspace, overlayDiv, dialogClass);
    }
}
