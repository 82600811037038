/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//Adobe Internal
import { RenditionOptions, RenditionType } from "@dcx/common-types";

//Application Specific
import Logger, { LogLevel } from "../Logger";
import { AssetStorageUtils, DataResponseType, MediaDataResponse } from "../AssetStorageUtils";
import { ELAdobeAsset, elDeserializeAsset, RenditionData } from "../../common/interfaces/storage/AssetTypes";
import Utils from "../Utils";
import SIVAction, { SIVDataPayload } from "../../stores/actions/SIVAction";
import { RootState } from "../../stores/store";
import { PROCESSING_THUMBDATA } from "../Constants/Constants";
import { RenditionState } from "../../common/interfaces/hooks/RenditionFetchTypes";
import { ELRenditionType } from "@elements/elementswebcommon";

export interface ELAssetProps {
    current: ELAdobeAsset | undefined,
    previous?: ELAdobeAsset | undefined,
    next?: ELAdobeAsset | undefined
}

type fetchRenditionType = (asset: ELAdobeAsset, renditionOptions: RenditionOptions, responseType: DataResponseType) => Promise<MediaDataResponse>;

interface useRenditionParams {
    fetchRendition: fetchRenditionType,
    assetProps: ELAssetProps
}

interface RenditionParams {
    fetchRendition: fetchRenditionType,
    asset: ELAdobeAsset | undefined
}

interface RenditionFetchHook {
    renditionState: RenditionState,
    fetchAndCacheRenditionWithAbortFunction: (renditionProps: useRenditionParams, retryFetchCount: number) => FetchAndCacheRenditionWithAbort;
}

interface FetchAndCacheRenditionWithAbort {
    fetchAndCacheRenditionData: (renditionProps: useRenditionParams) => Promise<RenditionData>,
    abortFetchRendition: () => void;
}

const useRenditionFetch = (): RenditionFetchHook => {
    const dispatch = useDispatch();
    const [renditionState, setRenditionState] = useState(RenditionState.isFetching);
    const sivData = useSelector((state: RootState) => state.sivReducer);

    const getRenditionDataFromStore = (asset: ELAdobeAsset): RenditionData => {
        if (sivData) {
            for (let idx = 0; idx < sivData.sivRenditionData.length; idx++) {
                if (sivData.sivRenditionData[idx].assetId === asset.assetId) {
                    return sivData.sivRenditionData[idx].renditionData;
                }
            }
        }

        return { imgData: "" };
    };

    const updateStoreData = (asset: ELAdobeAsset, data: RenditionData): void => {

        if (!asset.assetId || Utils.isMalformedThumbData(data.imgData)) {
            return;
        }
        if (data.videoData && Utils.isMalformedThumbData(data.videoData)) {
            return;
        }

        const sivData: SIVDataPayload = {
            assetId: asset.assetId,
            renditionData: data
        };

        dispatch(SIVAction.updateData(sivData));
    };

    const fetchRenditionData = async (renditionParam: RenditionParams): Promise<RenditionData> => {
        if (renditionParam.asset === undefined) {
            return Promise.reject();
        }

        const deserializedAsset = elDeserializeAsset(renditionParam.asset);

        const data = getRenditionDataFromStore(deserializedAsset);
        if (data.imgData !== "") {
            return Promise.resolve(data);
        }

        const isVideoMimeType = Utils.isVideoMimeType(deserializedAsset);
        const renditionType = isVideoMimeType ? ELRenditionType.VIDEO_METADATA : ELRenditionType.IMAGE_JPG;
        const responseType = (isVideoMimeType || AssetStorageUtils.isLargeSizeGif(deserializedAsset)) ? "json" : "arraybuffer";
        const renditionOpts = {
            size: isVideoMimeType ? 0 : Math.max(window.screen.width, window.screen.height),
            type: renditionType as RenditionType
        };
        try {
            const response = await renditionParam.fetchRendition(deserializedAsset, renditionOpts, responseType);
            if (isVideoMimeType) {
                const data = response.data as RenditionData;
                updateStoreData(deserializedAsset, data);

                return Promise.resolve(data);
            } else {
                const data = { imgData: AssetStorageUtils.getRenditionData(deserializedAsset, response) } as RenditionData;
                updateStoreData(deserializedAsset, data);
                return Promise.resolve(data);
            }
        } catch (error) {
            Logger.log(LogLevel.WARN, "useRenditionFetch:fetchRenditionDataUtil: Unable to fetch rendition", error);
            return Promise.reject();
        }
    };

    const fetchAndCacheRenditionDataWithAbortFunction = (renditionProps: useRenditionParams, retryFetchCount = 0): FetchAndCacheRenditionWithAbort => {
        let retryFetchCountLeft = retryFetchCount;
        let isFetchingAborted = false;
        const REFETCH_TIME = 3000;
        setRenditionState(RenditionState.isFetching);

        const initRenditionProps = renditionProps;

        const fetchAndCacheRenditionData = async (renditionProps: useRenditionParams): Promise<RenditionData> => {
            const currentAsset = renditionProps.assetProps.current;
            const renditionParam: RenditionParams = {
                asset: currentAsset,
                fetchRendition: renditionProps.fetchRendition
            };
            try {
                const data: RenditionData = await fetchRenditionData(renditionParam);
                renditionProps.assetProps.next &&
                    fetchRenditionData({ asset: renditionProps.assetProps.next, fetchRendition: renditionProps.fetchRendition });
                renditionProps.assetProps.previous &&
                    fetchRenditionData({ asset: renditionProps.assetProps.previous, fetchRendition: renditionProps.fetchRendition });

                if (data.imgData === PROCESSING_THUMBDATA && data.imgData === PROCESSING_THUMBDATA) {
                    if (retryFetchCountLeft <= 0) {
                        if(isFetchingAborted) {
                            const data: RenditionData = {
                                imgData: undefined,  
                                videoData: undefined
                            }
                            return Promise.resolve(data);
                        }
                        setRenditionState(RenditionState.error);
                        return Promise.reject();
                    } else {
                        retryFetchCountLeft--;
                        setRenditionState(RenditionState.inProcessing);
                        await Utils.wait(REFETCH_TIME);
                        const assetProps: ELAssetProps = {
                            current: initRenditionProps.assetProps.current
                        };
                        const data: RenditionData = await fetchAndCacheRenditionData({ assetProps: assetProps, fetchRendition: renditionProps.fetchRendition });
                        return Promise.resolve(data);
                    }
                }
                setRenditionState(RenditionState.fetched);
                return Promise.resolve(data);
            } catch (error) {
                setRenditionState(RenditionState.error);
                Logger.log(LogLevel.ERROR, "useRenditionFetch:fetchAndCacheRenditionData: ", error);
                return Promise.reject();
            }
        };

        const abortFetchRendition = (): void => {
            retryFetchCountLeft = 0;
            isFetchingAborted = true;
        };

        return {
            fetchAndCacheRenditionData: fetchAndCacheRenditionData,
            abortFetchRendition: abortFetchRendition
        };
    };

    const useRenditionFetchObj: RenditionFetchHook = {
        renditionState: renditionState,
        fetchAndCacheRenditionWithAbortFunction: fetchAndCacheRenditionDataWithAbortFunction
    };

    return useRenditionFetchObj;
};

export default useRenditionFetch;