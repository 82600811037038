/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

//Adobe Internal
import {
    Menu,
    ActionButton,
    Item,
    MenuTrigger
} from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes, INGEST_PROFILE_WORKSPACE } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELFooterLangMenu.scss";

interface ELFooterLangMenuProps {
    selectedLocale: string;
}

const LocaleMap: Record<string, string> = {
    "en-US": "English",
    "ja-JP": "日本語",
    "de-DE": "Deutsch",
    "fr-FR": "Français"
};

interface ELChangeLocaleItem {
    key: string,
    name: string;
}

const ELChangeLocaleList: ELChangeLocaleItem[] = [
    {
        key: "en-US",
        name: LocaleMap["en-US"]
    },
    {
        key: "ja-JP",
        name: LocaleMap["ja-JP"]
    },
    {
        key: "de-DE",
        name: LocaleMap["de-DE"]
    },
    {
        key: "fr-FR",
        name: LocaleMap["fr-FR"]
    },
];

export const ELFooterLangMenuView = (props: ELFooterLangMenuProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const onLanguageChange = async (key: React.Key): Promise<void> => {
        const languagePickerIngestObject = IngestUtils.addWorkspaceDetail(INGEST_PROFILE_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
			IngestEventTypes.change, IngestEventSubTypes.languagePreference, key));
		IngestLogging.getInstance().logEvent(languagePickerIngestObject);

        await intlHandler.languagePickerChange(key as string);
        window.location.reload();
    }

    return (
        <>
            <span>
                {intlHandler.formatMessage("footer-lang")}
            </span>
            <ELReactSpectrumV3Provider height="100%" width="100%">
                <MenuTrigger>
                    <ActionButton UNSAFE_className="el-footer-lang-menu__button">
                        <span className="el-footer-lang-menu__span">
                            {LocaleMap[props.selectedLocale]}
                        </span>
                    </ActionButton>
                    <Menu
                        selectionMode="single"
                        selectedKeys={new Set([props.selectedLocale])}
                        disabledKeys={new Set([props.selectedLocale])}
                        onAction={onLanguageChange}
                        items={ELChangeLocaleList}
                    >
                        {item => <Item key={item.key}>{item.name}</Item>}
                    </Menu>
                </MenuTrigger>
            </ELReactSpectrumV3Provider>
        </>
    );
};