/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { PropsWithChildren, useCallback, useEffect, useReducer, useRef, useState } from "react"
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

//Adobe Internal
import { ProgressCircle, Text } from "@adobe/react-spectrum";

// Application specific
import store, { RootState } from "../../../../stores/store";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Logger, { LogLevel } from "../../../../utils/Logger";
import ELMediaThumb from "../../atoms/el-media-thumb/ELMediaThumbView";
import ELMediaGrid, {
    MediaGridHandler,
    ELMediaTileData,
    ElementType,
    GetMediaFetchHookFunc,
    SetSelectedMediaAssetsFunc,
    VisibleElementType,
    ELSectionHeaderData,
    ELAnchorData,
    ELMediaGridControllerAction
} from "./ELMediaGrid";
import IViewController from "../../../IViewController";
import SelectedMediaAssetsAction from "../../../../stores/actions/selectedMediaListActions";
import { SelectedMediaListType } from "./ELMediaGrid";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import { ELAdobeAsset, elDeserializeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { UserInfoProvider } from "../../../../utils/hooks/useAssetUserInfo";
import { ViewAction } from "../../../IBaseController";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import Utils from "../../../../utils/Utils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import MediaGridToolbarAction from "../../../../stores/actions/mediaGridToolbarAction";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import UploadedMediaListAction from "../../../../stores/actions/uploadedMediaListActions";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { ELMediaSelectionMode } from "../../../../common/interfaces/media/ELThumbTypes";
import { ToastUtils } from "../../../../utils/ToastUtils";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";

import "./ELMediaGrid.scss";

export enum ELMediaGridViewAction {
    setPendingDirFetchFlag = "SET_PENDING_DIR_FETCH",
    setVisibleElements = "SET_VISIBLE_ELEMENTS",
    setInSelectionMode = "SET_IN_SELECTION_MODE",
    reset = "RESET"
}

export interface ELMediaGridLayoutConfig {
    TILE_W: number,
    TILE_H: number,
    SECTION_HEADING_H: number,
    BUF_SIZE: number,
    Y_DIST_BETWEEN_TILE: number,
    X_DIST_BETWEEN_TILE: number,
    Y_DIST_BETWEEN_SECTION: number,
    OFFSET_PERCENTAGE_X: number,
    Y_OFFSET_TOP: number,
    Y_OFFSET_BOTTOM: number
}

export interface ELMediaGridProps {
    getMediaFetchHook: GetMediaFetchHookFunc,
    dirPath: string,
    layoutConfig: ELMediaGridLayoutConfig,
    createTracks: boolean,
    controller: ELMediaGrid,
    setSelectedMediaAssets?: SetSelectedMediaAssetsFunc,
    toolbar?: IViewController,
    emptyGridBanner: React.ReactElement,
    selectionEnabled: boolean,
    selectionMode: ELMediaSelectionMode
}

export const EL_MEDIA_GRID_SCROLL_THROTTLE_TIME = 200;

interface ELGridTileProps {
    positionX: number;
    positionY: number;
    asset: ELAdobeAsset,
    assethumbRequestFunc: (asset: ELAdobeAsset) => Promise<void>,
    toggleMediaTileSelectionFunc: (asset: ELAdobeAsset) => void,
    isSelected: boolean,
    TILE_H: number,
    TILE_W: number,
    inSelectionMode: boolean,
    selectionEnabled: boolean,
    callback: (asset: ELAdobeAsset) => void,
    isHidden?: boolean,
    isDisabled?: boolean,
    selectionMode: ELMediaSelectionMode
}

interface ELSectionHeaderProps {
    positionX: number,
    positionY: number,
    title: string,
    SECTION_HEADING_H: number
}

interface ELGridAnchorProps {
    positionX: number,
    positionY: number
}

const ELGridTile = (props: ELGridTileProps): React.ReactElement<ELGridTileProps> => {
    const data = useSelector((state: RootState) => state.mediaThumbReducer);
    const [sThumbData, setThumbData] = useState<string>("");

    const onThumbPress = (asset: ELAdobeAsset): void => {
        props.callback(asset);
    }

    useEffect(() => {
        const getThumbDataForAsset = (): void => {
            const assetThumb = data.filter((ele) => ele.assetId === props.asset.assetId);
            if (assetThumb.length > 0) {
                setThumbData(assetThumb[0].objectURL);
                return;
            }

            props.assethumbRequestFunc(props.asset);
        }
        if (sThumbData.length === 0) {
            getThumbDataForAsset();
        }
    }, [data, props.asset.assetId]);

    const getGridTileClass = (): string => {
        let defaultClass = "grid-media-thumb-container";
        if (props.isHidden) {
            defaultClass += " no-display";
        } else if (props.isDisabled) {
            defaultClass += " disabled";
        }

        return defaultClass;
    }

    return (
        <div className={getGridTileClass()}
            style={{
                position: "absolute",
                top: `${props.positionY}px`,
                left: `${props.positionX}px`
            }}>
            <ELMediaThumb height={props.TILE_H}
                width={props.TILE_W}
                asset={props.asset}
                mediaName={props.asset.name}
                toggleMediaTileSelectionFunc={props.toggleMediaTileSelectionFunc}
                isSelected={props.isSelected}
                base64Img={sThumbData}
                inSelectionMode={props.inSelectionMode}
                selectionEnabled={props.selectionEnabled}
                onPress={onThumbPress}
                selectionMode={props.selectionMode}
            />
        </div>
    );
}

const ELSectionHeader = (props: ELSectionHeaderProps): React.ReactElement<ELSectionHeaderProps> => {

    const intlHandler = IntlHandler.getInstance();

    let header = "";
    try {
        const titleDate = new Date(props.title);
        const isTodaysDate = Utils.isTodaysDate(titleDate);
        header = isTodaysDate ? intlHandler.formatMessage("today") : intlHandler.formatDate(titleDate, { year: "numeric", month: "long" });
    } catch (err) {
        header = "";
    }
    return (
        <div className="grid-section-header" style={{
            height: props.SECTION_HEADING_H + "rem",
            left: props.positionX + 'px',
            top: props.positionY + 'px'
        }}>
            {header}
        </div>
    )
}

const ELGridAnchor = (props: ELGridAnchorProps): React.ReactElement<ELGridAnchorProps> => {
    return (
        <div className="grid-anchor" style={{
            left: props.positionX + 'px',
            top: props.positionY + 'px'
        }}>
        </div>
    );
}

const tileElementsPresent = (visibleElementList: VisibleElementType[]): boolean => {
    for (const visibleElement of visibleElementList) {
        if (visibleElement.elementType === ElementType.tile) {
            return true;
        }
    }
    return false;
}

interface ELMediaGridViewState {
    sIsPendingDirFetch: boolean,
    sVisibleElements: VisibleElementType[],
    sInSelectionMode: boolean
}

const init = (): ELMediaGridViewState => {
    return {
        sIsPendingDirFetch: false,
        sVisibleElements: [],
        sInSelectionMode: false
    }
}

const reducer = (state: ELMediaGridViewState, action: ViewAction): ELMediaGridViewState => {
    const newState = { ...state };
    switch (action.type) {
        case ELMediaGridViewAction.setPendingDirFetchFlag:
            if (typeof action.payload === "boolean")
                newState.sIsPendingDirFetch = action.payload;
            return newState;
        case ELMediaGridViewAction.setVisibleElements:
            if (Array.isArray(action.payload))
                newState.sVisibleElements = action.payload;
            return newState;
        case ELMediaGridViewAction.setInSelectionMode:
            if (typeof action.payload === "boolean")
                newState.sInSelectionMode = action.payload;
            return newState;
        default:
            return init();
    }
}

const ELMediaGridView = (props: PropsWithChildren<ELMediaGridProps>): React.ReactElement<PropsWithChildren<ELMediaGridProps>> => {
    const { selectedAssetsList, rootData, configData, uploadedMediaData } = useSelector((state: RootState) => {
        return {
            selectedAssetsList: state.selectedMediaListReducer,
            rootData: state.mediaOrganizerReducer,
            configData: state.mediaConfigReducer[GRID_CONFIG_KEY],
            uploadedMediaData: state.uploadedMediaListReducer
        }
    });
    const sivData = useSelector((state: RootState) => state.sivReducer);
    const [state, viewDispatch] = useReducer(reducer, init, init);
    const setPendingDirFetchTrue = (): void => {
        viewDispatch({ type: ELMediaGridViewAction.setPendingDirFetchFlag, payload: true });
    }
    const setVisibleElements = (arr: VisibleElementType[]): void => {
        viewDispatch({ type: ELMediaGridViewAction.setVisibleElements, payload: arr });
    }

    const containerRef = useRef<HTMLDivElement>(null);
    const loadingCircleRef = useRef<HTMLDivElement>(null);
    const dataBuffer = useRef<ELAdobeAsset[]>([]);
    const prevStoreKey = useRef<string>("");
    const [shiftPressed, setShiftPressed] = useState(false);
    const [anchorAsset, setAnchorAsset] = useState({} as ELAdobeAsset);
    const [shiftSelectedAssetIds, setShiftSelectedAssetIds] = useState([] as any[]);

    const rootDispatch = useDispatch();
    const { fetchData, isFetching, storeKey, pushAssetInThumbQueue } = props.getMediaFetchHook(props.dirPath);

    const visibleElements = state.sVisibleElements.filter(item => {
        return item.elementType === ElementType.tile && !((item as ELMediaTileData).isHidden);
    });
    const visibleAssets = visibleElements.map(element => (element as ELMediaTileData).asset);
    const updateSelectedMediaAssets = (selectedMediaList: SelectedMediaListType): void => {
        rootDispatch(SelectedMediaAssetsAction.updateSelectedMediaList(selectedMediaList));
    }

    //selectOrUnselectClickedItem is to be used only in toggleMediaTileSelection. Using it independently can lead to a change in anchorAsset.
    const selectOrUnselectClickedItem = (asset: ELAdobeAsset): void => {
        const result = selectedAssetsList.filter(item => {
            return item.assetId !== asset.assetId;
        });
        if (result.length === selectedAssetsList.length) {
            setAnchorAsset(asset);
            result.push(asset);
        }
        else {
            setAnchorAsset({});
        }
        updateSelectedMediaAssets(result);
    }

    const shiftUnselectItems = (selectedAssetsMap: Map<string | undefined, ELAdobeAsset>): void => {
        shiftSelectedAssetIds.forEach(assetId => selectedAssetsMap.delete(assetId));
        setShiftSelectedAssetIds([]);
    }

    const findIndexesofAnchorandSelectedAsset = (asset: ELAdobeAsset): [number, number] => {
        let startIndex = -1, endIndex = -1;
        visibleAssets.some((element, index) => {
            if (anchorAsset?.assetId === element.assetId)
                startIndex = index;
            if (asset.assetId === element.assetId)
                endIndex = index;

            if (startIndex !== -1 && endIndex !== -1)
                return true;
            else
                return false;
        });
        return [startIndex, endIndex];
    }

    //TODO: Shift selection is not optimized as we need to find the index of each selected or unselected item
    const shiftSelectItems = (asset: ELAdobeAsset, selectedAssetsMap: Map<string | undefined, ELAdobeAsset>): void => {
        const [startIndex, endIndex] = findIndexesofAnchorandSelectedAsset(asset);
        if (startIndex !== -1 && endIndex !== -1) {
            const min_asset_index = Math.min(startIndex, endIndex);
            const max_asset_index = Math.max(startIndex, endIndex);
            const newAssetIds = [...shiftSelectedAssetIds];
            for (let i = min_asset_index; i < max_asset_index + 1; i++) {
                newAssetIds.push(visibleAssets[i]?.assetId)
                if (!selectedAssetsMap.has(visibleAssets[i]?.assetId))
                    selectedAssetsMap.set(visibleAssets[i]?.assetId, visibleAssets[i]);
            }
            setShiftSelectedAssetIds(newAssetIds);
            const selectedAssets = Array.from(selectedAssetsMap.values());
            updateSelectedMediaAssets(selectedAssets);
        }
    }

    const toggleMediaTileSelection = (asset: ELAdobeAsset): void => {
        if (shiftPressed && anchorAsset?.assetId) {
            const selectedAssetsMap: Map<string | undefined, ELAdobeAsset> = new Map();
            selectedAssetsList.forEach(item => selectedAssetsMap.set(item.assetId, item));

            shiftUnselectItems(selectedAssetsMap);
            shiftSelectItems(asset, selectedAssetsMap);
        }
        else {
            setShiftSelectedAssetIds([]);
            selectOrUnselectClickedItem(asset);
        }
    }
    const ingest = async (logObject: Record<string, string>): Promise<void> => {
        props.controller.notify({
            type: WorkflowActionType.ingest,
            payload: logObject
        });
    }
    const mediaGridHandlerRef = useRef<MediaGridHandler>(new MediaGridHandler(setVisibleElements, setPendingDirFetchTrue, props.layoutConfig, ingest));

    const setFetchFlag = (flag: boolean): void => {
        viewDispatch({ type: ELMediaGridViewAction.setPendingDirFetchFlag, payload: flag });
        mediaGridHandlerRef.current.setPendingDirFetch(flag);
    }

    const sivCallback = (asset: ELAdobeAsset): void => {

        props.controller.notify({
            type: ELMediaGridControllerAction.startSIV,
            payload: {
                asset: asset,
                getMediaFetchHook: props.getMediaFetchHook,
                dirPath: props.dirPath,
                storeKey: storeKey
            }
        });
    }

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === KeyboardKey.shift) {
            setShiftPressed(true);
        }
    }, []);

    const handleKeyUp = useCallback((event: KeyboardEvent) => {
        if (event.key === KeyboardKey.shift) {
            setShiftPressed(false);
        }
    }, []);

    const updateSelectedMedia = (): void => {
        if (rootData[storeKey] && rootData[storeKey].children) {
            const newAssetList: ELAdobeAsset[] = [];
            const dirResult = rootData[storeKey];
            for (let i = 0; i < dirResult.children.length; i++) {
                const asset = elDeserializeAsset(dirResult.children[i]) as ELAdobeAsset;
                if (asset.name && uploadedMediaData.includes(asset.name)) {
                    newAssetList.push(asset);
                }
            }
            const filteredNewAssetList = CreationUtils.filterMediaByFormat(newAssetList, configData);
            if (filteredNewAssetList.length < newAssetList.length) {
                const message = IntlHandler.getInstance().formatMessage("creation-media-skipped");
                ToastUtils.info(message);
            }
            store.dispatch(SelectedMediaListAction.updateSelectedMediaList([...selectedAssetsList, ...filteredNewAssetList]));
            store.dispatch(UploadedMediaListAction.clearMedia());
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        }
    }, []);

    useEffect(() => {
        if (selectedAssetsList.length === 0) {
            setAnchorAsset({});
            setShiftSelectedAssetIds([]);
        }
    }, [selectedAssetsList.length]);

    useEffect(() => {
        Logger.log(LogLevel.DEBUG, "Media Grid Mount");
        mediaGridHandlerRef.current.setContainerRef(containerRef);
        //clean up
        return () => {
            Logger.log(LogLevel.DEBUG, "Media Grid Unmount");
            mediaGridHandlerRef.current.removeEventListeners();
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        if (state.sIsPendingDirFetch) {
            fetchData();
            setFetchFlag(false);
        }
    }, [state.sIsPendingDirFetch, fetchData]);

    useEffect(() => {
        if (rootData[storeKey] && rootData[storeKey].children) {
            if ((storeKey !== prevStoreKey.current) || !(_.isEqual(rootData[storeKey].children, dataBuffer.current))) {
                dataBuffer.current = rootData[storeKey].children;
                prevStoreKey.current = storeKey;
                mediaGridHandlerRef.current.setData(rootData[storeKey].children, props.createTracks, configData);
                setFetchFlag(false);
                ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.mediaGridHasMoreData,
                    IngestEventTypes.info, IngestEventSubTypes.count, rootData[storeKey].hasNextPage));
            }
        } else {
            setFetchFlag(true);
        }
    }, [rootData, storeKey]);

    useEffect(() => {
        updateSelectedMedia();
    }, [rootData[storeKey]]);

    useEffect(() => {
        const inSelectionMode = selectedAssetsList.length > 0 ? true : false;
        viewDispatch({ type: ELMediaGridViewAction.setInSelectionMode, payload: inSelectionMode });
        if (props.setSelectedMediaAssets) {
            props.setSelectedMediaAssets(selectedAssetsList);
        }
        rootDispatch(SelectedMediaAssetsAction.updateSelectedMediaList(selectedAssetsList));
    }, [selectedAssetsList]);

    useEffect(() => {
        Logger.log(LogLevel.DEBUG, "isFetching", isFetching);
        rootDispatch(MediaGridToolbarAction.updateMediaGridToolbarFilterState({ isFilterDisable: isFetching }));
        const loadingCircle = !!(isFetching && containerRef.current && loadingCircleRef.current);
        if (loadingCircle) {
            loadingCircleRef.current.style.top = `${containerRef.current.scrollTop}px`;
        }
        const keepLoadingCircleInView = _.throttle((): void => {
            if (loadingCircle) {
                loadingCircleRef.current.style.top = `${containerRef.current.scrollTop}px`;
            }
        }, EL_MEDIA_GRID_SCROLL_THROTTLE_TIME);
        containerRef.current?.addEventListener("scroll", keepLoadingCircleInView);
        return () => {
            containerRef.current?.removeEventListener("scroll", keepLoadingCircleInView);
        }
    }, [isFetching]);

    useEffect(() => {
        if (AssetStorageUtils.isValidAssetId(sivData.currentAssetId))
            mediaGridHandlerRef.current.onSIVAssetUpdated(sivData.currentAssetId);
    }, [sivData.currentAssetId, sivData]);

    const intlHandler = IntlHandler.getInstance();

    const getMediaGridContents = (): React.ReactElement[] | React.ReactElement => {

        if (!isFetching && !tileElementsPresent(state.sVisibleElements)) {
            return props.emptyGridBanner;
        }
        else {
            return state.sVisibleElements.map((ele, indx) => {
                let domNode = <></>;
                if (ele.elementType === ElementType.tile) {
                    const e = ele as ELMediaTileData;
                    const isSelected = selectedAssetsList.some((asset) => { return asset.assetId === e.asset.assetId });
                    const key = e.asset.assetId ? e.asset.assetId : "assetId" + indx;
                    domNode =
                        <UserInfoProvider elAdobeAsset={e.asset} key={key}>
                            <ELGridTile positionX={e.positionX} positionY={e.positionY} assethumbRequestFunc={pushAssetInThumbQueue}
                                asset={e.asset} isDisabled={e.isDisabled} isHidden={e.isHidden}
                                toggleMediaTileSelectionFunc={toggleMediaTileSelection} isSelected={isSelected}
                                TILE_H={e.height} TILE_W={e.width}
                                selectionEnabled={props.selectionEnabled}
                                inSelectionMode={state.sInSelectionMode} callback={sivCallback} selectionMode={props.selectionMode} />
                        </UserInfoProvider>

                } else if (ele.elementType === ElementType.sectionHeading) {
                    const e = ele as unknown as ELSectionHeaderData;
                    const key = e.title + indx;
                    domNode = <ELSectionHeader positionX={e.positionX} positionY={e.positionY} title={e.title}
                        key={key} SECTION_HEADING_H={props.layoutConfig.SECTION_HEADING_H} />
                } else if (ele.elementType === ElementType.anchor) {
                    const e = ele as unknown as ELAnchorData;
                    const key = (1 + e.positionX) * (1 + e.positionY) + indx;
                    domNode = <ELGridAnchor positionX={e.positionX} positionY={e.positionY} key={key} />
                }
                return domNode;
            });
        }
    }

    return (
        <div id="el-media-grid-scroll-container" className="el-media-grid-container" ref={containerRef}>
            {(props.toolbar && <div id="el-media-grid-scroll-container__grid-toolbar">
            </div>)}
            <div className={`el-media-grid-scroll-container__loading-circle ${isFetching ? "" : "no-display"}`}
                ref={loadingCircleRef}>
                <ProgressCircle isIndeterminate size="L" aria-label="Media Grid Loading..." />
                <Text> {intlHandler.formatMessage("loading")}  </Text>
            </div>
            {getMediaGridContents()}
        </div>
    );
}

export default ELMediaGridView;
