/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../IWorkflow";
import IWorkspace from "../../../IWorkspace";
import { ControllerAction } from "../../../../view/IViewController";
import { ViewAction } from "../../../../view/IBaseController";
import { TryThisInDesktopView } from "./TryThisInDesktopView";
import ELOpenInDesktopManager from "../../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopManager";
import { ELOpenInDesktopDeeplinkAction } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import { WorkspacesName } from "../../../IBaseWorkspace";
import {
    IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes
} from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { PlatformUtils } from "../../../../utils/PlatformUtils";

class TryThisInDesktop extends IWorkflow {
    private _openInDesktopManager!: ELOpenInDesktopManager;

    constructor(workspace: IWorkspace) {
        super(workspace, WorkflowsName.tryThisInDesktop);
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const userHomeView = React.createElement(TryThisInDesktopView, {
            controller: this,
        });

        ReactDOM.render(
            userHomeView,
            container
        );
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkflowActionType.ingest,
            payload: payload
        });
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
        if (!PlatformUtils.isAHandheldDevice(window.innerWidth, window.innerHeight)) {
            this._openInDesktopManager = new ELOpenInDesktopManager(this, WorkflowsName.tryThisInDesktop);
            this._openInDesktopManager.notify({ "type": ELOpenInDesktopDeeplinkAction.tryThis, "payload": window.location.href });
        }
        this._ingest(IngestUtils.addWorkspaceDetail(WorkspacesName.downloadDesktop, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
            IngestEventSubTypes.success, IngestEventTypes.render)));
    }

    endWorkflow(): void {
        this.destroyView();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this._owner.notify(action);
    }
}

export { TryThisInDesktop };
