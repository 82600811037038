/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "../actions/IAction";
import MediaMetadataAction, { MetadataPayload } from "../actions/mediaMetadataAction";


const udpateMetadata = (state: Array<MetadataPayload> = [], action: IAction<MetadataPayload>):  Array<MetadataPayload> => {
    const data = action.payload;
    if(data){
        const arr = state.filter((ele) => ele.assetId !== data.assetId).map((ele) => {
            return ele;
        });
        arr.push(data);
        return arr;
    }else{
        return state;
    }
}

const mediaMetadataReducer = (state: Array<MetadataPayload> = [] , action: IAction<MetadataPayload>): Array<MetadataPayload> => {
    switch (action.type) {
        case MediaMetadataAction.UPDATE_METADATA:
            return udpateMetadata(state, action);
        default:
            return state;
    }
}

export default mediaMetadataReducer;