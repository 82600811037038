/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { v4 as uuid } from "uuid";

//Adobe Internal
import {
    RepoResponseResult
} from "@dcx/assets";
import { RenditionType } from "@dcx/common-types";
import {
    Asset as ELServiceAsset,
    AssetData as ELServiceAssetdata,
    StorageType as ELServiceStorageType,
    Request as ELServiceRequest,
    CreationOperationTypeInfo,
    CreationOperationSubTypeInfo,
    ELRenditionType,
} from "@elements/elementswebcommon";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../IWorkflow";
import Logger, { LogLevel } from "../../../utils/Logger";
import ElementsCreationsService from "../../../services/ElementsServices/ElementsCreationsService";
import {
    CreationsJobStorageType,
    CreationsJobProjectType,
    CreationsJobProjectSubType,
    CreationJobAsset,
    CreationsJobOperations,
    CreationsData,
    CreationsStatus,
    CreationsErrorMsg,
    CreationsErrorCode,
    CSAsset,
    CreationsOperationsSpecificInfo,
    CreationsOutputConfigResultPath,
    CreationsOutputInfo,
    CreationsMode,
    ELCreationWorkflowPayload,
    ELViewType,
} from "./../../../common/interfaces/creations/CreationTypes";
import { ELAdobeAsset, elDeserializeAsset, RenditionData } from "../../../common/interfaces/storage/AssetTypes";
import Utils from "../../../utils/Utils";
import CreationUtils from "../utils/CreationUtils";
import SelectedMediaListAction from "../../../stores/actions/selectedMediaListActions";
import store from "../../../stores/store";
import { StorageService } from "../../../services/StorageServiceWrapper";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import { LocalStorageKeys } from "../../../utils/Constants/Constants";
import { ELIngestParams, ELMoveToolIngestParams } from "../../../common/interfaces/ingest/IngestTypes";
import { ELDualDocumentView } from "../../../editors/document/dualDocumentView/ELDualDocumentView";
import { ShareOptions } from "../../../view/components/organism/el-share-options/ELShareOptions";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../IBaseWorkspace";
import { GRID_CONFIG_KEY } from "../../../stores/reducers/mediaGridConfigReducer";
import { ELLayoutInfo } from "../../../common/interfaces/creations/ELSocialLayoutTypes";
import LayoutAction from "../../../stores/actions/LayoutAction";
import { FeatureName } from "../../../services/Floodgate/FloodgateConstants";
import { FeaturesManager } from "../../../modules/floodgate/Featuresmanager";
import CreationInAppNotifier from "../utils/CreationInAppNotifier";
import ELOpenInDesktopCreationObserver from "../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopCreationObserver";
import { CreationAppSubscriberType } from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { ELFeedbackDialog as ELFeedbackPopover } from "../../../view/components/templates/el-feedback-popover/ELFeedbackDialog";
import { ELUserFeedbackAction, FeedbackIconVariant } from "../../../common/interfaces/creations/templates/ELFeedbackDialogTypes";
import { ControllerAction } from "../../../view/IViewController";
import { IngestUtils } from "../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../utils/IngestConstants";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { ToastUtils } from "../../../utils/ToastUtils";
import IDoc, { DocumentType } from "../../../editors/document/IDoc";
import ELPSDConvertorFactory from "../../../editors/pie/psdConvertor/ELPSDConvertorFactory";
import ELNativeUploadHandler from "../../../modules/uploadHandler/ELNativeUploadHandler";
import ELPIEDoc from "../../../editors/pie/models/ELPIEDoc";
import ELCreationsPreviewHeader from "../../../view/components/templates/el-creations-header/ELCreationPreviewHeader";
import { HistoryUtils } from "../../../utils/HistoryUtils";
import { IBaseRequestParams } from "../../../common/interfaces/creations/ELCreationsJobTypes";
import Constants from "../../../utils/Constants/Constants";
import { ELDownloadData, ELImageData } from "../../../common/interfaces/stage/StageTypes";
import { DocumentActions, DocumentDirty, DocumentFormat } from "../../../common/interfaces/document/DocumentTypes";
import { ELOpenDeeplinkAction, ELOpenInDesktopManagerViewAction, ELOpenInDesktopOpenAssetPayload } from "../../../common/interfaces/creations/ELOpenInDesktopTypes";
import ELOpenInDesktopManager from "../../../view/components/templates/el-open-in-desktop-manager/ELOpenInDesktopManager";
import { ELCreationsHeaderControllerAction } from "../../../common/interfaces/creations/ELCreationsHeaderTypes";
import Jarvis from "../../../services/Jarvis";
import { ELRenditionHandler } from "../../../modules/elRenditionHandler/ELRenditionHandler";
import { PSD_NAME_INTL_KEY } from "../../../editors/pie/utils/PIEUtils";
import { JarvisCTA } from "../../../common/interfaces/services/JarvisTypes";
import { CreationsJobCreator } from "../utils/CreationsJobCreator";
import { DEFAULT_FEEDBACK_POINTS, ELCreationFeedbackDialog } from "../../../view/components/templates/el-creation-feedback-dialog/ELCreationFeedbackDialog";
import { ELFeedbackDialog } from "../../../view/components/templates/el-feedback-dialog/ELFeedbackDialog";
import { ELFeedbackDialogAction } from "../../../view/components/templates/el-feedback-dialog/ELFeedbackDialogView";
import DualViewAction from "../../../stores/actions/DualViewAction";
import { ELStageLayerDataOptions } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import RecentCreationAction from "../../../stores/actions/RecentCreationsAction";
import CreationsAction, { CreationsThumb } from "../../../stores/actions/CreationsAction";

/**
 * copied from here: https://wiki.corp.adobe.com/display/dva/Test+Plan%3A+Rendition+Generation+Progress+in+GET+API
 */
enum RenditionsStatus {
    QUEUED = "QUEUED",
    PROCESSING = "PROCESSING",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED"
}

const CREATION_FEEDBACK_CHARACTER_LIMIT = 500;

abstract class CreationWorkflow<DocPayloadType> extends IWorkflow {
    private static readonly RENDITION_MAX_ATTEMPT = 12;
    private static readonly RENDITION_FETCH_WAIT_DURATION = 10 * 1000;

    protected isWorkflowRunning = false;
    protected beforeDoc?: IDoc;
    protected doc?: IDoc;
    protected dualView?: ELDualDocumentView;
    protected startDate!: Date;
    protected defaultTitle?: string;
    protected ingestParams: ELIngestParams = {};
    protected moveToolIngestParams: ELMoveToolIngestParams = {};
    protected projectData?: CreationsData;
    protected projectId?: string;
    protected requestId?: string;
    protected creationsHeader?: ELCreationsPreviewHeader;
    protected mode!: CreationsMode;
    protected shareOptions: ShareOptions;
    protected mediaGridConfig;
    protected projectParams?: unknown;
    protected feedbackPopoverContainer?: ELFeedbackPopover;
    protected openInDesktopManager?: ELOpenInDesktopManager;
    protected renditionHandler: ELRenditionHandler;
    protected onDeeplinkProgressComplete: () => void;

    protected abstract logIngestData(creationStatus: string): void;

    constructor(owner: IBaseWorkspace, workflowName: WorkflowsName) {
        super(owner, workflowName);
        this.mediaGridConfig = store.getState().mediaConfigReducer[GRID_CONFIG_KEY];
        this.shareOptions = new ShareOptions(owner);
        this.renditionHandler = new ELRenditionHandler();
        this.onDeeplinkProgressComplete = () => { /*do nothing */ };
    }

    getWorkflowConfiguration(): Record<string, unknown> {
        const getMediaType = (): (string | undefined)[] => {
            const mediaType = store.getState().selectedMediaListReducer.map((media) => media.format);
            return mediaType;
        }

        return {
            "workflowConfiguration": {
                "name": this.workflowName,
                "requestType": this.mode,
                "inputConfiguration": {
                    "mediaCount": store.getState().selectedMediaListReducer.length,
                    "mediaType": getMediaType(),
                    "timeTaken": Utils.getDateDifference(this.startDate ?? new Date(), new Date(), "second")
                }
            }
        };
    }

    protected updateStoreStateOnEnter(): void { return }
    protected updateStoreStateOnExit(): void { return }

    protected getTrueTitleForRequest(currentTitle: string): string {
        const uiTitle = store.getState().recommendationWorkflowReducer.uiTitle;
        if (uiTitle && uiTitle.length !== 0) {
            return uiTitle;
        }
        return currentTitle;
    }

    protected preprocessCreationEdit(projectId: string): void {
        const creationsThumb: CreationsThumb = { id: projectId, objectURL: "" };
        store.dispatch(CreationsAction.updateThumb(creationsThumb));
    }

    protected async _getAssetsInfoForRequest(assetList: ELAdobeAsset[]): Promise<ELServiceAsset[]> {
        const assetInfoList: ELServiceAsset[] = [];

        for (let index = 0; index < assetList.length; index++) {
            const asset = assetList[index];
            const mediaInfo: ELServiceAssetdata = {
                assetURN: asset.assetId ? asset.assetId : "",
                storageType: ELServiceStorageType.RAPI,
                mimeType: asset.format ? asset.format : "image/jpeg"
            }
            const assetObj = new ELServiceAsset();
            await assetObj.serializeInWithContext(mediaInfo);
            assetInfoList.push(assetObj);
        }

        return assetInfoList;
    }

    //"replace" this with _getAssetsInfoForRequest ---> changes needed for slideshow / collage v3
    private _getMediaInfoList(assetList: ELAdobeAsset[]): CreationJobAsset[] {
        const mediaInfoList = [];
        for (let index = 0; index < assetList.length; index++) {
            const asset = assetList[index];
            const mediaInfo: CreationJobAsset = {
                assetURN: asset.assetId ? asset.assetId : "",
                storageType: CreationsJobStorageType.RAPI,
                mimeType: asset.format ? asset.format : "image/jpeg"
            }
            mediaInfoList.push(mediaInfo);
        }

        return mediaInfoList;
    }

    private _getOperationInfoList(operationSubType: CreationsJobProjectSubType,
        specificInfo?: CreationsOperationsSpecificInfo[]): CreationsJobOperations[] {
        const operationInfo: CreationsJobOperations = {
            version: 2,
            id: uuid(),
            operationType: CreationsJobProjectType.creation,
            operationSubType: operationSubType,
            specificInfo: specificInfo
        };

        const operationInfoList = [];
        operationInfoList.push(operationInfo);
        return operationInfoList;
    }

    private _isCreationRenditionJobActive(response: RepoResponseResult): boolean {
        const renditionState = (response.result as any);

        if (!("renditionsStatus" in renditionState))
            return true;

        return renditionState.renditionsStatus.state === RenditionsStatus.QUEUED || renditionState.renditionsStatus.state === RenditionsStatus.PROCESSING;
    }

    private _isCreationRenditionJobCompleted(response: RepoResponseResult): boolean {
        const renditionState = (response.result as any);

        if (!("renditionsStatus" in renditionState))
            return true;

        return renditionState.renditionsStatus.state === RenditionsStatus.COMPLETED;
    }

    private async _pollCreationRenditionStatus(outputAsset: ELAdobeAsset, renditionType: RenditionType = ELRenditionType.VIDEO_METADATA, attempt = 0): Promise<RepoResponseResult> {
        if (attempt > CreationWorkflow.RENDITION_MAX_ATTEMPT) {
            return Promise.reject(CreationsErrorCode.failedFetchingOutput);
        }
        try {
            const response = await CreationUtils.getCreationRendition(outputAsset, renditionType);

            if (this._isCreationRenditionJobActive(response)) {
                await Utils.wait(CreationWorkflow.RENDITION_FETCH_WAIT_DURATION);
                return this._pollCreationRenditionStatus(outputAsset, renditionType, attempt + 1);
            }

            if (this._isCreationRenditionJobCompleted(response)) {
                return Promise.resolve(response);
            } else {
                Logger.log(LogLevel.WARN, "CreationWorkflow:_pollCreationRenditionStatus: ", CreationsErrorMsg.invalidOutput);
                return Promise.reject(CreationsErrorCode.invalidOutput);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    protected updateViewStatusAndProgressText(status: CreationsStatus, progressText: string): void {
        Logger.log(LogLevel.INFO, "View update progress info: ", status, progressText);
    }

    protected async createPSDAndOpenDeeplinkOnClient(documentType: DocumentType, assetType: CreationsJobProjectSubType, name: string): Promise<void> {
        this.updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("saving-psd"));
        this.uploadAsPSD(documentType, name, true).then(asset => {
            const outputAssetId = asset?.assetId;
            if (outputAssetId) {
                this.viewDispatcher?.call(this.viewDispatcher, { type: ELOpenInDesktopManagerViewAction.inProgressStatus, payload: true });
                this.updateViewStatusAndProgressText(CreationsStatus.success, IntlHandler.getInstance().formatMessage("saving-psd"));
                this.openInDesktopManager?.notify({ type: ELOpenDeeplinkAction.openDeepLinkForAsset, payload: { assetId: outputAssetId, assetType: assetType } });
            } else {
                Logger.log(LogLevel.WARN, this.workflowName, "::_openDeeplink", "Asset not found");
                ToastUtils.error(IntlHandler.getInstance().formatMessage("saving-psd-failed"));
                this.updateViewStatusAndProgressText(CreationsStatus.error, IntlHandler.getInstance().formatMessage("saving-psd-failed"));
            }
        }).catch(error => {
            Logger.log(LogLevel.WARN, this.workflowName, "::_openDeeplink", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("saving-psd-failed"));
            this.updateViewStatusAndProgressText(CreationsStatus.error, IntlHandler.getInstance().formatMessage("saving-psd-failed"));
        });
    }

    protected async openDeeplink(): Promise<void> {
        throw new Error("openDeepLink is not implemented for this creation: " + this.workflowName);
    }

    protected getAssetListForJob(assets: CreationJobAsset[]): ELAdobeAsset[] {
        const assetList = [];
        for (let index = 0; index < assets.length; index++) {
            const asset = assets[index];
            const elAsset: ELAdobeAsset = {
                assetId: asset.assetURN ? asset.assetURN : "",
                format: asset.mimeType ? asset.mimeType : "image/jpeg"
            }
            assetList.push(elAsset);
        }

        return assetList;
    }

    protected createFeedbackView(container: HTMLElement): void {
        const intlHandler = IntlHandler.getInstance();
        const feedbackText = intlHandler.formatMessage("feedback-popup");
        const variant = FeedbackIconVariant.insideCreation;
        this.feedbackPopoverContainer = new ELFeedbackPopover(this, feedbackText, variant);
        this.feedbackPopoverContainer.createView(container);
    }

    protected async createCreation(creationJob: unknown): Promise<string> {
        try {
            const projectId = await ElementsCreationsService.getInstance().createCreation(creationJob);
            return Promise.resolve(projectId);
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationWorkflow:createCreation: ", CreationsErrorMsg.creationsFailed + error);
            return Promise.reject(CreationsErrorCode.creationsFailed);
        }
    }

    protected async retryCreation(projectId: string): Promise<string> {
        try {
            const projectData = await ElementsCreationsService.getInstance().retryCreation(projectId);
            return Promise.resolve(projectData);
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationWorkflow:retryCreation: ", CreationsErrorMsg.creationsFailed + error);
            return Promise.reject(CreationsErrorCode.creationsFailed);
        }
    }

    protected async editCreation(projectId: string, creationsJob: unknown): Promise<string> {
        try {
            const projectData = await ElementsCreationsService.getInstance().editCreation(projectId, creationsJob);
            return Promise.resolve(projectData);
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationWorkflow:editCreation: ", CreationsErrorMsg.editingFailed + error);
            return Promise.reject(CreationsErrorCode.editingFailed);
        }
    }

    protected async getOriginalData(outputAsset: ELAdobeAsset): Promise<RenditionData> {
        try {
            const asset = elDeserializeAsset(outputAsset);
            const downloadURI = await AssetStorageUtils.getDownloadURI(asset);
            const renditionData: RenditionData = { videoData: downloadURI };
            return renditionData;
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "CreationWorkflow - getFullResOutputURI : failed to get asset download uri");
        }

        return Promise.reject();
    }

    protected async getOriginalDataOrPollAssetRendition(asset: ELAdobeAsset, renditionType: RenditionType = ELRenditionType.VIDEO_METADATA): Promise<RenditionData> {
        const useRenditionData = localStorage.getItem(LocalStorageKeys.kRenditionData);
        if (!useRenditionData || useRenditionData === "false") {
            Logger.log(LogLevel.INFO, "Fetching original data to play");
            try {
                const renditionData = await this.getOriginalData(asset);
                return renditionData;
            } catch (error) {
                Logger.log(LogLevel.DEBUG, "CreationWorkflow - getOriginalDataOrPollAssetRendition : failed to get fullResRenditionData");
            }
        }

        try {
            Logger.log(LogLevel.INFO, "Fetching rendition data to play");
            const response = await this._pollCreationRenditionStatus(asset, renditionType);
            const renditionRecord = response.result as Record<string, any>;
            const renditionData: RenditionData = { imgData: renditionRecord.posterframe.url, videoData: renditionRecord.renditions[0].url };
            return renditionData;
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "CreationWorkflow - getOriginalDataOrPollAssetRendition : failed to poll rendition data");
        }

        return Promise.reject();
    }

    protected updateHistoryPath(url: string): void {
        HistoryUtils.replaceHistory(url);
    }

    protected async ingest(payload: Record<string, string>): Promise<boolean> {
        const handled = await this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
        return handled;
    }

    protected async download(): Promise<void> {
        if (!this.projectId) {
            Logger.log(LogLevel.ERROR, "CreationWorkflow:download: ", "invalid project id");
            return;
        }
        if (!this.projectData) {
            Logger.log(LogLevel.ERROR, "CreationWorkflow:download: ", "invalid project data for ", this.projectId);
            return;
        }

        const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
            { media: this.projectData.operationSubType.toLowerCase() });
        ToastUtils.info(message);

        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;

        try {
            await CreationUtils.downloadCreation(this.projectData);
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.download, this.projectData.operationSubType, additionalLogInfo));
        } catch (e) {
            //wait for download start message to go away then show the failed msg
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.download, this.projectData.operationSubType, additionalLogInfo));
            Logger.log(LogLevel.ERROR, "CreationWorkflow:_download: ", "could not download creation ", this.projectData);
        }
    }

    protected async getOriginalDataOrPollCreationRendition(creationData: CreationsData, renditionType: RenditionType = ELRenditionType.VIDEO_METADATA): Promise<RenditionData> {
        let outputAsset: ELAdobeAsset;
        try {
            outputAsset = await CreationUtils.getCreationOutputAsset(creationData);
            const renditionData = await this.getOriginalDataOrPollAssetRendition(outputAsset, renditionType);
            return renditionData;
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "CreationWorkflow - getOriginalDataOrPollCreationRendition : failed to get rendition");
            return Promise.reject();
        }
    }

    /**
     * Takes OutputResultConfig.json data as input,
     * and returns serialized Request Object
     * @param outputJson 
     * @returns 
     */
    protected async getSerializedRequestObject(outputJson: unknown): Promise<ELServiceRequest> {
        const requestObj = new ELServiceRequest();
        try {
            await requestObj.serializeInWithContext(outputJson);
            return requestObj;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationWorkflow: getSerializedRequestObject, failed to serializeIn the jsonData", error);
            return Promise.reject();
        }
    }

    protected async getCreationStatus(projectId: string): Promise<CreationsStatus> {
        try {
            const projectStatus = await ElementsCreationsService.getInstance().getCreationsStatus(projectId);
            return Promise.resolve(projectStatus)
        } catch (error) {
            return Promise.reject(CreationsErrorCode.failedProjectStatus);
        }
    }

    // will be used to get path of config.json for both project / recommendations
    protected getOutputConfigPathFromOutputConfigurations(outputConfig: CreationsOutputInfo): CreationsOutputConfigResultPath {
        const outputConfigPath: CreationsOutputConfigResultPath = {
            rootPath: outputConfig.root as string,
            relativeFilePath: outputConfig.assetURN as string
        }
        return outputConfigPath;
    }

    protected async getProjectData(projectId: string): Promise<CreationsData> {
        try {
            const projectData = await CreationUtils.getProjectData(projectId);
            return Promise.resolve(projectData);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    protected async notifyViaEmail(projectId: string): Promise<boolean> {
        try {
            const response = await ElementsCreationsService.getInstance().notifyCreation(projectId);
            return response;
        } catch (error) {
            Logger.log(LogLevel.WARN, "CreationWorkflow:notifyViaEmail: ", error);
        }
        return false;
    }

    protected set setIsWorkflowRunning(isWorkflowRunning: boolean) {
        this.isWorkflowRunning = isWorkflowRunning;
    }

    protected async populateSelectedMediaList(assets?: CSAsset[]): Promise<void> {
        if (!assets)
            return;

        const selectedAssetList: ELAdobeAsset[] = [];
        for (let i = 0; i < assets?.length; i++) {
            const asset = {
                repositoryId: "",
                assetId: assets[i].assetURN
            };

            try {
                const outputAsset = await StorageService.getInstance().resolveAsset(asset, "id");
                selectedAssetList.push(outputAsset);
            } catch (error) {
                //Should notify user that used media is no longer available?
                Logger.log(LogLevel.WARN, "CreationWorkflow: populateSelectedMediaList: ", error);
            }
        }

        //removve duplicates
        const updatedList = selectedAssetList.filter((asset1, index, array) =>
            array.findIndex(asset2 => asset1.assetId === asset2.assetId) === index);

        if (this.isWorkflowRunning)
            store.dispatch(SelectedMediaListAction.updateSelectedMediaList(updatedList));
    }

    protected updateLayoutStore(layoutInfo?: ELLayoutInfo): void {
        const scale = layoutInfo ? Math.ceil((layoutInfo.scaleX - 1) * 100) : 0;
        const selectedLayoutId = layoutInfo ? layoutInfo.layoutId : "";
        store.dispatch(LayoutAction.updateScale(scale));
        store.dispatch(LayoutAction.updateSelectedLayout(selectedLayoutId));
    }

    destroy(): void {
        super.destroy();
        this.defaultTitle = undefined;
        this.projectParams = undefined;
        this.beforeDoc = undefined;
        this.dualView = undefined;
    }

    protected shouldAllowCreationToOpen(featureName?: FeatureName): boolean {
        if (!featureName) {
            return true;
        } else {
            const isCreationFeatureActive = FeaturesManager.getInstance().IsFeatureActive(featureName);
            return isCreationFeatureActive;
        }
    }

    protected pollProjectStatus(): void {
        if (this.projectId) {
            CreationInAppNotifier.pollStatus(this.projectId);
        }
    }

    protected pollOpenInDesktopProjectStatus(openInDesktopCreationObserver: ELOpenInDesktopCreationObserver): void {
        if (this.projectId) {
            CreationInAppNotifier.subscribe(openInDesktopCreationObserver, CreationAppSubscriberType.statusChange);
            CreationInAppNotifier.pollStatus(this.projectId);
        }
    }

    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow, action?: T): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this.setIsWorkflowRunning = true;
        Jarvis.getInstance().showHideJarvisCTA(JarvisCTA.hide);
    }

    endWorkflow(): void {
        super.endWorkflow();
        this.setIsWorkflowRunning = false;
        Jarvis.getInstance().showHideJarvisCTA(JarvisCTA.show);
        store.dispatch(RecentCreationAction.cacheRecentCreationsList([]));
    }

    protected async startPreviousWorkflow(): Promise<void> {
        this._owner.notify({ type: WorkspaceActionType.endWorkflow });

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.creationsHome
        };
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
        this._owner.notify(workspaceAction);
    }

    protected async exportPSD(documentType: DocumentType, doResize = false): Promise<IDoc> {
        const psdConvertor = ELPSDConvertorFactory.createConvertor(documentType);
        if (this.doc) {
            const doc = await psdConvertor.convertToPSD(this.doc, doResize);
            return Promise.resolve(doc);
        }
        return Promise.reject("Couldn't create PSD document!");
    }

    protected async downloadPSD(documentType: DocumentType, name?: string, doResize = false): Promise<void> {
        try {
            const psdDoc = await this.exportPSD(documentType, doResize);

            const imageData: ELImageData = { format: DocumentFormat.PSD };
            const defaultName = IntlHandler.getInstance().formatMessage(PSD_NAME_INTL_KEY);
            const downloadData: ELDownloadData = { name: name ?? defaultName, imageData: imageData };

            psdDoc.download(downloadData);

            psdDoc.destroy();
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "downloadPSD failed: ", error);
            return Promise.reject("Couldn't upload PSD document!");
        }
    }

    protected async uploadAsPSD(documentType: DocumentType, name?: string, doResize = false): Promise<ELAdobeAsset | undefined> {
        try {
            const doc = await this.exportPSD(documentType, doResize);
            const psdUploader = new ELNativeUploadHandler();
            const defaultName = IntlHandler.getInstance().formatMessage(PSD_NAME_INTL_KEY);
            const asset = await psdUploader.uploadPSDDoc(doc as ELPIEDoc, name ?? defaultName);
            
            doc.destroy();
            return asset;
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "uploadAsPSD failed: ", error);
            return Promise.reject("Couldn't upload PSD document!");
        }
    }

    protected shouldUpdateLayoutDetails(): boolean {
        const currentAssetId = store.getState().selectedMediaListReducer[0].assetId;
        if (this.projectData?.assets && this.projectData.assets[0].assetURN === currentAssetId) {
            return true;
        }
        return false;
    }

    protected async tryAgainForOpenDeeplink(payload: ELOpenInDesktopOpenAssetPayload): Promise<void> {
        this.viewDispatcher?.call(this.viewDispatcher, { type: ELOpenInDesktopManagerViewAction.inProgressStatus, payload: true });
        this.onDeeplinkProgressComplete = async () => {
            await this.openInDesktopManager?.notify({ type: ELOpenDeeplinkAction.openDeepLinkForAsset, payload: payload });
        };
    }

    protected abstract ingestCreationFeedback(eventSubType: string): Promise<void>;
    protected abstract openProject(projectId: string): Promise<void>;
    protected abstract enterProject(eLCreationWorkflowPayload: ELCreationWorkflowPayload): void;
    protected abstract getJobCreator(): CreationsJobCreator;

    protected createRequestJson(requestParams: IBaseRequestParams): unknown {
        try {
            const requestCreatorObj = this.getJobCreator();
            const requestJson = requestCreatorObj.getRequestBody(requestParams);
            return requestJson;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationsWorkflow:createRequestJson, failed with error, " + error);
            throw error;
        }
    }

    protected async setCloudAssetPathForDoc(): Promise<void> {
        if (!this.projectId || !this.projectData) {
            Logger.log(LogLevel.ERROR, "CreationWorkflow: (_setCloudAssetForDoc)", "project id or data not valid", this.projectId, this.projectData);
            return;
        }

        const outputAssetPath = (await CreationUtils.getCreationOutputAssetPathOrId(this.projectData)).path;
        this.doc?.notify({ type: DocumentActions.updateCloudAssetPath, payload: outputAssetPath });
    }
    protected launchUserFeedbackDialog(): void {
        const LEAVE_FEEDBACK = "creation-feedback-question";
        const FEEDBACK_QUESTION = "feedback-question";
        const FEEDBACK_MESSAGE = "feedback-message";
        const ingestValueOnSubmit = IngestUtils.getIngestCreationsWorkflowName(this.workflowName);
        const backButtonFeedbackDialog = new ELFeedbackDialog(this, LEAVE_FEEDBACK, FEEDBACK_QUESTION, FEEDBACK_MESSAGE, undefined, ingestValueOnSubmit);
        backButtonFeedbackDialog.createView(this.ensureHTMLElement("feedback-dialog-container"));
    }

    protected updateView(viewType: ELViewType): void {
        this.dualView?.showDoc(viewType);
        store.dispatch(DualViewAction.updateView(viewType));
    }

    protected async getLayerDataOptionsList(outputJsonData: unknown): Promise<ELStageLayerDataOptions[] | undefined> {
        const requestObj = await this.getSerializedRequestObject(outputJsonData);
        const operation = requestObj.operations[0];
        const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
        const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as CreationOperationSubTypeInfo;
        const documentEdit = operationSubTypeInfo.configurations[0].documentEdit;
        if (documentEdit) {
            return documentEdit as ELStageLayerDataOptions[];
        }
        return undefined;
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        const intlHandler = IntlHandler.getInstance();
        switch (action.type) {
            case ELCreationsHeaderControllerAction.openInDesktop: {
                this.openDeeplink();
                handled = true;
                break;
            }
            case ELUserFeedbackAction.clickedYes: {
                await this.ingestCreationFeedback(IngestEventSubTypes.feedbackLike);
                handled = await this._owner.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success, IngestEventSubTypes.feedbackLike,
                        IngestUtils.getIngestCreationsWorkflowName(this.workflowName!))
                });
                ToastUtils.success(intlHandler.formatMessage("feedback-submitted-successfully"));
                break;
            }
            case ELUserFeedbackAction.clickedNo: {
                await this.ingestCreationFeedback(IngestEventSubTypes.feedbackDislike);
                handled = await this._owner.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success, IngestEventSubTypes.feedbackDislike,
                        IngestUtils.getIngestCreationsWorkflowName(this.workflowName!))
                });
                const feedbackDialog = new ELCreationFeedbackDialog(this, {
                    feedbackCharacterLimit: CREATION_FEEDBACK_CHARACTER_LIMIT,
                    feedbackPoints: DEFAULT_FEEDBACK_POINTS,
                    heading: "feedback-thanks-and-whats-wrong",
                    subHeading: "feedback-tell-more-about-experience",
                    creationName: this.workflowName
                });
                feedbackDialog.createView(this.ensureHTMLElement("feedback-dialog-container"));
                break;
            }
            case ELUserFeedbackAction.clicked: {

                handled = await this._owner.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.feedback, IngestEventTypes.render,
                        IngestEventSubTypes.feedbackDialog, IngestUtils.getIngestCreationsWorkflowName(this.workflowName!))
                });
                break;
            }
            case ELOpenInDesktopManagerViewAction.progressCompleteStatus: {
                this.onDeeplinkProgressComplete();
                this.onDeeplinkProgressComplete = () => { /*do nothing */ };
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSaveAskFeedback: {
                this.launchUserFeedbackDialog();
                handled = true;
                break;
            }
            case ELFeedbackDialogAction.onCTA: {
                this.notify({ type: ELCreationsHeaderControllerAction.dontSave });
                CreationUtils.setFeedbackLastLaunchTime();
                handled = true;
                break;
            }
            default: {
                Logger.log(LogLevel.WARN, "Invalid action type clicked " + action.type + " in workflow " + this.workflowName);
                handled = false;
                break;
            }
        }
        if (!handled)
            handled = await super.notify(action);

        return handled;
    }


    protected async createAndRenderDoc(payload: DocPayloadType, documentDirty: DocumentDirty): Promise<void> {
        throw new Error("createAndRenderDoc is not implemented for this creation: " + this.workflowName);
    }

    protected async createAndRenderAfterDoc(payload: DocPayloadType, documentDirty: DocumentDirty): Promise<void> {
        throw new Error("createAndRenderAfterDoc is not implemented for this creation: " + this.workflowName);
    }

    protected async createAndRenderBeforeDoc(payload: DocPayloadType, documentDirty: DocumentDirty): Promise<void> {
        throw new Error("createAndRenderBeforeDoc is not implemented for this creation: " + this.workflowName);
    }

    protected async revert(): Promise<void> {
        throw new Error("revert is not implemented for this creation: " + this.workflowName);
    }
}

export default CreationWorkflow;

export type DocTypeUnset = undefined;