/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import ReactDOM from 'react-dom';

//Application Specific
import { ViewAction } from '../../../IBaseController';
import ITemplateViewController from '../../../ITemplateViewController';
import ELLayoutPanelView from './ELLayoutPanelView';
import { ControllerAction } from '../../../IViewController';
import { ContentEntity, ContentType, PreviewType } from '@elements/elementswebcommon';
import { ELContentCacheDownloader } from '../../../../workspaces/creations/utils/ELContentCacheDownloader';
import Logger, { LogLevel } from '../../../../utils/Logger';
import { ERROR_THUMBDATA, PROMISE_FULFILLED } from '../../../../utils/Constants/Constants';
import { ELSocialLayoutThumbData, ELSocialLayoutViewActions } from '../../../../common/interfaces/creations/ELSocialLayoutTypes';
import { ELLayoutPanelControllerAction } from '../../../../common/interfaces/stage/StageTypes';
import { IntlHandler } from '../../../../modules/intlHandler/IntlHandler';

export default class ELLayoutPanel extends ITemplateViewController {
    private _socialLayoutThumbList: ELSocialLayoutThumbData[] = [];

    private async _getSocialLayoutThumbData(): Promise<ELSocialLayoutThumbData[]> {
        if (this._socialLayoutThumbList.length === 0) {
            await this._fetchSocialLayoutThumbData();
        }
        return this._socialLayoutThumbList;
    }

    private async _fetchSocialLayoutThumbData(): Promise<void> {
        try {
            const socialLayoutData = await ELContentCacheDownloader.getContentForContentType(ContentType.socialLayouts);
            await this._parseOverlaysDataForThumb(socialLayoutData);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELLayoutPanel: _fetchSocialLayoutData, failed to get layout content", error);
            Promise.reject()
        }
    }

    private async _parseOverlaysDataForThumb(socialLayoutData: ContentEntity[]): Promise<void> {
        const intlHandler = IntlHandler.getInstance();
        const previews = await this._getSocialLayoutPreviews(socialLayoutData);
        this._socialLayoutThumbList = socialLayoutData.map((data, index) => {
            return {
                id: data.props.id,
                type: intlHandler.formatMessage(data.props.specificInfo?.category ?? ""),
                subType: intlHandler.formatMessage(data.props.specificInfo?.subCategory ?? ""),
                previewUrl: previews[index],
                aspectRatio: {
                    width: parseInt(data.props.specificInfo?.aspectRatioWidth as string),
                    height: parseInt(data.props.specificInfo?.aspectRatioHeight as string)
                },
                hoverText: data.props.specificInfo?.hoverText
            } as ELSocialLayoutThumbData
        });
    }

    private _updateAllThumbsView(): void {
        this.viewDispatcher?.({
            type: ELSocialLayoutViewActions.updateAllThumbsData,
            payload: this._socialLayoutThumbList
        });
    }

    private async _getSocialLayoutPreviews(socialLayoutData: ContentEntity[]): Promise<string[]> {
        const previewPromises: Promise<string>[] = [];
        socialLayoutData.forEach(data => {
            previewPromises.push(data.getPreviewURL(PreviewType.image, ""));
        })
        const response = await Promise.allSettled(previewPromises);

        const previews: string[] = [];
        response.forEach(element => {
            if (element.status === PROMISE_FULFILLED) {
                previews.push(element.value);
            } else {
                previews.push(ERROR_THUMBDATA);
            }
        });

        return Promise.resolve(previews);
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const layoutView = React.createElement(ELLayoutPanelView, { controller: this });
        ReactDOM.render(layoutView, container);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._getSocialLayoutThumbData().then(() => {
            this._updateAllThumbsView();
        });
    }

    destroy(): void {
        super.destroy();
        this._owner.notify({ type: ELLayoutPanelControllerAction.panelClose });
        this.destroyView();
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELLayoutPanelView, {
            controller: this
        })
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this._owner.notify(action);
    }
}
