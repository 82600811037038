/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useRef, useState, useCallback, useReducer, ReactNode, KeyboardEvent, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

// Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { Divider, TextField, View, Text } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import ELIconButton from "../../molecules/el-icon-button/ELIconButtonView";
import { ViewAction } from "../../../IBaseController";
import Utils from "../../../../utils/Utils";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import { RootState } from "../../../../stores/store";
import { CreationsData, CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import { ViewportProvider, useViewport } from "../../../../utils/hooks/useViewport";
import ELPopover from "../../molecules/el-popover/ELPopoverView";
import { ShareOptionsView } from "../../organism/el-share-options/ELShareOptionsView";
import {
    ELCreationsHeaderControllerAction,
    ELCreationsHeaderState,
    ELCreationsHeaderViewAction,
    ELCreationsHeaderViewProps,
    ELCreationsRenameStatus
} from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";
import { ELSlideshowSettingsPopover } from "../../organism/el-slideshow-settings/ELSlideshowSettings";
import CreationsAction from "../../../../stores/actions/CreationsAction";
import ELButton from "../../atoms/el-button/ELButtonView";
import { CLOSED_PANEL_KEY } from "../../../../common/interfaces/creations/SlideshowTypes";
import { BackButton, PencilPlaceholder } from "../../organism/el-creations-header/ELCreationsHeaderComponents";
import KeyEventUtils from "../../../../utils/KeyEventUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import useCreationsUITitle from "../../../../utils/hooks/useCreationsUITitle";

import "./ELCreationsHeader.scss";

interface ELSlideshowHeaderState extends ELCreationsHeaderState {
    isDoneButtonDisabled: boolean
}

function init(title: string): ELSlideshowHeaderState {
    return {
        title: title,
        renameStatus: ELCreationsRenameStatus.success,
        isDoneButtonDisabled: true
    }
}

const reducer = (state: ELSlideshowHeaderState, action: ViewAction): ELSlideshowHeaderState => {
    const payload = action.payload as any;
    switch (action.type) {
        case ELCreationsHeaderViewAction.nameChanged:
            return {
                ...state,
                renameStatus: payload.status === true ? ELCreationsRenameStatus.success : ELCreationsRenameStatus.failure,
                title: payload.status === true ? payload.title : state.title
            };
        case ELCreationsHeaderViewAction.updateCreationsData:
            return {
                ...state,
                data: action.payload as CreationsData
            };
        case ELCreationsHeaderViewAction.changeName:
            return {
                ...state,
                renameStatus: payload
            };
        case ELCreationsHeaderViewAction.updateDoneButtonState: {
            return {
                ...state,
                isDoneButtonDisabled: action.payload as boolean
            };
        }
        default:
            return state;
    }
}

const ELCreationsHeaderView = (props: ELCreationsHeaderViewProps): React.ReactElement => {
    const { isMobile } = useViewport();
    const intlHandler = IntlHandler.getInstance();
    const [editingTitle, setEditingTitle] = useState(false);
    const [state, dispatch] = useReducer(reducer, init(props.data ? props.data.title : ""));
    const [uiTitle, setUITitle] = useState(state.title);
    const creationsStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);
    const [creationsStatus, setCreationsStatus] = useState(CreationsStatus.requested);
    const titleInputRef = useRef(null);
    const slideshowStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);
    const reduxDispatch = useDispatch();
    const selectedMediaList = useSelector((state: RootState) => state.selectedMediaListReducer);

    const setEditing = (): void => {
        setEditingTitle(true);
    };

    useCreationsUITitle(uiTitle);

    useEffect(() => {
        props.controller.initialize(dispatch);
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        setUITitle(state.title);
        updateStoreData();
    }, [state.title]);

    useEffect(() => {
        if (titleInputRef.current) {
            const inputElement = (titleInputRef.current) as HTMLInputElement;
            inputElement.focus();
            if (inputElement.value)
                inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
        }
    }, [editingTitle]);

    useEffect(() => {
        props.controller.notify({ type: ELCreationsHeaderControllerAction.slideshowDataUpdated });
        if (creationsStoreData.activeSlideshowId) {
            const slideshowId = creationsStoreData.activeSlideshowId;
            setCreationsStatus(creationsStoreData.data[slideshowId].status as CreationsStatus);
        }
    }, [creationsStoreData]);

    useEffect(() => {
        props.controller.notify({ type: ELCreationsHeaderControllerAction.slideshowMediaUpdated, payload: selectedMediaList });
    }, [selectedMediaList]);

    const updateStoreData = (): void => {
        const slideshowId = slideshowStoreData.activeSlideshowId;
        if (slideshowId) {
            const uiData = _.cloneDeep(slideshowStoreData.data[slideshowId].uiData);
            if (uiData) {
                uiData.title = state.title;
                reduxDispatch(CreationsAction.updateUIData(uiData));
            }

            const appliedData = _.cloneDeep(slideshowStoreData.data[slideshowId].appliedData);
            if (appliedData) {
                appliedData.title = state.title;
                reduxDispatch(CreationsAction.updateAppliedData(appliedData));
            }
        }
    }

    const isSyncingEdit = (): boolean => {
        return state.renameStatus === ELCreationsRenameStatus.inProgress;
    }

    const isValidTitle = (): boolean => {
        return uiTitle.length !== 0 && uiTitle !== state.title;
    }

    const notifyTitleChange = (): void => {
        if (isValidTitle()) {
            dispatch({ type: ELCreationsHeaderViewAction.changeName, payload: ELCreationsRenameStatus.inProgress });
            props.controller.notify({
                type: ELCreationsHeaderControllerAction.changeName,
                payload: uiTitle
            });
        } else {
            if (state.title.length !== 0)
                setUITitle(state.title);
        }
        setEditingTitle(false);
    }

    const renameEnterKeyPress = useCallback((e: KeyboardEvent): void => {
        if (e.key.toLowerCase() === KeyboardKey.enter && !KeyEventUtils.isComposingEnter(e.nativeEvent)) {
            notifyTitleChange();
        }
    }, []);

    const onTextEditFocusChange = (isFocused: boolean): void => {
        setEditingTitle(isFocused)
        if (isFocused === false) {
            notifyTitleChange();
        }
    };

    const getCreatedDescription = (): string => {
        if (!state.data)
            return "";

        return CreationUtils.getCreatedDescription(state.data.creationDate);
    }

    const isCreationReady = (): boolean => {
        return !!state.data && creationsStatus === CreationsStatus.success;
    }

    const isCreationInProgress = (): boolean => {
        return creationsStatus === CreationsStatus.requested ||
            creationsStatus === CreationsStatus.editing;
    }

    const getHeaderClass = (): string => {
        let headerClass = "creation-header__text-box";
        if (isCreationInProgress())
            headerClass = " disabled";
        return headerClass;
    }

    const onShare = (): void => {
        props.controller.notify({ type: ELCreationsHeaderControllerAction.sharePopoverClicked });
    }

    const shouldDisableCTAControls = (): boolean => {
        return (creationsStatus !== CreationsStatus.success);
    }

    const getPrimaryShareButton = (): ReactElement => {
        return (
            <ELIconButton size="L" variant={"cta"} iconkey={IconType.share} iconColor={Theme.light.scooped_button_S}
                iconHoverColor={Theme.light.scooped_button_S} iconWidth={"1.375rem"} iconHeight={"1.375rem"}
                onClick={() => onShare()}>
                <span className="creation-header__share-text">{intlHandler.formatMessage("share")}</span>
            </ELIconButton>
        )
    }

    const getClosePanelSlideshowHeader = (): ReactNode => {
        return (
            <Flex isHidden={!isCreationReady()} UNSAFE_className="creation-header__cta-box"
                direction="row" gap="1rem">
                <ELSlideshowSettingsPopover notify={props.controller.notify.bind(props.controller)} />
                <ELIconButton size="L" iconkey={IconType.download} iconColor={Theme.dark.gray_controls_color_N} variant="secondary"
                    onClick={() => props.controller.notify({ type: ELCreationsHeaderControllerAction.download })}
                    iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.375rem"} iconHeight={"1.375rem"}>
                    <span className="workflow-header__cta-text">{intlHandler.formatMessage("download")}</span>
                </ELIconButton>
                <ELPopover type={Utils.getSpectrumPopoverType((isMobile as boolean))} closeOnPress>
                    {getPrimaryShareButton()}
                    <ShareOptionsView controller={props.shareOptionController} onPress={() => {
                        props.controller.notify({ type: ELCreationsHeaderControllerAction.share });
                    }} showQRView={true} />
                </ELPopover>
            </Flex>
        )
    }

    const getOpenPanelSlideshowHeader = (): ReactNode => {
        return (
            <Flex isHidden={!isCreationReady()} UNSAFE_className="creation-header__cta-box"
                direction="row" gap="1rem">
                <ELButton size="L" variant="primary" dataTestId="creations-header-cancel"
                    onClick={() => props.controller.notify({ type: ELCreationsHeaderControllerAction.ctaCancel })}>
                    <span className="creation-header-button-text">{intlHandler.formatMessage("cancel")}</span>
                </ELButton>
                <ELButton size="L" variant="cta" isDisabled={state.isDoneButtonDisabled || shouldDisableCTAControls()} dataTestId="creations-header-done"
                    onClick={() => props.controller.notify({ type: ELCreationsHeaderControllerAction.ctaDone })}>
                    <span className="creation-header-button-text">{intlHandler.formatMessage("done")}</span>
                </ELButton>
            </Flex >
        )
    }

    const isSlideshowPanelClosed = (): boolean => {
        return creationsStoreData.panelSelectedKey === CLOSED_PANEL_KEY;
    }

    const getSlideshowHeaderContent = (): ReactNode => {
        if (isSlideshowPanelClosed()) {
            return getClosePanelSlideshowHeader();
        } else {
            return getOpenPanelSlideshowHeader();
        }
    }

    return (
        <ELReactSpectrumV3Provider>
            <ViewportProvider>
                <Flex UNSAFE_className="creation-header" gap="0.8rem" direction="row" alignItems="center">
                    <BackButton notify={props.controller.notify.bind(props.controller)} />
                    <Flex UNSAFE_className={getHeaderClass()} direction="row" gap="size-250" alignItems="center">
                        <View UNSAFE_className="creation-header__edit-box">
                            <span className={`creation-header__title-text
                             ${!isCreationReady() || editingTitle ? "no-display" : ""}`}
                                title={uiTitle}>
                                {uiTitle}
                            </span>
                            <TextField data-testid="el-text-field" ref={titleInputRef} UNSAFE_className="creation-header__text-input" inputMode="text"
                                isHidden={!isCreationReady() || !editingTitle}
                                value={uiTitle} onChange={setUITitle}
                                onKeyDown={renameEnterKeyPress} onFocusChange={(isFocused) => onTextEditFocusChange(isFocused)} />
                        </View>
                        <Flex isHidden={!isCreationReady()} alignItems="baseline" gap="0.8rem">
                            <PencilPlaceholder isSyncingEdit={isSyncingEdit()}
                                editingTitle={editingTitle} setEditing={setEditing} />
                            <Divider orientation="vertical" size="S" />
                            <Text UNSAFE_className="creation-header__created-text">{getCreatedDescription()}</Text>
                        </Flex>
                    </Flex>

                    {getSlideshowHeaderContent()}
                </Flex >
            </ViewportProvider>
        </ELReactSpectrumV3Provider>
    );
}
export default ELCreationsHeaderView;



