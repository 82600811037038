/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const enum ELImageEditWorkflowHeaderViewAction {
    changeName = "CHANGE_NAME",
    updateCurrentAsset = "UPDATE_CURRENT_ASSET",
    updateFileName = "UPDATE_FILE_NAME",
    updateBackDialogState = "UPDATE_BACK_DIALOG_STATE",
}


export const enum ELImageEditWorkflowViewAction {
    showProgress = "SHOW_PROGRESS",
    progressText = "PROGRESS_TEXT",
    updateReplaceMediaDialogState = "UPDATE_REPLACE_MEDIA_DIALOG_STATE"
}

export const enum ELImageEditWorkflowControllerAction {
    onDismissReplaceMediaDialog = "ON_DISMISS_REPLACE_MEDIA_DIALOG",
    onSaveAsReplaceMediaDialog = "ON_SAVE_AS_REPLACE_MEDIA_DIALOG",
    onDontSaveReplaceMediaDialog = "ON_DONT_SAVE_REPLACE_MEDIA_DIALOG",
    showErrorDialog = "SHOW_ERROR_DIALOG",
}