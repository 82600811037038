/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "../actions/IAction";
import UploadedMediaListAction from "../actions/uploadedMediaListActions";

const appendMedia = (state: string[] = [], action: IAction<string>): string[] => {
    const data = action.payload as string;
    if (data) {
        return [...state, data];
    } else {
        return state;
    }
}

const clearMedia = (state: string[] = [], action: IAction<string>): string[] => {
    return [];
}

const uploadedMediaListReducer = (state: string[] = [], action: IAction<string>): string[] => {
    switch (action.type) {
        case UploadedMediaListAction.APPEND_MEDIA:
            return appendMedia(state, action);
        case UploadedMediaListAction.CLEAR_MEDIA:
            return clearMedia(state, action);
        default:
            return state;
    }
}

export default uploadedMediaListReducer;
