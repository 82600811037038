/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import IAction from "./IAction";


export type PeekThroughActionPayload = string | CreationsStatus;

export default class PeekThroughAction {
    public static readonly UPDATE_STATUS: string = "PeekThroughAction.UPDATE_STATUS";
    public static readonly UPDATE_OVERLAY: string = "PeekThroughAction.UPDATE_OVERLAY";

    public static updateStatus(status: CreationsStatus): IAction<CreationsStatus> {
        return {
            type: PeekThroughAction.UPDATE_STATUS,
            payload: status
        };
    }

    public static updateOverlayData(overlayData: string): IAction<string> {
        return {
            type: PeekThroughAction.UPDATE_OVERLAY,
            payload: overlayData
        };
    }
}