/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";

//Application specific
import ELDialog from "../el-dialog/ELDialog"
import { ELIframe } from "../../atoms/el-iframe/ELIframe";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import IViewController from "../../../IViewController";

import "./ELVideoOverlay.scss";

interface ELVideoOverlayViewProps {
    controller: IViewController,
    src: string,
    handleEscape: () => void,
    autoPlay?: boolean,
    overlayDiv?: HTMLDivElement,
}

export const ELVideoOverlayView = (props: ELVideoOverlayViewProps): React.ReactElement => {
    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <ViewportProvider>
            <ELDialog UNSAFE_className="video-overlay-container" overlayDiv={props.overlayDiv} handleEscape={props.handleEscape} isDismissible={true}>
                <ELIframe src={props.src} title="video-overlay-iframe" allowFullScreen={true} allow={props.autoPlay ? "autoplay; allowfullscreen" : ""} frameBorder="0" className="video-overlay__iframe"></ELIframe>
            </ELDialog>
        </ViewportProvider>
    )
}
