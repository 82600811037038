/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { LinkParams } from "./Constants/Constants";
import Utils from "./Utils";

export const LINK_SOURCE_DESKTOP = "desktop";
export const IMS_LOGIN_REDIRECT = "imsLogin";

/**
 * Class providing utility functions for handling worflows of redirections to web.
 * @class RedirectToWebWorkflowsUtils
 */
export class RedirectToWebWorkflowsUtils {
    static isDesktopToWebHandover(href: string): boolean {
        const linkSource = Utils.getLinkParamValue(href, LinkParams.ELEMENTS_LINK_SOURCE_PARAM);
        if (linkSource === LINK_SOURCE_DESKTOP) {
            return true;
        }
        return false;
    }

    /**
     * Check if the redirection to web is for direct IMS login
     * @param href URL of the app for which IMS redirection is to be checked
     * @returns void
     */
    static isIMSLoginRedirection(href: string): boolean {
        const redirect = Utils.getLinkParamValue(href, LinkParams.ELEMENTS_REDIRECT_PARAM); 
        if (redirect === IMS_LOGIN_REDIRECT) {
            return true;
        }
        return false;
    }
}