/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import ResizedDocAction, { ResizedDocPayload } from "../actions/ResizedDocAction";
import IAction from "../actions/IAction";

const cleanData = (objectURL: string): void => {
    URL.revokeObjectURL(objectURL);
}

const updateData = (state: ResizedDocPayload[] = [], action: IAction<ResizedDocPayload | string>): ResizedDocPayload[] => {
    const data = action.payload as ResizedDocPayload;
    if (data) {
        const savedData = state.filter((ele) => {
            if (ele.assetId === data.assetId)
                return true;
            return false;
        })[0];

        if (savedData) {
            cleanData(savedData.objectURL);
        }

        const arr = state.filter((ele) => ele.assetId !== data.assetId).map((ele) => {
            return ele;
        });
        arr.push(data);

        return arr;
    } else {
        return state;
    }
}

const clearData = (state: ResizedDocPayload[] = [], action: IAction<ResizedDocPayload | string>): ResizedDocPayload[] => {
    const assetId = action.payload as string;
    if (assetId) {
        state.some((ele) => {
            if (ele.assetId === assetId) {
                cleanData(ele.objectURL);
                return true;
            }
            return false;
        });

        const arr = state.filter((ele) => ele.assetId !== assetId).map((ele) => {
            return ele;
        });

        return arr;
    } else {
        return state;
    }
}

const clearAllData = (state: ResizedDocPayload[] = []): ResizedDocPayload[] => {
    state.every((ele) => {
        cleanData(ele.objectURL);
        return true;
    });

    return [];
}

const resizedDocReducer = (state: Array<ResizedDocPayload> = [], action: IAction<ResizedDocPayload | string>): ResizedDocPayload[] => {
    switch (action.type) {
        case ResizedDocAction.UPDATE_DATA:
            return updateData(state, action);
        case ResizedDocAction.CLEAR_ALL_DATA:
            return clearAllData(state);
        case ResizedDocAction.CLEAR_DATA:
            return clearData(state, action);
        default:
            return state;
    }
}

export default resizedDocReducer;