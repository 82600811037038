/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { RequestInvocationMode } from "@elements/elementswebcommon";
import { ELStageCorner } from "../stage/StageTypes";
import { ELAdobeAsset } from "../storage/AssetTypes";
import { CSAssetWithData } from "./CreationTypes";
import { CSAssetEditInfo } from "./ELCollageTypes";
import { ELLayoutInfo } from "./ELSocialLayoutTypes";

export interface ELPeekThroughOverlayInfo {
    asset: CSAssetWithData,
    edit?: CSAssetEditInfo
}

export type ELPeekThroughOverlayInfoType = ELPeekThroughOverlayInfo | null;

export interface ELPeekThroughDocPayload {
    backgroundAsset: CSAssetWithData,
    overlayInfoList?: ELPeekThroughOverlayInfoType[],
    layoutInfo?: ELLayoutInfo
}

export interface ELPeekThroughShapeData {
    asset?: CSAssetWithData,
    overlayPlacementId: ELStageCorner
}

export enum ELPeekThroughDocAction {
    overlayUpdated = "OVERLAY_UPDATED",
    removeOverlay = "REMOVE_OVERLAY",
}

export const overlayCornerflipDict = {
    [ELStageCorner.topLeft]: [false, false],
    [ELStageCorner.topRight]: [true, false],
    [ELStageCorner.bottomLeft]: [false, true],
    [ELStageCorner.bottomRight]: [true, true]
};

export const rectOriginMultiplier = [
    [0, 0],
    [1 / 2, 0],
    [0, 1 / 2],
    [1 / 2, 1 / 2]
];

export const PeekThroughViewActions = {
    peekThroughStatus: "PEEK_THROUGH_STATUS",
    peekThroughProgressText: "PEEK_THROUGH_PROGRESS_TEXT"
}

export interface ELPeekThroughProjectRequestParams {
    assets: ELAdobeAsset[],
    title?: string,
    requestInvocationMode?: RequestInvocationMode
}

export enum ELSavedPeekThroughVersion {
    version_1_0 = "1.0"
}

export interface ELSavedPeekThroughData {
    version: string,
    backgroundAsset: CSAssetWithData,
    overlayInfoList: ELPeekThroughOverlayInfoType[],
    layoutInfo: ELLayoutInfo
}

export const ELPeekThroughRectFill = "rgba(0, 0, 0, 0.01)";
export const ELPeekThroughRectBorder = "rgb(255, 0, 255)";

export interface ELPeekThroughOverlayCommonData {
    id: string;
    name: string,
    previewUrl: string,
    contentUrl: string,
}

export interface ELPeekThroughCornerData {
    commonData: ELPeekThroughOverlayCommonData,
    defaultCorner: number
}

export interface ELPeekThroughOverlayData {
    commonData: ELPeekThroughOverlayCommonData,
    cornerlist: ELPeekThroughCornerData[]
}