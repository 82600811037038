/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { AdjustmentsPreset } from "../../editors/adjustments/AdjustmentsPresets";
import IAction from "./IAction";

export type AdjustmentsActionPayload = AdjustmentsPresetURLPayload | string | number;
export type AdjustmentsPresetURLPayload = Pick<AdjustmentsPreset, "id" | "previewUrl">;

export default class AdjustmentsAction {
    public static readonly UPDATE_PRESET_PREVIEW_URL: string = "AdjustmentsAction.UPDATE_PRESET_PREVIEW_URL";
    public static readonly RESET_PRESETS_PREVIEW_URLS: string = "AdjustmentsAction.RESET_PRESETS_PREVIEW_URLS";
    public static readonly UPDATE_SELECTED_PRESET_ID: string = "AdjustmentsAction.UPDATE_SELECTED_PRESET_ID";
    public static readonly UPDATE_INTENSITY: string = "AdjustmentsAction.UPDATE_INTENSITY";

    public static updatePresetPreviewURL(payload: AdjustmentsPresetURLPayload): IAction<AdjustmentsPresetURLPayload> {
        return {
            type: AdjustmentsAction.UPDATE_PRESET_PREVIEW_URL,
            payload: payload
        }
    }

    public static resetPresetsPreviewURLs(): IAction<undefined> {
        return {
            type: AdjustmentsAction.RESET_PRESETS_PREVIEW_URLS
        }
    }

    public static updateSelectedPresetId(payload: string): IAction<string> {
        return {
            type: AdjustmentsAction.UPDATE_SELECTED_PRESET_ID,
            payload: payload
        }
    }

    public static updateIntensity(payload: number): IAction<number> {
        return {
            type: AdjustmentsAction.UPDATE_INTENSITY,
            payload: payload
        }
    }
}