/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { CSAssetWithData } from "../../common/interfaces/creations/CreationTypes";
import { ELAdobeAssetDocActions } from "../../common/interfaces/creations/templates/ELAdobeAssetDocTypes";
import { RenderedShapesName, RendererUpdateData } from "../../common/interfaces/renderer/RendererTypes";
import { ELStageBackgroundMode } from "../../common/interfaces/stage/StageTypes";
import { StageUtils } from "../../utils/stage/StageUtils";
import { ControllerAction } from "../../view/IViewController";
import IDocDataParser from "../document/parser/IDocDataParser";
import IRenderer from "../renderer/IRenderer";
import IGraphicsStage from "../stage/IGraphicsStage";
import ELAdobeAssetDoc from "./ELAdobeAssetDoc";

export default class ELAdobeAssetRenderer extends IRenderer {
    private _doc: ELAdobeAssetDoc;

    constructor(doc: ELAdobeAssetDoc, stage: IGraphicsStage, dataParser: IDocDataParser) {
        super(stage, dataParser);
        this._doc = doc;
    }

    async render(container: HTMLElement): Promise<void> {
        await super.render(container);
        return new Promise((resolve) => {
            this.stage.setBackground("#ececec");
            this.dataParser.parseDocData().then((stageShapeAndObjectOptionsList) => {
                this.drawShapes(stageShapeAndObjectOptionsList).then((stageObjectList) => {
                    this.saveStageData(stageShapeAndObjectOptionsList, stageObjectList);
                    resolve();
                });
            });
        });
    }

    async update(updateData: RendererUpdateData): Promise<void> {
        switch (updateData.type) {
            case ELAdobeAssetDocActions.assetUpdated: {
                const backgroundData = StageUtils.getRenderedDataListByName(this.stageDataList, [RenderedShapesName.imageAreaWithLayout])[0];

                const newBackgroundOptions = {
                    ...backgroundData.stageShapeAndObjectOptions.objectOptions,
                    background: {
                        mode: ELStageBackgroundMode.image,
                        value: (updateData.payload as CSAssetWithData).objectURL
                    },
                    image: (updateData.payload as CSAssetWithData).objectURL,
                    fitToImageArea: true,
                    selectable: false,
                    top: 0,
                    left: 0,
                };

                const updatedObject = await this.updateShape(backgroundData.stageShapeAndObjectOptions.shape, newBackgroundOptions);
                if (updatedObject) {
                    backgroundData.object = updatedObject;
                    backgroundData.stageShapeAndObjectOptions.objectOptions = newBackgroundOptions;
                }

                const filteredStageDataList = StageUtils.getRenderedDataListByExclusionName(this.stageDataList, [RenderedShapesName.imageAreaWithLayout]);
                filteredStageDataList.push(backgroundData);

                this.stageDataList = filteredStageDataList;

                break;
            }
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return await super.notify(action);
    }

    destroy(): void {
        super.destroy();
        this.stage.destroy();
    }
}