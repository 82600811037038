/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//Internal
import { AssetPath, CreationOperationTypeInfo, CreationOperationTypeOutput, ELAdobeAsset, OutputType, PatternOverlayCreationOperationSubTypeInfo, PatternOverlayJobUtils, Request, TemplateId } from "@elements/elementswebcommon";

//Application Specific
import { GRID_CONFIG_KEY } from "../../../../../stores/reducers/mediaGridConfigReducer";
import { MediaGridConfig, MediaGridSortBy } from "../../../../../stores/actions/mediaGridConfigActions";
import store from "../../../../../stores/store";
import { GIF_FILE_FORMAT, HEIC_FILE_FORMAT, PSDC_FILE_FORMAT, PSD_FILE_FORMATS } from "../../../../../common/interfaces/storage/FileTypes";
import { FilterMediaUiProp } from "../../../../../stores/actions/mediaGridConfigActions";
import { FilterMediaData } from "../../../../../stores/actions/mediaGridConfigActions";
import { GridMediaType } from "../../../../../stores/actions/mediaGridConfigActions";
import { WorkspaceAction, WorkspacePayload } from "../../../../IBaseWorkspace";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import Constants from "../../../../../utils/Constants/Constants";
import { ToastUtils } from "../../../../../utils/ToastUtils";
import { AssetStorageUtils } from "../../../../../utils/AssetStorageUtils";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { Routes } from "../../../../../app/AppRoute";
import { CreationsMode, CreationsStatusPayload, CreationsURLParams } from "../../../../../common/interfaces/creations/CreationTypes";
import { WorkflowsName } from "../../../../IWorkflow";
import Utils from "../../../../../utils/Utils";

export default class ReplaceBackgroundUtils {

    static getReplaceBackgroundMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);
        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageOnly;
        const filterData: FilterMediaData = {
            format: [PSDC_FILE_FORMAT, GIF_FILE_FORMAT, ...PSD_FILE_FORMATS, HEIC_FILE_FORMAT],
            uiProp: FilterMediaUiProp.hide
        }
        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 1;
        mediaGridConfig.maxImageCount = 1;
        mediaGridConfig.minImageCount = 1;
        mediaGridConfig.maxVideoCount = 0;
        mediaGridConfig.maxTotalMediaSize = 400; //in MB;
        mediaGridConfig.maxVideoLength = 0;
        mediaGridConfig.selectUploadedMedia = true;
        return mediaGridConfig;
    }

    static filterMediaByCount(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): ELAdobeAsset[] {
        if ((mediaGridConfig.maxImageCount !== undefined) && assets.length > mediaGridConfig.maxImageCount) {
            assets.splice(mediaGridConfig.maxImageCount, assets.length - mediaGridConfig.maxImageCount);
        }

        return assets;
    }

    /**
     * @returns boolean - true if replaceBackground url is present
     */
    static hasReplaceBackgroundUrl(): boolean {
        const workflow = Utils.getLinkParamValue(window.location.href, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.replaceBackground.toLowerCase() === workflow.toLowerCase());
    }

    static isOpeningExistingReplaceBackground(workspaceAction: WorkspaceAction): boolean {
        return workspaceAction.initMode === CreationsMode.open;
    }

    /**
     * @returns string - replaceBackground id from window href
     */
    static getReplaceBackgroundIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    static getReplaceBackgroundPayload(): WorkspacePayload {
        const projectId = ReplaceBackgroundUtils.getReplaceBackgroundIdFromHref();
        let mode = CreationsMode.open;
        let payload: CreationsStatusPayload | ELAdobeAsset[] | null = {
            projectId: projectId
        };

        if (projectId === Utils.getNilUUID()) {
            mode = CreationsMode.create;
            payload = store.getState().selectedMediaListReducer;
        }

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.replaceBackground,
            initMode: mode,
            payload: payload
        }

        return workspacePayload;
    }

    /**
     * @returns string - history state based on projectId
     */
    static getReplaceBackgroundHistoryState(projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + WorkflowsName.replaceBackground.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    static showErrorMessageForQuotaExceeded(): void {
        const message = IntlHandler.getInstance().formatMessage("creation-storage-quota-error", { creation: IntlHandler.getInstance().formatMessage("replace-background-creation").toLowerCase() });
        ToastUtils.error(message, { timeout: Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number });
    }

    static canStartReplaceBackground(selectedAssets: ELAdobeAsset[]): boolean {
        const minImageCount = ReplaceBackgroundUtils.getReplaceBackgroundMediaGridConfig().minImageCount;
        if (minImageCount && selectedAssets.length >= minImageCount)
            return true;
        return false;
    }

    static isMaxImageLimitExceeded(selectedAssets: ELAdobeAsset[]): boolean {
        const maxImageCount = ReplaceBackgroundUtils.getReplaceBackgroundMediaGridConfig().maxImageCount;
        if ((maxImageCount !== undefined) && selectedAssets.length > maxImageCount)
            return true;
        return false;
    }

    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = AssetStorageUtils.filterRemoveVideoAssets(assets);
        filteredAssets = AssetStorageUtils.filterRemoveGifAssets(filteredAssets);
        filteredAssets = AssetStorageUtils.filterRemoveHeicAssets(filteredAssets);
        filteredAssets = ReplaceBackgroundUtils.filterMediaByCount(filteredAssets, mediaGridConfig);

        Logger.log(LogLevel.INFO, "ReplaceBackground - getFilteredAssetList: ", filteredAssets);
        return filteredAssets;
    }

    // TODO/REVISIT: (samyjain): REPLACE BACKGROUND
    // need to revisit : once common code util class is there for replace background
    static async getRecommendedAssetsIdForOverlays(outputJsonData: unknown): Promise<Map<TemplateId, AssetPath>> {
        try {
            const requestObj = new Request();
            await requestObj.serializeInWithContext(outputJsonData);
            const operation = requestObj.operations[0];
            const operationTypeInfo = operation.operationTypeInfo as CreationOperationTypeInfo;
            const operationSubTypeInfo = operationTypeInfo.operationSubTypeInfo as PatternOverlayCreationOperationSubTypeInfo;
            const output = operationTypeInfo.output as CreationOperationTypeOutput;
            const mapOfTemplateIdToAssetPath = PatternOverlayJobUtils.getMappedTemplateIdsToAssetPath(operationSubTypeInfo, output, OutputType.preview);
            for (const [overlayId, assetPath] of mapOfTemplateIdToAssetPath) {
                const assetID = await AssetStorageUtils.getAssetIdForAssetPath(assetPath);
                if (assetID !== undefined) {
                    mapOfTemplateIdToAssetPath.set(overlayId, assetID);
                }
            }
            return mapOfTemplateIdToAssetPath;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ReplaceBackgroundUtils:_getRecommendedAssetsIdForOverlays, failed to process OutputResultConfig.json");
            return Promise.reject();
        }
    }
}