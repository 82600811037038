/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { API, ApiClientConfig } from "../services/APIServiceWrapper";
import IMS from "../services/IMS";
import Constants from "./Constants/Constants";
import Logger, { LogLevel } from "./Logger";
import Utils from "./Utils";

export const MAX_MONITOR_RETRY_COUNT = 100;
export const DEFAULT_MONITOR_DELAY = "2";
export enum RequestHeaders {
    RETRY_AFTER = "Retry-After",
}

export class ServiceUtils {

    static async monitorResponse(monitorUrl: string, retryCount: number, successStatus: number, inProgressStatus: number): Promise<boolean> {
        if (retryCount >= MAX_MONITOR_RETRY_COUNT) {
            return Promise.reject("Max retry count reached for monitor response");
        }
        const monitorConfig = {
            headers: {
                "X-api-key": `${process.env.REACT_APP_ELEMENTS_API_KEY}`,
                "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
            }
        }
        const apiClientConfig: ApiClientConfig =
        {
            needResponseMonitoring: false,
            monitorSuccessStatus: Constants.HTTP_RESPONSE_OK_200 as number,
            monitorInProgressStatus: Constants.HTTP_RESPONSE_OK_202 as number
        };
        try {
            const monitorClient = new API(monitorUrl, apiClientConfig);
            const response = await monitorClient.get(monitorUrl, monitorConfig);
            const delay = parseInt(response.headers.get(RequestHeaders.RETRY_AFTER) || DEFAULT_MONITOR_DELAY);
            if (response.status === successStatus) { // operation success
                return Promise.resolve(true);
            } else if (response.status === inProgressStatus) { // keep monitoring
                await Utils.wait(delay * 1000);
                return ServiceUtils.monitorResponse(monitorUrl, retryCount + 1, successStatus, inProgressStatus);
            } else {
                Logger.log(LogLevel.ERROR, "ServiceUtils:monitorResponse: ", "Error in monitor response with url : ", monitorUrl, " status code : ", response.status);
                return Promise.resolve(false);
            }
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "ServiceUtils:monitorResponse: ", "Unable to fetch monitor link status", error);
            return Promise.reject(false);
        }

    }
}