/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import AccessProfileAction from "../actions/AccessProfileAction";
import IAction from "../actions/IAction";

const updateAccessProfileStatus = (state: string, action: IAction<string>): string => {
    const data = action.payload;
    if (data !== undefined) {
        return data;
    } else {
        return state;
    }
};

const accessProfileReducer = (state = "PROFILE_UNAVAILABLE", action: IAction<string>): string => {
    switch (action.type) {
        case AccessProfileAction.UPDATE_PROFILE_STATUS:
            return updateAccessProfileStatus(state, action);
        default:
            return state;
    }
};

export default accessProfileReducer;