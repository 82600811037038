/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { ELAdobeAsset, OutputType, Resolution } from "@elements/elementswebcommon";

//Application Specific
import { ELCreationDocumentDetails, CompositeSettings } from "./CreationTypes";
import { IBaseRequestParams } from "./ELCreationsJobTypes";

export interface ELMovingOverlayCreationRequestParams extends IBaseRequestParams {
    outputTypes: OutputType[],
    contentResolution: Resolution,
    compositeSettings: CompositeSettings,
    overlayIdAudioIdMap: Map<string, string>,
    documentDetails?: ELCreationDocumentDetails
}

export interface ELMovingOverlayOutputAssets {
    preview: ELAdobeAsset,
    full: ELAdobeAsset
}

export enum ELMovingOverlayControllerActions {
    updateEditViewData = "UPDATE_EDIT_VIEW_DATA"
}

export enum ELOverlayProjectActionMode {
    regular = "regular",
    openInDesktop = "openInDesktop"
}

export interface ELMovingOverlayUpdateDocumentPayload {
    mapOfTemplateIdToAsset: Map<string, ELMovingOverlayOutputAssets>,
    overlayId: string
}