/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React from "react";
import { ProgressCircle } from "@adobe/react-spectrum";

import "./ELCreationProgressView.scss";

export interface ELCreationProgressViewProps {
    progressText: string,
    mode: "parent" | "fullscreen"
}

export const ELCreationProgressView = (props: ELCreationProgressViewProps): React.ReactElement => {
    return (
        <div className={"creation__loading-circle-box " + props.mode}>
            <div data-testid="creation-loading-circle" className="creation__loading-circle">
                <ProgressCircle isIndeterminate size="M" aria-label="Loading..." />
                <span data-testid="creation-progress-text">{props.progressText}</span>
            </div>
        </div>
    )
}