/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELDownloadData, ELImageData } from "../../common/interfaces/stage/StageTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import Utils from "../../utils/Utils";
import IDocumentDownloader from "../document/downloader/IDocumentDownloader";

export default class ELCollageDocDownloader extends IDocumentDownloader {
    async download(downloadData: ELDownloadData): Promise<boolean> {
        Logger.log(LogLevel.INFO, "ELCollageDocDownloader - (download): downloading asset");
        try {
            const imageForDownload = downloadData.imageData.objectUrl ?? await this.stage?.getImageDataURL(downloadData.imageData);
            if (imageForDownload) {
                Utils.downloadURI(imageForDownload, downloadData.name + "." + downloadData.imageData.format?.toLocaleLowerCase());
                return Promise.resolve(true);
            } else {
                return Promise.reject("imageData not available");
            }
        } catch (error) {
            Logger.log(LogLevel.WARN, "ELCollageDocDownloader:download: " + error);
            return Promise.reject(error);
        }
    }

    async getDataURL(): Promise<string> {
        Logger.log(LogLevel.INFO, "ELCollageDocDownloader - (getDataURL): getting collage data");
        const defaultImageData: ELImageData = {};
        if (this.stage) {
            return await this.stage.getImageDataURL(defaultImageData);
        }

        Logger.log(LogLevel.ERROR, "ELCollageDocDownloader:getDataURL: invalid stage");
        return Promise.reject("stage is invalid!");
    }
}
