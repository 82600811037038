/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import Logger, { LogLevel } from "../../utils/Logger";
import ELDeeplinkHandler from "./ELDeeplinkHandler";
import { ELDeeplinkActionParams, WEB_APP_SOURCE_NAME } from "./ELDeeplinkTypes";
import IDeeplinkCreator from "./IDeeplinkCreator";

type successFailureCb = (isSuccessful: boolean) => void;

export default class ELDeeplinkManager {
    private _deeplinkCreator: IDeeplinkCreator;
    private _deeplinkHandler: ELDeeplinkHandler;
    private _deeplinkSuccessFailureCallback: successFailureCb;

    constructor(deeplinkCreator: IDeeplinkCreator, successFailureCallback: successFailureCb) {
        this._deeplinkCreator = deeplinkCreator;
        this._deeplinkHandler = new ELDeeplinkHandler();
        this._deeplinkSuccessFailureCallback = successFailureCallback;
    }

    private _appendCommonParams(params: Map<ELDeeplinkActionParams, string>): Map<ELDeeplinkActionParams, string> {
        const updatedParams = params;
        updatedParams.set(ELDeeplinkActionParams.source, WEB_APP_SOURCE_NAME);
        return updatedParams;
    }

    public createAndOpenDeeplink(params: Map<ELDeeplinkActionParams, string>): void {
        const updatedParams = this._appendCommonParams(params);
        const link = this._deeplinkCreator.createLink(updatedParams);

        this._deeplinkHandler.openLink(link).then(() => {
            Logger.log(LogLevel.INFO, "ELDeeplinkManager:createAndOpenDeeplink - deeplink open success");
            this._deeplinkSuccessFailureCallback(true);
        }).catch(() => {
            Logger.log(LogLevel.WARN, "ELDeeplinkManager:createAndOpenDeeplink - deeplink open failure");
            this._deeplinkSuccessFailureCallback(false);
        });
    }
}
