/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { DocumentType } from "../../document/IDoc";
import ELPeekThroughPSDConvertor from "./ELPeekThroughPSDConvertor";
import ELStageDocPSDConvertor from "./ELStageDocPSDConvertor";
import IPSDConvertor from "./IPSDConvertor";

export const PSDConvertorMap: { [key in DocumentType]: any } = {
    [DocumentType.peekThrough]: { convertor: ELPeekThroughPSDConvertor },
    [DocumentType.collage]: { convertor: {} },
    [DocumentType.adobeAsset]: { convertor: {} },
    [DocumentType.stageDoc]: { convertor: ELStageDocPSDConvertor },
    [DocumentType.pie]: { convertor: {} },
};

export default abstract class ELPSDConvertorFactory {
    static createConvertor(docType: DocumentType): IPSDConvertor {
        return new PSDConvertorMap[docType].convertor();
    }
}
