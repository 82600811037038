/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import { Provider as ReactReduxProvider } from "react-redux";
import { PositionProps } from '@react-types/overlays';

//Adobe Internal
import { DialogTrigger } from "@adobe/react-spectrum";

//Application Specific
import { ShareOptions } from "../el-share-options/ELShareOptions";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import ELActionButton, { ELActionButtonVariant } from "../../molecules/el-action-button/ELActionButton";
import Utils from "../../../../utils/Utils";
import store from "../../../../stores/store";
import { ShareOptionsView } from "../el-share-options/ELShareOptionsView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELSharePopoverView.scss";

interface ELShareOptionsPopoverProps {
    shareOptions: ShareOptions,
    sharePressCallback: () => void,
    isMobile: boolean,
    positionParams: PositionProps,
    text?: string,
    actionButtonVariant?: ELActionButtonVariant,
    popoverButtonPress: () => void,
    showQRView: boolean
}

interface ELShareButtonProps {
    onPress: () => void,
    text?: string,
    variant?: ELActionButtonVariant
}

export const ELShareButton = (props: ELShareButtonProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const shareIcon = (<ELIcon iconkey={IconType.share} fill={"#fff"} className="share-action-button__icon" />);
    return (<ELActionButton tooltip={intlHandler.formatMessage("share")} elIcon={shareIcon} text={props.text} variant={props.variant}
        onPress={props.onPress} dataTestId="share" />)
}

export const ELShareOptionsPopover = (props: ELShareOptionsPopoverProps): React.ReactElement => {
    return (
        <ELReactSpectrumV3Provider>
            <DialogTrigger key="Share" type={Utils.getSpectrumPopoverType((props.isMobile))} {...props.positionParams} crossOffset={0}>
                <ELShareButton text={props.text} onPress={() => { props.popoverButtonPress(); }} variant={props.actionButtonVariant} />
                {(close) => (
                    <ReactReduxProvider store={store}>
                        <ShareOptionsView controller={props.shareOptions} onPress={() => { props.sharePressCallback(); close(); }} showQRView={props.showQRView} />
                    </ReactReduxProvider>
                )}
            </DialogTrigger>
        </ELReactSpectrumV3Provider>
    );

}