/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

//Adobe internal
import { Flex, Grid, repeat, Slider } from "@adobe/react-spectrum";

//Application Specific
import { ELPhotoTextBackgroundContentPanel } from "./ELPhotoTextBackgroundContentPanel";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import { ELTransparentCard } from "../../organism/el-transparent-card/ELTransparentCard";
import ELColorPickerView from "../../organism/el-color-picker/ELColorPickerView";
import { COLOR_CHANGE_THROTTLE, ELPhotoTextWorkflowControllerActions } from "../../../../common/interfaces/creations/ELPhotoTextWorkflowTypes";
import { RootState } from "../../../../stores/store";
import PhotoTextAction from "../../../../stores/actions/PhotoTextAction";

import "./ELPhotoTextBackgroundContentPanel.scss";

interface ELPhotoTextPanelViewProps {
    controller: ELPhotoTextBackgroundContentPanel
}

const ELPhotoTextBackgroundContentPanelView = (props: ELPhotoTextPanelViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const reduxDispatch = useDispatch();

    const specLayoutConfig = { TILE_W: 5, TILE_H: 5, DIST_BETWEEN_TILE: 1 };
    const gridRef = useRef(null);
    const scrollParentRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: scrollParentRef,
        gridRef: gridRef
    });

    const photoTextData = useSelector((state: RootState) => state.photoTextReducer);

    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller])

    const transparentCardClicked = (): void => {
        reduxDispatch(PhotoTextAction.updateTransparentBackground(true));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.transparentCardClicked });
    }

    const handleColorChange = _.debounce((color: string): void => {
        reduxDispatch(PhotoTextAction.updateTransparentBackground(false));
        reduxDispatch(PhotoTextAction.updateBackground(color));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.backgroundColorChange, payload: color });
    }, COLOR_CHANGE_THROTTLE);

    const onSliderChange = (opacity: number): void => {
        reduxDispatch(PhotoTextAction.updateOpacity(opacity));
        props.controller.notify({ type: ELPhotoTextWorkflowControllerActions.backgroundOpacityChange, payload: opacity });
    }

    return (
        <div className="photo-text-content-panel-box">
            <ELPanelHeader title={intlHandler.formatMessage("background")} alignTitle="start" />
            <ELScrollPanel forwardedRef={scrollParentRef} UNSAFE_className="photo-text-content-panel-scroll" scrollY={true} width="100%">
                <div className="photo-text-content-panel-content-box">
                    <div ref={gridRef} >
                        <Grid marginTop={"1rem"} columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                            justifyContent="center"
                            autoRows={viewLayoutConfig.TILE_W}
                            gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                            <ELTransparentCard width={viewLayoutConfig.TILE_W} height={viewLayoutConfig.TILE_H} onPress={() => transparentCardClicked()}
                                selected={photoTextData.transparentBackground} />
                            <ELColorPickerView color={photoTextData.background} onChange={(e) => { handleColorChange(e.target.value); }} />
                        </Grid>
                        <Flex UNSAFE_className="photo-text-content-opacity-slider">
                            <Slider
                                label={intlHandler.formatMessage("opacity")}
                                value={photoTextData.opacity}
                                onChange={onSliderChange}
                                width={"100%"}
                                isDisabled={photoTextData.transparentBackground}
                                isFilled={true}
                            />
                        </Flex>
                    </div>
                </div>
            </ELScrollPanel>
        </div>
    )
}

export { ELPhotoTextBackgroundContentPanelView };