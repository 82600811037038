/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

//Application Specific
import Logger, { LogLevel } from '../../../../utils/Logger';
import { ViewAction } from '../../../IBaseController';
import IViewController, { ControllerAction } from '../../../IViewController';
import { ContentType } from '../../../../services/ElementsServices/BaseContentService';
import SlideshowTemplateView from './SlideshowTemplateView';
import { ELSlideshowTemplateViewAction } from "../../../../common/interfaces/creations/SlideshowTypes";
import BaseContentService from '../../../../services/ElementsServices/BaseContentService';
import { SlideshowTemplateData } from '../../../../common/interfaces/creations/ElementsContentTypes';
import store from '../../../../stores/store';
import ITemplateViewController from '../../../ITemplateViewController';
import { WorkflowActionType } from '../../../../workspaces/IWorkflow';

export default class SlideshowTemplate extends ITemplateViewController {
	private _contentService: BaseContentService;
	private _templateList: SlideshowTemplateData[] = [];

	constructor(owner: IViewController, contentService: BaseContentService) {
		super(owner);

		this._contentService = contentService;
		this._fetchTemplateList();
	}

	private async _fetchTemplateList(): Promise<void> {
		try {
			this._templateList = await this._contentService.getContent(ContentType.slideshowTemplate) as SlideshowTemplateData[];
			this._updateTemplateList();
		} catch (error) {
			Logger.log(LogLevel.WARN, "SlideshowTemplate:_fetchTemplateList: ", error);
		}
	}

	private _updateTemplateList(): void {
		if (this.viewDispatcher) {
			this.viewDispatcher({ type: ELSlideshowTemplateViewAction.themeData, payload: this._templateList });
		}
	}

	get getTemplateList(): SlideshowTemplateData[] {
		return this._templateList;
	}

	createView(container: HTMLElement): void {
		super.createView(container);

		const templateView = React.createElement(SlideshowTemplateView, { controller: this });
		const reactReduxProviderHydratedTemplateView = React.createElement(Provider, { store: store }, templateView);

		ReactDOM.render(reactReduxProviderHydratedTemplateView, container);
	}

	destroyView(): void {
		if (this.container) {
			ReactDOM.unmountComponentAtNode(this.container);
		}
		super.destroyView();
	}

	initialize(dispatch?: React.Dispatch<ViewAction>): void {
		super.initialize(dispatch);
		this._updateTemplateList();
	}

	destroy(): void {
		super.destroy();
	}

	async notify<T extends ControllerAction>(action: T): Promise<boolean> {
		const handled = false;
		switch (action.type) {
			case WorkflowActionType.ingest:
				{
					this._owner.notify({
						type: WorkflowActionType.ingest,
						payload: action?.payload
					});
					break;
				}
			default: {
				Logger.log(LogLevel.WARN, "SlideshowTemplate (notify) - bad controller action", action);
			}
		}
		return handled;
	}
}
