/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { AxiosResponse } from "axios";

//Adobe Internal
import { API, RequestCancelHandler } from "@elements/elementswebcommon";

//Application specific
import {
    AdobeAsset,
} from "@dcx/assets";
import Logger, { LogLevel } from "../utils/Logger";
import { LinkGenerationStatus, UnshareLinkStatus } from "../common/interfaces/share/ShareTypes";
import { RepoDirListData } from "@elements/elementswebcommon";
import Constants from "../utils/Constants/Constants";
import IMS from "./IMS";
import { ShareUtils } from "../utils/ShareUtils";

export const LINK_GENERATION_CANCEL_MSG = "Link generation cancelled by user";
const ELEMENTS_API_KEY = process.env.REACT_APP_ELEMENTS_API_KEY;

export type LinkGenerationResponse = {
    status: LinkGenerationStatus,
    link: string,
    assetsCount: number
}

export type UnshareLinkResponse = {
    status: UnshareLinkStatus
}

export class ElementsShareServiceWrapper {
    private static _singletonInstance: ElementsShareServiceWrapper | null = null;
    private _serviceAPI: API;
    private _generateLinkCancelHandler: RequestCancelHandler;
    private _linkGenerationStatus: LinkGenerationStatus;

    static getInstance(): ElementsShareServiceWrapper {
        if (ElementsShareServiceWrapper._singletonInstance !== null) return ElementsShareServiceWrapper._singletonInstance;
        ElementsShareServiceWrapper._singletonInstance = new ElementsShareServiceWrapper();
        return ElementsShareServiceWrapper._singletonInstance;
    }

    private constructor() {
        Logger.log(LogLevel.INFO, "Creating Singleton Instance of ElementsShareServiceWrapper");
        if (process.env.REACT_APP_ELEMENTS_V1_URL === undefined) {
            throw new Error("Generate Link URL not found in env");
        }

        if (ELEMENTS_API_KEY === undefined) {
            throw new Error("API Key is not set");
        }

        this._serviceAPI = new API(`${process.env.REACT_APP_ELEMENTS_V1_URL}`);
        this._generateLinkCancelHandler = new RequestCancelHandler();
        this._linkGenerationStatus = LinkGenerationStatus.notStarted;
    }

    private _getElementsAPIConfig(): Record<string, Record<string, string>> {
        return {
            headers: {
                "Content-Type": "application/json",
                "X-api-key": `${process.env.REACT_APP_IMS_API_KEY}`
            }
        }
    }

    async getCollectionData(collectionId: string): Promise<RepoDirListData> {
        try {
            const dirResult = await this._serviceAPI.get(`${Constants.ELEMENTS_SHARE_ENDPOINT}/${collectionId}`, this._getElementsAPIConfig())
                .then((response) => {
                    if (response.data?.assets && response.data?.creationDate && response.data?.userId)
                        return response;
                    else {
                        Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:getCollectionData: ", response);
                        throw new Error("[getCollectionData]: malformed response from Share API");
                    }
                }).then(
                    (response) => {
                        const adobeAssets = response.data.assets.map((element: string): AdobeAsset => {
                            return {
                                assetId: element,
                                // Need to revisit 
                                // created date, modified date, createdBy can be === collection Id's information or not?
                                // TODO diagarwa 
                                createDate: response.data.creationDate,
                                modifyDate: response.data.creationDate,
                                createdBy: response.data.userId
                            }
                        }) as Array<AdobeAsset>;
                        return { children: adobeAssets, hasNextPage: true } as RepoDirListData
                    }
                );
            return Promise.resolve(dirResult);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:getCollectionData: ", error);
            return Promise.reject();
        }
    }

    private async _makeShareLinkRequest(selectedMediaList: string[], makePublic: boolean): Promise<AxiosResponse<any>> {
        const cancelToken = this._generateLinkCancelHandler.getCancelToken;
        try {
            const response = await this._serviceAPI.post(
                {
                    "assets": selectedMediaList,
                    "makePublic": makePublic,
                },
                {
                    headers: {
                        "X-Api-Key": ELEMENTS_API_KEY,
                        "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
                    },
                    cancelToken: cancelToken
                }, Constants.ELEMENTS_SHARE_ENDPOINT as string);
            return response;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:_makeShareLinkRequest: ", "Error from makeShareLinkRequest = " + error);
            return Promise.reject(error);
        }
    }

    async generateShareLink(selectedMediaList: string[], makePublic: boolean): Promise<LinkGenerationResponse> {
        try {
            this._linkGenerationStatus = LinkGenerationStatus.started;
            const response = await this._makeShareLinkRequest(selectedMediaList, makePublic);
            const link = ShareUtils.makePublicLink(response.data.collection_id);

            if (response.status === Constants.HTTP_RESPONSE_ACCEPTED_202) {
                Logger.log(LogLevel.INFO, "Partial images shared : " + link);
                this._linkGenerationStatus = LinkGenerationStatus.partial;
                return Promise.resolve({ status: LinkGenerationStatus.partial, link: link, assetsCount: response.data.assets_count });
            } else if (response.status === Constants.HTTP_RESPONSE_OK_200) {
                Logger.log(LogLevel.INFO, "Success : " + link);
                this._linkGenerationStatus = LinkGenerationStatus.complete;
                return Promise.resolve({ status: LinkGenerationStatus.complete, link: link, assetsCount: response.data.assets_count });
            }
            Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:generateShareLink: ", "Response status not 200 or 202, status = " + response.statusText);
            this._linkGenerationStatus = LinkGenerationStatus.error;
            return Promise.reject(LinkGenerationStatus.error);
        }
        catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:generateShareLink: ", "Error from request = " + error);
            this._linkGenerationStatus = LinkGenerationStatus.error;
            return Promise.reject(error);
        }
    }

    async generateCollectionId(selectedMediaList: string[], makePublic = false): Promise<string> {
        try {
            const response = await this._makeShareLinkRequest(selectedMediaList, makePublic);
            return Promise.resolve(response.data.collection_id);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    cancelLinkGeneration(): void {
        if (this._linkGenerationStatus === LinkGenerationStatus.started)
            this._generateLinkCancelHandler?.cancelRequest(LINK_GENERATION_CANCEL_MSG);
    }

    private async _deleteShareLinkRequest(collectionId: string): Promise<AxiosResponse> {
        try {
            const response = await this._serviceAPI.delete(`${Constants.ELEMENTS_SHARE_ENDPOINT}/${collectionId}`,
                {
                    headers: {
                        "X-api-key": `${process.env.REACT_APP_IMS_API_KEY}`,
                        "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
                    }
                });
            return response;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:_deleteShareLinkRequest: ", "Error from deleteShareLinkRequest = " + error);
            return Promise.reject(error);
        }
    }

    async unshareLink(collectionId: string): Promise<UnshareLinkResponse> {
        try {
            const response = await this._deleteShareLinkRequest(collectionId);
            if (response.status === Constants.HTTP_RESPONSE_ACCEPTED_202) {
                Logger.log(LogLevel.INFO, "Success in deleting collectionId: " + collectionId);
                return Promise.resolve({ status: UnshareLinkStatus.success });
            }
            Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:unshareLink: ", "Response status not 202, status = " + response.statusText);
            return Promise.reject(UnshareLinkStatus.error);
        }
        catch (error) {
            Logger.log(LogLevel.INFO, "Failure in unsharing collectionId: " + collectionId);
            Logger.log(LogLevel.ERROR, "ElementsShareServiceWrapper:unshareLink: ", "Error from request = " + error);
            return Promise.reject(error);
        }
    }

}