/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { IMAGE_URI, ELStageObject, ELStageObjectOptions, ELStageImageObject, ELImageData, ELStageTextObject, ELStageRectObject } from "../../common/interfaces/stage/StageTypes";
import IStage from "./IStage";

export enum GraphicsStageType {
    fabricJS = "FABRIC_JS"
}

export default abstract class IGraphicsStage extends IStage {
    abstract addImageArea(options: ELStageObjectOptions): Promise<ELStageObject>;
    abstract addImageAreaWithLayout(options: ELStageObjectOptions): Promise<ELStageObject>;
    abstract addImageFromURI(uri: IMAGE_URI, options: ELStageObjectOptions, layerIndex?: number): Promise<ELStageObject>;
    abstract addImageFromElement(element: HTMLImageElement, options: ELStageObjectOptions): Promise<ELStageObject>;
    abstract addRect(options: ELStageObjectOptions): ELStageObject;
    abstract addPath(options: ELStageObjectOptions): ELStageObject;
    abstract addText(options: ELStageObjectOptions): Promise<ELStageObject>;
    abstract addToGroup(objectList: ELStageObject[], options: ELStageObjectOptions): ELStageObject;
    abstract replaceActiveImage(options: ELStageObjectOptions): Promise<ELStageObject>;
    abstract replaceRect(rectObject: ELStageRectObject, options: ELStageObjectOptions): ELStageRectObject;
    abstract replaceImage(imageObject: ELStageImageObject, options: ELStageObjectOptions): Promise<ELStageObject>;
    abstract replaceText(textObject: ELStageTextObject, options: ELStageObjectOptions): Promise<ELStageTextObject>;
    abstract getObjects(): ELStageObject[];
    abstract removeObject(object: ELStageObject): void;
    abstract getImageDataURL(imageData: ELImageData): Promise<string>;
    abstract setActiveObject(object: ELStageObject): void;
    abstract clearCanvas(): void;
}
