/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { fabric } from "fabric";

//Application Specific
import { ELPIEPoint, ELPIEQuad } from "../../geometry/ELGeometry"
import { ELTextSpread } from "../../stage/StageTypes"
import { ELPIELayerParams } from "../pie/ELPIELayerTypes"

export enum ELLayerKind {
    pixel = "pixel",
    text = "text",
    path = "path",
    adjustment = "adjustment",
    gradient = "gradient",
    layerGroup = "layerGroup",
    rectangle = "rectangle",
    image = "image"
}

export interface CSLayerTransformInfo {
    quadrilateral: ELPIEQuad,
    scale: ELPIEPoint,
    rotate: number,
    offset: ELPIEPoint,
    center?: ELPIEPoint,
    skew?: ELPIEPoint,
    flipX?: boolean,
    flipY?: boolean
}

export interface CSLayerTextInfo {
    text: string,
    fontFamily: string,
    fontSize: number,
    stroke?: string,
    strokeWidth?: number,
    shadowBlur?: number,
    shadowColor?: string,
    textAlign?: string,
    underline?: boolean,
    spread?: ELTextSpread
}

export interface CSLayerEditInfo {
    transform?: CSLayerTransformInfo,
    opacity?: number,
    fill?: string | fabric.Pattern | fabric.Gradient,
    textInfo?: CSLayerTextInfo
}

export interface ELStageLayerDataOptions {
    layerKind: ELLayerKind,
    path?: string,
    fitToBackground?: boolean,
    hasAlpha?: boolean,
    isClipped?: boolean,
    export?: boolean,
    layerParams?: ELPIELayerParams,
    redraw?: boolean,
    selectable?: boolean,
    editInfo?: CSLayerEditInfo,
    name?: string,
    visible?: boolean,
    clipPathOptions?: ELStageLayerDataOptions
}

export interface ELStageLayerData extends ELStageLayerDataOptions {
    data: ImageData | ArrayBuffer
}

export interface ELStageLayerPayload extends ELStageLayerData {
    id: string
}