/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { ReactNode, useEffect, useRef } from "react";

import { DialogTrigger } from "@react-spectrum/dialog";
import { Pressable } from "@react-aria/interactions";
import { SpectrumDialogTriggerProps } from "@react-types/dialog";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

export interface ELPopoverProps extends SpectrumDialogTriggerProps {
    bodyClassName?: string,
    triggerElementClassName?: string,
    onOpenChange?: (isOpen: boolean) => void,
    closeOnPress?: boolean
}

interface ELClosableDialogProps {
    className?: string,
    children: ReactNode
    close: () => void,
}

const ELClosableDialog = (props: ELClosableDialogProps): React.ReactElement => {
    const { className, children, close } = props;
    const popOverRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        popOverRef.current?.addEventListener("click", close, false)
        return () => {
            popOverRef.current?.removeEventListener("click", close);
        }
    }, [close])
    return (
        <div className={className} ref={popOverRef}>
            {children}
        </div>
    );
}

const ELPopover = (props: ELPopoverProps): React.ReactElement<ELPopoverProps> => {
    const triggerElementClassName = props.triggerElementClassName ?? "";
    const bodyClassName = props.bodyClassName ?? "";
    // TODO(yaverma) : Need to revisit, wrt theme management, should read theme from a single location
    return (
        <ELReactSpectrumV3Provider>
            <DialogTrigger {...props} onOpenChange={props.onOpenChange} >
                <Pressable>
                    <div className={triggerElementClassName} >
                        {props.children[0]}
                    </div>
                </Pressable>
                {
                    (close) =>
                        <ELClosableDialog className={bodyClassName} close={props.closeOnPress ? close : () => false} >
                            {props.children.length > 1 && props.children[1]}
                        </ELClosableDialog>
                }

            </DialogTrigger>
        </ELReactSpectrumV3Provider>
    );
}
export default ELPopover;