/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import IMS from "../services/IMS";
import { IngestLogging } from "../services/IngestWrapper";
import { LinkParams } from "../utils/Constants/Constants";
import { HistoryUtils } from "../utils/HistoryUtils";
import { INGEST_APP_WORKSPACE, IngestWorkflowTypes, IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestLogObjectCustomKey } from "../utils/IngestConstants";
import { IngestUtils } from "../utils/IngestUtils";
import Logger, { LogLevel } from "../utils/Logger";
import { PlatformUtils } from "../utils/PlatformUtils";
import { IMS_LOGIN_REDIRECT, RedirectToWebWorkflowsUtils } from "../utils/RedirectToWebWorkflowsUtils";
import { ShareUtils } from "../utils/ShareUtils";
import Utils from "../utils/Utils";

export class AppInitializer {
    static processUrl(url: string): void {
        let processedURL = url;
        if (RedirectToWebWorkflowsUtils.isIMSLoginRedirection(processedURL)) {
            if (IMS.getInstance().isSignedInUser() && !ShareUtils.isDesktopToWebShareHandover(processedURL)) {
                processedURL = Utils.removeParamFromLink(processedURL, LinkParams.ELEMENTS_LINK_SOURCE_PARAM);
                processedURL = Utils.removeParamFromLink(processedURL, LinkParams.ELEMENTS_REDIRECT_PARAM);
            } else if (IMS.getInstance().isSignedInUser()) {
                processedURL = Utils.removeParamFromLink(processedURL, LinkParams.ELEMENTS_REDIRECT_PARAM);
            }
            HistoryUtils.replaceHistory(processedURL);
        }
        // JIRA EO-4203613 - Removing linkSource param if not share workflow.
        else if (!ShareUtils.isDesktopToWebShareHandover(processedURL)) {
            processedURL = Utils.removeParamFromLink(processedURL, LinkParams.ELEMENTS_LINK_SOURCE_PARAM);
            HistoryUtils.replaceHistory(processedURL);
        }
    }

    static logIMSRedirectionInfo(): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = sessionStorage.getItem("linkSource") ?? "";
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.source;
        const signInIngestObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.app,
            IngestEventSubTypes.success, IngestEventTypes.redirect, IMS_LOGIN_REDIRECT, additionalLogInfo));
        IngestLogging.getInstance().logEvent(signInIngestObject);
    }

    static preInit(url: string): void {
        Logger.log(LogLevel.INFO, "Build Number: ", PlatformUtils.getBuildVersion());

        const linkSource = Utils.getLinkParamValue(url, LinkParams.ELEMENTS_LINK_SOURCE_PARAM) ?? "";
        sessionStorage.setItem("linkSource", linkSource);
    }
}