/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ShareHandler } from "./ShareHandler";
import { ShareResponse } from "../../common/interfaces/share/ShareTypes";
import { PlatformUtils } from "../../utils/PlatformUtils";
import Utils from "../../utils/Utils";
import Logger, { LogLevel } from "../../utils/Logger";

const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const SDK_VERSION = process.env.REACT_APP_FACEBOOK_SDK_VERSION;
const SDK_EN_US_SOURCE = "https://connect.facebook.net/en_US/sdk.js";
const FACEBOOK_SHARE_POPUP_WIDTH = 626;
const FACEBOOK_SHARE_POPUP_HEIGHT = 436;
export const FB_LOCALE_PARAM = "locale";
export const FB_SHARE_URL = "https://www.facebook.com/sharer/sharer.php?u=";

class FacebookShareHandler extends ShareHandler {

    private static _initFBCompleted = false;

    constructor() {
        super();

        if (APP_ID === undefined) {
            throw new Error("Facebook App id not found in env");
        }

        if (SDK_VERSION === undefined) {
            throw new Error("Facebook SDK version not found in env");
        }
    }

    private get getSDKSource(): string {
        //SHARE_REVISIT - locale specific SDK
        return SDK_EN_US_SOURCE;
    }

    private _getFbShareLink(link: string): string {
        const url = new URL(FB_SHARE_URL + link);
        url.searchParams.append(FB_LOCALE_PARAM, Utils.getCurrentLocaleInSnakeCase());
        return url.toString();
    }

    init(callback?: () => void): void {
        window.fbAsyncInit = () => {
            FB.init({
                version: SDK_VERSION as string,
                appId: APP_ID,
                xfbml: true,
            });
            FacebookShareHandler._initFBCompleted = true;
            if (callback)
                callback();
        }

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.getSDKSource;
        const scriptNode = document.getElementsByTagName("script")[0];
        if (scriptNode.parentNode)
            scriptNode.parentNode.insertBefore(script, scriptNode);
    }

    shareLink(link: string, callback: (response: ShareResponse) => void, payload?: unknown): void {
        Logger.log(LogLevel.INFO, "link fetched: ", link);

        const isMobileDevice = PlatformUtils.isMobileDevice(window.innerWidth, window.innerHeight);
        const fbLink = this._getFbShareLink(link);

        if (isMobileDevice) {
            Utils.openInNewTab(fbLink);
        } else {
            Utils.openPopup(fbLink, FACEBOOK_SHARE_POPUP_WIDTH, FACEBOOK_SHARE_POPUP_HEIGHT);
        }
    }

    get getInitCompleted(): boolean {
        return FacebookShareHandler._initFBCompleted;
    }

}

export { FacebookShareHandler }