/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { RootState } from "../../../../stores/store";
import IViewController from "../../../IViewController";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import ELManageMediaButton from "../../molecules/el-manage-media-button/ELManageMediaButton";
import ELCreationMediaView, { CreationMediaActionType } from "../el-creation-media-panel/ELCreationMediaView";

import "./ELCollageMediaView.scss";

interface ELCollageMediaViewProps {
    controller: IViewController,
}

const ELCollageMediaView = (props: ELCollageMediaViewProps): React.ReactElement => {
    const COLLAGE_WIDTH_HEIGHT_MULTIPLIER = 7;
    const COLLAGE_DIST_BETWEEN_TILE_MULTIPLIER = 3;
    const specLayoutConfig = {
        TILE_W: COLLAGE_WIDTH_HEIGHT_MULTIPLIER,
        TILE_H: COLLAGE_WIDTH_HEIGHT_MULTIPLIER,
        DIST_BETWEEN_TILE: COLLAGE_DIST_BETWEEN_TILE_MULTIPLIER
    };

    const intlHandler = IntlHandler.getInstance();
    const [status, setStatus] = useState(CreationsStatus.requested);
    const collageStoreData = useSelector((rootState: RootState) => rootState.collageReducer);
    const isCollageSuccess = (): boolean => {
        return status === CreationsStatus.success;
    }

    const onPress = (): void => {
        props.controller.notify({ type: CreationMediaActionType.manageMedia });
    }

    const collageHeaderBox = (): React.ReactElement => {
        return (<div>
            <ELPanelHeader title={intlHandler.formatMessage("selected-media")} alignTitle="start" />
            <ELManageMediaButton isEnabled={isCollageSuccess()} onPress={onPress}></ELManageMediaButton>
        </div>)
    }

    useEffect(() => {
        setStatus(collageStoreData.status);
    }, [collageStoreData])

    return (
        <ELCreationMediaView controller={props.controller} variant="collage" isDeletableThumb={false}
            specLayoutConfig={specLayoutConfig} creationHeaderBox={collageHeaderBox()} ></ELCreationMediaView>
    )
}

export default ELCollageMediaView;
