/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe internal
import { GLia } from "../../libraries/glia";

//Application specific
import { IEditingEngine } from "./IEditingEngine";
import Logger, { LogLevel } from "../../utils/Logger";
import { GLiaConfig } from "../../common/interfaces/editing/editingEngines/EditingEnginesTypes";

export class GLiaEditingEngine implements IEditingEngine<GLia, GLiaConfig> {
    private _gliaModule: GLia;
    private _readyPromise: Promise<void>;
    private _readyPromiseResolve?: () => void;
    private _readyPromiseReject?: (reason?: any) => void;

    constructor() {
        this._gliaModule = new GLia();
        this._readyPromise = new Promise((resolve, reject) => {
            this._readyPromiseResolve = resolve;
            this._readyPromiseReject = reject;
        });
    }

    async initialize(config: GLiaConfig): Promise<void> {
        try {
            this._gliaModule.init(config.ctx);
            Logger.log(LogLevel.INFO, "GLiaEditingEngine: initialize: GLia successfully initialized");
            if (this._readyPromiseResolve) {
                this._readyPromiseResolve();
            }
        } catch (error: any) {
            if (this._readyPromiseReject) {
                Logger.log(LogLevel.WARN, "GLiaEditingEngine: initialize: Error initializing GLia", error);
                this._readyPromiseReject(
                    "Error initializing GLia " + error
                );
            }
        }
    }

    async shutdown(): Promise<void> {
        this._gliaModule.destroy();
    }

    getModule(): GLia {
        return this._gliaModule;
    }

    ready(): Promise<void> {
        return this._readyPromise;
    }
}