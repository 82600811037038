/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELDeeplinkActionParams } from "./ELDeeplinkTypes";
import IDeeplinkCreator from "./IDeeplinkCreator";

const URL_SEPARATOR = "/";
//Commenting for now as currently not required
// const PARAMS_SEPARATOR = "&";
// const QUERY_STRING_SEPARATOR = "?";

export default class ELPSEDeeplinkCreator extends IDeeplinkCreator {

    constructor() {
        const scheme = "adbpse://";
        const host = "app";
        const version = "v1";

        super(scheme, host, version);
    }

    public createLink(params: Map<ELDeeplinkActionParams, string>): string {
        const link = this._scheme + this._host + URL_SEPARATOR + this._version;
        const url = new URL(link);

        for (const [key, value] of params) {
            url.searchParams.append(key, value);
        }

        return decodeURIComponent(url.toString());
    }
}
