/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import IAction from "./IAction";


export default class ImageEditWorkflowAction {
    public static readonly UPDATE_STATUS: string = "ImageEditWorkflowAction.UPDATE_STATUS";

    // this status is needed whether the workflow is in ready state to replace media or not
    // as the ELSingleImageMediaManager requires this status to enable/disable the replace button
    public static updateStatus(status: CreationsStatus): IAction<CreationsStatus> {
        return {
            type: ImageEditWorkflowAction.UPDATE_STATUS,
            payload: status
        };
    }
}