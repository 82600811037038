/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Request } from "@elements/elementswebcommon";

//Application Specific
import { ELBatchRequestResponse } from "../../../common/interfaces/modules/elBatchHandler/ELCreationBatchHandlerTypes";
import { IBatchProcessor } from "./IBatchProcessor";
import ELCreationBatchRequestHandler from "../elBatchRequestHandler/ELCreationBatchHandler/ELCreationBatchRequestHandler";

export abstract class ElementsCreationBatchProcessor<ResponseData> extends IBatchProcessor<Request, ResponseData> {

    protected progress = 0;
    protected batchResponseData: ELBatchRequestResponse[] = [];
    protected batchRequestHandler?: ELCreationBatchRequestHandler<ResponseData>;
    
    abstract deleteRequestedCreations(): Promise<boolean>;
    abstract retryAllFailedRequests(): Promise<boolean>;
}