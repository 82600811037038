/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//Application Specific
import { WorkflowsName } from "../../IWorkflow";
import { IconType } from "../../../assets/IconConstants";
import { ShareMode } from "../../../common/interfaces/share/ShareTypes";

type ShareModeViewDataType = {
    shareWorkflow: WorkflowsName,
    linkGenerationMsgLabel: string,
    icon: IconType,
};

const FacebookData: ShareModeViewDataType = {
    shareWorkflow: WorkflowsName.facebookShare,
    linkGenerationMsgLabel: "facebook-link-generation-msg",
    icon: IconType.facebook
}

const LinkData: ShareModeViewDataType = {
    shareWorkflow: WorkflowsName.linkShare,
    linkGenerationMsgLabel: "link-generation-msg",
    icon: IconType.link
}

const EmailData: ShareModeViewDataType = {
    shareWorkflow: WorkflowsName.emailShare,
    linkGenerationMsgLabel: "link-generation-msg",
    icon: IconType.email
}


const ShareModeDataMap: { [key in ShareMode]: ShareModeViewDataType } = {
    [ShareMode.facebook]: FacebookData,
    [ShareMode.email]: EmailData,
    [ShareMode.link]: LinkData,
}

export class ShareModeViewData {
    static getShareModeData(shareMode: ShareMode): ShareModeViewDataType {
        return ShareModeDataMap[shareMode];
    }
}