/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React from "react";

// Adobe internal
import { NumberField } from '@adobe/react-spectrum';

import "./ELNumberField.scss";

export interface ELNumberFieldProps {
    value: number,
    minValue: number,
    maxValue: number,
    onChange: (value: number) => void
}

const ELNumberField = (props: ELNumberFieldProps): React.ReactElement => {

    return (
        <NumberField
            value={props.value}
            minValue={props.minValue}
            maxValue={props.maxValue}
            onChange={props.onChange}
            UNSAFE_className="el-number-field"
        />
    );
}

export default ELNumberField;