/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Application Specific
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELNoMediaBanner } from "../../molecules/el-no-media-banner/ELNoMediaBannerView";

import "./ELEmptyCreationsBanner.scss";

interface ELEmptyCreationsBannerViewProps {
    textKey: string,
    children?: React.ReactElement
}

export const ELEmptyCreationsBannerView = (props: ELEmptyCreationsBannerViewProps): React.ReactElement => {
    const elIcon = <ELIcon iconkey={IconType.emptyELMediaGrid} width="10rem" height="10rem" />;
    const intlHandler = IntlHandler.getInstance();


    const textBody = (<>
        <span className="no-creations-banner__paras">{intlHandler.formatMessage(props.textKey)}</span>
    </>);

    return (
        <ELNoMediaBanner icon={elIcon} textBody={textBody} className="no-creations-banner"
            heading={intlHandler.formatMessage("no-creations-banner-heading")}>
                {props.children}
        </ELNoMediaBanner>
    )
}
