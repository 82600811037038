/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELCollageDocActions } from "../../common/interfaces/creations/collage/ELCollageDocTypes";
import { RenderedShapesName } from "../../common/interfaces/renderer/RendererTypes";
import { ELCollageAssetInfo } from "../../common/interfaces/creations/ELCollageTypes";
import { RendererUpdateData } from "../../common/interfaces/renderer/RendererTypes";
import { ELStageBackground, ELStageObjectOptions } from "../../common/interfaces/stage/StageTypes";
import { StageUtils } from "../../utils/stage/StageUtils";
import IDocDataParser from "../document/parser/IDocDataParser";
import IRenderer from "../renderer/IRenderer";
import IGraphicsStage from "../stage/IGraphicsStage";
import { CSAssetWithData } from "../../common/interfaces/creations/CreationTypes";
import { ControllerAction } from "../../view/IViewController";
import IDoc from "../document/IDoc";

export default class ELCollageRenderer extends IRenderer {
    private _doc: IDoc;

    constructor(doc: IDoc, stage: IGraphicsStage, dataParser: IDocDataParser) {
        super(stage, dataParser);
        this._doc = doc;
    }

    hasRenderingError(): boolean {
        for (const stageData of this.stageDataList) {
            if (stageData.stageShapeAndObjectOptions.shape.hasError()) {
                return true;
            }
        }

        return false;
    }

    async update(updateData: RendererUpdateData): Promise<void> {
        switch (updateData.type) {
            case ELCollageDocActions.assetUpdated: {
                for (const stageData of this.stageDataList) {
                    const data = (updateData.payload as ELCollageAssetInfo).asset;
                    if (stageData.object && stageData.object.data && stageData.object.data.payload.asset.assetURN === data.assetURN) {
                        if (data.objectURL !== undefined) {
                            const imageOptions: ELStageObjectOptions = {
                                ...stageData.stageShapeAndObjectOptions.objectOptions,
                                data: {
                                    ...stageData.stageShapeAndObjectOptions.objectOptions.data,
                                    payload: {
                                        ...stageData.stageShapeAndObjectOptions.objectOptions.data.payload,
                                        asset: {
                                            ...stageData.stageShapeAndObjectOptions.objectOptions.data.payload.asset,
                                            objectURL: data.objectURL
                                        }
                                    }
                                },
                                image: data.objectURL
                            };
                            await this.updateShape(stageData.stageShapeAndObjectOptions.shape, imageOptions);
                        }
                    }
                }
                break;
            }
            case ELCollageDocActions.backgroundChanged: {
                const backgroundData = StageUtils.getRenderedDataListByName(this.stageDataList, [RenderedShapesName.imageArea])[0];

                const background = updateData.payload as ELStageBackground;
                const newBackgroundOptions = {
                    ...backgroundData.stageShapeAndObjectOptions.objectOptions,
                    background: background
                }

                const updatedObject = await this.updateShape(backgroundData.stageShapeAndObjectOptions.shape, newBackgroundOptions);
                if (updatedObject) {
                    backgroundData.object = updatedObject;
                    backgroundData.stageShapeAndObjectOptions.objectOptions = newBackgroundOptions;
                }

                const filteredStageDataList = StageUtils.getRenderedDataListByExclusionName(this.stageDataList, [RenderedShapesName.imageArea]);
                filteredStageDataList.push(backgroundData);

                this.stageDataList = filteredStageDataList;

                break;
            }
            case ELCollageDocActions.assetReplaced: {
                const replaceAssetInfo = updateData.payload as { assetToReplaceWith: CSAssetWithData, assetIdToReplace: string, layoutId: unknown };
                const indexToReplace = this.stageDataList.findIndex(stageData => stageData.object?.data?.payload.layoutId === replaceAssetInfo.layoutId);

                this.stageDataList[indexToReplace].stageShapeAndObjectOptions.objectOptions.data.payload = { asset: replaceAssetInfo.assetToReplaceWith, layoutId: replaceAssetInfo.layoutId };

                const imageOptions: ELStageObjectOptions = {
                    ...this.stageDataList[indexToReplace].stageShapeAndObjectOptions.objectOptions,
                    image: replaceAssetInfo.assetToReplaceWith.objectURL as string,
                    fitToClipShape: true,
                    width: this.stageDataList[indexToReplace].stageShapeAndObjectOptions.clipObjectOptions?.width,
                    height: this.stageDataList[indexToReplace].stageShapeAndObjectOptions.clipObjectOptions?.height,
                    top: this.stageDataList[indexToReplace].stageShapeAndObjectOptions.clipObjectOptions?.top,
                    left: this.stageDataList[indexToReplace].stageShapeAndObjectOptions.clipObjectOptions?.left,
                    angle: 0
                };

                await this.updateShape(this.stageDataList[indexToReplace].stageShapeAndObjectOptions.shape, imageOptions);
                break;
            }
        }
    }

    destroy(): void {
        super.destroy();
        this.stage.destroy();
    }

    async render(container: HTMLElement): Promise<void> {
        await super.render(container);
        return new Promise((resolve) => {
            this.stage.setBackground("#ececec");
            this.dataParser.parseDocData().then((stageShapeAndObjectOptionsList) => {
                this.drawShapes(stageShapeAndObjectOptionsList).then((stageObjectList) => {
                    this.saveStageData(stageShapeAndObjectOptionsList, stageObjectList);
                    resolve();
                });
            });
        });
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return await super.notify(action);
    }
}
