/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Application specific
import { CreationsStatus, CreationReducers } from "../../../../common/interfaces/creations/CreationTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { RootState } from "../../../../stores/store";
import IViewController from "../../../IViewController";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import ELManageMediaButton from "../../molecules/el-manage-media-button/ELManageMediaButton";
import ELCreationMediaView, { CreationMediaActionType } from "../el-creation-media-panel/ELCreationMediaView";
interface ELSingleImageMediaManagerViewProps {
    controller: IViewController,
    currentReducer: CreationReducers
}

export const ELSingleImageMediaManagerView = (props: ELSingleImageMediaManagerViewProps): React.ReactElement => {
    const THUMB_WIDTH_HEIGHT_MULTIPLIER = 7;
    const THUMB_DIST_BETWEEN_TILE_MULTIPLIER = 3;
    const specLayoutConfig = {
        TILE_W: THUMB_WIDTH_HEIGHT_MULTIPLIER,
        TILE_H: THUMB_WIDTH_HEIGHT_MULTIPLIER,
        DIST_BETWEEN_TILE: THUMB_DIST_BETWEEN_TILE_MULTIPLIER
    };

    const intlHandler = IntlHandler.getInstance();
    const [status, setStatus] = useState(CreationsStatus.requested);
    const currentWorkflowReducerData = useSelector((rootState: RootState) => rootState[props.currentReducer])

    const isManageMediaEnabled = (): boolean => {
        return status === CreationsStatus.success;
    }

    const onPress = (): void => {
        props.controller.notify({ type: CreationMediaActionType.replaceMedia });
    }

    const singleImageMediaMangerHeaderBox = (): React.ReactElement => {
        return (<div>
            <ELPanelHeader title={intlHandler.formatMessage("single-image-selected-media-manager")} alignTitle="start" />
            <ELManageMediaButton isEnabled={isManageMediaEnabled()} onPress={onPress} title={"replace-media"}></ELManageMediaButton>
        </div>)
    }

    useEffect(() => {
        setStatus(currentWorkflowReducerData.status);
    }, [currentWorkflowReducerData])

    return (
        <ELCreationMediaView controller={props.controller} variant="collage" isDeletableThumb={false}
            specLayoutConfig={specLayoutConfig} creationHeaderBox={singleImageMediaMangerHeaderBox()} ></ELCreationMediaView>
    )
}