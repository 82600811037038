/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React, { useState } from "react";
import FieldLabel from '@react/react-spectrum/FieldLabel';

// Adobe internal
import { Content, Dialog, Divider, Flex, Heading, Text, TextField, TextArea, Link } from "@adobe/react-spectrum";
import { RadioGroup, Radio } from '@adobe/react-spectrum';

//utils
import Utils from "../../../../utils/Utils";
import { TERMS_OF_USE_HREF } from "../../../../utils/Constants/Constants"

//service
import IMS from "./../../../../services/IMS";
import { IReportAbuseUserInputData, IReportAbuseType } from "../../../../services/leia-service/ReportAbuseInterface";

// Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../atoms/el-button/ELButtonView";
import "./ELReportAbuseDialogView.scss";

export interface ELReportAbuseDialogViewProps {
    onClose: () => void,
    onCancel: () => void,
    onConfirm: (data: IReportAbuseUserInputData) => void,
    heading?: string,
    subHeading?: string,
}

export const ELReportAbuseDialogView = (props: ELReportAbuseDialogViewProps): React.ReactElement => {

    const intlHandler = IntlHandler.getInstance();
    const radioButtonsList = [
        { id: 'defamation' },
        { id: 'hate' },
        { id: 'trademark' },
        { id: 'pornography' },
        { id: 'vulgar' },
        { id: 'other' },
    ];

    //const isLoggedIn = false;
    const isLoggedIn = IMS.getInstance().isSignedInUser();

    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [reporterName, setReporterName] = useState((isLoggedIn && (typeof IMS.getInstance().getUserName() === 'string')) ?
        (IMS.getInstance().getUserName()) as string : "");
    const [reporterEmail, setReporterEmail] = useState((isLoggedIn && (typeof IMS.getInstance().getUserEmail() === 'string')) ?
        (IMS.getInstance().getUserEmail()) as string : "");
    const [reason, setReporterReason] = useState("");
    const [reasonDescription, setReasonDescription] = useState("");

    const handleTextAreaValueChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setReasonDescription(e.target.value);
    }

    const handleConfirm = (): void => {
        setAttemptedSubmit(true);
        if (!reason || !reporterName || !Utils.isValidEmail(reporterEmail)) {
            return; // Keep the dialog open.
        }
        const data: IReportAbuseUserInputData = { reporterName: reporterName, reporterEmail: reporterEmail, reportType: reason as IReportAbuseType, reportDesc: reasonDescription };
        props.onConfirm(data);
        props.onClose();
    };

    return (
        <Dialog UNSAFE_className={"report-abuse-dialog"}>
            <Heading>
                <div className="report-abuse-dialog-title">{props.heading}</div>
            </Heading>
            <Divider size={"S"} />
            <Content>
                <Flex direction="column" alignItems="start" gap={"size-50"}>
                    <span className="report-abuse-dialog-body-text">{props.subHeading}
                        <Link onPress={() => Utils.openInNewTab(TERMS_OF_USE_HREF)} UNSAFE_className="report-abuse-dialog-terms-of-use">
                            {intlHandler.formatMessage("terms-of-use")}
                        </Link>
                        <span className="report-abuse-dialog-body-text">{intlHandler.formatMessage("report-abuse-description-part2")}</span>
                    </span>
                    <p className="report-abuse-dialog-bold-text">
                        {intlHandler.formatMessage("report-abuse-contact-information")}
                    </p>
                    <span className="report-abuse-dialog-body-text">{intlHandler.formatMessage("report-abuse-require-contact-info")}</span>

                    <Flex UNSAFE_className="report-abuse-input-box">
                        <FieldLabel

                            label={intlHandler.formatMessage("report-abuse-your-name")}
                            necessity="required"
                            className="report-abuse__field_label_name"
                        >
                        </FieldLabel>
                        <TextField
                            isDisabled={isLoggedIn}
                            isRequired={attemptedSubmit && !isLoggedIn}
                            onChange={setReporterName}
                            value={reporterName}
                            width="20rem"
                            validationState={
                                attemptedSubmit && !reporterName ? 'invalid' : undefined
                            }
                            UNSAFE_className="report-abuse-input-text-field"
                        />
                    </Flex>
                    <Flex UNSAFE_className="report-abuse-input-box" >
                        <FieldLabel
                            label={intlHandler.formatMessage("report-abuse-your-email-address")}
                            necessity="required"
                            className="report-abuse__field_label_mail"
                        >
                        </FieldLabel>
                        <TextField
                            isDisabled={isLoggedIn}
                            isRequired={attemptedSubmit && !isLoggedIn}
                            onChange={setReporterEmail}
                            validationState={
                                attemptedSubmit && !Utils.isValidEmail(reporterEmail)
                                    ? 'invalid'
                                    : undefined
                            }
                            value={reporterEmail}
                            UNSAFE_className="report-abuse-input-text-field"
                        />
                    </Flex>
                    <br></br>
                    <FieldLabel
                        label={intlHandler.formatMessage("report-abuse-why-report-this-material")}
                        necessity="required"
                        className="report-abuse-dialog-bold-text"
                    />
                    <Flex UNSAFE_className="report-abuse-radio-button" >
                        <RadioGroup
                            value={reason}
                            onChange={setReporterReason}
                            validationState={attemptedSubmit && !reason ? "invalid" : undefined}
                        >
                            <div >
                                {radioButtonsList.map(item => {
                                    return (
                                        <Radio value={item.id} key={item.id}>
                                            <div className="report-abuse-dialog-body-text">
                                                {intlHandler.formatMessage(`report-abuse-${item.id}`)}
                                            </div>
                                        </Radio>
                                    );
                                })}
                            </div>
                        </RadioGroup>
                    </Flex>
                    <TextArea
                        onInput={handleTextAreaValueChanged}
                        placeholder={intlHandler.formatMessage("report_abuse-other-reason-description")}
                        width="90%"
                        id="report-abuse-reason-description-message" />
                    <Flex>
                        <p className="report-abuse-dialog-body-text"> {intlHandler.formatMessage("report-abuse-copyright-violation")}
                            <Link onPress={() => Utils.openInNewTab(TERMS_OF_USE_HREF)} UNSAFE_className="report-abuse-dialog-terms-of-use">
                                {intlHandler.formatMessage("terms-of-use")}
                            </Link>
                        </p>
                    </Flex>
                    <Flex alignSelf="end" >
                        <Flex UNSAFE_className="report-abuse-cancel-btn">
                            <ELButton size="L" onClick={props.onCancel} >
                                <Text UNSAFE_className=" report-abuse-dialog__cta-text">{intlHandler.formatMessage("cancel")}</Text>
                            </ELButton>
                        </Flex>
                        <ELButton size="L" onClick={handleConfirm}>
                            <Text UNSAFE_className="report-abuse-dialog__cta-text">{intlHandler.formatMessage("submit")}</Text>
                        </ELButton>
                    </Flex>
                </Flex>
            </Content>
        </Dialog>
    );
}