/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//Application Specific
import { Routes } from "../../../../../app/AppRoute";
import Utils from "../../../../../utils/Utils";
import { WorkflowsName } from "../../../../IWorkflow";
import {
    CreationsExportSettings,
    CreationsOperationsOutputs,
    CreationsOperationsOutputsData,
    CreationsVideoExportPreset,
    CreationsURLParams,
    CreationsImageMimeType,
    CreationsOperationsSpecificInfo,
    CreationsStatusPayload,
    CURRENT_CREATION_VERSION,
    CreationsMode
} from "../../../../../common/interfaces/creations/CreationTypes";
import { FilterMediaData, FilterMediaUiProp, GridMediaType, MediaGridConfig, MediaGridSortBy } from "../../../../../stores/actions/mediaGridConfigActions";
import { AVI_FILE_FORMAT, M4V_FILE_FORMAT, PSDC_FILE_FORMAT, PSD_FILE_FORMATS } from "../../../../../common/interfaces/storage/FileTypes";
import store from "../../../../../stores/store";
import { GRID_CONFIG_KEY } from "../../../../../stores/reducers/mediaGridConfigReducer";
import { ELAdobeAsset } from "../../../../../common/interfaces/storage/AssetTypes";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { SlideshowInputInfo } from "../../../../../common/interfaces/creations/SlideshowTypes";
import { WorkspaceAction, WorkspacePayload } from "../../../../IBaseWorkspace";
import CreationUtils from "../../../utils/CreationUtils";


export default class SlideshowUtils {

    /**
     * @returns boolean - true if slideshow url is present
     */
    static hasSlideshowUrl(): boolean {
        const workflow = Utils.getLinkParamValue(window.location.href, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.slideshow.toLowerCase() === workflow.toLowerCase());
    }

    /**
     * @returns string - history state based on projectId
     */
    static getSlideshowHistoryState(projectId: string): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + WorkflowsName.slideshow.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    /**
     * @returns string - history state based on projectId
     */
    static getSlideshowURL(projectId: string): string {
        return window.location.origin + SlideshowUtils.getSlideshowHistoryState(projectId);
    }

    /**
     * @returns string - returns history state when we go from slideshow to it's parent view
     */
    static getParentHistoryState(): string {
        return Routes.CREATIONS;
    }

    /**
     * @returns string - slideshow id from window href
     */
    static getSlideshowIdFromHref(): string {
        const projectId = Utils.getLinkParamValue(window.location.href, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }

    /**
     * @returns WorkspacePayload
     */
    static getSlideshowPayload(): WorkspacePayload {
        const projectId = SlideshowUtils.getSlideshowIdFromHref();
        let mode = CreationsMode.render;
        let payload: CreationsStatusPayload | ELAdobeAsset[] | null = {
            projectId: projectId
        };

        if (projectId === Utils.getNilUUID()) {
            mode = CreationsMode.create
            payload = store.getState().selectedMediaListReducer;
        }

        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.slideshow,
            initMode: mode,
            payload: payload
        };

        return workspacePayload;
    }

    /**
     * @returns MediaGridConfig for slideshow workflow
     */
    static getSlideshowMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);
        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageAndVideo;
        const filterData: FilterMediaData = {
            format: [PSDC_FILE_FORMAT, AVI_FILE_FORMAT, M4V_FILE_FORMAT, ...PSD_FILE_FORMATS],
            uiProp: FilterMediaUiProp.hide
        }
        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 40;
        mediaGridConfig.maxVideoCount = 5;
        mediaGridConfig.maxTotalMediaSize = 400; //in MB
        mediaGridConfig.maxVideoLength = 10 * 60;
        mediaGridConfig.selectUploadedMedia = true;
        mediaGridConfig.minImageCount = undefined;
        mediaGridConfig.maxImageCount = undefined;

        return mediaGridConfig;
    }

    /**
     * @returns string - slideshow id from window href
     */
    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = await CreationUtils.filterVideoAssets(assets, mediaGridConfig);
        filteredAssets = CreationUtils.filterMediaByFormat(assets, mediaGridConfig);
        filteredAssets = CreationUtils.filterMediaByCount(filteredAssets, mediaGridConfig);
        filteredAssets = CreationUtils.filterMediaBySize(filteredAssets, mediaGridConfig);

        Logger.log(LogLevel.INFO, "SlideshowUtils - getFilteredAssetList: ", filteredAssets);
        return filteredAssets;
    }

    /**
     * @returns string - slideshow id from window href
     */
    static canStartSlideshow(selectedAssets: ELAdobeAsset[]): boolean {
        return selectedAssets.length > 0;
    }

    static isOpeningSlideshow(workspaceAction: WorkspaceAction): boolean {
        return workspaceAction.initMode === CreationsMode.render
    }

    static getDefaultFullExportSettings(exportPreset?: CreationsVideoExportPreset): CreationsExportSettings {
        const fullExportSettings: CreationsExportSettings = {
            version: 1,
            preset: exportPreset ?? CreationsVideoExportPreset.EPR360,
            hardwareAccelerated: true,
            startTime: 0,
            endTime: 360000000,
            renderQuality: 4
        }

        return fullExportSettings;
    }

    static getSlideshowOperationsOutput(exportPreset: CreationsVideoExportPreset): CreationsOperationsOutputs {
        const previewExportSettings: CreationsExportSettings = {
            version: 1,
            width: 240,
            height: 120,
            mimeType: CreationsImageMimeType.IMAGE_PNG
        }
        const previewOutputData: CreationsOperationsOutputsData = {
            version: 1,
            type: "image",
            exportSettings: previewExportSettings
        };

        const fullExportSettings = SlideshowUtils.getDefaultFullExportSettings(exportPreset);

        const fullOutputData: CreationsOperationsOutputsData = {
            version: 1,
            type: "video",
            exportSettings: fullExportSettings
        };

        const slideshowOperationsOutputs: CreationsOperationsOutputs = {
            preview: previewOutputData,
            full: fullOutputData,
            project: {
                version: 1
            },
        }

        return slideshowOperationsOutputs;
    }

    static getSlideshowOperationsInfo(inputInfo: SlideshowInputInfo, exportPreset: CreationsVideoExportPreset): CreationsOperationsSpecificInfo {
        const info: CreationsOperationsSpecificInfo = {
            version: CURRENT_CREATION_VERSION,
            inputs: inputInfo,
            outputs: SlideshowUtils.getSlideshowOperationsOutput(exportPreset)
        }

        return info;
    }
}
