/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React, { useEffect, useState } from "react";

// Adobe internal
import { Button, Checkbox, CheckboxGroup, Content, Dialog, DialogContainer, Divider, Flex, Heading, TextArea } from "@adobe/react-spectrum";
import { KeyboardEvent } from "@react-types/shared";

// Application Specific
import ELButton from "../../atoms/el-button/ELButtonView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import IViewController, { ControllerAction } from "../../../IViewController";
import KeyEventUtils from "../../../../utils/KeyEventUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { ELCreationFeedbackDialogAction, ELCreationFeedbackPayload, ELCreationFeedbackProps } from "./ELCreationFeedbackTypes";

import "./ELCreationFeedbackDialogView.scss";


interface ELCreationFeedbackDialogViewProps {
    controller: IViewController,
    feedbackConfig: ELCreationFeedbackProps;
}


export const ELCreationFeedbackDialogView = (props: ELCreationFeedbackDialogViewProps): React.ReactElement => {

    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    const intlHandler = IntlHandler.getInstance();
    const [feedback, setFeedback] = useState("");
    const [selectedFeedback, setSelectedFeedback] = useState<Array<string>>([]);

    const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFeedback(e.target.value);
    };

    const handleFeedbackSelection = (selectedFeedbacks: string[]): void => {
        setSelectedFeedback(selectedFeedbacks);
    };

    const onKeyDown = (e: KeyboardEvent): void => {
        if (KeyEventUtils.isComposingEscape(e.nativeEvent)) {
            e.stopPropagation();
        } else {
            e.continuePropagation();
        }
    };

    const handleSubmit = (feedback: string): void => {
        const overallFeedback: ELCreationFeedbackPayload = {
            feedbackMessage: feedback !== "" ? feedback : undefined,
            checkboxFeedback: selectedFeedback
        }
        const action: ControllerAction = { type: ELCreationFeedbackDialogAction.onFeedbackSubmit, payload: overallFeedback };
        props.controller.notify(action);
    };

    const notifyDismiss = (): void => {
        const action: ControllerAction = { type: ELCreationFeedbackDialogAction.onDismiss };
        props.controller.notify(action);
    };

    const getFeedbackCheckbox = (): React.ReactElement => {
        const { feedbackPoints } = props.feedbackConfig;
        let feedbackList = <></>;
        if (feedbackPoints.length) {
            const checkboxList = feedbackPoints.map(point => {
                return (
                    <Checkbox value={point.ingestString} key={point.ingestString}>
                        <span className="creation-feedback-dialog__checkbox-message">{intlHandler.formatMessage(point.message)}</span>
                    </Checkbox>
                );
            });
            feedbackList = <CheckboxGroup onChange={handleFeedbackSelection}>
                {checkboxList}
            </CheckboxGroup>;
        }
        return feedbackList;
    };

    return (
        <ELReactSpectrumV3Provider>
            <DialogContainer onDismiss={() => notifyDismiss()} isDismissable>
                <Dialog data-testid="creation-feedback-dialog" UNSAFE_className={"creation-feedback-dialog__container"}>
                    <Heading>
                        <div data-testid="creation-feedback-dialog__heading" className="creation-feedback-dialog__heading">{intlHandler.formatMessage(props.feedbackConfig.heading)}</div>
                        <div data-testid="creation-feedback-dialog__sub-heading" className="creation-feedback-dialog__sub-heading">{intlHandler.formatMessage(props.feedbackConfig.subHeading)}</div>
                    </Heading>
                    <Divider size={"S"} />
                    <Content>
                        <Flex direction="column" alignItems="start" gap={"size-50"}>
                            <div data-testid="creation-feedback-dialog__check-boxes">
                                {getFeedbackCheckbox()}
                            </div>
                            <div data-testid="creation-feedback-dialog__text-area-heading" className="creation-feedback-dialog__text-area-heading">{intlHandler.formatMessage("feedback-other-concern")}</div>
                            <TextArea data-testid="creation-feedback-dialog__text-area"
                                onInput={handleFeedbackChange} placeholder={intlHandler.formatMessage("feedback-add-more-details")}
                                width="100%" height="9.375rem" id="creation-feedback-dialog__text-area"
                                maxLength={props.feedbackConfig.feedbackCharacterLimit} onKeyDown={onKeyDown} />
                            <Flex alignSelf="flex-end" marginTop="1.75rem" gap="1rem">
                                <Button margin={"0px 4px"} variant="secondary" onPress={notifyDismiss}>
                                    <span className="creation-feedback-dialog__cta">
                                        {intlHandler.formatMessage("feedback-skip")}
                                    </span>
                                </Button>
                                <ELButton dataTestId="creation-feedback-dialog__cta" size="L"
                                    onClick={() => handleSubmit(feedback)} isDisabled={feedback.length <= 0 && selectedFeedback.length <= 0} >
                                    <span className="creation-feedback-dialog__cta">{intlHandler.formatMessage("feedback-send")}</span>
                                </ELButton>
                            </Flex>
                        </Flex>
                    </Content>
                </Dialog>
            </DialogContainer>
        </ELReactSpectrumV3Provider>
    );
};