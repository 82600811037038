/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageShapesType } from "../../../common/interfaces/stage/StageTypes";
import ELStageImageArea from "./ELStageImageArea";
import ELStageImageAreaWithLayout from "./ELStageImageAreaWithLayout";
import ELStageImageFromElement from "./ELStageImageFromElement";
import ELStageImageFromURI from "./ELStageImageFromURI";
import ELStagePath from "./ELStagePath";
import ELStageRect from "./ELStageRect";
import ELStageText from "./ELStageText";
import IStageShapes from "./IStageShapes";


export const ShapesMap: { [key in ELStageShapesType]: any } = {
    [ELStageShapesType.imageArea]: ELStageImageArea,
    [ELStageShapesType.imageAreaWithLayout]: ELStageImageAreaWithLayout,
    [ELStageShapesType.imageFromURI]: ELStageImageFromURI,
    [ELStageShapesType.imageFromElement]: ELStageImageFromElement,
    [ELStageShapesType.path]: ELStagePath,
    [ELStageShapesType.rect]: ELStageRect,
    [ELStageShapesType.text]: ELStageText
};

export default abstract class ShapesFactory {
    static createShape(shapeType: ELStageShapesType): IStageShapes {
        const shape = new ShapesMap[shapeType]();
        return shape;
    }
}
