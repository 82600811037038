/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELPIEPoint, ELPIEQuad, ELRect, ELStroke } from "../geometry/ELGeometry"
import { ELAdobeAsset } from "../storage/AssetTypes"
import { CSAssetWithData } from "./CreationTypes"
import { CollageTemplateData } from "./ElementsContentTypes"
import { IBaseRequestParams } from "./ELCreationsJobTypes";

export enum ELCollageCategoryEnum {
    instagram = "instagram",
    facebookCover = "facebook cover",
    landscape = "landscape",
    portrait = "portrait"
}

export interface ELCollageLayout {
    svgPath: string
    bounds: ELRect,
    stroke: ELStroke
}

export enum ELCollageTemplateBackgroundMode {
    color = "color",
    image = "image",
    embeddedFromPSD = "embeddedFromPSD",
    none = "none"
}

export interface ELCollageTemplateBackground {
    mode: ELCollageTemplateBackgroundMode,
    value: string
}

export interface ELCollageTemplate extends CollageTemplateData {
    background: ELCollageTemplateBackground,
    bounds: ELRect,
    layouts: ELCollageLayout[]
}

export interface ELCollageAssetInfo {
    asset: CSAssetWithData,
    edit?: CSAssetEditInfo
}

export interface CSAssetEditInfo {
    transform: CSAssetTransformInfo
}

export interface CSAssetTransformInfo {
    quadrilateral: ELPIEQuad,
    scale: ELPIEPoint,
    rotate: number,
    offset: ELPIEPoint,
    center?: ELPIEPoint,
    skew?: ELPIEPoint,
    flipX?: boolean,
    flipY?: boolean
}

export interface ELSavedCollageTemplateData {
    templateId: string,
    background: ELCollageTemplateBackground
}

export enum ELSavedCollageVersion {
    version_1_0 = "1.0"
}

export interface ELSavedCollageData {
    version: string,
    templateData: ELSavedCollageTemplateData,
    assetInfoList: ELCollageAssetInfo[]
}

export interface ReplaceAssetInfo {
    data?: unknown,
    assetIdToReplace: string,
    assetToReplaceWith: ELAdobeAsset
}

export interface ELCollageShapeData {
    asset: CSAssetWithData,
    layoutId: number
}

export enum ELCollageLayoutViewAction {
    layoutData = "LAYOUT_DATA",
    setLayoutId = "SET_LAYOUT_ID",
    setTemplateId = "SET_TEMPLATE_ID"
}

export enum BackgroundChangedStatus {
    success = "success",
    error = "error"
}


export enum ReplaceMediaStatus {
    success = "success",
    error = "error"
}

export const CollageViewActions = {
    collageStatus: "COLLAGE_STATUS",
    collageProgressText: "COLLAGE_PROGRESS_TEXT",
    showDeleteConfirmationDialog: "SHOW_DELETE_CONFIRMATION_DIALOG",
    showActiveObjectMenu: "SHOW_ACTIVE_OBJECT_MENU"
}

export enum ELCollagePanelWorkflowAction {
    manageMedia = "MANAGE_MEDIA"
}

export type ELCollageProjectRequestParams = IBaseRequestParams;