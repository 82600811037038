/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageObject, ELStageObjectOptions, ELStageShapesType } from "../../../common/interfaces/stage/StageTypes";
import IGraphicsStage from "../IGraphicsStage";
import IStageShapes from "./IStageShapes";

export default class ELStageImageArea extends IStageShapes {

    constructor() {
        super(ELStageShapesType.imageArea);
    }

    async update<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject> {
        const oldObject = this.object as ELStageObject;
        this.object = await this.draw(stage, options);
        stage.removeObject(oldObject);
        return this.object;
    }

    async draw<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject> {
        this.object = await stage.addImageArea(options);
        return this.object;
    }
}
