/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { CreationsData, CreationsJobProjectSubType } from "../../../../common/interfaces/creations/CreationTypes";
import ITemplateViewController from "../../../../view/ITemplateViewController";
import ICreationsFullAssetProvider from "./ICreationsFullAssetProvider";
import ELPatternOverlayFullAssetProvider from "./PatternOverlayFullAssetProvider";
import ELReplaceBackgroundFullAssetProvider from "./ReplaceBackgroundFullAssetProvider";

abstract class ELCreationsFullAssetProviderFactory {
    static createFullAssetProvider(owner: ITemplateViewController, creationsData: CreationsData): ICreationsFullAssetProvider {
        let fullAssetProvider;
        switch (creationsData.operationSubType) {
            case CreationsJobProjectSubType.patternOverlay: {
                fullAssetProvider = new ELPatternOverlayFullAssetProvider(owner, creationsData);
                return fullAssetProvider;
            }
            case CreationsJobProjectSubType.replaceBackground: {
                fullAssetProvider = new ELReplaceBackgroundFullAssetProvider(owner, creationsData);
                return fullAssetProvider;
            }
            default: {
                throw new Error("Creation type not supported yet");
            }
        }
    }
}

export default ELCreationsFullAssetProviderFactory;