/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// More details about types mentioned below can be found at below link
// https://developers.corp.adobe.com/Access-Platform/docs/api-reference/api_sample.md

export enum ELAccessProfileStatus {
    PROFILE_AVAILABLE = "PROFILE_AVAILABLE",
    PROFILE_UNAVAILABLE = "PROFILE_UNAVAILABLE",
    PROFILE_EXPIRED = "PROFILE_EXPIRED"
}

export interface AdobeElementsWeb {
    feature_ids?: string[];
}

export interface FulfillableItems {
    adobe_elements_web?: AdobeElementsWeb;
}

export interface Summary {
    fulfillable_items?: FulfillableItems;
}

export interface AppProfile {
    appId: string;
    summary?: Summary;
}

// Specific to Photoshop Elements
export enum AppProfileFeatureIds {
    NDL = "NDL",    // Stands for No data logging but is not being used currently, requested by desktop team for future puposes
    EDU_D = "EDU_D",    // Educational User - Direct
    EDU_IN = "EDU_IN",  // Educational User - Indirect
}