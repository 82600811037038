/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { Dispatch } from "react";

//Application Specific
import IViewController, { ControllerAction } from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import {
    CreationAppSubscriberType,
    CreationInAppNotifierAction,
    CreationStatusData,
} from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import CreationInAppNotifier from "../../../../workspaces/creations/utils/CreationInAppNotifier";
import { ELOpenInDesktopDeeplinkAction } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import Logger, { LogLevel } from "../../../../utils/Logger";

export default class ELOpenInDesktopCreationObserver extends IViewController {
    private _owner: IViewController;

    constructor(workflow: IViewController) {
        super();
        this._owner = workflow;
    }

    initialize(dispatch?: Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    private async _handleOpenDeeplinkForCreationStatusChange(creationStatusData: CreationStatusData): Promise<void> {
        try {
            const projectData = await CreationUtils.getProjectData(creationStatusData.projectId);
            const assetID = (await CreationUtils.getCreationMasterAssetPathOrId(projectData)).assetId;
            this._owner.notify({
                type: ELOpenInDesktopDeeplinkAction.deeplinkStatusChanged,
                payload: { assetId: assetID, assetType: projectData.operationSubType }
            });
        } catch (e) {
            Logger.log(LogLevel.ERROR,
                "ELOpenInDesktopCreationObserver: _handleOpenDeeplinkForCreationStatusChange, error in getting asset Id");
            return Promise.reject();
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case CreationInAppNotifierAction.creationStatusChanged: {
                CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
                const creationStatusData = action.payload as CreationStatusData;
                this._handleOpenDeeplinkForCreationStatusChange(creationStatusData);
                handled = true;
                break;
            }
            default: {
                handled = await this._owner.notify(action);
            }
        }
        return handled;
    }
}