/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { Provider as ReactReduxProvider } from "react-redux";

//Adobe Internal
import { warning } from "@react/react-spectrum/Toast";

//Application Specific
import { ViewAction } from "../../../../view/IBaseController";
import MediaManagerView from "./MediaManagerView";
import IBaseWorkspace, { WorkspaceActionType } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../../IWorkflow";
import ELMediaGrid, { ELMediaGridData, SelectedMediaListType } from "../../../../view/components/organism/el-mediagrid/ELMediaGrid";
import useMediaFetch, { MediaSource } from "../../../../utils/hooks/useMediaFetch";
import Constants from "../../../../utils/Constants/Constants";
import { ControllerAction } from "../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import store from "../../../../stores/store";
import SelectedMediaListAction from "../../../../stores/actions/selectedMediaListActions";
import {
    MediaManagerViewAction,
    MediaManagerControllerAction,
    MediaManagerWorkflowAction
} from "../../../../common/interfaces/workflows/MediaManagerTypes";
import { GRID_CONFIG_KEY } from "../../../../stores/reducers/mediaGridConfigReducer";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Utils from "../../../../utils/Utils";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import ELMediaUploader from "../../../../view/components/templates/el-media-uploader/ELMediaUploader";
import { MediaGridWorkflowActions } from "../../../organizer/workflows/mediaGrid/MediaGrid";
import { ELImportButtonAction } from "../../../../view/components/organism/el-import-button/ELImportButton";
import { ELNoMediaBanner } from "../../../../view/components/molecules/el-no-media-banner/ELNoMediaBannerView";
import { ELIcon } from "../../../../view/components/atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { ELMediaSelectionMode } from "../../../../common/interfaces/media/ELThumbTypes";
import MediaGridConfigAction, { MediaGridConfig } from "../../../../stores/actions/mediaGridConfigActions";

class MediaManager extends IWorkflow {
    private _grid?: ELMediaGrid;
    private _selectedAssets: SelectedMediaListType;
    private _initialSelectedAssets: SelectedMediaListType;
    private _mediaGridConfig: MediaGridConfig;
    private _initialMediaGridConfig: MediaGridConfig;

    private _continueState: boolean;
    private readonly _mediaSelectionWarningToastId = "media-selection-warning-toast-id";
    private _mediaUploader: ELMediaUploader | undefined;
    private readonly _importProgressContainer = "import-progress-container";
    private _nextWorkflowName?: WorkflowsName;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.mediaManager);
        this._selectedAssets = [];
        this._initialSelectedAssets = store.getState().selectedMediaListReducer;
        this._initialMediaGridConfig = this._mediaGridConfig = store.getState().mediaConfigReducer[GRID_CONFIG_KEY];
        this._continueState = false;
        this._mediaUploader = undefined;
    }

    set setContinueState(state: boolean) {
        this._continueState = state;
    }

    get getContinueState(): boolean {
        return this._continueState;
    }

    private async _showWarningMessage(selectedAssets: SelectedMediaListType): Promise<boolean> {
        let warn = false;
        warn = warn || this._showWarningMessageForSelectedAsset(selectedAssets);
        warn = warn || this._showWarningMessageForMaxVideo(selectedAssets);
        warn = warn || this._showWarningMessageForMaxImage(selectedAssets);
        warn = warn || this._shouldWarnForMinImage(selectedAssets);
        warn = warn || this._showWarningMessageForTotalMediaSize(selectedAssets);
        warn = warn || await this._showWarningMessageForVideoLength(selectedAssets);

        return warn;
    }

    private _showWarningMessageForTotalMediaSize(selectedAssets: SelectedMediaListType): boolean {
        let totolMediaSize = 0;
        for (let i = 0; i < selectedAssets.length; i++) {
            const size = selectedAssets[i].size;
            totolMediaSize += size ? Utils.getBytesToMB(size) : 0;
        }

        let warn = false;
        if ((this._mediaGridConfig.maxTotalMediaSize !== undefined) && totolMediaSize > this._mediaGridConfig.maxTotalMediaSize) {
            warn = true;
            if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                const message = IntlHandler.getInstance().formatMessage("max-total-media-size-limit",
                    { max_size: this._mediaGridConfig.maxTotalMediaSize });
                warning(message, { id: this._mediaSelectionWarningToastId });
            }
        }

        return warn;
    }

    private async _showWarningMessageForVideoLength(selectedAssets: SelectedMediaListType): Promise<boolean> {
        let warn = false;
        for (let i = 0; i < selectedAssets.length; i++) {
            if (Utils.isVideoMimeType(selectedAssets[i])) {
                try {
                    const embeddedMetadata = await AssetStorageUtils.getAndUpdateMetadata(selectedAssets[i]);
                    const videoDuration = AssetStorageUtils.getVideoDuration(embeddedMetadata);

                    if ((this._mediaGridConfig.maxVideoLength !== undefined) && videoDuration > this._mediaGridConfig.maxVideoLength) {
                        warn = true;
                        if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                            const message = IntlHandler.getInstance().formatMessage("max-video-length-limit",
                                { max_duration: this._mediaGridConfig.maxVideoLength / 60 });
                            warning(message, { id: this._mediaSelectionWarningToastId });
                        }
                        return warn;
                    }
                } catch (error) {
                    Logger.log(LogLevel.WARN, "MediaManager:_showWarningMessageForVideoLength: ", error);
                }
            }
        }

        return warn;
    }

    private _showWarningMessageForMaxImage(selectedAssets: SelectedMediaListType): boolean {
        const selectedVideoCount = AssetStorageUtils.parseVideoCount(selectedAssets);
        const selectedImageCount = selectedAssets.length - selectedVideoCount;
        const maxImageLimit = this._mediaGridConfig.maxImageCount;

        let warn = false;
        if (maxImageLimit && selectedImageCount > maxImageLimit) {
            warn = true;
            if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                const message = IntlHandler.getInstance().formatMessage("max-image-limit", { max_count: maxImageLimit });
                warning(message, { id: this._mediaSelectionWarningToastId });
            }
        }

        return warn;
    }

    private _shouldWarnForMinImage(selectedAssets: SelectedMediaListType): boolean {
        const selectedVideoCount = AssetStorageUtils.parseVideoCount(selectedAssets);
        const selectedImageCount = selectedAssets.length - selectedVideoCount;
        const minImageLimit = this._mediaGridConfig.minImageCount;

        let warn = false;
        if (minImageLimit && selectedImageCount > 0 && selectedImageCount < minImageLimit) {
            warn = true;
        }

        return warn;
    }

    private _showWarningMessageForMaxVideo(selectedAssets: SelectedMediaListType): boolean {
        const selectedVideoCount = AssetStorageUtils.parseVideoCount(selectedAssets);

        let warn = false;
        if ((this._mediaGridConfig.maxVideoCount !== undefined) && selectedVideoCount > this._mediaGridConfig.maxVideoCount) {
            warn = true;
            if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                const message = IntlHandler.getInstance().formatMessage("max-video-limit", { max_count: this._mediaGridConfig.maxVideoCount });
                warning(message, { id: this._mediaSelectionWarningToastId });
            }
        }

        return warn;
    }

    private _showWarningMessageForSelectedAsset(selectedAssets: SelectedMediaListType): boolean {
        let warn = false;
        if ((this._mediaGridConfig.maxMediaCount !== undefined) && selectedAssets.length > this._mediaGridConfig.maxMediaCount) {
            warn = true;
            if (!Utils.isMessageToastVisible(this._mediaSelectionWarningToastId)) {
                let message;
                if (selectedAssets.length === AssetStorageUtils.parsePhotoCount(selectedAssets)) {
                    message = IntlHandler.getInstance().formatMessage("max-image-limit", { max_count: this._mediaGridConfig.maxMediaCount });
                } else {
                    message = IntlHandler.getInstance().formatMessage("max-asset-limit", { max_count: this._mediaGridConfig.maxMediaCount });
                }
                warning(message, { id: this._mediaSelectionWarningToastId });
            }
        }

        return warn;
    }

    private _updateContinueControl(selectedAssets: SelectedMediaListType, warn: boolean): void {
        const initialAssetIds = this._initialSelectedAssets.map((asset) => { return asset.assetId });
        const selectedAssetIds = selectedAssets.map((asset) => { return asset.assetId });
        const isAssetListSame = _.isEqual(_.sortBy(initialAssetIds), _.sortBy(selectedAssetIds));
        const disableControl = (isAssetListSame || selectedAssets.length === 0 || warn);

        this.setContinueState = !disableControl;

        if (this.viewDispatcher) {
            this.viewDispatcher({
                type: MediaManagerViewAction.updateContinueButtonState,
                payload: disableControl
            });
        }
    }

    private async _onSelectedAssetChange(selectedAssets: SelectedMediaListType): Promise<void> {
        const warn = await this._showWarningMessage(selectedAssets);
        this._updateContinueControl(selectedAssets, warn);
    }

    private _getEmptyBanner(): React.ReactElement {
        const emptyElement = React.createElement('div', null);
        return React.createElement(ELNoMediaBanner, {
            icon: React.createElement(ELIcon, {
                iconkey: IconType.emptyELMediaGrid,
                width: "10rem",
                height: "10rem"
            }),
            heading: IntlHandler.getInstance().formatMessage("no-media-banner-media-manager"),
            textBody: emptyElement
        });
    }

    private _ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    private _logMediaManagerRendered(): void {
        const mediaSelectionScreenText = "media-selection-screen";
        const mediaGridType = "media";
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = mediaGridType;
        additionalLogInfo[IngestLogObjectKey.contentName] = mediaSelectionScreenText;
        const ingestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success,
            IngestEventSubTypes.render, IngestUtils.getIngestCreationsWorkflowName(this._nextWorkflowName ?? WorkflowsName.mediaManager), additionalLogInfo);
        this._ingest(ingestPayload);
    }

    private _logToolbarButtonEvents(controlEventType: string): void {
        const mediaTypeText = "media-type";
        const mediaImportType = "user-media"
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventCount] = mediaImportType;
        additionalLogInfo[IngestLogObjectKey.contentName] = mediaTypeText;
        const ingestPayload = IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventTypes.click,
            controlEventType, IngestUtils.getIngestCreationsWorkflowName(this._nextWorkflowName ?? WorkflowsName.sampleMediaManager), additionalLogInfo);
        this._ingest(ingestPayload);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._logMediaManagerRendered();
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const mediaManager: React.ReactElement = React.createElement(MediaManagerView, {
            controller: this,
            title: this._initialSelectedAssets.length === 0 ? "select-media" : "manage-media-title-case"
        });

        const reduxWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, mediaManager);

        ReactDOM.render(
            reduxWrappedProvider,
            container,
            (): void => {
                const dirPath = Constants.ELEMENTS_PHOTOS_PATH as string;
                const mediaSourceType = MediaSource.repoDirDataFetch;

                const repoDirFetchHook = _.partial(useMediaFetch, _, mediaSourceType);

                const setSelectedAssets = (arr: SelectedMediaListType): void => {
                    this._selectedAssets = arr;

                }
                const emptyBanner = this._getEmptyBanner();
                const mediaGridData: ELMediaGridData = {
                    workflow: this,
                    mediaFetchHookFunc: repoDirFetchHook,
                    dirPath: dirPath,
                    createTracks: true,
                    emptyGridBanner: emptyBanner,
                    selectionEnabled: true,
                    setSelectedMediaAssetsInWorkflow: setSelectedAssets,
                    selectionMode: ELMediaSelectionMode.SINGLE_CLICK
                };
                this._grid = new ELMediaGrid(mediaGridData);
                this._grid.createView(this.ensureHTMLElement("media-manager-grid-container"));
            }
        )
    }

    destroyView(): void {
        this._grid?.destroyView();
        this._mediaUploader?.destroyView();
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this._grid = undefined;
        this._selectedAssets = [];
    }

    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow, action?: T): void {
        this._mediaGridConfig = (action?.payload as MediaGridConfig) ?? this._mediaGridConfig;
        this._nextWorkflowName = action?.nextWorkflow;
        store.dispatch(MediaGridConfigAction.updateConfig(this._mediaGridConfig));

        super.startWorkflow<T>(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
        store.dispatch(MediaGridConfigAction.updateConfig(this._initialMediaGridConfig));
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case MediaManagerControllerAction.toolbarCancel:
                {
                    const ingestWorkflowName = (this.prevWorkflow?.getWorkflowName ?? "") + "_" + this.getWorkflowName;
                    this._ingest(IngestUtils.getPseudoLogObject(ingestWorkflowName, IngestEventTypes.click, IngestEventSubTypes.cancel));
                    this._logToolbarButtonEvents(IngestEventSubTypes.cancel);
                    store.dispatch(SelectedMediaListAction.updateSelectedMediaList(this._initialSelectedAssets));
                    const workspaceAction = { type: WorkspaceActionType.endWorkflow };
                    handled = await this._owner.notify(workspaceAction);
                    break;
                }
            case MediaManagerControllerAction.toolbarContinue:
                {
                    const ingestWorkflowName = (this.prevWorkflow?.getWorkflowName ?? "") + "_" + this.getWorkflowName;
                    this._ingest(IngestUtils.getPseudoLogObject(ingestWorkflowName, IngestEventTypes.click, IngestEventSubTypes.continue, this._selectedAssets.length));
                    this._logToolbarButtonEvents(IngestEventSubTypes.continue);
                    const selectedAssets = this._selectedAssets; //store it before ending workflow
                    const prevWorkflow = this.prevWorkflow;

                    const endModalWorkflowAction = { type: WorkspaceActionType.endWorkflow };
                    await this._owner.notify(endModalWorkflowAction);

                    const workflowPayload = {
                        nextWorkflow: this.initAction?.nextWorkflow,
                        nextWorkflowInitMode: this.initAction?.nextWorkflowInitMode,
                        payload: selectedAssets
                    };
                    const workflowAction = { type: MediaManagerWorkflowAction.mediaSelection, ...workflowPayload };
                    await prevWorkflow?.notify(workflowAction);
                    handled = true;
                    break;
                }
            case MediaManagerControllerAction.mediaChanged:
                {
                    this._onSelectedAssetChange(action.payload as SelectedMediaListType);
                    handled = true;
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "MediaManager(notify): Bad action" + action);
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELImportButtonAction.startImport: {
                this._mediaUploader = new ELMediaUploader(this);
                this._mediaUploader.createView(this.ensureHTMLElement(this._importProgressContainer));
                this._mediaUploader.handleImport(Array.from(action.payload as FileList), IngestLogObjectValue.creations);
                this._logToolbarButtonEvents(IngestEventSubTypes.addFromComputer);
                handled = true;
                break;
            }
            case MediaGridWorkflowActions.stopUpload: {
                if (this._mediaUploader) {
                    this._mediaUploader.stopImport();
                }
                handled = true;
                break;
            }
            case MediaGridWorkflowActions.retryUpload: {
                if (this._mediaUploader) {
                    this._mediaUploader.retryImport();
                }
                handled = true;
                break;
            }
            default:
                handled = await super.notifyWorkflow(action);
                break;
        }
        return handled;
    }
}

export default MediaManager;
