/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useEffect } from "react";

import { IngestLogging } from "../../../../services/IngestWrapper";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes, INGEST_APP_WORKSPACE } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import Utils from "../../../../utils/Utils";
import { LoginUtils } from "../../../../utils/LoginUtils";

import "./UtilityNav.scss";

//TODO: Theme
type UtilityNavProps = {
	signedIn: boolean,
	theme: string,
	locale: string
}

declare global {
	interface Window {
		utilityNavConfig: Record<string, unknown>
		UtilityNav: (navConfig: Record<string, unknown>) => void
	}
}

const setConfigInWindow = (signedIn: boolean, containerDivId: string, theme: string, locale: string): void => {

	const containerDiv = document.getElementById(containerDivId); // DOM element within which UtilityNav should render
	if (!containerDiv) {
		return;
	}

	// // getAppLocale"s 3rd argument is the fallback if the language is not accepted by UtilityNav
	// const locale = "en_US";
	const signOutIngestObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.app,
		IngestEventSubTypes.success, IngestEventTypes.signOut, true));

	const navChildren: Array<Record<string, any>> = []
	if (signedIn) {
		navChildren.push(
			{
				name: "nav-profile",
				events: {
					signin: () => Logger.log(LogLevel.INFO, "headerSignInClick", { action: "headerClick" }),
					signout: () => {
						window.onbeforeunload = null;
						IngestLogging.getInstance().logEvent(signOutIngestObject);
						LoginUtils.handleUserSignOut();
					}
				}
			})
	}

	window.utilityNavConfig = {
		theme: theme, // theme can take light or dark value. default value is light.
		env: process.env.REACT_APP_ENV,
		locale,
		target: containerDiv,
		analyticsContext: {
			consumer: {
				name: process.env.REACT_APP_NAME,
				version: process.env.REACT_APP_VERSION,
				platform: "Web",
				client_id: process.env.REACT_APP_IMS_API_KEY,
			},
		},
		children: navChildren,
	}
}

const renderUtilityNav = (): void => {
	window.UtilityNav(window.utilityNavConfig)
}

const UtilityNav = (props: UtilityNavProps): React.ReactElement => {
	const containerDivId = "el-util-nav-container";
	const { signedIn, theme, locale } = props;

	useEffect(() => {
		setConfigInWindow(signedIn, containerDivId, theme, locale);
		const getUtilityNavJsUrl = (): string => {
			if (process.env.REACT_APP_UTILITYNAV_JS_URL) {
				return process.env.REACT_APP_UTILITYNAV_JS_URL
			}
			else {
				Logger.log(LogLevel.WARN, "UtilityNav:useEffect: ", "JS URL not defined");
				return "";
			}
		}
		const utilitynavJSPromise = Utils.loadScript(
			"utilnavjs",
			getUtilityNavJsUrl(),
			true
		)
		const getUtilityNavCssUrl = (): string => {
			if (process.env.REACT_APP_UTILITYNAV_CSS_URL) {
				return process.env.REACT_APP_UTILITYNAV_CSS_URL
			}
			else {
				Logger.log(LogLevel.WARN, "UtilityNav:useEffect: ", "CSS URL not defined");
				return "";
			}
		}
		const utilitynavCSSPromise = Utils.loadCSS(
			"utilnavcss",
			getUtilityNavCssUrl()
		)
		Promise.all([utilitynavJSPromise, utilitynavCSSPromise])
			.then(() => {
				renderUtilityNav()
			}, () => {
				Logger.log(LogLevel.ERROR, "UtilityNav:useEffect: ", "Unable to resolve");
			})
			.catch(error => {
				Logger.log(LogLevel.ERROR, "UtilityNav:useEffect: ", error)
			})
	}, [])

	return (
		<div id={containerDivId} className={signedIn ? "global-utilnav-signin" : "global-utilnav-signout"} />
	);
}

export default UtilityNav;

// refresh(): void {
//   return;
// }

