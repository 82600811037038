/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import { Toast } from "@react/react-spectrum/Toast";

//Application Specific
import { RecommendationsStatusData } from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { ELRecommendationsOutputJsonConfigData } from "../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { DEFAULT_NUMBER_OF_RETRIES } from "../../../common/interfaces/modules/elBatchHandler/ELCreationBatchHandlerTypes";
import { ELRecommendationBatchProcessor } from "../../../modules/elBatchProcessing/elBatchProcessor/ELRecommendationBatchProcessor";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import RecommendationsWorkflow from "./RecommendationsWorkflow";
import { ToastUtils } from "../../../utils/ToastUtils";
import Constants from "../../../utils/Constants/Constants";

abstract class BatchHandlingWorkflow<DocPayloadType> extends RecommendationsWorkflow<DocPayloadType> {

    private _referenceToRetryToast?: Toast;

    protected primaryRequestDone = false;
    protected recommendationBatchProcessor?: ELRecommendationBatchProcessor;
    protected primaryOverlayId?: string;
    protected numberOfRetry: number = DEFAULT_NUMBER_OF_RETRIES;

    protected abstract setPrimaryOverlayId(projectData: ELRecommendationsOutputJsonConfigData): Promise<void>;
    protected abstract batchRequestStatusChanged(recommendationsStatusData: RecommendationsStatusData): Promise<void>;

    protected addToastForRetryBatch(): void {
        const retryToast = new Toast({
            children: IntlHandler.getInstance().formatMessage("few-creations-not-generated"),
            variant: "warning",
            closable: true,
            actionLabel: IntlHandler.getInstance().formatMessage("regenerate-all"),
            onAction: async () => {
                this.recommendationBatchProcessor?.retryAllFailedRequests();
                this.removeRetryToast();
            },
            onClose: () => {
                this.removeRetryToast();
            }
        });
        ToastUtils.addToast(retryToast, Constants.TOAST_NO_TIMEOUT as number);
        this._referenceToRetryToast = retryToast;
    }

    protected removeRetryToast(): void {
        if (this._referenceToRetryToast) {
            ToastUtils.removeToast(this._referenceToRetryToast);
            this._referenceToRetryToast = undefined;
        }
    }
}

export default BatchHandlingWorkflow;