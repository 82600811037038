/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";

// Adobe internal
import { Flex, Link } from "@adobe/react-spectrum";
import { Text } from "@react-spectrum/text";

// Application specific
import IViewController from "../../../../view/IViewController";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { URLConstants } from "../../../../utils/Constants/URLConstants";

import { WorkflowActionType } from "../../../IWorkflow";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { WorkspacesName } from "../../../IBaseWorkspace";
import { IngestEventSubTypes, IngestEventTypes, IngestOSValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import Utils from "../../../../utils/Utils";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { ELReactSpectrumV3Provider } from "../../../../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./TryThisInDesktop.scss";

interface TryThisInDesktopViewProps {
    controller: IViewController
}

export const TryThisInDesktopView = (props: TryThisInDesktopViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const { width, height } = useViewport();

    const handleMacDownload = (): void => {
        Utils.downloadURI(URLConstants.GoURL.PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK_MAC);
        props.controller.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.addWorkspaceDetail(WorkspacesName.downloadDesktop, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.download,
                IngestEventSubTypes.click, IngestEventTypes.downloadButton, IngestOSValue.macos))
        });
    }

    const handleWinDownload = (): void => {
        Utils.downloadURI(URLConstants.GoURL.PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK_WIN);
        props.controller.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.addWorkspaceDetail(WorkspacesName.downloadDesktop, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.download,
                IngestEventSubTypes.click, IngestEventTypes.downloadButton, IngestOSValue.windows))
        });
    }

    const handleDownload = (): void => {
        if (PlatformUtils.isRunningOnMacOS()) {
            handleMacDownload();
        } else {
            handleWinDownload();
        }
    }

    const handleLearnMoreClick = (): void => {
        Utils.openInNewTab(URLConstants.NotGoURL.PHOTOSHOP_ELEMENTS_APP_LEARN_MORE_LINK);
    }

    const getDesktopView = (): React.ReactElement => {
        return (
            <ELReactSpectrumV3Provider height={"100%"}>
                <Flex UNSAFE_className="try-this-in-desktop-page">
                    <Flex UNSAFE_className="try-this-in-desktop-home">
                        <Flex justifyContent={"center"}>
                            <img crossOrigin="anonymous" src="favicon.png" alt="Elements tag" className="try-this-in-desktop-image" />
                        </Flex>
                        <Text UNSAFE_className="try-this-in-desktop-text-1">{intlHandler.formatMessage("try-this-in-desktop-text-a")}</Text>
                        <Flex direction={"row"} gap={"0.625rem"} justifyContent={"center"}>
                            <Text UNSAFE_className="try-this-in-desktop-text-2">{intlHandler.formatMessage("try-this-in-desktop-text-b1")}</Text>
                            <Link onPress={handleDownload} UNSAFE_className="try-this-in-desktop-text-3">{intlHandler.formatMessage("try-this-in-desktop-text-b2")}</Link>
                        </Flex>
                        <Link onPress={handleLearnMoreClick} UNSAFE_className="try-this-in-desktop-text-4">{intlHandler.formatMessage("try-this-in-desktop-text-c")}</Link>
                    </Flex>
                </Flex >
            </ELReactSpectrumV3Provider>
        );
    }

    const getNonDesktopView = (): React.ReactElement => {
        return (
            <ELReactSpectrumV3Provider height={"100%"}>
                <Flex UNSAFE_className="try-this-in-desktop-page-mobile">
                    <Flex UNSAFE_className="try-this-in-desktop-home-mobile">
                        <Flex justifyContent={"center"}>
                            <img crossOrigin="anonymous" src="favicon.png" alt="Elements tag" className="try-this-in-desktop-image" />
                        </Flex>
                        <Text UNSAFE_className="try-this-in-desktop-mobile-text-1">{intlHandler.formatMessage("try-this-in-desktop-mobile-text-1")}</Text>
                        <Text UNSAFE_className="try-this-in-desktop-mobile-text-2">{intlHandler.formatMessage("try-this-in-desktop-mobile-text-2")}</Text>
                    </Flex>
                </Flex>
            </ELReactSpectrumV3Provider>
        );
    }

    return PlatformUtils.isAHandheldDevice((width as number), (height as number)) ? getNonDesktopView() : getDesktopView();
}
