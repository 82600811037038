/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import { useSelector } from "react-redux";

//Application Specific
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../atoms/el-button/ELButtonView";
import { ELNoMediaBanner } from "../../molecules/el-no-media-banner/ELNoMediaBannerView";
import Utils from "../../../../utils/Utils";
import { Routes } from "../../../../app/AppRoute";
import { RootState } from "../../../../stores/store";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { LinkParams } from "../../../../utils/Constants/Constants";
import { RepoDirListData } from "@elements/elementswebcommon";

import "./ELEmptyBannerSharedGridView.scss";

function collectionIsUnknown(sharedCollection?: RepoDirListData): boolean {
    if (sharedCollection?.hasError === true) {
        Logger.log(LogLevel.WARN, "[collectionIsUnknown]", sharedCollection);
        return true;
    }
    return false;
}

const unknownCollectionBanner = (exploreElementsButton: React.ReactElement): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const elIcon = <ELIcon iconkey={IconType.incorrectCollectionSharedView} width="13rem" height="12rem" />;
    const textBody = (<>
        <span className="incorrect-collection-shared-view-paras">
            {intlHandler.formatMessage("incorrect-collection-shared-view-para1")}
        </span>
        <span className="incorrect-collection-shared-view-paras">
            {intlHandler.formatMessage("incorrect-collection-shared-view-para2")}
        </span>
    </>);

    return (
        <ELNoMediaBanner icon={elIcon} heading={intlHandler.formatMessage("incorrect-collection-shared-view-heading")}
            textBody={textBody} className="incorrect-collection-shared-view">
            {exploreElementsButton}
        </ELNoMediaBanner>
    )
}

export const ELEmptyBannerSharedGridView = (): React.ReactElement => {
    const collectionId = Utils.getURLSearchParam(LinkParams.ELEMENTS_COLLECTION_ID_PARAM) ?? ""
    const sharedCollection = useSelector((state: RootState) => state.mediaOrganizerReducer[collectionId]);
    const intlHandler = IntlHandler.getInstance();
    const exploreElementsButton = (
        <ELButton size="L" onClick={() => Utils.openInNewTab(Routes.ROOT)}>
            <span className="no-media-banner-shared-view__button__text">{intlHandler.formatMessage("explore-elements")}</span>
        </ELButton>);
    if (collectionIsUnknown(sharedCollection)) {
        return unknownCollectionBanner(exploreElementsButton);
    }

    const elIcon = <ELIcon iconkey={IconType.noMediaSharedView} width="12.25rem" height="11.56rem" />;
    const textBody = (<>
        <span className="no-media-banner-shared-view__paras">{intlHandler.formatMessage("no-media-banner-shared-view-text")}</span>
    </>)

    return (
        <ELNoMediaBanner icon={elIcon} className="no-media-banner-shared-view"
            heading={intlHandler.formatMessage("no-media-banner-shared-view-heading")}
            textBody={textBody}>
            {exploreElementsButton}
        </ELNoMediaBanner>
    )
}