/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { LinkInfo, ShareMode, ShareType } from "../../common/interfaces/share/ShareTypes";
import IAction from "../actions/IAction";
import { ShareAction, SharePayloadUnion } from "../actions/ShareAction";


interface ShareConfig {
    shareMode: ShareMode,
    shareType: ShareType,
    linkInfo: LinkInfo
    assetsToShare: string[],
    viewType: string
}

const defaultState: ShareConfig = {
    shareMode: ShareMode.facebook,
    shareType: ShareType.media,
    linkInfo: {
        link: "",
        sharedAssetsCount: 0,
    },
    assetsToShare: [],
    viewType: ""
}

const updateShareType = (state: ShareConfig = defaultState, action: IAction<SharePayloadUnion>): ShareConfig => {
    const shareType = action.payload?.data as ShareType;

    return {
        ...state,
        shareType: shareType
    }
}

const updateShareMode = (state: ShareConfig = defaultState, action: IAction<SharePayloadUnion>): ShareConfig => {
    const shareMode = action.payload?.data as ShareMode;

    return {
        ...state,
        shareMode: shareMode
    }
}

const updateLinkInfo = (state: ShareConfig = defaultState, action: IAction<SharePayloadUnion>): ShareConfig => {
    const linkInfo = action.payload?.data as LinkInfo;

    return {
        ...state,
        linkInfo: linkInfo
    }
}

const updateAssetsToShare = (state: ShareConfig = defaultState, action: IAction<SharePayloadUnion>): ShareConfig => {
    const assetsToShare = action.payload?.data as string[];

    return {
        ...state,
        assetsToShare: assetsToShare
    }
}

const updateViewType = (state: ShareConfig = defaultState, action: IAction<SharePayloadUnion>): ShareConfig => {
    const viewType = action.payload?.data as string;

    return {
        ...state,
        viewType: viewType
    }
}

const reset = (): ShareConfig => {
    return defaultState;
}

const shareReducer = (state: ShareConfig = defaultState, action: IAction<SharePayloadUnion>): ShareConfig => {
    switch (action.type) {
        case ShareAction.UPDATE_SHARE_MODE:
            return updateShareMode(state, action);
        case ShareAction.UPDATE_SHARE_TYPE:
            return updateShareType(state, action);
        case ShareAction.UPDATE_LINK_INFO:
            return updateLinkInfo(state, action);
        case ShareAction.UPDATE_ASSETS_TO_SHARE:
            return updateAssetsToShare(state, action);
        case ShareAction.UPDATE_VIEW_TYPE:
            return updateViewType(state, action);
        case ShareAction.RESET:
            return reset();
        default:
            return state;
    }
}

export default shareReducer;