/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Application Specific
import { LinkParams } from "../../../../utils/Constants/Constants";
import { ElementsShareServiceWrapper } from "../../../../services/ElementsShareServiceWrapper";
import Utils from "../../../../utils/Utils";
import { Routes } from "../../../../app/AppRoute";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestLogging } from "../../../../services/IngestWrapper";
import Logger, { LogLevel } from "../../../../utils/Logger";

export const handleDiscard = async (): Promise<void> => {
    const discardIngestClickObject = IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.sharedLink, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.manageLink,
        IngestEventSubTypes.click, IngestEventTypes.discardLink, null));
    IngestLogging.getInstance().logEvent(discardIngestClickObject);
    const collectionId = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_COLLECTION_ID_PARAM);
    if (collectionId) {
        try {
            await (ElementsShareServiceWrapper.getInstance().unshareLink(collectionId));
            const discardIngestSuccessObject = IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.sharedLink, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.success, IngestEventTypes.discardLink, null));
            IngestLogging.getInstance().logEvent(discardIngestSuccessObject);
            Utils.openInSameTab(Routes.MEDIA);
        } catch (error: any) {
            const msg = IntlHandler.getInstance().formatMessage("discard-error-toast");
            ToastUtils.error(msg);
            Logger.log(LogLevel.WARN, "ELManageShareLinkDialog:handleDiscard: ", "Could not discard link ", error);
            const discardIngestErrorObject = IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.sharedLink, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                IngestEventSubTypes.error, IngestEventTypes.discardLink, error));
            IngestLogging.getInstance().logEvent(discardIngestErrorObject);
        }
    }
}