/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { fabric } from "fabric";
import axios from "axios";

//Adobe Internal
import {
    ContentType,
    TemplateId,
    ContentEntity,
} from "@elements/elementswebcommon";
import { FontIdentifier } from "@adobe-fonts/fontpicker";
import { tqtypes } from '@coretech/typequestweb';

//Application Specific
import store from "../../../../stores/store";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ViewAction } from "../../../../view/IBaseController";
import IBaseWorkspace, { WorkspaceActionType, WorkspacePayload } from "../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowsName } from "../../../IWorkflow";
import CreationWorkflow from "../CreationWorkflow";
import ELPanelManager from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import PhotoTextUtils from "./utils/PhotoTextUtils";
import ELPhotoTextPanelProvider from "../../utils/panelProvider/ELPhotoTextPanelProvider";
import { ELTabPanelKey, ELTabPanelType } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import ELMediaRecommendationHeader from "../../../../view/components/templates/el-creations-header/ELMediaRecommendationHeader";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { CreationsDownloadFileType, CreationsJobProjectSubType, CreationsMode, CreationsStatus, CreationsStatusPayload, ELCreationWorkflowPayload, UNTITLED_INTL_KEY } from "../../../../common/interfaces/creations/CreationTypes";
import CreationInAppNotifier from "../../utils/CreationInAppNotifier";
import { CreationAppSubscriberType, CreationInAppNotifierAction, RecommendationsAppSubscriberType } from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import RecommendationsInAppNotifier from "../../utils/RecommendationsInAppNotifier";
import PhotoTextView from "./PhotoTextView";
import { CreationStatusPayload } from "../../../../stores/actions/CreationsAction";
import { RecommendationWorkflowAction } from "../../../../stores/actions/RecommendationWorkflowAction";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { ToastUtils } from "../../../../utils/ToastUtils";
import { ELCreateAndEditProjectParams, ELPreviewCreationThumbData, ELRecommendationsOutputJsonConfigData, ELRecommendationWorkflowControllerActions, ELRecommendationWorkflowViewActions, ELThumbUpdateProps } from "../../../../common/interfaces/creations/ELRecommendationsWorkflowTypes";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import DocActions from "../../../../stores/actions/DocActions";
import { DocumentActions, DocumentDirty, DocumentSaveStatus } from "../../../../common/interfaces/document/DocumentTypes";
import { CreationsJobCreator } from "../../utils/CreationsJobCreator";
import { ELContentCacheDownloader } from "../../utils/ELContentCacheDownloader";
import { ITextPreset } from "../../../../view/components/templates/el-text-preset-creator/ITextPresetCreator";
import Utils from "../../../../utils/Utils";
import FullResMediaAction from "../../../../stores/actions/FullResMediaAction";
import IDoc, { DocumentType } from "../../../../editors/document/IDoc";
import DocumentFactory, { DocumentFactoryPayload } from "../../../../editors/document/DocumentFactory";
import { ELStageDocActions, ELStageDocPayload, ELStageDocTextPropertyPayload } from "../../../../common/interfaces/document/ELStageDocTypes";
import { ELLayerKind, ELStageLayerDataOptions, CSLayerTextInfo } from "../../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import ImageUtils from "../../../../utils/ImageUtils";
import { CanvasZoomLevelAction, DEFAULT_ZOOM_PERCENTAGE, ELFabricConfig, ELImageData, ELStageObject, ELStageTextObject, ELStageTextProperty, ELTextAlign, ELTextSpread } from "../../../../common/interfaces/stage/StageTypes";
import { ControllerAction } from "../../../../view/IViewController";
import { ELPhotoTextWorkflowControllerActions } from "../../../../common/interfaces/creations/ELPhotoTextWorkflowTypes";
import { ELLinkType } from "../../../../common/interfaces/link/ELLinkTypes";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import Constants from "../../../../utils/Constants/Constants";
import { StorageService } from "../../../../services/StorageServiceWrapper";
import ELDynamicImageTextPresetCreator from "../../../../view/components/templates/el-text-preset-creator/ELDynamicImageTextPresetCreator";
import { CreationMediaActionType } from "../../../../view/components/templates/el-creation-media-panel/ELCreationMediaView";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { ReplaceMediaManagerMode, ReplaceMediaManagerWorkflowAction } from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import { ReplaceAssetInfo } from "../../../../common/interfaces/creations/ELCollageTypes";
import { FontsStore } from "../../../../services/font/ELFontStore";
import TextEditAction from "../../../../stores/actions/TextEditAction";
import ELStageDocUtils from "../../../../utils/stageDoc/ELStageDocUtils";
import { IClientCreationRequestParams } from "../../../../common/interfaces/creations/ELCreationsJobTypes";
import { HistoryUtils } from "../../../../utils/HistoryUtils";
import CreationUtils from "../../utils/CreationUtils";
import ELStageDocSaveManager from "../../../../editors/document/documentSaveManager/ELStageDocSaveManager";
import { PhotoTextJobCreator } from "./utils/PhotoTextJobCreator";
import PhotoTextAction from "../../../../stores/actions/PhotoTextAction";
import ELAdobeAssetDataResolver, { DocumentDataType } from "../../../../editors/document/dataResolver/ELAdobeAssetDataResolver";

import "./PhotoTextView.scss";

interface PhotoTextCreateAndEditRequestParams extends ELCreateAndEditProjectParams {
    title?: string,
    layerDataOptionsList?: ELStageLayerDataOptions[]
}

class PhotoText extends CreationWorkflow<ELStageDocPayload> {
    private _leftTabPanel: ELPanelManager;
    private _rightTabPanel: ELPanelManager;
    private _creationsHeader: ELMediaRecommendationHeader;
    private _photoTextPayload: ELCreationWorkflowPayload;
    private _progressTimer?: NodeJS.Timer;
    private _inputMedia?: ELAdobeAsset;
    private _layerDataOptionsList?: ELStageLayerDataOptions[];
    private _layerNameToIdMap: Map<string, string> = new Map();

    private readonly _leftTabPanelContainer = "photo-text-left-panel-container";
    private readonly _rightTabPanelContainer = "photo-text-right-panel-container";
    private readonly _creationsHeaderContainer = "photo-text-creations-header-container";
    private readonly _layers = {
        backgroundLayer: "backgroundLayer",
        fillLayer: "fillLayer",
        textLayer1: "textLayer1",
        imageLayer: "imageLayer",
        textLayer2: "textLayer2"
    };
    private readonly _presetWidth = 420;
    private readonly _feedbackContainer = "feedback-popover-container";

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.photoText);
        this.mediaGridConfig = PhotoTextUtils.getMediaGridConfig();

        const panelProvider = new ELPhotoTextPanelProvider(this);
        this._leftTabPanel = panelProvider.getTabPanel(ELTabPanelType.leftTabPanel);
        this._rightTabPanel = panelProvider.getTabPanel(ELTabPanelType.rightTabPanel);

        const backButtonDialogHeading = IntlHandler.getInstance().formatMessage("photo-text-creation");
        const downloadOptions = [CreationsDownloadFileType.jpeg, CreationsDownloadFileType.png];
        this._creationsHeader = new ELMediaRecommendationHeader(this, this.shareOptions, backButtonDialogHeading,
            CreationsJobProjectSubType.photoText, downloadOptions, false, false);

        this._photoTextPayload = {};
    }

    protected getJobCreator(): CreationsJobCreator {
        return new PhotoTextJobCreator();
    }

    protected logIngestData(creationStatus: string, errorInfo?: string): void {
        try {
            const allMedia = store.getState().selectedMediaListReducer;

            const customEntries: Record<string, string> = IngestUtils.getMediaLoggingInfo(allMedia);
            customEntries[IngestLogObjectCustomKey.totalCount] = allMedia.length.toString();

            const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;

            if (selectedOverlayId) {
                customEntries[IngestLogObjectCustomKey.overlay] = selectedOverlayId;
            }

            const eventContextId = Utils.getRandomUUID();
            const additionalLogInfo: Record<string, string> = {};
            additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;

            if (errorInfo)
                additionalLogInfo[IngestLogObjectKey.errorDescription] = errorInfo;

            if (!this.ingestParams.subType)
                this.ingestParams.subType = this.mode;

            const timeElapsed = Utils.getDateDifference(this.startDate, new Date(), "second");
            customEntries[IngestLogObjectCustomKey.timeTaken] = timeElapsed.toString();

            if (this.ingestParams.subType === CreationsMode.save && this.ingestParams.eventViewType) {
                additionalLogInfo[IngestLogObjectCustomKey.viewType] = this.ingestParams.eventViewType;
            }

            for (const key in customEntries) {
                const additionalLogInfoTemp = { ...additionalLogInfo };
                additionalLogInfoTemp[IngestLogObjectKey.eventContextGuid] = eventContextId;
                additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
                additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, this.ingestParams.subType, CreationsJobProjectSubType.photoText, additionalLogInfoTemp));
            }

            this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                creationStatus, this.ingestParams.subType, CreationsJobProjectSubType.photoText, additionalLogInfo));
        }
        catch (e) {
            Logger.log(LogLevel.WARN, `PhotoText:logIngestData:${this.mode}: `, `Dunamis Logging Error:${e as string}`);
        }
    }

    protected ingestCreationFeedback(eventSubType: string): Promise<void> {
        return Promise.resolve();
    }

    protected updateStoreStateOnEnter(): void {
        super.updateStoreStateOnEnter();
        store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayId(undefined));
        store.dispatch(DocActions.updateDocumentDirty(DocumentDirty.DIRTY));
        const defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
        store.dispatch(RecommendationWorkflowAction.updateProjectTitle(defaultTitle));
    }

    protected async updateStoreState(): Promise<void> {
        if (!this.doc) {
            Logger.log(LogLevel.WARN, "PhotoText:updateStoreState: ", "Document not found");
            return;
        }
        const layerDataOptionsList = await ELStageDocUtils.getLayerDataOptionsList(this.doc);
        if (layerDataOptionsList && layerDataOptionsList[2].editInfo?.textInfo) {
            const textInfo = layerDataOptionsList[2].editInfo.textInfo;
            const scale = layerDataOptionsList[2].editInfo.transform?.scale.horizontal ?? 1;
            store.dispatch(TextEditAction.updatePostscriptName(textInfo.fontFamily));
            store.dispatch(TextEditAction.updateFontSize(Math.floor(textInfo.fontSize * scale) ?? 128));
            store.dispatch(TextEditAction.updateStrokeWidth(textInfo.strokeWidth ?? 0));
            store.dispatch(TextEditAction.updateStrokeColor(textInfo.stroke ?? "black"));
            store.dispatch(TextEditAction.updateShadowBlur(textInfo.shadowBlur ?? 0));
            store.dispatch(TextEditAction.updateUnderline(textInfo.underline ?? false));

            const fillLayer = layerDataOptionsList[1];
            store.dispatch(PhotoTextAction.updateTransparentBackground(fillLayer.visible === false ?? false));
            store.dispatch(PhotoTextAction.updateBackground(fillLayer.editInfo?.fill ?? "black"));
            store.dispatch(PhotoTextAction.updateOpacity((fillLayer.editInfo?.opacity ?? 1) * 100));
        }
    }

    async initialize(dispatch?: React.Dispatch<ViewAction>): Promise<void> {
        super.initialize(dispatch);
        this._leftTabPanel.createView(this.ensureHTMLElement(this._leftTabPanelContainer));
        this._rightTabPanel.createView(this.ensureHTMLElement(this._rightTabPanelContainer));
        await this._creationsHeader.createView(this.ensureHTMLElement(this._creationsHeaderContainer));
        this.createFeedbackView(this.ensureHTMLElement(this._feedbackContainer));

        CreationInAppNotifier.subscribe(this, CreationAppSubscriberType.statusChange);
        RecommendationsInAppNotifier.subscribe(this, RecommendationsAppSubscriberType.statusChange);

        this.enterProject(this._photoTextPayload);
    }

    protected async enterProject(photoTextPayload: ELCreationWorkflowPayload): Promise<void> {
        try {
            store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.requested));
            this._updateProgressValueWithTimer();
            const isCreationActive = this.shouldAllowCreationToOpen(FeatureName.ePhotoText);
            if (!isCreationActive) {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("creation-disabled-via-feature-flag-message"));
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                return;
            }
            switch (photoTextPayload.initMode) {
                case CreationsMode.create: {
                    this._resetPhotoTextData();
                    this.updateStoreStateOnEnter();
                    this.projectId = undefined;
                    this._inputMedia = (photoTextPayload.payload as ELAdobeAsset[])[0];
                    if (!this._inputMedia) {
                        throw new Error("Media not found!");
                    }
                    this._createPhotoText();
                    break;
                }
                case CreationsMode.open: {
                    const photoTextStatusPayload = photoTextPayload.payload as CreationsStatusPayload;
                    this.openProject(photoTextStatusPayload.projectId);
                    break;
                }
                default: {
                    Logger.log(LogLevel.WARN, "PhotoText (enterProject) - Invalid mode");
                    break;
                }
            }
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "Unable to enter PhotoText - ", error);
            this.startPreviousWorkflow();
        }
    }

    protected async openProject(projectId: string): Promise<void> {
        await this._getAndSetProjectData(projectId);
        await this._openSavedPhotoText();
    }

    protected updateViewStatusAndProgressText(status: CreationsStatus, progressText: string): void {
        this._updateProgressText(progressText);
        this._updateViewStatus(status);
    }

    protected async revert(): Promise<void> {
        this.mode = CreationsMode.update;
        if (!this.projectId || !this._layerDataOptionsList) {
            const selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;

            if (selectedOverlayId) {
                await this._applyTextPreset(selectedOverlayId);
            } else {
                Logger.log(LogLevel.WARN, "PhotoText: (revert)", "selectedOverlayId not found", selectedOverlayId);
                this.logIngestData(IngestEventSubTypes.error, "selectedOverlayId not found");
                return;
            }
        } else {
            this.updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("revert-creation", { creation: IntlHandler.getInstance().formatMessage("photo-text-creation") }));
            await this._createAndRenderDocFromLayerData();
            this._updateViewStatus(CreationsStatus.success);
        }

        this.logIngestData(IngestEventSubTypes.success);
        window.requestAnimationFrame(() => { this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY); });
    }

    protected async createRecommendationProject(createProjectParams: PhotoTextCreateAndEditRequestParams): Promise<void> {
        const { asset, title, layerDataOptionsList } = createProjectParams;
        const intlHandler = IntlHandler.getInstance();
        const trueTitle = this.getTrueTitleForRequest(title ?? intlHandler.formatMessage(UNTITLED_INTL_KEY));

        const projectRequestParams: IClientCreationRequestParams = {
            assets: [asset],
            title: trueTitle,
            layerDataOptionsList: layerDataOptionsList
        };

        try {
            this.projectParams = projectRequestParams;
            const requestJson = this.createRequestJson(projectRequestParams);
            this.projectId = await this.createCreation(requestJson);
            this.projectData = await this.getProjectData(this.projectId);
            await this._saveAssetToProjectPath(requestJson);
            this._notifySubViews();
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PhotoText project creation failed!", error);
            const message = IntlHandler.getInstance().formatMessage("failed-recommendation-project");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PhotoText project creation failed");
            return Promise.reject();
        }

        this._updatePhotoTextRoute();
    }

    protected async editRecommendationsProject(editRequestParams: PhotoTextCreateAndEditRequestParams): Promise<void> {
        const { asset, title, layerDataOptionsList } = editRequestParams;

        const intlHandler = IntlHandler.getInstance();
        const trueTitle = this.getTrueTitleForRequest(title ?? intlHandler.formatMessage(UNTITLED_INTL_KEY));

        if (!this.projectId || !this.projectData) {
            Logger.log(LogLevel.ERROR, "PhotoText:editRecommendationsProject: ", "project id or project data not valid");
            return Promise.reject();
        }

        const projectRequestParams: IClientCreationRequestParams = {
            assets: [asset],
            title: trueTitle,
            layerDataOptionsList: layerDataOptionsList
        };

        try {
            this.projectParams = projectRequestParams;
            const requestJson = this.createRequestJson(projectRequestParams);

            this.projectId = await this.editCreation(this.projectId, requestJson);
            this.projectData = await this.getProjectData(this.projectId);
            await this._saveAssetToProjectPath(requestJson);
        } catch (error) {
            Logger.log(LogLevel.WARN, "PhotoText:editRecommendationsProject: , project edit failed!" + error);
            return Promise.reject();
        }
    }

    private _resetPhotoTextData(): void {
        this._inputMedia = undefined;
        this._layerDataOptionsList = undefined;
        this._layerNameToIdMap.clear();
    }

    private async _openSavedPhotoText(): Promise<void> {
        this.ingestParams.subType = this.mode;
        if (!this.projectData || !this.projectData.outputs || !this.projectData.outputs.preview || !this.projectData.outputs.preview.assetURN) {
            const message = IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs");
            ToastUtils.warning(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PhotoText get project outputs failed");
            return Promise.reject();
        }
        if (!this.projectData.assets) {
            const message = IntlHandler.getInstance().formatMessage("creation-asset-not-found");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            return Promise.reject();
        }
        const assetId = this.projectData.outputs.preview.assetURN;
        const projectOutputAsset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, "id");
        const json = await StorageService.getInstance().getAppMetadata(projectOutputAsset);
        const outputConfigJson = json as unknown as ELRecommendationsOutputJsonConfigData;
        await this._populatePanelData(outputConfigJson);

        this._inputMedia = store.getState().selectedMediaListReducer[0];
        this._layerDataOptionsList = await this.getLayerDataOptionsList(json);
        this._fetchAndLoadFontFromLayerData();

        try {
            await this._createPhotoText();
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PhotoText:_processUIRenderForProject, not able to resolve asset, might be deleted", error);
            const message = IntlHandler.getInstance().formatMessage("failed-to-resolve-asset-for-doc");
            ToastUtils.error(message);
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "Not able to resolve asset");
            return Promise.reject();
        }
        this._notifySubViews();
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
    }

    private async _populatePanelData(projectData: ELRecommendationsOutputJsonConfigData): Promise<void> {
        await this.populateSelectedMediaList(projectData.assets);
    }

    private async _getAndSetProjectData(projectId: string): Promise<void> {
        this.projectId = projectId;
        try {
            this.projectData = await this.getProjectData(this.projectId);
            this._updatePhotoTextRoute();
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PhotoText:_getAndSetProjectData, failed to get projectData for projectId, ", projectId);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("failed-to-get-project-outputs"));
            this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
            this.logIngestData(IngestEventSubTypes.error, "PhotoText get project outputs failed");
            return Promise.reject();
        }
    }

    private async _createPhotoText(): Promise<void> {
        this.startDate = new Date();
        await this._generateRecommendations();
    }

    private async _generateRecommendations(): Promise<void> {
        if (!this._inputMedia || !this._inputMedia.assetId) {
            return Promise.reject("inputMedia not set!");
        }

        try {
            const overlaysData = await ELContentCacheDownloader.getContentForContentType(ContentType.photoText);
            const textPresets = await this._fetchTextPresets(overlaysData);

            const asset = await StorageService.getInstance().resolveAsset({ assetId: this._inputMedia.assetId }, 'id');
            const assetResolver = new ELAdobeAssetDataResolver();
            const objectURL = await assetResolver.getDataAndUpdateStore(asset, DocumentDataType.rendition, this._presetWidth);

            const textPresetCreator = new ELDynamicImageTextPresetCreator();
            const textPresetUrls = await textPresetCreator.getPresetUrls(textPresets, objectURL);

            const mapOfOverlayIdAssetPath = this._getMapOfOverlayIdAssetPath(overlaysData, textPresetUrls);
            this._updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdAssetPath);

            if (this._layerDataOptionsList) {
                await this._createAndRenderDocFromLayerData();
            } else {
                const selectedOverlayId = await this._getFirstOverlayIdToRender();
                const selectedOverlayIndex = overlaysData.findIndex(data => data.props.id === selectedOverlayId);
                this._reorderPanelThumbnails(selectedOverlayId);
                await this._createAndRenderDocFromTextPreset(textPresets[selectedOverlayIndex]);
            }

            store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.success));
            this._updateViewStatus(CreationsStatus.success);
            this.logIngestData(IngestEventSubTypes.success);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Photo text - Unable to generate recommendations", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("photo-text-creation") }));
            this.startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Couldn't generate recommendations");
        }

        this._updateProgressPercentageToView(0);
        clearInterval(this._progressTimer);
        return Promise.resolve();
    }

    private async _getFirstOverlayIdToRender(): Promise<string> {
        let selectedOverlayId = store.getState().recommendationWorkflowReducer.selectedOverlayId;
        const projectId = store.getState().recommendationWorkflowReducer.id;

        if (!selectedOverlayId) {
            const overlayContent = await ELContentCacheDownloader.getContentForContentType(ContentType.photoText);
            const randomOverlayId = Math.floor(Math.random() * overlayContent.length);
            selectedOverlayId = overlayContent[randomOverlayId].props.id;
            if (!projectId) this.ingestParams.subType = CreationsMode.create;
        }

        this._updateSelectedOverlayId(selectedOverlayId);

        return selectedOverlayId;
    }

    private _updateSelectedOverlayId(selectedOverlayId?: string): void {
        store.dispatch(RecommendationWorkflowAction.updateSelectedOverlayId(selectedOverlayId));
        this._leftTabPanel.notify({ type: ELRecommendationWorkflowControllerActions.setSelectedOverlayId, payload: selectedOverlayId });
    }

    private async _fetchTextPresets(overlaysData: ContentEntity[]): Promise<ITextPreset[]> {
        const textPresets = [];
        for (const overlayData of overlaysData) {
            const overlayJsonLink = await overlayData.getContentURL("");
            const overlayJson = (await axios.get(overlayJsonLink)).data as ITextPreset;
            textPresets.push(overlayJson);
        }
        return Promise.resolve(textPresets);
    }

    private _getMapOfOverlayIdAssetPath(contents: ContentEntity[], textPresetUrls: string[]): Map<TemplateId, ELAdobeAsset> {
        const mapOfTemplateIdToAssets: Map<TemplateId, ELAdobeAsset> = new Map();

        for (const [index, url] of textPresetUrls.entries()) {
            const assetId = Utils.getRandomUUID();
            const tempAsset: ELAdobeAsset = { assetId: assetId, url: url, isLocal: true };
            mapOfTemplateIdToAssets.set(contents[index].props.id, tempAsset);
            store.dispatch(FullResMediaAction.updateData({ assetId: assetId, objectURL: url }));
        }

        return mapOfTemplateIdToAssets;
    }

    private async _updateOverlayPanelViewForRequestedOverlays(mapOfOverlayIdAssetPath: Map<TemplateId, ELAdobeAsset>): Promise<void> {
        const overlayData = await ELContentCacheDownloader.getContentForContentType(ContentType.photoText);
        overlayData.forEach(data => {
            if (mapOfOverlayIdAssetPath.has(data.props.id)) {
                const updatedThumbData: ELThumbUpdateProps = { id: data.props.id, asset: mapOfOverlayIdAssetPath.get(data.props.id) };
                this._leftTabPanel.notify({ type: ELRecommendationWorkflowControllerActions.updateSingleOverlayData, payload: updatedThumbData });
            }
        });
    }

    private async _createAndRenderDocFromTextPreset(textPreset: ITextPreset): Promise<void> {
        this.doc?.destroy();
        this.doc = await this._createStageDocumentFromTextPreset(textPreset);
        await this._renderDoc();
        this.updateStoreState();
        await this._linkTextLayers();
    }

    private async _createStageDocumentFromTextPreset(textPreset: ITextPreset): Promise<IDoc> {
        if (!this._inputMedia || !this._inputMedia.assetId) {
            return Promise.reject();
        }

        const imageUrl = await AssetStorageUtils.getAndStoreAssetData(this._inputMedia.assetId);
        const imageSize = await ImageUtils.getImageSizeFromURL(imageUrl);
        const imageData = await ImageUtils.createImageData(imageUrl);
        const dummyImageData = new ImageData(imageSize.width, imageSize.height);
        const textOptions = await this._getTextOptionsFromTextPreset(textPreset);
        const isBackgroundVisible = textPreset.background === "clear" ? false : true;

        const docPayload: ELStageDocPayload = { layerKind: ELLayerKind.image, data: imageData, selectable: false, visible: isBackgroundVisible };
        const stagePayload: ELFabricConfig = { showReplaceMediaButton: false, showDeleteButton: false, objectHoverColor: "rgb(0, 255, 255)", addCanvasHandlers: true };
        const docFactoryPayload: DocumentFactoryPayload = { docPayload: docPayload, stagePayload: stagePayload };

        const doc = await DocumentFactory.createDocumentWithStage(DocumentType.stageDoc, this, docFactoryPayload);
        this._layerNameToIdMap.set(this._layers.backgroundLayer, doc.getBaseLayerId());

        const fill = textPreset.background === "clear" ? "black" : textPreset.background;
        const backgroundOpacity = textPreset.backgroundOpacity ? textPreset.backgroundOpacity / 100 : 1;
        const fillLayer = await doc.addLayer({ layerKind: ELLayerKind.rectangle, data: dummyImageData, selectable: false, editInfo: { fill: fill, opacity: backgroundOpacity }, visible: isBackgroundVisible });
        this._layerNameToIdMap.set(this._layers.fillLayer, fillLayer);

        const textLayer1 = await doc.addLayer({ layerKind: ELLayerKind.text, data: dummyImageData, editInfo: { textInfo: textOptions } });
        this._layerNameToIdMap.set(this._layers.textLayer1, textLayer1);

        const imageLayer = await doc.addLayer({ layerKind: ELLayerKind.image, data: imageData, isClipped: true, clipPathOptions: { layerKind: ELLayerKind.text, editInfo: { textInfo: textOptions } } });
        this._layerNameToIdMap.set(this._layers.imageLayer, imageLayer);

        const textLayer2 = await doc.addLayer({ layerKind: ELLayerKind.text, data: dummyImageData, selectable: true, editInfo: { textInfo: textOptions, opacity: 0.01 } });
        this._layerNameToIdMap.set(this._layers.textLayer2, textLayer2);

        return doc;
    }

    private async _createAndRenderDocFromLayerData(): Promise<void> {
        this.doc?.destroy();
        this.doc = await this._createStageDocumentFromLayerData();
        await this._renderDoc();
        this.updateStoreState();
        await this._linkTextLayers();
    }

    private async _createStageDocumentFromLayerData(): Promise<IDoc> {
        if (!this._inputMedia || !this._inputMedia.assetId || !this._layerDataOptionsList) {
            return Promise.reject();
        }

        const layerDataOptionsList = _.cloneDeep(this._layerDataOptionsList);

        const imageUrl = await AssetStorageUtils.getAndStoreAssetData(this._inputMedia.assetId);
        const imageSize = await ImageUtils.getImageSizeFromURL(imageUrl);
        const dummyImageData = new ImageData(imageSize.width, imageSize.height);
        const imageData = await ImageUtils.createImageData(imageUrl);

        const docPayload: ELStageDocPayload = { ...layerDataOptionsList[0], data: imageData };
        const stagePayload: ELFabricConfig = { showReplaceMediaButton: false, showDeleteButton: false, objectHoverColor: "rgb(0, 255, 255)", addCanvasHandlers: true };
        const docFactoryPayload: DocumentFactoryPayload = { docPayload: docPayload, stagePayload: stagePayload };

        const doc = await DocumentFactory.createDocumentWithStage(DocumentType.stageDoc, this, docFactoryPayload);
        this._layerNameToIdMap.set(this._layers.backgroundLayer, doc.getBaseLayerId());

        const fillLayer = await doc.addLayer({ ...layerDataOptionsList[1], data: dummyImageData });
        this._layerNameToIdMap.set(this._layers.fillLayer, fillLayer);

        const textLayer1 = await doc.addLayer({ ...layerDataOptionsList[2], data: dummyImageData });
        this._layerNameToIdMap.set(this._layers.textLayer1, textLayer1);


        const imageLayer = await doc.addLayer({ ...layerDataOptionsList[3], data: imageData });
        this._layerNameToIdMap.set(this._layers.imageLayer, imageLayer);

        const textLayer2 = await doc.addLayer({ ...layerDataOptionsList[4], data: dummyImageData });
        this._layerNameToIdMap.set(this._layers.textLayer2, textLayer2);

        return doc;
    }

    private async _renderDoc(): Promise<void> {
        if (!this.doc) {
            return Promise.reject();
        }
        try {
            await this.doc.render(this.ensureHTMLElement("photo-text-edit-container"));
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PhotoText:_renderDoc: ", "Couldn't render document", error);
            ToastUtils.error(IntlHandler.getInstance().formatMessage("creations-render-error", { workflow: IntlHandler.getInstance().formatMessage("photo-text") }));
            this.startPreviousWorkflow();
            this.logIngestData(IngestEventSubTypes.error, "Document render failed");
        }
    }

    private async _getTextOptionsFromTextPreset(textPreset: ITextPreset): Promise<CSLayerTextInfo> {
        const stroke = textPreset.style.stroke ?? "black";
        const strokeWidth = textPreset.style.strokeWidth ?? 0;
        let shadowBlur: number | undefined;
        if (textPreset.style.shadow) {
            switch (textPreset.style.shadow) {
                case "low": shadowBlur = 5; break;
                case "mid": shadowBlur = 10; break;
                case "max": shadowBlur = 15; break;
            }
        }

        const text = (await this._getTextFromTextLayer()) ?? IntlHandler.getInstance().formatMessage("photo-text-initial-text");

        const textOptions: CSLayerTextInfo = {
            text: text,
            fontFamily: textPreset.font,
            fontSize: 128,
            shadowBlur: shadowBlur,
            shadowColor: "black",
            stroke: stroke,
            strokeWidth: strokeWidth,
            textAlign: textPreset.style.alignment,
            spread: textPreset.style.spread,
            underline: false
        };
        return textOptions;
    }

    private async _getTextFromTextLayer(): Promise<string | undefined> {
        if (this.doc) {
            const textLayerId = this._layerNameToIdMap.get(this._layers.textLayer1);
            if (textLayerId) {
                const textLayer = await this.doc.getLayerById(textLayerId);
                return textLayer.getText();
            }
        }
    }

    private async _linkTextLayers(): Promise<void> {
        const linkLayer1 = { layerId: this._layerNameToIdMap.get(this._layers.textLayer1), isClipPath: false };
        const linkLayer2 = { layerId: this._layerNameToIdMap.get(this._layers.imageLayer), isClipPath: true };
        const linkLayer3 = { layerId: this._layerNameToIdMap.get(this._layers.textLayer2), isClipPath: false };
        const linkLayers = [linkLayer1, linkLayer2, linkLayer3];
        const linkType = ELLinkType.textLayer;
        await this.doc?.notify({ type: ELStageDocActions.linkLayers, payload: { linkLayers, linkType } });
    }

    private _reorderPanelThumbnails(overlayId: string): void {
        this._leftTabPanel.notify({ type: ELRecommendationWorkflowControllerActions.keepThumbIdOnTop, payload: overlayId });
    }

    private _updateProgressValueWithTimer(): void {
        let timerSeed = 1;
        let lastProgressValue = 0;
        const slowUpdateBreakpoint = 90;
        const intervalDuration = 1000;
        this._progressTimer = setInterval(() => {
            let progressValue = Math.floor((timerSeed * 10) - Math.random() * 10);

            if (progressValue > slowUpdateBreakpoint) {
                progressValue = slowUpdateBreakpoint + (timerSeed % 10);
                progressValue = (progressValue < lastProgressValue) ? lastProgressValue : progressValue;
            }
            lastProgressValue = progressValue;
            this._updateProgressPercentageToView(progressValue);
            timerSeed++;
        }, intervalDuration);
    }

    private async _updateProgressPercentageToView(progress: number): Promise<void> {
        this.viewDispatcher?.({
            type: CreationInAppNotifierAction.creationProgressChanged,
            payload: progress
        });
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const element = React.createElement(PhotoTextView, {
            controller: this
        });

        const provider = React.createElement(Provider, { store }, element);

        ReactDOM.render(
            provider,
            container
        );
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    destroy(): void {
        this.renditionHandler.clearRenditions();

        CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
        RecommendationsInAppNotifier.unsubscribe(this, RecommendationsAppSubscriberType.statusChange);

        clearInterval(this._progressTimer);

        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);

        const workflowPayload = action?.payload as CreationStatusPayload;
        this.mode = action?.initMode as CreationsMode;

        const photoTextPayload: ELCreationWorkflowPayload = {
            initMode: this.mode,
            payload: workflowPayload
        };

        this._photoTextPayload = photoTextPayload;

        this.createView(this.ensureHTMLElement(containerId));
    }

    endWorkflow(): void {
        super.endWorkflow();
    }

    private _updateViewStatus(status: CreationsStatus): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowStatus,
            payload: status,
        });
    }

    private _updateProgressText(progressText: string): void {
        this.viewDispatcher?.call(this.viewDispatcher, {
            type: ELRecommendationWorkflowViewActions.recommendationWorkflowProgressText,
            payload: progressText
        });
    }

    private async _transparentCardClicked(): Promise<void> {
        const backgroundLayerId = this._layerNameToIdMap.get(this._layers.backgroundLayer);
        const fillLayerId = this._layerNameToIdMap.get(this._layers.fillLayer);
        if (!fillLayerId || !backgroundLayerId) {
            return Promise.reject("layer not found!");
        }

        await this.doc?.setLayerVisible({ layerId: backgroundLayerId, data: false });
        await this.doc?.setLayerVisible({ layerId: fillLayerId, data: false });
    }

    private async _updateBackgroundColor(color: string): Promise<void> {
        const backgroundLayerId = this._layerNameToIdMap.get(this._layers.backgroundLayer);
        const fillLayerId = this._layerNameToIdMap.get(this._layers.fillLayer);
        if (!fillLayerId || !backgroundLayerId) {
            return Promise.reject("layer not found!");
        }

        await this.doc?.setLayerVisible({ layerId: backgroundLayerId, data: true });
        await this.doc?.setLayerVisible({ layerId: fillLayerId, data: true });
        await this.doc?.setLayerFill({ layerId: fillLayerId, data: color });
    }

    private async _updateOpacityColor(opacity: number): Promise<void> {
        const backgroundLayerId = this._layerNameToIdMap.get(this._layers.backgroundLayer);
        const fillLayerId = this._layerNameToIdMap.get(this._layers.fillLayer);
        if (!fillLayerId || !backgroundLayerId) {
            return Promise.reject("layer not found!");
        }

        await this.doc?.setLayerVisible({ layerId: backgroundLayerId, data: true });
        await this.doc?.setLayerVisible({ layerId: fillLayerId, data: true });
        await this.doc?.setLayerOpacity({ layerId: fillLayerId, data: opacity / 100 });
    }

    private async _applyTextPreset(selectedTextPresetId: string): Promise<void> {
        const contentData = await ELContentCacheDownloader.getContentForContentType(ContentType.photoText);
        let selectedOverlayIndex = 0;
        contentData.forEach((data, index) => {
            if (data.props.id === selectedTextPresetId) {
                selectedOverlayIndex = index;
            }
        });

        const textPreset = (await this._fetchTextPresets(contentData))[selectedOverlayIndex];

        const isBackgroundVisible = textPreset.background === "clear" ? false : true;
        if (!isBackgroundVisible) {
            await this._transparentCardClicked();
        } else {
            await this._updateBackgroundColor(textPreset.background);
            await this._updateOpacityColor(textPreset.backgroundOpacity ? textPreset.backgroundOpacity : 100);
        }

        const textOptions = await this._getTextOptionsFromTextPreset(textPreset);

        const textLayer1 = this._layerNameToIdMap.get(this._layers.textLayer1);
        await this.doc?.setLayerTextOptions({ layerId: textLayer1, data: textOptions });

        const imageLayer = this._layerNameToIdMap.get(this._layers.imageLayer);
        if (!imageLayer) {
            Logger.log(LogLevel.WARN, "PhotoText:_applyTextPreset: ", "Image layer not found!");
            return Promise.reject("Image layer not found!");
        }
        const clipPathOptions = await this.doc?.getClipPathOptions(imageLayer) as ELStageLayerDataOptions;
        clipPathOptions.editInfo = { textInfo: textOptions };
        await this.doc?.setClipPathOptions({ layerId: imageLayer, data: clipPathOptions });

        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        await this.doc?.setLayerTextOptions({ layerId: textLayer2, data: textOptions });

        this.updateStoreState();

        await this._linkTextLayers();
    }


    private async _overlayClicked(thumbInfo: ELPreviewCreationThumbData): Promise<void> {
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
        this._updateSelectedOverlayId(thumbInfo.id);

        this._applyTextPreset(thumbInfo.id);

        this.ingestParams.subType = CreationsMode.update;
        this.logIngestData(IngestEventSubTypes.success);
    }

    private async _updateTextProperty(psName: FontIdentifier): Promise<void> {
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_updateTextProperty: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.fontFamily, value: psName.postscriptName };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _fontChange(psName: FontIdentifier): Promise<void> {
        await FontsStore.getInstance().checkCacheAndLoadFont(psName.postscriptName);
        await this._updateTextProperty(psName);
    }

    private async _fontPreviewChange(psName: FontIdentifier): Promise<void> {
        await FontsStore.getInstance().checkCacheAndLoadFont(psName.postscriptName, tqtypes.FontUsageOperation.PREVIEW);
        await this._updateTextProperty(psName);
    }

    private async _fontSizeChange(fontSize: number): Promise<void> {
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_fontSizeChange: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.fontSize, value: fontSize };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _updateStrokeWidth(width: number): Promise<void> {
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_updateStrokeWidth: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.strokeWidth, value: width };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _updateStrokeColor(color: string): Promise<void> {
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_updateStrokeColor: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.stroke, value: color };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _updateShadowBlur(blur: number): Promise<void> {
        const shadow = new fabric.Shadow({ blur: blur, color: "black" });
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_updateShadowBlur: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.shadow, value: shadow };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _updateAlignment(alignment: ELTextAlign): Promise<void> {
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_updateAlignment: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.textAlign, value: alignment };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _updateSpread(spread: ELTextSpread): Promise<void> {
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_updateSpread: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.spread, value: spread };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _updateUnderline(underline: boolean): Promise<void> {
        const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
        if (!textLayer2) {
            Logger.log(LogLevel.WARN, "PhotoText:_updateUnderline: ", "textLayer2 not found!");
            return Promise.reject("textLayer2 not found!");
        }
        const payload: ELStageDocTextPropertyPayload = { layerId: textLayer2, key: ELStageTextProperty.underline, value: underline };
        await this.doc?.notify({ type: ELStageDocActions.updateTextProperty, payload: payload });
    }

    private async _download(imageData: ELImageData): Promise<void> {
        this.startDate = new Date();
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.workspace;
        try {
            const message = IntlHandler.getInstance().formatMessage("file-download-in-background",
                { media: IntlHandler.getInstance().formatMessage("photo-text-creation").toLowerCase() });
            ToastUtils.info(message);
            const defaultTitle = IntlHandler.getInstance().formatMessage(UNTITLED_INTL_KEY);
            this.doc?.notify({ type: DocumentActions.download, payload: { name: this.projectData?.title ?? defaultTitle, imageData: imageData } });
            this.ingestParams.subType = IngestEventSubTypes.download;
            this.logIngestData(IngestEventSubTypes.success);
        } catch (e) {
            setTimeout(() => {
                ToastUtils.error(IntlHandler.getInstance().formatMessage("download-fail-toast-msg"), {
                    closable: true,
                    timeout: 0
                });
            }, Constants.TOAST_DEFAULT_TIME_OUT_LIMIT as number);
            this.ingestParams.subType = IngestEventSubTypes.download;
            this.logIngestData(IngestEventSubTypes.error, e as string);
        }
    }

    private async _save(): Promise<void> {
        try {
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saveInProgress));
            this.updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("saving-creation"));

            this.startDate = new Date();
            this._updateProgressPercentageToView(Constants.ZERO as number);
            const message = IntlHandler.getInstance().formatMessage("saving-creation");
            this._updateProgressText(message);

            const asset = store.getState().selectedMediaListReducer[0];
            const title = store.getState().recommendationWorkflowReducer.title;
            const layerDataOptionsList = this.doc ? await ELStageDocUtils.getLayerDataOptionsList(this.doc) : undefined;

            if (!this.projectId) {
                await this.createRecommendationProject({ asset: asset, title: title, layerDataOptionsList: layerDataOptionsList });
            } else {
                await this.editRecommendationsProject({ asset: asset, title: title, layerDataOptionsList: layerDataOptionsList });
            }

            this._layerDataOptionsList = _.cloneDeep(layerDataOptionsList);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Unable to save auto background Creation!");
        } finally {
            this._updateViewStatus(CreationsStatus.success);
            store.dispatch(DocActions.updateDocumentSaveStatus(DocumentSaveStatus.saved));
            this.doc?.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
        }
    }

    private async _saveAssetToProjectPath(projectInfo: unknown): Promise<void> {
        try {
            if (!this.projectId || !this.projectData || !this.doc) {
                Logger.log(LogLevel.ERROR, "PhotoText: (_saveAssetAndMaskToProjectPath)", "project id or data or doc not valid", this.projectId, this.projectData, this.doc);
                return Promise.reject();
            }
            const outputAssetPath = (await CreationUtils.getCreationOutputAssetPathOrId(this.projectData)).path;
            if (!outputAssetPath) {
                return Promise.reject();
            }
            const saveManager = new ELStageDocSaveManager();
            const success = await saveManager.save(this.doc, outputAssetPath, projectInfo as Record<string, unknown>);
            if (success) {
                await CreationUtils.updateCreationStatus(this.projectId, CreationsStatus.success);
                this.doc.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "PhotoText:_saveAssetToProjectPath: ", error);
            return Promise.reject("Couldn't save photo text document");
        }
    }

    private _updatePhotoTextRoute(): void {
        if (!this.projectId) {
            Logger.log(LogLevel.ERROR, "PhotoText:_updatePhotoTextRoute: ", "project id not valid");
            return;
        }

        HistoryUtils.replaceHistory(PhotoTextUtils.getHistoryState(this.projectId));
    }

    private _notifySubViews(): void {
        this._creationsHeader.notify({ type: ELCreationsHeaderControllerAction.updateCreationsData, payload: this.projectData });
    }

    private async _saveAndStartPreviousWorkflow(): Promise<void> {
        this.ingestParams.subType = CreationsMode.save;
        this.ingestParams.eventViewType = IngestLogObjectValue.dialog;
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            this.updateViewStatusAndProgressText(CreationsStatus.requested, IntlHandler.getInstance().formatMessage("saving-creation"));
            await this._waitForSaveComplete();
        } else if (this.doc?.isDocumentDirty === DocumentDirty.DIRTY) {
            await this._save();
            await this._waitForDocumentDirtyStatus();
        }
        if (this.projectId) {
            this.preprocessCreationEdit(this.projectId);
        }
        this.startPreviousWorkflow();
    }

    private async _waitForSaveComplete(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const saveStatus = store.getState().docStateReducer.saveStatus;
        if (saveStatus === DocumentSaveStatus.saveInProgress) {
            await Utils.wait(2000);
            await this._waitForSaveComplete(waitCount + 1);
        }
    }

    private async _waitForDocumentDirtyStatus(waitCount = 0, maxWaitCount = 10): Promise<void> {
        if (waitCount >= maxWaitCount) {
            return;
        }
        const isDocDirty = store.getState().docStateReducer.isDirty;
        if (isDocDirty === DocumentDirty.DIRTY) {
            await Utils.wait(2000);
            await this._waitForDocumentDirtyStatus(waitCount + 1);
        }
    }

    private async _onReplaceMedia(replaceAssetInfo: ReplaceAssetInfo): Promise<void> {
        this._updateProgressValueWithTimer();
        const asset = replaceAssetInfo.assetToReplaceWith;
        this.ingestParams.subType = CreationsMode.update;
        store.dispatch(RecommendationWorkflowAction.updateRecommendationStatus(CreationsStatus.requested));
        this._updateViewStatus(CreationsStatus.requested);
        this._updateProgressText(IntlHandler.getInstance().formatMessage("generating-photo-text"));
        this._resetPhotoTextData();
        this.doc?.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
        this._inputMedia = asset;
        this._createPhotoText();
    }

    private _isTextLayer2Object(object: ELStageObject): boolean {
        if (object.data && object.data.payload === this._layerNameToIdMap.get(this._layers.textLayer2)) {
            return true;
        }
        return false;
    }

    private _handleObjectEvent(objectEvent: DocumentActions, object?: ELStageObject): void {
        if (object && this._isTextLayer2Object(object) && objectEvent === DocumentActions.objectScaled) {
            const textObject = object as ELStageTextObject;
            if (textObject.scaleX && textObject.scaleY && textObject.fontSize) {
                const scaledFontSize = Math.floor(Math.min(textObject.scaleX, textObject.scaleY) * textObject.fontSize);
                store.dispatch(TextEditAction.updateFontSize(scaledFontSize));
            }
        } else if (objectEvent === DocumentActions.mouseDoubleClick) {
            const textLayer2 = this._layerNameToIdMap.get(this._layers.textLayer2);
            this.doc?.notify({ type: ELStageDocActions.updateActiveObject, payload: textLayer2 });
        } else if (objectEvent === DocumentActions.textEditingEntered) {
            this._leftTabPanel.updateSelectedTabKey(ELTabPanelKey.empty);
            this._rightTabPanel.updateSelectedTabKey(ELTabPanelKey.text);
        }
    }

    private _onDocumentDirty(dirty: DocumentDirty): void {
        if (this.doc) {
            store.dispatch(DocActions.updateDocumentError(this.doc.hasRenderingError()));
        }

        store.dispatch(DocActions.updateDocumentDirty(dirty));
    }

    private _fetchAndLoadFontFromLayerData(): void {
        if (!this._layerDataOptionsList) {
            Logger.log(LogLevel.WARN, "Layer data options not found");
            throw new Error("Layer data options not found");
        }
        const fontFamily = this._layerDataOptionsList[2].editInfo?.textInfo?.fontFamily;
        if (!fontFamily) {
            Logger.log(LogLevel.WARN, "fontFamily not found");
            throw new Error("fontFamily not found");
        }
        FontsStore.getInstance().checkCacheAndLoadFont(fontFamily);
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case DocumentActions.markDocumentDirty: {
                this._onDocumentDirty(action.payload as DocumentDirty);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.transparentCardClicked: {
                this._transparentCardClicked();
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.backgroundColorChange: {
                const color = action.payload as string;
                this._updateBackgroundColor(color);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.backgroundOpacityChange: {
                const opacity = action.payload as number;
                this._updateOpacityColor(opacity);
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.overlayClicked: {
                const thumbInfo = action.payload as ELPreviewCreationThumbData;
                this._overlayClicked(thumbInfo);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.fontChange: {
                const psName = action.payload as FontIdentifier;
                await this._fontChange(psName);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.fontPreviewChange: {
                const psName = action.payload as FontIdentifier;
                await this._fontPreviewChange(psName);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.fontSizeChange: {
                const fontSize = action.payload as number;
                await this._fontSizeChange(fontSize);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.strokeWidthChange: {
                const strokeWidth = parseInt(action.payload as string);
                await this._updateStrokeWidth(strokeWidth);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.strokeColorChange: {
                const strokeColor = action.payload as string;
                await this._updateStrokeColor(strokeColor);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.shadowBlurChange: {
                const shadowBlur = parseInt(action.payload as string);
                await this._updateShadowBlur(shadowBlur);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.textAlignmentChange: {
                const alignment = action.payload as ELTextAlign;
                await this._updateAlignment(alignment);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.spreadChange: {
                const spreadType = action.payload as ELTextSpread;
                await this._updateSpread(spreadType);
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.revert: {
                await this.revert();
                handled = true;
                break;
            }
            case ELPhotoTextWorkflowControllerActions.underlineChange: {
                const underline = action.payload as boolean;
                await this._updateUnderline(underline);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.download: {
                const imageData = action.payload as ELImageData;
                this._download(imageData);
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.save: {
                this.ingestParams.subType = CreationsMode.save;
                this.ingestParams.eventViewType = IngestLogObjectValue.workspace;
                await this._save();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.dontSave: {
                this.notify({ type: ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView });
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace,
                    IngestEventTypes.click, IngestEventSubTypes.backDialogDontSave, CreationsJobProjectSubType.photoText));
                handled = true;
                break;
            }
            case ELRecommendationWorkflowControllerActions.backRecommendationWorkflowView: {
                const workspacePayload = { startWorkflow: WorkflowsName.creationsHome };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case WorkspaceActionType.startPreviousWorkflow: {
                this._saveAndStartPreviousWorkflow();
                handled = true;
                break;
            }
            case CreationMediaActionType.replaceMedia: {
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.photoText, IngestEventTypes.click, IngestEventSubTypes.replaceMedia));
                const workspacePayload: WorkspacePayload = {
                    startWorkflow: WorkflowsName.replaceMediaManager,
                    payload: {
                        assetId: store.getState().selectedMediaListReducer[0].assetId,
                        mode: ReplaceMediaManagerMode.replacingMedia,
                        mediaGridConfig: this.mediaGridConfig
                    }
                };
                const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
                handled = await this._owner.notify(workspaceAction);
                break;
            }
            case ReplaceMediaManagerWorkflowAction.replaceMediaSelection: {
                const replaceAssetInfo = action.payload as ReplaceAssetInfo;
                this._onReplaceMedia(replaceAssetInfo);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomInEvent:
            case CanvasZoomLevelAction.zoomOutEvent: {
                this.doc?.notify(action);
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.photoText, IngestEventTypes.click, IngestEventSubTypes.zoom, "Zoom-In-Out"));
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.changeZoomValue: {
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.photoText, IngestEventTypes.click, IngestEventSubTypes.zoom, action.payload as string));
                this.doc?.notify(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFill: {
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.photoText, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fill"));
                this.doc?.notify({ type: CanvasZoomLevelAction.changeZoomValue, payload: DEFAULT_ZOOM_PERCENTAGE });
                this.doc?.notify(action);
                handled = true;
                break;
            }
            case CanvasZoomLevelAction.zoomToFit: {
                this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.photoText, IngestEventTypes.click, IngestEventSubTypes.zoom, "Fit"));
                this.doc?.notify({ type: CanvasZoomLevelAction.changeZoomValue, payload: DEFAULT_ZOOM_PERCENTAGE });
                this.doc?.notify(action);
                handled = true;
                break;
            }
            case DocumentActions.objectScaled:
            case DocumentActions.mouseDoubleClick:
            case DocumentActions.textEditingEntered: {
                this._handleObjectEvent(action.type, action.payload ? action.payload as ELStageObject : undefined);
                handled = true;
                break;
            }
        }
        if (!handled) {
            handled = await super.notify(action);
        }
        return handled;
    }
}

export default PhotoText;