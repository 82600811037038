/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//ThirdParty
import React from "react";

//Adobe internal
import { Flex, Text } from "@adobe/react-spectrum";
import { usePress } from "@react-aria/interactions";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELTransParentIcon from "../../../../assets/icons/EL_Transparent_N.png";

import "./ELTransparentCard.scss";

export interface ELTransparentCardProps {
    width: number;
    height: number;
    onPress: () => void;
    selected: boolean;
}

export const ELTransparentCard = (props: ELTransparentCardProps): React.ReactElement => {
    const { pressProps } = usePress({
        onPress: () => { props.onPress(); }
    });

    const getIconClassName = (): string => {
        if (props.selected) {
            return "transparent-icon-selected";
        } else {
            return "transparent-icon";
        }
    }

    return (
        <Flex data-testid="transparent-card" width={props.width} height={props.height} direction={"column"}>
            <img  {...pressProps} className={getIconClassName()} crossOrigin="anonymous" src={ELTransParentIcon} />
            <Text UNSAFE_className="transparent-text">{IntlHandler.getInstance().formatMessage("none")}</Text>
        </Flex>
    )
}