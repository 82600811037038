/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ITabPanelItem, ELTabPanelKey, ELTabPanelType } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { ELLooksPanel } from "../../../../view/components/templates/el-looks-panel/ELLooksPanel";
import ELPanelManager, { ELPanelManagerConfig } from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import { ELSingleImageMediaManager } from "../../../../view/components/templates/el-single-image-media-manager/ELSingleImageMediaManager";
import IPanelProvider from "../../../common/IPanelProvider";
import { ELEditPanelItemProvider } from "./ELEditPanelItemProvider";

export class ELEditPanelProvider extends IPanelProvider {
    private _getRightTabPanel(): ELPanelManager {
        const looksPanel = new ELLooksPanel(this.owner);
        const mediaManager = new ELSingleImageMediaManager(this.owner);

        const listOfTabItemsUIInfo = ELEditPanelItemProvider();
        const listOfTabItems: ITabPanelItem[] = [];
        listOfTabItemsUIInfo.forEach((buttonItem) => {
            switch (buttonItem.key) {
                case ELTabPanelKey.looks: {
                    listOfTabItems.push({
                        ...buttonItem,
                        controller: looksPanel,
                        view: looksPanel.getView()
                    });
                    break;
                }
                case ELTabPanelKey.media: {
                    listOfTabItems.push({
                        ...buttonItem,
                        controller: mediaManager,
                        view: mediaManager.getView("imageEditWorkflowReducer")
                    })
                }
            }
        })

        const config: ELPanelManagerConfig = {
            selectedKey: ELTabPanelKey.looks,
            panelItemDirection: "row-reverse"
        };

        return new ELPanelManager(this.owner, listOfTabItems, config);
    }

    getTabPanel(panelType: ELTabPanelType): ELPanelManager {
        if (panelType === ELTabPanelType.rightTabPanel) {
            return this._getRightTabPanel();
        } else {
            throw new Error("ELEditPanelProvider:getTabPanel: panelType not valid");
        }
    }
}