/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import IAction from "../actions/IAction";
import ImageEditWorkflowAction from "../actions/ImageEditWorkflowActions";


interface ImageEditWorkflowData {
    status: CreationsStatus,
}

const updateStatus = (state: ImageEditWorkflowData, action: IAction<any>): ImageEditWorkflowData => {
    if (action.payload) {
        return {
            ...state,
            status: action.payload as CreationsStatus
        }
    }
    return state;
};

const imageEditWorkflowReducer = (state: ImageEditWorkflowData = { status: CreationsStatus.success }, action: IAction<any>): ImageEditWorkflowData => {
    switch (action.type) {
        case ImageEditWorkflowAction.UPDATE_STATUS: {
            return updateStatus(state, action);
        }
        default:
            return state;
    }
}

export default imageEditWorkflowReducer;