/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";

//Application Specific
import { IconType } from "../../../../assets/IconConstants";
import { ELIcon } from "../el-icon/ELIconView";

import "./ELDeletableMediaThumb.scss"

export interface ELDeletableMediaThumbProps {
    imgSrc: string,
    altName?: string,
    onDeleteClick: (assetId: number) => void,
    assetId: number,
    tileWidth: number,
    tileHeight: number,
    isDeletableThumb: boolean,
}

const ELDeletableMediaThumb = (props: ELDeletableMediaThumbProps): React.ReactElement => {
    const closeIcon = (<ELIcon iconkey={IconType.close} className="deletable-media-thumb-close-icon" />);

    const getDeleteButton = (): React.ReactElement => {
        return (<>
            <button className="deletable-media-thumb-button" onClick={() => { props.onDeleteClick(props.assetId) }} >{closeIcon}</button>
        </>);
    }

    return (
        <div className="deletable-media-thumb-container">
            <Flex>
                <img crossOrigin="anonymous" onContextMenu={(e) => e.preventDefault()} draggable="false" style={{ width: props.tileWidth, height: props.tileHeight }}
                    className="deletable-media-thumb" src={props.imgSrc} alt={props.altName} />
                {props.isDeletableThumb && getDeleteButton()}
            </Flex>
        </div>)
}

export default ELDeletableMediaThumb;
