/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third party
import React, { Key, useEffect, useReducer, useState } from "react";

// Adobe internal
import { Dialog, DialogContainer, Flex, Heading, Item, TabList, TabPanels, Tabs } from "@adobe/react-spectrum";

// Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import IViewController, { ControllerAction } from "../../../IViewController";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { ViewAction } from "../../../IBaseController";
import { ELWhatsNewDialogAction, ELWhatsNewTabData } from "../../../../common/interfaces/ELWhatsNewTypes";

import "./ELWhatsNewPanelView.scss";

interface ELWhatsNewDialogViewProps {
    controller: IViewController;
    onTabSelectionChange: (tabName: string) => void;
}

interface ELWhatsNewContentState {
    whatsNewData: ELWhatsNewTabData[];
}

export const ELWhatsNewDialogView = (props: ELWhatsNewDialogViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const reducer = (state: ELWhatsNewContentState, action: ViewAction): ELWhatsNewContentState => {
        switch (action.type) {
            case ELWhatsNewDialogAction.updateWhatsNewContent: {
                return {
                    ...state,
                    whatsNewData: action.payload as ELWhatsNewTabData[]
                };
            }
            default:
                return state;
        }
    };
    const [state, viewDispatch] = useReducer(reducer, { whatsNewData: [] } as ELWhatsNewContentState);

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        //clean up
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    const notifyDismiss = (): void => {
        const action: ControllerAction = { type: ELWhatsNewDialogAction.onDismiss };
        props.controller.notify(action);
    };

    const isKeySelected = (key: number): boolean => {
        // type casting to number as selectedTab is coming as string,
        // might be related TabList as if I do the same thing using a div or image, its working fine
        return (Number(selectedTab) === key);
    };

    const getTabItemClassName = (key: number): string => {
        return isKeySelected(key) ? "whats-new__tab-name selected" : "whats-new__tab-name";
    };

    const getTabItemSelectionIndicatorClassName = (key: number): string => {
        return isKeySelected(key) ? "whats-new__tablist-item__selection-indicator selected" : "whats-new__tablist-item__selection-indicator";
    };

    const onTabSelectionChange = (key: number): void => {
        setSelectedTab(key);
        props.onTabSelectionChange(state.whatsNewData[key].tabName);
    };

    const getTabPanel = (): React.ReactElement => {
        const whatsNew = intlHandler.formatMessage("whats-new");

        return (
            <Flex data-testid="whatsnew-dialog-container" UNSAFE_className="whats-new-dialog__content">
                <Tabs aria-label="Whats new Vertical tabs" orientation="vertical" selectedKey={selectedTab}
                    onSelectionChange={(key: Key) => onTabSelectionChange(key as number)}>
                    <Flex UNSAFE_className="whats-new-dialog__left-tab-panel">
                        <Heading UNSAFE_className="whats-new__top-heading" >{whatsNew}</Heading>
                        <div className="whats-new__tablist-container">
                            <TabList UNSAFE_className="whats-new__tablist">
                                {
                                    state.whatsNewData.map((item) =>
                                        <Item key={item.key}>
                                            <div className="whats-new__tablist-item-and-selection-indicator">
                                                <div className={getTabItemSelectionIndicatorClassName(item.key)} />
                                                <span className={getTabItemClassName(item.key)}>{intlHandler.formatMessage(item.tabName)}</span>
                                            </div>
                                        </Item>)
                                }
                            </TabList>
                        </div>
                    </Flex >
                    <TabPanels UNSAFE_className="whats-new-dialog__tabpanels">
                        {
                            state.whatsNewData.map((viewItem) => <Item key={viewItem.key}> {viewItem.panelData} </Item>)
                        }
                    </TabPanels>
                </Tabs>
            </Flex>
        );
    };

    return (
        <ELReactSpectrumV3Provider >
            <DialogContainer onDismiss={() => notifyDismiss()} isDismissable>
                <Dialog UNSAFE_className="whats-new-dialog__top-container" data-testid="whatsnew-dialog">
                    {getTabPanel()}
                </Dialog>
            </DialogContainer>
        </ELReactSpectrumV3Provider>
    );
};
