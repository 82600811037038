/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { EditingEngineType } from "../../../common/interfaces/editing/editingEngines/EditingEnginesTypes";
import { CSLayerEditInfo, ELLayerKind, ELStageLayerData } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELLayerBlendModes } from "../../../common/interfaces/editing/pie/ELPIELayerTypes";
import { ELPoint, ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import ImageUtils from "../../../utils/ImageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import IDoc from "../../document/IDoc";
import ILayer from "../../document/layer/ILayer";
import { EditingEngineManager } from "../../editingEngines/EditingEngineManager";
import { PIEEditingEngine } from "../../editingEngines/PIEEditingEngine";
import ELPIEDoc from "../models/ELPIEDoc";
import IPSDConvertor from "./IPSDConvertor";

export default class ELStageDocPSDConvertor extends IPSDConvertor {
    private _getRatio(inSize: ELSize, outSize: ELSize): number {
        const hRatio = outSize.width / inSize.width;
        const vRatio = outSize.height / inSize.height;
        const ratio = Math.max(hRatio, vRatio);

        return ratio;
    }

    private _getFitToBackgroundSize(inSize: ELSize, outSize: ELSize): ELSize {
        const ratio = this._getRatio(inSize, outSize);

        const scaledWidth = inSize.width * ratio;
        const scaledHeight = inSize.height * ratio;

        return { width: scaledWidth, height: scaledHeight };
    }

    private _getFitToBackgroundCenter(inSize: ELSize, outSize: ELSize): ELPoint {
        const ratio = this._getRatio(inSize, outSize);

        const centerShift_x = (outSize.width - inSize.width * ratio) / 2;
        const centerShift_y = (outSize.height - inSize.height * ratio) / 2;

        return { x: centerShift_x, y: centerShift_y };
    }

    private async _getCutoutSize(inputDoc: IDoc): Promise<ELSize> {
        const pieEditingEngine = await EditingEngineManager.getEditingEngine(EditingEngineType.pie) as PIEEditingEngine;
        await pieEditingEngine.ready();
        const psdDoc: IDoc = await this.createDocWithWhiteBackgroundLayer(inputDoc, pieEditingEngine);

        const layers = await inputDoc.getLayers();
        const cutoutLayer = layers[2];

        const data = cutoutLayer.getData() as ImageData;
        const buffer = await ImageUtils.getArrayBufferFromImageData(data);

        const layerData: ELStageLayerData = {
            data: buffer,
            layerParams: { layerBlendMode: ELLayerBlendModes.normal },
            redraw: false,
            layerKind: ELLayerKind.pixel,
        };

        await psdDoc.addLayer(layerData);

        const psdLayers = await psdDoc.getLayers();
        return Promise.resolve(await psdLayers[0].getSize());
    }

    /**
     * ImageData gives bounds for all pixels. It doesn't honour transparent pixels.
     * PSD gives bounds only for non transparent pixels, hence setting it transparent pixel to 1 to get entire bounds. 
    */
    private _removeAlpha(imageData: ImageData): ImageData {
        const data = new ImageData(new Uint8ClampedArray(imageData.data), imageData.width, imageData.height);

        for (let i = 3, len = data.data.length; i < len; i = i + 4) {
            data.data[i] = (data.data[i] === 0) ? 1 : data.data[i];
        }

        return data;
    }

    private async _getEditInfoForFitToBackground(layer: ILayer, inputDoc: IDoc): Promise<CSLayerEditInfo> {
        const layerSize = await layer.getSize();
        const docSize = await inputDoc.getSize();
        const scale = this._getRatio(layerSize, docSize);
        const editInfo: CSLayerEditInfo = {
            transform: {
                quadrilateral: {
                    left_top: 0,
                    right_top: 0,
                    left_bottom: layerSize.width,
                    right_bottom: layerSize.height,
                },
                scale: {
                    horizontal: scale,
                    vertical: scale
                },
                center: {
                    horizontal: (layerSize.width * scale) / 2,
                    vertical: (layerSize.height * scale) / 2
                },
                rotate: 0,
                offset: {
                    horizontal: 0,
                    vertical: 0
                }
            }
        };
        return Promise.resolve(editInfo);
    }

    private async _getLayerEditInfo(layer: ILayer, inputDoc: IDoc): Promise<CSLayerEditInfo | undefined> {
        let editInfo = layer.getEditInfo();

        if (!editInfo && layer.getFitToBackground()) {
            editInfo = await this._getEditInfoForFitToBackground(layer, inputDoc);
        }

        return editInfo;
    }

    private async _addLayers(inputDoc: IDoc, psdDoc: ELPIEDoc): Promise<ELPIEDoc> {
        const layers = await inputDoc.getLayers();
        for (const layer of layers.values()) {
            const data = layer.getData() as ImageData;

            const buffer = await ImageUtils.getArrayBufferFromImageData(data);
            const editInfo = await this._getLayerEditInfo(layer, inputDoc);

            const layerData: ELStageLayerData = {
                data: buffer,
                layerParams: { layerBlendMode: ELLayerBlendModes.normal },
                redraw: false,
                layerKind: ELLayerKind.pixel,
                editInfo: editInfo,
                visible: layer.getVisibility()
            };

            await psdDoc.addLayer(layerData);
        }
        return Promise.resolve(psdDoc);
    }

    async convertToPSD(inputDoc: IDoc, doResize = false): Promise<IDoc> {
        try {
            const pieEditingEngine = await EditingEngineManager.getEditingEngine(EditingEngineType.pie) as PIEEditingEngine;
            await pieEditingEngine.ready();
            let psdDoc: IDoc = await this.createDocWithWhiteBackgroundLayer(inputDoc, pieEditingEngine);
            psdDoc.setLayerVisibility(0, false);
            psdDoc = await this._addLayers(inputDoc, psdDoc as ELPIEDoc);

            if (doResize && (await this.shouldResize())) {
                psdDoc = await this.resizeDocument(psdDoc as ELPIEDoc, pieEditingEngine);
            }

            return Promise.resolve(psdDoc);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "Unable to create PSD", error);
            return Promise.reject("PSD couldn't be created!");
        }
    }
}