/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import Utils from "./Utils";

// add extensions name in lowercase only in both arrays.
const ADDITIONAL_FILES_EXTENSIONS: string[] = ["heic"];  // extensions to be added in case import is allowed for them.
const UNSUPPORTED_FILE_EXTENSIONS: string[] = ["mod", "psd", "avi", "dv", "vob"];  // files with this extensions won't be allowed to be imported.

export const FileUtils = {
    async urlToFile(imageUrl: string, filename: string, mimeType: string): Promise<File> {
        const response = await fetch(imageUrl);
        const data = await response.blob();
        const metadata = { type: mimeType };
        const file = new File([data], filename, metadata);
        return file;
    },

    getFileNameFromPath(filePath: string): string {
        return filePath.split('/').pop() || '';
    },

    /* on windows , type field of File class comes empty, so we need to add below handling */
    additionalSupportedFiles(name: string): boolean {
        const extension = Utils.getFileExtension(name);
        return ADDITIONAL_FILES_EXTENSIONS.includes(extension.toLowerCase());
    },

    isUnsupportedFileExtensions(name: string): boolean {
        const extension = Utils.getFileExtension(name);
        return UNSUPPORTED_FILE_EXTENSIONS.includes(extension.toLowerCase());
    }
};