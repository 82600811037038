/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import AppRouteUtil from "../../../app/AppRouteUtil";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { IngestLogging } from "../../../services/IngestWrapper";
import SelectedMediaListAction from "../../../stores/actions/selectedMediaListActions";
import store from "../../../stores/store";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes } from "../../../utils/IngestConstants";
import { IngestUtils } from "../../../utils/IngestUtils";
import { ToastUtils } from "../../../utils/ToastUtils";
import { SelectedMediaListType } from "../../../view/components/organism/el-mediagrid/ELMediaGrid";
import { WorkspacesName } from "../../IBaseWorkspace";
import ImageEditWorkflowUtils from "./ImageEditWorkflowUtils";

export class EditWorkflowLauncher {
    private _warningToastForMinImageLimit(minImageLimit: number | undefined): void {
        //GLIA_REVISIT: vib - Check ingest logging
        const minImageObject = IngestUtils.addWorkspaceDetail(WorkspacesName.edit, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.imageEdit,
            IngestEventTypes.create, IngestEventSubTypes.error, "Minimum image limit"));
        IngestLogging.getInstance().logEvent(minImageObject);
        ToastUtils.warning(IntlHandler.getInstance().formatMessage("min-image-limit", { min_count: minImageLimit }));
    }

    private _warningToastForMaxImageLimit(maxImageLimit: number | undefined): void {
        //GLIA_REVISIT: vib - Check ingest logging
        const maxImageObject = IngestUtils.addWorkspaceDetail(WorkspacesName.edit, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.imageEdit,
            IngestEventTypes.create, IngestEventSubTypes.error, "Maximum image limit"));
        IngestLogging.getInstance().logEvent(maxImageObject);
        ToastUtils.warning(IntlHandler.getInstance().formatMessage("max-image-limit", { max_count: maxImageLimit }));
    }

    private _showErrorMessageForQuotaExceeded(): void {
        const message = IntlHandler.getInstance().formatMessage("insufficient-storage-adjustments");
        ToastUtils.error(message);
    }

    async startImageEditWorkflow(selectedAssets: SelectedMediaListType): Promise<boolean> {
        const quotaExceeded = AssetStorageUtils.hasQuotaExceeded();
        if (quotaExceeded) {
            //GLIA_REVISIT: vib - Check ingest logging
            const quotaExceededObject = IngestUtils.addWorkspaceDetail(WorkspacesName.edit, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.imageEdit,
                IngestEventTypes.create, IngestEventSubTypes.error, "Quota storage exceeded"));
            IngestLogging.getInstance().logEvent(quotaExceededObject);
            this._showErrorMessageForQuotaExceeded();
            return false;
        }

        const mediaGridConfig = ImageEditWorkflowUtils.getMediaGridConfig();

        if (!ImageEditWorkflowUtils.canStartWorkflow(selectedAssets)) {
            this._warningToastForMinImageLimit(mediaGridConfig.minImageCount);
            return false;
        }

        if (ImageEditWorkflowUtils.isMaxImageLimitExceeded(selectedAssets)) {
            this._warningToastForMaxImageLimit(mediaGridConfig.maxImageCount);
            return false;
        }

        const filteredAssets = await ImageEditWorkflowUtils.getFilteredAssetList(selectedAssets, mediaGridConfig);

        if (!ImageEditWorkflowUtils.canStartWorkflow(filteredAssets)) {
            //GLIA_REVISIT: vib - Check ingest logging and toast message
            const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.edit, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.imageEdit,
                IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
            IngestLogging.getInstance().logEvent(unsupportedMediaObject);
            ToastUtils.warning(IntlHandler.getInstance().formatMessage("select-supported-file-format"));
            return false;
        } else {
            const hasSkippedMedia = selectedAssets.length !== filteredAssets.length;
            if (hasSkippedMedia) {
                const unsupportedMediaObject = IngestUtils.addWorkspaceDetail(WorkspacesName.edit, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.imageEdit,
                    IngestEventTypes.create, IngestEventSubTypes.error, "Unsupported file formats"));
                IngestLogging.getInstance().logEvent(unsupportedMediaObject);
                ToastUtils.info(IntlHandler.getInstance().formatMessage("media-selection-skipped"));
            }
        }
        store.dispatch(SelectedMediaListAction.updateSelectedMediaList([]));
        AppRouteUtil.changeRoute(ImageEditWorkflowUtils.getHistoryState(filteredAssets));
        return true;
    }

}