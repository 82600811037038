/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import loadImage from "blueimp-load-image";
import ImageUtils from "./ImageUtils";
import Logger, { LogLevel } from "./Logger";

class MetaDataUtils {
    private static _instance: MetaDataUtils | null = null;

    static getInstance(): MetaDataUtils {
        if (MetaDataUtils._instance === null)
            MetaDataUtils._instance = new MetaDataUtils();
        return MetaDataUtils._instance;
    }
    
    private async _getCaptureDateFromImage (file: File): Promise<string> {
        return new Promise((resolve) => loadImage.parseMetaData(
            file,
            function (data) {
                Logger.log(LogLevel.INFO, "Image Exif data: ", data.exif); // requires exif extension
                    
                let captureDate: string = (data.exif?.get('Exif') as any)?.get('DateTimeOriginal');
                if (captureDate) {
                    captureDate = captureDate.replace(':', '-').replace(':', '-');
                }
                return resolve(captureDate);
            },
            {
                disableImageHead: true
            }
        ));
    }

    private async _getCaptureDateFromVideo (file: File): Promise<string> {
        const { getInfo } = await import("react-mediainfo");
        const fileInfo = await getInfo(file);
        Logger.log(LogLevel.INFO, "Metadata of video file", fileInfo);

        const captureDate = fileInfo?.media.track[0]?.Encoded_Date;
        return captureDate;
    }

    async getCaptureDateForFile (file: File): Promise<string> {
        let captureDate: string;
        if (ImageUtils.isImageFile(file))
            captureDate = await this._getCaptureDateFromImage(file);
        else if (ImageUtils.isVideoFile(file))
            captureDate = await this._getCaptureDateFromVideo(file);
        else
            captureDate = (new Date()).toDateString();
        return captureDate;
    }
}

export { MetaDataUtils };
