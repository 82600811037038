/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { IconType } from "../../../../assets/IconConstants";
import { ITabPanelButtonInfo, ELTabPanelKey, TabPanelButtonTypes } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

export const ELEditPanelItemProvider = (): ITabPanelButtonInfo[] => {
    const intlHandler = IntlHandler.getInstance();

    const listOfTabButtonItems: ITabPanelButtonInfo[] = [
        {
            key: ELTabPanelKey.media,
            buttonInfo: {
                iconType: IconType.mediaManagerTab,
                name: intlHandler.formatMessage("media"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            }
        },
        {
            key: ELTabPanelKey.looks,
            buttonInfo: {
                iconType: IconType.looksIcon,
                name: intlHandler.formatMessage("looks"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            }
        }
    ];

    return listOfTabButtonItems;
}