/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    ContentType,
    CreationType,
    ExportConfigurationType,
    ExportOperationTypeInfo,
    ExportPreset,
    ExportType,
    FileMimeType,
    MovingOverlayConfigurationInfoType,
    MovingOverlayCreationOperationSubTypeConfiguration,
    MovingOverlayCreationOperationSubTypeConfigurationInfo,
    MovingOverlayCreationOperationSubTypeInfo,
    MovingOverlayCreationOperationTemplate,
    OperationContentType,
    OutputType,
    RenderQuality,
    Resolution,
    RootFolderName,
    SequenceConfigurationInfoType,
    SequenceExportOperationSubTypeConfiguration,
    SequenceExportOperationSubTypeInfo,
    StorageType,
    Utils
} from "@elements/elementswebcommon";

//Application Specific
import { ELMovingOverlayCreationRequestParams } from "../../../../../common/interfaces/creations/ELMovingOverlayTypes";
import { CreationsJobCreator } from "../../../utils/CreationsJobCreator";

const DEFAULT_MOVING_OVERLAY_PREVIEW_FILE_NAME = "Preview.jpg";
const DEFAULT_MOVING_OVERLAY_FILE_NAME = "MovingOverlay.mp4";

const DEFAULT_RESOLUTION = Resolution._720p;
const SEQUENCE_START_TIME_MO = 0;
const SEQUENCE_END_TIME_MO = 6000;

export class MovingOverlayJobCreator extends CreationsJobCreator {
    protected getCreationType(): CreationType {
        return CreationType.movingOverlay;
    }

    private _getDefaultConfigurationInfo(overlayId: string, contentResolution: Resolution, audioId: string): MovingOverlayConfigurationInfoType {
        const movingOverlayVideoTemplate = new MovingOverlayCreationOperationTemplate();
        movingOverlayVideoTemplate.operationContentData = {
            id: overlayId,
            contentType: ContentType.movingOverlay,
            mimetype: FileMimeType.eApplicationZip,
            resolution: contentResolution
        };

        const movingOverlayAudioTemplate = new MovingOverlayCreationOperationTemplate();
        movingOverlayAudioTemplate.operationContentData = {
            id: audioId,
            contentType: ContentType.audio,
            mimetype: FileMimeType.eMp3Audio,
            resolution: Resolution._4000p
        };

        const configurationInfo: MovingOverlayConfigurationInfoType = {
            templates: {
                video: movingOverlayVideoTemplate,
                audio: movingOverlayAudioTemplate,
            }
        };

        return configurationInfo;
    }

    private _createConfigurationInfoForOverlayId(overlayId: string, contentResolution: Resolution, audioId: string): MovingOverlayCreationOperationSubTypeConfigurationInfo {
        const configInfoObj = new MovingOverlayCreationOperationSubTypeConfigurationInfo();
        configInfoObj.configurationInfo = this._getDefaultConfigurationInfo(overlayId, contentResolution, audioId);
        return configInfoObj;
    }

    private _createConfigurationsForOverlayIds(overlayIdAudioIdMap: Map<string, string>, contentResolution: Resolution): MovingOverlayCreationOperationSubTypeConfiguration[] {
        const configurations: MovingOverlayCreationOperationSubTypeConfiguration[] = [];
        for (const [overlayId, audioId] of overlayIdAudioIdMap) {
            const randomUniqueId = Utils.generateRandomGUID();
            const singleConfigObj = new MovingOverlayCreationOperationSubTypeConfiguration();
            singleConfigObj.version = this.configurationVersion;
            singleConfigObj.id = randomUniqueId;
            singleConfigObj.representativeFolderPath = randomUniqueId;

            const configurationInfoObj = this._createConfigurationInfoForOverlayId(overlayId, contentResolution, audioId);
            singleConfigObj.configurationInfo = configurationInfoObj;
            configurations.push(singleConfigObj);
        }
        return configurations;
    }

    private _createThumbExportConfigurationType(folderPath: string): ExportConfigurationType {
        const exportOperationConfiguration: ExportConfigurationType = {
            tag: OutputType.preview,
            root: RootFolderName.cloudContent,
            fileName: DEFAULT_MOVING_OVERLAY_PREVIEW_FILE_NAME,
            storageType: StorageType.RAPI,
            representativeFolderPath: folderPath
        };
        return exportOperationConfiguration;
    }

    private _createVideoExportConfigurationType(folderPath: string): ExportConfigurationType {
        const exportOperationConfiguration: ExportConfigurationType = {
            tag: OutputType.full,
            root: RootFolderName.cloudContent,
            fileName: DEFAULT_MOVING_OVERLAY_FILE_NAME,
            storageType: StorageType.RAPI,
            representativeFolderPath: folderPath
        };
        return exportOperationConfiguration;
    }

    private _createThumbExportPreset(): ExportPreset {
        const operationContentType: OperationContentType = {
            id: "1F4B4D52-B154-4E74-B8C7-1AB1JKCA35AG", // EPR file id for preview output shared by CoreEngine
            contentType: ContentType.videoOutputPreset,
            mimetype: FileMimeType.eApplicationEpr,
            resolution: DEFAULT_RESOLUTION
        };
        const preset: ExportPreset = this._getExportPreset(operationContentType);
        return preset;
    }

    private _createVideoExportPreset(): ExportPreset {
        const operationContentType: OperationContentType = {
            id: "1F4B4D52-B154-4E74-B8C7-1AB1JKCA35AR", // EPR file id for fullres output shared by CoreEngine
            contentType: ContentType.videoOutputPreset,
            mimetype: FileMimeType.eApplicationEpr,
            resolution: DEFAULT_RESOLUTION
        };
        const preset: ExportPreset = this._getExportPreset(operationContentType);
        return preset;
    }

    private _createVideoExportOperationConfiguration(): SequenceExportOperationSubTypeConfiguration {
        const randomUniqueId = Utils.generateRandomGUID();
        const exportOperationConfiguration = this._createVideoExportConfigurationType(randomUniqueId);

        const sequenceConfigPayloadForVideo: SequenceConfigurationInfoType = {
            preset: this._createVideoExportPreset(),
            hardwareAccelerated: true,
            startTime: SEQUENCE_START_TIME_MO,
            endTime: SEQUENCE_END_TIME_MO,
            renderQuality: RenderQuality.draft
        };

        const sequenceConfigurationInfoType = this._getSequenceConfigurationInfoType(sequenceConfigPayloadForVideo);
        const exportOperationConfigurationObj = this._getSequenceExportOperationSubTypeConfiguration(exportOperationConfiguration, sequenceConfigurationInfoType);
        exportOperationConfigurationObj.id = randomUniqueId;
        return exportOperationConfigurationObj;
    }

    private _createThumbExportOperationConfiguration(): SequenceExportOperationSubTypeConfiguration {
        const randomUniqueId = Utils.generateRandomGUID();
        const exportOperationConfiguration = this._createThumbExportConfigurationType(randomUniqueId);

        const sequenceConfigPayloadForThumb: SequenceConfigurationInfoType = {
            preset: this._createThumbExportPreset(),
            hardwareAccelerated: true,
            startTime: 2000,
            endTime: 360000000,
            renderQuality: RenderQuality.draft
        };
        const sequenceConfigurationInfoType = this._getSequenceConfigurationInfoType(sequenceConfigPayloadForThumb);
        const exportOperationConfigurationObj = this._getSequenceExportOperationSubTypeConfiguration(exportOperationConfiguration, sequenceConfigurationInfoType);
        exportOperationConfigurationObj.id = randomUniqueId;
        return exportOperationConfigurationObj;
    }

    private _createExportOperationSubTypeInfo(outputTypes: OutputType[]): any | undefined {
        const exportOperationSubTypeInfo = new SequenceExportOperationSubTypeInfo();
        exportOperationSubTypeInfo.configurations = [
            this._createVideoExportOperationConfiguration(),
            this._createThumbExportOperationConfiguration()
        ];
        return exportOperationSubTypeInfo;
    }

    protected createOperationSubTypeInfo(requestParameters: ELMovingOverlayCreationRequestParams): MovingOverlayCreationOperationSubTypeInfo {
        const operationSubTypeInfo = new MovingOverlayCreationOperationSubTypeInfo();
        operationSubTypeInfo.version = this.operationSubTypeInfoVersion;
        operationSubTypeInfo.configurations = this._createConfigurationsForOverlayIds(requestParameters.overlayIdAudioIdMap, requestParameters.contentResolution);
        operationSubTypeInfo.compositeSettings = this.createOperationSubTypeInfoCompositeSettings(requestParameters.compositeSettings);
        return operationSubTypeInfo;
    }

    protected createExportOperationTypeInfo(requestParameters: ELMovingOverlayCreationRequestParams): ExportOperationTypeInfo {
        const exportOperationTypeInfo = new ExportOperationTypeInfo();
        exportOperationTypeInfo.operationSubType = ExportType.sequence;
        exportOperationTypeInfo.operationSubTypeInfo = this._createExportOperationSubTypeInfo(requestParameters.outputTypes);
        return exportOperationTypeInfo;
    }
}