/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { API } from "@elements/elementswebcommon";

export enum ContentType {
    slideshowTemplate = "SLIDESHOW_TEMPLATE",
    slideshowAudio = "SLIDESHOW_AUDIO",
    collageTemplates = "COLLAGE_TEMPLATES",
    collageBackground = "COLLAGE_BACKGROUND",
    collageDefaultBackground = "COLLAGE_DEFAULT_BACKGROUND"
}

export default abstract class BaseContentService {
    protected _client!: API;

    abstract getContent(contentType: ContentType): Promise<unknown>
}
