/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import { useEffect, useState } from "react";

//Application Specific
import { ELDynamicLayoutProps, ELDynamicLayoutConfig } from "../../common/interfaces/hooks/DynamicLayoutTypes";
import Utils from "../Utils";

export const useDynamicLayout = (props: ELDynamicLayoutProps): ELDynamicLayoutConfig => {
    const [viewLayoutConfig, setViewLayoutConfig] = useState(props.specLayoutConfig);

    const updateLayoutConfig = (): void => {
        window.requestAnimationFrame(() => {
            const renderFactor = Utils.getRenderedFactor();
            if (props.gridRef.current) {
                const gridDiv = (props.gridRef.current as HTMLDivElement);
                const gridWidth = gridDiv.getBoundingClientRect().width;
                const layoutConfig = {
                    TILE_W: props.specLayoutConfig.TILE_W * renderFactor,
                    TILE_H: props.specLayoutConfig.TILE_H * renderFactor,
                    X_DIST_BETWEEN_TILE: props.specLayoutConfig.DIST_BETWEEN_TILE * renderFactor
                };
                const { width, height } = Utils.getBestFitTileInGrid(layoutConfig, gridWidth);
                const updatedLayoutConfig = {
                    TILE_W: width,
                    TILE_H: height,
                    DIST_BETWEEN_TILE: props.specLayoutConfig.DIST_BETWEEN_TILE * renderFactor
                }
                setViewLayoutConfig(updatedLayoutConfig);
            }
        });
    }

    const contentBoxResizeObserver = new ResizeObserver(updateLayoutConfig);

    useEffect(() => {
        if (props.gridRef.current) {
            if (props.observerRef.current) {
                contentBoxResizeObserver.observe(props.observerRef.current);
            }
        }
        return () => {
            if (props.observerRef.current) {
                contentBoxResizeObserver.unobserve(props.observerRef.current);
            }
        }
    }, [props.gridRef.current]);

    return viewLayoutConfig;
}
