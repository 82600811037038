/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { RenditionData } from "../storage/AssetTypes"
import { CreationsStatus } from "./CreationTypes"
import { RenditionType } from "@dcx/assets";

export enum CreationPreviewActions {
    creationStatus = "CREATION_STATUS",
    creationPreviewData = "CREATION_PREVIEW_DATA",
    backCreationView = "BACK_CREATION_VIEW",
    fetchRendition = "FETCH_RENDITION",
    renditionStatus = "RENDITION_STATUS"
}

export interface ELCreationPreviewState {
    id: string,
    status: CreationsStatus,
    renditionType: RenditionType,
    renditionData: RenditionData,
    isRenditionFailed: boolean
}