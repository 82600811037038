/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactElement, useRef } from "react";

//Adobe Internal
import { useButton } from "@react-aria/button";
import { Flex } from "@adobe/react-spectrum";
import { StyleProps } from '@react-types/shared';
import { useStyleProps } from "@react-spectrum/utils";

import "./ELActionButton.scss";

export type ELActionButtonVariant = "primary" | "secondary" | "light";

export interface ELActionButtonProps extends StyleProps {
    variant: ELActionButtonVariant,
    elIcon: ReactElement,
    onPress: () => void,
    dataTestId?: string,
    text?: string,
    tooltip?: string,
    transition?: "none" | "smooth"
}

/*
    These are buttons which would be usually used inside a workflow. Similar to the philosophy of React spectrum Action Button
*/

const ELActionButton = (props: ELActionButtonProps): React.ReactElement => {
    const {
        elIcon,
        text,
        onPress,
        ...otherProps
    } = props;
    const { styleProps } = useStyleProps(otherProps);

    const ref = useRef(null);
    const { buttonProps } = useButton({
        onPress: props.onPress,
    }, ref);

    const getVariantClass = (): string => {
        if (props.variant === "primary") {
            return "el-action-button-primary";
        } else if (props.variant === "secondary") {
            return "el-action-button-secondary";
        } else if (props.variant === "light") {
            return "el-action-button-light";
        } else { //return primary
            return "el-action-button-primary";
        }
    }

    return (
        <Flex direction="column" alignItems="center" UNSAFE_className={props.transition === "smooth" ? "el-action-button-box" : ""}
            isHidden={styleProps.hidden} UNSAFE_style={styleProps.style}>
            <button ref={ref} {...buttonProps} style={{ width: styleProps.style?.width, height: styleProps.style?.height }} title={props.tooltip}
                className={"el-action-button " + getVariantClass()} data-testid={props.dataTestId}>
                {props.elIcon}
            </button>
            <span className={`el-action-button__action-text`}>{props.text}</span>
        </Flex>
    )
}

ELActionButton.defaultProps = {
    variant: "primary",
    transition: "none"
}

export default ELActionButton;