/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { useEffect, useReducer } from "react";

//Adobe Internal
import { Flex } from "@adobe/react-spectrum";

//Application specific
import ImageEditWorkflow from "./ImageEditWorkflow";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { ViewAction } from "../../../view/IBaseController";
import { ELImageEditWorkflowControllerAction, ELImageEditWorkflowViewAction } from "../../../common/interfaces/editing/editWorkspace/ELImageEditWorkflowTypes";
import { ELCreationProgressView } from "../../../view/components/organism/el-creation-progress-view/ELCreationProgressView";
import { ELReactSpectrumV3Provider } from "../../../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { ViewportProvider } from "../../../utils/hooks/useViewport";
import { ReplaceMediaDialogView } from "../common/ReplaceMediaDialogView";

import "./ImageEditWorkflowView.scss";

interface IEditWorkflowProps {
    controller: ImageEditWorkflow
}

interface ImageEditWorkflowState {
    showProgress: boolean,
    progressText: string,
    showReplaceMediaDialog: boolean
}

const getInitialState = (): ImageEditWorkflowState => {
    return {
        showProgress: true,
        progressText: IntlHandler.getInstance().formatMessage("generating-output"),
        showReplaceMediaDialog: false
    }
}

const ImageEditWorkflowView = (props: IEditWorkflowProps): React.ReactElement => {

    const reducer = (state: ImageEditWorkflowState, action: ViewAction): ImageEditWorkflowState => {
        switch (action.type) {
            case ELImageEditWorkflowViewAction.showProgress: {
                const showProgress = action.payload as boolean;
                return {
                    ...state,
                    showProgress: showProgress
                };
            }
            case ELImageEditWorkflowViewAction.progressText: {
                const progressText = action.payload as string;
                return {
                    ...state,
                    progressText: progressText
                };
            }
            case ELImageEditWorkflowViewAction.updateReplaceMediaDialogState: {
                const showReplaceMediaDialog = action.payload as boolean;
                return {
                    ...state,
                    showReplaceMediaDialog: showReplaceMediaDialog
                }
            }
            default:
                return state;
        }
    }

    const [state, viewDispatch] = useReducer(reducer, getInitialState());

    const showProgress = (): boolean => {
        return state.showProgress;
    }

    const onDismiss = (): void => {
        props.controller.notify({
            type: ELImageEditWorkflowControllerAction.onDismissReplaceMediaDialog
        })
    }

    const onDontSave = (): void => {
        props.controller.notify({
            type: ELImageEditWorkflowControllerAction.onDontSaveReplaceMediaDialog
        })
    }

    const onSaveAs = (): void => {
        props.controller.notify({
            type: ELImageEditWorkflowControllerAction.onSaveAsReplaceMediaDialog
        })
    }


    useEffect(() => {
        props.controller.initialize(viewDispatch);

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <ELReactSpectrumV3Provider>
            <ViewportProvider>
                <div id="edit-workflow-container">
                    <div id="edit-workflow__header-container" />
                    <Flex direction="column">
                        <div className="edit-workflow__main-content-box">
                            <div id="edit-workflow__doc-container" />
                            <div id="edit-workflow__right-panel-container" />
                            <div id="edit-workflow__dialog-container" />
                            {showProgress()
                                //GLIA_REVISIT - vib - Might need to rename ELCreationProgressView
                                && <ELCreationProgressView progressText={state.progressText} mode="parent" />}
                            {state.showReplaceMediaDialog &&
                                <ReplaceMediaDialogView onDismiss={onDismiss} onSaveAs={onSaveAs} onDontSave={onDontSave} />
                            }
                        </div>
                    </Flex>
                </div >
            </ViewportProvider>
        </ELReactSpectrumV3Provider>
    )
}

export default ImageEditWorkflowView;