/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    ContentType,
    CreationType,
    ExportOperationTypeInfo,
    ExportType,
    OutputType,
    Resolution,
    ImageFormat,
    CreationCompositeType,
    MagicalBackdropCreationOperationTemplate,
    MagicalBackdropCreationOperationSubTypeConfigurationInfo,
    MagicalBackdropCreationOperationSubTypeConfiguration,
    MagicalBackdropCreationOperationSubTypeInfo
} from "@elements/elementswebcommon";

//Application Specific
import { CreationsJobCreator } from "../../../utils/CreationsJobCreator";
import Utils from "../../../../../utils/Utils";
import { ELImageExportConfigurationParams } from "../../../../../common/interfaces/creations/ELCreationsJobTypes";
import { ELReplaceBackgroundCreationRequestParams } from "../../../../../common/interfaces/creations/ELReplaceBackgroundTypes";
import { ELStageLayerDataOptions } from "../../../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { TRANSPARENT_BACKGROUND_ID } from "../../../../../common/interfaces/creations/CreationTypes";

const defaultCreationFileName = "magicalBackdrop.jpg";
const defaultCreationPSDFileName = "magicalBackdrop.psd";
const defaultExportHeight = 720;
const defaultExportWidth = 1440;

export class MagicalBackdropJobCreator extends CreationsJobCreator {

    private _createTemplateInfoForBackgroundId(backgroundId: string, contentResolution: Resolution): MagicalBackdropCreationOperationTemplate {
        const template = new MagicalBackdropCreationOperationTemplate();
        template.operationContentData = {
            id: backgroundId,
            contentType: ContentType.replaceBackground,
            mimetype: "image/png",
            resolution: contentResolution
        };
        return template;
    }

    private _createConfigurationInfoForBackgroundId(backgroundId: string, contentResolution: Resolution): MagicalBackdropCreationOperationSubTypeConfigurationInfo {
        const configInfoObj = new MagicalBackdropCreationOperationSubTypeConfigurationInfo();
        if (backgroundId !== TRANSPARENT_BACKGROUND_ID) {
            configInfoObj.configurationInfo = {
                "template": this._createTemplateInfoForBackgroundId(backgroundId, contentResolution)
            };
        }
        return configInfoObj;
    }

    private _createConfigurationsForBackgroundIds(backgroundIds: string[], contentResolution: Resolution, layerDataOptionsList?: ELStageLayerDataOptions[]): MagicalBackdropCreationOperationSubTypeConfiguration[] {
        const configurations = backgroundIds.map(backgroundId => {

            const randomUniqueId = Utils.getRandomUUID();

            const singleConfigObj = new MagicalBackdropCreationOperationSubTypeConfiguration();
            singleConfigObj.version = this.configurationVersion;
            singleConfigObj.id = randomUniqueId;
            singleConfigObj.representativeFolderPath = randomUniqueId;
            if (layerDataOptionsList) {
                singleConfigObj.documentEdit = layerDataOptionsList;
            }

            const configurationInfoObj = this._createConfigurationInfoForBackgroundId(backgroundId, contentResolution);
            singleConfigObj.configurationInfo = configurationInfoObj;

            return singleConfigObj;
        });
        return configurations;
    }

    private _createImageExportOperationParams(outputTypes: OutputType[]): ELImageExportConfigurationParams[] {
        const imageExportOperationSubTypeConfigurationParams: ELImageExportConfigurationParams[] = [];

        outputTypes.forEach((outputType) => {
            const isOutputTypePreview = outputType === OutputType.preview;
            const fileName = isOutputTypePreview ? defaultCreationFileName : defaultCreationPSDFileName;
            const exportFormat = isOutputTypePreview ? ImageFormat.jpg : ImageFormat.psd;
            imageExportOperationSubTypeConfigurationParams.push({
                fileName: fileName,
                outputType: outputType,
                exportFormat: exportFormat,
                exportHeight: defaultExportHeight,
                exportWidth: defaultExportWidth
            });
        });

        return imageExportOperationSubTypeConfigurationParams;
    }

    protected createOperationSubTypeInfo(requestParameters: ELReplaceBackgroundCreationRequestParams): MagicalBackdropCreationOperationSubTypeInfo {
        const operationSubTypeInfo = new MagicalBackdropCreationOperationSubTypeInfo();
        operationSubTypeInfo.version = this.operationSubTypeInfoVersion;
        operationSubTypeInfo.configurations = this._createConfigurationsForBackgroundIds(requestParameters.backgroundId, requestParameters.contentResolution, requestParameters.layerDataOptionsList);
        operationSubTypeInfo.compositeType = CreationCompositeType.pieImageDocument;
        return operationSubTypeInfo;
    }

    protected createExportOperationTypeInfo(requestParameters: ELReplaceBackgroundCreationRequestParams): ExportOperationTypeInfo | undefined {
        const exportOperationTypeInfo = new ExportOperationTypeInfo();
        exportOperationTypeInfo.operationSubType = ExportType.image;
        exportOperationTypeInfo.operationSubTypeInfo = this.createImageExportOperationSubTypeInfo(this._createImageExportOperationParams(requestParameters.outputTypes));
        return exportOperationTypeInfo;
    }

    protected getCreationType(): CreationType {
        return CreationType.magicalBackdrop;
    }
}