/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { CreationWorkflowActions, CreationsStatus } from "../../../common/interfaces/creations/CreationTypes";
import { DocumentActions, DocumentDirty } from "../../../common/interfaces/document/DocumentTypes";
import { ELStageDocActions, ELStageDocAddLayerPayload, ELStageDocDataPayload, ELStageDocDeleteLayerPayload, ELStageDocPayload } from "../../../common/interfaces/document/ELStageDocTypes";
import { ControllerData } from "../../../common/interfaces/editing/ControllerTypes";
import { CSLayerEditInfo, ELLayerKind, ELStageLayerData, ELStageLayerDataOptions, CSLayerTextInfo } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import { RendererUpdateData } from "../../../common/interfaces/renderer/RendererTypes";
import { ELDownloadData } from "../../../common/interfaces/stage/StageTypes";
import { CanvasMode } from "../../../stores/actions/CanvasAction";
import DocActions from "../../../stores/actions/DocActions";
import store from "../../../stores/store";
import Logger, { LogLevel } from "../../../utils/Logger";
import Utils from "../../../utils/Utils";
import IDoc from "../../document/IDoc";
import ILayer from "../../document/layer/ILayer";
import ELStageLayer from "./ELStageLayer";

export default class ELStageDoc extends IDoc {
    async redraw(actionType: ELStageDocActions, payload: unknown): Promise<void> {
        try {
            const updateData: RendererUpdateData = { type: actionType, payload: payload };
            await this.renderer?.update(updateData);
        } catch (error) {
            Logger.log(LogLevel.WARN, "ELStageDoc: redraw: Error redrawing doc with action " + actionType + " " + error);
            return Promise.reject("Couldn't redraw doc!");
        }
    }

    async addLayer(data: ELStageLayerData, redraw = false): Promise<string> {
        try {
            const layer = await ELStageLayer.createLayer({ ...data, id: Utils.getRandomUUID() }, this);
            this.layers.push(layer);
            const layerId = layer.getId();
            if (redraw) {
                await this.redraw(ELStageDocActions.addLayer, layerId);
            }
            return Promise.resolve(layerId);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELStageDoc: addLayer: Error adding layer to document " + error);
            return Promise.reject("Couldn't add layer to document!");
        }
    }

    async addLayerByIndex(data: ELStageLayerData, layerIndex: number): Promise<string> {
        try {
            const layer = await ELStageLayer.createLayer({ ...data, id: Utils.getRandomUUID() }, this);
            this.layers.splice(layerIndex, 0, layer);

            const addData: RendererUpdateData = { type: ELStageDocActions.addLayerByIndex, payload: { layer, layerIndex } };
            await this.renderer?.update(addData);
            return Promise.resolve(layer.getId());
        } catch (error) {
            return Promise.reject("Couldn't add layer to document!");
        }
    }

    getLayerById(id: string): Promise<ILayer> {
        const layer = this.layers.filter((layer) => layer.getId() === id)[0];

        if (layer) {
            Logger.log(LogLevel.DEBUG, "ELStageDoc: getLayerById: Layer found with id " + id);
            return Promise.resolve(layer);
        }

        Logger.log(LogLevel.WARN, "ELStageDoc: getLayerById: Layer not found with id " + id);
        return Promise.reject(layer);
    }

    getLayerIdByName(name: string): string {
        const layer = this.layers.filter((layer) => {
            return (layer.getName() === name);
        })[0];

        if (layer) {
            Logger.log(LogLevel.DEBUG, `ELStageDoc: getLayerIdByName: Layer with name ${name} found`);
            return layer.getId();
        }

        Logger.log(LogLevel.WARN, `Layer with name ${name} not found`);
        throw new Error(`Layer not found`);
    }

    getTopLayer(): ILayer {
        return this.layers[this.layers.length - 1];
    }

    destroy(): void {
        this.renderer?.destroy();
        this.layers.forEach((layer) => { layer.destroy(); });

        super.destroy();
        this.close();
    }

    save(): void {
        throw new Error("Method not implemented.");
    }

    async render(container: HTMLElement): Promise<void> {
        try {
            await this.renderer?.render(container);
            window.requestAnimationFrame(() => { //let canvas drawing complete
                this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.success });
            });
        } catch (error) {
            this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.error });
        }
    }

    close(): Promise<void> {
        return Promise.resolve();
    }

    getOriginalSize(): Promise<ELSize> {
        return Promise.resolve(this.layers[0].getSize());
    }

    async getSize(): Promise<ELSize> {
        const size = await this.getOriginalSize();
        if (this.layoutInfo && store.getState().canvasReducer.mode === CanvasMode.render) {
            size.width = (this.layoutInfo.right - this.layoutInfo.left) * this.layoutInfo.scaleX * size.width;
            size.height = (this.layoutInfo.bottom - this.layoutInfo.top) * this.layoutInfo.scaleY * size.height;
        }
        return size;
    }

    documentUpdated(payload: unknown): void {
        const layerEditInfoList = this.dataParser?.parseDocUpdatedData(payload) as CSLayerEditInfo[];
        layerEditInfoList.forEach((layerEditinfo, index) => {
            this.layers[index].setEditInfo(layerEditinfo);
        });
        const layerClipDataEditInfoList = this.dataParser?.parseDocUpdatedClipData(payload) as CSLayerEditInfo[];
        layerClipDataEditInfoList.forEach((layerEditinfo, index) => {
            this.layers[index].setClipLayerEditInfo(layerEditinfo);
        });
        this.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);
        Logger.log(LogLevel.INFO, "document dirty: ", this.isDirty);
    }

    async download(downloadData: ELDownloadData): Promise<void> {
        await this.downloader?.download(downloadData);
    }

    async getDataURL(): Promise<string> {
        if (this.downloader)
            return await this.downloader?.getDataURL();
        else {
            return Promise.reject("No downloader available for stage doc");
        }
    }

    async initialize<T>(payload?: T): Promise<boolean> {
        this.data = payload as unknown as ELStageDocPayload;
        this.layoutInfo = (this.data as ELStageDocPayload).layoutInfo;
        await this.addLayer(this.data as ELStageLayerData);
        return Promise.resolve(true);
    }

    getData(): unknown {
        return this.data;
    }

    private async _updateLayerData(payload: ELStageDocDataPayload): Promise<void> {
        try {
            const layer = await this.getLayerById(payload.layerId);
            const layerKind = layer.getLayerKind();

            switch (layerKind) {
                case ELLayerKind.pixel: {
                    layer.setData(payload.data as ImageData);
                    break;
                }
                case ELLayerKind.path: {
                    layer.setPath(payload.data as string);
                    break;
                }
                case ELLayerKind.text: {
                    layer.setText(payload.data as string);
                    break;
                }
            }

            const updateData: RendererUpdateData = { type: ELStageDocActions.updateLayerData, payload: payload };
            await this.renderer?.update(updateData);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async setLayerVisible(payload: ELStageDocDataPayload): Promise<void> {
        try {
            const layer = await this.getLayerById(payload.layerId);
            layer.setVisibility(payload.data as boolean);
            await this.renderer?.setLayerVisible(payload);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async setLayerFill(payload: ELStageDocDataPayload): Promise<void> {
        try {
            const layer = await this.getLayerById(payload.layerId);
            layer.setFill(payload.data as string);
            await this.renderer?.setLayerFill(payload);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async setLayerOpacity(payload: ELStageDocDataPayload): Promise<void> {
        try {
            const layer = await this.getLayerById(payload.layerId);
            layer.setOpacity(payload.data as number);
            await this.renderer?.setLayerOpacity(payload);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getLayerTextOptions(layerId: string): Promise<CSLayerTextInfo | undefined> {
        try {
            const layer = await this.getLayerById(layerId);
            return Promise.resolve(layer.getTextOptions());
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async setLayerTextOptions(payload: ELStageDocDataPayload): Promise<void> {
        try {
            const layer = await this.getLayerById(payload.layerId);
            layer.setTextOptions(payload.data as CSLayerTextInfo);
            await this.renderer?.setLayerTextOptions(payload);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getClipPathOptions(layerId: string): Promise<ELStageLayerDataOptions | undefined> {
        try {
            const layer = await this.getLayerById(layerId);
            return Promise.resolve(layer.getClipLayerOptions());
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async setClipPathOptions(payload: ELStageDocDataPayload): Promise<void> {
        try {
            const layer = await this.getLayerById(payload.layerId);
            layer.setClipLayerOptions(payload.data as ELStageLayerDataOptions);
            await this.renderer?.setClipPathOptions(payload);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async removeLayer(layerId: string, redraw?: boolean): Promise<void> {
        try {
            const layer = await this.getLayerById(layerId);
            this.layers = this.layers.filter((layer) => layer.getId() !== layerId);
            layer.destroy();

            if (redraw) {
                this.redraw(ELStageDocActions.removeLayer, layerId);
            }
            return Promise.resolve();
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELStageDoc: deleteLayer: Error deleting layer with id " + layerId + " " + error);
            return Promise.reject(error);
        }
    }

    async notify(action: ControllerData): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELStageDocActions.updateLayerData: {
                await this._updateLayerData(action.payload as ELStageDocDataPayload);
                handled = true;
                break;
            }
            case ELStageDocActions.removeLayer: {
                const { layerId, redraw } = action.payload as ELStageDocDeleteLayerPayload;
                await this.removeLayer(layerId, redraw);
                handled = true;
                break;
            }
            case ELStageDocActions.addLayer: {
                const { data, redraw } = action.payload as ELStageDocAddLayerPayload;
                await this.addLayer(data, redraw);
                handled = true;
                break;
            }
            case ELStageDocActions.linkLayers:
            case ELStageDocActions.updateTextProperty:
            case ELStageDocActions.updateActiveObject: {
                await this.renderer?.notify(action);
                handled = true;
                break;
            }
            case DocumentActions.documentUpdated: {
                this.documentUpdated(action.payload);
                handled = true;
                break;
            }
            case DocumentActions.download: {
                const downloadData = action.payload as ELDownloadData;
                await this.download(downloadData);
                handled = true;
                break;
            }
            case DocumentActions.objectMoved:
            case DocumentActions.objectRotated:
            case DocumentActions.objectScaled:
            case DocumentActions.mouseDoubleClick:
            case DocumentActions.textEditingEntered:
            case DocumentActions.textEditingExited: {
                handled = await this.owner.notify(action);
                break;
            }
            case DocumentActions.zoomModified: {
                store.dispatch(DocActions.updateZoomLevel(action.payload as number));
                handled = true;
                break;
            }
        }

        if (!handled) {
            handled = await super.notify(action);
        }

        return handled;
    }

}