/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";
import ReactDOM from "react-dom";

//Application Specefic
import { IngestEventSubTypes, IngestEventTypes, IngestWorkflowTypes, INGEST_APP_WORKSPACE } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import Utils from "../../../../utils/Utils";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import { ViewAction } from "../../../IBaseController";
import ITemplateViewController from "../../../ITemplateViewController";
import { ControllerAction } from "../../../IViewController";
import { ELHelpMenuItems, ELHelpMenuNavView } from "./ELHelpMenuNavView";
import { URLConstants } from "../../../../utils/Constants/URLConstants";

class ELHelpMenuNav extends ITemplateViewController {
    createView(container: HTMLElement): void {
        super.createView(container);

        const helpMenuNav = React.createElement(ELHelpMenuNavView, {
            controller: this
        })

        ReactDOM.render(helpMenuNav, container);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELHelpMenuItems.faqs:
                {
                    const faqIngestObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utilityNav,
                        IngestEventTypes.faq, IngestEventSubTypes.click, true));
                    this._owner.notify({ type: WorkflowActionType.ingest, payload: faqIngestObject });
                    Utils.openInNewTab(URLConstants.GoURL.FAQ_HREF);
                    handled = true;
                    break;
                }
            case ELHelpMenuItems.supportCommunity:
                {
                    const supportCommunityIngestObject = IngestUtils.addWorkspaceDetail(INGEST_APP_WORKSPACE, IngestUtils.getPseudoLogObject(IngestWorkflowTypes.utilityNav,
                        IngestEventTypes.supportCommunity, IngestEventSubTypes.click, true));
                    this._owner.notify({ type: WorkflowActionType.ingest, payload: supportCommunityIngestObject });
                    Utils.openInNewTab(URLConstants.GoURL.SUPPORT_COMMUNITY_HREF);
                    handled = true;
                    break;
                }
        }

        if (!handled) {
            handled = await this._owner.notify(action);
        }

        return handled;
    }
}

export default ELHelpMenuNav;