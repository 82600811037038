/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import { ELCollageTemplateBackground } from "../../common/interfaces/creations/ELCollageTypes";
import CollageAction, { CollageActionBackgroundPayload, CollageActionPayload, CollageActionTemplatePayload } from "../actions/CollageAction";
import IAction from "../actions/IAction";

interface CollageReducerData {
    id?: string,
    category?: string,
    backgroundData?: ELCollageTemplateBackground,
    layout?: number,
    status: CreationsStatus
}

const updateTemplateData = (state: CollageReducerData, action: IAction<CollageActionPayload>): CollageReducerData => {
    if (action.payload) {
        const templatePayload = action.payload as CollageActionTemplatePayload;
        return {
            ...state,
            id: templatePayload.id,
            category: templatePayload.category,
            layout: templatePayload.layout,
            backgroundData: templatePayload.backgroundData
        }
    }
    return state;
}

const updateBackgroundData = (state: CollageReducerData, action: IAction<CollageActionPayload>): CollageReducerData => {
    if (action.payload) {
        const backgroundDataPayload = action.payload as CollageActionBackgroundPayload;
        return {
            ...state,
            backgroundData: backgroundDataPayload.backgroundData
        }
    }
    return state;
}

const updateStatus = (state: CollageReducerData, action: IAction<CollageActionPayload>): CollageReducerData => {
    if (action.payload) {
        return {
            ...state,
            status: action.payload as CreationsStatus
        }
    }
    return state;
}

const collageReducer = (state: CollageReducerData = { status: CreationsStatus.requested }, action: IAction<CollageActionPayload>): CollageReducerData => {
    switch (action.type) {
        case CollageAction.UPDATE_TEMPLATE_DATA:
            return updateTemplateData(state, action);
        case CollageAction.UPDATE_BACKGROUNDATA:
            return updateBackgroundData(state, action);
        case CollageAction.UPDATE_STATUS:
            return updateStatus(state, action);
        default:
            return state;
    }
}

export default collageReducer;
