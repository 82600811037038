/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { IconType } from "../../../../assets/IconConstants";
import { ELIcon } from "../../atoms/el-icon/ELIconView";

import ELActionButton from "../el-action-button/ELActionButton";
import ELIconButton from "../el-icon-button/ELIconButtonView";
import { Theme } from "../../../../utils/Theme";
import { FeaturesManager } from "../../../../modules/floodgate/Featuresmanager";
import { FeatureName } from "../../../../services/Floodgate/FloodgateConstants";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { PlatformUtils } from "../../../../utils/PlatformUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

import "./ELOpenInDesktopButton.scss";

interface ELOpenInDesktopButtonProps {
    onPress: (() => void),
    size: "S" | "M" | "L",
    tooltip?:string,
}

const ELOpenInDesktopButton = (props : ELOpenInDesktopButtonProps): React.ReactElement => {
    const openInDesktopIcon = (<ELIcon iconkey={IconType.openInDesktop} fill={"#fff"} className="el-action-button__icon" />);
    const intlHandler = IntlHandler.getInstance();
    const openInDesktopText = intlHandler.formatMessage("open-in-desktop");
    const { width, height } = useViewport();

    const showLargeButton = (): boolean => {
        if(props.size === "L")
            return true;
        return false;
    }

    const isOpenInDesktopFeatureSupported = (): boolean => {
        if (FeaturesManager.getInstance().IsFeatureActive(FeatureName.eOpenInDesktop)
            && !PlatformUtils.isAHandheldDevice((width as number), (height as number)))
            return true;
        return false;
    }

    const getOpenInDesktopButtonAndContainer = (): React.ReactElement => {
        const largeButton = showLargeButton() ?
            <div className="el-open-in-desktop-button">
                <ELIconButton size="L" iconkey={IconType.openInDesktop} iconColor={Theme.dark.gray_controls_color_N}
                    iconHoverColor={Theme.dark.gray_controls_color_N} iconWidth={"1.375rem"} iconHeight={"1.375rem"}
                    onClick={() => { props.onPress(); }} dataTestId="open-in-desktop" >
                    <span className="workflow-header__cta-text">{openInDesktopText}</span>
                </ELIconButton>
            </div> :
            <ELActionButton tooltip={openInDesktopText}
                variant="secondary" elIcon={openInDesktopIcon} onPress={() => { props.onPress(); }} dataTestId="open-in-desktop" />;

        return isOpenInDesktopFeatureSupported() ?
            <ELReactSpectrumV3Provider key="OpenInDesktop">{largeButton}</ELReactSpectrumV3Provider>
            : <></>;
    }

    return (<>{getOpenInDesktopButtonAndContainer()}</>)
}

export default ELOpenInDesktopButton;