/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useState, useEffect, useRef } from 'react';
import { StyleProps, PressEvent } from '@react-types/shared';

//Application Specific
import ELArrow from '../../atoms/el-arrow/ELArrow';
import ELScrollPanel from '../../atoms/el-scroll-panel/ELScrollPanel';
import { useViewport } from '../../../../utils/hooks/useViewport';

import Logger, { LogLevel } from '../../../../utils/Logger';

import "./ELCarousel.scss";

export interface ELCarouselProps {
    variant: "independent" | "popover",
    children?: React.ReactNode
}

const POPOVER_PADDING = 2.2;
const INDEPENDENT_PADDING = 1.5;

const ELCarousel = (props: ELCarouselProps): React.ReactElement => {
    const { width, height } = useViewport();
    const scrollPanel = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [carouselBoxDomRect, setCarouselBoxDomRect] = useState<DOMRect>({} as DOMRect);
    const carouselBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        updateArrowState();
    }, [width, height]);

    useEffect(() => {
        if (carouselBoxRef.current) {
            setCarouselBoxDomRect((carouselBoxRef.current as HTMLDivElement).getBoundingClientRect());
        }
    }, [carouselBoxRef, width, height])

    const updateArrowState = (): void => {
        const rightArrowState = Math.floor((scrollPanel.current as HTMLElement).getBoundingClientRect().right) < Math.floor((scrollPanel.current?.lastChild as HTMLElement).getBoundingClientRect().right);
        setShowRightArrow(rightArrowState);

        const leftArrowState = Math.floor((scrollPanel.current as HTMLElement).getBoundingClientRect().left) > Math.floor((scrollPanel.current?.firstChild as HTMLElement).getBoundingClientRect().left);
        setShowLeftArrow(leftArrowState as boolean);
    }

    const lastNonVisibleChildIndex = (): number => {
        const children = scrollPanel.current?.children;

        if (!children)
            return 0;

        let lastIndex = (children.length - 1);

        for (const [index, child] of Array.from(children).reverse().entries()) {
            const scrollPanelRect = (scrollPanel.current as HTMLElement).getBoundingClientRect();
            const childRect = (child as HTMLElement).getBoundingClientRect();
            Logger.log(LogLevel.INFO, Math.floor(childRect.right), Math.floor(scrollPanelRect.right));
            if (Math.floor(childRect.right) >= Math.floor(scrollPanelRect.right)) {
                lastIndex = (children.length - 1 - index);
            } else {
                break;
            }
        }


        return lastIndex;
    }

    const firstNonVisibleChildIndex = (): number => {
        const children = scrollPanel.current?.children;

        if (!children)
            return 0;

        let firstIndex = 0;

        for (const [index, child] of Array.from(children).entries()) {
            const scrollPanelRect = (scrollPanel.current as HTMLElement).getBoundingClientRect();
            const childRect = (child as HTMLElement).getBoundingClientRect();
            if (childRect.right < scrollPanelRect.left) {
                firstIndex = index;
            } else {
                break;
            }
        }


        return firstIndex;
    }

    const scrollCarousel = (direction: "left" | "right"): void => {
        if (direction === "left") {
            scrollPanel.current?.children.item(firstNonVisibleChildIndex())?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
        } else if (direction === "right") {
            scrollPanel.current?.children.item(lastNonVisibleChildIndex())?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' })
        }
    }

    const handleLeftArrowClick = (e: PressEvent): void => {
        scrollCarousel("left");
    }

    const handleRightArrowClick = (e: PressEvent): void => {
        scrollCarousel("right");
    }

    const getLeftArrowPosition = (): StyleProps => {
        if (props.variant === "independent") {
            return {
                top: carouselBoxDomRect.top / INDEPENDENT_PADDING,
                left: 0
            }
        } else {
            return {
                top: carouselBoxDomRect.height / POPOVER_PADDING,
                left: 0
            }
        }
    }

    const getRightArrowPosition = (): StyleProps => {
        if (props.variant === "independent") {
            return {
                top: carouselBoxDomRect.top / INDEPENDENT_PADDING,
                right: 0
            }
        } else {
            return {
                top: carouselBoxDomRect.height / POPOVER_PADDING,
                right: "1rem"
            }
        }
    }

    return (
        <div className="carousel-contentaier">
            <ELArrow direction="left" {...getLeftArrowPosition() as StyleProps} isVisible={showLeftArrow} onPress={handleLeftArrowClick} />
            <div className="carousel-content-box" ref={carouselBoxRef}>
                <ELScrollPanel forwardedRef={scrollPanel} UNSAFE_className="carousel-content-box__scroller"
                    onScroll={() => updateArrowState()} scrollX={true} scrollY={false}>
                    {props.children}
                </ELScrollPanel>
            </div>
            <ELArrow direction="right" {...getRightArrowPosition() as StyleProps} onPress={handleRightArrowClick} isVisible={showRightArrow} />
        </div>
    )
}

export default ELCarousel;
