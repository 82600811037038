/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';

export interface ThumbDataPayload {
    assetId: string,
    objectURL: string
}

export default class MediaThumbAction {

    public static readonly UPDATE_THUMB: string = 'MediaThumbAction.UPDATE_THUMB';
    public static readonly CLEAR_ALL_DATA: string = 'MediaThumbAction.CLEAR_DATA';

    public static udpateThumb(thumbData: ThumbDataPayload): IAction<ThumbDataPayload> {
        return {
            type: MediaThumbAction.UPDATE_THUMB,
            payload: thumbData
        };
    }

    public static clearAllData(): IAction<ThumbDataPayload> {
        return {
            type: MediaThumbAction.CLEAR_ALL_DATA
        };
    }
}
