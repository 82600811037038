/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";

//Application Specific
import { LinkPreview } from "./LinkPreview";
import { ELNavbarContainerView } from "../common/component/ELNavbarContainerView";

import "./LinkPreview.scss";

export interface ILinkPreviewProps {
    controller: LinkPreview
}

export const LinkPreviewApp = (props: ILinkPreviewProps): React.ReactElement => {
    useEffect(() => {
        // If this app has dispatch please send it from here
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <>
            <ELNavbarContainerView />
            <div id="link-preview-app-container" />
            <div id="link-preview-modal-app-container" />
        </>
    )
};

