/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useState } from "react";

//Adobe internal
import { Text, Flex, TextField, TextArea, Checkbox } from "@adobe/react-spectrum";
import { KeyboardEvent } from "@react-types/shared";

//Utils
import Utils from "../../../../../utils/Utils";
import KeyEventUtils from "../../../../../utils/KeyEventUtils";

//Application Specific
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import ELButton from "../../../../../view/components/atoms/el-button/ELButtonView";
import IViewController from "../../../../../view/IViewController";

import "./EmailShareView.scss";

interface EmailShareViewProps {
    controller: IViewController
}

export enum EmailControllerAction {
    sendEmailPressed = "SEND_EMAIL_PRESSED"
}

export type SendEmailParamsType = {
    receiptentList: string,
    message: string,
    sendCopyValue: boolean
}

const MESSAGE_CHARACTER_LIMIT = 500;
const CHARACTERS_REMAININING_STARTING_LIMIT = 10;

export const EmailShareView = (props: EmailShareViewProps): React.ReactElement => {

    const [optMessage, setOptMessage] = useState("");
    const [sendCopyVal, setSendCopyVal] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const onSendEmailClicked = (): void => {
        props.controller.notify({ type: EmailControllerAction.sendEmailPressed, payload: { receiptentList: email, message: optMessage, sendCopyValue: sendCopyVal } });
    }

    const onKeyDown = (e: KeyboardEvent): void => {
        if(KeyEventUtils.isComposingEscape(e.nativeEvent)) {
            e.stopPropagation();
        } else {
            e.continuePropagation();
        }
    }

    const handleTextAreaValueChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setOptMessage(e.target.value);
    }

    const handleSendCopyCheckBoxValChanged = (isSelected: boolean): void => {
        setSendCopyVal(isSelected);
    }

    const getCharactersRemaining = (): number => {
        return MESSAGE_CHARACTER_LIMIT - optMessage.length;
    }

    const intlHandler = IntlHandler.getInstance();

    return (
        <div className="email-dialog-section">
            <Flex direction="column" alignItems="start" gap="size-300">
                <div className={"email-text-Area"}>
                    <div className={"email-text-field__label"}>{intlHandler.formatMessage("email-textField-label")}</div>
                    <TextField placeholder={intlHandler.formatMessage("enter-email")}
                        isRequired={true}
                        value={email}
                        onChange={setEmail} width="95%" />
                    <div className={"email-text-field__description"}>{intlHandler.formatMessage("email-textField-description")}</div>
                </div>
                <div className={"email-text-Area"}>
                    <div className={"email-text-field__label"}>{intlHandler.formatMessage("email-textArea-label")}</div>
                    <TextArea onInput={handleTextAreaValueChanged} placeholder={intlHandler.formatMessage("email-textArea-placeholder-mssg")} width="95%" height="110px" id="email-text-field__msg" maxLength={MESSAGE_CHARACTER_LIMIT} onKeyDown={onKeyDown}/>
                    { getCharactersRemaining() <= CHARACTERS_REMAININING_STARTING_LIMIT ? <div className={"email-text-field__characters-limit"}>{intlHandler.formatMessage("characters-remaining", { count: getCharactersRemaining() })}</div> : <></> }
                </div>
            </Flex>
            <Flex direction="row" justifyContent="right" marginTop="size-100" marginEnd="5%">
                <Checkbox onChange={handleSendCopyCheckBoxValChanged} isDisabled={!Utils.isValidEmail(email)}>{intlHandler.formatMessage("send-copy")}</Checkbox>
                <ELButton variant="cta" onClick={onSendEmailClicked} isDisabled={!Utils.isValidEmail(email)}>
                    <Text>{intlHandler.formatMessage("send-email")}</Text>
                </ELButton>
            </Flex>
        </div>

    )
}