/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../../IWorkflow";
import { ViewAction } from "../../../../../view/IBaseController";
import { LinkShareView, LinkShareControllerAction } from "./LinkShareView";
import { ShareOptions } from "../../../../../view/components/organism/el-share-options/ELShareOptions";
import { ControllerAction } from "../../../../../view/IViewController";
import { ShareOptionsActionType } from "../../../../../view/components/organism/el-share-options/ELShareOptionsView";
import store from "../../../../../stores/store";
import { ShareMode } from "../../../../../common/interfaces/share/ShareTypes";
import { ShareUtils } from "../../../../../utils/ShareUtils";
import { IngestEventSubTypes, IngestWorkflowTypes, ShareInvocationPoint } from "../../../../../utils/IngestConstants";
import { ClipboardService } from "../../../../../services/ClipboardService";
import { IngestUtils } from "../../../../../utils/IngestUtils";
import IBaseWorkspace from "../../../../IBaseWorkspace";

class LinkShare extends IWorkflow {

    private _link!: string;
    private shareOptions: ShareOptions;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.linkShare);
        this.shareOptions = new ShareOptions(this._owner);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        this.shareOptions.setOptions = ShareUtils.getDefaultShareOptionsForShareModes([ShareMode.facebook, ShareMode.email]);
        ReactDOM.render(
            React.createElement(LinkShareView,
                {
                    controller: this,
                    link: this._link,
                    shareOptions: this.shareOptions
                }),
            container
        )
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this._link = store.getState().shareReducer.linkInfo.link;
        this.createView(this.ensureHTMLElement(containerId));
    }

    /**
    * Handles ui events generated by views rendered in the workflow
    * @param action ControllerAction
    */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case LinkShareControllerAction.shareOptionPressed:
                {
                    //start final workflow directly since we have link already
                    ShareUtils.ingestShareStart(ShareInvocationPoint.managelink, this.notify.bind(this));
                    const controllerPayload = ShareUtils.getShareWorkflowName;
                    handled = await this.shareOptions.notify({ type: ShareOptionsActionType.openShareWorkflow, payload: controllerPayload });
                    break;
                }
            case LinkShareControllerAction.copyPressed:
                {
                    handled = await this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.link,
                            IngestEventSubTypes.click, IngestEventSubTypes.copy, true)
                    });
                    ClipboardService.writeText(this._link);
                    break
                }
            default:
                {
                    break;
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }
}

export { LinkShare };
