/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { LocaleType } from "../common/interfaces/intl/LocaleTypes"

export class FontUtils {
    /**
    * adobe-clean-han fonts Typekit ID (Managed by G11N team ID: "globalization-typekitfontsATadobe.com")
    * @see https://git.corp.adobe.com/pages/intl/guidelines/fonts/using_adobe_clean_fonts_for_web_app/
    */
    private static _typekitIds: Map<LocaleType, string> = new Map([
        [LocaleType.jaJP, "lnw1zlh"]
    ])

    public static getTypeKitId(locale: LocaleType): string | undefined{
        return this._typekitIds.get(locale) ;
    }
}