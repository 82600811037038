/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { v4 as uuid } from "uuid";
import AppAction from "../actions/AppActions";
import IAction from "../actions/IAction";
/**
 *  appReducer: {                                   ------------
 *       sessionId: "00--00--00--00"                            | ==> Store chop for this reducer
 *  }                                               ------------
 * 
 */

type AppReducerDataChop = { 
    sessionId: string,
    daysInTrial: number
 };

const setSessionId = (state: AppReducerDataChop, action: IAction<string | number>): AppReducerDataChop => {
    const data = action.payload as string;
    if (data) {
        return {
            ...state,
            sessionId: data
        };
    } else {
        return state;
    }
}

const setDaysInTrial = (state: AppReducerDataChop, action: IAction<string | number>): AppReducerDataChop => {
    const data = action.payload as number;
    if (data) {
        return {
            ...state,
            daysInTrial: data
        };
    } else {
        return state;
    }
}

const appReducer = (state: AppReducerDataChop = { sessionId: uuid(), daysInTrial: -1 }, action: IAction<string | number>): AppReducerDataChop => {
    switch (action.type) {
        case AppAction.SET_SESSION_ID:
            return setSessionId(state, action);
        case AppAction.SET_DAYS_IN_TRIAL:
            return setDaysInTrial(state, action);
        default:
            return state;
    }
}

export default appReducer;