/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { WorkflowsName } from "../../../../workspaces/IWorkflow";

export interface ELCreationFeedbackProps {
    heading: string,
    subHeading: string,
    feedbackPoints: ELCreationFeedbackCheckboxProps[],
    feedbackCharacterLimit: number,
    creationName: WorkflowsName
}

export enum ELCreationFeedbackDialogAction {
    onDismiss = "ON_DISMISS",
    onFeedbackSubmit = "FEEDBACK_SUBMIT"
}

export interface ELCreationFeedbackPayload {
    checkboxFeedback: string[],
    feedbackMessage?: string
}

export interface ELCreationFeedbackCheckboxProps {
    message: string,
    ingestString: string;
}
