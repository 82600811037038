/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CookiesUtils } from "../../utils/CookiesUtils";
import Logger, { LogLevel } from "../../utils/Logger";

import type { PrimitiveType, FormatXMLElementFn } from "intl-messageformat";
import { NumberParser } from "@internationalized/number";
import { createIntl, createIntlCache, defineMessage, FormatDateOptions } from "@formatjs/intl";
import type { MessageDescriptor, IntlCache, IntlShape } from "@formatjs/intl";
import axios from "axios";
import { AppCode, LocaleUtils } from "../../utils/LocaleUtils";
import Utils from "../../utils/Utils";
import IMS from "../../services/IMS";
import { LocaleType } from "../../common/interfaces/intl/LocaleTypes";
import { FontUtils } from "../../utils/FontUtils";

export { defineMessage };
export type { MessageDescriptor };
export type FormatMessageValues = Record<string, PrimitiveType | FormatXMLElementFn<string, string>> | undefined;

export const APP_LOCALE_COOKIE_NAME = "locale";
export const INT_LOCALE_COOKIE_NAME = "international"; // TODO(yaverma):[high] verify, could not find locale on this cookie

const FALLBACK_LOCALE = "en-US";

/**
 * Maps from locale to the absolute URL of the locale's strings file
 */
export type LocaleUrlMap = {
    [locale: string]: string[]
};

export type IntlInitOptions = {
    localeUrlMap: LocaleUrlMap;
};

interface TypeKitWindow extends Window {
    Typekit: {
        load: (config: unknown) => void;
    };
}
interface CompatibleScript extends HTMLScriptElement {
    readyState?: string;
    onReadyStateChange?: () => void | null;
}

const COOKIE_MAXAGE = { maxAge: 31536000 };

export class IntlHandler {

    private static _instance: IntlHandler | null = null;
    private _locale?: string;
    private _intl?: IntlShape<string>;
    private _intlCache: IntlCache;
    private _localeUrlMap?: LocaleUrlMap;
    private _supportedLocales?: string[];
    private _numberParser?: NumberParser;

    constructor() {
        this._intlCache = createIntlCache();
    }

    static getInstance(): IntlHandler {
        if (IntlHandler._instance === null)
            IntlHandler._instance = new IntlHandler();
        return IntlHandler._instance;
    }

    private _fetchData = async (): Promise<void> => {
        if (this._locale && this._intl) {
            const strings = (await this._loadLocale(this._locale));
            if (strings)
                Object.assign(this._intl.messages, strings);
            else {
                Logger.log(LogLevel.ERROR, "IntlHandler:_fetchData: ", "Intl.UnableToFetchLocaleData");
            }
        }
    }

    /**
     * Use adobe-clean-han web font for CCJK
     */
    private _loadTypeKitFont(locale: string): void {
        const localeInSnakeCase = Utils.convertToSnakeCase(locale) as LocaleType;
        if (!FontUtils.getTypeKitId(localeInSnakeCase)) {
            return;
        }

        const TYPEKIT_SCRIPT_LOAD_TIMEOUT = 3000;

        (function (d) {
            const config = {
                kitId: FontUtils.getTypeKitId(localeInSnakeCase),
                scriptTimeout: TYPEKIT_SCRIPT_LOAD_TIMEOUT,
                async: true
            },
                h = d.documentElement,
                t = setTimeout(() => {
                    h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
                }, config.scriptTimeout),
                tk: CompatibleScript = d.createElement("script"),
                s = d.getElementsByTagName("script")[0];
            let a,
                f = false;
            h.className += " wf-loading";
            tk.src = "https://use.typekit.net/" + config.kitId + ".js";
            tk.async = true;
            tk.onload = tk.onReadyStateChange = function () {
                a = this.readyState;
                if (f || (a && a !== "complete" && a !== "loaded")) return;
                f = true;
                clearTimeout(t);
                try {
                    (window as TypeKitWindow & typeof globalThis).Typekit.load(config);
                } catch (err) {
                    Logger.log(LogLevel.ERROR, "IntlHandler: _loadTypeKitFont: Error while loading typekit script for locale " + locale + "error = " + err);
                }
            };
            s.parentNode?.insertBefore(tk, s);
        })(document);
    }
    // TODO(yaverma):high :: this might not re-rednder the application with new strings
    // should we make this part of redux store to make this re-render, for now not providing
    // the ability to switch locale dynamically using a locale switcher

    private _changeLocale = async (locale: string): Promise<void> => {
        if (this._locale !== locale && this._isLocaleSupported(locale)) {
            this._locale = locale;
            this._loadTypeKitFont(locale); // load CCJK adobe-clean-han fonts

            //set lang html attribute
            const LANG_ATTRIBUTE_BODY = "lang";
            document.body.setAttribute(LANG_ATTRIBUTE_BODY, LocaleUtils.getAppLocale(AppCode.fonts, this._locale));

            this._intl = createIntl(
                {
                    locale: this._locale,
                    messages: {}
                },
                this._intlCache
            );
            this._numberParser = new NumberParser(locale);
            return this._fetchData();
        } else if (!this._isLocaleSupported(locale)) {
            Logger.log(LogLevel.ERROR, "IntlHandler:_changeLocale: ", "Intl.UnSupportedLocale");
            throw new Error("Intl.UnSupportedLocale");
        }
    }

    private _getDictionaryData = async (dictionaryURL: string): Promise<Record<string, string>> => {
        let data: Record<string, string> = {}
        try {
            data = (await axios.get(dictionaryURL)).data;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "IntlHandler:_getDictionaryData: Error getting dictionary data from url = " + dictionaryURL);
        }
        return Promise.resolve(data);
    }

    /**
     * Takes multiple dictionary files and makes one common dictionary file
     * @param dictionariesURLs absolute URLs for all the dictionary files
     * @returns combined dictionary file
     */
    private _compileLocaleDictionaries = async (dictionariesURLs: string[]): Promise<Record<string, string>> => {
        /*
            TODO - vib, fix it correctly, instead of making compiled json again on loading of locale, 
            should have pushed the contents in temp directory in a compiled json file
        */
        let compiledJson: Record<string, string> = {};
        for (const url of dictionariesURLs) {
            const data = await this._getDictionaryData(url);
            compiledJson = {
                ...compiledJson,
                ...data
            }
        }
        return compiledJson;
    }

    private _loadLocale = async (locale: string): Promise<Record<string, string>> => {
        if (!this._localeUrlMap) {
            Logger.log(LogLevel.ERROR, "IntlHandler:_loadLocale: ", "Intl.LocaleURLMap.LoadFailed");
            throw new Error("Intl.LocaleURLMap.LoadFailed");
        }
        return await this._compileLocaleDictionaries(this._localeUrlMap[locale]);
    }

    private _isLocaleSupported = (locale: string): boolean | undefined => {
        // HZ-FIXME we should get Horizon added to the adobe-locales library and have it handle this for us.
        //      It also handles the fallback and makes it easy for other Adobe properties to properly link
        //      to us. (Yes, I should write a JIRA ticket for this)
        //      https://git.corp.adobe.com/intl/adobe-locales
        return this._supportedLocales && this._supportedLocales.includes(locale);
    }

    init = async (options: IntlInitOptions): Promise<void> => {
        // only initialize once
        if (!this._locale) {
            this._localeUrlMap = options.localeUrlMap;
            this._supportedLocales = Object.keys(this._localeUrlMap);

            // 1. app locale cookie
            let locale = CookiesUtils.read(APP_LOCALE_COOKIE_NAME);

            // 2. URL query string
            if (!locale || !this._isLocaleSupported(locale)) {
                const localeQueryParam = Utils.getURLSearchParam(APP_LOCALE_COOKIE_NAME);
                if (localeQueryParam)
                    locale = localeQueryParam;
            }

            // 3. IMS data
            if ((!locale || !this._isLocaleSupported(locale))) {
                const imsPreferredLocale = this._getLocaleFromUserProfile();
                if (imsPreferredLocale)
                    locale = imsPreferredLocale;
            }

            // 4. Adobe.com cookie
            if (!locale || !this._isLocaleSupported(locale)) {
                locale = CookiesUtils.read(INT_LOCALE_COOKIE_NAME);
            }

            // 5. Browser setting
            /*
                Converting locale code from navigator to our app, 
                Since our locale codes are same as fonts locale codes, using them
            */
            if (!locale || !this._isLocaleSupported(locale)) {
                locale = [navigator.language, ...navigator.languages].map((language) => {
                    return LocaleUtils.getAppLocale(AppCode.fonts, language);
                }).find(this._isLocaleSupported);
            }

            // 6. Fallback to `en-US`
            if (!locale || !this._isLocaleSupported(locale)) {
                locale = FALLBACK_LOCALE;
            }
            try {
                await this._changeLocale(locale);
            } catch (error) {
                Logger.log(LogLevel.WARN, error);
            }
        }
    }

    private _getLocaleFromUserProfile: () => string | undefined = () => {
        const userProfile = IMS.getInstance().getUserProfile();
        if (userProfile) {
            //using fonts, since our locale set is same as fonts.adobe.com
            return userProfile?.preferred_languages?.map((lang: string) => LocaleUtils.getAppLocale(AppCode.fonts, lang))?.find(this._isLocaleSupported);
        }
    };

    parseNumber(value: string): number | undefined {
        return this._numberParser?.parse(value);
    }

    formatMessage = (id: string, values?: FormatMessageValues): string => {
        let message = "";
        if (this._intl && this._intl.messages) {
            if (this._intl.messages[id])
                message = this._intl.formatMessage({ id: id, defaultMessage: this._intl.messages[id] }, values);
            else {
                Logger.log(LogLevel.WARN, "Intl key not found, falling back to id " + id);
                message = String(id || "");
            }
            return message;
        }

        Logger.log(LogLevel.WARN, "Attempt to format message before initialization, falling back to default!", id);
        return String(id || "");
    }

    formatNumber = (num: number): string => {
        return this._intl && Number.isFinite(num) ? this._intl?.formatNumber(num) : String(num);
    }

    formatDate = (date: Date, options?: FormatDateOptions): string => {
        return this._intl ? this._intl.formatDate(date, options) : date.toDateString();
    }

    getCurrentLocale = (): string => {
        if (this._locale)
            return this._locale;
        return FALLBACK_LOCALE;
    }

    async languagePickerChange(locale: string): Promise<void> {
        CookiesUtils.write(APP_LOCALE_COOKIE_NAME, locale, COOKIE_MAXAGE);
        await this._changeLocale(locale);
    }

}

export type MessageFormatter = typeof IntlHandler.prototype.formatMessage;