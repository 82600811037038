/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { Storage, StorageConfig, Environment, HeadersPriority } from "@elements/elementswebcommon";
import IMS from "./IMS";

export class StorageService {
    static getInstance(): Storage {
        const storageEnv: Environment = process.env.REACT_APP_ENV === "prod" ? Environment.production : Environment.stage;
        const storageConfig: StorageConfig = {
            env: storageEnv,
            apiKey: process.env.REACT_APP_REPO_API_KEY as string,
            authToken: IMS.getInstance().getUserAccessToken(),
            priority: HeadersPriority.high
        }
        return Storage.getInstance(storageConfig);
    }
}