/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';

//Application Specific
import Logger, { LogLevel } from '../../../../utils/Logger';
import { ViewAction } from '../../../IBaseController';
import IViewController, { ControllerAction } from '../../../IViewController';
import BaseContentService, { ContentType } from '../../../../services/ElementsServices/BaseContentService';
import { CollageBackgroundData } from '../../../../common/interfaces/creations/ElementsContentTypes';
import { ELGraphicPanelControllerAction, ELGraphicPanelViewAction } from '../../../../common/interfaces/creations/templates/ELGraphicPanelTypes';
import ITemplateViewController from '../../../ITemplateViewController';

export default class ELGraphicPanel extends ITemplateViewController {
    private _contentService: BaseContentService;
    private _backgroundList: CollageBackgroundData[] = [];

    constructor(owner: IViewController, contentService: BaseContentService) {
        super(owner);
        this._contentService = contentService;
        this._fetchBackgroundList();
    }

    private async _fetchBackgroundList(): Promise<void> {
        try {
            this._backgroundList = await this._contentService.getContent(ContentType.collageBackground) as CollageBackgroundData[];
            this._updateBackgroundList();
        } catch (error) {
            Logger.log(LogLevel.WARN, "GraphicPanel:_fetchBackgroundList: ", error);
        }
    }

    private _updateBackgroundList(): void {
        if (this.viewDispatcher) {
            this.viewDispatcher({ type: ELGraphicPanelViewAction.backgroundData, payload: this._backgroundList });
        }
    }

    get getBackgroundList(): CollageBackgroundData[] {
        return this._backgroundList;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._updateBackgroundList();
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELGraphicPanelControllerAction.imageBackgroundChanged: {
                this._owner.notify(action);
                handled = true;
                break;
            }
            case ELGraphicPanelControllerAction.colorBackgroundChanged: {
                this._owner.notify(action);
                handled = true;
                break;
            }
        }

        return handled;
    }
}
