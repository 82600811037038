/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

//Application Specific
import Creations from "./Creations";
import { ELNavbarContainerView } from "../common/component/ELNavbarContainerView";
import Utils from "../../utils/Utils";
import { LinkParams } from "../../utils/Constants/Constants";
import { WorkspaceActionType } from "../IBaseWorkspace";
import { WorkflowsName } from "../IWorkflow";
import { Routes } from "../../app/AppRoute";

import "./Creations.scss";

export interface ICreationsProps {
    controller: Creations
}

const CreationsApp = (props: ICreationsProps): React.ReactElement => {
    const location = useLocation();

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const shouldStartCreationHome = (): boolean => {
        const route = Utils.getRouteFromHref(window.location.href);
        const workflowParam = Utils.getLinkParamValue(window.location.href, LinkParams.ELEMENTS_WORKFLOW);
        const currentWorkflow = props.controller.getWorkflow?.getWorkflowName;

        return route === Routes.CREATIONS && !workflowParam && currentWorkflow !== WorkflowsName.creationsHome;
    }

    useEffect(() => {
        if (shouldStartCreationHome()) {
            props.controller.notify({ type: WorkspaceActionType.startWorkflow, startWorkflow: WorkflowsName.creationsHome });
        }
    }, [location]);

    return (
        <>
            <ELNavbarContainerView />
            <div id="creation-app-container"></div>
            <div id="creations-modal-app-container"></div>
        </>
    )
};

export default CreationsApp;