/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import IAction from "../actions/IAction";
import PeekThroughAction, { PeekThroughActionPayload } from "../actions/PeekThroughAction";

interface PeekThroughReducerData {
    status: CreationsStatus,
    overlayData?: string
}

const updateStatus = (state: PeekThroughReducerData, action: IAction<PeekThroughActionPayload>): PeekThroughReducerData => {
    if (action.payload) {
        return {
            ...state,
            status: action.payload as CreationsStatus
        }
    }
    return state;
}

const updateOverlayData = (state: PeekThroughReducerData, action: IAction<PeekThroughActionPayload>): PeekThroughReducerData => {
    if (action.payload) {
        return {
            ...state,
            overlayData: action.payload as string
        }
    }
    return state;
}

const peekThroughReducer = (state: PeekThroughReducerData =
    { status: CreationsStatus.requested }, action: IAction<PeekThroughActionPayload>): PeekThroughReducerData => {
    switch (action.type) {
        case PeekThroughAction.UPDATE_STATUS: {
            return updateStatus(state, action);
        }
        case PeekThroughAction.UPDATE_OVERLAY: {
            return updateOverlayData(state, action);
        }
        default:
            return state;
    }
}

export default peekThroughReducer;