/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { AxiosResponse } from "axios";

//Application Specific
import { API } from "@elements/elementswebcommon";
import IMS from "./IMS";
import Utils from "../utils/Utils";
import Logger, { LogLevel } from "../utils/Logger";

export class AccessProfileService {
    private static _instance: AccessProfileService;
    private _accessProfileServiceAPI: API;

    private readonly ELEMENTS_NGL_APP_NAME = "ElementsWeb1";

    static getInstance(): AccessProfileService {
        if (AccessProfileService._instance === undefined) {
            AccessProfileService._instance = new AccessProfileService();
        }
        return AccessProfileService._instance;
    }

    private constructor() {
        if (process.env.REACT_APP_ACCESS_PROFILE_ENDPOINT === undefined) {
            throw new Error("API server URL not found in env");
        }

        this._accessProfileServiceAPI = new API(`${process.env.REACT_APP_ACCESS_PROFILE_ENDPOINT}`);
    }

    private _getRequestBodyHeaders(): Record<string, Record<string, string>> {
        return {
            headers: {
                "Content-Type": "application/json",
                "X-api-key": `${process.env.REACT_APP_ELEMENTS_API_KEY}`,
                "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
            }
        };
    }

    private _getRequestBody(): string {
        const requestBody = JSON.stringify({
            "appDetails": {
                "nglAppId": this.ELEMENTS_NGL_APP_NAME,
                "nglAppVersion": "26.0.1",
                "nglLibRuntimeMode": "NAMED_USER_ONLINE",
                "locale": Utils.getCurrentLocaleInSnakeCase()
            }
        });
        return requestBody;
    }

    async getUserAccessProfile(): Promise<AxiosResponse> {
        try {
            const response = await this._accessProfileServiceAPI.post(this._getRequestBody(), this._getRequestBodyHeaders());
            return response;
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "AccessProfileService:getUserAccessProfile: ", "error getting access profile for user", error);
            return Promise.reject(error);
        }
    }
}