/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { CSAssetWithData } from "../../common/interfaces/creations/CreationTypes";
import { CSAssetEditInfo, CSAssetTransformInfo, ELCollageTemplateBackgroundMode } from "../../common/interfaces/creations/ELCollageTypes";
import { ELPeekThroughOverlayInfoType, ELPeekThroughRectBorder, ELPeekThroughRectFill, overlayCornerflipDict, rectOriginMultiplier } from "../../common/interfaces/creations/ELPeekThroughTypes";
import { ELPIEPoint, ELPIEQuad } from "../../common/interfaces/geometry/ELGeometry";
import { RenderedShapesName } from "../../common/interfaces/renderer/RendererTypes";
import { ELStageBackgroundOptions, ELStageCorner, ELStageObjectData, ELStageObjectOptions, ELStageShapeAndObjectOptions, ELStageShapesType } from "../../common/interfaces/stage/StageTypes";
import CollageUtils from "../../workspaces/creations/workflows/collage/utils/CollageUtils";
import IDocDataParser from "../document/parser/IDocDataParser";
import ShapesFactory from "../stage/shapes/ShapesFactory";
import ELPeekThroughDoc from "./ELPeekThroughDoc";

export default class ELPeekThroughDocDataParser extends IDocDataParser {

    private async _createImageArea(): Promise<ELStageShapeAndObjectOptions> {
        const peekThroughDoc = this.doc as ELPeekThroughDoc;
        const docSize = await peekThroughDoc.getSize();
        const backgroundAsset = peekThroughDoc.getBackgroundAsset;
        const shape = ShapesFactory.createShape(ELStageShapesType.imageAreaWithLayout);

        const backgroundOptions: ELStageBackgroundOptions = {
            name: RenderedShapesName.imageAreaWithLayout,
            width: docSize.width,
            height: docSize.height,
            background: CollageUtils.getStageBackground({
                mode: ELCollageTemplateBackgroundMode.image,
                value: backgroundAsset.objectURL as string
            })
        };
        const stageAndObjectOptions: ELStageShapeAndObjectOptions = { shape: shape, objectOptions: backgroundOptions };

        return stageAndObjectOptions;
    }

    private async _createOverlays(): Promise<ELStageShapeAndObjectOptions[]> {
        const peekThroughDoc = this.doc as ELPeekThroughDoc;
        const docSize = await peekThroughDoc.getSize();
        const overlayInfoList = peekThroughDoc.getOverlayInfoList;

        const stageAndObjectOptionsOverlayList: ELStageShapeAndObjectOptions[] = [];
        const stageAndObjectOptionsRectangleList: ELStageShapeAndObjectOptions[] = [];

        overlayInfoList.forEach((overlay, index) => {
            if (overlay) {
                const shape = ShapesFactory.createShape(ELStageShapesType.imageFromURI);
                const shapeData: ELStageObjectData = {
                    payload: { asset: overlay?.asset, overlayPlacementId: index },
                    transformedToImageArea: true,
                    allowDrop: true,
                    showBorderOnHover: true
                };

                const overlayEditInfo = overlay?.edit;
                const objectOptions: ELStageObjectOptions = {
                    name: RenderedShapesName.image,
                    data: shapeData,
                    image: overlay?.asset.objectURL ?? "",
                    width: overlayEditInfo ? overlayEditInfo.transform.quadrilateral.left_bottom : docSize.width / 2,
                    height: overlayEditInfo ? overlayEditInfo.transform.quadrilateral.right_bottom : docSize.height / 2,
                    left: overlayEditInfo ? overlayEditInfo.transform.offset.horizontal : 0,
                    top: overlayEditInfo ? overlayEditInfo.transform.offset.vertical : 0,
                    fill: "lightgrey",
                    selectable: true,
                    fitToCorner: overlayEditInfo === undefined ? index : undefined,
                    scaleX: overlayEditInfo?.transform.scale.horizontal ?? 1,
                    scaleY: overlayEditInfo?.transform.scale.vertical ?? 1,
                    angle: overlayEditInfo?.transform.rotate,
                    flipX: overlayCornerflipDict[index as ELStageCorner][0],
                    flipY: overlayCornerflipDict[index as ELStageCorner][1]
                };

                const stageAndObjectOptions: ELStageShapeAndObjectOptions = {
                    shape: shape,
                    objectOptions: objectOptions
                };
                stageAndObjectOptionsOverlayList.push(stageAndObjectOptions);

            } else {
                const shape = ShapesFactory.createShape(ELStageShapesType.rect);
                const shapeData: ELStageObjectData = {
                    payload: { overlayPlacementId: index },
                    transformedToImageArea: true,
                    allowDrop: false,
                    showBorderOnHover: true
                };

                const objectOptions: ELStageObjectOptions = {
                    name: RenderedShapesName.rect,
                    data: shapeData,
                    width: docSize.width / 2,
                    height: docSize.height / 2,
                    left: rectOriginMultiplier[index][0] * docSize.width,
                    top: rectOriginMultiplier[index][1] * docSize.height,
                    fill: ELPeekThroughRectFill,
                    selectable: true,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    addToStage: true,
                    scaleX: 1,
                    scaleY: 1,
                    originX: "left",
                    originY: "top",
                    borderColor: ELPeekThroughRectBorder
                };

                const stageAndObjectOptions: ELStageShapeAndObjectOptions = {
                    shape: shape,
                    objectOptions: objectOptions
                };
                stageAndObjectOptionsRectangleList.push(stageAndObjectOptions);
            }
        });

        const stageAndObjectOptionsList = [...stageAndObjectOptionsRectangleList, ...stageAndObjectOptionsOverlayList];
        return stageAndObjectOptionsList;
    }

    async parseDocData(): Promise<ELStageShapeAndObjectOptions[]> {
        const stageAndObjectsOptions: ELStageShapeAndObjectOptions[] = [];

        stageAndObjectsOptions.push(await this._createImageArea());
        stageAndObjectsOptions.push(...(await this._createOverlays()));

        return stageAndObjectsOptions;
    }

    parseDocUpdatedData(data: unknown): unknown {
        let objectList = data as fabric.Object[];
        objectList = objectList.filter((object) => object.name === RenderedShapesName.image || object.name === RenderedShapesName.rect);
        objectList.sort((a, b) => {
            return a.data.payload.overlayPlacementId - b.data.payload.overlayPlacementId;
        });

        const overlayInfoList: ELPeekThroughOverlayInfoType[] = [];

        objectList.forEach((object) => {
            const { width, height, top, left, scaleX, scaleY, angle, data, name, flipX, flipY } = object;

            if (name === RenderedShapesName.rect) {
                overlayInfoList.push(null);
                return;
            }

            const quadrilateral: ELPIEQuad = {
                left_top: left ?? 0,
                right_top: top ?? 0,
                left_bottom: width ?? 0,
                right_bottom: height ?? 0
            }

            const scale: ELPIEPoint = {
                horizontal: scaleX ?? 1,
                vertical: scaleY ?? 1
            }

            const offset: ELPIEPoint = {
                horizontal: left ?? 0,
                vertical: top ?? 0
            }

            const center: ELPIEPoint = {
                horizontal: object.getCenterPoint().x,
                vertical: object.getCenterPoint().y
            }

            const rotate = angle ?? 0;

            const overlayTransformInfo: CSAssetTransformInfo = {
                quadrilateral: quadrilateral,
                scale: scale,
                rotate: rotate,
                offset: offset,
                center: center,
                flipX: flipX,
                flipY: flipY
            }

            const overlayEditInfo: CSAssetEditInfo = {
                transform: overlayTransformInfo
            }

            const overlayData = data.payload.asset as CSAssetWithData;
            const overlayInfo: ELPeekThroughOverlayInfoType = { asset: overlayData };
            overlayInfo.edit = overlayEditInfo;

            overlayInfoList.push(overlayInfo);
        });

        return overlayInfoList;
    }
}
