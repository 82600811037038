/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";

//Application Specific
import IViewController from "../../../IViewController";
import { IconType } from "../../../../assets/IconConstants";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import { ELFeedbackNavControllerAction } from "../../../../common/interfaces/navbar/ELNavBarTypes";
import { Theme } from "../../../../utils/Theme";

import "./ELFeedbackNavView.scss";

interface ELFeedbackNavProps {
    controller: IViewController
}

const ELFeedbackNavView = (props: ELFeedbackNavProps): React.ReactElement => {
    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const handleFeedbackClick = (): void => {
        props.controller.notify({
            type: ELFeedbackNavControllerAction.feedbackIconClick
        })
    }

    return (
        <div className="feedback-icon__box" data-testid="feedback-icon__box">
            <ELCustomIcon variant="independent" iconkey={IconType.feedbackNavIcon}
                color={Theme.global.gray_controls_color_N} hoverColor={Theme.global.gray_controls_color_H}
                width={"1.9rem"} height={"1.8rem"} cursor={"pointer"} onPress={handleFeedbackClick} />
        </div>
    );
}

export default ELFeedbackNavView;