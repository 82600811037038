/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { region } from "@intl/adobe-locales";

//Application Specific
import { LocaleType } from "../../common/interfaces/intl/LocaleTypes";
import Logger, { LogLevel } from "../Logger";
import Utils from "../Utils";
import Constants from "./Constants";

export class URLConstants {
   static get GoURL(): Record<string, string> {
        const locale = Utils.getCurrentLocaleInSnakeCase();

        const goUrls: Record<string, string> = {
            FAQ_HREF: "http://www.adobe.com/go/elweb-faq",
            SUPPORT_COMMUNITY_HREF: "http://www.adobe.com/go/elweb-support-community",
            PHOTOSHOP_ELEMENTS_APP_LINK: "https://www.adobe.com/go/learnpsefromweb",
            PREMIERE_ELEMENTS_APP_LINK: "https://www.adobe.com/go/learnprefromweb",
            PEPE_APP_LINK: "https://www.adobe.com/go/learnpepefromweb",
            REACT_APP_SIGNED_OUT_BUY_DESKTOP_APP_URL: "http://www.adobe.com/go/buyDesktopSignedOut",
            PREMIERE_ELEMENTS_UPGRADE_LINK: "https://www.adobe.com/go/upgradeprefromweb",
            PHOTOSHOP_ELEMENTS_UPGRADE_LINK: "https://www.adobe.com/go/upgradepsefromweb",
            PEPE_UPGRADE_LINK: "https://www.adobe.com/go/upgradepepefromweb",
            PREMIERE_ELEMENTS_BUY_LINK: "https://www.adobe.com/go/buyprefromweb",
            PHOTOSHOP_ELEMENTS_BUY_LINK: "https://www.adobe.com/go/buypsefromweb",
            PEPE_BUY_LINK: "https://www.adobe.com/go/buypepefromweb",
            LEARN_MORE_OPEN_IN_DESKTOP_DIALOG_LINK: "https://www.adobe.com/go/desktopapp_notfound",
            PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK_MAC: "https://www.adobe.com/go/downloadpsemacfromweb",
            PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK_WIN: "https://www.adobe.com/go/downloadpsewinfromweb",
            ELEMENTS_WEB_SURVEY_HREF: "https://www.adobe.com/go/web_survey",
            REACT_APP_TRIAL_ENDED_LEARN_MORE_URL: "http://www.adobe.com/go/elweb-trial-expired",
            REACT_APP_TRIAL_TROUBLESHOOT: "http://www.adobe.com/go/elweb-trial-troubleshoot",
            WEBGL_TROUBLESHOOT: "http://www.adobe.com/go/elweb-webgl-troubleshoot"
        };

        switch (locale) {
            case LocaleType.enUS: {
                Logger.log(LogLevel.INFO, "Using goURL as it is for this locale.");
                break;
            }
            default: {
                for (const urlKey in goUrls) {
                    goUrls[urlKey] = goUrls[urlKey] + Constants.UNDERSCORE + region(locale).toLowerCase();
                }
                break;
            }
        }

        return Object.freeze(goUrls);
    }

    /**
     * Need to make this urls as go
     */
    static get NotGoURL(): Record<string, string> {

        const locale = Utils.getCurrentLocaleInSnakeCase();

        const notGoUrls = {
            FOOTER_TERMS_OF_USE: "https://www.adobe.com/legal/terms.html",
            FOOTER_AD_CHOICES: "https://www.adobe.com/privacy/opt-out.html#interest-based-ads",
            FOOTER_PRIVACY_POLICY: "https://www.adobe.com/privacy/policy.html",
            PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK: "https://helpx.adobe.com/download-install/kb/photoshop-elements-downloads.html",
            PHOTOSHOP_ELEMENTS_APP_LEARN_MORE_LINK: "https://www.adobe.com/products/photoshop-elements/whats-new.html?trackingid=NGWGRBFF&mv=in-product&mv2=pse"
        };

        switch (locale) {
            case LocaleType.deDE: {
                notGoUrls.FOOTER_TERMS_OF_USE = "https://www.adobe.com/de/legal/terms.html";
                notGoUrls.FOOTER_AD_CHOICES = "https://www.adobe.com/de/privacy/opt-out.html#interest-based-ads";
                notGoUrls.FOOTER_PRIVACY_POLICY = "https://www.adobe.com/de/privacy/policy.html";
                notGoUrls.PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK = "https://helpx.adobe.com/de/download-install/kb/photoshop-elements-downloads.html";
                notGoUrls.PHOTOSHOP_ELEMENTS_APP_LEARN_MORE_LINK = "https://www.adobe.com/de/products/photoshop-elements/whats-new.html?trackingid=NGWGRBFF&mv=in-product&mv2=pse";
                break;
            }
            case LocaleType.frFR: {
                notGoUrls.FOOTER_TERMS_OF_USE = "https://www.adobe.com/fr/legal/terms.html";
                notGoUrls.FOOTER_AD_CHOICES = "https://www.adobe.com/fr/privacy/opt-out.html#interest-based-ads";
                notGoUrls.FOOTER_PRIVACY_POLICY = "https://www.adobe.com/fr/privacy/policy.html";
                notGoUrls.PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK = "https://helpx.adobe.com/fr/download-install/kb/photoshop-elements-downloads.html";
                notGoUrls.PHOTOSHOP_ELEMENTS_APP_LEARN_MORE_LINK = "https://www.adobe.com/fr/products/photoshop-elements/whats-new.html?trackingid=NGWGRBFF&mv=in-product&mv2=pse";
                break;
            }
            case LocaleType.jaJP: {
                notGoUrls.FOOTER_TERMS_OF_USE = "https://www.adobe.com/jp/legal/terms.html";
                notGoUrls.FOOTER_AD_CHOICES = "https://www.adobe.com/jp/privacy/opt-out.html#interest-based-ads";
                notGoUrls.FOOTER_PRIVACY_POLICY = "https://www.adobe.com/jp/privacy/policy.html";
                notGoUrls.PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK = "https://helpx.adobe.com/jp/download-install/kb/photoshop-elements-downloads.html";
                notGoUrls.PHOTOSHOP_ELEMENTS_APP_LEARN_MORE_LINK = "https://www.adobe.com/jp/products/photoshop-elements/whats-new.html?trackingid=NGWGRBFF&mv=in-product&mv2=pse";
                break;
            }
            default:
        }

        return Object.freeze(notGoUrls);
    }
}