/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import {
    ELRecommendationsProgressPayload,
    RecommendationsAppSubscriberType,
    RecommendationsInAppNotifierAction,
    RecommendationsStatusData,
    RequestId
} from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { CreationsStatus } from "../../../common/interfaces/creations/CreationTypes";
import ElementsCreationsService from "../../../services/ElementsServices/ElementsCreationsService";
import Logger, { LogLevel } from "../../../utils/Logger";
import IViewController from "../../../view/IViewController";
import Utils from "../../../utils/Utils";
import ElementsCreationInAppNotifier from "./ElementsCreationInAppNotifier";

class RecommendationsInAppNotifier extends ElementsCreationInAppNotifier {

    private _statusSubscriberQueue: IViewController[] = [];

    private _notifyRecommendationStatusChanged(recommendationStatusData: RecommendationsStatusData): void {
        const action = { type: RecommendationsInAppNotifierAction.recommendationStatusChanged, payload: recommendationStatusData };
        this._allActionSubscriberQueue.forEach((controller) => {
            controller.notify(action);
        });
        this._statusSubscriberQueue.forEach((controller) => {
            controller.notify(action);
        });
    }

    private _notifySubscribersForProgressChange(requestId: string, progress: number): void {
        this._statusSubscriberQueue.forEach(controller => {
            const payload: ELRecommendationsProgressPayload = {
                requestId: requestId,
                progress: progress
            };
            controller.notify({ type: RecommendationsInAppNotifierAction.recommendationProgressChanged, payload: payload });
        });
    }

    public subscribe(controller: IViewController, forAction: unknown): void {
        switch (forAction) {
            case RecommendationsAppSubscriberType.all: {
                this._allActionSubscriberQueue.push(controller);
                break;
            }
            case RecommendationsAppSubscriberType.statusChange: {
                this._statusSubscriberQueue.push(controller);
                break;
            }
        }
    }

    public unsubscribe(controller: IViewController, forAction: unknown): void {
        switch (forAction) {
            case RecommendationsAppSubscriberType.all: {
                this._allActionSubscriberQueue = this._allActionSubscriberQueue.filter((element) => (controller !== element));
                break;
            }
            case RecommendationsAppSubscriberType.statusChange: {
                this._statusSubscriberQueue = this._statusSubscriberQueue.filter((element) => (controller !== element));
                break;
            }
        }
    }

    public async pollStatus(id: RequestId): Promise<void> {
        const POLLING_CALL_GAP = 2000;
        try {
            if (id === "") {
                return Promise.reject(new Error("Empty requestID received"));
            }
            const { status, progress } = await ElementsCreationsService.getInstance().getRecommendationStatusWithProgress(id);
            this._notifySubscribersForProgressChange(id, progress);
            if (this.isRequestActive(status)) {
                await Utils.wait(POLLING_CALL_GAP);
                return this.pollStatus(id);
            } else {
                const creationStatusData = { requestId: id, status: status };
                this._notifyRecommendationStatusChanged(creationStatusData);
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "RecommendationsInAppNotifier:pollStatus, failed to poll for id" + id + " with error:" + error);
            const creationStatusData = { requestId: id, status: CreationsStatus.apiError };
            this._notifyRecommendationStatusChanged(creationStatusData);
        }
    }
}

export default new RecommendationsInAppNotifier();