/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import { ELCollageTemplateBackground } from "../../common/interfaces/creations/ELCollageTypes";
import IAction from "./IAction";

export interface CollageActionTemplatePayload {
    id: string,
    category: string,
    layout: number,
    backgroundData: ELCollageTemplateBackground
}

export interface CollageActionBackgroundPayload {
    backgroundData: ELCollageTemplateBackground
}

export type CollageActionPayload = CollageActionTemplatePayload | CollageActionBackgroundPayload | CreationsStatus;

export default class CollageAction {
    public static readonly UPDATE_TEMPLATE_DATA: string = "CollageAction.UPDATE_TEMPLATE_DATA";
    public static readonly UPDATE_BACKGROUNDATA: string = "CollageAction.UPDATE_BACKGROUNDATA";
    public static readonly UPDATE_STATUS: string = "CollageAction.UPDATE_STATUS";

    public static updateTemplateData(template: CollageActionTemplatePayload): IAction<CollageActionTemplatePayload> {
        return {
            type: CollageAction.UPDATE_TEMPLATE_DATA,
            payload: template
        };
    }

    public static updateBackgroundData(backgroundData: CollageActionBackgroundPayload): IAction<CollageActionBackgroundPayload> {
        return {
            type: CollageAction.UPDATE_BACKGROUNDATA,
            payload: backgroundData
        };
    }

    public static updateStatus(status: CreationsStatus): IAction<CreationsStatus> {
        return {
            type: CollageAction.UPDATE_STATUS,
            payload: status
        };
    }
}

