/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React from "react";

//Adobe Internal
import { ContentEntity, ContentEntityType, ContentType, PreviewType } from "@elements/elementswebcommon";

//Application specific
import { ELPeekThroughOverlayData, ELPeekThroughCornerData } from "../../../../common/interfaces/creations/ELPeekThroughTypes";
import { ELPeekThroughOverlayPanelViewAction } from "../../../../common/interfaces/creations/templates/ELPeekThroughOverlayPanelTypes";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ViewAction } from "../../../IBaseController";
import ITemplateViewController from "../../../ITemplateViewController";
import { ControllerAction } from "../../../IViewController";
import ELPeekThroughOverlayView from "./ELPeekThroughOverlayView";
import { ELContentCacheDownloader } from "../../../../workspaces/creations/utils/ELContentCacheDownloader";
import { PROMISE_FULFILLED } from "../../../../utils/Constants/Constants";
import { ContentUtils } from "../../../../utils/ContentUtils";

class ELPeekThroughOverlay extends ITemplateViewController {
    private _overlaysData: ContentEntity[] = [];
    private _overlayList: ELPeekThroughOverlayData[] = [];

    private async _fetchOverlayData(): Promise<void> {
        try {
            this._overlaysData = await ELContentCacheDownloader.getContentForContentType(ContentType.peekThroughOverlay);
            await this._parseOverlaysDataForThumb();
        } catch (error) {
            Logger.log(LogLevel.WARN, "ELPeekThroughOverlay:_fetchOverlayData: ", error);
        }
    }

    private async _getPeekThroughOverlayData(): Promise<ELPeekThroughOverlayData[]> {
        if (this._overlayList.length === 0) {
            await this._fetchOverlayData();
        }
        return this._overlayList;
    }

    private _cacheContent(contentUrls: string[]): void {
        for (const url of contentUrls) {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = url;
        }
    }

    private async _parseOverlaysDataForThumb(): Promise<void> {
        const parentData = this._overlaysData.filter(overlay => overlay.props.type === ContentEntityType.template);
        const parentPreviewUrls = await ContentUtils.getPreviewUrls(PreviewType.image, parentData);
        const parentContentUrls = await ContentUtils.getContentUrls(parentData);

        const childData = this._overlaysData.filter(overlay => overlay.props.type === ContentEntityType.image);
        const childThumbsPromises = parentData.map(parent => this._getChildThumbs(parent, childData));
        const childThumbs = await Promise.allSettled(childThumbsPromises);

        parentData.forEach((parent, parentIndex) => {
            const childThumb = childThumbs[parentIndex];
            if (childThumb.status === PROMISE_FULFILLED) {
                const parentThumb: ELPeekThroughOverlayData = {
                    commonData: {
                        id: parent.props.id,
                        name: parent.props.title,
                        previewUrl: parentPreviewUrls[parentIndex],
                        contentUrl: parentContentUrls[parentIndex],
                    },
                    cornerlist: childThumb.value
                };
                this._overlayList.push(parentThumb);
            }
        });
    }

    private async _getChildThumbs(parent: ContentEntity, childData: ContentEntity[]): Promise<ELPeekThroughCornerData[]> {
        const childIds: string[] = [];
        parent.props.resources?.forEach(resource => {
            childIds.push(resource.refId);
        })

        const filterChildsData: ContentEntity[] = childData.filter(child => childIds.includes(child.props.id));
        const filterChildPreviewUrls = await ContentUtils.getPreviewUrls(PreviewType.image, filterChildsData);
        const filterChildContentUrls = await ContentUtils.getContentUrls(filterChildsData);

        this._cacheContent(filterChildContentUrls);

        const childThumbs: ELPeekThroughCornerData[] = [];
        filterChildsData.forEach((child, childIndex) => {
            const childThumb: ELPeekThroughCornerData = {
                commonData: {
                    id: child.props.id,
                    name: child.props.title,
                    previewUrl: filterChildPreviewUrls[childIndex],
                    contentUrl: filterChildContentUrls[childIndex],
                },
                defaultCorner: parseInt(child.props.specificInfo?.position as string)
            };
            childThumbs.push(childThumb);
        })
        childThumbs.sort((first, second) => (first.defaultCorner > second.defaultCorner) ? 1 : -1);

        return childThumbs;
    }

    private _updateOverlayList(): void {
        if (this.viewDispatcher)
            this.viewDispatcher({ type: ELPeekThroughOverlayPanelViewAction.overlayData, payload: this._overlayList });
    }

    initialize(dispatch?: React.Dispatch<ViewAction> | undefined): void {
        super.initialize(dispatch);
        this._getPeekThroughOverlayData().then(() => this._updateOverlayList());
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this._owner.notify(action);
    }

    getView(viewProps?: unknown): React.ReactNode {
        return React.createElement(ELPeekThroughOverlayView, {
            controller: this
        })
    }
}

export default ELPeekThroughOverlay;