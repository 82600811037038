/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Adobe Internal
import { DialogTrigger } from "@adobe/react-spectrum";

//service
import { LeiaService } from "./../../../../services/leia-service/LeiaService";

//utils
import Utils from "../../../../utils/Utils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import Constants from "../../../../utils/Constants/Constants";
import { ToastUtils } from "../../../../utils/ToastUtils";

//Application Specific
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELActionButton, { ELActionButtonVariant } from "../../molecules/el-action-button/ELActionButton";
import { ELReportAbuseDialogView } from "../el-report-abuse-dialog/ELReportAbuseDialogView";
import { IReportAbuseUserInputData, IReportAbuseData } from "../../../../services/leia-service/ReportAbuseInterface";
import { useViewport } from "../../../../utils/hooks/useViewport";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

interface ELReportAbuseProps {
    reportAbuseSubmitCallback: () => void,
    text?: string,
    assetID: string,
    actionButtonVariant?: ELActionButtonVariant
}
export const PRODUCT_ID = "Photoshop Elements";

export const ELReportAbuse = (props: ELReportAbuseProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const { isMobile } = useViewport();
    const reportAbuseNotify = (isReportSuccess: boolean): void => {
        if (isReportSuccess) {
            ToastUtils.success(intlHandler.formatMessage("report-abuse-success-toast"));
        } else {
            ToastUtils.error(intlHandler.formatMessage("report-abuse-failure-toast"));
        }
    }

    const reportAbuse = async (payload: IReportAbuseData): Promise<void> => {
        try {
            const response = await LeiaService.getInstance().reportAbuse(payload);
            Logger.log(LogLevel.INFO, "Report abuse response : ", response);
            if (response.status === Constants.HTTP_RESPONSE_ACCEPTED_202) { // Need to check if status 200 is also success
                reportAbuseNotify(true) // Success
                return Promise.resolve();
            } else {
                reportAbuseNotify(false) // Failed
                return Promise.reject();
            }
        } catch (error) {
            reportAbuseNotify(false) // Failed
            Logger.log(LogLevel.WARN, "ELReportAbuse:reportAbuse: ", (error as Error)?.message ?? error);
            return Promise.reject(error);

        }
    }

    const onConfirm = (data: IReportAbuseUserInputData): void => {
        const payload: IReportAbuseData = {
            productId: PRODUCT_ID,
            reporterName: data.reporterName,
            reporterEmail: data.reporterEmail,
            reportType: data.reportType,
            assetList: [{ assetId: props.assetID }],
            reportDate: new Date().toISOString(),
            reportDesc: data.reportDesc,
            language: Utils.getCurrentLocaleInSnakeCase()
        }
        Logger.log(LogLevel.INFO, "Report abuse payload : ", payload);
        reportAbuse(payload);
    };

    const onCancel = (): void => {
        // If we need something then only will handle
        Logger.log(LogLevel.INFO, "Report abuse dialog cancelled.");
    };

    const reportAbuseIcon = (<ELIcon iconkey={IconType.reportAbuse} fill={"#fff"} className="el-action-button__icon" title={intlHandler.formatMessage("report")} />);

    return (<ELReactSpectrumV3Provider key="reportAbuse">
        <DialogTrigger type={Utils.getSpectrumDialogType((isMobile as boolean))} isDismissable>
            <ELActionButton elIcon={reportAbuseIcon} variant={props.actionButtonVariant} tooltip={intlHandler.formatMessage("report-abuse")}
             text={props.text} onPress={() => { Logger.log(LogLevel.INFO, "[ELReportAbuse] Report Abuse clicked") }} dataTestId="reportAbuse" />
            {(close) => (
                <ELReportAbuseDialogView onClose={() => {
                    props.reportAbuseSubmitCallback();
                    close();
                }}
                    heading={intlHandler.formatMessage("report-abuse")}
                    subHeading={intlHandler.formatMessage("report-abuse-description")}
                    onConfirm={onConfirm}
                    onCancel={() => {
                        close();
                        onCancel();
                    }}
                />
            )}
        </DialogTrigger>
    </ELReactSpectrumV3Provider>
    )
}
