/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';

export type CookiePreferencesPayload = string[];

export default class CookiePreferencesAction {

    public static readonly UPDATE_COOKIE_PREFERNCES: string = 'CookiePreferencesAction.UPDATE_COOKIE_PREFERNCES';

    public static updateCookiePreferences(cookieGroups: string[]): IAction<CookiePreferencesPayload> {
        return {
            type: CookiePreferencesAction.UPDATE_COOKIE_PREFERNCES,
            payload: cookieGroups
        };
    }
}