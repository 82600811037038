/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum TrialState {
    notStarted = "TRIAL_NO_STARTED",
    inProgress = "TRIAL_IN_PROGRESS",
    expired = "TRIAL_EXPIRED"
}

export enum TrialActionType {
    startTrial = "START_TRIAL",
    buyElementsDesktop = "BUY_NOW",
    troubleshoot = "TROUBLESHOOT"
}