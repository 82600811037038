/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";

//Application Specific
import { ViewAction } from "../view/IBaseController";
import IViewController from "../view/IViewController";

interface RouteUtilViewProps {
    controller: IViewController
}

const RouteUtilView = (props: RouteUtilViewProps): React.ReactElement => {
    const history = useHistory();
    const reducer = (state: unknown, action: ViewAction): unknown => {
        switch (action.type) {
            default: {
                history.push(action.payload as string, { from: window.location.href});
                return state;
            }
        }
    }
    const [, viewDispatch] = useReducer(reducer, {});
    
    useEffect(() => {
        props.controller.initialize(viewDispatch);
        return () => {
			props.controller.destroy();
		}
    }, []);

    return (
        <>
        </>
    );
}

export default RouteUtilView;