/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useEffect } from "react";
import Organizer from "./Organizer";
import { ELNavbarContainerView } from "../common/component/ELNavbarContainerView";

import "./Organizer.scss";

export interface IOrganizerProps {
    controller: Organizer
}

const OrganizerApp = (props: IOrganizerProps): React.ReactElement => {

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <>
            <ELNavbarContainerView />
            <div id="organizer-app-container" />
            <div id="organizer-modal-app-container"/>
        </>
    )
};

export default OrganizerApp;