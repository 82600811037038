/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useEffect, useReducer } from "react";
import { Provider as ReactReduxProvider } from "react-redux";
import { Router } from "react-router-dom";

import { ProgressCircle } from "@adobe/react-spectrum";

import { history } from "../utils/Utils";
import store from "./../stores/store";
import App from "./App";
import AppRoute from "./AppRoute";
import { ViewAction } from "../view/IBaseController";
import { AppViewActions } from "../common/interfaces/app/AppTypes";
import IMS from "../services/IMS";
import { ELReactSpectrumV3Provider } from "../view/components/atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import { ELCreationProgressView } from "../view/components/organism/el-creation-progress-view/ELCreationProgressView";

import "./AppView.scss";

interface IAppProps {
	controller: App
}

interface AppState {
	IMSInitialized: boolean,
	loggedIn: boolean,
	userAccessInfoFetched: boolean,
	redirectToIMSLogin: boolean
	progressText: string;
}

const defaultAppState = (): AppState => {
	return {
		IMSInitialized: false,
		loggedIn: false,
		userAccessInfoFetched: false,
		redirectToIMSLogin: false,
		progressText: "",
	}
}

const AppView = (props: IAppProps): React.ReactElement<IAppProps> => {
	const reducer = (state: AppState, action: ViewAction): AppState => {
		switch (action.type) {
			case AppViewActions.imsInitialized:
				return {
					...state,
					IMSInitialized: (action.payload as boolean) ?? false
				};
			case AppViewActions.loginState:
				return {
					...state,
					loggedIn: (action.payload as boolean) ?? false
				};
			case AppViewActions.userAccessInfoFetched:
				return {
					...state,
					userAccessInfoFetched: (action.payload as boolean) ?? false
				};
			case AppViewActions.redirectToIMSLogin:
				return {
					...state,
					redirectToIMSLogin: (action.payload as boolean) ?? false
				};
			case AppViewActions.updateProgressText:
				return {
					...state,
					progressText: action.payload as string
				};
			default: return state;
		}
	}
	const [state, dispatch] = useReducer(reducer, defaultAppState());

	useEffect(() => {
		props.controller.initialize(dispatch);
		return () => {
			//clean up
			props.controller.destroy();
		}
	}, [props.controller]);

	const canShowAppContent = (): boolean => {
		if (state.IMSInitialized && !state.redirectToIMSLogin && (!IMS.getInstance().isSignedInUser() || state.userAccessInfoFetched))
			return true;

		return false;
	}

	const getHeaderAndBody = (): React.ReactElement => {
		if (canShowAppContent()) {
			return (<>
				<Router history={history}>
					<div className="workspace-container">
						<AppRoute controller={props.controller} />
					</div>
					<div id="full-screen-workflow-container" />
					<div id="import-progress-container" />
					<div id="feedback-dialog-container" />
					<div id="whatsnew-dialog-container" />
				</Router>
				<div id="route-change-container" data-testid="route-change-container" />
			</>);
		} else {
			return (
				<div className="app-view__loading-circle" data-testid="app-view__loading-circle">
					<ProgressCircle isIndeterminate size="L" aria-label="Loading..." />
				</div>
			);
		}
	}

	const showProgressUI = (): boolean => {
		return !!state.progressText;
	};

	return (
		<ReactReduxProvider store={store}>
			<div id="global-toast-container" data-testid="global-toast-container" />
			<div id="global-popup-container" data-testid="global-popup-container" />
			<ELReactSpectrumV3Provider height="100%" width="100%">
				{getHeaderAndBody()}
				{
					showProgressUI() && <ELCreationProgressView progressText={state.progressText} mode="fullscreen" />
				}
			</ELReactSpectrumV3Provider>
		</ReactReduxProvider >
	)
}

export default AppView;
