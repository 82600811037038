/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ELStageDocConversionPayload, ELStageDocActions } from "../../../common/interfaces/document/ELStageDocTypes";
import { ELLayerKind, ELStageLayerData } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import ImageUtils from "../../../utils/ImageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import ELStageDoc from "../../client/document/ELStageDoc";
import { DocDataConverter } from "./DocDataConverter";

export class ELStageDocToImageDataConverter extends DocDataConverter<ELStageDoc, ImageData, ELStageDocConversionPayload> {
    async convertToDocData(data: ImageData, payload?: ELStageDocConversionPayload): Promise<void> {
        try {
            const { layerName, layerKind, redraw } = payload ?? {};
            const stageLayerData: ELStageLayerData = {
                    layerKind: layerKind ?? ELLayerKind.pixel,
                    data: data,
                    name: layerName
            }
            await this.doc.notify({ type: ELStageDocActions.addLayer, payload: { redraw: redraw, data: stageLayerData } });
            return Promise.resolve();
        } catch (error: any) {
            Logger.log(LogLevel.WARN, `Error converting data to doc data: ${error}`);
            return Promise.reject(error);
        }
    }

    async convertToData(): Promise<ImageData> {
        try {
            const dataURL = await this.doc.getDataURL();
            return await ImageUtils.createImageData(dataURL);
        } catch (error: any) {
            Logger.log(LogLevel.WARN, `Error converting doc data to data: ${error}`);
            return Promise.reject(error);
        }
    }
}