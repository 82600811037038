/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import Logger, { LogLevel } from "../../../../utils/Logger";
import { PerfAction } from "./PerfAction";
import { BeginActionOptions, EndActionOptions, PerfMonitorBase } from "./PerfMonitorBase";

/**
 * Constructor options for `PerfMonitorModel`.
 */
export interface PerfMonitorModelOptions {
    /**
     * A function that returns the current time when called, in the desired unit for monitoring performance, typically
     * ms. If not specified, a default function will be used that uses the highest-resolution browser APIs available.
     */
    currentTimeFn?: () => number;

    /**
     * Performance time origin in the desired unit for monitoring performance, typically ms. If not specified, a
     * default value of zero will be used.
     */
    timeOrigin?: number;

    /**
     * Function that returns current time based on Date api, for measuring clock sync.
     */
    currentDateFn?: () => number;

    /**
     * The maximum number of actions the model can store. The oldest-inserted actions will be removed to make space for
     * new actions if the model is at capacity. However, "locked" actions will never be removed.
     */
    maxActions: number;

    /**
     * Should Performance API marks/measures be created for all PerfActions.
     */
    markAllActions?: boolean;
}

export class PerfMonitorModel extends PerfMonitorBase {
    /**
     * The collection of actions that have been completed, i.e. that have both a start time and an end time defined.
     * New actions are inserted at the back of the collection. Old actions may be removed to make space for new actions,
     * depending on the `maxActions` construction option.
     */
    private _completedActions: PerfAction[] = [];

    /**
     * The collection of actions that have been started but have *not* been ended yet. The collection is keyed by
     * id.
     */
    private _openActionsByID = new Map<string, PerfAction>();

    /**
     * The maximum number of complete actions the model can store. The oldest-inserted actions will be removed to make
     * space for new actions if the model is at capacity. However, "locked" actions will never be removed.
     */
    private _maxActions: number;

    /**
     * Should Performance API marks/measures be created for all PerfActions.
     */
    private _markAllActions?: boolean;

    /**
     * Constructor.
     */
    constructor(options: PerfMonitorModelOptions) {
        super(options.currentTimeFn, options.timeOrigin, options.currentDateFn);

        this._maxActions = options.maxActions;
        this._markAllActions = options.markAllActions;
    }

    /**
     * The collection of actions that have been completed, i.e. that have both a start time and an end time defined.
     */
    get completedActions(): readonly PerfAction[] {
        return this._completedActions;
    }
    
    protected override _beginAction(
        category: string,
        name: string,
        id: string,
        startTime: number,
        options: BeginActionOptions
    ): void {
        if (this._openActionsByID.has(id)) {
            return;
        }

        // Create a new action and add it to the open actions.
        const action = new PerfAction(
            category,
            name,
            id,
            startTime,
            options.startTimeOffset ?? 0,
            options.locked,
            options.marked
        );

        this._openActionsByID.set(id, action);

        const markInTimeLine = this._markAllActions || options.marked;
        // Add a performance begin mark for the web inspector profiler, if desired.
        if (markInTimeLine) {
            this._beginMark(action, options.markStartTime);
        }
    }

    protected override _endAction(id: string, endActionOptions: EndActionOptions): void {
        const action = this._openActionsByID.get(id);
        if (!action) {
            if (endActionOptions.noError) {
                return;
            }
            Logger.log(LogLevel.ERROR, `Action not found with id: ${id}`);
            return;
        }

        const markInTimeLine = this._markAllActions || action.marked;
        // End the action.
        action.end(endActionOptions.endTime);
        action.endTimeOffset = endActionOptions.endTimeOffset ?? 0;

        // Add a performance end mark for the web inspector profiler, if desired.
        if (markInTimeLine) {
            this._endMark(action);
        }

        // Remove the action from the open actions.
        this._openActionsByID.delete(action.id);

        this._onActionCompleted(action);
    }

    private _onActionCompleted(action: PerfAction): void {
        // Add to completedActions if possible
        let addToCompletedActions = true;
        if (this._completedActions.length >= this._maxActions) {
            // Remove the oldest action if we're over capacity for stored completed actions.
            if (!this._removeOldestAction()) {
                // If we fail to remove the oldest action, yet we're at capacity with actions, that could mean we're
                // full of locked actions or not-yet-ended actions, which can't be removed. In this case, we drop the
                // current action. Even if the new action itself is locked, the max actions limit wins.
                addToCompletedActions = false;
            }
        }

        if (addToCompletedActions) {
            // Commit the action to the list of completed actions.
            this._completedActions.push(action);
        }
    }

    /**
     * Remove the oldest action from the collection of completed actions. This is typically called in order to make
     * space for new actions.
     * @returns True if the oldest non-locked action was removed.
     */
    private _removeOldestAction(): boolean {
        const actionIndex = this._completedActions.findIndex((action: PerfAction) => {
            // Don't remove locked actions.
            return !action.locked;
        });

        if (actionIndex < 0) {
            return false;
        }

        this._completedActions.splice(actionIndex, 1);
        return true;
    }

    /**
     * Call the performance.mark API to record the given action so that it appears up in the Timings row of the Chrome
     * web inspector profile.
     */
    private _beginMark(action: PerfAction, markStartTime?: number): void {
        const performanceMarkOptions =
            markStartTime !== undefined
                ? { startTime: markStartTime }
                : { startTime: action.startTime - this.timeOrigin };
        performance.mark(this._getMarkName(action.category, action.id, "begin"), performanceMarkOptions);
    }

    /**
     * Call the performance.mark and performance.measure APIs to record the given action so that it appears up in the
     * Timings row of the Chrome web inspector profile.
     */
    private _endMark(action: PerfAction): void {
        const performanceMarkOptions = { startTime: action.endTime - this.timeOrigin };
        performance.mark(this._getMarkName(action.category, action.id, "end"), performanceMarkOptions);
        performance.measure(
            this._getMarkName(action.category, action.id),
            this._getMarkName(action.category, action.id, "begin"),
            this._getMarkName(action.category, action.id, "end")
        );
    }

    /**
     * Create a name for a performance mark, passed to the performance.mark or performance.measure APIs.
     */
    private _getMarkName(category: string, id: string, suffix?: string): string {
        return suffix ? `${category}:${id}:${suffix}` : `${category}:${id}`;
    }
}
