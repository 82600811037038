/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    CreationType,
    ExportOperationTypeInfo,
    ExportType,
    OutputType,
    Resolution,
    RootFolderName,
    StorageType,
    ExportConfigurationType,
    SlideshowCreationOperationSubTypeInfo,
    SlideshowCreationOperationSubTypeConfiguration,
    SlideshowCreationOperationSubTypeConfigurationInfo,
    SlideshowConfigurationInfoType,
    SlideshowCreationOperationTemplate,
    ExportOperationSubTypeInfo,
    SequenceExportOperationSubTypeInfo,
    SequenceConfigurationInfoType,
    RenderQuality,
    ExportPreset,
    Utils,
    OperationContentType,
    SequenceExportOperationSubTypeConfiguration,
    ContentType
} from "@elements/elementswebcommon";

//Application Specific
import { CreationsJobCreator } from "../../../utils/CreationsJobCreator";
import { ELSlideshowProjectRequestParams, SlideshowInputInfo } from "../../../../../common/interfaces/creations/SlideshowTypes";
import { CreationsVideoExportPreset } from "../../../../../common/interfaces/creations/CreationTypes";

const DEFAULT_SLIDESHOW_PREVIEW_FILE_NAME = "Preview.jpg";
const DEFAULT_SLIDESHOW_FILE_NAME = "Slideshow.mp4";

const DEFAULT_VIDEO_THEME_ID = "theme15";
const DEFAULT_AUDIO_THEME_ID = "theme15";

export class SlideshowJobCreator extends CreationsJobCreator {

    private _createThumbExportConfigurationType(folderPath: string): ExportConfigurationType {
        const exportOperationConfiguration: ExportConfigurationType = {
            tag: OutputType.preview,
            root: RootFolderName.cloudContent,
            fileName: DEFAULT_SLIDESHOW_PREVIEW_FILE_NAME,
            storageType: StorageType.RAPI,
            representativeFolderPath: folderPath
        };
        return exportOperationConfiguration;
    }

    private _createVideoExportConfigurationType(folderPath: string): ExportConfigurationType {
        const exportOperationConfiguration: ExportConfigurationType = {
            tag: OutputType.full,
            root: RootFolderName.cloudContent,
            fileName: DEFAULT_SLIDESHOW_FILE_NAME,
            storageType: StorageType.RAPI,
            representativeFolderPath: folderPath
        };
        return exportOperationConfiguration;
    }

    private _createThumbExportPreset(): ExportPreset {
        const operationContentType: OperationContentType = {
            id: "sequenceImage360",
            contentType: ContentType.sequenceImageOutputPreset,
            mimetype: "application/epr",
            resolution: Resolution._360p
        };
        const preset: ExportPreset = this._getExportPreset(operationContentType);
        return preset;
    }

    private _createVideoExportPreset(videoExportConfiguration?: CreationsVideoExportPreset): ExportPreset {
        const isLowResolution = videoExportConfiguration === undefined || videoExportConfiguration === CreationsVideoExportPreset.EPR360;
        const id = isLowResolution ? CreationsVideoExportPreset.EPR360 : CreationsVideoExportPreset.EPR720;
        const resolution = isLowResolution ? Resolution._360p : Resolution._720p;
        const operationContentType: OperationContentType = {
            id: id,
            contentType: ContentType.videoOutputPreset,
            mimetype: "application/epr",
            resolution: resolution
        };
        const preset: ExportPreset = this._getExportPreset(operationContentType);
        return preset;
    }

    private _createVideoExportOperationConfiguration(videoExportConfiguration?: CreationsVideoExportPreset): SequenceExportOperationSubTypeConfiguration {
        const randomUniqueId = Utils.generateRandomGUID();
        const exportOperationConfiguration = this._createVideoExportConfigurationType(randomUniqueId);

        const sequenceConfigPayloadForVideo: SequenceConfigurationInfoType = {
            preset: this._createVideoExportPreset(videoExportConfiguration),
            hardwareAccelerated: true,
            startTime: 0,
            endTime: 360000000,
            renderQuality: RenderQuality.high
        };

        const sequenceConfigurationInfoType = this._getSequenceConfigurationInfoType(sequenceConfigPayloadForVideo);
        const exportOperationConfigurationObj = this._getSequenceExportOperationSubTypeConfiguration(exportOperationConfiguration, sequenceConfigurationInfoType);
        exportOperationConfigurationObj.id = randomUniqueId;
        return exportOperationConfigurationObj;
    }

    private _createThumbExportOperationConfiguration(): SequenceExportOperationSubTypeConfiguration {
        const randomUniqueId = Utils.generateRandomGUID();
        const exportOperationConfiguration = this._createThumbExportConfigurationType(randomUniqueId);

        const sequenceConfigPayloadForThumb: SequenceConfigurationInfoType = {
            preset: this._createThumbExportPreset(),
            hardwareAccelerated: true,
            startTime: 7000,
            endTime: 360000000,
            renderQuality: RenderQuality.draft
        };
        const sequenceConfigurationInfoType = this._getSequenceConfigurationInfoType(sequenceConfigPayloadForThumb);
        const exportOperationConfigurationObj = this._getSequenceExportOperationSubTypeConfiguration(exportOperationConfiguration, sequenceConfigurationInfoType);
        exportOperationConfigurationObj.id = randomUniqueId;
        return exportOperationConfigurationObj;
    }

    private _createExportOperationSubTypeInfo(videoExportConfiguration?: CreationsVideoExportPreset): ExportOperationSubTypeInfo | undefined {

        const exportOperationSubTypeInfo = new SequenceExportOperationSubTypeInfo();
        exportOperationSubTypeInfo.configurations = [
            this._createVideoExportOperationConfiguration(videoExportConfiguration),
            this._createThumbExportOperationConfiguration()
        ];
        return exportOperationSubTypeInfo;
    }

    private _getDefaultConfigurationInfo(): SlideshowConfigurationInfoType {
        const defaultVideoTemplate = new SlideshowCreationOperationTemplate();
        defaultVideoTemplate.operationContentData = {
            id: DEFAULT_VIDEO_THEME_ID,
            contentType: ContentType.slideshowTemplate,
            mimetype: "application/zip",
            resolution: Resolution._360p
        };

        const defaultAudioTemplate = new SlideshowCreationOperationTemplate();
        defaultAudioTemplate.operationContentData = {
            id: DEFAULT_AUDIO_THEME_ID,
            contentType: ContentType.slideshowTemplate,
            mimetype: "application/zip",
            resolution: Resolution._360p
        };

        const defaultConfigurationInfo: SlideshowConfigurationInfoType = {
            templates: {
                video: defaultVideoTemplate,
                audio: defaultAudioTemplate
            },
            titles: [{
                position: 0,
                mainText: ""
            }]
        };

        return defaultConfigurationInfo;
    }

    private _getConfigurationInfo(inputInfo: SlideshowInputInfo, resolution: CreationsVideoExportPreset): SlideshowConfigurationInfoType {
        const videoTemplate = new SlideshowCreationOperationTemplate();
        videoTemplate.operationContentData = {
            id: inputInfo.templates.video,
            contentType: ContentType.slideshowTemplate,
            mimetype: "application/zip",
            resolution: resolution === CreationsVideoExportPreset.EPR360 ? Resolution._360p : Resolution._720p
        };

        const audioTemplate = new SlideshowCreationOperationTemplate();
        audioTemplate.operationContentData = {
            id: inputInfo.templates.audio,
            contentType: ContentType.slideshowTemplate,
            mimetype: "application/zip",
            resolution: resolution === CreationsVideoExportPreset.EPR360 ? Resolution._360p : Resolution._720p
        };

        const defaultConfigurationInfo: SlideshowConfigurationInfoType = {
            templates: {
                video: videoTemplate,
                audio: audioTemplate
            },
            titles: [{
                position: inputInfo.titles[0].position,
                mainText: inputInfo.titles[0].mainText
            }]
        };

        return defaultConfigurationInfo;
    }

    private _createConfigurationInfo(projectRequestParams: ELSlideshowProjectRequestParams): SlideshowCreationOperationSubTypeConfigurationInfo {
        const configInfoObj = new SlideshowCreationOperationSubTypeConfigurationInfo();
        if (projectRequestParams.inputInfo && projectRequestParams.resolution) {
            configInfoObj.configurationInfo = this._getConfigurationInfo(projectRequestParams.inputInfo, projectRequestParams.resolution);
        } else {
            configInfoObj.configurationInfo = this._getDefaultConfigurationInfo();
        }
        return configInfoObj;
    }

    private _createConfigurations(projectRequestParams: ELSlideshowProjectRequestParams): SlideshowCreationOperationSubTypeConfiguration[] {
        const randomUniqueId = Utils.generateRandomGUID();

        const singleConfigObj = new SlideshowCreationOperationSubTypeConfiguration();
        singleConfigObj.version = this.configurationVersion;
        singleConfigObj.id = randomUniqueId;
        singleConfigObj.representativeFolderPath = randomUniqueId;
        const configurationInfoObj = this._createConfigurationInfo(projectRequestParams);
        singleConfigObj.configurationInfo = configurationInfoObj;

        return [singleConfigObj];
    }

    protected createOperationSubTypeInfo(requestParameters: ELSlideshowProjectRequestParams): SlideshowCreationOperationSubTypeInfo {
        const operationSubTypeInfo = new SlideshowCreationOperationSubTypeInfo();
        operationSubTypeInfo.version = this.operationSubTypeInfoVersion;
        operationSubTypeInfo.configurations = this._createConfigurations(requestParameters);
        return operationSubTypeInfo;
    }

    protected createExportOperationTypeInfo(requestParameters: ELSlideshowProjectRequestParams): ExportOperationTypeInfo {
        const exportOperationTypeInfo = new ExportOperationTypeInfo();
        exportOperationTypeInfo.operationSubType = ExportType.sequence;
        exportOperationTypeInfo.operationSubTypeInfo = this._createExportOperationSubTypeInfo(requestParameters.resolution);
        return exportOperationTypeInfo;
    }

    protected getCreationType(): CreationType {
        return CreationType.slideshow;
    }
}