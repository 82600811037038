/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import ELAdobeAssetDataResolver from "../../../../../editors/document/dataResolver/ELAdobeAssetDataResolver";
import ImageUtils from "../../../../../utils/ImageUtils";
import IDoc from "../../../../../editors/document/IDoc";
import { ELContentCreationCutoutData, ELContentCreationMediaData } from "../../../../../common/interfaces/creations/client/ELContentCreationsCreatorTypes";
import Logger, { LogLevel } from "../../../../../utils/Logger";

export default class ELContentCreationsCreator {

    async generateCreations(doc: IDoc): Promise<string> {
        const layers = await doc.getLayers();
        const backgroundData = await layers[0].getBitmap();
        const size = { width: backgroundData.width, height: backgroundData.height };

        const canvas = ImageUtils.createHTMLCanvasElementForSize(size);
        const ctx = canvas.getContext("2d");

        for (const layer of layers) {
            const bitmap = await layer.getBitmap();
            if (ctx) {
                if (layer.getFitToBackground()) {
                    const hRatio = ctx.canvas.width / bitmap.width;
                    const vRatio = ctx.canvas.height / bitmap.height;
                    const ratio = Math.max(hRatio, vRatio);
                    const centerShift_x = (ctx.canvas.width - bitmap.width * ratio) / 2;
                    const centerShift_y = (ctx.canvas.height - bitmap.height * ratio) / 2;

                    ctx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height,
                        centerShift_x, centerShift_y, bitmap.width * ratio, bitmap.height * ratio);
                } else {
                    ctx.drawImage(bitmap, 0, 0);
                }
            }
        }

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }
                if (blob) {
                    resolve(URL.createObjectURL(blob));
                }
                reject("ELContentCreationsCreator: generateCreations canvas.toBlob api returned null");
            });
        });
    }

    async getImageDataFromURL(url: string): Promise<ImageData> {
        try {
            const imageData = await ImageUtils.createImageData(url);
            return Promise.resolve(imageData);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getImageDataFromAsset(mediaData: ELContentCreationMediaData): Promise<ImageData> {
        try {
            const assetResolver = new ELAdobeAssetDataResolver();

            const mediaURL = await assetResolver.getDataAndUpdateStore(mediaData.media, mediaData.documentDataType, mediaData.size);
            const imageData = await ImageUtils.createImageData(mediaURL);

            return Promise.resolve(imageData);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async generateCutoutFromMask(cutoutData: ELContentCreationCutoutData): Promise<ImageData> {
        try {
            return new Promise((resolve, reject) => {
                const imageData = new ImageData(new Uint8ClampedArray(cutoutData.source.data), cutoutData.source.width, cutoutData.source.height);
                const maskData = cutoutData.mask;

                const maskCanvas = ImageUtils.createHTMLCanvasElement(imageData);
                const maskCTX = maskCanvas.getContext("2d");

                Logger.log(LogLevel.DEBUG, "generateCutoutFromMask: ", imageData, maskData);

                if (maskCTX) {
                    const createCutout = async (): Promise<ImageData> => {
                        for (let i = 3, len = imageData.data.length; i < len; i = i + 4) {
                            /**
                            * ImageData gives bounds for all pixels. It doesn't honour transparent pixels.
                            * PSD gives bounds only for non transparent pixels, hence setting it transparent pixel to 1 to get entire bounds. 
                            */
                            imageData.data[i] = (maskData.data[i - 1] === 0) ? 1 : maskData.data[i - 1]; //copies blue channel, can be any channel since all values should be 0 for mask
                        }

                        return imageData;
                    }

                    const drawCutout = (cutoutImageData: ImageData): void => {
                        maskCTX.clearRect(0, 0, maskCTX.canvas.width, maskCTX.canvas.height);
                        maskCTX.globalCompositeOperation = "source-over";
                        maskCTX.putImageData(cutoutImageData, 0, 0);
                    }

                    createCutout().then((cutoutImageData) => {
                        drawCutout(cutoutImageData);
                        const imageData = maskCTX.getImageData(0, 0, maskCTX.canvas.width, maskCTX.canvas.height);
                        resolve(imageData);
                    }).catch((err) => {
                        Logger.log(LogLevel.DEBUG, "Create cutout failed - ", err);
                        reject("Couldn't create cutout! " + err);
                    });
                }
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
}