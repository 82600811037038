/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageLayerDataOptions } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import Logger, { LogLevel } from "../Logger";
import IDoc from "../../editors/document/IDoc";

export default class ELStageDocUtils {
    static async getLayerDataOptionsList(doc: IDoc): Promise<ELStageLayerDataOptions[]> {
        try {
            const layerDataOptionsList: ELStageLayerDataOptions[] = [];

            (await doc.getLayers()).forEach((layer) => {
                const laterDataOptions: ELStageLayerDataOptions = {
                    layerKind: layer.getLayerKind(),
                    path: layer.getPath(),
                    fitToBackground: layer.getFitToBackground(),
                    hasAlpha: layer.getHasAlpha(),
                    isClipped: layer.getIsClipped(),
                    export: layer.canExport(),
                    layerParams: layer.getLayerParams(),
                    redraw: layer.getRedraw(),
                    selectable: layer.getSelectable(),
                    editInfo: layer.getEditInfo(),
                    visible: layer.getVisibility(),
                    clipPathOptions: layer.getClipLayerOptions()
                };
                layerDataOptionsList.push(laterDataOptions);
            });
            return Promise.resolve(layerDataOptionsList);

        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELStageDocUtils:getLayerDataOptionsList: ", error);
            return Promise.reject("Couldn't get layerDataOptionsList");
        }
    }
}