/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useRef } from "react";

//Adobe Internal
import { Text } from '@react-spectrum/text';
import { Flex, Grid, repeat } from "@adobe/react-spectrum";
import { useButton } from "@react-aria/button";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { Theme } from "../../../../utils/Theme";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELPanelState } from "../../../../common/interfaces/creations/common/PanelTypes";
import { useDynamicLayout } from "../../../../utils/hooks/useDynamicLayout";
import { CollapsiblePanelData, ELCollapsiblePanelProps } from "../../../../common/interfaces/creations/templates/ELCollapsiblePanelTypes";
import ELSkeleton from "../../atoms/el-skeleton/ELSkeleton";

import "./ELCollapsiblePanel.scss";

const ELCollapsiblePanelView = (props: ELCollapsiblePanelProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const specLayoutConfig = {
        TILE_W: 7,
        TILE_H: 7,
        DIST_BETWEEN_TILE: 1.5
    };
    const gridRef = useRef(null);
    const scrollParentRef = useRef(null);
    const viewLayoutConfig = useDynamicLayout({
        specLayoutConfig: specLayoutConfig,
        observerRef: scrollParentRef,
        gridRef: gridRef
    });

    const getTemplateContainerClass = (): string => {
        if (props.opened === true)
            return "collapsible-panel-container-final";
        else
            return "collapsible-panel-container-initial";
    }

    const collapsiblePanelHeaderRef = useRef(null);
    const { buttonProps } = useButton({
        onPress: () => props.onPress(props.panelID),
    }, collapsiblePanelHeaderRef);

    const getImageContainerClass = (templateId: string): string => {
        if (props.showImageBorder) {
            return "collapsible-panel-box";
        } else if (templateId === props.selectedTemplateId) {
            return "collapsible-panel-box-selected";
        }
        return "collapsible-panel-box";
    }

    const getImageClass = (templateId: string): string => {
        if (props.showImageBorder) {
            if (templateId === props.selectedTemplateId) {
                return "collapsible-panel-image-with-border-selected";
            } else {
                return "collapsible-panel-image-with-border";
            }
        }
        return "collapsible-panel-image";
    }

    const showHoverText = (hoverText?: string): boolean => {
        return (props.opened && hoverText !== undefined && hoverText.length > 0);
    }

    const getPreview = (template: CollapsiblePanelData, index: number): React.ReactElement => {
        if (props.showLoadingPreview && !template.previewUrl) {
            return <ELSkeleton width={`${viewLayoutConfig.TILE_W}px`} height={`${viewLayoutConfig.TILE_H}px`} />
        } else {
            return (
                <div key={index} data-testid={"el-collapsible-panel-child-" + template.id} className={getImageContainerClass(template.id)}
                    onClick={() => props.onThumbPress(template.id)}
                    onKeyPress={() => Logger.log(LogLevel.INFO, "Not handling key events for ELCollapsiblePanelView")}
                    style={{ width: viewLayoutConfig.TILE_W, height: viewLayoutConfig.TILE_H }}>
                    <img crossOrigin="anonymous" className={getImageClass(template.id)} src={template.previewUrl} alt="sample-filling" />
                    {showHoverText(template.hoverText) && <Text UNSAFE_className="collapsible-panel-image-text">
                        {intlHandler.formatMessage(template.hoverText ?? "")}
                    </Text>}
                </div>)
        }
    }

    return (
        <div ref={scrollParentRef} data-testid={"el-collapsible-panel-" + props.panelID}
            className={"collapsible-panel-" + (props.opened ? ELPanelState.opened : ELPanelState.closed)}>
            {props.showHeadingDropdown ?
                <div ref={collapsiblePanelHeaderRef} {...buttonProps}>
                    <Flex direction="row" UNSAFE_className="collapsible-panel-header-box">
                        <div className="collapsible-panel-header-text">
                            <Text>{intlHandler.formatMessage(props.flyoutHeaderText)}</Text>
                        </div>
                        <ELIcon iconkey={props.opened ? IconType.collapsibleDownward : IconType.collapsibleForward} color={Theme.dark.gray_controls_color_N}
                            width={"1.75rem"} height={"1.75rem"}>
                        </ELIcon>
                    </Flex>
                </div>
                : <></>
            }
            <div data-testid={getTemplateContainerClass()} className={getTemplateContainerClass()} ref={gridRef}>
                <Grid columns={repeat("auto-fit", viewLayoutConfig.TILE_W)}
                    justifyContent={"start"}
                    gap={viewLayoutConfig.DIST_BETWEEN_TILE}>
                    {props.data.map((template, index) =>
                        <Flex key={index} direction={"column"}>
                            {getPreview(template, index)}
                            {props.showImageText && <Text alignSelf={"center"}> {template.name}</Text>}
                        </Flex>
                    )}
                </Grid>
            </div >
        </div >
    );
}

ELCollapsiblePanelView.defaultProps = {
    showImageText: false,
    showImageBorder: false,
    showHeadingDropdown: true
}

export default ELCollapsiblePanelView;
