/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//Application Specific
import IDoc, { DocumentType } from "../document/IDoc";
import { ELPeekThroughDocAction, ELPeekThroughDocPayload, ELPeekThroughOverlayInfoType, ELPeekThroughShapeData, ELSavedPeekThroughVersion } from "../../common/interfaces/creations/ELPeekThroughTypes";
import IWorkflow from "../../workspaces/IWorkflow";
import { CSAssetWithData, CreationWorkflowActions, CreationsDownloadFileType, CreationsStatus } from "../../common/interfaces/creations/CreationTypes";
import { StorageService } from "../../services/StorageServiceWrapper";
import store from "../../stores/store";
import Logger, { LogLevel } from "../../utils/Logger";
import { CanvasControllerAction, CanvasViewAction, ELDownloadData, ELImageData } from "../../common/interfaces/stage/StageTypes";
import { ELSize } from "../../common/interfaces/geometry/ELGeometry";
import ImageUtils from "../../utils/ImageUtils";
import { ControllerAction } from "../../view/IViewController";
import { DocumentActions, DocumentDirty, DocumentFormat } from "../../common/interfaces/document/DocumentTypes";
import DocActions from "../../stores/actions/DocActions";
import { ELLayoutInfo } from "../../common/interfaces/creations/ELSocialLayoutTypes";
import { CanvasMode } from "../../stores/actions/CanvasAction";
import { ELStageObjectData } from "../../common/interfaces/stage/StageTypes";
import ELPSDConvertorFactory from "../pie/psdConvertor/ELPSDConvertorFactory";
import ELClientUploadHandler from "../../modules/clientUploadHandler/ELClientUploadHandler";
import { ELRenditionHandler } from "../../modules/elRenditionHandler/ELRenditionHandler";

export default class ELPeekThroughDoc extends IDoc {
    private _backgroundAsset: CSAssetWithData;
    private _overlayInfoList: ELPeekThroughOverlayInfoType[];
    private _assetPath?: string;

    constructor(owner: IWorkflow) {
        super(owner);
        this._backgroundAsset = {} as CSAssetWithData;
        this._overlayInfoList = [];
    }

    async initialize<T>(payload?: T): Promise<boolean> {
        const peekThroughDocPayload = payload as unknown as ELPeekThroughDocPayload;
        this._backgroundAsset = peekThroughDocPayload.backgroundAsset;
        this._overlayInfoList = peekThroughDocPayload.overlayInfoList ?? [null, null, null, null];
        this.layoutInfo = peekThroughDocPayload.layoutInfo;

        this.data = peekThroughDocPayload;

        return Promise.resolve(true);
    }

    private _resetOverlays(): void {
        this._overlayInfoList.forEach((overlayInfo) => {
            if (overlayInfo) {
                overlayInfo.edit = undefined;
            }
        });
    }

    private _setBackgroundAssetData(objectURL: string): void {
        this._backgroundAsset.objectURL = objectURL;
    }

    private async _getBackgroundAsset(): Promise<void> {
        const assetId = this._backgroundAsset.assetURN;
        try {
            const renditionHandler = new ELRenditionHandler();
            const asset = await StorageService.getInstance().resolveAsset({ assetId: assetId }, 'id');
            const objectURL = await renditionHandler.getRendition(asset);
            this._setBackgroundAssetData(objectURL);
            return Promise.resolve();
        } catch (error) {
            Logger.log(LogLevel.WARN, "ELPeekThroughDoc::_getBackgroundAsset", error);
            return Promise.reject(error);
        }
    }

    private _onOverlayUpdate(shapeData: ELPeekThroughShapeData): void {
        if (shapeData.asset) {
            this._overlayInfoList[shapeData.overlayPlacementId] = {
                asset: shapeData.asset
            };
            this.renderer?.update({ type: ELPeekThroughDocAction.overlayUpdated, payload: shapeData });
        }
    }

    private _onRemoveOverlay(shapeData: ELPeekThroughShapeData): void {
        if (this._overlayInfoList[shapeData.overlayPlacementId]) {
            this._overlayInfoList[shapeData.overlayPlacementId] = null;
            this.renderer?.update({ type: ELPeekThroughDocAction.removeOverlay, payload: shapeData });
        }
    }

    private _commitLayoutWorkflow(layoutInfo?: ELLayoutInfo): void {
        if (layoutInfo) {
            this._resetOverlays();
            this.owner.notify({ type: DocumentActions.commitLayout, payload: layoutInfo });
        } else {
            this.owner.notify({ type: DocumentActions.commitLayout, payload: this.layoutInfo });
        }
    }

    get getBackgroundAsset(): CSAssetWithData {
        return this._backgroundAsset;
    }

    get getOverlayInfoList(): ELPeekThroughOverlayInfoType[] {
        return this._overlayInfoList;
    }

    set setOverlayInfoList(overlayInfoList: ELPeekThroughOverlayInfoType[]) {
        this._overlayInfoList = overlayInfoList;
    }

    hasRenderingError(): boolean {
        return this.renderer ? this.renderer.hasRenderingError() : super.hasRenderingError();
    }

    async render(container: HTMLElement): Promise<void> {
        try {
            await this._getBackgroundAsset();
            await this.renderer?.render(container);
            window.requestAnimationFrame(() => { //let canvas drawing complete
                this.owner.notify({ type: CreationWorkflowActions.creationRenderStatus, payload: CreationsStatus.success });
                this.renderer?.notify({ type: CanvasViewAction.sendDocumentCreatedUpdate });
                this.renderer?.notify({ type: CanvasViewAction.addCanvasChangedHandlers });
            });
        } catch (error) {
            Promise.reject(error);
        }
    }

    async getSize(): Promise<ELSize> {
        const size = await this.getOriginalSize();
        if (this.layoutInfo && store.getState().canvasReducer.mode === CanvasMode.render) {
            size.width = (this.layoutInfo.right - this.layoutInfo.left) * this.layoutInfo.scaleX * size.width;
            size.height = (this.layoutInfo.bottom - this.layoutInfo.top) * this.layoutInfo.scaleY * size.height;
        }
        return size;
    }

    async getOriginalSize(): Promise<ELSize> {
        if (!this.documentSize) {
            if (this._backgroundAsset.objectURL) {
                const img = await ImageUtils.getMetaDataFromURL(this._backgroundAsset.objectURL);
                this.documentSize = {
                    width: img.naturalWidth,
                    height: img.naturalHeight
                }
            } else {
                return Promise.reject("backgroundAsset doesn't contains objecturl");
            }
        }
        return _.cloneDeep(this.documentSize);
    }

    close(): Promise<void> {
        return Promise.resolve();
    }

    destroy(): void {
        super.destroy();
        this.close();
        this.renderer?.destroy();
    }

    async save(): Promise<void> {
        Logger.log(LogLevel.INFO, "ELPeekThroughDoc - (save)", "Saving doc!");

        if (this.hasRenderingError()) {
            return Promise.reject("ELPeekThroughDoc:save: document has rendering error");
        }

        if (!this._assetPath) {
            return Promise.reject("ELPeekThroughDoc:save: _assetPath not set for document");
        }

        if (this.isDirty === DocumentDirty.NON_DIRTY) {
            return Promise.reject("ELPeekThroughDoc:save: save request came without document being dirty");
        }

        try {
            const dataURL = await this.downloader?.getDataURL();

            if (dataURL) {
                const blob = await ImageUtils.createBlob(dataURL, "image/jpeg", 1);
                this._backgroundAsset.objectURL = undefined;
                const jsonDoc = { version: ELSavedPeekThroughVersion.version_1_0, backgroundAsset: this._backgroundAsset, overlayInfoList: this._overlayInfoList, layoutInfo: this.layoutInfo };

                const clientUploadHandler = new ELClientUploadHandler();
                await clientUploadHandler.upload({
                    assetPath: this._assetPath,
                    contentType: "image/jpeg",
                    saveInfo: { blob: blob, metaData: jsonDoc }
                });

                await this.owner.notify({ type: CreationWorkflowActions.updateCreationStatus, payload: CreationsStatus.success });

                this.markAndNotifyDocumentDirty(DocumentDirty.NON_DIRTY);
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELPeekThroughDoc:save: ", error);
            return Promise.reject("Couldn't save peek through document");
        }
    }

    documentCreated(payload: unknown): Promise<void> {
        const overlayInfoList = this.dataParser?.parseDocUpdatedData(payload) as ELPeekThroughOverlayInfoType[];

        overlayInfoList.forEach((overlayInfo, index) => {
            if (overlayInfo) {
                this._overlayInfoList[index] = {
                    asset: overlayInfo.asset,
                    edit: overlayInfo?.edit
                };
            } else {
                this._overlayInfoList[index] = null;
            }
        });

        return Promise.resolve();
    }

    documentUpdated(payload: unknown): void {
        const overlayInfoList = this.dataParser?.parseDocUpdatedData(payload) as ELPeekThroughOverlayInfoType[];

        overlayInfoList.forEach((overlayInfo, index) => {
            if (overlayInfo) {
                this._overlayInfoList[index] = {
                    asset: overlayInfo.asset,
                    edit: overlayInfo?.edit
                };
            } else {
                this._overlayInfoList[index] = null;
            }
        });

        this.markAndNotifyDocumentDirty(DocumentDirty.DIRTY);

        Logger.log(LogLevel.INFO, "document dirty: ", this.isDirty);
    }

    async exportPSD(downloadData: ELDownloadData): Promise<void> {
        const psdConvertor = ELPSDConvertorFactory.createConvertor(DocumentType.peekThrough);
        const doc = await psdConvertor.convertToPSD(this, false);

        const imageData: ELImageData = { format: DocumentFormat.PSD };
        doc?.download({ ...downloadData, imageData: imageData });
    }

    download(downloadData: ELDownloadData): void {
        const format = (downloadData.imageData.format as unknown as CreationsDownloadFileType);
        switch (format) {
            case CreationsDownloadFileType.psd: {
                this.exportPSD(downloadData);
                break;
            }
            default: {
                this.downloader?.download(downloadData);
                break;
            }
        }
    }

    getData(): unknown {
        const payload: ELPeekThroughDocPayload = {
            backgroundAsset: this._backgroundAsset,
            overlayInfoList: this._overlayInfoList,
            layoutInfo: this.layoutInfo
        }
        return payload;
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELPeekThroughDocAction.overlayUpdated:
                {
                    this._onOverlayUpdate(action.payload as ELPeekThroughShapeData);
                    handled = true;
                    break;
                }
            case ELPeekThroughDocAction.removeOverlay:
                {
                    this._onRemoveOverlay(action.payload as ELPeekThroughShapeData);
                    handled = true;
                    break;
                }
            case CanvasControllerAction.deleteActiveObject:
                {
                    const stageData = (action.payload as ELStageObjectData);
                    this._onRemoveOverlay(stageData.payload as ELPeekThroughShapeData);
                    handled = true;
                    break;
                }
            case DocumentActions.activeObjectChange:
                {
                    handled = await this.owner.notify(action);
                    break;
                }
            case DocumentActions.documentCreated:
                {
                    this.documentCreated(action.payload);
                    handled = true;
                    break;
                }
            case DocumentActions.documentUpdated:
                {
                    this.documentUpdated(action.payload);
                    handled = true;
                    break;
                }
            case DocumentActions.updateCloudAssetPath: {
                this._assetPath = action.payload as string;
                handled = true;
                break;
            }
            case DocumentActions.download: {
                const downloadData = action.payload as ELDownloadData;
                this.download(downloadData);
                handled = true;
                break;
            }
            case DocumentActions.zoomModified: {
                store.dispatch(DocActions.updateZoomLevel(action.payload as number));
                handled = true;
                break;
            }
            case DocumentActions.commitLayout: {
                this._commitLayoutWorkflow(action.payload as ELLayoutInfo);
                handled = true;
                break;
            }
        }

        if (!handled) {
            handled = await super.notify(action);
        }

        return handled;
    }
}