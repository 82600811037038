/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

//Adobe Internal
import { Text, Dialog, Content, Divider, Heading, Flex, Link, DialogContainer } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Utils from "../../../../utils/Utils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import IViewController from "../../../IViewController";
import ELButton from "../../atoms/el-button/ELButtonView";

import "./ELEditErrorDialog.scss";

export interface ELEditErrorDialogViewProps {
    controller: IViewController,
    heading: string,
    description: string,
    onClose: () => void
    learnMoreLink: string
}

export const ELEditErrorDialogView = (props: ELEditErrorDialogViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const [isDialogOpen, setIsDialogOpen] = React.useState(true);
    const location = useLocation();
    const firstRenderRef = useRef(false);

    useEffect(() => {
        if (firstRenderRef.current) {
            if (isDialogOpen) {
                onClose();
            }
        } else {
            firstRenderRef.current = true;
        }
    }, [location]);

    useEffect(() => {
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const onClose = (): void => {
        setIsDialogOpen(false);
        props.onClose();
    }

    return (
        <ELReactSpectrumV3Provider>
            <DialogContainer onDismiss={onClose}>
                {isDialogOpen && <Dialog width="39.75rem">
                    <Heading UNSAFE_className="edit-error-dialog__heading">{props.heading}</Heading>
                    <Content>
                        <Flex direction="column" gap={"3vh"} marginTop={"3vh"}>
                            <Text UNSAFE_className="edit-error-dialog__description">
                                {props.description}
                            </Text>
                            <Link onPress={() => Utils.openInNewTab(props.learnMoreLink)} UNSAFE_className="edit-error-dialog__learn-more">
                                {intlHandler.formatMessage("learn-more")}
                            </Link>
                            <Divider size="M" />
                            <Flex justifyContent="end" width={"100%"}>
                                <ELButton variant="cta" onClick={onClose} size="L"><Text UNSAFE_className="edit-error-dialog__button-text">{intlHandler.formatMessage("close")}</Text></ELButton>
                            </Flex>
                        </Flex>
                    </Content>
                </Dialog>}
            </DialogContainer>
        </ELReactSpectrumV3Provider>
    )
}