/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Asset, Version } from "@elements/elementswebcommon";

//Application Specfic
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELCreationsPathResolver } from "./ELCreationsPathResolver";
import { ELAssetPathOrId } from "../../../../common/interfaces/storage/AssetTypes";
import { CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";

export class ELPhotoTextPathResolver extends ELCreationsPathResolver {

    async getCreationPreviewAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();
        switch (requestVersion) {
            case Version.V3: {
                if (!this.creationsData.outputs || !this.creationsData.outputs.preview) {
                    Logger.log(LogLevel.ERROR, "ELPhotoTextPathResolver: getCreationPreviewAssetPathOrId : V3, not able to find outputs or preview");
                    return Promise.reject();
                }
                return this.getAssetIdFromOutputInfo(this.creationsData.outputs.preview);
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELPhotoTextPathResolver: getCreationPreviewAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationFullAssetPathOrId(): Promise<ELAssetPathOrId> {
        const requestVersion = this.getCreationVersion();

        switch (requestVersion) {
            case Version.V3: {
                if (!this.creationsData.outputs || !this.creationsData.outputs.preview) {
                    Logger.log(LogLevel.ERROR, "ELPhotoTextPathResolver: getCreationFullAssetPathOrId : V3, not able to find outputs or outputs.preview");
                    return Promise.reject();
                }
                if (this.creationsData.status === CreationsStatus.success) {
                    return this.getAssetIdFromOutputInfo(this.creationsData.outputs.preview);
                }
                return this.getAssetPathFromOutputInfo(this.creationsData.outputs.preview);
            }
            default: {
                Logger.log(LogLevel.ERROR, "ELPhotoTextPathResolver: getCreationFullAssetPathOrId, wrong version, ", requestVersion);
                return Promise.reject();
            }
        }
    }

    async getCreationMasterAssetData(): Promise<Asset[]> {
        Logger.log(LogLevel.WARN, "ELPhotoTextPathResolver:getPSDCreationAssetID: ", "Function not supported for MagicalBackdrop");
        throw new Error("Function not supported for Collage");
    }

}