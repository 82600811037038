/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React, { useEffect, useRef, useState } from "react";
import { Flex } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";

import { RootState } from "../../../../stores/store";

import "./ELActionBar.scss";

export interface ELActionBarProps {
    items: Array<JSX.Element>
}

const ELActionBar = (props: ELActionBarProps): React.ReactElement => {
    // initially we can keep action bar "no-display" to ensure its not clickable as well
    const [animationClass, setAnimationClass] = useState("no-display");
    const prevSelectedCount = useRef<number>(0);
    const currentSelectedMediaCount = useSelector((state: RootState) =>
        (state.selectedMediaListReducer.map(asset => asset.assetId).filter(val => val) as string[] ?? []).length);
    const isImportProgressBarVisible = useSelector((state: RootState) => state.mediaGridToolbarStateReducer?.isImportProgressBarVisible ?? false);

    useEffect(() => {
        if (prevSelectedCount.current === 0 && currentSelectedMediaCount >= 1) {
            setAnimationClass("action-bar--fade-in");
        } else if (prevSelectedCount.current >= 1 && currentSelectedMediaCount === 0) {
            // Adding disabled for this bug: EO-4203766
            // this does not interfere with the animation as well
            setAnimationClass("action-bar--fade-out disabled");
        }
        return () => {
            // before next render capture the new previous values
            prevSelectedCount.current = currentSelectedMediaCount;
        }

    }, [currentSelectedMediaCount]);

    return (
        <>
            <Flex UNSAFE_className={`action-bar ${animationClass} ${isImportProgressBarVisible ? "action-bar--shift-up" : ""}`} direction="column">
                <Flex UNSAFE_className={"action-bar__button-group"} direction="row" justifyContent="space-between" >
                    {props.items}
                </Flex>
            </Flex>
        </>
    );

}

export default ELActionBar;
