/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export interface DropData {
    type: string,
    payload?: unknown
}

export default abstract class IDragDropHandler<CanvasType, MouseEventType> {
    protected _startDragging: boolean;
    constructor() {
        this._startDragging = false;
    }
    abstract startDragging(canvas: CanvasType, mouseEvent: MouseEventType): void;
    abstract stopDragging(canvas: CanvasType): void;
    abstract onDrop(canvas: CanvasType, mouseEvent: MouseEventType): DropData;
}
