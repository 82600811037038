/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { KeyboardEvent, useEffect, useRef, useState, useCallback, useReducer, Key } from "react";
import { useSelector } from "react-redux";

// Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { Divider, TextField, View, Text } from "@adobe/react-spectrum";

//Application Specific
import { ViewAction } from "../../../IBaseController";
import Utils from "../../../../utils/Utils";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import { RootState } from "../../../../stores/store";
import { CreationsData, CreationsStatus, MAX_PROJECT_TITLE_LENGTH } from "../../../../common/interfaces/creations/CreationTypes";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import {
    ELCreationsHeaderControllerAction,
    ELCreationsHeaderState,
    ELCreationsHeaderViewAction,
    ELCreationsHeaderViewProps,
    ELCreationsRenameStatus
} from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import ELComboBox from "../../organism/el-combo-box/ELComboBox";
import { CanvasZoomLevelAction, ZoomDropdownStrings, ZoomDropdownValues } from "../../../../common/interfaces/stage/StageTypes";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";
import { useNetwork } from "../../../../utils/hooks/useNetwork";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { BackButton, DownloadView, NoInternetSaveView, NoSpaceSaveView, PencilPlaceholder, SaveView, ShareView } from "../../organism/el-creations-header/ELCreationsHeaderComponents";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import KeyEventUtils from "../../../../utils/KeyEventUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import useCreationsUITitle from "../../../../utils/hooks/useCreationsUITitle";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

import "./ELCreationsHeader.scss";

function init(title: string): ELCreationsHeaderState {
    return {
        title: title,
        renameStatus: ELCreationsRenameStatus.success
    }
}

const reducer = (state: ELCreationsHeaderState, action: ViewAction): ELCreationsHeaderState => {
    const payload = action.payload as any;
    switch (action.type) {
        case ELCreationsHeaderViewAction.nameChanged:
            return {
                ...state,
                renameStatus: payload.status === true ? ELCreationsRenameStatus.success : ELCreationsRenameStatus.failure,
                title: payload.status === true ? payload.title : state.title
            };
        case ELCreationsHeaderViewAction.updateCreationsData:
            return {
                ...state,
                data: action.payload as CreationsData
            };
        case ELCreationsHeaderViewAction.changeName:
            return {
                ...state,
                renameStatus: payload
            };
        default:
            return state;
    }
}

const ELCollageHeaderView = (props: ELCreationsHeaderViewProps): React.ReactElement => {
    const [editingTitle, setEditingTitle] = useState(false);
    const [state, dispatch] = useReducer(reducer, init(props.data ? props.data.title : ""));
    const [uiTitle, setUITitle] = useState(state.title);
    const collageStoreData = useSelector((rootState: RootState) => rootState.collageReducer);
    const docStoreData = useSelector((rootState: RootState) => rootState.docStateReducer);
    const [zoomValue, setZoomValue] = useState("100%");
    const titleInputRef = useRef(null);
    const networkStatus = useNetwork(false);
    const prevName = useRef(state.title);

    const elCreationsDropBoxOptions = [
        { name: "Zoom Values(%)", children: ZoomDropdownValues },
        { name: "Zoom ShortCuts", children: ZoomDropdownStrings }
    ];

    const setEditing = (): void => {
        prevName.current = uiTitle;
        setEditingTitle(true);
    };

    useEffect(() => {
        props.controller.initialize(dispatch);
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useCreationsUITitle(uiTitle);

    useEffect(() => {
        setZoomValue(Utils.getPercentageFromNumber(docStoreData.zoomLevel));
    }, [docStoreData])

    useEffect(() => {
        setUITitle(state.title);
    }, [state.title]);

    useEffect(() => {
        if (titleInputRef.current) {
            const inputElement = (titleInputRef.current) as HTMLInputElement;
            inputElement.focus();
            if (inputElement.value)
                inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
        }
    }, [editingTitle]);

    const isSyncingEdit = (): boolean => {
        return state.renameStatus === ELCreationsRenameStatus.inProgress;
    }

    const isValidTitle = (): boolean => {
        return uiTitle.length !== 0 && uiTitle !== state.title;
    }

    const notifyTitleChange = (): void => {
        if (isValidTitle()) {
            dispatch({ type: ELCreationsHeaderViewAction.changeName, payload: ELCreationsRenameStatus.inProgress });
            props.controller.notify({
                type: ELCreationsHeaderControllerAction.changeName,
                payload: uiTitle
            });
        }
        else {
            if (uiTitle.length === 0) {
                setUITitle(prevName.current);
            }
            dispatch({ type: ELCreationsHeaderViewAction.changeName, payload: ELCreationsRenameStatus.inProgress });
            props.controller.notify({
                type: ELCreationsHeaderControllerAction.changeName,
                payload: prevName.current
            });
        }
        setEditingTitle(false);
    }

    const renameEnterKeyPress = useCallback((e: KeyboardEvent): void => {
        if (e.key.toLowerCase() === KeyboardKey.enter && !KeyEventUtils.isComposingEnter(e.nativeEvent)) {
            notifyTitleChange();
        }
    }, []);

    const onTextEditFocusChange = (isFocused: boolean): void => {
        setEditingTitle(isFocused);
        if (isFocused === false) {
            notifyTitleChange();
        }
    };

    const getCreatedDescription = (): string => {
        if (!state.data)
            return "";

        return CreationUtils.getCreatedDescription(state.data.creationDate);
    }

    const isCreationReady = (): boolean => {
        return !!state.data && collageStoreData.status === CreationsStatus.success;
    }

    const isCreationInProgress = (): boolean => {
        return collageStoreData.status === CreationsStatus.requested ||
            collageStoreData.status === CreationsStatus.editing;
    }

    const getHeaderClass = (): string => {
        let headerClass = "creation-header__text-box";
        if (isCreationInProgress())
            headerClass = " disabled";
        return headerClass;
    }

    const onSelectionChange = (value: Key): void => {
        if (value !== null) {
            switch (value) {
                case CanvasZoomLevelAction.zoomToFit:
                    {
                        props.controller.notify({ type: CanvasZoomLevelAction.zoomToFit });
                        break;
                    }
                case CanvasZoomLevelAction.zoomToFill:
                    {
                        props.controller.notify({ type: CanvasZoomLevelAction.zoomToFill });
                        break;
                    }
                default:
                    {
                        props.controller.notify({ type: CanvasZoomLevelAction.changeZoomValue, payload: value });
                        break;
                    }
            }
        }
    }

    const addAsteriskIfSaveEnable = (): string => {
        if (docStoreData.isDirty === DocumentDirty.DIRTY) {
            return "*";
        } else return "";
    }

    const onChangeRenameTextField = (userInput: string): void => {
        const parseInputTitle = userInput.trimStart();
        setUITitle(parseInputTitle);
    }

    return (
        <ELReactSpectrumV3Provider>
            <ViewportProvider>
                <Flex UNSAFE_className="creation-header" gap="0.8rem" direction="row" alignItems="center">
                    <BackButton launchFeedbackDialogOnDontSave={CreationUtils.shouldLaunchCreationFeedbackDialog()} notify={props.controller.notify.bind(props.controller)}
                        hasDialog={docStoreData.isDirty === DocumentDirty.DIRTY}
                        headingDescription={IntlHandler.getInstance().formatMessage("photo-collage")} />
                    <Flex UNSAFE_className={getHeaderClass()} direction="row" gap="size-250" alignItems="center">
                        <View UNSAFE_className="creation-header__edit-box">
                            <span className={`creation-header__title-text
                             ${!isCreationReady() || editingTitle ? "no-display" : ""}`}
                                title={uiTitle}>
                                {uiTitle + addAsteriskIfSaveEnable()}
                            </span>
                            <TextField ref={titleInputRef} UNSAFE_className="creation-header__text-input" inputMode="text"
                                isHidden={!isCreationReady() || !editingTitle}
                                value={uiTitle} onChange={onChangeRenameTextField}
                                maxLength={MAX_PROJECT_TITLE_LENGTH}
                                onKeyDown={renameEnterKeyPress} onFocusChange={(isFocused) => onTextEditFocusChange(isFocused)} />
                        </View>
                        <Flex isHidden={!isCreationReady()} alignItems="baseline" gap="0.8rem">
                            <PencilPlaceholder isSyncingEdit={isSyncingEdit()}
                                editingTitle={editingTitle} setEditing={setEditing} />
                            <Divider orientation="vertical" size="S" />
                            <Text UNSAFE_className="creation-header__created-text">{getCreatedDescription()}</Text>
                        </Flex>
                    </Flex>

                    <Flex isHidden={!isCreationReady()}>
                        <ELComboBox options={elCreationsDropBoxOptions} onSelectionChange={(key: Key) => onSelectionChange(key)} defaultValue={zoomValue} inputValue={zoomValue} styleProps={{ caretColor: "transparent" }} />
                    </Flex>

                    <Flex isHidden={!isCreationReady()} UNSAFE_className="creation-header__cta-box"
                        direction="row" gap="1rem">
                        <Flex isHidden={networkStatus.online}>
                            <NoInternetSaveView />
                        </Flex>

                        <Flex isHidden={!AssetStorageUtils.hasQuotaExceeded() || !networkStatus.online}>
                            <NoSpaceSaveView />
                        </Flex>

                        <Flex isHidden={!CreationUtils.isSaveAvailable(networkStatus.online)}>
                            <SaveView controller={props.controller} docStoreData={docStoreData} />
                        </Flex>

                        <DownloadView notify={props.controller.notify.bind(props.controller)} />
                        <ShareView controller={props.controller} shareOptionController={props.shareOptionController} docStoreData={docStoreData} />
                    </Flex>
                </Flex >
            </ViewportProvider>
        </ELReactSpectrumV3Provider>
    );
}
export default ELCollageHeaderView;



