/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { API } from "@elements/elementswebcommon";

//Application Specific
import Logger, { LogLevel } from "../../utils/Logger";
import {
    CreationsStatus,
    CreationsData,
    RecommendationsData,
} from "../../common/interfaces/creations/CreationTypes";
import Utils from "../../utils/Utils";
import IMS from "../../services/IMS";

export interface RequestStatusWithProgress {
    status: CreationsStatus,
    progress: number
}

export default class ElementsCreationsService {
    private readonly _client = new API(`${process.env.REACT_APP_ELEMENTS_V1_URL}`);
    private readonly _coreserviceEndpoint = "coreservice";
    private readonly _recommendationEndpoint = "coreservice/recommendation";
    private readonly _metadataEndpoint = "coreservice/metadata";
    private readonly _createEndpoint = "coreservice/create";
    private readonly _editEndpoint = "coreservice/edit";
    private readonly _retryEndpoint = "coreservice/retry";
    private readonly _projectStatusEndpoint = "coreservice/status";

    private readonly _queryTypeRequest = "request";
    private readonly _queryTypeProject = "project";

    private static _instance: ElementsCreationsService | null = null;

    static getInstance(): ElementsCreationsService {
        if (ElementsCreationsService._instance === null)
            ElementsCreationsService._instance = new ElementsCreationsService();
        return ElementsCreationsService._instance;
    }

    private constructor() {
        if (!process.env.REACT_APP_ELEMENTS_V1_URL)
            Logger.log(LogLevel.WARN, "ElementsCreationsService:constructor: ", "REACT_APP_ELEMENTS_V1_URL not set");
        return;
    }

    private _getCommonHeaders = (): Record<string, string> => {
        return {
            "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`,
            "x-api-key": `${process.env.REACT_APP_ELEMENTS_API_KEY}`
        }
    }

    private _getQueryParameterOfType = (type: string): Record<string, string> => {
        return {
            "type": type
        }
    }

    private _isJobActive = (jobStatus: unknown): boolean => {
        if (jobStatus === CreationsStatus.requested)
            return true;
        return false;
    }

    private _isRecommendationsJobActive = (jobStatus: unknown): boolean => {
        if (jobStatus === CreationsStatus.requested) {
            return true;
        }
        return false;
    }

    private _checkRecommendationsJobFinished = async (requestId: string): Promise<CreationsStatus> => {
        const requestStatus = await this.getRecommendationStatus(requestId);
        const POLLING_CALL_GAP = 2000;
        if (this._isRecommendationsJobActive(requestStatus)) {
            await Utils.wait(POLLING_CALL_GAP);
            return this._checkRecommendationsJobFinished(requestId);
        }

        return requestStatus;
    }

    private _checkCreationsJobFinished = async (projectId: string): Promise<CreationsStatus> => {
        const jobStatus = await this.getCreationsStatus(projectId);

        if (this._isJobActive(jobStatus)) {
            await Utils.wait(2000);
            return this._checkCreationsJobFinished(projectId);
        }

        return jobStatus;
    }

    getRecommendationStatusWithProgress = async (requestId: string): Promise<RequestStatusWithProgress> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders()
                },
                params: {
                    ...this._getQueryParameterOfType(this._queryTypeRequest)
                }
            };
            const recommendationStatus = await this._client.get(this._projectStatusEndpoint + "/" + requestId, options);
            return Promise.resolve(recommendationStatus.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:_getRecommendationsJobStatus: ", error);
            return Promise.reject(CreationsStatus.error);
        }
    }

    getRecommendationStatus = async (requestId: string): Promise<CreationsStatus> => {
        try {
            const recommendationStatus = await this.getRecommendationStatusWithProgress(requestId);
            return Promise.resolve(recommendationStatus.status);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:getRecommendationStatus: ", error);
            return Promise.reject(CreationsStatus.error);
        }
    }

    getCreationsStatusWithProgress = async (projectId: string): Promise<RequestStatusWithProgress> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders()
                },
                params: {
                    ...this._getQueryParameterOfType(this._queryTypeProject)
                }
            };
            const projectStatus = await this._client.get(this._projectStatusEndpoint + "/" + projectId, options);
            return Promise.resolve(projectStatus.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:getCreationsStatusWithProgress failed with error: " + error);
            return Promise.reject(CreationsStatus.error);
        }
    }

    getCreationsStatus = async (projectId: string): Promise<CreationsStatus> => {
        try {
            const projectStatus = await this.getCreationsStatusWithProgress(projectId);
            return Promise.resolve(projectStatus.status);
        } catch (error) {
            Logger.log(LogLevel.ERROR, error);
            return Promise.reject(CreationsStatus.error);
        }
    }

    getRecommendationsRequestData = async (requestId: string): Promise<RecommendationsData> => {
        try {
            const options = {
                headers: this._getCommonHeaders(),
                params: {
                    ...this._getQueryParameterOfType(this._queryTypeRequest)
                }
            };
            const requestEndPoint = this._coreserviceEndpoint + "/" + requestId;
            const recommendationsData = await this._client.get(requestEndPoint, options)
            return Promise.resolve(recommendationsData.data)
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:getRecommendationsRequestData: ", error);
            return Promise.reject();
        }
    }

    getCreationsData = async (): Promise<CreationsData[]> => {
        try {
            const options = {
                headers: this._getCommonHeaders(),
            }
            const creationsData = await this._client.get(this._coreserviceEndpoint, options);
            return Promise.resolve(creationsData.data.projects);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:getCreationsData: ", error);
            return Promise.reject();
        }
    }

    createCreation = async (creationsJob: unknown): Promise<string> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders(),
                    "Content-Type": "application/json",
                    "Accept": "*/*"
                }
            };

            const projectData = await this._client.post(creationsJob, options, this._createEndpoint);
            return Promise.resolve(projectData.data.projectId);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:createCreation: ", error);
            return Promise.reject();
        }
    }

    createRecommendations = async (recommendationJobSchema: unknown): Promise<string> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders(),
                    "Content-Type": "application/json",
                    "Accept": "*/*"
                }
            };
            const recommendationData = await this._client.post(recommendationJobSchema, options, this._recommendationEndpoint);
            return Promise.resolve(recommendationData.data.requestId);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:createRecommendations: ", error);
            return Promise.reject();
        }
    }

    editCreation = async (projectId: string,
        creationsJobProjectSchema: unknown): Promise<string> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders(),
                    "Content-Type": "application/json",
                    "Accept": "*/*"
                }
            };

            const editProjectEndpoint = this._editEndpoint + "/" + projectId;
            const projectData = await this._client.post(creationsJobProjectSchema, options, editProjectEndpoint);
            return Promise.resolve(projectData.data.projectId);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:editCreation: ", error);
            return Promise.reject();
        }
    }

    retryCreation = async (projectId: string): Promise<string> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders(),
                    "Content-Type": "application/json",
                    "Accept": "*/*"
                }
            };

            const retryProjectEndpoint = this._retryEndpoint + "/" + projectId;
            const projectData = await this._client.post({}, options, retryProjectEndpoint);
            return Promise.resolve(projectData.data.projectId);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:retryCreation: ", error);
            return Promise.reject();
        }
    }

    pollRecommendationStatus = async (requestId: string): Promise<CreationsStatus> => {
        return await this._checkRecommendationsJobFinished(requestId);
    }

    pollProjectStatus = async (projectId: string): Promise<CreationsStatus> => {
        return await this._checkCreationsJobFinished(projectId);
    }

    getProjectData = async (projectId: string): Promise<CreationsData> => {
        try {
            const options = {
                headers: this._getCommonHeaders(),
                params: {
                    ...this._getQueryParameterOfType(this._queryTypeProject)
                }
            }
            const projectEndPoint = this._coreserviceEndpoint + "/" + projectId;
            const projectData = await this._client.get(projectEndPoint, options);
            return Promise.resolve(projectData.data);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:getProjectData: ", error);
            return Promise.reject();
        }
    }

    renameCreation = async (projectId: string, title: string): Promise<boolean> => {
        try {
            const options = {
                headers: this._getCommonHeaders(),
            }
            const projectEndPoint = this._metadataEndpoint + "/" + projectId;
            await this._client.patch({ title }, options, projectEndPoint);
            return Promise.resolve(true);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:renameCreation: ", error);
            return Promise.reject(false);
        }
    }

    updateCreationStatus = async (projectId: string, status: CreationsStatus): Promise<boolean> => {
        try {
            const options = {
                headers: this._getCommonHeaders(),
            }
            const projectEndPoint = this._metadataEndpoint + "/" + projectId;
            await this._client.patch({ status: status }, options, projectEndPoint);
            return Promise.resolve(true);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:updateCreationStatus: ", error);
            return Promise.reject(false);
        }
    }

    notifyCreation = async (projectId: string): Promise<boolean> => {
        try {
            const options = {
                headers: this._getCommonHeaders(),
            }
            const projectEndPoint = this._metadataEndpoint + "/" + projectId;
            await this._client.patch({ notify: true }, options, projectEndPoint);
            return Promise.resolve(true);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:notifyCreation: ", error);
            return Promise.reject(false);
        }
    }


    deleteRecommendations = async (requestId: string, action: "all" | "active" = "all"): Promise<boolean> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders(),
                },
                params: {
                    ...this._getQueryParameterOfType(this._queryTypeRequest)
                }
            };

            const endPoint = this._coreserviceEndpoint + "/" + requestId + "?action=" + action;
            await this._client.delete(endPoint, options);
            return Promise.resolve(true);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:deleteRecommendations: ", error);
            return Promise.resolve(false);
        }
    }

    deleteCreation = async (projectId: string, action: "all" | "active" = "all"): Promise<boolean> => {
        try {
            const options = {
                headers: {
                    ...this._getCommonHeaders(),
                },
                params: {
                    ...this._getQueryParameterOfType(this._queryTypeProject)
                }
            };

            const projectEndPoint = this._coreserviceEndpoint + "/" + projectId + "?action=" + action;
            await this._client.delete(projectEndPoint, options);
            return Promise.resolve(true);
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ElementsCreationsService:deleteCreation: ", error);
            return Promise.resolve(false);
        }
    }
}