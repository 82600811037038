/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export const enum ELNavBarViewAction {
    appendHighlightNavBar = "APPEND_HIGHLIGHT_NAV_BAR",
    removeHighlightNavBar = "REMOVE_HIGHLIGHT_NAV_BAR",
}

export const enum ELNavBarControllerAction {
    tabSelectionChanged = "TAB_SELECTION_CHANGED",
    buyNow = "BUY_NOW",
    whatsNewIconClick = "WHATS_NEW_ICON_CLICK",
    startFreeTrial = "START_FREE_TRIAL",
    createWithElements = "CREATE_WITH_ELEMENTS"
}

export const enum ELFeedbackNavControllerAction {
    feedbackIconClick = "FEEDBACK_ICON_CLICK"
}