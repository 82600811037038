/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

//Application Specific
import { ControllerAction } from "../../../IViewController";
import ITemplateViewController from "../../../ITemplateViewController";
import ELImageEditWorkflowHeaderView from "./ELImageEditWorkflowHeaderView";
import { ViewAction } from "../../../IBaseController";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../../workspaces/IWorkflow";
import { ShareOptions } from "../../organism/el-share-options/ELShareOptions";
import { CreationsDownloadFileType } from "../../../../common/interfaces/creations/CreationTypes";
import store from "../../../../stores/store";
import { history } from "../../../../utils/Utils";
import { ELAdobeAsset } from "../../../../common/interfaces/storage/AssetTypes";
import { ELImageEditWorkflowHeaderViewAction } from "../../../../common/interfaces/editing/editWorkspace/ELImageEditWorkflowTypes";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { ELCreationsHeaderControllerAction } from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { ELQRSharePayload, ShareTypeCategory } from "../../../../common/interfaces/share/ShareTypes";
import { ShareOptionsActionType } from "../../organism/el-share-options/ELShareOptionsView";
import { IngestLogObjectKey, IngestLogObjectCustomKey, IngestWorkflowTypes, IngestEventSubTypes, IngestEventTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import Logger, { LogLevel } from "../../../../utils/Logger";

export default class ELImageEditWorkflowHeader extends ITemplateViewController {
    private _shareOptions: ShareOptions;
    private _backButtonHeading: string;
    private _downloadOptions: CreationsDownloadFileType[];
    private _currentAsset?: ELAdobeAsset;

    constructor(owner: IWorkflow, shareOptions: ShareOptions, backButtonHeading: string, downloadOptions?: CreationsDownloadFileType[]) {
        super(owner);
        this._shareOptions = shareOptions;
        this._backButtonHeading = backButtonHeading;
        this._downloadOptions = downloadOptions ?? this._getDownloadOptions();
    }

    private _getDownloadOptions(): CreationsDownloadFileType[] {
        const radioButtonList = [CreationsDownloadFileType.jpeg, CreationsDownloadFileType.png];
        return radioButtonList;
    }

    private async _updateFileName(): Promise<void> {
        if (this._currentAsset) {
            const assetData = await AssetStorageUtils.resolveAssetInfo(this._currentAsset);
            const name = assetData.name?.split(".")[0] ?? "";
            this.viewDispatcher?.({ type: ELImageEditWorkflowHeaderViewAction.changeName, payload: name });
        }
    }

    private async _sharePopoverClicked(): Promise<void> {
        if (this._currentAsset?.assetId) {
            const payload: ELQRSharePayload = {
                assetsToShare: [this._currentAsset.assetId],
                category: ShareTypeCategory.media,
                source: WorkflowsName.imageEditWorkflow
            }
            this._shareOptions.notify({ type: ShareOptionsActionType.createShareQR, payload: payload });
        }
    }

    private _ingestShareClick(): void {
        const shareMode = store.getState().shareReducer.shareMode;
        const shareType = IngestUtils.shareTypeToIngest(store.getState().shareReducer.shareType);
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.shareChannel;
        additionalLogInfo[IngestLogObjectKey.eventCount] = shareMode;
        this.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.click, IngestEventTypes.share, shareType, additionalLogInfo)
        });
    }

    private async _shareEdit(): Promise<void> {
        if (this._currentAsset?.assetId) {
            this._ingestShareClick();
            this._shareOptions.notify({
                type: ShareOptionsActionType.openShareWorkspace, payload: {
                    mediaList: [this._currentAsset.assetId],
                    workflowName: WorkflowsName.imageEditWorkflow
                }
            });
        }
    }

    protected async _createShareQR(): Promise<void> {
        if (!this._currentAsset || !this._currentAsset.assetId) {
            Logger.log(LogLevel.DEBUG, "[_createShareQR]: Please debug how _currentAsset is empty up till here");
            return Promise.reject("_createShareQR: currentAsset is empty");
        }

        const payload: ELQRSharePayload = {
            assetsToShare: [this._currentAsset.assetId],
            category: ShareTypeCategory.media,
            source: WorkflowsName.imageEditWorkflow
        };
        this._shareOptions.notify({ type: ShareOptionsActionType.createShareQR, payload: payload });
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const editWorkflowHeader = React.createElement(ELImageEditWorkflowHeaderView, {
            controller: this,
            backButtonDialogHeading: this._backButtonHeading,
            shareOptionController: this._shareOptions,
            radioButtonList: this._downloadOptions
        });

        const editWorkflowHeaderWithRouter = React.createElement(Router, { history }, editWorkflowHeader);
        const editWorkflowHeaderWithProvider = React.createElement(Provider, { store }, editWorkflowHeaderWithRouter);

        ReactDOM.render(editWorkflowHeaderWithProvider, container);
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroy(): void {
        super.destroy();
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case ELImageEditWorkflowHeaderViewAction.updateCurrentAsset: {
                this._currentAsset = action.payload as ELAdobeAsset[];
                this.notify({ type: ELImageEditWorkflowHeaderViewAction.updateFileName });
                handled = true;
                break;
            }
            case ELImageEditWorkflowHeaderViewAction.updateFileName: {
                await this._updateFileName();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.sharePopoverClicked: {
                await this._sharePopoverClicked();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.share: {
                await this._shareEdit();
                handled = true;
                break;
            }
            case ELCreationsHeaderControllerAction.back: {
                this.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventSubTypes.click, IngestEventSubTypes.back, true)
                });
                break;
            }
            case ELCreationsHeaderControllerAction.createShareQR: {
                await this._createShareQR();
                handled = true;
                break;
            }
            case ELImageEditWorkflowHeaderViewAction.updateBackDialogState: {
                const isDialogOpen = action.payload ? action.payload as boolean : false;
                this.viewDispatcher?.({ type: ELImageEditWorkflowHeaderViewAction.updateBackDialogState, payload: isDialogOpen });
                handled = true;
                break;
            }
        }

        if (!handled)
            handled = await this._owner.notify(action as WorkflowAction);

        return handled;
    }
} 