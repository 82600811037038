/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application specific
import IMS from "../../../../services/IMS";
import IViewController, { ControllerAction } from "../../../IViewController";
import App from "../../../../app/App";
import NavbarView from "./ELNavbarView";
import { ViewAction } from "../../../IBaseController";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import { CreationAppSubscriberType, CreationInAppNotifierAction, CreationStatusData } from "../../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { ELNavBarControllerAction, ELNavBarViewAction } from "../../../../common/interfaces/navbar/ELNavBarTypes";
import { Routes } from "../../../../app/AppRoute";
import CreationInAppNotifier from "../../../../workspaces/creations/utils/CreationInAppNotifier";
import store from "../../../../stores/store";

//Utils
import { LoginUtils, UserAllowedUtils } from "../../../../utils/LoginUtils";
import { ShareUtils } from "../../../../utils/ShareUtils";
import { TrialUtils } from "../../../../utils/TrialUtils";
import Utils, { history } from "../../../../utils/Utils";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import ELHelpMenuNav from "../../templates/el-help-menu-nav/ELHelpMenuNav";
import { UserUtils } from "../../../../utils/UserUtils";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes, IngestLogObjectCustomKey } from "../../../../utils/IngestConstants";
import { EliveUtils } from "../../../../utils/EliveUtils";
import ELFeedbackNav from "../../templates/el-feedback-nav/ELFeedbackNav";
import { URLConstants } from "../../../../utils/Constants/URLConstants";
import ELWhatsNewNav from "../../templates/el-whatsnew-nav/ELWhatsNewNav";
import { IngestLogging } from "../../../../services/IngestWrapper";
import { ELCreateOnDemandAction } from "../../../../common/interfaces/creations/ELCreateOnDemandTypes";

const getNavBarToPrefsName = (route: HighlightNavBarRoutes): string => {
	const clickedSuffix = "_nav_bar_click_count";
	return route + clickedSuffix;
};

enum HighlightNavBarRoutes {
	creations = "/creations"
}

const WHATS_NEW_APP_VERSION = 0;

export default class NavBar extends IViewController {
	private _app: App;
	private _helpMenuNavBar: ELHelpMenuNav;
	private _feedbackNav: ELFeedbackNav;
	private _whatsNewNav: ELWhatsNewNav;
	private readonly _helpMenuNavContainer = "helpmenu-container";
	private readonly _feedbackIconContainer = "feedback-icon-container";
	private readonly _whatsNewIconContainer = "whats-new-icon-container";

	constructor(app: App) {
		super();
		this._app = app;
		this._helpMenuNavBar = new ELHelpMenuNav(this);
		this._feedbackNav = new ELFeedbackNav(this);
		this._whatsNewNav = new ELWhatsNewNav(this, this.shouldAllowNavigation);
	}

	private _shouldHighlightCreationsTabForNewCreations(): boolean {
		return (!!this.prefData && ((this.prefData[getNavBarToPrefsName(HighlightNavBarRoutes.creations)] as number) === 0));
	}

	private _highlightCreationsTab(): void {
		if (this._shouldHighlightCreationsTabForNewCreations()) {
			this.viewDispatcher?.call(this.viewDispatcher, {
				type: ELNavBarViewAction.appendHighlightNavBar,
				payload: Routes.CREATIONS
			});
		}
	}

	private _onTabChanged(key: string): void {
		if (this.prefData) {
			const route = key as HighlightNavBarRoutes;
			if (Object.values(HighlightNavBarRoutes).includes(route)) {
				const clickCount = this.prefData[getNavBarToPrefsName(route)] as number;
				this.prefData[getNavBarToPrefsName(route)] = clickCount + 1;
				this.updatePref();
			}
		}
	}

	private _getLogInfoWithViewType(): Record<string, string> {
		const additionalLogInfo: Record<string, string> = {};
		const daysInTrial = store.getState().appReducer.daysInTrial;
		additionalLogInfo[IngestLogObjectCustomKey.viewType] = EliveUtils.getUserState(daysInTrial);
		return additionalLogInfo;
	}

	createView(container: HTMLElement): void {
		super.createView(container);
		const navbarView = React.createElement(NavbarView, {
			controller: this,
			signedIn: IMS.getInstance().isSignedInUser(),
			canStartFreeTrial: this.canStartFreeTrial()
		});
		const router = React.createElement(Router, { history }, navbarView);
		const viewPortAwareRouter = React.createElement(ViewportProvider, null, router);
		const reduxWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, viewPortAwareRouter);
		ReactDOM.render(
			reduxWrappedProvider,
			container
		);
	}

	destroyView(): void {
		if (!ShareUtils.isLinkPreviewWorkflow()) {
			this._helpMenuNavBar.destroyView();
			this._feedbackNav.destroyView();
			this._whatsNewNav.destroyView();
		}
		if (this.container) {
			ReactDOM.unmountComponentAtNode(this.container);
		}
		super.destroyView();
	}

	initialize(dispatch?: React.Dispatch<ViewAction>): void {
		super.initialize(dispatch);
		if (!ShareUtils.isLinkPreviewWorkflow() && IMS.getInstance().isSignedInUser()) {
			this._helpMenuNavBar.createView(this.ensureHTMLElement(this._helpMenuNavContainer));
			this._feedbackNav.createView(this.ensureHTMLElement(this._feedbackIconContainer));
			this._whatsNewNav.createView(this.ensureHTMLElement(this._whatsNewIconContainer));
		}

		this.readPrefs();
		this._highlightCreationsTab();
		CreationInAppNotifier.subscribe(this, CreationAppSubscriberType.statusChange);
		const value = Number(localStorage.getItem("_whats-new-version")) ?? 0;
		if (this.shouldAllowNavigation() && (value <= WHATS_NEW_APP_VERSION)) {
			this._onWhatsNewClick();
			localStorage.setItem("_whats-new-version", String(WHATS_NEW_APP_VERSION + 1));
		}
	}

	destroy(): void {
		super.destroy();
		CreationInAppNotifier.unsubscribe(this, CreationAppSubscriberType.statusChange);
	}

	home(): void {
		this._app.home();
	}

	login(): void {
		const additionalLogInfo = this._getLogInfoWithViewType();
		this.notify({
			type: WorkflowActionType.ingest,
			payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.navbar, IngestEventTypes.click,
				IngestEventSubTypes.signIn, true, additionalLogInfo)
		});
		LoginUtils.signIn();
	}

	getPrefsKeys(): string[] {
		return Object.values(HighlightNavBarRoutes).map((route) => getNavBarToPrefsName(route));
	}

	readPrefs(): void {
		this.prefData = {};
		this.getPrefsKeys().forEach(prefType => {
			const value = Number(localStorage.getItem(prefType)) ?? 0;
			if (this.prefData) {
				this.prefData[prefType] = value;
			}
		});
	}

	updatePref(): void {
		const updatePref: { key: string, value: string; }[] = [];
		this.getPrefsKeys().forEach(prefType => {
			updatePref.push({
				key: prefType,
				value: this.prefData ? this.prefData[prefType] as string : "0"
			});
		});
		this.writePrefs(updatePref);
	}

	shouldShowMinimalOptions(daysInTrial: number): boolean {
		return !(TrialUtils.isTrialExpired(daysInTrial) || UserAllowedUtils.isUserAllowed(daysInTrial)) || !IMS.getInstance().isSignedInUser() ||
			ShareUtils.isLinkPreviewWorkflow() || !Utils.isValidRoutePath(window.location.pathname);
	}

	shouldAllowNavigation(): boolean {
		const daysInTrial = store.getState().appReducer.daysInTrial;
		return UserAllowedUtils.isUserAllowed(daysInTrial) && IMS.getInstance().isSignedInUser() && !ShareUtils.isLinkPreviewWorkflow() && Utils.isValidRoutePath(window.location.pathname);
	}

	canStartFreeTrial(): boolean {
		return !(IMS.getInstance().isSignedInUser() || ShareUtils.isLinkPreviewWorkflow())
	}

	private _onWhatsNewClick(): void {
		this._whatsNewNav.notify({ type: ELNavBarControllerAction.whatsNewIconClick });
		IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.whatsNew,
			IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventTypes.success, IngestEventSubTypes.render, "auto")));
	}

	async notify<T extends ControllerAction>(action: T): Promise<boolean> {
		let handled = false;
		switch (action.type) {
			case WorkflowActionType.ingest:
				handled = await this._app.notify(action);
				break;
			case CreationInAppNotifierAction.creationStatusChanged: {
				const creationStatusData = action.payload as CreationStatusData;
				if (CreationUtils.shouldHighlightCreationsTab(window.location.pathname, creationStatusData)) {
					this.viewDispatcher?.call(this.viewDispatcher, {
						type: ELNavBarViewAction.appendHighlightNavBar,
						payload: Routes.CREATIONS
					});
				}
				handled = true;
				break;
			}
			case ELNavBarControllerAction.tabSelectionChanged: {
				this._onTabChanged(action.payload as string);
				handled = true;
				break;
			}
			case ELNavBarControllerAction.buyNow: {
				const additionalLogInfo = this._getLogInfoWithViewType();
				this.notify({
					type: WorkflowActionType.ingest,
					payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.navbar, IngestEventTypes.click,
						IngestEventSubTypes.buyNow, true, additionalLogInfo)
				});

				UserUtils.buyDesktop(URLConstants.GoURL.REACT_APP_SIGNED_OUT_BUY_DESKTOP_APP_URL);
				handled = true;
				break;
			}
			case ELNavBarControllerAction.startFreeTrial: {
				const additionalLogInfo = this._getLogInfoWithViewType();
				this.notify({
					type: WorkflowActionType.ingest,
					payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.navbar, IngestEventTypes.click,
						IngestEventSubTypes.startTrial, true, additionalLogInfo)
				});
				LoginUtils.signIn();
				handled = true;
				break;
			}
			case ELNavBarControllerAction.createWithElements: {
				this.notify({
					type: WorkflowActionType.ingest,
					payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventTypes.click,
						IngestEventSubTypes.createCreations, true)
				});
				Utils.openInNewTab(Routes.CREATIONS);
				handled = true;
				break;
			}
			case ELCreateOnDemandAction.workflowThumb: {
				await this._app.notify(action);
				handled = true;
				break;
			}
		}
		return handled;
	}
}
