/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import { fabric } from "fabric";

//Application Specific
import ITextPresetCreator, { ITextPreset } from "./ITextPresetCreator";
import { ELStageObjectType } from "../../../../common/interfaces/stage/StageTypes";
import { DocumentFormat } from "../../../../common/interfaces/document/DocumentTypes";
import ImageUtils from "../../../../utils/ImageUtils";
import { ELSize } from "../../../../common/interfaces/geometry/ELGeometry";
import { FontsStore } from "../../../../services/font/ELFontStore";
import ELPatternCreator from "../../../../editors/stage/patternCreator/ELPatternCreator";
import { ELPatternType } from "../../../../common/interfaces/stage/ELFabricPatternTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

interface IImageObjectProps {
    imageUrl: string,
    clipPath?: fabric.Text,
    visible?: boolean
}

interface IRectObjectProps {
    background: string,
    opacity?: number,
    visible?: boolean
}

export default class ELDynamicImageTextPresetCreator extends ITextPresetCreator {
    private readonly _dataUrlOptions = { format: DocumentFormat.PNG, quality: 1 };
    private readonly _backgroundPatternSqaureSize = 10;
    private readonly _textSpreadMargin = 0.1;
    private _documentSize!: ELSize;

    private async _getBackgroundObject(): Promise<fabric.Rect> {
        const patternCreator = new ELPatternCreator();
        const pattern = await patternCreator.getPattern(ELPatternType.checkBoard, { squareSize: this._backgroundPatternSqaureSize });
        const rectObject = new fabric.Rect({
            width: this._documentSize.width,
            height: this._documentSize.height,
            fill: pattern
        });
        return rectObject;
    }

    private _getRectObject(options: IRectObjectProps): fabric.Rect {
        const rectObject = new fabric.Rect({
            width: this._documentSize.width,
            height: this._documentSize.height,
            fill: options.background === "clear" ? "black" : options.background,
            opacity: options.opacity ?? 1,
            visible: options.visible ?? true
        });
        return rectObject;
    }

    private async _getTextObject(textPreset: ITextPreset): Promise<fabric.Text> {
        await FontsStore.getInstance().checkCacheAndLoadFont(textPreset.font);
        const stroke = textPreset.style.stroke ?? "black";
        const strokeWidth = textPreset.style.strokeWidth ?? 0;
        let shadowBlur: number | undefined;
        if (textPreset.style.shadow) {
            switch (textPreset.style.shadow) {
                case "low": shadowBlur = 5; break;
                case "mid": shadowBlur = 10; break;
                case "max": shadowBlur = 15; break;
            }
        }
        const text = IntlHandler.getInstance().formatMessage("photo-text-initial-text");
        const textObject = new fabric.IText(text, {
            type: ELStageObjectType.text,
            name: "original",
            left: 0,
            top: 0,
            width: this._documentSize.width,
            height: this._documentSize.height,
            absolutePositioned: true,
            selectable: true,
            perPixelTargetFind: false,
            fontFamily: textPreset.font,
            fill: "black",
            fontSize: 64,
            cursorColor: "blue",
            cursorWidth: 1,
            editable: true,
            textAlign: textPreset.style.alignment
        });
        if (stroke && strokeWidth) {
            textObject.set({ stroke: stroke, strokeWidth: strokeWidth });
        }
        if (shadowBlur) {
            textObject.set({ shadow: new fabric.Shadow({ color: "black", blur: shadowBlur }) });
        }
        this._applyTextPresetSpread(textObject, textPreset.style.spread);
        return textObject;
    }

    private _getImageObject(options: IImageObjectProps): Promise<fabric.Image> {
        return new Promise((resolve, reject) => {
            fabric.util.loadImage(options.imageUrl, function (img) {
                if (img === null) {
                    reject("image couldn't be loaded");
                } else {
                    fabric.Image.fromURL(options.imageUrl, (image) => {
                        image.set({
                            objectCaching: false,
                            originX: "left",
                            originY: "top",
                            left: 0,
                            top: 0,
                            clipPath: options.clipPath,
                            absolutePositioned: true,
                            selectable: false,
                            crossOrigin: "anonymous",
                            visible: options.visible ?? true
                        });

                        resolve(image);
                    });
                }
            }, { crossOrigin: "anonymous" });
        });
    }

    private _applyTextPresetSpread(textObject: fabric.Text, spread: string): void {
        if (!textObject.width || !textObject.height) {
            throw new Error("ELDynamicImageTextPresetCreator - (_applyTextPresetSpread): object not set or width/height not defined");
        }

        const scaleX = (this._documentSize.width * (1 - this._textSpreadMargin)) / textObject.width;
        const scaleY = (this._documentSize.height * (1 - this._textSpreadMargin)) / textObject.height;
        const newleft = this._documentSize.width * this._textSpreadMargin / 2;
        const newTop = this._documentSize.height * this._textSpreadMargin / 2;

        switch (spread) {
            case 'fill': {
                textObject.set({ "scaleX": scaleX, "scaleY": scaleY, "top": newTop, "left": newleft });
                break;
            }
            case 'fit': {
                const scaledWidth = this._documentSize.width * (1 - this._textSpreadMargin);
                const scaledHeight = this._documentSize.height * (1 - this._textSpreadMargin);
                textObject.set({ "scaleX": Math.min(scaleX, scaleY), "scaleY": Math.min(scaleX, scaleY) });
                textObject.set({ "left": newleft + scaledWidth / 2 - textObject.getScaledWidth() / 2, "top": newTop + scaledHeight / 2 - textObject.getScaledHeight() / 2 });
                break;
            }
        }
    }

    private async _createTextPresetUrl(textPreset: ITextPreset, imageUrl: string): Promise<string> {
        const htmlcanvas: HTMLCanvasElement = document.createElement('canvas');
        htmlcanvas.width = this._documentSize.width;
        htmlcanvas.height = this._documentSize.height;
        const fabricCanvas = new fabric.Canvas(htmlcanvas, { width: this._documentSize.width, height: this._documentSize.height });

        const backgroundObject = await this._getBackgroundObject();

        const isBackgroundVisible = textPreset.background === "clear" ? false : true;
        const backgroundImageObject = await this._getImageObject({ imageUrl: imageUrl, visible: isBackgroundVisible });
        const rectObject = this._getRectObject({ background: textPreset.background, opacity: textPreset.backgroundOpacity ? textPreset.backgroundOpacity / 100 : 1, visible: isBackgroundVisible });

        const textObject = await this._getTextObject(textPreset);
        const clipTextObject = fabric.util.object.clone(textObject);
        const imageObject = await this._getImageObject({ imageUrl: imageUrl, clipPath: clipTextObject });

        fabricCanvas.add(backgroundObject, backgroundImageObject, rectObject, textObject, imageObject);
        fabricCanvas.requestRenderAll();
        const presetUrl = fabricCanvas.toDataURL(this._dataUrlOptions);
        return presetUrl;
    }

    async getPresetUrls(textPresets: ITextPreset[], imageUrl: string): Promise<string[]> {
        this._documentSize = await ImageUtils.getImageSizeFromURL(imageUrl);
        const presetUrlPromises = [];
        for (let i = 0; i < textPresets.length; i++) {
            presetUrlPromises.push(this._createTextPresetUrl(textPresets[i], imageUrl));
        }
        const presetUrls = await Promise.all(presetUrlPromises);
        return Promise.resolve(presetUrls);
    }
}