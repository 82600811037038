/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import { useSelector } from "react-redux";
import React, { ReactElement, useEffect } from "react";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";

//Application specific
import { RootState } from "../../../../stores/store";
import IViewController from "../../../IViewController";
import { EliveUtils } from "../../../../utils/EliveUtils";
import { UserState } from "../../../../common/interfaces/elive/EliveTypes";
import ELDesktopBuyBanner from "../../organism/el-desktop-buy/ELDesktopBuyBanner";
import ELQRBanner from "../../organism/el-qr-banner/ELQRBanner";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELCTABannerView from "../../organism/el-cta-banner/ELCTABanner";
import { ELUserHomeStaticContentActions } from "../../../../common/interfaces/home/UserHomeTypes";
import { ELFaq } from "../../organism/el-faq/ELFaq";
import { VISIBLE_FAQ_COUNT } from "../../../../utils/Constants/Constants";

interface ELUserHomeStaticContentProps {
    controller: IViewController,
    faqs: Record<string, ReactElement>
}

const ELUserHomeStaticContentView = (props: ELUserHomeStaticContentProps): React.ReactElement => {
    const daysInTrial = useSelector((state: RootState) => state.appReducer.daysInTrial);
    const intlHandler = IntlHandler.getInstance();

    const handleLearnMoreClicked = (): void => {
        props.controller.notify({ type: ELUserHomeStaticContentActions.learnMoreClicked });
    }

    const handleTakeSurveyClicked = (): void => {
        props.controller.notify({ type: ELUserHomeStaticContentActions.takeSurveyClicked });
    }

    const canShowQRBanner = (userState: UserState): boolean => {
        return (userState !== UserState.trialExpired);
    }

    const getCTABanner = (userState: UserState): React.ReactElement => {
        switch (userState) {
            case UserState.trialExpired:
                return <ELCTABannerView variant={"primary"} text={intlHandler.formatMessage("media-availability-trial-expire")} ctaText={intlHandler.formatMessage("learn-more")} ctaHandler={handleLearnMoreClicked} ctaDataTestId="trial-expired-learn-more" />
            case UserState.trialInProgress:
            case UserState.trialNotStarted:
            case UserState.paid:
                return <ELCTABannerView variant={"primary"} text={intlHandler.formatMessage("survey-description")} ctaText={intlHandler.formatMessage("take-survey")} ctaHandler={handleTakeSurveyClicked} />
            default:
                return <></>
        }
    }

    const getQRBanner = (userState: UserState): React.ReactElement => {
        return (canShowQRBanner(userState) ? <ELQRBanner /> : <></>);
    }

    useEffect(() => {
        props.controller.initialize();
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    return (
        <Flex gap="5rem" direction="column" data-testid="user-home-static-content">
            <Flex direction="column" marginStart={"9.3%"} marginEnd={"9.3%"} gap="5rem">
                {getQRBanner(EliveUtils.getUserState(daysInTrial))}
                <ELDesktopBuyBanner />
                {getCTABanner(EliveUtils.getUserState(daysInTrial))}
            </Flex>
            <ELFaq faqs={props.faqs} visibleCount={VISIBLE_FAQ_COUNT} />
        </Flex>
    );
}

export default ELUserHomeStaticContentView;