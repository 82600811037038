/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { API, ELRenditionType, isAxiosError } from "@elements/elementswebcommon";

import { DataResponseType, MediaDataResponse } from "../utils/AssetStorageUtils";
import Constants, { PROCESSING_THUMBDATA, PROMISE_FULFILLED } from "../utils/Constants/Constants";
import Logger, { LogLevel } from "../utils/Logger";
import { ELAdobeAsset, elDeserializeAsset, RenditionData, ELRenditionOptions } from "../common/interfaces/storage/AssetTypes";
import { AxiosResponse } from "axios";
import { InvitationRenditionState, InvitationVideoResponseType } from "../common/interfaces/invitation/InvitationTypes";

/* 
    "/auth/${asset.assetId}" endpoint
*/
export interface InvitationAuthResponseType {
    accessURL?: string,
    accessToken?: string,
    resourceType?: string,
    isPrivate: boolean
}

export enum InvitationServiceHeaderRelations {
    rendition = "\"http://ns.adobe.com/adobecloud/rel/rendition\"",
    download = "\"http://ns.adobe.com/adobecloud/rel/download\"",
    repoMetadata = "\"http://ns.adobe.com/adobecloud/rel/metadata/repository\"",
    primary = "\"http://ns.adobe.com/adobecloud/rel/primary\"",
    embedded = "\"http://ns.adobe.com/adobecloud/rel/metadata/embedded\"",
}

export interface MediaThumbsResponse {
    ownerId: string,
    creationDate: string,
    assetDataMap: Map<string, string>
}

export const ERROR_GETTING_URL_FOR_RELATION =  "Could not find valid URL for given relation";

export class InvitationServiceWrapper {
    private readonly _invitationClient = new API(`${process.env.REACT_APP_INVITATION_API_URL}`);
    private readonly _authEndpoint = "auth";
    private static _singletonInstance: InvitationServiceWrapper | null = null;

    static getInstance(): InvitationServiceWrapper {
        if (InvitationServiceWrapper._singletonInstance !== null) return InvitationServiceWrapper._singletonInstance;
        InvitationServiceWrapper._singletonInstance = new InvitationServiceWrapper();
        return InvitationServiceWrapper._singletonInstance;
    }

    private constructor() {
        Logger.log(LogLevel.INFO, "Creating Singleton Instance of InvitationServiceWrapper");
    }

    private _getInvitationAPIConfig(): Record<string, Record<string, string>> {
        return {
            headers: {
                "x-api-key": `${process.env.REACT_APP_INVITATION_API_KEY}`
            },
            params: {
                "cdnAcceleration": "true"
            }
        }
    }

    private _getRelationAPIConfig(accessToken: string, responseType: DataResponseType, acceptType = "*/*"): Record<string, Record<string, string> | string> {
        return {
            headers: {
                "x-access-token": `${accessToken}`,
                "x-api-key": `${process.env.REACT_APP_INVITATION_API_KEY}`,
                "Accept": `${acceptType}`
            },
            responseType: responseType,
        }
    }

    private _getDownloadAPIConfig(accessToken: string): Record<string, Record<string, string> | string> {
        return {
            headers: {
                "x-access-token": `${accessToken}`,
                "x-api-key": `${process.env.REACT_APP_INVITATION_API_KEY}`
            }
        }
    }

    // TODO: urgent: diagarwa: need to optimise it
    private _parseAccessURLs(accessURL: string, relation: string): string {
        const accessURLs = accessURL.split(', ');

        for (let j = 0; j < accessURLs.length; j++) {
            //segregate key value pairs in each url
            const keyValues = accessURLs[j].split('; ');

            //traverse the key value pairs in each URL and find one with rel as in argument
            for (let i = 0; i < keyValues.length; i++) {
                const segregatedKeyValue = keyValues[i].split('=');
                if (segregatedKeyValue.length >= 2 && segregatedKeyValue[0] === "rel" && segregatedKeyValue[1] === relation) {
                    const url = keyValues[i - 1].substring(1, keyValues[i - 1].length - 1);
                    if (url.indexOf("{") !== -1) {
                        return url.substring(0, url.indexOf('{'));
                    }
                    return url;
                }
            }
        }
        throw new Error(ERROR_GETTING_URL_FOR_RELATION);
    }

    async getAssetAccessInfo(assetId?: string): Promise<InvitationAuthResponseType> {
        try {
            if (!assetId) {
                return Promise.reject();
            }
            const response = await this._invitationClient
                .get(`${this._authEndpoint}/${assetId}`, this._getInvitationAPIConfig())
                .then((response) => {
                    // Response vetting
                    if (response.data?.accessURL && response.data?.accessToken && response.data?.resourceType)
                        return response;
                    else {
                        Logger.log(LogLevel.ERROR, "InvitationServiceWrapper:getAssetAccessInfo: ", response);
                        throw new Error("[getAccessCredentialsForAsset]: malformed invitation API response");
                    }
                })
                .then((response) => {
                    return {
                        accessURL: response.headers.link,
                        accessToken: response.data.accessToken,
                        resourceType: response.data.resourceType,
                        isPrivate: false
                    }
                });
            return response;
        } catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (isAxiosError(error) && error.response!.status === Constants.HTTP_RESPONSE_NOT_FOUND_404) {
                Logger.log(LogLevel.INFO, assetId, `InvitationServiceWrapper:getAssetAccessInfo: ${assetId} is private`, error);
                return Promise.resolve({ isPrivate: true });
            }
            Logger.log(LogLevel.ERROR, assetId, `InvitationServiceWrapper:getAssetAccessInfo: error fetching ${assetId}`, error);
            return Promise.reject();
        }
    }
    /** 
     * Internal method to get array of Promise of "get" request for asset info (urls are from invitation service header)
     * @param  {ELAdobeAsset[]} assetList
     * @param  {Map<string, InvitationAuthResponseType>} assetCredentials
     * @returns {Promise<AxiosResponse<any> | ELAdobeAsset>[]} Array of Promise object containing either result or failure
     */
    private _getAssetRepoData(assetList: ELAdobeAsset[], assetCredentials: Map<string, InvitationAuthResponseType>):
        Promise<AxiosResponse<any> | ELAdobeAsset>[] {

        const assetRepoDataPromises = [];
        for (const asset of assetList) {
            const accessURLs = assetCredentials.get(asset.assetId ?? "")?.accessURL;
            const accessToken = assetCredentials.get(asset.assetId ?? "")?.accessToken;
            if (!accessURLs || !accessToken) {
                assetRepoDataPromises.push(Promise.resolve(asset));
                continue;
            }
            try {
                const repoInfoURL = this._parseAccessURLs(accessURLs, InvitationServiceHeaderRelations.repoMetadata);
                const repoInfoConfig = this._getRelationAPIConfig(accessToken, "json");
                const repoInfoClient = new API(repoInfoURL);
                assetRepoDataPromises.push(repoInfoClient.get("", repoInfoConfig));
            } catch (e) {
                // _parseAccessURLs can give error and in that case also we want rejected promise
                Logger.log(LogLevel.ERROR, "InvitationServiceWrapper:_getAssetRepoData: Error calling repo end point for asset:", asset, e);
                assetRepoDataPromises.push(Promise.reject());
            }
        }
        return assetRepoDataPromises;
    }
    /**
     * @param  {ELAdobeAsset[]} assetList
     * @param  {Map<string,InvitationAuthResponseType> } assetCredentials
     * @returns Promise<ELAdobeAsset[]> Asset info from repo Metadata
     * Turns to be a no op in case of failure. No exception thrown.
     */
    async getAssetInfo(assetList: ELAdobeAsset[], assetCredentials: Map<string, InvitationAuthResponseType>): Promise<ELAdobeAsset[]> {
        if (!assetList.length || !assetCredentials.size) {
            return Promise.reject();
        }
        try {
            const assetRepoDataPromises = this._getAssetRepoData(assetList, assetCredentials);
            const response = await Promise.allSettled(assetRepoDataPromises);
            const enrichedPublicAssetList: ELAdobeAsset[] = [];
            response.forEach((element, i) => {
                if (element.status === PROMISE_FULFILLED && (element.value as AxiosResponse).status === Constants.HTTP_RESPONSE_OK_200) {
                    const assetData = elDeserializeAsset((element.value as AxiosResponse).data);
                    // we should not pick created by data
                    enrichedPublicAssetList.push(assetData);
                } else {
                    enrichedPublicAssetList.push(assetList[i]);
                }
            })
            return Promise.resolve(enrichedPublicAssetList);
        } catch (e) {
            Logger.log(LogLevel.ERROR, "InvitationServiceWrapper:getAssetInfo: Some error while enriching assets", e);
            return Promise.resolve(assetList);
        }
    }


    private _getVideoDataFromJson(data: InvitationVideoResponseType): RenditionData {
        const state = data.renditionsStatus.state;
        if (state === InvitationRenditionState.processing || state === InvitationRenditionState.queued) {
            return { imgData: PROCESSING_THUMBDATA, videoData: PROCESSING_THUMBDATA };
        }
        const posterFrame = data.posterframe?.url ?? "";
        // 'at' is not supported by safari 
        const videoSrc = data.renditions?.filter((e) => e?.videoContainer === "MP4")[0]?.url ?? "";
        return { imgData: posterFrame, videoData: videoSrc, videoMetaData: { dimensionsInfo: data.originalDimensions } };
    }

    async fetchRendition(accessURL?: string, accessToken?: string, renditionRelation?: string, renditionOptions?: ELRenditionOptions, responseType: DataResponseType = "arraybuffer"): Promise<MediaDataResponse> {
        if (!accessURL || !accessToken || !renditionRelation) {
            return Promise.reject();
        }
        try {
            let renditionApiURL = this._parseAccessURLs(accessURL, renditionRelation);
            const renditionConfig = this._getRelationAPIConfig(accessToken, responseType, renditionOptions?.type);

            const size = renditionOptions?.size;
            if (size && size !== 0)
                renditionApiURL += `;size=` + size;
            const renditionClient: API = new API(renditionApiURL);
            const renditionResponse = await (renditionClient.get("", renditionConfig));
            const responseData = renditionOptions?.type === ELRenditionType.VIDEO_METADATA ? this._getVideoDataFromJson(renditionResponse.data as InvitationVideoResponseType) : renditionResponse.data;
            const response = { data: responseData, statusCode: renditionResponse.status, responseType: responseType };
            return Promise.resolve(response);
        } catch (error) {
            Logger.log(LogLevel.ERROR, `InvitationServiceWrapper:fetchRendition: Error while fetching thumb data using accessURL: ${accessURL}`, error);
            return Promise.reject();
        }
    }

    private _getAPIConfig(accessToken: string, acceptType = "application/json"): Record<string, Record<string, string> | string> {
        return {
            headers: {
                "x-access-token": `${accessToken}`,
                "x-api-key": `${process.env.REACT_APP_INVITATION_API_KEY}`,
                "Accept": `${acceptType}`
            },
        }
    }

    async fetchAssetEmbeddedInfo(assetId?: string): Promise<AxiosResponse> {
        if (!assetId) {
            return Promise.reject();
        }
        try {
            const assetInfo = await this.getAssetAccessInfo(assetId);
            const accessURL = assetInfo.accessURL;
            const accessToken = assetInfo.accessToken;

            if (!assetInfo || assetInfo.isPrivate || !accessURL || !accessToken) {
                return Promise.reject();
            }

            const embeddedAPIURL = this._parseAccessURLs(accessURL, InvitationServiceHeaderRelations.embedded);
            const embeddedConfig = this._getAPIConfig(accessToken);
            const embeddedClient = new API(embeddedAPIURL);
            const response = await (embeddedClient.get("", embeddedConfig));

            if (response) {
                return Promise.resolve(response);
            } else {
                return Promise.reject();
            }
        } catch (error) {
            Logger.log(LogLevel.INFO, `[fetchAssetEmbeddedInfo]: Error while getting embedded data for asset: ${assetId}`, error);
            return Promise.reject();
        }
    }

    /**
    * @returns public url that can be used to download asset
    *
    * @param accessURL URL returned by invitation service's auth API
    * @param accessToken Token required to access accessURL
    */

    async getPublicDownloadURL(assetId?: string): Promise<string> {
        if (!assetId) {
            return Promise.reject();
        }
        try {
            const assetInfo = await this.getAssetAccessInfo(assetId);
            const accessURL = assetInfo.accessURL;
            const accessToken = assetInfo.accessToken;

            if (!assetInfo || assetInfo.isPrivate || !accessURL || !accessToken) {
                return Promise.reject();
            }

            const downloadAPIURL = this._parseAccessURLs(accessURL, InvitationServiceHeaderRelations.download);
            const downloadConfig = this._getDownloadAPIConfig(accessToken);
            const downloadClient = new API(downloadAPIURL);
            const downloadResponse = await (downloadClient.get("", downloadConfig));
            const downloadURL = downloadResponse.data.href;

            if (downloadURL) {
                return Promise.resolve(downloadURL);
            } else {
                Logger.log(LogLevel.ERROR, `InvitationServiceWrapper:getPublicDownloadURL: No download href present, access url used: ${accessURL}`);
                return Promise.reject();
            }
        } catch (error) {
            Logger.log(LogLevel.ERROR, `InvitationServiceWrapper:getPublicDownloadURL: Error while getting download data for asset: ${assetId}`, error);
            return Promise.reject();
        }
    }
}