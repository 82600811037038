/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { AxiosResponse } from "axios";
import Utils from "../../utils/Utils";

//Adobe Internal
import { API } from "@elements/elementswebcommon";

//Application specific
import IMS from "./../IMS";
import Logger, { LogLevel } from "../../utils/Logger";

export class FloodgateServiceWrapper {
    private static _instance: FloodgateServiceWrapper | null = null;
    private _serviceAPI: API;
    private readonly _endpoint: string = "feature";

    static getInstance(): FloodgateServiceWrapper {
        if (FloodgateServiceWrapper._instance === null)
            FloodgateServiceWrapper._instance = new FloodgateServiceWrapper();
        return FloodgateServiceWrapper._instance;
    }

    constructor() {
        this._serviceAPI = new API(`${process.env.REACT_APP_FLOODGATE_API_URL}`);
    }

    private _getFloodgateFeaturesAPIConfig(): Record<string, Record<string, string>> {
        return {
            headers: {
                "x-api-key": `${process.env.REACT_APP_FLOODGATE_API_KEY}`,
                "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
            },
            params: {
                "clientId": `${process.env.REACT_APP_FLOODGATE_CLIENT_ID}`,
                "meta": "true",
                "elementsLanguage": `${Utils.getCurrentLocaleInSnakeCase()}`,
            }
        }
    }

    async fetchFloodgateFeatures(): Promise<AxiosResponse> {
        try {
            const config = this._getFloodgateFeaturesAPIConfig();
            const response = await this._serviceAPI.get(this._endpoint, config);
            return Promise.resolve(response);
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "FloodgateServiceWrapper:fetchFloodgateFeatures: ", error);
            return Promise.reject(error);
        }
    }
}
