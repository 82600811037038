/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import axios from "axios";

//Application Specific
import { AperitifData } from "../../common/interfaces/services/AperitifTokenServiceTypes";
import IMS from "../IMS";

export default class AperitifTokenService {
  private static _instance?: AperitifTokenService;

  static getInstance(): AperitifTokenService {
    if (!AperitifTokenService._instance) {
      AperitifTokenService._instance = new AperitifTokenService();
    }
    return AperitifTokenService._instance;
  }


  async getAperitifData(storageCount = 1): Promise<AperitifData> {
    const apiKey = process.env.REACT_APP_APERITIF_SERVICE_API_KEY;
    const api = process.env.REACT_APP_APERITIF_API_URL;

    if (!apiKey || !api) {
      return Promise.reject("REACT_APP_APERITIF_SERVICE_API_KEY or REACT_APP_APERITIF_API_URL missing!");
    }

    const response = await axios.get(`${api}/storage?num=${storageCount}`, {
                            headers: {
                                "X-Api-Key": apiKey,
                                "Authorization": `Bearer ${IMS.getInstance().getUserAccessToken()}`
                              },
                    });

    const aperitifData = response.data as unknown as AperitifData;

    const aperitifDataStatusSucceeded = "succeeded";
    
    if(aperitifData.status === aperitifDataStatusSucceeded) {
      return response.data;
    }

    return Promise.reject("Invalid AperitifData!");
  }
}
