/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactNode, useEffect, useState, useRef } from "react";

//Adobe Internal
import { FocusScope } from "@react-aria/focus";
import { KeyboardProps, useKeyboard } from "@react-aria/interactions";
import { ActionButton } from "@adobe/react-spectrum";
import { StyleProps } from '@react-types/shared';
import { useStyleProps } from "@react-spectrum/utils";
import Close from "@spectrum-icons/workflow/Close";

//Application Specific
import { useViewport } from "../../../../utils/hooks/useViewport";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";
import "./ELDialog.scss";

export interface ELDialogProps extends StyleProps, KeyboardProps {
    handleEscape: () => void,
    overlayDiv?: HTMLDivElement,
    isDismissible?: boolean
    children?: ReactNode,
    dataTestId?: string
}

const ELDialog = (props: ELDialogProps): React.ReactElement => {
    const dialogBoxChildrenRef = useRef(null);
    const [animateClass, setAnimateClass] = useState("");
    const {
        handleEscape,
        overlayDiv,
        isDismissible,
        children,
        ...otherProps
    } = props;
    const { styleProps } = useStyleProps(otherProps);

    const { width, height, isMobile } = useViewport();

    const { keyboardProps } = useKeyboard({
        onKeyDown: (e) => {
            if (e.key.toLowerCase() === KeyboardKey.escape) {
                props.handleEscape();
            }
            if (props.onKeyDown)
                props.onKeyDown(e);
        }
    });

    const resizeObserverCallback = (): void => {
        window.requestAnimationFrame(() => {
            if (dialogBoxChildrenRef.current) {
                const dialogBox = dialogBoxChildrenRef.current as HTMLElement;
                const dialogContentBox = dialogBox.querySelector(".dialog__content-container") as HTMLElement;
                if (dialogContentBox) {
                    const dialogBoxHeight = dialogBox.clientHeight;
                    const dialogBoxHeaderHeight = Math.abs(dialogContentBox.getBoundingClientRect().top - dialogBox.getBoundingClientRect().top);
                    const dialogContentScrollHeight = dialogBoxHeight - dialogBoxHeaderHeight;

                    const DIALOG_CONTENT_BOTTOM_PADDING = 20;
                    dialogContentBox.style.height = `${dialogContentScrollHeight - DIALOG_CONTENT_BOTTOM_PADDING}px`;
                }
            }
        });
    }

    const dialogBoxChildrenResizeObserver = new ResizeObserver(resizeObserverCallback);

    useEffect(() => {
        if (dialogBoxChildrenRef.current)
            dialogBoxChildrenResizeObserver.observe(dialogBoxChildrenRef.current);
        return () => {
            if (dialogBoxChildrenRef.current)
                dialogBoxChildrenResizeObserver.unobserve(dialogBoxChildrenRef.current);
        }
    }, [dialogBoxChildrenRef.current]);

    const [parentRect, setParentRect] = useState({ top: 0, left: 0, width: 0, height: 0 });
    useEffect(() => {
        if (props.overlayDiv) {
            setParentRect({
                top: props.overlayDiv.offsetTop,
                left: props.overlayDiv.offsetLeft,
                width: getOverlayWidth(),
                height: getOverlayHeight()
            });
        }
    }, [props.overlayDiv, width, height]);

    useEffect(() => {
        if (parentRect.height !== 0 && parentRect.width !== 0)
            setAnimateClass("dialog-box__animate");
    }, [width, height]);

    const getOverlayWidth = (): number => {
        if (props.overlayDiv)
            return props.overlayDiv.offsetWidth;
        return 0;
    }

    const getOverlayHeight = (): number => {
        if (props.overlayDiv)
            return props.overlayDiv.offsetHeight;
        return 0;
    }

    return (
        <FocusScope autoFocus>
            <div {...keyboardProps} className={"dialog-box " + animateClass} tabIndex={0}
                style={{
                    top: !isMobile ? parentRect.top : 0,
                    left: !isMobile ? parentRect.left : 0,
                    width: !isMobile ? parentRect.width : "100%",
                    height: !isMobile ? parentRect.height : "100%",
                }} data-testid={props.dataTestId}>
                <div className={"dialog-box__children " + styleProps.className} ref={dialogBoxChildrenRef} >
                    {isDismissible &&
                        <ActionButton position="absolute" right="size-0" isQuiet
                            onPress={props.handleEscape}
                            marginTop="1rem"
                            marginEnd="1rem" width="1.875rem" height="1.875rem" zIndex={1}>
                            <Close width="1.875rem" height="1.875rem" />
                        </ActionButton>
                    }
                    {props.children}
                </div>
            </div>
        </FocusScope>
    )
}

export default ELDialog;
