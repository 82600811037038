/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// Third-party
import platform from "platform";

//Application
import Logger, { LogLevel } from "./Logger";

export class PlatformUtils {

    static getUserAgent(): string {
        return navigator.userAgent;
    }

    static getBuildVersion(): string {
        return document?.querySelector("meta[name='build-sha']")?.getAttribute("content") ?? "";
    }

    static isIpadOS(): boolean {
        /**
         * Older iPads has iPad in userAgent, newer has Macintosh in userAgent.
         * This is because Apple promises "Desktop-Class browsing with Safari" on iPad
         * https://www.apple.com/newsroom/2019/06/the-new-ipados-powers-unique-experiences-designed-for-ipad/
         * Hence this work-around.
         **/
        return PlatformUtils.deviceHasTouchSupport() &&
            (/Macintosh/.test(navigator.userAgent) || /iPad/.test(navigator.userAgent));
    }
    /**
     * @returns string: Platform description
     */
    static description(): string | undefined {
        return platform.description;
    }
    /**
     * @returns string: Browser version
     */
    static browserVersion(): string | undefined {
        return platform.version;
    }

    /**
     * @returns string: OS family 
     */
    static osFamily(): string | undefined {
        /**
         * platform library hasn't been update for 2020, hence doesn't support new iPad 
         * which is what is found as per bug: https://jira.corp.adobe.com/browse/EO-4204258
         **/
        return PlatformUtils.isIpadOS() ? "iPadOS" : (platform.os as Record<string, string | undefined>).family;
    }

    /**
     * @returns string: ipad/phone etc. For desktop it is usually null
     */
    static deviceType(): string | undefined {
        return platform.manufacturer;
    }

    /**
     * @returns string: OS version
     */
    static osVersion(): string | undefined {
        return (platform.os as any).version;
    }

    /**
     * @returns boolean - true if running on android
     */
    static isRunningOnAndroid(): boolean {
        Logger.log(LogLevel.INFO, "PlatformUtils(isRunningOnAndroid) userAgent: ", navigator.userAgent);

        if (/Android/i.test(navigator.userAgent)) {
            return true;
        }

        return false;
    }

    /**
     * 
     * @returns boolean - true if running on mac platform
     */
    static isRunningOnMacOS(): boolean {
        return this.osFamily() === "OS X";
    }

    /**
     * 
     * @returns boolean - true if running on safari browser
     */
    static isRunningOnSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    /**
     * @returns boolean - true if running on mobile
     * should use https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData once available on Safari
     */
    static isMobileDevice(width: number, height: number): boolean {
        const minimumSupportedDesktopWidth = 1024;
        const isMobileDevice = Math.max(width, height) < minimumSupportedDesktopWidth;

        return isMobileDevice && PlatformUtils.deviceHasTouchSupport();
    }

    /**
     * @returns boolean - true if device has touch support
     * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/maxTouchPoints - has full browser support
     */
    static deviceHasTouchSupport(): boolean {
        return navigator.maxTouchPoints > 0;
    }

    static isAHandheldDevice = (width: number, height: number): boolean => {
        if(PlatformUtils.isMobileDevice((width as number), (height as number)) || PlatformUtils.isIpadOS()) {
            return true;
        }
        return false;
    }
}