/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { ReactElement, useEffect } from "react";

//Adobe internal
import { Text, Flex } from "@adobe/react-spectrum";

//Application Specific
import ELButton from "../../../../../view/components/atoms/el-button/ELButtonView";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import IViewController from "../../../../../view/IViewController";

import "./../../ShareWorkspaceView.scss";

export interface LinkGenerationErrorActionProps {
    actionLabel?: string,
    actionDescription?: string,
    onAction?: () => void
}

interface LinkGenerationErrorViewProps {
    controller: IViewController,
    children?: ReactElement,
    text: string,
    actionProps?: LinkGenerationErrorActionProps
}

export enum LinkGenerationErrorControllerAction {
    continuePressed = "CONTINUE_PRESSED",
    cancelPressed = "CANCEL_PRESSED"
}

export const LinkGenerationErrorView = (props: LinkGenerationErrorViewProps): React.ReactElement => {

    useEffect(() => {
        props.controller.initialize();
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const intlHandler = IntlHandler.getInstance();
    const { actionProps } = props;

    const onCancelClick = (): void => {
        props.controller.notify({ type: LinkGenerationErrorControllerAction.cancelPressed })
    }

    return (
        <Flex UNSAFE_className="share-dialog__content-container">
            <Flex UNSAFE_className="share-dialog__description">
                {props.children ?? <></>}
                <Text justifySelf="center" alignSelf="center" UNSAFE_className="share-dialog__msg">{props.text}</Text>
            </Flex>
            {(actionProps?.actionLabel && actionProps?.actionDescription) ? <Text UNSAFE_className="share-dialog__msg">{actionProps?.actionDescription}</Text> : <></>}
            <Flex UNSAFE_className="share-dialog__cta">
                {actionProps?.actionLabel ? <ELButton size="L" onClick={actionProps?.onAction}>
                    <Text UNSAFE_className="dialog__cta-text">{actionProps?.actionLabel}</Text>
                </ELButton> : <></>}
                <ELButton size="L" onClick={() => {onCancelClick()}}>
                    <Text UNSAFE_className="dialog__cta-text">{intlHandler.formatMessage("cancel")}</Text>
                </ELButton>
            </Flex>
        </Flex>
    )
}


