/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Application specific
import { ViewAction } from "../view/IBaseController";
import IViewController, { ControllerAction } from "../view/IViewController";
import Logger, { LogLevel } from "../utils/Logger";
import IBaseWorkspace, { WorkspaceActionType } from "./IBaseWorkspace";
import Utils from "../utils/Utils";

import "./Workflow.scss";

type WorkflowInitType = string;

export enum WorkflowsName {
    slideshow = "SLIDESHOW",
    creationsPreview = "CREATIONS_PREVIEW",
    creationsHome = "CREATIONS_HOME",
    creationsComingSoon = "CREATIONS_COMING_SOON",
    consent = "CONSENT",
    linkGeneration = "Link Generation",
    emailShare = "Email Share",
    linkShare = "Link Share",
    facebookShare = "Facebook Share",
    sharedMedia = "SHARED_MEDIA",
    linkGenerationError = "Link Generation Error",
    mediaManager = "MEDIA_MANAGER",
    publicLanding = "PUBLIC_LANDING",
    userHome = "USER_HOME",
    noAccessUserHome = "NO_ACCESS_USER_HOME",
    noPageFound = "NO_PAGE_FOUND",
    mediaGrid = "MEDIA_GRID",
    singleImageView = "SINGLE_IMAGE_VIEW",
    collage = "COLLAGE",
    replaceMediaManager = "REPLACE_MEDIA_MANAGER",
    patternOverlay = "PATTERN_OVERLAY",
    peekThrough = "PEEK_THROUGH",
    movingOverlay = "MOVING_OVERLAY",
    replaceBackground = "MAGICAL_BACKDROP",
    tryThisInDesktop = "TRY_THIS_IN_DESKTOP",
    imageEditWorkflow = "IMAGE_EDIT_WORKFLOW",
    sampleMediaManager = "SAMPLE_MEDIA_MANAGER",
    photoText = "PHOTO_TEXT"
}

export enum WorkflowActionType {
    initialize = "INITIALIZE",
    ingest = "INGEST"
}

export interface WorkflowPayload {
    initMode?: WorkflowInitType,
    nextWorkflowInitMode?: WorkflowInitType,
    nextWorkflow?: WorkflowsName,
    payload?: unknown;
}

export interface WorkflowAction extends WorkflowPayload {
    type: string;
}

export default abstract class IWorkflow extends IViewController {
    protected _owner: IBaseWorkspace;
    protected workflowName: WorkflowsName;
    protected prevWorkflow?: IWorkflow;
    protected initAction?: WorkflowAction;

    get getWorkflowName(): WorkflowsName {
        return this.workflowName;
    }

    get getPrevWorkflow(): IWorkflow | undefined {
        return this.prevWorkflow;
    }

    constructor(owner: IBaseWorkspace, workflowName: WorkflowsName) {
        super();
        this._owner = owner;
        this.workflowName = workflowName;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    destroyView(): void {
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
        this.prevWorkflow = undefined;
    }

    getWorkflowConfiguration(): Record<string, unknown> {
        return { name: this.workflowName };
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> { return this.notifyWorkflow(action as WorkflowAction); }

    protected async notifyWorkflow<T extends WorkflowAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkflowActionType.ingest:
                if (!Utils.isInputWellDefined(action.payload)) return handled;
                handled = await this._owner.getNonModalWorkspace.notify({
                    type: WorkspaceActionType.ingest,
                    payload: action.payload
                })
                return handled;
            default:
                Logger.log(LogLevel.WARN, "IWorkflow:notifyWorkflow: ", "Incorrect action item to workflow");
                break;
        }
        return handled;
    }

    protected ingest(payload: Record<string, string>): void {
        this._owner.notify({
            type: WorkspaceActionType.ingest,
            payload: payload
        });
    }

    startWorkflow<T extends WorkflowAction>(containerId: string, prevWorkflow?: IWorkflow, action?: T): void {
        this.prevWorkflow = prevWorkflow;
        this.initAction = action;
        this._owner.setWorkflow = this;
    }

    endWorkflow(): void {
        this.destroyView();
    }
}

