/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";

//Application Specific
import { ControllerAction } from '../../../IViewController';
import SlideshowMediaView from './SlideshowMediaView';
import { CreationMediaActionType } from '../el-creation-media-panel/ELCreationMediaView';
import { ViewAction } from '../../../IBaseController';
import Logger, { LogLevel } from '../../../../utils/Logger';
import store from '../../../../stores/store';
import ITemplateViewController from '../../../ITemplateViewController';

export default class SlideshowMedia extends ITemplateViewController {

	createView(container: HTMLElement): void {
		super.createView(container);

		const mediaView = React.createElement(SlideshowMediaView, { controller: this });
		const reactReduxProviderHydratedMediaView = React.createElement(Provider, { store: store }, mediaView);

		ReactDOM.render(reactReduxProviderHydratedMediaView, container);
	}

	destroyView(): void {
		if (this.container) {
			ReactDOM.unmountComponentAtNode(this.container);
		}
		super.destroyView();
	}

	initialize(dispatch?: React.Dispatch<ViewAction>): void {
		super.initialize(dispatch);
	}

	destroy(): void {
		super.destroy();
	}

	async notify<T extends ControllerAction>(action: T): Promise<boolean> {
		let handled = false;
		switch (action.type) {
			case CreationMediaActionType.manageMedia:
				{
					this._owner.notify(action);
					handled = true;
					break;
				}
			default:
				{
					Logger.log(LogLevel.WARN, "SlideshowMedia - notify : Invalid action" + action);
				}
		}
		return handled;
	}
}
