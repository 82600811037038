/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useState } from "react";
import _ from "lodash";

//Adobe Internal
import { Flex, TextField } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELPanelHeader from "../../atoms/el-panel-header/ELPanelHeader";
import IViewController from "../../../IViewController";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import CreationsAction from "../../../../stores/actions/CreationsAction";

import "./ELSlideshowIntroView.scss";

export interface ELSlideshowIntroViewProps {
    controller: IViewController
}

const ELSlideshowIntroView = (props: ELSlideshowIntroViewProps): React.ReactElement => {
    const [introText, setIntroText] = useState("");
    const slideshowStoreData = useSelector((rootState: RootState) => rootState.creationsReducer);
    const dispatch = useDispatch();
    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {
        Logger.log(LogLevel.INFO, "ELSlideshowIntroView - useEffect");
        props.controller.initialize();

        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        const slideshowId = slideshowStoreData.activeSlideshowId;
        if (slideshowId) {
            const uiData = slideshowStoreData.data[slideshowId]?.uiData;
            if (uiData && uiData.inputs && uiData.inputs.titles && uiData.inputs.titles[0]?.mainText) {
                setIntroText(uiData.inputs.titles[0].mainText);
            }
        }
    }, [slideshowStoreData.data]);

    const handleIntroTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = e.target.value;
        setIntroText(newValue);
        const slideshowId = slideshowStoreData.activeSlideshowId;
        if (slideshowId) {
            const uiData = _.cloneDeep(slideshowStoreData.data[slideshowId].uiData);
            if (uiData && uiData.inputs && uiData.inputs.titles) {
                uiData.inputs.titles[0].mainText = newValue;
                dispatch(CreationsAction.updateUIData(uiData));
            }
        }
    }

    return (
        <>
            <div className="slideshow-panel-box">
                <ELPanelHeader title={intlHandler.formatMessage("intro-text")} alignTitle="start" />
                <div className="slideshow-panel-description">{intlHandler.formatMessage("intro-play-description")}</div>
                <div className="slideshow-panel__content-box">
                    <Flex direction="column" alignItems="start" gap="size-100" marginTop="size-200" width="100%">
                        <TextField data-test-id="slideshow-intro-text-field" value={introText} onInput={handleIntroTextChange} width="100%" />
                    </Flex>
                </div>
            </div>
        </>
    )
}

export default ELSlideshowIntroView;