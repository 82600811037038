/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useRef } from "react";

//Adobe internal
import { useButton } from "@react-aria/button";

//Application specific
import { IconType } from "../../../../assets/IconConstants"
import { Theme } from "../../../../utils/Theme"
import { ELIcon } from "../../atoms/el-icon/ELIconView"
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";

import "./ELManageMediaButton.scss"

interface ELManageMediaButtonProps {
    isEnabled: boolean,
    onPress: (() => void),
    title: string
}

const ELManageMediaButton = (props: ELManageMediaButtonProps): React.ReactElement => {
    const manageMediaRef = useRef(null);
    const intlHandler = IntlHandler.getInstance();
    const { buttonProps } = useButton({
        onPress: () => {
            props.onPress();
        }
    }, manageMediaRef);

    return (
        <div data-test-id="manage-media-control"
            className={props.isEnabled ? "manage-media-box" : ("manage-media-box disabled")}
            ref={manageMediaRef} {...buttonProps}>
            <ELIcon iconkey={IconType.slideshowManageMedia}
                fill={Theme.light.light_gray_color_N} width="1rem" height="1rem" />
            <span>{intlHandler.formatMessage(props.title)}</span>
        </div>)
}

ELManageMediaButton.defaultProps = {
    title: "manage-media"
}

export default ELManageMediaButton;
