/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { Key, KeyboardEvent, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { useSelector } from "react-redux";

//Adobe Internal
import { Divider, Flex, Text, TextField, View } from "@adobe/react-spectrum";

//Application Specific
import {
    ELCreationsHeaderControllerAction, ELCreationsHeaderState, ELCreationsHeaderViewAction,
    ELCreationsHeaderViewProps, ELCreationsRenameStatus
} from "../../../../common/interfaces/creations/ELCreationsHeaderTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ViewportProvider } from "../../../../utils/hooks/useViewport";
import { BackButton, DownloadView, ELSwitch, NoInternetSaveView, NoSpaceSaveView, PencilPlaceholder, SaveView, ShareView } from "../../organism/el-creations-header/ELCreationsHeaderComponents";
import { useNetwork } from "../../../../utils/hooks/useNetwork";
import { RootState } from "../../../../stores/store";
import { AssetStorageUtils } from "../../../../utils/AssetStorageUtils";
import { ViewAction } from "../../../IBaseController";
import { CreationsData, CreationsStatus, MAX_PROJECT_TITLE_LENGTH, UNTITLED_INTL_KEY } from "../../../../common/interfaces/creations/CreationTypes";
import ELComboBox from "../../organism/el-combo-box/ELComboBox";
import { CanvasZoomLevelAction, ZoomDropdownStrings, ZoomDropdownValues } from "../../../../common/interfaces/stage/StageTypes";
import Utils from "../../../../utils/Utils";
import { DocumentDirty } from "../../../../common/interfaces/document/DocumentTypes";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";
import ELOpenInDesktopButton from "../../molecules/el-open-in-desktop-button/ELOpenInDesktopButton";
import CreationUtils from "../../../../workspaces/creations/utils/CreationUtils";
import KeyEventUtils from "../../../../utils/KeyEventUtils";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import useCreationsUITitle from "../../../../utils/hooks/useCreationsUITitle";

import "./ELCreationsHeader.scss";

function init(title: string): ELCreationsHeaderState {
    return {
        title: title,
        renameStatus: ELCreationsRenameStatus.success
    }
}

const reducer = (state: ELCreationsHeaderState, action: ViewAction): ELCreationsHeaderState => {
    const payload = action.payload as any;
    switch (action.type) {
        case ELCreationsHeaderViewAction.nameChanged: {
            return {
                ...state,
                renameStatus: payload.status === true ? ELCreationsRenameStatus.success : ELCreationsRenameStatus.failure,
                title: payload.status === true ? payload.title : state.title
            };
        }
        case ELCreationsHeaderViewAction.updateCreationsData: {
            return {
                ...state,
                data: action.payload as CreationsData
            };
        }
        case ELCreationsHeaderViewAction.changeName: {
            return {
                ...state,
                renameStatus: payload
            }
        }
        default:
            return state;
    }
}

const ELPeekThroughHeaderView = (props: ELCreationsHeaderViewProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const networkStatus = useNetwork(false);
    const docStoreData = useSelector((rootState: RootState) => rootState.docStateReducer);
    const peekThroughStoreData = useSelector((rootState: RootState) => rootState.peekThroughReducer);
    const defaultTitle = intlHandler.formatMessage(UNTITLED_INTL_KEY);
    const [state, dispatch] = useReducer(reducer, init(props.data ? props.data.title : defaultTitle));
    const [zoomValue, setZoomValue] = useState("100%");
    const [editingTitle, setEditingTitle] = useState(false);
    const [uiTitle, setUITitle] = useState(state.title);
    const titleInputRef = useRef(null);

    const elCreationsDropBoxOptions = [
        { name: "Zoom Values(%)", children: ZoomDropdownValues },
        { name: "Zoom ShortCuts", children: ZoomDropdownStrings }
    ];

    const setEditing = (): void => {
        setEditingTitle(true);
    };

    useCreationsUITitle(uiTitle);

    useEffect(() => {
        props.controller.initialize(dispatch);

        //called during clean-up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        setZoomValue(Utils.getPercentageFromNumber(docStoreData.zoomLevel));
    }, [docStoreData]);

    useEffect(() => {
        setUITitle(state.title);
    }, [state.title]);

    useEffect(() => {
        if (titleInputRef.current) {
            const inputElement = (titleInputRef.current) as HTMLInputElement;
            inputElement.focus();
            if (inputElement.value)
                inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
        }
    }, [editingTitle]);

    const isSyncingEdit = (): boolean => {
        return state.renameStatus === ELCreationsRenameStatus.inProgress;
    }

    const isValidTitle = (): boolean => {
        return uiTitle.length !== 0 && uiTitle !== state.title;
    }

    const isSaveAvailable = (): boolean => {
        if (!networkStatus.online || AssetStorageUtils.hasQuotaExceeded()) {
            return false;
        }
        return true;
    }

    const notifyTitleChange = (): void => {
        if (isValidTitle()) {
            dispatch({ type: ELCreationsHeaderViewAction.changeName, payload: ELCreationsRenameStatus.inProgress });
            props.controller.notify({
                type: ELCreationsHeaderControllerAction.changeName,
                payload: uiTitle
            });
        }
        setEditingTitle(false);
    }

    const renameEnterKeyPress = useCallback((e: KeyboardEvent): void => {
        if (e.key.toLowerCase() === KeyboardKey.enter && !KeyEventUtils.isComposingEnter(e.nativeEvent)) {
            notifyTitleChange();
        }
    }, []);

    const onTextEditFocusChange = (isFocused: boolean): void => {
        setEditingTitle(isFocused);
        if (isFocused === false) {
            notifyTitleChange();
        }
    }

    const getCreatedDescription = (): string => {
        if (!state.data)
            return "";

        return CreationUtils.getCreatedDescription(state.data.creationDate);
    }

    const isCreationReady = (): boolean => {
        return peekThroughStoreData.status === CreationsStatus.success;
    }

    const isCreationInProgress = (): boolean => {
        return peekThroughStoreData.status === CreationsStatus.requested ||
            peekThroughStoreData.status === CreationsStatus.editing;
    }

    const getHeaderClass = (): string => {
        let headerClass = "creation-header__text-box";
        if (isCreationInProgress())
            headerClass = "disabled";
        return headerClass;
    }

    const onSelectionChange = (value: Key): void => {
        if (value !== null) {
            switch (value) {
                case CanvasZoomLevelAction.zoomToFit: {
                    props.controller.notify({ type: CanvasZoomLevelAction.zoomToFit });
                    break;
                }
                case CanvasZoomLevelAction.zoomToFill: {
                    props.controller.notify({ type: CanvasZoomLevelAction.zoomToFill });
                    break;
                }
                default: {
                    props.controller.notify({ type: CanvasZoomLevelAction.changeZoomValue, payload: value });
                    break;
                }
            }
        }
    }

    const openDeeplink = (): void => {
        props.controller.notify({ type: ELCreationsHeaderControllerAction.openInDesktop });
    }

    const addAsteriskIfSaveEnable = (): string => {
        return (docStoreData.isDirty === DocumentDirty.DIRTY) ? "*" : "";
    }

    const onChangeRenameTextField = (userInput: string): void => {
        const parseInputTitle = userInput.trimStart();
        setUITitle(parseInputTitle);
    }

    return (<ELReactSpectrumV3Provider>
        <ViewportProvider>
            <Flex UNSAFE_className="creation-header" gap="0.8rem" direction="row" alignItems="center">
                <BackButton launchFeedbackDialogOnDontSave={CreationUtils.shouldLaunchCreationFeedbackDialog()} notify={props.controller.notify.bind(props.controller)}
                    hasDialog={docStoreData.isDirty === DocumentDirty.DIRTY}
                    headingDescription={intlHandler.formatMessage("peek-through-overlay-creation")} />

                <Flex UNSAFE_className={getHeaderClass()} direction="row" gap="size-250" alignItems="center">
                    <View UNSAFE_className="creation-header__edit-box">
                        <span className={`creation-header__title-text
                            ${!isCreationReady() || editingTitle ? "no-display" : ""}`} title={uiTitle}>
                            {uiTitle + addAsteriskIfSaveEnable()}
                        </span>
                        <TextField ref={titleInputRef} UNSAFE_className="creation-header__text-input" inputMode="text"
                            isHidden={!isCreationReady() || !editingTitle} value={uiTitle} onChange={onChangeRenameTextField}
                            maxLength={MAX_PROJECT_TITLE_LENGTH}
                            onKeyDown={renameEnterKeyPress} onFocusChange={(isFocused) => onTextEditFocusChange(isFocused)} />
                    </View>
                    <Flex isHidden={!isCreationReady()} alignItems="baseline" gap="0.8rem">
                        <PencilPlaceholder isSyncingEdit={isSyncingEdit()}
                            editingTitle={editingTitle} setEditing={setEditing} />
                        <Divider orientation="vertical" size="S" />
                        <Text UNSAFE_className="creation-header__created-text"> {getCreatedDescription()}</Text>
                    </Flex>
                    <ELSwitch controller={props.controller} />
                </Flex>

                <Flex isHidden={!isCreationReady()} alignItems="baseline" gap="0.8rem">
                    <ELComboBox options={elCreationsDropBoxOptions} onSelectionChange={(key: Key) => onSelectionChange(key)}
                        defaultValue={zoomValue} inputValue={zoomValue} styleProps={{ caretColor: "transparent" }} />
                </Flex>

                <Flex isHidden={!isCreationReady()} UNSAFE_className="creation-header__cta-box" direction="row" gap="1rem">
                    <Flex isHidden={networkStatus.online}>
                        <NoInternetSaveView />
                    </Flex>

                    <Flex isHidden={!AssetStorageUtils.hasQuotaExceeded() || !networkStatus.online}>
                        <NoSpaceSaveView />
                    </Flex>

                    <Flex isHidden={!isSaveAvailable()}>
                        <SaveView controller={props.controller} docStoreData={docStoreData} />
                    </Flex>
                    <DownloadView notify={props.controller.notify.bind(props.controller)} radioButtonList={props.radioButtonList} />

                    <View isHidden={!props.showOpenInDesktop}>
                        <ELOpenInDesktopButton onPress={() => openDeeplink()} size="L" />
                    </View>

                    <ShareView controller={props.controller} shareOptionController={props.shareOptionController}
                        docStoreData={docStoreData} isDisabled={!state.data && !docStoreData.isDirty} />
                </Flex>
            </Flex>
        </ViewportProvider>
    </ELReactSpectrumV3Provider>);
}

export default ELPeekThroughHeaderView;