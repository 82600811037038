/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import IWorkflow from "../../workspaces/IWorkflow";
import ELCollageDoc from "../collage/ELCollageDoc";
import { RendererType } from "../renderer/IRenderer";
import RendererFactory from "../renderer/RendererFactory";
import GraphicsStageFactory from "../stage/GraphicsStageFactory";
import { GraphicsStageType } from "../stage/IGraphicsStage";
import IDoc, { DocumentType } from "./IDoc";
import DocDataParserFactory from "./parser/DocDataParserFactory";
import DocumentDownloaderFactory from "./downloader/DocumentDownloaderFactory";
import ELAdobeAssetDoc from "../adobeAsset/ELAdobeAssetDoc";
import ELPeekThroughDoc from "../peekThrough/ELPeekThroughDoc";
import ELStageDoc from "../client/document/ELStageDoc";
import ELPIEDoc from "../pie/models/ELPIEDoc";

export const DocumentMap: { [key in DocumentType]: any } = {
    [DocumentType.collage]: { doc: ELCollageDoc, rendererType: RendererType.collageDoc, stage: GraphicsStageType.fabricJS },
    [DocumentType.adobeAsset]: { doc: ELAdobeAssetDoc, rendererType: RendererType.adobeAsset, stage: GraphicsStageType.fabricJS },
    [DocumentType.peekThrough]: { doc: ELPeekThroughDoc, rendererType: RendererType.peekThrough, stage: GraphicsStageType.fabricJS },
    [DocumentType.stageDoc]: { doc: ELStageDoc, rendererType: RendererType.stageDoc, stage: GraphicsStageType.fabricJS },
    [DocumentType.pie]: { doc: ELPIEDoc, rendererType: RendererType.pie, stage: GraphicsStageType.fabricJS }
};

export interface DocumentFactoryPayload {
    docPayload: unknown,
    stagePayload?: unknown
}

export default abstract class DocumentFactory {
    static async createDocument(docType: DocumentType, workflow: IWorkflow, payload: DocumentFactoryPayload): Promise<IDoc> {
        const doc = new DocumentMap[docType].doc(workflow);

        const downloader = DocumentDownloaderFactory.createDownloader(docType, doc);
        doc.setDownloader = downloader;

        await doc.initialize(payload.docPayload);

        return doc;
    }

    static async createDocumentWithStage(docType: DocumentType, workflow: IWorkflow, payload: DocumentFactoryPayload): Promise<IDoc> {
        const doc = new DocumentMap[docType].doc(workflow);

        const dataParser = DocDataParserFactory.createParser(docType, doc);
        doc.setDataParser = dataParser;

        const stage = GraphicsStageFactory.createStage(DocumentMap[docType].stage, doc, payload.stagePayload);

        const downloader = DocumentDownloaderFactory.createDownloader(docType, doc, stage);
        doc.setDownloader = downloader;

        const renderer = RendererFactory.createRenderer(DocumentMap[docType].rendererType, doc, stage, dataParser);
        doc.setRenderer = renderer;

        await doc.initialize(payload.docPayload);

        return doc;
    }
}