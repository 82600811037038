/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { DEFAULT_INTENSITY_VALUE, DEFAULT_PRESET_ID } from "../../common/interfaces/editing/adjustments/AdjustmentsEditorTypes";
import AdjustmentsAction, { AdjustmentsActionPayload, AdjustmentsPresetURLPayload } from "../actions/AdjustmentsActions";
import IAction from "../actions/IAction";

interface AdjustmentsReducerData {
    presetsPreviewURLs: Record<string, string | undefined>,
    selectedPresetId: string,
    intensity: number
}

const defaultState: AdjustmentsReducerData = {
    presetsPreviewURLs: {},
    selectedPresetId: DEFAULT_PRESET_ID,
    intensity: DEFAULT_INTENSITY_VALUE
}

const updatePresetPreviewURL = (state: AdjustmentsReducerData, action: IAction<AdjustmentsActionPayload>): AdjustmentsReducerData => {
    if (action.payload) {
        const { id, previewUrl } = action.payload as AdjustmentsPresetURLPayload;

        return {
            ...state,
            presetsPreviewURLs: {
                ...state.presetsPreviewURLs,
                [id]: previewUrl
            }
        }
    } else {
        return state;
    }
}

const resetPresetsPreviewURLs = (state: AdjustmentsReducerData, action: IAction<AdjustmentsActionPayload>): AdjustmentsReducerData => {
    return {
        ...state,
        presetsPreviewURLs: {}
    }
}

const updateSelectedPresetId = (state: AdjustmentsReducerData, action: IAction<AdjustmentsActionPayload>): AdjustmentsReducerData => {
    return {
        ...state,
        selectedPresetId: action.payload as string
    }
}

const updateIntensity = (state: AdjustmentsReducerData, action: IAction<AdjustmentsActionPayload>): AdjustmentsReducerData => {
    return {
        ...state,
        intensity: action.payload as number
    }

}

const adjustmentsReducer = (state: AdjustmentsReducerData = defaultState, action: IAction<AdjustmentsActionPayload>): AdjustmentsReducerData => {
    switch (action.type) {
        case AdjustmentsAction.UPDATE_PRESET_PREVIEW_URL:
            return updatePresetPreviewURL(state, action);
        case AdjustmentsAction.RESET_PRESETS_PREVIEW_URLS:
            return resetPresetsPreviewURLs(state, action);
        case AdjustmentsAction.UPDATE_SELECTED_PRESET_ID:
            return updateSelectedPresetId(state, action);
        case AdjustmentsAction.UPDATE_INTENSITY:
            return updateIntensity(state, action);
        default:
            return state;
    }
}

export default adjustmentsReducer;