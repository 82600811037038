/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


import { ELSize } from "../common/interfaces/geometry/ELGeometry";
import Utils from "./Utils"

export default class ImageUtils {
	static createHTMLCanvasElementWithColor(size: ELSize, fillColor = "white"): HTMLCanvasElement {
		const canvas = document.createElement("canvas");
		canvas.width = Number(size.width);
		canvas.height = Number(size.height);

		const ctx = canvas.getContext("2d");

		if (!ctx) {
			return canvas;
		}

		ctx.rect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = fillColor;
		ctx.fill();

		return canvas;
	}

	static createHTMLCanvasElementForSize(size: ELSize): HTMLCanvasElement {
		const canvas = document.createElement("canvas");
		canvas.width = Number(size.width);
		canvas.height = Number(size.height);
		return canvas;
	}

	//would use offscreen when Safari starts to support it
	static createOffscreenCanvasElementForSize(size: ELSize): OffscreenCanvas | HTMLCanvasElement {
		// If offscreen canvas can be created then create that first.
		if (globalThis.OffscreenCanvas) {
			return new OffscreenCanvas(size.width, size.height);
		} else {
			return ImageUtils.createHTMLCanvasElementForSize(size);
		}
	}

	static createHTMLCanvasElement(imageData: ImageData): HTMLCanvasElement {
		const canvas = ImageUtils.createHTMLCanvasElementForSize({ width: imageData.width, height: imageData.height });
		const ctx = canvas.getContext("2d");
		if (ctx !== null) {
			ctx.putImageData(imageData, 0, 0);
		}
		return canvas
	}

	static async createImageData(blob: string | Blob): Promise<ImageData> {
		let didCreateBlob = false;
		return new Promise((resolve, reject) => {
			const image = new Image()
			image.crossOrigin = "anonymous";
			const URL = window.URL || window.webkitURL
			image.onload = async () => {
				if (didCreateBlob) {
					URL.revokeObjectURL(image.src)
				}

				const canvas = document.createElement("canvas")
				canvas.width = image.width;
				canvas.height = image.height;

				const ctx = canvas.getContext("2d")
				if (ctx) {
					ctx.drawImage(image, 0, 0)

					const imageData = ctx.getImageData(0, 0, image.width, image.height);
					resolve(imageData);
				} else {
					reject(new Error("no ctx"))
				}
			}
			image.onerror = e => {
				console.error(e)
				reject(e)
			}
			if (typeof blob === "string" || blob instanceof String) {
				image.src = blob as string
			} else {
				didCreateBlob = true
				image.src = URL.createObjectURL(blob)
			}
		})
	}

	static async getDataURL(imageData: ImageData): Promise<string> {
		const canvas = ImageUtils.createHTMLCanvasElement(imageData);
		return canvas.toDataURL();
	}

	static async getBlobFromImageData(imageData: ImageData): Promise<Blob> {
		return new Promise((resolve, reject) => {
			const canvas = ImageUtils.createHTMLCanvasElement(imageData);
			canvas.toBlob((blob) => {
				if (blob) {
					resolve(blob);
				} else {
					reject("Couldn't create blob from canvas!");
				}
			});
		});
	}

	static async getArrayBufferFromImageData(imageData: ImageData): Promise<ArrayBuffer> {
		const blob = await ImageUtils.getBlobFromImageData(imageData);
		return await ImageUtils.readArrayBufferFromBlob(blob);
	}

	static createBlob(
		blob: string | Blob,
		type: string | undefined = undefined,
		quality: number | undefined = undefined
	): Promise<Blob> {
		let didCreateBlob = false
		return new Promise((resolve, reject) => {
			const image = new Image()
			image.crossOrigin = "anonymous"
			const URL = window.URL || window.webkitURL
			image.onload = async () => {
				if (didCreateBlob) {
					URL.revokeObjectURL(image.src)
				}

				const canvas = document.createElement("canvas")
				canvas.width = image.width;
				canvas.height = image.height;

				const ctx = canvas.getContext("2d")
				if (ctx) {
					ctx.drawImage(image, 0, 0)

					canvas.toBlob((blob: Blob | null): void => {
						if (blob) {
							resolve(blob);
						} else {
							reject(new Error("Bitmap encoding failed in canvas.toBlob call."));
						}
					}, type, quality);
				} else {
					reject(new Error("no ctx"))
				}
			}
			image.onerror = e => {
				console.error(e)
				reject(e)
			}
			if (typeof blob === "string" || blob instanceof String) {
				image.src = blob as string
			} else {
				didCreateBlob = true
				image.src = URL.createObjectURL(blob)
			}
		})
	}

	/**
	 * Reads an ArrayBuffer from a blob, including support for Safari 13.
	 * @param blob - the Blob of image
	 * @returns ArrayBuffer from blob
	 */
	static async readArrayBufferFromBlob(blob: Blob): Promise<ArrayBuffer> {
		if (typeof blob.arrayBuffer === "function") {
			return blob.arrayBuffer();
		}
		return ImageUtils._loadArrayBufferFromBlob(blob);
	}


	/**
	 * This function provides a way to load an ArrayBuffer from a Blob in Safari 13, which doesn't support `blob.arrayBuffer()`.
	 * @param blob - the Blob of image
	 * @returns ArrayBuffer from blob
	 */
	private static async _loadArrayBufferFromBlob(blob: Blob): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				resolve(reader.result as ArrayBuffer);
			});
			reader.addEventListener("error", () => {
				reject(reader.error);
			});
			reader.readAsArrayBuffer(blob);
		});
	}

	static getMaxViewportDimsWebGl(): Array<number> | null {
		const canvas = document.createElement("canvas")
		const ctx = canvas.getContext("webgl")
		const params = ctx?.getParameter(ctx?.MAX_VIEWPORT_DIMS)
		if (!params || params.length !== 2) {
			return null
		}
		// Half size because of oversampling
		return [Math.floor(params[0] / 2), Math.floor(params[1] / 2)]
	}

	static async resizeImageFromURL(url: string, size: number): Promise<string> {
		const imageData = await ImageUtils.createImageData(url);
		const aspectRatio = imageData.width / imageData.height;
		let newWidth = size, newHeight = size;
		if (aspectRatio > 1) {
			newHeight = size / aspectRatio;
		} else {
			newWidth = size * aspectRatio;
		}
		const resizedImageData = ImageUtils.resizeImageData(imageData, newWidth, newHeight);
		if (resizedImageData) {
			const resizedURL = await ImageUtils.getDataURL(resizedImageData);
			return resizedURL;
		} else {
			return url;
		}
	}

	static resizeImageData(imageData: ImageData, newWidth: number, newHeight: number): ImageData | null {
		if (newWidth < 1 || newHeight < 1) {
			return null
		}
		if (imageData.width === newWidth && imageData.height === newHeight) {
			return imageData
		}

		const oldCanvas = ImageUtils.createHTMLCanvasElementForSize({ width: imageData.width, height: imageData.height })
		const oldCtx = oldCanvas.getContext("2d")

		const newCanvas = ImageUtils.createHTMLCanvasElementForSize({ width: newWidth, height: newHeight })
		const newCtx = newCanvas.getContext("2d")

		if (!oldCtx || !newCtx) {
			return null
		}

		oldCtx.putImageData(imageData, 0, 0)
		newCtx.drawImage(oldCanvas, 0, 0, imageData.width, imageData.height, 0, 0, newCanvas.width, newCanvas.height)
		return newCtx.getImageData(0, 0, newCanvas.width, newCanvas.height)
	}

	static resizeImageDataWithAspectRatio(imageData: ImageData, maxSize: number): ImageData | null {
		if (maxSize < 1) {
			return null;
		}

		const aspectRatio = imageData.width / imageData.height;
		let newWidth, newHeight;

		if (aspectRatio > 1) {
			newWidth = maxSize;
			newHeight = maxSize / aspectRatio;
		} else {
			newWidth = maxSize * aspectRatio;
			newHeight = maxSize;
		}

		return ImageUtils.resizeImageData(imageData, newWidth, newHeight);
	}

	static renderIntoImageData(originalCanvas: HTMLCanvasElement, opacity: number): ImageData | undefined {
		const canvas = ImageUtils.createHTMLCanvasElementForSize({ width: originalCanvas.width, height: originalCanvas.height })
		const ctx = canvas.getContext("2d")
		if (!ctx) {
			return undefined
		}
		ctx.drawImage(originalCanvas, 0, 0, canvas.width, canvas.height)
		ctx.globalAlpha = opacity
		return ctx.getImageData(0, 0, canvas.width, canvas.height)
	}

	static getBase64DataFromArrayBuffer = (arrayBuffer: SharedArrayBuffer): string => {
		return "data:image/jpeg;base64," + Utils.getBase64Data(arrayBuffer);
	}

	/**
	 * @param  {File} file
	 * @returns boolean True if the file is an image file
	 */
	static isImageFile(file: File): boolean {
		return file.type.includes("image");
	}

	/**
	 * @param  {File} file
	 * @returns boolean True if the file is a video file
	 */
	static isVideoFile(file: File): boolean {
		return file.type.includes("video");
	}

	static async getMetaDataFromURL(url: string): Promise<HTMLImageElement> {
		const img = new Image();
		img.src = url;
		img.crossOrigin = "anonymous";
		await img.decode();
		return img;
	}

	static async getImageSizeFromURL(url: string): Promise<ELSize> {
		const img = await ImageUtils.getMetaDataFromURL(url);
		return {
			width: img.naturalWidth,
			height: img.naturalHeight
		}
	}

	static async getImageDataSHA256Hash(imageData: ImageData): Promise<string> {

		const metadata = JSON.stringify({
			colorSpace: imageData.colorSpace,
			width: imageData.width,
			height: imageData.height
		});

		// Convert metadata to Uint8Array
		const metadataArray = new TextEncoder().encode(metadata);

		// Convert ImageData pixel data to Uint8Array
		const pixelDataArray = new Uint8Array(imageData.data.buffer);

		// Concatenate metadata and pixel data
		const combinedArray = new Uint8Array(metadataArray.length + pixelDataArray.length);
		combinedArray.set(metadataArray);
		combinedArray.set(pixelDataArray, metadataArray.length);

		const hashBuffer = await crypto.subtle.digest('SHA-256', combinedArray);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		return hashHex;
	}
}