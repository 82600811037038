/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

 //At maximum we can give expiry date as Tue, 19 Jan 2038 03:14:07 UTC as date - https://en.wikipedia.org/wiki/Year_2038_problem
 export const PERSISTENT_COOKIE_MAXIMUM_EXPIRY = "Tue, 19 Jan 2038 03:14:07 UTC";

 export type CookieAttributes = {
	path?: string;
	domain?: string;
	maxAge?: number;
	expires?: string;
	secure?: boolean;
	samesite?: "lax" | "strict" | "none";
};
    
export const CookiesUtils = {
    read: (name: string): string | undefined => {
        const sName = `${encodeURIComponent(name)}=`;
        const cookie = document.cookie.split("; ").find(cookie => cookie.startsWith(sName));

        if (cookie) {
            return decodeURIComponent(cookie.replace(sName, ""));
        }
        return undefined;
    },

    write: (name: string, value: string, attributes: CookieAttributes = {}): void => {
        const parts = Object.entries(attributes).map(([key, value]) => {
            if (key === "secure" && value === true) {
            return key;
            }
            return `${key}=${encodeURIComponent(String(value))}`;
        });
        parts.unshift(`${name}=${encodeURIComponent(value)}`);
        document.cookie = parts.join("; ")
    }
}
    