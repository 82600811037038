/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { CreationsData, CreationsJobProjectSubType } from "./CreationTypes";

export enum ELRecentCreationsControllerActions {
    deleteCreation = "DELETE_CREATION",
    downloadCreation = "DOWNLOAD_CREATION",
    fetchThumbnail = "FETCH_THUMBNAIL",
    fetchAllThumbnails = "FETCH_ALL_THUMBNAILS",
    openCreation = "OPEN_CREATION",
    refreshCreation = "REFRESH_CREATION",
    renameCreation = "RENAME_CREATION",
    openDeepLink = "OPEN_DEEPLINK",
    filterCreations = "FILTER_CREATIONS",
    sortCreationsByField = "SORT_CREATIONS_BY_FIELD",
    sortCreationsByOrder = "SORT_CREATIONS_BY_ORDER",
}

export enum ELRecentCreationsViewActions {
    creationsData = "CREATIONS_DATA",
    refetchCreations = "REFETCH_CREATIONS",
    recentCreationStatus = "RECENT_CREATION_STATUS",
    recentCreationProgressText = "RECENT_CREATION_PROGRESS_TEXT"
}

export type ELRecentCreationsStatus = "success" | "error";

export interface ELRecentCreationsViewData {
    data: CreationsData[],
    status: ELRecentCreationsStatus;
}

export type RecentCreationFilterType = "all" | keyof typeof CreationsJobProjectSubType;
export type RecentCreationSortType = "createdDate" | "modifiedDate" | "name";
export type RecentCreationSortOrder = "ascending" | "descending";

export interface RecentCreationsConfig {
    creationType: RecentCreationFilterType,
    sortBy: RecentCreationSortType,
    order: RecentCreationSortOrder,
    creationsData: CreationsData[]
}

export enum RecentCreationsSortByField {
    name = "name",
    modifiedDate = "modifiedDate",
}

export enum RecentCreationSortOrderKey {
    ascending = "ascending",
    descending = "descending",
}

export const ALL_CREATIONS = "all";

export interface RecentCreationsFilterMenu {
    id: string,
    name: string
}