/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { CreationsData } from "../../common/interfaces/creations/CreationTypes";
import { RecentCreationFilterType, RecentCreationSortOrder, RecentCreationSortType, RecentCreationsConfig } from "../../common/interfaces/creations/ELRecentCreationsTypes";
import IAction from "../actions/IAction";
import RecentCreationAction, { RecentCreationsActionUnion } from "../actions/RecentCreationsAction";

const initialState: RecentCreationsConfig = {
    creationType: "all",
    sortBy: "modifiedDate",
    order: "descending",
    creationsData: []
};

const updateFilter = (state: RecentCreationsConfig, action: IAction<RecentCreationsActionUnion>): RecentCreationsConfig => {
    const newFilter = action.payload as RecentCreationFilterType;
    if (newFilter) {
        return {
            ...state,
            creationType: newFilter
        };
    } else return state;
};

const updateSortByField = (state: RecentCreationsConfig, action: IAction<RecentCreationsActionUnion>): RecentCreationsConfig => {
    const sortBy = action.payload as RecentCreationSortType;
    if (sortBy) {
        return {
            ...state,
            sortBy: sortBy
        };
    } else return state;
};

const updateSortOrder = (state: RecentCreationsConfig, action: IAction<RecentCreationsActionUnion>): RecentCreationsConfig => {
    const sortOrder = action.payload as RecentCreationSortOrder;
    if (sortOrder) {
        return {
            ...state,
            order: sortOrder
        };
    } else return state;
};

const updateCreationsData = (state: RecentCreationsConfig, action: IAction<RecentCreationsActionUnion>): RecentCreationsConfig => {
    const creationsData = action.payload as CreationsData[];
    if (creationsData) {
        return {
            ...state,
            creationsData: creationsData
        };
    } else return state;
};

const recentCreationReducer = (state: RecentCreationsConfig = initialState, action: IAction<RecentCreationsActionUnion>): RecentCreationsConfig => {
    switch (action.type) {
        case RecentCreationAction.CHANGE_FILTER:
            return updateFilter(state, action);
        case RecentCreationAction.CHANGE_SORT_BY_FIELD:
            return updateSortByField(state, action);
        case RecentCreationAction.CHANGE_SORT_ORDER:
            return updateSortOrder(state, action);
        case RecentCreationAction.RECENT_CREATIONS_LIST:
            return updateCreationsData(state, action);
        default:
            return state;
    }
};

export default recentCreationReducer;