/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELProgressBarActions, ELProgressValue, ImportCompleteState, MediaImportStatusActions, ProgressState } from "../../../../common/interfaces/import/ImportProgressTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import UploadHandler, { BatchUploadCompleteInfo, BatchUploadState, FileInfo, UploadErrorCode, UploadInfoArg, UploadStatus } from "../../../../modules/uploadHandler/UploadHandler";
import ELProgressBarFooter from "../../molecules/el-import-progress-bar-footer/ELImportProgressBar";
import { StorageQuota } from "../../../../modules/storageQuota/StorageQuota";
import Constants from "../../../../utils/Constants/Constants";
import { IngestEventSubTypes, IngestEventTypes, IngestImportState, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { ToastUtils } from "../../../../utils/ToastUtils";
import Utils from "../../../../utils/Utils";
import IWorkflow, { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import IViewController from "../../../IViewController";
import MediaGridToolbarAction from "../../../../stores/actions/mediaGridToolbarAction";
import MediaOrganizerAction from "../../../../stores/actions/mediaOrganizerActions";
import store from "../../../../stores/store";
import ELImportProgressBar from "../../molecules/el-import-progress-bar-footer/ELImportProgressBar";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { CONTAINER_TRANSITION_TIMEOUT } from "../../../../workspaces/organizer/workflows/mediaGrid/MediaGrid";
import { ELMediaGridToolbarControllerAction } from "../../molecules/el-media-grid-toolbar/ELMediaGridToolbar";
import { ViewAction } from "../../../IBaseController";
import { MediaExistUtil } from "../../../../workspaces/creations/utils/MediaExistUtils";

class ELMediaUploader extends IViewController {

    private _workflow: IWorkflow;
    private _uploadHandler: UploadHandler | undefined;
    private _importProgressBarFooter: ELImportProgressBar | undefined;
    private _importProgressState: "started" | "stopped" | "notStarted" | "stoppedAndNotified";
    private _initialUploadList: File[];
    private _viewType: string;

    constructor(workflow: IWorkflow) {
        super();
        this._workflow = workflow;
        this._importProgressState = "notStarted";
        this._importProgressBarFooter = new ELProgressBarFooter(this._workflow);
        this._initialUploadList = [];
        this._viewType = IngestLogObjectValue.media;
    }

    createView(container: HTMLElement): void {
        if (this._importProgressBarFooter) {
            this._importProgressBarFooter.createView(container);
        }
    }

    destroyView(): void {
        this._importProgressBarFooter?.destroyView();
    }

    get getImportProgressState(): string {
        return this._importProgressState;
    }

    private async _ingestImportStart(): Promise<void> {
        const quota = await StorageQuota.getInstance().getQuota(false);
        this._workflow.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.importStartStorage, IngestEventTypes.info,
                IngestEventSubTypes.count, Utils.humanFileSize(quota.available))
        });
    }


    private async _handleUploadToast(data: BatchUploadCompleteInfo): Promise<void> {
        const intlHandler = IntlHandler.getInstance();
        const uploadState = data.uploadState;
        const importState = this._getImportCompleteState(data);
        this._ingestUploadEnd(importState, uploadState.successCnt);
        let msg = "";
        switch (importState) {
            case ImportCompleteState.success: {
                ToastUtils.success(intlHandler.formatMessage("upload-success-toast", { value: uploadState.successCnt }));
                return;
            }
            case ImportCompleteState.successWithSkipped: {
                ToastUtils.warning(intlHandler.formatMessage("upload-warning-toast", { upload: uploadState.successCnt, total: uploadState.totalCnt }), {
                    timeout: Constants.TOAST_NO_TIMEOUT as number
                });
                return;
            }
            case ImportCompleteState.importStopped: {
                ToastUtils.success(intlHandler.formatMessage("upload-stopped-success-toast", { value: uploadState.successCnt }));
                const additionalLogInfo: Record<string, string> = {};
                additionalLogInfo[IngestLogObjectCustomKey.viewType] = this._viewType;
                this._workflow.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.import,
                        IngestEventSubTypes.click, IngestEventSubTypes.stopImport, uploadState.successCnt.toString(), additionalLogInfo)
                });
                return;
            }
            case ImportCompleteState.storageQuotaFull: {
                const quota = await StorageQuota.getInstance().getQuota(false);
                const available = Utils.humanFileSize(quota.used);
                //const fileSize = files.reduce((totalSize, fileInfo) => totalSize + fileInfo.file.size, 0);
                //const selectionSize = Utils.humanFileSize(fileSize);
                const totalSize = Utils.humanFileSize(quota.total);
                msg = intlHandler.formatMessage("storage-error-toast-msg", { availableSize: available, totalSize: totalSize });
                break;
            }
            case ImportCompleteState.noSupportedFile: {
                msg = intlHandler.formatMessage("no-supported-file-toast-msg");
                break;
            }
            case ImportCompleteState.partialUpload: {
                msg = intlHandler.formatMessage("partial-upload", { value: uploadState.successCnt });
                ToastUtils.error(msg);
                return;
            }
            case ImportCompleteState.partialWithSkipped: {
                msg = intlHandler.formatMessage("partial-upload-with-skip", { value: uploadState.successCnt });
                break;
            }
            case ImportCompleteState.internetConnectionLost: {
                msg = intlHandler.formatMessage("no-internet-import", { value: uploadState.successCnt });
                break;
            }
            case ImportCompleteState.unreachableServer: {
                msg = intlHandler.formatMessage("server-not-reachable-toast-msg", { successCnt: data.uploadState.successCnt, totalCnt: data.uploadState.totalCnt });
                ToastUtils.error(msg);
                return;
            }
        }
        ToastUtils.error(msg, {
            timeout: Constants.TOAST_NO_TIMEOUT as number
        });
    }


    private _getImportCompleteState(data: BatchUploadCompleteInfo): ImportCompleteState {
        const uploadState = data.uploadState;
        if (uploadState.successCnt === uploadState.totalCnt) {
            return ImportCompleteState.success;
        } else if (uploadState.failedCnt === 0 && uploadState.successCnt !== uploadState.totalCnt) {
            return ImportCompleteState.successWithSkipped;
        } else {
            switch (data.error) {
                case UploadErrorCode.unknowError: {
                    if (uploadState.failedCnt + uploadState.successCnt < uploadState.totalCnt) {
                        return ImportCompleteState.partialWithSkipped;
                    } else {
                        return ImportCompleteState.partialUpload;
                    }
                }
                case UploadErrorCode.noInternet: {
                    return ImportCompleteState.internetConnectionLost;
                }
                case UploadErrorCode.uploadAborted: {
                    return ImportCompleteState.importStopped;
                }
                case UploadErrorCode.storageFull: {
                    return ImportCompleteState.storageQuotaFull;
                }
                case UploadErrorCode.noSupportedFile: {
                    return ImportCompleteState.noSupportedFile;
                }
                default: {
                    return ImportCompleteState.unreachableServer;
                }
            }
        }
    }

    private _ingestImportLogging(isSuccess: boolean, state: string, successCnt: number): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = this._viewType;
        const fileList = this._initialUploadList;
        const photos = fileList.map(asset => asset.type).filter(val => val?.includes("image")) as string[] ?? [];
        const videos = fileList.map(asset => asset.type).filter(val => val?.includes("video")) as string[] ?? [];
        const photoFormatCounts = IngestUtils.photoFormatCount(photos);
        const videoFormatCounts = IngestUtils.videoFormatCount(videos);
        const totalSize = Utils.getBytesToMB(fileList.reduce((totalSize, file2) => { return totalSize + file2.size }, 0)).toFixed(1);

        const customEntries: Record<string, string> = {};
        customEntries[IngestLogObjectCustomKey.photoCount] = photos.length.toString();
        customEntries[IngestLogObjectCustomKey.videoCount] = videos.length.toString();
        customEntries[IngestLogObjectCustomKey.totalCount] = fileList.length.toString();
        customEntries[IngestLogObjectCustomKey.totalSize] = totalSize;

        for (const format in photoFormatCounts) {
            const customEntry = format + IngestLogObjectCustomKey.countSuffix;
            if (format) customEntries[customEntry] = photoFormatCounts[format].toString();
        }

        for (const format in videoFormatCounts) {
            const customEntry = format + IngestLogObjectCustomKey.countSuffix;
            if (format) customEntries[customEntry] = videoFormatCounts[format].toString();
        }

        for (const key in customEntries) {
            const additionalLogInfoTemp = { ...additionalLogInfo };
            additionalLogInfoTemp[IngestLogObjectKey.contentName] = key;
            additionalLogInfoTemp[IngestLogObjectKey.eventCount] = customEntries[key];
            this._workflow.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.info, IngestEventSubTypes.import, successCnt, additionalLogInfoTemp)
            });
        }

        if (isSuccess) {
            this._workflow.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.success, IngestEventSubTypes.import, successCnt, additionalLogInfo)
            });
        }
        else {
            additionalLogInfo[IngestLogObjectKey.errorDescription] = state;
            this._workflow.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations,
                    IngestEventSubTypes.error, IngestEventSubTypes.import, successCnt, additionalLogInfo)
            });
        }
    }

    private async _ingestUploadEnd(importState: ImportCompleteState, successCnt: number): Promise<void> {
        const quota = await StorageQuota.getInstance().getQuota(true);
        this._workflow.notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.importEndStorage, IngestEventTypes.info,
                IngestEventSubTypes.count, Utils.humanFileSize(quota.available))
        });
        if (IngestImportState[importState] === IngestImportState[ImportCompleteState.success])
            this._ingestImportLogging(true, IngestImportState[importState], successCnt)
        else
            this._ingestImportLogging(false, IngestImportState[importState], successCnt)
    }


    private _handleFileUploadProgress(fileInfo: FileInfo, uploadState: BatchUploadState): void {
        if (!this._importProgressBarFooter) {
            return;
        }

        Logger.log(LogLevel.DEBUG, "Progress callback", fileInfo);
        this._enableUserStop();
        if (fileInfo.status === UploadStatus.success || fileInfo.status === UploadStatus.waiting) {
            this._updateProgressOnView(uploadState.totalCnt, uploadState.successCnt, ProgressState.inProgress);
        } else if (fileInfo.status === UploadStatus.failed) {
            // we don't want to wait for the upload complete callback
            if (fileInfo.error === UploadErrorCode.uploadAborted || fileInfo.error === UploadErrorCode.noInternet) {
                if (this._importProgressState === "stoppedAndNotified") {
                    // ignore further callbacks
                    // import stop is shown to user
                    return;
                }
                if (fileInfo.error === UploadErrorCode.uploadAborted) {
                    this.cleanUpImportWorkflow(CONTAINER_TRANSITION_TIMEOUT);
                    if (uploadState.successCnt > 0) {
                        this._workflow.notify({ type: MediaImportStatusActions.mediaImported, payload: true });
                    }
                }
                if (fileInfo.error === UploadErrorCode.noInternet) {
                    this._enableUserRetry(true);
                }
                const uploadCompleteData: BatchUploadCompleteInfo = {
                    fileInfo: [],
                    uploadState: uploadState,
                    error: fileInfo.error
                }
                this._handleUploadToast(uploadCompleteData);
                this._updateProgressOnView(uploadState.totalCnt, uploadState.successCnt, ProgressState.interrupted);
                store.dispatch(MediaOrganizerAction.reset(Constants.ELEMENTS_PHOTOS_PATH as string));
                this._importProgressState = "stoppedAndNotified";
            }
        }
    }

    private _isMediaImportedSuccessfully(importCompleteState: ImportCompleteState): boolean {
        return importCompleteState === ImportCompleteState.success || importCompleteState === ImportCompleteState.successWithSkipped ||
            importCompleteState === ImportCompleteState.partialUpload || importCompleteState === ImportCompleteState.partialWithSkipped;
    }

    private _handleUploadComplete(data: BatchUploadCompleteInfo): void {
        store.dispatch(MediaOrganizerAction.reset(Constants.ELEMENTS_PHOTOS_PATH as string));
        const importCompleteState = this._getImportCompleteState(data);
        const retryStates = [ImportCompleteState.internetConnectionLost,
        ImportCompleteState.unreachableServer,
        ImportCompleteState.partialUpload,
        ImportCompleteState.partialWithSkipped];
        this._workflow.notify({ type: MediaImportStatusActions.mediaImported, payload: this._isMediaImportedSuccessfully(importCompleteState) });
        if (!retryStates.includes(importCompleteState)) {
            this.cleanUpImportWorkflow(CONTAINER_TRANSITION_TIMEOUT);
        } else {
            this._updateProgressOnView(data.uploadState.totalCnt, data.uploadState.successCnt, ProgressState.interrupted);
            this._enableUserRetry(true);
        }
        this._handleUploadToast(data);
    }


    private _notifyViewUploadInProgress(inProgress: boolean): void {
        store.dispatch(MediaGridToolbarAction.updateMediaGridToolbarImportState({ importInProgress: inProgress }))
        this._workflow.notify({ type: ELMediaGridToolbarControllerAction.changeImportProgressState, payload: inProgress })
    }


    private _enableUserStop(): void {
        if (!this._importProgressBarFooter) {
            return;
        }
        const action: ViewAction = {
            type: ELProgressBarActions.showStopButton,
            payload: true
        }
        this._importProgressBarFooter.notify(action);
    }


    private _updateProgressOnView(totalCnt: number, successCnt: number, progressState: ProgressState): void {
        if (!this._importProgressBarFooter)
            return;

        const payload: ELProgressValue = {
            total: totalCnt,
            value: successCnt,
            progressState: progressState
        }
        const action: ViewAction = {
            type: ELProgressBarActions.updateProgress,
            payload: payload
        }
        this._importProgressBarFooter.notify(action);
    }

    private _enableUserRetry(toStart: boolean): void {
        if (!this._importProgressBarFooter) {
            return;
        }
        const action: ViewAction = {
            type: ELProgressBarActions.showRetryButton,
            payload: toStart
        }
        this._importProgressBarFooter.notify(action);
    }

    cleanUpImportWorkflow(inMs: number): void {
        setTimeout(() => {
            this._notifyViewUploadInProgress(false);
            this._importProgressBarFooter?.destroyView();
        }, inMs);
    }

    handleImport(files: File[], viewType?: string): void {
        this._ingestImportStart();
        this._initialUploadList = Array.from(files) as File[];
        this._uploadHandler = new UploadHandler();
        if (viewType)
            this._viewType = viewType;
        const uploadInfo: UploadInfoArg = {
            files: files,
            progressCallback: this._handleFileUploadProgress.bind(this),
            batchUploadCompleteCallback: this._handleUploadComplete.bind(this),
            ingestCallback: async (workflow: string, eventSubType: string, eventValue: string): Promise<void> => {
                this._workflow.notify({
                    type: WorkflowActionType.ingest,
                    payload: IngestUtils.getPseudoLogObject(workflow,
                        IngestEventTypes.info, eventSubType, eventValue)
                });
            }
        };
        this._uploadHandler.uploadFiles(uploadInfo).then((files) => {
            this._importProgressState = "started";
            this._notifyViewUploadInProgress(true);
        });
    }

    async stopImport(): Promise<void> {
        this._importProgressState = "stopped";
        if (!this._uploadHandler) {
            Logger.log(LogLevel.DEBUG, "[MediaGrid] Stop Handler not defined");
            return;
        }
        await this._uploadHandler.stopUpload().catch(() => {
            // import operation finished on all files
            this.cleanUpImportWorkflow(CONTAINER_TRANSITION_TIMEOUT);
            store.dispatch(MediaOrganizerAction.reset(Constants.ELEMENTS_PHOTOS_PATH as string));
        });

    }

    retryImport(): void {
        if (this._uploadHandler) {
            this._uploadHandler.retryUpload().then((files) => {
                Logger.log(LogLevel.DEBUG, "Retrying upload on ", files);
                this._importProgressState = "started";
            });
        }
        this._enableUserRetry(false);
    }

}

export default ELMediaUploader;
