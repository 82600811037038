/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { Routes } from "../../../../../app/AppRoute";
import {
    CreationsURLParams,
    CreationsMode,
    CreationsStatusPayload
} from "../../../../../common/interfaces/creations/CreationTypes";
import { WorkflowsName } from "../../../../IWorkflow";
import Utils from "../../../../../utils/Utils";
import { WorkspacePayload } from "../../../../IBaseWorkspace";
import { ELAdobeAsset } from "../../../../../common/interfaces/storage/AssetTypes";
import CreationUtils from "../../../utils/CreationUtils";


export default class CreationPreviewUtils {

    static async getCreationPreviewPayload(): Promise<WorkspacePayload> {
        const projectId = CreationPreviewUtils.getCreationPreviewIdFromHref(window.location.href);
        const mode = CreationsMode.preview;
        const payload: CreationsStatusPayload | ELAdobeAsset[] | null = {
            projectId: projectId,
            data: await CreationUtils.getProjectData(projectId)
        };
        const workspacePayload: WorkspacePayload = {
            startWorkflow: WorkflowsName.creationsPreview,
            initMode: mode,
            payload: payload
        }

        return workspacePayload;
    }

    /**
     * @returns string - history state based on projectId
     */
    static getCreationHistoryState(projectId: string, workflowName: WorkflowsName): string {
        const historyState = Routes.CREATIONS + "?" + CreationsURLParams.workflow + "=" + workflowName.toLowerCase() +
            "&" + CreationsURLParams.projectId + "=" + projectId;
        return historyState;
    }

    /**
     * @returns boolean - true if creation url is present
     */
    static hasCreationPreviewUrl(url: string): boolean {
        const workflow = Utils.getLinkParamValue(url, CreationsURLParams.workflow);
        return (workflow !== null && WorkflowsName.creationsPreview.toLowerCase() === workflow.toLowerCase());
    }

    /**
     * @returns string - creation id from window href
     */
    static getCreationPreviewIdFromHref(url: string): string {
        const projectId = Utils.getLinkParamValue(url, CreationsURLParams.projectId);
        return projectId ?? Utils.getNilUUID();
    }
}
