/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import { AxiosRequestConfig } from "axios";

//Elements Internal
import { BaseContentManager } from "@elements/elementswebcommon";

//Application Specific
import { API } from "../APIServiceWrapper";
import { BannerData, CardData, ChannelName, EliveContentType } from "../../common/interfaces/elive/EliveTypes";
import Logger, { LogLevel } from "../../utils/Logger";
import Utils from "../../utils/Utils";

export type ContentEntity = CardData | BannerData;

const ELIVE_APP_NAME = "web";
const ELIVE_APP_VERSION = "13.0";
const ELIVE_INSTALLATION_TYPE = "pse";

export class EliveContentManager implements BaseContentManager<ContentEntity, EliveContentType> {
    private static _instance: EliveContentManager | null = null;
    private _serviceAPI: API;
    private readonly _eliveInfoEndpoint = "/api/cards/getELiveInfo";
    private _cards: CardData[] = [];
    private _banners: BannerData[] = [];

    static get instance(): EliveContentManager {
        if (!this._instance) {
            this._instance = new EliveContentManager();
        }
        return this._instance;
    }

    constructor() {
        if (process.env.REACT_APP_ELIVE_SERVER_URL === undefined) {
            throw new Error("Elive server URL not found in env");
        }

        this._serviceAPI = new API(`${process.env.REACT_APP_ELIVE_SERVER_URL}`);
    }

    private _populateBannersData(allBanners: string[], bannersMetadata: Record<string, any>): BannerData[] {
        const banners: BannerData[] = [];
        for (const banner of allBanners) {
            const bannerMetadata = bannersMetadata[banner];
            banners.push(bannerMetadata);
        }
        return banners;
    }

    private async _getBannersInfo(): Promise<BannerData[]> {
        if(this._banners.length > 0) {
            return this._banners;
        }
        const response = await this._serviceAPI.get(this._eliveInfoEndpoint, this._getEliveInfoRequestConfig());
        const allBanners = response.data.allBanners;
        const bannersMetadata = response.data.bannersMetadata;
        this._banners = this._populateBannersData(allBanners, bannersMetadata);
        return this._banners;
    }

    private _getEliveInfoRequestConfig(): AxiosRequestConfig {
        const eliveAppLocale = Utils.getCurrentLocaleInSnakeCase().toLowerCase();
        return ({
            params: {
                stage: process.env.REACT_APP_ENV === "stage" ? true : false,
                appName: ELIVE_APP_NAME,
                appVersion: ELIVE_APP_VERSION,
                appLanguage: eliveAppLocale,
                installationType: ELIVE_INSTALLATION_TYPE,
                firstLaunch: true,
                retailBuild: true
            }
        })
    }

    private _getChannelsData(allChannels: string[], channelsMetadata: Record<string, any>): Record<string, ChannelName> {
        const channelIdToNameMap: Record<string, ChannelName> = {};
        for (const channel of allChannels) {
            channelIdToNameMap[channelsMetadata[channel].channelID] = channelsMetadata[channel].channelName;
        }
        return channelIdToNameMap;
    }

    private _populateCardsData(allCards: string[], cardsMetadata: Record<string, any>, channelsData: Record<string, ChannelName>): CardData[] {
        const cards: CardData[] = [];
        for (const card of allCards) {
            const cardMetadata = cardsMetadata[card];
            const cardData: CardData = {
                cardID: cardMetadata.cardID,
                channelName: channelsData[cardMetadata.channelID],
                title: cardMetadata.title,
                desc: cardMetadata.desc,
                cardURL: cardMetadata.cardURL,
                thumbnail: cardMetadata.thumbnail,
                workflowName: cardMetadata.workflowName,
                userStates: cardMetadata.userStates,
                platform: cardMetadata.platform
            }
            cards.push(cardData);
        }
        return cards;
    }

    private async _getCardsInfo(): Promise<CardData[]> {
        try {
            if (this._cards.length > 0) {
                return this._cards;
            }
            const response = await this._serviceAPI.get(this._eliveInfoEndpoint, this._getEliveInfoRequestConfig());
            const allCards = response.data.allCards;
            const cardsMetadata = response.data.cardsMetadata;
            const channelsData = this._getChannelsData(response.data.allChannels, response.data.channelsMetadata);
            this._cards = this._populateCardsData(allCards, cardsMetadata, channelsData);
            return this._cards;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    async fetchListing(contentType: EliveContentType): Promise<ContentEntity[]> {
        switch (contentType) {
            case EliveContentType.banner:
                return await this._getBannersInfo();
            case EliveContentType.card:
                return await this._getCardsInfo();
            default: {
                Logger.log(LogLevel.DEBUG, "EliveContentManager: fetchListing: Unable to get listing for content type  ", contentType);
                return Promise.reject("Unable to get listing");
            }
        }
    }

    //ELIVE_REVISIT check if we should derive from BaseContentManager
    getContentEntityById(id: string, contentType: EliveContentType): Promise<ContentEntity> {
        throw new Error("Method not implemented.");
    }
}
