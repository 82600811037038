/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Adobe internal
import { Slider, Flex } from "@adobe/react-spectrum";

//Application Specific
import { IconType } from "../../../../assets/IconConstants";
import { CollapsiblePanelData } from "../../../../common/interfaces/creations/templates/ELCollapsiblePanelTypes";
import { ELLooksPanelControllerActions } from "../../../../common/interfaces/editing/adjustments/ELLooksPanelTypes";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { Theme } from "../../../../utils/Theme";
import IViewController from "../../../IViewController";
import ELScrollPanel from "../../atoms/el-scroll-panel/ELScrollPanel";
import ELCustomIcon from "../../molecules/el-custom-icon/ELCustomIcon";
import ELCollapsiblePanelView from "../../organism/el-collapsible-panel/ELCollapsiblePanelView";
import AdjustmentsPresets, { AdjustmentsPreset } from "../../../../editors/adjustments/AdjustmentsPresets";
import { RootState } from "../../../../stores/store";
import { IngestWorkflowTypes, IngestEventSubTypes, IngestEventTypes } from "../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../utils/IngestUtils";
import { WorkflowActionType } from "../../../../workspaces/IWorkflow";
import AdjustmentsAction from "../../../../stores/actions/AdjustmentsActions";
import { DEFAULT_PRESET_ID } from "../../../../common/interfaces/editing/adjustments/AdjustmentsEditorTypes";

import "./ELLooksPanel.scss";

export interface ELLooksPanelViewProps {
    controller: IViewController,
    adjustmentsPresets: AdjustmentsPreset[]
}

const ELLooksPanelView = (props: ELLooksPanelViewProps): React.ReactElement => {
    const { adjustmentsPresets } = props;
    const DEFAULT_INTENSITY_VALUE = 1;
    const DEFAULT_SLIDER_INTERVAL = 200;
    const {presetsPreviewURLs, selectedPresetId, intensity} = useSelector((state: RootState) => state.adjustmentsReducer);
    const sliderIntervalId = React.useRef<NodeJS.Timer | null>(null);
    const intlHandler = IntlHandler.getInstance();
    const reduxDispatch = useDispatch();
    
    useEffect(() => {
        props.controller.initialize();

        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const onPresetChange = (id: string): void => {
        if (selectedPresetId !== id) {
            const preset = AdjustmentsPresets.getById(Number(id));
            reduxDispatch(AdjustmentsAction.updateSelectedPresetId(id));
            reduxDispatch(AdjustmentsAction.updateIntensity(DEFAULT_INTENSITY_VALUE));
            props.controller.notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.workspace, IngestEventSubTypes.click, IngestEventTypes.looks, preset?.title)
            });
            props.controller.notify({ type: ELLooksPanelControllerActions.applyPreset, payload: { presetId: id } });
        }
    }

    const onIntensityChange = (value: number): void => {
        reduxDispatch(AdjustmentsAction.updateIntensity(value));
        clearTimeout(sliderIntervalId.current as NodeJS.Timer);
        sliderIntervalId.current = setTimeout(() => {
            props.controller.notify({ type: ELLooksPanelControllerActions.applyPreset, payload: { presetId: selectedPresetId, intensity: value } });
        }, DEFAULT_SLIDER_INTERVAL);
    }

    const onResetPress = (): void => {
        reduxDispatch(AdjustmentsAction.updateSelectedPresetId(DEFAULT_PRESET_ID));
        reduxDispatch(AdjustmentsAction.updateIntensity(DEFAULT_INTENSITY_VALUE));
        props.controller.notify({ type: ELLooksPanelControllerActions.reset });
    }

    const getAdjustmentsPresets = (id: string): CollapsiblePanelData[] => {
        const intlHandler = IntlHandler.getInstance();
        return adjustmentsPresets.map(preset => {
            return {
                id: preset.id.toString(),
                name: intlHandler.formatMessage(`adjustment-preset-${preset.title}`) ?? "",
                previewUrl: presetsPreviewURLs[preset.id] ?? DEFAULT_PRESET_ID
            }
        });
    }

    const getLooksView = (): React.ReactNode => {
        return (
            <ELCollapsiblePanelView key={1} flyoutHeaderText={"temp"} panelID={"temp"}
                onThumbPress={(id) => onPresetChange(id)} onPress={() => {return;}} opened={true}
                data={getAdjustmentsPresets("temp")} selectedTemplateId={selectedPresetId} showImageText showLoadingPreview showHeadingDropdown={false} />
        )
    }

    return (
        <div className="looks-panel" data-testid="looks-panel">
            <Flex UNSAFE_className="looks-panel__header">
                <div>{intlHandler.formatMessage("looks")}</div>
                <div className={"looks-panel__reset"} title={intlHandler.formatMessage("looks-reset-tooltip-msg")}>
                    <ELCustomIcon variant="independent" iconkey={IconType.revert} color={Theme.dark.gray_controls_color_N}
                        hoverColor={Theme.dark.gray_controls_color_N} width={"1.5rem"} height={"1.5rem"}
                        onPress={onResetPress} isDisabled={selectedPresetId === DEFAULT_PRESET_ID}>
                    </ELCustomIcon>
                </div>
            </Flex>
            <Flex UNSAFE_className="looks-panel__body" gap={"0.72rem"}>
                <ELScrollPanel UNSAFE_className="looks-panel__scroll" scrollY={true} width="100%">
                    <div className="looks-panel__presets-container">
                        <Flex direction="column" gap="0.625rem" marginTop="1rem">
                            {getLooksView()}
                        </Flex>
                    </div>
                </ELScrollPanel>
                <Flex UNSAFE_className="looks-panel__intensity-slider">
                    <Slider
                        label={intlHandler.formatMessage("intensity")}
                        showValueLabel={false}
                        width={"100%"}
                        isDisabled={selectedPresetId === DEFAULT_PRESET_ID}
                        isFilled={true}
                        minValue={0.1}
                        maxValue={2}
                        step={0.01}
                        fillOffset={1}
                        onChange={onIntensityChange}
                        value={intensity}
                    />
                </Flex>
            </Flex>
        </div>
    );
}

export default ELLooksPanelView;
