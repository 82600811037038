/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "./IAction";

export enum CanvasMode {
    render = "RENDER",
    layout = "LAYOUT"
}

export type CanvasActionPayload = CanvasMode;

export default class CanvasAction {
    public static readonly UPDATE_MODE: string = "CanvasAction.UPDATE_MODE";

    public static updateMode(mode: CanvasMode): IAction<CanvasMode> {
        return {
            type: CanvasAction.UPDATE_MODE,
            payload: mode
        }
    }
}