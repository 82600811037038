/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum ELWhatsNewDialogAction {
    onDismiss = "ON_DISMISS",
    updateWhatsNewContent = "UPDATE_WHATS_NEW_CONTENT"
}

export interface ELWhatsNewButtonInfo {
    action: string,
    label: string,
    primaryCTA: string;
}

export interface ELWhatsNewDialogContentType {
    mediaType: string;
    contentURL: string,
    tabName: string,
    tabTitle: string,
    tabDescription: ELWhatsNewDescription[],
    buttonData: ELWhatsNewButtonInfo[],
    size: string;
}

export interface ELWhatsNewDescription {
    desc: string;
}

export interface ELWhatsNewSpecificInfoType {
    tabTitle: string,
    tabDescription: ELWhatsNewDescription[],
    size: string,
    button: ELWhatsNewButtonInfo[];
}

export interface ELWhatsNewPanelViewPropsData {
    itemContent: ELWhatsNewDialogContentType;
}

export interface ELWhatsNewTabData {
    key: number;
    tabName: string;
    panelData: React.ReactElement;
}