/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ShareResponse } from "../../common/interfaces/share/ShareTypes";

abstract class ShareHandler {

    protected initCompleted: boolean;

    constructor() {
        this.initCompleted = false;
    }

    get getInitCompleted(): boolean {
        return this.initCompleted;
    }

    init(callback?: () => void): void {
        return;
    }

    abstract shareLink(link: string, callback: (response: ShareResponse) => void, payload?: unknown): void;

}

export { ShareHandler }