/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import React, { useEffect, useReducer, useState } from "react";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";

//Application Specific
import { ELSampleMediaGridData } from "../../../../common/interfaces/media/ELSampleMediaTypes";
import IViewController from "../../../IViewController";
import { ViewAction } from "../../../IBaseController";
import { SampleMediaGridViewAction, SampleMediaManagerControllerAction } from "../../../../common/interfaces/workflows/SampleMediaManagerTypes";
import { ELDropZoneView } from "../el-drop-zone/ELDropZone";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { FILE_TYPE_FILTER } from "../../../../common/interfaces/storage/FileTypes";
import { ELCreationProgressView } from "../el-creation-progress-view/ELCreationProgressView";
import { FileUtils } from "../../../../utils/FileUtils";
import { KeyboardKey } from "../../../../utils/KeyboardConstants";

import "./ELSampleMediaGrid.scss";

interface ELSampleMediaGridViewProps {
    controller: IViewController,
    onMediaClick: (key: number) => void;
}

interface ELSamepleMediaGridViewState {
    mediaData: ELSampleMediaGridData[];
    progressText: string;
}

const defaultState = (): ELSamepleMediaGridViewState => {
    return { mediaData: [], progressText: "" };
};

const reducer = (state: ELSamepleMediaGridViewState, action: ViewAction): ELSamepleMediaGridViewState => {
    switch (action.type) {
        case SampleMediaGridViewAction.updateView: {
            return {
                ...state,
                mediaData: action.payload as ELSampleMediaGridData[]
            };
        }
        case SampleMediaGridViewAction.updateProgressText: {
            return {
                ...state,
                progressText: action.payload as string
            };
        }
        default:
            return state;
    }
};

export const ELSampleMediaGridView = (props: ELSampleMediaGridViewProps): React.ReactElement => {
    const [state, dispatch] = useReducer(reducer, defaultState());
    const [selectedImage, setSelectedImage] = useState<number | null>(null);
    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {
        props.controller.initialize(dispatch);

        //clean up
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    const handleMediaClick = (index: number): void => {
        if (selectedImage !== index) {
            props.onMediaClick(index);
            setSelectedImage(index);
        }
    };

    const handleMediaKeyDown = (event: React.KeyboardEvent<HTMLImageElement>, index: number): void => {
        if ((event.key === KeyboardKey.enter) || (event.key === KeyboardKey.space)) {
            handleMediaClick(index);
        }
    };

    const allowedFilesFilter = (file: File): boolean => {
        if (FILE_TYPE_FILTER.includes(file.type) || FileUtils.additionalSupportedFiles(file.name)) {
            return true;
        }
        return false;
    };

    const handleDroppedFiles = (files: File[]): void => {
        props.controller.notify({ type: SampleMediaManagerControllerAction.handleDroppedFiles, payload: files });
    };

    const getSampleMediaImageClassName = (index: number): string => {
        return selectedImage === index ? "sample-media__image selected" : "sample-media__image";
    };

    const getSampleMediaData = (): React.ReactElement => {
        return (
            <Flex gap="1.375rem" justifyContent="center">
                {state.mediaData.map((media, index) => {
                    return (
                        <div key={index}>
                            <img src={media.src} alt={media.alt} className={getSampleMediaImageClassName(index)} onClick={() => handleMediaClick(index)}
                                onKeyDown={(event) => handleMediaKeyDown(event, index)} role="button" tabIndex={0} />
                        </div>
                    );
                })}
            </Flex>
        );
    };

    const showProgressUI = (): boolean => {
        return state.progressText !== "";
    };

    return (
        <div>
            <div className="sample-media-item-container">
                <Flex width="36%" alignItems="center" direction="column" marginTop="2.5rem" marginBottom="2rem">
                    <ELDropZoneView controller={props.controller} allowedFilesFilter={allowedFilesFilter}
                        handleDroppedFiles={handleDroppedFiles} dragAndDropHeading={intlHandler.formatMessage("drag-and-drop-heading-text")}
                        dragAndDropContentText={intlHandler.formatMessage("drag-and-drop-content-text")}
                        dragAndDropFilledText={intlHandler.formatMessage("media-upload-in-progress")} />
                    <span className="sample-media__select-sample-text">{intlHandler.formatMessage("select-sample-media-text")}</span>
                    {getSampleMediaData()}
                </Flex>
            </div>
            {
                showProgressUI() && <ELCreationProgressView progressText={state.progressText} mode="parent" />
            }
        </div>
    );
};