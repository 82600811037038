/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { Routes } from "../app/AppRoute";
import { ELAdobeAsset } from "../common/interfaces/storage/AssetTypes";
import { ControllerAction } from "../view/IViewController";
import { WorkspacesName } from "../workspaces/IBaseWorkspace";
import { WorkflowActionType } from "../workspaces/IWorkflow";
import { LinkParams } from "./Constants/Constants";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestLogObjectValue, IngestWorkflowTypes } from "./IngestConstants";
import { IngestUtils } from "./IngestUtils";
import Utils from "./Utils";

export const SIV_DIALOG_CLASSNAME = "siv-dialog";

export enum SIVRouteURLParam {
    ORGANIZER = "organizer",
    LINK_PREVIEW = "link_preview"
}

export class SIVUtils {

    /**
     * @returns boolean - true if siv url is present
     */
    static hasSIVUrl(url: string): boolean {
        const pathRegEx = new RegExp(Routes.SIV, "gi");
        if (url.match(pathRegEx)) {
            return true;
        }
        return false;
    }

    /**
     * @returns boolean - true if siv workspace param is organizer
     */
    static isOrganizerRoute(href: string): boolean {
        const sivRouteURLParam = (new URLSearchParams(href))?.get(LinkParams.ELEMENTS_SIV_ROUTE_URL_PARAM);
        return sivRouteURLParam === SIVRouteURLParam.ORGANIZER;
    }

    /**
     * @returns boolean - true if siv workspace param is organizer
     */
    static isLinkPreviewRoute(href: string): boolean {
        const sivRouteURLParam = (new URLSearchParams(href))?.get(LinkParams.ELEMENTS_SIV_ROUTE_URL_PARAM);
        return sivRouteURLParam === SIVRouteURLParam.LINK_PREVIEW;
    }

    /**
     * @returns boolean - true if shared media
     */
    static isSharedMedia(ownerWorkspace: WorkspacesName): boolean {
        return ownerWorkspace === WorkspacesName.linkPreview;
    }

    /**
    * @returns boolean - true if user media
    */
    static isUserMedia(ownerWorkspace: WorkspacesName): boolean {
        return ownerWorkspace === WorkspacesName.media;
    }

    /**
     * @returns string - history state based on workspace, assetId
     */
    static getSIVHistoryState(ownerWorkspace: WorkspacesName, assetId: string): string {
        let historyState = Routes.SIV + "?" + LinkParams.ELEMENTS_MEDIA_ID_PARAM + "=" + assetId;

        const collectionId = (new URLSearchParams(window.location.search))?.get(LinkParams.ELEMENTS_COLLECTION_ID_PARAM);
        if (collectionId)
            historyState += "&" + LinkParams.ELEMENTS_COLLECTION_ID_PARAM + "=" + collectionId;

        const sivRouteParam = (SIVUtils.isUserMedia(ownerWorkspace)) ? SIVRouteURLParam.ORGANIZER : SIVRouteURLParam.LINK_PREVIEW;
        historyState += "&" + LinkParams.ELEMENTS_SIV_ROUTE_URL_PARAM + "=" + sivRouteParam;

        return historyState;
    }

    /**
     * @returns string - returns history state when we go from SIV to it's parent view
     */
    static getParentHistoryState(ownerWorkspace: WorkspacesName, collectionId: string): string {
        switch (ownerWorkspace) {
            case WorkspacesName.linkPreview:
                {
                    return Routes.VIEW + "?" + LinkParams.ELEMENTS_COLLECTION_ID_PARAM + "=" + collectionId;
                }
            case WorkspacesName.media:
            default:
                {
                    return Routes.MEDIA;
                }
        }
    }

    static async ingestSIVMedia(asset: ELAdobeAsset, notify: (_: ControllerAction) => void): Promise<void> {
        notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.sivMedia, IngestEventTypes.info, IngestEventSubTypes.type,
                Utils.isVideoMimeType(asset) ? IngestLogObjectValue.video : IngestLogObjectValue.photo)
        });
    }
    static async ingestMediaAction(ingestEventSubType: string, notify: (_: ControllerAction) => void): Promise<void> {
        notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.siv,
                IngestEventTypes.click, ingestEventSubType, true)
        });
    }

    static async ingestMediaActionStatus(ingestEventType: string, ingestEventSubType: string,
        notify: (_: ControllerAction) => void, format: string | undefined): Promise<void> {
        const additionalLogInfo: Record<string, string> = {};
        const eventContextId = Utils.getRandomUUID();
        additionalLogInfo[IngestLogObjectKey.eventContextGuid] = eventContextId;
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.siv;
        notify({
            type: WorkflowActionType.ingest,
            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, ingestEventSubType,
                ingestEventType, null, additionalLogInfo)
        });

        if (format) {
            const [type, extension] = format.split('/');

            additionalLogInfo[IngestLogObjectKey.contentName] = (type as string) + "_count";
            additionalLogInfo[IngestLogObjectKey.eventCount] = "1";
            notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.info,
                    ingestEventType, null, additionalLogInfo)
            });

            additionalLogInfo[IngestLogObjectKey.contentName] = (extension as string) + "_count";
            additionalLogInfo[IngestLogObjectKey.eventCount] = "1";
            notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.info,
                    ingestEventType, null, additionalLogInfo)
            });

            additionalLogInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.totalCount;
            additionalLogInfo[IngestLogObjectKey.eventCount] = "1";
            notify({
                type: WorkflowActionType.ingest,
                payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.info,
                    ingestEventType, null, additionalLogInfo)
            });
        }
    }
}
