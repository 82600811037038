/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { ReactNode } from "react";

//Application specific
import { IconType } from "../../../assets/IconConstants";
import IViewController from "../../../view/IViewController";

export interface ITabPanelButton {
    iconType: IconType,
    name: string
    buttonType?: TabPanelButtonTypes
}

export interface ITabPanelButtonInfo {
    key: ELTabPanelKey,
    buttonInfo: ITabPanelButton,
}

export interface ITabPanelItem {
    key: ELTabPanelKey,
    buttonInfo: ITabPanelButton,
    controller: IViewController,
    view: React.ReactNode
}

export enum TabPanelButtonTypes {
    scoopedTabButton = "SCOOPED_TAB_BUTTON"
}

export const ELTabPanelViewAction = {
    selectedKey: "SELECTED_KEY"
};

export enum ELTabPanelKey {
    media = "media",
    template = "template",
    background = "background",
    layout = "layout",
    overlays = "overlays",
    looks = "looks",
    text = "text",
    empty = ""
}

export interface ELTabItem {
    item: ReactNode
}

export interface ELTabPanel {
    panel: ReactNode
}

export interface ELTabData {
    key: string,
    item: ELTabItem,
    panel: ELTabPanel
}

export type ELFlexDirection = "row" | "row-reverse" | "column" | "column-reverse" | "initial" | "inherit";

export enum ELTabPanelType {
    leftTabPanel,
    rightTabPanel,
    bottomTabPanel,
    floatingTabPanel
}

export const enum ELTabPanelActions {
    updateCurrentMedia = "UPDATE_CURRENT_MEDIA",
    initWorkflow = "INIT_WORKFLOW"
}