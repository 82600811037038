/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer } from "react";

//Application Specific
import ELOpenInDesktopManager from "./ELOpenInDesktopManager";
import { ViewAction } from "../../../IBaseController";
import { ELOpenDeeplinkAction, ELOpenInDesktopDialogIngestData, ELOpenInDesktopManagerViewAction } from "../../../../common/interfaces/creations/ELOpenInDesktopTypes";
import { URLConstants } from "../../../../utils/Constants/URLConstants";
import ELOpenInDesktopDialogContainer from "../../molecules/el-open-in-desktop-dialogcontainer/ELOpenInDesktopDialogContainer";
import { IngestWorkflowTypes, IngestEventTypes, IngestEventSubTypes } from "../../../../utils/IngestConstants";

interface ELOpenInDesktopManagerViewProps {
    controller: ELOpenInDesktopManager,
}

interface ELOpenInDesktopState {
    isDialogVisible: boolean
}

const initialState: ELOpenInDesktopState = {
    isDialogVisible: false
}

const reducer = (state: ELOpenInDesktopState, action: ViewAction): ELOpenInDesktopState => {
    switch (action.type) {
        case ELOpenInDesktopManagerViewAction.openDeeplinkDialog: {
            const payload = action.payload as boolean;
            return (
                {
                    ...state,
                    isDialogVisible: payload
                }
            );
        }
        default:
            return state;
    }
}

const ELOpenInDesktopManagerView = (props: ELOpenInDesktopManagerViewProps): React.ReactElement => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        props.controller.initialize(dispatch);

        //for clean-up, called when the view is unmounted
        return () => {
            props.controller.destroy();
        };
    }, [props.controller]);

    const resetOpenInDesktopState = (): void => {
        dispatch({ type: ELOpenInDesktopManagerViewAction.openDeeplinkDialog, payload: false });
        props.controller.notify({
            type: ELOpenDeeplinkAction.openInDesktopDialogIngest,
            payload: {
                subCategory: IngestWorkflowTypes.errorDialogOpenDesktop,
                eventType: IngestEventTypes.click,
                eventSubType: IngestEventSubTypes.ok
            } as ELOpenInDesktopDialogIngestData
        });
    }

    const onLearnMoreClick = (): void => {
        props.controller.notify({
            type: ELOpenDeeplinkAction.openInDesktopDialogIngest,
            payload: {
                subCategory: IngestWorkflowTypes.errorDialogOpenDesktop,
                eventType: IngestEventTypes.click,
                eventSubType: IngestEventSubTypes.learnMore
            } as ELOpenInDesktopDialogIngestData
        });
    }

    return (<>
        {state.isDialogVisible &&
            <ELOpenInDesktopDialogContainer dataTestId="openInDesktopManager" onPressReset={resetOpenInDesktopState}
                onLearnMoreClick={onLearnMoreClick} learnMoreLink={URLConstants.GoURL.LEARN_MORE_OPEN_IN_DESKTOP_DIALOG_LINK}></ELOpenInDesktopDialogContainer>}
    </>)
}

export default ELOpenInDesktopManagerView;