/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from "react";
import Logger, { LogLevel } from "../../utils/Logger";

import { ViewAction } from "../../view/IBaseController";
import ELMediaGridToolBar from "../../view/components/molecules/el-media-grid-toolbar/ELMediaGridToolbar";
import IWorkflow, { WorkflowActionType, WorkflowsName } from "../IWorkflow";
import { WorkspaceAction, WorkspaceActionType, WorkspacesName } from "../IBaseWorkspace";
import WorkflowFactory from "../WorkflowFactory";
import IViewController, { ControllerAction } from "../../view/IViewController";
import MediaGridWorkflow from "./workflows/mediaGrid/MediaGrid";
import { ModalWorkspaceName } from "../IModalWorkspace";
import { ShareOptions } from "../../view/components/organism/el-share-options/ELShareOptions";
import ModalWorkspaceFactory from "../ModalWorkspaceFactory";
import { Routes } from "../../app/AppRoute";
import { SIVUtils, SIV_DIALOG_CLASSNAME } from "../../utils/SIVUtils";
import IWorkspace from "../IWorkspace";
import App from "../../app/App";

export default class Organizer extends IWorkspace {
    private _mediaGrid: MediaGridWorkflow;
    private _toolbar: IViewController;
    private shareOptions: ShareOptions;
    private _route: Routes;

    private readonly _workflowContainerId = "organizer-app-container";
    private readonly _modalAppContainerId = "organizer-modal-app-container";

    constructor(owner: App) {
        super(owner);

        this.workspaceName = WorkspacesName.media;
        this._route = Routes.MEDIA;
        this._mediaGrid = WorkflowFactory.createWorkflow(WorkflowsName.mediaGrid, this) as MediaGridWorkflow;
        this._toolbar = new ELMediaGridToolBar(this._mediaGrid);
        this._mediaGrid.toolbar = this._toolbar;
        this.shareOptions = new ShareOptions(this);
        this._mediaGrid.shareOptionsController = this.shareOptions;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);

        this._owner.createNavbarView();

        if (SIVUtils.hasSIVUrl(window.location.href)) {
            this._route = Routes.SIV;
        } else {
            this._route = Routes.MEDIA;
        }

        switch (this._route) {
            case Routes.SIV:
                {
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: WorkflowsName.singleImageView };
                    this._startWorkflows(workspaceAction);
                    break;
                }
            case Routes.MEDIA:
            default:
                {
                    this.startWorkflow(this._workflowContainerId, this._mediaGrid);
                    break;
                }
        }
    }

    destroy(): void {
        this._owner.destroyNavbarView();
        super.destroy();
        return;
    }

    endCurrentWorkflow(): IWorkflow | undefined {
        return super.endCurrentWorkflow();
    }

    private _startModalWorkspace<T extends WorkspaceAction>(action: T): void {
        const modalWorkspaceName = action.startModalWorkspace;

        const payload = action.payload as Record<string, unknown>;
        const overlayDiv = payload?.overlayDiv as string;
        const workflowName = payload?.workflowName as WorkflowsName;

        if (modalWorkspaceName && overlayDiv) {
            this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(modalWorkspaceName, this, this.ensureHTMLElement(overlayDiv) as HTMLDivElement);
            this.modalWorkspace.startWorkspace(this._modalAppContainerId, workflowName);
        }
    }

    private _startWorkflows(action: WorkspaceAction): void {
        switch (action.startWorkflow) {
            case WorkflowsName.singleImageView:
                {
                    const { type, ...workflowPayload } = action;
                    const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };
                    this.modalWorkspace = ModalWorkspaceFactory.createModalWorkspace(ModalWorkspaceName.modalWrapper, this, this.ensureHTMLElement("root") as HTMLDivElement, SIV_DIALOG_CLASSNAME);
                    this.modalWorkspace.startWorkspace(this._modalAppContainerId, WorkflowsName.singleImageView, workflowAction);
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "Organizer:_startWorkflows: ", "Invalid next workflow name");
                    break;
                }
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startModalWorkspace: {
                this._startModalWorkspace(action);
                handled = true;
                break;
            }
            case WorkspaceActionType.endModalWorkspace: {
                super.endModalWorkspace();
                handled = true;
                break;
            }
            case WorkspaceActionType.startWorkflow:
                {
                    this._startWorkflows(action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.startPreviousWorkflow:
                {
                    super.startPrevWorkflow(this._workflowContainerId, action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.startDefaultWorkflow:
                {
                    if (this.getWorkflow !== this._mediaGrid) {
                        this.startWorkflow(this._workflowContainerId, this._mediaGrid);
                    }
                    handled = true;
                    break;
                }
            default:
                {
                    handled = await super.notify(action as WorkspaceAction);
                    break;
                }
        }
        return handled;
    }

    get getShareOptionsController(): ShareOptions {
        return this.shareOptions;
    }
}
