/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useReducer, ReactNode, useEffect } from "react";

// Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { Text } from '@react-spectrum/text';
import { RenditionType } from "@dcx/assets";

//Application Specific
import ELVideo from "../../../../view/components/atoms/el-video/ELVideo";
import ELProgressBanner from "../../../../view/components/organism/el-progress-banner/ELProgressBanner";
import ELProgressContent from "../../../../view/components/organism/el-progress-content/ELProgressContent";
import ELButton from "../../../../view/components/atoms/el-button/ELButtonView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { CreationName, CreationsData, CreationsDisplayNames, CreationsStatus } from "../../../../common/interfaces/creations/CreationTypes";
import { ViewAction } from "../../../../view/IBaseController";
import Utils from "../../../../utils/Utils";
import { ELIcon } from "../../../../view/components/atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import IViewController from "../../../../view/IViewController";
import CreationUtils from "../../utils/CreationUtils";
import { CreationPreviewActions, ELCreationPreviewState } from "../../../../common/interfaces/creations/ELCreationPreviewTypes";

import "./CreationPreview.scss";

interface ICreationPreviewProps {
    controller: IViewController,
    projectData?: CreationsData
}

const initialState: ELCreationPreviewState = {
    id: Utils.getNilUUID(),
    status: CreationsStatus.requested,
    renditionType: RenditionType.IMAGE_JPG,
    renditionData: {},
    isRenditionFailed: false
}


export const CreationPreviewView = (props: ICreationPreviewProps): React.ReactElement => {
    const reducer = (state: ELCreationPreviewState, action: ViewAction): ELCreationPreviewState => {
        switch (action.type) {
            case CreationPreviewActions.renditionStatus: {
                const data = action.payload as ELCreationPreviewState;
                return {
                    ...state,
                    isRenditionFailed: data.isRenditionFailed,
                };
            }
            case CreationPreviewActions.creationPreviewData: {
                const data = action.payload as ELCreationPreviewState;
                return {
                    ...state,
                    id: data.id,
                    status: data.status,
                    renditionType: data.renditionType,
                    renditionData: data.renditionData,
                };
            }
            default:
                return state;
        }
    }

    const getCreationName = (): string => {
        let creationName = CreationName.creation;
        if (props.projectData) {
            creationName = CreationUtils.getCreationNameFromOperationSubtype(props.projectData);
        }
        return intlHandler.formatMessage("retrieving-your") + " " + intlHandler.formatMessage(CreationsDisplayNames[creationName]);
    }

    const [state, viewDispatch] = useReducer(reducer, initialState);
    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    const creationSucceeded = (): boolean => {
        if ((state.renditionData.videoData) || (state.renditionData.imgData)) return true
        return false;
    }

    const creationInProgress = (): boolean => {
        return !(creationSucceeded() || isCreationRenditionFailed());
    }

    const isOpeningFailedProject = (): boolean => {
        if (props.projectData?.status === CreationsStatus.success)
            return false;
        return true;
    }

    const onBackCreationPreview = (): void => {
        props.controller.notify({ type: CreationPreviewActions.backCreationView });
    }

    const isCreationRenditionFailed = (): boolean => {
        return state.isRenditionFailed;
    }

    const getCreationMediaRendition = (): ReactNode => {
        if (state.renditionType === RenditionType.VIDEO_METADATA && state.renditionData.videoData) {
            return (<ELVideo key={state.renditionData.videoData} UNSAFE_className="creation-preview-container-video" variant="native" type="video/mp4"
                posterURL={state.renditionData.imgData}
                src={state.renditionData.videoData} />);
        } else if (state.renditionType === RenditionType.IMAGE_JPG && state.renditionData.videoData) {
            return <img crossOrigin="anonymous" key={state.renditionData.videoData} className="creation-preview-container-image" data-testid="siv-img" onContextMenu={(e) => e.preventDefault()}
                src={state.renditionData.videoData} alt="Preview"/>;
        } else
            return (<></>);
    }

    const showCreationProgress = (): ReactNode => {
        if (isOpeningFailedProject() || isCreationRenditionFailed()) {
            return getFailedCreationBanner(intlHandler.formatMessage("invalid-creation-rendition"));
        } else if (creationInProgress()) {
            return getProgressBanner("100%");
        } else
            return (<></>);
    }

    const showCreationMedia = (): ReactNode => {
        if (!isCreationRenditionFailed()) {
            return getCreationMediaRendition();
        }
        return <></>;
    }

    const getProgressBanner = (bannerHeight: string, progressContent = ""): ReactNode => {
        return (
            <div className="creation-progress-container">
                <ELProgressBanner key="creation_progress_banner" height={bannerHeight} width="100%">
                    <ELProgressContent contentHeader={progressContent} progressText={intlHandler.formatMessage(getCreationName())} >
                    </ELProgressContent>
                </ELProgressBanner>
            </div>
        );
    }

    const getFailedCreationBanner = (primaryText: string): ReactNode => {
        return (<Flex UNSAFE_className="failed-creation__box" direction="column" alignItems="center"
            justifyContent="center" height="100%">
            <ELIcon iconkey={IconType.negativeSentimentIcon} width="3.375rem" height="3.375rem" />
            <Flex direction="column" marginTop="size-300" alignItems="center" justifyContent="center">
                <Text data-testid="failed-creation-text" UNSAFE_className="failed-creation__primary_text">
                    {primaryText}
                </Text>
            </Flex>
            <Flex direction="row" marginTop="size-1200" gap="1rem">
                <ELButton dataTestId="creation-back" size="L" variant="accent" onClick={onBackCreationPreview}>
                    <Text UNSAFE_className="failed-creation__cta-text">{intlHandler.formatMessage("back")} </Text>
                </ELButton>
            </Flex>
        </Flex>);
    }

    return (
        <div id="creation-preview-container">
            <div id="creation-preview-header-container" />
            <Flex justifyContent={"center"}>
                <div className="creation-preview-main-content-box">
                    <div id="creation-preview-loading-circle-box">
                        {showCreationProgress()}
                    </div>
                    <div id="creation-preview-media-container">
                        {showCreationMedia()}
                    </div>
                </div>
            </Flex>
        </div>
    )
};

export default CreationPreviewView;
