/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Adobe Internal
import { DialogTrigger } from "@adobe/react-spectrum";

//Application Specific
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Utils from "../../../../utils/Utils";
import ELActionButton, { ELActionButtonVariant } from "../../molecules/el-action-button/ELActionButton";
import { ELConfirmationDialog } from "../el-confirmation-dialog/ELConfirmationDialog";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";

interface ELDeleteDialogProps {
    isMobilePortraitMode: boolean,
    selectedItemCount: number,
    deletePressCallback: () => void,
    text?: string,
    actionButtonVariant?: ELActionButtonVariant,
    actionButtonPressCallback: () => void
}

export const ELDeleteDialog = (props: ELDeleteDialogProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();
    const deleteIcon = (<ELIcon iconkey={IconType.delete} fill={"#fff"} className="el-action-button__icon" />);
    const textBody = (<>
        {intlHandler.formatMessage("confirm-delete-media-dialog-message-str1")}
        < br /> < br />
        {intlHandler.formatMessage("confirm-delete-media-dialog-message-str2")}
    </>);

    const deleteIconforDialog = (<ELIcon iconkey={IconType.deleteforDialog} aria-label="Delete Button" />);
    return (<ELReactSpectrumV3Provider key="Delete">
        <DialogTrigger type={Utils.getSpectrumDialogType((props.isMobilePortraitMode as boolean))} isDismissable>
            <ELActionButton tooltip={intlHandler.formatMessage("delete")} elIcon={deleteIcon} text={props.text} variant={props.actionButtonVariant}
                onPress={() => { props.actionButtonPressCallback(); }} dataTestId="delete" />
            {(close) => (
                <ELConfirmationDialog confirm={() => {
                    props.deletePressCallback();
                    close();
                }} close={close}
                    text={textBody}
                    heading={intlHandler.formatMessage("delete-selected-items")}
                    subHeading={intlHandler.formatMessage("selected", { itemCount: props.selectedItemCount })}
                    iconType={deleteIconforDialog} />
            )}
        </DialogTrigger>
    </ELReactSpectrumV3Provider>
    )
}
