/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";

//Adobe internal
import { Flex } from "@adobe/react-spectrum";
import { DialogTrigger } from "@react-spectrum/dialog";

//Application specific
import { ViewAction } from "../../../IBaseController";
import ELImportProgressBar from "./ELImportProgressBar";
import ELButton from "../../atoms/el-button/ELButtonView";
import { ELConfirmationDialog } from "../../organism/el-confirmation-dialog/ELConfirmationDialog";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { useViewport } from "../../../../utils/hooks/useViewport";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import Utils from "../../../../utils/Utils";
import ELCustomIcon from "../el-custom-icon/ELCustomIcon";
import { Theme } from "../../../../utils/Theme";
import { ProgressState, ELProgressValue, ELProgressBarActions } from "../../../../common/interfaces/import/ImportProgressTypes";
import { useUnloadHandler } from "../../../../utils/hooks/useUnloadHandler";
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";
import MediaGridToolbarAction from "../../../../stores/actions/mediaGridToolbarAction";

import "./ELImportProgressBar.scss";

export interface ELImportProgressBarProps {
    controller: ELImportProgressBar,
    currentProgress: ELProgressValue,
}

export interface ELProgressBarState {
    progressState: ProgressState,
    value: number,
    total: number,
    isStopButtonVisible: boolean,
    isRetryButtonVisible: boolean
}

const getcurrentProgress = (currentProgress: ELProgressValue): ELProgressBarState => {
    const state: ELProgressBarState = {
        progressState: currentProgress.progressState,
        value: currentProgress.value,
        total: currentProgress.total,
        isStopButtonVisible: false,
        isRetryButtonVisible: false
    };
    return state;
}

const reducer = (state: ELProgressBarState, action: ViewAction): ELProgressBarState => {
    const newState = { ...state };
    switch (action.type) {
        case ELProgressBarActions.updateProgress: {
            const payload = action.payload as ELProgressValue;
            if (!payload) {
                Logger.log(LogLevel.DEBUG, "[ELProgressBarActions.updateProgress] Invalid payload type");
                return newState;
            }
            newState.progressState = payload.progressState;
            newState.total = payload.total;
            newState.value = payload.value;
            return newState;
        }
        case ELProgressBarActions.showStopButton: {
            const showButton = action.payload as boolean;
            if (!showButton) {
                Logger.log(LogLevel.DEBUG, "[ELProgressBarActions.showStopButton] Invalid payload type");
                return newState;
            }
            newState.isStopButtonVisible = showButton;
            return newState;
        }
        case ELProgressBarActions.showRetryButton: {
            const showButton = action.payload as boolean;
            if (!showButton) {
                Logger.log(LogLevel.DEBUG, "[ELProgressBarActions.showRetryButton] Invalid payload type");
            }
            newState.isRetryButtonVisible = showButton;
            return newState;
        }
        default: {
            Logger.log(LogLevel.DEBUG, "[ELProgressBarActions] Invalid action type");
            throw new Error("Invalid action");
        }
    }
}

const ELImportProgressBarView = (props: ELImportProgressBarProps): React.ReactElement<ELImportProgressBarProps> => {
    const [state, viewDispatch] = useReducer(reducer, getcurrentProgress(props.currentProgress));
    const { isMobilePortraitMode } = useViewport();
    const dispatch = useDispatch();

    const handleNoInternet = (): void => {
        // stop in case of no internet
        handleStopButtonPress();
    }

    useUnloadHandler(false);

    useEffect(() => {
        dispatch(MediaGridToolbarAction.updateImportProgressBarVisibility(true));
        props.controller.initialize(viewDispatch);
        window.addEventListener("offline", handleNoInternet);

        return () => {
            dispatch(MediaGridToolbarAction.updateImportProgressBarVisibility(false));
            window.removeEventListener("offline", handleNoInternet);
            props.controller.destroy();
        }
    }, [props.controller]);

    const getProgressIndicatorClassName = (): string => {

        let name = "progressIndicator ";
        switch (state.progressState) {
            case ProgressState.inProgress: {
                name += "progressIndicatorColor__inProgress";
                break;
            }
            case ProgressState.interrupted: {
                name += "progressIndicatorColor__error";
                break;
            }
            default: {
                Logger.log(LogLevel.DEBUG, "Invalid Progress state");
            }
        }
        return name;
    }

    const intlHandler = IntlHandler.getInstance();

    const getDisplayMessage = (): string => {
        let message = "";
        switch (state.progressState) {
            case ProgressState.notStarted: {
                message = intlHandler.formatMessage("upload-notstarted");
                break;
            }
            case ProgressState.inProgress: {
                message = intlHandler.formatMessage("upload-inProgress", { value: state.value, total: state.total });
                break;
            }
            case ProgressState.interrupted: {
                message = intlHandler.formatMessage("upload-interrupted")
                break;
            }
            default: {
                Logger.log(LogLevel.DEBUG, "Invalid Progress state");
            }
        }
        return message;
    }

    const getProgressIndicatorWidth = (): string => {
        let percent = "0%";
        if (state.total !== 0) {
            const val = (state.value / state.total) * 100;
            percent = val.toString() + "%";
        }
        return percent;
    }

    const handleRetryButtonPress = (): void => {
        props.controller.notify({ type: ELProgressBarActions.retryUpload });
    }

    const handleStopButtonPress = (): void => {
        props.controller.notify({ type: ELProgressBarActions.stopUpload });
    }

    const getCloseConfirmationDialog = (close: () => void): React.ReactElement => {
        const textBody = (<>
            {intlHandler.formatMessage("import-stop-dialog-body-str1")}
            < br />
            {intlHandler.formatMessage("import-stop-dialog-body-str2")}
        </>);
        const stopImportIcon = <ELIcon iconkey={IconType.importStop} aria-label={intlHandler.formatMessage("import-stop-dialog-icon-label")}></ELIcon>
        return <ELConfirmationDialog confirm={() => {
            handleStopButtonPress();
            close();
        }} close={close}
            text={textBody}
            heading={intlHandler.formatMessage("import-stop-dialog-heading")}
            subHeading={intlHandler.formatMessage("media")}
            iconType={stopImportIcon} />
    }

    const getStopTriggerControl = (): React.ReactElement => {
        return (<>
            <div className="el-progress-bar-footer__stop_trigger_desktop">
                <ELButton>
                    {intlHandler.formatMessage("stop")}
                </ELButton>
            </div>
            <div className="el-progress-bar-footer__stop_trigger_mobile">
                <ELCustomIcon variant="independent" iconkey={IconType.close} color={Theme.global.controls_color_N}
                    hoverColor={Theme.global.controls_color_H} width={"2.2rem"} height={"2.2rem"} />
            </div>
        </>);
    }

    const getStopButton = (): React.ReactElement => {
        return (
            <ELReactSpectrumV3Provider key="Delete">
                <DialogTrigger type={Utils.getSpectrumDialogType((isMobilePortraitMode as boolean))} isDismissable>
                    {getStopTriggerControl()}
                    {(close) => (
                        getCloseConfirmationDialog(close)
                    )}
                </DialogTrigger>
            </ELReactSpectrumV3Provider>
        );
    }

    const getRetryButton = (): React.ReactElement => {
        return (<>
            <div className="el-progress-bar-footer__retry_desktop">
                <ELButton onClick={handleRetryButtonPress} >
                    <div className="progressButtonText">{intlHandler.formatMessage("retry")}</div>
                </ELButton>
            </div>
            <div className="el-progress-bar-footer__retry_mobile">
                <ELCustomIcon variant="independent" iconkey={IconType.restartIcon} color={Theme.global.controls_color_N}
                    hoverColor={Theme.global.controls_color_H} width={"2rem"} height={"2rem"} />
            </div>
        </>);
    }

    return (
        <Flex UNSAFE_className="el-progress-bar-footer" data-testid={"el-progress-bar-footer"}>
            <div className="el-progress-bar__message-box">
                <span className="el-progress-bar__message">{getDisplayMessage()}</span>
            </div>
            <div className="progress-bar">
                <div className="progressContainer">
                    <div className={getProgressIndicatorClassName()} style={{ "width": getProgressIndicatorWidth() }} ></div>
                </div>
                <Flex UNSAFE_className="el-progress-bar__button-grp" gap="size-200">
                    {state.isRetryButtonVisible && getRetryButton()}
                    {state.isStopButtonVisible && getStopButton()}
                </Flex>
            </div>
        </Flex>
    );
}

export default ELImportProgressBarView;
