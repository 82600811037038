/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { fabric } from "fabric";
import IAction from "./IAction";

export type PhotoTextActionPayload = number | string | boolean | fabric.Gradient | fabric.Pattern;

export default class PhotoTextAction {
    public static readonly UPDATE_TRANSPARENT_BACKGROUND: string = "PhotoTextAction.UPDATE_TRANSPARENT_BACKGROUND";
    public static readonly UPDATE_BACKGROUND: string = "PhotoTextAction.UPDATE_BACKGROUND";
    public static readonly UPDATE_OPACITY: string = "PhotoTextAction.UPDATE_OPACITY";

    public static updateTransparentBackground(isTransparent: boolean): IAction<boolean> {
        return {
            type: PhotoTextAction.UPDATE_TRANSPARENT_BACKGROUND,
            payload: isTransparent
        };
    }

    public static updateBackground(background: string | fabric.Pattern | fabric.Gradient): IAction<string | fabric.Pattern | fabric.Gradient> {
        return {
            type: PhotoTextAction.UPDATE_BACKGROUND,
            payload: background
        };
    }

    public static updateOpacity(opacity: number): IAction<number> {
        return {
            type: PhotoTextAction.UPDATE_OPACITY,
            payload: opacity
        };
    }
}