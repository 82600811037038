/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

export enum ELOpenInDesktopDeeplinkAction {
    deeplinkStatusChanged = "DEEPLINK_STATUS_CHANGED",
    tryThis = "TRY_THIS",
    deeplinkCallbackStatus = "DEEPLINK_CALLBACK_STATUS",
    deeplinkTryAgain = "DEEPLINK_TRY_AGAIN"
}

export enum ELOpenDeeplinkAction {
    openDeepLinkForAsset = "OPEN_DEEPLINK_FOR_ASSET",
    openInDesktopDialogIngest = "OPEN_IN_DESKTOP_DIALOG_INGEST"
}

export enum ELOpenInDesktopManagerViewAction {
    openDeeplinkDialog = "OPEN_DEEPLINK_DIALOG",
    inProgressStatus = "IN_PROGRESS_STATUS",
    progressCompleteStatus = "PROGRESS_COMPLETE_STATUS"
}

export interface ELOpenInDesktopOpenAssetPayload {
    assetId: string,
    assetType: string
}

export interface ELOpenInDesktopDialogIngestData {
    subCategory: string,
    eventType: string,
    eventSubType: string
}