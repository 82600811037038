/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Application Specific
import { ELReactSpectrumV3Provider } from "../../atoms/el-react-spectrum-provider/ELReactSpectrumV3Provider";


interface ELCreatePopoverProps {
    popoverButtonPress: () => void,
    children?: React.ReactNode
}

const ELCreatePopover = (props: ELCreatePopoverProps): React.ReactElement => {
    return (
        <ELReactSpectrumV3Provider>
            {props.children}
        </ELReactSpectrumV3Provider>
    );
}

export default ELCreatePopover;