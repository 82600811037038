/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { ELQRSharePayload, ELQRShareVersion, QR_WEB_APP_SOURCE_NAME } from "../../common/interfaces/share/ShareTypes";
import { APPLICATION_TYPE } from "../../utils/Constants/AppConstants";

export enum LinkKeys {
    deeplinkPath = "$deeplink_path",
    PLATFORM = "platform",
    PRODUCT = "product",
    ENV = "env",
    CATEGORY = "category",
    SOURCE = "source",
    ASSETID = "assetId",
    COLLECTIONID = "collectionId",
    ACTION = "action",
    VERSION = "version"
}

export enum LinkOperators {
    equal = "=",
    and = "&",
    questionMark = "?"
}

export default abstract class IMediaShareLinkCreator {
    private _getDeeplinkPath = (searchParams: [LinkKeys, string][]): string => {
        let deeplinkPath = "";
        searchParams.forEach((param, index) => {
            deeplinkPath += param[0] + LinkOperators.equal + param[1];
            if (index + 1 < searchParams.length) {
                deeplinkPath += LinkOperators.and;
            }
        });
        return deeplinkPath;
    }

    protected getCommonParams = (): [LinkKeys, string][] => {
        if (process.env.REACT_APP_ELEMENTS_DEPLOYMENT_STACK === undefined) {
            throw new Error("Content Server not found in env");
        }
        const searchParams: [LinkKeys, string][] = [
            [LinkKeys.PLATFORM, APPLICATION_TYPE],
            [LinkKeys.PRODUCT, QR_WEB_APP_SOURCE_NAME],
            [LinkKeys.ENV, process.env.REACT_APP_ELEMENTS_DEPLOYMENT_STACK],
            [LinkKeys.VERSION, ELQRShareVersion.version_1_0]
        ];
        return searchParams;
    }

    async createLink(payload: ELQRSharePayload): Promise<string> {
        try {
            const searchParams = await this.getParams(payload);
            const deeplinkPath = this._getDeeplinkPath(searchParams);
            const encodedDeeplinkPath = encodeURIComponent(deeplinkPath);
            const link = process.env.REACT_APP_BRANCHIO_QR_SHARE_APP_LINK + LinkOperators.questionMark + LinkKeys.deeplinkPath + LinkOperators.equal + encodedDeeplinkPath;
            return Promise.resolve(link);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    abstract getParams(payload: ELQRSharePayload): Promise<[LinkKeys, string][]>;
}