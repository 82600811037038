/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import _ from "lodash";

//Adobe Internal
import { ELAdobeAsset } from "@elements/elementswebcommon";

//Application specific
import {
    FilterMediaUiProp,
    GridMediaType,
    MediaGridConfig,
    MediaGridSortBy
} from "../../../stores/actions/mediaGridConfigActions";
import { GRID_CONFIG_KEY } from "../../../stores/reducers/mediaGridConfigReducer";
import {
    GIF_FILE_FORMAT,
    PSDC_FILE_FORMAT,
    PSD_FILE_FORMATS
} from "../../../common/interfaces/storage/FileTypes";
import store from "../../../stores/store";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import CreationUtils from "../../creations/utils/CreationUtils";
import { Routes } from "../../../app/AppRoute";
import { EditWorkspaceURLParams } from "../../../common/interfaces/editing/editWorkspace/EditWorkspaceTypes";
import { WorkflowsName } from "../../IWorkflow";
import Utils from "../../../utils/Utils";

export default class ImageEditWorkflowUtils {
    static getMediaGridConfig(): MediaGridConfig {
        const mediaGridConfig = _.cloneDeep(store.getState().mediaConfigReducer[GRID_CONFIG_KEY]);

        mediaGridConfig.sortBy = MediaGridSortBy.importDate;
        mediaGridConfig.mediaType = GridMediaType.eImageOnly;

        const filterData = {
            format: [PSDC_FILE_FORMAT, GIF_FILE_FORMAT, ...PSD_FILE_FORMATS],
            uiProp: FilterMediaUiProp.hide
        }

        mediaGridConfig.filterMedia = filterData;
        mediaGridConfig.maxMediaCount = 1;
        mediaGridConfig.maxImageCount = 1;
        mediaGridConfig.minImageCount = 1;
        mediaGridConfig.maxVideoCount = 0;
        mediaGridConfig.maxTotalMediaSize = 400;
        mediaGridConfig.maxVideoLength = 0;
        mediaGridConfig.selectUploadedMedia = true;
        return mediaGridConfig;
    }

    static async getFilteredAssetList(assets: ELAdobeAsset[], mediaGridConfig: MediaGridConfig): Promise<ELAdobeAsset[]> {
        let filteredAssets = AssetStorageUtils.filterRemoveVideoAssets(assets);
        filteredAssets = AssetStorageUtils.filterRemoveGifAssets(filteredAssets);
        filteredAssets = CreationUtils.filterImagesByCount(filteredAssets, mediaGridConfig);
        return filteredAssets;
    }

    static canStartWorkflow(selectedAssets: ELAdobeAsset[]): boolean {
        const minImageCount = ImageEditWorkflowUtils.getMediaGridConfig().minImageCount;
        if (minImageCount && selectedAssets.length >= minImageCount) {
            return true;
        }
        return false;
    }

    static isMaxImageLimitExceeded(selectedAssets: ELAdobeAsset[]): boolean {
        const maxImageCount = ImageEditWorkflowUtils.getMediaGridConfig().maxImageCount;
        if (maxImageCount && selectedAssets.length > maxImageCount) {
            return true;
        }
        return false;
    }

    static hasImageEditWorkflowUrl(url: string): boolean {
        const workflow = Utils.getLinkParamValue(url, EditWorkspaceURLParams.workflow);
        return workflow !== null && workflow.toLowerCase() === WorkflowsName.imageEditWorkflow.toLowerCase();
    }

    static getAssetURNFromUrl(url: string): string {
        const assetURN = Utils.getLinkParamValue(url, EditWorkspaceURLParams.assetURN);
        return assetURN ?? Utils.getNilUUID();
    }

    /**
    * @returns string - history state
    */
    static getHistoryState(assets: ELAdobeAsset[]): string {
        //TODO: Glia Revisit this code
        // this URL is now hooked with the logic that we only have one asset in the list
        const historyState = Routes.EDIT + "?" + EditWorkspaceURLParams.workflow + "=" + WorkflowsName.imageEditWorkflow.toLowerCase() +
            "&" + EditWorkspaceURLParams.assetURN + "=" + assets[0].assetId;
        return historyState;
    }
}