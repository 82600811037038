/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { Asset } from "@elements/elementswebcommon";

//Application Specific
import { CreationsData, CreationsJobProjectSubType } from "../../../../common/interfaces/creations/CreationTypes";
import { ELCollagePathResolver } from "./ELCollagePathResolver";
import { ELCreationsPathResolver } from "./ELCreationsPathResolver";
import { ELSlideshowPathResolver } from "./ELSlideshowPathResolver";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { ELPatternOverlayPathResolver } from "./ELPatternOverlayPathResolver";
import { ELMovingOverlayPathResolver } from "./ELMovingOverlayPathResolver";

import { ELAssetPathOrId } from "../../../../common/interfaces/storage/AssetTypes";
import { ELPeekThroughPathResolver } from "./ELPeekThroughPathResolver";
import { ELReplaceBackgroundPathResolver } from "./ELReplaceBackgroundPathResolver";
import { ELMagicalBackdropPathResolver } from "./ELMagicalBackdropPathResolver";
import { ELPhotoTextPathResolver } from "./ELPhotoTextPathResolver";


export class CreationsAssetResolver {

    private _getPathResolverObj(creationsData: CreationsData): ELCreationsPathResolver {
        switch (creationsData.operationSubType) {
            case CreationsJobProjectSubType.slideshow: {
                return new ELSlideshowPathResolver(creationsData);
            }
            case CreationsJobProjectSubType.photoCollage: {
                return new ELCollagePathResolver(creationsData);
            }
            case CreationsJobProjectSubType.magicalBackdrop: {
                return new ELMagicalBackdropPathResolver(creationsData);
            }
            case CreationsJobProjectSubType.patternOverlay: {
                return new ELPatternOverlayPathResolver(creationsData);
            }
            case CreationsJobProjectSubType.peekThrough: {
                return new ELPeekThroughPathResolver(creationsData);
            }
            case CreationsJobProjectSubType.movingOverlay: {
                return new ELMovingOverlayPathResolver(creationsData);
            }
            case CreationsJobProjectSubType.replaceBackground: {
                return new ELReplaceBackgroundPathResolver(creationsData);
            }
            case CreationsJobProjectSubType.photoText: {
                return new ELPhotoTextPathResolver(creationsData);
            }
            default: {
                Logger.log(LogLevel.ERROR, "CreationsAssetResolver: _getPathResolverObj, unexpected creations.operationSubType, creationsData", creationsData);
                throw new Error("creationsData.operationSubType not valid");
            }
        }
    }

    async getFullAssetData(creationsData: CreationsData): Promise<ELAssetPathOrId> {
        try {
            const pathResolverObj = this._getPathResolverObj(creationsData);
            const assetPathOrId = await pathResolverObj.getCreationFullAssetPathOrId();
            return assetPathOrId;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationsAssetResolver:getFullAssetData, failed with error: " + error);
            return Promise.reject(error);
        }
    }

    async getPreviewAssetData(creationsData: CreationsData): Promise<ELAssetPathOrId> {
        try {
            const pathResolverObj = this._getPathResolverObj(creationsData);
            const assetPathOrId = await pathResolverObj.getCreationPreviewAssetPathOrId();
            return assetPathOrId;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationsAssetResolver:getPreviewAssetData, failed with error: " + error);
            return Promise.reject(error);
        }
    }

    async getMasterAssetData(creationsData: CreationsData): Promise<Asset[]> {
        try {
            const pathResolverObj = this._getPathResolverObj(creationsData);
            const assets = await pathResolverObj.getCreationMasterAssetData();
            return assets;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "CreationsAssetResolver:getMasterAssetData, failed with error: " + error);
            return Promise.reject(error);
        }
    }
}