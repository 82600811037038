/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//ThirdParty
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

//Adobe internal
import {
    AppEntitlement,
    Environment,
    FontIdentifier,
    FontPropertiesMode,
    FontPropertiesType,
    StyleToggler,
    StyleTogglerUnderlineEventDetail
} from "@adobe-fonts/fontpicker";
import "@adobe-fonts/fontpicker/af-fontproperties.js";
import "@adobe-fonts/fontpicker/af-styletoggler.js";
import {
    FontProperties,
    FontPropertiesPreviewEventDetail
} from "@adobe-fonts/fontpicker/src/FontProperties";
import "@spectrum-web-components/theme/sp-theme.js";
import "@spectrum-web-components/theme/src/themes.js";
import '@spectrum-web-components/picker/sp-picker.js';
import '@spectrum-web-components/menu/sp-menu-item.js';

//Application Specific
import IMS from "../../../../services/IMS";
import Utils from "../../../../utils/Utils";
import Logger, { LogLevel } from "../../../../utils/Logger";
import { RootState } from "../../../../stores/store";

import "./ELFontPickerView.scss";

const APP_ID = process.env.REACT_APP_IMS_API_KEY;

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'sp-theme': any;
            'af-fontproperties': any;
            'sp-picker': any;
            'sp-menu-item': any;
            'af-styletoggler': any;
        }
    }
}

interface ELAdobeFontPickerProps {
    currValue?: string;
    onChange?: (psName: FontIdentifier) => void;
    onPreview?: (psName: FontIdentifier) => void;
    isDisabled?: boolean;
    onFontSizeChange?: (fontSize: React.Key) => void;
    onUnderlineChange?: () => void;
}

export function ELFontPickerView(props: ELAdobeFontPickerProps) {
    const { currValue, onChange, onPreview, isDisabled } = props;
    const fontPickerRef = useRef<FontProperties>();
    const stylePickerRef = useRef<StyleToggler>();
    const [previewText, setPreviewText] = useState("Sample Text");
    const underline = useSelector((state: RootState) => state.textEditReducer.underline);
    const postscriptName = useSelector((state: RootState) => state.textEditReducer.postscriptName);

    const onFontPropertiesChange = (event: Event): void => {
        const fp = event.target as FontProperties;
        Logger.log(LogLevel.DEBUG, 'onFontPropertiesChange: ', fp.valueAsFontIdentifier);
        if (onChange) {
            onChange(fp.valueAsFontIdentifier);
        }
    }

    const onFontPremium = (event: Event): void => {
        event.preventDefault();
        Logger.log(LogLevel.ERROR, "Premium FONT selection is not allowed on our App!");
    }

    const onFontPropertiesPreview = (event: CustomEvent<FontPropertiesPreviewEventDetail>): void => {
        const fp = event.target as FontProperties;
        Logger.log(LogLevel.DEBUG, 'onFontPropertiesPreview: ', event.detail.value);
        const fontIdentifier: FontIdentifier = { postscriptName: event.detail.value, fontId: event.detail.fontId };
        if (onPreview) {
            onPreview(fontIdentifier);
        }
    };

    const onStyleTogglerChange = (event: Event): void => {
        const styleToggler = event.target as StyleToggler;
        const { valueAsFontIdentifier, selectedItem, fauxBold, fauxItalic } = styleToggler;
        if (onChange) {
            onChange(valueAsFontIdentifier);
        }
    }

    function onStyleTogglerUnderline(event: CustomEvent<StyleTogglerUnderlineEventDetail>): void {
        if (props.onUnderlineChange) {
            props.onUnderlineChange();
        }
    }

    const getUnderline = () => {
        if (underline)
            return { "underline": true };
        else
            return {};
    }

    useEffect(() => {
        if (stylePickerRef.current instanceof StyleToggler) {
            stylePickerRef.current.addEventListener('change', onStyleTogglerChange);
            stylePickerRef.current.addEventListener('af-styletoggler:underline', onStyleTogglerUnderline);
        }

        if (fontPickerRef.current instanceof FontProperties) {
            fontPickerRef.current.addEventListener('change', onFontPropertiesChange);
            fontPickerRef.current.addEventListener('af-fontproperties:premium', onFontPremium);
            fontPickerRef.current.addEventListener('af-fontproperties:preview', onFontPropertiesPreview);
        }
        return () => {
            if (fontPickerRef.current instanceof FontProperties) {
                fontPickerRef.current.removeEventListener('change', onFontPropertiesChange);
                fontPickerRef.current.removeEventListener('af-fontproperties:premium', onFontPremium);
                fontPickerRef.current.removeEventListener('af-fontproperties:preview', onFontPropertiesPreview);
            }
            if (stylePickerRef.current instanceof StyleToggler) {
                stylePickerRef.current.removeEventListener('change', onStyleTogglerChange);
                stylePickerRef.current.removeEventListener('af-styletoggler:underline', onStyleTogglerUnderline);
            }
        };
    }, []);

    return (
        <sp-theme color="light" scale="large">
            <af-fontproperties
                block={true}
                variableFonts={true}
                ref={fontPickerRef}
                type={FontPropertiesType.FAMILIES}
                mode={FontPropertiesMode.FIELD}
                env={process.env.REACT_APP_ENV as Environment}
                appEntitlement={AppEntitlement.FREE}
                previewText={previewText.replace(/\s+/g, " ")}
                clientId={APP_ID}
                userId={IMS.getInstance().getUserId()}
                placement="bottom-end"
                locale={Utils.getCurrentLocaleInKebabCase()}
                accessToken={IMS.getInstance().getUserAccessToken() ?? ""}
                value={postscriptName}>
            </af-fontproperties>
            <div className="style-toggler-container">
                <af-styletoggler
                    ref={stylePickerRef}
                    accessToken={IMS.getInstance().getUserAccessToken() ?? ""}
                    clientId={APP_ID}
                    appEntitlement={AppEntitlement.FREE}
                    data-e2e="type-style-toggler"
                    env={process.env.REACT_APP_ENV as Environment}
                    locale={Utils.getCurrentLocaleInKebabCase()}
                    showUnderline={true}
                    {...getUnderline()}
                    userId={IMS.getInstance().getUserId()}
                    value={postscriptName}>
                </af-styletoggler>
            </div>
        </sp-theme>
    );
}