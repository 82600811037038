/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ProjectId, RequestId } from "../../../common/interfaces/creations/CreationInAppNotifierTypes";
import { CreationsStatus } from "../../../common/interfaces/creations/CreationTypes";
import INotifier from "../../common/INotifier";


abstract class ElementsCreationInAppNotifier extends INotifier {

    protected isRequestActive(status: CreationsStatus): boolean {
        return status === CreationsStatus.requested;
    }

    public abstract pollStatus(id: RequestId | ProjectId): void;
}

export default ElementsCreationInAppNotifier;