/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { GridMediaType, MediaGridConfig, MediaGridSortBy, MediaGridSortOrder } from "../../stores/actions/mediaGridConfigActions";
import { GRID_CONFIG_KEY } from "../../stores/reducers/mediaGridConfigReducer";

enum SortOrder {
    asc = "",
    desc = "-"
}

enum SortBy {
    name = "repo:name",
    deviceModifyDate = "storage:deviceModifyDate",
    deviceCreatedDate = "storage:deviceCreateDate",
    repoModifyDate = "repo:modifyDate",
    repoCreateDate = "repo:createDate"
}

export const MediaRepoDirFetchUtils = {

    getSortBy(config: MediaGridConfig): string {
        switch (config.sortBy) {
            case MediaGridSortBy.name: {
                return SortBy.name;
            }
            case MediaGridSortBy.importDate: {
                return SortBy.repoCreateDate;
            }
            case MediaGridSortBy.createdDate: {
                return SortBy.deviceCreatedDate;
            }
            case MediaGridSortBy.modifiedDate: {
                return SortBy.repoModifyDate;
            }
            default: {
                return SortBy.repoCreateDate;
            }
        }
    },

    getSortOrder(config: MediaGridConfig): string {
        switch (config.sortOrder) {
            case MediaGridSortOrder.ascending: {
                return SortOrder.asc;
            }
            case MediaGridSortOrder.descending: {
                return SortOrder.desc;
            }
            default: {
                if (config.sortBy) {
                    if (config.sortBy === MediaGridSortBy.name) {
                        return SortOrder.asc;
                    }
                    return SortOrder.desc;
                }
                return SortOrder.desc;
            }
        }
    },

    getOrderByStr(config: MediaGridConfig): string {
        return this.getSortOrder(config) + this.getSortBy(config);
    },

    getTypeStr(config: MediaGridConfig): string {
        let filterProperty = "";
        switch (config.mediaType) {
            case GridMediaType.eImageOnly:
                // Considering psdc files as image and combining both mime types as of now
                filterProperty = "image/*,document/vnd.adobe.cpsd+dcx";
                break;
            case GridMediaType.eVideoOnly:
                filterProperty = "video/*";
                break;
            case GridMediaType.eNone:
                filterProperty = "none";
                break;
            case GridMediaType.eImageAndVideo:
                filterProperty = "*/*";
                break;
            default:
                filterProperty = "";
        }
        return filterProperty;
    },

    getStoreKeyForPath(dirPath: string, configData: Record<string, MediaGridConfig>): string {
        const config = configData[GRID_CONFIG_KEY];
        return dirPath + "/orderBy=" + this.getOrderByStr(config) + "/type=" + this.getTypeStr(config);
    }
};