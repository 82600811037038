/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { FacebookShareHandler } from "./FacebookShareHandler";
import { ShareHandler } from "./ShareHandler";
import { ShareMode } from "../../common/interfaces/share/ShareTypes";
import { EmailShareHandler } from "./EmailShareHandler";

class ShareHandlerFactory {

    private static _instance: ShareHandlerFactory | null = null;

    static getInstance(): ShareHandlerFactory {
        if (ShareHandlerFactory._instance === null)
            ShareHandlerFactory._instance = new ShareHandlerFactory();
        return ShareHandlerFactory._instance;
    }

    getShareHandler(shareMode: ShareMode): ShareHandler | undefined {
        switch (shareMode) {
            case ShareMode.facebook:
                return new FacebookShareHandler();
            case ShareMode.email:
                return new EmailShareHandler();    
        }
    }
}

export { ShareHandlerFactory }