/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELStageImageObject, ELStageObject, ELStageObjectOptions, ELStageShapesType } from "../../../common/interfaces/stage/StageTypes";
import IGraphicsStage from "../IGraphicsStage";
import IStageShapes from "./IStageShapes";

export default class ELStageImageAreaWithLayout extends IStageShapes {

    constructor() {
        super(ELStageShapesType.imageAreaWithLayout);
    }

    async update<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject> {
        this.object = await stage.replaceImage(this.object as ELStageImageObject, options);
        return this.object;
    }

    async draw<T extends ELStageObjectOptions>(stage: IGraphicsStage, options: T): Promise<ELStageObject> {
        this.object = await stage.addImageAreaWithLayout(options);
        return this.object;
    }
}
