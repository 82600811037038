/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Adobe Internal
import { StyleProps } from "@react-types/shared";
import { IconType } from "../../../../assets/IconConstants";
import { ELIcon } from "../el-icon/ELIconView";

import "./ELCloseButton.scss";

export interface ELCloseButtonProps extends StyleProps {
    onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void),
    label?: string
}

const ELCloseButton = (props: ELCloseButtonProps): React.ReactElement => {

    return (
        <button
            className={props.UNSAFE_className + " el-close-button"}
            aria-label={props.label}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.onClick?.(e)}

        > <ELIcon iconkey={IconType.close} className="el-close-button__icon" />
        </button>
    )
}

export default ELCloseButton;