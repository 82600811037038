/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application specific
import { localeUrlMap } from "../../locales";

export class LocaleCompiler {
    private _dictMap: Record<string, string[]> = {};
    private _supportedLocales: string[] = [];
    private static _instance: LocaleCompiler | null = null;

    private constructor() {
        this._supportedLocales = Object.keys(localeUrlMap);
        this._initializeDefaultDictMap();
    }

    private _initializeDefaultDictMap(): void {
        this._supportedLocales.forEach((locale) => {
            const localeURL = process.env.PUBLIC_URL + localeUrlMap[locale]
            if (!this._dictMap[locale]) {
                this._dictMap[locale] = [];
            }
            if (localeURL) {
                this._dictMap[locale].push(localeURL);
            }
        });
    }

    static get instance(): LocaleCompiler {
        if (!this._instance) {
            this._instance = new LocaleCompiler();
        }
        return this._instance;
    }

    addDictionary(dictionary: Record<string, string>): void {
        Object.keys(dictionary).forEach((locale) => {
            if (this._supportedLocales.includes(locale)) {
                this._dictMap[locale].push(dictionary[locale]);
            }
        });
    }

    get dictMap(): Record<string, string[]> {
        return this._dictMap;
    }
}