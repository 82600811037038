/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from "./IAction";
import { RenditionData } from "../../common/interfaces/storage/AssetTypes";

export interface SIVDataPayload {
    assetId: string,
    renditionData: RenditionData
}

export interface SIVData {
    currentAssetId: string,
    sivRenditionData: SIVDataPayload[]
}


export default class SIVAction {

    public static readonly UPDATE_DATA: string = "SIVAction.UPDATE_DATA";
    public static readonly CLEAR_DATA: string = "SIVAction.CLEAR_DATA";
    public static readonly SIV_ASSET_UPDATED: string = "SIVAction.SIV_ASSET_UPDATED";

    public static updateData(data: SIVDataPayload): IAction<SIVDataPayload> {
        return {
            type: SIVAction.UPDATE_DATA,
            payload: data
        };
    }

    public static clearData(): IAction<SIVDataPayload> {
        return {
            type: SIVAction.CLEAR_DATA
        };
    }

    public static sivAssetUpdated(assetId: string): IAction<string> {
        return {
            type: SIVAction.SIV_ASSET_UPDATED,
            payload: assetId
        };
    }
}