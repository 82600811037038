/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { WorkflowsName } from "../../IWorkflow";
import { IconType } from "../../../assets/IconConstants";
import { AssetMimeType } from "../../../common/interfaces/storage/AssetTypes";
import { ELCreateOnDemandData } from "../../../common/interfaces/creations/ELCreateOnDemandTypes";
import { FeatureName } from "../../../services/Floodgate/FloodgateConstants";
import { FeaturesManager } from "../../../modules/floodgate/Featuresmanager";
import SlideshowPreview from "../../../assets/home/Slideshow.png";
import CollagePreview from "../../../assets/home/Collage.png";
import PatternOverlayPreview from "../../../assets/home/PatternOverlay.png";
import PeekThroughPreview from "../../../assets/home/PeekThrough.png";
import ReplaceBackgroundPreview from "../../../assets/home/ReplaceBackground.gif";
import MovingOverlayPreview from "../../../assets/home/MovingOverlay.mp4";
import PhotoTextPreview from "../../../assets/home/PhotoText.jpeg";
import SlideshowUtils from "../workflows/slideshow/utils/SlideshowUtils";
import PeekThroughUtils from "../workflows/peekThrough/utils/PeekThroughUtils";
import PatternOverlayUtils from "../workflows/pattern-overlay/utils/PatternOverlayUtils";
import ReplaceBackgroundUtils from "../workflows/replace-background/utils/ReplaceBackgroundUtils";
import CollageUtils from "../workflows/collage/utils/CollageUtils";
import MovingOverlayUtils from "../workflows/moving-overlay/utils/MovingOverlayUtils";
import { ALL_CREATIONS, RecentCreationsFilterMenu } from "../../../common/interfaces/creations/ELRecentCreationsTypes";
import { CreationsJobProjectSubType } from "../../../common/interfaces/creations/CreationTypes";
import PhotoTextUtils from "../workflows/photoText/utils/PhotoTextUtils";

const CreateOnDemandThumbIdToFeatureFlagNameMap: Record<string, FeatureName> = {
    [WorkflowsName.patternOverlay]: FeatureName.ePatternOverlay,
    [WorkflowsName.peekThrough]: FeatureName.ePeekThrough,
    [WorkflowsName.movingOverlay]: FeatureName.eMovingOverlay,
    [WorkflowsName.replaceBackground]: FeatureName.eReplaceBackground,
    [WorkflowsName.photoText]: FeatureName.ePhotoText
};

export class ELCreateOnDemandUtils {
    private static _intializeData = (): ELCreateOnDemandData[] => {
        const createOnDemandData: ELCreateOnDemandData[] = [
            {
                headerText: "photo-text",
                thumbId: WorkflowsName.photoText,
                iconKey: IconType.photoText,
                previewtext: "watch-preview",
                descriptiveText: "photo-text-description",
                previewSrc: PhotoTextPreview,
                previewType: AssetMimeType.image,
                showNewBadge: true,
                creationsJobProjectSubType: CreationsJobProjectSubType.photoText,
                mediaGridConfig: PhotoTextUtils.getMediaGridConfig(),
            },
            {
                headerText: "replace-background",
                thumbId: WorkflowsName.replaceBackground,
                iconKey: IconType.replaceBackground,
                previewtext: "watch-preview",
                descriptiveText: "replace-background-description",
                previewSrc: ReplaceBackgroundPreview,
                previewType: AssetMimeType.image,
                showNewBadge: true,
                creationsJobProjectSubType: CreationsJobProjectSubType.replaceBackground,
                mediaGridConfig: ReplaceBackgroundUtils.getReplaceBackgroundMediaGridConfig(),
            },
            {
                headerText: "moving-overlay",
                thumbId: WorkflowsName.movingOverlay,
                iconKey: IconType.movingOverlay,
                previewtext: "watch-preview",
                descriptiveText: "moving-overlays-description",
                previewSrc: MovingOverlayPreview,
                previewType: AssetMimeType.video,
                showNewBadge: true,
                creationsJobProjectSubType: CreationsJobProjectSubType.movingOverlay,
                mediaGridConfig: MovingOverlayUtils.getMovingOverlayMediaGridConfig()
            },
            {
                headerText: "peek-through",
                thumbId: WorkflowsName.peekThrough,
                iconKey: IconType.peekThrough,
                previewtext: "watch-preview",
                descriptiveText: "peek-through-description",
                previewSrc: PeekThroughPreview,
                previewType: AssetMimeType.image,
                showNewBadge: true,
                creationsJobProjectSubType: CreationsJobProjectSubType.peekThrough,
                mediaGridConfig: PeekThroughUtils.getPeekThroughMediaGridConfig()
            },
            {
                headerText: "pattern-overlay",
                thumbId: WorkflowsName.patternOverlay,
                iconKey: IconType.patternOverlay,
                previewtext: "watch-preview",
                descriptiveText: "pattern-overlays-description",
                previewSrc: PatternOverlayPreview,
                previewType: AssetMimeType.image,
                showNewBadge: true,
                creationsJobProjectSubType: CreationsJobProjectSubType.patternOverlay,
                mediaGridConfig: PatternOverlayUtils.getPatternOverlayMediaGridConfig()
            },
            {
                headerText: "photo-collage",
                thumbId: WorkflowsName.collage,
                iconKey: IconType.photoCollage,
                previewtext: "watch-preview",
                descriptiveText: "photo-collage-description",
                previewSrc: CollagePreview,
                previewType: AssetMimeType.image,
                creationsJobProjectSubType: CreationsJobProjectSubType.photoCollage,
                mediaGridConfig: CollageUtils.getCollageMediaGridConfig()
            },
            {
                headerText: "slideshow",
                thumbId: WorkflowsName.slideshow,
                iconKey: IconType.slideshow,
                previewtext: "watch-preview",
                descriptiveText: "slideshow-description",
                previewSrc: SlideshowPreview,
                previewType: AssetMimeType.image,
                creationsJobProjectSubType: CreationsJobProjectSubType.slideshow,
                mediaGridConfig: SlideshowUtils.getSlideshowMediaGridConfig()
            }
        ];
        return createOnDemandData;
    };

    static getCreateOnDemandData(): ELCreateOnDemandData[] {
        const allCreateOnDemandData = ELCreateOnDemandUtils._intializeData();
        const createOnDemandData: ELCreateOnDemandData[] = [];

        allCreateOnDemandData.forEach(data => {
            const featureFlagName = CreateOnDemandThumbIdToFeatureFlagNameMap[data.thumbId];
            if (featureFlagName) {
                const isCreationActive = FeaturesManager.getInstance().IsFeatureActive(featureFlagName);
                if (isCreationActive) {
                    createOnDemandData.push(data);
                }
            } else {
                createOnDemandData.push(data);
            }
        });

        return createOnDemandData;
    }

    static getRecentCreationFilterList(): RecentCreationsFilterMenu[] {
        const recentCreationMenuItems = [{
            id: ALL_CREATIONS,
            name: "all"
        }];

        ELCreateOnDemandUtils.getCreateOnDemandData().forEach(data => {
            recentCreationMenuItems.push({
                id: data.creationsJobProjectSubType,
                name: data.headerText
            });
        });

        return recentCreationMenuItems;
    }
}