/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


import { createStore } from 'redux'
import rootReducers from './reducers/index';
import { composeWithDevTools } from 'redux-devtools-extension';

// TODO: Need to add here dev env check since we should not have dev tools in prod right?
const store = createStore(rootReducers, {}, composeWithDevTools());

export default store;
export type RootState = ReturnType<typeof rootReducers>;

