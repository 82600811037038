/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { FeaturesManager } from "../modules/floodgate/Featuresmanager";
import { FeatureName } from "../services/Floodgate/FloodgateConstants";
import Utils from "./Utils";
import { IngestEventSubTypes, IngestLogObjectValue, IngestWorkflowTypes } from "./IngestConstants";
import { TrialUtils } from "./TrialUtils";
import { IngestLogging } from "../services/IngestWrapper";
import { IngestUtils } from "./IngestUtils";
import { URLConstants } from "./Constants/URLConstants";
import { AccessProfileManager } from "../modules/accessProfile/AccessProfile";

export const UserUtils = {
    downloadDesktop(): void {
        Utils.openInNewTab(URLConstants.NotGoURL.PHOTOSHOP_ELEMENTS_APP_DOWNLOAD_LINK);
        IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.userHome,
            IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.success,
                IngestEventSubTypes.downloadDesktop, null)));
    },

    buyDesktop(appURL: string): void {
        Utils.openInNewTab(appURL);
    },

    isUserPaid(): boolean {
        return (AccessProfileManager.getInstance().isPaidUser() ||
            (AccessProfileManager.getInstance().isNonNGLProfile() && FeaturesManager.getInstance().IsFeatureActive(FeatureName.eWebAccess)));
    },

    getTrialTypeValue(daysInTrial: number): string {
        if (this.isUserPaid()) {
            return IngestLogObjectValue.paid;
        } else if (TrialUtils.isTrialExpired(daysInTrial)) {
            return IngestLogObjectValue.trialExpired;
        } else if (TrialUtils.isTrialInProgress(daysInTrial)) {
            const maxTrialDays = TrialUtils.getMaxTrialDays();
            const daysLeft = Math.round(maxTrialDays - daysInTrial);
            return IngestLogObjectValue.continueTrial + `${daysLeft.toString()} days left`;
        } else {
            return IngestLogObjectValue.startTrial;
        }
    }
};
