/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    ContentType,
    CreationType,
    ExportOperationTypeInfo,
    ExportType,
    OutputType,
    PatternOverlayCreationOperationSubTypeConfiguration,
    PatternOverlayCreationOperationSubTypeConfigurationInfo,
    PatternOverlayCreationOperationSubTypeInfo,
    PatternOverlayCreationOperationTemplate,
    Resolution,
    ImageFormat,
    CreationCompositeType
} from "@elements/elementswebcommon";

//Application Specific
import { CreationsJobCreator } from "../../../utils/CreationsJobCreator";
import { ELPatternOverlayCreationRequestParams } from "../../../../../common/interfaces/creations/ELPatternOverlayTypes";
import Utils from "../../../../../utils/Utils";
import { ELCreationDocumentDetails } from "../../../../../common/interfaces/creations/CreationTypes";
import { ELImageExportConfigurationParams } from "../../../../../common/interfaces/creations/ELCreationsJobTypes";

const defaultCreationFileName = "patternArt.jpg";
const defaultCreationPSDFileName = "patternArt.psd";
const defaultExportHeight = 720;
const defaultExportWidth = 1440;

export class PatternOverlayJobCreator extends CreationsJobCreator {

    private _createTemplateInfoForOverlayId(overlayId: string, contentResolution: Resolution): PatternOverlayCreationOperationTemplate {
        const template = new PatternOverlayCreationOperationTemplate();
        template.operationContentData = {
            id: overlayId,
            contentType: ContentType.patternOverlay,
            mimetype: "image/png",
            resolution: contentResolution
        };
        return template;
    }

    private _createConfigurationInfoForOverlayId(overlayId: string, contentResolution: Resolution): PatternOverlayCreationOperationSubTypeConfigurationInfo {
        const configInfoObj = new PatternOverlayCreationOperationSubTypeConfigurationInfo();
        configInfoObj.configurationInfo = {
            "template": this._createTemplateInfoForOverlayId(overlayId, contentResolution)
        };
        return configInfoObj;
    }

    private _createConfigurationsForOverlayIds(overlayIds: string[], contentResolution: Resolution, documentDetails?: ELCreationDocumentDetails): PatternOverlayCreationOperationSubTypeConfiguration[] {
        const configurations = overlayIds.map(overlayId => {

            const randomUniqueId = Utils.getRandomUUID();

            const singleConfigObj = new PatternOverlayCreationOperationSubTypeConfiguration();
            singleConfigObj.version = this.configurationVersion;
            singleConfigObj.id = randomUniqueId;
            singleConfigObj.representativeFolderPath = randomUniqueId;
            if (documentDetails)
                singleConfigObj.compositePostEdit = this.createProjectEditInfo(documentDetails);

            const configurationInfoObj = this._createConfigurationInfoForOverlayId(overlayId, contentResolution);
            singleConfigObj.configurationInfo = configurationInfoObj;

            return singleConfigObj;
        });
        return configurations;
    }

    private _createImageExportOperationParams(outputTypes: OutputType[]): ELImageExportConfigurationParams[] {
        const imageExportOperationSubTypeConfigurationParams: ELImageExportConfigurationParams[] = [];

        outputTypes.forEach((outputType) => {
            const isOutputTypePreview = outputType === OutputType.preview;
            const fileName = isOutputTypePreview ? defaultCreationFileName : defaultCreationPSDFileName;
            const exportFormat = isOutputTypePreview ? ImageFormat.jpg : ImageFormat.psd;
            imageExportOperationSubTypeConfigurationParams.push({
                fileName: fileName,
                outputType: outputType,
                exportFormat: exportFormat,
                exportHeight: defaultExportHeight,
                exportWidth: defaultExportWidth
            });
        });

        return imageExportOperationSubTypeConfigurationParams;
    }

    protected createOperationSubTypeInfo(requestParameters: ELPatternOverlayCreationRequestParams): PatternOverlayCreationOperationSubTypeInfo {
        const operationSubTypeInfo = new PatternOverlayCreationOperationSubTypeInfo();
        operationSubTypeInfo.version = this.operationSubTypeInfoVersion;
        operationSubTypeInfo.configurations = this._createConfigurationsForOverlayIds(requestParameters.overlayIds, requestParameters.contentResolution, requestParameters.documentDetails);
        operationSubTypeInfo.compositeType = CreationCompositeType.pieImageDocument;
        if (requestParameters.documentDetails)
            operationSubTypeInfo.compositeSettings = this.createProjectSettings(requestParameters.documentDetails);
        return operationSubTypeInfo;
    }

    protected createExportOperationTypeInfo(requestParameters: ELPatternOverlayCreationRequestParams): ExportOperationTypeInfo | undefined {
        const exportOperationTypeInfo = new ExportOperationTypeInfo();
        exportOperationTypeInfo.operationSubType = ExportType.image;
        exportOperationTypeInfo.operationSubTypeInfo = this.createImageExportOperationSubTypeInfo(this._createImageExportOperationParams(requestParameters.outputTypes));
        return exportOperationTypeInfo;
    }

    protected getCreationType(): CreationType {
        return CreationType.patternOverlay;
    }
}