/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELLinkType } from "../../../common/interfaces/link/ELLinkTypes";
import { ELStageObject } from "../../../common/interfaces/stage/StageTypes";
import Logger, { LogLevel } from "../../../utils/Logger";
import Utils from "../../../utils/Utils";
import { ELStageObjectToJsonConverterFactory } from "../stageObjectFilterer/ELStageObjectFilterFactory";
import ILinkHandler from "./ILinkHandler";

export default class ELFabricLinkHandler extends ILinkHandler<fabric.Canvas, ELStageObject> {
    private _canLinkObjects(objects: ELStageObject[], linkType: ELLinkType): boolean {
        if (objects.length <= 1) {
            return false;
        }
        return true;
    }

    private _getLinkedObjects(canvas: fabric.Canvas, object: ELStageObject): ELStageObject[] {
        const linkId = object.data?.link?.id;
        const objects = canvas.getObjects();

        let objectsWithSameLink: ELStageObject[] = [];
        objects.forEach((currentObject) => {
            const currentLinkId = currentObject.data?.link?.id;
            if (linkId === currentLinkId) {
                objectsWithSameLink.push(currentObject);
            }

            const currentObjectClip = currentObject.clipPath;
            if (currentObjectClip) {
                const clipObjectLinkId = currentObjectClip.data?.link?.id;
                if (linkId === clipObjectLinkId) {
                    objectsWithSameLink.push(currentObjectClip);
                }
            }
        })
        objectsWithSameLink = objectsWithSameLink.filter(currentObject => currentObject !== object);
        return objectsWithSameLink;
    }

    private _getJsonObject(object: ELStageObject): unknown {
        if (!object.data || !object.data.link) {
            return {};
        }
        const linkType = object.data.link.type as ELLinkType;
        const stageObjectToJsonConverter = ELStageObjectToJsonConverterFactory.createConverter(linkType);
        const jsonObject = stageObjectToJsonConverter.toJson(object);
        return jsonObject
    }

    linkObjects(objects: ELStageObject[], linkType: ELLinkType): void {
        if (!this._canLinkObjects(objects, linkType)) {
            Logger.log(LogLevel.WARN, "ELFabricLinkHandler:linkObjects:: fabric objects can not be linked");
            return;
        }
        const uniqueId = Utils.getRandomUUID();
        const linkId = `link-${uniqueId}`;
        objects.forEach((object) => {
            if (!object.data) {
                object.data = {};
            }
            object.data.link = { id: linkId, type: linkType };
        });
    }

    unLinkObjects(objects: ELStageObject[]): void {
        objects.forEach((object) => {
            if (object.data && object.data.link) {
                delete object.data.link;
            }
        });
    }

    updateLinkedObjects(canvas: fabric.Canvas, object: ELStageObject): void {
        const objectsWithSameLink: ELStageObject[] = this._getLinkedObjects(canvas, object);
        const jsonObject = this._getJsonObject(object);
        objectsWithSameLink.forEach((currentObject) => {
            currentObject.set(jsonObject as Record<string, unknown>);
            currentObject.setCoords();
        })
        canvas.requestRenderAll();
    }
}