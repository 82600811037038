/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, useReducer } from "react";

import { Provider as ReactReduxProvider, useSelector } from "react-redux";

//Application Specific
import store, { RootState } from "../../../../stores/store";
import ELButton from "../../../../view/components/atoms/el-button/ELButtonView";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELMediaManagerToolBarView from "../../../../view/components/molecules/el-media-manager-toolbar/ELMediaManagerToolbarView";
import { ViewAction } from "../../../../view/IBaseController";
import IViewController from "../../../../view/IViewController";
import { ReplaceMediaManagerViewAction, ReplaceMediaManagerControllerAction } from "../../../../common/interfaces/workflows/ReplaceMediaManagerTypes";
import ImportButton from "../../../../view/components/organism/el-import-button/ELImportButton";

import "./ReplaceMediaManager.scss"

interface IReplaceMediaManagerProps {
    controller: IViewController,
    title: string
}

interface ReplaceMediaManagerState {
    isContinueButtonDisabled: boolean
}

const initialState: ReplaceMediaManagerState = {
    isContinueButtonDisabled: true
}

const MediaManagerView = (props: IReplaceMediaManagerProps): React.ReactElement => {
    const reducer = (state: ReplaceMediaManagerState, action: ViewAction): ReplaceMediaManagerState => {
        switch (action.type) {
            case ReplaceMediaManagerViewAction.updateContinueButtonState: {
                return { isContinueButtonDisabled: action.payload as boolean };
            }
            default:
                return state;
        }
    }
    const [state, viewDispatch] = useReducer(reducer, initialState);
    const selectedMediaList = useSelector((state: RootState) => state.selectedMediaListReducer);
    const intlHandler = IntlHandler.getInstance();
    const isImportInProgress = useSelector((state: RootState) => state.mediaGridToolbarStateReducer?.importInProgress ?? false);

    useEffect(() => {
        props.controller.initialize(viewDispatch);
        //clean up
        return () => {
            props.controller.destroy();
        }
    }, [props.controller]);

    useEffect(() => {
        props.controller.notify({ type: ReplaceMediaManagerControllerAction.mediaChanged, payload: selectedMediaList });
    }, [selectedMediaList]);

    const notify = props.controller.notify.bind(props.controller);

    return (
        <>
            <ReactReduxProvider store={store}>
                <ELMediaManagerToolBarView notify={notify}
                    title={props.title} >
                    <ELButton dataTestId="replace-media-manager-cancel" size="L" onClick={() => props.controller.notify({ type: ReplaceMediaManagerControllerAction.toolbarCancel })}>
                        <span className="dialog__cta-text"> {intlHandler.formatMessage("cancel")} </span>
                    </ELButton>
                    <ImportButton controller={props.controller} isDisabled={isImportInProgress} variant={"add-from-computer"} />
                    <ELButton dataTestId="replace-media-manager-continue" size="L" isDisabled={state.isContinueButtonDisabled}
                        onClick={() => props.controller.notify({ type: ReplaceMediaManagerControllerAction.toolbarContinue })}>
                        <span className="dialog__cta-text"> {intlHandler.formatMessage("continue")} </span>
                    </ELButton>
                </ELMediaManagerToolBarView>

                <div id="replace-media-manager-grid-container" className="dialog__content-container" />

            </ReactReduxProvider>
        </>

    )
};

export default MediaManagerView;
