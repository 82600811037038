/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import CanvasAction, { CanvasActionPayload, CanvasMode } from "../actions/CanvasAction";
import IAction from "../actions/IAction";

interface CanvasReducerData {
    mode: CanvasMode
}

const updateMode = (state: CanvasReducerData, action: IAction<CanvasActionPayload>): CanvasReducerData => {
    const mode = action.payload as CanvasMode;
    if (mode) {
        return {
            ...state,
            mode: mode
        }
    }
    return state;
}

const canvasReducer = (state: CanvasReducerData = { mode: CanvasMode.render }, action: IAction<CanvasActionPayload>): CanvasReducerData => {
    switch (action.type) {
        case CanvasAction.UPDATE_MODE:
            return updateMode(state, action);
        default:
            return state;
    }
}

export default canvasReducer;