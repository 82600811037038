/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import { PageOptions, AdobeDirectoryData, deserializeAsset } from "@dcx/assets";
import { AssetWithRepoAndPathOrId } from "@dcx/common-types";
import { ELAdobeAsset, RepoDirListData } from "@elements/elementswebcommon";

//Application Specific
import ELClientUploadHandler, { ASSET_ALREADY_EXISTS_ERROR } from "../../../modules/clientUploadHandler/ELClientUploadHandler";
import ImageUtils from "../../../utils/ImageUtils";
import Logger, { LogLevel } from "../../../utils/Logger";
import IDoc from "../IDoc";
import Constants from "../../../utils/Constants/Constants";
import { StorageService } from "../../../services/StorageServiceWrapper";
import { MAX_DIR_FETCH_LIMIT, REPO_API_ORDER_BY_NAME } from "../../../modules/uploadHandler/UploadHandler";
import { AssetStorageUtils } from "../../../utils/AssetStorageUtils";
import { IntlHandler } from "../../../modules/intlHandler/IntlHandler";
import { DocSaveError } from "../../../common/interfaces/document/DocumentTypes";

export default class ELStageDocSaveManager {
    async getAssetNameForSaveAs(asset: ELAdobeAsset): Promise<string> {
        try {
            const assetData = await AssetStorageUtils.resolveAssetInfo(asset);
            const name = assetData.name?.split(".")[0] ?? IntlHandler.getInstance().formatMessage("untitled");
            const extension = assetData.name?.split(".")[1] ?? "jpeg";
            const newAssetName = await this._fetchSuitableName(name, extension);
            return newAssetName;
        } catch (error: any) {
            Logger.log(LogLevel.ERROR, "ELStageDocSaveManager: getAssetNameForSaveAs: ", error);
            return Promise.reject("Couldn't get suitable name for save as");
        }
    }

    async save(doc: IDoc, assetPath: string, data?: Record<string, unknown>): Promise<boolean> {
        try {
            const dataURL = await doc.getDownloader?.getDataURL();
            if (!dataURL) {
                return Promise.reject();
            }
            const blob = await ImageUtils.createBlob(dataURL, "image/jpeg", 1);

            const clientUploadHandler = new ELClientUploadHandler();
            await clientUploadHandler.upload({
                assetPath: assetPath,
                contentType: "image/jpeg",
                saveInfo: { blob: blob, metaData: data }
            });

            return true;
        } catch (error) {
            Logger.log(LogLevel.ERROR, "ELStageDocSaveManager:save: ", error);
            return Promise.reject("Couldn't save ELStageDoc document");
        }
    }

    async saveAs(doc: IDoc, asset: ELAdobeAsset, data?: Record<string, unknown>, newNameWithExtension?: string): Promise<string> {
        try {
            const dataURL = await doc.getDownloader?.getDataURL();
            if (!dataURL) {
                return Promise.reject();
            }
            const blob = await ImageUtils.createBlob(dataURL, "image/jpeg", 1);
            const newAssetName = newNameWithExtension ?? await this.getAssetNameForSaveAs(asset);
            const newAssetPath = (Constants.ELEMENTS_PHOTOS_PATH as string) + Constants.DIR_SEPERATOR + newAssetName;
            const clientUploadHandler = new ELClientUploadHandler();
            await clientUploadHandler.upload({
                assetPath: newAssetPath,
                contentType: "image/jpeg",
                saveInfo: { blob: blob, metaData: data }
            });

            return newAssetPath;
        } catch (error) {
            // TODO: Glia Revisit this code
            // need to look into error code, if any
            Logger.log(LogLevel.ERROR, "ELStageDocSaveManager:saveAs: ", error);
            if(error === ASSET_ALREADY_EXISTS_ERROR) {
                return Promise.reject(DocSaveError.ASSET_ALREADY_EXISTS)  ;
            }
            return Promise.reject(error);
        }
    }

    private _suggestName(fileName: string, ext: string, list: string[]): string {
        list.sort();
        let suggestedName = fileName;
        let cnt = 1;
        do {
            suggestedName = fileName + " " + cnt + "." + ext;
            cnt += 1;
        } while ((list.includes(suggestedName)))
        return suggestedName;
    }

    private async _fetchSuitableName(name: string, ext: string): Promise<string> {
        const pageOpts: PageOptions = {
            start: name + " ",
            orderBy: REPO_API_ORDER_BY_NAME,
            limit: MAX_DIR_FETCH_LIMIT
        }
        const elementsPhotosDirPath = Constants.ELEMENTS_PHOTOS_PATH as string;
        const dirAsset: AssetWithRepoAndPathOrId = { path: elementsPhotosDirPath, repositoryId: "" };
        const dir = await StorageService.getInstance().resolveAsset(dirAsset, "id") as AdobeDirectoryData;
        const dirList = await StorageService.getInstance().getDirectoryList(dir, pageOpts) as RepoDirListData;
        let fileNames: string[] = [];
        if (dirList.children) {
            fileNames = dirList.children.map((file, indx) => {
                const asset = deserializeAsset(file);
                return asset.name ?? name;
            });
        }
        const fileName = this._suggestName(name, ext, fileNames);
        return Promise.resolve(fileName);
    }
}