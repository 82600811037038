/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import { Flex } from "@adobe/react-spectrum";

//utils
import Utils from "../../../../utils/Utils";

//Application Specific
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELActionButton from "../../molecules/el-action-button/ELActionButton";
import { AssetMetaData } from "../../../../common/interfaces/storage/StorageTypes";
import { AssetMimeType } from "../../../../common/interfaces/storage/AssetTypes";
import { ClipboardService } from "../../../../services/ClipboardService";
import { LocaleUtils } from "../../../../utils/LocaleUtils";

import "./ELSidebarPanelView.scss";

const ISO_RATING = "ISO ";
const MM = " mm";
const SECONDS = " sec";
const FOCAL_NOTATION = "f/";
const MUL_NOTATION = "x";
const EXPOSURE_TIME_MAX_LENGTH = 7;

export interface ELSidebarPanelViewProps {
    infoPanelData: AssetMetaData,
    handleInfoPanelCloseIconClick: () => void
}
export const ELSidebarPanelView = (props: ELSidebarPanelViewProps): React.ReactElement => {
    const infoPanelData = props.infoPanelData;
    const intlHandler = IntlHandler.getInstance();
    const date = new Date(infoPanelData.deviceCreateDate as string);
    const closeIcon = <ELIcon iconkey={IconType.collapseSidebarPanel} fill={"#fff"} className="info-panel-icon" />;

    const handleCloseIconClick = (): void => {
        props.handleInfoPanelCloseIconClick();
    }

    const assetDeviceDetails = (): React.ReactElement => {
        if (!infoPanelData.isoSpeedRatings && !infoPanelData.focalLength && !infoPanelData.focalNumber &&
            !infoPanelData.exposureTime && !infoPanelData.deviceMake && !infoPanelData.deviceModel) {
            return <></>;
        }
        const isoSpeed = infoPanelData.isoSpeedRatings ? ISO_RATING + infoPanelData.isoSpeedRatings : "";
        const assetFocalLength = infoPanelData.focalLength ? parseFloat(infoPanelData.focalLength).toFixed(2) + MM : "";
        const assetFocalNumber = infoPanelData.focalNumber ? FOCAL_NOTATION + parseFloat(infoPanelData.focalNumber).toFixed(2) : "";
        let assetExposureTime = "";
        if (infoPanelData.exposureTime) {
            assetExposureTime = infoPanelData.exposureTime.length > EXPOSURE_TIME_MAX_LENGTH ? (parseFloat(infoPanelData.exposureTime).toFixed(2) + SECONDS) :
                infoPanelData.exposureTime + SECONDS;
        }
        const deviceMakeAndModel = infoPanelData.deviceMake + " " + infoPanelData.deviceModel;

        return (
            <Flex UNSAFE_className="info-panel-field">
                <ELIcon iconkey={IconType.assetSource} className="info-panel-icon" />
                <Flex direction="column" width="100%">
                    <div className="info-panel-field-heading">{deviceMakeAndModel}</div>
                    <Flex gap="3%" >
                        <div>{isoSpeed} </div>
                        <div>{assetFocalLength} </div>
                        <div>{assetFocalNumber} </div>
                        <div>{assetExposureTime} </div>
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    const assetResolution = (): React.ReactElement => {
        if (infoPanelData.length && infoPanelData.width && infoPanelData.size) {
            const assetLength = infoPanelData.length;
            const assetWidth = infoPanelData.width;
            const size = infoPanelData.size;
            const assetResolution = Utils.calculateResolution(assetLength, assetWidth);
            const assetSize = Utils.humanFileSize(size, true, false).toString();
            const dimension = assetLength.toString() + MUL_NOTATION + assetWidth.toString();

            return (
                <Flex gap="5%">
                    <div>{dimension} </div>
                    {(infoPanelData.mimeType === AssetMimeType.image) && <div>{assetResolution} </div>}
                    <div>{assetSize} </div>
                </Flex>
            );
        } else if (infoPanelData.size) {
            const size = infoPanelData.size;
            const assetSize = Utils.humanFileSize(size, true, false).toString();

            return (
                <Flex>
                    <div>{assetSize} </div>
                </Flex>
            );
        } else {
            return <></>;
        }
    }

    const handleNameClick = (): void => {
        ClipboardService.writeText(infoPanelData.name as string);
    }

    const assetDimensionInfo = (): React.ReactElement => {
        const assetType = Utils.getFileExtension(infoPanelData.name ?? "").toString().toUpperCase();
        const assetTypeIcon = (infoPanelData.mimeType === AssetMimeType.image) ? IconType.imageTypeAsset : IconType.videoTypeAsset;
        return (
            <Flex UNSAFE_className="info-panel-field">
                <ELIcon iconkey={assetTypeIcon} className="info-panel-icon" />
                <Flex direction="column" width="80%">
                    <div className="info-panel-field-heading"> {assetType} </div>
                    <div className="info-panel-asset-name" title={infoPanelData.name} onClick={handleNameClick} onKeyDown={handleNameClick}>
                        {infoPanelData.name}
                    </div>
                    {assetResolution()}
                </Flex>
            </Flex>
        )
    }
    const infoPanelBodyView = (): React.ReactElement => {
        if (infoPanelData) {
            return (
                <Flex direction="column" UNSAFE_className="info-panel-field-container" >
                    {assetDimensionInfo()}
                    <Flex UNSAFE_className="info-panel-field" >
                        <ELIcon iconkey={IconType.assetDate} className="info-panel-icon" />
                        <Flex direction="column">
                            <div className="info-panel-field-heading">{Utils.formatDate(date, LocaleUtils.DateFormat)}</div>
                            <div>{Utils.formatTime(date, LocaleUtils.DateFormat)}</div>
                        </Flex>
                    </Flex>
                    {assetDeviceDetails()}
                </Flex>
            )
        } else {
            return <></>;
        }

    }

    return (
        <Flex direction="column" width="100%" data-testid="info-panel">
            <Flex UNSAFE_className="info-panel-heading">
                <div>{intlHandler.formatMessage("info")}</div>
                <ELActionButton elIcon={closeIcon} tooltip={intlHandler.formatMessage("close")} onPress={handleCloseIconClick} variant="light" dataTestId="infoPanelClose" />
            </Flex>
            {infoPanelBodyView()}
        </Flex>
    )
}
