/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import IAction from './IAction';

export default class AccessProfileAction {

    public static readonly UPDATE_PROFILE_STATUS: string = "AccessProfileAction.UPDATE_PROFILE_STATUS";

    public static updateAccessProfileStatus(profileStatus: string): IAction<string> {
        return {
            type: AccessProfileAction.UPDATE_PROFILE_STATUS,
            payload: profileStatus
        };
    }
}