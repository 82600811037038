/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../../IWorkflow";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import { ViewAction } from "./../../../../../view/IBaseController";
import IBaseWorkspace, { WorkspaceActionType } from "../../../../IBaseWorkspace";
import { LinkGenerationErrorView, LinkGenerationErrorControllerAction, LinkGenerationErrorActionProps } from "./LinkGenerationErrorView";
import { ELIcon } from "../../../../../view/components/atoms/el-icon/ELIconView";
import { IconType } from "../../../../../assets/IconConstants";
import store from "../../../../../stores/store";
import { ControllerAction } from "../../../../../view/IViewController";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { ShareUtils } from "../../../../../utils/ShareUtils";
import { LinkGenerationStatus } from "../../../../../common/interfaces/share/ShareTypes";
import { IngestEventTypes, IngestEventSubTypes } from "../../../../../utils/IngestConstants";
import { IngestUtils } from "../../../../../utils/IngestUtils";

export type LinkGenerationErrorType = LinkGenerationStatus.partial | LinkGenerationStatus.error | LinkGenerationStatus.cancelled;

export interface LinkGenerationErrorWorkflowPayload {
    linkGenerationState: LinkGenerationErrorType
}

class LinkGenerationError extends IWorkflow {
    private _link: string;
    private _linkGenerationState: LinkGenerationStatus;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.linkGenerationError);
        this._link = "";
        this._linkGenerationState = LinkGenerationStatus.error;
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    private get _getLinkGenerationErrorMsg(): string {
        const intlHandler = IntlHandler.getInstance();
        switch (this._linkGenerationState) {
            case LinkGenerationStatus.partial: {
                return intlHandler.formatMessage("link-generation-common-error-msg") + " " + intlHandler.formatMessage("link-generation-partial-state-error-msg");
            }
            case LinkGenerationStatus.error: {
                return intlHandler.formatMessage("link-generation-common-error-msg");
            }
            default: {
                return "";
            }
        }
    }

    private get _getLinkGenerationActionDetails(): LinkGenerationErrorActionProps {
        const intlHandler = IntlHandler.getInstance();
        switch (this._linkGenerationState) {
            case LinkGenerationStatus.partial: {
                return {
                    actionLabel: intlHandler.formatMessage("continue"),
                    actionDescription: intlHandler.formatMessage("wish-to-continue"),
                    onAction: () => this.notify({ type: LinkGenerationErrorControllerAction.continuePressed })
                };
            }
            case LinkGenerationStatus.error: {
                return {};
            }
            default: {
                return {};
            }
        }

    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const action = this._getLinkGenerationActionDetails;
        ReactDOM.render(
            React.createElement(LinkGenerationErrorView, {
                controller: this,
                text: this._getLinkGenerationErrorMsg,
                ...action
            },
                React.createElement(ELIcon, { iconkey: IconType.linkGenerationError, width: "5rem", height: "5rem" })), //TODO --ssen will check with XD whether we have different icons for fb, email error workflows
            container
        )
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this._link = store.getState().shareReducer.linkInfo.link;
        const payload = action?.payload as LinkGenerationErrorWorkflowPayload;
        this._linkGenerationState = payload?.linkGenerationState ?? LinkGenerationStatus.error;
        this.prevWorkflow = prevWorkflow;
        this.createView(this.ensureHTMLElement(containerId));
    }

    /**
    * Handles ui events generated by views rendered in the workflow
    * @param action ControllerAction
    */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case LinkGenerationErrorControllerAction.continuePressed:
                {
                    this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(WorkflowsName.linkGenerationError,
                            IngestEventTypes.click, IngestEventSubTypes.continue, true)
                    });
                    const workspaceAction = { type: WorkspaceActionType.startWorkflow, startWorkflow: ShareUtils.getShareWorkflowName, payload: this._link };
                    this._owner.notify(workspaceAction);
                    handled = true;
                    break;
                }
            case LinkGenerationErrorControllerAction.cancelPressed:
                {
                    this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(WorkflowsName.linkGenerationError,
                            IngestEventTypes.click, IngestEventSubTypes.cancel, true)
                    });
                    this._owner.notify({ type: WorkspaceActionType.endWorkspace });
                    handled = true;
                    break;
                }
            default:
                {
                    Logger.log(LogLevel.WARN, "LinkGenerationError - ControllerAction: Invalid action" + action);
                    break;
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }
}

export { LinkGenerationError };
