/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe internal
import { PIE, init } from "@piewasm/pie-web-npm-package";

//Application specific
import { IEditingEngine } from "./IEditingEngine";
import {
    TEMP_DIRECTORY,
    PIE_PERSISTENT_DIRECTORY,
    PHOTOSHOP_TEMP_DIRECTORY,
} from "../../common/interfaces/editing/pie/PIETypes";
import Logger, { LogLevel } from "../../utils/Logger";

export interface PIEConfig {
    locateFile: (file: any) => any;
    mainScriptUrlOrBlob: string
}

export class PIEEditingEngine implements IEditingEngine<PIE, PIEConfig> {
    private _initialized = false;
    private _pieModule?: PIE;
    private _readyPromise: Promise<void>;
    private _readyPromiseResolve?: () => void;
    private _readyPromiseReject?: (reason?: any) => void;

    constructor() {
        this._readyPromise = new Promise((resolve, reject) => {
            this._readyPromiseResolve = resolve;
            this._readyPromiseReject = reject;
        });
    }

    async initialize(config: PIEConfig): Promise<void> {
        if (this._initialized) {
            return;
        }
        this._initialized = true;

        try {
            const pieObj = await init(config);
            const FS: any = pieObj.FS;
            const IDBFS: any = pieObj.IDBFS;

            FS.mkdir(PIE_PERSISTENT_DIRECTORY);
            FS.mkdir(PHOTOSHOP_TEMP_DIRECTORY);
            FS.mkdir(TEMP_DIRECTORY);

            FS.mount(IDBFS, {}, PIE_PERSISTENT_DIRECTORY);
            FS.mount(IDBFS, {}, PHOTOSHOP_TEMP_DIRECTORY);
            FS.mount(IDBFS, {}, TEMP_DIRECTORY);
            this._pieModule = pieObj;
            
            Logger.log(LogLevel.INFO, "PIEEditingEngine: initialize: PIE successfully initialized");
            if (this._readyPromiseResolve) {
                this._readyPromiseResolve();
            }
        } catch (error: any) {
            if (this._readyPromiseReject) {
                Logger.log(LogLevel.WARN, "PIEEditingEngine: initialize: Error initializing PIE", error);
                this._readyPromiseReject(
                    "Error parsing PIE MODULE from static resource file: " + error
                );
            }
        }
    }

    async ready(): Promise<void> {
        return this._readyPromise;
    }

    getModule(): PIE {
        if (!this._pieModule) {
            Logger.log(LogLevel.WARN, "PIEEditingEngine: getModule: pieModule not defined");
            throw new Error("_pieModule not defined");
        }
        return this._pieModule;
    }

    async shutdown(): Promise<void> {
        this._pieModule?.shutdown();
    }
}