/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ContentEntity, PreviewType } from "@elements/elementswebcommon";
import { ERROR_THUMBDATA, PROMISE_FULFILLED } from "./Constants/Constants";


export const ContentUtils = {

    async getContentUrls(data: ContentEntity[]): Promise<string[]> {
        const contentPromises: Promise<string>[] = [];
        data.forEach(element => {
            contentPromises.push(element.getContentURL(""));
        });
        const response = await Promise.allSettled(contentPromises);

        const content: string[] = [];
        response.forEach(element => {
            if (element.status === PROMISE_FULFILLED) {
                content.push(element.value);
            } else {
                content.push(ERROR_THUMBDATA);
            }
        });
        return Promise.resolve(content);
    },

    async getPreviewUrls(previewType: PreviewType,data: ContentEntity[]): Promise<string[]> {
        const previewPromises: Promise<string>[] = [];
        data.forEach(element => {
            previewPromises.push(element.getPreviewURL(previewType, ""));
        })
        const response = await Promise.allSettled(previewPromises);

        const previews: string[] = [];
        response.forEach(element => {
            if (element.status === PROMISE_FULFILLED) {
                previews.push(element.value);
            } else {
                previews.push(ERROR_THUMBDATA);
            }
        });
        return Promise.resolve(previews);
    }
}