/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/**
 * Base class for a performance clock
 */
export abstract class PerfClockBase {
    /**
     * Function that returns the current high resolution time, typically in ms. Set via the constructor.
     */
    private _currentTimeFn: () => number;

    /**
     * Time origin, as set during constructor.
     */
    private _timeOrigin: number;

    /**
     * Function that returns current time based on Date api, for detecting clock shift. Set via the constructor.
     */
    private _currentDateFn: () => number;

    /**
     * Create a default function for returning the current time, using the best resolution timing API available in the
     * current browser.
     */
    private _createDefaultCurrentTimeFunction(): () => number {
        return performance.timeOrigin !== undefined
            ? () => {
                return performance.timeOrigin + performance.now();
            }
            : () => {
                return Date.now();
            };
    }

    /**
     * Create a default function for returning the current time based on Date API
     */
    private _createDefaultCurrentDateFunction(): () => number {
        return () => {
            return Date.now();
        };
    }

    /**
     * Constructor.
     * @param _currentTimeFn - A function that returns the current time when called, in the desired unit for monitoring
     *      performance, typically ms.
     * @param _timeOrigin - Performance time origin in the desired unit for monitoring performance, typically ms.
     * @param _currentDateOrigin - Function that returns current time based on Date api, for measuring clock sync.
     */
    constructor(currentTimeFn?: () => number, timeOrigin?: number, currentDateFn?: () => number) {
        this._currentTimeFn = currentTimeFn ?? this._createDefaultCurrentTimeFunction();
        if (timeOrigin !== undefined) {
            this._timeOrigin = timeOrigin;
        } else {
            this._timeOrigin = performance.timeOrigin ?? performance.timing.navigationStart;
        }
        this._currentDateFn = currentDateFn ?? this._createDefaultCurrentDateFunction();
    }

    public get timeOrigin(): number {
        return this._timeOrigin;
    }

    /**
     * Return the current time, in the desired unit for monitoring performance, typically ms.
     */
    public currentTime(): number {
        return this._currentTimeFn();
    }

    /**
     * Measure the offset between currentTime and Date.now.
     */
    protected currentTimeOffset(): number {
        return this._currentDateFn() - this.currentTime();
    }
}