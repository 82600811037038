/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReactReduxProvider } from "react-redux";

//Application Specific
import { ControllerAction } from "../../../view/IViewController";
import { WorkspaceAction, WorkspaceActionType, WorkspacesName } from "../../IBaseWorkspace";
import IModalWorkspace, { ModalWorkspaceName } from "../../IModalWorkspace";
import { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../IWorkflow";
import IWorkspace from "../../IWorkspace";
import store from "../../../stores/store";
import { SampleMediaWorkspaceView } from "./SampleMediaWorkspaceView";
import { ViewAction } from "../../../view/IBaseController";
import Logger, { LogLevel } from "../../../utils/Logger";
import WorkflowFactory from "../../WorkflowFactory";
import { WorkflowSwitcher } from "../../common/WorkflowSwitcher";
import MediaGridConfigAction, { MediaGridConfig } from "../../../stores/actions/mediaGridConfigActions";
import { IngestUtils } from "../../../utils/IngestUtils";

import "./SampleMediaWorkspace.scss";

export class SampleMediaWorkspace extends IModalWorkspace {
    private readonly _modalWorkflowContainerId = "sample-media-wrapper-app-container";
    private _initWorkflowName?: WorkflowsName;
    private _initialAction?: WorkflowAction;
    protected overlayDiv!: HTMLDivElement;
    protected dialogClass?: string;
    protected container?: HTMLElement;

    constructor(owner: IWorkspace, overlayDiv: HTMLDivElement) {
        super();
        this._owner = owner;
        this.modalWorkspaceName = ModalWorkspaceName.sampleMedia;
        this.overlayDiv = overlayDiv;
    }

    createView(container: HTMLElement): void {
        super.createView(container);

        const sampleMediaWorkspace = React.createElement(SampleMediaWorkspaceView, {
            controller: this,
            overlayDiv: this.overlayDiv
        });

        const sampleMediaWorkspaceWrappedProvider = React.createElement(ReactReduxProvider, { store: store }, sampleMediaWorkspace);
        ReactDOM.render(
            sampleMediaWorkspaceWrappedProvider,
            container
        );
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        const workspacePayload = { startWorkflow: this._initWorkflowName };
        const workspaceAction = { type: WorkspaceActionType.startWorkflow, ...workspacePayload };
        this._startWorkflows(workspaceAction);
        if (this._initialAction) {
            const mediaGridConfig = this._initialAction.payload as MediaGridConfig;
            store.dispatch(MediaGridConfigAction.updateConfig(mediaGridConfig));
        }
    }

    /**
     *
     * @param containerId : id of html element where workspace is mounted
     * @param workflowName : optional workflowName to be started, since there can be modal workspaces which have fixed set of workflows like share.
     * @param payload : any info that needs to be pass on to the workflow
     */
    startWorkspace<T extends WorkflowAction>(containerId: string, workflowName?: WorkflowsName, action?: T): void {
        this._initWorkflowName = workflowName;
        this.createView(this.ensureHTMLElement(containerId));
        this._initialAction = action;
    }

    private _startWorkflows(action: WorkspaceAction): void {
        const startWorkflowName = action.startWorkflow;
        if (startWorkflowName) {
            const { type, ...workflowPayload } = action;
            workflowPayload.nextWorkflow = this._initialAction?.nextWorkflow;
            const workflowAction = { type: WorkflowActionType.initialize, ...workflowPayload };

            switch (startWorkflowName) {
                case WorkflowsName.sampleMediaManager:
                    {
                        const sampleMediaWorkflow = WorkflowFactory.createWorkflow(startWorkflowName, this);
                        this.currentWorkflow = sampleMediaWorkflow;
                        this.currentWorkflow?.startWorkflow(this._modalWorkflowContainerId, this._owner.getWorkflow, workflowAction);
                        break;
                    }
                case WorkflowsName.mediaManager:
                    {
                        this.currentWorkflow?.endWorkflow();
                        const modalWorkflowSwitcher = WorkflowSwitcher.getInstance();
                        const mediaSwitcherWorkflowAction = modalWorkflowSwitcher.getWorkflowAction;
                        if (mediaSwitcherWorkflowAction) {
                            const { type, ...workflowPayload } = mediaSwitcherWorkflowAction;
                            const switchModalWorkflowAction = { type: WorkspaceActionType.startWorkflow, ...workflowPayload };
                            const mediaWorkflow = WorkflowFactory.createWorkflow(startWorkflowName, this);
                            this.currentWorkflow = mediaWorkflow;
                            this.currentWorkflow?.startWorkflow(this._modalWorkflowContainerId, this._owner.getWorkflow, switchModalWorkflowAction);
                        }
                        break;
                    }
                default:
                    {
                        Logger.log(LogLevel.WARN, "SampleMediaWorkspace:_startWorkflows: ", "Invalid next workflow name");
                        break;
                    }
            }
        }
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        return this.notifyWorkspace(action as WorkspaceAction);
    }

    protected async notifyWorkspace<T extends WorkspaceAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case WorkspaceActionType.startWorkflow:
                {
                    this._startWorkflows(action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.startPreviousWorkflow:
                {
                    super.startPrevWorkflow(this._modalWorkflowContainerId, action);
                    handled = true;
                    break;
                }
            case WorkspaceActionType.endWorkflow:
            case WorkspaceActionType.endWorkspace:
                {
                    action.type = WorkspaceActionType.endModalWorkspace;
                    handled = await this.notifyWorkspace(action);
                    break;
                }
            case WorkspaceActionType.endModalWorkspace:
                {
                    this.endCurrentWorkflow();
                    handled = await this._owner.notify(action);
                    break;
                }
            case WorkspaceActionType.ingest:
                {
                    const ingestPayloadWithWorkflow = IngestUtils.addWorkspaceDetail(WorkspacesName.creations,
                        action.payload as Record<string, string>);
                    const ingestUpdatedAction = { type: WorkspaceActionType.ingest, payload: ingestPayloadWithWorkflow };
                    handled = await super.notifyWorkspace(ingestUpdatedAction);
                    break;
                }
            default:
                {
                    handled = await super.notifyWorkspace(action);
                    break;
                }
        }
        return handled;
    }
}