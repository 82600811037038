/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ContentType } from "@elements/elementswebcommon";
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import ELRecommendationWorkflowThumbPanel from "../../../../view/components/templates/el-overlay-panel/ELRecommendationWorkflowThumbPanel";
import ELPanelManager, { ELPanelManagerConfig } from "../../../../view/components/templates/el-panel-manager/ELPanelManager";
import IPanelProvider from "../../../common/IPanelProvider";
import { ELTabPanelKey, ELTabPanelType, ITabPanelItem, TabPanelButtonTypes } from "../../../../common/interfaces/tabpanel/ELTabPanelTypes";
import { IconType } from "../../../../assets/IconConstants";
import { ELSingleImageMediaManager } from "../../../../view/components/templates/el-single-image-media-manager/ELSingleImageMediaManager";
import ELLayoutPanel from "../../../../view/components/templates/el-social-layout/ELLayoutPanel";

export default class ELPatternOverlayPanelProvider extends IPanelProvider {

    private _getLeftTabPanel(): ELPanelManager {
        const intlHandler = IntlHandler.getInstance();
        const thumbPanelHeadingText = intlHandler.formatMessage("pattern-overlay-presets");
        const patterOverlayPanel = new ELRecommendationWorkflowThumbPanel(this.owner, ContentType.patternOverlay, thumbPanelHeadingText, true);

        const listOfTabItems: ITabPanelItem[] = [{
            buttonInfo: {
                iconType: IconType.patternOverlayTab,
                name: intlHandler.formatMessage("pattern-overlay-presets"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            },
            key: ELTabPanelKey.overlays,
            controller: patterOverlayPanel,
            view: patterOverlayPanel.getView()
        }];

        const config: ELPanelManagerConfig = {
            selectedKey: ELTabPanelKey.overlays,
            panelItemDirection: "row"
        };

        return new ELPanelManager(this.owner, listOfTabItems, config);
    }

    private _getRightTabPanel(): ELPanelManager {
        const intlHandler = IntlHandler.getInstance();
        const mediaManager = new ELSingleImageMediaManager(this.owner);
        const layout = new ELLayoutPanel(this.owner);

        const listOfTabItems: ITabPanelItem[] = [{
            buttonInfo: {
                iconType: IconType.mediaManagerTab,
                name: intlHandler.formatMessage("media"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            },
            key: ELTabPanelKey.media,
            controller: mediaManager,
            view: mediaManager.getView("recommendationWorkflowReducer")
        }, {
            buttonInfo: {
                iconType: IconType.socialLayout,
                name: intlHandler.formatMessage("layout"),
                buttonType: TabPanelButtonTypes.scoopedTabButton
            },
            key: ELTabPanelKey.layout,
            controller: layout,
            view: layout.getView()
        }];

        const config: ELPanelManagerConfig = {
            selectedKey: ELTabPanelKey.empty,
            panelItemDirection: "row-reverse"   
        }

        return new ELPanelManager(this.owner, listOfTabItems, config);
    }

    getTabPanel(panelType: ELTabPanelType): ELPanelManager {
        if (panelType === ELTabPanelType.leftTabPanel) {
            return this._getLeftTabPanel();
        } else if (panelType === ELTabPanelType.rightTabPanel) {
            return this._getRightTabPanel();
        } else {
            throw new Error("ELPatternOverlayPanelProvider::getTabPanel panelType not valid");
        }
    }
}