/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";

//Adobe Internal
import { Text, Dialog, Content, Divider, Heading, Flex, Button } from "@adobe/react-spectrum";

//Application Specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { TrialUtils } from "../../../../utils/TrialUtils";

export interface ELStartDialogProps {
    onStartTrialPress: () => void
}

export const ELStartTrialDialog = (props: ELStartDialogProps): React.ReactElement => {
    const intlHandler = IntlHandler.getInstance();

    return (
        <Dialog width="25vw" isDismissable>
            <Heading UNSAFE_className="start-trial-heading-text">{intlHandler.formatMessage("start-trial-heading", { max_trial_days: TrialUtils.getMaxTrialDays() })}</Heading>
            <Content>
                <Flex direction="column" alignItems="center" gap={"3vh"} marginTop={"3vh"}>
                    <Text UNSAFE_className="start-trial-body-text">
                        {intlHandler.formatMessage("start-trial-body", { max_trial_days: TrialUtils.getMaxTrialDays() })}
                    </Text>
                    <Divider size="M" />
                    <Flex justifyContent="center" width={"100%"}>
                        <Button variant="cta" onPress={props.onStartTrialPress}>{intlHandler.formatMessage("start-free-trial")}</Button>
                    </Flex>
                </Flex>
            </Content>
        </Dialog>
    )
}