/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


//Thirdparty
import { fabric } from "fabric";

//Application Specific
import { CSLayerEditInfo, ELLayerKind, ELStageLayerDataOptions, ELStageLayerPayload, CSLayerTextInfo } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELStageShapesType } from "../../../common/interfaces/stage/StageTypes";
import ImageUtils from "../../../utils/ImageUtils";
import { ELPIELayerParams } from "../../../common/interfaces/editing/pie/ELPIELayerTypes";
import { ELPIEBlendOptions, ELPIELayerEffects } from "../../../common/interfaces/editing/pie/PIETypes";
import { ELBounds, ELPoint, ELSize } from "../../../common/interfaces/geometry/ELGeometry";
import IController from "../../controller/IController";
import { IEditingEngine } from "../../editingEngines/IEditingEngine";
import IDoc from "../IDoc";

export default abstract class ILayer extends IController {
    protected engine?: IEditingEngine<unknown, unknown>;
    protected doc?: IDoc;
    protected layerData: ELStageLayerPayload = {} as ELStageLayerPayload;
    protected originalWidth = 0;
    protected originalHeight = 0;
    protected rotation = 0.0;
    protected lastPosition = { x: 0, y: 0 };
    protected layerImageDataBlobURL: string;

    constructor(doc?: IDoc, engine?: IEditingEngine<unknown, unknown>) {
        super();
        this.doc = doc;
        this.engine = doc?.getEngine() ?? engine;
        this.layerImageDataBlobURL = "";
    }

    getLastPosition(): ELPoint {
        return this.lastPosition;
    }

    setLastPosition(position: ELPoint): void {
        this.lastPosition = position;
    }

    getLastOriginalSize(): ELSize {
        return { width: this.originalWidth, height: this.originalHeight };
    }

    setLastOriginalSize(size: ELSize): void {
        this.originalWidth = size.width;
        this.originalHeight = size.height;
    }

    getLastRotation(): number {
        return this.rotation;
    }

    setLastRotation(rotation: number): void {
        this.rotation = rotation;
    }

    getVisibility(): boolean | undefined {
        return this.layerData.visible;
    }

    setVisibility(visible: boolean): void {
        this.layerData.visible = visible;
    }

    async setBlendOptions(blendOptions: ELPIEBlendOptions): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    async setEffects(effects: ELPIELayerEffects): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    async flipXLayer(): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    async flipYLayer(): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    async moveLayer(position: ELPoint): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    async rotateLayer(angle: number): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    async scaleLayer(newSize: ELSize): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    async setPixels(data: ArrayBuffer): Promise<boolean> {
        throw new Error("Method not implemented!");
    }

    getId(): string {
        return this.layerData.id;
    }

    getName(): string | undefined {
        return this.layerData.name;
    }

    setName(name: string): void {
        this.layerData.name = name;
    }

    hasAlpha(): boolean {
        return this.layerData.hasAlpha ?? false;
    }

    getData(): ImageData {
        return this.layerData.data as ImageData; //PIE_WASM_REVISIT - data is both arraybufer and imagedata - need templatized control for creating doc and layer
    }

    setData(data: ImageData): void {
        this.layerData.data = data;
    }

    getPath(): string | undefined {
        return this.layerData.path;
    }

    setPath(path: string): void {
        this.layerData.path = path;
    }

    getText(): string | undefined {
        return this.layerData.editInfo?.textInfo?.text;
    }

    setText(text: string): void {
        if (this.layerData.editInfo && this.layerData.editInfo.textInfo) {
            this.layerData.editInfo.textInfo.text = text;
        }
    }

    getLayerKind(): ELLayerKind {
        return this.layerData.layerKind;
    }

    getFitToBackground(): boolean {
        return this.layerData.fitToBackground ?? false;
    }

    getHasAlpha(): boolean | undefined {
        return this.layerData.hasAlpha;
    }

    getIsClipped(): boolean {
        return this.layerData.isClipped ?? false;
    }

    canExport(): boolean {
        return this.layerData.export ?? true;
    }

    getLayerParams(): ELPIELayerParams | undefined {
        return this.layerData.layerParams;
    }

    getRedraw(): boolean | undefined {
        return this.layerData.redraw;
    }

    getSize(): Promise<ELSize> {
        //PIE_WASM_REVISIT - data is both arraybufer and imagedata - need templatized control for creating doc and layer
        return Promise.resolve({ width: (this.layerData.data as ImageData).width, height: (this.layerData.data as ImageData).height });
    }

    async getBounds(): Promise<ELBounds> {
        //PIE_WASM_REVISIT - data is both arraybufer and imagedata - need templatized control for creating doc and layer
        const size = await this.getSize();
        return Promise.resolve({
            top: 0,
            left: 0,
            right: size.width,
            bottom: size.height,
        });

    }

    getSelectable(): boolean {
        return this.layerData.selectable ?? false;
    }

    private _getStageShapeFromLayerKind(layerKind: ELLayerKind): ELStageShapesType {
        switch (layerKind) {
            case ELLayerKind.path:
                return ELStageShapesType.path;
            case ELLayerKind.text:
                return ELStageShapesType.text;
            case ELLayerKind.rectangle:
                return ELStageShapesType.rect;
            case ELLayerKind.pixel:
            case ELLayerKind.image:
            default:
                return ELStageShapesType.imageFromURI;
        }
    }

    getStageShapeType(): ELStageShapesType {
        return this._getStageShapeFromLayerKind(this.layerData.layerKind);
    }

    getClipPathStageShapeType(): ELStageShapesType {
        if (!this.layerData.clipPathOptions) {
            throw new Error("Clip path options not available");
        }
        return this._getStageShapeFromLayerKind(this.layerData.clipPathOptions.layerKind);
    }

    async getStageData(): Promise<string> {
        switch (this.layerData.layerKind) {
            case ELLayerKind.path:
                return this.layerData.path ?? "";
            case ELLayerKind.text:
                return this.layerData.editInfo?.textInfo?.text ?? "";
            case ELLayerKind.pixel:
            default: {
                    this.layerImageDataBlobURL = URL.createObjectURL(
                        await ImageUtils.getBlobFromImageData(this.layerData.data as ImageData)
                    );

                    return this.layerImageDataBlobURL;
            }
        }
    }

    async getRendtionURL(): Promise<string> {
        return await ImageUtils.getDataURL(this.layerData.data as ImageData);
    }

    getEngine(): IEditingEngine<unknown, unknown> | undefined {
        return this.engine;
    }

    getEditInfo(): CSLayerEditInfo | undefined {
        return this.layerData.editInfo;
    }

    setEditInfo(layerEditInfo: CSLayerEditInfo): void {
        this.layerData.editInfo = layerEditInfo;
    }

    getTextOptions(): CSLayerTextInfo | undefined {
        return this.layerData.editInfo?.textInfo;
    }

    setTextOptions(textOptions: CSLayerTextInfo): void {
        if (!this.layerData.editInfo) {
            this.layerData.editInfo = {};
        }
        this.layerData.editInfo.textInfo = textOptions;
    }

    getFill(): string | fabric.Pattern | fabric.Gradient | undefined {
        return this.layerData.editInfo?.fill;
    }

    setFill(fill: string): void {
        if (!this.layerData.editInfo) {
            this.layerData.editInfo = {};
        }
        this.layerData.editInfo.fill = fill;
    }

    getOpacity(): number | undefined {
        return this.layerData.editInfo?.opacity;
    }

    setOpacity(opacity: number): void {
        if (!this.layerData.editInfo) {
            this.layerData.editInfo = {};
        }
        this.layerData.editInfo.opacity = opacity;
    }

    getClipLayerOptions(): ELStageLayerDataOptions | undefined {
        return this.layerData.clipPathOptions;
    }

    setClipLayerOptions(clipPathOptions: ELStageLayerDataOptions): void {
        this.layerData.clipPathOptions = clipPathOptions;
    }

    setClipLayerEditInfo(layerEditInfo: CSLayerEditInfo): void {
        if (this.layerData.clipPathOptions) {
            this.layerData.clipPathOptions.editInfo = layerEditInfo;
        }
    }

    getClipLayerEditInfo(): CSLayerEditInfo | undefined {
        return this.layerData.clipPathOptions?.editInfo;
    }

    abstract validateLayerData(): Promise<boolean>;
    abstract getBitmap(): Promise<ImageBitmap>;
    abstract save(path: string): Promise<boolean>;
    abstract getCenter(): Promise<ELPoint>;
}