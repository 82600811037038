/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { ELViewType } from "../../common/interfaces/creations/CreationTypes";
import IAction from "./IAction";

export type DualViewActionPayload = ELViewType;

export default class DualViewAction {
    public static readonly UPDATE_VIEW: string = "DualViewAction.UPDATE_VIEW";

    public static updateView(viewType: ELViewType): IAction<ELViewType> {
        return {
            type: DualViewAction.UPDATE_VIEW,
            payload: viewType
        };
    }
}