/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React from "react";
import ReactDOM from "react-dom";

//Application Specific
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../IWorkflow";
import IWorkspace from "../../IWorkspace";
import { UserHomeView } from "./UserHomeView";
import { ControllerAction } from "../../../view/IViewController";
import { ViewAction } from "../../../view/IBaseController";
import Logger, { LogLevel } from "../../../utils/Logger";
import ELVideoOverlay from "../../../view/components/organism/el-video-overlay/ELVideoOverlay";
import ELUserHomeEliveCards from "../../../view/components/templates/el-user-home-elive-cards/ELUserHomeEliveCards";
import ELUserHomeBanner from "../../../view/components/templates/el-user-home-banner/ELUserHomeBanner";
import { ELUserHomeActions } from "../../../common/interfaces/home/UserHomeTypes";
import ELUserHomeStaticContent from "../../../view/components/templates/el-user-home-static-content/ELUserHomeStaticContent";
import { EliveUtils } from "../../../utils/EliveUtils";
import { IngestEventSubTypes, IngestEventTypes, IngestLogObjectCustomKey, IngestLogObjectValue, IngestWorkflowTypes } from "../../../utils/IngestConstants";
import store from "../../../stores/store";
import { TrialUtils } from "../../../utils/TrialUtils";
import { IngestUtils } from "../../../utils/IngestUtils";
import { ELIngestParams } from "../../../common/interfaces/ingest/IngestTypes";

class UserHome extends IWorkflow {
    private _userHomeBanner: ELUserHomeBanner;
    private _userHomeEliveCards: ELUserHomeEliveCards;
    private _userHomeStaticContent: ELUserHomeStaticContent;
    private _videoOverlay?: ELVideoOverlay;
    private readonly _fullScreenContainer = "user-home__full_screen-container";
    private readonly _bannerContainer = "user-home-workflow__banner-container";
    private readonly _eliveCardsContainer = "user-home-workflow__cards-container";
    private readonly _staticContentContainer = "user-home-workflow__static-content-container";
    private readonly _footerContainer = "user-home-workflow__footer-container";


    constructor(workspace: IWorkspace) {
        super(workspace, WorkflowsName.userHome);
        this._userHomeBanner = new ELUserHomeBanner(this);
        this._userHomeEliveCards = new ELUserHomeEliveCards(this);
        this._userHomeStaticContent = new ELUserHomeStaticContent(this);
    }

    private _handleVideoOverlayEscape(): void {
        this.notify({ type: ELUserHomeActions.closeVideo });
    }

    private async _startTrial(payload: ELIngestParams): Promise<void> {
        await TrialUtils.startTrial(payload);
    }

    private _startTrialLater(payload: ELIngestParams): void {
        const additionalLogInfo: Record<string, string> = {};
        if (payload.eventViewType)
            additionalLogInfo[IngestLogObjectCustomKey.viewType] = payload.eventViewType;

        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.success, IngestEventTypes.startTrialLater, payload.eventValue, additionalLogInfo));
    }

    private _renderStartTrialDialog(): void {
        const additionalLogInfo: Record<string, string> = {};
        additionalLogInfo[IngestLogObjectCustomKey.viewType] = IngestLogObjectValue.trialModal;

        this.ingest(IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.success, IngestEventTypes.render, IngestLogObjectValue.trialModal, additionalLogInfo));
    }

    createView(container: HTMLElement): void {
        super.createView(container);
        const userHomeView = React.createElement(UserHomeView, {
            controller: this,
        });

        ReactDOM.render(
            userHomeView,
            container
        );
    }

    destroyView(): void {
        if (this.container) {
            ReactDOM.unmountComponentAtNode(this.container);
            this._userHomeBanner.destroyView();
            this._userHomeEliveCards.destroyView();
            this._userHomeStaticContent.destroyView();
        }
        super.destroyView();
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
        this._userHomeBanner.createView(this.ensureHTMLElement(this._bannerContainer));
        this._userHomeEliveCards.createView(this.ensureHTMLElement(this._eliveCardsContainer));
        this._userHomeStaticContent.createView(this.ensureHTMLElement(this._staticContentContainer));
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this.createView(this.ensureHTMLElement(containerId));
    }

    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;

        switch (action.type) {
            case ELUserHomeActions.watchVideo: {
                const src = action.payload as string;
                this._videoOverlay = new ELVideoOverlay(src, this._handleVideoOverlayEscape.bind(this), true);
                this._videoOverlay.createView(this.ensureHTMLElement(this._fullScreenContainer));
                handled = true;
                break;
            }
            case ELUserHomeActions.closeVideo: {
                this._videoOverlay?.destroyView();
                handled = true;
                break;
            }
            case ELUserHomeActions.startTrial: {
                this._startTrial(action.payload as ELIngestParams);
                handled = true;
                break;
            }
            case ELUserHomeActions.startTrialLater: {
                this._startTrialLater(action.payload as ELIngestParams);
                handled = true;
                break;
            }
            case ELUserHomeActions.renderStartTrialDialog: {
                this._renderStartTrialDialog();
                handled = true;
                break;
            }
            case WorkflowActionType.ingest: {
                const logObject = action.payload as Record<string, string>;
                const daysInTrial = store.getState().appReducer.daysInTrial;
                logObject[IngestLogObjectCustomKey.viewType] = EliveUtils.getUserState(daysInTrial);

                const actionWithAdditionalLogInfo = {
                    type: WorkflowActionType.ingest,
                    payload: logObject
                }
                handled = await this.notifyWorkflow(actionWithAdditionalLogInfo as WorkflowAction);
                break;
            }

            default: {
                Logger.log(LogLevel.WARN, "UserHome(notify): Bad action" + action);
            }
        }
        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }
}

export { UserHome };
