/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ELLayoutInfo } from "../../common/interfaces/creations/ELSocialLayoutTypes";
import { ELStageLayerData } from "../../common/interfaces/editing/layer/ELStageLayerTypes";
import { CanvasZoomLevelAction, ELDownloadData, ELLayoutPanelControllerAction } from "../../common/interfaces/stage/StageTypes";
import IViewController, { ControllerAction } from "../../view/IViewController";
import { DocumentActions, DocumentDirty } from "../../common/interfaces/document/DocumentTypes";
import { ELPIEBlendOptions, ELPIELayerEffects } from "../../common/interfaces/editing/pie/PIETypes";
import { ELPoint, ELSize } from "../../common/interfaces/geometry/ELGeometry";
import { ELAdobeAsset } from "../../common/interfaces/storage/AssetTypes";
import IWorkflow from "../../workspaces/IWorkflow";
import { IEditingEngine } from "../editingEngines/IEditingEngine";
import IRenderer from "../renderer/IRenderer";
import IDocumentDownloader from "./downloader/IDocumentDownloader";
import ILayer from "./layer/ILayer";
import IDocDataParser from "./parser/IDocDataParser";

export enum DocumentType {
	collage = "COLLAGE",
	adobeAsset = "ADOBE_ASSET",
	peekThrough = "PEEK_THROUGH",
	stageDoc = "STAGE_DOC",
	pie = "PIE"
}

export default abstract class IDoc extends IViewController {
	protected fileName?: string;
	protected mimeType?: string;
	protected dataParser?: IDocDataParser;
	protected renderer?: IRenderer;
	protected downloader?: IDocumentDownloader;
	protected cloudAsset?: ELAdobeAsset;
	protected engine?: IEditingEngine<unknown, unknown>;
	protected layers: ILayer[];
	protected isDirty: DocumentDirty;
	protected owner: IWorkflow;
	protected documentSize?: ELSize;
	protected layoutInfo?: ELLayoutInfo;
	protected data: unknown;
	protected usedPostscriptNames: string[] = [];
	protected docId?: string;

	constructor(owner: IWorkflow) {
		super();
		this.owner = owner;
		this.isDirty = DocumentDirty.NON_DIRTY;
		this.layers = [];
	}

	abstract save(): void;
	abstract render(container: HTMLElement): Promise<void>;
	abstract close(): Promise<void>;
	abstract getSize(): Promise<ELSize>;
	abstract getOriginalSize(): Promise<ELSize>;
	abstract documentUpdated(payload: unknown): void;
	abstract download(downloadData: ELDownloadData): void;
	abstract getData(): unknown;

	documentCreated(payload: unknown): Promise<void> { return Promise.resolve(); }
	async getImageData(): Promise<ImageData> { return Promise.reject("ImageData not available"); }
	setData(data: unknown): void { this.data = data; }

	async setLayerPixels(layerId: number, data: ArrayBuffer, redraw = false): Promise<boolean> {
		throw new Error("Method not implemented!");
	}

	async setLayerBlendOptions(layerId: number, blendOptions: ELPIEBlendOptions, redraw = false): Promise<boolean> {
		throw new Error("Method not implemented!");
	}

	async setLayerEffects(layerId: number, effects: ELPIELayerEffects, redraw = false): Promise<boolean> {
		throw new Error("Method not implemented!");
	}

	async setLayerVisibility(layerIndex: number, visible: boolean, redraw = false): Promise<boolean> {
		throw new Error("Method not implemented!");
	}

	async moveLayer(layerId: number, position: ELPoint, redraw = false): Promise<boolean> {
		throw new Error("Method not implemented!");
	}

	async rotateLayer(layerId: number, angle: number, redraw = false): Promise<boolean> {
		throw new Error("Method not implemented!");
	}

	async scaleLayer(layerId: number, newSize: ELSize, redraw = false): Promise<boolean> {
		throw new Error("Method not implemented!");
	}

	async exportPSD(downloadData: ELDownloadData): Promise<void> {
		throw new Error("Method not implemented!");
	}

	getEngine(): IEditingEngine<unknown, unknown> | undefined {
		return this.engine;
	}

	get getOwner(): IWorkflow {
		return this.owner;
	}

	get getFileName(): string | undefined {
		return this.fileName;
	}

	get getFileType(): string | undefined {
		return this.mimeType;
	}

	get isDocumentDirty(): DocumentDirty {
		return this.isDirty;
	}

	get getLayoutInfo(): ELLayoutInfo | undefined {
		return this.layoutInfo;
	}

	get getDownloader(): IDocumentDownloader | undefined {
		return this.downloader;
	}

	get getUsedPostscriptNames(): string[] {
		return this.usedPostscriptNames;
	}

	get getDocumentId(): string | undefined {
		return this.docId;
	}

	set setFileName(fileName: string) {
		this.fileName = fileName;
	}

	set setFileType(mimeType: string) {
		this.mimeType = mimeType;
	}

	set setRenderer(renderer: IRenderer) {
		this.renderer = renderer;
	}

	set setDataParser(dataParser: IDocDataParser) {
		this.dataParser = dataParser;
	}

	set setDownloader(downloader: IDocumentDownloader) {
		this.downloader = downloader;
	}

	set setLayoutInfo(layoutInfo: ELLayoutInfo) {
		this.layoutInfo = layoutInfo;
	}

	set setUsedPostscriptNames(postscriptNameList: string[]) {
		this.usedPostscriptNames = postscriptNameList;
	}

	set setDocumentId(id: string) {
		this.docId = id;
	}

	initialize<T>(payload?: T): Promise<boolean> {
		super.initialize(payload as any);
		return Promise.resolve(true);
	}

	async addLayer(data: ELStageLayerData, redraw = false): Promise<string> {
		throw new Error("Method not implemented!");
	}

	async removeLayer(layerId: string, redraw = false): Promise<void> {
		throw new Error("Method not implemented!");
	}

	async addLayerByIndex(data: ELStageLayerData, layerIndex: number): Promise<string> {
		throw new Error("Method not implemented!");
	}

	getLayerById(id: string): Promise<ILayer> {
		throw new Error("Method not implemented!");
	}

	getBaseLayerId(): string {
		if (this.layers.length === 0) {
			throw new Error("No layers in document");
		}
		return this.layers[0].getId();
	}

	getLayers(): Promise<ILayer[]> {
		return Promise.resolve(this.layers);
	}

	markAndNotifyDocumentDirty(dirty: DocumentDirty): void {
		this.isDirty = dirty;
		this.owner.notify({ type: DocumentActions.markDocumentDirty, payload: this.isDirty });
	}

	hasRenderingError(): boolean {
		return false;
	}

	async redraw(actionType: unknown, payload: unknown): Promise<void> {
		throw new Error("Method not implemented!");
	}

	destroy(): void {
		this.documentSize = undefined;
		this.layoutInfo = undefined;
		this.layers = [];
	}

	async notify<T extends ControllerAction>(action: T): Promise<boolean> {
		let handled = false;
		switch (action.type) {
			case CanvasZoomLevelAction.zoomInEvent:
			case CanvasZoomLevelAction.zoomOutEvent:
			case CanvasZoomLevelAction.changeZoomValue:
			case CanvasZoomLevelAction.zoomToFill:
			case CanvasZoomLevelAction.zoomToFit:
			case ELLayoutPanelControllerAction.scale:
			case ELLayoutPanelControllerAction.change:
			case ELLayoutPanelControllerAction.commit:
			case ELLayoutPanelControllerAction.cancel:
			case ELLayoutPanelControllerAction.revert:
				handled = this.renderer ? await this.renderer.notify(action) : false;
				break;
		}
		return handled;
	}

	async setLayerVisible(payload: unknown): Promise<void> {
		throw new Error("Method not implemented!");
	}

	async setLayerFill(payload: unknown): Promise<void> {
		throw new Error("Method not implemented!");
	}

	async setLayerOpacity(payload: unknown): Promise<void> {
		throw new Error("Method not implemented!");
	}

	getLayerTextOptions(layerId: string): Promise<unknown> {
		throw new Error("Method not implemented!");
	}

	async setLayerTextOptions(payload: unknown): Promise<void> {
		throw new Error("Method not implemented!");
	}

	getClipPathOptions(layerId: string): Promise<unknown> {
		throw new Error("Method not implemented!");
	}

	async setClipPathOptions(payload: unknown): Promise<void> {
		throw new Error("Method not implemented!");
	}
}
