/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Third party
import React, { useState, useEffect } from "react";

//Adobe internal
import { Text, Button, Flex } from "@adobe/react-spectrum";

//Application specific
import { IntlHandler } from "../../../../modules/intlHandler/IntlHandler";
import { ELCreationPopUp } from "./ELCreationPopUp";
import { ELSharePopUpAction } from "../../../../common/interfaces/share/ShareTypes";
import ELVideo from "../../atoms/el-video/ELVideo";

import "./ELCreationPopUp.scss";

interface ELCreationPopupViewProps {
    controller: ELCreationPopUp;
    timeout: number;
    visible: boolean;
    mediaPath: string;
    contentKey: string;
}

export const ELCreationPopUpView: React.FC<ELCreationPopupViewProps> = ({ controller, timeout, visible, mediaPath, contentKey }) => {
    const [isVisible, setIsVisible] = useState(false);
    const intlHandler = IntlHandler.getInstance();

    useEffect(() => {

        const timer = setTimeout(() => {
            setIsVisible(visible);
        }, timeout);

        return () => clearTimeout(timer);
    }, [timeout, visible]);

    const handleGetStartedClick = (): void => {
        controller.notify({ type: ELSharePopUpAction.popUpGetStarted });
    }

    const handleCloseButtonClick = (): void => {
        controller.notify({ type: ELSharePopUpAction.popUpClosed });
    }

    return (
        <Flex isHidden={!isVisible} UNSAFE_className="popup">
            <button data-testId="creation-popup-close-button" onClick={handleCloseButtonClick} className="popup__close-button">&times;</button>
            <div className="popup__content">
                <ELVideo src={mediaPath} autoPlay={true} loop={true} muted={true} hideControls={true} UNSAFE_className="popup__video" variant="native" type="video/mp4" />
                <div data-testId="creation-popup-content" className="popup__creation-text">
                    <Text>{intlHandler.formatMessage(contentKey)}</Text>
                </div>
                <Button data-testId="creation-popup-getstarted-button" variant="cta" onPress={handleGetStartedClick} UNSAFE_className={"popup__get-started-button"}>
                    <Text>{intlHandler.formatMessage("user-get-started")}</Text>
                </Button>
            </div>
        </Flex>
    );
};
