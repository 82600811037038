/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Adobe Internal
import {
    CreationOperationSubTypeInfo,
    CreationType, ExportOperationTypeInfo,
} from "@elements/elementswebcommon";

//Application Specific
import { CreationsJobCreator } from "../../../utils/CreationsJobCreator";
import { ELCollageProjectRequestParams } from "../../../../../common/interfaces/creations/ELCollageTypes";
import { IBaseRequestParams } from "../../../../../common/interfaces/creations/ELCreationsJobTypes";

export class CollageJobCreator extends CreationsJobCreator {

    // overriding
    protected createOperationOutput(requestParameters: IBaseRequestParams): undefined {
        return undefined;
    }

    protected createExportOperationTypeInfo(requestParameters: ELCollageProjectRequestParams): ExportOperationTypeInfo | undefined {
        return undefined;
    }
    protected getCreationType(): CreationType {
        return CreationType.photoCollage;
    }
    protected createOperationSubTypeInfo(requestParameters: ELCollageProjectRequestParams): CreationOperationSubTypeInfo | undefined {
        return undefined;
    }
}