/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { Routes } from "../app/AppRoute";
import { TrialState } from "../common/interfaces/trial/TrialTypes";
import { TrialService } from "../services/ElementsServices/TrialService";
import { LocalStorageKeys } from "./Constants/Constants";
import Logger, { LogLevel } from "./Logger";
import Utils from "./Utils";
import { IngestLogging } from "../services/IngestWrapper";
import { IngestUtils } from "./IngestUtils";
import { WorkspacesName } from "../workspaces/IBaseWorkspace";
import { IngestEventSubTypes, IngestLogObjectCustomKey, IngestLogObjectKey, IngestWorkflowTypes } from "./IngestConstants";
import { WindowUtils } from "./WindowUtils";
import { ELIngestParams } from "../common/interfaces/ingest/IngestTypes";
import { URLConstants } from "./Constants/URLConstants";

const TRIAL_EXPIRING_LIMIT = 3;

export const TrialUtils = {

    getMaxTrialDays: (): number => {
        const maxTrialDaysInput = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "prod" ? localStorage.getItem(LocalStorageKeys.kMaxTrialDDays) : null;
        const maxTrialDays = maxTrialDaysInput !== null ? Number(maxTrialDaysInput) : Number(process.env.REACT_APP_MAX_NUMBER_OF_TRIAL_DAYS);

        return maxTrialDays;
    },

    getTrialState: (daysInTrial: number): TrialState => {
        const minTrialDays = Number(process.env.REACT_APP_MIN_NUMBER_OF_TRIAL_DAYS);
        const maxTrialDays = TrialUtils.getMaxTrialDays();

        if (!isNaN(maxTrialDays) && daysInTrial > maxTrialDays)
            return TrialState.expired;
        else if (!isNaN(minTrialDays) && daysInTrial < minTrialDays)
            return TrialState.notStarted;
        else
            return TrialState.inProgress;
    },

    startTrial: async (ingestParams?: ELIngestParams): Promise<void> => {
        const additionalLogInfo: Record<string, string> = {};
        if (ingestParams?.eventViewType)
            additionalLogInfo[IngestLogObjectCustomKey.viewType] = ingestParams.eventViewType;

        try {
            const trialService = new TrialService();
            await trialService.startTrial();
            IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(WorkspacesName.userHome,
                IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.success, IngestEventSubTypes.startTrial, ingestParams?.eventValue, additionalLogInfo)));
            TrialUtils.handleTrialStarted(true);
        } catch (error: any) {
            Logger.log(LogLevel.WARN, "TrialUtils:tartTrial: ", "error starting trial for user", error);
            IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(WorkspacesName.userHome,
                IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.error, IngestEventSubTypes.startTrial, ingestParams?.eventValue, additionalLogInfo)));
            TrialUtils.handleTrialStarted(false);
        }
    },

    handleTrialStarted: (trialStarted: boolean): void => {
        const route = window.location.pathname;
        const isValidRouteForRedirection = !Utils.isValidRoutePath(route) || route === Routes.HOME || route === Routes.ROOT;
        if (trialStarted && isValidRouteForRedirection)
            Utils.openInSameTab(Routes.MEDIA);
        else
            WindowUtils.reload();
    },

    continueTrial: (): void => {
        Utils.openInSameTab(Routes.MEDIA);
    },

    troubleshoot: (): void => {
        Utils.openInNewTab(URLConstants.GoURL.REACT_APP_TRIAL_TROUBLESHOOT);
        const additionalIngestInfo: Record<string, string> = {};
        additionalIngestInfo[IngestLogObjectKey.contentName] = IngestLogObjectCustomKey.landingURL;
        additionalIngestInfo[IngestLogObjectKey.eventCount] = URLConstants.GoURL.REACT_APP_TRIAL_TROUBLESHOOT;
        IngestLogging.getInstance().logEvent(IngestUtils.addWorkspaceDetail(IngestWorkflowTypes.userHome,
            IngestUtils.getPseudoLogObject(IngestWorkflowTypes.operations, IngestEventSubTypes.success, IngestEventSubTypes.troubleshootAccess, null, additionalIngestInfo)));
    },

    isTrialInProgress: (daysInTrial: number): boolean => {
        return (TrialUtils.getTrialState(daysInTrial) === TrialState.inProgress);
    },

    isTrialExpired: (daysInTrial: number): boolean => {
        return (TrialUtils.getTrialState(daysInTrial) === TrialState.expired);
    },

    getDaysLeftForTrial: (daysInTrial: number): number => {
        const maxTrialDays = TrialUtils.getMaxTrialDays();

        return Math.ceil(Math.max(0, maxTrialDays - daysInTrial));
    },

    isTrialGoingToExpire: (daysInTrial: number): boolean => {
        return TrialUtils.getDaysLeftForTrial(daysInTrial) <= TRIAL_EXPIRING_LIMIT;
    }
}