/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React from "react";
import ReactDOM from "react-dom";

//utils
import { MAX_ASSETS_SHARE_LIMIT } from "../../../../../utils/Constants/Constants";

//Application Specific
import { ViewAction } from "../../../../../view/IBaseController";
import { EmailShareView, EmailControllerAction, SendEmailParamsType } from "./EmailShareView";
import IBaseWorkspace, { WorkspaceActionType } from "../../../../IBaseWorkspace";
import IWorkflow, { WorkflowAction, WorkflowActionType, WorkflowsName } from "../../../../IWorkflow";
import { ShareHandlerFactory } from "../../../../../services/shareHandler/ShareHandlerFactory";
import { EmailData, LinkInfo, ShareMode, ShareResponse, ShareType } from "../../../../../common/interfaces/share/ShareTypes";
import Logger, { LogLevel } from "../../../../../utils/Logger";
import { ControllerAction } from "../../../../../view/IViewController";
import store from "../../../../../stores/store";
import { IntlHandler } from "../../../../../modules/intlHandler/IntlHandler";
import { ShareUtils } from "../../../../../utils/ShareUtils";
import { IngestUtils } from "../../../../../utils/IngestUtils";
import { IngestEventSubTypes, IngestWorkflowTypes } from "../../../../../utils/IngestConstants";

//Adobe Internal
import { ToastUtils } from "../../../../../utils/ToastUtils";

const MAX_RECEIPTENTS = 50;

class EmailShare extends IWorkflow {

    private _linkInfo!: LinkInfo;

    constructor(owner: IBaseWorkspace) {
        super(owner, WorkflowsName.emailShare);
    }

    initialize(dispatch?: React.Dispatch<ViewAction>): void {
        super.initialize(dispatch);
    }

    private _ingestShareComplete(success: boolean): void {
        ShareUtils.ingestShareComplete(ShareMode.email, success, this.notify.bind(this));
    }

    private _handleEmailShareResponse(response: ShareResponse, assetsCount: number, shareType: ShareType): void {
        if (!(response.errorCode || response.errorMessage)) {
            this._ingestShareComplete(true);
            ShareUtils.showShareSuccessToast(response.link,
                ShareUtils.getShareCompleteToastMsg(assetsCount, ShareMode.email, shareType))
        } else if (response.errorMessage) {
            this._ingestShareComplete(false);
            this._handleEmailErrorToast(response.errorMessage);
        }
    }

    private _handleEmailErrorToast(errorMsgKey: string): void {
        const intlHandler = IntlHandler.getInstance();
        ToastUtils.error(intlHandler.formatMessage(errorMsgKey));
    }

    async startEmailShare(receiptentList: string, message: string, sendCopyValue: boolean): Promise<void> {
        try {

            const shareHandler = ShareHandlerFactory.getInstance().getShareHandler(ShareMode.email);
            const shareType = store.getState().shareReducer.shareType;
            //TODO - vib need to have separate template for slideshow so that string a slideshow/media can be localized
            const emailData: EmailData = {
                receiptentList: receiptentList,
                message: message,
                sendCopyValue: sendCopyValue,
                shareType: shareType
            }

            if (this._linkInfo.link !== "" && this._linkInfo.sharedAssetsCount > 0)
                await shareHandler?.shareLink(this._linkInfo.link, (response: ShareResponse) => { this._handleEmailShareResponse(response, this._linkInfo.sharedAssetsCount, shareType) }, emailData);
            else {
                Logger.log(LogLevel.ERROR, "EmailShare:startEmailShare: ", `cannot proceed to share link, got link = ${this._linkInfo.link}, assetsCount = ${this._linkInfo.sharedAssetsCount}`);
                return Promise.reject();
            }
        }
        catch (err) {
            Logger.log(LogLevel.ERROR, "EmailShare:startEmailShare: ", "Error in sending email request ", err);
            return Promise.reject(err);
        }
    }

    private _onSendEmailPress(receiptentList: string, message: string, sendCopyValue: boolean): void {
        let toListLength = (receiptentList.replace(/\s/g, '').split(',')).length;
        if (sendCopyValue)
            toListLength++;

        if (toListLength <= MAX_RECEIPTENTS) {
            this.startEmailShare(receiptentList, message, sendCopyValue);
            this._owner.notify({ type: WorkspaceActionType.endWorkspace });
        } else {
            ToastUtils.error(IntlHandler.getInstance().formatMessage("email-receiptentList-length-exceeded-msg", { count: MAX_ASSETS_SHARE_LIMIT }));
        }
    }

    createView(container: HTMLElement): void {
        super.createView(container)


        ReactDOM.render(
            React.createElement(EmailShareView,
                {
                    controller: this
                }),
            container
        )
    }

    destroyView(): void {
        if (this.container)
            ReactDOM.unmountComponentAtNode(this.container);
        super.destroyView();
    }

    destroy(): void {
        super.destroy();
    }

    startWorkflow(containerId: string, prevWorkflow?: IWorkflow, action?: WorkflowAction): void {
        super.startWorkflow(containerId, prevWorkflow, action);
        this._linkInfo = store.getState().shareReducer.linkInfo;
        this.createView(this.ensureHTMLElement(containerId));
    }

    /**
     * Handles ui events generated by views rendered in the workflow
     * @param action ControllerAction
     */
    async notify<T extends ControllerAction>(action: T): Promise<boolean> {
        let handled = false;
        switch (action.type) {
            case EmailControllerAction.sendEmailPressed:
                {
                    const params = action.payload as SendEmailParamsType;
                    if (params.sendCopyValue === true) {
                        this.notify({
                            type: WorkflowActionType.ingest,
                            payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.email,
                                IngestEventSubTypes.click, IngestEventSubTypes.sendCopy, true)
                        });
                    }
                    this.notify({
                        type: WorkflowActionType.ingest,
                        payload: IngestUtils.getPseudoLogObject(IngestWorkflowTypes.email,
                            IngestEventSubTypes.click, IngestEventSubTypes.sendEmail, true)
                    });
                    this._onSendEmailPress(params.receiptentList, params.message, params.sendCopyValue);
                    handled = true;
                    break;
                }
            default:
                {
                    break;
                }
        }

        if (!handled)
            handled = await this.notifyWorkflow(action as WorkflowAction);

        return handled;
    }
}

export { EmailShare };
