/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import React, { useEffect, ReactNode, useReducer } from "react";

const iPhoneXRLandscapeWidth = 896; //landscape mode
const iPhoneXRPortraitWidth = 414; //portrait mode

interface ViewportContext {
    width: number;
    height: number;
    isMobile: boolean;
    isMobilePortraitMode: boolean;
}

interface ViewportProviderProps {
    children?: ReactNode
}

function getInitialViewPortState(): ViewportContext {
    return {
        width: window.innerWidth, height: window.innerHeight,
        isMobile: false, isMobilePortraitMode: false
    }
}

interface ViewPortAction {
    type: string;
    payload: ViewportContext;
}


enum ViewPortActionType {
    updateViewPortState = "UPDATE_VIEWPORT_STATE"
}

function reducer(state: ViewportContext, action: ViewPortAction): ViewportContext {
    switch (action.type) {
        case ViewPortActionType.updateViewPortState:
            {
                let newState = state;
                newState = {
                    ...newState,
                    height: action.payload.height ?? state.height,
                    width: action.payload.width ?? state.width,
                    isMobile: action.payload.isMobile ?? state.isMobile,
                    isMobilePortraitMode: action.payload.isMobilePortraitMode ?? state.isMobilePortraitMode
                }
                return newState;
            }
        default:
            return state;

    }
}

const viewportContext = React.createContext<ViewportContext>({
    width: window.innerWidth, height: window.innerHeight,
    isMobile: false, isMobilePortraitMode: false
});

function debounce(fn: () => void, ms: number): () => void {
    let timer: unknown;
    return () => {
        clearTimeout(timer as NodeJS.Timer);
        timer = setTimeout(() => {
            timer = null;
            fn();
        }, ms)
    };
}

export const ViewportProvider = (props: ViewportProviderProps): JSX.Element => {
    const [state, viewDispatch] = useReducer(reducer, getInitialViewPortState());

    const debouncedHandleResize = debounce((): void => {

        viewDispatch({
            type: ViewPortActionType.updateViewPortState,
            payload: {
                width: window.innerWidth, height: window.innerHeight,
                isMobile: (window.innerWidth <= iPhoneXRLandscapeWidth),
                isMobilePortraitMode: (window.innerWidth <= iPhoneXRPortraitWidth)
            }
        })

    }, 200); // TODO: niskumar - check if debounce value can be passed as param and does it overwritten by the last Provider wrapper?


    useEffect(() => {
        window.addEventListener("resize", debouncedHandleResize);
        return () => window.removeEventListener("resize", debouncedHandleResize);
    }, []);

    return (
        <viewportContext.Provider value={state} >
            {props.children}
        </viewportContext.Provider>
    );
};

export const useViewport = (): Record<string, number | boolean> => {
    const { width, height, isMobile, isMobilePortraitMode } = React.useContext(viewportContext);
    return { width, height, isMobile, isMobilePortraitMode };
};

