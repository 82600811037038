/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import React from "react";

import { Flex } from "@adobe/react-spectrum";
import { DOMProps } from "@react-types/shared";
import { ELIcon } from "../../atoms/el-icon/ELIconView";
import { IconType } from "../../../../assets/IconConstants";


export interface IconAndTextProps extends DOMProps {
    text: string,
    marginTextStart?: string,
    marginTextEnd?: string,
    icon: IconType,
    superImage?: string
    superAlt?: string,
    iconClass?: string,
    textClass?: string //TODO - niskumar: marginTextEnd and marginTextStart should be merged in the class itself
}

const IconAndText = (props: IconAndTextProps): React.ReactElement => {
    return (
        <Flex id={props.id} direction="row" alignItems="center" justifyContent="center">
            <ELIcon iconkey={props.icon} className={props.iconClass} />
            <h1 className={props.textClass}>{props.text}</h1>
            {props.superImage ? <img crossOrigin="anonymous" src={props.superImage} alt={props.superAlt ? props.superAlt : ""} />
                : <></>}
        </Flex>)
}

IconAndText.defaultProps = {
    iconClass: "icon-and-text__icon"
}

export default IconAndText;