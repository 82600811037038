/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Application Specific
import { ControllerData } from "../../../common/interfaces/editing/ControllerTypes";
import { ELLayerKind, ELStageLayerPayload } from "../../../common/interfaces/editing/layer/ELStageLayerTypes";
import { ELPoint } from "../../../common/interfaces/geometry/ELGeometry";
import Logger, { LogLevel } from "../../../utils/Logger";
import IDoc from "../../document/IDoc";
import ILayer from "../../document/layer/ILayer";

export default class ELStageLayer extends ILayer {
    protected bitmapPromise!: Promise<ImageBitmap>;

    private constructor(doc?: IDoc) {
        super(doc);
    }

    public static async createLayer(data: ELStageLayerPayload, doc?: IDoc): Promise<ELStageLayer> {
        const stageLayer = new ELStageLayer(doc);
        await stageLayer.initialize(data);
        return stageLayer;
    }

    validateLayerData(): Promise<boolean> {
        let isValid = true;

        if (this.layerData.layerKind === ELLayerKind.text) {
            isValid = !!this.layerData.editInfo?.textInfo?.text;
        }

        if (this.layerData.layerKind === ELLayerKind.path) {
            isValid = !!this.layerData.path;
        }

        if (this.layerData.layerKind === ELLayerKind.rectangle) {
            isValid = true;
        }

        if (this.layerData.layerKind === ELLayerKind.image) {
            isValid = !!this.layerData.data;
        }

        if (!(this.layerData.data instanceof ImageData)) {
            isValid = false;
        }

        return Promise.resolve(isValid);
    }

    async initialize<T>(payload?: T | undefined): Promise<boolean> {
        const stageLayerPayload = payload as unknown as ELStageLayerPayload;
        this.layerData = stageLayerPayload;

        try {
            if (await this.validateLayerData()) {
                this.bitmapPromise = createImageBitmap(this.layerData.data as ImageData);  //PIE_WASM_REVISIT - data is both arraybufer and imagedata - need templatized control for creating doc and layer
                return Promise.resolve(true);
            }
        } catch (error) {
            Logger.log(LogLevel.DEBUG, "ELStageLayer: initialize failed - ", error);
        }

        return Promise.reject("Invalid layer data!");
    }

    destroy(): void {
        if(this.layerImageDataBlobURL) {
            URL.revokeObjectURL(this.layerImageDataBlobURL);   
        }
        Logger.log(LogLevel.INFO, "ELStageLayer - (destroy)");
    }

    getBitmap(): Promise<ImageBitmap> {
        return this.bitmapPromise;
    }

    save(path: string): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    getCenter(): Promise<ELPoint> {
        throw new Error("Method not implemented.");
    }

    async notify(action: ControllerData): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

}