/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

//Thirdparty
import { ExportStore, IFileProgressInfo, IExportFileData } from "@ccxi/ccac-utilities";

//Application Specific
import IMS from "../../services/IMS";
import Logger, { LogLevel } from "../../utils/Logger";
import IExportHandler from "../../workspaces/common/IExportHandler";
import { ELAdobeAsset } from "../../common/interfaces/storage/AssetTypes";
import Utils from "../../utils/Utils";
import { ELExportOption, ELExportInfo } from "../../common/interfaces/export/ELExportTypes";

export default class ELAssetExportHandler extends IExportHandler {

    private _exportStore: ExportStore;

    constructor() {
        super();
        this._exportStore = new ExportStore();
        if (!this._exportStore) {
            Logger.log(LogLevel.ERROR, "ELAssetExportHandler: ", "Unable to init Export Store");
            return;
        }
    }

    async downloadFile(exportInfo: ELExportInfo, exportOption: ELExportOption): Promise<void> {

        const asset = await this.getValidAdobeAsset(exportInfo.asset);

        if (!asset) {
            Logger.log(LogLevel.WARN, "ELAssetExportHandler: downloadFile", "Could not start export, asset undefined");
            return Promise.reject();
        }

        const env = process.env.REACT_APP_ENV ?? "";
        const clientID = process.env.REACT_APP_REPO_API_KEY ?? "";
        if (env === "" || clientID === "") {
            Logger.log(LogLevel.DEBUG, "Error uploading file", env, clientID);
            return Promise.reject();
        }

        this._exportStore.init({
            environment: env,
            clientId: clientID,
            getAccessToken: () => Promise.resolve(IMS.getInstance().getUserAccessToken())
        });

        const formattedDownloadFile = (item: ELAdobeAsset): IExportFileData => ({
            urn: item.assetId ?? "",
            mimeType: item.format ?? "",
            fileName: exportInfo.defaultName ?? item.name ?? "",
            modifiedDate: new Date(item.modifyDate ?? ""),
        });
        const formattedDownloadFiles = (items: ELAdobeAsset[]): IExportFileData[] => items.map(item => formattedDownloadFile(item));

        const callbacks = (exportOption === ELExportOption.native) ? {
            handleArrayBuffer: (fileId: string, buffer: ArrayBuffer): Promise<void> => {
                const url = URL.createObjectURL(new Blob([buffer]));
                Utils.downloadURI(url, exportInfo.defaultName);
                return Promise.resolve();
            }
        } : {};

        const onCompletePromise = this._exportStore.downloadFiles(
            formattedDownloadFiles([asset]),
            callbacks
        );

        const promise = onCompletePromise.onComplete.then((value: IFileProgressInfo[]) => {
            Logger.log(LogLevel.INFO, "Download Complete", value);
            return Promise.resolve();
        }).catch(() => {
            Logger.log(LogLevel.ERROR, "ELAssetExportHandler:downloadFile: ", "Unable to download");
            return Promise.reject();
        });

        return promise;
    }
}