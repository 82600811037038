/*************************************************************************
 *
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/


export enum ELLayerBlendModes {
    normal = "normal",
    dissolve = "dissolve",
    multiply = "multiply",
    divide = "divide",
    screen = "screen",
    overlay = "overlay",
    soft_light = "soft_light",
    hard_light = "hard_light",
    darken = "darken",
    lighten = "lighten",
    difference = "difference",
    hue = "hue",
    saturation = "saturation",
    color = "color",
    luminosity = "luminosity",
    exclusion = "exclusion",
    color_dodge = "color_dodge",
    color_burn = "color_burn",
    hard_mix = "hard_mix",
    linear_dodge = "linear_dodge",
    linear_burn = "linear_burn",
    linear_light = "linear_light",
    vivid_light = "vivid_light",
    pin_light = "pin_light",
    saturate = "saturate",
    desaturate = "desaturate",
    lighter_color = "lighter_color",
    darker_color = "darker_color",
}

export interface ELPIELayerParams {
    name?: string,
    fillNeutral?: boolean;
    group?: boolean,
    opacity?: number;
    layerBlendMode?: ELLayerBlendModes;
}